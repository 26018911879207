import { withStyles } from '@material-ui/core';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import clsx from 'clsx';
import { ReactNode } from 'react';

import { colors } from '../../Core/Theme';

type Props = {
  className?: string;
  children: ReactNode;
  classes: ClassNameMap;
  theme?: ChipThemes;
};

export enum ChipThemes {
  Primary,
  Gray,
}

const Chip = (props: Props) => {
  const { classes, className, children, theme } = props;
  return (
    <div
      className={clsx(
        classes.container,
        className,
        theme === ChipThemes.Primary && classes.themePrimary,
        theme === ChipThemes.Gray && classes.themeGray
      )}
    >
      {children}
    </div>
  );
};

const styles = {
  container: {
    borderRadius: 9999,
    fontFamily: 'VerlagBold',
    padding: '4px 10px 4px 10px',
    fontSize: 16,
    lineHeight: 1.1,
    width: 'fit-content',
  },
  themePrimary: {
    color: colors.palette.primary.main,
    backgroundColor: '#e7f5e6',
  },
  themeGray: {
    color: colors.darkGray,
    backgroundColor: colors.light,
  },
};

Chip.defaultProps = {
  className: '',
  theme: ChipThemes.Primary,
};

export default withStyles(styles)(Chip);
