export const LOAD_BOOKINGS_PENDING = 'LOAD_BOOKINGS_PENDING';
export const LOAD_BOOKINGS_FULFILLED = 'LOAD_BOOKINGS_FULFILLED';
export const LOAD_BOOKINGS_REJECTED = 'LOAD_BOOKINGS_REJECTED';
export const LOAD_BOOKINGS = 'LOAD_BOOKINGS';

export const UPDATE_BOOKING = 'UPDATE_BOOKING';

export const DELETE_BOOKING = 'DELETE_BOOKING';

export const ADD_BOOKING = 'ADD_BOOKING';
