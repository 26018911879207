import PropTypes from 'prop-types';
import ContentLoader from 'react-content-loader';
import { colors } from '../../../Core/Theme';

const LOADING_BLOCK = (
  <>
    <rect x="0" y="0" rx="1" ry="1" width="100%" height="100%" />
  </>
);

const SkeletonLoader = props => {
  const { className, speed } = props;
  return (
    <div className={className}>
      <ContentLoader
        viewBox="0 0 100% 100%"
        height="100%"
        width="100%"
        speed={speed}
        backgroundColor={colors.light}
        foregroundColor={colors.middle}
      >
        {LOADING_BLOCK}
      </ContentLoader>
    </div>
  );
};

SkeletonLoader.defaultProps = {
  className: '',
  speed: 1,
};

SkeletonLoader.propTypes = {
  className: PropTypes.string,
  speed: PropTypes.number,
};

export default SkeletonLoader;
