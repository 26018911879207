import { colors, breakpoints } from '../../../Core/Theme';

const styles = theme => ({
  dayPicker: {
    borderBottom: 'solid 1px black',
    display: 'flex',
    flexDirection: 'row',
    cursor: 'pointer',
    fontFamily: 'VerlagBold',
    fontSize: 20,
  },
  roundPicker: {
    border: `solid 1.5px ${colors.palette.primary.main}`,
    borderRadius: 15,
    fontFamily: 'VerlagBook',
    fontSize: 12,
    cursor: 'pointer',
    width: 90,
    paddingTop: 3,
    [theme.breakpoints.up(breakpoints.MOBILE)]: {
      fontSize: 14,
      width: 110,
    },
  },
  closeButton: {
    height: 30,
    width: 30,
    float: 'right',
  },
  closeDiv: {
    textAlign: 'center',
    marginTop: 20,
    marginBottom: 30,
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    paddingRight: 20,
    paddingLeft: 20,
    bottom: 0,
  },
  inputText: {
    fontFamily: 'VerlagBold',
    fontSize: '18px',
    borderBottom: 'solid 1px black',
    display: 'flex',
    '&:focus:not(.focus-visible)': {
      outline: 'none',
    },
  },
  dateLabel: {
    fontFamily: 'VerlagBold',
    fontSize: '20px',
    marginBottom: '-23px',
    width: '100%',
    position: 'absolute',
  },
  dateLabelError: {
    fontFamily: 'VerlagLight',
    fontSize: '18px',
    marginBottom: '-23px',
    width: 'calc(100% - 30px)',
    position: 'absolute',
    color: colors.alertMessage,
    background: 'white',
    zIndex: 3,
    top: 2,
    paddingLeft: 10,
  },
  expandMore: {
    fontSize: 20,
    marginLeft: 'auto',
    marginRight: -4,
  },
  textButton: {
    marginTop: 5,
  },
  carat: {
    // marginLeft: 'auto',
    marginRight: 0,
    marginTop: 5,
    width: `100%`,
    textAlign: 'right',
  },
  dateTitle: {
    fontFamily: 'VerlagBold',
    fontSize: 20,
    // paddingLeft: 20,
  },
  submitDate: {
    // marginTop: '60vh',
    position: 'fixed',
    bottom: 30,
    left: 20,
    right: 20,
    width: 'calc(100% - 40px)',
    fontFamily: 'VerlagBold',
    fontSize: 15,
  },
  todayButton: {
    paddingLeft: 0,
  },
  weekDate: {
    background: colors.palette.primary.light,
    height: 'calc(100% + 2px)',
    marginTop: -1,
    marginLeft: -1.5,
    marginRight: -1,
    [theme.breakpoints.up(breakpoints.MOBILE)]: {
      marginLeft: -3,
      marginRight: -5,
    },
  },
  monday: {
    borderTopLeftRadius: '50%',
    borderBottomLeftRadius: '50%',
  },
  friday: {
    borderTopRightRadius: '50%',
    borderBottomRightRadius: '50%',
  },
  wrapper: {
    position: 'relative',
  },
  calendarIcon: {
    paddingRight: 5,
    paddingLeft: 7,
    marginTop: -4,
    width: 12,
    [theme.breakpoints.up(breakpoints.MOBILE)]: {
      paddingLeft: 10,
      width: 14,
      paddingRight: 7,
    },
  },
  loader: {
    height: 31,
    width: 'calc(700%)',
    marginLeft: 5,
    [theme.breakpoints.up(breakpoints.MOBILE)]: {
      width: 'calc(700% + 35px)',
    },
  },
  inputLabel: {
    fontFamily: 'VerlagBook',
    fontSize: 18,
    marginBottom: '-23px',
    position: 'absolute',
    background: 'white',
    zIndex: 2,
    top: 2,
    paddingLeft: 10,
  },
  disabledDate: {
    textDecoration: 'line-through',
    color: colors.darkGray,
    cursor: 'default',
    pointerEvents: 'none',
  },
  errorMessage: {
    fontFamily: 'VerlagLight',
    fontSize: 16,
    color: colors.alertMessage,
    paddingTop: 5,
  },
  errorRoot: {
    borderColor: `${colors.alertMessage} !important`,
  },
});

export default styles;
