import { createSelector } from 'reselect';
import Lodash from 'lodash';

export const getReservationsReducer = state => {
  return Lodash.get(state, 'reservations', {});
};

export const getReservationsById = createSelector(
  getReservationsReducer,
  reservations => {
    return Lodash.get(reservations, ['data', 'byId'], {});
  }
);
export const getReservationsAllIds = createSelector(
  getReservationsReducer,
  reservations => {
    return Lodash.get(reservations, ['data', 'allIds'], []);
  }
);
export const getIsLoading = createSelector(
  getReservationsReducer,
  reservations => {
    return Lodash.get(reservations, ['loading'], []);
  }
);

export const getOwnersLookup = createSelector(
  getReservationsReducer,
  reservations => {
    return Lodash.get(reservations, ['owners', 'byId'], {});
  }
);
export const getOwnersAllIds = createSelector(
  getReservationsReducer,
  reservations => {
    return Lodash.get(reservations, ['owners', 'allIds'], []);
  }
);

export const getDesksAllIds = createSelector(
  getReservationsReducer,
  reservations => {
    return Lodash.get(reservations, ['desks', 'allIds'], []);
  }
);
export const getDesksLookup = createSelector(
  getReservationsReducer,
  reservations => {
    return Lodash.get(reservations, ['desks', 'byId'], {});
  }
);

export const getBookableDesksLookup = createSelector(
  getDesksLookup,
  deskLookup => {
    return Lodash.reduce(
      deskLookup,
      (objDesksBookableLookup, objDesk) => {
        const isBookable = Lodash.get(objDesk, ['isBookable'], false);

        if (isBookable) {
          const idDesk = Lodash.get(objDesk, ['idDesk'], false);

          return { ...objDesksBookableLookup, [idDesk]: objDesk };
        }
        return objDesksBookableLookup;
      },
      {}
    );
  }
);

export const getOfficesAllIds = createSelector(
  getReservationsReducer,
  reservations => {
    return Lodash.get(reservations, ['offices', 'allIds'], []);
  }
);

export const getOfficesLookup = createSelector(
  [getReservationsReducer],
  reservations => {
    return Lodash.get(reservations, ['offices', 'byId'], {});
  }
);

export const getSetLookup = createSelector(
  getReservationsReducer,
  reservations => {
    return Lodash.get(reservations, ['sets', 'byId'], {});
  }
);

export const getIsFetchingReservations = createSelector(
  getReservationsReducer,
  reservations => {
    return Lodash.get(reservations, ['loading'], false);
  }
);

export const getCompleteReservationsById = createSelector(
  [
    getReservationsAllIds,
    getReservationsById,
    getOwnersLookup,
    getDesksLookup,
    getOfficesLookup,
    getSetLookup,
  ],
  (
    reservationsAllIds,
    reservationsById,
    ownersLookup,
    desksLookup,
    officesLookup,
    setsLookup
  ) => {
    const completedBookings = reservationsAllIds.reduce((acc, id) => {
      const reservation = reservationsById[id];
      const { ownerSfId, resourceId, reservationSetId } = reservation;
      const owner = Lodash.get(ownersLookup, ownerSfId, '');
      const desk = Lodash.get(desksLookup, resourceId, {});
      const reservationSet = Lodash.get(setsLookup, reservationSetId, {});
      const { setOwnerSfId } = reservationSet;
      const setOwner = Lodash.get(ownersLookup, setOwnerSfId, {});
      const { parentOfficeSfId } = desk;
      const parentOffice = Lodash.get(officesLookup, parentOfficeSfId, {});
      acc[id] = {
        ...reservation,
        owner,
        desk: { ...desk, parentOffice },
        reservationSet: { ...reservationSet, setOwner },
      };
      return acc;
    }, {});
    return completedBookings;
  }
);
