import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import clsx from 'clsx';

import { colors, breakpoints } from '../../../Core/Theme';

const QuickLinkButton = props => {
  const { name, className, classes, onClick, ...rest } = props;
  return (
    <Button
      variant="contained"
      {...rest}
      className={clsx(classes.main, className)}
      onClick={onClick}
      focusVisibleClassName={classes.focusVisible}
    >
      {name}
    </Button>
  );
};
const styles = theme => ({
  focusVisible: {
    background: `${colors.white} !important`,
    borderWidth: `3px !important`,
  },
  main: {
    fontFamily: 'VerlagBook',
    textTransform: 'none',
    color: colors.black,
    backgroundColor: colors.white,
    borderColor: colors.middle,
    borderRadius: '3px',
    border: 'solid 1px',
    fontSize: 16,
    lineHeight: '24px',
    textAlign: 'center',
    padding: 0,
    boxShadow: '0 0 4px 0 rgba(0, 0, 0, 0.1)',
    height: 50,
    width: '100%',
    [theme.breakpoints.up(breakpoints.MOBILE)]: {
      fontSize: 18,
    },
    '&:hover': {
      boxShadow: '0 0 7px 2px rgba(0, 0, 0, 0.1)',
      backgroundColor: colors.white,
    },
  },
});

QuickLinkButton.defaultProps = {
  onClick: () => '',
  className: '',
  isLoading: false,
};
QuickLinkButton.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  onClick: PropTypes.func,
  name: PropTypes.string.isRequired,
  className: PropTypes.string,
  isLoading: PropTypes.bool,
};
export default withStyles(styles)(QuickLinkButton);
