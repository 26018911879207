import Lodash from 'lodash';

import {
  updateReservationsApi,
  deleteReservationsApi,
  createReservationsApi,
} from '../../Core/Api';
import {
  trackUpdateDeskReservation,
  trackCancelDeskReservation,
} from '../../Core/Tracking';

export const getUpdateReservationsDto = values => {
  const { reservations, addons } = values;
  const reservation = Lodash.get(reservations, 0);
  const officeSfId = Lodash.get(reservation, ['desk', 'parentOfficeSfId'], '');
  const ownerSfId = Lodash.get(reservation, ['owner', 'sfId'], null);
  const resourceId = Lodash.get(reservation, ['desk', 'idDesk'], '');
  const startEpoch = Lodash.get(reservation, ['startEpoch'], null);
  const endEpoch = Lodash.get(reservation, ['endEpoch'], null);

  const valueAddons = Lodash.get(addons, officeSfId, []);
  const dtoAddons = Object.keys(valueAddons).filter(k => valueAddons[k]);
  return {
    addons: dtoAddons,
    ownerSfId,
    resourceId,
    startEpoch,
    endEpoch,
  };
};

export const handleUpdate =
  updateReservationsRedux =>
  (values, reservations, handleSuccess, handleError, setSubmitLoading) => {
    setSubmitLoading(true);
    const reservationId = Lodash.get(reservations, [0, 'id']);
    const updateDto = getUpdateReservationsDto(values);

    updateReservationsApi(reservationId, updateDto)
      .then(objResponse => {
        const { data } = objResponse;
        updateReservationsRedux(data);
        handleSuccess(false);
        trackUpdateDeskReservation();
      })
      .catch(handleError);
  };

export const getCreateReservationsDto = (values, setOwner) => {
  const { reservations, addons } = values;

  const reservationsDto = reservations.map(r => {
    const officeSfId = Lodash.get(r, ['desk', 'parentOfficeSfId'], '');
    const ownerSfId = Lodash.get(r, ['owner', 'sfId'], null);
    const resourceId = Lodash.get(r, ['desk', 'idDesk'], '');
    const startEpoch = Lodash.get(r, ['startEpoch'], null);
    const endEpoch = Lodash.get(r, ['endEpoch'], null);

    const valueAddons = Lodash.get(addons, officeSfId, []);
    const dtoAddons = Object.keys(valueAddons).filter(k => valueAddons[k]);
    return {
      addons: dtoAddons,
      ownerSfId,
      resourceId,
      startEpoch,
      endEpoch,
    };
  });
  return {
    reservations: reservationsDto,
    ownerSfId: Lodash.get(setOwner, 'sfId', ''),
  };
};

export const handleCancelReservation = (
  reservations,
  deleteReservations,
  setSubmitLoading,
  handleError,
  onSubmit,
  handleSuccess
) => {
  const ids = reservations.map(r => Lodash.get(r, 'id', ''));
  setSubmitLoading(true);
  deleteReservationsApi(ids)
    .then(objResponse => {
      const { data } = objResponse;
      handleSuccess();
      deleteReservations(data);
      onSubmit();
      trackCancelDeskReservation();
    })
    .catch(handleError);
};

export const handleCreateReservations = (
  values,
  createReservations,
  setSubmitLoading,
  handleSuccess,
  handleError,
  setOwner
) => {
  setSubmitLoading(true);

  const createDto = getCreateReservationsDto(values, setOwner);
  return createReservationsApi(createDto)
    .then(objResponse => {
      const { data } = objResponse;
      handleSuccess(false);
      createReservations(data);
    })
    .catch(handleError);
};
