import typeToReducer from 'type-to-reducer';
import Lodash from 'lodash';
import * as types from './actionTypes';
import { normalizeLocations, NormObjCstr } from './schema';

function compareLocs(byId) {
  return (locA, locB) => {
    return byId[locA].externalName > byId[locB].externalName ? 1 : -1;
  };
}

const initialState = {
  data: new NormObjCstr({}, []),
  loading: false,
  hasError: false,
};

const onLoadingLocationsPending = state => {
  return {
    ...state,
    loading: true,
  };
};

const onLoadLocationsFulfilled = (state, action) => {
  const locationPayload = Lodash.get(action, ['payload'], []);

  if (locationPayload.length) {
    const { allIds, byId } = normalizeLocations(locationPayload);
    const allIdsSort = allIds.sort(compareLocs(byId));
    return {
      ...state,
      data: new NormObjCstr(byId, allIdsSort),
      loading: false,
      hasError: false,
    };
  }
  return state;
};

const onLoadLocationsRejected = state => {
  return {
    ...state,
    loading: false,
    hasError: true,
  };
};

const contentReducer = typeToReducer(
  {
    [types.LOAD_LOCATIONS]: {
      PENDING: onLoadingLocationsPending,
      FULFILLED: onLoadLocationsFulfilled,
      REJECTED: onLoadLocationsRejected,
    },
  },
  initialState
);

export default contentReducer;
