import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import { colors } from '../../../../Core/Theme';
import { Text, InfoBox } from '../../../Common';
import FormikInput from '../../../FormikInputs';
import { Office } from '../../../../Models';

const Addons = props => {
  const { classes, className, office, addons, disabled, ...rest } = props;
  const { t } = useTranslation();
  const { sfId } = office;
  const officeName = Office.getOfficeName(office);
  return (
    <InfoBox
      title={t('reserveDesk.addons.title').toUpperCase()}
      className={clsx(className)}
      subHeader={t('reserveDesk.addons.desc')}
    >
      <>
        <Text text={officeName} className={classes.office} />
        {addons.map(a => {
          return (
            <div className={classes.input}>
              <FormikInput
                name={`addons.${sfId}.${a}`}
                {...rest}
                label={t(`deskAdmin.addons.${a}`)}
                input="checkBox"
                variant="small"
                id={`addons.${sfId}`}
                disabled={disabled}
              />
              <Text
                text={t(`deskAdmin.addons.descriptions.${a}`)}
                className={classes.addonDesc}
              />
            </div>
          );
        })}
      </>
    </InfoBox>
  );
};

const styles = () => ({
  office: {
    fontSize: 12,
    fontFamily: 'VerlagBold',
    display: 'block',
    paddingTop: 11,
  },
  addonDesc: {
    fontFamily: 'VerlagLight',
    display: 'block',
    fontSize: 14,
    color: colors.darkGray,
    paddingLeft: 24,
    lineHeight: 1.43,
    marginTop: -6,
  },
  input: {
    paddingLeft: 8,
  },
});

Addons.defaultProps = {
  className: '',
  addons: [],
  office: {},
  disabled: false,
};

Addons.propTypes = {
  classes: PropTypes.object.isRequired,
  office: PropTypes.object,
  addons: PropTypes.arrayOf(PropTypes.object),
  className: PropTypes.string,
  disabled: PropTypes.bool,
};

export default withStyles(styles)(Addons);
