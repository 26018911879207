import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';
import { colors, breakpoints } from '../../../Core/Theme';
import EventCard from '../../Events/Components/EventCard';
import { TextButton, Loading } from '../../../Components/Common';
import { EVENT_FETCH_LIMIT } from '../constants';
import { Icons } from '../../../resources';
import { trackSeeMoreEventsFromHome } from '../../../Core/Tracking';

const UpcomingEvents = props => {
  const {
    classes,
    occasions,
    occasionTypesById,
    updateRsvpRedux,
    deleteRsvpRedux,
    occasionRsvps,
    history,
    locationSfId,
    locationName,
    isLoading,
    openDrawerToView,
    hasMoreEventsUpcoming,
    userIsVirtual,
  } = props;
  const { t } = useTranslation();

  return (
    <div className={classes.main}>
      <div className={classes.title} datatestid="upcoming_events_title">
        {userIsVirtual
          ? t('home.upcoming_events.event_spotlight_title')
          : t('home.upcoming_events.title')}
      </div>
      {userIsVirtual && occasions.length !== 0 ? (
        <div className={classes.disclaimer}>
          {t('home.upcoming_events.disclaimer', {
            locationName: `${locationName}`,
          })}
        </div>
      ) : null}
      <div className={classes.body}>
        {occasions.map((o, idx) => (
          <EventCard
            className={classes.card}
            event={o}
            occasionRsvps={occasionRsvps}
            occasionType={occasionTypesById[o.occasionTypes[0]]}
            canUpdate={false}
            key={`eventcard_home_page_${o.idOccasion}`}
            updateRsvpRedux={updateRsvpRedux}
            deleteRsvpRedux={deleteRsvpRedux}
            openDrawerToView={openDrawerToView}
            imageClass={classes.imageClass}
            datatestid={`upcoming_events_event_${idx}`}
            hideRsvpButton={userIsVirtual}
          />
        ))}
        {userIsVirtual ? null : (
          <>
            {hasMoreEventsUpcoming ? (
              <div className={classes.seeAll}>
                <TextButton
                  text="See all events"
                  onClick={() => {
                    history.push(
                      `home/events?locationSfId=${locationSfId}&scroll=true`
                    );
                    trackSeeMoreEventsFromHome();
                  }}
                  className={classes.seeAllButton}
                />
              </div>
            ) : null}
          </>
        )}
        {occasions.length === 0 && !isLoading ? (
          <div
            className={classes.emptyState}
            datatestid="upcoming_events_empty_no_events_message"
          >
            <div>
              <Icons.EmptyCalendar className={classes.emptyCalendar} />
              {t('home.upcoming_events.no_events')}
            </div>
          </div>
        ) : null}
        {occasions.length !== 0 &&
        !isLoading &&
        occasions.length < EVENT_FETCH_LIMIT ? (
          <div className={classes.moreEvents}>
            {t('home.upcoming_events.more')}
          </div>
        ) : null}
        {isLoading ? (
          <div className={classes.loading}>
            <Loading color={colors.black} size={50} />
          </div>
        ) : null}
      </div>
    </div>
  );
};

const styles = theme => ({
  main: {
    border: `solid 1px ${colors.middle}`,
    boxShadow: '0 0 4px 0 rgba(0, 0, 0, 0.1)',
    borderRadius: 2,
    display: 'flex',
    flexDirection: 'column',
    background: 'white',
    [theme.breakpoints.up(breakpoints.MOBILE)]: {
      minHeight: 765,
    },
  },
  body: {
    paddingLeft: 10,
    paddingRight: 10,
    paddingTop: 10,
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    [theme.breakpoints.down(breakpoints.MOBILE)]: {
      minHeight: 300,
    },
  },
  title: {
    textAlign: 'center',
    fontFamily: 'VerlagBold',
    fontSize: 18,
    height: 35,
    paddingTop: 15,
    borderBottom: `solid 1px ${colors.middle}`,
    [theme.breakpoints.down(breakpoints.MOBILE)]: {
      fontSize: 16,
    },
  },
  disclaimer: {
    textAlign: 'center',
    fontSize: 18,
    paddingTop: 15,
    [theme.breakpoints.down(breakpoints.MOBILE)]: {
      fontSize: 16,
    },
  },
  card: {
    boxShadow: 'none',
    border: `solid 1px ${colors.middle}`,
    marginBottom: 15,
    maxWidth: '100%',
  },
  seeAll: {
    textAlign: 'center',
  },
  emptyState: {
    margin: 'auto',
    textAlign: 'center',
    fontFamily: 'VerlagBold',
    fontSize: 18,
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
  },
  emptyCalendar: {
    paddingBottom: 10,
    display: 'block',
    margin: 'auto',
  },
  loading: {
    marginTop: '35%',
    textAlign: 'center',
  },
  moreEvents: {
    fontSize: 16,
    fontFamily: 'VerlagBook',
    color: colors.darkest,
    textAlign: 'center',
    paddingTop: 10,
    [theme.breakpoints.down(breakpoints.MOBILE)]: {
      paddingTop: 0,
      paddingBottom: 10,
    },
  },
  imageClass: {
    [theme.breakpoints.down(breakpoints.MOBILE)]: {
      width: 'calc(100% + 2px)',
    },
  },
  seeAllButton: {
    [theme.breakpoints.down(breakpoints.MOBILE)]: {
      fontSize: 18,
      marginBottom: 15,
    },
  },
});
UpcomingEvents.defaultProps = {};

UpcomingEvents.propTypes = {
  classes: PropTypes.object.isRequired,
  occasions: PropTypes.arrayOf(PropTypes.object).isRequired,
  occasionRsvps: PropTypes.arrayOf(PropTypes.object).isRequired,
  occasionTypesById: PropTypes.object.isRequired,
  updateRsvpRedux: PropTypes.func.isRequired,
  deleteRsvpRedux: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  locationSfId: PropTypes.string.isRequired,
  isLoading: PropTypes.bool.isRequired,
  openDrawerToView: PropTypes.func.isRequired,
  hasMoreEventsUpcoming: PropTypes.bool.isRequired,
  userIsVirtual: PropTypes.bool.isRequired,
  locationName: PropTypes.string.isRequired,
};

export default withStyles(styles)(UpcomingEvents);
