import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { useTranslation } from 'react-i18next';
import Moment from 'moment';
import queryString from 'query-string';
import clsx from 'clsx';
import Lodash from 'lodash';

// Core
import { colors, breakpoints } from '../../Core/Theme';
import { HomeRoute } from '../../Core/constants';
import { date as DateUtil } from '../../Core/Utils';
import {
  companyInfoAccess,
  getIsManager,
} from '../../Core/Utils/userPermissions';

// Components
import {
  Tooltip,
  InfoBanner,
  BackButton,
  SfLinkButton,
} from '../../Components/Common';

import { ActivityTableSummary } from '../../Components';

// Api
import { fetchAccount, fetchUserBalance } from '../../Core/Api';

// Redux
import { getUserProfile } from '../../Redux/Common/UserManager/selectors';
import locationsRedux from '../../Redux/Common/Locations';
import { getLocations } from '../../Redux/Common/Locations/selectors';
import { fetchAccountSettings } from '../../Redux/Common/UserManager/actions';

const AccountOverview = props => {
  const { classes, history, profile, addLocations, locations } = props;
  const { t } = useTranslation();

  const [account, setAccount] = React.useState(null);
  const [meetingRoomBalance, setMeetingRoomBalance] = React.useState({});
  const [hasError, setHasError] = React.useState(false);

  const handleBadAccount = () => {
    history.push(HomeRoute);
  };

  const handleError = () => setHasError(true);

  const fetchConferenceHours = sfId => {
    fetchUserBalance(sfId, Moment().startOf('month').unix(), true).then(
      objResponse => {
        const { data } = objResponse;
        setMeetingRoomBalance(data);
      },
      handleError
    );
  };

  const initializeDataForAccount = a => {
    DateUtil.setTimezone(a?.location?.timeZoneId);
    setAccount(a);
    fetchConferenceHours(a?.sfId);
  };

  React.useEffect(() => {
    const windowLocationSearch = window.location.search;
    const params = queryString.parse(windowLocationSearch);
    const { accountSfId } = params;
    if (!accountSfId) {
      history.push(`/home/account?accountSfId=${profile?.accountSfId}`);
      return;
    }
    if (!locations.length) {
      addLocations(false); // false is the include deleted value
    }
    if (accountSfId === profile?.accountSfId) {
      setAccount(profile?.account);
      initializeDataForAccount(profile?.account);
      return;
    }
    fetchAccount(accountSfId).then(objResponse => {
      const { data } = objResponse;
      const userCanUpdateAccount = companyInfoAccess.update({
        profile,
        obj: data,
      });
      if (!userCanUpdateAccount) {
        handleBadAccount();
        return;
      }
      initializeDataForAccount(data);
    }, handleBadAccount);
  }, [window.location.search]);

  const navigateToActivityPage = () =>
    history.push({
      pathname: '/home/accountActivity',
      search: `accountSfId=${account?.sfId}`,
    });

  const remainingDays = Moment().daysInMonth() - Moment().date();

  const hasUnlimitedConferenceHours =
    meetingRoomBalance?.monthlyAllowanceCredits === -1;
  const remainingConferenceBalanceDisplay = hasUnlimitedConferenceHours ? (
    <div className={classes.unlimitedDisplay}>{t('general.unlimited')}</div>
  ) : (
    meetingRoomBalance?.remainingMonthlyAllowanceCredits +
    meetingRoomBalance?.remainingRolloverCredits
  );

  const handleOnBackPress = () => {
    history.push({
      pathname: '/home/memberactivity',
    });
  };

  const isManager = getIsManager(profile);

  return (
    <div className={classes.wrapper}>
      {hasError && (
        <InfoBanner
          variant="error"
          theme="light"
          text={t('general.page_error')}
          className={classes.errorMessage}
        />
      )}
      <Grid container className={classes.content} spacing={16}>
        <>
          {isManager ? (
            <Grid item xs={12}>
              <BackButton
                className={classes.backButton}
                onBackPress={handleOnBackPress}
              />
            </Grid>
          ) : null}
          <Grid item xs={12} className={clsx(!isManager && classes.title)}>
            <SfLinkButton isManager={isManager} account={account} />
          </Grid>
          <Grid item xs={12}>
            <div className={classes.moduleTitle}>
              {t('accountOverview.balance.title')}
            </div>
            <div className={classes.moduleSubTitle}>
              {t('accountOverview.balance.remaining_days', {
                count: remainingDays,
              })}
            </div>
            <div className={clsx(classes.module, classes.balance)}>
              <div className={classes.balanceLayout}>
                <div>
                  <div className={classes.moduleUnitTitle}>
                    {t('accountOverview.balance.meeting_balance')}
                  </div>
                  <div className={classes.moduleUnitDescription}>
                    {Lodash.isEmpty(meetingRoomBalance)
                      ? '--'
                      : remainingConferenceBalanceDisplay}
                  </div>
                </div>
              </div>
              <div className={classes.moduleDisclaimer}>
                {hasUnlimitedConferenceHours
                  ? t(
                      'accountOverview.balance.membership_includes_end_plural',
                      {
                        count: t('general.unlimited'),
                      }
                    )
                  : t('accountOverview.balance.membership_includes_end', {
                      count: meetingRoomBalance?.monthlyAllowanceCredits,
                    })}
                <Tooltip
                  title={t('accountOverview.balance.tooltip')}
                  iconClass={classes.lightTooltip}
                />
              </div>
            </div>
          </Grid>
          <Grid item xs={12}>
            <div className={clsx(classes.moduleTitle, classes.activityTitle)}>
              {t('accountOverview.activity.title')}
            </div>
            <ActivityTableSummary
              month={account ? Moment().startOf('month').unix() : null}
              accountSfId={account?.sfId}
              ctaText={t('accountOverview.activity.cta')}
              onAction={navigateToActivityPage}
              account={account}
              setHasError={setHasError}
              history={history}
            />
          </Grid>
        </>
      </Grid>
    </div>
  );
};

const styles = theme => ({
  wrapper: {
    background: colors.palette.secondary2.main,
    paddingTop: 20,
    paddingRight: 14,
    paddingLeft: 14,
    [theme.breakpoints.up(breakpoints.MOBILE)]: {
      paddingTop: 14,
      paddingRight: 44,
      paddingLeft: 44,
    },
    minHeight: '100vh',
  },
  title: {
    fontFamily: 'VerlagBold',
    fontSize: 24,
    marginBottom: 10,
    [theme.breakpoints.up(breakpoints.MOBILE)]: {
      fontSize: 36,
      marginTop: 10,
      marginBottom: 0,
    },
  },
  moduleTitle: {
    fontSize: 18,
    fontFamily: 'VerlagBold',
    marginTop: 5,
    [theme.breakpoints.up(breakpoints.MOBILE)]: {
      fontSize: 24,
    },
  },
  moduleSubTitile: {
    fontFamily: 'VerlagBook',
    fontSize: 15,
  },
  module: {
    boxShadow: '0 0 4px 0 rgba(0, 0, 0, 0.1)',
    borderRadius: 3,
    border: `solid 1px ${colors.middle}`,
    background: 'white',
    [theme.breakpoints.up(breakpoints.MOBILE)]: {
      maxWidth: 430,
    },
  },
  balance: {
    fontFamily: 'VerlagBold',
    fontSize: 15,
    padding: 15,
    marginTop: 10,
  },
  paymentButton: {
    fontSize: 15,
    padding: 0,
    marginLeft: 'auto',
  },
  moduleSubTitle: {
    fontSize: 16,
    fontFamily: 'VerlagLight',
  },
  moduleUnitTitle: {
    fontFamily: 'VerlagBook',
    fontSize: 12,
    marginBottom: 15,
    paddingRight: 20,
  },
  moduleUnitDescription: {
    fontFamily: 'VerlagBook',
    fontSize: 24,
    paddingTop: 15,
  },
  balanceLayout: {
    display: 'flex',
    justifyContent: 'space-between',
    borderBottom: `solid 1px ${colors.middle}`,
    paddingBottom: 30,
    marginRight: -15,
    paddingRight: 15,
    marginLeft: -15,
    paddingLeft: 15,
  },
  moduleDisclaimer: {
    color: colors.dark,
    fontFamily: 'VerlagBook',
    fontSize: 15,
    marginTop: 15,
  },
  lightTooltip: {
    opacity: 0.5,
  },
  tooltip2: {
    opacity: 0.6,
    marginTop: -3,
  },
  passPurchase: {
    display: 'flex',
    padding: 15,
    marginTop: 5,
    fontFamily: 'VerlagBook',
    fontSize: 12,
  },
  toggleValue: {
    fontFamily: 'VerlagBold',
    paddingLeft: 2,
  },
  activityTitle: {
    marginBottom: 20,
  },
  errorMessage: {
    width: 'auto',
    marginBottom: 20,
  },

  backButton: {
    [theme.breakpoints.up(breakpoints.MOBILE)]: {
      marginTop: 15,
    },
  },
  settingLoader: {
    width: 20,
    height: 15,
  },
  unlimitedDisplay: {
    fontSize: 24,
  },
});

AccountOverview.propTypes = {
  profile: PropTypes.object.isRequired,
  addLocations: PropTypes.func.isRequired,
  locations: PropTypes.arrayOf(PropTypes.object).isRequired,
};

const mapStateToProps = state => {
  return {
    profile: getUserProfile(state),
    locations: getLocations(state),
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      addLocations: locationsRedux.actions.addLocations,
      fetchAccountSettingsRedux: fetchAccountSettings,
    },

    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(AccountOverview));
