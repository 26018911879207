import get from 'lodash/get';
import includes from 'lodash/includes';
import isNil from 'lodash/isNil';
import indexOf from 'lodash/indexOf';
import without from 'lodash/without';
import {
  getReservableDesks,
  getAllReservedDesks,
  getOfficeHasAvailableDesks,
  getCommonArea,
} from '../Utils';
import { FEATURES_TYPE } from '../Constants';
import { getIsIntegrated } from '../../../Models/groupings';

const DeskReservation = ({ mapboxLookup }) => {
  const getOffices = ({ offices, reservations, groupingId }) => {
    const { reservedDesks } = getAllReservedDesks({ reservations });

    return offices.reduce(
      (objData, objOffice) => {
        const strOfficeId = get(objOffice, ['sfId'], null);
        const strFeatureID = get(
          mapboxLookup,
          [FEATURES_TYPE.OFFICE, `${strOfficeId}`, 'mapboxFeatureID'],
          null
        );
        const officesDesks = get(objOffice, ['desks'], []);
        const hasAvailableDesks = getOfficeHasAvailableDesks({
          desks: officesDesks,
          reservations: reservedDesks,
        });

        const officesWithDesksAvailable = hasAvailableDesks
          ? [...objData.officesWithDesksAvailable, strFeatureID]
          : objData.officesWithDesksAvailable;

        if (!strFeatureID) {
          return objData;
        }
        if (objOffice.isOasisReservable && !objOffice.isFlexOffice) {
          if (
            groupingId === objOffice.groupingId ||
            (!groupingId && getIsIntegrated(objOffice.grouping))
          ) {
            return {
              ...objData,
              assigned: [...objData.assigned, strFeatureID],
              officesWithDesksAvailable,
              assignedOfficeSfIds: [
                ...objData.assignedOfficeSfIds,
                strOfficeId,
              ],
            };
          }
        }

        return {
          ...objData,
          unavailable: [...objData.unavailable, strFeatureID],
          officesWithDesksAvailable,
        };
      },
      {
        assigned: [],
        unavailable: [],
        officesWithDesksAvailable: [],
        assignedOfficeSfIds: [],
      }
    );
  };

  const getDesks = ({
    offices,
    selectedDesks,
    reservations,
    userSfId,
    groupingId,
    assignedOfficeSfIds,
    teammateReservedDeskIds,
  }) => {
    const desks = getReservableDesks({ offices });
    const { reservedDesks, userReservedDesks } = getAllReservedDesks({
      reservations,
      userSfId,
    });

    return desks.reduce(
      (objData, objDesk) => {
        const strDeskID = get(objDesk, ['idDesk'], null);
        const isSelected = includes(selectedDesks, strDeskID);
        const isReserved = includes(reservedDesks, strDeskID);
        const isTeammate = includes(teammateReservedDeskIds, strDeskID);
        const isUserReserved = includes(userReservedDesks, strDeskID);

        const strFeatureID = get(
          mapboxLookup,
          [FEATURES_TYPE.DESK, `${strDeskID}`, 'mapboxFeatureID'],
          null
        );
        const strChairFeatureID = get(
          mapboxLookup,
          [FEATURES_TYPE.DESK, `${strDeskID}`, 'chair'],
          null
        );
        const isActive = get(objDesk, ['active'], null);
        const isBookable = get(objDesk, ['isBookable'], null);
        const parentOfficeSfId = get(objDesk, ['parentOfficeSfId'], null);

        const isInCorrectGrouping =
          !groupingId || get(objDesk, 'groupingId') === groupingId;

        const isInAvailableOffice =
          assignedOfficeSfIds.includes(parentOfficeSfId);
        if (!strFeatureID || !strDeskID) {
          return objData;
        }

        if (
          isActive &&
          isBookable &&
          isInCorrectGrouping &&
          isInAvailableOffice
        ) {
          if (isUserReserved) {
            return {
              ...objData,
              userReserved: [
                ...objData.userReserved,
                strFeatureID,
                strChairFeatureID,
              ],
            };
          }
          if (isTeammate) {
            return {
              ...objData,
              teammateReserved: [
                ...objData.teammateReserved,
                strFeatureID,
                strChairFeatureID,
              ],
            };
          }
          if (!isReserved) {
            return {
              ...objData,
              selected: isSelected
                ? [...objData.selected, strFeatureID, strChairFeatureID]
                : [
                    ...without(
                      objData.selected,
                      strFeatureID,
                      strChairFeatureID
                    ),
                  ],
              available: !isSelected
                ? [...objData.available, strFeatureID, strChairFeatureID]
                : [
                    ...without(
                      objData.available,
                      strFeatureID,
                      strChairFeatureID
                    ),
                  ],
            };
          }

          return {
            ...objData,
            unavailable: [
              ...objData.unavailable,
              strFeatureID,
              strChairFeatureID,
            ],
          };
        }
        return objData;
      },
      {
        selected: [],
        available: [],
        unavailable: [],
        userReserved: [],
        teammateReserved: [],
      }
    );
  };

  const getSelectedDesksLabel = ({ selected, selectedDesks }) => {
    const featuresLookup = get(mapboxLookup, ['featuresLookup'], {});
    const desksLookup = get(mapboxLookup, ['Desk'], {});

    return selected.reduce(
      (objLabels, strSelectedId) => {
        const objFeature = get(featuresLookup, [strSelectedId], null);
        const idDesk = get(objFeature, ['idDesk'], 0);
        const idDeskLabel = get(desksLookup, [idDesk, 'label'], null);
        const { indicators, desksLabelId } = objLabels;
        if (!isNil(objFeature) && idDeskLabel) {
          const arrDesksLabelId = [...desksLabelId, idDeskLabel];
          return {
            desksLabelId: arrDesksLabelId,
            indicators: {
              ...indicators,
              [idDeskLabel]: indexOf(selectedDesks, idDesk) + 1,
            },
          };
        }

        return objLabels;
      },
      { indicators: {}, desksLabelId: [] }
    );
  };

  const generateLabelsSource = objParams => {
    const selectedDesks = getSelectedDesksLabel(objParams);
    const commonArea = getCommonArea(mapboxLookup);
    return {
      selectedDesks,
      commonArea,
    };
  };

  return {
    getOffices,
    getDesks,
    getSelectedDesksLabel,
    generateLabelsSource,
  };
};

export default DeskReservation;
