import {
  centroid,
  polygon,
  point,
  bboxPolygon,
  bbox,
  Position,
  AllGeoJSON,
  BBox,
  Geometry,
  Feature,
  Properties,
  Polygon,
} from '@turf/turf';
import { LngLatBounds } from 'mapbox-gl';

import { v4 as uuidv4 } from 'uuid';
import { FlyToInterpolator } from 'react-map-gl';
import memoizeOne from 'memoize-one';
import {
  FEATURES_TYPE,
  INTERACTIVE_MAPBOX_FEATURE_TYPE,
  LABEL_DISABLED_MAPBOX_FEATURE_TYPE,
} from './constants';
import { createHash } from '../../Core/Utils';
import {
  CommonAreaIndicator,
  FeatureData,
  GeometryTypes,
  LabelIndicators,
  Layers,
  MapData,
  MapDataset,
} from './types';
import { DeskDto, OfficeAvailabilityDto, OfficeDto } from '../../Core/Api';
import { upperFirst } from '../../Core/Utils/strings';

const getCenter = (coordinates: AllGeoJSON): Position => {
  const centroidPoint = centroid(coordinates);
  return centroidPoint.geometry?.coordinates ?? [];
};

const centroidPolygon = (coordinates: Position[][]) => {
  const polygonCoordinates = polygon(coordinates);
  return getCenter(polygonCoordinates);
};

const centroidPoint = (coordinates: Position) => {
  const polygonCoordinates = point(coordinates);
  return getCenter(polygonCoordinates);
};

export const calculateCentroid = (params: Geometry) => {
  const { type, coordinates } = params;

  if (type === GeometryTypes.Polygon) {
    return centroidPolygon(coordinates as Position[][]);
  }

  if (type === GeometryTypes.Point) {
    return centroidPoint(coordinates as Position);
  }

  return <Position>[0, 0];
};

const generateBoundingBoxCoordinates = (boundingBox?: LngLatBounds): BBox => {
  return [
    boundingBox?.getNorthEast().lng ?? 0,
    boundingBox?.getNorthEast().lat ?? 0,
    boundingBox?.getSouthWest().lng ?? 0,
    boundingBox?.getSouthWest().lat ?? 0,
  ];
};

export const generateFeatureOverlay = memoizeOne(
  (bounds?: LngLatBounds): Feature<Polygon, Properties> | undefined => {
    if (bounds == null) {
      return undefined;
    }

    const boundingBox = generateBoundingBoxCoordinates(bounds);

    return {
      ...bboxPolygon(boundingBox),
      properties: { type: Layers.Overlay },
    };
  }
);

export const generateNewViewport = (params: {
  zoom: number;
  coordinates: [number, number];
  withTransition?: boolean;
}) => {
  const { zoom, coordinates, withTransition = true } = params;

  const [longitude, latitude] = coordinates;

  return {
    longitude,
    latitude,
    zoom,
    ...(withTransition && {
      transitionInterpolator: new FlyToInterpolator({ speed: 1.2 }),
      transitionDuration: 'auto',
    }),
  };
};

export const getOfficeByfeatureId = (offices: OfficeAvailabilityDto[]) => {
  return offices.reduce((featureLookup, office) => {
    const { officeMapFeatureId } = office;

    if (officeMapFeatureId == null) {
      return featureLookup;
    }

    return {
      ...featureLookup,
      [officeMapFeatureId]: office,
    };
  }, {} as { [featureId: string]: OfficeAvailabilityDto });
};

const getProductID = (product: any) => {
  const featuresIdentifier = {
    [FEATURES_TYPE.DESK]: (desk?: DeskDto) => desk?.idDesk,
    [FEATURES_TYPE.OFFICE]: (office?: OfficeAvailabilityDto) =>
      office?.officeSfId,
  };

  const type = product?.type;

  return featuresIdentifier[type]
    ? featuresIdentifier[type](product)
    : product?.sfId;
};

const mapFeature = (feature: FeatureData): FeatureData => {
  const featureId = feature.id as string;
  const originalProperties = feature.properties ?? {};

  const properties = {
    ...originalProperties,
    id: featureId || uuidv4(),
  };

  return { ...feature, properties };
};

const isDesk = memoizeOne(type => {
  const desksType = [FEATURES_TYPE.DESK];
  return desksType.includes(type);
});

const getFeatureLabel = (
  listMapboxFeature: FeatureData[],
  featureId: string
) => {
  return listMapboxFeature.find(feature => {
    const associatedFeatureId = feature.properties?.associatedFeature;
    const featureType = feature.properties?.type;

    return (
      associatedFeatureId === featureId && featureType === FEATURES_TYPE.LABEL
    );
  });
};

const getOfficeFeatureDesk = (
  listMapboxFeature: FeatureData[],
  officeId: string
) => {
  const relatedDesks = listMapboxFeature.filter(feature => {
    const featureOfficeId = feature.properties?.officeId;
    const featureType = feature.properties?.type;

    return officeId === featureOfficeId && featureType === FEATURES_TYPE.DESK;
  });

  return relatedDesks.map(desk => desk?.id);
};

const generateProductMappings = (
  featureLookup: { [featureId: string]: any },
  listMapboxFeature: FeatureData[]
) => {
  return listMapboxFeature.reduce(
    (productsLookup: { [featureId: string]: any }, feature) => {
      const featureId = (feature.id ?? '') as string;
      const product = featureLookup?.[`${featureId}`];

      if (product == null) {
        return productsLookup;
      }

      const productId = getProductID(product);
      const isDeskType = isDesk(product?.type);
      const productType = isDeskType
        ? FEATURES_TYPE.DESK
        : FEATURES_TYPE.OFFICE;
      const featureLabel = getFeatureLabel(listMapboxFeature, featureId);

      const featureResult = {
        mapboxFeatureID: featureId,
        label: featureLabel?.properties?.id,
        ...(isDeskType && {
          chair: feature?.properties?.associatedFeature,
        }),
        ...(!isDeskType && {
          desks: getOfficeFeatureDesk(listMapboxFeature, featureId),
        }),
      };

      const productsByKey = productsLookup[productType];

      return {
        ...productsLookup,
        [productType]: {
          ...productsByKey,
          [productId]: featureResult,
        },
      };
    },
    {}
  );
};

const getOfficeData = (
  listMapboxFeature: FeatureData[],
  featureLookup: { [featureId: string]: unknown }
) => {
  return generateProductMappings(featureLookup, listMapboxFeature);
};

export const getMapData = (
  mapDataset?: MapDataset,
  offices: OfficeAvailabilityDto[] = []
) => {
  const features = mapDataset?.features ?? [];
  const featuresWithPropertyId = features.map(mapFeature);
  const officeByFeatureId = getOfficeByfeatureId(offices);
  return {
    dataset: {
      ...mapDataset,
      features: featuresWithPropertyId,
    },
    ...getOfficeData(featuresWithPropertyId, officeByFeatureId),
    officeByFeatureId,
  };
};

export const getAllDesks = (params: { offices: OfficeDto[] }) => {
  const { offices } = params;

  if (offices.length === 0) {
    return [];
  }

  return offices.reduce((desks: DeskDto[], office: OfficeDto) => {
    const listOfficeDesks = office.desks;
    return [...desks, ...listOfficeDesks];
  }, []);
};

export const getReservableDesks = (params: { offices: OfficeDto[] }) => {
  const { offices } = params;

  if (offices.length === 0) {
    return [];
  }

  return offices.reduce((desks: DeskDto[], office: OfficeDto) => {
    if (!office.isOasisReservable) {
      return desks;
    }

    const listOfficeDesks = office?.desks ?? [];
    const listDesksWithGroupings = listOfficeDesks.map(d => ({
      ...d,
      groupingId: office.groupingId,
      parentOffice: office,
    }));

    return [...desks, ...listDesksWithGroupings];
  }, []);
};

export const getOfficeHasAvailableDesks = (params: {
  desks: DeskDto[];
  reservationDeskIds: number[];
}) => {
  const { desks, reservationDeskIds } = params;

  return desks.some(desk => {
    const { idDesk, isBookable } = desk;

    return isBookable && !reservationDeskIds.includes(idDesk);
  });
};

const getBathroomDisplayName = (params: { feature: FeatureData; t: any }) => {
  const { feature, t } = params;

  const gender = feature.properties?.gender;

  if (gender == null) {
    return t('floorPlan.bathroom');
  }

  return t('floorPlan.genderedBathroom', { gender });
};

const getCommonSpacesDisplayName = (params: { t: any }) => {
  const { t } = params;

  return t('floorPlan.commonSpace');
};

const genericFeatureLabel = {
  [FEATURES_TYPE.BATHROOM]: getBathroomDisplayName,
  [FEATURES_TYPE.COMMON_SPACES]: getCommonSpacesDisplayName,
};

export const getDisplayName = (params: { feature: FeatureData; t: any }) => {
  const { feature, t } = params;

  const featureType = feature.properties?.type;
  const getGenericLabel = genericFeatureLabel[featureType];

  if (typeof getGenericLabel === 'function') {
    return getGenericLabel({ feature, t });
  }

  return feature.properties?.name || featureType;
};

const getLabelIndicators =
  (params: { indicators: LabelIndicators; t: any }) =>
  (feature: FeatureData) => {
    const { indicators, t } = params;
    if (
      feature.properties != null &&
      !LABEL_DISABLED_MAPBOX_FEATURE_TYPE.includes(
        upperFirst(feature.properties.type)
      )
    ) {
      const featureId = feature.id as string;

      return {
        ...indicators,
        [featureId]: getDisplayName({ feature, t }),
      };
    }
    return indicators;
  };

export const getCommonArea = (params: { mapData: MapData; t: any }) => {
  const { mapData, t } = params;

  const features = mapData.dataset.features ?? [];

  return features.reduce(
    (commonLabels: CommonAreaIndicator, feature: FeatureData) => {
      const { indicators, commonAreaIds } = commonLabels;
      const featureId = feature.id as string | undefined;
      const featureType = feature.properties?.type;
      const getLabels = getLabelIndicators({ indicators, t });

      if (
        featureId &&
        featureType &&
        !INTERACTIVE_MAPBOX_FEATURE_TYPE.includes(upperFirst(featureType))
      ) {
        return {
          commonAreaIds: [...commonAreaIds, featureId],
          indicators: getLabels(feature),
        };
      }

      return commonLabels;
    },
    { commonAreaIds: [], indicators: {} }
  );
};

const getBbox = memoizeOne((featureMapCollection: MapDataset | null) => {
  if (
    featureMapCollection?.type == null ||
    featureMapCollection?.features == null
  ) {
    return [];
  }

  return bbox(featureMapCollection);
});

export const isSameMap = (
  newFeaturesMap: MapDataset[],
  lastFeaturesMap: MapDataset[]
) => {
  const newMapBboxHash = createHash(getBbox(newFeaturesMap[0]));
  const lastMapBboxHash = createHash(getBbox(lastFeaturesMap[0]));

  return newMapBboxHash === lastMapBboxHash;
};
