/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { useRef, useState, useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Lodash from 'lodash';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

// Theme
import { breakpoints, colors } from '../../../../Core/Theme';
import { Icons } from '../../../../resources';
import FilterPopover from './FilterPopover';

const FilterPopoverAnchor = props => {
  const { classes, items, onFilter, selectedItems } = props;
  const { t } = useTranslation();
  const [activeItems, setActiveItems] = useState([]);
  const [anchorRef, setAnchorRef] = useState(null);
  const filterButtonRef = useRef(null);

  useEffect(() => {
    setActiveItems(selectedItems);
  }, [anchorRef, selectedItems]);

  const onClosePopover = () => {
    setAnchorRef(null);
  };

  const handleOnClick = e => {
    setAnchorRef(e.currentTarget);
  };

  const onClickItem = strKeyFilter => {
    const isChecked = Lodash.includes(activeItems, strKeyFilter);
    if (isChecked) {
      return setActiveItems(
        Lodash.filter(activeItems, key => key !== strKeyFilter)
      );
    }
    return setActiveItems([...activeItems, strKeyFilter]);
  };

  const handleOnFilterPress = () => {
    setAnchorRef(null);
    return onFilter(activeItems);
  };

  const hasSelectedFilters = activeItems.length;
  return (
    <>
      <div
        onClick={handleOnClick}
        className={clsx([
          classes.filterButtonContainer,
          hasSelectedFilters && classes.activeFilterButton,
        ])}
        ref={filterButtonRef}
      >
        <img
          src={Icons.Filter}
          className={classes.filterColumnButton}
          alt={t('altTexts.filter')}
        />
      </div>

      <FilterPopover
        isOpen={Boolean(anchorRef)}
        onFilter={handleOnFilterPress}
        items={items}
        selectedItems={selectedItems}
        onClose={onClosePopover}
        anchorRef={anchorRef}
        onClickItem={onClickItem}
        activeItems={activeItems}
        setActiveItems={setActiveItems}
      />
    </>
  );
};

FilterPopoverAnchor.propTypes = {
  items: PropTypes.arrayOf(PropTypes.string).isRequired,
  selectedItems: PropTypes.arrayOf(PropTypes.string).isRequired,
  onFilter: PropTypes.func.isRequired,
};

const styles = theme => ({
  labelItem: {
    fontFamily: 'VerlagBold',
    fontSize: 15,
  },
  itemCheckbox: {
    marginBottom: -12,
  },
  rootPopover: {
    padding: 15,
  },
  popover: {
    boxShadow: '0 0 4px 1px rgba(0, 0, 0, 0.1)',
    borderRadius: 4,
    display: 'none',
    [theme.breakpoints.up(breakpoints.MOBILE)]: {
      display: 'block',
    },
  },
  headWithFilter: {
    marginBottom: '12px',
  },
  filterButtonContainer: {
    borderRadius: '4px',
    border: 'solid 0.8px #d8d8d8',
    display: 'inline-flex',
    marginLeft: '10px',
    padding: '3px',
    cursor: 'pointer',
  },
  filterColumnButton: {
    height: 15,
    width: 15,
  },
  description: {
    fontSize: 15,
    fontFamily: 'VerlagBook',
    opacity: 0.5,
    paddingLeft: 34,
    marginTop: -15,
    marginBottom: 20,
  },
  activeFilterButton: {
    borderColor: colors.palette.primary.light,
    borderWidth: 2,
  },
});
export default withStyles(styles)(FilterPopoverAnchor);
