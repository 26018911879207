import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';

// Styles
import { colors, breakpoints } from '../../../../../../Core/Theme';

// Common Components
import Button from '../../../../../../Components/Common/BaseButton';
import SubmitButton from '../../../../../../Components/Common/SubmitButton';

const MeetingSummaryCancelation = ({
  onBack,
  onCancel,
  classes,
  isSuccess,
  isButtonDisabled,
  isSubmitting,
  isOnDemandReservation,
}) => {
  const { t } = useTranslation();
  return (
    <React.Fragment>
      <div className={classes.summaryContainerCancelation}>
        <div className={classes.summaryDetailsContainer}>
          <div className={classes.confirmationInfoCancelation}>
            <p className={classes.summaryCancelationParagraph}>
              {isOnDemandReservation
                ? t('meetingSummary.onDemandCancellation')
                : t('meetingSummary.cancellation')}
            </p>
          </div>

          <div className={classes.confirmationButtonsCancelation}>
            <div className={classes.goBackButtonCancelation}>
              <Button
                className={classes.buttonGoBack}
                // size="large"
                name={t('general.no_go_back')}
                fullWidth
                onClick={onBack}
                autoFocus
                datatestid="rr_go_back_reservation_button"
              />
            </div>
            <div className={classes.buttonCancelReservationCancelation}>
              <SubmitButton
                className={classes.buttonReserveRoot}
                classes={{ root: classes.buttonReserveRoot }}
                defaultText={t('general.yes_cancel')}
                size="large"
                isLoading={isSubmitting}
                isSuccess={isSuccess}
                disabled={isButtonDisabled}
                onClick={onCancel}
                fullWidth
                buttonStyle="buttonStyleSmall"
                datatestid="rr_cancel_reservation_button"
              />
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

const styles = theme => ({
  confirmationButtonsCancelation: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    width: '100%',
    [theme.breakpoints.down(breakpoints.MOBILE)]: {
      justifyContent: 'space-between',
      alignItems: 'baseline',
    },
    [theme.breakpoints.up(breakpoints.MOBILE)]: {
      width: '365px',
      marginRight: '4%',
    },
  },
  confirmationInfoCancelation: {
    fontFamily: 'VerlagLight',
    [theme.breakpoints.up(breakpoints.MOBILE)]: {
      width: 'calc(100% - 365px)',
    },
  },
  summaryContainerCancelation: {
    flexDirection: 'column',
    display: 'flex',
    [theme.breakpoints.up(breakpoints.MOBILE)]: {
      position: 'relative',
      zIndex: 10,
    },
  },
  summaryDetailsContainer: {
    justifyContent: 'space-around',
    alignItems: 'left',
    flexDirection: 'row',
    display: 'flex',
    padding: '5px',
    [theme.breakpoints.down(breakpoints.MOBILE)]: {
      flexDirection: 'column',
      padding: '19px',
      alignItems: 'left',
    },
    backgroundColor: colors.white,
  },
  buttonReserveRoot: {
    [theme.breakpoints.up(breakpoints.MOBILE)]: {
      width: '150px',
    },
    [theme.breakpoints.down(breakpoints.MOBILE)]: {
      width: '100%',
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
  buttonGoBack: {
    [theme.breakpoints.down(breakpoints.MOBILE)]: {
      width: '100%',
    },
  },
  buttonCancelReservation: {
    height: '100%',
    [theme.breakpoints.up(breakpoints.MOBILE)]: {
      width: '150px',
      maxHeight: '40px',
      marginRight: '2.5%',
    },
    fontFamily: 'VerlagBook',
    textTransform: 'none',
    color: colors.white,
    backgroundColor: colors.palette.primary.main,
  },
  summaryCancelationParagraph: {
    [theme.breakpoints.down(breakpoints.MOBILE)]: {
      fontFamily: 'VerlagLight',
      fontSize: '16px',
      margin: 0,
      marginBottom: '10px',
    },
    [theme.breakpoints.up(breakpoints.MOBILE)]: {
      marginLeft: '9.5%',
    },
  },
  goBackButtonCancelation: {
    [theme.breakpoints.up(breakpoints.MOBILE)]: {
      marginRight: 25,
      marginLeft: 25,
    },
    [theme.breakpoints.down(breakpoints.MOBILE)]: {
      height: '50px',
      marginRight: '10px',
    },
    width: '100%',
  },
  buttonCancelReservationCancelation: {
    [theme.breakpoints.up(breakpoints.MOBILE)]: {
      width: '150px',
    },
    [theme.breakpoints.down(breakpoints.MOBILE)]: {
      height: '50px',
      width: '100%',
    },
  },
});

MeetingSummaryCancelation.propTypes = {
  onBack: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  classes: PropTypes.shape({}).isRequired,
  isSuccess: PropTypes.bool.isRequired,
  isButtonDisabled: PropTypes.bool.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  isOnDemandReservation: PropTypes.bool.isRequired,
};

export default withStyles(styles)(MeetingSummaryCancelation);
