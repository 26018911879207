import { createSelector } from 'reselect';
import Lodash from 'lodash';

export const getVisitingMembersRedux = state =>
  Lodash.get(state, ['visitingMembers'], {});

export const getSelectedGrouping = createSelector(
  getVisitingMembersRedux,
  visitingMembers => Lodash.get(visitingMembers, ['selectedGrouping'], {})
);
