import { useEffect, useState } from 'react';
import { BalanceDto, fetchUserBalance } from '../Core/Api';

export const useMonthlyMeetingRoomStats = (props: {
  accountSfId: string;
  startMonthEpoch: number;
  refreshCount: number;
}) => {
  const { accountSfId, startMonthEpoch, refreshCount } = props;
  const [stats, setStats] = useState<BalanceDto | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    let ignore = false;

    const refreshMeetingRoomStats = async () => {
      setIsLoading(true);
      setHasError(false);

      try {
        const response = await fetchUserBalance(accountSfId, startMonthEpoch);
        if (ignore) return;

        const { data } = response;

        if (!data) {
          setHasError(true);
        }
        setStats(data);
      } catch (error) {
        setHasError(true);
      }

      setIsLoading(false);
    };

    refreshMeetingRoomStats();

    return () => {
      ignore = true;
    };
  }, [accountSfId, startMonthEpoch, refreshCount]);

  return { stats, isLoading, hasError };
};
