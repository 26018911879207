/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */

import * as React from 'react';
import { AppBar } from '@material-ui/core';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import Hidden from '@material-ui/core/Hidden';
import { ExpandMore } from '@material-ui/icons';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import MenuIcon from '@material-ui/icons/Menu';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import CheckIcon from '@material-ui/icons/Check';
import Moment from 'moment';
import 'moment/min/locales';

import { getUserProfile } from '../../Redux/Common/UserManager/selectors';
import {
  getUserDefaultGrouping,
  getUserGroupingsIsLoading,
} from '../../Redux/Common/Groupings/selectors';
import styles from './styles';

import { Logos } from '../../resources';
import NavPanel from '../../Components/NavPanel';
import {
  Text,
  SkeletonLoader,
  TextButton,
  Banner,
  SplitButton,
  Carat,
} from '../../Components/Common';
import LogoComponent from './LogoComponent';
import { referralLink } from './Referral';

import {
  ADMIN_PORTAL_URL,
  EN_US,
  LOCALES,
  LOCALE_LABELS,
  CUSTOM_LOCALE_FORMATS,
} from '../../Core/constants';
import { AVAILABLE_LANGUAGES } from '../../Core/Utils/translations';
import { customProps } from '../../Core/Utils';
import { handlePress } from '../../Core/Utils/events';
import { getIsManager } from '../../Core/Utils/userPermissions';
import { makeSubNav } from './MakeSubNav';
import Components from '../../Components';
import { getIsMobile } from '../../Core/Utils/windowDimensions';
import { MEMBER_ACTIVITY_PATH } from '../DeskUsage/constants';
import { ROOM_BOOKING_PATH } from '../RoomBooking/constants';
import { EVENTS_PATH } from '../Events/constants';
import { MESSAGE_MEMBERS_PATH } from '../MessageMembers/constants';
import { GROUP_MANAGEMENT_PATH } from '../GroupManagement/constants';
import { CHECK_ACTIVITY_PATH } from '../CheckInActivity/constants';
import { PACKAGE_ALERTS_PATH } from '../PackageAlerts/constants';
import { MANAGE_DESKS_PATH } from '../ManageDesks/constants';
import { MANAGE_OFFICES_PATH } from '../ManageOffices/constants';
import BillingLink from './BillingLink';
import { getWebsiteUrl } from '../../Core/Utils/linkGenerator';

const { LEFT, RIGHT } = Components.Routes.constants;

const closeMenu = setAnchorEl => {
  setAnchorEl(null);
};

const handleOnKeyDown = setAnchorEl => e => {
  if (e.key === ' ') {
    setAnchorEl(e.currentTarget);
  }
};

const Header = props => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorLanguage, setAnchorLanguage] = React.useState(null);

  const [drawerState, toggleDrawerState] = React.useState(false);

  const {
    classes,
    topNavList,
    subNavOrder,
    subNavList,
    profileData,
    history,
    isLoading,
    userDefaultGrouping,
  } = props;

  const adminMenuList = useMemo(
    () => [
      [
        {
          name: 'adminButton.navigationMenu.dailyreservations',
          path: `${ADMIN_PORTAL_URL}/reservations`,
          isExternal: true,
        },
        {
          name: 'adminButton.navigationMenu.memberaccountactivity',
          path: MEMBER_ACTIVITY_PATH,
          private: true,
          type: 'SUBCONTENT',
        },
      ],
      [
        {
          name: 'adminButton.navigationMenu.manageDesks',
          path: MANAGE_DESKS_PATH,
          private: true,
          type: 'SUBCONTENT',
        },
        {
          name: 'adminButton.navigationMenu.manageOffices',
          path: MANAGE_OFFICES_PATH,
          private: true,
          type: 'SUBCONTENT',
        },
        {
          name: 'adminButton.navigationMenu.managemeetingRooms',
          path: ROOM_BOOKING_PATH,
          private: true,
          type: 'SUBCONTENT',
        },
        {
          name: 'adminButton.navigationMenu.managemenu&Events',
          path: EVENTS_PATH,
          private: true,
          type: 'SUBCONTENT',
        },
      ],
      [
        {
          name: 'adminButton.navigationMenu.messagemembers',
          path: MESSAGE_MEMBERS_PATH,
          private: true,
          type: 'SUBCONTENT',
        },
        {
          name: 'adminButton.navigationMenu.groupmanagement',
          path: GROUP_MANAGEMENT_PATH,
          private: true,
          type: 'SUBCONTENT',
        },
      ],
      [
        {
          name: 'adminButton.navigationMenu.checkinactivity',
          path: CHECK_ACTIVITY_PATH,
          private: true,
          type: 'SUBCONTENT',
        },
        {
          name: 'adminButton.navigationMenu.packagealerts',
          path: PACKAGE_ALERTS_PATH,
          private: true,
          type: 'SUBCONTENT',
        },
      ],
    ],
    [userDefaultGrouping?.idGrouping]
  );

  const { t, i18n } = useTranslation();
  const isMobile = getIsMobile();

  const currentLocale = localStorage.getItem('currentLocale');
  const selectedLanguage =
    currentLocale || AVAILABLE_LANGUAGES[navigator.language] || EN_US;

  const onClickMenuNav = (path, search = '') => {
    closeMenu(setAnchorEl);
    history.push({
      pathname: `${path}`,
      search,
    });
  };

  React.useEffect(() => {
    Moment.updateLocale(
      selectedLanguage,
      CUSTOM_LOCALE_FORMATS[selectedLanguage]
    );
  }, []);

  const handleLangueChange = language => {
    i18n.changeLanguage(language);
    localStorage.setItem('currentLocale', language);
    Moment.updateLocale(language, CUSTOM_LOCALE_FORMATS[language]);
  };

  const onClickLanguageMenu = language => {
    closeMenu(setAnchorLanguage);
    handleLangueChange(language);
  };

  const navigateToNewPage = (path, search = '', isExternal) => {
    if (isExternal) {
      window.open(path, '_blank');
    } else {
      history.push({
        pathname: `${path}`,
        search,
      });
    }
  };

  const navigateToAdminPage = () => {
    window.open(ADMIN_PORTAL_URL);
  };

  const pages = topNavList.filter(navTab => navTab.type === 'CONTENT');

  let genNav;
  if (isLoading) {
    genNav = [0, 1, 2, 3, 4, 5].map(idx => {
      return (
        <MenuItem
          key={idx}
          classes={{
            root: classes.genNavProfileItems,
          }}
          datatestid={`user_dropdown_loading_${idx}`}
        >
          <SkeletonLoader className={classes.skeletonLoader} />
        </MenuItem>
      );
    });
  } else {
    genNav = pages.map(navTab => {
      return (
        <MenuItem
          key={navTab.path}
          onClick={handlePress(onClickMenuNav, navTab.path, navTab.search)}
          selected={false}
          classes={{
            root: classes.genNavProfileItems,
          }}
          datatestid={`user_dropdown_${navTab.name}`}
        >
          <Text
            textStyle="h6"
            text={t(navTab.name)}
            className={classes.genNavProfileItemsText}
          />
        </MenuItem>
      );
    });
  }

  const isManager = getIsManager(profileData);

  const subNav = subNavOrder.reduce(
    makeSubNav(subNavList, classes, isLoading, t, isManager),
    {
      [LEFT]: [],
      [RIGHT]: [],
    }
  );

  if (!isLoading) {
    genNav.splice(genNav.length - 1, 0, referralLink(classes, profileData));
  }

  const navigateToDeltaOffer = () => {
    window.open(getWebsiteUrl('lp/dbt-offer'));
  };

  const renderBannerContent = () => {
    return (
      <div className={classes.referBanner}>
        <Text text={t('banner.label')} />{' '}
        <TextButton
          className={classes.referButton}
          onClick={navigateToDeltaOffer}
          text={t('banner.cta')}
        />
      </div>
    );
  };

  return (
    <>
      <AppBar position="fixed" className={classes.appBar}>
        <Banner>{renderBannerContent()}</Banner>
        <div className={classes.main}>
          <Hidden mdUp implementation="css">
            <MenuIcon
              className={classes.sideNavMenuButton}
              onClick={() => {
                toggleDrawerState(!drawerState);
              }}
            />
            <NavPanel
              isOpen={drawerState}
              onClose={toggleDrawerState}
              subNavOrder={subNavOrder}
              subNavList={subNavList}
              adminMenuList={adminMenuList}
              history={history}
              profile={profileData}
              classes={classes}
              selectedLanguage={selectedLanguage}
              onLanguageItemMenuClick={handleLangueChange}
            />
            <LogoComponent Logo={Logos.IndLogoBeeBlack} />
          </Hidden>

          <Hidden smDown implementation="css">
            <LogoComponent Logo={Logos.BlackIndLogoHoriz} />
          </Hidden>

          <Hidden
            smDown
            implementation="css"
            className={classes.topSubNavLeftWrapper}
          >
            <div className={classes.topSubNavLeft}>
              {subNav[LEFT]}
              <BillingLink className={classes.subNavMenuItem} />
            </div>
          </Hidden>

          <Hidden
            smDown
            implementation="css"
            className={classes.topSubNavRightWrapper}
          >
            <div className={classes.topSubNavRight}>{subNav[RIGHT]}</div>
          </Hidden>

          <div className={classes.userInfoDiv}>
            {isMobile ? (
              <div className={classes.accountMobile}>
                <div
                  onClick={e => setAnchorEl(e.currentTarget)}
                  className={classes.accountInfo}
                  onKeyDown={handleOnKeyDown(setAnchorEl)}
                >
                  <p
                    style={{ margin: 0, cursor: 'pointer' }}
                    aria-owns={anchorEl ? 'simple-menu' : undefined}
                    aria-haspopup="true"
                  >
                    Account
                  </p>
                  <ExpandMore
                    className={classes.caratIconResponsive}
                    aria-owns={anchorEl ? 'simple-menu' : undefined}
                    aria-haspopup="true"
                    tabIndex={0}
                    datatestid="home_user_dropdown_menu"
                  />
                </div>
              </div>
            ) : (
              <div className={classes.accountDesktop}>
                {isManager ? (
                  <div className={classes.adminButtonContainer}>
                    <SplitButton
                      label={t('adminButton.actionLabel')}
                      items={adminMenuList}
                      onItemPress={navigateToNewPage}
                      onButtonPress={navigateToAdminPage}
                    />
                  </div>
                ) : null}
                <div
                  onClick={e => setAnchorEl(e.currentTarget)}
                  className={classes.accountInfo}
                  onKeyDown={handleOnKeyDown(setAnchorEl)}
                >
                  <p
                    aria-owns={anchorEl ? 'simple-menu' : undefined}
                    aria-haspopup="true"
                  >
                    {profileData.name ? profileData.name : ''}
                  </p>
                  <ExpandMore
                    className={classes.caratIcon}
                    aria-owns={anchorEl ? 'simple-menu' : undefined}
                    aria-haspopup="true"
                    tabIndex={0}
                    datatestid="home_user_dropdown_menu"
                  />
                </div>
                <div
                  className={classes.languageToggle}
                  onClick={e => setAnchorLanguage(e.currentTarget)}
                  onKeyDown={handleOnKeyDown(setAnchorLanguage)}
                >
                  <p
                    aria-owns={anchorEl ? 'language-menu' : undefined}
                    aria-haspopup="true"
                    className={classes.languageLabel}
                  >
                    {selectedLanguage}
                  </p>
                  <Carat
                    orientation={anchorLanguage ? 'up' : 'down'}
                    className={classes.languageMenuIcon}
                    aria-haspopup="true"
                    tabIndex={0}
                    datatestid="home_language_dropdown_menu"
                  />
                </div>
              </div>
            )}
            <Menu
              disableAutoFocusItem
              open={Boolean(anchorEl)}
              anchorEl={anchorEl}
              onClose={() => setAnchorEl(null)}
              classes={{
                paper: clsx(classes.menuPaperProfileNav),
              }}
              MenuListProps={{
                disablePadding: true,
              }}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
            >
              {genNav}
            </Menu>
            <Menu
              disableAutoFocusItem
              open={Boolean(anchorLanguage)}
              anchorEl={anchorLanguage}
              onClose={() => setAnchorLanguage(null)}
              classes={{
                paper: clsx(classes.languageMenuNav),
              }}
              MenuListProps={{
                disablePadding: true,
              }}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
            >
              {LOCALES.map(l => (
                <MenuItem
                  key={l}
                  onClick={handlePress(onClickLanguageMenu, l)}
                  selected={false}
                  classes={{
                    root: classes.languageMenuItem,
                  }}
                  datatestid={`language_dropdown_${l}`}
                >
                  <Text
                    text={LOCALE_LABELS[l]}
                    className={classes.languageMenuItemText}
                  />
                  {selectedLanguage === l && (
                    <CheckIcon className={classes.checkIcon} />
                  )}
                </MenuItem>
              ))}
            </Menu>
          </div>
        </div>
      </AppBar>
      <div className={classes.dummyHeader}>
        <div className={classes.dummyHeaderCover} />
        <div className={classes.main} />
        <Banner>{renderBannerContent()}</Banner>
      </div>
    </>
  );
};

Header.propTypes = {
  classes: PropTypes.object.isRequired,
  topNavList: customProps.topNavList.isRequired,
  profileData: customProps.profile.isRequired,
  subNavOrder: PropTypes.arrayOf(PropTypes.string).isRequired,
  subNavList: customProps.subNavList.isRequired,
  isLoading: PropTypes.bool.isRequired,
  userDefaultGrouping: PropTypes.object.isRequired,
};

const mapStateToProps = state => {
  return {
    profileData: getUserProfile(state),
    isLoading: getUserGroupingsIsLoading(state),
    userDefaultGrouping: getUserDefaultGrouping(state),
  };
};

export default connect(mapStateToProps)(withStyles(styles)(Header));
