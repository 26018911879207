import React from 'react';
import PropTypes from 'prop-types';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import { withStyles } from '@material-ui/core/styles';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import clsx from 'clsx';
import Lodash from 'lodash';

import { colors } from '../../../Core/Theme';
import Carat from '../Carat';
import TooltipC from '../Tooltip';

class SelectInput extends React.PureComponent {
  handleChange = event => {
    const { onChange } = this.props;
    onChange(event.target.value);
  };

  render() {
    const {
      options,
      value,
      label,
      disabled,
      displayEmpty,
      renderValue,
      placeholder,
      classes,
      variant,
      className,
      isLabelHidden,
      datatestid,
      toolTipMessage,
      hasError,
      errorMessage,
      inputClassName,
      classNameCarat,
      classNameSelectRoot,
      menuClassName,
      noCaratWithSingleOption,
      isLabelUppercase,
    } = this.props;

    if (Lodash.isNil(value) && placeholder) {
      options.push({ value: 'placeholder_key', label: placeholder });
    }

    const isPrimary = variant === 'primary';
    const isForm = variant === 'form';
    const isBoxedLarge = variant === 'boxedLarge';
    const isBoxed = variant === 'boxed' || isBoxedLarge;
    const numOptions = options.length;
    const disabledBySingleOption = noCaratWithSingleOption && numOptions <= 1;
    return (
      <FormControl
        className={clsx(className, isBoxed && !numOptions && classes.noEvents)}
      >
        {label ? (
          <InputLabel
            htmlFor="select-helper"
            disableAnimation
            className={clsx(
              classes.inputLabel,
              hasError && classes.inputLabelError,
              isLabelHidden && classes.inputLabelHidden,
              isPrimary && classes.inputLabelPrimary,
              disabled && isBoxed && classes.disabledLabel,
              disabledBySingleOption && isBoxed && classes.disabledLabel,
              isLabelUppercase && classes.uppercase
            )}
            datatestid={`${datatestid}_label`}
          >
            {label}
            {toolTipMessage && (
              <TooltipC title={toolTipMessage} iconClass={classes.toolTip} />
            )}
          </InputLabel>
        ) : null}
        <Select
          value={Lodash.isNil(value) ? 'placeholder_key' : value}
          onChange={this.handleChange}
          classes={{
            root: clsx(
              classNameSelectRoot || (!isBoxed && classes.selectBorder),
              disabled && !isBoxed && classes.disabledBorder,
              disabledBySingleOption && !isBoxed && classes.disabledBorder
            ),
            select: clsx(
              inputClassName,
              classes.select,
              isBoxed && classes.boxedSelect,
              hasError && isBoxed && classes.boxedSelectError,
              isBoxedLarge && classes.boxedLargeSelect,
              isForm && classes.baseFonts,
              isPrimary && classes.inputPrimary,
              ((Lodash.isNil(value) && placeholder) || disabled) &&
                classes.disabledInput,
              disabledBySingleOption && classes.boxedSelectSingleOptionDisabled
            ),
          }}
          disabled={disabled || options.length === 0 || disabledBySingleOption}
          IconComponent={props => (
            <a
              {...props}
              className={clsx(
                classNameCarat || classes.carat,
                (disabled || !options.length) && classes.disabledCarat,
                isBoxed && classes.boxedCarat,
                noCaratWithSingleOption &&
                  options.length <= 1 &&
                  classes.noCarat
              )}
              datatestid={`${datatestid}_carat`}
            >
              <Carat size={isBoxed ? 'mediumSmall' : 'medium'} />
            </a>
          )}
          MenuProps={{
            classes: {
              paper: clsx(
                classes.menu,
                menuClassName,
                isBoxed && classes.boxedMenu
              ),
            },
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'left',
            },
            transformOrigin: {
              vertical: 'top',
              horizontal: 'left',
            },
            getContentAnchorEl: null,
          }}
          disableUnderline
          displayEmpty={displayEmpty}
          renderValue={renderValue}
          SelectDisplayProps={{
            datatestid: `${datatestid}_input`,
          }}
        >
          {numOptions &&
            options.map((option, idx) => (
              <MenuItem
                value={option.value}
                key={option.label}
                disabled={option.disabled}
                datatestid={`${datatestid}_${idx}_${option.label}`}
                className={clsx(
                  option.value === 'placeholder_key' && classes.hiddenMenuItem,
                  option.disabled && classes.disabledOption,
                  classes.baseFonts,
                  isBoxed && classes.boxedMenuItem,
                  idx === 0 && isBoxed && classes.boxedMenuItemFirst,
                  idx === numOptions - 1 &&
                    isBoxed &&
                    classes.boxedMenuItemLast,
                  isBoxedLarge && classes.largerMenuItem
                )}
              >
                {option.label}
              </MenuItem>
            ))}
        </Select>
        {hasError && <div className={classes.errorMessage}>{errorMessage}</div>}
      </FormControl>
    );
  }
}
const styles = () => ({
  formControlLabel: {
    position: 'inherit',
  },
  carat: {
    top: `calc(50% - 5px)`,
    right: 0,
    position: `absolute`,
    pointerEvents: `none`,
  },
  disabledCarat: {
    filter: 'opacity(.3)',
  },
  hiddenMenuItem: {
    display: 'none',
  },
  disabledInput: {
    color: colors.darkGray,
  },
  disabledOption: {
    color: colors.darkGray,
    opacity: 1,
  },
  errorMessage: {
    fontFamily: 'VerlagLight',
    fontSize: 12,
    color: colors.alertMessage,
    paddingTop: 2,
  },
  inputPrimary: {
    fontSize: 20,
    fontFamily: 'VerlagBold',
    paddingTop: 9,
  },
  baseFonts: {
    fontSize: 18,
    fontFamily: 'VerlagLight',
    lineHeight: '20px',
    backgroundColor: `${colors.white} !important`,
    '&:hover': {
      backgroundColor: `${colors.palette.secondary2.main} !important`,
    },
  },
  select: {
    paddingBottom: 7,
    paddingRight: 5,
    backgroundColor: 'white',
    minHeight: '0px',
    '&:hover': {
      backgroundColor: 'white',
    },
    '&:focus:not(.focus-visible)': {
      background: 'white',
    },
  },
  selectBorder: {
    borderBottom: '1px solid #000000',
  },
  disabledBorder: {
    borderBottom: `1px solid ${colors.gray}`,
  },
  noMenu: {
    display: 'none',
  },
  menu: {
    maxHeight: '150px',
    width: '115px',
    borderRadius: '3px',
    border: `solid 1px ${colors.dark}`,
    boxShadow: `0 0 3px 1px rgba(0, 0, 0, 0.25)`,
    marginTop: 2,
  },
  inputLabel: {
    color: 'black !important',
    fontSize: 12,
    fontFamily: 'VerlagBold',
    transform: 'none',
    whiteSpace: 'nowrap',
  },
  inputLabelPrimary: {
    fontFamily: 'VerlagBook !important',
  },
  inputLabelError: {
    color: `${colors.alertMessage} !important`,
  },
  inputLabelHidden: {
    position: 'absolute !important',
    height: 1,
    width: 1,
    overflow: 'Hidden',
    clip: 'rect(1px, 1px, 1px, 1px)',
    whiteSpace: 'nowrap',
  },
  uppercase: {
    textTransform: 'uppercase',
  },
  labelWrapper: {
    display: 'flex',
  },
  toolTip: {
    verticalAlign: 'top',
    marginTop: -5,
  },
  boxedSelect: {
    borderRadius: '4px !important',
    fontFamily: 'VerlagBook',
    paddingLeft: 10,
    fontSize: 16,
    paddingTop: 11,
    paddingRight: 30,
    borderBottom: 'none',
    border: `solid 1px ${colors.middle} !important`,
    lineHeight: '20px',
  },
  boxedSelectError: {
    border: `solid 1px ${colors.alertMessage} !important`,
  },
  boxedSelectSingleOptionDisabled: {
    paddingRight: 10,
    color: colors.black,
  },
  boxedLargeSelect: {
    fontSize: 18,
  },
  noCarat: {
    display: 'none',
  },
  noEvents: {
    pointerEvents: 'none',
  },
  boxedCarat: {
    top: `calc(50% - 7px)`,
    right: 10,
  },
  boxedCaratInner: { width: 12 },
  boxedMenu: {
    boxShadow: '0 0 4px 0 rgba(0, 0, 0, 0.1)',
    borderRadius: 4,
    border: `solid 1px ${colors.middle}`,
  },
  boxedMenuItem: {
    fontSize: 16,
    height: 12,
    paddingTop: 15,
    paddingBottom: 15,
  },
  boxedMenuItemFirst: {
    marginTop: -3,
  },
  boxedMenuItemLast: {
    marginBottom: -3,
  },
  largerMenuItem: {
    fontSize: 18,
  },
  disabledLabel: {
    color: `${colors.darkGray} !important`,
  },
});

SelectInput.defaultProps = {
  disabled: false,
  className: '',
  displayEmpty: null,
  toolTipMessage: null,
  datatestid: 'select',
  placeholder: '',
  variant: 'boxed',
  isLabelHidden: false,
  hasError: false,
  errorMessage: '',
  inputClassName: '',
  renderValue: null,
  name: '',
  classNameCarat: null,
  classNameSelectRoot: null,
  menuClassName: '',
  noCaratWithSingleOption: false,
  label: '',
  isLabelUppercase: false,
};

SelectInput.propTypes = {
  label: PropTypes.string,
  toolTipMessage: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.object,
  ]).isRequired,
  classes: PropTypes.shape({}).isRequired,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.object,
      ]).isRequired,
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        .isRequired,
    })
  ).isRequired,
  name: PropTypes.string,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  renderValue: PropTypes.func,
  displayEmpty: PropTypes.bool,
  datatestid: PropTypes.string,
  placeholder: PropTypes.string,
  variant: PropTypes.string,
  isLabelHidden: PropTypes.bool,
  isLabelUppercase: PropTypes.bool,
  hasError: PropTypes.bool,
  errorMessage: PropTypes.string,
  inputClassName: PropTypes.string,
  classNameCarat: PropTypes.string,
  classNameSelectRoot: PropTypes.string,
  menuClassName: PropTypes.string,
  noCaratWithSingleOption: PropTypes.bool,
};

export default withStyles(styles)(SelectInput);
