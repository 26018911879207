import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Lodash from 'lodash';
import { useTranslation } from 'react-i18next';

// Components
import { ExpansionPanel, RowToggle } from '../../../../Components/Common';
import NotificationHeader from '../NotificationHeader';

// Constants & utils
import { colors } from '../../../../Core/Theme';
import { DELIVERY_METHODS } from '../../types';

const SettingRow = props => {
  const {
    classes,
    settings,
    userId,
    patchSettings,
    hasPhoneNumber,
    onToggleDrawer,
    postCheckType,
    setPostCheckType,
    openSuccessSnack,
    openFailSnack,
    events,
    title,
    subtitle,
    id,
    hideSms,
    postCheckId,
    setPostCheckId,
    patchSettingsRedux,
  } = props;
  const { t } = useTranslation();

  const handleSnack = response => {
    if (response.value) {
      openSuccessSnack();
    } else {
      openFailSnack();
    }
  };

  const getValueIsRequired = (deliveryMethod, type) => {
    const deliveryMethodIsRequired = Lodash.get(
      events,
      [type, 'deliveryMethodIsRequired'],
      false
    );
    if (!deliveryMethodIsRequired) {
      return false;
    }
    const emailEnabled = Lodash.get(settings, [
      id,
      'events',
      type,
      'deliveryMethods',
      DELIVERY_METHODS.EMAIL,
    ]);
    const smsEnabled = Lodash.get(settings, [
      id,
      'events',
      type,
      'deliveryMethods',
      DELIVERY_METHODS.SMS,
    ]);
    if (
      deliveryMethod === DELIVERY_METHODS.EMAIL &&
      emailEnabled &&
      !smsEnabled
    ) {
      return true;
    }

    if (
      deliveryMethod === DELIVERY_METHODS.SMS &&
      !emailEnabled &&
      smsEnabled
    ) {
      return true;
    }
    return false;
  };

  const handleToggle = (deliveryMethod, eventType) => {
    if (getValueIsRequired(deliveryMethod, eventType)) {
      return;
    }
    const settingsClone = Lodash.cloneDeep(settings);
    settingsClone[id].events[eventType].deliveryMethods[deliveryMethod] =
      !settingsClone[id].events[eventType].deliveryMethods[deliveryMethod];
    patchSettingsRedux(settingsClone);
    patchSettings(userId, settingsClone).then(handleSnack);
  };

  const handleMailCheck = type => {
    handleToggle(DELIVERY_METHODS.EMAIL, type);
  };

  const handleSmsCheck = type => {
    if (!hasPhoneNumber) {
      onToggleDrawer();
      setPostCheckType(type);
      setPostCheckId(id);
      return;
    }
    handleToggle(DELIVERY_METHODS.SMS, type);
  };

  // after the phone number is set update the setting the user was trying to turn on
  React.useEffect(() => {
    if (!postCheckType) return;
    if (!hasPhoneNumber) return;

    if (postCheckId === id) {
      handleSmsCheck(postCheckType);
      setPostCheckType(null);
      setPostCheckId(null);
    }
  }, [postCheckType, hasPhoneNumber]);
  return (
    <div className={classes.wrapper}>
      <div className={classes.root}>
        <ExpansionPanel title={t(title)} subtitle={subtitle ? t(subtitle) : ''}>
          <div className={classes.internalContainer}>
            <NotificationHeader hideSwitchTwo={hideSms} />
            {Object.keys(events).map(ek => {
              const event = events[ek];
              const { deliveryMethods, type, deliveryMethodIsRequired } = event;
              const { smsEnabled, emailEnabled } = deliveryMethods;
              return (
                <RowToggle
                  title={t(Lodash.get(event, 'title', ''))}
                  subtitle={t(Lodash.get(event, 'subtitle', ''))}
                  leftSwitchValue={emailEnabled}
                  leftSwitchHandleChange={() => handleMailCheck(type)}
                  rightSwitchValue={hideSms ? null : smsEnabled}
                  rightSwitchHandleChange={() => {
                    handleSmsCheck(type);
                  }}
                  hasError={deliveryMethodIsRequired}
                  isRightSwitchHidden={hideSms}
                />
              );
            })}
          </div>
        </ExpansionPanel>
      </div>
    </div>
  );
};

const styles = theme => ({
  root: {
    marginLeft: -24,
    marginRight: -18,
  },
  internalContainer: {
    display: 'grid',
    width: '100%',
    marginLeft: 70,
    marginRight: 80,
    marginTop: -25,
    [theme.breakpoints.down('xs')]: {
      marginLeft: 0,
      marginRight: 0,
      marginTop: -5,
    },
  },
  wrapper: {
    marginTop: 10,
    marginLeft: 40,
    borderBottom: `1px solid ${colors.middle}`,
    [theme.breakpoints.down('xs')]: {
      marginLeft: 0,
    },
  },
});

SettingRow.propTypes = {
  userId: PropTypes.string.isRequired,
  settings: PropTypes.object.isRequired,
  patchSettings: PropTypes.func.isRequired,
  patchSettingsRedux: PropTypes.func.isRequired,
  hasPhoneNumber: PropTypes.bool.isRequired,
  onToggleDrawer: PropTypes.func.isRequired,
  postCheckType: PropTypes.string.isRequired,
  setPostCheckType: PropTypes.func.isRequired,
  postCheckId: PropTypes.string.isRequired,
  setPostCheckId: PropTypes.func.isRequired,
  openSuccessSnack: PropTypes.func.isRequired,
  openFailSnack: PropTypes.func.isRequired,
  events: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  hideSms: PropTypes.bool.isRequired,
};

export default withStyles(styles)(SettingRow);
