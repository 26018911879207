import { useSelector, useDispatch } from 'react-redux';
import { useEffect } from 'react';

import floorsRedux from '../Redux/Common/Floors';
import { FloorDto } from '../Core/Api';

export const useFloors = () => {
  const floors = useSelector<unknown, FloorDto[]>(state =>
    floorsRedux.selectors.getFloors(state)
  );
  const dispatch = useDispatch();

  useEffect(() => {
    if (!floors?.length) {
      dispatch(floorsRedux.actions.addFloors());
    }
  }, []);

  return { floors };
};
