import { Link } from 'react-router-dom';
import { get } from 'lodash';
import clsx from 'clsx';
import MenuItem from '@material-ui/core/MenuItem';
import * as React from 'react';
import { LabelChip, Text } from '../Common';

import NavPanelMenu from './NavPanelMenu';
import Components from '..';
import { trackNavigationClick } from '../../Core/Tracking';

const { LEFT, RIGHT } = Components.Routes.constants;

const genLink = (navTab, onClose, isOpen, classes, t) => {
  const { name, path } = navTab;
  const position = get(navTab, ['position'], LEFT);
  return (
    <MenuItem
      className={clsx(
        classes.menuItem,
        position === RIGHT && classes.menuItemBottom
      )}
      key={path}
    >
      <Link
        to={path}
        className={classes.link}
        onClick={() => {
          trackNavigationClick({ event_label: t(name) });
          onClose(!isOpen);
        }}
        datatestid={`home_${t(name)}_navigation_button`}
      >
        <Text textStyle="h5" text={t(name)} className={classes.text} />
        {navTab.isNew ? (
          <LabelChip
            className={classes.labelChip}
            label={t('navigation.new')}
          />
        ) : null}
      </Link>
    </MenuItem>
  );
};

const genMenuAccordian = (key, navTabs, onClose, isOpen) => {
  return (
    <NavPanelMenu
      name={key}
      navTabs={navTabs}
      onClose={onClose}
      isOpen={isOpen}
      key={`navpanel-drop-down-${key}`}
    />
  );
};

export const makeMenu =
  (subNavList, onClose, isOpen, classes, t) => (acc, key) => {
    const temp =
      subNavList[key].length < 2
        ? genLink(subNavList[key][0], onClose, isOpen, classes, t)
        : genMenuAccordian(key, subNavList[key], onClose, isOpen);

    const position = get(subNavList[key][0], ['position'], LEFT);

    acc[position].push(temp);

    return acc;
  };

export const makeAdminMenuItems = (adminMenuList, onClose, isOpen) => {
  const adminMenuItemListWithBottomSectionIndicators = adminMenuList.map(
    list => {
      const array = [...list];
      // add withBottomBorder: true to last item in each subarray of items
      array.splice(list.length - 1, 1, {
        ...list[list.length - 1],
        withBottomBorder: true,
      });
      return array;
    }
  );
  return genMenuAccordian(
    'adminButton.listTitle',
    adminMenuItemListWithBottomSectionIndicators.flat(),
    onClose,
    isOpen
  );
};
