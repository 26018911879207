import React from 'react';

// Components
import { MapLayerWrapper as Layer } from '../../Common';

// Types
import { Layers } from '../types';

// Theme
import { colors } from '../../../Core/Theme';

const LAYER_ID = Layers.Overlay;

// Layer properties
const fillProperties = {
  id: LAYER_ID,
  type: 'fill',
  source: 'features',
  filter: ['in', ['get', 'type'], ['literal', [LAYER_ID]]],
  paint: {
    'fill-color': colors.blackOpacityLight,
  },
};

const Overlay = (props: any) => {
  return <Layer properties={[fillProperties]} {...props} />;
};

export default React.memo(Overlay);
