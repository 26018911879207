import { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import SmartTable from '../SmartTable';
import SearchInput from '../SearchInput';
import Text from '../Text';
import { breakpoints, colors } from '../../../Core/Theme';
import { FilterModalAnchor } from '../Table/Components';
import { TextButton } from '../index';

const SmartTableWithSearchBox = props => {
  const {
    classes,
    onChangeSearchValue,
    searchValue,
    placeholder,
    renderLeftHeader,
    textHeader,
    classNameTable,
    emptyMessage,
    noResultsMessage,
    containerClassName,
    headerClassName,
    searchAlignItems,
    renderBottomHeader,
    columns,
    filterParams,
    onFilterPress,
    onClearFilters,
    initialLoad,
  } = props;
  const { t } = useTranslation();
  const [internalSelectedFilters, setInternalSelectedFilters] = useState({});

  const emptyMessageWrapped = () => {
    if (searchValue && noResultsMessage) {
      return noResultsMessage;
    }
    return emptyMessage;
  };

  const handleOnFilterPress = objFilters => {
    setInternalSelectedFilters(objFilters);
    onFilterPress(objFilters);
  };
  const hasFilterableItems = columns.some(column => column.filters);

  const selectedFilterLabels = useMemo(
    () =>
      Object.keys(internalSelectedFilters).reduce((acc, key) => {
        if (!internalSelectedFilters[key]?.length) {
          return acc;
        }
        const columnFilters = columns.find(c => c.key === key)?.filters;
        return acc.concat(
          internalSelectedFilters[key]?.map(
            f => columnFilters.find(cf => cf.value === f).label
          )
        );
      }, []),
    [columns, internalSelectedFilters]
  );

  const handleOnClearFilters = () => {
    setInternalSelectedFilters({});
    onClearFilters();
  };

  return (
    <Grid container className={clsx(classes.container, containerClassName)}>
      <Grid item xs={12} className={clsx(classes.header, headerClassName)}>
        <Grid
          container
          alignItems={searchAlignItems}
          justify="space-between"
          spacing={8}
        >
          <Grid item xs={12} md={8}>
            {renderLeftHeader ? (
              renderLeftHeader()
            ) : (
              <Text text={textHeader} className={classes.textHeader} />
            )}
          </Grid>
          <Grid item xs={12} md={4} className={classes.searchInput}>
            <div className={classes.input}>
              <SearchInput
                placeholder={placeholder}
                onChange={onChangeSearchValue}
                value={searchValue}
                variant="box"
              />
            </div>
            {hasFilterableItems && (
              <div className={classes.mobileFilters}>
                <FilterModalAnchor
                  columns={columns}
                  onFilter={handleOnFilterPress}
                  selectedItems={filterParams || internalSelectedFilters}
                  onClearFilters={handleOnClearFilters}
                />
              </div>
            )}
          </Grid>
          {renderBottomHeader ? (
            <Grid item xs={12}>
              {renderBottomHeader()}
            </Grid>
          ) : null}
          {selectedFilterLabels.length ? (
            <Grid item xs={12} className={classes.filterDisplay}>
              {t('general.table.filters.filters_prefix')}
              {selectedFilterLabels.join(', ')}
              <TextButton
                text={t('general.table.filters.clear')}
                onClick={handleOnClearFilters}
              />
            </Grid>
          ) : null}
        </Grid>
      </Grid>
      <Grid
        item
        xs={12}
        className={clsx(classes.tableContainer, classNameTable)}
      >
        <SmartTable
          {...props}
          withInnerPadding
          emptyMessage={emptyMessageWrapped()}
          columns={columns}
          filterParams={filterParams || internalSelectedFilters}
          onFilterPress={handleOnFilterPress}
          initialLoad={initialLoad}
        />
      </Grid>
    </Grid>
  );
};

SmartTableWithSearchBox.defaultProps = {
  searchValue: null,
  placeholder: null,
  renderLeftHeader: null,
  textHeader: null,
  classNameTable: null,
  noResultsMessage: null,
  emptyMessage: null,
  containerClassName: '',
  headerClassName: '',
  searchAlignItems: 'center',
  renderBottomHeader: null,
  onFilterPress: () => {},
  onClearFilters: () => {},
  filterParams: null,
  initialLoad: false,
};

SmartTableWithSearchBox.propTypes = {
  onChangeSearchValue: PropTypes.func.isRequired,
  searchValue: PropTypes.string,
  placeholder: PropTypes.string,
  textHeader: PropTypes.string,
  classNameTable: PropTypes.string,
  renderLeftHeader: PropTypes.func,
  emptyMessage: PropTypes.node,
  noResultsMessage: PropTypes.node,
  containerClassName: PropTypes.string,
  headerClassName: PropTypes.string,
  searchAlignItems: PropTypes.string,
  renderBottomHeader: PropTypes.func,
  onFilterPress: PropTypes.func,
  columns: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  filterParams: PropTypes.shape({
    [PropTypes.string]: PropTypes.arrayOf(PropTypes.string),
  }),
  onClearFilters: PropTypes.func,
  initialLoad: PropTypes.bool,
};

const styles = theme => ({
  textHeader: {
    fontFamily: 'VerlagBold',
    fontSize: 16,
    lineHeight: 0.9,
    [theme.breakpoints.up(breakpoints.MOBILE)]: {
      fontSize: 20,
    },
  },
  header: {
    paddingLeft: 20,
    paddingRight: 20,
    paddingTop: 20,
    marginBottom: 30,
  },
  tableContainer: {
    overflowY: 'scroll',
    [theme.breakpoints.up(breakpoints.MOBILE)]: {
      '&::-webkit-scrollbar-thumb': {
        borderRadius: 2,
        backgroundColor: colors.darkGray,
      },
      '&::-webkit-scrollbar-thumb:hover ': {
        background: colors.darkGray,
      },
      '&::-webkit-scrollbar': {
        width: 12,
      },
    },
  },
  container: {
    borderRadius: '2px',
    boxShadow: '0 0 4px 1px rgba(0, 0, 0, 0.1)',
    border: 'solid 1px #d8d8d8',
    backgroundColor: '#ffffff',
  },
  mobileFilters: {
    display: 'block',
    [theme.breakpoints.up(breakpoints.MOBILE)]: {
      display: 'none',
    },
  },
  searchInput: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    [theme.breakpoints.up(breakpoints.MOBILE)]: {
      justifyContent: 'flex-end',
    },
  },
  input: {
    width: '100%',
  },
  filterDisplay: {
    display: 'none',
    [theme.breakpoints.up(breakpoints.MOBILE)]: {
      display: 'block',
    },
  },
});
export default withStyles(styles)(SmartTableWithSearchBox);
