export const SET_LOCATION = 'SET_LOCATIONS_REC';
export const LOAD_YEAR_NOTIFICATIONS = 'LOAD_YEAR_NOTIFICATIONS';
export const LOAD_YEAR_NOTIFICATIONS_PENDING =
  'LOAD_YEAR_NOTIFICATIONS_PENDING';
export const LOAD_YEAR_NOTIFICATIONS_FULFILLED =
  'LOAD_YEAR_NOTIFICATIONS_FULFILLED';
export const LOAD_YEAR_NOTIFICATIONS_REJECTED =
  'LOAD_YEAR_NOTIFICATIONS_REJECTED';

// LOCATION SETTINGS
export const LOAD_SELECTED_LOCATION_SETTINGS =
  'LOAD_SELECTED_LOCATION_SETTINGS';
export const LOAD_SELECTED_LOCATION_SETTINGS_PENDING =
  'LOAD_SELECTED_LOCATION_SETTINGS_PENDING';
export const LOAD_SELECTED_LOCATION_SETTINGS_FULFILLED =
  'LOAD_SELECTED_LOCATION_SETTINGS_FULFILLED';
export const LOAD_SELECTED_LOCATION_SETTINGS_REJECTED =
  'LOAD_SELECTED_LOCATION_SETTINGS_REJECTED';
export const SET_MAILROOM_DESC = 'SET_MAILROOM_DESC';
export const ADD_NOTIFICATION_RECIPIENT = 'ADD_NOTIFICATION_RECIPIENT';
export const REMOVE_NOTIFICATION_RECIPIENT = 'REMOVE_NOTIFICATION_RECIPIENT';
export const SET_TIMEZONE = 'RECEPTION/SET_TIMEZONE';

// PACKAGE ALERTS
export const SET_REDIRECT_TO_PACKAGE_ALERTS = 'REDIRECT_TO_PACKAGE_ALERTS';
export const SET_DRAWER_STATE = 'SET_DRAWER_STATE';
export const SET_ACTIVE_DRAWER_CONTENT = 'SET_ACTIVE_DRAWER_CONTENT';
