import withStyles, {
  ClassNameMap,
  StyleRulesCallback,
} from '@material-ui/core/styles/withStyles';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Drawer, Snack } from '../Common';

import { colors } from '../../Core/Theme';

import { OfficeReservationDto } from '../../Core/Api/dto/OfficeReservations';
import OfficeReservationForm from '../../Containers/ReserveOffice/Components/OfficeReservationForm';
import { OfficeAvailabilityDto, UserDto } from '../../Core/Api/dto';
import { Office } from '../../Models';
import { officeReservationRoleAccess } from '../../Core/Utils/userPermissions';
import { cancelOfficeReservation } from '../../Core/Api/officeReservationsAPI';

type Props = {
  classes: ClassNameMap;
  profile: UserDto;
  reservation?: OfficeReservationDto;
  onClose: () => void;
  onSuccess: () => void;
};

const OfficeReservationSummary = (props: Props) => {
  const { classes, profile, reservation, onClose, onSuccess } = props;
  const { t } = useTranslation();
  const [isCancelling, setIsCancelling] = useState(false);
  const [isCancelSnackOpen, setIsCancelSnackOpen] = useState(false);

  const onCancel = () => {
    if (!reservation?.id) return;
    setIsCancelling(true);
    cancelOfficeReservation(reservation.id).then(() => {
      setIsCancelling(false);
      onClose();
      setIsCancelSnackOpen(true);
      onSuccess();
    });
  };

  const officeAvailability = useMemo((): OfficeAvailabilityDto | null => {
    if (!reservation) return null;

    return {
      ...reservation,
      floorName: reservation?.office?.floor?.floorName ?? '',
      isReserved: true,
      reservedBy: {
        ...reservation.owner,
        isSelf: reservation.ownerSfId === profile.sfId,
      },
      officeName: Office.getOfficeName(reservation.office),
      amenities:
        reservation.office.amenityOfficeRelations?.map(aor => aor.amenity) ??
        [],
      seats: reservation.office.seats,
    };
  }, [reservation]);

  return (
    <>
      <Drawer
        isOpenDrawer={!!reservation}
        className="drawerPaperMediumSmall"
        anchor="right"
        onCloseButton={onClose}
        onCloseDrawer={onClose}
        withCloseButton
      >
        {officeAvailability && reservation && (
          <div className={classes.panelContent}>
            <OfficeReservationForm
              officeAvailability={officeAvailability}
              grouping={reservation?.grouping}
              profile={profile}
              currencyIsoCode={reservation.currency}
              isCancel
              canCancel={officeReservationRoleAccess.delete({
                obj: reservation,
                profile,
              })}
              isCancelling={isCancelling}
              onCancel={onCancel}
              isReservingWithDayPasses={reservation?.usesDayPasses}
            />
          </div>
        )}
      </Drawer>
      <Snack
        message={t('officeReservationSummary.cancel_snack')}
        autoClose
        isOpen={isCancelSnackOpen}
        onClose={() => setIsCancelSnackOpen(false)}
      />
    </>
  );
};

OfficeReservationSummary.defaultProps = {
  reservation: undefined,
};

const style: StyleRulesCallback<string> = () => ({
  panelContent: {
    backgroundColor: colors.palette.secondary2.light,
    height: '100%',
    padding: '30px 40px',
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
  },
});

export default withStyles(style)(OfficeReservationSummary);
