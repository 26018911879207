import { FeatureCollection } from '@turf/turf';
import { getStaticUrl, Http } from '../Utils';

export const fetchFeaturesForDatasetId = async (datasetId: string) => {
  const path = `mapbox/dataset_${datasetId}.geojson`;
  const url = getStaticUrl(path);

  const { data } = await Http.get<FeatureCollection>(url);

  return data;
};
