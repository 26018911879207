import { createSelector } from 'reselect';
import Lodash from 'lodash';
import { date as dateUtils } from '../../../Core/Utils';

const { getJsonDateTimeForTimezone } = dateUtils;

const countDates = items => {
  const history = [...items];
  const dates = [];

  for (let i = 0; i < history.length; i++) {
    const date = history[i].notificationDate.split('T')[0];
    if (!dates.some(el => el.date === date)) {
      dates.push({ date, count: 1 });
    } else {
      const d = dates.filter(el => el.date === date);
      if (d) {
        d[0].count++;
      }
    }
  }
  return dates;
};

const getPackageAlertsObject = state => Lodash.get(state, 'parcels', {});
const getReceptionistObject = state => Lodash.get(state, 'receptionist', {});

export const getSelectedLocationTimeZoneId = createSelector(
  getReceptionistObject,
  obj => Lodash.get(obj, ['selectedLocation', 'timeZoneId'], null)
);

export const getParcelHistories = createSelector(
  getPackageAlertsObject,
  getSelectedLocationTimeZoneId,
  (obj, tz) => {
    const histories = Lodash.get(obj, 'parcelHistories', []);
    return histories.map(el => ({
      ...el,
      notificationDate: getJsonDateTimeForTimezone(el.notificationDate, tz),
    }));
  }
);

export const getDateCount = createSelector(
  getParcelHistories,
  parcelHistories => countDates(parcelHistories)
);

export const getIsLoading = createSelector(getPackageAlertsObject, obj =>
  Lodash.get(obj, 'loading', false)
);

export const getLastRecordId = createSelector(getParcelHistories, arr =>
  Lodash.get(Lodash.last(arr), 'idParcelHistory', null)
);

export const getTotalLimitOffset = createSelector(
  getPackageAlertsObject,
  obj => {
    const total = Lodash.get(obj, 'total', 0);
    const limit = Lodash.get(obj, 'limit', 0);
    const offset = Lodash.get(obj, 'offset', 0);
    return { total, limit, offset };
  }
);

export const getLoadedRecordsCount = createSelector(
  getParcelHistories,
  parcelHistories => parcelHistories.length
);

export const getNoRecordsFound = createSelector(getPackageAlertsObject, obj => {
  if (obj.isLoading) return false;
  const parcelsAreEmpty = Lodash.isEmpty(
    Lodash.get(obj, 'parcelHistories', [])
  );
  const firstLoadingOcurred = Lodash.get(obj, 'firstLoadingOccurred', false);
  return parcelsAreEmpty && firstLoadingOcurred;
});

export const getSelectedDate = createSelector(
  getPackageAlertsObject,
  packageAlertsObjects => Lodash.get(packageAlertsObjects, 'selectedDate', null)
);
