import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Lodash from 'lodash';
import { withTranslation } from 'react-i18next';

// Styles
import { colors, breakpoints } from '../../../../../../Core/Theme';

// Common Components
import TitleDescription from '../../../../../../Components/Common/TitleDescription';

class MeetingSummaryUneditable extends Component {
  doesUserOwnBooking() {
    const { profile, bookingValues } = this.props;
    const userSfId = Lodash.get(profile, ['sfId'], '');
    const ownerSfId = Lodash.get(bookingValues, ['user', 'sfId'], '');
    return userSfId === ownerSfId && userSfId !== '';
  }

  renderSummary = summaryDetails => {
    return Object.keys(summaryDetails).map(key => {
      if (key === 'time') {
        const value = Lodash.get(summaryDetails[key], ['value'], null);
        return (
          <div datatestid={`rr_${key}_selected`}>
            <TitleDescription
              title={key.toUpperCase()}
              description={
                value ||
                `${summaryDetails[key].initialTime} - ${summaryDetails[key].endTime}`
              }
            />
          </div>
        );
      }
      return (
        !summaryDetails[key].isHidden && (
          <div datatestid={`rr_${key}_selected`}>
            {summaryDetails[key].isVisible ? (
              <TitleDescription
                title={summaryDetails[key].key.toUpperCase()}
                description={summaryDetails[key].value}
              />
            ) : (
              <TitleDescription
                title={key.toUpperCase()}
                description={summaryDetails[key]}
              />
            )}
          </div>
        )
      );
    });
  };

  render() {
    const { classes, data, errorMessage, t } = this.props;
    const userOwned = this.doesUserOwnBooking();
    const message = userOwned
      ? t('meetingSummary.30_min_edit_warning')
      : t('meetingSummary.only_edit_if_owner');
    return (
      <React.Fragment>
        <div className={classes.summaryContainerUnEditable}>
          <div className={classes.summaryDetailsContainerUneditable}>
            <div className={classes.summaryDetailsInfoUneditable}>
              {this.renderSummary(data)}
            </div>
            <div className={classes.unEditableInfo}>
              <p>{errorMessage || message}</p>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const styles = theme => ({
  unEditableInfo: {
    display: 'flex',
    flexDirection: 'row',
    width: '80%',
    fontFamily: 'VerlagLight',
    fontSize: '14px',
    [theme.breakpoints.up(breakpoints.MOBILE)]: {
      flexDirection: 'column',
      width: '76%',
      marginRight: '6%',
      marginLeft: '4%',
      textAlign: 'center',
    },
    [theme.breakpoints.down(breakpoints.MOBILE)]: {
      display: 'none',
    },
  },
  summaryContainerUnEditable: {
    flexDirection: 'column',
    display: 'flex',
  },
  summaryDetailsInfoUneditable: {
    [theme.breakpoints.down(breakpoints.MOBILE)]: {
      justifyContent: 'space-between',
    },
    [theme.breakpoints.up(breakpoints.MOBILE)]: {
      marginLeft: '3%',
    },
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    maxWidth: 400,
    width: '100%',
  },
  summaryDetailsContainerUneditable: {
    justifyContent: 'space-around',
    alignItems: 'center',
    flexDirection: 'row',
    display: 'flex',
    backgroundColor: colors.white,
    [theme.breakpoints.up(breakpoints.MOBILE)]: {
      height: '80px',
    },
    [theme.breakpoints.down(breakpoints.MOBILE)]: {
      flexDirection: 'column',
      padding: '20px',
    },
  },
});

MeetingSummaryUneditable.defaultProps = {
  errorMessage: null,
};
MeetingSummaryUneditable.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  data: PropTypes.shape({}).isRequired,
  profile: PropTypes.shape({}).isRequired,
  bookingValues: PropTypes.shape({}).isRequired,
  errorMessage: PropTypes.string,
  t: PropTypes.func.isRequired,
};
export default withTranslation()(withStyles(styles)(MeetingSummaryUneditable));
