import { createSelector } from 'reselect';
import Lodash from 'lodash';

import {
  getGroupingsById,
  getGroupingsAllIds,
} from '../../../Redux/Common/Groupings/selectors';
import { compareGroupings } from '../utils';

export const getGroupManagement = state =>
  Lodash.get(state, ['groupManagement'], {});

export const getSelectedLocation = createSelector(
  getGroupManagement,
  groupManagment => Lodash.get(groupManagment, ['selectedLocation'], {})
);
export const getAccounts = createSelector(
  getGroupManagement,
  groupManagment => {
    return Lodash.get(groupManagment, ['gars'], []);
  }
);

export const getQueriedLocations = createSelector(
  getGroupManagement,
  groupManagment => {
    return Lodash.get(groupManagment, ['queriedLocations'], []);
  }
);

export const getIsLoading = createSelector(
  getGroupManagement,
  groupManagment => {
    return Lodash.get(groupManagment, ['isLoading'], false);
  }
);

export const getGroupingsForSelectedGroupManagementLocation = createSelector(
  getGroupingsById,
  getGroupingsAllIds,
  getSelectedLocation,
  (groupingsById, groupingsAllIds, location) => {
    const { sfId } = location;
    const completeFilteredGroupings = groupingsAllIds.reduce((acc, id) => {
      const g = groupingsById[id];
      if (g.locationSfId === sfId) {
        acc.push(g);
      }
      return acc;
    }, []);
    return completeFilteredGroupings.sort(compareGroupings);
  }
);

export const getAccountsForLocationGroupings = createSelector(
  getGroupingsForSelectedGroupManagementLocation,
  getAccounts,
  (locationGroupings, accounts) => {
    const groupingIds = locationGroupings.map(g => g.idGrouping);
    const filteredAccounts = accounts.filter(a => {
      return groupingIds.includes(a?.groupingId);
    });
    return Lodash.sortBy(filteredAccounts, 'account.name');
  }
);
