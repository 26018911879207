/* eslint-disable @typescript-eslint/no-use-before-define */
import * as React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Hidden } from '@material-ui/core';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import clsx from 'clsx';
import Lodash from 'lodash';
import Moment from 'moment';
import ContentLoader from 'react-content-loader';
import Grid from '@material-ui/core/Grid';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';

// Redux
import eventsRedux from '../Events/Redux';
import {
  getOccasionTypesById,
  getOccasionRsvps,
  getOccasionsById,
} from '../Events/Redux/selectors';
import { getCurrentRoomsCalendar } from '../../Redux/Common/Rooms/selectors';
import {
  getUserProfile,
  getUserProfileAccountSfId,
} from '../../Redux/Common/UserManager/selectors';
import locationsRedux from '../../Redux/Common/Locations';
import roomsRedux from '../../Redux/Common/Rooms';
import bookingsRedux from '../../Redux/Common/Bookings';
import userRedux from '../../Redux/Common/UserManager';

import homeRedux from './Redux';
import {
  getLocationDetailsAllIds,
  getLocationDetailsForSelectedLocation,
  getCommunityManagerForSelectedLocation,
  getOccasionsForGrouping,
  getSelectedGrouping,
  getIntegratedGroupingsForHomePage,
  getQueriedGroupings,
} from './Redux/selectors';
import calendarRedux from '../RoomBooking/Redux';
import {
  getBookingRestrictions,
  getTimezone,
  getQueriedDateAndGrouping,
} from '../RoomBooking/Redux/selectors';

import {
  getLocations,
  getLocationsById,
} from '../../Redux/Common/Locations/selectors';
import { getUserDefaultGrouping } from '../../Redux/Common/Groupings/selectors';

// Utils
import { loadDataForGrouping } from './utils';

import { breakpoints, colors } from '../../Core/Theme';
import { date as DateUtils, getIsMobile } from '../../Core/Utils';

// Components
import {
  InfoBanner,
  GroupingLocationList,
  SelectInput,
} from '../../Components/Common';
import CommunityManager from './Components/CommunityManager';
import Greeting from './Components/Greeting';
import Partnerships from './Components/Partnerships';
import UpcomingEvents from './Components/UpcomingEvents';
import { Schedule, ProfessionalMailingAdrTile } from '../../Components';

// Constants
import { EVENT_FETCH_LIMIT, RSVPS_ACTIONS } from './constants';
import { createCommunityManagerFromContact } from './Models/CommunityManager';
import { Partnerships as PartnershipData } from './Models/Partnership';

// Api
import { trackViewEventsFromCard } from '../../Core/Tracking';

// Access
import {
  officesRoleAccess,
  getIsVirtualMember,
  getHasGlobalNetworkAddon,
  getIsAccessMember,
} from '../../Core/Utils/userPermissions';
import { getIsIntegrated } from '../../Models/groupings';
import EventDrawerView from '../Events/Components/EventDrawerView/EventDrawerView';
import ReserveOptions from './Components/ReserveOptions';

const closeDrawerWrapper = setDrawerOpen => () => {
  setDrawerOpen(false);
};
const openDrawerToView = (setDrawerOpen, setSelectedEvent) => event => () => {
  setSelectedEvent(event.idOccasion);
  setDrawerOpen(true);
  trackViewEventsFromCard(event.title);
};

const groupingOptions = locationGroupings => {
  const menuItems = locationGroupings.map(l => {
    return { value: l, label: l.groupingName };
  });
  return Lodash.sortBy(menuItems, 'label');
};

const ACCESS_MEMBERSHIP_LINK = 'https://www.industriousoffice.com/access';

const Home = props => {
  const {
    classes,
    profile,
    loadOccasions,
    loadLocations,
    selectedGrouping,
    setHomePageGrouping,
    occasions,
    occasionTypesById,
    updateRsvpRedux,
    deleteRsvpRedux,
    addOccasionRsvps,
    occasionRsvps,
    history,
    occasionsById,
    setTimezone,
    loadLocationDetails,
    locationDetails,
    locationDetailsAllIds,
    integratedGroupings,
    communityManager,
    listLocations,
    usersDefaultGrouping,
    queriedGroupings,
  } = props;

  const { t } = useTranslation();
  const [isLoading, setIsLoading] = React.useState(true);
  const [isViewingDrawerOpen, setViewingDrawerOpen] = React.useState(false);
  const [selectedEvent, setSelectedEvent] = React.useState({});
  const [scheduleRefreshCount, setScheduleRefreshCount] = React.useState(0);

  const canGetOffices = officesRoleAccess.get({ profile });

  const now = new Moment();

  const hasMoreEventsUpcoming = occasions.length > EVENT_FETCH_LIMIT;

  useEffect(() => {
    const timeZoneId = selectedGrouping?.location?.timeZoneId;
    if (timeZoneId) {
      const oldTz = DateUtils.getCurrentZone();
      DateUtils.setTimezone(timeZoneId);
      setTimezone({ newTz: timeZoneId, oldTz });
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    if (usersDefaultGrouping && !selectedGrouping.idGrouping) {
      loadDataForGrouping(
        loadOccasions,
        loadLocationDetails,
        locationDetailsAllIds,
        queriedGroupings,
        setIsLoading,
        setTimezone,
        profile,
        {
          canGetOffices,
        },
        setHomePageGrouping,
        selectedGrouping
      )(usersDefaultGrouping);
    }
  }, [usersDefaultGrouping]);

  useEffect(() => {
    if (Lodash.isEmpty(listLocations)) {
      loadLocations(false);
    }

    addOccasionRsvps(profile.sfId, Moment().unix(), false, 50, 0, true);
  }, [usersDefaultGrouping]);

  const handleOnRspvsClick = key => async objRsvps => {
    switch (key) {
      case RSVPS_ACTIONS.UPDATE:
        await updateRsvpRedux(objRsvps);
        return setScheduleRefreshCount(scheduleRefreshCount + 1);
      case RSVPS_ACTIONS.DELETE:
        await deleteRsvpRedux(objRsvps);
        return setScheduleRefreshCount(scheduleRefreshCount + 1);
      default:
        return null;
    }
  };

  const userIsVirtual = getIsVirtualMember(profile);
  const hasGlobalNetworkAddon = getHasGlobalNetworkAddon(profile);
  const isAccessMember = getIsAccessMember(profile);
  const showGlobalNetworkAddonBanner = isAccessMember && !hasGlobalNetworkAddon;

  const isMobile = getIsMobile();

  const onViewVirtualBannerCtaClick = () => window.open(ACCESS_MEMBERSHIP_LINK);

  const onGlobalAccessBannerClicked = e => {
    const primaryLocationEmail = profile.account.location.email;
    const subject = t('home.global_access_email.subject', {
      accountName: profile.account.name,
    });
    const body = t('home.global_access_email.body', {
      accountName: profile.account.name,
    });
    window.location.href = `mailto:${primaryLocationEmail}?subject=${subject}&body=${body}`;
    e.preventDefault();
  };

  return (
    <div className={classes.wrapper}>
      <Grid container className={classes.content} spacing={isMobile ? 8 : 24}>
        <Grid item xs={12} md={12}>
          <div
            className={clsx(
              classes.sectionWrapper,
              !userIsVirtual && classes.greetingsAndLocationWrapper
            )}
          >
            <Greeting firstName={profile.firstName} now={now} />
          </div>
        </Grid>
        {userIsVirtual && (
          <Grid item xs={12}>
            <InfoBanner
              text={t('home.virtual_member_banner')}
              actionText={t('home.virtual_member_banner_cta')}
              variant="tertiary"
              onAction={onViewVirtualBannerCtaClick}
              className={classes.virtualMemberBanner}
            />
          </Grid>
        )}
        {showGlobalNetworkAddonBanner && (
          <Grid item xs={12}>
            <InfoBanner
              text={t('home.global_access_banner_start')}
              actionText={t('home.global_access_banner_action')}
              text2={t('home.global_access_banner_end')}
              variant="tertiary"
              onAction={onGlobalAccessBannerClicked}
              className={classes.virtualMemberBanner}
            />
          </Grid>
        )}
        <ReserveOptions history={history} />
        <Grid item xs={12}>
          <div className={classes.listElements}>
            <div className={classes.groupingList}>
              <GroupingLocationList
                onChange={loadDataForGrouping(
                  loadOccasions,
                  loadLocationDetails,
                  locationDetailsAllIds,
                  queriedGroupings,
                  setIsLoading,
                  setTimezone,
                  profile,
                  {
                    canGetOffices,
                  },
                  setHomePageGrouping,
                  selectedGrouping
                )}
                gaCategory="Home Page"
                selectedGrouping={selectedGrouping}
              />
            </div>
            {integratedGroupings?.length > 1 &&
            getIsIntegrated(selectedGrouping) ? (
              <div className={classes.groupingList}>
                <SelectInput
                  options={groupingOptions(integratedGroupings)}
                  value={selectedGrouping}
                  label={t('home.groupings.dropdown.label')}
                  onChange={loadDataForGrouping(
                    loadOccasions,
                    loadLocationDetails,
                    locationDetailsAllIds,
                    queriedGroupings,
                    setIsLoading,
                    setTimezone,
                    profile,
                    {
                      canGetOffices,
                    },
                    setHomePageGrouping,
                    selectedGrouping
                  )}
                  variant="boxedLarge"
                  renderValue={a => Lodash.get(a, 'groupingName', '')}
                />
              </div>
            ) : null}
          </div>
        </Grid>
      </Grid>
      <Grid
        container
        className={clsx(classes.content, classes.gray, classes.topContent)}
        spacing={24}
      >
        <Hidden smDown>
          <Grid item xs={12} md={8}>
            <UpcomingEvents
              occasions={occasions}
              occasionTypesById={occasionTypesById}
              updateRsvpRedux={handleOnRspvsClick(RSVPS_ACTIONS.UPDATE)}
              deleteRsvpRedux={handleOnRspvsClick(RSVPS_ACTIONS.DELETE)}
              occasionRsvps={occasionRsvps}
              history={history}
              locationSfId={selectedGrouping?.locationSfId}
              locationName={selectedGrouping?.location?.externalName}
              isLoading={isLoading}
              openDrawerToView={openDrawerToView(
                setViewingDrawerOpen,
                setSelectedEvent
              )}
              hasMoreEventsUpcoming={hasMoreEventsUpcoming}
              userIsVirtual={userIsVirtual}
            />
          </Grid>
        </Hidden>
        <Grid
          item
          xs={12}
          md={4}
          direction="column"
          className={classes.dashboardRightContainer}
        >
          <div className={classes.communityManagerWrapper}>
            {locationDetails ? (
              <CommunityManager
                communityManager={createCommunityManagerFromContact(
                  communityManager.contact
                )}
                slackUrl={locationDetails.slackInvitationUrl}
              />
            ) : (
              <ContentLoader
                backgroundColor="#f3f3f3"
                foregroundColor="#ecebeb"
              >
                <rect x="48" y="8" rx="3" ry="3" width="88" height="6" />
                <rect x="48" y="26" rx="3" ry="3" width="52" height="6" />
                <rect x="0" y="56" rx="3" ry="3" width="410" height="6" />
                <rect x="0" y="72" rx="3" ry="3" width="380" height="6" />
                <rect x="0" y="88" rx="3" ry="3" width="178" height="6" />
                <circle cx="21" cy="22" r="20" />
              </ContentLoader>
            )}
          </div>
          <div className={classes.scheduleContainer}>
            <Schedule
              datatestid="your_schedule"
              contentClassName={classes.scheduleContent}
              history={history}
              emptyDescriptionKey="Schedule.empty_description"
              userSfId={profile.sfId}
              refreshCount={scheduleRefreshCount}
            />
          </div>
          <Hidden mdUp>
            <div className={classes.thirdsSectionWrapper}>
              <UpcomingEvents
                occasions={occasions.slice(0, EVENT_FETCH_LIMIT)}
                occasionTypesById={occasionTypesById}
                updateRsvpRedux={handleOnRspvsClick(RSVPS_ACTIONS.UPDATE)}
                deleteRsvpRedux={handleOnRspvsClick(RSVPS_ACTIONS.DELETE)}
                occasionRsvps={occasionRsvps}
                history={history}
                locationName={selectedGrouping?.location?.externalName}
                locationSfId={selectedGrouping.locationSfId}
                isLoading={isLoading}
                openDrawerToView={openDrawerToView(
                  setViewingDrawerOpen,
                  setSelectedEvent
                )}
                hasMoreEventsUpcoming={hasMoreEventsUpcoming}
                userIsVirtual={userIsVirtual}
              />
            </div>
          </Hidden>
          {userIsVirtual && (
            <ProfessionalMailingAdrTile
              className={classes.professionalMailingAdrTile}
            />
          )}
        </Grid>
      </Grid>
      <Grid
        container
        justify="center"
        className={clsx(classes.contentGray, classes.gray)}
      >
        <Grid
          item
          xs={12}
          md={12}
          className={clsx(classes.nonFullWidthContents, classes.sectionWrapper)}
        >
          <Partnerships partnerships={PartnershipData} />
        </Grid>
      </Grid>
      <EventDrawerView
        isOpen={isViewingDrawerOpen}
        closeDrawer={closeDrawerWrapper(setViewingDrawerOpen)}
        selectedEvent={occasionsById[selectedEvent]}
        onSuccess={() => setScheduleRefreshCount(scheduleRefreshCount + 1)}
      />
    </div>
  );
};

const consts = {
  desktop: {
    bodyMargins: 40,
  },
  mobile: {
    bodyMargins: 20,
  },
};

const styles = theme => ({
  sectionWrapper: {
    [theme.breakpoints.up(breakpoints.MOBILE)]: {
      paddingTop: 50,
    },
    [theme.breakpoints.down(breakpoints.MOBILE)]: {
      paddingTop: 30,
    },
  },
  nonFullWidthContents: {
    paddingBottom: 20,
    [theme.breakpoints.up(breakpoints.MOBILE)]: {
      marginLeft: consts.desktop.bodyMargins,
      marginRight: consts.desktop.bodyMargins,
    },
    [theme.breakpoints.down(breakpoints.MOBILE)]: {
      marginLeft: consts.mobile.bodyMargins,
      marginRight: consts.mobile.bodyMargins,
    },
  },
  fullWidthMobile: {
    [theme.breakpoints.down(breakpoints.MOBILE)]: {
      marginLeft: 0,
      marginRight: 0,
    },
  },

  thirdsSectionWrapper: {
    [theme.breakpoints.up(breakpoints.MOBILE)]: {
      marginTop: 20,
    },
    [theme.breakpoints.down(breakpoints.MOBILE)]: {
      marginTop: 30,
    },
  },
  greetingsAndLocationWrapper: {
    marginBottom: 15,
  },
  communityManagerWrapper: {
    [theme.breakpoints.down(breakpoints.MOBILE)]: {
      marginTop: 20,
    },
  },
  listElements: {
    marginTop: 10,
    marginBottom: 30,
    [theme.breakpoints.up(breakpoints.MOBILE)]: {
      display: 'flex',
      flexDirection: 'row',
    },
  },
  groupingList: {
    [theme.breakpoints.up(breakpoints.MOBILE)]: {
      width: 300,
      marginRight: 20,
    },
    [theme.breakpoints.down(breakpoints.MOBILE)]: {
      marginTop: 25,
    },
  },
  informationBoxContainer: {
    marginTop: 20,
  },
  content: {
    paddingLeft: consts.desktop.bodyMargins,
    paddingRight: consts.desktop.bodyMargins,
    [theme.breakpoints.down(breakpoints.MOBILE)]: {
      paddingLeft: consts.mobile.bodyMargins,
      paddingRight: consts.mobile.bodyMargins,
    },
  },
  dashboardRightContainer: {
    display: 'flex',
  },
  scheduleContainer: {
    marginTop: 20,
  },
  professionalMailingAdrTile: {
    marginTop: 20,
  },
  wrapper: {
    overflowX: 'hidden',
    overflowY: 'hidden',
  },
  navButtonImage: {
    paddingRight: 20,
    height: '55%',
    marginTop: -5,
    verticalAlign: 'middle',
    [theme.breakpoints.up(breakpoints.MOBILE)]: {
      height: '33%',
    },
  },
  navButtonContent: {
    position: 'absolute',
    height: '100%',
    paddingTop: 25,
    [theme.breakpoints.up(breakpoints.MOBILE)]: {
      paddingTop: 65,
    },
  },
  reserveRoomButton: {
    [theme.breakpoints.down(breakpoints.MOBILE)]: {
      marginBottom: -20,
    },
  },
  gray: {
    background: colors.palette.secondary2.main,
  },
  topContent: {
    paddingTop: 10,
  },
  scheduleContent: {
    height: 250,
    overflow: 'scroll',
  },
  virtualMemberBanner: {
    width: 'auto',
  },
});

Home.defaultProps = {
  communityManager: {},
};

Home.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  profile: PropTypes.object.isRequired,
  loadOccasions: PropTypes.func.isRequired,
  listLocations: PropTypes.arrayOf(PropTypes.object).isRequired,
  loadLocations: PropTypes.func.isRequired,
  selectedGrouping: PropTypes.object.isRequired,
  setHomePageGrouping: PropTypes.func.isRequired,
  occasions: PropTypes.arrayOf(PropTypes.object).isRequired,
  occasionTypesById: PropTypes.object.isRequired,
  updateRsvpRedux: PropTypes.func.isRequired,
  deleteRsvpRedux: PropTypes.func.isRequired,
  occasionRsvps: PropTypes.arrayOf(PropTypes.object).isRequired,
  history: PropTypes.object.isRequired,
  addOccasionRsvps: PropTypes.func.isRequired,
  occasionsById: PropTypes.object.isRequired,
  integratedGroupings: PropTypes.arrayOf(PropTypes.object).isRequired,
  setTimezone: PropTypes.func.isRequired,
  loadLocationDetails: PropTypes.func.isRequired,
  locationDetails: PropTypes.object.isRequired,
  locationDetailsAllIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  communityManager: PropTypes.object,
  queriedGroupings: PropTypes.arrayOf(PropTypes.string).isRequired,
  usersDefaultGrouping: PropTypes.object.isRequired,
};

const mapStateToProps = (state, ownProps) => {
  return {
    profile: getUserProfile(state),
    selectedGrouping: getSelectedGrouping(state),
    queriedGroupings: getQueriedGroupings(state),
    occasions: getOccasionsForGrouping(state),
    occasionTypesById: getOccasionTypesById(state),
    occasionRsvps: getOccasionRsvps(state),
    occasionsById: getOccasionsById(state),
    bookingRestrictions: getBookingRestrictions(state),
    rooms: getCurrentRoomsCalendar(state),
    userAccountSfId: getUserProfileAccountSfId(state),
    integratedGroupings: getIntegratedGroupingsForHomePage(state),
    tz: getTimezone(state),
    locationDetails: getLocationDetailsForSelectedLocation(state),
    locationDetailsAllIds: getLocationDetailsAllIds(state),
    communityManager: getCommunityManagerForSelectedLocation(state),
    listLocations: getLocations(state, ownProps),
    queriedDatesAndGroupings: getQueriedDateAndGrouping(state),
    locationsById: getLocationsById(state),
    usersDefaultGrouping: getUserDefaultGrouping(state),
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      loadOccasions: eventsRedux.actions.addOccasions,
      loadLocations: locationsRedux.actions.addLocations,
      loadAmenities: calendarRedux.actions.addAmenities,
      loadRooms: roomsRedux.actions.addRooms,
      loadBookings: bookingsRedux.actions.addBookings,
      setHomePageGrouping: homeRedux.actions.setGrouping,
      updateRsvpRedux: eventsRedux.actions.updateRsvp,
      deleteRsvpRedux: eventsRedux.actions.deleteRsvp,
      addOccasionRsvps: eventsRedux.actions.addOccasionRsvps,
      loadBookingRestriction: calendarRedux.actions.addBookingRestriction,
      setTimezone: userRedux.actions.setTimeZone,
      loadQueriedDateAndLocation:
        calendarRedux.actions.addQueriedDateAndLocation,
      loadLocationDetails: homeRedux.actions.addLocationDetails,
      setLocationCalendar: calendarRedux.actions.setLocation,
      addFloors: roomsRedux.actions.addFloors,
      setGrouping: calendarRedux.actions.setGrouping,
      addQueriedDateAndGrouping: calendarRedux.actions.addQueriedDateGrouping,
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(Home));
