import { schema, normalize } from 'normalizr';
import Lodash from 'lodash';

export function NormObjCstr(byId, allIds) {
  this.byId = byId;
  this.allIds = allIds;
}

const floorSchema = new schema.Entity(
  'floor',
  {},
  {
    idAttribute: 'idFloor',
  }
);

const floorArraySchema = [floorSchema];

export const normalizeFloors = data => {
  const temp = Array.isArray(data)
    ? normalize(data, floorArraySchema)
    : normalize(data, floorSchema);

  if (Lodash.isEmpty(temp.entities)) {
    return {
      floors: new NormObjCstr({}, []),
    };
  }
  return {
    floors: new NormObjCstr(temp.entities.floor, temp.result),
  };
};
