import typeToReducer from 'type-to-reducer';
import Lodash from 'lodash';

import * as types from './actionTypes';

const initialState = {
  selectedGrouping: {},
};

export const setSelectedGrouping = (state, action) => {
  const selectedGrouping = Lodash.get(action, ['payload'], {});
  return {
    ...state,
    selectedGrouping,
  };
};

const reducer = typeToReducer(
  {
    [types.SET_GROUPING]: setSelectedGrouping,
  },
  initialState
);

export default reducer;
