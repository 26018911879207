import queryString from 'query-string';

export const makeNamePossessive = (name: string) => {
  const lastCharacter = name.charAt(name.length - 1);
  if (lastCharacter.toLowerCase() === 's') {
    return `${name}'`;
  }
  return `${name}'s`;
};

export const isValidUrl = (url: string) => {
  try {
    // eslint-disable-next-line no-new
    new URL(url);
  } catch (_) {
    return false;
  }
  return true;
};

export const stringifyParams = (params: {
  queryParams: Record<string, any>;
  options?: queryString.StringifyOptions;
}): string => {
  return queryString.stringify(params.queryParams, params.options);
};

export const formatCurrency = (props: {
  value: number;
  currency: string;
  lang?: string;
  maximumFractionDigits?: number;
  minimumFractionDigits?: number;
}) => {
  const {
    value,
    currency,
    lang = 'en',
    maximumFractionDigits = 0,
    minimumFractionDigits = 0,
  } = props;
  return Intl.NumberFormat(lang, {
    style: 'currency',
    currency,
    maximumFractionDigits,
    minimumFractionDigits,
  }).format(value);
};

export const getCurrencySymbol = (currencyIsoCode: string): string => {
  return formatCurrency({
    value: 1,
    currency: currencyIsoCode,
    maximumFractionDigits: 0,
  }).replaceAll('1', '');
};

export const upperFirst = (str: string): string => {
  return str ? str.charAt(0).toUpperCase() + str.slice(1) : '';
};
