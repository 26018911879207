import React, { useState, useEffect, useMemo } from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

// Core + constants + utils
import { colors, breakpoints } from '../../Core/Theme';
import { Icons } from '../../resources';
import { date as DateUtils, getIsMobile } from '../../Core/Utils';
import { fetchAnyReservations } from '../../Core/Api';
import {
  trackMyScheduleEventClick,
  trackMyScheduleReservationClick,
} from '../../Core/Tracking';

// Components
import SeatReservationSummary from '../SeatReservationSummary/SeatReservationSummary';
import ReserveDeskWithApi from '../ReserveDeskWithApi';
import SmartMeetingRoomBooking from '../SmartMeetingRoomBooking/SmartMeetingRoomBooking';
import EventDrawerView from '../../Containers/Events/Components/EventDrawerView';
import { ScheduleContent } from './Components';
import { Text, Loading, CollapseHeader, TextButton } from '../Common';

// Redux
import { getUserProfile } from '../../Redux/Common/UserManager/selectors';
import { getLocationsById } from '../../Redux/Common/Locations/selectors';
import { AnyReservation } from '../../Models';
import { RESERVATION_TYPES } from '../../Models/anyReservation';
import OfficeReservationSummary from '../OfficeReservationSummary/OfficeReservationSummary';

const SCHEDULE_LIMIT = 15;

const Schedule = ({
  classes,
  variant,
  titleKey,
  emptyDescriptionKey,
  emptyTitleKey,
  withCollapse,
  withAction,
  actionText,
  onAction,
  datatestid,
  contentClassName,
  history,
  reservationTypes,
  userSfId,
  locationSfId,
  startEpoch,
  profile,
  locationsById,
  refreshCount,
}) => {
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState(true);
  const [reservations, setReservations] = useState([]);
  const [conferenceBooking, setConferenceBooking] = React.useState(null);
  const [deskReservation, setDeskReservation] = React.useState(null);
  const [seatBooking, setSeatBooking] = React.useState(null);
  const [rsvp, setRsvp] = React.useState(null);
  const [officeReservation, setOfficeReservation] = React.useState(null);

  const isDetailed = variant === 'detailed';
  const isMobile = getIsMobile();

  const fetchData = () => {
    setIsLoading(true);
    fetchAnyReservations({
      limit: SCHEDULE_LIMIT,
      offset: 0,
      startEpoch,
      userSfId,
      locationSfId,
      reservationTypes,
      includeOngoing: true,
    }).then(objResponse => {
      const { data } = objResponse;
      if (!data) {
        return; // handle error with catch all error state
      }
      const { reservations: newReservations } = data;
      setReservations(newReservations);
      setIsLoading(false);
    });
  };
  useEffect(() => {
    if (userSfId || locationSfId) {
      fetchData();
    }
  }, [refreshCount, userSfId, locationSfId]);

  const reservationsByDay = useMemo(() => {
    return reservations.reduce((acc, r) => {
      const timeZoneId = AnyReservation.getReservationTimezoneId(r);
      const reservationStart = AnyReservation.getReservationStartEpoch(r);
      const key = DateUtils.getDateInMomentWithZone(
        reservationStart,
        timeZoneId
      ).format('l');
      if (acc[key]) {
        acc[key].push(r);
        return acc;
      }
      acc[key] = [r];
      return acc;
    }, {});
  }, [reservations]);

  const handleOnBookingClick = booking => {
    trackMyScheduleReservationClick();
    setConferenceBooking(booking);
  };

  const handleDeskReservationClick = reservation => {
    return setDeskReservation(reservation);
  };

  const handleOnEventClick = clickedRsvp => {
    trackMyScheduleEventClick();
    setRsvp(clickedRsvp);
  };
  const handleOnSeatBookingClick = booking => {
    setSeatBooking(booking);
  };

  const handleOnScheduleEventClick = objSchedule => {
    const { type, reservation } = objSchedule;
    switch (type) {
      case RESERVATION_TYPES.RSVP:
        return handleOnEventClick(reservation);
      case RESERVATION_TYPES.CONFERENCE_BOOKING:
        return handleOnBookingClick(reservation);
      case RESERVATION_TYPES.DESK_RESERVATION:
        return handleDeskReservationClick(reservation);
      case RESERVATION_TYPES.SEAT_BOOKING:
        return handleOnSeatBookingClick(reservation);
      case RESERVATION_TYPES.OFFICE_RESERVATION:
        return setOfficeReservation(reservation);

      default:
        return null;
    }
  };

  return (
    <>
      <CollapseHeader
        title={t(titleKey).toUpperCase()}
        className={clsx(
          classes.container,
          isDetailed && classes.detailedContainer
        )}
        disabled={!withCollapse || !isMobile}
        titleClassName={clsx(
          classes.title,
          isDetailed && classes.detailedTitle
        )}
        datatestid={`${datatestid}_title`}
      >
        <div className={clsx(classes.content, contentClassName)}>
          {!isLoading && !reservations.length ? (
            <div
              className={classes.emptyStateContainer}
              datatestid={`${datatestid}_empty_state`}
            >
              <Icons.NoSchedule
                style={
                  isDetailed ? { height: 155, width: 135, paddingTop: 30 } : {}
                }
              />
              <Text
                text={t(emptyTitleKey)}
                className={clsx(
                  classes.emptyStateTitle,
                  isDetailed && classes.emptyStateTitleDetailed
                )}
              />
              {emptyDescriptionKey && (
                <Text
                  text={t(emptyDescriptionKey)}
                  className={classes.emptyStateDescription}
                />
              )}
            </div>
          ) : null}
          {isLoading ? (
            <div className={classes.loading}>
              <Loading color={colors.black} size={50} />
            </div>
          ) : null}
          {!isLoading && reservations.length ? (
            <div className={classes.scheduleListContainer}>
              {Object.keys(reservationsByDay).map(date => {
                return (
                  <ScheduleContent
                    date={date}
                    reservations={reservationsByDay[date]}
                    onClick={handleOnScheduleEventClick}
                    isDetailed={isDetailed}
                    datatestid={`${datatestid}_day_${date}`}
                  />
                );
              })}
            </div>
          ) : null}
        </div>
        {withAction && !isLoading && (
          <TextButton
            text={actionText}
            className={classes.actionButton}
            onClick={onAction}
            datatestid={`${datatestid}_action_button`}
          />
        )}
      </CollapseHeader>
      <SmartMeetingRoomBooking
        isOpen={!!conferenceBooking}
        onClose={() => {
          setConferenceBooking(null);
        }}
        objBooking={conferenceBooking}
        onSuccess={fetchData}
      />
      <ReserveDeskWithApi
        isOpen={!!deskReservation}
        onClose={() => setDeskReservation(null)}
        reservations={[deskReservation]}
        isCancel
        history={history}
        onSubmit={fetchData}
      />
      <EventDrawerView
        isOpen={!!rsvp}
        closeDrawer={() => setRsvp(null)}
        selectedEvent={rsvp?.occasion}
        onSuccess={fetchData}
      />
      <SeatReservationSummary
        isOpen={!!seatBooking}
        onClose={() => setSeatBooking(null)}
        reservation={seatBooking}
        profile={profile}
        locationsById={locationsById}
        setSelectedReservation={setSeatBooking}
        onSeatReservationUpdated={fetchData}
      />
      <OfficeReservationSummary
        profile={profile}
        onClose={() => setOfficeReservation(null)}
        reservation={officeReservation}
        onSuccess={fetchData}
      />
    </>
  );
};

const styles = theme => ({
  container: {
    border: `1px solid ${colors.middle}`,
    borderRadius: '2px',
    boxShadow: '0 0 4px 0 rgba(0, 0, 0, 0.1)',
    background: 'white',
  },
  detailedContainer: {
    border: 'none',
    height: '100%',
  },
  header: {
    borderBottom: `1px solid ${colors.middle}`,
    padding: 16,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  detailedHeader: {
    borderColor: colors.light,
  },
  title: {
    fontFamily: 'VerlagBold',
    fontSize: 16,
    letterSpacing: 0.53,
    lineHeight: 1,
    [theme.breakpoints.up(breakpoints.MD)]: {
      fontSize: 18,
      letterSpacing: 0.6,
    },
  },
  detailedTitle: {
    fontSize: 14,
  },
  content: {
    padding: 10,
    paddingLeft: 15,
    paddingRight: 15,
    paddingBottom: 0,
    paddingTop: 7,
  },
  emptyStateContainer: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    textAlign: 'center',
    justifyContent: 'center',
    alignItems: 'center',
  },
  emptyStateTitle: {
    fontFamily: 'VerlagBold',
    fontSize: '16px',
    lineHeight: '16px',
    paddingBottom: '6px',
    paddingTop: '10px',
  },
  emptyStateDescription: {
    fontFamily: 'VerlagLight',
    fontSize: '14px',
    lineHeight: '14px',
  },
  scheduleListContainer: {
    paddingTop: 8,
    overflowY: 'scroll',
    '&::-webkit-scrollbar': {
      width: 0 /* Remove scrollbar space */,
      background: 'transparent' /* Optional: just make scrollbar invisible */,
    },
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
  },
  loading: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    height: '100%',
  },
  actionButton: {
    width: '100%',
    textAlign: 'center',
    paddingTop: 7,
  },
  emptyStateTitleDetailed: {
    fontSize: 24,
    paddingTop: 50,
    paddingBottom: 30,
    lineHeight: 1,
  },
});

Schedule.defaultProps = {
  variant: 'home',
  titleKey: 'Schedule.title',
  emptyTitleKey: 'Schedule.empty_title',
  emptyDescriptionKey: null,
  withCollapse: false,
  withAction: false,
  actionText: '',
  onAction: () => {},
  datatestid: 'schedule',
  contentClassName: null,
  reservationTypes: [
    RESERVATION_TYPES.SEAT_BOOKING,
    RESERVATION_TYPES.DESK_RESERVATION,
    RESERVATION_TYPES.CONFERENCE_BOOKING,
    RESERVATION_TYPES.RSVP,
    RESERVATION_TYPES.OFFICE_RESERVATION,
  ],
  locationSfId: null,
  userSfId: null,
  startEpoch: DateUtils.getDateInMoment().unix(),
  refreshCount: 0,
};

Schedule.propTypes = {
  variant: PropTypes.string,
  titleKey: PropTypes.string,
  emptyTitleKey: PropTypes.string,
  emptyDescriptionKey: PropTypes.string,
  withCollapse: PropTypes.bool,
  withAction: PropTypes.bool,
  actionText: PropTypes.string,
  onAction: PropTypes.func,
  datatestid: PropTypes.string,
  contentClassName: PropTypes.string,
  history: PropTypes.object.isRequired,
  reservationTypes: PropTypes.arrayOf(PropTypes.string),
  locationSfId: PropTypes.string,
  userSfId: PropTypes.string,
  startEpoch: PropTypes.number,
  profile: PropTypes.object.isRequired,
  locationsById: PropTypes.object.isRequired,
  refreshCount: PropTypes.number,
};

const mapStateToProps = state => {
  return {
    profile: getUserProfile(state),
    locationsById: getLocationsById(state),
  };
};

export default connect(
  mapStateToProps,
  undefined
)(withStyles(styles)(Schedule));
