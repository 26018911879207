/* eslint-disable react/prop-types */
import { useMemo } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Lodash from 'lodash';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import clsx from 'clsx';
import Hidden from '@material-ui/core/Hidden';
import PropTypes from 'prop-types';
import MemoizeOne from 'memoize-one';
import InfiniteScroll from 'react-infinite-scroller';

import Table from '../Table';
import ExpansionPanel from '../ExpansionPanel';
import { colors, breakpoints } from '../../../Core/Theme';
import { OptionsColumn, LinkColumn } from './Components';
import SkeletonLoader from '../SkeletonLoader';
import Loading from '../Loading';
import { THRESHOLD_INFINITE_SCROLL } from '../../../Core/constants';

const StyledTableCell = withStyles(() => ({
  body: {
    padding: 0,
    fontSize: '15px',
  },
}))(TableCell);

const getDataFilteredBySearchValue = MemoizeOne(
  (searchTerm, listSearchFields, listData) => {
    if (!listSearchFields.length) {
      return listData;
    }
    const strSearchTerm = searchTerm ? Lodash.toLower(searchTerm) : null;
    const strSearchTermTrimed = Lodash.trim(strSearchTerm);
    if (Lodash.isEmpty(searchTerm)) {
      return listData;
    }
    return listData.filter(objData => {
      return listSearchFields.some(varSearchField => {
        const strValue = Lodash.isFunction(varSearchField)
          ? varSearchField(objData)
          : Lodash.get(objData, varSearchField, null);
        const strValueInLowerCase = Lodash.toLower(strValue);

        return (
          strValue &&
          Lodash.trim(strValueInLowerCase).includes(strSearchTermTrimed)
        );
      });
    });
  }
);

const CUSTOM_COLUMNS = {
  LINK: props => <LinkColumn {...props} />,
  OPTIONS: props => <OptionsColumn {...props} />,
};

const BaseSmartTable = props => {
  const {
    classes,
    filterParams,
    onActionPress,
    emptyMessage,
    searchFields,
    columns,
    className,
    searchValue,
    data,
    withInnerPadding,
    onFilterPress,
    withAccordion,
    classNameMobileContainer,
    isLoading,
    onExpansionStateChange,
    fullWidthColumns,
    loadMore,
    hasMore,
    isLoadingMore,
    numLoadingRows,
    renderCustomTopContent,
    cellClassName,
    closedAccordionDisplayColumnIndices,
    initialLoad,
  } = props;

  const loadingRows = useMemo(() => {
    return [...Array(numLoadingRows)].map((_, i) => i);
  }, [numLoadingRows]);

  const renderCustomField = ({ type, row, column }) => {
    if (isLoading) {
      return (
        <SkeletonLoader
          className={clsx(
            classes.paragraphStyleText,
            classes.loader,
            fullWidthColumns && classes.fullWidthColumn
          )}
        />
      );
    }
    const renderCell = Lodash.get(column, ['render'], null);
    const options = Lodash.get(column, ['options'], null);
    const columnKey = Lodash.get(column, ['key'], null);
    const dataRow = Lodash.isFunction(columnKey)
      ? columnKey({ data: row })
      : Lodash.get(row, columnKey, null);

    const fnCellComponent = type ? CUSTOM_COLUMNS[type.toUpperCase()] : null;
    if (Lodash.isFunction(fnCellComponent)) {
      return fnCellComponent({
        onActionPress,
        data: row,
        label: dataRow,
        options,
        type,
      });
    }

    return renderCell ? (
      renderCell({ column, data: dataRow })
    ) : (
      <p
        className={clsx(
          classes.paragraphStyleText,
          fullWidthColumns && classes.fullWidthColumn
        )}
      >
        {dataRow}
      </p>
    );
  };

  const renderSmartRow = listRows => {
    return (
      listRows &&
      listRows.map((objRow, rowIndex) => {
        const isOddRow = rowIndex % 2 === 1;

        return (
          <TableRow
            className={clsx(classes.row, isOddRow && classes.coloredRow)}
          >
            {columns.map((objColumn, index) => {
              const isFirstColumn = index === 0;
              const isLastColumn = index === columns.length - 1;
              return (
                <StyledTableCell
                  variant="body"
                  className={clsx(
                    classes.cell,
                    isFirstColumn &&
                      withInnerPadding &&
                      classes.innerPaddingLeft,
                    isLastColumn &&
                      withInnerPadding &&
                      classes.innerPaddingRight,
                    cellClassName
                  )}
                >
                  {renderCustomField({
                    type: Lodash.get(objColumn, ['type'], null),
                    row: objRow,
                    column: objColumn,
                  })}
                </StyledTableCell>
              );
            })}
          </TableRow>
        );
      })
    );
  };

  const renderSmartAccordionSection = ({ type, row, column, index }) => {
    const columnLabel = Lodash.get(column, ['label'], null);
    return (
      <div className={classes.sectionCard}>
        <div className={classes.titleSection}>
          {Lodash.toUpper(columnLabel)}
        </div>
        {renderCustomField({ type, row, column, index })}
      </div>
    );
  };

  const renderSmartMobileTableSection = ({ type, row, column, index }) => {
    const columnLabel = Lodash.get(column, ['label'], null);
    const isLastAndOptions = type === 'options' && index === columns.length - 1;
    return (
      <div
        className={clsx(
          classes.sectionMobileTable,
          isLastAndOptions && classes.lastSectionMobileTable
        )}
      >
        {columnLabel && (
          <p className={classes.titleSection}>{Lodash.toUpper(columnLabel)}</p>
        )}
        {renderCustomField({ type, row, column, index })}
      </div>
    );
  };

  const renderMobileTableCard = () => {
    const dataFiltered = isLoading
      ? loadingRows
      : getDataFilteredBySearchValue(searchValue, searchFields, data);
    return dataFiltered.length
      ? dataFiltered.map((objData, rowIndex) => {
          const isOddRow = rowIndex % 2 === 1;
          return (
            <div
              className={clsx(
                classes.mainMobileTable,
                isOddRow && classes.coloredRow
              )}
            >
              <div className={classes.bodyMobileTable}>
                {columns.map((objColumn, index) =>
                  renderSmartMobileTableSection({
                    type: Lodash.get(objColumn, ['type'], null),
                    row: objData,
                    column: objColumn,
                    index,
                  })
                )}
              </div>
            </div>
          );
        })
      : emptyMessage;
  };

  const renderMobileAccordionCards = () => {
    const dataFiltered = isLoading
      ? loadingRows
      : getDataFilteredBySearchValue(searchValue, searchFields, data);
    const strFirstColumnType = Lodash.get(columns, [0, 'type'], null);
    const columnsInExpandedAccordion = Lodash.filter(
      columns,
      (objCol, idx) => !closedAccordionDisplayColumnIndices.includes(idx)
    );
    return dataFiltered.map(objData => {
      return (
        <ExpansionPanel
          classNamePanel={classes.expansionPanel}
          rootClass={classes.expansionPanelRoot}
          classNameTitle={classes.expansionTitle}
          expandedClassName={classes.expandedClassName}
          renderSummarySubtitle={() =>
            closedAccordionDisplayColumnIndices.map(idx => {
              const { label } = columns[idx];
              return (
                <div className={clsx([classes.sectionCard])}>
                  {label ? (
                    <div className={clsx([classes.titleSection])}>
                      {label.toUpperCase()}
                    </div>
                  ) : null}
                  <div>
                    {renderCustomField({
                      type: strFirstColumnType,
                      row: objData,
                      column: Lodash.get(columns, [idx], {}),
                    })}
                  </div>
                </div>
              );
            })
          }
          callout={objData.callout}
          defaultExpanded={false}
          withLeftIcon
          onChange={onExpansionStateChange}
        >
          {columnsInExpandedAccordion.map((objColumn, index) =>
            renderSmartAccordionSection({
              type: Lodash.get(objColumn, ['type'], null),
              row: objData,
              column: objColumn,
              index,
            })
          )}
        </ExpansionPanel>
      );
    });
  };

  return (
    <div className={clsx(classes.rootContainer, className)}>
      <InfiniteScroll
        loadMore={loadMore}
        hasMore={hasMore && !isLoadingMore}
        threshold={THRESHOLD_INFINITE_SCROLL}
        useWindow={false}
        initialLoad={initialLoad}
      >
        {renderCustomTopContent?.()}
        <Hidden smDown implementation="css">
          <Table
            filtersParams={filterParams}
            className={classes.tableStyle}
            renderRow={renderSmartRow}
            rows={
              isLoading
                ? loadingRows
                : getDataFilteredBySearchValue(searchValue, searchFields, data)
            }
            columns={columns}
            onFilterPress={onFilterPress}
            emptyMessage={emptyMessage}
            withInnerPadding={withInnerPadding}
          />
        </Hidden>
        <Hidden mdUp implementation="css">
          <div className={clsx(classNameMobileContainer)}>
            <div className={classes.stickyHorizon}>
              <hr className={classes.horizon} />
            </div>
            {withAccordion
              ? renderMobileAccordionCards()
              : renderMobileTableCard()}
          </div>
        </Hidden>
        {isLoadingMore && (
          <div className={classes.infiniteLoader}>
            <Loading size={40} />
          </div>
        )}
      </InfiniteScroll>
    </div>
  );
};

BaseSmartTable.defaultProps = {
  filterParams: null,
  className: null,
  classNameMobileContainer: null,
  searchValue: null,
  withInnerPadding: false,
  columns: [],
  fullWidthColumns: false,
  numLoadingRows: 5,
  loadMore: () => null,
  isLoadingMore: false,
  hasMore: false,
  cellClassName: '',
  closedAccordionDisplayColumnIndices: [0],
  searchFields: [],
  renderCustomTopContent: null,
  initialLoad: false,
};

BaseSmartTable.propTypes = {
  filterParams: PropTypes.shape({
    [PropTypes.string]: PropTypes.arrayOf(PropTypes.string),
  }),
  onActionPress: PropTypes.func.isRequired,
  emptyMessage: PropTypes.string.isRequired,
  classNameMobileContainer: PropTypes.string,
  searchFields: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.func])
  ),
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      [PropTypes.string]: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.func,
        PropTypes.arrayOf(
          PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.func,
            PropTypes.object,
          ])
        ),
      ]),
    })
  ),
  className: PropTypes.string,
  searchValue: PropTypes.string,
  withInnerPadding: PropTypes.bool,
  fullWidthColumns: PropTypes.bool,
  numLoadingRows: PropTypes.number,
  isLoadingMore: PropTypes.bool,
  loadMore: PropTypes.bool,
  hasMore: PropTypes.bool,
  cellClassName: PropTypes.string,
  closedAccordionDisplayColumnIndices: PropTypes.number,
  renderCustomTopContent: PropTypes.func,
  initialLoad: PropTypes.bool,
};

const styles = theme => ({
  sectionMobileTable: {
    width: '45%',
    marginRight: 5,
    marginLeft: 5,
  },
  lastSectionMobileTable: {
    marginLeft: 'auto',
    marginTop: 'auto',
  },
  mainMobileTable: {
    padding: 10,
  },
  bodyMobileTable: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginBottom: 14,
  },
  stickyHorizon: {
    position: 'sticky',
    top: '0px',
    zIndex: 9,
  },
  mobileCardContainer: {
    overflowY: 'scroll',
    '&::-webkit-scrollbar': {
      width: 0 /* Remove scrollbar space */,
      background: 'transparent' /* Optional: just make scrollbar invisible */,
    },
  },
  horizon: {
    position: 'sticky',
    top: '0px',
    height: '3px',
    backgroundColor: colors.palette.secondary.main,
    margin: '0px',
    border: 'none',
    width: '100%',
  },
  mobileCardsContainer: {
    borderTop: `2px solid ${colors.palette.secondary.main}`,
  },
  titleSection: {
    marginBottom: 10,
    fontFamily: 'VerlagBold',
    fontSize: 14,
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  expansionPanel: {
    borderBottom: `solid 1px ${colors.middle}`,
  },
  expansionTitle: {
    fontFamily: 'VerlagBold',
    fontSize: 14,
  },
  sectionCard: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: 30,
  },
  expansionPanelRoot: {
    display: 'flex',
    flexDirection: 'column',
  },
  row: { paddingTop: '10px' },
  cell: {
    verticalAlign: 'baseline',
    borderBottom: 'none',
  },
  innerPaddingLeft: {
    paddingLeft: '20px !important',
  },
  innerPaddingRight: {
    paddingRight: '20px !important',
  },
  paragraphStyleText: {
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    maxWidth: '135px',
    marginTop: 15,
    marginBottom: 15,
    fontFamily: 'VerlagBook',
    fontSize: 15,
    [theme.breakpoints.up(breakpoints.MOBILE)]: {
      fontSize: 16,
    },
  },
  fullWidthColumn: {
    maxWidth: '100%',
    marginRight: 20,
  },
  rootContainer: {
    backgroundColor: colors.white,
    overflow: 'auto',
  },
  tableStyle: {
    borderCollapse: 'unset',
    tableLayout: 'fixed',
    width: '100%',
  },
  loader: {
    height: 15,
    width: '80%',
  },
  coloredRow: {
    backgroundColor: colors.palette.secondary2.light,
  },
  infiniteLoader: {
    textAlign: 'center',
    marginTop: 20,
    marginBottom: 10,
  },
  expansionTitleTopPadding: {
    paddingTop: 20,
  },
  expandedClassName: {
    marginBottom: '0px !important',
  },
});

const SmartTable = objProps => {
  if (objProps.withAccordion) {
    return <BaseSmartTable {...objProps} withAccordion />;
  }

  return <BaseSmartTable {...objProps} />;
};

export default withStyles(styles)(SmartTable);
