import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import Grid from '@material-ui/core/Grid';
import { useTranslation } from 'react-i18next';
import Lodash from 'lodash';
import Moment from 'moment';
import clsx from 'clsx';

import { GroupingFeatureKey } from '@industriousoffice/member-portal-rbac/dist/types';
import { SubmitButton, Text, TextButton } from '../Common';
import { breakpoints, colors } from '../../Core/Theme';
import { date as DateUtils } from '../../Core/Utils';
import FormikInputs from '../FormikInputs';
import { useDayAvailability } from '../../Hooks';
import {
  getNextAvailableDay,
  getSelectedDaysAvailability,
} from '../AvailabilityDayPicker/utils';
import { useGroupings } from '../../Hooks/useGroupings';

const ReserveDeskForm = ({
  onFindDesk,
  classes,
  profile,
  onChangeDate,
  date,
  isButtonDisabled,
  datatestid,
}) => {
  const { t } = useTranslation();
  const [grouping, setGrouping] = React.useState(null);

  const {
    displayGroupings,
    isLoading: isLoadingGroupings,
    defaultGrouping,
  } = useGroupings(GroupingFeatureKey.HotDesk);

  React.useEffect(() => {
    if (!grouping && defaultGrouping) {
      setGrouping(defaultGrouping);
    }
  }, [grouping, defaultGrouping]);

  const locationSfId = grouping?.locationSfId;
  const locationTimezoneId = grouping?.location?.timeZoneId;

  const { isFetching, availabilities, fetchAvailability } = useDayAvailability(
    Moment.unix(date).startOf('month').unix(),
    Moment.unix(date).endOf('month').unix(),
    locationSfId,
    locationTimezoneId,
    grouping
  );

  const availabilitiesTimeZoneId = Lodash.get(
    availabilities,
    [0, 'timezoneId'],
    ''
  );

  const reserveForm = objForm => {
    const { submitForm, values } = objForm;
    const { reserveDate } = values;

    const selectedDaysAvailability = getSelectedDaysAvailability(
      availabilities,
      reserveDate,
      availabilitiesTimeZoneId
    );

    const { nextAvailableDayEpoch, nextAvailableDayCount } =
      getNextAvailableDay(availabilities, reserveDate);

    const goToAvailable = () => {
      onFindDesk({ ...values, reserveDate: nextAvailableDayEpoch });
    };

    const hasError = selectedDaysAvailability === 0;

    return (
      <Grid
        container
        justify="center"
        direction="row"
        className={classes.container}
        spacing={16}
      >
        <Grid item xs={12} md={9} lg={10}>
          <Grid container direction="row" spacing={16}>
            <Grid item xs={12} md={6} className={classes.content}>
              <Text
                text={t('general.location').toUpperCase()}
                className={clsx(
                  classes.labelTitle,
                  hasError && classes.labelError
                )}
                datatestid={`${datatestid}_location_label`}
              />
              <FormikInputs
                input="groupingLocationList"
                name="grouping"
                label={null}
                withBoxDesign
                withoutViewHomeLocation
                onChange={setGrouping}
                showYourLocationSection
                datatestid={`${datatestid}_location`}
                forceShowError={hasError}
                locationGroupings={displayGroupings}
                isLoading={isLoadingGroupings}
                usersDefaultGrouping={defaultGrouping}
              />
            </Grid>
            <Grid item xs={12} md={6} className={classes.content}>
              <Text
                text={t('general.date').toUpperCase()}
                className={classes.labelTitle}
                datatestid={`${datatestid}_date_label`}
              />
              <FormikInputs
                input="availabilityDayPicker"
                name="reserveDate"
                onChange={onChangeDate}
                disablePastDate
                locationSfId={locationSfId}
                profile={profile}
                availabilities={availabilities}
                isLoading={isFetching}
                fetchAvailability={fetchAvailability}
                selectedDaysAvailability={selectedDaysAvailability}
                locationTimezoneId={locationTimezoneId}
                datatestid={`${datatestid}_day_picker`}
                selectedGrouping={grouping}
                hideError
              />
            </Grid>
            {selectedDaysAvailability === 0 && (
              <Grid item xs={12} className={classes.errorDisplay}>
                <div className={classes.error}>
                  {nextAvailableDayEpoch
                    ? t('deskReservation.fully_booked')
                    : t('deskReservation.fully_booked_all_time')}
                </div>
                {nextAvailableDayEpoch && (
                  <TextButton
                    text={t('deskReservation.fully_booked_cta', {
                      date: nextAvailableDayEpoch
                        ? Moment.unix(nextAvailableDayEpoch).format('[MMMM D]')
                        : '',
                      count: nextAvailableDayCount,
                    })}
                    onClick={goToAvailable}
                  />
                )}
              </Grid>
            )}
          </Grid>
        </Grid>
        <Grid item xs={12} md={3} lg={2}>
          <SubmitButton
            defaultText={
              selectedDaysAvailability
                ? t('deskReservation.see_desks', {
                    count: selectedDaysAvailability,
                  })
                : t('reserveDesk.find_desk_cta')
            }
            className={classes.findDeskButton}
            onClick={submitForm}
            isLoading={isFetching}
            disabled={isButtonDisabled || !selectedDaysAvailability}
            datatestid="desks_reserve_see_x_desks_button"
          />
        </Grid>
      </Grid>
    );
  };

  return (
    <Formik
      initialValues={{
        reserveDate: DateUtils.getTodayNotWeekend(),
        grouping: defaultGrouping,
      }}
      enableReinitialize
      render={reserveForm}
      onSubmit={onFindDesk}
    />
  );
};

ReserveDeskForm.propTypes = {
  onFindDesk: PropTypes.func.isRequired,
  classes: PropTypes.string.isRequired,
  profile: PropTypes.object.isRequired,
  onChangeDate: PropTypes.func.isRequired,
  date: PropTypes.number.isRequired,
  isButtonDisabled: PropTypes.bool,
  datatestid: PropTypes.string,
};

ReserveDeskForm.defaultProps = {
  isButtonDisabled: false,
  datatestid: 'desk_reserve_desk_form',
};

const styles = theme => ({
  linkMeetingRoom: {
    fontFamily: 'VerlagBold',
    color: colors.palette.primary.main,
    textDecoration: 'underline',
  },
  multipleLabel: {
    fontSize: 16,
    fontFamily: 'verlagBook',
  },
  multipleContainer: {
    textAlign: 'center',
  },
  divider: {
    width: '100%',
    height: '2px',
    backgroundColor: colors.gray,
    [theme.breakpoints.up(breakpoints.MOBILE)]: {
      marginBottom: 20,
    },
  },
  findDeskButton: {
    [theme.breakpoints.up(breakpoints.MOBILE)]: {
      marginTop: '20px !important',
    },
  },
  content: {
    [theme.breakpoints.up(breakpoints.MOBILE)]: {
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column',
      alignSelf: 'center',
    },
  },
  labelTitle: {
    fontFamily: 'VerlagBold',
    fontSize: 12,
    paddingBottom: 5,
  },
  labelError: {
    color: colors.palette.error.main,
  },
  container: {
    backgroundColor: `rgb(255, 255, 255, 0.9)`,
    width: '100%',
    borderRadius: 4,
    padding: 12,
    margin: '0px',
    boxShadow: '0 0 4px 0 rgba(0, 0, 0, 0.1)',
    [theme.breakpoints.up(breakpoints.MOBILE)]: {
      marginBottom: 270,
    },
  },
  error: {
    color: colors.palette.error.main,
  },
  errorDisplay: { display: 'flex', padding: '0 8px !important' },
});

export default withStyles(styles)(ReserveDeskForm);
