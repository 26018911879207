export enum InputTypes {
  AvailabilityDayPicker = 'availabilityDayPicker',
  CheckBox = 'checkBox',
  DayPicker = 'dayPicker',
  DayPickerBox = 'dayPickerBox',
  DropZone = 'dropZone',
  GroupingLocationList = 'groupingLocationList',
  ImagePicker = 'imagePicker',
  Input = 'fieldInput',
  MemberAutocomplete = 'memberAutocomplete',
  Radio = 'radio',
  RichTextLimited = 'richTextLimited',
  SelectAutocomplete = 'selectAutocomplete',
  SelectAutocompleteWithBash = 'selectAutocompleteWithBash',
  SelectInput = 'select',
  SelectInputBox = 'SelectInputBox',
  SelectWithGroups = 'selectWithGroups',
  Stepper = 'stepper',
  Switch = 'switch',
}
