import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import { useHistory } from 'react-router-dom';

import {
  RoundedButton,
  RoundedButtonTypes,
  TextButton,
} from '../../../Components/Common';
import { trackManageWorkspacesClick } from '../../../Core/Tracking';
import { breakpoints } from '../../../Core/Theme';
import { VISITING_MEMBER_PATH } from '../../VisitingMemberReservations/constants';

const PopupAlertReservations = ({
  classes,
  open,
  handleOnClose,
  handleOnConfirm,
  groupingId,
  deskNumber,
  officeName,
  locationSfId,
}) => {
  const { t } = useTranslation();
  const history = useHistory();

  const handleViewAllReservations = () => {
    trackManageWorkspacesClick('View all reservations');
    return history.push({
      pathname: VISITING_MEMBER_PATH,
      search: `?locationSfId=${locationSfId}&groupingId=${groupingId}`,
    });
  };

  return (
    <Dialog
      open={open}
      onClose={handleOnClose}
      classes={{
        paper: classes.containerPopup,
      }}
    >
      <>
        <div className={classes.titlePopup}>
          {t('manageOffices.warning_desk_not_reservable.title')}
        </div>
        {deskNumber ? (
          <div>
            {t('manageOffices.confirm_unassign_desk_part1', {
              deskNumber,
            })}
            <TextButton
              text={t('manageOffices.all_desk_reservations')}
              size="medium"
              className={classes.seeDesks}
              onClick={handleViewAllReservations}
            />
            {t('manageOffices.confirm_unassign_desk_part2')}
          </div>
        ) : (
          <div>
            {t('manageOffices.confirm_unassign_part1', {
              officeName,
            })}
            <TextButton
              text={t('manageOffices.all_desk_reservations')}
              size="medium"
              className={classes.seeDesks}
              onClick={handleViewAllReservations}
            />
            {t('manageOffices.confirm_unassign_part2')}
          </div>
        )}

        <div className={classes.groupingButtons}>
          <RoundedButton
            className={classes.buttonPopup}
            onClick={handleOnClose}
            type={RoundedButtonTypes.Secondary}
          >
            {t('manageOffices.warning_desk_not_reservable.cta_no')}
          </RoundedButton>
          <RoundedButton
            className={classes.buttonPopup}
            onClick={handleOnConfirm}
          >
            {t('manageOffices.warning_desk_not_reservable.cta_yes')}
          </RoundedButton>
        </div>
      </>
    </Dialog>
  );
};

const styles = theme => ({
  containerPopup: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '16px 30px',
    fontSize: 18,
    textAlign: 'center',
  },
  titlePopup: {
    fontFamily: 'VerlagBold',
    marginBottom: 20,
  },
  groupingButtons: {
    display: 'flex',
    flexDirection: 'column-reverse',
    [theme.breakpoints.up(breakpoints.MOBILE)]: {
      flexDirection: 'row',
    },
  },
  buttonPopup: {
    margin: '16px 20px 0',
    width: 150,
  },
  seeDesks: {
    padding: 0,
  },
});

PopupAlertReservations.defaultProps = {
  groupingId: null,
  officeName: null,
  deskNumber: null,
};

PopupAlertReservations.propTypes = {
  classes: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  handleOnClose: PropTypes.func.isRequired,
  handleOnConfirm: PropTypes.func.isRequired,
  groupingId: PropTypes.string,
  officeName: PropTypes.string,
  deskNumber: PropTypes.string,
  locationSfId: PropTypes.string.isRequired,
};

export default withStyles(styles)(PopupAlertReservations);
