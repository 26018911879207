import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import Moment from 'moment';
import Lodash from 'lodash';

import { colors } from '../../Core/Theme';
import { getIsManager } from '../../Core/Utils/userPermissions';
import { DayPicker } from '../Common';
import { date as DateUtils } from '../../Core/Utils';

const AvailabilityDayPicker = props => {
  const {
    classes,
    locationSfId,
    profile,
    availabilities,
    fetchAvailability,
    selectedDaysAvailability,
    isLoading,
    locationTimezoneId,
    classNameRoot,
    classNameCarat,
    classNameInputLabel,
    datatestid,
    selectedGrouping,
    hideError,
    ...rest
  } = props;

  const fetchUsageForMonth = month => {
    fetchAvailability(
      month.startOf('month').unix(),
      month.endOf('month').unix(),
      locationSfId,
      locationTimezoneId,
      selectedGrouping
    );
  };

  const isManager = getIsManager(profile);
  const currentMomentTz = Moment().tz() || Moment.tz.guess();
  const availabilitiesTimeZoneId = Lodash.get(
    availabilities,
    [0, 'timezoneId'],
    ''
  );
  const disabledDates = availabilities.reduce((acc, a) => {
    const { startEpoch, totalRemaining } = a;
    if (totalRemaining) {
      return acc;
    }
    const adjustedStart = DateUtils.updateDateForTZ(
      startEpoch,
      currentMomentTz,
      availabilitiesTimeZoneId
    );
    acc.push(adjustedStart);
    return acc;
  }, []);

  const hasError = selectedDaysAvailability === 0;
  const showError = hasError && !hideError && !isLoading && hasError;

  return (
    <DayPicker
      type="day"
      isFocus={() => null}
      variant="box"
      {...rest}
      hasError={false}
      disableWeekends={!isManager}
      disablePastDate={!isManager}
      disabledDates={disabledDates}
      onNextMonthClick={fetchUsageForMonth}
      onPrevMonthClick={fetchUsageForMonth}
      classNameRoot={clsx(showError && classes.errorBorder, classNameRoot)}
      classNameCarat={clsx(showError && classes.errorCarat, classNameCarat)}
      classNameInputLabel={clsx(
        showError && classes.errorLabel,
        classNameInputLabel
      )}
      isLoading={isLoading}
      datatestid={datatestid}
    />
  );
};

const styles = {
  errorBorder: {
    borderColor: colors.palette.error.main,
    position: 'relative',
  },
  errorCarat: {
    zIndex: 4,
  },
  errorLabel: {
    color: colors.palette.error.main,
  },
};

AvailabilityDayPicker.defaultProps = {
  classNameRoot: '',
  classNameCarat: '',
  classNameInputLabel: '',
  datatestid: '',
  fetchAvailability: () => null,
  selectedGrouping: {},
  hideError: false,
};

AvailabilityDayPicker.propTypes = {
  locationSfId: PropTypes.string.isRequired,
  profile: PropTypes.object.isRequired,
  availabilities: PropTypes.arrayOf(PropTypes.number).isRequired,
  fetchAvailability: PropTypes.func,
  selectedDaysAvailability: PropTypes.number.isRequired,
  isLoading: PropTypes.bool.isRequired,
  locationTimezoneId: PropTypes.string.isRequired,
  classNameRoot: PropTypes.string,
  classNameCarat: PropTypes.string,
  classNameInputLabel: PropTypes.string,
  datatestid: PropTypes.string,
  selectedGrouping: PropTypes.object,
  hideError: PropTypes.bool,
};

export default withStyles(styles)(AvailabilityDayPicker);
