import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

// Styling
import styles from './styles';

const AuthPage = props => {
  const { classes, children } = props;

  return <main className={classes.container}>{children}</main>;
};

AuthPage.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  children: PropTypes.shape({}).isRequired,
};

export default withStyles(styles)(AuthPage);
