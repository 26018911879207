import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Transition } from 'react-transition-group';
import { useWindowDimensions } from '../../../Core/Utils';

// Constants + Theme
import { breakpoints } from '../../../Core/Theme';

const transitionStyles = duration => ({
  entering: {
    transform: 'translate(100%)',
    display: 'block',
  },
  entered: {
    transform: 'translate(0%)',
    transition: `transform ${duration}ms ease-in-out`,
    display: 'block',
  },
  exiting: {
    transform: 'translate(0%)',
    display: 'block',
  },
  exited: {
    transform: 'translate(-100%)',
    transition: `transform ${duration}ms ease-in-out`,
    display: 'block',
  },
});

const defaultStyle = {
  display: 'none',
  position: 'absolute',
};

const getTransitionStyle = (exitOnly, enterOnly, state, reverse, duration) => {
  if (enterOnly && exitOnly && state === 'entered') {
    return { display: 'block' };
  }
  if (enterOnly && state === 'exiting') {
    return { display: 'none' };
  }
  if (exitOnly && state === 'entering') {
    return { display: 'block' };
  }
  if (reverse && state === 'entering') {
    return {
      transform: 'translate(0%)',
      transition: `transform ${duration}ms ease-in-out`,
      display: 'block',
    };
  }
  if (reverse && state === 'exiting') {
    return {
      transform: 'translate(100%)',
      transition: `transform ${duration}ms ease-in-out`,
      display: 'block',
    };
  }
  if (reverse && state === 'exited') {
    return {
      transform: 'translate(100%)',
      transition: `transform ${duration}ms ease-in-out`,
      display: 'block',
    };
  }
  return transitionStyles(duration)[state];
};

const SlideAcross = props => {
  const {
    classes,
    children,
    enterOn,
    exitOnly,
    enterOnly,
    focusChild,
    reverse,
    duration,
  } = props;
  const { width } = useWindowDimensions();
  const isMobile = width <= breakpoints.MOBILE;
  return (
    <Transition in={enterOn} timeout={duration}>
      {state => {
        if (state === 'entering' && focusChild && !isMobile) {
          focusChild();
        }
        return (
          <div
            className={classes.main}
            style={{
              ...defaultStyle,
              ...getTransitionStyle(
                exitOnly,
                enterOnly,
                state,
                reverse,
                duration
              ),
            }}
            aria-hidden={state === 'exited'}
          >
            {children}
          </div>
        );
      }}
    </Transition>
  );
};

const styles = theme => ({
  main: {
    [theme.breakpoints.up(breakpoints.MOBILE)]: {
      height: '100%',
      overflow: 'hidden',
    },
    [theme.breakpoints.down(breakpoints.MOBILE)]: {
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
    },

    position: 'relative',
    width: '100%',
    overflowX: 'hidden',
  },
});

SlideAcross.defaultProps = {
  exitOnly: false,
  enterOnly: false,
  focusChild: null,
  reverse: false,
  duration: 700,
  children: undefined,
};

SlideAcross.propTypes = {
  exitOnly: PropTypes.bool,
  enterOnly: PropTypes.bool,
  enterOn: PropTypes.bool.isRequired,
  focusChild: PropTypes.func,
  reverse: PropTypes.bool,
  duration: PropTypes.number,
  children: PropTypes.object,
};

export default withStyles(styles)(SlideAcross);
