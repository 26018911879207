import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import MaterialDrawer from '@material-ui/core/Drawer';
import { KeyboardArrowRight, Close } from '@material-ui/icons';
import clsx from 'clsx';
import Slide from '@material-ui/core/Slide';

import { TRANSITION_DURATION_MS } from '../DrawerTab';
import { colors, breakpoints } from '../../../Core/Theme';
import { noOp } from '../../../Core/constants';
import TextButton from '../TextButton';
import SubmitButton from '../SubmitButton';
import ScrollingPanel from './ScrollingPanel';

export const PanelOpenWidth = 280;
export const PanelOpenDurationMs = TRANSITION_DURATION_MS;

const Panel = props => {
  const {
    classes,
    children,
    isOpen,
    onClickClose,
    hideMobileCloseButton,
    title,
    customClasses,
    hasFooter,
    onClickButtonFooter,
    textFooter,
    withoutBoxShadow,
    variant,
    buttonFooter,
    withoutRightArrow,
    buttonDisabled,
    buttonIsLoading,
    buttonIsSuccess,
    withFooterSlide,
    footertestid,
    datatestid,
    width,
  } = props;
  const [hideDrawer, setHideDrawer] = React.useState(isOpen);

  const isLight = variant === 'light';
  const isScrolling = variant === 'scrolling';

  // hide the drawer when it is closed, and has had time to transition away
  React.useEffect(() => {
    if (isOpen) {
      setHideDrawer(false);
    } else {
      setTimeout(() => setHideDrawer(true), PanelOpenDurationMs);
    }
  }, [isOpen]);

  if (isScrolling) {
    return <ScrollingPanel {...props} />;
  }
  const footerContent = (
    <div
      className={clsx(
        textFooter && classes.footerButtonContainerText,
        isLight && classes.light,
        classes.footerButtonMain
      )}
      style={{ width }}
    >
      {withoutBoxShadow ? null : (
        <div className={clsx(classes.shadow, isLight && classes.lightShadow)} />
      )}
      {textFooter && (
        <TextButton
          onClick={onClickButtonFooter}
          text={textFooter}
          className={classes.footerButtonText}
        />
      )}
      {buttonFooter && (
        <SubmitButton
          onClick={onClickButtonFooter}
          defaultText={buttonFooter}
          className={classes.footerButton}
          disabled={buttonDisabled}
          isLoading={buttonIsLoading}
          isSuccess={buttonIsSuccess}
          datatestid={footertestid}
        />
      )}
    </div>
  );

  const footer = !withFooterSlide ? (
    footerContent
  ) : (
    <Slide direction="left" in={isOpen} mountOnEnter unmountOnExit>
      {footerContent}
    </Slide>
  );
  return (
    <>
      {hasFooter ? footer : null}
      <MaterialDrawer
        anchor="right"
        variant="persistent"
        open={isOpen}
        classes={{
          paperAnchorRight: clsx(
            classes.main,
            isLight && classes.lightMain,
            customClasses.drawer
          ),
        }}
        PaperProps={{ style: { width } }}
        style={hideDrawer ? { visibility: 'hidden' } : {}}
      >
        <div className={clsx(classes.slider, customClasses.sliderMain)}>
          {hideMobileCloseButton ? null : (
            <Close
              className={classes.closeButton}
              onClick={() => onClickClose(false)}
              datatestid={`${datatestid}_close_button`}
            />
          )}
          <div
            className={clsx(
              classes.header,
              customClasses.header,
              isLight && classes.headerLight
            )}
          >
            {!withoutRightArrow && (
              <KeyboardArrowRight
                onClick={() => onClickClose(false)}
                className={clsx(classes.rightArrow, customClasses.rightArrow)}
                datatestid={`${datatestid}_close_button`}
              />
            )}
            <div
              className={clsx(
                classes.title,
                customClasses.title,
                isLight && classes.lightTitle
              )}
            >
              {title}
            </div>
          </div>
          <div className={classes.content}>{children}</div>
        </div>
      </MaterialDrawer>
    </>
  );
};

Panel.defaultProps = {
  children: null,
  onClickClose: noOp,
  hideMobileCloseButton: false,
  customClasses: {},
  hasFooter: false,
  onClickButtonFooter: noOp,
  textFooter: null,
  buttonFooter: null,
  withoutBoxShadow: false,
  withoutRightArrow: false,
  variant: 'default',
  buttonDisabled: false,
  buttonIsLoading: false,
  buttonIsSuccess: false,
  withFooterSlide: true,
  footertestid: '',
  datatestid: 'panel',
  width: PanelOpenWidth,
};

Panel.propTypes = {
  width: PropTypes.number,
  classes: PropTypes.shape({}).isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  isOpen: PropTypes.bool.isRequired,
  hideMobileCloseButton: PropTypes.bool,
  onClickClose: PropTypes.func,
  title: PropTypes.string.isRequired,
  customClasses: PropTypes.object,
  hasFooter: PropTypes.bool,
  withoutBoxShadow: PropTypes.bool,
  onClickButtonFooter: PropTypes.func,
  textFooter: PropTypes.string,
  withoutRightArrow: PropTypes.bool,
  variant: PropTypes.string,
  buttonFooter: PropTypes.string,
  buttonDisabled: PropTypes.bool,
  buttonIsLoading: PropTypes.bool,
  buttonIsSuccess: PropTypes.bool,
  withFooterSlide: PropTypes.bool,
  footertestid: PropTypes.string,
  datatestid: PropTypes.string,
};

const styles = theme => ({
  footerButtonText: {
    textAlign: 'center',
    color: colors.white,
    borderBottom: `solid 1px ${colors.white}`,
    marginBottom: 35,
    padding: 0,
    marginTop: 15,
  },
  footerButtonMain: {
    position: 'fixed',
    bottom: 0,
    right: 0,
    background: colors.darkest,
    zIndex: 1300,
    textAlign: 'center',
    [theme.breakpoints.down(breakpoints.MOBILE)]: {
      width: '100% !important',
    },
  },
  footerButtonContainerText: {
    height: '45px',
  },
  footerButton: {
    textAlign: 'center',
    color: colors.white,
    margin: 20,
    width: 'calc(100% - 40px)',
  },
  main: {
    background: colors.darkest,
    position: 'fixed',
    zIndex: 1300,
    overflow: 'hidden',
    [theme.breakpoints.up(breakpoints.MOBILE)]: {
      position: 'absolute',
      zIndex: 1000,
    },
    [theme.breakpoints.down(breakpoints.MOBILE)]: {
      left: 0,
      top: 0,
      right: 0,
      bottom: 0,
      zIndex: 1290,
      width: '100% !important',
    },
  },
  slider: {
    textAlign: 'center',
    flexDirection: 'column',
    display: 'flex',
    overflow: 'scroll',
    '-ms-overflow-style': 'none',
    '&::-webkit-scrollbar': {
      width: 0 /* Remove scrollbar space */,
      background: 'transparent' /* Optional: just make scrollbar invisible */,
    },
    [theme.breakpoints.up(breakpoints.MOBILE)]: {
      paddingLeft: 20,
      paddingRight: 20,
      height: '100%',
    },
    [theme.breakpoints.down(breakpoints.MOBILE)]: {
      paddingLeft: 40,
      paddingRight: 40,
      height: '100vh',
    },
  },
  closeButton: {
    position: 'absolute',
    color: colors.white,
    right: 17,
    top: 17,
    zIndex: 5,
    [theme.breakpoints.up(breakpoints.MOBILE)]: {
      display: 'none',
    },
  },
  header: {
    fontFamily: 'VerlagBook',
    fontSize: 16,
    color: colors.white,
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: 20,
    position: 'sticky',
    top: 0,
    paddingTop: 17,
    background: colors.darkest,
    marginLeft: -10,
    marginRight: -10,
    zIndex: 2,
    [theme.breakpoints.down(breakpoints.MOBILE)]: {
      fontFamily: 'VerlagBold',
      paddingTop: 67,
      paddingBottom: 40,
    },
  },
  headerLight: {
    background: colors.white,
  },
  rightArrow: {
    position: 'absolute',
    left: 15,
    top: 15,
    bottom: 0,
    cursor: 'pointer',
    marginLeft: -13,
    '&:hover': {
      background: colors.dark,
      borderRadius: '50%',
    },
    [theme.breakpoints.down(breakpoints.MOBILE)]: {
      display: 'none',
    },
  },
  title: {
    textAlign: 'center',
    padding: 0,
    margin: 0,
    [theme.breakpoints.down(breakpoints.MOBILE)]: {
      fontSize: 20,
      fontFamily: 'VerlagBold',
      width: '100%',
    },
    [theme.breakpoints.up(breakpoints.MOBILE)]: {
      fontFamily: 'VerlagBook',
      fontSize: 16,
    },
  },
  shadow: {
    boxShadow: `inset 0 0 2px 0 rgba(0, 0, 0, 0.5)`,
    height: 2,
    [theme.breakpoints.down(breakpoints.MOBILE)]: {
      width: '100%',
    },
  },
  lightShadow: {
    boxShadow: `inset 0 0 2px 0 rgba(0, 0, 0, 0.3)`,
  },
  light: {
    background: 'white',
  },
  lightTitle: {
    color: colors.black,
    fontFamily: 'VerlagBold',
    fontSize: 24,
    [theme.breakpoints.up(breakpoints.MOBILE)]: {
      fontSize: 20,
    },
  },
  lightMain: {
    boxShadow: '0 0 4px 0 rgba(0, 0, 0, 0.1) inset',
    background: 'white',
  },
  content: {
    paddingBottom: 80,
  },
});

export default withStyles(styles)(Panel);
