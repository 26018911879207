import { withStyles } from '@material-ui/core/styles';

import SelectInput from '../SelectInput';
import { colors } from '../../../Core/Theme';

const SelectInputBox = props => {
  const { classes } = props;

  return (
    <SelectInput
      {...props}
      inputClassName={classes.container}
      classNameCarat={classes.carat}
      classNameSelectRoot={classes.root}
      className={classes.content}
      menuClassName={classes.menu}
    />
  );
};

const styles = () => ({
  menu: {
    maxHeight: 270,
    marginTop: -0.5,
  },
  container: {
    padding: 10,
    display: 'flex',
    backgroundColor: `${colors.white} !important`,
    borderRadius: 4,
    border: `solid 1px ${colors.middle}`,
    cursor: 'pointer',
  },
  content: {
    width: '100%',
  },
  carat: {
    top: `calc(50% - 8px)`,
    right: 12,
    position: `absolute`,
    pointerEvents: `none`,
  },
  root: {
    borderBottom: '0px ',
  },
});

export default withStyles(styles)(SelectInputBox);
