import { withStyles } from '@material-ui/core/styles';
import { InputLabel, Input } from '@material-ui/core';
import PropTypes from 'prop-types';
import Lodash from 'lodash';
import { useTranslation } from 'react-i18next';

import { Icons } from '../../../resources';
import { colors, breakpoints } from '../../../Core/Theme';
import { MAX_IMAGE_SIZE } from '../../../Core/constants';

const ImageUpload = props => {
  const { t } = useTranslation();
  const {
    classes,
    setDisplayImage,
    textInput,
    labelText = t('imageUpload.add_photo'),
    toggleModal,
    textButtonInput,
    setIsImageError,
  } = props;
  const onSelect = e => {
    const image = new FileReader();
    const imageFile = Lodash.get(e, ['currentTarget', 'files', '0'], null);
    if (imageFile && imageFile.size >= MAX_IMAGE_SIZE) {
      setIsImageError(true);
    } else {
      image.onload = event => {
        setDisplayImage(event.target.result);
        setIsImageError(false);
      };
      if (imageFile) {
        image.readAsDataURL(imageFile);
      }
    }
    if (toggleModal) {
      toggleModal();
    }
  };

  if (textInput) {
    return (
      <div>
        <InputLabel className={classes.textInput}>
          {labelText}
          <Input
            type="file"
            name="image"
            className={classes.inputFileField}
            onChange={onSelect}
          />
        </InputLabel>
      </div>
    );
  }
  if (textButtonInput) {
    return (
      <div>
        <InputLabel className={classes.button}>
          {labelText}
          <Input
            type="file"
            name="image"
            className={classes.inputFileField}
            onChange={onSelect}
          />
        </InputLabel>
      </div>
    );
  }
  return (
    <div className={classes.content}>
      <img
        src={Icons.Upload}
        className={classes.icon}
        alt={t('altTexts.upload_icon')}
      />
      <InputLabel className={classes.uploadLabel}>
        {labelText ?? t('imageUpload.upload_a_photo')}
        <Input
          type="file"
          name="image"
          className={classes.inputFileField}
          onChange={onSelect}
        />
      </InputLabel>
    </div>
  );
};

ImageUpload.defaultProps = {
  classes: {},
  textInput: false,
  labelText: undefined,
  toggleModal: null,
  textButtonInput: false,
  setIsImageError: () => null,
};

ImageUpload.propTypes = {
  setDisplayImage: PropTypes.func.isRequired,
  classes: PropTypes.shape({}),
  textInput: PropTypes.bool,
  labelText: PropTypes.string,
  toggleModal: PropTypes.func,
  textButtonInput: PropTypes.bool,
  setIsImageError: PropTypes.func,
};

const styles = theme => ({
  content: {
    display: 'flex',
    flexDirection: 'column',
    width: '335px',
    height: '160px',
    margin: 'auto',
    border: `dashed 2px ${colors.middle}`,
    maxWidth: 'calc(100% - 10px)',
    [theme.breakpoints.up(breakpoints.MOBILE)]: {
      width: 453,
      height: 183,
    },
  },
  icon: {
    height: '31.6px',
    width: '31.6px',
    margin: 'auto',
    marginTop: 40,
    marginBottom: 30,
    [theme.breakpoints.up(breakpoints.MOBILE)]: {
      marginTop: 60,
    },
  },
  inputFileField: {
    display: 'none',
  },
  label: {
    fontFamily: 'VerlagLight',
    fontSize: 18,
    fontWeight: 'normal',
  },
  textInput: {
    color: colors.palette.primary.main,
    fontFamily: 'VerlagBook',
  },
  uploadLabel: {
    color: colors.palette.primary.main,
    fontFamily: 'VerlagBook',
    marginBottom: 40,
  },
  button: {
    [theme.breakpoints.up(breakpoints.MOBILE)]: {
      padding: 0,
      width: 215,
      lineHeight: '36px',
    },
    [theme.breakpoints.down(breakpoints.MOBILE)]: {
      width: 158,
      height: 46,
      fontSize: '15px',
      padding: 0,
      lineHeight: '45px',
    },
    cursor: 'pointer',
    display: 'block',
    fontFamily: 'VerlagBold',
    textTransform: 'none',
    color: colors.black,
    backgroundColor: 'transparent',
    borderColor: colors.palette.primary.main,
    borderWidth: 2,
    borderRadius: '3px',
    border: 'solid 2px',
    boxShadow: 'none',
    '&:hover': {
      borderColor: colors.palette.primary.light,
      backgroundColor: colors.white,
    },
    '&:focus': {
      boxShadow: 'none',
      backgroundColor: colors.white,
    },
  },
});

export default withStyles(styles)(ImageUpload);
