export enum languageType {
  en = 'en-US',
  fr = 'fr-FR',
  nl = 'nl-NL',
}

export const AVAILABLE_LANGUAGES: { [key in languageType]: string } = {
  [languageType.en]: 'en',
  [languageType.fr]: 'fr',
  [languageType.nl]: 'nl',
};
