export const VISITING_ERROR_TYPES = {
  NONE: 'NONE',
  DAY: 'DAY',
  MONTH: 'MONTH',
};

export const FILTERS_TYPE = {
  SEAT: 'Seat',
  AMENITY: 'Amenity',
  FLOOR: 'Floor',
};

export const SEATS = {
  s: '1-4',
  m: '5-9',
  l: '10+',
};

export const MINIMUM_BOOKING_INCREMENT = 30 * 60;

export const ROOM_BOOKING_PATH = '/home/calendar/roombooking';
