import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { BaseButton, Drawer, Text } from '../../../Components/Common';
import { breakpoints, colors } from '../../../Core/Theme';
import DeskToggleContents from './DeskToggleContents';

const DeskToggleDrawer = props => {
  const { classes, isOpen, setOpen } = props;
  const { t } = useTranslation();

  const open = () => setOpen(true);
  const close = () => setOpen(false);

  return (
    <>
      <BaseButton
        name={t('manageOffices.see_desks')}
        className={classes.seeDeskButton}
        onClick={open}
      />
      <Drawer
        isOpenDrawer={isOpen}
        onCloseDrawer={close}
        anchor="bottom"
        withCloseButton
        onCloseButton={close}
        classNameRoot={classes.drawerPaper}
        classes={{ paper: classes.drawerPaper }}
        customDuration={{ enter: 350, exit: 300 }}
      >
        <div className={classes.main}>
          <Text text={t('deskAdmin.title')} className={classes.title} />
          <DeskToggleContents {...props} className={classes.form} withFooter />
        </div>
      </Drawer>
    </>
  );
};

const styles = theme => ({
  drawerPaper: {
    top: 0,
    borderTop: 'none',
    overflow: 'hidden',
  },
  main: {
    textAlign: 'center',
    marginTop: 50,
    height: 'calc(100vh - 50px)',
  },
  seeDeskButton: {
    position: 'fixed',
    bottom: -3,
    width: 'calc(100% + 4px)',
    border: 'none',
    right: -2,
    left: -2,
    color: colors.palette.primary.main,
    borderRadius: 9,
    [theme.breakpoints.up(breakpoints.MOBILE)]: {
      display: 'none',
    },
  },
  title: {
    fontSize: 20,
    fontFamily: 'VerlagBold',
    textAlign: 'center',
  },
  form: {
    marginTop: 20,
    height: 'calc(100% - 40px)',
    display: 'flex',
    flexDirection: 'column',
  },
});

DeskToggleDrawer.defaultProps = {};

DeskToggleDrawer.propTypes = {
  classes: PropTypes.object.isRequired,
  submitForm: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
};

export default withStyles(styles)(DeskToggleDrawer);
