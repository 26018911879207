/* eslint-disable no-underscore-dangle */

import { Http, stringifyUrl } from '../Utils';
import {
  CreateOccasionDto,
  UpdateOccasionDto,
  BatchUpsertOccasionsDto,
  OccasionDto,
  OccasionsPageDto,
  HospitalityCategoryDto,
} from './dto';

export const createOccasion = (occasion: CreateOccasionDto) => {
  return Http.post<OccasionDto>(`/occasions`, occasion);
};

export const getOccasionsPage = (
  limit: number,
  offset: number,
  groupingIds: string[] | string,
  locationSfIds: string[] | string,
  startEpoch: number,
  endEpoch: number,
  isMenu?: boolean,
  isList?: boolean,
  includeOngoing = false,
  includeDeleted = false
) => {
  const url = stringifyUrl('/occasions', {
    start_epoch: startEpoch,
    end_epoch: endEpoch,
    include_deleted: includeDeleted,
    limit,
    offset,
    include_ongoing: includeOngoing,
    location_sfids: Array.isArray(locationSfIds)
      ? locationSfIds.join()
      : locationSfIds,
    grouping_ids: Array.isArray(groupingIds) ? groupingIds.join() : groupingIds,
    is_in_menu: isMenu,
    is_in_list: isList,
  });

  return Http.get<OccasionsPageDto>(url);
};

export const getOccasionById = (id: string) => {
  return Http.get<OccasionDto>(`/occasions/${id}`);
};

export const deleteOccasion = (id: string) => {
  return Http.remove<void>(`/occasions/${id}`);
};

export const updateOccasion = (id: string, occasion: UpdateOccasionDto) => {
  return Http.put<OccasionDto>(`/occasions/${id}`, occasion);
};

export const addMultipleOccasions = (batch: BatchUpsertOccasionsDto) => {
  return Http.post<OccasionDto[]>('/occasions/upsert', batch);
};

export const addOccasionImage = (id: string, image: string | Blob) => {
  const data = new FormData();
  data.append('file', image);

  return Http.put<OccasionDto>(`/occasions/${id}/image`, data, {
    headers: {
      'Content-Type': `multipart/form-data ; boundary=${
        (data as any)._boundary
      }`,
    },
  });
};

export const getHospitalityCategories = () => {
  return Http.get<HospitalityCategoryDto[]>(
    '/occasions/hospitality_categories'
  );
};
