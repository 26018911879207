import { createAction } from 'redux-actions';
import * as types from './actionTypes';
import { fetchRooms, fetchFloors, fetchBookingRules } from '../../../Core/Api';
import { fetchHandlerActions } from '../../../Core/Utils/fetch';

export const addRooms = createAction(
  types.LOAD_ROOMS,
  fetchHandlerActions(fetchRooms)
);

export const addBookingRules = createAction(
  types.LOAD_BOOKING_RULES,
  fetchHandlerActions(fetchBookingRules)
);

export const deleteRoom = createAction(types.DELETE_ROOM);

export const createRoom = createAction(types.CREATE_ROOM);

export const updateRoom = createAction(types.UPDATE_ROOM);

export const addFloor = createAction(types.ADD_FLOOR);

export const addFloors = createAction(
  types.LOAD_FLOORS,
  fetchHandlerActions(fetchFloors)
);
