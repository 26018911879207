import * as React from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Menu from '@material-ui/core/Menu';
import IconButton from '@material-ui/core/IconButton';

import clsx from 'clsx';
// Theme
import { colors, breakpoints } from '../../../Core/Theme';
import { Icons } from '../../../resources';

const Kebab = props => {
  const { menuItems, classes, className, datatestid } = props;
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = event => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };
  const handleClose = e => {
    e.stopPropagation();
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton
        aria-label={t('general.menu')}
        aria-haspopup="true"
        onClick={handleClick}
        className={clsx(classes.main, className)}
        datatestid={`${datatestid}_kebab`}
      >
        <Icons.KebabIcon className={classes.icon} />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        variant="menu"
        classes={{ paper: classes.menu }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        getContentAnchorEl={null}
        onClick={handleClose}
        disableAutoFocusItem
      >
        {menuItems}
      </Menu>
    </>
  );
};

const styles = theme => ({
  main: {
    color: colors.palette.primary.main,
    width: 30,
    height: 30,
    '&:hover': {
      backgroundColor: colors.light,
    },
  },
  menu: {
    borderRadius: 3,
    boxShadow: '0 0 3px 1px rgba(0, 0, 0, 0.25)',
    border: `solid 1px ${colors.dark}`,
  },
  icon: {
    [theme.breakpoints.up(breakpoints.MOBILE)]: {
      height: 18,
    },
  },
});

Kebab.defaultProps = {
  datatestid: 'kebab',
};

Kebab.propTypes = {
  classes: PropTypes.object.isRequired,
  menuItems: PropTypes.arrayOf(PropTypes.object).isRequired,
  datatestid: PropTypes.string,
};
export default withStyles(styles)(Kebab);
