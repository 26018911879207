/* eslint-disable no-underscore-dangle */
import React, { useEffect, useState } from 'react';
import { StyleRules, withStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import { useDispatch, useSelector } from 'react-redux';
import Dialog from '@material-ui/core/Dialog';
import axios from 'axios';

import UserManager from '../../Redux/Common/UserManager';
import { getUserProfile } from '../../Redux/Common/UserManager/selectors';

// Theme
import { colors } from '../../Core/Theme';

// Components
import {
  Text,
  TextButton,
  SkeletonLoader,
  RoundedButton,
  RoundedButtonTypes,
  SubmitButton,
} from '../../Components/Common';
import {
  fetchAcsUser,
  fetchUserDoorPinCode,
  resetUserDoorPinCode,
} from '../../Core/Api';

interface PinCodeSectionProps {
  classes: ClassNameMap;
}

const PinCodeSection: React.FC<PinCodeSectionProps> = ({ classes }) => {
  const { t } = useTranslation();
  const [isFetchingPin, setIsFetchingPin] = useState(false);
  const [userDoorPin, setUserDoorPin] = useState('');
  const [isShowLabelVisible, setIsShowLabelVisible] = useState(false);
  const [isResetingPin, setIsResetingPin] = useState(false);
  const [isConfirming, setIsConfirming] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [hasDoorPin, setHasDoorPin] = React.useState(false);
  const maskCode = `●●●  ●●● #`;

  const { _id: oid, name } = useSelector(getUserProfile);
  const dispatch = useDispatch();

  const fetchAccessUser = async () => {
    const response = await fetchAcsUser(oid);
    if (!response.data) return;
    setHasDoorPin(Boolean(response.data.locationsAccess.length));
  };

  const fetchPin = async () => {
    try {
      setIsFetchingPin(true);
      const response = await fetchUserDoorPinCode(oid);
      // somehow there is an interceptor that catches ALL axios error in MP
      // (src/Core/Api/errorInterceptor.ts)
      // and I don't want to disable it now to not break anything, so:
      if (axios.isAxiosError(response)) throw response;
      setUserDoorPin(response.data.pin);
    } catch (e) {
      dispatch(
        UserManager.actions.setCustomErrorDescription(
          t('PinCodeSection.errorMessage')
        )
      );
    } finally {
      setIsFetchingPin(false);
    }
  };

  const resetPin = async () => {
    try {
      setIsResetingPin(true);
      setIsShowLabelVisible(false);
      const response = await resetUserDoorPinCode(oid);
      if (axios.isAxiosError(response)) throw response;
      setUserDoorPin(response.data.pin);
      setIsSuccess(true);
    } catch (e) {
      setIsSuccess(false);
      dispatch(UserManager.actions.addApiError(e));
      dispatch(
        UserManager.actions.setCustomErrorDescription(
          t('YourDoorAccessPinSection.resetErrorMessage')
        )
      );
    } finally {
      setIsResetingPin(false);
      setIsConfirming(false);
    }
  };
  React.useEffect(() => {
    fetchAccessUser();
  }, []);

  useEffect(() => {
    if (isShowLabelVisible) {
      fetchPin();
    }
  }, [isShowLabelVisible]);

  if (!hasDoorPin) {
    return null;
  }

  return (
    <div className={classes.container}>
      <div className={classes.divider} />
      <Text
        className={classes.title}
        text={t('YourDoorAccessPinSection.title')}
      />
      <Text
        className={classes.inputLabel}
        text={t('YourDoorAccessPinSection.uniquePinCodeLabel', {
          name,
        })}
      />

      {isFetchingPin ? (
        <div className={classes.loaderContainer}>
          <SkeletonLoader className={classes.loader} />
        </div>
      ) : (
        <div className={classes.labelContainer}>
          <div className={classes.code}>
            {isShowLabelVisible ? userDoorPin : maskCode}
          </div>
          <div className={classes.toggleButtonContainer}>
            <TextButton
              className={classes.button}
              onClick={() => setIsShowLabelVisible(!isShowLabelVisible)}
              text={isShowLabelVisible ? 'Hide' : 'Show'}
              dataTestId={
                !isShowLabelVisible
                  ? 'login_show_password_button'
                  : 'login_hide_password_button'
              }
            />
          </div>
        </div>
      )}

      <div className={classes.disclaimer}>
        {t('YourDoorAccessPinSection.disclaimer')}
        <br />
        {t('YourDoorAccessPinSection.disclaimerResetPin')}
      </div>

      <div className={classes.resetPinSection}>
        <TextButton
          onClick={() => setIsConfirming(true)}
          text={t('YourDoorAccessPinSection.resetPinButton')}
          dataTestId="login_reset_pin_button"
          className={classes.resetPinButton}
        />
      </div>
      <div className={classes.divider} />
      <Dialog
        open={isConfirming}
        onClose={() => setIsConfirming(false)}
        classes={{
          paper: classes.containerPopup,
        }}
      >
        <>
          <div className={classes.titlePopup}>
            {t('YourDoorAccessPinSection.confirmPopup.title')}
          </div>
          <div className={classes.descriptionPopup}>
            {t('YourDoorAccessPinSection.confirmPopup.description')}
          </div>
          <div className={classes.actionPopup}>
            <RoundedButton
              className={classes.buttonPopup}
              onClick={() => setIsConfirming(false)}
              type={RoundedButtonTypes.Secondary}
              isDisabled={isResetingPin}
            >
              {t('YourDoorAccessPinSection.confirmPopup.no')}
            </RoundedButton>
            <SubmitButton
              className={classes.buttonPopup}
              onClick={() => resetPin()}
              isLoading={isResetingPin}
              defaultText={t('YourDoorAccessPinSection.confirmPopup.yes')}
            />
          </div>
        </>
      </Dialog>
      <Dialog
        open={isSuccess}
        onClose={() => setIsSuccess(false)}
        classes={{
          paper: classes.containerPopup,
        }}
      >
        <>
          <div className={classes.titlePopup}>
            {t('YourDoorAccessPinSection.successPopup.title')}
          </div>
          <div className={classes.descriptionPopup}>
            {t('YourDoorAccessPinSection.successPopup.description')}
          </div>
          <div
            className={classes.actionPopup}
            style={{ justifyContent: 'center' }}
          >
            <RoundedButton
              className={classes.buttonPopup}
              onClick={() => setIsSuccess(false)}
              type={RoundedButtonTypes.Secondary}
            >
              {t('YourDoorAccessPinSection.successPopup.ok')}
            </RoundedButton>
          </div>
        </>
      </Dialog>
    </div>
  );
};

const styles = (): StyleRules => ({
  containerPopup: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '26px 60px',
    fontSize: 18,
    textAlign: 'center',
  },
  titlePopup: {
    fontFamily: 'VerlagBold',
    marginBottom: 20,
  },
  actionPopup: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  },
  buttonPopup: {
    width: 'auto',
    paddingLeft: 20,
    paddingRight: 20,
    height: 40,
    marginTop: 0,
  },
  descriptionPopup: {
    marginBottom: 30,
  },
  container: {
    marginBottom: '41px',
  },
  divider: {
    height: 1,
    background: colors.middle,
  },
  title: {
    marginTop: '41px',
    fontFamily: 'VerlagBold',
    fontSize: '28px',
    lineHeight: '28px',
    marginBottom: '40px',
    display: 'block',
  },
  inputLabel: {
    fontFamily: 'VerlagBold',
    fontSize: '16px',
    display: 'block',
    marginBottom: '6px',
  },
  labelContainer: {
    display: 'flex',
    alignItems: 'center',
    paddingTop: 9,
    paddingBottom: 9,
    paddingLeft: 10,
    border: `solid 1px ${colors.middle}`,
    borderRadius: 4,
    justifyContent: 'space-between',
    width: '320px',
    marginBottom: '6px',
  },
  code: {
    fontFamily: 'VerlagBold',
    fontSize: '20px',
  },
  button: {
    width: '26px',
    height: '18px',
    fontFamily: 'VerlagBook',
    fontSize: '12px',
    fontWeight: 'normal',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 1.5,
    letterSpacing: 'normal',
    color: colors.palette.primary.main,
    padding: '0 0 0 0',
    textTransform: 'none',
    marginRight: 15,
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  toggleButtonContainer: {
    textAlign: 'right',
  },
  disclaimer: {
    fontSize: '16px',
    fontFamily: 'VerlagBook',
    marginBottom: '43px',
  },
  loaderContainer: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '6px',
  },
  loader: {
    width: '320px',
    height: '40px',
  },
  resetPinSection: {
    marginBottom: '41px',
    fontSize: '16px',
  },
  resetPinButton: {
    textDecoration: 'underline',
  },
});

export default withStyles(styles)(PinCodeSection);
