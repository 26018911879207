/* eslint-disable react/no-typos */
import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

// Constants
import { breakpoints } from '../../../Core/Theme';

import AmenityIcon from './AmenityIcon';

const AmenityIconGroup = ({ amens }) => {
  if (amens.length === 0) return null;

  return amens.map(({ sfId, name, iconUrl }) => {
    return (
      <React.Fragment key={sfId}>
        <AmenityIcon amenityName={name} amenityIconUrl={iconUrl} />
      </React.Fragment>
    );
  });
};

const styles = theme => ({
  small: {
    height: 18,
    paddingRight: 10,
  },
  medium: {
    height: 20,
    paddingRight: 10,
  },
  amenitiesNames: {
    fontFamily: 'VerlagLight',
    fontSize: '15px',
    margin: 0,
    marginRight: '15px',
    [theme.breakpoints.up(breakpoints.mobile)]: {
      display: 'none',
    },
  },
});

AmenityIconGroup.defaultProps = {
  amens: [],
};
AmenityIconGroup.propTypes = {
  amens: PropTypes.arrayOf(PropTypes.object),
};

export default withStyles(styles)(AmenityIconGroup);
