import Components from '../../Components';

const { containerTypes } = Components.Routes;

const RouteConfig = {
  path: '/login',
  exact: true,
  name: 'navigation.menu.login',
  private: false,
  type: containerTypes.PAGE,
};

export default RouteConfig;
