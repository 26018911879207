import { ReactNode } from 'react';
import clsx from 'clsx';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';

import { withStyles } from '@material-ui/core/styles';

import { colors } from '../../../Core/Theme';
import { RoundedButtonTypes } from './RoundedButtonTypes.enum';

type Props = {
  classes: ClassNameMap;
  className?: string;
  children?: ReactNode;
  type?: RoundedButtonTypes;
  isSmall?: boolean;
  isDisabled?: boolean;
  onClick: () => void;
} & typeof defaultProps;

const defaultProps = {
  type: RoundedButtonTypes.Primary,
  className: '',
  isSmall: false,
  isDisabled: false,
  children: '',
};

const RoundedButton = (props: Props) => {
  const { classes, className, children, type, isSmall, isDisabled, onClick } =
    props;

  return (
    <button
      className={clsx(
        classes.default,
        className,
        classes[type],
        isSmall && classes.small,
        isDisabled && classes.disabled
      )}
      onClick={isDisabled ? () => null : onClick}
      type="button"
    >
      {children}
    </button>
  );
};

const styles = () => ({
  default: {
    fontFamily: 'VerlagBold',
    fontSize: 16,
    cursor: 'pointer',
    padding: 10,
    borderRadius: 20,
    border: `2px solid ${colors.palette.primary.main}`,
  },
  primary: {
    color: colors.white,
    backgroundColor: colors.palette.primary.main,
    '&:hover': {
      backgroundColor: colors.palette.primary.light,
      borderColor: colors.palette.primary.light,
    },
  },
  secondary: {
    color: colors.palette.primary.main,
    backgroundColor: colors.white,
    '&:hover': {
      color: colors.white,
      backgroundColor: colors.palette.primary.main,
      borderColor: colors.palette.primary.main,
    },
  },
  small: {
    padding: '0 20px',
    height: 30,
  },
  disabled: {
    backgroundColor: colors.white,
    borderColor: colors.light,
    color: colors.subtleGray,
    cursor: 'default',
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: colors.white,
      borderColor: colors.light,
      color: colors.subtleGray,
    },
  },
});

RoundedButton.defaultProps = defaultProps;

export default withStyles(styles)(RoundedButton);
