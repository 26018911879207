import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

// Redux
import UserRedux from '../../Redux/Common/UserManager';
import { getUserId } from '../../Redux/Common/UserManager/selectors';

const InitialLoad = ({
  children,
  userSfId,
  fetchUserProfile,
  fetchNotificationSettings,
}) => {
  React.useEffect(() => {
    if (userSfId) {
      fetchUserProfile(userSfId);
      fetchNotificationSettings(userSfId);
    }
  }, []);

  return children;
};

const mapStateToProps = state => {
  return {
    userSfId: getUserId(state),
  };
};

const mapDispatchToProps = dispatch => {
  const { actions } = UserRedux;
  return bindActionCreators(
    {
      fetchUserProfile: actions.fetchUserProfile,
      fetchNotificationSettings: actions.fetchUserNotificationSettings,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(InitialLoad);
