import { TFunction } from 'i18next';
import { AmenityDto } from '../Core/Api';
import { getStaticImageUrl } from '../Core/Utils';

export class Amenity {
  id: number;
  name: string;
  iconName: string;
  iconUrl: string;
  altIconUrl: string;
  displayOrder: number;

  constructor(dto: AmenityDto, t: TFunction) {
    this.id = dto.id;
    this.iconUrl = getStaticImageUrl(`amenities/${dto.code}.svg`);
    this.altIconUrl = getStaticImageUrl(`amenities/${dto.code}-alt.svg`);
    this.displayOrder = dto.displayOrder;
    this.name = t(`amenities.${dto.code}.name`);
    this.iconName = t(`amenities.${dto.code}.iconName`);
  }
}
