import Moment from 'moment';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import { date as DateUtils } from '../../../../Core/Utils';
import { colors } from '../../../../Core/Theme';
import { SkeletonLoader } from '../../../../Components/Common';

const DayBox = props => {
  const {
    classes,
    startEpoch,
    occasionTypeName,
    className,
    isVirtual,
    isLoading,
  } = props;
  const { t } = useTranslation();
  const renderTypeBox = () => {
    return isVirtual ? (
      <div
        className={clsx(
          classes.virtualTypeBox,
          !startEpoch && classes.typeBoxHideDate
        )}
      >
        <div className={classes.virtual}>{t('events.virtual')}</div>
        <div className={classes.occasionName}>{occasionTypeName}</div>
      </div>
    ) : (
      <div
        className={clsx(
          classes.typeBox,
          !startEpoch && classes.typeBoxHideDate
        )}
      >
        {isLoading ? (
          <SkeletonLoader className={classes.loadingType} />
        ) : (
          occasionTypeName
        )}
      </div>
    );
  };

  return (
    <div className={clsx(classes.main, className)}>
      {startEpoch ? (
        <div className={classes.dayBox}>
          <p className={classes.dayBoxDay}>
            {isLoading ? (
              <SkeletonLoader className={classes.loadingDay} />
            ) : (
              DateUtils.getDayString(startEpoch, undefined, t)
            )}
          </p>
          <p className={classes.dayBoxDate}>
            {isLoading ? (
              <SkeletonLoader className={classes.loadingDayDate} />
            ) : (
              Moment.unix(startEpoch).format('[MMM D]')
            )}
          </p>
        </div>
      ) : null}
      {(occasionTypeName || isLoading) && renderTypeBox()}
    </div>
  );
};

const styles = () => ({
  main: {
    opacity: 'transparent',
    height: '106px',
    width: '70px',
    position: 'absolute',
  },
  dayBox: {
    borderRadius: 5,
    boxShadow: '0 0 2px 0 rgba(0, 0, 0, 0.25)',
    textAlign: 'center',
    background: colors.white,
    height: '70px',
    margin: 0,
    position: 'static',
    padding: 0,
  },
  dayBoxDay: {
    fontFamily: 'VerlagBook',
    fontSize: 9,
    padding: '20px 0 0 0',
    margin: 0,
  },
  dayBoxDate: {
    fontFamily: 'VerlagBold',
    fontSize: 18,
    padding: '3px 0 0 0',
    margin: 0,
  },
  typeBox: {
    marginTop: '5px',
    borderRadius: 3,
    background: colors.palette.secondary.main,
    fontFamily: 'VerlagBold',
    fontSize: 11,
    textAlign: 'center',
    paddingTop: 6,
    paddingBottom: 4,
    maxHeight: '25px',
  },
  virtualTypeBox: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 5,
    width: 70,
  },
  virtual: {
    background: colors.palette.tertiary2.main,
    color: colors.white,
    fontFamily: 'VerlagBold',
    fontSize: 9,
    letterSpacing: 0.3,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: 15,
    borderTopLeftRadius: 3,
    borderTopRightRadius: 3,
  },
  occasionName: {
    background: colors.palette.secondary.main,
    fontFamily: 'VerlagBold',
    fontSize: 11,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: 19,
    borderBottomLeftRadius: 3,
    borderBottomRightRadius: 3,
  },
  loadingDay: {
    width: 46,
    height: 10,
    margin: 'auto',
  },
  loadingDayDate: {
    width: 50,
    height: 18,
    margin: 'auto',
  },
  loadingType: {
    width: 47,
    height: 10,
    margin: 'auto',
    marginBottom: 1,
  },
  typeBoxHideDate: {
    marginTop: '60px',
  },
});

DayBox.defaultProps = {
  classes: {},
  startEpoch: 0,
  occasionTypeName: '',
  className: '',
  isVirtual: false,
  isLoading: false,
};

DayBox.propTypes = {
  classes: PropTypes.shape({}),
  startEpoch: PropTypes.number,
  occasionTypeName: PropTypes.string,
  className: PropTypes.string,
  isVirtual: PropTypes.bool,
  isLoading: PropTypes.bool,
};

export default withStyles(styles)(DayBox);
