import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

// Core + constants + utils
import { colors, breakpoints } from '../../Core/Theme';
import { Icons } from '../../resources';

// Components
import { CollapseHeader } from '../Common';

// Redux
import { getUserProfileAccount } from '../../Redux/Common/UserManager/selectors';

const ProfessionalMailingAdrTile = ({ classes, account, className }) => {
  const { t } = useTranslation();

  const companyName =
    account.name || t('home.professional_mailing_adr_tile.default_company');
  const adrStreet = account.location.address;
  const adrSupplement = account.location.address2;
  const adrLastLine = `${account.location.city}, ${account.location.state} ${account.location.zipCode}`;

  return (
    <CollapseHeader
      title={t('home.professional_mailing_adr_tile.title')}
      className={clsx(classes.container, className)}
      titleClassName={classes.title}
      datatestid="your_professional_mailing_adr"
      disabled
    >
      <div className={classes.content}>
        <Icons.ProfessionalMailingAdr className={classes.logo} />
        <div className={classes.address}>
          <div>{companyName}</div>
          <div>{adrStreet}</div>
          {adrSupplement && <div>{adrSupplement}</div>}
          <div>{adrLastLine}</div>
        </div>
        <p className={classes.info}>
          {t('home.professional_mailing_adr_tile.mail_info')}
        </p>
      </div>
    </CollapseHeader>
  );
};

const styles = theme => ({
  container: {
    border: `1px solid ${colors.middle}`,
    borderRadius: '2px',
    boxShadow: '0 0 4px 0 rgba(0, 0, 0, 0.1)',
    background: 'white',
  },
  title: {
    fontFamily: 'VerlagBold',
    fontSize: 16,
    letterSpacing: 0.53,
    lineHeight: 1,
    [theme.breakpoints.up(breakpoints.MD)]: {
      fontSize: 18,
      letterSpacing: 0.6,
    },
  },
  content: {
    padding: 10,
    paddingLeft: 15,
    paddingRight: 15,
    paddingBottom: 0,
    paddingTop: 7,
  },
  logo: {
    paddingTop: 30,
    margin: 'auto',
    display: 'block',
  },
  address: {
    textAlign: 'center',
    fontWeight: 325,
    fontSize: '16px',
    lineHeight: '125%',
    fontStyle: 'normal',
    margin: '1rem auto',
  },
  info: {
    fontSize: '12px',
    marginTop: '1rem',
  },
});

const mapStateToProps = state => {
  return {
    account: getUserProfileAccount(state),
  };
};

ProfessionalMailingAdrTile.propTypes = {
  classes: PropTypes.object.isRequired,
  account: PropTypes.object.isRequired,
};

export default connect(
  mapStateToProps,
  undefined
)(withStyles(styles)(ProfessionalMailingAdrTile));
