import * as React from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import ImageUpload from '../ImageUpload';
import ImageCropper from '../ImageCropper';
import TextButton from '../TextButton';
import FadeInModal from '../FadeInModal';

const newPreviousImage = (setPreviousImage, setIsImageError) => image => {
  setPreviousImage(image);
  setIsImageError(false);
};

const handleClose =
  (toggleModal, setDisplayImage, previousImage, setIsImageError) =>
  (e, reason) => {
    toggleModal();
    setIsImageError(false);
    if (reason === 'backdropClick') {
      setDisplayImage(previousImage);
    }
  };
const ImageInput = props => {
  const {
    image,
    setDisplayImage,
    classes,
    onSubmit,
    toggleModal,
    isModalOpen,
    textInput,
    editButtonClassName,
    title,
  } = props;
  const { t } = useTranslation();
  const [previousImage, setPreviousImage] = React.useState(image);
  const [isImageError, setIsImageError] = React.useState(false);
  if (!image && !isImageError) {
    return (
      <ImageUpload
        setDisplayImage={setDisplayImage}
        className={classes.uploadView}
        textInput={textInput}
        toggleModal={toggleModal}
        setIsImageError={setIsImageError}
      />
    );
  }
  return (
    <>
      <TextButton
        text={t('imageUpload.edit_photo')}
        onClick={toggleModal}
        className={editButtonClassName}
      />
      <FadeInModal
        isModalOpen={isModalOpen}
        handleClose={handleClose(
          toggleModal,
          setDisplayImage,
          previousImage,
          setIsImageError
        )}
        RenderProp={() => (
          <ImageCropper
            image={image}
            setDisplayImage={setDisplayImage}
            classes={classes}
            onSubmit={onSubmit}
            toggleModal={toggleModal}
            newPreviousImage={newPreviousImage(
              setPreviousImage,
              setIsImageError
            )}
            previousImage={previousImage}
            title={title ?? t('imageUpload.title')}
            isImageError={isImageError}
            setIsImageError={setIsImageError}
          />
        )}
      />
    </>
  );
};

ImageInput.defaultProps = {
  classes: {},
  textInput: false,
  editButtonClassName: '',
  title: undefined,
};

ImageInput.propTypes = {
  setDisplayImage: PropTypes.func.isRequired,
  classes: PropTypes.shape({}),
  textInput: PropTypes.bool,
  image: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  toggleModal: PropTypes.func.isRequired,
  isModalOpen: PropTypes.bool.isRequired,
  editButtonClassName: PropTypes.string,
  title: PropTypes.string,
};

const styles = () => ({
  uploadView: {
    width: '80%',
    height: '80%',
  },
});

export default withStyles(styles)(ImageInput);
