import { date as DateUtils } from '../../../Core/Utils';

export class ReservationEmailSubjectDate extends String {
  static format = '[dddd, MMMM D]';

  private constructor(value?: any) {
    super(value);
  }

  static FromEpoch(epoch: number) {
    const value = DateUtils.getUnixTimeInFormat(
      epoch,
      ReservationEmailSubjectDate.format
    );
    return new ReservationEmailSubjectDate(value);
  }
}
