import * as React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useTranslation } from 'react-i18next';

// Components
import { Loading, TextButton, Drawer, Snack } from '../../Components/Common';
import Header from './Header';
import { ContactInfo, SettingRow } from './Components';
// Redux
import {
  getUserProfile,
  getUserSettingsIsReady,
  getUserSettings,
  getProfileUserSfId,
} from '../../Redux/Common/UserManager/selectors';
import Redux from '../../Redux/Common/UserManager';
import {
  getUserGroupings,
  getUserGroupingsIsLoading,
} from '../../Redux/Common/Groupings/selectors';
// Theme
import { colors, breakpoints } from '../../Core/Theme';
import { getRowValuesFromSettings } from './Models';

const Settings = props => {
  const {
    classes,
    userProfile,
    userSettingsIsReady,
    fetchNotificationSettings,
    userGroupings,
    userGroupingsIsLoading,
    settings,
    patchSettings,
    patchSettingsRedux,
    userId,
  } = props;
  const { t } = useTranslation();

  const [drawerIsOpen, setDrawerOpenState] = React.useState(false);
  const [hasPhoneNumber, setHasPhoneNumber] = React.useState(false);
  const [openSettingsWithError, setOpenSettingsWithError] =
    React.useState(false);
  const [isSuccessSnackOpen, setSuccessSnackOpen] = React.useState(false);
  const [isFailSnackOpen, setFailSnackOpen] = React.useState(false);

  const [postCheckType, setPostCheckType] = React.useState(null);
  const [postCheckId, setPostCheckId] = React.useState(null);

  React.useEffect(() => {
    if (userProfile.phoneNumber) {
      setHasPhoneNumber(true);
      return;
    }
    setHasPhoneNumber(false);
  }, [userProfile]);

  React.useEffect(() => {
    const { sfId } = userProfile;
    fetchNotificationSettings(sfId);
  }, [userSettingsIsReady]);

  const toggleDrawer = () => {
    if (drawerIsOpen) {
      setOpenSettingsWithError(false);
      setPostCheckType(null);
    }
    setDrawerOpenState(!drawerIsOpen);
  };
  const toggleDrawerWithError = () => {
    setOpenSettingsWithError(true);
    toggleDrawer();
  };

  const openSuccessSnack = () => setSuccessSnackOpen(true);
  const closeSuccessSnack = () => setSuccessSnackOpen(false);
  const openFailSnack = () => setFailSnackOpen(true);
  const closeFailSnack = () => setFailSnackOpen(false);

  const renderSettings = () => {
    const rows = getRowValuesFromSettings(
      settings,
      userProfile,
      userGroupings,
      userGroupingsIsLoading
    );
    return (
      <>
        {rows.map(r => {
          const { events, title, subtitle, id, hideSms, canAccess } = r;
          return canAccess ? (
            <SettingRow
              events={events}
              id={id}
              title={title}
              subtitle={subtitle}
              hasPhoneNumber={hasPhoneNumber}
              onToggleDrawer={toggleDrawerWithError}
              postCheckType={postCheckType}
              setPostCheckType={setPostCheckType}
              userGroupings={userGroupings}
              userProfile={userProfile}
              openSuccessSnack={openSuccessSnack}
              openFailSnack={openFailSnack}
              patchSettings={patchSettings}
              hideSms={hideSms}
              setPostCheckId={setPostCheckId}
              postCheckId={postCheckId}
              patchSettingsRedux={patchSettingsRedux}
              userId={userId}
              settings={settings}
            />
          ) : null;
        })}
      </>
    );
  };

  const renderSpinner = () => {
    return (
      <div className={classes.spinnerContainer}>
        <Loading />
      </div>
    );
  };

  return (
    <div className={classes.body}>
      <Drawer
        isOpenDrawer={drawerIsOpen}
        onCloseDrawer={toggleDrawer}
        anchor="right"
        className="drawerPaperSmall"
      >
        <ContactInfo
          onClose={toggleDrawer}
          userProfile={userProfile}
          userGroupings={userGroupings}
          showWithError={openSettingsWithError}
          toggleDrawer={toggleDrawer}
        />
      </Drawer>
      <Header />
      <div className={classes.subHeaderContainer}>
        <div className={classes.subHeaderText}>{t('settings.title')}</div>
        {userSettingsIsReady && (
          <div className={classes.textButtonContainer}>
            <TextButton
              text={t('settings.update_phone')}
              onClick={toggleDrawer}
              className={classes.updatePhoneButton}
            />
          </div>
        )}
      </div>
      {userSettingsIsReady ? renderSettings() : renderSpinner()}
      <Snack
        message={t('settings.updates_saved')}
        autoClose
        isOpen={isSuccessSnackOpen}
        onClose={closeSuccessSnack}
      />
      <Snack
        message={t('settings.updates_failed')}
        autoClose
        isOpen={isFailSnackOpen}
        onClose={closeFailSnack}
        variant="error"
      />
    </div>
  );
};

const styles = theme => ({
  body: {
    left: 0,
    position: 'relative',
    right: 0,
    paddingLeft: 150,
    paddingRight: 150,
    [theme.breakpoints.down(breakpoints.MD)]: {
      paddingLeft: 20,
      paddingRight: 20,
    },
    [theme.breakpoints.down('xs')]: {
      paddingLeft: 20,
      paddingRight: 20,
    },
    marginBottom: 150,
  },
  subHeaderContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottom: `1px solid ${colors.middle}`,
    height: 60,
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
      justifyContent: 'space-around',
    },
  },
  subHeaderText: {
    fontFamily: 'VerlagBold',
    fontSize: 20,
    color: colors.black,
    [theme.breakpoints.down('xs')]: {
      fontSize: 18,
    },
  },
  settingsContainer: {
    marginTop: 10,
    marginLeft: 40,
    borderBottom: `1px solid ${colors.middle}`,
    [theme.breakpoints.down('xs')]: {
      marginLeft: 0,
    },
  },
  textButtonContainer: {
    [theme.breakpoints.down('xs')]: {
      marginTop: 10,
      marginBottom: 15,
    },
  },
  spinnerContainer: {
    width: '100%',
    marginTop: 20,
    textAlign: 'center',
  },
  updatePhoneButton: {
    padding: '0 0 0 0',
    marginLeft: 0,
    [theme.breakpoints.up(breakpoints.MOBILE)]: {
      fontSize: 18,
    },
  },
});

Settings.propTypes = {
  classes: PropTypes.object.isRequired,
  userProfile: PropTypes.object.isRequired,
  userGroupingsIsLoading: PropTypes.bool.isRequired,
  userSettingsIsReady: PropTypes.bool.isRequired,
  fetchNotificationSettings: PropTypes.func.isRequired,
  userGroupings: PropTypes.arrayOf(PropTypes.object).isRequired,
  settings: PropTypes.object.isRequired,
  patchSettings: PropTypes.func.isRequired,
  patchSettingsRedux: PropTypes.func.isRequired,
  userId: PropTypes.string.isRequired,
};

const mapStateToProps = state => {
  return {
    userProfile: getUserProfile(state),
    userSettingsIsReady: getUserSettingsIsReady(state),
    userGroupings: getUserGroupings(state),
    userGroupingsIsLoading: getUserGroupingsIsLoading(state),
    settings: getUserSettings(state),
    userId: getProfileUserSfId(state),
  };
};

const mapDispatchToProps = dispatch => {
  const { actions } = Redux;
  return bindActionCreators(
    {
      fetchNotificationSettings: actions.fetchUserNotificationSettings,
      patchSettings: actions.patchUserNotificationSettings,
      patchSettingsRedux: actions.patchUserNotificationSettingsRedux,
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(Settings));
