import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Select from 'react-select';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

import { colors } from '../../../Core/Theme';
import Text from '../Text';

const customStyles = {
  option: (styles, { isFocused, isSelected }) => {
    let backgroundColor = colors.white;
    if (isSelected) {
      backgroundColor = colors.palette.primary.main;
    }
    if (isFocused) {
      backgroundColor = colors.palette.secondary2.main;
    }
    return {
      ...styles,
      backgroundColor,
      paddingTop: 10,
      paddingBottom: 10,
      fontFamily: 'VerlagLight',
      fontSize: 18,
      color: isSelected ? colors.white : colors.black,
      ':active': {
        backgroundColor: colors.palette.secondary2.main,
      },
      cursor: 'pointer',
    };
  },
  multiValueLabel: base => ({
    ...base,
    backgroundColor: colors.palette.primary.main,
    fontSize: 15,
    color: colors.white,
    height: 30,
    borderBottomLeftRadius: 4,
    borderTopLeftRadius: 4,
    paddingLeft: 13,
    paddingRight: 9,
    alignItems: 'center',
    display: 'flex',
  }),
  multiValueRemove: base => ({
    ...base,
    backgroundColor: colors.palette.primary.main,
    height: 30,
    paddingRight: 10,
    paddingLeft: 10,
    marginLeft: 1,
    color: colors.white,
    borderTopRightRadius: 4,
    borderBottomRightRadius: 4,
    display: 'flex',
    alignItems: 'center',
    ':hover': {
      backgroundColor: colors.palette.secondary2.main,
      color: colors.darkest,
    },
  }),
  control: (base, { isDisabled }) => {
    let border = `1px solid ${colors.middle}`;

    let color = 'black';
    if (isDisabled) {
      border = `1px solid ${colors.light}`;
      color = colors.gray;
    }
    return {
      ...base,
      borderRadius: 4,
      // This line disable the blue border
      border,
      background: 'white',
      boxShadow: 'none',
      color,
      '&:hover': {
        border,
      },
    };
  },
  selectContainer: base => ({
    ...base,
    backgroundColor: 'red',
  }),
  valueContainer: base => ({
    ...base,
    fontFamily: 'VerlagLight',
    fontSize: 18,
    padding: 0,
    paddingRight: 8,
    cursor: 'pointer',
  }),
  menu: base => ({
    ...base,
    borderRadius: 4,
    border: `solid 1px ${colors.middle}`,
    boxShadow: '0 0 4px 0 rgb(0 0 0 / 10%)',
  }),
  placeholder: base => ({
    ...base,
    color: colors.darkGray,
  }),
  singleValue: (base, { isDisabled }) => {
    let color = 'black';
    if (isDisabled) {
      color = colors.darkGray;
    }
    return {
      ...base,
      color,
    };
  },
};

const IndicatorSeparator = () => {
  return null;
};

const ClearIndicator = () => {
  return null;
};

const DropdownIndicator = () => {
  return <KeyboardArrowDownIcon />;
};

class SelectAutocomplete extends React.Component {
  handleOnChange = selectedOption => {
    const { onChange, isMultiValue } = this.props;
    if (isMultiValue) {
      return onChange(selectedOption);
    }
    return onChange([selectedOption]);
  };

  render() {
    const {
      placeholder,
      classes,
      options,
      label,
      defaultValue,
      isMultiValue,
      isClearable,
      value,
      components,
      styles,
    } = this.props;
    return (
      <div>
        <Text text={label} className={classes.labelTitle} />
        <Select
          {...this.props}
          defaultValue={defaultValue}
          closeMenuOnSelect={!isMultiValue}
          components={{
            IndicatorSeparator,
            ClearIndicator,
            DropdownIndicator,
            ...components,
          }}
          onChange={this.handleOnChange}
          options={options}
          isMulti={isMultiValue}
          styles={{ ...customStyles, ...styles }}
          placeholder={placeholder}
          isClearable={isClearable}
          value={value}
        />
      </div>
    );
  }
}

const styles = () => ({
  labelTitle: {
    fontFamily: 'VerlagBold',
    fontSize: '12px',
    marginBottom: '15px',
    cursor: 'default',
  },
});

SelectAutocomplete.defaultProps = {
  placeholder: null,
  options: [],
  onChange: () => null,
  defaultValue: null,
  label: null,
  isMultiValue: false,
  isClearable: true,
  value: null,
  components: null,
  styles: null,
};

SelectAutocomplete.propTypes = {
  placeholder: PropTypes.string,
  value: PropTypes.any,
  components: PropTypes.object,
  styles: PropTypes.object,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    })
  ),
  onChange: PropTypes.func,
  defaultValue: PropTypes.string,
  label: PropTypes.string,
  isMultiValue: PropTypes.bool,
  isClearable: PropTypes.bool,
};

export default withStyles(styles)(SelectAutocomplete);
