import Components from '../../Components';

const { containerTypes } = Components.Routes;

const RouteConfig = {
  path: '/',
  exact: true,
  name: 'Root',
  private: false,
  type: containerTypes.NAV,
};

export default RouteConfig;
