import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

import { TextButton } from '../index';
import { Icons } from '../../../resources';
import { getSfBaseUrl } from '../../../Core/Utils/linkGenerator';
import { breakpoints } from '../../../Core/Theme';

const SfLinkButton = props => {
  const { isManager, account, classes } = props;

  const openSfUrl = () => {
    const sfBaseUrl = getSfBaseUrl();
    if (account?.sfId) {
      window.open(`${sfBaseUrl}${account?.sfId}`);
    }
  };

  return isManager ? (
    <TextButton
      text={
        account?.name && (
          <>
            {account?.name}
            <Icons.ExternalLink className={classes.exportIcon} />
          </>
        )
      }
      onClick={openSfUrl}
      className={classes.accountButton}
    />
  ) : (
    account?.name || null
  );
};

const styles = theme => ({
  exportIcon: {
    height: 15,
    paddingLeft: 7,
    paddingRight: 7,
    [theme.breakpoints.up(breakpoints.MOBILE)]: { height: 19 },
  },
  accountButton: {
    fontFamily: 'VerlagBold',
    padding: 0,
    fontSize: 24,
    [theme.breakpoints.up(breakpoints.MOBILE)]: {
      fontSize: 36,
    },
  },
});
SfLinkButton.defaultProps = {
  isManager: false,
};

SfLinkButton.propTypes = {
  isManager: PropTypes.bool,
  account: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(SfLinkButton);
