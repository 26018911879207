import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { colors, breakpoints } from '../../../Core/Theme';
import { noOp } from '../../../Core/constants';

export const TRANSITION_DURATION_MS = 300;

const DrawerTab = props => {
  const {
    classes,
    onClick,
    onKeyDown,
    children,
    count,
    datatestid,
    variant,
    customClasses,
  } = props;
  const isLight = variant === 'light';

  return (
    <div
      className={clsx(
        classes.tab,
        isLight && classes.lightTab,
        customClasses.tab
      )}
      role="button"
      onClick={onClick}
      onKeyDown={onKeyDown !== noOp ? onKeyDown : onClick}
      tabIndex={0}
      datatestid={`${datatestid}_button`}
    >
      {count !== null ? (
        <div className={classes.countWrapper}>
          <span
            className={classes.count}
            datatestid={`${datatestid}_button_count`}
          >
            {count}
          </span>
        </div>
      ) : null}
      <span
        className={classes.tabContents}
        datatestid={`${datatestid}_button_content`}
      >
        {children}
      </span>
    </div>
  );
};

DrawerTab.defaultProps = {
  onKeyDown: noOp,
  customClasses: {},
  children: null,
  datatestid: '',
  count: null,
  variant: 'default',
};

DrawerTab.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  customClasses: PropTypes.shape({ tab: PropTypes.string }),
  onClick: PropTypes.func.isRequired,
  onKeyDown: PropTypes.func,

  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  datatestid: PropTypes.string,
  count: PropTypes.number,
  variant: PropTypes.string,
};

const styles = theme => ({
  tab: {
    right: -35,

    paddingLeft: 15,
    paddingTop: 10,
    paddingBottom: 10,

    backgroundColor: colors.darkest,
    color: colors.white,
    borderRadius: 19,
    boxShadow: `0 0 3px 0 rgba(0, 0, 0, 0.5)`,
    fontFamily: 'VerlagBook',
    fontSize: '15px',
    lineHeight: '15px',
    zIndex: 10,
    cursor: 'pointer',

    display: 'flex',
    alignItems: 'baseline',
    '&:focus:not(.focus-visible)': {
      outline: 'none',
    },
    [theme.breakpoints.up(breakpoints.MOBILE)]: {
      paddingRight: 50,
      top: 10,
      transform: 'translate(7px, 0px)',
      transitionDuration: `${TRANSITION_DURATION_MS}ms`,
      '&:hover': {
        transform: 'translate(-7px, 0px)',
        transitionDuration: `${TRANSITION_DURATION_MS}ms`,
      },
      position: 'absolute',
    },
    [theme.breakpoints.down(breakpoints.MOBILE)]: {
      position: 'fixed',
      bottom: 15,
      paddingRight: 45,
    },
  },
  tabContents: {
    marginBottom: -1,
  },

  countWrapper: {
    minWidth: 20,
    height: 18,
    paddingTop: 1,
    paddingBottom: 1,
    borderRadius: '50%',
    background: colors.palette.secondary.main,
    marginRight: 7,
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  count: {
    color: colors.black,
    fontSize: 15,
    fontFamily: 'VerlagBold',
  },
  lightTab: {
    background: colors.white,
    color: colors.black,
    opacity: 0.9,
  },
});

export default withStyles(styles)(DrawerTab);
