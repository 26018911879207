import { PatchOfficeDto, UpdateOfficeDto } from './dto/Office';
import { OfficeDto } from './dto';
import { Http, stringifyUrl } from '../Utils';

export const fetchOffices = (params: {
  locationSfId?: string;
  withPendingStatus?: boolean;
  withOccupiedStatus?: boolean;
  withSuites?: boolean;
  groupingId?: string[];
  floorId?: string;
  integratedOnly?: boolean;
}) => {
  const {
    locationSfId,
    withPendingStatus,
    withOccupiedStatus,
    withSuites,
    groupingId,
    floorId,
  } = params;

  const objSchema = {
    location_sfid: locationSfId,
    grouping_id: groupingId,
    floor_id: floorId,
    with_pending_status: withPendingStatus ?? false,
    with_occupied_status: withOccupiedStatus ?? false,
    with_suites: withSuites ?? false,
  };

  const strQueryUrl = stringifyUrl('/offices', objSchema);

  return Http.get<OfficeDto[]>(strQueryUrl);
};

export const activateReservableOffice = (
  officeSfId: string,
  groupingId: string
) => {
  return Http.post<OfficeDto>(`offices/${officeSfId}/oasis`, { groupingId });
};

export const deactivateReservableOffice = (officeSfId: string) => {
  return Http.remove<OfficeDto>(`offices/${officeSfId}/oasis`);
};

export const fetchFloorMapsOffices = (params: {
  locationSfId?: string;
  onlyIncludeOasis?: boolean;
  includeUnmapped?: boolean;
  groupingIds?: string[];
  floorId?: string;
  integratedOnly?: boolean;
}) => {
  const {
    locationSfId,
    onlyIncludeOasis,
    includeUnmapped,
    groupingIds,
    floorId,
    integratedOnly,
  } = params;
  const queryParams = {
    location_sfid: locationSfId,
    only_include_oasis_reservable: onlyIncludeOasis,
    include_unmapped: includeUnmapped,
    grouping_ids: groupingIds,
    floor_id: floorId,
    integrated_only: integratedOnly,
  };

  const strQueryUrl = stringifyUrl('/offices/floor_maps', queryParams);

  return Http.get<OfficeDto[]>(strQueryUrl);
};

export const patchOffice = (sfId: string, office: PatchOfficeDto) => {
  return Http.patch<OfficeDto>(`/offices/${sfId}`, office);
};

export const updateOffice = (sfId: string, office: UpdateOfficeDto) => {
  return Http.put<OfficeDto>(`/offices/${sfId}`, office);
};
