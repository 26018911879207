import typeToReducer from 'type-to-reducer';
import Lodash from 'lodash';
import Moment from 'moment';

import * as types from './actionTypes';
import { HomeRoute } from '../../../Core/constants';

export const ERROR_PERSIST_LENGTH = 1; // How long api errors are considered valid after addressed in minutes

const initialState = {
  token: '',
  id: '',
  mail: '',
  profile: {
    loading: false,
    hasError: false,
  },
  isRememberMe: false,
  isUserActive: false,
  loading: false,
  newTz: null,
  isFirstLogin: false,
  currentPathname: HomeRoute,
  notificationSettings: {},
  notificationSettingsLoadingState: {
    notificationSettingsLoading: false,
    notificationSettingsHasError: false,
    patchingNotifications: 0,
    patchNotificationsError: false,
  },
  profileLoadingState: {
    loading: false,
    hasError: false,
  },
  accountSettingsLoadingState: {
    isLoading: false,
    hasError: false,
    isUpdating: false,
  },
  accountSettings: {},
  hasTriggeredCustomDimensions: false,
  apiErrors: [],
  customErrorDescription: '',
  clientAccessClaims: {},
};

// SET_USER_TOKEN
export const onSetUserToken = (state, action) => {
  const userTokenPayload = Lodash.get(action, ['payload'], '');

  return {
    ...state,
    token: userTokenPayload,
  };
};

// LOG_OUT
export const onUserLogOut = state => {
  const savedMail = localStorage.getItem('mail');
  const isRememberMe = localStorage.getItem('isRememberMe');
  localStorage.clear();
  if (isRememberMe === 'true') {
    localStorage.setItem('mail', savedMail);
    localStorage.setItem('isRememberMe', isRememberMe);
  }

  return {
    ...state,
    id: '',
    profile: {},
    token: '',
  };
};

// LOG_IN
export const onUserLogIn = (state, action) => {
  const savedMail = Lodash.get(action, ['payload'], '');
  return {
    ...state,
    mail: savedMail,
  };
};

// SET_USER_PROFILE
export const onSetUserProfile = (state, action) => {
  const userProfilePayload = Lodash.get(action, ['payload'], '');
  return {
    ...state,
    profile: { ...state.profile, ...userProfilePayload },
  };
};

// SET_USER_ID
export const onSetUserId = (state, action) => {
  const userIdPayload = Lodash.get(action, ['payload'], '');
  return {
    ...state,
    id: userIdPayload,
  };
};

// SET_USER_ACTIVE
export const onSetUserActive = (state, action) => {
  const userActivePayload = Lodash.get(action, ['payload'], '');
  return {
    ...state,
    isUserActive: userActivePayload,
  };
};

// TOGGLE_EMAIL_OPT_OUT
export const onToggleEmailOptOut = state => {
  return {
    ...state,
    profile: { ...state.profile, emailOptOut: !state.profile.emailOptOut },
  };
};

// SET_TIMEZONE
export const onSetTimeZone = (state, action) => {
  const timeZonePayload = Lodash.get(action, ['payload'], null);
  return {
    ...state,
    newTz: timeZonePayload,
  };
};

export const setIsFirstLogin = (state, action) => {
  const isFirstLogin = Lodash.get(action, ['payload'], null);
  return {
    ...state,
    isFirstLogin,
  };
};

export const updateUserAccount = (state, action) => {
  const accountPayload = Lodash.get(action, ['payload'], null);
  return {
    ...state,
    profile: {
      ...state.profile,
      account: { ...state.profile.account, ...accountPayload },
    },
  };
};

export const setCurrentPath = (state, action) => {
  const { payload } = action;
  return {
    ...state,
    currentPathname: payload,
  };
};

export const onLoadUserNotificationSettings = state => {
  return {
    ...state,
    notificationSettingsLoadingState: {
      ...state.notificationSettingsLoadingState,
      notificationSettingsLoading: true,
    },
  };
};

export const onFetchUserNotificationSettings = (state, action) => {
  const payload = Lodash.get(action, 'payload', {});
  return {
    ...state,
    notificationSettings: payload,
    notificationSettingsLoadingState: {
      ...state.notificationSettingsLoadingState,
      notificationSettingsLoading: false,
      notificationSettingsHasError: false,
    },
  };
};

export const onLoadUserNotificationSettingsRejected = state => {
  return {
    ...state,
    notificationSettingsLoadingState: {
      ...state.notificationSettingsLoadingState,
      notificationSettingsLoading: false,
      notificationSettingsHasError: true,
    },
  };
};

export const onPatchUserNotificationSettings = state => {
  return {
    ...state,
    notificationSettingsLoadingState: {
      ...state.notificationSettingsLoadingState,
      patchingNotifications:
        state.notificationSettingsLoadingState.patchingNotifications + 1,

      patchNotificationsError: false,
    },
  };
};

export const onPatchUserNotificationsFulfilled = (state, action) => {
  const payload = Lodash.get(action, 'payload', {});
  return {
    ...state,
    notificationSettings: payload,
    notificationSettingsLoadingState: {
      ...state.notificationSettingsLoadingState,
      patchingNotifications:
        state.notificationSettingsLoadingState.patchingNotifications - 1,
      patchNotificationsError: false,
    },
  };
};

export const onPatchUserNotificationsRejected = state => {
  return {
    ...state,
    notificationSettingsLoadingState: {
      ...state.notificationSettingsLoadingState,
      patchingNotifications:
        state.notificationSettingsLoadingState.patchingNotifications - 1,
      patchNotificationsError: true,
    },
  };
};

export const patchUserNotifications = (state, action) => {
  const payload = Lodash.get(action, 'payload', {});
  return {
    ...state,
    notificationSettings: payload,
  };
};

export const onPatchUserProfile = state => {
  return {
    ...state,
    profileLoadingState: {
      ...state.profileLoadingState,
      loading: true,
    },
  };
};

export const onPatchUserProfileFulfilled = (state, action) => {
  const payload = Lodash.get(action, 'payload', {});
  return {
    ...state,
    profile: payload,
    profileLoadingState: {
      ...state.profileLoadingState,
      loading: false,
      hasError: false,
    },
  };
};

export const onPatchUserProfileRejected = state => {
  return {
    ...state,
    profileLoadingState: {
      ...state.profileLoadingState,
      loading: false,
      hasError: true,
    },
  };
};

export const onLoadingUserProfile = state => {
  return {
    ...state,
    profile: {
      ...state.profile,
      loading: true,
      hasError: false,
    },
  };
};

export const onFetchUserProfile = (state, action) => {
  const userProfilePayload = Lodash.get(action, 'payload', {});
  return {
    ...state,
    profile: {
      ...userProfilePayload,
      loading: false,
      hasError: false,
    },
  };
};

export const onLoadUserProfileRejected = state => {
  return {
    ...state,
    profile: {
      ...state.profile,
      loading: false,
      hasError: true,
    },
  };
};

export const onFetchAccountSettingsPending = state => {
  return {
    ...state,
    accountSettingsLoadingState: {
      ...state.accountSettingsLoadingState,
      isLoading: true,
      hasError: false,
    },
  };
};

export const onFetchAccountSettingsFulfilled = (state, action) => {
  const payload = Lodash.get(action, 'payload', {});
  return {
    ...state,
    accountSettings: payload,
    accountSettingsLoadingState: {
      ...state.accountSettingsLoadingState,
      isLoading: false,
      hasError: false,
    },
  };
};

export const onFetchAccountSettingsRejected = state => {
  return {
    ...state,
    accountSettingsLoadingState: {
      ...state.accountSettingsLoadingState,
      isLoading: false,
      hasError: true,
      isUpdating: false,
    },
  };
};

export const onPatchAccountSettingsPending = state => {
  return {
    ...state,
    accountSettingsLoadingState: {
      ...state.accountSettingsLoadingState,
      isLoading: false,
      isUpdating: true,
      hasError: false,
    },
  };
};

const removeOldErrors = errors => {
  const tenMinutesAgo = Moment()
    .subtract(ERROR_PERSIST_LENGTH, 'minutes')
    .unix();
  // remove errors that have an an addressed epoch thats later than 10 minutes ago
  // closing the error message or refreshing address all existing errors
  return errors.filter(
    err => !err.addressedEpoch || err.addressedEpoch >= tenMinutesAgo
  );
};

export const onAddApiError = (state, action) => {
  const newError = Lodash.get(action, 'payload', {});
  const errorsNotOldErrors = removeOldErrors(state.apiErrors);
  return {
    ...state,
    apiErrors: [...errorsNotOldErrors, newError],
  };
};

export const onMarkAsAddressed = state => {
  const now = Moment().unix();
  const errorsNotOldErrors = removeOldErrors(state.apiErrors);

  return {
    ...state,
    customErrorDescription: '',
    apiErrors: errorsNotOldErrors.map(err => ({
      ...err,
      hasBeenAddressed: true,
      addressedEpoch: err.addressedEpoch || now,
    })),
  };
};

export const onAddCustomErrorDescription = (state, action) => {
  return {
    ...state,
    customErrorDescription: action.payload,
  };
};

export const onSetClientAccessClaims = (state, action) => {
  return {
    ...state,
    clientAccessClaims: action.payload,
  };
};

export const onPatchAccountSettingsFulfilled = (state, action) => {
  const updatedSetting = Lodash.get(action, 'payload', {});
  const filteredSettings = state?.accountSettings.filter(
    setting => updatedSetting?.settingName !== setting?.settingName
  );
  return {
    ...state,
    accountSettings: [...filteredSettings, updatedSetting],
    accountSettingsLoadingState: {
      ...state.accountSettingsLoadingState,
      isLoading: false,
      hasError: false,
      isUpdating: false,
    },
  };
};

const contentReducer = typeToReducer(
  {
    [types.FETCH_USER_PROFILE]: {
      PENDING: onLoadingUserProfile,
      FULFILLED: onFetchUserProfile,
      REJECTED: onLoadUserProfileRejected,
    },
    [types.FETCH_USER_NOTIFICATION_SETTINGS]: {
      PENDING: onLoadUserNotificationSettings,
      FULFILLED: onFetchUserNotificationSettings,
      REJECTED: onLoadUserNotificationSettingsRejected,
    },
    [types.PATCH_USER_NOTIFICATION_SETTINGS]: {
      PENDING: onPatchUserNotificationSettings,
      FULFILLED: onPatchUserNotificationsFulfilled,
      REJECTED: onPatchUserNotificationsRejected,
    },
    [types.PATCH_USER_PROFILE]: {
      PENDING: onPatchUserProfile,
      FULFILLED: onPatchUserProfileFulfilled,
      REJECTED: onPatchUserProfileRejected,
    },
    [types.SET_USER_ACTIVE]: onSetUserActive,
    [types.SET_USER_PROFILE]: onSetUserProfile,
    [types.SET_TIMEZONE]: onSetTimeZone,
    [types.SET_USER_ID]: onSetUserId,
    [types.LOG_IN]: onUserLogIn,
    [types.LOG_OUT]: onUserLogOut,
    [types.SET_USER_TOKEN]: onSetUserToken,
    [types.TOGGLE_EMAIL_OPT_OUT]: onToggleEmailOptOut,
    [types.UPDATE_USER_ACCOUNT]: updateUserAccount,
    [types.SET_IS_FIRST_LOGIN]: setIsFirstLogin,
    [types.SET_CURRENT_PATH]: setCurrentPath,
    [types.PATCH_USER_NOTIFICATION_SETTINGS_REDUX]: patchUserNotifications,
    [types.FETCH_ACCOUNT_SETTINGS]: {
      PENDING: onFetchAccountSettingsPending,
      FULFILLED: onFetchAccountSettingsFulfilled,
      REJECTED: onFetchAccountSettingsRejected,
    },
    [types.PATCH_ACCOUNT_SETTINGS]: {
      PENDING: onPatchAccountSettingsPending,
      FULFILLED: onPatchAccountSettingsFulfilled,
      REJECTED: onFetchAccountSettingsRejected,
    },
    [types.ADD_API_ERROR]: onAddApiError,
    [types.ADDRESS_API_ERROR]: onMarkAsAddressed,
    [types.SET_CUSTOM_ERROR_DESCRIPTION]: onAddCustomErrorDescription,
    [types.SET_CLIENT_ACCESS_CLAIMS]: onSetClientAccessClaims,
  },
  initialState
);
export default contentReducer;
