import { useEffect, useState } from 'react';

import { AmenityDto, fetchAmenities } from '../Core/Api';

export const useAmenities = () => {
  const [amenities, setAmenities] = useState<AmenityDto[]>();

  useEffect(() => {
    fetchAmenities().then(response => setAmenities(response.data));
  }, []);

  return { amenities };
};
