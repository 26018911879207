import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { useMemo } from 'react';

import { getGoogleMapsLink } from '../../../Core/Utils';
import TextButton from '../TextButton';
import { getIsStandalone } from '../../../Models/groupings';
import { Icons } from '../../../resources';
import { breakpoints } from '../../../Core/Theme';

const MapLink = props => {
  const {
    classes,
    location,
    profile,
    grouping,
    className,
    variant,
    withExternalIcon,
    ...rest
  } = props;

  const handleOnLocationPress = e => {
    e.stopPropagation();
    window.open(getGoogleMapsLink(location, profile?.brand?.name));
  };
  const getLocationMapLabel = useMemo(() => {
    if (variant === 'address') {
      return [
        location?.address,
        location?.city,
        location?.state,
        location?.zipCode,
      ]
        .filter(a => a)
        .join(', ');
    }
    return grouping && getIsStandalone(grouping)
      ? grouping?.groupingName
      : location?.externalName;
  }, [variant, location]);

  return (
    <TextButton
      className={[classes.link, className]}
      onClick={handleOnLocationPress}
      text={
        <>
          {getLocationMapLabel}
          {withExternalIcon ? (
            <Icons.ExternalLink className={classes.icon} />
          ) : null}
        </>
      }
      {...rest}
    />
  );
};

const styles = theme => ({
  link: {
    padding: 0,
  },
  icon: {
    height: 15,
    paddingLeft: 7,
    paddingRight: 7,
    [theme.breakpoints.up(breakpoints.MOBILE)]: { height: 12 },
  },
});
MapLink.defaultProps = {
  grouping: {},
  variant: 'name',
  withExternalIcon: false,
};

MapLink.propTypes = {
  profile: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  grouping: PropTypes.object,
  variant: PropTypes.string,
  withExternalIcon: PropTypes.bool,
};

export default withStyles(styles)(MapLink);
