import { Component } from 'react';
import PropTypes from 'prop-types';

class Image extends Component {
  constructor(props) {
    super(props);

    this.state = {
      errored: false,
    };
  }

  onError = () => {
    const { errored } = this.state;
    if (!errored) {
      this.setState({
        errored: true,
      });
    }
  };

  render() {
    const { errored } = this.state;
    const { src, fallbackSrc, alt, ...props } = this.props;

    return (
      <img
        src={errored ? fallbackSrc : src}
        onError={this.onError}
        {...props}
        alt={alt}
      />
    );
  }
}

Image.propTypes = {
  src: PropTypes.string.isRequired,
  fallbackSrc: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
};

export default Image;
