/* eslint-disable no-console */
import * as React from 'react';
import { captureException } from '@sentry/react';
import { withRouter } from 'react-router-dom';

import FailWhale from '../FailWhale';
import { isProdEnvironment } from '../../../Core/Utils';

class ErrorBoundary extends React.Component {
  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  constructor(props) {
    super(props);
    this.state = { hasError: false, isProd: isProdEnvironment() };
  }

  componentDidMount() {
    const { hasError } = this.state;
    const { history } = this.props;
    this.unlisten = history.listen(() => {
      if (hasError) {
        this.setState({ hasError: false });
      }
    });
  }

  componentWillUnmount() {
    this.unlisten();
  }

  componentDidCatch(error, errorInfo) {
    const { isProd } = this.state;
    // You can also log the error to an error reporting service
    captureException({ error, errorInfo });
    if (!isProd) {
      console.log(error);
    }
  }

  render() {
    const { hasError } = this.state;
    if (hasError) {
      // You can render any custom fallback UI
      return <FailWhale fullpage />;
    }
    const { children } = this.props;

    return children;
  }
}

export default withRouter(ErrorBoundary);
