import { createSelector } from 'reselect';
import Lodash from 'lodash';

import { getIsManager } from '../../../Core/Utils/userPermissions';
import { HomeRoute } from '../../../Core/constants';
import { LOGIN_PATH } from '../../../Containers/Login/constants';

export const getUserManager = state => Lodash.get(state, ['userManager'], {});

export const getUserId = createSelector(
  getUserManager,
  objUserManager => objUserManager.id
);

export const getUserToken = createSelector(
  getUserManager,
  objUserManager => objUserManager.token
);

export const getUserProfile = createSelector(
  getUserManager,
  objUserManager => objUserManager.profile
);
export const getUserProfileAccount = createSelector(
  getUserProfile,
  objUserProfile => Lodash.get(objUserProfile, ['account'], {})
);

export const getUserAccounts = createSelector(getUserProfile, objUserProfile =>
  Lodash.get(objUserProfile, ['accounts'], [])
);

export const getHasUnlimitedHours = createSelector(
  getUserProfileAccount,
  objUserProfile => Lodash.get(objUserProfile, ['hasUnlimitedHours'], false)
);

export const getUserProfileAccountSfId = createSelector(
  getUserProfileAccount,
  objUserProfileAccount => Lodash.get(objUserProfileAccount, ['sfId'], {})
);

export const getUserProfileIsLoading = createSelector(getUserManager, obj =>
  Lodash.get(obj, ['profileLoadingState', 'loading'], false)
);

export const getUserProfileHasError = createSelector(getUserManager, obj =>
  Lodash.get(obj, ['profileLoadingState', 'hasError'], false)
);

export const getUserProfileLocation = createSelector(
  getUserProfile,
  objUserProfile => Lodash.get(objUserProfile, ['primaryLocation'], {})
);

export const getStatusUser = createSelector(getUserManager, objUserManager =>
  Lodash.get(objUserManager, ['isUserActive'], {})
);

export const getProfileUserId = createSelector(getUserManager, objUserManager =>
  Lodash.get(objUserManager, ['profile', 'IdUser'], 0)
);

export const getProfileUserSfId = createSelector(
  getUserManager,
  objUserManager => Lodash.get(objUserManager, ['profile', 'sfId'], 0)
);

export const getProfileLocationSfId = createSelector(
  getUserProfileLocation,
  objUserLocation => Lodash.get(objUserLocation, ['sfId'], '')
);

export const getUserFirstName = createSelector(getUserProfile, objUserProfile =>
  Lodash.get(objUserProfile, ['firstName'], {})
);

export const getIsFirstLogin = createSelector(
  getUserManager,
  objUserManager => objUserManager.isFirstLogin
);

export const getCurrentPathname = createSelector(
  getUserManager,
  objUserManager => Lodash.get(objUserManager, ['currentPathname'], '')
);

export const getUserSettings = createSelector(
  getUserManager,
  objectUserManager => Lodash.get(objectUserManager, 'notificationSettings', {})
);

export const getUserSettingsIsReady = createSelector(
  getUserSettings,
  userSettings => !Lodash.isEmpty(userSettings)
);

export const getSecondaryUserRole = createSelector(
  getUserAccounts,
  listAccounts => {
    const listAccountRoles = Lodash.get(listAccounts, [0, 'roles'], []);
    return listAccountRoles.join(', ');
  }
);

export const getSettingsPatchError = createSelector(
  getUserManager,
  objectUserManager =>
    Lodash.get(
      objectUserManager,
      ['notificationSettingsLoadingState', 'patchNotificationsError'],
      false
    )
);

export const getSettingsPatchIsLoading = createSelector(
  getUserManager,
  objectUserManager =>
    Lodash.get(
      objectUserManager,
      ['notificationSettingsLoadingState', 'patchingNotifications'],
      0
    ) > 0
);

export const getAccountSettings = createSelector(
  getUserManager,
  objectUserManager => Lodash.get(objectUserManager, ['accountSettings'], {})
);

export const getAccountSettingsIsLoading = createSelector(
  getUserManager,
  objectUserManager =>
    Lodash.get(
      objectUserManager,
      ['accountSettingsLoadingState', 'isLoading'],
      false
    )
);

export const getApiErrors = createSelector(getUserManager, objectUserManager =>
  Lodash.get(objectUserManager, ['apiErrors'], {})
);

export const getUnAddressedApiErrors = createSelector(
  getApiErrors,
  arrApiErrors => arrApiErrors.filter(err => !err.hasBeenAddressed)
);

export const getCustomErrorDescription = createSelector(
  getUserManager,
  objectUserManager =>
    Lodash.get(objectUserManager, ['customErrorDescription'], {})
);

export const selectIsManager = createSelector(getUserProfile, profile =>
  getIsManager(profile)
);

export const getCanAccessMemberPortal = createSelector(
  getUserManager,
  objectUserManager =>
    objectUserManager.clientAccessClaims?.can_access_member_portal
);

export const getCanAccessBillingPortal = createSelector(
  getUserManager,
  objectUserManager =>
    objectUserManager.clientAccessClaims?.can_access_billing_portal
);

export const getUsersDefaultRoute = createSelector(
  getCanAccessMemberPortal,
  getCanAccessBillingPortal,
  canAccessMemberPortal => {
    if (canAccessMemberPortal) {
      return HomeRoute;
    }
    return LOGIN_PATH;
  }
);
