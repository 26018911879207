import axios, { AxiosRequestConfig } from 'axios';

export interface RequestConfig {
  headers?: any;
  data?: any;
}

export const get = <T>(url: string) => {
  return axios.get<T>(url);
};

export const remove = <T>(url: string, config?: RequestConfig) => {
  return axios.delete<T>(url, config);
};

export const put = <T>(url: string, data?: any, config?: RequestConfig) => {
  return axios.put<T>(url, data, config);
};

export const post = <T>(
  url: string,
  data?: any,
  config?: AxiosRequestConfig
) => {
  return axios.post<T>(url, data, config);
};

export const patch = <T>(url: string, data?: any) => {
  return axios.patch<T>(url, data);
};
