/* eslint-disable jsx-a11y/no-static-element-interactions */
import * as React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import ExpandMore from '@material-ui/icons/ExpandMore';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import { colors } from '../../../Core/Theme';
import Text from '../Text';
import { customProps } from '../../../Core/Utils';

const handleOnKeyDown = setAnchorEl => e => {
  if (e.key === ' ') {
    setAnchorEl(e.currentTarget);
  }
};
const SplitButton = ({ classes, items, label, onItemPress, onButtonPress }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const { t } = useTranslation();

  const onItemMenuPress = navTab => {
    setAnchorEl(null);
    onItemPress(navTab?.path, navTab?.search, navTab.isExternal);
  };

  const renderMenuItems = items.map((navRoutes, index) => {
    const lastItem = items.length - 1;
    return (
      <div className={index !== lastItem && classes.menuItemDivider}>
        {navRoutes.map(navTab => {
          return (
            <MenuItem
              selected={false}
              className={classes.menuItem}
              onClick={() => onItemMenuPress(navTab)}
            >
              <Text textStyle="h6" text={t(navTab?.name)} />
            </MenuItem>
          );
        })}
      </div>
    );
  });

  return (
    <div className={clsx(classes.container)}>
      <Button variant="text" className={classes.button} onClick={onButtonPress}>
        {label}
      </Button>
      <span
        className={classes.icon}
        onClick={e => setAnchorEl(e.currentTarget)}
        onKeyDown={handleOnKeyDown(setAnchorEl)}
      >
        <ExpandMore className={classes.expandMore} />
      </span>
      <Menu
        variant="menu"
        disableAutoFocusItem
        open={Boolean(anchorEl)}
        getContentAnchorEl={null}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        onClose={() => setAnchorEl(null)}
        classes={{
          paper: clsx(classes.menu),
        }}
      >
        {renderMenuItems}
      </Menu>
    </div>
  );
};

const styles = () => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    color: colors.palette.primary.main,
    backgroundColor: colors.white,
    borderColor: colors.palette.primary.main,
    borderWidth: 2,
    border: 'solid 2px',
    boxShadow: 'none',
    textAlign: 'center',
    padding: 0,
    height: '40px',
    borderRadius: '100px',
  },
  button: {
    color: colors.palette.primary.main,
    fontFamily: 'VerlagBold',
    textTransform: 'none',
    fontSize: 16,
    flex: 1,
    alignItems: 'center',
    textAlign: 'center',
    justifyContent: 'center',
    borderRadius: '100px 0px 0px 100px',
    '&:hover': {
      borderColor: colors.palette.primary.light,
      backgroundColor: '#e4edec',
    },
    '&:focus': {
      boxShadow: 'none',
      backgroundColor: colors.white,
    },
  },
  icon: {
    borderLeft: '12px solid',
    height: '100%',
    borderWidth: 2,
    alignItems: 'center',
    display: 'flex',
    borderRadius: '0px 100px 100px 0px',
    '&:hover': {
      borderColor: colors.palette.primary.light,
      backgroundColor: '#e4edec',
    },
    '&:focus': {
      boxShadow: 'none',
      backgroundColor: colors.white,
    },
  },
  expandMore: {
    padding: 5,
  },
  menu: {
    boxShadow: `0 0 3px 0 rgba(0, 0, 0, 0.25)`,
    borderRadius: 4,
    width: '191px',
    marginTop: '2px',
  },
  menuItem: {
    fontFamily: 'VerlagBook',
    fontSize: 14,
    backgroundColor: 'white',
  },
  menuItemDivider: {
    borderBottom: `1px solid ${colors.middle}`,
  },
  menuContainer: {
    alignItems: 'center',
    justifyContent: 'center',
  },
});

SplitButton.propTypes = {
  items: customProps.topNavList.isRequired,
  label: PropTypes.string.isRequired,
  onItemPress: PropTypes.func.isRequired,
  onButtonPress: PropTypes.func.isRequired,
};

export default withStyles(styles)(SplitButton);
