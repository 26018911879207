import typeToReducer from 'type-to-reducer';
import Lodash from 'lodash';
import * as types from './actionTypes';
import { normalizeReservations, NormObjCstr } from './schema';

const initialState = {
  data: new NormObjCstr({}, []),
  owners: new NormObjCstr({}, []),
  addons: new NormObjCstr({}, []),
  desks: new NormObjCstr({}, []),
  offices: new NormObjCstr({}, []),
  sets: new NormObjCstr({}, []),
  loading: false,
  hasError: false,
  total: null,
  offset: null,
  limit: null,
};

export const onLoadingReservationsPending = state => {
  return {
    ...state,
    loading: true,
  };
};

export const onLoadingReservationsFulfilled = (state, action) => {
  const { payload } = action;
  const { reservations } = payload;
  const reservationsNormalized = normalizeReservations(reservations);

  const existingReservationsById = Lodash.get(state, ['data', 'byId'], {});
  const existingReservationsAllIds = Lodash.get(state, ['data', 'allIds'], []);
  const newReservationsById = {
    ...existingReservationsById,
    ...reservationsNormalized.data.byId,
  };
  const newReservationsAllIds = Lodash.uniqBy(
    existingReservationsAllIds.concat(reservationsNormalized.data.allIds)
  );

  const exisitingSetsById = Lodash.get(state, ['sets', 'byId'], {});
  const existingSetsAllId = Lodash.get(state, ['sets', 'allIds'], []);
  const newSetsById = {
    ...exisitingSetsById,
    ...reservationsNormalized.sets.byId,
  };
  const newSetsAllIds = Lodash.uniqBy(
    existingSetsAllId.concat(reservationsNormalized.sets.allIds)
  );
  const exisitingOwnersById = Lodash.get(state, ['owners', 'byId'], {});
  const existingOwnersAllId = Lodash.get(state, ['owners', 'allIds'], []);
  const newOwnersById = {
    ...exisitingOwnersById,
    ...reservationsNormalized.owners.byId,
  };
  const newOwnersAllIds = Lodash.uniqBy(
    existingOwnersAllId.concat(reservationsNormalized.owners.allIds)
  );

  const exisitingDesksById = Lodash.get(state, ['desks', 'byId'], {});
  const existingDesksAllId = Lodash.get(state, ['desks', 'allIds'], []);
  const newDesksById = {
    ...exisitingDesksById,
    ...reservationsNormalized.desks.byId,
  };

  const newDesksAllIds = Lodash.uniqBy(
    existingDesksAllId.concat(reservationsNormalized.desks.allIds)
  );

  const exisitingOfficesById = Lodash.get(state, ['offices', 'byId'], {});
  const existingOfficesAllId = Lodash.get(state, ['offices', 'allIds'], []);
  const newOfficesById = {
    ...exisitingOfficesById,
    ...reservationsNormalized.offices.byId,
  };
  const newOfficesAllIds = Lodash.uniqBy(
    existingOfficesAllId.concat(reservationsNormalized.offices.allIds)
  );

  const exisitingAddonsById = Lodash.get(state, ['addons', 'byId'], {});
  const existingAddonsAllId = Lodash.get(state, ['addons', 'allIds'], []);
  const newAddonsById = {
    ...exisitingAddonsById,
    ...reservationsNormalized.addons.byId,
  };

  const newAddonsAllIds = Lodash.uniqBy(
    existingAddonsAllId.concat(reservationsNormalized.addons.allIds)
  );

  if (reservations.length) {
    return {
      ...state,
      data: new NormObjCstr(newReservationsById, newReservationsAllIds),
      sets: new NormObjCstr(newSetsById, newSetsAllIds),
      owners: new NormObjCstr(newOwnersById, newOwnersAllIds),
      desks: new NormObjCstr(newDesksById, newDesksAllIds),
      offices: new NormObjCstr(newOfficesById, newOfficesAllIds),
      addons: new NormObjCstr(newAddonsById, newAddonsAllIds),
      loading: false,
      hasError: false,
    };
  }
  return {
    ...state,
    loading: false,
    hasError: false,
  };
};

export const onLoadingReservationsRejected = state => {
  return {
    ...state,
    loading: false,
    hasError: true,
  };
};

export const onDeleteReservation = (state, action) => {
  const deletedId = action.payload;
  const existingReservationsById = Lodash.get(state, ['data', 'byId'], {});
  const existingReservationsAllIds = Lodash.get(state, ['data', 'allIds'], []);

  const updatedReservationsById = Lodash.omit(
    existingReservationsById,
    deletedId
  );
  const updatedReservationsAllIds = existingReservationsAllIds.filter(
    id => id !== deletedId
  );
  return {
    ...state,
    data: new NormObjCstr(updatedReservationsById, updatedReservationsAllIds),
  };
};

const onCreateReservations = (state, action) => {
  const reservations = Lodash.get(action, 'payload', []);
  return onLoadingReservationsFulfilled(state, {
    payload: { reservations },
  });
};

const onUpdateReservation = (state, action) => {
  const reservation = Lodash.get(action, 'payload', []);
  return onLoadingReservationsFulfilled(state, {
    payload: { reservations: [reservation] },
  });
};

export const onDeleteReservations = (state, action) => {
  const deletedIds = action.payload;
  const existingReservationsById = Lodash.get(state, ['data', 'byId'], {});
  const existingReservationsAllIds = Lodash.get(state, ['data', 'allIds'], []);

  const updatedReservationsById = Lodash.omit(
    existingReservationsById,
    deletedIds
  );
  const updatedReservationsAllIds = existingReservationsAllIds.filter(
    id => !deletedIds.includes(id)
  );
  return {
    ...state,
    data: new NormObjCstr(updatedReservationsById, updatedReservationsAllIds),
  };
};

const contentReducer = typeToReducer(
  {
    [types.LOAD_RESERVATION]: {
      PENDING: onLoadingReservationsPending,
      FULFILLED: onLoadingReservationsFulfilled,
      REJECTED: onLoadingReservationsRejected,
    },
    [types.DELETE_RESERVATION]: onDeleteReservation,
    [types.DELETE_RESERVATIONS]: onDeleteReservations,
    [types.CREATE_RESERVATIONS]: onCreateReservations,
    [types.UPDATE_RESERVATION]: onUpdateReservation,
  },
  initialState
);

export default contentReducer;
