/* eslint-disable react/no-array-index-key */
import React from 'react';
import PropTypes from 'prop-types';

// Components
import { MapLayerWrapper as Layer } from '../../Common';

// Constants
import { LAYERS } from '../Constants';

// Theme & Utils
import { colors } from '../../../Core/Theme';
import { createHash } from '../../../Core/Utils';

// Layer properties
const fillProperties = {
  id: `${LAYERS.OFFICE}-lines`,
  type: 'line',
  source: 'features',
  paint: {
    'line-color': colors.gray,
    'line-width': 6,
  },
};
const lineProperties = {
  id: LAYERS.OFFICE,
  type: 'fill',
  source: 'features',
  paint: {
    'fill-color': [
      'case',
      ['boolean', ['feature-state', 'hover'], false],
      colors.lightest,
      colors.white,
    ],
  },
};

const Office = props => {
  const { offices, unassigned } = props;
  return (
    <Layer
      key={`${LAYERS.OFFICE}-${createHash([...offices, ...unassigned])}`}
      properties={[fillProperties, lineProperties]}
      filter={['in', ['get', 'id'], ['literal', offices]]}
      {...props}
    />
  );
};

Office.defaultProps = {
  offices: [],
  unassigned: [],
};
Office.propTypes = {
  offices: PropTypes.arrayOf(PropTypes.string),
  unassigned: PropTypes.arrayOf(PropTypes.string),
};

export default React.memo(Office);
