import typeToReducer from 'type-to-reducer';

import { date as Date } from '../../../Core/Utils';
import * as types from './actionTypes';

const initialState = {
  selectedGrouping: {},
  selectedDate: Date.getTodayNotWeekend(),
  selectedFloor: {},
};

const onSetGrouping = (state, action) => {
  const { payload } = action;
  return { ...state, selectedGrouping: payload };
};

const onSetDate = (state, action) => {
  const { payload } = action;
  return { ...state, selectedDate: payload };
};

const onSetFloor = (state, action) => {
  const { payload } = action;
  return { ...state, selectedFloor: payload };
};

const contentReducer = typeToReducer(
  {
    [types.SET_GROUPING]: onSetGrouping,
    [types.SET_DATE]: onSetDate,
    [types.SET_FLOOR]: onSetFloor,
  },
  initialState
);

export default contentReducer;
