import { schema, normalize } from 'normalizr';
import Lodash from 'lodash';

//  constructor
export function NormObjCstr(byId, allIds) {
  this.byId = byId;
  this.allIds = allIds;
}

const getKeys = obj => {
  if (!obj) {
    return [];
  }
  return Object.keys(obj);
};

// user Schema
const ownerSchema = new schema.Entity(
  'owners',
  {},
  {
    idAttribute: 'sfId',
  }
);

// office
const officeSchema = new schema.Entity(
  'offices',
  {},
  {
    idAttribute: 'sfId',
  }
);

// desk
const deskSchema = new schema.Entity(
  'desks',
  {
    parentOffice: officeSchema,
  },
  {
    idAttribute: 'idDesk',
  }
);

// set
const setSchema = new schema.Entity(
  'sets',
  {
    setOwner: ownerSchema,
  },
  {
    idAttribute: 'id',
  }
);

// set
const addonSchema = new schema.Entity(
  'addons',
  {},
  {
    idAttribute: 'addOnId',
  }
);

const reservationsSchema = new schema.Entity(
  'reservations',
  {
    owner: ownerSchema,
    desk: deskSchema,
    reservationSet: setSchema,
    reservationAddons: [addonSchema],
  },
  {
    idAttribute: 'id',
    processStrategy: booking => {
      const bookingCopy = { ...booking };
      return bookingCopy;
    },
  }
);

const reservationsArraySchema = [reservationsSchema];

export const normalizeReservations = data => {
  const temp = Array.isArray(data)
    ? normalize(data, reservationsArraySchema)
    : normalize(data, reservationsSchema);
  if (Lodash.isEmpty(temp.entities)) {
    return {
      data: new NormObjCstr({}, []),
      owners: new NormObjCstr({}, []),
      desks: new NormObjCstr({}, []),
      offices: new NormObjCstr({}, []),
      sets: new NormObjCstr({}, []),
      addons: new NormObjCstr({}, []),
    };
  }

  const ownerKeys = getKeys(temp.entities.owners);
  const setKeys = getKeys(temp.entities.sets);
  const deskKeys = getKeys(temp.entities.desks);
  const officeKeys = getKeys(temp.entities.offices);
  const addonKeys = getKeys(temp.entities.addons);

  const objNormalized = {
    data: new NormObjCstr(temp.entities.reservations, temp.result),
    owners: new NormObjCstr(temp.entities.owners, ownerKeys),
    sets: new NormObjCstr(temp.entities.sets, setKeys),
    addons: new NormObjCstr(temp.entities.addons, addonKeys),
  };

  if (!Lodash.isEmpty(deskKeys) && !Lodash.isEmpty(officeKeys)) {
    return {
      ...objNormalized,
      desks: new NormObjCstr(temp.entities.desks, deskKeys),
      offices: new NormObjCstr(temp.entities.offices, officeKeys),
    };
  }

  return objNormalized;
};
