import {
  getIsAccessMember,
  getIsManager,
} from '../../Core/Utils/userPermissions';

export default {
  communityManager: {
    withSubtitle: true,
    featureKey: '',
    order: 5,
  },
  occasions: { order: 3 },
  eReception: {
    order: 4,
    withSubtitle: true,
    events: {
      alerts: {
        deliveryMethodIsRequired: true,
      },
    },
  },
  roomBooking: {
    order: 1,
    events: {
      reminders: {
        canAccess: false,
      },
    },
  },
  oasisReservation: {
    order: 0,
    canAccess: profile => getIsAccessMember(profile) || getIsManager(profile),
    events: {
      reminders: {
        canAccess: false,
      },
      alerts: {
        canAccess: false,
      },
    },
  },
  openWorkspaces: {
    order: 2,
    hideSms: true,
  },
};
