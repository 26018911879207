import Lodash from 'lodash';

import { CheckBox } from '../Common';

const CheckBoxWrapper = objProps => {
  const { onChange } = objProps;
  const checked = Lodash.get(objProps, ['field', 'value'], false);

  const handleChange = () => {
    onChange(!checked);
  };

  return (
    <CheckBox
      {...objProps.field}
      {...objProps}
      onChange={handleChange}
      checked={checked}
    />
  );
};

export default CheckBoxWrapper;
