import typeToReducer from 'type-to-reducer';
import Lodash from 'lodash';
import * as types from './actionTypes';
import { normalizeFloors, NormObjCstr } from './schema';

const initialState = {
  data: new NormObjCstr({}, []),
  loading: false,
  hasError: false,
};

export const onLoadingFloorsPending = state => {
  return {
    ...state,
    loading: true,
  };
};

export const onLoadingFloorsFulfilled = (state, action) => {
  const { payload } = action;
  if (!payload.length) {
    return { ...state, loading: false, hasError: false };
  }
  const floorsNormalized = normalizeFloors(payload);

  const existingFloorsById = Lodash.get(state, ['data', 'byId'], {});
  const existingFloorsAllIds = Lodash.get(state, ['data', 'allIds'], []);
  const newFloorsById = {
    ...existingFloorsById,
    ...floorsNormalized.floors.byId,
  };
  const newFloorsAllIds = Lodash.uniqBy([
    ...existingFloorsAllIds,
    ...floorsNormalized.floors.allIds,
  ]);
  return {
    ...state,
    data: new NormObjCstr(newFloorsById, newFloorsAllIds),
    loading: false,
    hasError: false,
  };
};

export const onLoadingFloorsRejected = state => {
  return {
    ...state,
    loading: false,
    hasError: true,
  };
};

const contentReducer = typeToReducer(
  {
    [types.ADD_FLOORS]: {
      PENDING: onLoadingFloorsPending,
      FULFILLED: onLoadingFloorsFulfilled,
      REJECTED: onLoadingFloorsRejected,
    },
  },
  initialState
);

export default contentReducer;
