import Drawer from '@material-ui/core/Drawer';
import Paper from '@material-ui/core/Paper';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import MenuItem from '@material-ui/core/MenuItem';

import { customProps } from '../../Core/Utils';
import { Logos } from '../../resources';
import styles from './styles';
import { makeAdminMenuItems, makeMenu } from './MakeMenu';
import { getIsManager } from '../../Core/Utils/userPermissions';
import BillingLink from '../../Containers/Header/BillingLink';
import { ADMIN_PORTAL_URL } from '../../Core/constants';
import MobileLanguageMenu from './MobileLanguageMenu';

const NavPanel = props => {
  const {
    classes,
    subNavOrder,
    subNavList,
    isOpen,
    onClose,
    adminMenuList,
    profile,
    selectedLanguage,
    onLanguageItemMenuClick,
  } = props;
  const { t } = useTranslation();
  const genMenuItems = subNavOrder.reduce(
    makeMenu(subNavList, onClose, isOpen, classes, t),
    { LEFT: [], RIGHT: [] }
  );

  const navigateToAdminPage = () => {
    window.open(ADMIN_PORTAL_URL);
  };

  const adminPortalItem = {
    name: 'adminButton.actionLabel',
    onClick: navigateToAdminPage,
    className: classes.adminLink,
  };

  const adminMenuItems = getIsManager(profile)
    ? makeAdminMenuItems([[adminPortalItem], ...adminMenuList], onClose, isOpen)
    : [];

  return (
    <Drawer
      classes={{ root: classes.root, paper: classes.drawer }}
      anchor="left"
      variant="temporary"
      open={isOpen}
      onClose={() => {
        onClose(!isOpen);
      }}
    >
      <Logos.WhiteIndLogoHoriz className={classes.logo} />
      <Paper className={classes.menu}>
        <div>
          {genMenuItems.LEFT}
          <MenuItem className={classes.menuItem}>
            <BillingLink className={classes.link} />
          </MenuItem>
          {adminMenuItems}
        </div>
        <div>{genMenuItems.RIGHT}</div>
        <MobileLanguageMenu
          title={selectedLanguage}
          onLanguageItemMenuClick={onLanguageItemMenuClick}
        />
      </Paper>
    </Drawer>
  );
};

NavPanel.defaultProps = {
  subNavList: {},
};

NavPanel.propTypes = {
  classes: PropTypes.object.isRequired,
  subNavOrder: PropTypes.arrayOf(PropTypes.string).isRequired,
  adminMenuList: PropTypes.arrayOf(PropTypes.object).isRequired,
  subNavList: customProps.subNavList,
  isOpen: PropTypes.bool.isRequired,
  profile: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  onLanguageItemMenuClick: PropTypes.func.isRequired,
  selectedLanguage: PropTypes.string.isRequired,
};
export default withStyles(styles)(NavPanel);
