/* eslint-disable jsx-a11y/no-static-element-interactions */
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { withStyles } from '@material-ui/core/styles';
import { Icons } from '../../../resources';
import { colors } from '../../../Core/Theme';

const SlidingListItem = ({
  id,
  title,
  description,
  onActionClick,
  hasBorderBottom,
  classes,
  classNameContainer,
}) => {
  const { RightArrowGrayIcon } = Icons;
  const handleActionClick = () => {
    onActionClick(id);
  };
  return (
    <div
      className={clsx(
        classes.constainer,
        classes.withPointer,
        hasBorderBottom && classes.hasBorderBottom,
        classNameContainer
      )}
      onClick={handleActionClick}
      onKeyPress={handleActionClick}
    >
      <div className={classes.textContainer}>
        <div className={classes.textContainerTitle}>{title}</div>
        {description ? (
          <div className={classes.textContainerDescription}>{description}</div>
        ) : null}
      </div>
      <div className={classes.arrowContainer}>
        <RightArrowGrayIcon className={classes.arrow} />
      </div>
    </div>
  );
};

const styles = () => ({
  constainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingTop: '20px',
    paddingBottom: '20px',
  },
  textContainer: {},
  textContainerTitle: {
    fontFamily: 'VerlagBold',
    fontSize: '16px',
  },
  textContainerDescription: {
    fontFamily: 'VerlagLight',
    lineHeight: '18px',
    fontSize: '14px',
    marginTop: '6px',
  },
  arrowContainer: {
    display: 'flex',
    alignItems: 'flex-end',
    flexDirection: 'column',
    width: '20px',
  },
  hasBorderBottom: {
    borderBottom: `1px solid ${colors.middle}`,
  },
  withPointer: {
    cursor: 'pointer',
  },
});

SlidingListItem.defaultProps = {
  hasBorderBottom: false,
  classNameContainer: null,
  description: '',
};

SlidingListItem.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  hasBorderBottom: PropTypes.bool,
  onActionClick: PropTypes.func.isRequired,
  classNameContainer: PropTypes.string,
};

export default withStyles(styles)(SlidingListItem);
