export function sortTopNavigation(menuItems: { name: string }[]) {
  const order: { [key: string]: number } = {
    'navigation.menu.profile': 1,
    'navigation.menu.company': 2,
    'navigation.menu.account_overview': 2.1,
    'navigation.menu.account_activity': 2.2,
    'navigation.menu.message': 3,
    'navigation.menu.groupmanagement': 4,
    'navigation.menu.membermanagement': 4.5,
    'navigation.menu.faq': 5,
    'navigation.menu.settings': 6,
    'referral.refer_a_friend': 7,
    'navigation.menu.signout': 8,
  };

  return menuItems.sort((a, b) => {
    return order[a.name] - order[b.name];
  });
}

export function sortNavGroupings(translationKeys: string[]) {
  const order: { [key: string]: number } = {
    'navigation.grouping.home': 1,
    'navigation.grouping.reserve': 2,
    'navigation.grouping.reception': 3,
  };

  return [...translationKeys].sort((a, b) => {
    return order[a] - order[b];
  });
}
