import Lodash from 'lodash';
import * as moment from 'moment';
import { date as DateUtils, queriedDateAndLocation } from '../../../Core/Utils';
import { locationRoleAccess } from '../../../Core/Utils/userPermissions';

const getGroupingToLoad = (selectedGrouping, userDefaultGrouping, profile) => {
  if (selectedGrouping?.location) {
    const canViewSelectedGrouping = locationRoleAccess.bookAt({
      obj: selectedGrouping.location,
      profile,
    });
    if (!canViewSelectedGrouping) {
      return userDefaultGrouping;
    }
    return selectedGrouping;
  }
  return userDefaultGrouping;
};

const loadData = async (
  profile,
  addRooms,
  addBookings,
  addAmenities,
  setLocation,
  setTimezone,
  addQueriedDateAndLocation,
  rooms,
  locations,
  userAccountSfId,
  selectedLocation,
  getAndAddBookingRestrictions,
  setLoading,
  tz,
  queriedDatesAndLocations,
  addFloors,
  selectedGrouping,
  setHasLoaded,
  setGrouping,
  addQueriedDateAndGrouping,
  queriedDatesandGroupings,
  userDefaultGrouping,
  selectedDate
) => {
  setLoading(true);

  const groupingToLoadFor = getGroupingToLoad(
    selectedGrouping,
    userDefaultGrouping,
    profile
  );
  const { location } = groupingToLoadFor;
  setGrouping(groupingToLoadFor);
  setLocation(location);

  const includeDeleted = false;
  DateUtils.setTimezone(location.timeZoneId);
  setTimezone({
    oldTz: tz,
    newTz: location.timeZoneId,
  });

  const dateUpdatedForNewZone = DateUtils.updateDateForTZ(
    selectedDate,
    location.timeZoneId,
    tz
  );

  const currentUnixMonth = DateUtils.getUnixCurrentMonth();
  const today = DateUtils.getTodayNotWeekend();
  const dateToFetchFor = dateUpdatedForNewZone || today;
  const monthToFetchFor =
    moment.unix(dateUpdatedForNewZone).startOf('month').unix() ||
    currentUnixMonth;

  const startToday = DateUtils.getUnixStartOfDay(dateToFetchFor);
  const endToday = DateUtils.getUnixEndOfDay(dateToFetchFor);
  const currentQueriedSearch = queriedDateAndLocation(
    dateToFetchFor,
    location.sfId
  );

  const listParallelPromises = [];
  if (!queriedDatesAndLocations.includes(currentQueriedSearch)) {
    listParallelPromises.concat([
      addQueriedDateAndLocation(currentQueriedSearch),
      addAmenities(false),
      getAndAddBookingRestrictions(monthToFetchFor, location.sfId),
      addFloors(location.sfId),
    ]);
  }
  if (
    !Lodash.get(
      queriedDatesandGroupings,
      [groupingToLoadFor?.idGrouping],
      []
    ).includes(startToday)
  ) {
    listParallelPromises.push(
      addBookings({
        startEpoch: startToday,
        endEpoch: endToday,
        locationSfId: location.sfId,
        groupingId: groupingToLoadFor.idGrouping,
        includeDeleted,
      })
    );
    if (groupingToLoadFor.idGrouping) {
      listParallelPromises.push(
        addQueriedDateAndGrouping({
          groupingId: groupingToLoadFor.idGrouping,
          date: startToday,
        })
      );
    }
  }

  const roomsAtLocation = rooms.filter(
    r =>
      r.locationSfId === location.sfId &&
      r.groupingId === groupingToLoadFor.idGrouping
  );
  if (roomsAtLocation.length === 0) {
    listParallelPromises.push(
      addRooms(location.sfId, groupingToLoadFor.idGrouping)
    );
  }

  await Promise.all(listParallelPromises);
  setHasLoaded(true);
  setLoading(false);
};

export default loadData;
