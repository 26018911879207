import { useTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { useEffect, useState } from 'react';

import { updateOffice } from '../../../Core/Api/officesAPI';

import { Text, SubmitButton } from '../../../Components/Common';
import FormikInputs from '../../../Components/FormikInputs';
import { colors, breakpoints } from '../../../Core/Theme';
import {
  SECOND_IN_MS,
  REGEX_ALPHANUMERIC_WITH_SPACES,
} from '../../../Core/Utils';
import { Office } from '../../../Models';

const SUCCESS_DURATION = 2 * SECOND_IN_MS;

const OfficeForm = props => {
  const { classes, office, onUpdated, onError } = props;

  const { t } = useTranslation();

  const [isSubmitSuccess, setSubmitSuccess] = useState(false);

  const initialValues = {
    name: Office.getOfficeName(office).replace('Office ', ''),
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .transform(v => v?.trim())
      .required(t('FormErrors.required'))
      .matches(REGEX_ALPHANUMERIC_WITH_SPACES, t('FormErrors.alphanumeric')),
  });

  useEffect(() => {
    if (isSubmitSuccess) {
      const timeoutId = setTimeout(() => {
        setSubmitSuccess(false);
      }, SUCCESS_DURATION);

      return () => clearTimeout(timeoutId);
    }
    return undefined;
  }, [isSubmitSuccess]);

  const handleSubmitting = async (values, { setSubmitting }) => {
    const officeUpdate = {
      name: `${t(
        'manageOffices.office_form.name_prefix'
      )} ${values.name.trim()}`,
      seats: office.seats,
      dailyPrice: office.priceInMoney,
      amenityIds: office.amenityOfficeRelations?.map(r => r.amenity.id),
    };

    setSubmitting(true);

    try {
      const response = await updateOffice(office.sfId, officeUpdate);
      if (response.status !== 200) {
        throw new Error();
      }
      setSubmitSuccess(true);
      onUpdated(response.data);
    } catch (err) {
      onError(t('manageOffices.office_form.fail_msg'));
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <>
      <div className={classes.spacer}>
        <Text
          text={t('manageOffices.office_form.title')}
          className={classes.title}
        />
        <Text
          text={t('manageOffices.office_form.subtitle')}
          className={classes.subtitle}
        />

        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmitting}
          validateOnBlur
          validateOnMount
          enableReinitialize
        >
          {({ isValid, handleSubmit, isSubmitting }) => (
            <form>
              <div>
                <FormikInputs
                  name="name"
                  label={t('manageOffices.office_form.field_name_lbl')}
                  placeholder={t('manageOffices.office_form.field_name_lbl')}
                  datatestid="rr_name"
                  prefix={t('manageOffices.office_form.name_prefix')}
                  isLabelUppercase
                  isRequired
                  autoFocus
                />
              </div>

              <div className={classes.footer}>
                <SubmitButton
                  defaultText={t('general.save_changes')}
                  className={classes.submitButton}
                  isLoading={isSubmitting}
                  disabled={!isValid}
                  onClick={handleSubmit}
                  isSuccess={isSubmitSuccess}
                />
              </div>
            </form>
          )}
        </Formik>
      </div>
    </>
  );
};

const styles = theme => ({
  title: {
    fontSize: 24,
    fontFamily: 'VerlagBold',
    paddingTop: 15,
    display: 'block',
    paddingBottom: 8,
    textTransform: 'capitalize',
  },
  subtitle: {
    display: 'block',
    fontFamily: 'VerlagBook',
    fontSize: 16,
    color: colors.darkGray,
    marginBottom: 32,
    '&::first-letter': {
      textTransform: 'uppercase',
    },
  },
  spacer: {
    marginLeft: 20,
    marginRight: 20,
    [theme.breakpoints.up(breakpoints.MOBILE)]: {
      marginLeft: 40,
      marginRight: 40,
    },
  },
  footer: {
    position: 'absolute',
    bottom: 0,
    right: 0,
    left: 0,
    padding: '20px 40px',
    justifyContent: 'center',
    boxShadow: `0 0px 4px 0px rgba(0, 0, 0, 0.25)`,
    [theme.breakpoints.down(breakpoints.MOBILE)]: {
      bottom: 0,
      padding: 15,
    },
  },
  submitButton: {
    textTransform: 'capitalize',
  },
  inputLabel: {
    width: 'max-content',
  },
  fieldLabel: {
    fontFamily: 'VerlagBold',
    fontSize: 12,
    marginTop: 16,
    textTransform: 'uppercase',
  },
  checkboxesContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  checkboxText: {
    display: 'flex',
    alignItems: 'center',
  },
  checkboxIcon: {
    marginRight: 9,
    paddingBottom: 5,
  },
  checkboxOverride: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  loading: {
    margin: 'auto',
    display: 'block',
    marginTop: 20,
  },
});

OfficeForm.propTypes = {
  office: PropTypes.object.isRequired,
  onUpdated: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
};

export default withStyles(styles)(OfficeForm);
