import { withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import PropTypes from 'prop-types';

import DayPicker from '../DayPicker/DayPicker';
import { colors } from '../../../Core/Theme';

require('../DayPicker/react_dates_overrides.css');

const DayPickerBox = props => {
  const { classes, classNameRoot, className, classNameCarat } = props;

  return (
    <DayPicker
      {...props}
      classNameRoot={clsx(classes.root, classNameRoot)}
      className={clsx(classes.container, className)}
      classNameCarat={clsx(classes.carat, classNameCarat)}
    />
  );
};

const styles = () => ({
  root: {
    backgroundColor: `${colors.white} !important`,
    borderRadius: 4,
    border: `solid 1px ${colors.middle}`,
    cursor: 'pointer',
    fontFamily: 'VerlagBook',
    fontSize: '18px',
    height: '37px',
    alignItems: 'center',
    display: 'flex',
    paddingRight: 10,
    paddingBottom: 1,
  },
  carat: {
    marginTop: 2,
  },
  container: {
    display: 'flex',
    flex: 1,
    fontFamily: 'VerlagBook',
    fontSize: '18px',
    alignItems: 'center',
    position: 'relative',
  },
});

DayPickerBox.defaultProps = {
  classNameRoot: null,
  className: null,
  classNameCarat: null,
};
DayPickerBox.propTypes = {
  classNameRoot: PropTypes.string,
  className: PropTypes.string,
  classNameCarat: PropTypes.string,
};

export default withStyles(styles)(DayPickerBox);
