export const LOAD_ROOMS = 'LOAD_ROOMS';
export const LOAD_ROOMS_PENDING = 'LOAD_ROOMS_PENDING';
export const LOAD_ROOMS_FULFILLED = 'LOAD_ROOMS_FULFILLED';
export const LOAD_ROOMS_REJECTED = 'LOAD_ROOMS_REJECTED';

export const DELETE_ROOM = 'DELETE_ROOMS';

export const CREATE_ROOM = 'CREATE_ROOM';

export const UPDATE_ROOM = 'UPDATE_ROOM';

export const ADD_FLOOR = 'ADD_FLOOR';

export const LOAD_FLOORS = 'LOAD_FLOORS';

export const LOAD_BOOKING_RULES = 'LOAD_BOOKING_RULES';

export default { LOAD_ROOMS, DELETE_ROOM, CREATE_ROOM, UPDATE_ROOM };
