import Moment from 'moment';
import Lodash from 'lodash';
import { extendMoment } from 'moment-range';
import {
  parse as dateFnsParse,
  isSameDay as dateFnsIsSameDay,
  fromUnixTime as dateFnsFromUnixTime,
} from 'date-fns';

import { utcToZonedTime as dateFnsUtcToZonedTime } from 'date-fns-tz';

const moment = extendMoment(Moment);

require('moment-timezone');

// date is a moment object here
const isWeekend = date => {
  const weekDay = date.isoWeekday();
  //  if it is a weekend
  return weekDay === 6 || weekDay === 7;
};

// Check if first date is in the same month of the second
const isSameMonth = (firstDate, secondDate) => {
  const momentFirstDate = moment.unix(firstDate * 1);
  const momentSecondDate = moment.unix(secondDate * 1);
  return momentFirstDate.isSame(momentSecondDate, 'month');
};

const getMomentFromUnixAndTimeZone = (unix, tz) => {
  return moment.tz(unix * 1000, tz);
};

const getUnixCurrentMonth = () => {
  return moment().startOf('month').unix();
};

const getUnixCurrentEndOfTheMonth = timestamp => {
  if (Lodash.isNil(timestamp)) {
    return moment().endOf('month').unix();
  }
  return moment.unix(timestamp).endOf('month').unix();
};

function getLocalDay(date) {
  const isoString = new Date(date).toISOString();
  return isoString.split('T')[0];
}

const getDateInMoment = varDate => {
  if (varDate) {
    if (
      Lodash.isNumber(varDate) ||
      (Lodash.isString(varDate) &&
        Lodash.isNumber(varDate * 1) &&
        !Lodash.isNaN(varDate * 1))
    ) {
      return moment.unix(varDate);
    }

    return moment.isMoment(varDate) ? varDate : moment(varDate);
  }

  return moment();
};

const getDateInMomentWithZone = (varDate, tz) => {
  const momentDate = getDateInMoment(varDate);
  if (tz) {
    return momentDate.tz(tz);
  }
  return momentDate;
};

const getFormattedDateInMomentWithZone = (varDate, tz, format) => {
  const momentDate = getDateInMoment(varDate);
  if (tz) {
    return momentDate.tz(tz).format(format);
  }
  return momentDate.format(format);
};

const getIsDateValid = varDate => {
  return getDateInMoment(varDate).isValid();
};

const isHourPastCurrentHour = hour => {
  const momentHour = getDateInMoment(hour);

  return momentHour.isBefore(moment(), 'hour');
};

const getRangeDaysBetweenDates = (intFirstDate, intSecondDate) => {
  const objMomentFirstDate = getDateInMoment(intFirstDate).toDate();
  const objMomentSecondDate = getDateInMoment(intSecondDate).toDate();

  const range = moment.range(objMomentFirstDate, objMomentSecondDate);

  return range.diff('days');
};

const isHourInCurrentDay = hour => {
  const momentHour = moment.unix(hour * 1);
  return momentHour.isSame(moment(), 'day');
};

const isSameDate = (dateToValidate, date) => {
  const firstDate = getDateInMoment(dateToValidate);
  const secondDate = getDateInMoment(date);
  return firstDate.isSame(secondDate, 'day');
};

const setTimezone = newTz => {
  if (!newTz || newTz === '') {
    return;
  }
  moment.tz.setDefault(newTz);
};

// this function takes in a unix timestamp that represent midnight of the selected day in the
// initial tz and changes it to midnight of teh same day in the new tz
const updateDateForTZ = (
  initialUnixMidnight,
  newTz,
  oldTz = moment().tz() || moment.tz.guess()
) => {
  // this gets the offset in minutes
  if (!newTz || newTz === '') {
    return initialUnixMidnight;
  }
  const initialOffset = oldTz
    ? moment.tz(oldTz).utcOffset()
    : moment().utcOffset();
  const newOffset = moment.tz(newTz).utcOffset();
  const offsetDifference = initialOffset - newOffset;
  const diffInSeconds = offsetDifference * 60;
  const newTime = +initialUnixMidnight + diffInSeconds;
  return newTime;
};

const getMidnightTomorrow = selectedDate => {
  const twoFourHours = 24 * 60 * 60;
  return selectedDate + twoFourHours;
};

//  this function is used by the daypicker, the input date is a Date object
const getMidnight = date => {
  return moment(date).startOf('day').unix();
};

const getCurrentZone = () => {
  /* eslint-disable-next-line no-underscore-dangle */
  const zone = moment()._z;
  return zone ? zone.name : moment.tz.guess();
};

const getTodayNotWeekend = timeZoneId => {
  const timeZoneToUse = timeZoneId ?? getCurrentZone();
  const now = moment().tz(timeZoneToUse);
  //  if it is a weekend
  if (isWeekend(now)) {
    const weekDay = now.isoWeekday();
    now.add(8 - weekDay, 'days');
  }
  return now.startOf('day').unix();
};

const getTime = unix => {
  if (unix === '' || !unix) {
    return null;
  }
  return moment.isMoment(unix) ? unix : moment.unix(unix);
};

const getUnixStartOfDay = (unixDate, timeZoneId) => {
  const timeZoneToUse = timeZoneId || getCurrentZone();
  return moment.unix(unixDate).tz(timeZoneToUse).startOf('day').unix();
};

const getUnixEndOfDay = (unixDate, timeZoneId) => {
  const timeZoneToUse = timeZoneId || getCurrentZone();

  return moment.unix(unixDate).tz(timeZoneToUse).endOf('day').unix();
};

const getTimeInZone = (unix, zone) => {
  if (!zone) {
    return moment.unix(unix);
  }

  return moment.unix(unix).tz(zone);
};

const getDateStartAndEnd = (date, timeZoneId) => {
  const startDate = getUnixStartOfDay(date, timeZoneId);
  const endDate = getUnixEndOfDay(date, timeZoneId);

  return {
    startDate,
    endDate,
  };
};

const getDiffMinutes = (initialTime, endTime) => {
  const start = moment.unix(initialTime * 1);
  const end = moment.unix(endTime * 1);
  const diffBetweenHours = moment.duration(end.diff(start)).asMinutes();
  if (diffBetweenHours && diffBetweenHours > 0) {
    return diffBetweenHours;
  }
  return 0;
};

const getDayString = (varDate, timeZoneId, t = a => a) => {
  const today = getDateInMomentWithZone(null, timeZoneId);
  const then = getDateInMomentWithZone(varDate, timeZoneId);
  if (then.isSame(today, 'day')) {
    return t('general.today');
  }
  if (then.isSame(today.add(1, 'days'), 'day')) {
    return t('general.tomorrow');
  }
  return then.format('dddd').toUpperCase();
};

const getUnixNextHalfHourEpoch = epoch => {
  const now = epoch ? moment.unix(epoch) : moment();
  const minuteOffset = now.minute() % 30;
  const nextHalfHour = now.set({
    hours: moment(now).hours(),
    minutes: moment(now).minute() - minuteOffset + 30,
    seconds: 0,
  });
  return nextHalfHour.unix();
};

// Here, dt is a JSON enconded UTC string date and tz is the TimeZone
const getJsonDateTimeForTimezone = (dt, timeZoneId) => {
  const tz = timeZoneId || getCurrentZone();
  const momentDate = moment(dt);
  try {
    return momentDate.tz(tz).format();
  } catch (error) {
    return null;
  }
};

const getUnixOfPrevHalfHour = epoch => {
  const nowEpoch = epoch || moment().unix();
  const secOffset = nowEpoch % 1800;
  return nowEpoch - secOffset;
};

const getExactTimeInUnix = () => {
  return moment().unix();
};

const getTimeInUnix = time => {
  return moment(time).unix();
};

const getUnixTimeInFormat = (unix, format) => {
  return moment.unix(unix).format(format);
};

const formatEpoch = (epoch, format, timezone) => {
  let date = moment.unix(epoch);

  if (timezone) {
    date = date.tz(timezone);
  }
  return date.format(format);
};

const convertJSDateToString = jsDate => {
  return moment(jsDate).format('YYYY-MM-DD');
};

const getTimeZoneCode = (timeZoneId, epoch = moment().unix()) => {
  if (!timeZoneId) {
    return '';
  }
  return moment.tz.zone(timeZoneId).abbr(epoch * 1000);
};

const cloneMomentDate = momentDate => {
  return moment(momentDate);
};

const parseToMomentWithFormat = (dateString, format) => {
  return moment(dateString, format);
};

const getTodayEpoch = () => {
  return moment().unix();
};

const getStartOfMonthEpochInZone = (epoch, timeZoneId) => {
  return updateDateForTZ(
    getDateInMoment(epoch).startOf('month').unix(),
    timeZoneId
  );
};

const parse = (date, format, referenceDate) => {
  return dateFnsParse(date, format, referenceDate);
};

const isSameDay = (dateLeft, dateRight) => {
  return dateFnsIsSameDay(dateLeft, dateRight);
};

const fromUnixTime = unixTime => {
  return dateFnsFromUnixTime(unixTime);
};

const utcToZonedTime = (date, timeZone) => {
  return dateFnsUtcToZonedTime(date, timeZone);
};

export default {
  getExactTimeInUnix,
  getMomentFromUnixAndTimeZone,
  getUnixCurrentMonth,
  getDateStartAndEnd,
  isWeekend,
  updateDateForTZ,
  setTimezone,
  getMidnightTomorrow,
  getMidnight,
  getTodayNotWeekend,
  getTime,
  isSameMonth,
  isSameDate,
  getUnixStartOfDay,
  getUnixEndOfDay,
  getTimeInZone,
  getDiffMinutes,
  isHourPastCurrentHour,
  isHourInCurrentDay,
  getDayString,
  getUnixNextHalfHourEpoch,
  getCurrentZone,
  getJsonDateTimeForTimezone,
  getUnixOfPrevHalfHour,
  getTimeInUnix,
  convertJSDateToString,
  getUnixTimeInFormat,
  getDateInMoment,
  getTimeZoneCode,
  getUnixCurrentEndOfTheMonth,
  getIsDateValid,
  getRangeDaysBetweenDates,
  cloneMomentDate,
  getDateInMomentWithZone,
  parseToMomentWithFormat,
  getFormattedDateInMomentWithZone,
  formatEpoch,
  getTodayEpoch,
  getStartOfMonthEpochInZone,
  getLocalDay,
  parse,
  isSameDay,
  fromUnixTime,
  utcToZonedTime,
};
