import { createMuiTheme } from '@material-ui/core/styles';

const constants = {
  SIDE_NAV_WIDTH: 270,
  TOP_NAV_HEIGHT_LG: 81,
  TOP_NAV_HEIGHT_SM: 59,
  SIDE_NAV_MENU_TOP_OFFSET: 36,
  CALENDAR_TABLE_CELL_WIDTH: 206,
  CALENDAR_HEADER_HEIGHT: 170,
  BREAKPOINTS: {
    SM: 600,
    MD: 960,
    LG: 1280,
    XL: 1920,
  },
};

const breakpoints = {
  SM: 600,
  MD: 960,
  LG: 1280,
  XL: 1920,
  MOBILE: 480,
};

const fonts = {
  h1: {
    fontWeight: 'bold',
    fontSize: 60,
    lineHeight: 60,
    height: 60,
    fontFamily: "'VerlagBold'",
  },
  h2: {
    fontWeight: 'bold',
    fontSize: 36,
    lineHeight: 36,
    fontFamily: "'VerlagBold'",
  },
  h3: {
    fontWeight: 'bold',
    fontSize: 18,
    lineHeight: 18,
    fontFamily: "'VerlagBold'",
  },
  h4: {
    fontWeight: 'bold',
    fontSize: 20,
    lineHeight: 20,
    fontFamily: "'VerlagBold'",
  },
  h5: {
    fontWeight: 'bold',
    fontSize: 18,
    lineHeight: 18,
    fontFamily: "'VerlagBook'",
  },
  h6: {
    fontWeight: 'lighter',
    fontSize: 15,
    lineHeight: 18,
    fontFamily: "'VerlagLight'",
  },
  h7: {
    fontWeight: 'lighter',
    fontSize: 15,
    lineHeight: 18,
    fontFamily: "'VerlagBold'",
  },
  h8: {
    fontSize: 15,
    lineHeight: 18,
    fontFamily: "'VerlagBook'",
  },
  body1: {
    fontWeight: 'lighter',
    fontSize: 24,
    lineHeight: 26,
    fontFamily: "'VerlagBook'",
  },
  body2: {
    fontWeight: 'lighter',
    fontSize: 18,
    lineHeight: 26,
    fontFamily: "'VerlagBook'",
  },
};

const shadows = {
  headerOverBody: 'inset 0 1px 3px 0 rgba(0, 0, 0, 0.25)',
};

const theme = createMuiTheme({
  typography: {
    useNextVariants: true,
    fontFamily: "'VerlagBook'",
  },
  props: {
    MuiButtonBase: {
      // The properties to apply
      disableRipple: true, // No more ripple
    },
  },
  overrides: {
    MuiInputBase: {
      input: {
        '&:-webkit-autofill': {
          transitionDelay: '9999s',
          transitionProperty: 'background-color, color',
        },
      },
    },
    MuiInput: {
      input: {
        '&:before': {
          borderBottom: `1px solid red`,
        },
      },
    },
    MuiSelect: {
      select: {
        '&:focus': {
          background: 'white',
        },
      },
    },
    MuiFormControl: {
      root: {
        width: '100%',
      },
    },
    MuiTableCell: {
      root: {
        '&:last-child': {
          padding: 0,
          paddingRight: 0,
        },
      },
    },
  },
});

export { theme, constants, fonts, shadows, breakpoints };
