/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Lodash from 'lodash';
import Moment from 'moment';

import SubmitButton from '../SubmitButton';
import LocationAndDateSelect from './LocationAndDateSelect';
import Drawer from '../Drawer';
import TextButton from '../TextButton';
import { colors } from '../../../Core/Theme';
import { date as DateUtils } from '../../../Core/Utils';
import { Icons } from '../../../resources';
import { getSelectedDaysAvailability } from '../../AvailabilityDayPicker/utils';

const { CloseIcon } = Icons;

const LocationAndDateSelectMobile = props => {
  const {
    selectedDate,
    classes,
    onSelectLocationAndDate,
    label,
    actionLabel,
    isLoading,
    profile,
    availabilities,
    fetchAvailability,
    nextAvailableDayEpoch,
    nextAvailableDayCount,
    goToAvailable,
    selectedGrouping,
    locationListProps,
    onChangeLocation,
    onChangeDate,
    onReset,
    isLoadingActionLabel,
  } = props;
  const [isOpenEditDrawer, setIsOpenEditDrawer] = React.useState(false);
  const [date, setSelectDate] = React.useState();

  const { t } = useTranslation();
  const locationSfId = selectedGrouping?.locationSfId;
  const locationTimezoneId = selectedGrouping?.location?.timeZoneId;

  React.useEffect(() => {
    if (selectedDate) {
      setSelectDate(selectedDate);
    }
  }, [selectedDate]);

  React.useEffect(() => {
    if (!date) {
      return;
    }
    fetchAvailability(
      Moment.unix(date).startOf('month').unix(),
      Moment.unix(date).endOf('month').unix(),
      locationSfId,
      locationTimezoneId,
      selectedGrouping
    );
  }, [selectedGrouping?.idGrouping, isOpenEditDrawer, date]);

  const toggleEditDrawer = () => setIsOpenEditDrawer(!isOpenEditDrawer);

  const handleOnEditPress = e => {
    e.stopPropagation();
    return toggleEditDrawer();
  };

  const handleOnCloseDrawer = () => {
    return setIsOpenEditDrawer(false);
  };

  const handleCloseDrawerWithReset = () => {
    onReset?.();
    handleOnCloseDrawer();
  };

  const handleOnDateChange = objDateMoment => {
    const intDateSelected = objDateMoment
      ? objDateMoment.unix()
      : DateUtils.getDateInMoment().unix();
    onChangeDate?.(intDateSelected);
    return setSelectDate(intDateSelected);
  };

  const handleOnSelectLocationAndDate = () => {
    if (onSelectLocationAndDate) {
      onSelectLocationAndDate({
        selectedDate: date,
        selectedGrouping,
      });
    }

    return handleOnCloseDrawer();
  };

  const availabilitiesTimeZoneId = Lodash.get(
    availabilities,
    [0, 'timezoneId'],
    ''
  );
  const selectedDaysAvailability = getSelectedDaysAvailability(
    availabilities,
    date,
    availabilitiesTimeZoneId
  );

  const renderDrawer = () => {
    return (
      <Drawer
        anchor="top"
        isOpenDrawer={isOpenEditDrawer}
        onCloseDrawer={handleCloseDrawerWithReset}
        onCloseButton={handleCloseDrawerWithReset}
        customClass={classes.editDrawerContainer}
      >
        <div className={classes.editYourSearchHeader}>
          <div className={classes.editYourSearch}>
            <p className={classes.label}>{label}</p>
          </div>
          <div className={classes.closeIconContainer}>
            <CloseIcon onClick={handleCloseDrawerWithReset} />
          </div>
        </div>
        <div className={classes.closeIconContainer}>
          <LocationAndDateSelect
            selectedGrouping={selectedGrouping}
            selectedDate={date}
            onDateChange={handleOnDateChange}
            onChangeLocation={onChangeLocation}
            isLoading={isLoading}
            fetchAvailability={fetchAvailability}
            selectedDaysAvailability={selectedDaysAvailability}
            profile={profile}
            locationSfId={locationSfId}
            nextAvailableDayCount={nextAvailableDayCount}
            nextAvailableDayEpoch={nextAvailableDayEpoch}
            goToAvailable={goToAvailable}
            availabilities={availabilities}
            locationTimezoneId={locationTimezoneId}
            locationListProps={locationListProps}
          />
          <SubmitButton
            id="select-location-and-date"
            defaultText={actionLabel}
            className={classes.button}
            onClick={handleOnSelectLocationAndDate}
            isLoading={isLoading || isLoadingActionLabel}
            disabled={selectedDaysAvailability === 0}
          />
        </div>
      </Drawer>
    );
  };

  return (
    <>
      {renderDrawer()}
      <div
        className={classes.infoContainer}
        onClick={handleOnEditPress}
        role="button"
      >
        <div className={classes.info}>
          <div className={classes.locationInfo}>
            <span className={classes.text}>
              {selectedGrouping?.displayName}
            </span>
          </div>
          <p className={classes.dotDiviver}>•</p>
          <div className={classes.dateInfo}>
            <span className={classes.text}>
              {DateUtils.getUnixTimeInFormat(selectedDate, '[ddd, MMM D]')}
            </span>
          </div>
        </div>
        <TextButton
          text={t('general.edit')}
          onClick={handleOnEditPress}
          size="small"
          datatestid="edit_location_and_date"
        />
      </div>
    </>
  );
};

const styles = () => ({
  button: {
    marginTop: 20,
    marginBottom: 20,
  },
  closeIconContainer: {
    paddingRight: 40,
  },
  editDrawerContainer: {
    paddingLeft: 20,
    paddingRight: 40,
  },
  editYourSearchHeader: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  editYourSearch: {
    marginLeft: 'auto',
    width: '52%',
  },
  closeButton: {
    backgroundColor: 'transparent',
  },
  text: {
    fontFamily: 'VerlagBook',
    fontSize: 16,
  },
  label: {
    fontFamily: 'VerlagBook',
    fontSize: 18,
  },
  info: {
    marginLeft: 'auto',
    marginRight: 'auto',
    width: '70%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  infoContainer: {
    display: 'flex',
    boxShadow: `0 0 4px 0 ${colors.blackOpacityLightest}`,
    justifyContent: 'center',
    alignItems: 'center',
    height: 35,
  },
  dotDiviver: {
    paddingLeft: 10,
    paddingRight: 10,
  },
  locationInfo: {
    textAlign: 'end',
    whiteSpace: 'nowrap',
    width: 120,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  dateInfo: {},
  container: {
    display: 'flex',
    boxShadow: `0 0 4px 0 ${colors.blackOpacityLightest}`,
  },
  containerLocationList: {
    width: 200,
    borderBottomRightRadius: '0px',
    borderTopRightRadius: '0px',
  },
  containerDayPicker: {
    width: 200,
    borderBottomLeftRadius: '0px',
    borderTopLeftRadius: '0px',
  },
});

LocationAndDateSelectMobile.defaultProps = {
  onChangeLocation: () => {},
  fetchAvailability: () => null,
  isLoading: false,
  availabilities: [],
  goToAvailable: () => {},
  nextAvailableDayCount: 0,
  nextAvailableDayEpoch: 0,
  profile: null,
  locationListProps: {},
  onChangeDate: () => {},
  onReset: () => {},
  isLoadingActionLabel: false,
  selectedGrouping: null,
};

LocationAndDateSelectMobile.propTypes = {
  selectedDate: PropTypes.number.isRequired,
  locationListProps: PropTypes.object,
  selectedGrouping: PropTypes.object,
  onChangeLocation: PropTypes.func,
  onChangeDate: PropTypes.func,
  onSelectLocationAndDate: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  actionLabel: PropTypes.string.isRequired,
  profile: PropTypes.object,
  availabilities: PropTypes.arrayOf(PropTypes.number),
  fetchAvailability: PropTypes.func,
  isLoading: PropTypes.bool,
  nextAvailableDayEpoch: PropTypes.number,
  nextAvailableDayCount: PropTypes.number,
  goToAvailable: PropTypes.func,
  onReset: PropTypes.func,
  isLoadingActionLabel: PropTypes.bool,
};

export default withStyles(styles)(LocationAndDateSelectMobile);
