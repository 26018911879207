import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';

import SubmitButton from '../../../Components/Common/SubmitButton';
import { EmptyStateDesks } from '../../../resources';
import { EmptyStateLevels } from './EmptyStateLevels.enum';

const EmptyState = ({ classes, level }) => {
  const { t } = useTranslation();

  const onClickCta = () => {
    window.open('https://industrious.zendesk.com/hc/en-us/requests/new');
  };

  return (
    <div className={classes.wrapper}>
      <img
        src={EmptyStateDesks}
        alt={t('altTexts.desk_empty')}
        className={classes.image}
      />
      <div className={classes.description}>
        {t(`manageOffices.no_workspaces_${level}`)}
      </div>
      <SubmitButton
        onClick={onClickCta}
        defaultText={t(`manageOffices.no_workspaces_cta`)}
        className={classes.button}
      />
    </div>
  );
};

const styles = () => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: '20px 16px 0',
  },
  description: {
    fontFamily: 'VerlagBold',
    fontSize: 20,
    marginBottom: 20,
    textAlign: 'center',
  },
  image: {
    width: 250,
    marginBottom: 40,
  },
  button: {
    width: 285,
  },
});

EmptyState.propTypes = {
  classes: PropTypes.object.isRequired,
  level: PropTypes.oneOf([EmptyStateLevels.Floor, EmptyStateLevels.Location])
    .isRequired,
};

export default withStyles(styles)(EmptyState);
