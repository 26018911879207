import Lodash from 'lodash';
import Moment from 'moment';
import { date as DateUtils, queriedDateAndLocation } from '../../../Core/Utils';
import selectGrouping from './groupingSelect';
import { getIsIntegrated } from '../../../Models/groupings';

const selectLocation =
  (
    addRooms,
    addBookings,
    setLocation,
    addQueriedDateAndLocation,
    queriedDatesAndLocations,
    selectedDate,
    setTimezone,
    setIsLoading,
    getAndAddBookingRestrictions,
    addFloors,
    setGrouping,
    queriedDateAndGrouping,
    addQueriedDateAndGrouping,
    lastGroupingAt,
    allBookableGroupings,
    handleUpdateUrlParams = () => {}
  ) =>
  async grouping => {
    const { location } = grouping;
    const { timeZoneId: newTz, sfId: locationSfId } = location;
    const oldTz = Moment().tz();

    const dateInNewZone = DateUtils.updateDateForTZ(selectedDate, newTz, oldTz);
    setTimezone({ newTz, oldTz });
    DateUtils.setTimezone(newTz);
    setLocation(location);

    const { startDate, endDate } = DateUtils.getDateStartAndEnd(dateInNewZone); // why is this date not in the right timezone
    const currentQueriedSearch = queriedDateAndLocation(
      selectedDate,
      locationSfId
    );

    const listParallelPromises = [];
    if (addQueriedDateAndLocation && queriedDatesAndLocations) {
      if (!Lodash.includes(queriedDatesAndLocations, currentQueriedSearch)) {
        if (setIsLoading) {
          setIsLoading(true);
        }
        listParallelPromises.concat([
          getAndAddBookingRestrictions(
            Moment.unix(startDate).startOf('month').unix(),
            locationSfId
          ),
          addFloors(locationSfId),
        ]);
      }
    }
    const previouslySelectedGroupingId = lastGroupingAt[grouping.locationSfId];
    const previouslySelectedGrouping = allBookableGroupings?.find(
      g => g.idGrouping === previouslySelectedGroupingId
    );

    let groupingToSelect = grouping;
    if (
      grouping.openToCoworkingMembers &&
      getIsIntegrated(previouslySelectedGrouping)
    ) {
      groupingToSelect = previouslySelectedGrouping;
    }

    listParallelPromises.push(
      selectGrouping(
        addRooms,
        addBookings,
        setGrouping,
        startDate,
        endDate,
        locationSfId,
        setIsLoading,
        addQueriedDateAndGrouping,
        queriedDateAndGrouping,
        handleUpdateUrlParams,
        newTz
      )(groupingToSelect)
    );
    await Promise.all(listParallelPromises);
    if (setIsLoading) {
      setIsLoading(false);
    }
  };

export default selectLocation;
