import { StyleRulesCallback, withStyles } from '@material-ui/core';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import clsx from 'clsx';
import { ReactNode } from 'react';

type Props = {
  className?: string;
  classes: ClassNameMap;
  datatestid: string;
  children: ReactNode;
};

const OfficeCard = (props: Props) => {
  const { classes, className, children, datatestid } = props;

  return (
    // @ts-ignore
    <div className={clsx(classes.default, className)} datatestid={datatestid}>
      {children}
    </div>
  );
};

const style: StyleRulesCallback<string> = () => ({
  default: {
    backgroundColor: 'white',
    padding: 20,
    borderRadius: 2,
    boxShadow: '0 0 4px 0 rgba(0, 0, 0, 0.1)',
  },
});

OfficeCard.defaultProps = {
  className: '',
};

export default withStyles(style)(OfficeCard);
