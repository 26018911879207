import * as React from 'react';
import PropTypes from 'prop-types';
import Lodash from 'lodash';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import queryString from 'query-string';

import ReserveDesk from '../ReserveDesk';

import reservationsRedux from '../../Redux/Common/Reservations';
import {
  handleUpdate,
  handleCancelReservation,
  handleCreateReservations,
} from './utils';
import { getUserProfile } from '../../Redux/Common/UserManager/selectors';
import { fetchLocation, fetchReservationById } from '../../Core/Api';
import DateUtils from '../../Core/Utils/date';
import { useDayPasses } from '../../Hooks';
import {
  getAllDisplayGroupings,
  getGroupingsAllIds,
  getGroupingsById,
} from '../../Redux/Common/Groupings/selectors';
import { setCustomErrorDescription as setCustomErrorDescriptionRedux } from '../../Redux/Common/UserManager/actions';

const ReserveDeskWithApi = props => {
  const {
    reservations,
    isOpen,
    isCancel,
    profile,
    updateReservations,
    groupingsById,
    groupingsAllIds,
    setCustomErrorDescription,
    history,
    setReservations,
    urlPathname,
    displayGroupings,
  } = props;
  const { t } = useTranslation();
  const [locationManagers, setLocationManagers] = React.useState([]);

  const selectedReservation = reservations[0];

  const location = selectedReservation?.grouping?.location;

  const windowLocationSearch = window.location.search;
  const params = queryString.parse(windowLocationSearch);
  const { reservationId } = params;

  React.useEffect(() => {
    const locationSfId = Lodash.get(location, 'sfId', '');
    const managersLocation = Lodash.get(locationManagers, [0, 'locationSfId']);
    if (locationSfId && locationSfId !== managersLocation && !isCancel) {
      fetchLocation(locationSfId).then(objResponse => {
        const { data } = objResponse;
        const { managers } = data;
        setLocationManagers(managers);
      });
    }
  }, [isOpen]);

  const fetchReservation = async () => {
    fetchReservationById(reservationId).then(objResponse => {
      const reservation = objResponse.data;
      if (reservation) {
        setReservations([reservation]);
        return;
      }
      history.push({
        pathname: '/home',
      });
      setCustomErrorDescription(t('deskReservation.reservation_load_error'));
    });
  };

  useEffect(() => {
    if (reservationId && !selectedReservation && urlPathname) {
      fetchReservation();
    }
  }, [reservationId]);

  useEffect(() => {
    if (!urlPathname) {
      return;
    }
    if (selectedReservation?.id) {
      history.push({
        pathname: urlPathname,
        search: `reservationId=${selectedReservation.id}`,
      });
      return;
    }
    history.push({
      pathname: urlPathname,
    });
  }, [selectedReservation?.id]);

  const reservationStart = selectedReservation?.startEpoch;

  const monthStart = DateUtils.getDateInMomentWithZone(
    reservationStart,
    selectedReservation?.owner?.account?.location?.timeZoneId ||
      profile?.primaryLocation?.timeZoneId
  )
    .startOf('month')
    .unix();

  const { fetchUsage, passesRemaining, hasUnlimitedDayPasses } = useDayPasses(
    monthStart,
    profile,
    selectedReservation?.owner?.accountSfId
  );

  const locationManagerEmail = Lodash.get(locationManagers, [
    0,
    'contact',
    'email',
  ]);

  return (
    <ReserveDesk
      {...props}
      groupingsById={groupingsById}
      location={location}
      locationManagerEmail={locationManagerEmail}
      fetchUsage={fetchUsage}
      passesRemaining={passesRemaining}
      handleUpdateReservation={handleUpdate(updateReservations)}
      handleCancelReservation={handleCancelReservation}
      handleCreateReservations={handleCreateReservations}
      groupingsAllIds={groupingsAllIds}
      hasUnlimitedDayPasses={hasUnlimitedDayPasses}
      displayGroupings={displayGroupings}
    />
  );
};

ReserveDeskWithApi.defaultProps = {
  reservations: [],
  isCancel: false,
  setReservations: () => null,
  urlPathname: null,
};

ReserveDeskWithApi.propTypes = {
  reservations: PropTypes.arrayOf(PropTypes.object),
  locationsById: PropTypes.object.isRequired,
  isOpen: PropTypes.bool.isRequired,
  isCancel: PropTypes.bool,
  profile: PropTypes.object.isRequired,
  updateReservations: PropTypes.object.isRequired,
  groupingsById: PropTypes.object.isRequired,
  groupingsAllIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  setCustomErrorDescription: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  setReservations: PropTypes.func,
  urlPathname: PropTypes.string,
  displayGroupings: PropTypes.arrayOf(PropTypes.object).isRequired,
};

const mapStateToProps = state => {
  return {
    groupingsById: getGroupingsById(state),
    profile: getUserProfile(state),
    groupingsAllIds: getGroupingsAllIds(state),
    displayGroupings: getAllDisplayGroupings(state),
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      deleteReservations: reservationsRedux.actions.deleteReservations,
      createReservations: reservationsRedux.actions.createReservations,
      updateReservations: reservationsRedux.actions.updateReservations,
      setCustomErrorDescription: setCustomErrorDescriptionRedux,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(ReserveDeskWithApi);
