import React from 'react';
import Lodash from 'lodash';
import Moment from 'moment';
import { date as DateUtils } from '../Core/Utils';

import { getAvailableDeskCounts } from '../Core/Api';
import { getIsIntegrated, getIsStandalone } from '../Models/groupings';

const useDayAvailability = (
  startEpoch,
  endEpoch,
  locationSfId,
  locationTimeZoneId,
  grouping
) => {
  const [availabilities, setAvailabilities] = React.useState([]);
  const [isFetching, setIsFetching] = React.useState(true);
  const [hasError, setError] = React.useState(false);
  const [fetchedMonths, setFetchedMonths] = React.useState([]);
  const [hasInitialized, setHasInitialized] = React.useState(false);
  const [lastFetchedLocationSfId, setLastFetchedLocationSfId] =
    React.useState('');
  const fetchAvailability = (
    intStart,
    intEnd,
    strLocationSfId,
    strLocationTimeZoneId,
    objGrouping,
    months = fetchedMonths,
    currentAvailabilites = availabilities
  ) => {
    if (
      !strLocationSfId ||
      (months.includes(intStart) && strLocationSfId === locationSfId)
    ) {
      return;
    }
    if (!isFetching) {
      setIsFetching(true);
    }
    setHasInitialized(true);
    getAvailableDeskCounts({
      startEpoch: DateUtils.updateDateForTZ(
        intStart,
        strLocationTimeZoneId,
        Moment().tz()
      ),
      endEpoch: DateUtils.updateDateForTZ(
        intEnd + 1,
        strLocationTimeZoneId,
        Moment().tz()
      ),
      locationSfId: strLocationSfId,
      groupingIds: getIsStandalone(objGrouping)
        ? [objGrouping?.idGrouping]
        : null,
      integratedOnly: getIsIntegrated(objGrouping),
      includeUnmapped: true,
    })
      .then(objResponse => {
        setIsFetching(false);
        setError(false);
        const counts = Lodash.get(objResponse, ['data', 'counts'], '');
        setLastFetchedLocationSfId(strLocationSfId);
        if (strLocationSfId === lastFetchedLocationSfId) {
          setAvailabilities([...counts, ...currentAvailabilites]);
          setFetchedMonths([...months, intStart]);
        } else {
          setAvailabilities(counts);
          setFetchedMonths([]);
        }
      })
      .catch(() => {
        setError(true);
        return setIsFetching(false);
      });
  };

  // reset availabilities on location/grouping changes
  React.useEffect(() => {
    setAvailabilities([]);
    setFetchedMonths([]);
    if (!locationSfId) {
      return;
    }
    fetchAvailability(
      startEpoch,
      endEpoch,
      locationSfId,
      locationTimeZoneId,
      grouping,
      [],
      []
    );
  }, [locationSfId, locationTimeZoneId, grouping?.idGrouping]);

  // Add to current availabilities on date changes
  React.useEffect(() => {
    if (hasInitialized) {
      fetchAvailability(
        startEpoch,
        endEpoch,
        locationSfId,
        locationTimeZoneId,
        grouping
      );
    }
  }, [startEpoch, endEpoch]);

  return { isFetching, fetchAvailability, hasError, availabilities };
};

export default useDayAvailability;
