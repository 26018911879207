import typeToReducer from 'type-to-reducer';
import Lodash from 'lodash';
import * as types from './actionTypes';
import { normalizeBookings, NormObjCstr } from './schema';

const initialState = {
  bookingData: new NormObjCstr({}, []),
  users: new NormObjCstr({}, []),
  loading: false,
  hasError: false,
  total: null,
  offset: null,
};

export const onLoadingBookingsPending = state => {
  return {
    ...state,
    loading: true,
  };
};

export const onLoadingBookingsFulfilled = (state, action) => {
  const { payload } = action;
  const listBookingsWithPagination = Lodash.get(payload, ['bookings'], null);
  const intTotal = Lodash.get(payload, ['total'], null);
  const intOffset = Lodash.get(payload, ['offset'], null);
  const listBookings = listBookingsWithPagination || payload;
  const bookingsNormalized = normalizeBookings(listBookings);
  const existingBookingsById = Lodash.get(state, ['bookingData', 'byId'], {});
  const existingBookingsAllIds = Lodash.get(
    state,
    ['bookingData', 'allIds'],
    []
  );

  const temp = Lodash.cloneDeep(existingBookingsById);
  const newBookingsById = Lodash.merge(temp, bookingsNormalized.bookings.byId);

  const newBookingsAllIds = Lodash.uniqBy(
    existingBookingsAllIds.concat(bookingsNormalized.bookings.allIds)
  );
  const exisitingUsersById = Lodash.get(state, ['users', 'byId'], {});
  const existingUsersAllId = Lodash.get(state, ['users', 'allIds'], []);
  const newUsersById = {
    ...exisitingUsersById,
    ...bookingsNormalized.users.byId,
  };
  const newUsersAllIds = Lodash.uniqBy(
    existingUsersAllId.concat(bookingsNormalized.users.allIds)
  );
  if (listBookings.length) {
    return {
      ...state,
      bookingData: new NormObjCstr(newBookingsById, newBookingsAllIds),
      users: new NormObjCstr(newUsersById, newUsersAllIds),
      loading: false,
      hasError: false,
      total: intTotal,
      offset: intOffset,
    };
  }
  return { ...state, loading: false, hasError: false };
};

export const onLoadingBookingsRejected = state => {
  return {
    ...state,
    loading: false,
    hasError: true,
  };
};

export const onUpdateBooking = (state, action) => {
  const updatedBooking = action.payload;
  const bookingsNormalized = normalizeBookings(updatedBooking);
  const existingBookingsById = Lodash.get(state, ['bookingData', 'byId'], {});
  const existingBookingsAllIds = Lodash.get(
    state,
    ['bookingData', 'allIds'],
    []
  );

  const temp = Lodash.cloneDeep(existingBookingsById);
  const newBookingsById = Lodash.merge(temp, bookingsNormalized.bookings.byId);

  const newBookingsAllIds = Lodash.uniqBy(
    existingBookingsAllIds.concat(bookingsNormalized.bookings.allIds)
  );

  const exisitingUsersById = Lodash.get(state, ['users', 'byId'], {});
  const existingUsersAllId = Lodash.get(state, ['users', 'allIds'], []);
  const newUsersById = {
    ...exisitingUsersById,
    ...bookingsNormalized.users.byId,
  };
  const newUsersAllIds = Lodash.uniqBy(
    existingUsersAllId.concat(bookingsNormalized.users.allIds)
  );
  return {
    ...state,
    bookingData: new NormObjCstr(newBookingsById, newBookingsAllIds),
    users: new NormObjCstr(newUsersById, newUsersAllIds),
    loading: false,
    hasError: false,
  };
};
export const onDeleteBooking = (state, action) => {
  const deletedBookingId = action.payload;
  const existingBookingsById = Lodash.get(state, ['bookingData', 'byId'], {});
  const existingBookingsAllIds = Lodash.get(
    state,
    ['bookingData', 'allIds'],
    []
  );

  const updatedBookingsById = Lodash.omit(
    existingBookingsById,
    deletedBookingId
  );
  const updatedBookingsAllIds = existingBookingsAllIds.filter(
    id => id !== deletedBookingId
  );
  return {
    ...state,
    bookingData: new NormObjCstr(updatedBookingsById, updatedBookingsAllIds),
  };
};

const contentReducer = typeToReducer(
  {
    [types.LOAD_BOOKINGS]: {
      PENDING: onLoadingBookingsPending,
      FULFILLED: onLoadingBookingsFulfilled,
      REJECTED: onLoadingBookingsRejected,
    },
    [types.ADD_BOOKING]: onUpdateBooking,
    [types.UPDATE_BOOKING]: onUpdateBooking,
    [types.DELETE_BOOKING]: onDeleteBooking,
  },
  initialState
);

export default contentReducer;
