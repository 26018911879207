/* eslint-disable react/require-default-props */

import { StyleRulesCallback, withStyles } from '@material-ui/core';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

type Props = {
  classes: ClassNameMap;
  className?: string;
  nbReservableOffices: number;
  nbOffices: number;
};

const OfficeAvailabilityCount = (props: Props) => {
  const { classes, className, nbReservableOffices, nbOffices } = props;
  const { t } = useTranslation();

  return (
    <div className={clsx(classes.default, className)}>
      {t<string>('manageOffices.count', {
        nbReservableOffices,
        count: nbOffices,
      })}
    </div>
  );
};

const style: StyleRulesCallback<string> = () => ({
  default: {
    whiteSpace: 'nowrap',
  },
});

export default withStyles(style)(OfficeAvailabilityCount);
