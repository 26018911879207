import { withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import PropTypes from 'prop-types';

import SelectWithGroups from '../SelectWithGroups';
import { colors } from '../../../Core/Theme';

const SelectInputBoxWithGroups = props => {
  const {
    classes,
    className,
    classNameMain,
    classNameTextInput,
    classNameCarat,
  } = props;

  return (
    <SelectWithGroups
      {...props}
      className={clsx(classes.container, className)}
      classNameMain={clsx(classes.main, classNameMain)}
      classNameTextInput={clsx(classes.textInput, classNameTextInput)}
      classNameCarat={clsx(classes.carat, classNameCarat)}
    />
  );
};

const styles = () => ({
  container: {
    display: 'flex',
    cursor: 'pointer',
  },
  main: {
    borderBottom: `solid 0px black !important`,
    flex: 1,
  },
  textInput: {
    paddingLeft: 10,
    paddingRight: 10,
    fontFamily: 'VerlagBook',
    fontSize: '18px',
    border: `solid 1px ${colors.middle}`,
    borderRadius: 4,
    marginRight: -8,
    backgroundColor: `${colors.white} !important`,
  },
  carat: {
    right: 10,
    position: `absolute`,
    pointerEvents: `none`,
  },
});

SelectInputBoxWithGroups.defaultProps = {
  classNameMain: null,
  classNameTextInput: null,
  classNameCarat: null,
};
SelectInputBoxWithGroups.propTypes = {
  classNameMain: PropTypes.string,
  classNameTextInput: PropTypes.string,
  classNameCarat: PropTypes.string,
};

export default withStyles(styles)(SelectInputBoxWithGroups);
