/* eslint-disable react/no-array-index-key */
import { useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Lodash from 'lodash';
import PropTypes from 'prop-types';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useTranslation } from 'react-i18next';

// Theme
import { breakpoints, colors } from '../../../Core/Theme';
import TextButton from '../TextButton';

import { getIsMobile } from '../../../Core/Utils/windowDimensions';

const MapLegend = ({ classes, legends }) => {
  const isMobile = getIsMobile();
  const [isShowingMore, setIsShowingMore] = useState(false);
  const { t } = useTranslation();

  const handleShowingLegend = () => {
    if (isShowingMore) {
      return setIsShowingMore(false);
    }
    return setIsShowingMore(true);
  };

  const renderShowMore = () => {
    return (
      <span className={classes.showMoreSpan}>
        <TextButton
          text={isShowingMore ? t('general.hide') : t('general.show')}
          onClick={handleShowingLegend}
          className={classes.showMoreButton}
        />
        {isShowingMore ? (
          <ExpandMoreIcon
            onClick={handleShowingLegend}
            className={classes.icon}
          />
        ) : (
          <ExpandLessIcon
            onClick={handleShowingLegend}
            className={classes.icon}
          />
        )}
      </span>
    );
  };

  const renderLegends = () => {
    if (!isShowingMore && isMobile) {
      return <div className={classes.legend}>Legend</div>;
    }

    return legends.map((legend, index) => {
      const indicator = Lodash.get(legend, ['indicator'], null);
      const indicatorStyle = Lodash.get(legend, ['style'], null);
      const label = Lodash.get(legend, ['label'], null);
      const labelKey = Lodash.get(legend, ['labelKey'], null);
      const key = Lodash.get(legend, ['key'], null);
      return (
        <div
          key={`${key}-${index}`}
          className={classes.legendIndicatorContainer}
        >
          {Lodash.isFunction(indicator) ? (
            indicator(legend)
          ) : (
            <>
              <div
                className={clsx(classes.legendIndicator)}
                style={indicatorStyle}
              />
              <span className={classes.legendLabel}>
                {labelKey ? t(labelKey) : label}
              </span>
            </>
          )}
        </div>
      );
    });
  };

  if (!Lodash.isNil(legends) && !Lodash.isEmpty(legends)) {
    return (
      <div className={classes.container}>
        {isMobile && renderShowMore()}
        {renderLegends()}
      </div>
    );
  }

  return null;
};

const styles = theme => ({
  legendLabel: {
    fontFamily: 'VerlagLight',
    fontSize: 12,
    paddingLeft: 5,
    whiteSpace: 'break-spaces',
  },
  legendIndicatorContainer: {
    display: 'flex',
    alignItems: 'center',
    paddingTop: 8,
  },
  container: {
    [theme.breakpoints.up(breakpoints.MD)]: {
      bottom: '4%',
      width: 'fit-content',
      margin: 'auto',
    },
    width: 110,
    paddingTop: 2,
    background: colors.white,
    boxShadow: '0 0 4px 0 rgba(0, 0, 0, 0.25)',
    position: 'fixed',
    padding: 10,
    bottom: 90,
    borderRadius: 3,
    left: 20,
    fontFamily: 'VerlagLight',
    fontSize: 12,
  },
  legendIndicator: {
    width: '15px',
    height: '15px',
    borderRadius: '2px',
  },
  showMoreSpan: {
    fontFamily: 'VerlagLight',
    fontSize: 10,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
    position: 'absolute',
    right: 1,
  },
  showMoreButton: {
    fontFamily: 'VerlagLight',
    fontSize: 10,
    paddingRight: 1,
  },
  icon: {
    color: colors.palette.primary.main,
    width: 12,
    height: 12,
  },
  legend: {
    textAlign: 'center',
    paddingTop: 3,
    marginBottom: -5,
  },
});

MapLegend.defaultProps = {
  legends: null,
};
MapLegend.propTypes = {
  legends: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      label: PropTypes.string,
      indicator: PropTypes.any,
      style: PropTypes.shape,
    })
  ),
};

export default withStyles(styles)(MapLegend);
