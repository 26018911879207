/* eslint-disable @typescript-eslint/no-use-before-define */
import { useState, useEffect, useRef } from 'react';
import { Source, NavigationControl } from 'react-map-gl';
import { withStyles } from '@material-ui/core/styles';
import get from 'lodash/get';
import head from 'lodash/head';
import isEmpty from 'lodash/isEmpty';
import compact from 'lodash/compact';
import without from 'lodash/without';
import isNil from 'lodash/isNil';
import memoizeOne from 'memoize-one';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import CircularProgress from '@material-ui/core/CircularProgress';

// Constants
import Slide from '@material-ui/core/Slide';
import {
  DEFAULT_VIEWPORT,
  LAYERS,
  MAP_TYPE,
  FEATURES_TYPE,
  ZOOM_LEVEL,
  ZOOM_KEYS,
} from './Constants';

// Api & Core
import { fetchFeaturesForDatasetId } from '../../Core/Api';
import { getStaticImageUrl } from '../../Core/Utils';
import { S3IconStrings, S3ImagesString } from '../../resources';
import { breakpoints, colors } from '../../Core/Theme';

// Utils
import {
  calculateCentroid,
  generateNewViewport,
  generateFeatureOverlay,
  getMapData,
  isSameMap,
} from './Utils';

// Components
import * as Layers from './Layers';
import {
  MapWrapper,
  FloatingActionDescription,
  MapLegend,
  Text,
} from '../Common';

// Models
import { ManageWorkspaces, DeskReservation } from './Models';
import { Office } from '../../Models';

require('./map_gl_override.css');

const MAP_RESOURCES = {
  [MAP_TYPE.MANAGE_WORKSPACES]: [
    {
      img: getStaticImageUrl(S3ImagesString.deskUnavailable),
      name: 'unavailable',
    },
  ],
  [MAP_TYPE.DESK_RESERVATION]: [
    {
      img: getStaticImageUrl(S3ImagesString.deskUnavailable),
      name: 'deskUnavailable',
    },
  ],
};

const getMapStyleType = refMap => {
  const objStyleSheet = get(refMap, ['style', 'stylesheet', 'sources'], {});
  return get(
    objStyleSheet,
    [head(Object.keys(objStyleSheet)), 'type'],
    'raster'
  );
};

const getMapZoom =
  refMap =>
  ({ zoomKey, config }) => {
    const zoomConfigValue = get(config, [zoomKey], null);

    if (zoomConfigValue) {
      return zoomConfigValue;
    }

    const strMapStyle = getMapStyleType(refMap);

    return ZOOM_LEVEL[strMapStyle][zoomKey];
  };

const getMapConfig = objFeatureData => {
  const features = get(objFeatureData, ['features'], []);
  const objFeatureConfig = features.find(objFeature => {
    const strType = get(objFeature, ['properties', 'type'], null);
    return strType === FEATURES_TYPE.CONFIG;
  });

  return get(objFeatureConfig, ['properties'], {});
};

const getMapConfigMemoized = memoizeOne(getMapConfig, isSameMap);

const DeskFloorPlan = props => {
  const {
    offices,
    desks,
    datasetId,
    mapStyleId,
    classes,
    type,
    selectedOffice,
    onOfficePress,
    onDeskPress,
    onZoomChange,
    onMapReady,
    isLoading,
    onOverlayPress,
    selectedDesks,
    reservations,
    hasAnyMappedFloors,
    selectedFloor,
    selectedReservationId,
    conflictDeskId,
    selectedGrouping,
    isInteractionDisabled,
    limitDeskInteraction,
    floatingDescription,
    unselectablePopupLabel,
    userSfId,
    groupingId,
    isBannerVisible,
    accountSfId,
    showLicensedErrors,
  } = props;
  const [viewport, setViewport] = useState(DEFAULT_VIEWPORT);
  const [isFetchingFeatures, setIsFetchingFeatures] = useState(!!datasetId);
  const [associatedFeatureHoverIds, setAssociatedFeatureHoverIds] =
    useState(null);
  const [featureHoverId, setFeatureHoverId] = useState(null);

  const [mapIsReady, setMapIsReady] = useState(false);
  const [hasOverlay, setHasOverlay] = useState(false);

  const [featuresData, setFeaturesData] = useState(null);
  const [mapStyle, setMapStyle] = useState(null);
  const { t } = useTranslation();
  const mapRef = useRef(null);

  useEffect(() => {
    setViewport(DEFAULT_VIEWPORT);
    setMapIsReady(false);
    setMapStyle(null);
    setFeaturesData(null);
    if (datasetId) {
      setIsFetchingFeatures(true);
      fetchFeaturesForDatasetId(datasetId)
        .then(res => {
          setIsFetchingFeatures(false);
          setFeaturesData(res);
          return setMapStyle(
            `mapbox://styles/${process.env.REACT_APP_MAPBOX_USER}/${mapStyleId}`
          );
        })
        .catch(() => {
          return setIsFetchingFeatures(false);
        });
    }
  }, [datasetId]);

  useEffect(() => {
    if (isNil(selectedOffice) && hasOverlay) {
      setHasOverlay(false);
    }

    if (selectedOffice && mapIsReady && !isEmpty(featuresData)) {
      flyToFeatureFocus(selectedOffice);
    }
  }, [selectedOffice, mapIsReady]);

  const data = getMapData(featuresData, offices, datasetId);
  const getMap = get(mapRef, ['current', 'getMap'], () => null);
  const map = getMap();
  const zoom = get(viewport, ['zoom'], 0);
  const objMapConfig = getMapConfigMemoized(featuresData);
  const getZoom = getMapZoom(map);

  const getCursor = ({ isHovering, isDragging }) => {
    if (isDragging) {
      return 'grabbing';
    }
    return isHovering ? 'pointer' : 'grab';
  };

  const getOverlayFeature = () => {
    const boundingBox = map.getBounds();
    return generateFeatureOverlay(boundingBox);
  };

  const getDataWithOverlayBox = () => {
    const objOverlayFeature = getOverlayFeature();
    const actualFeatures = get(data, ['dataset', 'features'], []);
    const featuresWithOverlaybox = [objOverlayFeature, ...actualFeatures];
    const geoJsonDataWithOverlayFeature = {
      ...get(data, ['dataset'], null),
      features: featuresWithOverlaybox,
    };

    return geoJsonDataWithOverlayFeature;
  };

  const getDataset = memoizeOne((overlay, mapReady) => {
    if (overlay && mapReady) {
      return getDataWithOverlayBox();
    }

    return get(data, ['dataset'], null);
  });

  const getIsLabelVisible = memoizeOne(intZoom => {
    if (
      intZoom >=
      getZoom({
        zoomKey: ZOOM_KEYS.VISIBLE_ZOOM_LABELS,
        config: objMapConfig,
      })
    ) {
      return true;
    }
    return false;
  });

  const getInteractiveLayersId = () => {
    const interactiveLayers = {
      [MAP_TYPE.MANAGE_WORKSPACES]: [
        LAYERS.RESERVABLE_OFFICE,
        LAYERS.UNASSIGNED_OFFICE,
        LAYERS.UTILIZED_OFFICE,
      ],
      [MAP_TYPE.DESK_RESERVATION]: [
        LAYERS.SELECTED_DESK,
        isInteractionDisabled || selectedReservationId
          ? LAYERS.UNSELECTABLE_DESK
          : LAYERS.AVAILABLE_DESK,
      ],
    };
    const interactiveLayersOnOverlay = {
      [MAP_TYPE.MANAGE_WORKSPACES]: [
        LAYERS.OVERLAY,
        LAYERS.RESERVABLE_DESK,
        LAYERS.UNRESERVABLE_DESK,
      ],
      [MAP_TYPE.DESK_RESERVATION]: [],
    };

    if (hasOverlay) {
      return interactiveLayersOnOverlay[type];
    }

    return interactiveLayers[type];
  };

  const getLegendLayersIds = () => {
    const layers = {
      [MAP_TYPE.MANAGE_WORKSPACES]: [
        LAYERS.RESERVABLE_OFFICE,
        LAYERS.UNASSIGNED_OFFICE,
        LAYERS.UTILIZED_OFFICE,
      ],
      [MAP_TYPE.DESK_RESERVATION]: [
        LAYERS.AVAILABLE_DESK,
        LAYERS.UNAVAILABLE_DESK,
        LAYERS.SELECTED_DESK,
        LAYERS.USER_RESERVED_DESK,
      ],
    };
    const layersOnOverlay = {
      [MAP_TYPE.MANAGE_WORKSPACES]: [
        LAYERS.RESERVABLE_DESK,
        LAYERS.UNRESERVABLE_DESK,
      ],
      [MAP_TYPE.DESK_RESERVATION]: [],
    };
    if (hasOverlay) {
      return layersOnOverlay[type];
    }
    return layers[type];
  };

  const getLegend = memoizeOne(activeLayers => {
    const legends = activeLayers.map(strLayerActive => {
      return Layers.Legends[strLayerActive];
    });
    return compact(legends);
  });

  const getMapStyleIsLoaded = refMap => {
    const isStyleFullLoaded = get(refMap, ['_loaded'], false);
    return isStyleFullLoaded;
  };

  const getFloatingDescription = memoizeOne(
    (strType, intSelectedReservationId, arrFloatingDescriptions) => {
      if (arrFloatingDescriptions) {
        return arrFloatingDescriptions;
      }
      switch (strType) {
        case MAP_TYPE.MANAGE_WORKSPACES:
          return [t('deskFloorPlan.manageWorkspaces.description')];
        case MAP_TYPE.DESK_RESERVATION:
          if (intSelectedReservationId) {
            return [
              t('deskFloorPlan.deskReservation.description_edit'),
              t('deskFloorPlan.deskReservation.description_edit_2'),
            ];
          }
          return [t('deskFloorPlan.deskReservation.description')];
        default:
          return [t('deskFloorPlan.deskReservation.description')];
      }
    }
  );
  const flyTo = coordinates => {
    const newViewport = generateNewViewport({
      coordinates,
      zoom: getZoom({
        zoomKey: ZOOM_KEYS.DEFAULT_FLY_TO_ZOOM,
        config: objMapConfig,
      }),
    });
    return setViewport({ ...viewport, ...newViewport });
  };

  const flyToFeatureFocus = strOfficeId => {
    const featureId = get(
      data,
      [FEATURES_TYPE.OFFICE, strOfficeId, 'mapboxFeatureID'],
      null
    );
    const features = get(data, ['dataset', 'features'], []);
    const featureFocus = features.find(feature => {
      return get(feature, ['id'], null) === featureId;
    });
    const geometry = get(featureFocus, ['geometry'], null);
    const coordinates = calculateCentroid(geometry);
    return flyTo(coordinates);
  };

  const handleOnViewportChange = nextViewport => {
    return setViewport(nextViewport);
  };

  const handleOnOfficePress = feature => {
    const featuresLookup = get(data, ['featuresLookup'], {});
    const featureId = get(feature, ['id'], null);
    const officeData = featuresLookup[featureId];

    onOfficePress(officeData);

    return setHasOverlay(true);
  };

  const handleOnDeskPress = feature => {
    const featuresLookup = get(data, ['featuresLookup'], {});
    const featureId = get(feature, ['id'], null);
    const fullFeature = featuresLookup[featureId];
    const intDeskId = get(fullFeature, ['idDesk'], null);
    const isDeskPressAlreadySelected = selectedDesks
      ? selectedDesks.includes(intDeskId)
      : false;

    if (
      (isInteractionDisabled ||
        (selectedDesks && limitDeskInteraction === selectedDesks.length)) &&
      !isDeskPressAlreadySelected
    ) {
      return null;
    }

    if (!fullFeature) {
      const associatedFeatureId = get(feature, [
        'properties',
        'associatedFeature',
      ]);
      return onDeskPress(featuresLookup[associatedFeatureId]);
    }
    return onDeskPress(fullFeature);
  };

  const handleOnOverlayPress = () => {
    onOverlayPress();
    return setHasOverlay(false);
  };

  const handleOnClick = e => {
    const feature = get(e, ['features', 0], null);
    const geometry = get(feature, ['geometry'], {});
    const featureType = get(feature, ['properties', 'type'], null);

    if (
      mapIsReady &&
      feature &&
      !isEmpty(geometry) &&
      !hasOverlay &&
      map.getZoom() <
        getZoom({
          zoomKey: ZOOM_KEYS.DEFAULT_FLY_TO_ZOOM,
          config: objMapConfig,
        }) &&
      type === MAP_TYPE.MANAGE_WORKSPACES
    ) {
      const centroid = calculateCentroid(geometry);
      flyTo(centroid);
    }

    const objFeatureActionByType = {
      [FEATURES_TYPE.OFFICE]: handleOnOfficePress,
      [FEATURES_TYPE.DESK]: handleOnDeskPress,
      [FEATURES_TYPE.CHAIR]: handleOnDeskPress,
      [LAYERS.OVERLAY]: handleOnOverlayPress,
    };

    return (
      !isNil(objFeatureActionByType[featureType]) &&
      objFeatureActionByType[featureType](feature)
    );
  };

  const handleOnHover = e => {
    const feature = get(e, ['features', 0], {});
    const featureId = get(feature, ['id'], null);
    const associatedFeature = get(
      feature,
      ['properties', 'associatedFeature'],
      null
    );

    toggleHover([featureId, associatedFeature]);

    setFeatureHoverId(e?.features?.[0]?.properties?.id);

    if (isNil(feature) || isEmpty(feature)) {
      return null;
    }

    return setAssociatedFeatureHoverIds([featureId, associatedFeature]);
  };

  const handleOnMouseLeave = () => {
    setFeatureHoverId(null);
    toggleHover(associatedFeatureHoverIds);
  };

  const toggleHover = listId => {
    if (mapIsReady) {
      compact(associatedFeatureHoverIds).forEach(strHoverId => {
        const isHover = listId.includes(strHoverId);
        map.setFeatureState(
          { source: 'features', id: strHoverId },
          { hover: isHover }
        );
      });
      setAssociatedFeatureHoverIds(null);
    }
  };

  const loadMapImages = images => {
    if (images.length && map) {
      images.forEach(objImage => {
        const image = get(objImage, ['img'], '');
        const strName = get(objImage, ['name'], '');
        map.loadImage(image, (err, bitMapImage) => {
          map.addImage(strName, bitMapImage);
        });
      });
    }
  };

  const handleOnLoad = () => {
    if (map) {
      onMapReady();
      const isStyleFullLoaded = get(map, ['_loaded'], false);
      if (isStyleFullLoaded) {
        const centerCoordinates = get(
          map,
          ['style', 'stylesheet', 'center'],
          []
        );
        const newViewport = generateNewViewport({
          coordinates: centerCoordinates,
          zoom: getZoom({
            zoomKey: ZOOM_KEYS.INITIAL_ZOOM,
            config: objMapConfig,
          }),
          withTransition: false,
        });
        setViewport({ ...viewport, ...newViewport });
        loadMapImages(MAP_RESOURCES[type] || []);
        return setMapIsReady(true);
      }
    }

    return null;
  };

  const renderDeskAdminLayers = () => {
    const { getOffices, getDesks, generateLabelsSource } = ManageWorkspaces({
      mapboxLookup: data,
      showLicensedErrors,
    });
    const {
      reservable,
      unavailable,
      unassigned,
      utilized,
      utilizedReservable,
    } = getOffices({
      offices,
      featuresData,
      groupingId: selectedGrouping,
    });
    const { inactive, active } = getDesks({
      desks,
    });
    const selectedOfficeFeatureId = get(
      data,
      [FEATURES_TYPE.OFFICE, selectedOffice, 'mapboxFeatureID'],
      null
    );
    const isSelectedOfficeUnassigned = unassigned.includes(
      selectedOfficeFeatureId
    );
    const objLabelsSource = generateLabelsSource();
    const commonAreaIds = get(
      objLabelsSource,
      ['commonArea', 'commonAreaIds'],
      []
    );
    const indicators = get(objLabelsSource, ['commonArea', 'indicators'], {});

    // Show error if we have occupied office with Access
    let officeNameWithError = '';
    if (utilizedReservable.length === 1) {
      const officeWithError = offices.find(
        o =>
          o.mapboxFeatureProductMappings[0].mapboxFeatureId ===
          utilizedReservable[0]
      );
      officeNameWithError = Office.getOfficeName(officeWithError);
    }
    return (
      <>
        <Layers.CommonArea common={commonAreaIds} />

        <Layers.ReservableOffice
          offices={without(reservable, selectedOfficeFeatureId)}
        />
        <Layers.UnassignedOffice offices={unassigned} />
        <Layers.UtilizedOffice offices={utilized} />
        <Layers.UtilizedReservableOffice
          offices={utilizedReservable}
          data={data}
          selectedOfficeFeatureId={selectedOfficeFeatureId}
          officeHoverId={featureHoverId}
        />
        <Layers.ReservableOfficeUnavailable offices={unavailable} />
        {selectedOfficeFeatureId && (
          <>
            <Layers.Overlay />
            <Layers.Office
              offices={[selectedOfficeFeatureId]}
              unassigned={unassigned}
            />
            <Layers.ReservableDesk desks={active} />
            <Layers.UnreservableDesk
              desks={inactive}
              isOfficeUnassigned={isSelectedOfficeUnassigned}
            />
          </>
        )}
        <Layers.CommonLabels
          indicators={indicators}
          commonAreas={commonAreaIds}
          labelVisible={getIsLabelVisible(zoom)}
        />
        {!selectedOfficeFeatureId && utilizedReservable.length > 0 && (
          <div className={classes.utilizedReservableErrorMessage}>
            {utilizedReservable.length === 1
              ? t('deskFloorPlan.manageWorkspaces.licensed_offices_error', {
                  officeName: officeNameWithError,
                })
              : t(
                  'deskFloorPlan.manageWorkspaces.licensed_offices_error_multiple',
                  { count: utilizedReservable.length }
                )}
          </div>
        )}
      </>
    );
  };

  const renderDeskUserLayers = () => {
    const {
      getOffices: getUserOffices,
      getDesks: getUserDesks,
      generateLabelsSource,
    } = DeskReservation({
      mapboxLookup: data,
    });
    const {
      assigned,
      unavailable: unavailableOffice,
      officesWithDesksAvailable,
      assignedOfficeSfIds,
    } = getUserOffices({
      offices,
      reservations,
      groupingId,
    });

    const teammateReservations = reservations.filter(
      reservation => reservation?.owner?.accountSfId === accountSfId
    );

    const teammateReservedDeskIds = teammateReservations.map(
      reservation => reservation?.desk?.idDesk
    );

    const { selected, available, unavailable, userReserved, teammateReserved } =
      getUserDesks({
        offices,
        selectedDesks,
        reservations,
        userSfId,
        groupingId,
        assignedOfficeSfIds,
        teammateReservedDeskIds,
      });

    const objLabelsSource = generateLabelsSource({
      selected,
      selectedDesks,
    });
    const selectedDesksIndicators = get(
      objLabelsSource,
      ['selectedDesks', 'indicators'],
      []
    );
    const desksLabelId = get(
      objLabelsSource,
      ['selectedDesks', 'desksLabelId'],
      {}
    );
    const commonAreaIds = get(
      objLabelsSource,
      ['commonArea', 'commonAreaIds'],
      []
    );
    const commonAreaIndicators = get(
      objLabelsSource,
      ['commonArea', 'indicators'],
      {}
    );
    return (
      <>
        <Layers.CommonArea common={commonAreaIds} />
        <Layers.AssignedOffice
          officesWithDesksAvailable={officesWithDesksAvailable}
          offices={assigned}
        />
        <Layers.UnavailableDesk
          desks={unavailable}
          conflictDeskId={conflictDeskId}
          data={data}
        />
        <Layers.SelectedDesk desks={selected} />
        <Layers.SelectedDesksLabels
          desks={desksLabelId}
          indicators={selectedDesksIndicators}
        />
        <Layers.UnavailableOffice offices={unavailableOffice} />
        <Layers.CommonLabels
          indicators={commonAreaIndicators}
          commonAreas={commonAreaIds}
          labelVisible={getIsLabelVisible(zoom)}
        />
        <Layers.UserReservedDesk
          desks={[...userReserved, ...teammateReserved]}
        />
        {selectedReservationId || isInteractionDisabled ? (
          <Layers.UnselectableDesk
            desks={available}
            hoverIds={associatedFeatureHoverIds}
            data={data}
            unselectablePopupLabel={unselectablePopupLabel}
          />
        ) : (
          <Layers.AvailableDesk desks={available} />
        )}
      </>
    );
  };

  const handleOnViewStateChange = ({
    interactionState,
    viewState,
    oldViewState,
  }) => {
    const isZooming = get(interactionState, ['isZooming'], false);
    const intZoom = get(viewState, ['zoom'], false);
    const intOldZoom = get(oldViewState, ['zoom'], false);
    if (isZooming) {
      onZoomChange({ newZoom: intZoom, oldZoom: intOldZoom });
    }
  };

  const renderLoading = () => {
    return (
      <div className={classes.loadingContainer}>
        <CircularProgress
          size={50}
          thickness={8}
          className={classes.circularProgress}
        />
      </div>
    );
  };

  const renderLayers = () => {
    const mapLayers = {
      [MAP_TYPE.MANAGE_WORKSPACES]: renderDeskAdminLayers,
      [MAP_TYPE.DESK_RESERVATION]: renderDeskUserLayers,
    };

    return mapLayers[type]();
  };

  if (isLoading || isFetchingFeatures) {
    return renderLoading();
  }

  if ((!mapStyleId || !datasetId) && (!isLoading || !isFetchingFeatures)) {
    return (
      <div className={classes.emptyState}>
        <img
          src={getStaticImageUrl(S3IconStrings.deskWaiting1)}
          srcSet={`${getStaticImageUrl(S3IconStrings.deskWaiting2)},
   ${getStaticImageUrl(S3IconStrings.deskWaiting3)}`}
          alt={t('altTexts.desk_waiting')}
          className={classes.emptyImage}
        />
        <Text
          text={
            !hasAnyMappedFloors
              ? t('manageOffices.empty_title')
              : t('manageOffices.empty_floor_title', {
                  floorName: get(selectedFloor, 'floorName', ''),
                })
          }
          className={classes.mediumTitle}
        />
        <Text
          text={t('manageOffices.empty_description')}
          className={classes.emptyDescription}
        />
      </div>
    );
  }

  const interactiveLayersIds = getInteractiveLayersId();
  const legendLayersIds = getLegendLayersIds();
  const isStyleLoaded = getMapStyleIsLoaded(map);

  return (
    <>
      {!isStyleLoaded && renderLoading()}
      <MapWrapper
        {...viewport}
        attributionControl={false}
        ref={mapRef}
        onLoad={handleOnLoad}
        maxZoom={getZoom({
          zoomKey: ZOOM_KEYS.MAX_ZOOM,
          config: objMapConfig,
        })}
        minZoom={getZoom({
          zoomKey: ZOOM_KEYS.MIN_ZOOM,
          config: objMapConfig,
        })}
        scrollZoom={!hasOverlay}
        dragPan={!hasOverlay}
        dragRotate={false}
        mapStyle={mapStyle}
        onViewportChange={handleOnViewportChange}
        onViewStateChange={handleOnViewStateChange}
        interactiveLayerIds={interactiveLayersIds}
        onHover={handleOnHover}
        onMouseLeave={handleOnMouseLeave}
        onClick={handleOnClick}
        getCursor={getCursor}
        visible={isStyleLoaded}
        style={{ ...(!isStyleLoaded && { position: 'absolute' }) }}
      >
        {isStyleLoaded ? (
          <>
            {!hasOverlay && (
              <>
                <div className={classes.navigationControlContainer}>
                  <NavigationControl showCompass={false} />
                </div>
                <Slide
                  direction="up"
                  in={isBannerVisible}
                  mountOnEnter
                  unmountOnExit
                >
                  <FloatingActionDescription
                    description={getFloatingDescription(
                      type,
                      selectedReservationId,
                      floatingDescription
                    )}
                  />
                </Slide>
              </>
            )}
            {legendLayersIds.length > 0 && (
              <MapLegend legends={getLegend(legendLayersIds)} />
            )}
            <Source
              id="features"
              type="geojson"
              data={getDataset(hasOverlay, mapIsReady)}
              promoteId="id"
            >
              {renderLayers()}
              <Layers.Overlay />
            </Source>
          </>
        ) : null}
      </MapWrapper>
    </>
  );
};

DeskFloorPlan.defaultProps = {
  offices: [],
  desks: [],
  reservations: [],
  type: MAP_TYPE.MANAGE_WORKSPACES,
  selectedOffice: null,
  selectedDesks: [],
  onOfficePress: () => null,
  onDeskPress: () => null,
  onZoomChange: () => null,
  onMapReady: () => null,
  onOverlayPress: () => null,
  selectedFloor: {},
  hasAnyMappedFloors: false,
  selectedReservationId: null,
  conflictDeskId: null,
  selectedGrouping: null,
  isInteractionDisabled: false,
  floatingDescription: null,
  unselectablePopupLabel: null,
  limitDeskInteraction: null,
  userSfId: '',
  groupingId: '',
  isBannerVisible: false,
  accountSfId: undefined,
  showLicensedErrors: false,
};
DeskFloorPlan.propTypes = {
  datasetId: PropTypes.string.isRequired,
  mapStyleId: PropTypes.string.isRequired,
  selectedGrouping: PropTypes.string,
  isLoading: PropTypes.bool.isRequired,
  offices: PropTypes.array,
  desks: PropTypes.arrayOf(PropTypes.string),
  reservations: PropTypes.arrayOf(PropTypes.object),
  classes: PropTypes.object.isRequired,
  type: PropTypes.string,
  selectedOffice: PropTypes.string,
  selectedDesks: PropTypes.arrayOf(PropTypes.string),
  onOfficePress: PropTypes.func,
  onDeskPress: PropTypes.func,
  onZoomChange: PropTypes.func,
  onMapReady: PropTypes.func,
  onOverlayPress: PropTypes.func,
  selectedFloor: PropTypes.object,
  hasAnyMappedFloors: PropTypes.bool,
  isInteractionDisabled: PropTypes.bool,
  selectedReservationId: PropTypes.number,
  conflictDeskId: PropTypes.number,
  floatingDescription: PropTypes.arrayOf(PropTypes.string),
  unselectablePopupLabel: PropTypes.string,
  limitDeskInteraction: PropTypes.number,
  userSfId: PropTypes.string,
  groupingId: PropTypes.string,
  isBannerVisible: PropTypes.bool,
  accountSfId: PropTypes.string,
  showLicensedErrors: PropTypes.bool,
};

const styles = theme => ({
  circularProgress: {
    color: colors.gray,
  },
  loadingContainer: {
    height: '70vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  navigationControlContainer: {
    position: 'absolute',
    left: 20,
    top: 10,
  },
  emptyState: {
    display: 'grid',
    textAlign: 'center',
    paddingTop: 55,
    paddingRight: 40,
    paddingLeft: 40,
    [theme.breakpoints.up(breakpoints.MOBILE)]: {
      paddingTop: 80,
    },
  },
  emptyImage: {
    margin: 'auto',
    marginBottom: 10,
    height: 100,
    paddingBottom: 10,
    [theme.breakpoints.up(breakpoints.MOBILE)]: {
      paddingBottom: 40,
    },
  },
  mediumTitle: {
    fontSize: 20,
    fontFamily: 'VerlagBold',
    marginBottom: 10,
    [theme.breakpoints.up(breakpoints.MOBILE)]: {
      fontSize: 24,
    },
  },
  emptyDescription: {
    fontSize: 20,
    fontFamily: 'VerlagLight',
    [theme.breakpoints.up(breakpoints.MOBILE)]: {
      fontSize: 18,
    },
  },
  utilizedReservableErrorMessage: {
    display: 'flex',
    position: 'fixed',
    bottom: 0,
    right: 0,
    left: 0,
    border: `1px solid ${colors.palette.error.main}`,
    color: colors.palette.error.main,
    fontFamily: 'VerlagBook',
    background: colors.white,
    borderRadius: '2px',
    boxShadow: '0 0 4px 0 rgba(0, 0, 0, 0.25)',
    padding: '0 5px',
    textAlign: 'center',
    height: 80,
    alignItems: 'center',
    [theme.breakpoints.up(breakpoints.MOBILE)]: {
      display: 'none',
    },
  },
});

export default withStyles(styles)(DeskFloorPlan);
