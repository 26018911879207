import { Http, stringifyUrl } from '../Utils';
import {
  CreateOfficeReservationDto,
  OfficeReservationCharges,
  OfficeReservationDto,
} from './dto';
import getApiUrl from '../Utils/api';

export const createOfficeReservation = async (
  dto: CreateOfficeReservationDto
) => {
  return Http.post<OfficeReservationDto>('/office_reservations', dto, {
    baseURL: getApiUrl('1.10.0', process.env.REACT_APP_API_HOST),
  });
};

export const cancelOfficeReservation = async (reservationId: number) => {
  return Http.remove<OfficeReservationDto>(
    `/office_reservations/${reservationId}`
  );
};

export const getReservedOfficeSfIds = async (params: {
  floorId: string;
  locationSfId: string;
  minStartEpoch: number;
}) => {
  const url = stringifyUrl('/offices/reserved', params);
  const response = await Http.get<string[]>(url);
  return response.data;
};

export const fetchOfficeReservationCharges = async (props: {
  accountSfId: string;
  startEpoch: number;
  endEpoch: number;
}) => {
  const url = stringifyUrl('/office_reservations/charges', props);
  return Http.get<OfficeReservationCharges>(url);
};

export const fetchOfficeReservationById = (reservationId: number) => {
  return Http.get<OfficeReservationDto>(
    `/office_reservations/${reservationId}`
  );
};
