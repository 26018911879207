import { createSelector } from 'reselect';
import Lodash from 'lodash';
import drawerContent from '../../../Components/Reception/Header/drawerContent';

export const getReceptionistObject = state =>
  Lodash.get(state, ['receptionist'], {});

export const getCurrentLocation = createSelector(
  getReceptionistObject,
  objCalendar => Lodash.get(objCalendar, ['selectedLocation'], {})
);

export const getCurrentLocationSfId = createSelector(
  getCurrentLocation,
  objLocation => Lodash.get(objLocation, ['sfId'], '')
);

export const getTimezone = createSelector(getReceptionistObject, objCalendar =>
  Lodash.get(objCalendar, ['tz'], '')
);

export const getListNotifications = createSelector(
  getReceptionistObject,
  receptionistObject =>
    Lodash.get(receptionistObject, 'notificationsYearData', [])
);

export const getNotificationsAreLoading = createSelector(
  getReceptionistObject,
  receptionistObject =>
    Lodash.get(receptionistObject, 'notificationsLoading', false)
);

export const getNotificationsHaveError = createSelector(
  getReceptionistObject,
  receptionistObject =>
    Lodash.get(receptionistObject, 'notificationsHasError', false)
);

export const getLocationSettings = createSelector(getReceptionistObject, obj =>
  Lodash.get(obj, 'selectedLocationSettings', {})
);

export const getMailRoomDescription = createSelector(
  getLocationSettings,
  locationSettings => Lodash.get(locationSettings, 'mailroomDescription', null)
);

export const getMailroomDescriptionIsSet = createSelector(
  getMailRoomDescription,
  description => !Lodash.isEmpty(description)
);

export const getRedirectToPackageAlerts = createSelector(
  getReceptionistObject,
  obj => obj.redirectToPackageAlerts
);

export const getDrawerIsOpenState = createSelector(getReceptionistObject, obj =>
  Lodash.get(obj, 'drawerIsOpen', false)
);

export const getActiveDrawerContent = createSelector(
  getReceptionistObject,
  obj => Lodash.get(obj, 'activeDrawerContent', drawerContent.root)
);

export const getShowMailDescriptionErrorText = createSelector(
  getMailroomDescriptionIsSet,
  getRedirectToPackageAlerts,
  (mailRoomDescriptionIsSet, redirectToPackageAlerts) =>
    !mailRoomDescriptionIsSet && redirectToPackageAlerts
);

export const getNotificationRecipients = createSelector(
  getReceptionistObject,
  receptionistObject =>
    Lodash.get(receptionistObject, 'notificationRecipients', [])
);
