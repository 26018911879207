import Moment from 'moment';
import { extendMoment } from 'moment-range';
import useWindowDimensions, {
  getWindowDimensions,
  getIsMobile,
} from './windowDimensions';
import customProps from './customProps';
import pwValidate from './pwValidate';
import date from './date';
import { useEventListener } from './eventListener';
import createICS from './createICS';
import {
  getStaticImageUrl,
  getStaticUrl,
  getGoogleMapsLink,
} from './linkGenerator';
import { initializeSentry, setSentryUser } from './sentry';
import { iterateObjectProperties } from './objects';
import selectLocation from './selectLocation';
import { isDevEnvironment, isProdEnvironment } from './environment';
import { createHash } from './md5';
import * as strings from './strings';
import * as translations from './translations';
import {
  isMatchingStateAbbreviation,
  isMatchingStateName,
} from './locationMatchers';
import {
  NB_HOURS_IN_DAY,
  NB_MINUTES_IN_HOUR,
  NB_MS_IN_DAY,
  NB_MS_IN_HOUR,
  NB_MS_IN_MINUTE,
  NB_MS_IN_SECONDE,
  NB_SECONDS_IN_DAY,
  NB_SECONDS_IN_HOUR,
  NB_SECONDS_IN_MINUTE,
  SECOND_IN_MS,
} from './date-constants';
import * as Http from './http';
import { stringifyUrl } from './query-builder';
import { REGEX_ALPHANUMERIC_WITH_SPACES } from './regex';

const moment = extendMoment(Moment);

const queriedDateAndLocation = (dateUnix, locationSfId) => {
  if (dateUnix && locationSfId) {
    return `${dateUnix} ${locationSfId}`;
  }
  return ``;
};

const isChromeUserAgent =
  navigator.userAgent.toLowerCase().indexOf('chrome') > -1;

const isBookingAvailable = (startEpoch, endEpoch, actualStart, actualEnd) => {
  const rangeCreating = moment.range(startEpoch * 1, endEpoch * 1);
  const rangeActual = moment.range(actualStart * 1, actualEnd * 1);
  const isInValidRange = rangeCreating.overlaps(rangeActual);
  return isInValidRange;
};

const getCurrentBalance = objBalance => {
  const { remainingMonthlyAllowanceCredits, remainingRolloverCredits } =
    objBalance;
  if (remainingMonthlyAllowanceCredits <= 0) {
    return remainingRolloverCredits;
  }
  return remainingMonthlyAllowanceCredits;
};

const getDisplayPrice = value => {
  const isWholeValue = (value * 1) % 1 === 0;
  if (!isWholeValue) {
    return (Math.round(value * 100) / 100).toFixed(2);
  }
  return `${value * 1}`;
};

export {
  useWindowDimensions,
  useEventListener,
  getWindowDimensions,
  customProps,
  pwValidate,
  date,
  queriedDateAndLocation,
  isChromeUserAgent,
  isBookingAvailable,
  getCurrentBalance,
  createICS,
  getDisplayPrice,
  selectLocation,
  initializeSentry,
  setSentryUser,
  iterateObjectProperties,
  getStaticImageUrl,
  isDevEnvironment,
  isProdEnvironment,
  getIsMobile,
  getStaticUrl,
  createHash,
  strings,
  translations,
  getGoogleMapsLink,
  isMatchingStateAbbreviation,
  isMatchingStateName,
  NB_HOURS_IN_DAY,
  NB_MINUTES_IN_HOUR,
  NB_MS_IN_DAY,
  NB_MS_IN_HOUR,
  NB_MS_IN_MINUTE,
  NB_MS_IN_SECONDE,
  NB_SECONDS_IN_DAY,
  NB_SECONDS_IN_HOUR,
  NB_SECONDS_IN_MINUTE,
  SECOND_IN_MS,
  Http,
  stringifyUrl,
  REGEX_ALPHANUMERIC_WITH_SPACES,
};

export * from './strings';
