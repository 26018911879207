import { date as DateUtils, queriedDateAndLocation } from '../../Core/Utils';

export const fetchDataForGrouping = async (
  addRooms,
  addBookings,
  addQueriedDateAndLocation,
  queriedDatesAndLocations,
  selectedDate,
  setIsLoading,
  getAndAddBookingRestrictions,
  addFloors,
  queriedDatesAndGroupings,
  addQueriedDateAndGrouping,
  groupingId,
  location
) => {
  const { timeZoneId, sfId: locationSfId } = location;
  const dateInNewZone = DateUtils.updateDateForTZ(selectedDate, timeZoneId);

  const { startDate, endDate } = DateUtils.getDateStartAndEnd(
    dateInNewZone,
    timeZoneId
  );

  const currentQueriedSearch = queriedDateAndLocation(
    selectedDate,
    locationSfId
  );

  const listParallelPromises = [];
  if (queriedDatesAndLocations.includes(currentQueriedSearch)) {
    if (setIsLoading) {
      setIsLoading(true);
    }
    listParallelPromises.concat([
      getAndAddBookingRestrictions(
        DateUtils.getDateInMomentWithZone(dateInNewZone, timeZoneId)
          .startOf('month')
          .unix(),
        locationSfId
      ),
      addFloors(locationSfId),
    ]);
  }

  const hasVisitedGroupingAtThisDate =
    queriedDatesAndGroupings?.groupingId?.includes(startDate);

  if (!hasVisitedGroupingAtThisDate) {
    if (setIsLoading) {
      setIsLoading(true);
    }
    listParallelPromises.concat([
      addQueriedDateAndGrouping({
        groupingId,
        date: startDate,
      }),
      addBookings({
        startEpoch: startDate,
        endEpoch: endDate,
        locationSfId,
        groupingId,
      }),
      addRooms(locationSfId, groupingId),
    ]);
  }

  await Promise.all(listParallelPromises);
  if (setIsLoading) {
    setIsLoading(false);
  }
};
