import { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Lodash from 'lodash';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Moment from 'moment';
import MenuItem from '@material-ui/core/MenuItem';
import { withTranslation } from 'react-i18next';

// Access Control
import {
  bookingRoleAccess,
  getIsManager,
  locationRoleAccess,
} from '../../../../Core/Utils/userPermissions';

// Styles
import { colors, breakpoints } from '../../../../Core/Theme';

// Common Components
import FieldInput from '../../../../Components/Common/FieldInput';
import { AmenityIconGroup } from '../../../../Components/Common/AmenitiesIcon';
import TimeSelect from '../../../../Components/Common/TimeSelect';
import DayPicker from '../../../../Components/Common/DayPicker';
import FetchAutocomplete from '../../../../Components/Common/FetchAutocomplete';
import FormikInputs from '../../../../Components/FormikInputs';

// Constants
import { MeetingSummaryTypes } from '../../constants';

// Redux
import {
  getCurrentLocationSfId,
  getCurrentQueriedSearch,
  getQueriedDatesAndLocations,
} from '../../../RoomBooking/Redux/selectors';
import Redux from '../../../RoomBooking/Redux';
import { getFloorsById } from '../../../../Redux/Common/Rooms/selectors';
import { getProfileUserSfId } from '../../../../Redux/Common/UserManager/selectors';
import bookingsRedux from '../../../../Redux/Common/Bookings';
import { getBookingsForRoom } from '../../../../Redux/Common/Bookings/selectors';
import { getLocationsById } from '../../../../Redux/Common/Locations/selectors';
// Utils
import {
  getWindowDimensions,
  date as DateUtils,
  queriedDateAndLocation,
  customProps,
} from '../../../../Core/Utils';
import { parseRoomData, parseRoomsData } from './utils';
import { getSuggestions, timeOverlapsBooking } from '../../utils';

class MeetingForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      canBookFor: 'false',
    };
  }

  async componentDidMount() {
    const { profile } = this.props;
    this.setState({
      canBookFor: bookingRoleAccess.adminCreate({
        profile,
      }),
    });
  }

  getBookingStartTime = () => {
    const { profile, values } = this.props;
    const { selectedDate, initialTime } = values;
    if (!selectedDate) {
      return null;
    }

    const role = Lodash.get(profile, ['role'], '');
    const isCMorHQ = role === 'Coworking Member' || role === 'HQ Employee';
    const today = Moment();
    const isToday = today.isSame(selectedDate, 'day');
    const currentStartTime = selectedDate.set({
      hour: today.hour(),
      minutes: today.minute(),
      seconds: 0,
    });

    currentStartTime.subtract(30, 'minutes');
    if (isCMorHQ && selectedDate && isToday) {
      if (initialTime >= currentStartTime.unix()) {
        return currentStartTime.unix();
      }
      return initialTime;
    }
    return null;
  };

  getWindowDimensions = () => {
    const { height } = getWindowDimensions();
    return height;
  };

  handleChangeDate = async (dateMoment, setField) => {
    const {
      queriedDatesAndLocations,
      addQueriedDateAndLocation,
      values,
      addBookings,
      locationSfId,
      timeZoneId,
      onChangeDate,
    } = this.props;

    const momentStart = DateUtils.getDateInMomentWithZone(
      values.initialTime * 1,
      timeZoneId
    );
    const momentEnd = DateUtils.getDateInMomentWithZone(
      values.endTime * 1,
      timeZoneId
    );

    const startHour = momentStart.hour();
    const startMinutes = momentStart.minutes();
    const endHour = momentEnd.hour();
    const endMinutes = momentEnd.minutes();

    setField(
      'initialTime',
      Moment(dateMoment).set({ hour: startHour, minutes: startMinutes }).unix()
    );
    setField(
      'endTime',
      Moment(dateMoment).set({ hour: endHour, minutes: endMinutes }).unix()
    );
    setField('selectedDate', Lodash.cloneDeep(dateMoment));
    const dateToUnix = dateMoment.startOf('day').unix();
    const { startDate, endDate } = DateUtils.getDateStartAndEnd(dateToUnix);
    const currentQueriedSearch = queriedDateAndLocation(
      dateToUnix,
      locationSfId
    );

    onChangeDate(dateToUnix);

    if (addQueriedDateAndLocation && queriedDatesAndLocations) {
      if (!Lodash.includes(queriedDatesAndLocations, currentQueriedSearch)) {
        addBookings({
          startEpoch: startDate,
          endEpoch: endDate,
          locationSfId,
        }).then(() => {
          addQueriedDateAndLocation(currentQueriedSearch);
        });
      }
    }
  };

  handleChangeRoom = (field, setField, value) => {
    setField(field, value);
  };

  selectMember = setFieldValue => member => {
    setFieldValue('reservedId', {
      idUser: member.sfId,
      name: member.name,
      member,
    });
  };

  handleTimeSelect = (field, setField, time) => {
    const { values, timeZoneId } = this.props;
    const { endTime, initialTime } = values;

    const inputDate = values.selectedDate;
    const timeMoment = Moment(time, 'X').tz(timeZoneId);
    const timeDate = inputDate;
    timeDate.set({
      hour: timeMoment.get('hour'),
      minute: timeMoment.get('minute'),
      seconds: 0,
    });
    const epoch = timeDate.unix();
    const duration = endTime - initialTime * 1;
    setField(field, epoch.toString());
    if (field === 'initialTime') {
      setField('endTime', epoch + duration);
    }
  };

  handleViewDayPicker = focus => {
    const { isVisibleDayPicker } = this.props;
    isVisibleDayPicker(focus);
  };

  makeMenuItem = (item, index, highlightedIndex, itemProps) => {
    const { classes } = this.props;
    const nameAccount = Lodash.get(item, ['account', 'name'], '');
    const name = Lodash.get(item, ['name'], '');
    return (
      <MenuItem
        style={{
          backgroundColor:
            index === highlightedIndex
              ? colors.palette.secondary2.main
              : colors.white,
        }}
        {...itemProps}
      >
        <div className={classes.menuItem}>
          <p className={classes.name}>{name}</p>
          <p className={classes.company}>{nameAccount}</p>
        </div>
      </MenuItem>
    );
  };

  renderOwnerBookFor = () => {
    const { classes, values, userSfId, t } = this.props;
    const { canBookFor } = this.state;
    const bookingUserSfId = Lodash.get(
      values,
      ['user', 'sfId'],
      Lodash.get(values, ['user', 'member', 'sfId'], null)
    );
    const nameOwner = Lodash.get(values, ['user', 'name'], null);
    const hasOwnerBooking =
      canBookFor && userSfId !== bookingUserSfId && bookingUserSfId;
    if (hasOwnerBooking) {
      return (
        <>
          <FieldInput
            name="title"
            value={nameOwner}
            placeholder={t('meetingRoomBooking.placeholders.owner')}
            style={{ fontFamily: 'VerlagLight' }}
            inputClasses={{
              root: classes.meetingInputField,
              select: classes.meetingInputField,
            }}
            label={t('meetingRoomBooking.labels.owner')}
            InputLabelProps={{
              shrink: true,
              FormLabelClasses: {
                root: classes.formLabelRootLarge,
              },
            }}
            InputProps={{
              classes: {
                input: classes.inputProps,
              },
              readOnly: true,
              disableUnderline: true,
            }}
            datatestid="rr_create_rr_for_selected"
          />
        </>
      );
    }
    return null;
  };

  render() {
    const {
      classes,
      setFieldValue,
      values,
      rooms,
      type,
      profile,
      bookingsForRoom,
      datePickerVerticalSpacing,
      floorsLookup,
      timeZoneId,
      roomSelected,
      location,
      isSelectedDateUnavailable,
      t,
    } = this.props;

    const timeZoneCode = DateUtils.getTimeZoneCode(
      timeZoneId,
      values.initialTime
    );

    const { canBookFor } = this.state;
    const valueDateToUnix = values.selectedDate
      ? values.selectedDate.startOf('day').unix()
      : null;

    const roomParsed = parseRoomData(roomSelected);
    const roomsParsed = parseRoomsData(rooms);
    const amens = Lodash.get(roomParsed, ['amens'], []);
    const seats = Lodash.get(roomParsed, ['seats'], 0);
    const isUnneditable =
      type === MeetingSummaryTypes.UNEDITABLE ||
      type === MeetingSummaryTypes.UNEDITABLE_WITH_ERROR ||
      type === MeetingSummaryTypes.CANCELLATION;
    const isDateSelected = !Lodash.get(values, ['date'], null);
    const currentId = Lodash.get(values, ['id'], -1);
    let { startOverlap, endOverlap } = timeOverlapsBooking(
      bookingsForRoom,
      currentId,
      values.initialTime,
      values.endTime
    );
    const { isSuccess } = values;
    if (isSuccess) {
      startOverlap = false;
      endOverlap = false;
    }
    const isManager = getIsManager(profile);
    const canBookInPast = bookingRoleAccess.adminCreate({ profile });
    const canCreateAnyTime = bookingRoleAccess.createAnyTime({ profile });
    const floor = Lodash.get(roomSelected, ['floor'], null);
    const floorName = Lodash.get(floorsLookup, [floor, 'floorName'], '');

    return (
      <form className={classes.formMeeting}>
        <div>
          <div>
            {floorName && (
              <div className={classes.floorName}>
                {t('meetingRoomBooking.floor', { floorName })}
              </div>
            )}
            <div className={classes.formHeader}>
              <FormikInputs
                input="select"
                name="roomId"
                options={roomsParsed}
                disabled={isUnneditable}
                label={t('meetingRoomBooking.labels.room')}
                isLabelHidden
                datatestid="rr_room_name_dropdown"
                variant="boxedLarge"
              />

              <p className={classes.seatsLabel}>
                {t('meetingRoomBooking.seats', { seats })}
              </p>
            </div>
            <div className={classes.amenitiesContainer}>
              <AmenityIconGroup amens={amens} hasNames size="medium" />
            </div>
          </div>
          {(type === MeetingSummaryTypes.BOOKING ||
            type === MeetingSummaryTypes.CONFIRMATION) &&
          canBookFor ? (
            <div className={classes.createForInput}>
              <FetchAutocomplete
                locationSfId={profile.primaryLocationSfId}
                setFieldValue={setFieldValue}
                profile={profile}
                onChange={this.selectMember(setFieldValue)}
                getSuggestions={getSuggestions(
                  profile,
                  profile.primaryLocationSfId
                )}
                defaultValue={profile.name}
                makeMenuItem={this.makeMenuItem}
                datatestid="rr_create_rr_for"
                label={t('meetingRoomBooking.labels.create_for')}
              />
            </div>
          ) : (
            this.renderOwnerBookFor()
          )}
          <div className={classes.dayPicker}>
            <DayPicker
              type="day"
              disabled={isUnneditable}
              onDateChange={dateChange =>
                this.handleChangeDate(
                  dateChange,
                  setFieldValue,
                  valueDateToUnix
                )
              }
              hasError={isSelectedDateUnavailable}
              error={isSelectedDateUnavailable}
              isFocus={this.handleViewDayPicker}
              valueDate={valueDateToUnix}
              disablePastDate={!canBookInPast || !isManager}
              isAllowedFutureDays={canCreateAnyTime}
              verticalSpacing={datePickerVerticalSpacing}
              id="bookingFormDatePicker"
              selectedDate={valueDateToUnix}
              datatestid="rr_date_dropdown"
              timeZoneId={timeZoneId}
              disableWeekends={
                !locationRoleAccess.bookWeekendsAt({
                  profile,
                  obj: location,
                })
              }
            />
          </div>

          <div className={classes.timeInputsContainer}>
            <div className={classes.timeInputs}>
              <TimeSelect
                onChange={time =>
                  this.handleTimeSelect('initialTime', setFieldValue, time)
                }
                diffMenu
                name="initialTime"
                splitIn="half"
                startTime={this.getBookingStartTime()}
                disabled={isUnneditable || isDateSelected}
                inputDate={values.selectedDate}
                className={classes.selectHours}
                value={values.initialTime}
                isError={startOverlap}
                datatestid="rr_start_time_dropdown"
                timeZoneId={timeZoneId}
              />
              <div>
                <p className={classes.betweenDatesText}>
                  {t('meetingRoomBooking.to')}
                </p>
              </div>
              <TimeSelect
                onChange={time =>
                  this.handleTimeSelect('endTime', setFieldValue, time)
                }
                name="endTime"
                diffMenu
                withHours
                splitIn="half"
                startTime={values.initialTime}
                disabled={isUnneditable || isDateSelected}
                inputDate={values.selectedDate}
                className={classes.selectHours}
                value={values.endTime}
                isError={endOverlap}
                datatestid="rr_end_time_dropdown"
                timeZoneId={timeZoneId}
              />
            </div>
            <div className={classes.timeZone}>
              <p>{timeZoneCode}</p>
            </div>
          </div>
        </div>
        <FormikInputs
          input="fieldInput"
          name="title"
          label={t('meetingRoomBooking.labels.title')}
          className={classes.meetingInputFieldShort}
          placeholder={t('meetingRoomBooking.placeholders.title')}
          disabled={isUnneditable}
          autoFocus
          datatestid="rr_title"
        />

        <FormikInputs
          input="fieldInput"
          name="description"
          label={t('meetingRoomBooking.labels.description')}
          className={classes.meetingInputFieldShort}
          placeholder={t('meetingRoomBooking.placeholders.description')}
          datatestid="rr_description"
          disabled={isUnneditable}
        />
      </form>
    );
  }
}

const styles = theme => ({
  timeZone: {
    [theme.breakpoints.up(breakpoints.MOBILE)]: {
      width: '20%',
      marginLeft: '5px',
      textAlign: 'center',
    },
    [theme.breakpoints.down(breakpoints.MOBILE)]: {
      width: '100%',
      textAlign: 'left',
      marginLeft: '15px',
    },
    fontFamily: 'VerlagLight',
    fontSize: '14px',
    textAlign: 'center',
    fontWeight: 300,
    color: colors.darkest,
  },
  selectHours: {
    width: '40%',
  },
  selectInput: {
    [theme.breakpoints.up(breakpoints.MOBILE)]: {
      width: '60%',
    },
    width: '100%',
  },
  seatsLabel: {
    [theme.breakpoints.up(breakpoints.MOBILE)]: {
      fontSize: '24px',
    },
    fontFamily: 'VerlagLight',
    fontSize: '20px',
    lineHeight: 0.75,
    textAlign: 'right',
    whiteSpace: 'nowrap',
  },
  formHeader: {
    [theme.breakpoints.up(breakpoints.MOBILE)]: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'baseline',
    },
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'baseline',
    marginBottom: '5px',
    gap: '20px',
  },
  timeInputsContainer: {
    [theme.breakpoints.up(breakpoints.MOBILE)]: {
      justifyContent: 'space-between',
      alignItems: 'baseline',
      display: 'flex',
      flexDirection: 'row',
    },
    justifyContent: 'space-between',
    alignItems: 'baseline',
    display: 'flex',
    flexDirection: 'row',
    fontFamily: 'VerlagLight !important',
    fontSize: '20px',
  },
  timeInputs: {
    [theme.breakpoints.down(breakpoints.MOBILE)]: {
      minWidth: '198px',
      width: '62%',
      justifyContent: 'flex-start',
    },
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'baseline',
    justifyContent: 'space-between',
    width: '100%',
  },
  formMeetingWithoutScroll: {
    [theme.breakpoints.up(breakpoints.MOBILE)]: {
      paddingRight: '40px',
      paddingLeft: '40px',
    },
    [theme.breakpoints.down(breakpoints.MOBILE)]: {
      paddingBottom: '50px',
    },
    height: '100% + 70px',
    paddingLeft: '20px',
    paddingRight: '20px',
  },
  formMeeting: {
    [theme.breakpoints.up(breakpoints.MOBILE)]: {
      paddingRight: '40px',
      paddingLeft: '40px',
      paddingBottom: 20,
    },
    [theme.breakpoints.down(breakpoints.MOBILE)]: {
      paddingBottom: '50px',
    },
    // height: '100%',
    paddingLeft: '20px',
    paddingRight: '20px',
    background: colors.white,
    // overflow: 'scroll',
  },
  formLabelRoot: {
    color: `${colors.black}`,
    fontSize: '16px',
    fontFamily: 'VerlagBold',
  },
  formLabelRootLarge: {
    color: `${colors.black} !important`,
    fontSize: '18px',
    fontFamily: 'VerlagBold',
  },
  timePicker: {
    [theme.breakpoints.down(breakpoints.MOBILE)]: {
      fontSize: '18px',
    },
    color: `${colors.black} !important`,
    fontSize: '20px',
    fontFamily: 'VerlagLight',
    borderBottom: '1px solid #000000',
    backgroundColor: 'white',
    paddingBottom: 0,

    '&:hover': {
      backgroundColor: 'white',
    },
    '&:focus:not(.focus-visible)': {
      background: 'white',
    },
  },
  timePickerError: {
    [theme.breakpoints.down(breakpoints.MOBILE)]: {
      fontSize: '18px',
    },
    color: `red !important`,
    fontSize: '20px',
    fontFamily: 'VerlagBook',
    borderBottom: '1px solid red',
    backgroundColor: 'white',
    paddingBottom: 0,

    '&:hover': {
      backgroundColor: 'white',
    },
    '&:focus:not(.focus-visible)': {
      background: 'white',
    },
  },
  betweenDatesText: {
    [theme.breakpoints.up(breakpoints.MOBILE)]: {
      fontSize: '18px',
      margin: 0,
    },
    [theme.breakpoints.down(breakpoints.MOBILE)]: {
      marginRight: '20px',
      marginLeft: '20px',
    },
    fontFamily: 'VerlagBold',
    fontSize: '16px',
  },
  meetingInputField: {
    borderBottom: '1px solid #000000',
    fontFamily: 'VerlagLight',
    marginTop: 30,
    marginBottom: 0,
    fontSize: 18,
    color: colors.dark,
    [theme.breakpoints.up(breakpoints.MOBILE)]: {
      marginTop: 20,
    },
  },
  meetingInputFieldShort: {
    marginTop: 30,
    marginBottom: 0,
    [theme.breakpoints.up(breakpoints.MOBILE)]: {
      marginTop: 20,
    },
  },
  meetingInputFieldDisabled: {
    borderBottom: 'none',
  },
  inputProps: {
    fontFamily: 'VerlagLight',
    color: `${colors.black}`,
    fontSize: 18,
    [theme.breakpoints.down(breakpoints.MOBILE)]: {
      fontSize: 16,
    },
    marginTop: 5,
  },
  dayPicker: {
    [theme.breakpoints.down(breakpoints.MOBILE)]: {
      width: '100%',
      marginTop: 40,
    },
    marginTop: 30,
    width: '81.5%',
  },
  fieldLabel: {
    fontFamily: 'VerlagBold',
    fontSize: '12px',
    color: 'black',
    marginTop: 20,
    [theme.breakpoints.down(breakpoints.MOBILE)]: {
      marginTop: 30,
    },
  },

  amenitiesContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  formError: {
    color: colors.alertMessage,
    fontSize: '12px',
    fontFamily: 'VerlagLight',
    marginTop: '2px',
  },
  menuItem: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    fontFamily: 'VerlagLight',
    fontSize: '18px',
    display: 'flex',
    padding: 'none',
  },
  name: {
    color: colors.black,
    width: 142,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  company: {
    color: colors.dark,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    position: 'absolute',
    marginLeft: 142,
    width: 142,
  },
  createForInput: {
    width: '80%',
    marginTop: 30,
    marginBottom: 5,
    [theme.breakpoints.down(breakpoints.MOBILE)]: {
      marginTop: 40,
    },
  },
  floorName: {
    fontSize: 16,
    fontFamily: 'VerlagBook',
    marginTop: 20,
    marginBottom: -15,
  },
});

MeetingForm.defaultProps = {};

MeetingForm.propTypes = {
  isVisibleDayPicker: PropTypes.func.isRequired,
  userSfId: PropTypes.string.isRequired,
  classes: PropTypes.shape({}).isRequired,
  setFieldValue: PropTypes.func.isRequired,
  values: PropTypes.shape({}).isRequired,
  rooms: PropTypes.arrayOf(customProps.room).isRequired,
  type: PropTypes.string.isRequired,
  profile: PropTypes.object.isRequired,
  selectedDate: PropTypes.number.isRequired,
  addBookings: PropTypes.func.isRequired,
  locationSfId: PropTypes.string.isRequired,
  queriedDatesAndLocations: PropTypes.arrayOf(PropTypes.string).isRequired,
  addQueriedDateAndLocation: PropTypes.func.isRequired,
  bookingsForRoom: PropTypes.arrayOf(customProps.room).isRequired,
  datePickerVerticalSpacing: PropTypes.number.isRequired,
  creditsBalance: PropTypes.number.isRequired,
  locationsLookup: PropTypes.shape({}).isRequired,
  floorsLookup: PropTypes.shape({}).isRequired,
  timeZoneId: PropTypes.string.isRequired,
  onChangeDate: PropTypes.func.isRequired,
  roomSelected: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  isSelectedDateUnavailable: PropTypes.bool.isRequired,
  t: PropTypes.func.isRequired,
};
const mapStateToProps = (state, ownProps) => {
  return {
    queriedDatesAndLocations: getQueriedDatesAndLocations(state, ownProps),
    profile: state.userManager.profile,
    userSfId: getProfileUserSfId(state, ownProps),
    locationSfId: getCurrentLocationSfId(state, ownProps),
    currentQueriedSearch: getCurrentQueriedSearch(state, ownProps),
    bookingsForRoom: getBookingsForRoom(state, ownProps),
    locationsLookup: getLocationsById(state, ownProps),
    floorsLookup: getFloorsById(state),
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      setDate: Redux.actions.updateDate,
      addBookings: bookingsRedux.actions.addBookings,
      addQueriedDateAndLocation: Redux.actions.addQueriedDateAndLocation,
    },
    dispatch
  );
};
export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(MeetingForm))
);
