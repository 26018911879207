import { useState } from 'react';
import PropTypes from 'prop-types';
import Lodash from 'lodash';
import { Field } from 'formik';

import { InputTypes } from './InputTypes';
import InputComponentFactory from './InputComponentFactory';
import InputPropsCleaner from './InputPropsCleaner';

const FormikInput = props => {
  const { input, field, form, onBlur, onChange, onFocus, forceShowError } =
    props;

  const inputType = input || InputTypes.Input;
  const InputComponent = InputComponentFactory.getComponent(inputType);

  const [isFocus, setIsFocus] = useState(false);

  const listFieldName = Lodash.get(props, ['field', 'name'], '').split('.');

  const inputId = Lodash.get(props, 'id', listFieldName.join());
  const errorMessage = Lodash.get(
    props,
    ['form', 'errors', ...listFieldName],
    null
  );

  const skipTouched = Boolean(props.skipTouched);
  const hasBeenSubmitted = form?.subsubmitCount > 0;

  const hasBeenTouched = Lodash.get(
    props,
    ['form', 'touched', ...listFieldName],
    false
  );

  const getShowError = () => {
    if (forceShowError) {
      return true;
    }
    if (!errorMessage) {
      return false;
    }
    if (skipTouched) {
      return true;
    }
    return hasBeenSubmitted || hasBeenTouched;
  };

  const showError = getShowError();

  const handleOnBlur = event => {
    setIsFocus(false);
    onBlur(event);

    if (!event) {
      return form.setFieldTouched(field.name, true, true);
    }

    return field.onBlur(field.name)(event);
  };

  const handleOnChange = (value, e) => {
    onChange(value, e);

    if (Lodash.isString(value)) {
      return field.onChange(field.name)(value);
    }
    return form.setFieldValue(field.name, value, true);
  };

  const handleOnFocus = () => {
    setIsFocus(true);
    onFocus();
  };

  const inputProps = InputPropsCleaner.clean(props, inputType);

  if (inputType === InputTypes.Input) {
    return (
      <InputComponent
        {...inputProps}
        onChange={handleOnChange}
        onBlur={handleOnBlur}
        onFocus={handleOnFocus}
        hasError={showError}
        errorMessage={errorMessage}
        id={inputId}
      />
    );
  }

  return (
    <InputComponent
      {...props}
      onChange={handleOnChange}
      onBlur={handleOnBlur}
      onFocus={handleOnFocus}
      hasError={showError}
      errorMessage={errorMessage}
      id={inputId}
      isFocus={isFocus}
    />
  );
};

FormikInput.defaultProps = {
  field: {},
  form: {},
  onChange: () => {},
  onBlur: () => {},
  onFocus: () => {},
  style: null,
  input: PropTypes.Input,
  skipTouched: false,
  forceShowError: false,
};

FormikInput.propTypes = {
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  field: PropTypes.objectOf(Object),
  form: PropTypes.objectOf(Object),
  style: PropTypes.object,
  input: PropTypes.string,
  skipTouched: PropTypes.bool,
  forceShowError: PropTypes.bool,
};

export default objProps => {
  return <Field component={FormikInput} {...objProps} />;
};
