import Moment from 'moment';

import { fetchUserBalance } from '../../../Core/Api';
import { date as DateUtils } from '../../../Core/Utils';

export default (
  date,
  accountSfId,
  setIsLoading,
  setBalance,
  accountTimezone = Moment().tz() || Moment.tz.guess(),
  dateTimeZone
) => {
  setIsLoading(true);
  const adjustedDate = DateUtils.updateDateForTZ(
    DateUtils.getDateInMomentWithZone(date, dateTimeZone)
      .startOf('month')
      .unix(),
    accountTimezone,
    dateTimeZone
  );
  fetchUserBalance(accountSfId, adjustedDate).then(objResponse => {
    const { data } = objResponse;
    if (data) {
      setBalance({ balance: data, month: adjustedDate });
      setIsLoading(false);
    }
  });
};
