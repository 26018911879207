// name must correspond to User Profile
import { PHONE_MASK } from '../../Core/constants';

export const demographicFields = [
  {
    name: 'name',
    label: 'contactProfile.labels.name',
    placeholder: 'contactProfile.placeholders.name',
  },
  {
    name: 'companyName',
    label: 'contactProfile.labels.company',
    disabled: true,
  },
  {
    name: 'jobTitle',
    label: 'contactProfile.labels.title',
    placeholder: 'contactProfile.placeholders.title',
  },
  { name: 'email', label: 'contactProfile.labels.email', disabled: true },
  {
    name: 'phoneNumber',
    label: 'contactProfile.labels.phone',
    type: 'tel',
    placeholder: 'contactProfile.placeholders.phone',
    masked: true,
    mask: PHONE_MASK,
  },
];

export const socialMediaFields = [
  {
    name: 'linkedinProfileUrl',
    label: 'contactProfile.labels.linkedin',
    placeHolder: 'contactProfile.placeholders.linkedin',
  },
  {
    name: 'twitterHandle',
    label: 'contactProfile.labels.twitter',
    placeHolder: 'contactProfile.placeholders.twitter',
  },
  {
    name: 'instagramHandle',
    label: 'contactProfile.labels.instagram',
    placeHolder: 'contactProfile.placeholders.instagram',
  },
  {
    name: 'facebookProfileUrl',
    label: 'contactProfile.labels.facebook',
    placeHolder: 'contactProfile.placeholders.facebook',
  },
];
