import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { colors, breakpoints } from '../../../../Core/Theme';
import { date as DateUtils } from '../../../../Core/Utils';
import { MapLink, TextButton } from '../../../Common';
import DayBox from '../DayBox';

const handleKeyDown = fxn => e => {
  if (e.key === 'Enter') {
    fxn();
  }
};

const Card = props => {
  const {
    classes,
    time,
    space,
    location,
    grouping,
    startEpoch,
    onClick,
    reservation,
    profile,
    canEdit,
    type,
    canCancel,
  } = props;
  const { t } = useTranslation();

  const objMomentStartEpoch = DateUtils.getDateInMomentWithZone(
    startEpoch,
    location?.timeZoneId
  );

  const clickWrapped = () => onClick({ reservation, type });

  return (
    <div
      className={classes.card}
      onClick={clickWrapped}
      role="button"
      tabIndex={0}
      onKeyDown={handleKeyDown(clickWrapped)}
      datatestid={`${type}_reservation_card`}
    >
      <DayBox
        momentStart={objMomentStartEpoch}
        timeZoneId={location?.timeZoneId}
        datatestid="scheduleReservationDate"
      />
      <div className={classes.content}>
        <div className={classes.topWrapper}>
          <div datatestid="space_info" className={classes.space}>
            {space}
          </div>
          {canEdit && (
            <TextButton
              text={t('general.edit')}
              onClick={() => {}}
              className={classes.editButton}
              datatestid="edit_reservation_button"
            />
          )}
          {canCancel && !canEdit && (
            <TextButton
              state="accent"
              text={t('general.cancel')}
              onClick={() => {}}
              className={classes.editButton}
              datatestid="cancel_reservation_button"
            />
          )}
        </div>
        <div datatestid="date_time">{time}</div>
        {location ? (
          <MapLink
            location={location}
            grouping={grouping}
            profile={profile}
            className={classes.locationButton}
            datatestid="location_button"
          />
        ) : null}
      </div>
    </div>
  );
};

const styles = theme => ({
  card: {
    background: colors.white,
    borderTop: `solid 3px ${colors.palette.secondary.main}`,
    borderRadius: 4,
    marginBottom: 10,
    display: 'flex',
    '&:focus:not(.focus-visible)': {
      outline: 'none',
      boxShadow: '0 0 6px 3px rgba(0, 0, 0, 0.8)',
    },
    '&:hover': {
      cursor: 'pointer',
      boxShadow: '0 0 6px 3px rgba(0, 0, 0, 0.8)',
    },
    height: 79,
  },
  content: {
    paddingLeft: 10,
    fontSize: 14,
    fontFamily: 'VerlagBook',
    textAlign: 'left',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    paddingTop: 10,
    paddingBottom: 10,
    overflow: 'hidden',
    [theme.breakpoints.down(breakpoints.MOBILE)]: {
      width: 'calc(100% - 75px)',
    },
  },
  space: {
    fontFamily: 'VerlagBold',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    paddingRight: 2,
  },
  locationButton: {
    padding: 0,
    fontSize: 14,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    textAlign: 'left',
  },
  editButton: {
    padding: 0,
    paddingRight: 10,
    fontSize: 14,
  },
  topWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
});

Card.defaultProps = {
  onClick: () => {},
  time: null,
  space: null,
  location: null,
  grouping: null,
  reservation: null,
  canEdit: false,
  canCancel: false,
  startEpoch: null,
  type: null,
};
Card.propTypes = {
  classes: PropTypes.object.isRequired,
  profile: PropTypes.object.isRequired,
  onClick: PropTypes.func,
  reservation: PropTypes.object,
  time: PropTypes.string,
  space: PropTypes.string,
  location: PropTypes.object,
  grouping: PropTypes.object,
  startEpoch: PropTypes.number,
  canEdit: PropTypes.bool,
  canCancel: PropTypes.bool,
  type: PropTypes.string,
};

export default withStyles(styles)(Card);
