import { withStyles } from '@material-ui/core/styles';
import InputAdornment from '@material-ui/core/InputAdornment';
import clsx from 'clsx';
import get from 'lodash/get';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { getStaticImageUrl } from '../../../Core/Utils';
import FieldInput from '../FieldInput';
import { breakpoints, colors } from '../../../Core/Theme';

const SearchInput = props => {
  const { classes, customClasses } = props;
  const { t } = useTranslation();
  const inputClassName = get(customClasses, ['input'], null);
  const inputTextClassName = get(customClasses, ['inputText'], null);
  const searchIconClassName = get(customClasses, ['searchIcon'], null);
  return (
    <FieldInput
      {...props}
      className={clsx(classes.input, inputClassName)}
      classNameInput={clsx(classes.inputText, inputTextClassName)}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <img
              src={getStaticImageUrl('2020-08/searchIcon.svg')}
              className={clsx(classes.searchIcon, searchIconClassName)}
              alt={t('altTexts.magnifying_glass')}
            />
          </InputAdornment>
        ),
      }}
    />
  );
};

const styles = theme => ({
  searchIcon: {
    color: colors.dark,
    border: `solid 0.3px ${colors.white}`,
  },
  inputText: {
    fontFamily: 'VerlagLight',
    fontSize: '16px',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    '&:placeholder': {
      color: colors.darkGray,
    },
    [theme.breakpoints.up(breakpoints.MOBILE)]: {
      fontSize: '14px',
    },
  },
  input: {
    height: 40,
    backgroundColor: colors.white,
    display: 'flex',
    justifyContent: 'center',
    paddingLeft: 10,
    paddingRight: 7,
    width: 'auto',
    borderColor: colors.middle,
  },
});

SearchInput.defaultProps = {
  customClasses: {},
};
SearchInput.propTypes = {
  customClasses: PropTypes.object,
};

export default withStyles(styles)(SearchInput);
