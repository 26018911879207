import { withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import Popover from '@material-ui/core/Popover';
import Fab from '@material-ui/core/Fab';
import CloseIcon from '@material-ui/icons/Close';
import { useTranslation } from 'react-i18next';

import TextButton from '../TextButton';
import { colors } from '../../../Core/Theme';
import ConfirmationPopover from './ConfirmationPopover';

const CustomPopover = props => {
  const {
    classes,
    content,
    anchorEl,
    onClose,
    headerMessage,
    isActionable,
    actionText,
    onAction,
    actionError,
    actionDisabled,
    hasCloseButton,
    bodyStyle,
    variant,
  } = props;

  const { t } = useTranslation();
  if (variant === 'confirmation') {
    return <ConfirmationPopover {...props} />;
  }
  return (
    <Popover
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      onClose={onClose}
      className={classes.root}
    >
      <div className={classes.popover}>
        {headerMessage ? (
          <div className={classes.header}>{headerMessage}</div>
        ) : null}
        {hasCloseButton && (
          <Fab
            size="small"
            onClick={onClose}
            aria-label={t('general.close')}
            className={classes.closeButton}
            focusVisibleClassName={classes.focusVisible}
          >
            <CloseIcon classes={{ root: classes.closeIcon }} />
          </Fab>
        )}
        <div className={clsx(classes.body, bodyStyle)}>
          <>
            {content}
            {isActionable ? (
              <TextButton
                text={actionText}
                onClick={onAction}
                className={clsx(
                  classes.saveButton,
                  actionError && classes.saveButtonError
                )}
                isDisabled={actionDisabled}
              />
            ) : null}
          </>
        </div>
      </div>
    </Popover>
  );
};

const styles = {
  root: {
    width: 500,
  },
  popover: {
    position: 'relative',
    boxShadow: '0 0 3px 1px rgba(0, 0, 0, 0.25)',
    border: `solid 1px ${colors.dark}`,
    borderRadius: 4,
  },
  body: {
    paddingLeft: 10,
    paddingRight: 10,
  },
  closeButton: {
    color: colors.black,
    backgroundColor: 'transparent',
    position: 'absolute',
    right: '5px',
    top: '5px',
    height: 17,
    width: 17,
    minHeight: 17,
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: 'transparent',
    },
    '&:active': {
      boxShadow: 'none',
    },
    zIndex: 1000,
  },
  closeIcon: {
    fontSize: 17,
  },
  header: {
    fontFamily: 'VerlagBook',
    background: colors.lightest,
    fontSize: 14,
    textAlign: 'center',
    width: '100%',
    borderRadius: 4,
    paddingTop: 7,
    paddingBottom: 2,
  },
  focusVisible: {
    boxShadow: '0 0 5px 0 rgba(0, 0, 0, .4) !important',
  },
  saveButton: {
    width: '100%',
    marginTop: 10,
    marginBottom: 5,
    paddingTop: 0,
  },
  saveButtonError: {
    color: colors.darkGray,
    cursor: 'default',
  },
};

CustomPopover.defaultProps = {
  anchorEl: null,
  onClose: () => null,
  headerMessage: null,
  bodyStyle: null,
  hasCloseButton: true,
  actionText: '',
  isActionable: false,
  onAction: () => null,
  actionError: false,
  actionDisabled: false,
  variant: 'default',
};

CustomPopover.propTypes = {
  content: PropTypes.object.isRequired,
  anchorEl: PropTypes.object,
  classes: PropTypes.object.isRequired,
  onClose: PropTypes.func,
  headerMessage: PropTypes.string,
  bodyStyle: PropTypes.string,
  hasCloseButton: PropTypes.bool,
  actionText: PropTypes.string,
  isActionable: PropTypes.bool,
  onAction: PropTypes.func,
  actionError: PropTypes.bool,
  actionDisabled: PropTypes.bool,
  variant: PropTypes.string,
};

export default withStyles(styles)(CustomPopover);
