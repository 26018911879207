import styled from 'styled-components';

export default styled.div`
  padding: 16px 8px;
  font-weight: normal;
  display: flex;
  flex-direction: column;
  background-color: white;
  color: black;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  font-family: VerlagBook;
  font-size: 18px;
  justify-content: space-between;
`;
