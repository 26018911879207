import { stringifyUrl, Http } from '../Utils';
import {
  AnyReservationCountDto,
  AnyReservationTypes,
  SortDirections,
  AnyReservationPageDto,
  AnyReservationWithUserPageDto,
} from './dto';

export interface AnyReservationPageRequest {
  startEpoch?: number;
  endEpoch?: number;
  limit?: number;
  offset?: number;
  userSfId?: string;
  accountSfId?: string;
  reservationTypes?: AnyReservationTypes[];
  sortDirection?: SortDirections;
  locationSfId?: string;
  includeOngoing?: boolean;
  includeDeleted?: boolean;
}

export interface AnyReservationForMonthPageRequest {
  month: string;
  limit?: number;
  offset?: number;
  userSfId?: string;
  accountSfId?: string;
  reservationTypes?: AnyReservationTypes[];
  sortDirection?: SortDirections;
  locationSfId?: string;
  includeOngoing?: boolean;
  includeDeleted?: boolean;
}

export const fetchAnyReservations = (request: AnyReservationPageRequest) => {
  const url = stringifyUrl('any-reservations/paged', {
    end_epoch: request.endEpoch,
    start_epoch: request.startEpoch,
    limit: request.limit,
    offset: request.offset,
    user_sfid: request.userSfId,
    account_sfid: request.accountSfId,
    reservation_types: request.reservationTypes?.join(', '),
    location_sfid: request.locationSfId,
    include_ongoing: request.includeOngoing,
    include_deleted: request.includeDeleted,
  });

  return Http.get<AnyReservationPageDto>(url);
};

export const fetchAnyReservationsForMonth = (
  request: AnyReservationForMonthPageRequest
) => {
  const url = stringifyUrl('any-reservations/pagedForMonth', {
    month: request.month,
    limit: request.limit,
    offset: request.offset,
    user_sfid: request.userSfId,
    account_sfid: request.accountSfId,
    reservation_types: request.reservationTypes?.join(', '),
    location_sfid: request.locationSfId,
    include_ongoing: request.includeOngoing,
    include_deleted: request.includeDeleted,
  });

  return Http.get<AnyReservationPageDto>(url);
};

export interface AnyReservationFilters {
  startEpoch?: number;
  endEpoch?: number;
  limit?: number;
  offset?: number;
  locationSfId?: string;
  groupingId?: string;
  nameContains?: string;
  externalTypes?: AnyReservationTypes[];
  reservationTypes?: AnyReservationTypes[];
}

const createQueryParams = (filters: AnyReservationFilters) => {
  return {
    grouping_id: filters.groupingId,
    location_sfid: filters.locationSfId,
    start_epoch: filters.startEpoch,
    end_epoch: filters.endEpoch,
    name_contains: filters.nameContains,
    external_types: filters.externalTypes?.join(', '),
    reservation_types: filters.reservationTypes?.join(', '),
    limit: filters.limit,
    offset: filters.offset,
  };
};

export const fetchAnyReservationsWithUsers = (
  filters: AnyReservationFilters
) => {
  const url = stringifyUrl(
    'any-reservations/with-users',
    createQueryParams(filters)
  );
  return Http.get<AnyReservationWithUserPageDto>(url);
};

export const fetchAnyReservationCounts = (filters: AnyReservationFilters) => {
  const url = stringifyUrl(
    'any-reservations/counts',
    createQueryParams(filters)
  );
  return Http.get<AnyReservationCountDto>(url);
};
