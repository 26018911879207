/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-filename-extension */
import Lodash from 'lodash';
import MemoizeOne from 'memoize-one';
import { Constants } from '../../Components/Common/SmartTable';
import { CollapseList } from '../../Components/Common';

const DECISION_MAKER_ROLE = 'Decision Maker';

export const ACCOUNT_STATES = {
  ACTIVE: 'ACTIVE',
  INACTIVE: 'INACTIVE',
};

export const getColumns = MemoizeOne((selectedMonth, classes, t) => {
  return [
    {
      key: 'name',
      label: t('deskUsage.table.columns.account_name'),
      type: Constants.CUSTOM_COLUMNS.LINK,
      className: classes.accountColumn,
    },
    {
      key: 'isActive',
      label: t('deskUsage.table.columns.active.label'),
      render: ({ data }) => {
        return data
          ? t('deskUsage.table.columns.active.active')
          : t('deskUsage.table.columns.active.inactive');
      },
      className: classes.activeColumn,

      filters: [
        {
          value: ACCOUNT_STATES.ACTIVE,
          label: t('deskUsage.table.columns.active.active'),
          description: t('deskUsage.table.columns.active.active_description'),
        },
        {
          value: ACCOUNT_STATES.INACTIVE,
          label: t('deskUsage.table.columns.active.inactive'),
          description: t('deskUsage.table.columns.active.inactive_description'),
        },
      ],
    },
    {
      key: ({ data }) => {
        const accountContactRelations = Lodash.get(
          data,
          ['accountContactRelations'],
          []
        );

        return Lodash.filter(accountContactRelations, item => {
          const roles = Lodash.get(item, ['roles'], []);
          const isDecicionMaker = Lodash.includes(roles, DECISION_MAKER_ROLE);

          return !isDecicionMaker;
        });
      },
      label: t('deskUsage.table.columns.team_members'),
      render: ({ data }) => {
        return (
          <CollapseList
            data={data}
            render={({ item }) => (
              <p>{Lodash.get(item, ['contact', 'name'], null)}</p>
            )}
          />
        );
      },
      className: classes.teamMemberColumn,
    },
    {
      key: ({ data }) => {
        const accountContactRelations = Lodash.get(
          data,
          ['accountContactRelations'],
          []
        );

        return Lodash.filter(accountContactRelations, item => {
          const roles = Lodash.get(item, ['roles'], []);
          const isDecisionMaker = Lodash.includes(roles, DECISION_MAKER_ROLE);
          return isDecisionMaker;
        });
      },
      render: ({ data }) => {
        return (
          <CollapseList
            data={data}
            render={({ item }) => (
              <p>{Lodash.get(item, ['contact', 'name'], null)}</p>
            )}
          />
        );
      },
      label: t('deskUsage.table.columns.decision_maker'),
      className: classes.decisionMakersColumn,
    },
    {
      key: 'options',
      type: Constants.CUSTOM_COLUMNS.OPTIONS,
      label: '',
      options: [
        {
          label: t('deskUsage.table.options.manage'),
          type: Constants.ACTIONS_COLUMNS.MANAGE,
        },
      ],
      className: classes.optionsColumn,
    },
  ];
});
