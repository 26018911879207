export const SET_SELECTED_LOCATION = 'SET_SELECTED_LOCATION_GROUP_MANAGEMENT';

export const ADD_GARS = 'ADD_GARS';

export const ADD_QUERIED_LOCATION = 'ADD_QUERIED_LOCATION_GROUP';

export const SET_LOADING_GROUP_MANAGEMENT = 'SET_LOADING_GROUP_MANAGEMENT';

export const ADD_GAR = 'ADD_GAR';
export const DELETE_GAR = 'DELETE_GAR';
