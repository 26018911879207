/* eslint-disable func-names */
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { Formik } from 'formik';

import { getIsManager } from '../../../../Core/Utils/userPermissions';
import { getInitialDate } from '../../utils';
import CreateFormContent from '../CreateFormContent/CreateFormContent';

const createSchema = Yup.object().shape({
  grouping: Yup.object().nullable().required('general.required_field'),
  date: Yup.date().required('general.required_field'),
  user: Yup.object().nullable().required('general.required_field'),
});

const CreateForm = props => {
  const {
    profile,
    history,
    locationsById,
    handleCreate,
    usersDefaultGrouping,
  } = props;

  const isManager = getIsManager(profile);

  const renderContent = formikBag => {
    return (
      <CreateFormContent
        profile={profile}
        history={history}
        locationsById={locationsById}
        handleCreate={handleCreate}
        usersDefaultGrouping={usersDefaultGrouping}
        {...formikBag}
      />
    );
  };

  return (
    <Formik
      initialValues={{
        grouping: null,
        user: !isManager ? profile : null,
        date: getInitialDate(),
      }}
      validationSchema={createSchema}
      validateOnBlur
      validateOnMount
      onSubmit={handleCreate}
      render={renderContent}
    />
  );
};

CreateForm.defaultProps = {};

CreateForm.propTypes = {
  history: PropTypes.object.isRequired,
  profile: PropTypes.object.isRequired,
  locationsById: PropTypes.object.isRequired,
  handleCreate: PropTypes.func.isRequired,
  usersDefaultGrouping: PropTypes.object.isRequired,
};

export default CreateForm;
