import React from 'react';
import PropTypes from 'prop-types';

// Components
import { MapLayerWrapper as Layer } from '../../Common';

// Constants
import { LAYERS } from '../Constants';

// Theme & Utils
import { colors } from '../../../Core/Theme';
import { createHash } from '../../../Core/Utils';

// Layer properties
const lineProperties = {
  id: `${LAYERS.ASSIGNED_OFFICE}-lines`,
  type: 'line',
  source: 'features',
  paint: {
    'line-width': 2,
    'line-opacity': 0.6,
  },
};
const fillProperties = {
  id: LAYERS.ASSIGNED_OFFICE,
  type: 'fill',
  source: 'features',
  paint: {
    'fill-color': colors.white,
  },
};

// Legend
const legend = {
  key: LAYERS.ASSIGNED_OFFICE,
  style: {
    backgroundColor: colors.white,
    borderColor: colors.green,
    borderWidth: 2,
    borderStyle: 'solid',
  },
  labelKey: 'deskFloorPlan.legend.assignedOffice',
};

const AssignedOffice = props => {
  const { offices, officesWithDesksAvailable } = props;
  return (
    <Layer
      key={`${LAYERS.ASSIGNED_OFFICE}-${createHash(officesWithDesksAvailable)}`}
      properties={[
        fillProperties,
        {
          ...lineProperties,
          paint: {
            ...lineProperties.paint,
            'line-color': [
              'case',
              [
                '==',
                ['in', ['get', 'id'], ['literal', officesWithDesksAvailable]],
                true,
              ],
              colors.green,
              colors.darkGray,
            ],
          },
        },
      ]}
      filter={['in', ['get', 'id'], ['literal', offices]]}
      {...props}
    />
  );
};

AssignedOffice.defaultProps = {
  offices: [],
  officesWithDesksAvailable: [],
};
AssignedOffice.propTypes = {
  offices: PropTypes.arrayOf(PropTypes.string),
  officesWithDesksAvailable: PropTypes.arrayOf(PropTypes.string),
};

export { legend };
export default React.memo(AssignedOffice);
