import PropTypes from 'prop-types';

import DayPicker from './DayPicker';
import MonthPick from './MonthPick';
import MonthPickerBox from './MonthPickerBox';
import DayPickerBox from '../DayPickerBox';

import { WEEK, DAY, MONTH } from './constants';

const DateWrapper = props => {
  const { type, variant, ...rest } = props;
  if ((type === DAY || type === WEEK) && variant === 'box') {
    return <DayPickerBox {...rest} />;
  }
  if (type === DAY || type === WEEK) {
    return <DayPicker {...rest} type={type} variant={variant} />;
  }
  if (type === MONTH && variant === 'box') {
    return <MonthPickerBox {...rest} />;
  }
  return <MonthPick {...rest} />;
};

DateWrapper.defaultProps = {
  ...DayPicker.defaultProps,
  type: DAY,
  variant: 'box',
};
DateWrapper.propTypes = {
  type: PropTypes.oneOf([DAY, MONTH, WEEK]),
  variant: PropTypes.string,
  ...DayPicker.propTypes,
};
export default DateWrapper;
