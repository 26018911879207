import Components from '../../Components';
import { getIsManager } from '../../Core/Utils/userPermissions';

const { containerTypes } = Components.Routes;
const RouteConfig = {
  path: '/home/message_members',
  exact: true,
  name: 'navigation.menu.message',
  private: true,
  type: containerTypes.PAGE,
  requiresMemberPortalAccess: true,
  isAuthorized: ({ profile }) => {
    return getIsManager(profile);
  },
};

export default RouteConfig;
