import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { Transition } from 'react-transition-group';
import clsx from 'clsx';

import { colors } from '../../../Core/Theme';

const duration = 300;

const defaultStyle = {
  transition:
    'opacity 263ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, transform 175ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
  opacity: 0,
  transformOrigin: '0px 0px',
};

const transitionStyles = {
  entering: { opacity: 1, transform: 'scale(1, 1)' },
  entered: { opacity: 1, transform: 'scale(1, 1)' },
  exiting: {
    opacity: 0,
    transform: 'scale(.75, .5625)',
    transition:
      'opacity 263ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, transform 175ms cubic-bezier(0.4, 0, 0.2, 1) 88ms',
  },
  exited: { opacity: 0, transform: 'scale(.75, .5625)', zIndex: -1 },
};

const Menu = props => {
  const { children, open, classes, appear, className } = props;
  return (
    <Transition in={open} timeout={duration} appear={appear}>
      {state => (
        <div
          style={{
            ...defaultStyle,
            ...transitionStyles[state],
          }}
          className={classes.menu}
        >
          <div className={clsx(classes.menuContent, className)}>{children}</div>
        </div>
      )}
    </Transition>
  );
};

const styles = {
  menu: {
    zIndex: 1000,
    position: 'absolute',
    width: '100%',
  },
  menuContent: {
    width: 'calc(100% + 100px)',
    overflowY: 'scroll',
    borderRadius: 4,
    border: `solid 1px ${colors.middle}`,
    boxShadow: '0 0 4px 0 rgb(0 0 0 / 10%)',
    marginTop: 2,
    zIndex: 1000,
    background: 'white',
  },
};
Menu.defaultProps = {
  appear: false,
  className: null,
};

Menu.propTypes = {
  open: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
  classes: PropTypes.object.isRequired,
  appear: PropTypes.bool,
  className: PropTypes.string,
};

export default withStyles(styles)(Menu);
