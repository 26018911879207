import axios from 'axios';
import { GatewayServices, getGatewayUrl } from '../Utils/gateway';

export const resetPassword = (email: string) => {
  return axios.post(
    `/users/reset-password`,
    { email },
    { baseURL: getGatewayUrl(GatewayServices.authentication) }
  );
};
