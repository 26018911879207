import { useCallback } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import Grid from '@material-ui/core/Grid';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import { getIsMobile } from '../../../Core/Utils/windowDimensions';
import { breakpoints, colors } from '../../../Core/Theme';
import BaseButton from '../BaseButton';
import Image from '../Image';
import { getStaticImageUrl } from '../../../Core/Utils';
import { S3IconStrings } from '../../../resources';

const Popup = ({
  isVisible,
  onClose,
  classes,
  title,
  className,
  onClickActionPopup,
  buttonText,
  imageUrl,
  imageAlt,
}) => {
  const { t } = useTranslation();
  const handleOnClosePopup = () => {
    return onClose();
  };

  const renderPopupContent = useCallback(() => {
    return (
      <SnackbarContent
        classes={{
          root: clsx(classes.content, className),
        }}
        message={
          <Grid container alignItems="center">
            <Grid item xs={3} md={12}>
              {imageUrl && (
                <Image
                  src={imageUrl}
                  fallbackSrc={getStaticImageUrl(
                    S3IconStrings.underConstruction3
                  )}
                  alt={imageAlt}
                  className={classes.icon}
                />
              )}
            </Grid>
            <Grid item xs={9} md={12}>
              <Grid container>
                <Grid item xs={12}>
                  <span className={classes.popUpMessage}>{title}</span>
                </Grid>
                {buttonText && (
                  <Grid item xs={12}>
                    <BaseButton
                      link
                      name={buttonText}
                      className={classes.textActionPopup}
                      onClick={onClickActionPopup}
                    />
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
        }
        action={[
          <IconButton
            key="close"
            aria-label={t('general.close')}
            color="inherit"
            className={classes.close}
            onClick={handleOnClosePopup}
          >
            <CloseIcon />
          </IconButton>,
        ]}
      />
    );
  });

  return (
    <Snackbar
      anchorOrigin={{
        vertical: getIsMobile() ? 'top' : 'bottom',
        horizontal: 'left',
      }}
      open={isVisible}
      className={classes.snackbarContainer}
    >
      {renderPopupContent()}
    </Snackbar>
  );
};

const styles = theme => ({
  textActionPopup: {
    fontFamily: 'VerlagBook',
    color: colors.white,
    fontSize: 16,
  },
  popUpMessage: {
    fontFamily: 'VerlagBook',
    [theme.breakpoints.up(breakpoints.MOBILE)]: {
      fontSize: 20,
    },
    fontSize: 16,
  },
  icon: {
    width: 54,
    height: 54,
    [theme.breakpoints.up(breakpoints.MOBILE)]: {
      width: 77,
      height: 77,
    },
  },
  content: {
    backgroundColor: colors.palette.primary.main,
    flexDirection: 'column-reverse',
    [theme.breakpoints.up(breakpoints.MOBILE)]: {
      width: '20%',
    },
    width: '100%',
  },
  close: {
    backgroundColor: 'transparent',
  },
});

Popup.defaultProps = {
  className: undefined,
  buttonText: undefined,
  imageUrl: undefined,
  imageAlt: undefined,
};

Popup.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  classes: PropTypes.shape({}).isRequired,
  title: PropTypes.string.isRequired,
  className: PropTypes.string,
  onClickActionPopup: PropTypes.func.isRequired,
  buttonText: PropTypes.string,
  imageUrl: PropTypes.string,
  imageAlt: PropTypes.string,
};
export default withStyles(styles)(Popup);
