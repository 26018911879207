/* eslint-disable no-underscore-dangle */
import { Component, Fragment, useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import Fab from '@material-ui/core/Fab';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Formik } from 'formik';
import * as Yup from 'yup';
import Lodash from 'lodash';
import Moment from 'moment';
import { withTranslation } from 'react-i18next';

import Image from '../../Components/Common/Image';

import { colors, breakpoints } from '../../Core/Theme';

// Components
import MeetingSummary from './Components/MeetingSummaryReservation';
import MeetingForm from './Components/MeetingFormReservation';
import MeetingCalendarBooking from './Components/MeetingCalendarBooking';
import Drawer from '../../Components/Common/Drawer';

// Core
import { ReservationSources, createBooking } from '../../Core/Api';
import {
  customProps,
  date,
  getWindowDimensions,
  getStaticImageUrl,
} from '../../Core/Utils';
import {
  bookingRoleAccess,
  getMustRespectBusinessHours,
  getWithinBookingHours,
} from '../../Core/Utils/userPermissions';

// Utils Form and Date
import { getDiffHours, formattedTime, formattedDate } from './utils';
import { trackBookingCreation } from '../../Core/Tracking';

// Selectors

// Redux
import { getCurrentRoomsSelectedBookingGrouping } from '../../Redux/Common/Rooms/selectors';
import bookingRedux from '../../Redux/Common/Bookings';
import { getLocations } from '../../Redux/Common/Locations/selectors';

// Constants
import { MeetingSummaryTypes } from './constants';

const DAY_PICKER_TOP = -344;
const DAY_PICKER_BOTTOM = -14;
const DAY_PICKER_INFLECTION_PT = 520;

const meetingSchema = Yup.object().shape({
  title: Yup.string().max(254, 'Title cannot exceed 255 characters').nullable(),
  date: Yup.date().required('Required'),
  description: Yup.string().nullable(),
  initialTime: Yup.string().required('Required'),
  endTime: Yup.string().required('Required'),
  reservedId: Yup.string(),
  roomId: Yup.string().required('Required'),
});

const INITIAL_VALUES = {
  roomId: '1',
  selectedDate: null,
  date: date.getTime(date.getTodayNotWeekend()),
  initialTime: '',
  endTime: '',
  title: '',
  description: '',
  amens: [],
  reservedId: '',
  status: 'Edit',
  isSuccess: false,
};

class MeetingBookingRoom extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      initialData: INITIAL_VALUES,
      meetingSummary: null,
      meetingForm: null,
      meetingSummaryHeight: 0,
      datePickerVerticalSpacing: DAY_PICKER_BOTTOM,
      bookingError: false,
    };
    this.setHeight = this.setHeight.bind(this);
    this.setDatePickerOpenDirection =
      this.setDatePickerOpenDirection.bind(this);
  }

  componentDidMount() {
    this.updateHeight = this.updateHeight.bind(this);
    window.addEventListener('resize', this.updateHeight);
  }

  componentDidUpdate(prevProps) {
    const { objBooking, timeZoneId } = this.props;

    if (
      (!Lodash.isEqual(prevProps.objBooking, objBooking) ||
        timeZoneId !== prevProps.timeZoneId) &&
      timeZoneId &&
      !Lodash.isEmpty(objBooking)
    ) {
      this.handleBookingValues(objBooking);
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateHeight);
  }

  setHeight(height) {
    this.setState({ meetingSummaryHeight: height });
  }

  setError = bookingError => {
    this.setState({ bookingError });
  };

  setDatePickerOpenDirection() {
    const { meetingForm } = this.state;
    if (meetingForm) {
      const meetingFormHeight = meetingForm.getBoundingClientRect().height;
      if (meetingFormHeight < DAY_PICKER_INFLECTION_PT) {
        this.setState({ datePickerVerticalSpacing: DAY_PICKER_TOP });
      } else {
        this.setState({ datePickerVerticalSpacing: DAY_PICKER_BOTTOM });
      }
    }
  }

  getBookingStatus = objBooking => {
    const { profile, location } = this.props;
    const { startEpoch, idBooking: id, user = {}, space = {} } = objBooking;
    const startEpochWithoutNow = startEpoch * 1 - Moment().unix();
    const within30Minutes = startEpochWithoutNow <= 30 * 60;
    const userOwnBooking = this.doesUserOwnBooking(user);
    const objBookingWithSpace = {
      ...objBooking,
      space: { ...space, location, locationSfId: location?.sfId },
    };
    const canUpdate = bookingRoleAccess.update({
      obj: objBookingWithSpace,
      profile,
    });
    const canCancel = bookingRoleAccess.delete({
      obj: objBookingWithSpace,
      profile,
    });

    if (!id) {
      return MeetingSummaryTypes.BOOKING;
    }
    if (canUpdate) {
      return MeetingSummaryTypes.EDIT;
    }
    if (canCancel) {
      return MeetingSummaryTypes.CANCELLATION;
    }
    if (within30Minutes && userOwnBooking && !canUpdate) {
      return MeetingSummaryTypes.UNEDITABLE_WITH_ERROR;
    }
    return MeetingSummaryTypes.UNEDITABLE;
  };

  getCreateMeetingDto = objData => {
    const { profile } = this.props;
    const spaceSfId = Lodash.get(objData, ['roomId'], '');
    const startTime = Lodash.get(objData, ['initialTime'], '');
    const endTime = Lodash.get(objData, ['endTime'], '');
    const title = Lodash.get(objData, ['title'], '');
    const description = Lodash.get(objData, ['description'], '');
    const startEpoch = startTime * 1;
    const endEpoch = endTime * 1;
    let userData = Lodash.get(objData, ['reservedId'], '');
    const name = Lodash.get(profile, ['name'], '');
    const accountSfId = Lodash.get(profile, ['accountSfId'], '');
    //  There is no userdata when the meeting is not booked for someone else, so we populate with users own data
    if (userData === '') {
      userData = {
        name,
        sfId: profile.sfId,
        idUser: profile.sfId,
        accountSfId,
      };
    }
    //  user is who it is booked for, not who booked it
    return {
      spaceSfId,
      startEpoch,
      endEpoch,
      title,
      description,
      userSfId: userData.idUser,
      user: userData,
    };
  };

  getInitialDataForm = () => {
    this.setState({
      initialData: INITIAL_VALUES,
      isLoading: false,
    });
  };

  createBookingCreationDto = (data, userSfId) => {
    return {
      title: data.title,
      description: data.description,
      startEpoch: +data.initialTime,
      endEpoch: +data.endTime,
      spaceSfId: data.roomId,
      userSfId: data?.reservedId?.idUser ?? userSfId,
      source: ReservationSources.MemberPortal,
    };
  };

  createBookingToTrack = (data, room) => {
    return {
      startEpoch: +data.initialTime,
      space: {
        sfId: room.sfId,
        location: {
          externalName: room.location.externalName,
        },
        name: room.name,
        amenities: room.amenities?.map(amenity => ({ name: amenity.name })),
        seats: room.seats,
      },
    };
  };

  handleBookingValues = objBooking => {
    const { selectedDate, timeZoneId } = this.props;
    const {
      dateReservation,
      user,
      title,
      description,
      time: startTime,
      startEpoch,
      endEpoch,
      idBooking: id,
      space,
      source,
    } = objBooking ?? {};
    const roomId = objBooking?.spaceSfId || objBooking.spaceId;

    const defaultEnd = date.getTime(startTime)?.add(30, 'minutes').unix();
    const initialTime = startEpoch || startTime;
    const endTime = endEpoch || defaultEnd;
    const startOfDay = Moment.unix(initialTime)
      .tz(timeZoneId)
      .startOf('day')
      .unix();
    const d = date.getDateInMomentWithZone(
      dateReservation || startOfDay || selectedDate,
      timeZoneId
    );
    const objInitial = {
      user,
      id,
      ...INITIAL_VALUES,
      roomId,
      date: d,
      initialTime,
      endTime,
      title,
      description,
      space,
      selectedDate: d,
      status: this.getBookingStatus(objBooking),
      source,
    };
    this.setState({
      initialData: objInitial,
      bookingError: false,
    });
  };

  handleSubmit = (booking, objActions) => {
    const { onCloseModal, addBooking, profile, onSuccess, location } =
      this.props;
    const { setSubmitting, setFieldValue } = objActions;

    const room = this.props.rooms.find(x => x.sfId === booking.roomId);

    setSubmitting(true);
    this.setState({ bookingError: false });

    const space = {
      ...room,
      locationSfId: location.sfId,
      location,
    };
    const objMeetingData = { ...this.getCreateMeetingDto(booking), space };

    const meetingDataWithSpace = {
      ...objMeetingData,
      space,
    };

    const canCreateBooking = bookingRoleAccess.create({
      obj: meetingDataWithSpace,
      profile,
    });

    if (canCreateBooking) {
      const dto = this.createBookingCreationDto(booking, profile.sfId);

      createBooking(dto).then(
        res => {
          if (res.status === 201) {
            const objMeetingCreated = {
              ...objMeetingData,
              space,
              id: Lodash.get(res, ['data', 'idBooking'], 0),
              ...res.data,
            };
            addBooking(objMeetingCreated);

            this.trackBookingCreation(booking, space);
            setSubmitting(false);
            setFieldValue('isSuccess', true);
            onSuccess();
            setTimeout(() => {
              onCloseModal();
            }, 1000);
          } else {
            setSubmitting(false);
          }
        },
        () => {
          this.setState({ bookingError: true });
          setSubmitting(false);
          setFieldValue('isSuccess', false);
        }
      );
    }
  };

  summaryRef = element => {
    if (element) {
      this.setState({
        meetingSummary: element,
        meetingSummaryHeight: element.clientHeight,
      });
    }
  };

  formRef = element => {
    if (element) {
      this.setState(
        {
          meetingForm: element,
        },
        this.setDatePickerOpenDirection
      );
    }
  };

  doesUserOwnBooking = objBookingUser => {
    const { profile } = this.props;

    const userSfId = Lodash.get(profile, ['sfId'], '');
    const ownerSfId = Lodash.get(objBookingUser, ['sfId'], '');
    return userSfId === ownerSfId && userSfId !== '';
  };

  trackBookingCreation(booking, room) {
    const bookingToTrack = this.createBookingToTrack(booking, room);
    trackBookingCreation(bookingToTrack);
  }

  updateHeight() {
    this.forceUpdate(); // this is neccesary as since the user may adjust the screen height which requires a rerender for styling purposes
    this.setDatePickerOpenDirection();
  }

  renderMeetingBooking = objFormik => {
    const {
      onCloseModal,
      rooms,
      onSuccess,
      timeZoneId,
      onChangeDate,
      monthlyBalance,
      profile,
      location,
      isSelectedDateUnavailable,
      t,
    } = this.props;
    const { classes, ...rest } = this.props;
    const {
      meetingSummaryHeight,
      meetingForm,
      meetingSummary,
      isVisibleDayPicker,
      datePickerVerticalSpacing,
      bookingError,
    } = this.state;

    const meetingFormHeight = meetingForm
      ? meetingForm.getBoundingClientRect().height
      : 0;
    const { width } = getWindowDimensions();
    const { values } = objFormik;
    const { roomId, initialTime, endTime, title } = values;

    const valueDateToUnix = values.selectedDate
      ? values.selectedDate.startOf('day').unix()
      : null;
    const inputDate = values.date;

    const objSummaryDetails = {
      date: formattedDate(values.date),
      time: {
        initialTime: formattedTime(values.initialTime),
        endTime: formattedTime(values.endTime),
      },
      hours: getDiffHours(values.initialTime, values.endTime),
    };
    const overflowStyle = isVisibleDayPicker ? 'visible' : null;
    const roomSelected = rooms.find(r => r.sfId === values.roomId);
    const roomImage =
      roomSelected?.currentImage?.url ??
      getStaticImageUrl('2020-05/MeetingRoomPlaceholder.png');

    const isOutsideBusinessHoursError = useMemo(() => {
      return (
        getMustRespectBusinessHours(profile, location) &&
        !getWithinBookingHours(values.initialTime, values.endTime, timeZoneId)
      );
    }, [location, values.initialTime, values.endTime, timeZoneId]);

    return (
      <Fragment>
        <div className={classes.reactOpenModal}>
          <div
            className={classes.scrollBody}
            style={
              width >= breakpoints.MOBILE
                ? {
                    height: `calc(100% - ${meetingSummaryHeight}px)`,
                    overflow: overflowStyle,
                  }
                : {}
            }
          >
            <div className={classes.meetingHeader}>
              <Fab
                size="small"
                onClick={onCloseModal}
                aria-label={t('general.close')}
                className={classes.closeButton}
                datatestid="rr_close_button"
              >
                <CloseIcon />
              </Fab>
              <Image
                src={roomImage}
                fallbackSrc={getStaticImageUrl(
                  '2020-05/MeetingRoomPlaceholder.png'
                )}
                alt={t('altTexts.room')}
                className={classes.imageMeetingRoom}
                width="100%"
                height="100%"
              />
            </div>
            <div className={classes.meetingBody}>
              <div className={classes.meetingInfo} ref={this.formRef}>
                <MeetingForm
                  {...objFormik}
                  {...rest}
                  rooms={rooms}
                  type={values.status}
                  isVisibleDayPicker={isVisible =>
                    this.setState({ isVisibleDayPicker: isVisible })
                  }
                  datePickerVerticalSpacing={datePickerVerticalSpacing}
                  timeZoneId={timeZoneId}
                  onChangeDate={onChangeDate}
                  roomSelected={roomSelected}
                  location={location}
                  isSelectedDateUnavailable={isSelectedDateUnavailable}
                />
              </div>
              <div
                className={classes.meetingSchedule}
                // this is to keep the schedule as long as the form when compressing vertically
                style={
                  width >= breakpoints.MOBILE
                    ? {
                        height: `calc(100% - ${meetingSummaryHeight - 24})`, //  this 24 accounts for the top padding of the schedule
                        minHeight: meetingFormHeight - 20, // this 20 accounts for the top padding on the form
                      }
                    : {}
                }
              >
                {inputDate ? (
                  <MeetingCalendarBooking
                    location={location}
                    {...objFormik}
                    {...rest}
                    spaceSfId={roomId}
                    room={roomSelected}
                    startEpoch={initialTime}
                    endEpoch={endTime * 1}
                    title={title}
                    type={values.status}
                    isSuccess={values.isSuccess}
                    valueDate={valueDateToUnix}
                    timeZoneId={timeZoneId}
                    isOutsideBusinessHoursError={isOutsideBusinessHoursError}
                    isSelectedDateUnavailable={isSelectedDateUnavailable}
                  />
                ) : null}
              </div>
            </div>
          </div>
          <div className={classes.meetingBottom} ref={this.summaryRef}>
            {bookingError ? (
              <div className={classes.meetingError}>
                {t('meetingRoomBooking.booking_error')}
              </div>
            ) : null}
            <MeetingSummary
              {...objFormik}
              {...rest}
              type={values.status}
              data={objSummaryDetails}
              meetingSummaryRef={meetingSummary}
              meetingSummaryHeight={meetingSummaryHeight}
              setHeight={this.setHeight}
              setError={this.setError}
              userRemainingCredits={
                monthlyBalance?.remainingMonthlyAllowanceCredits
              }
              onSuccess={onSuccess}
              timeZoneId={timeZoneId}
              creditsPerHour={roomSelected?.bookingRule?.creditsPerHour}
              costPerHour={roomSelected?.bookingRule?.costPerHour}
              userSfId={profile?.sfId}
              roomSelected={roomSelected}
              isOutsideBusinessHoursError={isOutsideBusinessHoursError}
              isSelectedDateUnavailable={isSelectedDateUnavailable}
            />
          </div>
        </div>
      </Fragment>
    );
  };

  render() {
    const { openModal, onCloseModal, isLoadingData } = this.props;
    const { isLoading, initialData } = this.state;
    const initialValues = initialData || INITIAL_VALUES;
    if (isLoading || isLoadingData) {
      return <CircularProgress />;
    }
    return (
      <Drawer
        anchor="right"
        isOpenDrawer={openModal}
        onCloseDrawer={onCloseModal}
      >
        <Formik
          validationSchema={meetingSchema}
          initialValues={initialValues}
          onSubmit={(values, actions) => {
            this.handleSubmit(values, actions);
          }}
          enableReinitialize
          render={this.renderMeetingBooking}
        />
      </Drawer>
    );
  }
}

const styles = theme => ({
  imageMeetingRoom: {
    height: '100%',
    objectFit: 'cover',
  },
  closeButton: {
    color: colors.black,
    backgroundColor: colors.white,
    position: 'absolute',
    right: '15px',
    top: '15px',
    boxShadow: '0 0 4px 0 rgba(0, 0, 0, 0.25)',
    '&:hover': {
      backgroundColor: colors.white,
    },
    '&:focus': {
      boxShadow: '0 0 9px 8px rgba(0, 0, 0, .5)',
    },
    zIndex: 1000,
  },
  meetingBody: {
    backgroundColor: colors.white,
    [theme.breakpoints.up(breakpoints.MOBILE)]: {
      display: 'flex',
      width: '100%',
      flexDirection: 'row',
      height: 'calc(100% - 296px)',
    },
    [theme.breakpoints.down(breakpoints.MOBILE)]: {
      flexDirection: 'column',
    },
  },
  meetingSchedule: {
    [theme.breakpoints.up(breakpoints.MOBILE)]: {
      display: 'flex',
    },
    [theme.breakpoints.down(breakpoints.MOBILE)]: {
      display: 'none',
    },
    width: '100%',
    backgroundColor: colors.palette.secondary2.main,
    flexDirection: 'row',
    position: 'relative',
    padding: '23px 23px 0',
    minWidth: 195,
    zIndex: 1,
  },
  meetingInfo: {
    width: '100%',
    backgroundColor: colors.white,
    [theme.breakpoints.up(breakpoints.MOBILE)]: {
      minHeight: '520px',
      maxWidth: '50%',
      maxHeight: 475,
    },
  },
  meetingBottom: {
    [theme.breakpoints.up(breakpoints.MOBILE)]: {
      boxShadow: `0 -2px 2px 0 rgba(0, 0, 0, 0.25)`,
      zIndex: 3,
      position: 'relative',
      border: 'none',
      width: '100%',
      minWidth: 650,
    },
    [theme.breakpoints.down(breakpoints.MOBILE)]: {
      marginTop: 'auto',
    },
    borderTop: `2px solid ${colors.light}`,
    maxWidth: '100%',
    position: 'static',
  },
  meetingError: {
    border: `solid 1.5px ${colors.alertBackground}`,
    padding: 10,
    paddingLeft: 20,
    margin: 0,
    fontFamily: 'VerlagBold',
    color: `${colors.alertMessage} !important`,
    fontSize: 13,
    background: colors.white,
    [theme.breakpoints.up(breakpoints.MOBILE)]: {
      fontSize: 15,
      paddingLeft: 40,
    },
  },
  meetingHeader: {
    [theme.breakpoints.up(breakpoints.MOBILE)]: {
      width: '100%',
      maxWidth: '100%',
      height: 296,
    },
    width: '100%',
    maxWidth: '100%',
    height: 200,
  },
  reactOpenModal: {
    overflowX: 'hidden',
    overflowY: 'hidden',

    [theme.breakpoints.down(breakpoints.MOBILE)]: {
      minHeight: '100%',
      overflowY: 'scroll',
    },
    [theme.breakpoints.up(breakpoints.MOBILE)]: {
      backgroundColor: colors.lightest,
      height: '100%',
    },
  },
  scrollBody: {
    [theme.breakpoints.up(breakpoints.MOBILE)]: {
      overflow: 'initial',
      backgroundColor: colors.palette.secondary2.main,
      height: '100%',
      overflowY: 'scroll !important',
      '-ms-overflow-style': 'none',
      '&::-webkit-scrollbar': {
        width: 0 /* Remove scrollbar space */,
        background: 'transparent' /* Optional: just make scrollbar invisible */,
      },
    },
  },
});

MeetingBookingRoom.defaultProps = {
  objBooking: {},
  respectGrouping: true,
  roomsNoGroupingFilter: [],
  onSuccess: () => null,
  isLoadingData: false,
  amenFilters: [],
  floorFilters: [],
  seatFilters: [],
  onChangeDate: () => {},
};

MeetingBookingRoom.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  rooms: PropTypes.arrayOf(customProps.room).isRequired,
  objBooking: PropTypes.shape({}),
  openModal: PropTypes.bool.isRequired,
  onCloseModal: PropTypes.func.isRequired,
  selectedDate: PropTypes.number.isRequired,
  addBooking: PropTypes.func.isRequired,
  profile: PropTypes.object.isRequired,
  roomsNoGroupingFilter: PropTypes.arrayOf(customProps.room),
  respectGrouping: PropTypes.bool,
  onSuccess: PropTypes.func,
  isLoadingData: PropTypes.bool,
  timeZoneId: PropTypes.string.isRequired,
  seatFilters: PropTypes.arrayOf(PropTypes.string),
  amenFilters: PropTypes.arrayOf(PropTypes.string),
  floorFilters: PropTypes.arrayOf(PropTypes.number),
  monthlyBalance: PropTypes.object.isRequired,
  onChangeDate: PropTypes.func,
  location: PropTypes.object.isRequired,
  locations: PropTypes.arrayOf(PropTypes.object).isRequired,
  isSelectedDateUnavailable: PropTypes.bool.isRequired,
  t: PropTypes.func.isRequired,
};

const mapStateToProps = (state, ownProps) => {
  return {
    rooms: getCurrentRoomsSelectedBookingGrouping(state, ownProps),
    locations: getLocations(state),
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      addBooking: bookingRedux.actions.addBooking,
    },
    dispatch
  );
};
export default withTranslation()(
  withStyles(styles)(
    connect(mapStateToProps, mapDispatchToProps)(MeetingBookingRoom)
  )
);
