import { useState, useRef } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Lodash from 'lodash';
import MemoizeOne from 'memoize-one';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

// Components
import TextButton from '../TextButton';

import { colors } from '../../../Core/Theme';

// Resources
import { ImagePlaceholder } from '../../../resources';
import { MAX_IMAGE_SIZE } from '../../../Core/constants';

const createUrlForImage = MemoizeOne(varValue => {
  if (Lodash.isNil(varValue)) {
    return null;
  }

  return Lodash.isString(varValue) ? varValue : URL.createObjectURL(varValue);
});

const ImagePickerInput = ({ classes, onChange, value, maxImageSize }) => {
  const uploadInputRef = useRef(null);
  const [image, setImage] = useState(value);
  const { t } = useTranslation();

  const varInternalValue = value || image;

  const openImagePicker = () => {
    const inputCurrent = Lodash.get(uploadInputRef, ['current'], null);

    if (inputCurrent) {
      return inputCurrent.click();
    }

    return null;
  };

  const uploadImage = event => {
    const imageFile = Lodash.get(event, ['currentTarget', 'files', 0], null);
    onChange(imageFile);
    setImage(imageFile);
  };

  const imageSize = Lodash.get(varInternalValue, ['size'], null);
  const imageSrc = createUrlForImage(varInternalValue);

  return (
    <>
      <div className={classes.imageHeader}>
        <img
          src={imageSrc || ImagePlaceholder}
          alt={t('altTexts.preview_upload')}
          className={classes.image}
          width="100%"
          height="100%"
        />
      </div>
      {imageSize > maxImageSize ? (
        <div className={classes.imageError}>
          {t('general.image_size_error')}
        </div>
      ) : null}
      <div className={classes.editPhotoDiv}>
        <input
          className={classes.editPhotoInput}
          type="file"
          name="eventImage"
          onChange={uploadImage}
          ref={uploadInputRef}
          accept="image/*"
        />
        <TextButton
          text={
            imageSrc ? t('imageUpload.edit_photo') : t('imageUpload.add_photo')
          }
          onClick={openImagePicker}
        />
      </div>
    </>
  );
};

const styles = () => ({
  imageError: {
    border: `solid 1.5px ${colors.alertBackground}`,
    backgroundColor: colors.white,
    borderRadius: 2,
    fontFamily: 'VerlagBold',
    fontSize: 13,
    color: colors.alertMessage,
    paddingRight: 10,
    paddingLeft: 10,
    paddingTop: 5,
    paddingBottom: 5,
    textAlign: 'left',
    marginTop: 5,
  },
  editPhotoDiv: {
    textAlign: 'center',
    marginTop: 10,
  },
  editPhotoInput: {
    display: 'none',
  },
  imageHeader: {
    height: 215,
    width: '100%',
    maxWidth: '100%',
  },
  image: {
    height: '100%',
    objectFit: 'cover',
  },
});

ImagePickerInput.defaultProps = {
  value: null,
  maxImageSize: MAX_IMAGE_SIZE,
};

ImagePickerInput.propTypes = {
  value: PropTypes.shape({}),
  maxImageSize: PropTypes.number,
};

export default withStyles(styles)(ImagePickerInput);
