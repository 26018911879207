//  Style
import { colors, breakpoints } from '../../../Core/Theme';

const styles = theme => ({
  form: {
    backgroundColor: colors.blackOpacityDark,
    [theme.breakpoints.down(breakpoints.MOBILE)]: {
      maxHeight: '100vh',
      maxWidth: '100vh',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',
      height: '100%',
      backgroundColor: colors.blackOpacityLight,
    },
    maxHeight: '460px',
    width: '100%',
    maxWidth: '346px',
    alignItems: 'center',
    justifyContent: 'center',
  },
  formShort: {
    [theme.breakpoints.down(breakpoints.MOBILE)]: {
      maxHeight: '100vh',
      maxWidth: '100vh',
      alignItems: 'center',
      justifyContent: 'center',
      height: '100%',
      paddingTop: '50vh',
    },
    [theme.breakpoints.up(breakpoints.MOBILE)]: {
      width: '346px',
      backgroundColor: colors.blackOpacityDark,
    },
    backgroundColor: colors.blackOpacityLight,
    width: '100%',
    maxWidth: '346px',
    alignItems: 'center',
    justifyContent: 'center',
  },
});

export default styles;
