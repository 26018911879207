import Lodash from 'lodash';

export const updateOffice = (offices, newOffice) => {
  const { sfId, isOasisReservable, overrideName } = newOffice;
  return offices.map(o => {
    const officeId = Lodash.get(o, 'sfId', '');
    if (officeId === sfId) {
      return {
        ...o,
        isOasisReservable,
        overrideName,
      };
    }
    return o;
  });
};

export const sortByOfficeName = (officeA, officeB) => {
  const officeAName = officeA.overrideName || officeA.officeName;
  const officeBName = officeB.overrideName || officeB.officeName;
  const officeASuffix = parseInt(officeAName.split(' ')[1], 10);
  const officeBSuffix = parseInt(officeBName.split(' ')[1], 10);

  if (officeASuffix < officeBSuffix) {
    return -1;
  }
  if (officeASuffix > officeBSuffix) {
    return 1;
  }
  return 0;
};
