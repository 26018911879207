/* eslint-disable no-underscore-dangle */

import axios from 'axios';
import queryString from 'query-string';
import Lodash from 'lodash';

export const fetchReservations = objParams => {
  const ownerSfId = Lodash.get(objParams, ['ownerSfId'], null);
  const groupingId = Lodash.get(objParams, ['groupingId'], null);
  const accountSfId = Lodash.get(objParams, ['accountSfId'], null);
  const startEpoch = Lodash.get(objParams, ['startEpoch'], null);
  const endEpoch = Lodash.get(objParams, ['endEpoch'], null);
  const limit = Lodash.get(objParams, ['limit'], null);
  const offset = Lodash.get(objParams, ['offset'], null);
  const includeDeleted = Lodash.get(objParams, ['includeDeleted'], false);
  const sortOrder = Lodash.get(objParams, ['sortOrder'], null);
  const locationSfId = Lodash.get(objParams, ['locationSfId'], null);
  const floorId = Lodash.get(objParams, ['floorId'], null);

  const objSchema = {
    owner_sfid: ownerSfId,
    account_sfid: accountSfId,
    grouping_id: groupingId,
    end_epoch: endEpoch,
    start_epoch: startEpoch,
    include_deleted: includeDeleted,
    sort_order: sortOrder,
    location_sfid: locationSfId,
    offset,
    limit,
    floor_id: floorId,
  };
  const strEndpoint = 'reservations/paged';
  const strQueryUrl = queryString.stringifyUrl(
    {
      url: strEndpoint,
      query: objSchema,
    },
    {
      skipNull: true,
    }
  );

  return axios.get(strQueryUrl);
};

export const deleteReservationsApi = reservationIds => {
  return axios.delete(`/reservations`, { data: { reservationIds } });
};

export const createReservationsApi = data => {
  return axios.post(`/reservations`, { ...data });
};

export const updateReservationsApi = (reservationId, data) => {
  return axios.patch(`/reservations/${reservationId}`, {
    ...data,
  });
};

export const fetchReservationById = reservationId => {
  return axios.get(`/reservations/${reservationId}`);
};
