/* eslint-disable import/no-named-as-default */
import Route from './route';
import { LazyloadWrapper } from '../../Components/Common';

const MessageMembers = LazyloadWrapper(import('./MessageMembers'));

export default {
  RenderProp: MessageMembers,
  mainRoute: Route,
};
