import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { colors } from '../../../Core/Theme';

export const DefaultBackgroundColor = colors.white;
export const DefaultTailWidth = 15;
export const DefaultTailHeight = 10;
export const DefaultZIndex = 100;

const SpeechBubble = props => {
  const { classes, children, backgroundColor, tailWidth, tailHeight, zIndex } =
    props;

  return (
    <div
      className={classes.container}
      style={{
        zIndex,
      }}
    >
      <div
        className={classes.tail}
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: tailWidth,
          height: tailHeight,
          overflow: `hidden`,
          borderLeft: `${tailWidth}px solid ${backgroundColor}`,
          borderTop: `${tailHeight}px solid transparent`,
          zIndex,
        }}
      />
      <div
        className={classes.contents}
        style={{
          marginTop: tailHeight,
          backgroundColor,
          zIndex: zIndex + 1,
        }}
      >
        {children}
      </div>
    </div>
  );
};

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
  },
  tail: {},
  contents: {
    borderRadius: 5,
    paddingTop: 12,
    paddingBottom: 12,
    paddingLeft: 10,
    paddingRight: 10,
  },
};

SpeechBubble.defaultProps = {
  children: null,

  backgroundColor: DefaultBackgroundColor,

  tailWidth: DefaultTailWidth,
  tailHeight: DefaultTailHeight,

  zIndex: DefaultZIndex,
};

SpeechBubble.propTypes = {
  classes: PropTypes.shape({}).isRequired,

  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),

  backgroundColor: PropTypes.string,

  tailWidth: PropTypes.number,
  tailHeight: PropTypes.number,

  zIndex: PropTypes.number,
};

export default withStyles(styles)(SpeechBubble);
