import { withStyles } from '@material-ui/core/styles';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import Slide from '@material-ui/core/Slide';
import { StyleRulesCallback, Theme } from '@material-ui/core';

import { BaseButton as Button, SubmitButton } from '../index';
import { breakpoints } from '../../../Core/Theme';

interface Props {
  isOpen: boolean;
  classes: ClassNameMap;
  message: string;
  onLeftActionPress: () => void;
  onRightActionPress: () => void;
  leftLabel: string;
  rightLabel: string;
}

const ConfirmationSlide = (props: Props) => {
  const {
    classes,
    message,
    isOpen,
    onLeftActionPress,
    onRightActionPress,
    leftLabel,
    rightLabel,
  } = props;

  return (
    <Slide direction="up" in={isOpen} mountOnEnter unmountOnExit>
      <div className={classes.confirmationBox}>
        <div className={classes.confirmationMessage}>{message}</div>
        <div className={classes.buttonArea}>
          <Button
            className={classes.deleteButton}
            name={leftLabel}
            onClick={onLeftActionPress}
          />
          <SubmitButton
            className={classes.confirmButton}
            defaultText={rightLabel}
            onClick={onRightActionPress}
          />
        </div>
      </div>
    </Slide>
  );
};

const styles: StyleRulesCallback<string> = (theme: Theme) => ({
  confirmationBox: {
    position: 'absolute',
    background: 'white',
    bottom: 0,
    left: 0,
    right: 0,
    paddingTop: 10,
    paddingBottom: 10,
    paddingRight: 20,
    paddingLeft: 20,
    boxShadow: `0 -2px 2px 0 rgba(0, 0, 0, 0.25)`,

    [theme.breakpoints.up(breakpoints.MOBILE)]: {
      paddingRight: 30,
      paddingLeft: 30,
    },
  },
  confirmationMessage: {
    fontFamily: 'VerlagLight',
    paddingBottom: 10,
  },
  buttonArea: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },

  confirmButton: {
    [theme.breakpoints.up(breakpoints.MOBILE)]: {
      width: '150px',
      height: '40px',
      padding: 0,
    },
    [theme.breakpoints.down(breakpoints.MOBILE)]: {
      width: '47%',
      height: 50,
      marginLeft: 10,
    },
    marginTop: 0,
  },
  deleteButton: {
    [theme.breakpoints.up(breakpoints.MOBILE)]: {
      width: '150px',
    },
    [theme.breakpoints.down(breakpoints.MOBILE)]: {
      width: '47%',
    },
  },
});

export default withStyles(styles)(ConfirmationSlide);
