import { Theme, withStyles } from '@material-ui/core/styles';
import {
  ClassNameMap,
  StyleRulesCallback,
} from '@material-ui/core/styles/withStyles';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  InfoBox,
  InfoBoxLocation,
  MemberAutocomplete,
  OfficeSeatsChip,
  SkeletonLoader,
  SubmitButton,
  Text,
  TextButton,
} from '../../../Components/Common';

import {
  createOfficeReservation,
  GroupingDto,
  OfficeAvailabilityDto,
} from '../../../Core/Api';
import { breakpoints, colors } from '../../../Core/Theme';
import {
  getIsManager,
  passUsageRoleAccess,
} from '../../../Core/Utils/userPermissions';
import { formatCurrency, date as dateUtils } from '../../../Core/Utils';
import { ReservationDate } from '../../../Models';
import OfficeAmenity from '../../../Components/Common/Office/OfficeAmenity';
import { useDayPasses } from '../../../Hooks';
import { UserDto } from '../../../Core/Api/dto';

type OfficeData = OfficeAvailabilityDto & { owner?: UserDto };

type Props = {
  classes: ClassNameMap;
  profile: UserDto;
  grouping: GroupingDto;
  currencyIsoCode: string;
  officeAvailability: OfficeData;
  onCreated?: () => void;
  isCancel?: boolean;
  canCancel?: boolean;
  isCancelling?: boolean;
  onCancel?: () => void;
  onError?: (msg: string) => void;
  isReservingWithDayPasses?: boolean;
  isFetchingDayPasses?: boolean;
  passesRemaining?: string;
  hasUnlimitedDayPasses?: boolean;
};
// TODO move creation call and error handling into the creation panel
const OfficeReservationForm = (props: Props) => {
  const {
    classes,
    profile,
    grouping,
    officeAvailability,
    currencyIsoCode,
    onCreated,
    isCancel,
    isCancelling,
    onCancel,
    canCancel,
    onError,
    isReservingWithDayPasses,
    isFetchingDayPasses,
    passesRemaining,
    hasUnlimitedDayPasses,
  } = props;

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSubmitSuccess, setSubmitSuccess] = useState(false);
  const [selectedOwner, setSelectedOwner] = useState(
    officeAvailability.owner ?? profile
  );

  const {
    hasUnlimitedDayPasses: hasUnlimitedDayPassesAlternateAccount,
    passesRemaining: passesRemainingAlternateAccount,
    isFetching: isFetchingAlternateAccountPasses,
  } = useDayPasses(
    dateUtils.updateDateForTZ(
      dateUtils
        .getDateInMoment(officeAvailability.startEpoch)
        .startOf('month')
        .unix(),
      selectedOwner?.account?.location?.timeZoneId
    ), // need to ensure date midnight is midnight in zone of accounts location
    profile,
    selectedOwner.account.sfId,
    {
      onlyFetchNonUserAccountData: true,
      initialIsFetching: false,
      skip: isCancel,
    }
  );

  const isReservingWithDayPassesAlternateAccount = passUsageRoleAccess.purchase(
    {
      obj: {
        passesRemaining: passesRemainingAlternateAccount,
        passesNeeded: 1,
        hasUnlimitedDayPasses: hasUnlimitedDayPassesAlternateAccount,
        date: dateUtils.getDateInMoment(officeAvailability.startEpoch),
      },
      profile: selectedOwner,
    }
  );

  const { t } = useTranslation();

  const handleReservationCreation = async () => {
    setIsSubmitting(true);

    try {
      const response = await createOfficeReservation({
        officeSfId: officeAvailability.officeSfId,
        ownerSfId: selectedOwner.sfId,
        date: dateUtils
          .getDateInMomentWithZone(
            officeAvailability.startEpoch,
            grouping.location.timeZoneId
          )
          .format('YYYY-MM-DD'),
      });
      if (response.status !== 201) {
        throw new Error();
      }

      setSubmitSuccess(true);
      onCreated?.();
    } catch (err) {
      onError?.(t('error.title'));
    } finally {
      setIsSubmitting(false);
    }
  };

  const reservationDate = ReservationDate.FromEpochAndTimezoneId(
    officeAvailability.startEpoch,
    grouping.location.timeZoneId
  ).toString();

  const isManager = getIsManager(profile);

  const onChange = (value: any) => {
    setSelectedOwner(value);
  };

  const isBookingForOwnAccount =
    selectedOwner.account.sfId === profile.account.sfId;

  const showDayPasses = useMemo(() => {
    if (isCancel) {
      return isReservingWithDayPasses;
    }

    return isBookingForOwnAccount
      ? isReservingWithDayPasses
      : isReservingWithDayPassesAlternateAccount;
  }, [
    isReservingWithDayPassesAlternateAccount,
    isReservingWithDayPasses,
    selectedOwner.account.sfId,
  ]);

  const renderPrice = () =>
    showDayPasses
      ? t('officeReservationSummary.day_pass')
      : formatCurrency({
          value: officeAvailability.price,
          currency: currencyIsoCode,
        });

  const balance = useMemo(() => {
    if (
      (isBookingForOwnAccount && hasUnlimitedDayPasses) ||
      (!isBookingForOwnAccount && hasUnlimitedDayPassesAlternateAccount)
    ) {
      return t('officeReservationSummary.unlimited_day_passes');
    }

    return t('officeReservationSummary.day_passes_balance', {
      count: parseInt(
        (isBookingForOwnAccount
          ? passesRemaining
          : passesRemainingAlternateAccount) || '0',
        10
      ),
    });
  }, [
    showDayPasses,
    officeAvailability.price,
    currencyIsoCode,
    isBookingForOwnAccount,
    hasUnlimitedDayPasses,
    hasUnlimitedDayPassesAlternateAccount,
  ]);

  return (
    <div className={classes.container}>
      <Text
        text={t('officeReservationSummary.title')}
        className={classes.title}
      />

      <InfoBoxLocation grouping={grouping} profile={profile} />

      <InfoBox
        title={t('officeReservationSummary.date_lbl')}
        descriptions={[reservationDate]}
        disabled
      />

      <InfoBox title={t('officeReservationSummary.office_lbl')} disabled>
        <div className={classes.officeInfoContainer}>
          <div className={classes.officeInfo}>
            <div>
              <span className={classes.officeName}>
                {officeAvailability.officeName}
              </span>
              <span className={classes.floor}>
                {t<string>('officeReservationSummary.floor')}{' '}
                {officeAvailability.floorName}
              </span>
            </div>
            <OfficeSeatsChip
              seats={officeAvailability.seats}
              isDisabled={officeAvailability.isReserved && !isCancel}
            />
            <div>
              {officeAvailability.amenities.map(amenity => (
                <OfficeAmenity
                  key={amenity.id}
                  amenity={amenity}
                  className={classes.amenity}
                  isColoured
                />
              ))}
            </div>
          </div>
          <div>
            <MemberAutocomplete
              label={t('officeReservationSummary.owner_lbl')}
              variant="boxed"
              defaultValue={selectedOwner.name}
              locationSfId={grouping.locationSfId}
              accountSfId={isManager ? undefined : profile.accountSfId}
              withAccount={isManager}
              withLocation={isManager}
              topMenuItems={isManager ? [selectedOwner] : []}
              onChange={onChange}
              readOnly={isCancel}
            />
            <div className={classes.comment}>
              {t<string>('officeReservationSummary.owner_desc')}
            </div>
          </div>
        </div>
      </InfoBox>

      <InfoBox
        className={classes.account}
        title={t('officeReservationSummary.account_lbl')}
      >
        <TextButton
          onClick={() => {}}
          text={selectedOwner.account.name}
          style={{ paddingLeft: 0 }}
        />
        {showDayPasses &&
          !isFetchingDayPasses &&
          !isCancel &&
          !isFetchingAlternateAccountPasses && (
            <div className={classes.balance}>{balance}</div>
          )}
      </InfoBox>

      <InfoBox>
        <div className={classes.priceContainer}>
          <div className={classes.priceLabel}>
            {t<string>('officeReservationSummary.price_lbl')}
          </div>
          <div className={classes.priceValue}>
            {isFetchingDayPasses ? (
              <SkeletonLoader className={classes.passLoader} />
            ) : (
              renderPrice()
            )}
          </div>
        </div>
      </InfoBox>

      {/* ios fix (safari engine) - we use this instead of a margin-bottom to prevent a bug on ios where the reserve CTA would not show (TEC-3903) */}
      <div style={{ width: '100%', height: 175, flexShrink: 0 }} />

      <div className={classes.footer}>
        <div className={classes.disclaimer}>
          {t<string>('officeReservationSummary.disclaimer')}
        </div>
        {isCancel ? (
          <SubmitButton
            defaultText={t('officeReservationSummary.cancel_cta')}
            isLoading={isCancelling}
            onClick={onCancel}
            disabled={!canCancel}
          />
        ) : (
          <SubmitButton
            defaultText={t('officeReservationSummary.cta')}
            isLoading={isSubmitting}
            onClick={handleReservationCreation}
            isSuccess={isSubmitSuccess}
          />
        )}
      </div>
    </div>
  );
};

const styles: StyleRulesCallback = (theme: Theme) => ({
  container: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
    overflow: 'scroll',
    '-ms-overflow-style': 'none',
    '&::-webkit-scrollbar': {
      width: 0 /* Remove scrollbar space */,
      background: 'transparent' /* Optional: just make scrollbar invisible */,
    },
  },
  title: {
    fontSize: 24,
    fontFamily: 'VerlagBold',
    paddingTop: 15,
    display: 'block',
    paddingBottom: 8,
  },
  footer: {
    position: 'absolute',
    bottom: 0,
    right: 0,
    left: 0,
    padding: '20px 40px',
    justifyContent: 'center',
    background: colors.palette.secondary2.light,
    [theme.breakpoints.down(breakpoints.MOBILE)]: {
      bottom: 0,
      padding: 15,
    },
  },
  disclaimer: {
    fontSize: 12,
    marginBottom: 16,
    lineHeight: 1.25,
  },
  officeInfo: {
    display: 'flex',
    flexDirection: 'column',
    gap: '0.5rem',
    marginTop: '0.5rem',
  },
  officeInfoContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
  },
  officeName: {
    fontSize: 16,
    fontFamily: 'VerlagBold',
    marginRight: '0.5rem',
    letterSpacing: '0.5px',
  },
  floor: {
    fontSize: 16,
    fontFamily: 'VerlagLight',
  },
  amenity: {
    color: 'black',
  },
  owner: {
    marginTop: '2rem',
  },
  comment: {
    fontSize: 12,
    fontFamily: 'VerlagLight',
    marginTop: 4,
    marginBottom: '0.50rem',
  },
  account: {
    marginTop: '-1.25rem',
  },
  balance: {
    paddingTop: 5,
  },
  priceContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: -7,
  },
  priceLabel: {
    fontFamily: 'VerlagBold',
    fontSize: 12,
  },
  priceValue: {
    fontFamily: 'VerlagLight',
  },
  passLoader: {
    width: 50,
    height: 15,
  },
});

OfficeReservationForm.defaultProps = {
  onCreated: () => null,
  isCancel: false,
  canCancel: false,
  isCancelling: false,
  onCancel: () => null,
  onError: () => null,
  isReservingWithDayPasses: false,
  isFetchingDayPasses: false,
  passesRemaining: undefined,
  hasUnlimitedDayPasses: false,
};
export default withStyles(styles)(OfficeReservationForm);
