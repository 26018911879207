// Layers
import CommonArea from './CommonArea';
import Office from './Office';
import UnassignedOffice, {
  legend as UnassignedOfficeLegend,
} from './UnassignedOffice';
import UtilizedOffice, {
  legend as UtilizedOfficeLegend,
} from './UtilizedOffice';
import ReservableOffice, {
  legend as ReservableOfficeLegend,
} from './ReservableOffice';
import ReservableDesk, {
  legend as ReservableDeskLegend,
} from './ReservableDesk';
import UnreservableDesk, {
  legend as UnreservableDeskLegend,
} from './UnreservableDesk';
import AssignedOffice, {
  legend as AssignedOfficeLegend,
} from './AssignedOffice';
import AvailableDesk, { legend as AvailableDeskLegend } from './AvailableDesk';
import UnavailableDesk, {
  legend as UnavailableDeskLegend,
} from './UnavailableDesk';
import SelectedDesk, { legend as SelectedDeskLegend } from './SelectedDesk';
import UnavailableOffice from './UnavailableOffice';
import Overlay from './Overlay';
import CommonLabels from './CommonLabels';
import SelectedDesksLabels from './SelectedDesksLabels';
import UnselectableDesk, {
  legend as UnselectableDeskLegend,
} from './UnselectableDesk';
import ReservableOfficeUnavailable from './ReservableOfficeUnavailable';
import UserReservedDesk, {
  legend as UserReservedDeskLegend,
} from './UserReservedDesk';
import UtilizedReservableOffice from './UtilizedReservableOffice';

const Legends = {
  AssignedOffice: AssignedOfficeLegend,
  ReservableOffice: ReservableOfficeLegend,
  UnassignedOffice: UnassignedOfficeLegend,
  UtilizedOffice: UtilizedOfficeLegend,
  ReservableDesk: ReservableDeskLegend,
  UnreservableDesk: UnreservableDeskLegend,
  AvailableDesk: AvailableDeskLegend,
  UnavailableDesk: UnavailableDeskLegend,
  SelectedDesk: SelectedDeskLegend,
  UnselectableDesk: UnselectableDeskLegend,
  UserReservedDesk: UserReservedDeskLegend,
};

export {
  Overlay,
  CommonArea,
  ReservableOffice,
  UnassignedOffice,
  UtilizedOffice,
  ReservableDesk,
  UnreservableDesk,
  Office,
  AvailableDesk,
  UnavailableDesk,
  SelectedDesk,
  AssignedOffice,
  UnavailableOffice,
  Legends,
  CommonLabels,
  SelectedDesksLabels,
  UnselectableDesk,
  UserReservedDesk,
  ReservableOfficeUnavailable,
  UtilizedReservableOffice,
};
