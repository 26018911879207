import axios from 'axios';
import { Http, stringifyUrl } from '../Utils';
import { BlackoutDateDto, LocationDto } from './dto';
import { LocationGroupingDto } from './dto/LocationGroupingDto';
import { GatewayServices, getGatewayUrl } from '../Utils/gateway';

export const fetchLocations = (includeStandaloneGroupings: boolean = false) => {
  const url = stringifyUrl('locations', {
    include_standalone_groupings: includeStandaloneGroupings,
  });

  return Http.get<(LocationDto | LocationGroupingDto)[]>(url);
};

export const fetchLocation = (locationSfId: string) => {
  const url = `locations/${locationSfId}`;
  return Http.get<LocationDto>(url);
};

export const fetchLocationBlackoutDates = (locationId: string) => {
  return axios.get<BlackoutDateDto[]>(
    `/locations/${locationId}/blackout-dates`,
    {
      baseURL: getGatewayUrl(GatewayServices.inventory),
    }
  );
};
