import { InputTypes } from './InputTypes';

import AvailabilityDayPicker from './AvailabilityDayPicker';
import CheckBox from './CheckBox';
import DayPicker from './DayPicker';
import DayPickerBox from './DayPickerBox';
import DropZoneInput from './DropZoneInput';
import FieldInput from './FieldInput';
import GroupingLocationList from './GroupingLocationList';
import ImagePickerInput from './ImagePickerInput';
import MemberAutocomplete from './MemberAutocomplete';
import RadioButton from './RadioButton';
import RichTextLimited from './RichTextLimited';
import SelectAutocomplete from './SelectAutocomplete';
import SelectAutocompleteWithBash from './SelectAutocompleteWithBash';
import SelectInput from './SelectInput';
import SelectInputBox from './SelectInputBox';
import SelectWithGroups from './SelectWithGroups';
import StepperInput from './StepperInput';
import Switch from './Switch';

class InputComponentFactory {
  static getComponent(type: InputTypes) {
    if (type === InputTypes.AvailabilityDayPicker) {
      return AvailabilityDayPicker;
    }
    if (type === InputTypes.CheckBox) {
      return CheckBox;
    }
    if (type === InputTypes.DayPicker) {
      return DayPicker;
    }
    if (type === InputTypes.DayPickerBox) {
      return DayPickerBox;
    }
    if (type === InputTypes.DropZone) {
      return DropZoneInput;
    }
    if (type === InputTypes.GroupingLocationList) {
      return GroupingLocationList;
    }
    if (type === InputTypes.ImagePicker) {
      return ImagePickerInput;
    }
    if (type === InputTypes.MemberAutocomplete) {
      return MemberAutocomplete;
    }
    if (type === InputTypes.Radio) {
      return RadioButton;
    }
    if (type === InputTypes.RichTextLimited) {
      return RichTextLimited;
    }
    if (type === InputTypes.SelectAutocomplete) {
      return SelectAutocomplete;
    }
    if (type === InputTypes.SelectAutocompleteWithBash) {
      return SelectAutocompleteWithBash;
    }
    if (type === InputTypes.SelectInput) {
      return SelectInput;
    }
    if (type === InputTypes.SelectInputBox) {
      return SelectInputBox;
    }
    if (type === InputTypes.SelectWithGroups) {
      return SelectWithGroups;
    }
    if (type === InputTypes.Stepper) {
      return StepperInput;
    }
    if (type === InputTypes.Switch) {
      return Switch;
    }

    return FieldInput;
  }
}

export default InputComponentFactory;
