import { StyleRulesCallback, withStyles } from '@material-ui/core';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import { EmptyStateDesks } from '../../../resources';
import { breakpoints } from '../../../Core/Theme';

type Props = {
  classes: ClassNameMap;
  title: string;
  description: string;
};

const BlackoutDateMessage = (props: Props) => {
  const { classes, title, description } = props;

  return (
    <div className={classes.container}>
      <img
        src={EmptyStateDesks}
        className={classes.image}
        alt="unavailable-desk-img"
      />
      <div className={classes.title}>{title}</div>
      <div className={classes.description}>{description}</div>
    </div>
  );
};

const styles: StyleRulesCallback<string> = theme => ({
  container: {
    marginTop: 70,
    textAlign: 'center',
  },
  title: {
    fontFamily: 'VerlagBold',
    fontSize: 25,
    marginTop: 30,
    [theme.breakpoints.up(breakpoints.SM)]: {
      fontSize: 36,
    },
  },
  description: {
    fontFamily: 'VerlagLight',
    fontSize: 20,
    marginTop: 10,
    [theme.breakpoints.up(breakpoints.SM)]: {
      fontSize: 24,
    },
  },
  image: {
    height: '235px',
    objectFit: 'contain',
    width: '263px',
  },
});

export default withStyles(styles)(BlackoutDateMessage);
