import { date as DateUtils } from '../Core/Utils';

export class ReservationDate extends String {
  static format = '[dddd, MMMM D, YYYY]';

  private constructor(value?: any) {
    super(value);
  }

  static FromEpochAndTimezoneId(epoch: number, timezoneId?: string) {
    const value = DateUtils.formatEpoch(
      epoch,
      ReservationDate.format,
      timezoneId
    );
    return new ReservationDate(value);
  }
}
