import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Carat from '../Carat';

const ExpansionImage = props => {
  const { classes } = props;
  return <Carat className={classes.root} />;
};

const styles = {
  root: {
    height: '100%',
    width: '21px',
  },
};

ExpansionImage.propTypes = {
  classes: PropTypes.object.isRequired,
};
export default withStyles(styles)(ExpansionImage);
