import { colors, breakpoints } from '../../Core/Theme';
import { getStaticImageUrl } from '../../Core/Utils';

const styles = theme => ({
  fieldInputStyle: {
    backgroundColor: colors.white,
    borderRadius: '3px',
    height: '50px',
    marginTop: 16,
  },
  buttonSignInStyle: {
    textTransform: 'none',
    fontSize: '15px',
    fontFamily: 'VerlagBold',
    letterSpacing: '0.5px',
    marginBottom: 8,
    marginTop: 8,
    backgroundColor: colors.palette.primary.main,
    color: colors.white,
    height: 50,
    '&:hover': {
      backgroundColor: colors.palette.primary.light,
    },
  },
  checkBoxLabelStyle: {
    color: colors.white,
    fontWeight: 'normal',
    fontSize: 16,
  },
  forgotPasswordStyle: {
    fontSize: 18,
    fontFamily: "'VerlagBook'",
    color: colors.white,
  },
  linkContainer: {
    textAlign: 'center',
    marginTop: '8%',
  },
  container: {
    display: 'flex',
    [theme.breakpoints.down(breakpoints.MOBILE)]: {
      backgroundImage: `url(${getStaticImageUrl(
        '2020-05/CenturyCity_Mobile.jpg'
      )})`,
    },
    backgroundImage: `url(${getStaticImageUrl(
      '2020-05/Portland_Desktop.jpg'
    )})`,
    width: '100%',
    height: '100vh',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    alignItems: 'center',
    justifyContent: 'center',
  },
  form: {
    [theme.breakpoints.down(breakpoints.MOBILE)]: {
      maxHeight: '100vh',
      height: '100%',
      maxWidth: '100vh',
      alignItems: 'center',
      justifyContent: 'center',
      display: 'flex',
      flexDirection: 'column',
      backgroundColor: colors.blackOpacityLight,
    },
    width: '100%',
    paddingBottom: 20,
    maxHeight: '510px',
    maxWidth: '346px',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: colors.blackOpacityDark,
  },
  inputsContainer: {
    [theme.breakpoints.down(breakpoints.MOBILE)]: {
      padding: '0 20px',
    },
    [theme.breakpoints.up(breakpoints.MOBILE)]: {
      margin: '0 auto',
      maxWidth: '266px',
    },
    flexDirection: 'column',
    maxHeight: '480px',
    marginTop: '10%',
  },
  formContainer: {
    width: '100%',
  },
  titleLogoContainer: {
    [theme.breakpoints.up(breakpoints.MOBILE)]: {
      marginTop: '10%',
    },
    textAlign: 'center',
    fontWeight: '900',
    fontSize: 21,
    letterSpacing: '8px',
    color: colors.white,
  },
  containerMetaInfo: {
    [theme.breakpoints.up(breakpoints.MOBILE)]: {
      marginTop: '3%',
    },
    flexDirection: 'column',
    color: colors.alertMessage,
    backgroundColor: colors.alertBackground,
    padding: '4%',
    fontSize: '12px',
    borderRadius: 2,
    fontFamily: 'VerlagBold',
  },
  indLogo: {
    width: 160,
  },
  inputRoot: {
    border: 'none',
    fontSize: 16,
    padding: 0,
  },
  inputLabel: {
    position: 'absolute !important',
    height: 1,
    width: 1,
    overflow: 'Hidden',
    clip: 'rect(1px, 1px, 1px, 1px)',
    whiteSpace: 'nowrap',
  },
  checkBoxFocus: {
    outlineColor: '-webkit-focus-ring-color !important',
    outlineStyle: 'auto !important',
  },
  checkBox: {
    padding: '0 0 0 0 ',
    margin: '12px 12px 12px 12px',
  },
});

export default styles;
