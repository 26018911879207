import * as React from 'react';
import { InputAdornment, TextField } from '@material-ui/core';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import { colors } from '../../../Core/Theme';
import TextButton from '../../Common/TextButton';

const toggleButton = (bool, cb) => {
  return bool ? cb(false) : cb(true);
};

const PasswordInput = props => {
  const {
    label,
    value,
    onChange,
    textfieldclass,
    classes,
    dataTestId,
    ...rest
  } = props;
  const { t } = useTranslation();
  const [isShowing, toggleShow] = React.useState(false);
  const buttonInputProps = {
    endAdornment: (
      <InputAdornment position="end">
        <TextButton
          className={classes.button}
          onClick={() => toggleButton(isShowing, toggleShow)}
          text={isShowing ? t('general.hide') : t('general.show')}
          dataTestId={
            !isShowing
              ? 'login_show_password_button'
              : 'login_hide_password_button'
          }
        />
      </InputAdornment>
    ),
  };
  const inputProps = {
    ...buttonInputProps,
    disableUnderline: true,
    className: classes.inputRoot,
  };
  return (
    <TextField
      label={label}
      value={value}
      onChange={onChange}
      margin="normal"
      variant="standard"
      className={clsx(classes.text, textfieldclass)}
      {...rest}
      fullWidth
      type={isShowing ? 'text' : 'password'}
      /* eslint-disable react/jsx-no-duplicate-props */
      InputProps={inputProps}
      inputProps={{ dataTestId }}
      InputLabelProps={{
        classes: { root: classes.inputLabel },
        shrink: true,
      }}
    />
  );
};

const styles = {
  inputLabel: {
    position: 'absolute !important',
    height: 1,
    width: 1,
    overflow: 'Hidden',
    clip: 'rect(1px, 1px, 1px, 1px)',
    whiteSpace: 'nowrap',
  },
  text: {
    backgroundColor: colors.white,
    borderRadius: '3px',
    height: '50px',
  },
  inputRoot: {
    border: 'none',
    marginTop: '10px !important',
    paddingLeft: '10px',
  },
  button: {
    width: '26px',
    height: '18px',
    fontFamily: "'VerlagBook'",
    fontSize: '12px',
    fontWeight: 'normal',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 1.5,
    letterSpacing: 'normal',
    color: colors.palette.primary.main,
    padding: '0 0 0 0',
    textTransform: 'none',
    marginRight: 15,
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
};
PasswordInput.defaultProps = {
  value: '',
  label: '',
  textfieldclass: '',
  dataTestId: '',
};

PasswordInput.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  textfieldclass: PropTypes.string,
  classes: PropTypes.object.isRequired,
  dataTestId: PropTypes.string,
};

export default withStyles(styles)(PasswordInput);
