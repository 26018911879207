/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
import { ReactNode } from 'react';
import { StyleRulesCallback, withStyles } from '@material-ui/core';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';

type Props = {
  question: string;
  classes: ClassNameMap;
  id: string;
  children: ReactNode;
};

const Body = (props: Props) => {
  const { classes, question, id, children } = props;

  return (
    <div className={classes.bodyContainer} key={`faq_answer_${id}`}>
      <a id={`faq_${id}`} className={classes.hiddenAnchor} aria-hidden />
      <p className={classes.questionNotLink}>{question}</p>
      {children}
    </div>
  );
};

const style: StyleRulesCallback<string> = theme => ({
  bodyContainer: {
    position: 'relative',
  },
  hiddenAnchor: {
    position: 'absolute',
    visibility: 'hidden',
    [theme.breakpoints.up('xs')]: {
      top: -150,
    },
    [theme.breakpoints.down('xs')]: {
      top: -140,
    },
  },
  questionNotLink: {
    fontFamily: 'VerlagBold',
    display: 'block',
    [theme.breakpoints.up('xs')]: {
      fontSize: 22,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 20,
    },
  },
});

export default withStyles(style)(Body);
