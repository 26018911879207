import PropTypes from 'prop-types';

import DeskTogglePanel from './DeskTogglePanel';
import DeskToggleDrawer from './DeskToggleDrawer';

const DeskToggleForm = props => {
  const { variant, objFormik } = props;

  if (variant === 'panel') {
    return <DeskTogglePanel {...props} {...objFormik} />;
  }
  return <DeskToggleDrawer {...props} {...objFormik} />;
};

DeskToggleForm.defaultProps = {
  variant: 'drawer',
};

DeskToggleForm.propTypes = {
  variant: PropTypes.string,
  objFormik: PropTypes.object.isRequired,
};

export default DeskToggleForm;
