import { StyleRulesCallback, withStyles } from '@material-ui/core';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

type Props = {
  floorName: string;
  classes: ClassNameMap;
  className?: string;
};

const OfficeFloor = (props: Props) => {
  const { floorName, classes, className } = props;
  const { t } = useTranslation();

  return (
    <span className={clsx(classes.default, className)}>
      {t<string>('reserveOffice.floor')} {floorName}
    </span>
  );
};

OfficeFloor.defaultProps = {
  className: '',
};

const styles: StyleRulesCallback<string> = () => ({
  default: {
    fontSize: 16,
    fontFamily: 'VerlagLight',
  },
});

export default withStyles(styles)(OfficeFloor);
