import { StyleRulesCallback, withStyles } from '@material-ui/core';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import {
  OfficeAmenity,
  OfficeCard,
  OfficeFloor,
  OfficeName,
  OfficePrice,
  OfficeSeatsChip,
  RoundedButton,
  RoundedButtonTypes,
} from '../../../Components/Common';
import { colors } from '../../../Core/Theme';
import ReservedByMe from './ReservedByMe';
import ReservedByTeammate from './ReservedByTeammate';
import { AmenityDto } from '../../../Core/Api/dto';
import OfficeAmenityList from '../../../Components/Common/Office/OfficeAmenityList';

type Props = {
  classes: ClassNameMap;
  officeName: string;
  floorName: string;
  seats: number;
  price: number;
  currencyIsoCode: string;
  amenities: AmenityDto[];
  isReserved: boolean;
  userSfId: string;
  teammateName?: string;
  ownerSfId?: string;
  onReservationRequested: () => void;
  isReservingWithDayPasses: boolean;
  isFetchingDayPasses: boolean;
  isManager: boolean;
};

const OfficeAvailability = (props: Props) => {
  const {
    classes,
    officeName,
    floorName,
    seats,
    price,
    currencyIsoCode,
    amenities,
    teammateName,
    isReserved,
    userSfId,
    ownerSfId,
    onReservationRequested,
    isReservingWithDayPasses,
    isFetchingDayPasses,
    isManager,
  } = props;
  const { t } = useTranslation();

  const getPriceOrReservedByComponent = () => {
    if (!teammateName) {
      return (
        <OfficePrice
          currencyIsoCode={currencyIsoCode}
          price={price}
          isReservingWithDayPasses={isReservingWithDayPasses}
          isLoading={isFetchingDayPasses}
          isManager={isManager}
        />
      );
    }
    if (ownerSfId === userSfId) {
      return <ReservedByMe />;
    }
    return <ReservedByTeammate teammate={teammateName} />;
  };

  const datatestid = isReserved ? 'reserved_office' : 'available_office';

  return (
    // @ts-ignore
    <OfficeCard datatestid={datatestid}>
      <div
        className={clsx(
          classes.header,
          isReserved && !teammateName && classes.disabled
        )}
      >
        <div>
          <OfficeName name={officeName} />
          <OfficeFloor floorName={floorName} />
        </div>
        {getPriceOrReservedByComponent()}
      </div>
      <div className={classes.footer}>
        <div
          className={
            amenities.length
              ? classes.seatsWithAmenities
              : classes.seatsWithoutAmenities
          }
        >
          <OfficeSeatsChip seats={seats} isDisabled={isReserved} />
        </div>
        <OfficeAmenityList>
          {amenities.map(amenity => (
            <OfficeAmenity
              key={amenity.id}
              amenity={amenity}
              isLighter={isReserved && !teammateName}
            />
          ))}
        </OfficeAmenityList>
        <RoundedButton
          type={RoundedButtonTypes.Secondary}
          isSmall
          isDisabled={isReserved}
          onClick={onReservationRequested}
        >
          {isReserved
            ? t<string>('general.reserved')
            : t<string>('general.reserve')}
        </RoundedButton>
      </div>
    </OfficeCard>
  );
};

const styles: StyleRulesCallback<string> = () => ({
  header: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  seatsWithoutAmenities: {
    margin: '1rem 0 0.5rem 0',
  },
  seatsWithAmenities: {
    margin: '1rem 0 0.5rem 0',
    width: '100%',
  },
  footer: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  disabled: {
    color: colors.subtleGray,
    cursor: 'default',
  },
});

OfficeAvailability.defaultProps = {
  teammateName: undefined,
  ownerSfId: undefined,
};

export default withStyles(styles)(OfficeAvailability);
