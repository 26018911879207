import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import Text from '../../../../Components/Common/Text';
import FilterButton from './FilterButton';
import styles from './styles';
import { AmenityIcon } from '../../../../Components/Common/AmenitiesIcon';

const Amenities = props => {
  const {
    amenities,
    amenFilters,
    onFilterButtonClick,
    label,
    classNameFilterGroup,
  } = props;
  const { classes } = props;
  const { t } = useTranslation();

  return (
    <div
      role="group"
      aria-labelledby="amenities_filters"
      className={clsx(classes.filterGroup, classNameFilterGroup)}
    >
      <div className={classes.textLabel} id="amenities_filters">
        {label ?? t('roomBooking.amenities.label')}
      </div>
      <div className={classes.buttonArea}>
        {amenities.length ? (
          amenities.map((a, i) => {
            const content = (
              <div className={classes.amenitiesLabel}>
                <AmenityIcon amenityName={a.name} amenityIconUrl={a.iconUrl} />{' '}
                {t(`meeting-room-amenities.${a.name}`)}
              </div>
            );
            const isLast = i === amenities.length - 1;
            return (
              <FilterButton
                key={a.name}
                content={content}
                value={a.sfId}
                type="Amenity"
                status={amenFilters.includes(a.sfId)}
                isLast={isLast}
                datatestid={`home_amenities_${a.name}`}
                onFilterButtonClick={onFilterButtonClick}
              />
            );
          })
        ) : (
          <Text
            text={t('roomBooking.amenities.no_amenities')}
            className={classes.noAmenitiesLabel}
            datatestid="home_amenities_no_amenities"
          />
        )}
      </div>
    </div>
  );
};

Amenities.defaultProps = {
  onFilterButtonClick: null,
  label: undefined,
  classNameFilterGroup: null,
};

Amenities.propTypes = {
  amenities: PropTypes.arrayOf(PropTypes.object).isRequired,
  amenFilters: PropTypes.arrayOf(PropTypes.string).isRequired,
  classes: PropTypes.object.isRequired,
  onFilterButtonClick: PropTypes.func,
  label: PropTypes.string,
  classNameFilterGroup: PropTypes.string,
};

export default withStyles(styles)(Amenities);
