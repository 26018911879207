import { withStyles } from '@material-ui/core/styles';
import Lodash from 'lodash';
import PropTypes from 'prop-types';

import TextButton from '../../TextButton';
import { breakpoints } from '../../../../Core/Theme';
import { ACTIONS_COLUMNS } from '../Constants';

const OptionsColumns = props => {
  const { options, classes, onActionPress, data } = props;

  const handleOnPress = type => {
    return onActionPress({ type, data });
  };

  const renderableOptions = Lodash.isFunction(options)
    ? options(data)
    : options;

  return (
    <div className={classes.optionsContainer}>
      {!Lodash.isEmpty(renderableOptions)
        ? renderableOptions.map(objOptions => {
            const strOptionType = Lodash.get(objOptions, ['type'], null);
            const strOptionLabel = Lodash.get(objOptions, ['label'], null);
            return (
              <p className={classes.paragraphStyleText}>
                <TextButton
                  text={strOptionLabel}
                  onClick={() => handleOnPress(strOptionType)}
                  state={
                    strOptionType === ACTIONS_COLUMNS.CANCEL
                      ? 'accent'
                      : 'default'
                  }
                />
              </p>
            );
          })
        : null}
    </div>
  );
};

OptionsColumns.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string,
      label: PropTypes.string,
      render: PropTypes.func,
      className: PropTypes.string,
    })
  ).isRequired,
  data: PropTypes.shape.isRequired,
  onActionPress: PropTypes.func.isRequired,
};

const styles = theme => ({
  optionsContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    // the table switches to the mobile view at the MD pt
    [theme.breakpoints.up(breakpoints.MD)]: {
      display: 'block',
    },
  },
  paragraphStyleText: {
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    maxWidth: '100%',
    marginTop: 15,
    textAlign: 'right',
    paddingLeft: 20,
    [theme.breakpoints.up(breakpoints.MD)]: {
      paddingLeft: 0,
    },
  },
});

export default withStyles(styles)(OptionsColumns);
