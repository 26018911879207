import { colors, constants } from '../../Core/Theme';

export const CONSTS = {
  DRAWER_FLEX_SHRINK: 0,
  DRAWER_BORDER_RIGHT: 0,

  MENU_ITEM_ACCENT_WIDTH: '3%',
  MENU_ITEM_PADDING: '10%',

  MENU_BORDER_RADIUS: 0,
  MENU_MARGIN_TOP: 70,

  LOGO_MARGIN_TOP: 50,
  LOGO_MARGIN_SIDE: 30,
};

const styles = theme => ({
  root: {
    zIndex: 1303,
  },
  drawer: {
    width: constants.SIDE_NAV_WIDTH,
    flexShrink: CONSTS.DRAWER_FLEX_SHRINK,
    backgroundColor: colors.darkest,
    borderRight: CONSTS.DRAWER_BORDER_RIGHT,
    overflow: 'scroll',
  },

  menuItem: {
    backgroundColor: colors.darkest,
    color: colors.white,
    paddingLeft: CONSTS.MENU_ITEM_PADDING,
    '&:focus': {
      backgroundColor: colors.dark,
      marginRight: CONSTS.MENU_ITEM_ACCENT_WIDTH,
    },
    '&:hover': {
      backgroundColor: colors.dark,
      marginRight: CONSTS.MENU_ITEM_ACCENT_WIDTH,
    },
  },

  menu: {
    boxShadow: 'none',
    backgroundColor: colors.darkest,
    borderRadius: CONSTS.MENU_BORDER_RADIUS,
    marginTop: CONSTS.MENU_MARGIN_TOP,
    display: 'flex',
    flexDirection: 'column',
    height: '80%',
    justifyContent: 'space-between',
  },

  logo: {
    marginTop: CONSTS.LOGO_MARGIN_TOP,
    marginRight: CONSTS.LOGO_MARGIN_SIDE,
    marginLeft: CONSTS.LOGO_MARGIN_SIDE,
  },

  link: {
    textDecoration: 'none',
    color: colors.white,
    width: '100%',
  },

  menuItemBottom: {
    marginTop: 20,
    backgroundColor: colors.palette.tertiary2.main,
    '&:focus': {
      opacity: 0.8,
      marginRight: CONSTS.MENU_ITEM_ACCENT_WIDTH,
      backgroundColor: colors.palette.tertiary2.main,
    },
    '&:hover': {
      opacity: 0.8,
      marginRight: CONSTS.MENU_ITEM_ACCENT_WIDTH,
      backgroundColor: colors.palette.tertiary2.main,
    },
  },

  toolbar: theme.mixins.toolbar,
  adminLink: {
    fontFamily: 'VerlagBold',
    fontWeight: 'bold !important',
  },
  text: {
    fontWeight: 'normal',
  },
  labelChip: {
    marginLeft: 8,
  },
});

export default styles;
