import PropTypes from 'prop-types';
import FieldInput from '../Common/FieldInput';

const FieldInputWrapper = props => {
  return <FieldInput {...props.field} {...props} />;
};

FieldInputWrapper.defaultProps = {
  field: {},
};

FieldInputWrapper.propTypes = {
  field: PropTypes.object,
};

export default FieldInputWrapper;
