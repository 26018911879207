/* eslint-disable react/require-default-props  */

import { withStyles } from '@material-ui/core/styles';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';

import { colors } from '../../../Core/Theme';

type Props = {
  url: string;
  classes: ClassNameMap;
  linkLabel: string;
  target?: string;
};

const Link = (props: Props) => {
  const { classes, url, linkLabel, target = '_blank' } = props;

  return (
    <a
      className={classes.link}
      href={url}
      rel="noopener noreferrer"
      aria-label={url}
      target={target}
    >
      {linkLabel}
    </a>
  );
};

const styles = () => {
  return {
    link: {
      color: colors.textPalette.link.main,
      fontSize: '20px',
      fontFamily: 'VerlagBook',
    },
  };
};

export default withStyles(styles)(Link);
