import { schema, normalize } from 'normalizr';
import Lodash from 'lodash';

//  constructor
export function NormObjCstr(byId, allIds) {
  this.byId = byId;
  this.allIds = allIds;
}

const locationSchema = new schema.Entity(
  'location',
  {},
  { idAttribute: 'sfId' }
);

const locationsArray = [locationSchema];

export const normalizeLocations = data => {
  const temp = normalize(data, locationsArray);

  const allIds = Lodash.get(temp, ['result'], []);
  const byId = Lodash.get(temp, ['entities', 'location'], {});

  return new NormObjCstr(byId, allIds);
};
