import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Moment from 'moment';

import { TextButton } from '..';
import { colors, breakpoints } from '../../../Core/Theme';
import { AvailabilityDayPicker } from '../..';
import GroupingLocationList from '../GroupingLocationList';

const LocationAndDateSelect = props => {
  const {
    selectedDate,
    onChangeLocation,
    onDateChange,
    classes,
    profile,
    dayPickerProps,
    locationListProps,
    isLoading,
    availabilities,
    fetchAvailability,
    locationSfId,
    nextAvailableDayEpoch,
    selectedDaysAvailability,
    nextAvailableDayCount,
    goToAvailable,
    locationTimezoneId,
    datatestid,
    selectedGrouping,
  } = props;
  const { t } = useTranslation();
  return (
    <div className={classes.container}>
      <div className={classes.inputs}>
        <GroupingLocationList
          onChange={onChangeLocation}
          label={null}
          withoutViewHomeLocation
          withBoxDesign
          isLabelHidden
          className={classes.containerLocationList}
          classNameTextInput={classes.locationTextInput}
          selectedGrouping={selectedGrouping}
          {...locationListProps}
          datatestid={`${datatestid}_location`}
        />
        <AvailabilityDayPicker
          disablePastDate
          locationSfId={locationSfId}
          profile={profile}
          availabilities={availabilities}
          isLoading={isLoading}
          fetchAvailability={fetchAvailability}
          selectedDaysAvailability={selectedDaysAvailability}
          selectedDate={selectedDate}
          onDateChange={onDateChange}
          classNameRoot={classes.containerDayPicker}
          error={selectedDaysAvailability === 0 && !isLoading}
          {...dayPickerProps}
          locationTimezoneId={locationTimezoneId}
          datatestid={`${datatestid}_date`}
          selectedGrouping={selectedGrouping}
        />
      </div>
      {selectedDaysAvailability === 0 && !isLoading && (
        <div className={classes.error}>
          <div>
            {nextAvailableDayEpoch
              ? t('deskReservation.fully_booked')
              : t('deskReservation.fully_booked_all_time')}
          </div>
          {nextAvailableDayEpoch && (
            <TextButton
              text={t('deskReservation.fully_booked_cta', {
                date: nextAvailableDayEpoch
                  ? Moment.unix(nextAvailableDayEpoch).format('[MMMM D]')
                  : '',
                count: nextAvailableDayCount,
              })}
              onClick={goToAvailable}
              className={classes.errorButton}
            />
          )}
        </div>
      )}
    </div>
  );
};

const styles = theme => ({
  locationTextInput: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    paddingTop: 10,
    paddingBottom: 9,
    [theme.breakpoints.up(breakpoints.MOBILE)]: {
      borderTopRightRadius: '0px',
      borderBottomLeftRadius: '4px',
    },
    borderBottomRightRadius: '0px',
    borderBottomLeftRadius: '0px',
  },
  inputs: {
    [theme.breakpoints.up(breakpoints.MOBILE)]: {
      flexDirection: 'row',
      display: 'flex',
      width: 'fit-content',
    },
    boxShadow: `0 0 4px 0 ${colors.blackOpacityLightest}`,
    borderRadius: 8,
  },
  container: {
    [theme.breakpoints.up(breakpoints.MOBILE)]: {
      marginRight: 0,
    },
  },
  containerLocationList: {
    [theme.breakpoints.up(breakpoints.MOBILE)]: {
      width: 300,
    },
  },
  containerDayPicker: {
    [theme.breakpoints.up(breakpoints.MOBILE)]: {
      borderBottomLeftRadius: '0px',
      borderTopRightRadius: '4px',
      width: 200,
    },
    borderTopLeftRadius: '0px',
    borderTopRightRadius: '0px',
  },
  error: {
    color: colors.alertMessage,
    marginTop: 10,
    fontSize: 15,
    fontFamily: 'VerlagLight',
    marginBottom: -10,
    [theme.breakpoints.up(breakpoints.MOBILE)]: {
      display: 'flex',
      minWidth: 'max-content',
      marginTop: 5,
      marginBottom: 0,
    },
  },
  errorButton: {
    padding: '0 0 0 0',
    [theme.breakpoints.down(breakpoints.MOBILE)]: {
      marginTop: 5,
    },
  },
});

LocationAndDateSelect.defaultProps = {
  dayPickerProps: {},
  locationListProps: {},
  datatestid: '',
  selectedGrouping: {},
  fetchAvailability: () => null,
};
LocationAndDateSelect.propTypes = {
  selectedDate: PropTypes.number.isRequired,
  onChangeLocation: PropTypes.func.isRequired,
  onDateChange: PropTypes.func.isRequired,
  profile: PropTypes.object.isRequired,
  dayPickerProps: PropTypes.object,
  locationListProps: PropTypes.object,
  availabilities: PropTypes.arrayOf(PropTypes.number).isRequired,
  fetchAvailability: PropTypes.func,
  isLoading: PropTypes.bool.isRequired,
  nextAvailableDayEpoch: PropTypes.number.isRequired,
  nextAvailableDayCount: PropTypes.number.isRequired,
  selectedDaysAvailability: PropTypes.number.isRequired,
  goToAvailable: PropTypes.func.isRequired,
  locationSfId: PropTypes.string.isRequired,
  locationTimezoneId: PropTypes.string.isRequired,
  datatestid: PropTypes.string,
  selectedGrouping: PropTypes.object,
};

export default withStyles(styles)(LocationAndDateSelect);
