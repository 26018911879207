/* eslint-disable react/no-unused-state */
import React from 'react';
import PropTypes from 'prop-types';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import { withStyles } from '@material-ui/core/styles';
import Select from '@material-ui/core/Select';
import Moment from 'moment';
import MenuItem from '@material-ui/core/MenuItem';
import clsx from 'clsx';
import { withTranslation } from 'react-i18next';

// Constants
import {
  SPLITTER_HOURS_TYPE,
  SPLITTER_HOURS_VALUES,
  FULL_DAY_IN_HOUR,
} from './constants';

// Utils
import { getDiffHours } from '../../../Containers/MeetingRoomBooking/utils';
import { date as DateUtils } from '../../../Core/Utils';

// Theme
import { colors } from '../../../Core/Theme';

// Components
import Carat from '../Carat';

class TimeSelect extends React.PureComponent {
  state = {
    openMenu: false,
  };

  // start time is for the second picker so it knows how to calculate the offset
  getOptionsTime = (startTime, inputDate, diffMenu) => {
    const { endTime, timeZoneId } = this.props;
    const timeZoneToUse = timeZoneId ?? DateUtils.getCurrentZone();
    const dateTime =
      inputDate || DateUtils.getTime(DateUtils.getTodayNotWeekend()); // date.getTime(date.getTodayNotWeekend());
    const splitter = this.getSplit();

    const optionsTime = [...Array(FULL_DAY_IN_HOUR / splitter).keys()].map(
      (intHour, intIndex) => {
        const objDate = dateTime.startOf('day').add(intIndex * splitter, 'h');
        return {
          value: objDate.unix(),
          label: objDate.tz(timeZoneToUse).format('[h:mma]'),
        };
      }
    );
    if (diffMenu) {
      const optionsWithStartTime = optionsTime.filter(objTime => {
        return Moment.unix(startTime * 1).isBefore(Moment.unix(objTime.value));
      });

      const optionsFilteredWithDiff = optionsWithStartTime.map(option => {
        const objWithDiff = {
          ...option,
          diff: getDiffHours(startTime, option.value).replace('hours', ''),
        };
        return objWithDiff;
      });

      if (endTime) {
        const optionsWithEndTime = optionsFilteredWithDiff.filter(objTime => {
          return Moment.unix(endTime * 1).isSameOrAfter(
            Moment.unix(objTime.value)
          );
        });
        return optionsWithEndTime;
      }
      return optionsFilteredWithDiff;
    }

    return optionsTime;
  };

  getSplit = () => {
    const { splitIn } = this.props;
    switch (splitIn) {
      case SPLITTER_HOURS_TYPE.HALF:
        return SPLITTER_HOURS_VALUES.HALF;
      case SPLITTER_HOURS_TYPE.QUARTER:
        return SPLITTER_HOURS_VALUES.QUARTER;
      default:
        return SPLITTER_HOURS_VALUES.HOUR;
    }
  };

  handleChange = event => {
    const { onChange } = this.props;
    onChange(event.target.value);
  };

  triggerDropdownMenu = () => {
    this.setState(prevState => ({
      openMenu: !prevState.openMenu,
    }));
  };

  renderInputLabel = timeZoneId => value => {
    return (
      <div>
        {DateUtils.getDateInMomentWithZone(value, timeZoneId).format('[h:mma]')}{' '}
      </div>
    );
  };

  renderOptions = () => {
    const {
      diffMenu,
      withHours,
      startTime,
      inputDate,
      classes,
      datatestid,
      t,
    } = this.props;
    const options = this.getOptionsTime(startTime, inputDate, diffMenu);
    if (diffMenu) {
      return options.map(option => (
        <MenuItem
          value={option.value}
          key={option.value}
          className={classes.menuItem}
          datatestid={`${datatestid}_option_${option.label}`}
        >
          <p style={{ marginRight: 4 }}>
            {`${option.label} `}
            <span
              style={{
                fontFamily: 'VerlagBook',
                fontSize: '14px',
                color: '#898989',
              }}
            >
              {withHours && t('general.count_hours', { count: option.diff })}
            </span>
          </p>
        </MenuItem>
      ));
    }

    return options.map(option => {
      return (
        <MenuItem value={option.value} key={option.value}>
          <p>{`${option.label} `}</p>
        </MenuItem>
      );
    });
  };

  render() {
    const {
      value,
      titleLabel,
      className,
      classes,
      disabled,
      inputProps,
      menuClass,
      labelClass,
      isError,
      datatestid,
      errorMessage,
      timeZoneId,
      t,
    } = this.props;
    const timeZoneToUse = timeZoneId || DateUtils.getCurrentZone();

    return (
      <FormControl className={className}>
        <InputLabel
          htmlFor="select-helper"
          className={clsx(
            classes.label,
            labelClass,
            isError && classes.labelError
          )}
          datatestid={`${datatestid}_label`}
        >
          {titleLabel}
        </InputLabel>
        <Select
          value={value}
          onChange={this.handleChange}
          disabled={disabled}
          placeholder={t('altTexts.lego')}
          MenuProps={{
            onEnter: this.triggerDropdownMenu,
            onExit: this.triggerDropdownMenu,
            classes: {
              paper: clsx(menuClass, classes.menu),
            },
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'left',
            },
            transformOrigin: {
              vertical: 'top',
              horizontal: 'left',
            },
            getContentAnchorEl: null,
          }}
          IconComponent={props =>
            disabled ? null : (
              <a {...props} className={classes.carat}>
                <Carat />
              </a>
            )
          }
          inputProps={{ ...inputProps, datatestid: `${datatestid}_input` }}
          disableUnderline
          renderValue={this.renderInputLabel(timeZoneToUse)}
          classes={{
            select: clsx(
              isError && classes.error,
              disabled && classes.disabled,
              classes.timePicker
            ),
          }}
        >
          {this.renderOptions()}
        </Select>
        {isError && errorMessage && (
          <div className={classes.errorMessage}>{errorMessage}</div>
        )}
      </FormControl>
    );
  }
}
const styles = () => ({
  diffLabel: {
    fontFamily: 'VerlagBook',
    fontSize: '15px',
    paddingTop: 5,
    paddingLeft: 10,
    color: colors.dark,
  },
  TimeSelect: {
    position: 'absolute',
    width: '100%',
    border: 0,
    borderBottom: '1px solid',
    outline: 'none',
    padding: 0,
    margin: 0,
  },
  menuItem: {
    display: 'flex',
    fontFamily: 'VerlagBook',
    backgroundColor: `${colors.white} !important`,
    '&:hover': {
      backgroundColor: `${colors.palette.secondary2.main} !important`,
    },
  },
  menu: {
    maxHeight: '150px',
    width: '115px',
    borderRadius: '3px',
    border: `solid 1px ${colors.middle}`,
    boxShadow: `0 0 4px 0px rgba(0, 0, 0, 0.1)`,
  },
  carat: {
    top: `calc(50% - 9px)`,
    right: 6,
    color: `rgba(0, 0, 0, 0.54)`,
    position: `absolute`,
    pointerEvents: `none`,
  },
  timePicker: {
    color: `${colors.black} !important`,
    fontSize: '16px',
    fontFamily: 'VerlagBook',
    border: `1px solid ${colors.middle}`,
    backgroundColor: 'white',
    borderRadius: '4px !important',
    paddingTop: 11,
    paddingBottom: 8,
    paddingLeft: 5,
    '&:hover': {
      backgroundColor: 'white',
    },
    '&:focus:not(.focus-visible)': {
      background: 'white',
    },
  },
  error: {
    color: `${colors.alertMessage} !important`,
    border: `1px solid ${colors.alertMessage}`,
    backgroundColor: 'white',
  },
  disabled: {
    borderBottom: 'none !important',
  },
  label: {
    fontSize: 16,
    fontFamily: 'VerlagBold',
    color: `${colors.black} !important`,
  },
  labelError: {
    color: 'red !important',
  },
  errorMessage: {
    fontFamily: 'VerlagLight',
    fontSize: 12,
    color: colors.alertMessage,
    marginTop: 5,
    width: 'max-content',
  },
});

TimeSelect.defaultProps = {
  splitIn: SPLITTER_HOURS_TYPE.HOUR,
  startTime: '',
  endTime: null,
  disabled: false,
  diffMenu: false,
  withHours: false,
  inputClasses: {},
  titleLabel: '',
  inputProps: {},
  labelClass: '',
  menuClass: null,
  isError: false,
  datatestid: '',
  errorMessage: '',
  timeZoneId: null,
};
TimeSelect.propTypes = {
  withHours: PropTypes.bool,
  titleLabel: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  classes: PropTypes.shape({}).isRequired,
  splitIn: PropTypes.string,
  className: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  startTime: PropTypes.string,
  endTime: PropTypes.number,
  inputDate: PropTypes.object.isRequired,
  inputProps: PropTypes.object,
  disabled: PropTypes.bool,
  diffMenu: PropTypes.bool,
  inputClasses: PropTypes.object,
  labelClass: PropTypes.string,
  menuClass: PropTypes.string,
  isError: PropTypes.bool,
  datatestid: PropTypes.string,
  errorMessage: PropTypes.string,
  timeZoneId: PropTypes.string,
  t: PropTypes.func.isRequired,
};

export default withTranslation()(withStyles(styles)(TimeSelect));
