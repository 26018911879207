import withStyles, {
  ClassNameMap,
  StyleRulesCallback,
} from '@material-ui/core/styles/withStyles';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { History } from 'history';

import {
  BackButton,
  Drawer,
  SlideConfirmationMessage,
  Snack,
} from '../../../Components/Common';

import { GroupingDto, OfficeAvailabilityDto } from '../../../Core/Api';
import { colors } from '../../../Core/Theme';

import OfficeReservationForm from './OfficeReservationForm';
import OfficeReservationConfirmation from './OfficeReservationConfirmation';
import { SnackVariantes } from '../../../Components/Common/Snack/snackVariantes';
import { getUserProfile } from '../../../Redux/Common/UserManager/selectors';

type Props = {
  classes: ClassNameMap;
  history: History;
  grouping: GroupingDto;
  currencyIsoCode: string;
  officeAvailability?: OfficeAvailabilityDto;
  onClose: () => void;
  onReserved: () => void;
  isReservingWithDayPasses: boolean;
  isFetchingDayPasses: boolean;
  passesRemaining?: string;
  hasUnlimitedDayPasses?: boolean;
};

const OfficeReservationCreationPanel = (props: Props) => {
  const {
    classes,
    history,
    grouping,
    currencyIsoCode,
    officeAvailability,
    onClose,
    onReserved,
    isReservingWithDayPasses,
    isFetchingDayPasses,
    passesRemaining,
    hasUnlimitedDayPasses,
  } = props;
  const profile = useSelector(getUserProfile);
  const [showConfirmationMessage, setShowConfirmationMessage] = useState(false);
  const [errorMsg, setErrorMsg] = useState<string>();

  const handleClosePanel = () => {
    onClose();
    setShowConfirmationMessage(false);
  };

  const handleReservationCreated = () => {
    onReserved();
    setErrorMsg(undefined);
    setShowConfirmationMessage(true);
  };

  return (
    <>
      <Drawer
        isOpenDrawer={!!officeAvailability}
        className="drawerPaperMediumSmall"
        anchor="right"
        onCloseButton={handleClosePanel}
        onCloseDrawer={handleClosePanel}
        customDuration={{ enter: 350, exit: 300 }}
        withCloseButton={showConfirmationMessage}
      >
        {officeAvailability && (
          <div className={classes.panelContent}>
            {!showConfirmationMessage && (
              <div className={classes.officeReservationContainer}>
                <BackButton onBackPress={handleClosePanel} />
                <OfficeReservationForm
                  officeAvailability={officeAvailability}
                  grouping={grouping}
                  profile={profile}
                  currencyIsoCode={currencyIsoCode}
                  onCreated={handleReservationCreated}
                  onError={msg => setErrorMsg(msg)}
                  isReservingWithDayPasses={isReservingWithDayPasses}
                  isFetchingDayPasses={isFetchingDayPasses}
                  passesRemaining={passesRemaining}
                  hasUnlimitedDayPasses={hasUnlimitedDayPasses}
                />
              </div>
            )}

            <SlideConfirmationMessage
              enterOn={showConfirmationMessage}
              message={
                <OfficeReservationConfirmation
                  locationName={`${profile.brand.name} ${grouping.location.externalName}`}
                  epoch={officeAvailability.startEpoch}
                  groupingId={grouping.idGrouping}
                  timezoneId={grouping.location.timeZoneId}
                  history={history}
                  locationEmail={grouping.location.email}
                  officeName={officeAvailability.officeName}
                />
              }
            />
          </div>
        )}
      </Drawer>
      <Snack
        isOpen={!!errorMsg}
        message={errorMsg}
        autoClose
        onClose={() => setErrorMsg(undefined)}
        variant={SnackVariantes.Error}
      />
    </>
  );
};

OfficeReservationCreationPanel.defaultProps = {
  officeAvailability: undefined,
  passesRemaining: undefined,
  hasUnlimitedDayPasses: false,
};

const style: StyleRulesCallback<string> = () => ({
  panelContent: {
    backgroundColor: colors.palette.secondary2.light,
    width: '100%',
    height: '100%',
  },
  officeReservationContainer: {
    padding: '30px 40px',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
  },
});

export default withStyles(style)(OfficeReservationCreationPanel);
