import { StyleRulesCallback, withStyles } from '@material-ui/core';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import { useSelector } from 'react-redux';
import { OfficeAvailabilityDto } from '../../../Core/Api';
import {
  getProfileUserSfId,
  selectIsManager,
} from '../../../Redux/Common/UserManager/selectors';
import OfficeAvailability from './OfficeAvailability';

type Props = {
  classes: ClassNameMap;
  currencyIsoCode: string;
  officeAvailabilities: OfficeAvailabilityDto[];
  onReservationRequested: (officeAvailability: OfficeAvailabilityDto) => void;
  isReservingWithDayPasses: boolean;
  isFetchingDayPasses: boolean;
};

const OfficeAvailabilityList = (props: Props) => {
  const {
    classes,
    currencyIsoCode,
    officeAvailabilities,
    onReservationRequested,
    isReservingWithDayPasses,
    isFetchingDayPasses,
  } = props;
  const userSfId = useSelector(getProfileUserSfId);
  const isManager = useSelector(selectIsManager);

  return (
    // @ts-ignore
    <div className={classes.container} datatestid="office-availability-list">
      {officeAvailabilities.map(officeAvailability => (
        <OfficeAvailability
          key={officeAvailability.officeSfId}
          officeName={officeAvailability.officeName}
          currencyIsoCode={currencyIsoCode}
          floorName={officeAvailability.floorName}
          isReserved={officeAvailability.isReserved}
          price={officeAvailability.price}
          seats={officeAvailability.seats}
          teammateName={officeAvailability.reservedBy?.name}
          amenities={officeAvailability.amenities}
          userSfId={userSfId}
          ownerSfId={officeAvailability.reservedBy?.sfId}
          onReservationRequested={() =>
            onReservationRequested(officeAvailability)
          }
          isReservingWithDayPasses={isReservingWithDayPasses}
          isFetchingDayPasses={isFetchingDayPasses}
          isManager={isManager}
        />
      ))}
    </div>
  );
};

const style: StyleRulesCallback<string> = () => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: '1.5rem',
    width: '100%',
  },
});

export default withStyles(style)(OfficeAvailabilityList);
