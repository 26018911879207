import typeToReducer from 'type-to-reducer';
import Lodash from 'lodash';
import * as types from './actionTypes';
import {
  normalizedRoom,
  NormObjCstr,
  normalizedFloors,
  normalizedBookingRules,
} from './schema';

const initialState = {
  roomData: new NormObjCstr({}, []),
  bookingRules: new NormObjCstr({}, []),
  spaceTypes: new NormObjCstr({}, []),
  amenities: new NormObjCstr({}, []),
  floorsLoading: false,
  floorError: false,
  bookingRulesLoading: false,
  bookingRulesError: false,
  loading: false,
  hasError: false,
};

export const onLoadingRooms = state => {
  return {
    ...state,
    loading: true,
    hasError: false,
  };
};

export const onLoadRoomsFulfilled = (state, action) => {
  const roomsPayload = Lodash.get(action, ['payload'], []);
  const orderRooms = Lodash.orderBy(
    roomsPayload,
    [room => Lodash.get(room, ['name'], '').toLowerCase()],
    ['asc']
  );
  const roomsNormalized = normalizedRoom(orderRooms);
  const existingRoomsById = Lodash.get(state, ['roomData', 'byId'], {});
  const existingRoomsAllIds = Lodash.get(state, ['roomData', 'allIds'], []);
  const newRoomsById = { ...existingRoomsById, ...roomsNormalized.rooms.byId };
  const newRoomsAllIds = Lodash.uniqBy(
    existingRoomsAllIds.concat(roomsNormalized.rooms.allIds)
  );

  const existingTypesById = Lodash.get(state, ['spaceTypes', 'byId'], {});
  const existingTypesAllIds = Lodash.get(state, ['spaceTypes', 'allIds'], []);
  const newTypesById = {
    ...existingTypesById,
    ...roomsNormalized.spaceTypes.byId,
  };
  const newTypesAllIds = Lodash.uniqBy(
    existingTypesAllIds.concat(roomsNormalized.spaceTypes.allIds)
  );

  const existingRulesById = Lodash.get(state, ['bookingRules', 'byId'], {});
  const existingRulesAllIds = Lodash.get(state, ['bookingRules', 'allIds'], []);
  const newRulesById = {
    ...existingRulesById,
    ...roomsNormalized.bookingRules.byId,
  };
  const newRulesAllIds = Lodash.uniqBy(
    existingRulesAllIds.concat(roomsNormalized.bookingRules.allIds)
  );

  const existingAmensById = Lodash.get(state, ['amenities', 'byId'], {});
  const existingAmensAllIds = Lodash.get(state, ['amenities', 'allIds'], []);
  const newAmensById = {
    ...existingAmensById,
    ...roomsNormalized.amenities.byId,
  };
  const newAmensAllIds = Lodash.uniqBy(
    existingAmensAllIds.concat(roomsNormalized.amenities.allIds)
  );

  const existingFloorsById = Lodash.get(state, ['floors', 'byId'], {});
  const existingFloorsAllIds = Lodash.get(state, ['floors', 'allIds'], []);
  const newFloorsById = {
    ...existingFloorsById,
    ...roomsNormalized.floors.byId,
  };
  const newFloorsAllIds = Lodash.uniqBy(
    existingFloorsAllIds.concat(roomsNormalized.floors.allIds)
  );
  if (roomsPayload.length) {
    return {
      ...state,
      roomData: new NormObjCstr(newRoomsById, newRoomsAllIds),
      spaceTypes: new NormObjCstr(newTypesById, newTypesAllIds),
      bookingRules: new NormObjCstr(newRulesById, newRulesAllIds),
      amenities: new NormObjCstr(newAmensById, newAmensAllIds),
      floors: new NormObjCstr(newFloorsById, newFloorsAllIds),
      loading: false,
      hasError: false,
    };
  }
  return {
    ...state,
    loading: false,
    hasError: false,
  };
};

export const onLoadRoomsRejected = state => {
  return {
    ...state,
    loading: false,
    hasError: true,
  };
};

export const deleteRoom = (state, action) => {
  const roomIdToDelete = action.payload;
  const existingRoomsById = Lodash.get(state, ['roomData', 'byId'], {});
  const existingRoomsAllIds = Lodash.get(state, ['roomData', 'allIds'], []);
  const updatedRoomsById = Lodash.omit(existingRoomsById, roomIdToDelete);
  const updatedRoomsAllIds = existingRoomsAllIds.filter(
    id => id !== roomIdToDelete
  );
  return {
    ...state,
    roomData: new NormObjCstr(updatedRoomsById, updatedRoomsAllIds),
  };
};

export const updateRoom = (state, action) => {
  const updatedRoom = action.payload;

  const roomsNormalized = normalizedRoom(updatedRoom);
  const existingRoomsById = Lodash.get(state, ['roomData', 'byId'], {});
  const existingRoomsAllIds = Lodash.get(state, ['roomData', 'allIds'], []);
  const newRoomsById = { ...existingRoomsById, ...roomsNormalized.rooms.byId };
  const newRoomsAllIds = Lodash.uniqBy(
    existingRoomsAllIds.concat(roomsNormalized.rooms.allIds)
  );

  const existingTypesById = Lodash.get(state, ['spaceTypes', 'byId'], {});
  const existingTypesAllIds = Lodash.get(state, ['spaceTypes', 'allIds'], []);
  const newTypesById = {
    ...existingTypesById,
    ...roomsNormalized.spaceTypes.byId,
  };
  const newTypesAllIds = Lodash.uniqBy(
    existingTypesAllIds.concat(roomsNormalized.spaceTypes.allIds)
  );

  const existingRulesById = Lodash.get(state, ['bookingRules', 'byId'], {});
  const existingRulesAllIds = Lodash.get(state, ['bookingRules', 'allIds'], []);
  const newRulesById = {
    ...existingRulesById,
    ...roomsNormalized.bookingRules.byId,
  };
  const newRulesAllIds = Lodash.uniqBy(
    existingRulesAllIds.concat(roomsNormalized.bookingRules.allIds)
  );
  const existingAmensById = Lodash.get(state, ['amenities', 'byId'], {});
  const existingAmensAllIds = Lodash.get(state, ['amenities', 'allIds'], []);
  const newAmensById = {
    ...existingAmensById,
    ...roomsNormalized.amenities.byId,
  };
  const newAmensAllIds = Lodash.uniqBy(
    existingAmensAllIds.concat(roomsNormalized.amenities.allIds)
  );

  const existingFloorsById = Lodash.get(state, ['floors', 'byId'], {});
  const existingFloorsAllIds = Lodash.get(state, ['floors', 'allIds'], []);
  const newFloorsById = {
    ...existingFloorsById,
    ...roomsNormalized.floors.byId,
  };
  const newFloorsAllIds = Lodash.uniqBy(
    existingFloorsAllIds.concat(roomsNormalized.floors.allIds)
  );

  return {
    ...state,
    roomData: new NormObjCstr(newRoomsById, newRoomsAllIds),
    spaceTypes: new NormObjCstr(newTypesById, newTypesAllIds),
    bookingRules: new NormObjCstr(newRulesById, newRulesAllIds),
    amenities: new NormObjCstr(newAmensById, newAmensAllIds),
    floors: new NormObjCstr(newFloorsById, newFloorsAllIds),

    loading: false,
    hasError: false,
  };
};

const addFloor = (state, action) => {
  const floor = action.payload;
  const { idFloor } = floor;
  if (!idFloor) {
    return state;
  }
  const existingFloorsById = Lodash.get(state, ['floors', 'byId'], {});
  const existingFloorsAllIds = Lodash.get(state, ['floors', 'allIds'], []);
  const newFloorsById = {
    ...existingFloorsById,
    [idFloor]: floor,
  };
  const newFloorsAllIds = Lodash.uniqBy(existingFloorsAllIds.concat(idFloor));

  return {
    ...state,
    floors: new NormObjCstr(newFloorsById, newFloorsAllIds),
  };
};

const onLoadingFloors = state => {
  return {
    ...state,
    floorLoading: true,
    floorError: false,
  };
};
const onLoadFloorsFulfilled = (state, action) => {
  const floors = action.payload;
  if (!floors || !floors.length) {
    return state;
  }
  const floorsNormalized = normalizedFloors(floors);

  const existingFloorsById = Lodash.get(state, ['floors', 'byId'], {});
  const existingFloorsAllIds = Lodash.get(state, ['floors', 'allIds'], []);
  const newFloorsById = {
    ...existingFloorsById,
    ...floorsNormalized.byId,
  };
  const newFloorsAllIds = Lodash.uniqBy(
    existingFloorsAllIds.concat(floorsNormalized.allIds)
  );

  return {
    ...state,
    floors: new NormObjCstr(newFloorsById, newFloorsAllIds),
    floorLoading: false,
  };
};

const onLoadFloorsRejected = state => {
  return {
    ...state,
    floorLoading: false,
    floorError: true,
  };
};

// Booking Rules
const onLoadingBookingRulesRejected = state => {
  return {
    ...state,
    bookingRulesLoading: true,
    bookingRulesError: false,
  };
};

const onLoadingBookingRulesFulFilled = (state, action) => {
  const bookingRulesPayload = action.payload;

  const bookingRulesNormalized = normalizedBookingRules(bookingRulesPayload);

  const existingRulesById = Lodash.get(state, ['bookingRules', 'byId'], {});
  const existingRulesAllIds = Lodash.get(state, ['bookingRules', 'allIds'], []);
  const newRulesById = {
    ...existingRulesById,
    ...bookingRulesNormalized.byId,
  };
  const newRulesAllIds = Lodash.uniqBy(
    existingRulesAllIds.concat(bookingRulesNormalized.allIds)
  );

  return {
    ...state,
    bookingRules: new NormObjCstr(newRulesById, Lodash.compact(newRulesAllIds)),
    bookingRulesLoading: false,
  };
};

const onLoadingBookingRules = state => {
  return {
    ...state,
    bookingRulesLoading: false,
    bookingRulesError: true,
  };
};

const contentReducer = typeToReducer(
  {
    [types.LOAD_ROOMS]: {
      PENDING: onLoadingRooms,
      FULFILLED: onLoadRoomsFulfilled,
      REJECTED: onLoadRoomsRejected,
    },
    [types.DELETE_ROOM]: deleteRoom,
    [types.CREATE_ROOM]: updateRoom,
    [types.UPDATE_ROOM]: updateRoom,
    [types.ADD_FLOOR]: addFloor,
    [types.LOAD_FLOORS]: {
      PENDING: onLoadingFloors,
      FULFILLED: onLoadFloorsFulfilled,
      REJECTED: onLoadFloorsRejected,
    },
    [types.LOAD_BOOKING_RULES]: {
      PENDING: onLoadingBookingRules,
      FULFILLED: onLoadingBookingRulesFulFilled,
      REJECTED: onLoadingBookingRulesRejected,
    },
  },
  initialState
);

export default contentReducer;
