import { createSelector } from 'reselect';
import Lodash from 'lodash';
import { getSelectedGrouping } from '../../../Containers/Events/Redux/selectors';

import { getIsIntegrated, getIsStandalone } from '../../../Models/groupings';

const getGroupingsReducers = state => Lodash.get(state, 'groupings', {});

export const getGroupingsById = createSelector(
  getGroupingsReducers,
  groupings => {
    return Lodash.get(groupings, ['groupingsData', 'byId'], {});
  }
);
export const getGroupingsAllIds = createSelector(
  getGroupingsReducers,
  groupings => {
    return Lodash.get(groupings, ['groupingsData', 'allIds'], []);
  }
);
const getUserGroupingIds = createSelector(getGroupingsReducers, groupings => {
  return Lodash.get(groupings, ['userGroupingIds'], []);
});

export const getIsLoading = createSelector(getGroupingsReducers, groupings => {
  return Lodash.get(groupings, ['loading'], false);
});

export const getUserGroupingsIsLoading = createSelector(
  getGroupingsReducers,
  groupings => {
    return Lodash.get(groupings, ['userGroupingsIsLoading'], false);
  }
);

export const getUserGroupings = createSelector(
  getGroupingsById,
  getUserGroupingIds,
  (groupingsById, userGroupingIds) => {
    return userGroupingIds.map(id => {
      return groupingsById[id];
    });
  }
);

export const getUserGroupingIdsNotCoworking = createSelector(
  getGroupingsById,
  getUserGroupingIds,
  (groupingsById, userGroupingIds) => {
    return userGroupingIds.reduce((acc, id) => {
      if (groupingsById[id] && !groupingsById[id].openToCoworkingMembers) {
        acc.push(id);
      }
      return acc;
    }, []);
  }
);

export const getAllDisplayGroupings = createSelector(
  getGroupingsReducers,
  groupings => {
    return groupings?.displayGroupings;
  }
);

export const getUserDefaultGrouping = createSelector(
  getAllDisplayGroupings,
  displayGroupings => {
    return displayGroupings.find(g => g.isDefault);
  }
);

export const getLocationGroupings = createSelector(
  getAllDisplayGroupings,
  displayGroupings => {
    return displayGroupings.filter(
      g => g.openToCoworkingMembers || getIsStandalone(g)
    );
  }
);

export const getIntegratedGroupings = createSelector(
  getAllDisplayGroupings,
  displayGroupings => {
    return displayGroupings.filter(g => getIsIntegrated(g));
  }
);

export const getIsDisplayGroupingsLoading = createSelector(
  getGroupingsReducers,
  groupings => groupings.displayGroupingsIsLoading
);

export const getIntegratedGroupingsForEvents = createSelector(
  getSelectedGrouping,
  getAllDisplayGroupings,
  (selectedGrouping, displayGroupings) => {
    return displayGroupings.filter(
      g =>
        g.type === 'Integrated' &&
        g.locationSfId === selectedGrouping.locationSfId
    );
  }
);
