/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';

import Switch from '../../../Switch';

const CustomSwitch = props => {
  const { value, onChange, classes } = props;
  const { t } = useTranslation();
  const label = value ? t('general.on') : t('general.off');
  return (
    <div className={classes.root}>
      <div className={classes.label} onClick={onChange}>
        {label}
      </div>
      <Switch value={value} onChange={onChange} />
    </div>
  );
};

const styles = {
  root: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    marginRight: -12,
  },
  label: {
    marginRight: 8,
    paddingTop: 1,
    cursor: 'pointer',
  },
};

CustomSwitch.propTypes = {
  value: PropTypes.bool.isRequired,
};

export default withStyles(styles)(CustomSwitch);
