export const NB_SECONDS_IN_MINUTE = 60;
export const NB_MINUTES_IN_HOUR = 60;
export const NB_HOURS_IN_DAY = 24;
export const NB_MS_IN_SECONDE = 1000;

export const NB_SECONDS_IN_HOUR = NB_SECONDS_IN_MINUTE * NB_MINUTES_IN_HOUR;
export const NB_SECONDS_IN_DAY = NB_SECONDS_IN_HOUR * NB_HOURS_IN_DAY;
export const NB_MS_IN_MINUTE = NB_MS_IN_SECONDE * NB_SECONDS_IN_MINUTE;
export const NB_MS_IN_HOUR = NB_MS_IN_SECONDE * NB_SECONDS_IN_HOUR;
export const NB_MS_IN_DAY = NB_SECONDS_IN_DAY * NB_MS_IN_SECONDE;

export const SECOND_IN_MS = NB_MS_IN_SECONDE;
