/* eslint-disable jsx-a11y/interactive-supports-focus */
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import PropTypes from 'prop-types';
import Lodash from 'lodash';
import InfiniteScroll from 'react-infinite-scroller';
import Modal from '@material-ui/core/Modal';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';

import Text from '../Text';
import { colors, breakpoints } from '../../../Core/Theme';
import { THRESHOLD_INFINITE_SCROLL } from '../../../Core/constants';
import Loading from '../Loading';
import { Icons } from '../../../resources';
import { getWindowDimensions } from '../../../Core/Utils';

const Inbox = ({
  classes,
  renderInboxDetailBody,
  renderInboxDetailHeader,
  renderListHeader,
  listTitle,
  hasMore,
  renderInboxRow,
  onLoadMore,
  data,
  emptyText,
  renderEmptyState,
  isOpenMobileModal,
  onGoBackToList,
}) => {
  const { width } = getWindowDimensions();
  const isMobile = width <= breakpoints.SM;

  const renderInboxDetail = () => {
    return (
      <div className={classes.inboxDetailsContainer}>
        {renderInboxDetailHeader()}
        {renderInboxDetailBody()}
      </div>
    );
  };
  const renderListHeaderC = () => {
    if (renderListHeader) {
      return renderListHeader();
    }

    return (
      <div className={classes.listHeaderContainer}>
        <Text text={listTitle} />
      </div>
    );
  };

  const renderLoading = () => {
    return (
      <div className={classes.loader} key="inboxloader">
        {hasMore ? <Loading color={colors.palette.primary.main} /> : null}
      </div>
    );
  };
  const renderList = () => {
    return (
      <InfiniteScroll
        pageStart={0}
        loadMore={onLoadMore}
        hasMore={hasMore}
        threshold={THRESHOLD_INFINITE_SCROLL}
        loader={renderLoading()}
        useWindow={false}
        initialLoad={false}
        className={classes.inboxList}
      >
        {data.map(renderInboxRow)}
      </InfiniteScroll>
    );
  };

  const renderEmptyInbox = () => {
    return (
      <div className={classes.emptyStateContainer}>
        {renderEmptyState ? (
          renderEmptyState()
        ) : (
          <Text text={emptyText} className={classes.emptyText} />
        )}
      </div>
    );
  };

  return (
    <Paper className={classes.inboxContainer}>
      {Lodash.isEmpty(data) ? (
        renderEmptyInbox()
      ) : (
        <>
          <Grid container className={classes.root} spacing={0}>
            <Grid
              item
              xs={12}
              xm={12}
              md={12}
              className={classes.inboxListContainer}
            >
              {renderListHeaderC()}
              {renderList()}
            </Grid>
            <Hidden xsDown>
              <Grid item xs={12} xm={8} md={8} className={classes.inboxDetails}>
                {renderInboxDetail()}
              </Grid>
            </Hidden>
          </Grid>

          <Modal open={isMobile && isOpenMobileModal}>
            <div className={classes.containerInModal}>
              <div>
                <div
                  className={classes.modalCloseIcon}
                  onClick={onGoBackToList}
                  onKeyDown={onGoBackToList}
                  role="button"
                >
                  <Icons.CloseIcon />
                </div>
                {renderInboxDetail()}
              </div>
            </div>
          </Modal>
        </>
      )}
    </Paper>
  );
};

const styles = theme => ({
  emptyText: {
    fontFamily: 'VerlagBold',
    fontSize: 26,
  },
  emptyStateContainer: {
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  inboxDetailsContainer: {
    paddingLeft: '40px',
    paddingRight: '40px',
    paddingTop: '16px',
  },
  listHeaderContainer: {
    textAlign: 'center',
    paddingTop: '16px',
    paddingBottom: '16px',
    borderBottom: `3px solid ${colors.palette.primary.main}`,
    color: colors.palette.primary.main,
    fontFamily: 'VerlagBook',
    fontSize: 20,
  },
  inboxContainer: {
    width: '100%',
    height: '100%',
    backgroundColor: 'white',
    borderTop: `3px solid ${colors.black}`,
    display: 'flex',
    flex: 1,
    overflowY: 'hidden',
  },
  inboxListHeader: {
    flex: 1,
    backgroundColor: 'green',
  },
  inboxList: {
    flex: 1,
    overflowY: 'scroll',
    '&::-webkit-scrollbar': {
      width: 0 /* Remove scrollbar space */,
      background: 'transparent' /* Optional: just make scrollbar invisible */,
    },
  },
  inboxListContainer: {
    flex: 1,
    flexDirection: 'column',
    display: 'flex',
    height: '100%',
  },
  inboxDetails: {
    flex: 2,
    borderLeft: `2px solid ${colors.middle}`,
    [theme.breakpoints.down(breakpoints.SM)]: {
      display: 'none',
    },
  },
  containerInModal: {
    backgroundColor: 'white',
    height: '100%',
  },
  detailsHeaderContainer: {
    borderBottom: `3px solid ${colors.middle}`,
    paddingBottom: '16px',
    fontSize: 20,
  },
  loader: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '16px 0px',
  },
  modalCloseIcon: {
    textAlign: 'right',
    paddingTop: '14px',
    paddingRight: '14px',
  },
});

Inbox.defaultProps = {
  renderInboxDetailBody: () => null,
  onLoadMore: () => null,
  renderInboxDetailHeader: () => null,
  renderListHeader: null,
  renderInboxRow: () => null,
  itemInboxSelected: null,
  listTitle: null,
  emptyText: null,
  hasMore: false,
  isOpenMobileModal: false,
  onGoBackToList: () => null,
  renderEmptyState: null,
};

Inbox.propTypes = {
  listTitle: PropTypes.string,
  emptyText: PropTypes.string,
  renderInboxDetailBody: PropTypes.func,
  renderInboxDetailHeader: PropTypes.func,
  onLoadMore: PropTypes.func,
  hasMore: PropTypes.bool,
  renderListHeader: PropTypes.func,
  renderInboxRow: PropTypes.func,
  itemInboxSelected: PropTypes.shape({}),
  data: PropTypes.arrayOf(PropTypes.number).isRequired,
  isOpenMobileModal: PropTypes.bool,
  onGoBackToList: PropTypes.func,
  renderEmptyState: PropTypes.func,
};

export default withStyles(styles)(Inbox);
