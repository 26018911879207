import { lazy, Suspense } from 'react';
import FullPageCenterLoading from '../FullPageCenterLoading';

const retry = (fn, retriesLeft = 5, interval = 1000) => {
  return new Promise((resolve, reject) => {
    fn()
      .then(resolve)
      .catch(error => {
        setTimeout(() => {
          if (retriesLeft === 1) {
            // reject('maximum retries exceeded');
            reject(error);
            return;
          }

          // Passing on "reject" is the important part
          retry(fn, retriesLeft - 1, interval).then(resolve, reject);
        }, interval);
      });
  });
};

const componentsMap = new Map();

const getCachedLazy = (Component, hasBeenLoaded, setLoaded) => {
  // if we already have it get it
  if (componentsMap.has(Component)) return componentsMap.get(Component);

  // if its new wait for it
  const LazyComponent = lazy(async () => {
    if (!hasBeenLoaded) {
      const sleep = new Promise(resolve =>
        setTimeout(() => resolve(null), 1500)
      );
      const promises = [retry(() => Component), sleep];
      const [ImportedComponent] = await Promise.all(promises);
      setLoaded(true);
      return ImportedComponent;
    }
    return Component;
  });
  componentsMap.set(Component, LazyComponent);
  return LazyComponent;
};

//  This wrapper will check if the Component has been loaded before.
//  If it has not been loaded before, then we will wait for at least 1.5 second to allow the render of spinner
//  If it has been loaded before, then we just return the component
const LazyloadWrapper = Component => {
  let hasBeenLoaded = false;
  /* eslint-disable-next-line no-return-assign */
  const setLoaded = () => (hasBeenLoaded = true);
  return props => {
    const LazyComponent = getCachedLazy(Component, hasBeenLoaded, setLoaded);

    return (
      <Suspense fallback={<FullPageCenterLoading />}>
        <LazyComponent {...props} />
      </Suspense>
    );
  };
};

export default LazyloadWrapper;
