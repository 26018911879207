/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable react/no-danger */

import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { Text, SubmitButton } from '../Common';
import { colors, breakpoints } from '../../Core/Theme';

const PerkCard = ({
  classes,
  perkValue,
  logo,
  body,
  onClick,
  buttonCopy,
  datatestid,
}) => {
  const { t } = useTranslation();
  return (
    <div
      className={classes.container}
      onKeyDown={onClick}
      onClick={onClick}
      role="button"
      datatestid={datatestid}
    >
      <img
        src={logo}
        className={classes.logoImage}
        alt={t('altTexts.perk_logo')}
      />

      {perkValue ? (
        <Text text={t(perkValue)} className={classes.perkValueText} />
      ) : null}
      <Text
        text={
          <div
            dangerouslySetInnerHTML={{
              __html: t(body, { interpolation: { escapeValue: false } }),
            }}
            onClick={e => e.stopPropagation()}
            role="button"
            onKeyDown={e => {
              if (e.key === 'Enter' || e.key === ' ') {
                e.stopPropagation();
              }
            }}
          />
        }
        className={classes.bodyText}
      />
      <div className={classes.perkAction}>
        <SubmitButton
          className={classes.button}
          defaultText={buttonCopy ?? t('perks.get_started')}
        />
      </div>
    </div>
  );
};

const styles = theme => ({
  container: {
    display: 'flex',
    flex: 1,
    height: 'calc(100% - 40px)',
    backgroundColor: colors.white,
    boxShadow: '0 0 4px 1px rgba(0, 0, 0, 0.1)',
    border: `solid 1px ${colors.middle}`,
    flexDirection: 'column',
    padding: '20px',
    alignItems: 'center',
    cursor: 'pointer',
    '&:hover': {
      boxShadow: '0 0 7px 1px rgba(0, 0, 0, 0.25)',
    },
  },
  logoImage: {
    width: '100%',
    objectFit: 'contain',
    height: 60,
    [theme.breakpoints.up(breakpoints.MD)]: {
      height: 65,
    },
  },
  bodyText: {
    fontFamily: 'VerlagLight',
    fontSize: 16,
    letterSpacing: 0.53,
    marginTop: 10,
    textAlign: 'center',
    [theme.breakpoints.up(breakpoints.MD)]: {
      fontSize: 18,
      letterSpacing: 0.67,
    },
  },
  perkValueText: {
    fontFamily: 'VerlagBold',
    fontSize: 18,
    letterSpacing: 0.6,
    alignSelf: 'center',
    textAlign: 'center',
    marginTop: 10,
    [theme.breakpoints.up(breakpoints.MD)]: {
      fontSize: 21,
      letterSpacing: 0.7,
    },
  },
  button: {
    width: '100%',
    flex: 1,
    display: 'flex',
    color: colors.white,
    backgroundColor: colors.palette.primary.main,
  },
  perkAction: {
    display: 'flex',
    flex: 1,
    alignItems: 'flex-end',
    paddingTop: 0,
    marginTop: 25,
    height: 50,
    maxWidth: '250px',
    width: '100%',
    [theme.breakpoints.up(breakpoints.MD)]: {
      height: 40,
    },
  },
});

PerkCard.defaultProps = {
  perkValue: null,
  logo: null,
  body: null,
  onClick: () => null,
  buttonCopy: undefined,
  datatestid: '',
};
PerkCard.propTypes = {
  perkValue: PropTypes.string,
  logo: PropTypes.string,
  body: PropTypes.string,
  onClick: PropTypes.func,
  buttonCopy: PropTypes.string,
  datatestid: PropTypes.string,
};

export default withStyles(styles)(PerkCard);
