/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import PropTypes from 'prop-types';
import Lodash from 'lodash';
import { connect } from 'react-redux';

import { useMemo } from 'react';
import { trackLocationDropdownClick } from '../../../Core/Tracking';

import { getUserProfile } from '../../../Redux/Common/UserManager/selectors';

import { getOptionFromLocationOrGrouping } from './utils';
import { getLocations } from '../../../Redux/Common/Locations/selectors';
import BaseLocationList from '../BaseLocationList/BaseLocationList';

const LocationList = props => {
  const {
    selectLocation,
    gaCategory,
    withoutViewHomeLocation,
    isLoading,
    profile,
    locations,
    selectedLocation,
    titleKey,
  } = props;
  const userLocation = profile?.primaryLocation;

  const selectWrapped = l => {
    if (gaCategory) {
      const name = Lodash.get(l, 'externalName', '');
      trackLocationDropdownClick(name, gaCategory);
    }
    selectLocation(l);
  };

  const onReturnHome = () => selectWrapped(profile?.primaryLocation);

  const getShouldRenderReturnHome = () => {
    if (isLoading || withoutViewHomeLocation) return false;

    return selectedLocation.sfId !== userLocation.sfId;
  };

  const options = useMemo(
    () => locations.map(getOptionFromLocationOrGrouping),
    [locations.length]
  );

  const getIsItemSelected = item => {
    return selectedLocation.sfId === item.value.sfId;
  };

  return (
    <BaseLocationList
      options={options}
      value={selectedLocation}
      onReturnHome={onReturnHome}
      shouldRenderReturnHome={getShouldRenderReturnHome()}
      onChange={selectWrapped}
      getIsItemSelected={getIsItemSelected}
      titleKey={titleKey}
      {...props}
    />
  );
};

LocationList.defaultProps = {
  datatestid: '',
  gaCategory: '',
  label: 'LOCATION',
  withoutViewHomeLocation: false,
  titleKey: 'locationEditor.select_location',
};

LocationList.propTypes = {
  selectLocation: PropTypes.func.isRequired,
  gaCategory: PropTypes.string,
  datatestid: PropTypes.string,
  label: PropTypes.string,
  withoutViewHomeLocation: PropTypes.bool,
  isLoading: PropTypes.bool.isRequired,
  profile: PropTypes.object.isRequired,
  selectedLocation: PropTypes.object.isRequired,
  locations: PropTypes.arrayOf(PropTypes.object).isRequired,
  titleKey: PropTypes.string,
};

const mapStateToProps = state => {
  return {
    profile: getUserProfile(state),
    locations: getLocations(state),
  };
};

export default connect(mapStateToProps)(LocationList);
