import { createSelector } from 'reselect';
import Lodash from 'lodash';

const getListLocations = state => Lodash.get(state, ['locations', 'data'], {});

const getLoading = state => Lodash.get(state, ['locations', 'loading'], '');

const getHasError = state => Lodash.get(state, ['locations', 'hasError'], '');

export const getLocationsAllIds = createSelector(getListLocations, locations =>
  Lodash.get(locations, ['allIds'], [])
);
export const getLocationsById = createSelector(getListLocations, locations =>
  Lodash.get(locations, ['byId'], {})
);

export const getLocations = createSelector(
  [getLocationsAllIds, getLocationsById],
  (allIds, byId) => {
    return allIds.map(id => byId[id]);
  }
);

export const getLoadingLocations = createSelector(
  getLoading,
  isLoading => isLoading
);

export const getHasErrorLocations = createSelector(
  getHasError,
  hasError => hasError
);

export const getMappedLocations = createSelector(
  [getLocationsAllIds, getLocationsById],
  (allIds, byId) => {
    return allIds.map(id => byId[id]);
  }
);
