import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { colors } from '../../../../Core/Theme';

const NotificationHeader = props => {
  const { classes, hideSwitchTwo } = props;
  const { t } = useTranslation();
  return (
    <div className={classes.root}>
      <div className={classes.optionTitle}>{t('settings.email')}</div>
      {hideSwitchTwo ? null : (
        <div className={classes.optionTitle}>{t('settings.sms')}</div>
      )}
    </div>
  );
};

const styles = theme => ({
  root: {
    display: 'grid',
    gridTemplateColumns: 'auto 150px',
    justifyItems: 'right',
    alignItems: 'center',
    width: '100%',
    borderBottom: `1px solid ${colors.middle}`,
    paddingBottom: 10,
    [theme.breakpoints.down('xs')]: {
      gridTemplateColumns: 'auto 90px',
      marginTop: -10,
    },
  },
  optionTitle: {
    fontFamily: 'VerlagBook',
    fontSize: 16,
    color: colors.black,
  },
});

NotificationHeader.defaultProps = {
  hideSwitchTwo: false,
};
NotificationHeader.propTypes = {
  hideSwitchTwo: PropTypes.bool,
};
export default withStyles(styles)(NotificationHeader);
