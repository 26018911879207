import PropTypes from 'prop-types';
import FormControl from '@material-ui/core/FormControl';
import { withStyles } from '@material-ui/core/styles';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import clsx from 'clsx';

import { colors } from '../../../Core/Theme';
import { Carat } from '../../../Components/Common';

const SelectInput = ({
  onChange,
  options,
  value,
  disabled,
  displayEmpty,
  renderValue,
  classes,
  variant,
  className,
  datatestid,
}) => {
  const isActive = variant === 'active';
  const numOptions = options.length;

  const handleChange = event => {
    onChange(event.target.value);
  };

  return (
    <FormControl className={className}>
      <Select
        value={value}
        onChange={handleChange}
        classes={{
          root: classes.root,
          select: clsx(
            classes.select,
            isActive && classes.selectActive,
            disabled && classes.selectDisabled
          ),
        }}
        onClick={e => e.stopPropagation()}
        disabled={disabled || options.length === 0}
        IconComponent={props => (
          <Carat
            {...props}
            size="small"
            className={clsx(
              classes.carat,
              isActive && classes.caratActive,
              disabled && classes.caratDisabled
            )}
            datatestid={`${datatestid}_carat`}
          />
        )}
        MenuProps={{
          MenuListProps: { disablePadding: true },
          classes: {
            paper: classes.menu,
          },
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left',
          },
          getContentAnchorEl: null,
          style: { zIndex: 2 },
        }}
        disableUnderline
        displayEmpty={displayEmpty}
        renderValue={renderValue}
        SelectDisplayProps={{
          datatestid: `${datatestid}_input`,
        }}
      >
        {numOptions &&
          options.map((option, idx) => (
            <MenuItem
              value={option.value}
              key={option.value}
              disabled={option.disabled}
              datatestid={`${datatestid}_${idx}_${option.label}`}
              className={clsx(
                classes.menuItem,
                option.disabled && classes.disabledOption,
                option.hidden && classes.hidden
              )}
            >
              {option.label}
            </MenuItem>
          ))}
      </Select>
    </FormControl>
  );
};

const styles = () => ({
  root: {
    zIndex: 3,
  },
  carat: {
    top: `calc(50% - 4px)`,
    right: 10,
    position: `absolute`,
    pointerEvents: `none`,
    fill: colors.palette.primary.main,
  },
  caratActive: {
    fill: colors.white,
  },
  caratDisabled: {
    fill: colors.gray,
  },
  menuItem: {
    color: colors.palette.primary.main,
    fontFamily: 'VerlagBold',
    fontSize: 16,
    padding: '0 10px',
  },
  hidden: {
    display: 'none',
  },
  disabledOption: {
    color: colors.darkGray,
    opacity: 1,
  },
  select: {
    fontFamily: 'VerlagBold',
    fontSize: 16,
    lineHeight: '12px',
    color: colors.palette.primary.main,
    paddingTop: 5,
    paddingRight: 25,
    paddingBottom: 7,
    paddingLeft: 10,
    border: `solid 2px ${colors.palette.primary.main} !important`,
    borderRadius: '15px !important',
    boxShadow: '0 2px 4px 0 rgba(105, 88, 88, 0.17)',
    backgroundColor: 'white',
    minHeight: '0px',
    '&:hover': {
      backgroundColor: 'white',
    },
    '&:focus:not(.focus-visible)': {
      background: 'white',
    },
  },
  selectActive: {
    color: colors.white,
    backgroundColor: colors.palette.primary.main,
    '&:hover': {
      backgroundColor: colors.palette.primary.main,
    },
    '&:focus:not(.focus-visible)': {
      backgroundColor: colors.palette.primary.main,
    },
  },
  selectDisabled: {
    borderColor: `${colors.light} !important`,
    boxShadow: 'none',
    color: colors.subtleGray,
  },
  menu: {
    borderEndStartRadius: '10px',
    borderEndEndRadius: '10px',
    border: `solid 1px ${colors.palette.primary.main}`,
    boxShadow: `0 0 3px 1px rgba(0, 0, 0, 0.25)`,
    marginTop: '-12px',
    paddingTop: 10,
    background: colors.white,
  },
  noEvents: {
    pointerEvents: 'none',
  },
  boxedCaratInner: { width: 12 },
  disabledLabel: {
    color: `${colors.darkGray} !important`,
  },
});

SelectInput.defaultProps = {
  disabled: false,
  className: '',
  displayEmpty: null,
  datatestid: 'select',
  variant: 'default',
  renderValue: null,
  name: '',
  label: '',
};

SelectInput.propTypes = {
  label: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.object,
  ]).isRequired,
  classes: PropTypes.shape({}).isRequired,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.object,
      ]).isRequired,
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        .isRequired,
    })
  ).isRequired,
  name: PropTypes.string,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  renderValue: PropTypes.func,
  displayEmpty: PropTypes.bool,
  datatestid: PropTypes.string,
  variant: PropTypes.string,
};

export default withStyles(styles)(SelectInput);
