import Components from '../../Components';
import { getIsManager } from '../../Core/Utils/userPermissions';

const { containerTypes } = Components.Routes;

const RouteConfig = {
  path: '/home/memberactivity',
  exact: true,
  name: 'navigation.menu.deskUsage',
  private: true,
  type: containerTypes.NAV,
  grouping: 'navigation.grouping.reserve',
  requiresMemberPortalAccess: true,
  isAuthorized: ({ profile }) => {
    return getIsManager(profile);
  },
};

export default RouteConfig;
