import { useSelector, useDispatch } from 'react-redux';
import { useEffect } from 'react';

import locationsRedux from '../Redux/Common/Locations';
import { LocationDto, LocationGroupingDto } from '../Core/Api';

export const useLocations = () => {
  const locations = useSelector<unknown, (LocationDto | LocationGroupingDto)[]>(
    state => locationsRedux.selectors.getLocations(state)
  );
  const dispatch = useDispatch();

  useEffect(() => {
    if (!locations?.length) {
      dispatch(locationsRedux.actions.addLocations());
    }
  }, []);

  return { locations };
};
