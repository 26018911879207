import { date as DateUtils } from '../../Core/Utils';
import { AnyReservation, Office } from '../../Models';
import { trackVirtualEventLink } from '../../Core/Tracking';
import { getIsStandalone } from '../../Models/groupings';
import { TextButton } from '../Common';
import { RESERVATION_TYPES } from '../../Models/anyReservation';

export const getRowDataForReservation = (r, t, classes) => {
  const { reservation, type } = r;

  if (type === RESERVATION_TYPES.DESK_RESERVATION) {
    return {
      title: t('Schedule.floor_office_desk', {
        floorName: reservation.desk?.parentOffice?.floor?.floorName,
        officeName: Office.getOfficeName(reservation.desk?.parentOffice),
        deskNumber: reservation.desk?.deskNumber,
      }),
      location: reservation.grouping?.location,
      grouping: reservation.grouping,
    };
  }
  if (type === RESERVATION_TYPES.OFFICE_RESERVATION) {
    return {
      title: t('Schedule.floor_office', {
        floorName: reservation.office?.floor?.floorName,
        officeName: Office.getOfficeName(reservation.office),
      }),
      location: reservation.grouping?.location,
      grouping: reservation.grouping,
    };
  }
  if (type === RESERVATION_TYPES.CONFERENCE_BOOKING) {
    const timezoneId = AnyReservation.getReservationTimezoneId(r);

    return {
      time: `${DateUtils.getDateInMomentWithZone(
        reservation.startEpoch,
        timezoneId
      ).format('[h:mma]')} - ${DateUtils.getDateInMomentWithZone(
        reservation.endEpoch,
        timezoneId
      ).format('[h:mma]')}`,
      title: reservation.space?.name,
      location: reservation.space?.location,
      grouping: reservation.space?.grouping,
    };
  }
  if (type === RESERVATION_TYPES.SEAT_BOOKING) {
    return {
      title: t('Schedule.open_workspace_title'),
      location: reservation.grouping?.location,
      grouping: reservation.grouping,
    };
  }
  if (type === RESERVATION_TYPES.RSVP) {
    const onJoinVirtualEventClick = (e, strLink) => {
      e.stopPropagation();
      trackVirtualEventLink();
      return window.open(strLink);
    };
    const timezoneId = AnyReservation.getReservationTimezoneId(r);

    return {
      title: reservation.occasion?.title,
      time: `${DateUtils.getDateInMomentWithZone(
        reservation.occasion?.startEpoch,
        timezoneId
      ).format('[h:mma]')} - ${DateUtils.getDateInMomentWithZone(
        reservation.occasion?.endEpoch,
        timezoneId
      ).format('[h:mma]')}`,
      location: reservation.occasion?.location,
      grouping: reservation.occasion?.grouping,
      renderCustomLocation: () =>
        reservation.occasion?.isVirtual &&
        reservation.occasion?.conferenceLink ? (
          <>
            <TextButton
              text={t('Schedule.zoom_call')}
              className={classes.joinRsvpLink}
              onClick={e =>
                onJoinVirtualEventClick(e, reservation.occasion.conferenceLink)
              }
            />
            &nbsp;•&nbsp;
          </>
        ) : (
          <>
            {reservation.occasion.space?.name ??
              reservation.occasion?.locationDetails}
            &nbsp;•&nbsp;
          </>
        ),
    };
  }
  return {};
};

export const getDetailedRowDataForReservations = r => {
  const { type, reservation } = r;
  if (type === RESERVATION_TYPES.DESK_RESERVATION) {
    const { owner, grouping } = reservation;
    return {
      scheduleTitle: 'Schedule.access_title',
      scheduleSubtitle: 'SUBTITLE',
      title: 'deskAdmin.upcoming.reservationTitle',
      subHeadingtitle: owner?.name,
      scheduleCompany: owner?.account?.name,
      location: getIsStandalone(grouping)
        ? grouping?.groupingName
        : grouping.location?.externalName,
      floor: reservation?.desk?.parentOffice?.floor.floorName ?? 4,
      deskKey: 'deskAdmin.upcoming.desk',
      deskKeyOptions: {
        officeName: Office.getOfficeName(reservation.desk?.parentOffice),
        deskNumber: reservation.desk?.deskNumber,
      },
    };
  }
  return {};
};

export const unusedFunc = () => {};
