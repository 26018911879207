import { createAction } from 'redux-actions';
import * as types from './actionTypes';
import {
  fetchNotificationSettings,
  patchNotificationSettings,
  patchUser,
  fetchUserInfo,
  fetchAccountSettings as fetchAccountSettingsApi,
  patchAccountSetting as patchAccountSettingsApi,
} from '../../../Core/Api';
import { fetchHandlerActions } from '../../../Core/Utils/fetch';

export const login = (objUser, isRememberMe) => {
  const savedMail = localStorage.getItem('mail') || objUser.email;

  const rememberMe = isRememberMe || false;
  if (rememberMe) {
    localStorage.setItem('mail', objUser.email);
  }
  localStorage.setItem('isRememberMe', rememberMe);
  return {
    type: types.LOG_IN,
    payload: savedMail,
  };
};
export const setClientAccessClaims = createAction(
  types.SET_CLIENT_ACCESS_CLAIMS
);
export const setUserToken = createAction(types.SET_USER_TOKEN);
export const setTimeZone = createAction(types.SET_TIMEZONE);
export const setUserProfile = createAction(types.SET_USER_PROFILE);
export const setUserId = createAction(types.SET_USER_ID);
export const setUserActive = createAction(types.SET_USER_ACTIVE);
export const toggleEmailOptOut = createAction(types.TOGGLE_EMAIL_OPT_OUT);
export const logout = createAction(types.LOG_OUT);
export const updateUserAccount = createAction(types.UPDATE_USER_ACCOUNT);
export const setIsFirstLogin = createAction(types.SET_IS_FIRST_LOGIN);
export const setCurrentPath = createAction(types.SET_CURRENT_PATH);

export const fetchUserNotificationSettings = createAction(
  types.FETCH_USER_NOTIFICATION_SETTINGS,
  fetchHandlerActions(fetchNotificationSettings)
);
export const patchUserNotificationSettings = createAction(
  types.PATCH_USER_NOTIFICATION_SETTINGS,
  fetchHandlerActions(patchNotificationSettings)
);

export const patchUserNotificationSettingsRedux = createAction(
  types.PATCH_USER_NOTIFICATION_SETTINGS_REDUX
);
export const patchUserProfile = createAction(
  types.PATCH_USER_PROFILE,
  fetchHandlerActions(patchUser)
);

export const fetchUserProfile = createAction(
  types.FETCH_USER_PROFILE,
  fetchHandlerActions(fetchUserInfo)
);

export const fetchAccountSettings = createAction(
  types.FETCH_ACCOUNT_SETTINGS,
  fetchHandlerActions(fetchAccountSettingsApi)
);

export const patchAccountSettings = createAction(
  types.PATCH_ACCOUNT_SETTINGS,
  fetchHandlerActions(patchAccountSettingsApi)
);

export const addApiError = createAction(types.ADD_API_ERROR);

export const addressApiErrors = createAction(types.ADDRESS_API_ERROR);

export const setCustomErrorDescription = createAction(
  types.SET_CUSTOM_ERROR_DESCRIPTION
);
