import typeToReducer from 'type-to-reducer';
import Lodash from 'lodash';
import moment from 'moment';

import * as types from './actionTypes';
import { date } from '../../../Core/Utils';
import {
  normalizedOccasionTypes,
  normalizedOccasion,
  NormObjCstr,
} from './schema';
import { getIsIntegrated } from '../../../Models/groupings';

const initialState = {
  selectedEvent: '',
  selectedDate: date.getTodayNotWeekend(),
  selectedWeek: moment().startOf('week').add(1, 'day').unix(), // we want the start of monday here
  selectedMenuWeek: moment().startOf('week').add(1, 'day').unix(),
  tz: null,
  occasions: new NormObjCstr({}, []),
  occasionTypes: new NormObjCstr({}, []),
  occasionsRsvps: [],
  isLoading: false, // the loading state for occasions
  isError: false,
  queriedLocations: [],
  queriedGroupings: {},
  selectedGrouping: {},
  rsvpIsLoading: false,
  occasionTypesIsLoading: false,
  lastSelectedGroupingsByLocation: {},
  hospitalityCategories: [],
  hospitalityCategoriesLoading: false,
};

const setSelectedGrouping = (state, action) => {
  const payload = Lodash.get(action, ['payload'], {});

  return {
    ...state,
    selectedGrouping: payload,
    ...(getIsIntegrated(payload) && {
      lastSelectedGroupingsByLocation: {
        ...state.lastSelectedGroupingsByLocation,
        [payload.locationSfId]: payload.idGrouping,
      },
    }),
  };
};
export const onSetTimeZone = (state, action) => {
  const { oldTz, newTz } = Lodash.get(action, ['payload'], {});
  const strSelectedDate =
    Lodash.get(state, ['selectedDate'], null) || date.getTodayNotWeekend();

  const strInternalOldTz = oldTz || moment.tz.guess();

  return {
    ...state,
    selectedDate: date.updateDateForTZ(
      strSelectedDate,
      newTz,
      strInternalOldTz
    ),
    tz: newTz,
  };
};

const onSetDate = (state, action) => {
  const datePayload = Lodash.get(action, ['payload'], {});
  return {
    ...state,
    selectedDate: datePayload,
  };
};

const onSetMenuWeek = (state, action) => {
  const datePayload = Lodash.get(action, ['payload'], {});
  return {
    ...state,
    selectedMenuWeek: datePayload,
  };
};

const onSetSelectedEvent = (state, action) => {
  const payload = Lodash.get(action, ['payload'], {});
  return {
    ...state,
    selectedEvent: payload,
    isLoading: false,
    isError: false,
  };
};

//  add occasions will take in both list and menu
//  we always get all the menu for 7 days
//  we only get 20 menu items
//  hasMore and offset is only for menu items
//  there could be overlap between menu
export const onAddOccasions = (state, action) => {
  const { payload, meta } = action;
  const { locationSfIds, isList, groupingId } = meta;
  const { occasions, total, limit } = payload;
  if (!locationSfIds && !groupingId) {
    return {
      ...state,
      isError: false,
      isLoading: false,
    };
  }

  const occasionsNormalized = normalizedOccasion(occasions);

  //  to create the occasions object
  const existingOccasionsById = Lodash.get(state, ['occasions', 'byId'], {});
  const existingOccasionsAllIds = Lodash.get(
    state,
    ['occasions', 'allIds'],
    []
  );
  const occasionById = {
    ...existingOccasionsById,
    ...occasionsNormalized.occasions.byId,
  };

  const occasionAllIds = Lodash.uniq(
    existingOccasionsAllIds.concat(occasionsNormalized.occasions.allIds)
  );

  //  to concat occasionTypes
  const existingOccasionTypesById = Lodash.get(
    state,
    ['occasionTypes', 'byId'],
    {}
  );
  const existingOccasionTypesAllIds = Lodash.get(
    state,
    ['occasionTypes', 'allIds'],
    []
  );
  const typesById = {
    ...existingOccasionTypesById,
    ...occasionsNormalized.occasionTypes.byId,
  };
  const typesAllIds = Lodash.uniq(
    existingOccasionTypesAllIds.concat(occasionsNormalized.occasionTypes.allIds)
  );

  //  to create the queriedLocations object
  const existingQueriedGroupings = Lodash.get(state, ['queriedGroupings'], {});

  let newQueriedGroupings = state.queriedGroupings;
  if (groupingId) {
    const groupingHasMorePrevQueried = Lodash.get(
      existingQueriedGroupings,
      [groupingId, 'hasMore'],
      false
    );
    const groupingPrevLastListStart = Lodash.get(
      existingQueriedGroupings,
      [groupingId, 'lastListStartEpoch'],
      0
    );

    const groupingPrevLastCallTime = Lodash.get(
      existingQueriedGroupings,
      [groupingId, 'lastCallTime'],
      0
    );

    const hasMore = isList ? total > limit : groupingHasMorePrevQueried;

    const lastListStartEpochOccasionId =
      isList && occasionsNormalized.occasions.allIds.length
        ? Lodash.maxBy(
            occasionsNormalized.occasions.allIds,
            id => occasionById[id].startEpoch
          )
        : 0;
    const lastListStartEpoch =
      isList && occasionsNormalized.occasions.allIds.length
        ? occasionById[lastListStartEpochOccasionId].startEpoch
        : groupingPrevLastListStart;

    const lastCallTime = isList ? moment().unix() : groupingPrevLastCallTime;
    newQueriedGroupings = {
      ...existingQueriedGroupings,
      [groupingId]: {
        // offset,
        hasMore,
        lastListStartEpoch,
        lastCallTime,
      },
    };
  }
  return {
    ...state,
    isLoading: isList ? false : state.isLoading,
    isError: false,
    occasions: new NormObjCstr(occasionById, occasionAllIds),
    occasionTypes: new NormObjCstr(typesById, typesAllIds),
    queriedGroupings: newQueriedGroupings,
  };
};

export const addOccasionRsvps = (state, action) => {
  const { payload } = action;
  const { total, rsvps } = payload;

  if (!total) {
    return {
      ...state,
    };
  }
  const occasions = rsvps.map(r => r.occasion);
  const normOccasions = normalizedOccasion(occasions);
  const existingOccasionsById = Lodash.get(state, ['occasions', 'byId'], {});
  const existingOccasionsAllIds = Lodash.get(
    state,
    ['occasions', 'allIds'],
    []
  );

  // incoming occasions on RSVP's have {attendeeLimit: null} because the reservations are joined on the BE, so we have to merge better
  const temp = Lodash.cloneDeep(existingOccasionsById);
  const byId = Lodash.mergeWith(temp, normOccasions.occasions.byId, (a, b) =>
    b === null ? a : undefined
  );

  const allIds = Lodash.uniq(
    existingOccasionsAllIds.concat(normOccasions.occasions.allIds)
  );

  const existingRsvps = state.occasionsRsvps;
  const occasionsRsvps = Lodash.uniqBy([...existingRsvps, ...rsvps], 'idRsvp');
  return {
    ...state,
    rsvpIsLoading: false,
    isError: false,
    occasionsRsvps,
    occasions: new NormObjCstr(byId, allIds),
  };
};

const addOccasionTypes = (state, action) => {
  const occasionTypesArr = Lodash.get(action, ['payload'], []);

  const occasionTypes = normalizedOccasionTypes(occasionTypesArr);

  return {
    ...state,
    occasionTypes,
    occasionTypesIsLoading: false,
    isError: false,
  };
};

export const onAddHospitalityCategories = (state, action) => {
  const { payload } = action;

  if (!payload.length) {
    return {
      ...state,
    };
  }

  return {
    ...state,
    hospitalityCategoriesLoading: false,
    isError: false,
    hospitalityCategories: payload,
  };
};

export const addOccasion = (state, action) => {
  const payload = Lodash.get(action, ['payload'], {});
  const normOccasion = normalizedOccasion(payload);

  const existingOccasionsById = Lodash.get(state, ['occasions', 'byId'], {});
  const existingOccasionsAllIds = Lodash.get(
    state,
    ['occasions', 'allIds'],
    []
  );

  const byId = { ...existingOccasionsById, ...normOccasion.occasions.byId };
  const allIds = Lodash.uniq(
    existingOccasionsAllIds.concat(normOccasion.occasions.allIds)
  );
  return {
    ...state,
    occasions: new NormObjCstr(byId, allIds),
    isLoading: false,
    isError: false,
  };
};

export const deleteOccasion = (state, action) => {
  const payload = Lodash.get(action, ['payload'], {});
  const { idOccasion } = payload;

  const existingOccasionsAllIds = Lodash.get(
    state,
    ['occasions', 'allIds'],
    []
  );
  const existingOccasionsById = Lodash.get(state, ['occasions', 'byId'], {});

  const allIds = existingOccasionsAllIds.filter(id => {
    return id !== idOccasion;
  });

  const byId = Lodash.omit(existingOccasionsById, idOccasion);

  const normalizedOccasions = new NormObjCstr(byId, allIds);

  return {
    ...state,
    occasions: normalizedOccasions,
    isLoading: false,
    isError: false,
  };
};

export const updateOccasion = (state, action) => {
  const payload = Lodash.get(action, ['payload'], {});
  const normOccasion = normalizedOccasion(payload);

  const existingOccasionsAllIds = Lodash.get(
    state,
    ['occasions', 'allIds'],
    []
  );
  const existingOccasionsById = Lodash.get(state, ['occasions', 'byId'], {});

  const byId = { ...existingOccasionsById, ...normOccasion.occasions.byId };

  return {
    ...state,
    occasions: new NormObjCstr(byId, existingOccasionsAllIds),
    isLoading: false,
    isError: false,
  };
};

export const updateOccasionType = (state, action) => {
  const payload = Lodash.get(action, ['payload'], {});
  const { idOccasionType } = payload;

  const existingOccasionTypesAllIds = Lodash.get(
    state,
    ['occasionTypes', 'allIds'],
    []
  );
  const existingOccasionsById = Lodash.get(
    state,
    ['occasionTypes', 'byId'],
    {}
  );

  const byId = { ...existingOccasionsById, [idOccasionType]: payload };
  return {
    ...state,
    occasionTypes: new NormObjCstr(byId, existingOccasionTypesAllIds),
  };
};

export const addOccasionType = (state, action) => {
  const payload = Lodash.get(action, ['payload'], {});
  const existingOccasionsById = Lodash.get(
    state,
    ['occasionTypes', 'byId'],
    {}
  );
  const existingOccasionsAllIds = Lodash.get(
    state,
    ['occasionTypes', 'allIds'],
    []
  );

  const byId = { ...existingOccasionsById, [payload.idOccasionType]: payload };
  const allIds = existingOccasionsAllIds.concat(payload.idOccasionType);
  return {
    ...state,
    occasionTypes: new NormObjCstr(byId, allIds),
  };
};

const onLoading = state => {
  return {
    ...state,
    isLoading: true,
    isError: false,
  };
};

const onLoadingRsvps = state => {
  return {
    ...state,
    rsvpIsLoading: true,
    isError: false,
  };
};

const onLoadingOccasionTypes = state => {
  return {
    ...state,
    occasionTypesIsLoading: true,
    isError: false,
  };
};

const onLoadingHospitalityCategories = state => {
  return {
    ...state,
    hospitalityCategoriesLoading: true,
    isError: false,
  };
};

const onRejected = state => {
  return {
    ...state,
    isLoading: false,
    isError: true,
  };
};

const onRejectedRsvps = state => {
  return {
    ...state,
    rsvpIsLoading: false,
    isError: true,
  };
};

const onRejectedOccasionTypes = state => {
  return {
    ...state,
    occasionTypesIsLoading: false,
    isError: true,
  };
};

const onRejectedHospitalityCategories = state => {
  return {
    ...state,
    hospitalityCategoriesLoading: false,
    isError: true,
  };
};

export const onAddQueriedLocation = (state, action) => {
  const { payload } = action;
  const newQueried = [...state.queriedLocations, payload];
  return {
    ...state,
    queriedLocations: newQueried,
  };
};

export const updateRsvp = (state, action) => {
  const payload = Lodash.get(action, ['payload'], {});
  const listOccasionsRsvps = Lodash.get(state, ['occasionsRsvps'], []);
  const objOccasion = Lodash.get(
    state,
    ['occasions', 'byId', payload.occasionId],
    {}
  );
  let rsvpExists = false;

  const updatedRsvps = listOccasionsRsvps.map(r => {
    if (r.idRsvp === payload.idRsvp) {
      rsvpExists = true;
      return {
        ...payload,
        occasion: objOccasion, // TODO: This should be handle using normalization.
      };
    }
    return r;
  });
  if (!rsvpExists) {
    updatedRsvps.push({ ...payload, occasion: objOccasion });
  }
  return {
    ...state,
    occasionsRsvps: updatedRsvps,
  };
};
export const addRsvp = (state, action) => {
  const payload = Lodash.get(action, ['payload'], {});
  const listOccasionsRsvps = Lodash.get(state, ['occasionsRsvps'], []);
  return {
    ...state,
    occasionsRsvps: [...listOccasionsRsvps, payload],
  };
};

export const deleteRsvp = (state, action) => {
  const payload = Lodash.get(action, ['payload'], {});
  const originalRsvps = state.occasionsRsvps;

  const updatedRsvps = originalRsvps.filter(r => r.idRsvp !== payload);
  return {
    ...state,
    occasionsRsvps: updatedRsvps,
  };
};

const reducer = typeToReducer(
  {
    [types.SET_TIMEZONE]: onSetTimeZone,
    [types.SET_SELECTED_DATE]: onSetDate,
    [types.SET_SELECTED_MENU_WEEK]: onSetMenuWeek,
    [types.SET_SELECTED_EVENT]: onSetSelectedEvent,
    [types.ADD_OCCASIONS]: {
      PENDING: onLoading,
      FULFILLED: onAddOccasions,
      REJECTED: onRejected,
    },
    [types.ADD_OCCASION_TYPES]: {
      PENDING: onLoadingOccasionTypes,
      FULFILLED: addOccasionTypes,
      REJECTED: onRejectedOccasionTypes,
    },
    [types.ADD_OCCASION_RSVPS]: {
      PENDING: onLoadingRsvps,
      FULFILLED: addOccasionRsvps,
      REJECTED: onRejectedRsvps,
    },
    [types.ADD_HOSPITALITY_CATEGORIES]: {
      PENDING: onLoadingHospitalityCategories,
      FULFILLED: onAddHospitalityCategories,
      REJECTED: onRejectedHospitalityCategories,
    },
    [types.ADD_OCCASION]: addOccasion,

    [types.DELETE_OCCASION]: deleteOccasion,

    [types.UPDATE_OCCASION]: updateOccasion,

    [types.UPDATE_OCCASION_TYPE]: updateOccasionType,

    [types.ADD_OCCASION_TYPE]: addOccasionType,

    [types.SET_SELECTED_GROUPING]: setSelectedGrouping,

    [types.ADD_QUERIED_LOCATION]: onAddQueriedLocation,

    [types.UPDATE_RSVP]: updateRsvp,
    [types.DELETE_RSVP]: deleteRsvp,
    [types.ADD_RSVP]: addRsvp,
  },
  initialState
);

export default reducer;
