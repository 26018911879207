import PropTypes from 'prop-types';

export const CommunityManagerShape = {
  name: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,

  imageUrl: PropTypes.string,
  calendarUrl: PropTypes.string.isRequired,
};

export const createCommunityManagerFromContact = contact => {
  if (!contact) return {};

  return {
    name: contact.name,
    email: contact.email,
    imageUrl: contact.avatarUrl,
    calendarUrl: contact.calendlyLink,
  };
};

export const PlaceholderCommunityManager = {
  name: 'M. Bee',
  email: 'bee@industriousoffice.com',

  imageUrl:
    'https://www.industriousoffice.com/wp-content/themes/industrious-theme/images/mobile-logo.svg',

  calendarUrl: 'http://www.asdf.com',
};
