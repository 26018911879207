import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import Lodash from 'lodash';

import { InfoBox, TextButton } from '../../../Common';
import FormikInput from '../../../FormikInputs';
import { getSfBaseUrl } from '../../../../Core/Utils/linkGenerator';
import { Icons } from '../../../../resources';
import { getIsManager } from '../../../../Core/Utils/userPermissions';

const ResCard = props => {
  const {
    classes,
    className,
    floor,
    location,
    title,
    accountSfId,
    accountName,
    disabled,
    onEdit,
    profile,
    locationsById,
  } = props;
  const { t } = useTranslation();

  const openSfUrl = () => {
    const sfBaseUrl = getSfBaseUrl();
    if (accountSfId) {
      window.open(`${sfBaseUrl}${accountSfId}`);
    }
  };

  const owner = Lodash.get(props, 'owner', {});
  const name = Lodash.get(owner, 'name', '');

  const fieldName = Lodash.get(props, 'name', '');

  const userMenuItem = {
    sfId: Lodash.get(profile, 'sfId'),
    name: Lodash.get(profile, 'name'),
    account: profile?.account,
  };

  const isManager = getIsManager(profile);

  return (
    <InfoBox
      title={title}
      subHeader={floor}
      descriptions={[location]}
      className={clsx(className)}
      withAction
      actionText={t('general.edit')}
      onAction={onEdit}
      disabled={disabled}
    >
      <div className={classes.memberInput}>
        <FormikInput
          name={fieldName}
          {...props}
          label={t('reserveDesk.member_label').toUpperCase()}
          input="memberAutocomplete"
          accountSfId={accountSfId}
          defaultValue={name}
          variant="boxed"
          id="name"
          withAccount={isManager}
          placeholder={t('reserveDesk.member_placeholder')}
          topMenuItems={isManager ? [userMenuItem] : []}
          disabled={disabled}
          readOnly={disabled}
          locationsById={locationsById}
          withLocation={isManager}
        />
      </div>

      {accountName && (
        <div>
          <div className={classes.accountTitle}>
            {t('general.account').toUpperCase()}
          </div>
          <div className={classes.accountInfo}>
            {isManager ? (
              <TextButton
                text={
                  <>
                    {accountName}
                    <Icons.ExternalLink className={classes.exportIcon} />
                  </>
                }
                onClick={openSfUrl}
                className={classes.accountButton}
              />
            ) : (
              accountName
            )}
          </div>
        </div>
      )}
    </InfoBox>
  );
};

const styles = () => ({
  memberInput: {
    paddingTop: 15,
  },
  exportIcon: {
    height: 9,
    paddingLeft: 7,
    paddingRight: 7,
  },
  accountTitle: {
    fontFamily: 'VerlagBold',
    fontSize: 14,
    paddingTop: 20,
  },
  accountInfo: {
    marginTop: 5,
    fontFamily: 'VerlagLight',
  },
  dayPassInfo: {
    fontSize: 16,
    marginTop: 5,
    fontFamily: 'VerlagLight',
  },
  accountButton: {
    padding: '0 0 0 0',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    width: '65%',
    textAlign: 'left',
    overflow: 'hidden',
  },
});

ResCard.defaultProps = {
  className: '',
  floor: '',
  location: '',
  title: '',
  accountSfId: '',
  disabled: false,
  accountName: '',
  hasUnlimitedBalance: true,
  onEdit: () => {},
};

ResCard.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  floor: PropTypes.string,
  location: PropTypes.string,
  title: PropTypes.string,
  accountSfId: PropTypes.string,
  disabled: PropTypes.bool,
  accountName: PropTypes.string,
  passesRemaining: PropTypes.number.isRequired,
  onEdit: PropTypes.func,
  profile: PropTypes.object.isRequired,
  locationsById: PropTypes.object.isRequired,
  hasUnlimitedBalance: PropTypes.bool,
};

export default withStyles(styles)(ResCard);
