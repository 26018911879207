import * as Sentry from '@sentry/react';
import { browserTracingIntegration } from '@sentry/browser';
import { get } from 'lodash';

const cognitoNonErrorMessages = [
  'Non-Error promise rejection captured with value: No Cognito Federated Identity pool provided',
  'Non-Error promise rejection captured with value: No Cognito Identity pool provided for unauthenticated access',
];

const beforeSend = event => {
  const errorArray = get(event, ['exception', 'values'], []);

  if (
    !errorArray.length ||
    cognitoNonErrorMessages.includes(errorArray[0].value)
  ) {
    // when null is returned no event is sent to sentry
    return null;
  }

  return event;
};

const shouldEnableSentry = environment => {
  if (environment === 'staging' || environment === 'prod') {
    return true;
  }
  return false;
};

const replayIntegration = Sentry.replayIntegration({
  maskAllText: false,
  maskAllInputs: false, // In order to debug our reset and forgot password forms, will remove later
  blockAllMedia: true,
});

export const initializeSentry = (dsn, environment, release) => {
  Sentry.init({
    dsn,
    release,
    environment,
    integrations: [browserTracingIntegration(), replayIntegration],
    beforeSend,
    tracesSampleRate: 1.0,
    enabled: shouldEnableSentry(environment),
    attachStacktrace: true,
    maxBreadcrumbs: 50,
    replaysSessionSampleRate: 0, // 0 to disable session replays, activate it on demand
    replaysOnErrorSampleRate: 0,
  });
};

export const setSentryUser = userProfile => {
  const email = get(userProfile, ['email'], null);
  const sfId = get(userProfile, ['sfId'], null);
  const role = get(userProfile, ['role'], null);
  const accountSfId = get(userProfile, ['accountSfId'], null);
  const primaryLocationSfId = get(userProfile, ['primaryLocationSfId'], null);

  return Sentry.configureScope(scope => {
    scope.setUser({ email, role, id: sfId, accountSfId, primaryLocationSfId });
  });
};

export const startSentryReplay = () => {
  try {
    replayIntegration.start();
  } catch (error) {
    // error when already started
  }
};

export const stopSentryReplay = () => {
  try {
    replayIntegration.stop();
    replayIntegration.flush();
  } catch (error) {
    // error when already stoped
  }
};
