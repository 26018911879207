/**
 * Taken from: https://stackoverflow.com/questions/42514144/error-handling-redux-promise-middleware
 * a utility to check if a value is a Promise or not
 * @param value
 */
const isPromise = value =>
  value !== null &&
  typeof value === 'object' &&
  typeof value.then === 'function';

export default function errorMiddleware() {
  return next => action => {
    // If not a promise, continue on
    if (!isPromise(action.payload)) {
      return next(action);
    }

    /**
     * include a property in `meta and evaluate that property to check if this error will be handled locally
     *
     * if (!action.meta.localError) {
     *   // handle error
     * }
     *
     * The error middleware serves to dispatch the initial pending promise to
     * the promise middleware, but adds a `catch`.
     */
    if (!action.meta || !action.meta.localError) {
      // Dispatch initial pending promise, but catch any errors
      return next(action).catch(error => {
        return error;
      });
    }
    return next(action);
  };
}
