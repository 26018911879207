import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

import { breakpoints } from '../../../Core/Theme';

const TitleDescription = ({ title, description, classes, children }) => {
  const desc = description.value ? description.value : description;
  return (
    <div>
      <div className={classes.title}>{title}</div>
      <div className={classes.description}>
        {desc}
        {children}
      </div>
    </div>
  );
};

TitleDescription.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
};

const styles = theme => ({
  title: {
    fontFamily: 'VerlagBold',
    fontSize: '14px',
  },
  description: {
    [theme.breakpoints.up(breakpoints.MOBILE)]: {
      fontFamily: 'VerlagLight',
      fontSize: '18px',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
    fontFamily: 'VerlagLight',
    fontSize: '16px',
    fontWeight: 300,
  },
});

export default withStyles(styles)(TitleDescription);
