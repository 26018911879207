import axios from 'axios';

export const setUpErrorInterceptor = (addApiError: (error: any) => void) => {
  return axios.interceptors.response.use(
    success => {
      return success;
    },
    error => {
      if (error.response?.config) {
        const { method, status } = error.response.config;
        if (method === 'get' && status !== 401) {
          addApiError(error.response.data);
        }
      }

      // @todo: TEC-5777 change this to `return Promise.reject(error);` so that axios calls reject on error
      return error;
    }
  );
};
