import MenuItem from '@material-ui/core/MenuItem';
import { useTranslation } from 'react-i18next';

import { trackReferralLinkClick } from '../../Core/Tracking/gtag';
import { Text } from '../../Components/Common';

const openReferralLink = gtagParams => () => {
  window.open(
    `https://www.industriousoffice.com/refer?utm_medium=raf&utm_source=referralrock&utm_campaign=portalbanner`
  );
  trackReferralLinkClick(gtagParams);
};

export const referralLink = (classes, profileData) => {
  const { t } = useTranslation();
  return (
    <MenuItem
      key="referral_link"
      onClick={openReferralLink(profileData, { location_on_page: 'Menu Item' })}
      selected={false}
      classes={{
        root: classes.genNavProfileItems,
      }}
    >
      <Text
        textStyle="h6"
        text={t('referral.refer_a_friend')}
        className={classes.genNavProfileItemsText}
      />
    </MenuItem>
  );
};
