import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Formik, Form, FieldArray } from 'formik';
import * as Yup from 'yup';
import Lodash from 'lodash';
import { useTranslation } from 'react-i18next';

import { SubmitButton } from '../../Components/Common';

import FormikInputs from '../../Components/FormikInputs';
import { demographicFields, socialMediaFields } from './accountProfileFields';
import { colors, breakpoints } from '../../Core/Theme';

const profileSchema = t =>
  Yup.object().shape({
    name: Yup.string().required(t('general.required_field')),
    contactEmail: Yup.string()
      .email(t('account.valid_email'))
      .nullable()
      .max(80, t('general.field_limited', { count: 80 }))
      .required(t('general.required_field')),
    companySizeId: Yup.string().nullable(),
    websiteUrl: Yup.string()
      .url(t('account.valid_url'))
      .nullable()
      .max(255, t('general.field_limited', { count: 255 })),
    description: Yup.string().nullable(),
    linkedinProfileUrl: Yup.string()
      .nullable()
      .max(255, t('general.field_limited', { count: 255 })),
    twitterHandle: Yup.string()
      .nullable()
      .max(255, t('general.field_limited', { count: 255 })),
    instagramHandle: Yup.string()
      .nullable()
      .max(255, t('general.field_limited', { count: 255 })),
    facebookProfileUrl: Yup.string()
      .nullable()
      .max(255, t('general.field_limited', { count: 255 })),
  });

const AccountProfile = props => {
  const {
    classes,
    name,
    contactEmail,
    websiteUrl,
    companySizeId,
    description,
    twitterHandle,
    instagramHandle,
    facebookProfileUrl,
    linkedinProfileUrl,
    putAccount,
    sfId,
    fetchAccountPickList,
    setUserAccountProfile,
    setDisplayMsg,
    openSnack,
  } = props;
  const { t } = useTranslation();

  const [companySizePicklist, setCompanySizePicklist] = React.useState({});
  const [isSuccess, setSubmitSuccess] = React.useState(false);
  const initialValues = {
    name,
    contactEmail,
    websiteUrl,
    companySizeId,
    description,
    twitterHandle,
    instagramHandle,
    facebookProfileUrl,
    linkedinProfileUrl,
  };
  React.useEffect(() => {
    fetchAccountPickList()
      .then(res => setCompanySizePicklist(res.data.companySizeId))
      .catch(() => setCompanySizePicklist({}));
  }, []);
  return (
    <Formik
      initialValues={{
        name,
        contactEmail,
        websiteUrl,
        companySizeId,
        description,
        twitterHandle,
        instagramHandle,
        facebookProfileUrl,
        linkedinProfileUrl,
      }}
      validationSchema={profileSchema(t)}
      onSubmit={(values, actions) => {
        const valsToSubmit = Object.keys(values).reduce((acc, keyName) => {
          if (values[keyName] === initialValues[keyName]) {
            return acc;
          }
          acc[keyName] = values[keyName];
          return acc;
        }, {});
        if (Lodash.isEmpty(valsToSubmit)) {
          actions.setSubmitting(false);
          return;
        }
        putAccount(sfId, valsToSubmit).then(
          () => {
            setDisplayMsg(null);
            setUserAccountProfile(values);
            actions.setSubmitting(false);
            setSubmitSuccess(true);
            setTimeout(() => setSubmitSuccess(false), 3000);
            openSnack();
          },
          () => {
            setDisplayMsg(t('general.updates_failed'));
            actions.setSubmitting(false);
            setSubmitSuccess(false);
          }
        );
      }}
      render={renderProps => {
        const { values, handleSubmit, isSubmitting } = renderProps;
        return (
          <Form className={classes.form} onSubmit={handleSubmit}>
            <section className={classes.flexContainer}>
              <FieldArray
                name="accountDemographics"
                render={() => (
                  <>
                    {demographicFields.map(field => {
                      return (
                        <div className={classes.flex}>
                          <FormikInputs
                            input="fieldInput"
                            name={field.name}
                            label={t(field.label)}
                            className={classes.flexItem}
                          />
                        </div>
                      );
                    })}
                  </>
                )}
              />
              <FormikInputs
                input="select"
                name="companySizeId"
                options={Object.keys(companySizePicklist).reduce(
                  (acc, item) => {
                    const temp = {
                      value: item,
                      label: companySizePicklist[item],
                    };

                    return acc.concat(temp);
                  },
                  []
                )}
                label={t('account.labels.size')}
                className={classes.select}
                placeholder={t('account.placeholders.size')}
                displayEmpty
                renderValue={() =>
                  companySizePicklist[values.companySizeId] ||
                  values.companySizeId ||
                  t('account.placeholders.size')
                }
              />
            </section>
            <section>
              <FormikInputs
                input="fieldInput"
                name="description"
                label={t('account.labels.description')}
                multiline
                id="account_description"
                className={classes.description}
              />
            </section>
            <section>
              <div className={classes.sectionTitle}>{t('account.connect')}</div>
              <p className={classes.label}>{t('account.social_description')}</p>
            </section>
            <FieldArray
              name="socialMedia"
              render={() => (
                <section className={classes.flexContainer}>
                  <>
                    {socialMediaFields.map(field => {
                      return (
                        <div className={classes.flex}>
                          <FormikInputs
                            input="fieldInput"
                            name={field.name}
                            label={t(field.label)}
                            placeholder={t(field.placeHolder)}
                            className={classes.flexItem}
                          />
                        </div>
                      );
                    })}
                  </>
                </section>
              )}
            />
            <SubmitButton
              className={classes.submitButton}
              defaultText={t('general.save_updates')}
              type="submit"
              isLoading={isSubmitting}
              isSuccess={isSuccess}
            />
          </Form>
        );
      }}
    />
  );
};

const styles = theme => ({
  form: {
    [theme.breakpoints.up(breakpoints.MOBILE)]: {
      marginLeft: 55,
      maxWidth: 720,
    },
    [theme.breakpoints.down(breakpoints.MOBILE)]: {
      marginTop: 40,
    },
  },
  label: {
    padding: 0,
    margin: 0,
    fontFamily: 'VerlagLight',
    fontSize: 16,
    [theme.breakpoints.up(breakpoints.MOBILE)]: {
      fontSize: 18,
    },
  },
  select: {
    padding: 0,
    marginTop: 30,
    [theme.breakpoints.up(breakpoints.MOBILE)]: {
      maxWidth: '290px',
      marginTop: 30,
      marginRight: 70,
    },
  },
  description: {
    marginTop: 30,
    marginBottom: 0,
    color: colors.dark,
    [theme.breakpoints.up(breakpoints.MOBILE)]: {
      maxWidth: 'calc(100% - 70px)',
    },
  },
  sectionTitle: {
    fontFamily: 'VerlagBold',
    fontSize: 16,
    marginTop: 40,
    marginBottom: 10,
    [theme.breakpoints.up(breakpoints.MOBILE)]: {
      fontSize: 20,
    },
  },
  flexContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
  },
  flex: {
    [theme.breakpoints.up(breakpoints.MOBILE)]: {
      width: '360px',
    },
    width: '100%',
  },
  flexItem: {
    color: colors.dark,
    marginTop: 30,
    [theme.breakpoints.up(breakpoints.MOBILE)]: {
      maxWidth: '290px',
      marginRight: 70,
    },
  },
  submitButton: {
    marginTop: 50,
    [theme.breakpoints.up(breakpoints.MOBILE)]: {
      width: 290,
    },
    marginBottom: 50,
  },
});

AccountProfile.defaultProps = {
  classes: {},
  name: '',
  description: '',
  twitterHandle: '',
  instagramHandle: '',
  facebookProfileUrl: '',
  linkedinProfileUrl: '',
  companySizeId: '',
  websiteUrl: '',
  contactEmail: '',
};

AccountProfile.propTypes = {
  classes: PropTypes.shape({}),
  name: PropTypes.string,
  description: PropTypes.string,
  twitterHandle: PropTypes.string,
  instagramHandle: PropTypes.string,
  facebookProfileUrl: PropTypes.string,
  linkedinProfileUrl: PropTypes.string,
  companySizeId: PropTypes.string,
  websiteUrl: PropTypes.string,
  contactEmail: PropTypes.string,
  putAccount: PropTypes.func.isRequired,
  sfId: PropTypes.string.isRequired,
  fetchAccountPickList: PropTypes.func.isRequired,
  setUserAccountProfile: PropTypes.func.isRequired,
  setDisplayMsg: PropTypes.func.isRequired,
  openSnack: PropTypes.func.isRequired,
};

export default withStyles(styles)(AccountProfile);
