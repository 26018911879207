import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Tabs } from '@material-ui/core';
import clsx from 'clsx';

import TabItem from './TabItem';
import { colors, breakpoints } from '../../../Core/Theme';

const BoxedTabs = props => {
  const {
    legends,
    onTabClick,
    classes,
    selectedId,
    className,
    isDisabled,
    tabClassName,
  } = props;

  const renderLegends = () =>
    legends.map(el => (
      <TabItem
        key={el.id}
        isSelected={el.id === selectedId}
        onClick={e => !isDisabled && onTabClick(el.id, e)}
        classNames={{
          selected: clsx(
            classes.selected,
            classes.tab,
            isDisabled && classes.disabledSelected
          ),
          unselected: clsx(
            classes.unselected,
            classes.tab,
            isDisabled && classes.disabledUnselected
          ),
        }}
        isDisabled={isDisabled}
        className={tabClassName}
      >
        {el.text}
      </TabItem>
    ));

  return (
    <Tabs
      value={selectedId}
      className={className}
      classes={{
        indicator: clsx(
          classes.indicator,
          isDisabled && classes.disabledIndicator
        ),
        scroller: classes.scroller,
        root: classes.root,
      }}
    >
      {renderLegends()}
    </Tabs>
  );
};

const styles = theme => ({
  tab: {
    fontFamily: 'VerlagBold',
    fontSize: 18,
    zIndex: 2,
    padding: '10px',
    margin: 0,
    cursor: 'pointer',
    minWidth: 76,
  },
  selected: {
    color: 'white',
  },
  unselected: {
    color: colors.darkGray,
    fontFamily: 'VerlagBook',
  },
  disabledSelected: {
    color: colors.darkGray,
  },
  disabledUnSelected: {
    color: colors.gray,
  },
  indicator: {
    background: colors.palette.primary.main,
    height: '100%',
    borderRadius: 19,
  },
  disabledIndicator: {
    background: colors.light,
  },
  scroller: {
    borderRadius: 19,
    boxShadow: `inset 0 0 0 1px ${colors.gray}`,
    overflow: 'hidden',
    background: colors.white,
  },
  root: {
    boxShadow: '0 0 4px 0 rgba(61, 50, 50, 0.25)',
    borderRadius: 19,
    minHeight: 'auto',
    [theme.breakpoints.up(breakpoints.MOBILE)]: {
      width: 'fit-content',
    },
  },
});

BoxedTabs.defaultProps = {
  className: '',
  selectedId: 0, // default to the first tab
  isDisabled: false,
  tabClassName: '',
};

BoxedTabs.propTypes = {
  legends: PropTypes.arrayOf(PropTypes.object).isRequired,
  onTabClick: PropTypes.func.isRequired,
  className: PropTypes.string,
  selectedId: PropTypes.number,
  isDisabled: PropTypes.bool,
  tabClassName: PropTypes.string,
};

export default withStyles(styles)(BoxedTabs);
