import { Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { trackHomeButtonClick } from '../../Core/Tracking';
import { getUsersDefaultRoute } from '../../Redux/Common/UserManager/selectors';

const LogoComponent = props => {
  const { Logo, classes } = props;

  const defaultRoute = useSelector(getUsersDefaultRoute);

  const RenderComponent =
    typeof Logo === 'string' ? (
      <img className={classes.beeLogo} src={Logo} alt="bee logo" />
    ) : (
      <Logo className={classes.indLogo} />
    );

  return (
    <Link
      to={defaultRoute}
      onClick={() => {
        trackHomeButtonClick();
      }}
      className={classes.logoLink}
      datatestid="home_industr_logo_picture"
    >
      {RenderComponent}
    </Link>
  );
};

const CONSTS = {
  IND_LOGO_WIDTH: 200,
  BEE_LOG0_MARGIN_LEFT_SM: 100,
  BEE_LOG0_MARGIN_LEFT_XS: 80,
  BEE_LOGO_HEIGHT_LG: 50,
  BEE_LOGO_WIDTH_LG: 42,
  BEE_LOGO_TOP_LG: 20,
  BEE_LOGO_TOP_SM: 18,
};

const styles = theme => ({
  indLogo: {
    height: 30,
    width: CONSTS.IND_LOGO_WIDTH,
    marginTop: 25,
    marginLeft: 20,
  },
  logoLink: {
    position: 'relative',
    [theme.breakpoints.down('sm')]: {
      height: CONSTS.BEE_LOGO_HEIGHT_LG,
      width: CONSTS.BEE_LOGO_WIDTH_LG,
      marginLeft: CONSTS.BEE_LOG0_MARGIN_LEFT_SM,
    },
    [theme.breakpoints.down('xs')]: {
      height: CONSTS.BEE_LOGO_HEIGHT_LG,
      width: CONSTS.BEE_LOGO_WIDTH_LG,
      marginLeft: CONSTS.BEE_LOG0_MARGIN_LEFT_XS,
    },
  },
  beeLogo: {
    height: CONSTS.BEE_LOGO_HEIGHT_LG,
    width: CONSTS.BEE_LOGO_WIDTH_LG,
    [theme.breakpoints.down('sm')]: {
      height: CONSTS.BEE_LOGO_HEIGHT_LG,
      width: CONSTS.BEE_LOGO_WIDTH_LG,
      marginTop: CONSTS.BEE_LOGO_TOP_LG,
    },
    [theme.breakpoints.down('xs')]: {
      height: 30,
      width: 25,
      marginTop: CONSTS.BEE_LOGO_TOP_SM,
    },
  },
});

LogoComponent.defaultProps = {
  Logo: '',
};

LogoComponent.propTypes = {
  classes: PropTypes.object.isRequired,
  Logo: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

export default withStyles(styles)(LogoComponent);
