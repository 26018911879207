import { stringifyUrl, Http } from '../Utils';
import { FloorDto } from './dto';

export const fetchFloors = (locationSfId: string, includeDeleted = false) => {
  const queryParams = {
    location_sfid: locationSfId,
    include_deleted: includeDeleted,
  };

  const url = stringifyUrl('floors', queryParams);

  return Http.get<FloorDto[]>(url);
};
