import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { MailOutline } from '@material-ui/icons';
import clsx from 'clsx';

import TextButton from '../TextButton';
import { breakpoints } from '../../../Core/Theme';

const EmailButton = props => {
  const { email, classes, className } = props;
  return email ? (
    <TextButton
      text={
        <div className={classes.content}>
          <MailOutline className={classes.icon} />
          <div className={classes.email}>{email}</div>
        </div>
      }
      onClick={() => window.open(`mailto:${email}`)}
      className={clsx([classes.button, className])}
    />
  ) : null;
};

const styles = theme => ({
  button: {
    fontFamily: 'VerlagBook',
    fontSize: 16,
  },
  icon: {
    height: 15,
    padding: 0,
    paddingTop: 3,
  },
  email: {
    display: 'none',
    [theme.breakpoints.up(breakpoints.MOBILE)]: {
      display: 'block',
    },
  },
  content: {
    overflowWrap: 'anywhere',
    display: 'flex',
    textAlign: 'left',
  },
});
EmailButton.defaultProps = {
  email: null,
  className: '',
};

EmailButton.propTypes = {
  email: PropTypes.string,
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
};

export default withStyles(styles)(EmailButton);
