import { withStyles } from '@material-ui/core/styles';
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { colors } from '../../../Core/Theme';

const RadioButton = props => {
  const {
    id,
    classes,
    label,
    disabled,
    onChange,
    value,
    description,
    descriptions,
    ...rest
  } = props;
  const handleChange = () => onChange(id);
  const checked = id === value;
  return (
    <div>
      <FormControlLabel
        value={value}
        label={label}
        classes={{
          label: clsx(
            classes.label,
            disabled && classes.labelDisabled,
            disabled && !checked && classes.labelDisabledUnchecked
          ),
          root: classes.root,
        }}
        disabled={disabled}
        control={
          <Radio
            {...rest}
            type="radio"
            checked={checked}
            onChange={handleChange}
            className={clsx(classes.default, disabled && classes.disabledIcon)}
            classes={{
              checked: clsx(classes.checked, disabled && classes.disabledIcon),
            }}
            disabled={disabled}
            focusVisibleClassName={classes.checkBoxFocusVisible}
          />
        }
      />
      {description && <div className={classes.description}>{description}</div>}
      {descriptions &&
        descriptions.map((d, idx) => (
          <div
            className={clsx(
              classes.description,
              idx && classes.bottomDescription
            )}
          >
            {d}
          </div>
        ))}
    </div>
  );
};

const styles = {
  default: {
    color: colors.dark,
    padding: 0,
    margin: 0,
    height: 17,
    width: 17,
    marginTop: -5,
  },
  root: {
    margin: '0 0 0 0',
  },
  checked: {
    color: `${colors.palette.primary.main} !important`,
  },
  disabledIcon: {
    color: `${colors.middle} !important`,
  },
  label: {
    paddingLeft: 20,
    cursor: 'pointer',
    fontSize: 18,
    fontFamily: 'VerlagBook',
    lineHeight: 1,
    color: 'black !important',
  },
  labelDisabled: {
    cursor: 'default',
  },
  labelDisabledUnchecked: {
    color: `${colors.darkGray} !important`,
  },
  checkBoxFocusVisible: {
    outlineColor: '-webkit-focus-ring-color !important',
    outlineStyle: 'auto !important',
  },
  description: {
    fontFamily: 'VerlagLight',
    color: colors.darkest,
    fontSize: 14,
    paddingTop: 5,
    paddingLeft: 37,
  },
  bottomDescription: {
    marginTop: 5,
  },
};

RadioButton.defaultProps = {
  disabled: false,
  description: '',
  descriptions: null,
};
RadioButton.propTypes = {
  id: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  value: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  onChange: PropTypes.func.isRequired,
  description: PropTypes.string,
  descriptions: PropTypes.arrayOf(PropTypes.string),
};
export default withStyles(styles)(RadioButton);
