import { useState, useEffect, useLayoutEffect } from 'react';
import { breakpoints } from '../Theme';

export function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

export function useWindowSize() {
  const [size, setSize] = useState([0, 0]);
  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);
  return size;
}

function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
}

export const useMobileBreakpoint = () => {
  const windowDimensions = useWindowDimensions();
  const { width } = windowDimensions;
  return width <= breakpoints.MOBILE;
};

export function getIsSmall() {
  const { width } = getWindowDimensions();
  return width <= breakpoints.SM;
}

export function getIsMobile() {
  const { width } = getWindowDimensions();
  return width <= breakpoints.MOBILE;
}

export default useWindowDimensions;
