import Components from '../../Components';
import { ROOM_BOOKING_PATH } from './constants';

const { containerTypes } = Components.Routes;

const RouteConfig = {
  path: ROOM_BOOKING_PATH,
  exact: true,
  name: 'navigation.menu.meetingroom',
  private: true,
  type: containerTypes.SUBCONTENT,
  grouping: 'navigation.grouping.reserve',
  shortPathName: 'navigation.menu.meetingroomshort',
  requiresMemberPortalAccess: true,
};

export default RouteConfig;
