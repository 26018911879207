import { createSelector } from 'reselect';
import Lodash from 'lodash';
import Moment from 'moment';
import { date as DateUtils } from '../../../Core/Utils';

export const getEvents = state => Lodash.get(state, ['events'], {});

export const getSelectedEvent = createSelector(getEvents, events =>
  Lodash.get(events, ['selectedEvent'], {})
);

export const getTimezone = createSelector(getEvents, events =>
  Lodash.get(events, 'tz', '')
);

export const getSelectedDate = createSelector(getEvents, events =>
  Lodash.get(events, 'selectedDate', '')
);

export const getOccasionTypesAllIds = createSelector(getEvents, events =>
  Lodash.get(events, ['occasionTypes', 'allIds'], [])
);

export const getOccasionTypesById = createSelector(getEvents, events =>
  Lodash.get(events, ['occasionTypes', 'byId'], {})
);

export const getIsLoading = createSelector(getEvents, events =>
  Lodash.get(events, ['isLoading'], false)
);

export const getIsError = createSelector(getEvents, events =>
  Lodash.get(events, ['isError'], false)
);

const getOccasions = createSelector(getEvents, events =>
  Lodash.get(events, 'occasions', {})
);

const getOccasionsAllIds = createSelector(getOccasions, occasions =>
  Lodash.get(occasions, ['allIds'], [])
);

export const getOccasionsById = createSelector(getOccasions, occasions =>
  Lodash.get(occasions, ['byId'], {})
);

export const getOccasionsRsvps = createSelector(getEvents, occasionsRsvps =>
  Lodash.get(occasionsRsvps, ['occasionsRsvps'], [])
);

export const getOccasionRsvps = createSelector(getEvents, events =>
  Lodash.get(events, 'occasionsRsvps', [])
);
export const getSelectedGrouping = createSelector(getEvents, events =>
  Lodash.get(events, 'selectedGrouping', {})
);

export const getQueriedGrouping = createSelector(getEvents, events => {
  return Lodash.get(events, ['queriedGroupings'], {});
});

export const getQueriedLocations = createSelector(getEvents, events => {
  return Lodash.get(events, ['queriedLocations'], []);
});

export const getLastSelectedGroupingsByLocation = createSelector(
  getEvents,
  events => {
    return Lodash.get(events, ['lastSelectedGroupingsByLocation'], {});
  }
);

const getOccasionsForGrouping = createSelector(
  getSelectedGrouping,
  getOccasionsAllIds,
  getOccasionsById,
  (selectedGrouping, occasionsAllIds, occasionsById) => {
    const { idGrouping } = selectedGrouping;
    const list = occasionsAllIds.reduce((acc, id) => {
      if (occasionsById[id].groupingId === idGrouping) {
        acc.push(id);
      }
      return acc;
    }, []);

    return list.sort((a, b) => {
      return occasionsById[a].startEpoch - occasionsById[b].startEpoch;
    });
  }
);

export const getGroupingMenuEvents = createSelector(
  getOccasionsForGrouping,
  getOccasionsById,
  getOccasionTypesById,
  (occasionsForSelectedGrouping, occasionById, occasionTypesById) => {
    return occasionsForSelectedGrouping.filter(id => {
      const { primaryOccasionTypeId } = occasionById[id];
      if (!Lodash.isEmpty(occasionTypesById) && primaryOccasionTypeId) {
        return (
          occasionTypesById[primaryOccasionTypeId] &&
          occasionTypesById[primaryOccasionTypeId].isInMenu
        );
      }
      return false;
    });
  }
);

export const getGroupingMenuEventsForWeek = createSelector(
  getGroupingMenuEvents,
  getOccasionsById,
  (menuOccasions, occasionsById) => {
    const lastDate = Moment().add(7, 'days').startOf('day').unix();
    return menuOccasions.filter(id => {
      const e = occasionsById[id];
      const { startEpoch } = e;

      return startEpoch <= lastDate;
    });
  }
);

export const getLastListStartForGrouping = createSelector(
  getQueriedGrouping,
  getSelectedGrouping,
  (queriedGroupings, selectedGrouping) => {
    return Lodash.get(
      queriedGroupings,
      [selectedGrouping.idGrouping, 'lastListStartEpoch'],
      0
    );
  }
);

export const getLastCallTimeGrouping = createSelector(
  getQueriedGrouping,
  getSelectedGrouping,
  (queriedGroupings, selectedGrouping) => {
    return Lodash.get(
      queriedGroupings,
      [selectedGrouping.idGrouping, 'lastCallTime'],
      false
    );
  }
);
export const getGroupingListedEvents = createSelector(
  getOccasionsForGrouping,
  getOccasionsById,
  getOccasionTypesById,
  getLastListStartForGrouping,
  getLastCallTimeGrouping,
  (
    occasionsForSelectedGrouping,
    occasionById,
    occasionTypesById,
    lastListStartEpoch,
    lastCallTime
  ) => {
    return occasionsForSelectedGrouping.filter(id => {
      const { primaryOccasionTypeId, startEpoch, createdOn, endEpoch } =
        occasionById[id];

      const primaryOccasionType = Lodash.get(
        occasionTypesById,
        primaryOccasionTypeId,
        null
      );
      const createdOnEpoch = Moment(createdOn).unix();
      const eventWasCreatedAfterLastEventFetch = createdOnEpoch >= lastCallTime;
      if (
        primaryOccasionType &&
        (startEpoch <= lastListStartEpoch ||
          eventWasCreatedAfterLastEventFetch) &&
        endEpoch >= Moment().unix()
      ) {
        return primaryOccasionType.isInList
          ? true
          : primaryOccasionType.isInMenu &&
              DateUtils.isWeekend(Moment.unix(startEpoch));
      }
      return false;
    });
  }
);

export const getGroupingHasMore = createSelector(
  getQueriedGrouping,
  getSelectedGrouping,
  (queriedGroupings, selectedGrouping) => {
    return Lodash.get(
      queriedGroupings,
      [selectedGrouping.idGrouping, 'hasMore'],
      false
    );
  }
);

export const getSelectedWeek = createSelector(getEvents, events =>
  Lodash.get(events, 'selectedWeek', 0)
);

export const getSelectedMenuWeek = createSelector(getEvents, events =>
  Lodash.get(events, 'selectedMenuWeek', 0)
);

export const getHospitalityCategories = createSelector(getEvents, events =>
  Lodash.get(events, 'hospitalityCategories', [])
);

export const getTrackMultisaveError = createSelector(getEvents, events =>
  Lodash.get(events, 'trackMultiSaveError', false)
);
