export const MeetingSummaryTypes = {
  BOOKING: 'Booking',
  EDIT: 'Edit',
  CANCELLATION: 'Cancellation',
  CANCELLATION_CONFIRMATION: 'Cancellation_confirmation',
  CONFIRMATION: 'Confirmation',
  UNEDITABLE: 'UnEditable',
  UNEDITABLE_WITH_ERROR: 'UnEditable_With_Error',
};

export const MESSAGE_USER_DOES_NOT_OWN_THIS_BOOKING =
  'User does not own booking and has no permissions to delete booking.';

export const transitionStyles = {
  entering: {
    transform: 'translate(0px, 66px)',
    transition: 'transform 225ms cubic-bezier(0, 0, 0.2, 1) 0ms',
  },
  entered: {
    transform: 'translate(0px, 0px)',
    transition: 'transform 225ms cubic-bezier(0, 0, 0.2, 1) 0ms',
  },
  exiting: {
    transform: 'translate(0px, 66px)',
    transition: 'transform 225ms cubic-bezier(0, 0, 0.2, 1) 0ms',
  },
  exited: {
    display: 'none',
    transition: 'transform 225ms cubic-bezier(0, 0, 0.2, 1) 0ms',
  },
};
