import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import Lodash from 'lodash';

import { colors, breakpoints } from '../../../Core/Theme';

import SubmitButton from '../SubmitButton';
import BaseButton from '../BaseButton';

const FieldInput = props => {
  const {
    label,
    value,
    onChange,
    inputClasses,
    datatestid,
    inputProps,
    errorMessage,
    hasError,
    classes,
    InputLabelProps,
    className,
    isLabelHidden,
    disabled,
    InputProps,
    multiline,
    inputRef,
    classNameInput,
    variant,
    actions,
    actionsClassName,
    isLabelUppercase,
    isRequired,
    prefix,
    ...rest
  } = props;
  const formLabelRootClass = Lodash.get(
    InputLabelProps,
    ['FormLabelClasses', 'root'],
    ''
  );
  const isInline = variant === 'inline';
  const isBoxed = variant === 'boxed';

  const handleOnChange = e => {
    const inputValue = e.target.value;
    return onChange(inputValue);
  };

  const field = (
    <TextField
      label={label}
      value={value}
      onChange={handleOnChange}
      margin="normal"
      {...rest}
      multiline={multiline}
      fullWidth
      classes={inputClasses}
      disabled={disabled}
      className={clsx(
        className,
        hasError && !isBoxed && classes.inputError,
        disabled && classes.meetingInputFieldDisabled,
        !isBoxed && classes.meetingInputField,
        isInline && classes.inlineField,
        prefix && classes.textFieldWhenPrefixed
      )}
      inputProps={{
        ...inputProps,
        datatestid,
      }}
      /* eslint-disable react/jsx-no-duplicate-props */
      InputProps={{
        classes: {
          input: clsx(
            classes.inputProps,
            multiline && classes.multilineInput,
            classNameInput,
            isInline && classes.inlineInput,
            isBoxed && classes.boxedInput,
            hasError && isBoxed && classes.inputErrorBoxed,
            prefix && classes.prefixedInput
          ),
        },

        disableUnderline: true,
        ...InputProps,
      }}
      InputLabelProps={{
        shrink: true,
        datatestid: `${datatestid}_label`,
        FormLabelClasses: {
          root: clsx(
            classes.label,
            hasError && classes.labelError,
            !hasError && classes.labelBlack,
            (isLabelHidden || prefix) && classes.inputLabelHidden,
            formLabelRootClass,
            isBoxed && classes.boxedLabel,
            isBoxed && disabled && classes.labelDisabled,
            isLabelUppercase && classes.uppercase,
            isRequired && classes.required
          ),
        },
      }}
      inputRef={inputRef}
    />
  );

  const errors = errorMessage && hasError && (
    <div
      className={clsx(
        classes.requiredErrorMessage,
        isBoxed && classes.requiredErrorMessageBoxed
      )}
    >
      {errorMessage}
    </div>
  );

  const actionComponents = actions.length ? (
    <div className={clsx(classes.actions, actionsClassName)}>
      {actions.map((a, idx) => {
        const actionVariant = Lodash.get(a, 'variant', '');
        if (actionVariant === 'submit') {
          return (
            <SubmitButton
              {...a.buttonProps}
              className={clsx(idx && classes.actionItem)}
            />
          );
        }
        return (
          <BaseButton
            {...a.buttonProps}
            className={clsx(idx && classes.actionItem)}
          />
        );
      })}
    </div>
  ) : null;

  if (prefix) {
    return (
      <>
        <div
          className={clsx(
            classes.label,
            hasError && classes.labelError,
            !hasError && classes.labelBlack,
            isLabelHidden && classes.inputLabelHidden,
            formLabelRootClass,
            isBoxed && classes.boxedLabel,
            isBoxed && disabled && classes.labelDisabled,
            isLabelUppercase && classes.uppercase,
            prefix && classes.labelWhenPrefix,
            isRequired && classes.required
          )}
        >
          {label}
        </div>
        <div>
          <div className={classes.inputWithPrefix}>
            <div className={classes.prefix}>{prefix}</div>
            <div className={classes.inputWithErrors}>
              {field}
              {errors}
            </div>
          </div>
        </div>
        {actionComponents}
      </>
    );
  }

  return (
    <>
      {field}
      {errors}
      {actions}
    </>
  );
};

const styles = theme => ({
  requiredErrorMessage: {
    fontFamily: 'VerlagLight',
    fontSize: 12,
    color: colors.palette.error.main,
    paddingTop: 2,
  },
  requiredErrorMessageBoxed: {
    paddingTop: 0,
    marginTop: -3,
  },
  label: {
    fontSize: 16,
    fontFamily: 'VerlagBold',
  },
  labelError: {
    color: `${colors.palette.error.main} !important`,
  },
  inputError: {
    borderBottom: `1px solid ${colors.palette.error.main} !important`,
  },
  inputErrorBoxed: {
    border: `1px solid ${colors.palette.error.main} !important`,
  },
  labelBlack: {
    color: 'black  !important',
  },
  boxedLabel: {
    lineHeight: '10px',
  },
  inputProps: {
    fontFamily: 'VerlagLight',
    fontSize: 18,
    color: 'black',
    lineHeight: '20px',
  },
  multilineInput: {
    fontFamily: 'VerlagLight',
    fontSize: 18,
    color: 'black',
    lineHeight: '19px',
  },
  meetingInputField: {
    borderBottom: '1px solid #000000',
    fontFamily: 'VerlagLight',
    marginTop: 0,
    marginBottom: 0,
    fontSize: 18,
    color: colors.dark,
  },
  meetingInputFieldDisabled: {
    borderBottom: 'none !important',
  },
  inputLabelHidden: {
    position: 'absolute !important',
    height: 1,
    width: 1,
    overflow: 'Hidden',
    clip: 'rect(1px, 1px, 1px, 1px)',
    whiteSpace: 'nowrap',
  },
  inlineField: {
    border: `solid 1px ${colors.middle}`,
    boxShadow: '0 0 4px 1px rgba(0, 0, 0, 0.1)',
    borderRadius: 3,
    height: 28,
    [theme.breakpoints.up(breakpoints.MOBILE)]: {
      height: 32,
    },
  },
  inlineInput: {
    marginTop: -16,
    fontFamily: 'VerlagBold',
    fontSize: 20,
    paddingTop: 2,
    paddingLeft: 5,
    [theme.breakpoints.up(breakpoints.MOBILE)]: {
      fontSize: 24,
    },
  },
  boxedInput: {
    fontFamily: 'VerlagBook',
    fontSize: 16,
    paddingTop: 9,
    paddingBottom: 9,
    paddingLeft: 10,
    border: `solid 1px ${colors.middle}`,
    borderRadius: 4,
  },
  actions: {
    display: 'flex',
    // justifyContent: 'space-between',
    marginRight: -2,
    marginTop: 4,
    position: 'absolute',
    zIndex: 2,
  },
  actionItem: {
    marginLeft: 5,
  },
  labelDisabled: {
    color: `${colors.darkGray} !important`,
  },
  uppercase: {
    textTransform: 'uppercase',
  },
  inputWithPrefix: {
    display: 'flex',
  },
  inputWithErrors: {
    width: '100%',
  },
  prefix: {
    marginRight: 7,
    marginTop: 14,
    fontWeight: 'bold',
  },
  prefixedInput: {
    marginTop: -13,
  },
  labelWhenPrefix: {
    fontSize: 12,
    marginTop: 16,
    marginBottom: 3,
  },
  required: {
    '&::after': {
      content: '" *"',
    },
  },
  textFieldWhenPrefixed: {
    marginTop: 0,
  },
});
FieldInput.defaultProps = {
  value: '',
  label: '',
  inputClasses: {},
  datatestid: 'field',
  inputProps: {},
  InputLabelProps: {},
  hasError: false,
  errorMessage: '',
  isLabelHidden: false,
  disabled: false,
  InputProps: {},
  multiline: false,
  inputRef: null,
  classNameInput: null,
  actions: [],
  actionsClassName: null,
  variant: 'boxed',
  isLabelUppercase: false,
  prefix: undefined,
  isRequired: false,
};

FieldInput.propTypes = {
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  onChange: PropTypes.func.isRequired,
  inputClasses: PropTypes.object,
  datatestid: PropTypes.string,
  inputProps: PropTypes.object,
  InputProps: PropTypes.object,
  classes: PropTypes.object.isRequired,
  InputLabelProps: PropTypes.object,
  hasError: PropTypes.bool,
  errorMessage: PropTypes.string,
  isLabelHidden: PropTypes.bool,
  isLabelUppercase: PropTypes.bool,
  disabled: PropTypes.bool,
  multiline: PropTypes.bool,
  inputRef: PropTypes.object,
  classNameInput: PropTypes.string,
  variant: PropTypes.string,
  actions: PropTypes.arrayOf(PropTypes.object),
  actionsClassName: PropTypes.string,
  prefix: PropTypes.string,
  isRequired: PropTypes.bool,
};

export default withStyles(styles)(FieldInput);
