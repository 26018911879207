import typeToReducer from 'type-to-reducer';
import { get, uniqBy } from 'lodash';
import * as types from './actionTypes';
import { NormObjCstr, normalizeMessages } from './schema';

/* 
queriedLocations: { 
  sfid = 1
}

createdBy: {
  sfid: {}
}
*/

export const initialState = {
  selectedLocation: {},
  messages: new NormObjCstr({}, []),
  createdBy: {},
  hasError: false,
  isLoading: false,
  hasMore: true,
  offset: 0,
  queriedLocations: {},
};

export const onSelectLocation = (state, action) => {
  const objSelectedLocation = get(action, ['payload'], {});

  return {
    ...state,
    selectedLocation: objSelectedLocation,
  };
};

export const getMessages = (state, action) => {
  const payload = get(action, ['payload'], {});
  const locationSfIds = get(action, ['meta', 'locationSfIds'], []);
  const fetchedMessages = get(payload, ['messages'], []);
  const total = get(payload, ['total'], 0);
  const existingOffset = get(state, ['offset'], 0);
  const itemsFetched = fetchedMessages.length;
  const newOffset = existingOffset + itemsFetched;

  const hasMore = !(newOffset >= total);

  const locationSfIdsTransformed = locationSfIds.reduce((acc, sfId) => {
    acc[sfId] = true;
    return acc;
  }, {});

  const existingQueriedLocations = get(state, ['queriedLocations'], {});
  const newQueriedLocations = {
    ...existingQueriedLocations,
    ...locationSfIdsTransformed,
  };

  if (!itemsFetched) {
    return {
      ...state,
      offset: newOffset,
      isLoading: false,
      hasMore,
      queriedLocations: newQueriedLocations,
    };
  }

  const { messages, createdBy } = normalizeMessages(fetchedMessages);

  const existingAllIds = get(state, ['messages', 'allIds'], []);
  const existingById = get(state, ['messages', 'byId'], {});
  const existingCreatedBy = get(state, ['createdBy'], {});

  const newById = {
    ...existingById,
    ...messages.byId,
  };

  const newAllIds = uniqBy(existingAllIds.concat(messages.allIds));

  const newCreatedBy = {
    ...existingCreatedBy,
    ...createdBy,
  };

  return {
    ...state,
    messages: new NormObjCstr(newById, newAllIds),
    createdBy: newCreatedBy,
    isLoading: false,
    hasMore,
    offset: newOffset,
    queriedLocations: newQueriedLocations,
  };
};

export const createMessage = (state, action) => {
  const createdMessage = get(action, ['payload'], []);

  const { messages, createdBy } = normalizeMessages(createdMessage);

  const existingAllIds = get(state, ['messages', 'allIds'], []);

  const existingById = get(state, ['messages', 'byId'], {});

  const existingCreatedBy = get(state, ['createdBy'], {});

  const newById = {
    ...existingById,
    ...messages.byId,
  };

  const newCreatedBy = {
    ...existingCreatedBy,
    ...createdBy,
  };

  return {
    ...state,
    messages: new NormObjCstr(newById, messages.allIds.concat(existingAllIds)),
    createdBy: newCreatedBy,
  };
};

export const onLoadingGetMessages = state => {
  return {
    ...state,
    isLoading: true,
  };
};

export const onErrorGetMessages = state => {
  return {
    ...state,
    isLoading: false,
    isError: true,
  };
};

export const resetHasMoreOffset = state => ({
  ...state,
  hasMore: true,
  offset: 0,
});

const communityMessagesReducer = typeToReducer(
  {
    [types.SET_LOCATION]: onSelectLocation,
    [types.GET_MESSAGES]: {
      PENDING: onLoadingGetMessages,
      FULFILLED: getMessages,
      REJECTED: onErrorGetMessages,
    },
    [types.CREATE_MESSAGE]: createMessage,
    [types.RESET_HASMORE_OFFSET]: resetHasMoreOffset,
  },
  initialState
);

export default communityMessagesReducer;
