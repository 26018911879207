import { createAction } from 'redux-actions';
import * as types from './actionTypes';
import { fetchReservations } from '../../../Core/Api';
import { fetchHandlerActions } from '../../../Core/Utils/fetch';

export const addReservations = createAction(
  types.LOAD_RESERVATION,
  fetchHandlerActions(fetchReservations)
);

export const deleteReservation = createAction(types.DELETE_RESERVATION);
export const deleteReservations = createAction(types.DELETE_RESERVATIONS);
export const createReservations = createAction(types.CREATE_RESERVATIONS);
export const updateReservations = createAction(types.UPDATE_RESERVATION);
