import { createAction } from 'redux-actions';
import * as types from './actionTypes';

import { fetchHandlerActions } from '../../../Core/Utils/fetch';
import {
  fetchOccasionTypes,
  getOccasionsPage,
  getUserOccasionRsvpsPage,
  getHospitalityCategories,
} from '../../../Core/Api';

export const setSelectedLocation = loc => ({
  type: types.SET_SELECTED_LOCATION,
  payload: loc,
});

export const setTimeZone = createAction(types.SET_TIMEZONE);

export const setSelectedDate = createAction(types.SET_SELECTED_DATE);

export const setSelectedMenuWeek = createAction(types.SET_SELECTED_MENU_WEEK);

export const setSelectedEvent = createAction(types.SET_SELECTED_EVENT);

export const addOccasions = createAction(
  types.ADD_OCCASIONS,
  fetchHandlerActions(getOccasionsPage),
  (
    limit,
    offset,
    groupingId,
    locationSfIds,
    startEpoch,
    endEpoch,
    isMenu,
    isList
  ) => ({
    limit,
    offset,
    groupingId,
    locationSfIds,
    startEpoch,
    endEpoch,
    isMenu,
    isList,
  })
);

export const addOccasionTypes = createAction(
  types.ADD_OCCASION_TYPES,
  fetchHandlerActions(fetchOccasionTypes)
);

export const addOccasionRsvps = createAction(
  types.ADD_OCCASION_RSVPS,
  fetchHandlerActions(getUserOccasionRsvpsPage),
  sfId => ({
    sfId,
  })
);

export const addOccasion = createAction(types.ADD_OCCASION);

export const updateOccasion = createAction(types.UPDATE_OCCASION);

export const updateRsvp = createAction(types.UPDATE_RSVP);

export const deleteRsvp = createAction(types.DELETE_RSVP);

export const addRsvp = createAction(types.ADD_RSVP);

export const deleteOccasion = createAction(types.DELETE_OCCASION);

export const addOccasionType = createAction(types.ADD_OCCASION_TYPE);

export const updateOccasionType = createAction(types.UPDATE_OCCASION_TYPE);
export const setSelectedGrouping = createAction(types.SET_SELECTED_GROUPING);

export const addQueriedLocation = createAction(types.ADD_QUERIED_LOCATION);

export const addHospitalityCategories = createAction(
  types.ADD_HOSPITALITY_CATEGORIES,
  fetchHandlerActions(getHospitalityCategories)
);
