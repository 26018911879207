import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

// Styles
import { colors, breakpoints } from '../../../../../../Core/Theme';

// Common Components
import Button from '../../../../../../Components/Common/BaseButton';
import SubmitButton from '../../../../../../Components/Common/SubmitButton';

const MeetingConfirmation = ({
  // eslint-disable-next-line no-unused-vars
  onCancel,
  onComplete,
  classes,
  isSubmitting,
  values,
  messageOverage,
  t,
}) => {
  const { isSuccess } = values;
  return (
    <React.Fragment>
      <div className={classes.summaryContainerConfirmation}>
        <div className={classes.summaryDetails}>
          <div className={classes.confirmationInfoConfirmation}>
            {messageOverage}
          </div>

          <div className={classes.confirmationButtons}>
            <div className={classes.meetingSummaryCancelButton}>
              <Button
                className={classes.buttonCancelReservation}
                name={t('meetingSummary.confirmation_cancel')}
                fullWidth
                onClick={onCancel}
                datatestid="rr_cancel_reservation_button"
              />
            </div>
            <div className={classes.meetingSummarySubmitButton}>
              <SubmitButton
                className={classes.buttonReserveRootConfirmation}
                defaultText={t('meetingSummary.confirmation_continue')}
                isLoading={isSubmitting}
                isSuccess={isSuccess}
                onClick={onComplete}
                fullWidth
                buttonStyle="buttonStyleSmall"
                datatestid="rr_continue_reservation_button"
              />
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

const styles = theme => ({
  confirmationButtons: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
    [theme.breakpoints.up(breakpoints.MOBILE)]: {
      alignItems: 'baseline',
      width: '365px',
      marginRight: '4%',
    },
  },
  confirmationInfoConfirmation: {
    [theme.breakpoints.up(breakpoints.MOBILE)]: {
      marginRight: '5%',
    },
    marginTop: 5,
    marginBottom: 5,
    fontFamily: 'VerlagLight',
  },
  summaryContainerConfirmation: {
    flexDirection: 'column',
    display: 'flex',
    [theme.breakpoints.up(breakpoints.MOBILE)]: {
      position: 'relative',
      zIndex: 10,
    },
  },
  summaryDetails: {
    flexDirection: 'column',
    padding: '20px',
    [theme.breakpoints.up(breakpoints.MOBILE)]: {
      justifyContent: 'space-around',
      alignItems: 'center',
      flexDirection: 'row',
      display: 'flex',
      padding: '7px',
      paddingLeft: '4%',
    },
    backgroundColor: colors.white,
  },
  buttonReserveRootConfirmation: {
    margin: 0,

    [theme.breakpoints.up(breakpoints.MOBILE)]: {
      width: '150px',
    },
  },

  buttonCancelReservation: {
    width: '100%',
  },
  meetingSummarySubmitButton: {
    minWidth: '50%',
    [theme.breakpoints.up(breakpoints.MOBILE)]: {
      width: '150px',
      minWidth: 150,
    },
  },
  meetingSummaryCancelButton: {
    width: 162,
    [theme.breakpoints.up(breakpoints.MOBILE)]: {
      marginRight: '25px',
      maxHeight: '40px',
      width: 150,
    },
  },
});

MeetingConfirmation.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onComplete: PropTypes.func.isRequired,
  classes: PropTypes.shape.isRequired,
  values: PropTypes.shape.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  messageOverage: PropTypes.element.isRequired,
  t: PropTypes.func.isRequired,
};
export default withTranslation()(withStyles(styles)(MeetingConfirmation));
