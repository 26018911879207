const colors = {
  /**
   * @deprecated Try to restructure the colors in a Material Style palette. {@link https://material-ui.com/customization/default-theme/?expand-path=$.palette}
   */
  black: '#000000',
  /**
   * @deprecated Try to restructure the colors in a Material Style palette. {@link https://material-ui.com/customization/default-theme/?expand-path=$.palette}
   */
  blackOpacityDark: 'rgba(0, 0, 0, 0.8)',
  /**
   * @deprecated Try to restructure the colors in a Material Style palette. {@link https://material-ui.com/customization/default-theme/?expand-path=$.palette}
   */
  blackOpacityLight: 'rgba(0, 0, 0, 0.65)',
  /**
   * @deprecated Try to restructure the colors in a Material Style palette. {@link https://material-ui.com/customization/default-theme/?expand-path=$.palette}
   */
  blackOpacityLightest: 'rgba(0, 0, 0, 0.20)',
  /**
   * @deprecated Try to restructure the colors in a Material Style palette. {@link https://material-ui.com/customization/default-theme/?expand-path=$.palette}
   */
  white: '#FFFFFF',
  /**
   * @deprecated Try to restructure the colors in a Material Style palette. {@link https://material-ui.com/customization/default-theme/?expand-path=$.palette}
   */
  darkest: '#444444',
  /**
   * @deprecated Try to restructure the colors in a Material Style palette. {@link https://material-ui.com/customization/default-theme/?expand-path=$.palette}
   */
  dark: '#898989',
  /**
   * @deprecated Try to restructure the colors in a Material Style palette. {@link https://material-ui.com/customization/default-theme/?expand-path=$.palette}
   */
  middle: '#D8D8D8',
  /**
   * @deprecated Try to restructure the colors in a Material Style palette. {@link https://material-ui.com/customization/default-theme/?expand-path=$.palette}
   */
  light: '#EEEEEE',
  /**
   * @deprecated Try to restructure the colors in a Material Style palette. {@link https://material-ui.com/customization/default-theme/?expand-path=$.palette}
   */
  lightest: '#F8F8F8',
  /**
   * @deprecated Try to restructure the colors in a Material Style palette. {@link https://material-ui.com/customization/default-theme/?expand-path=$.palette}
   */
  alertBackground: '#f0c8c8',
  /**
   * @deprecated Try to restructure the colors in a Material Style palette. {@link https://material-ui.com/customization/default-theme/?expand-path=$.palette}
   */
  alertMessage: '#eb0000',
  /**
   * @deprecated Try to restructure the colors in a Material Style palette. {@link https://material-ui.com/customization/default-theme/?expand-path=$.palette}
   */
  whiteSmoke: '#f1f1f1',
  /**
   * @deprecated Try to restructure the colors in a Material Style palette. {@link https://material-ui.com/customization/default-theme/?expand-path=$.palette}
   */
  whiteSmoke2: '#f5f5f5',
  /**
   * @deprecated Try to restructure the colors in a Material Style palette. {@link https://material-ui.com/customization/default-theme/?expand-path=$.palette}
   */
  gray: '#C9C9C9',
  /**
   * @deprecated Try to restructure the colors in a Material Style palette. {@link https://material-ui.com/customization/default-theme/?expand-path=$.palette}
   */
  darkGray: '#767676',
  /**
   * @deprecated Try to restructure the colors in a Material Style palette. {@link https://material-ui.com/customization/default-theme/?expand-path=$.palette}
   */
  mediumGray: '#717171',
  /**
   * @deprecated Try to restructure the colors in a Material Style palette. {@link https://material-ui.com/customization/default-theme/?expand-path=$.palette}
   */
  subtleGray: '#B4B4B4',
  /**
   * @deprecated Try to restructure the colors in a Material Style palette. {@link https://material-ui.com/customization/default-theme/?expand-path=$.palette}
   */
  green: '#0d9e08',
  /**
   * @deprecated Try to restructure the colors in a Material Style palette. {@link https://material-ui.com/customization/default-theme/?expand-path=$.palette}
   */
  greenLight: '#86ce83',
  /**
   * @deprecated Try to restructure the colors in a Material Style palette. {@link https://material-ui.com/customization/default-theme/?expand-path=$.palette}
   */
  greenLightest: '#b6e1b4',
  /**
   * Follows material style palette structure, custom for text. Try to categorize any colors following the Zeplin style guide and define light/main/dark as needed.
   * {@link https://material-ui.com/customization/default-theme/?expand-path=$.palette}
   */
  textPalette: {
    link: { main: '#115D51' },
  },
  /**
   * Follows material style palette structure. Try to categorize any colors following the Zeplin style guide and define light/main/dark as needed.
   * {@link https://material-ui.com/customization/default-theme/?expand-path=$.palette}
   */
  palette: {
    primary: {
      lightest: '#013E3F19',
      light: '#3f6d6e',
      main: '#013e3f',
      dark: '#002526',
    },
    secondary: {
      lightest: '#fee58f',
      light: '#fde283',
      main: '#FDD344',
      dark: '#E4C96A',
    },
    secondary2: {
      light: '#F9F7F3',
      main: '#F3EEE7',
      dark: '#E7DED0',
      darkest: '#DACBB6',
    },
    tertiary1: {
      main: '#e5744b',
    },
    tertiary2: {
      main: '#003459',
    },
    tertiary3: {
      main: '#d7dd95',
    },
    error: {
      main: '#eb0000',
      light: '#f0c8c8',
    },
    background: {
      paper: '#FFFFFF',
    },
  },
};

export default colors;
