export interface CommunityMessageDto {
  idMessage: number;
  createdBy: {
    idUser: string;
    firstName: string;
    lastName: string;
    isFirstLogin: boolean;
    name: string;
    sfId: string;
  };
  createdBySfId: string;
  criteria: {
    idCriteria: number;
    referenceId: string;
    type: CommunityMessageDtoCriteriaTypes;
  }[];
  createdOn: Date;
  body: string;
  override: boolean;
}

export enum CommunityMessageDtoCriteriaTypes {
  LOCATION_SFID = 'location_sfid',
}
