import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { colors } from '../../../Core/Theme';
import Text from '../Text';
import TextButton from '../TextButton';

const InfoBox = props => {
  const {
    classes,
    title,
    descriptions,
    className,
    withAction,
    actionText,
    onAction,
    children,
    subHeader,
    disabled,
    actionDescriptions,
  } = props;
  const descriptionsLength = descriptions.length;
  return (
    <div className={clsx(classes.main, className)}>
      <Text text={title.toUpperCase()} className={classes.title} />
      {subHeader && <Text text={subHeader} className={classes.subHeader} />}

      <div className={classes.descriptions}>
        {descriptions.map((d, idx) => (
          <Text
            key={d}
            text={d}
            className={clsx(
              classes.description,
              idx === descriptionsLength - 1 && classes.lastDescription
            )}
          />
        ))}

        {withAction && !disabled && (
          <TextButton
            onClick={onAction}
            text={actionText}
            className={classes.actionButton}
          />
        )}
      </div>
      {actionDescriptions.map((d, idx) => (
        <TextButton
          key={d.text}
          className={clsx(
            classes.description,
            idx === descriptionsLength - 1 && classes.lastDescription
          )}
          onClick={d.onAction}
          text={d.text}
        />
      ))}
      {children}
    </div>
  );
};

const styles = () => ({
  main: {
    background: colors.white,
    paddingTop: 8,
    paddingLeft: 10,
    paddingRight: 10,
    paddingBottom: 8,
    borderRadius: 4,
  },
  title: {
    fontSize: 14,
    fontFamily: 'VerlagBold',
    display: 'block',
  },
  description: {
    fontSize: 16,
    fontFamily: 'VerlagLight',
    display: 'block',
    paddingBottom: 5,
    textAlign: 'left',
    paddingLeft: 0,
    paddingRight: 0,
  },
  lastDescription: {
    paddingBottom: 0,
  },
  descriptions: {
    paddingTop: 7,
    paddingRight: 25,
    position: 'relative',
  },
  actionButton: {
    position: 'absolute',
    right: 0,
    top: 7,
  },
  subHeader: {
    fontSize: 16,
    fontFamily: 'VerlagBold',
    display: 'block',
    paddingTop: 2,
    paddingRight: 25,
  },
});
InfoBox.defaultProps = {
  title: '',
  descriptions: [],
  className: '',
  withAction: false,
  actionText: '',
  onAction: () => {},
  subHeader: '',
  disabled: false,
  actionDescriptions: [],
  children: undefined,
};

InfoBox.propTypes = {
  classes: PropTypes.object.isRequired,
  title: PropTypes.string,
  descriptions: PropTypes.arrayOf(PropTypes.string),
  className: PropTypes.string,
  actionText: PropTypes.string,
  onAction: PropTypes.func,
  withAction: PropTypes.bool,
  subHeader: PropTypes.string,
  disabled: PropTypes.bool,
  actionDescriptions: PropTypes.arrayOf(
    PropTypes.shape({ text: PropTypes.string, onAction: PropTypes.func })
  ),
  children: PropTypes.any,
};

export default withStyles(styles)(InfoBox);
