import { useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';

import { Carat, Tooltip } from '../../../Components/Common';
import { colors, breakpoints } from '../../../Core/Theme';
import DeskItem from './DeskItem';

const Office = ({
  classes,
  office,
  desksIdsSelected,
  handleClickDesk,
  isDisabled,
  canSelectDesk,
  editingDesk,
  userReservedDesks,
  tooltipSelectedDeskId,
}) => {
  const { t } = useTranslation();

  const [showDesksList, setDesksList] = useState(editingDesk);

  const desksReservable = office.desks.filter(
    d => !d.isReserved && d.isBookable
  );
  const teammates = office.desks.filter(d => d.isReserved && d.teammate);
  const shouldBeDisabled = desksReservable.length === 0 || isDisabled;
  const selectedInOffice = office.desks.filter(desk =>
    desksIdsSelected.includes(desk.idDesk)
  );

  const handleClickHeader = () => {
    if (!shouldBeDisabled) {
      setDesksList(!showDesksList);
    }
  };

  const hasSelfReservation = office.desks.some(desk =>
    userReservedDesks.includes(desk.idDesk)
  );

  return (
    <div
      className={clsx(
        classes.wrapper,
        !shouldBeDisabled && classes.wrapperEnabled
      )}
    >
      <div
        className={clsx(
          classes.header,
          !shouldBeDisabled && classes.headerEnabled
        )}
        onClick={handleClickHeader}
        aria-hidden="true"
      >
        <div className={classes.title}>
          <div
            className={clsx(
              classes.officeName,
              shouldBeDisabled && classes.officeNameDisabled
            )}
          >
            {office.parentOfficeName}
          </div>
          {!shouldBeDisabled && teammates.length > 0 && (
            <div className={classes.teammates}>
              {t('deskFloorPlan.deskReservation.teammates')}
              <span className={classes.teammatesNumber}>
                {teammates.length}
              </span>
            </div>
          )}
        </div>
        {hasSelfReservation ? (
          <span className={classes.existingDesksReservationsDesktop}>
            {t('deskFloorPlan.deskReservation.existing_reservation')}
          </span>
        ) : null}
        <div className={classes.actions}>
          <div
            className={clsx(
              classes.reservableDesks,
              shouldBeDisabled && classes.reservableDesksDisabled
            )}
          >
            {t('deskFloorPlan.deskReservation.desksAvailable', {
              available: desksReservable.length,
              total: office.desks.length,
            })}
          </div>
          <button
            type="button"
            className={classes.caratButton}
            onClick={() => setDesksList(!showDesksList)}
          >
            {selectedInOffice.length > 0 &&
              t('deskFloorPlan.deskReservation.selected', {
                count: selectedInOffice.length,
              })}
            <Carat
              size="medium"
              orientation={showDesksList ? 'up' : 'down'}
              className={classes.carat}
            />
          </button>
        </div>
      </div>
      {showDesksList &&
        office.desks
          .sort((a, b) => a.deskNumber - b.deskNumber)
          .map(desk => (
            <Tooltip
              open={tooltipSelectedDeskId === desk?.idDesk}
              placement="left"
              customClasses={{
                tooltip: classes.Tooltip,
              }}
              title={t(
                'deskFloorPlan.deskReservation.description_select_another_desk'
              )}
              anchorContent={() => (
                <DeskItem
                  key={desk.idDesk}
                  desk={desk}
                  isSelected={desksIdsSelected.includes(desk.idDesk)}
                  canSelectDesk={canSelectDesk}
                  handleClickDesk={handleClickDesk}
                />
              )}
            />
          ))}
    </div>
  );
};

const styles = theme => ({
  wrapper: {
    backgroundColor: colors.white,
    boxShadow: '0 0 4px 0 rgba(61, 50, 50, 0.25)',
    borderRadius: 3,
  },
  wrapperEnabled: {
    '&:hover': {
      boxShadow: '0 0 7px 1px rgba(61, 50, 50, 0.25)',
    },
  },
  header: {
    padding: '20px 20px 16px',
  },
  headerEnabled: {
    cursor: 'pointer',
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  officeName: {
    fontSize: 20,
    fontFamily: 'VerlagBold',
    [theme.breakpoints.up(breakpoints.MOBILE)]: {
      fontSize: 24,
    },
  },
  officeNameDisabled: {
    color: colors.darkGray,
  },
  teammates: {
    fontSize: 18,
    fontFamily: 'VerlagBold',
  },
  teammatesNumber: {
    backgroundColor: colors.palette.primary.main,
    color: colors.white,
    borderRadius: '50%',
    fontFamily: 'VerlagBold',
    boxShadow: '0 2px 5px 0 rgba(0, 0, 0, 0.17)',
    marginLeft: 11,
    width: 23,
    height: 23,
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  actions: {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'space-between',
    marginTop: 15,
    [theme.breakpoints.up(breakpoints.MOBILE)]: {
      alignItems: 'center',
    },
  },
  reservableDesks: {
    backgroundColor: 'rgba(134, 206, 131, 0.2)',
    color: colors.palette.primary.main,
    fontSize: 18,
    lineHeight: '18px',
    fontFamily: 'VerlagBold',
    borderRadius: 12,
    padding: '3px 8px',
  },
  reservableDesksDisabled: {
    backgroundColor: colors.light,
    color: colors.black,
  },
  carat: {
    marginLeft: 18,
    fill: colors.palette.primary.main,
    '&:hover': {
      fill: colors.palette.primary.light,
    },
  },
  caratButton: {
    cursor: 'pointer',
    background: 'none',
    border: 'none',
    fontSize: 18,
    fontFamily: 'VerlagBook',
  },
  existingDesksReservationsDesktop: {
    display: 'none',
    fontSize: 15,
    fontFamily: 'VerlagBook',
    [theme.breakpoints.up(breakpoints.MOBILE)]: {
      display: 'block',
    },
  },
  Tooltip: {
    width: '160px',
    paddingLeft: 23,
  },
});

Office.defaultProps = {
  editingDesk: null,
  userReservedDesks: [],
  tooltipSelectedDeskId: null,
};

Office.propTypes = {
  classes: PropTypes.object.isRequired,
  office: PropTypes.shape({
    parentOfficeName: PropTypes.string,
    desks: PropTypes.arrayOf(PropTypes.shape({})),
    seats: PropTypes.number,
  }).isRequired,
  desksIdsSelected: PropTypes.arrayOf(PropTypes.number).isRequired,
  handleClickDesk: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool.isRequired,
  canSelectDesk: PropTypes.bool.isRequired,
  editingDesk: PropTypes.shape({}),
  userReservedDesks: PropTypes.arrayOf(PropTypes.shape({})),
  tooltipSelectedDeskId: PropTypes.number,
};

export default withStyles(styles)(Office);
