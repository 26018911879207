import { createAction } from 'redux-actions';
import * as types from './actionTypes';
import { fetchNotifications } from '../../../Core/Api';
import { fetchHandlerActions } from '../../../Core/Utils/fetch';

export const addNotifications = createAction(
  types.LOAD_NOTIFICATIONS,
  fetchHandlerActions(fetchNotifications)
);

export const resetToInitialState = createAction(
  types.RESET_STATE_TO_INITIAL_STATE
);

export const increaseOffsetBy = createAction(types.INCREASE_OFFSET_BY);
