/* eslint-disable jsx-a11y/no-static-element-interactions */
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { withStyles } from '@material-ui/core/styles';
import { colors, breakpoints } from '../../../Core/Theme';

const TabItem = props => {
  const { classes } = props;
  const { isSelected, onClick, children, classNames, className } = props;
  const { selected, unselected } = classNames;
  return (
    <div
      className={clsx(
        classes.container,
        isSelected && (selected || classes.defaultSelected),
        !isSelected && (unselected || classes.defaultUnselected),
        className
      )}
      onClick={onClick}
      onKeyPress={onClick}
      role="tab"
      tabIndex={0}
    >
      {children}
    </div>
  );
};

const styles = theme => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    paddingTop: '4px',
    [theme.breakpoints.down(breakpoints.MOBILE)]: {
      whiteSpace: 'nowrap',
      marginRight: '25px',
    },
  },
  defaultSelected: {
    borderBottom: `5px solid ${colors.palette.primary.main}`,
    color: colors.palette.primary.main,
    height: '60px',
    fontSize: '20px',
    fontFamily: 'VerlagBook',
    [theme.breakpoints.down(breakpoints.MOBILE)]: {
      height: '40px',
      fontSize: '17px',
    },
  },
  defaultUnselected: {
    height: '60px',
    fontSize: '20px',
    fontFamily: 'VerlagBook',
    [theme.breakpoints.up(breakpoints.MOBILE)]: {
      borderBottom: `1px solid ${colors.middle}`,
    },
    [theme.breakpoints.down(breakpoints.MOBILE)]: {
      height: '40px',
      fontSize: '17px',
    },
  },
});

TabItem.defaultProps = {
  classNames: {},
  className: '',
};
TabItem.propTypes = {
  children: PropTypes.string.isRequired,
  isSelected: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  classNames: PropTypes.object,
  className: PropTypes.string,
};

export default withStyles(styles)(TabItem);
