/* eslint-disable @typescript-eslint/no-use-before-define */
import { useReducer, useEffect } from 'react';
import Slide from '@material-ui/core/Slide';
import { withStyles } from '@material-ui/core/styles';
import get from 'lodash/get';
import clsx from 'clsx';
import PropTypes from 'prop-types';

const initialPopupBannerState = {
  timeout: { enter: 700, exit: 625 },
  in: false,
};

const POPUP_BANNER_TYPE_STATE = {
  APPEAR: 'APPEAR',
  DISSAPEAR: 'DISSAPEAR',
};

const poupBannerState = {
  [POPUP_BANNER_TYPE_STATE.APPEAR]: {
    direction: 'up',
    in: true,
  },
  [POPUP_BANNER_TYPE_STATE.DISSAPEAR]: {
    direction: 'down',
    in: false,
  },
};

const popupBannerReducer = (state, action) => {
  const strType = get(action, ['type'], null);
  switch (strType) {
    case POPUP_BANNER_TYPE_STATE.APPEAR:
      return { ...state, ...poupBannerState[strType] };
    case POPUP_BANNER_TYPE_STATE.DISSAPEAR:
      return { ...state, ...poupBannerState[strType] };
    default:
      return { ...state };
  }
};

const getTransitionStyle = (isShowing, classes) => {
  if (!isShowing) {
    return classes.dissapearContainer;
  }
  return classes.appearContainer;
};

const PopupBanner = ({ appear, classes, children, className }) => {
  const [slideAnimation, dispatch] = useReducer(
    popupBannerReducer,
    initialPopupBannerState
  );

  useEffect(() => {
    triggerBannerDisplay(appear);
  }, [appear]);

  const triggerBannerDisplay = isShowing => {
    if (isShowing) {
      return dispatch({ type: POPUP_BANNER_TYPE_STATE.APPEAR });
    }
    return dispatch({ type: POPUP_BANNER_TYPE_STATE.DISSAPEAR });
  };

  return (
    <div
      className={clsx(
        classes.container,
        getTransitionStyle(appear, classes),
        className
      )}
    >
      <Slide {...slideAnimation}>{children}</Slide>
    </div>
  );
};

const style = () => ({
  container: {
    zIndex: 10,
  },
  appearContainer: {
    height: 'auto',
    transition: 'height 0.15s ease-in',
  },
  dissapearContainer: {
    height: 0,
    transition: 'height 0.15s ease-out',
  },
});

PopupBanner.propTypes = {
  appear: PropTypes.bool.isRequired,
};

export default withStyles(style)(PopupBanner);
