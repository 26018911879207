import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { Icons } from '../../../resources';
import { colors } from '../../../Core/Theme';

const Carat = ({
  size,
  classes,
  orientation,
  isError,
  className,
  ...props
}) => {
  return (
    <Icons.Carat
      className={clsx(
        className,
        classes[size],
        classes[orientation],
        isError && classes.error
      )}
      {...props}
    />
  );
};

const styles = () => ({
  small: {
    width: 10,
    height: 6,
    transition: '200ms',
  },
  mediumSmall: {
    transition: '200ms',
  },
  medium: {
    width: 15,
    height: 10,
    transition: '200ms',
  },
  right: {
    transform: 'rotate(-90deg)',
    transition: '200ms',
  },
  up: {
    transform: 'rotate(180deg)',
    transition: '200ms',
  },
  down: {
    transform: 'rotate(0deg)',
    transition: '200ms',
  },
  error: {
    fill: colors.palette.error.main,
  },
});

Carat.defaultProps = {
  size: 'small',
  orientation: 'down',
  isError: false,
};

Carat.propTypes = {
  classes: PropTypes.object.isRequired,
  size: PropTypes.string,
  orientation: PropTypes.string,
  isError: PropTypes.bool,
};

export default withStyles(styles)(Carat);
