import clsx from 'clsx';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core';

import { colors } from '../../../Core/Theme';
import { Avatar } from '../../../Components/Common';
import { Icons } from '../../../resources';

const DeskSelection = ({
  classes,
  isReserved,
  isBookable,
  avatarUrl,
  nameTeammate,
  isSelected,
}) => {
  const isDisabled =
    !isBookable || (isReserved && !nameTeammate && !isSelected);

  let initials = '';
  if (nameTeammate) {
    const splitedName = nameTeammate.split(' ');
    initials = `${splitedName[0].charAt(0)}${splitedName[1].charAt(0)}`;
  }

  return (
    <div
      className={clsx(
        classes.wrapper,
        isDisabled && classes.wrapperDisabled,
        isSelected && classes.wrapperSelected
      )}
    >
      {isDisabled && <div className={classes.contentDisabled} />}
      {isSelected && <Icons.CheckMarkIcon className={classes.checkMarkIcon} />}
      {nameTeammate && (
        <Avatar
          className={classes.avatar}
          src={avatarUrl}
          textDisplay={initials}
        />
      )}
    </div>
  );
};

const styles = () => ({
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '50%',
    width: 30,
    height: 30,
    border: `2px solid ${colors.palette.primary.light}`,
    boxSizing: 'border-box',
    backgroundColor: colors.white,
  },
  wrapperDisabled: {
    padding: 3,
    border: '1px solid #d0d0d0',
  },
  wrapperSelected: {
    boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.17)',
    border: `2px solid ${colors.palette.primary.main}`,
    backgroundColor: colors.white,
  },
  contentDisabled: {
    display: 'block',
    backgroundColor: '#d8d8d8',
    width: '100%',
    height: '100%',
    borderRadius: '50%',
  },
  avatar: {
    backgroundColor: colors.palette.primary.main,
    color: colors.white,
    fontSize: 14,
    width: 30,
    height: 30,
    lineHeight: 1,
  },
  checkMarkIcon: {
    width: 14,
    height: 14,
    '& g': {
      fill: colors.palette.primary.main,
    },
  },
});

DeskSelection.defaultProps = {
  avatarUrl: null,
  nameTeammate: null,
};

DeskSelection.propTypes = {
  classes: PropTypes.object.isRequired,
  isBookable: PropTypes.bool.isRequired,
  isReserved: PropTypes.bool.isRequired,
  avatarUrl: PropTypes.string,
  nameTeammate: PropTypes.string,
  isSelected: PropTypes.bool.isRequired,
};

export default withStyles(styles)(DeskSelection);
