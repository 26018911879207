import { useEffect, useState } from 'react';
import { FloorDto } from '../Core/Api';

import { useFloorOptions } from './useFloorOptions';

export const useFloorSelection = (selectedGrouping: {
  locationSfId: string;
}) => {
  const { floorOptions } = useFloorOptions(selectedGrouping);
  const [selectedFloor, setSelectedFloor] = useState<FloorDto>();
  const [isFloorSelectedInitialised, setIsFloorSelectedInitialised] =
    useState(false);

  useEffect(() => {
    if (!floorOptions?.length || !selectedGrouping) {
      return;
    }

    setSelectedFloor(undefined);

    if (floorOptions.length) {
      setSelectedFloor(floorOptions[0].value);
    }
    setIsFloorSelectedInitialised(true);
  }, [floorOptions, selectedGrouping]);

  return {
    selectedFloor,
    floorOptions,
    isFloorSelectedInitialised,
    setSelectedFloor,
  };
};
