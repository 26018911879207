import * as React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';

//  Components
import { SubmitButton } from '../../Components/Common';
import { PasswordReq, PasswordInput } from '../../Components/Auth';

//  Styling
import styles from './styles';

import { submitCode } from './utils';

const handleEnter =
  (setLoading, email, code, newPassword, setSuccess, setDisplayMsg) => e => {
    if (e.nativeEvent.type === 'click' || e.key === 'Enter') {
      submitCode(
        setLoading,
        email,
        code,
        newPassword,
        setSuccess,
        setDisplayMsg
      );
    }
  };

const fakeInputStyle = {
  opacity: 0,
  float: 'left',
  border: 'none',
  height: '0',
  width: '0',
  display: 'none',
};

const SubmitCode = props => {
  const {
    classes,
    isLoading,
    isSuccess,
    setLoading,
    setSuccess,
    setDisplayMsg,
    displayMsg,
    email,
    code,
  } = props;

  const { t } = useTranslation();

  const [newPassword, inputNewPassword] = React.useState('');

  let error = '';
  if (displayMsg !== '') {
    error = <div className={classes.errorMsgCode}>{displayMsg}</div>;
  }
  return (
    <div className={classes.inputsContainerCode}>
      {/* this is a fake input it does nothing except get chrome to not autofill the fields below it */}
      <input
        type="password"
        name="autocomplete-ender"
        autoComplete="new-password"
        tabIndex="-1"
        style={fakeInputStyle}
        aria-hidden
      />
      {error}
      <PasswordInput
        textfieldclass={classes.fieldInputStyleCode}
        placeholder={t('forgotPW.new_password')}
        value={newPassword}
        onChange={e => {
          inputNewPassword(e.target.value);
        }}
        onKeyDown={handleEnter(
          setLoading,
          email,
          code,
          newPassword,
          setSuccess,
          setDisplayMsg
        )}
        label={t('forgotPW.password')}
        id="passwordInput"
      />
      <PasswordReq />

      <SubmitButton
        isLoading={isLoading}
        isSuccess={isSuccess}
        defaultText={t('forgotPW.reset_password')}
        onClick={handleEnter(
          setLoading,
          email,
          code,
          newPassword,
          setSuccess,
          setDisplayMsg
        )}
        disabled={isLoading || isSuccess}
        className={classes.submitButton}
      />
    </div>
  );
};

SubmitCode.defaultProps = {
  email: '',
  code: '',
};

SubmitCode.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  isLoading: PropTypes.bool.isRequired,
  isSuccess: PropTypes.bool.isRequired,
  setLoading: PropTypes.func.isRequired,
  setSuccess: PropTypes.func.isRequired,
  setDisplayMsg: PropTypes.func.isRequired,
  displayMsg: PropTypes.string.isRequired,
  email: PropTypes.string,
  code: PropTypes.string,
};

export default withStyles(styles)(SubmitCode);
