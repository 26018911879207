import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import BoxedTabs from './BoxedTabs';
import TabItem from './TabItem';

const Tabs = props => {
  const {
    legends,
    selectedId,
    onTabClick,
    classes,
    classNames,
    variant,
    tabClassName,
  } = props;

  const getPropsWithoutContainerClass = () => {
    const propsCleaned = {
      ...props,
    };
    if (!!classes && !!classes.container) {
      const boxedClasses = {
        ...classes,
      };
      delete boxedClasses.container;
      propsCleaned.classes = boxedClasses;
    }
    return propsCleaned;
  };

  if (variant === 'boxed') {
    const boxedTabsProps = getPropsWithoutContainerClass();
    return <BoxedTabs {...boxedTabsProps} />;
  }
  const renderLegends = () =>
    legends.map(el => (
      <TabItem
        key={el.id}
        isSelected={el.id === selectedId}
        onClick={() => onTabClick(el.id)}
        classNames={classNames}
        className={tabClassName}
      >
        {el.text}
      </TabItem>
    ));

  return <div className={classes.container}>{renderLegends()}</div>;
};

const styles = () => ({
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    cursor: 'pointer',
  },
});

Tabs.defaultProps = {
  variant: 'default',
  classNames: {},
  tabClassName: '',
};

Tabs.propTypes = {
  legends: PropTypes.arrayOf(PropTypes.object).isRequired,
  selectedId: PropTypes.number.isRequired,
  onTabClick: PropTypes.func.isRequired,
  classNames: PropTypes.object,
  variant: PropTypes.string,
  tabClassName: PropTypes.string,
};

export default withStyles(styles)(Tabs);
