import Account from './Account';
import RoomBooking from './RoomBooking';
import CheckIn from './CheckInActivity';
import PackageAlerts from './PackageAlerts';
import Header from './Header';
import Login from './Login';
import Logout from './Logout';
import Root from './Root';
import ResetPW from './ResetPW';
import ForgotPW from './ForgotPW';
import Settings from './Settings';
import FAQ from './FAQ';
import Home from './Home';
import Onboarding from './Onboarding';
import Contact from './Contact';
import Events from './Events';
import MessageMembers from './MessageMembers';
import Perks from './Perks';
import GroupManagement from './GroupManagement';
import MemberManagement from './MemberManagement';
import DeskUsage from './DeskUsage';
import ManageWorkspaces from './ManageOffices';
import AccountActivity from './AccountActivity';
import AccountOverview from './AccountOverview';
import VisitingMemberReservations from './VisitingMemberReservations';
import ReserveOffice from './ReserveOffice';
import OpenWorkspace from './OpenWorkspace';
import DeskReservationLanding from './DeskReservationLanding';
import DeskReservation from './DeskReservation';
import ManageDesks from './ManageDesks';

export default {
  Header, // Must be exported first for the dummy header to render first int he dom structure
  Account,
  RoomBooking,
  ReserveOffice,
  Login,
  Logout,
  Root,
  ResetPW,
  ForgotPW,
  Settings,
  Onboarding,
  Contact,
  FAQ,
  Home,
  Events,
  CheckIn,
  PackageAlerts,
  MessageMembers,
  Perks,
  GroupManagement,
  MemberManagement,
  DeskUsage,
  ManageWorkspaces,
  AccountActivity,
  AccountOverview,
  OpenWorkspace,
  VisitingMemberReservations,
  DeskReservation,
  DeskReservationLanding,
  ManageDesks,
};
