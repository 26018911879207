import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

import { Icons } from '../../../resources';

const AmenityIcon = ({ amenityName, amenityIconUrl, classes, size }) => {
  if (!amenityName) {
    return null;
  }
  if (!Icons[amenityName] && !amenityIconUrl) {
    return null;
  }
  return (
    <img
      src={Icons[amenityName] ? Icons[amenityName] : amenityIconUrl}
      key={amenityName}
      className={classes[size]}
      alt={amenityName}
    />
  );
};

const styles = () => ({
  small: {
    height: 18,
    paddingRight: 8,
  },
  medium: {
    height: 20,
    paddingRight: 10,
  },
});

AmenityIcon.defaultProps = {
  amenityName: undefined,
  amenityIconUrl: undefined,
  size: 'small',
};

AmenityIcon.propTypes = {
  amenityName: PropTypes.string,
  amenityIconUrl: PropTypes.string,
  size: PropTypes.string,
};

export default withStyles(styles)(AmenityIcon);
