import { useState, useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Lodash from 'lodash';
import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';
import Text from '../Text';
import TextButton from '../TextButton';
import { colors } from '../../../Core/Theme';
import { Icons } from '../../../resources';
import { MAX_IMAGE_SIZE } from '../../../Core/constants';

const createUrlForImage = varValue => {
  if (Lodash.isNil(varValue)) {
    return null;
  }
  return Lodash.isString(varValue) ? varValue : URL.createObjectURL(varValue);
};

const DropZoneInput = ({
  classes,
  classNameDropZoneContainer,
  classNameContainer,
  classNameContainerInfo,
  value,
  onChange,
  disabled,
  label,
  description,
  maxFileSize,
}) => {
  const [image, setImage] = useState(value);
  const { t } = useTranslation();

  const varInternalValue = value || image;

  const onDrop = useCallback(acceptedFiles => {
    const file = Lodash.get(acceptedFiles, [0], null);
    setImage(file);
    onChange(file);
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    disabled,
    onDrop,
  });

  const resetPhoto = () => {
    setImage(null);
    onChange(null);
  };

  const imageSize = Lodash.get(varInternalValue, ['size'], null);
  const imageSrc = createUrlForImage(varInternalValue);

  return (
    <section className={clsx(classes.container, classNameContainer)}>
      <div className={clsx(classes.containerInfo, classNameContainerInfo)}>
        <div className={classes.titleContainer}>
          <Text text={label} className={classes.titleText} />
        </div>
        <aside>
          <Text text={description} />
        </aside>
      </div>

      <div
        {...getRootProps({
          className: clsx(
            classes.dropZoneContainer,
            !imageSrc && classes.dropZoneContainerBorder,
            classNameDropZoneContainer
          ),
        })}
      >
        <input {...getInputProps()} />
        {imageSrc ? (
          <>
            <img
              src={imageSrc}
              alt={t('altTexts.img')}
              className={classes.image}
              width="100%"
              height="100%"
            />
            {imageSize > maxFileSize && (
              <div className={classes.imageError}>
                {t('general.image_size_error')}
              </div>
            )}
          </>
        ) : (
          <>
            <Icons.DropzoneUpload />
            <span className={classes.dragText}>
              {t('dropZone.drag_here')}
              <span className={classes.uploadText}>
                {t('dropZone.upload_file')}
              </span>
            </span>
          </>
        )}
      </div>
      {imageSrc && (
        <div className={classes.actionRemove}>
          <TextButton text={t('dropZone.remove_photo')} onClick={resetPhoto} />
        </div>
      )}
    </section>
  );
};

const styles = () => ({
  imageError: {
    border: `solid 1.5px ${colors.alertBackground}`,
    backgroundColor: colors.white,
    borderRadius: 2,
    fontFamily: 'VerlagBold',
    fontSize: 13,
    color: colors.alertMessage,
    paddingRight: 10,
    paddingLeft: 10,
    paddingTop: 5,
    paddingBottom: 5,
    textAlign: 'left',
    marginTop: 5,
  },
  container: {
    backgroundColor: 'transparent',
  },
  titleContainer: {
    marginBottom: 5,
  },
  titleText: {
    fontFamily: 'VerlagBold',
    fontSize: '12px',
  },
  containerInfo: {
    marginBottom: 10,
  },
  image: {
    cursor: 'pointer',
  },
  actionRemove: {
    textAlign: 'center',
  },
  dropZoneContainer: {
    backgroundColor: 'transparent',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '170px',
  },
  dropZoneContainerBorder: {
    borderStyle: 'dashed',
    borderColor: colors.gray,
  },
  dragText: {
    color: colors.darkest,
    fontFamily: 'VerlagLight',
    fontSize: '16px',
  },
  uploadText: {
    color: colors.palette.primary.main,
    fontFamily: 'VerlagBook',
    fontSize: '16px',
    cursor: 'pointer',
  },
});

DropZoneInput.defaultProps = {
  value: null,
  disabled: false,
  classNameDropZoneContainer: null,
  classNameContainerInfo: null,
  classNameContainer: null,
  label: null,
  description: null,
  maxFileSize: MAX_IMAGE_SIZE,
};

DropZoneInput.propTypes = {
  value: PropTypes.shape({}),
  classNameDropZoneContainer: PropTypes.string,
  classNameContainer: PropTypes.string,
  classNameContainerInfo: PropTypes.string,
  label: PropTypes.string,
  description: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  maxFileSize: PropTypes.number,
  disabled: PropTypes.bool,
};

export default withStyles(styles)(DropZoneInput);
