import React, { useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import { colors, breakpoints } from '../../../Core/Theme';
import { Icons } from '../../../resources';

function arrowGenerator(color) {
  return {
    '&[x-placement*="bottom"] $arrow': {
      left: 0,
      top: 0,
      marginTop: '-0.95em',
      width: '3em',
      height: '1em',
      '&::before': {
        borderWidth: '0 1em 1em 1em',
        borderColor: `transparent transparent ${color} transparent`,
      },
    },
    '&[x-placement*="top"] $arrow': {
      bottom: 0,
      left: 0,
      marginBottom: '-0.95em',
      width: '3em',
      height: '1em',
      backgroundColor: 'red',
      '&::before': {
        borderWidth: '1em 1em 0 1em',
        borderColor: `${color} transparent transparent transparent`,
      },
    },
    '&[x-placement*="right"] $arrow': {
      left: 0,
      marginLeft: '-0.95em',
      height: '3em',
      width: '1em',
      '&::before': {
        borderWidth: '1em 1em 1em 0',
        borderColor: `transparent ${color} transparent transparent`,
      },
    },
    '&[x-placement*="left"] $arrow': {
      right: 0,
      marginRight: '-0.95em',
      height: '3em',
      width: '1em',
      '&::before': {
        borderWidth: '1em 0 1em 1em',
        borderColor: `transparent transparent transparent ${color}`,
      },
    },
  };
}

const TooltipC = props => {
  const {
    title,
    classes,
    iconClass,
    children,
    variant,
    placement,
    anchorContent,
    customClasses,
    interactive,
  } = props;
  const { t } = useTranslation();
  const [arrowRef, setRef] = useState(null);
  const handleArrowRef = node => {
    setRef(node);
  };
  const isHeavy = variant === 'heavy';

  return (
    <Tooltip
      {...props}
      title={
        <React.Fragment>
          {title}
          {children}
          <span
            className={clsx(classes.arrow, customClasses.arrow)}
            ref={handleArrowRef}
          />
        </React.Fragment>
      }
      classes={{
        popper: clsx(classes.arrowPopper, customClasses.arrowPopper),
        tooltip: clsx(
          classes.tooltip,
          isHeavy && classes.heavyTooltip,
          customClasses.tooltip
        ),
        tooltipPlacementBottom: classes.tooltipPlacementBottom,
      }}
      PopperProps={{
        popperOptions: {
          modifiers: {
            arrow: {
              enabled: Boolean(arrowRef),
              element: arrowRef,
            },
          },
        },
      }}
      enterTouchDelay={0}
      leaveTouchDelay={2500}
      leaveDelay={children ? 1500 : 0} // if there are children keep the popper open
      interactive={interactive || !!children}
      placement={placement}
    >
      {anchorContent ? (
        anchorContent()
      ) : (
        <img
          src={Icons.QuestionToolTip}
          className={clsx(classes.tooltipIcon, iconClass)}
          alt={t('altTexts.tooltip_info')}
        />
      )}
    </Tooltip>
  );
};

const styles = theme => ({
  arrow: {
    position: 'absolute',
    [theme.breakpoints.down(breakpoints.MOBILE)]: {
      top: '-10px',
    },
    fontSize: 6,
    width: '3em',
    height: '3em',
    backgroundColor: 'transparent !important',
    '&::before': {
      content: '""',
      margin: 'auto',
      display: 'block',
      width: 0,
      height: 0,
      borderStyle: 'solid',
    },
  },
  tooltipPlacementBottom: {
    [theme.breakpoints.down(breakpoints.MOBILE)]: {
      marginTop: 10,
    },
  },
  arrowPopper: {
    ...arrowGenerator(colors.white, theme),
    opacity: 1,
  },
  tooltipIcon: {
    fill: colors.red,
    height: '16px',
    width: '16px',
    transform: 'translate(0px, 3px)',
    marginLeft: 5,
  },
  tooltip: {
    backgroundColor: colors.white,
    width: 210,
    borderRadius: 3,
    color: colors.black,
    paddingLeft: 10,
    paddingRight: 10,
    paddingBottom: 9,
    paddingTop: 9,
    fontSize: 12,
    boxShadow: '0 0 4px 2px  rgba(0, 0, 0, 0.1)',
    lineHeight: 1.3,
    position: 'relative',
    fontFamily: 'VerlagLight',
  },
  heavyTooltip: {
    width: 210,
    lineHeight: '18px',
    paddingLeft: 15,
    paddingRight: 15,
    paddingBottom: 9,
    paddingTop: 9,
    fontFamily: 'VerlagBook',
  },
});

TooltipC.defaultProps = {
  iconClass: '',
  children: null,
  anchorContent: null,
  variant: 'default',
  placement: 'bottom',
  customClasses: {},
  interactive: false,
};
TooltipC.propTypes = {
  title: PropTypes.string.isRequired,
  iconClass: PropTypes.string,
  children: PropTypes.node,
  variant: PropTypes.string,
  placement: PropTypes.string,
  anchorContent: PropTypes.func,
  interactive: PropTypes.bool,
  customClasses: PropTypes.shape({
    tooltip: PropTypes.string,
    arrow: PropTypes.string,
    arrowPopper: PropTypes.string,
  }),
};

export default withStyles(styles)(TooltipC);
