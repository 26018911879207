import { withStyles, StyleRulesCallback } from '@material-ui/core/styles';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';

import { colors } from '../../../Core/Theme';

type Props = {
  id: string;
  classes: ClassNameMap;
  title: string;
};

const Header = (props: Props) => {
  const { classes, id, title } = props;

  return (
    <a href={`#faq_${id}`} className={classes.title} key={`faq_title_${id}`}>
      {title}
    </a>
  );
};

const styles: StyleRulesCallback<string> = theme => ({
  title: {
    fontFamily: 'VerlagBold',
    color: `${colors.textPalette.link.main} !important`,
    display: 'block',
    paddingTop: 10,
    paddingBottom: 10,
    textDecoration: 'none',
    [theme.breakpoints.up('xs')]: {
      fontSize: 20,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 18,
    },
  },
});

export default withStyles(styles)(Header);
