export const SPLITTER_HOURS_TYPE = {
  HALF: 'half',
  QUARTER: 'quarter',
  HOUR: 'hour',
};

export const SPLITTER_HOURS_VALUES = {
  HALF: 0.5,
  QUARTER: 0.25,
  HOUR: 1,
};

export const FULL_DAY_IN_HOUR = 24;
