import React from 'react';
import PropTypes from 'prop-types';

// Components
import { MapLayerWrapper as Layer } from '../../Common';

const Label = props => {
  const { layout, labelVisible, labelKey, ...rest } = props;

  return (
    <Layer
      key={labelKey}
      {...rest}
      layout={{
        ...layout,
        visibility: labelVisible ? 'visible' : 'none',
      }}
    />
  );
};

Label.propTypes = {
  labelVisible: PropTypes.bool.isRequired,
  layout: PropTypes.object.isRequired,
  labelKey: PropTypes.string.isRequired,
};

export default React.memo(Label);
