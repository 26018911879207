import { withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { getStaticImageUrl } from '../../Core/Utils';
import { S3IconStrings } from '../../resources';
import { Text } from '../Common';
import { breakpoints } from '../../Core/Theme';

const DeskEmptyState = props => {
  const { classes, title, description, customClasses } = props;
  const { t } = useTranslation();

  return (
    <div className={clsx(classes.emptyState, customClasses.emptyState)}>
      <img
        src={getStaticImageUrl(S3IconStrings.deskWaiting1)}
        srcSet={`${getStaticImageUrl(S3IconStrings.deskWaiting2)},
${getStaticImageUrl(S3IconStrings.deskWaiting3)}`}
        alt={t('altTexts.desk_waiting')}
        className={clsx(classes.emptyImage, customClasses.image)}
      />
      <Text
        text={title}
        className={clsx(classes.mediumTitle, customClasses.mediumTitle)}
      />
      <Text
        text={description}
        className={clsx(
          classes.emptyDescription,
          customClasses.emptyDescription
        )}
      />
    </div>
  );
};

const styles = theme => ({
  emptyState: {
    display: 'grid',
    textAlign: 'center',
    paddingTop: 55,
    [theme.breakpoints.up(breakpoints.MOBILE)]: {
      paddingTop: 80,
    },
  },
  emptyImage: {
    margin: 'auto',
    marginBottom: 10,
    height: 100,
    paddingBottom: 10,
    [theme.breakpoints.up(breakpoints.MOBILE)]: {
      paddingBottom: 40,
    },
  },
  mediumTitle: {
    fontSize: 20,
    fontFamily: 'VerlagBold',
    marginBottom: 10,
    [theme.breakpoints.up(breakpoints.MOBILE)]: {
      fontSize: 24,
    },
  },
  emptyDescription: {
    fontSize: 20,
    fontFamily: 'VerlagLight',
    [theme.breakpoints.up(breakpoints.MOBILE)]: {
      fontSize: 18,
    },
  },
});

DeskEmptyState.defaultProps = {
  customClasses: {},
};

DeskEmptyState.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  customClasses: PropTypes.shape({
    image: PropTypes.string,
    mediumTitle: PropTypes.string,
    emptyDescription: PropTypes.string,
    emptyState: PropTypes.string,
  }),
};

export default withStyles(styles)(DeskEmptyState);
