import { UserDto } from './UserDto';
import { SpaceDto } from './SpaceDto';

export interface BookingDto {
  idBooking: number;
  userSfId: string;
  spaceSfId: string;
  title: string;
  description: string;
  startEpoch: number;
  endEpoch: number;
  createdOn: string;
  lastUpdatedOn: string;
  createdBySfId: string;
  updatedBySfId: string;
  active: boolean;
  cancelledBySystem: boolean;
  user: UserDto;
  space: SpaceDto;
  manager: UserDto;
  source?: ReservationSource;
}

export interface BookingRuleDto {
  idBookingRule: number;
  sfId: string;
  externalUuid: string;
  name: string;
  creditsPerHour: number;
  costPerHour: number;
  currencyCodeAlpha: string;
  createdDate: string;
  isDeleted: boolean;
  rentable: boolean;
  bookable: boolean;
  active: boolean;
}

export enum ReservationSource {
  MemberPortal = 'Member Portal',
  OnDemand = 'On Demand',
  Breather = 'Breather',
}
