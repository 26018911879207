import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import SubmitButton from '../SubmitButton';
import SlideAcross from '../SlideAcross';
import { breakpoints } from '../../../Core/Theme';
import { Icons } from '../../../resources';
import { TRANSITION_DURATION } from './constants';

const SlideConfirmationMessage = props => {
  const { enterOn, onClose, message, classes, withDismiss, className } = props;
  const { t } = useTranslation();
  return (
    <SlideAcross enterOn={enterOn} duration={TRANSITION_DURATION}>
      <div className={clsx(classes.confirmationMessage, className)}>
        <Icons.ConfirmCheck className={classes.confirmCheck} />
        <div>{message}</div>
        {withDismiss && (
          <SubmitButton
            defaultText={t('general.dismiss')}
            onClick={onClose}
            className={classes.dismissButton}
          />
        )}
      </div>
    </SlideAcross>
  );
};

const styles = theme => ({
  confirmationMessage: {
    fontFamily: 'VerlagLight',
    fontSize: 18,
    lineHeight: '24px',
    paddingLeft: 20,
    paddingRight: 20,
    paddingTop: 80,
    textAlign: 'center',
    height: 'calc(100vh - 80px)',
    position: 'relative',
    [theme.breakpoints.up(breakpoints.MOBILE)]: {
      fontSize: 20,
      lineHeight: '26px',
      paddingLeft: 40,
      paddingRight: 40,
    },
  },
  confirmCheck: {
    paddingBottom: 30,
    margin: 'auto',
    display: 'block',
  },
  dismissButton: {
    position: 'absolute',
    bottom: 20,
    left: 20,
    width: 'calc(100% - 40px)',
    [theme.breakpoints.up(breakpoints.MOBILE)]: {
      left: 40,
      width: 'calc(100% - 80px)',
    },
  },
});
SlideConfirmationMessage.defaultProps = {
  message: '',
  withDismiss: false,
  className: '',
  onClose: () => {},
};

SlideConfirmationMessage.propTypes = {
  onClose: PropTypes.func,
  enterOn: PropTypes.bool.isRequired,
  classes: PropTypes.object.isRequired,
  message: PropTypes.node,
  withDismiss: PropTypes.bool,
  className: PropTypes.string,
};

export default withStyles(styles)(SlideConfirmationMessage);
