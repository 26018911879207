import { Link } from 'react-router-dom';
import { get } from 'lodash';
import clsx from 'clsx';
import ContentLoader from 'react-content-loader';

import { NavDropDown } from '../../Components/Common';
import Components from '../../Components';
import { trackNavigationClick } from '../../Core/Tracking';
import { colors } from '../../Core/Theme';
import { RIGHT } from '../../Components/AppRoutes/constants';
import LabelChip from '../../Components/Common/LabelChip';

const { LEFT } = Components.Routes.constants;

const loadSvg = <rect x="0" y="0" rx="4" ry="4" width="100%" height="15" />;

const loadState = (classes, width, key, className) => {
  return (
    <div
      className={clsx(classes.contentLoaderWrapper, className)}
      style={{ width }}
      key={key}
    >
      <ContentLoader
        speed={2}
        backgroundColor={colors.light}
        foregroundColor={colors.middle}
        className={classes.contentLoader}
      >
        {loadSvg}
      </ContentLoader>
    </div>
  );
};

const genLink = (navTab, classes, isLoading, key, t, isManager) => {
  const { name, path } = navTab;
  const position = get(navTab, ['position'], LEFT);
  const width = position === LEFT ? 60 : 130;
  return !isLoading ? (
    <Link
      to={path}
      onClick={() => {
        trackNavigationClick({ event_label: name });
      }}
      className={clsx(
        classes.subNavMenuItem,
        position === LEFT
          ? classes.subNavMenuItemLeft
          : classes.subNavMenuItemRight,
        position === RIGHT && isManager && classes.managerSubNavMenuItemRight
      )}
      key={`subnav-${key}`}
      datatestid={`home_${name}_navigation_button_`}
    >
      {t(name)}
      {navTab.isNew ? (
        <LabelChip className={classes.labelChip} label={t('navigation.new')} />
      ) : null}
    </Link>
  ) : (
    loadState(
      classes,
      width,
      key,
      position === RIGHT && isManager && classes.managerSubNavMenuItemRight
    )
  );
};

const genDropDown = (key, navTabs, classes, isLoading) => {
  const onClickNavigation = name => {
    trackNavigationClick({ event_label: name });
  };
  return !isLoading ? (
    <NavDropDown
      name={key}
      navTabs={navTabs}
      onClick={onClickNavigation}
      classNames={{
        menu: clsx(classes.subNavMenuItem, classes.subNavMenuItemDropDown),
        paper: clsx(classes.menuPaper, classes.menuPaperDropDown),
      }}
      key={`subnav-drop-down-${key}`}
      datatestid={`home_${key}_navigation_button`}
    />
  ) : (
    loadState(classes, 60, key)
  );
};

export const makeSubNav =
  (subNavList, classes, isLoading, t, isManager) => (acc, key) => {
    const temp =
      subNavList[key].length < 2
        ? genLink(subNavList[key][0], classes, isLoading, key, t, isManager)
        : genDropDown(key, subNavList[key], classes, isLoading);

    const position = get(subNavList[key][0], ['position'], LEFT);

    acc[position].push(temp);

    return acc;
  };
