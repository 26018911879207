import { Http, stringifyUrl } from '../Utils';
import { CommunityMessagePageDto } from './dto';

const path = '/community_messages';

export const createCommunityMessage = (
  locationSfIds: string[],
  body: string,
  override = false
) => {
  return Http.post(path, { locationSfIds, body, override });
};

export const fetchCommunityMessagePage = (
  limit?: number,
  offset?: number,
  locationSfIds?: string[],
  override?: boolean
) => {
  const url = stringifyUrl(path, {
    limit,
    offset,
    override,
    locationSfIds: locationSfIds?.join(','),
  });

  return Http.get<CommunityMessagePageDto>(url);
};
