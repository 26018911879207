import MultiDecorator from 'draft-js-plugins-editor/lib/Editor/MultiDecorator';
import { CompositeDecorator } from 'draft-js';
import addLinkPlugin from './addLinkPlugin';

function getPluginDecoratorArray() {
  // check each plugin that will be used in the editor for decorators
  // (retrieve listOfPlugins however makes sense in your code)
  const listOfPlugins = [addLinkPlugin];
  const decorators = listOfPlugins.reduce((acc, plugin) => {
    if (plugin.decorators !== null && plugin.decorators !== undefined) {
      // if the plugin has any decorators, add them to a list of all decorators from all plugins
      return acc.concat(plugin.decorators);
    }
    return acc;
  }, []);
  return decorators;
}

export function getRTEDecorators() {
  // I can't quite remember why I had to wrap things in this exact way, but found that I ran into
  // errors if I did not both have a MultiDecorator and a CompositeDecorator wrapping
  // This MultiDecorator can now be used as shown in my previous post.
  return new MultiDecorator([
    new CompositeDecorator(getPluginDecoratorArray()),
  ]);
}
