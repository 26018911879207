import axios from 'axios';
import queryString from 'query-string';
import Lodash from 'lodash';

export const toggleDesks = desksDto => {
  return axios.patch(`desks`, desksDto);
};

export const getAvailableDeskCounts = objParams => {
  const startEpoch = Lodash.get(objParams, ['startEpoch'], null);
  const endEpoch = Lodash.get(objParams, ['endEpoch'], null);
  const locationSfId = Lodash.get(objParams, ['locationSfId'], null);
  const splitByFloor = Lodash.get(objParams, ['splitByFloor'], null);
  const floorId = Lodash.get(objParams, ['floorId'], null);
  const groupingIds = Lodash.get(objParams, ['groupingIds'], null);
  const integratedOnly = Lodash.get(objParams, ['integratedOnly'], null);
  const includeUnmapped = Lodash.get(objParams, ['includeUnmapped'], null);

  const objSchema = {
    end_epoch: endEpoch,
    start_epoch: startEpoch,
    location_sfid: locationSfId,
    split_by_floor: splitByFloor,
    floor_id: floorId,
    grouping_ids: groupingIds,
    integrated_only: integratedOnly,
    include_unmapped: includeUnmapped,
  };
  const strEndpoint = '/desks/available_desk_counts';

  const strQueryUrl = queryString.stringifyUrl(
    {
      url: strEndpoint,
      query: objSchema,
    },
    {
      skipNull: true,
    }
  );

  return axios.get(strQueryUrl);
};

export const getDeskAvailability = objParams => {
  const startEpoch = Lodash.get(objParams, ['startEpoch'], null);
  const groupingIds = Lodash.get(objParams, ['groupingIds'], null);
  const floorId = Lodash.get(objParams, ['floorId'], null);

  const objSchema = {
    start_epoch: startEpoch,
    grouping_ids: groupingIds,
    floor_id: floorId,
  };
  const strEndpoint = '/desks/desk_availability';

  const strQueryUrl = queryString.stringifyUrl(
    {
      url: strEndpoint,
      query: objSchema,
    },
    {
      skipNull: true,
    }
  );

  return axios.get(strQueryUrl);
};

export const fetchDesksWithAvailability = async ({
  locationSfId,
  startEpoch,
  endEpoch,
  groupingIds,
  floorId,
  integratedOnly,
}) => {
  const urlQueryParams = {
    location_sfid: locationSfId,
    start_epoch: startEpoch,
    end_epoch: endEpoch,
    grouping_ids: groupingIds?.join(','),
    floor_id: floorId,
    integrated_only: integratedOnly,
  };

  const strEndpoint = '/desks/desks_availabilities';
  const strQueryUrl = queryString.stringifyUrl(
    {
      url: strEndpoint,
      query: urlQueryParams,
    },
    { skipNull: true, skipEmptyString: true }
  );

  return axios.get(strQueryUrl);
};
