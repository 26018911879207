import * as Common from './Common';
import Routes from './AppRoutes';

import ReserveDeskForm from './ReserveDeskForm';
import DeskFloorPlan from './DeskFloorPlan';
import DeskEmptyState from './DeskEmptyState';
import ReserveDesk from './ReserveDesk';
import ReserveDeskWithApi from './ReserveDeskWithApi';
import AvailabilityDayPicker from './AvailabilityDayPicker';
import ActivityTableSummary from './ActivityTableSummary';
import SeatReservationSummary from './SeatReservationSummary';
import SmartMeetingRoomBooking from './SmartMeetingRoomBooking';
import Schedule from './Schedule';
import UpcomingReservations from './UpcomingReservations';
import UpcomingAndAllReservations from './UpcomingAndAllReservations';
import ProfessionalMailingAdrTile from './ProfessionalMailingAdrTile';
import Chip from './Chip/Chip';

export {
  ReserveDeskForm,
  DeskFloorPlan,
  ReserveDesk,
  ReserveDeskWithApi,
  DeskEmptyState,
  AvailabilityDayPicker,
  ActivityTableSummary,
  SeatReservationSummary,
  SmartMeetingRoomBooking,
  Schedule,
  UpcomingReservations,
  UpcomingAndAllReservations,
  ProfessionalMailingAdrTile,
  Chip,
};

export default {
  Common,
  Routes,
};
