import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import useStepper from 'use-stepper';
import PropTypes from 'prop-types';

import { Icons } from '../../../resources';
import { colors } from '../../../Core/Theme';

const StepperInput = props => {
  const {
    value,
    label,
    onChange,
    classes,
    classNameInput,
    min,
    max,
    errorMessage,
    hasError,
    disabled,
  } = props;
  const {
    getInputProps,
    getIncrementProps,
    getDecrementProps,
    value: stepperValue,
  } = useStepper({ min, max, defaultValue: value });

  React.useEffect(() => {
    if (onChange) {
      onChange(stepperValue);
    }
  }, [stepperValue]);

  return (
    <>
      <div className={classes.label}>{label}</div>
      <div
        className={clsx(
          classes.container,
          disabled && classes.disabledContainer
        )}
      >
        <div>
          <Icons.Decrement
            {...getDecrementProps({ disabled })}
            className={clsx(classes.icon, disabled && classes.disabledButtons)}
          />
        </div>
        <div className={classes.inputContainer}>
          <input
            {...getInputProps({ disabled })}
            type="number"
            className={clsx(classes.inputClass, classNameInput)}
          />
        </div>
        <div>
          <Icons.Increment
            {...getIncrementProps({ disabled })}
            className={clsx(classes.icon, disabled && classes.disabledButtons)}
          />
        </div>
      </div>
      {hasError && <div className={classes.errorMessage}>{errorMessage}</div>}
    </>
  );
};

StepperInput.defaultProps = {
  value: 0,
  classNameInput: null,
  min: 0,
  max: 10,
  label: null,
  errorMessage: null,
  hasError: null,
  disabled: false,
};

StepperInput.propTypes = {
  value: PropTypes.number,
  classNameInput: PropTypes.string,
  label: PropTypes.string,
  min: PropTypes.number,
  max: PropTypes.number,
  errorMessage: PropTypes.string,
  hasError: PropTypes.bool,
  disabled: PropTypes.bool,
};

const styles = () => ({
  disabledContainer: {
    opacity: 0.7,
  },
  disabledButtons: {
    cursor: 'not-allowed !important',
    '&:hover': {
      opacity: '1 !important',
    },
  },
  label: {
    color: 'black !important',
    fontSize: 14,
    fontFamily: 'VerlagBold',
    transform: 'none',
    whiteSpace: 'nowrap',
    paddingBottom: 10,
  },
  errorMessage: {
    fontFamily: 'VerlagLight',
    fontSize: 12,
    color: colors.alertMessage,
    paddingTop: 2,
  },
  icon: {
    cursor: 'pointer',
    '&:hover': {
      opacity: 0.6,
    },
  },
  inputClass: {
    textAlign: 'center',
    '&::-moz-appearance': 'textfield',
    '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
    outline: 'none',
    border: 'none',
    borderBottom: 'solid 1px #000000',
    width: '60px',
    borderRadius: 0,
  },
  inputContainer: {
    paddingLeft: 20,
    paddingRight: 20,
  },
  container: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
  },
});

export default withStyles(styles)(StepperInput);
