import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { breakpoints, colors } from '../../../Core/Theme';

type Props = {
  children?: ReactNode;
};

const defaultProps = {
  children: undefined,
};

const Container = styled.div`
  padding: 50px 45px 0px 45px;
  border-bottom: 1px solid ${colors.middle};
  background-color: white;
  @media (max-width: ${breakpoints.MOBILE}px) {
    padding: 50px 20px 0px 20px;
  }
`;

const Title = styled.div`
  font-size: 36px;
  font-family: VerlagBold;
  @media (max-width: ${breakpoints.MOBILE}px) {
    font-size: 24px;
  }
`;

export const ReserveOfficeHeader = (props: Props) => {
  const { children } = props;
  const { t } = useTranslation();

  return (
    <Container>
      <Title>{t<string>('reserveOffice.title')}</Title>
      {children}
    </Container>
  );
};

ReserveOfficeHeader.defaultProps = defaultProps;
