// Styles
import { colors } from '../../../Core/Theme';

const styles = theme => ({
  message: {
    fontFamily: "'VerlagBook'",
    fontSize: '12px',
    fontWeight: 'normal',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 1.25,
    letterSpacing: 'normal',
    color: colors.white,
    marginBottom: 30,
    [theme.breakpoints.up('xs')]: {
      maxWidth: '266px',
    },
    [theme.breakpoints.down('xs')]: {
      maxWidth: '100vh',
    },
  },
});

export default styles;
