import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const Header = props => {
  const { classes } = props;
  const { t } = useTranslation();
  return <div className={classes.header}>{t('settings.header_title')}</div>;
};

Header.propTypes = {
  classes: PropTypes.object.isRequired,
};

const styles = theme => ({
  header: {
    fontFamily: 'VerlagBold',
    [theme.breakpoints.up('xs')]: {
      paddingTop: 40,
      paddingBottom: 30,
      fontSize: '36px',
      marginLeft: -1,
    },
    [theme.breakpoints.down('xs')]: {
      paddingTop: 40,
      paddingBottom: 50,
      fontSize: '24px',
    },
  },
});
export default withStyles(styles)(Header);
