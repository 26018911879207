import typeToReducer from 'type-to-reducer';
import Lodash from 'lodash';
import * as types from './actionTypes';

const initialState = {
  total: 0,
  limit: 0,
  offset: 0,
  data: [],
  loading: false,
  hasError: false,
  firstLoadingOccurred: false,
};

export const onLoadNotificationsPending = state => {
  return {
    ...state,
    loading: true,
  };
};

export const onLoadNotificationsFulfilled = (state, action) => {
  const { total, limit, offset } = initialState;
  const data = Lodash.get(action, ['payload'], {
    total,
    limit,
    offset,
    data: [],
  });
  const combinedData = [...state.data, ...data.receptionHistories];
  return {
    ...state,
    total: data.total,
    limit: data.limit,
    offset: state.offset,
    data: Lodash.uniqBy(combinedData, 'idReceptionHistory'),
    loading: false,
    hasError: false,
    firstLoadingOccurred: true,
  };
};

export const onLoadNotificationsRejected = state => {
  return {
    ...state,
    loading: false,
    hasError: true,
  };
};

export const onResetStateToInitialState = state => {
  return {
    ...state,
    ...initialState,
  };
};

export const onIncreaseOffsetBy = (state, action) => {
  return {
    ...state,
    offset: state.offset + action.payload,
  };
};

const notificationsReducer = typeToReducer(
  {
    [types.LOAD_NOTIFICATIONS]: {
      PENDING: onLoadNotificationsPending,
      FULFILLED: onLoadNotificationsFulfilled,
      REJECTED: onLoadNotificationsRejected,
    },
    [types.RESET_STATE_TO_INITIAL_STATE]: onResetStateToInitialState,
    [types.INCREASE_OFFSET_BY]: onIncreaseOffsetBy,
  },
  initialState
);

export default notificationsReducer;
