import { withStyles } from '@material-ui/core/styles';

import ReservationSlot from '../ReservationSlot';
import { Tooltip } from '../../Common';

interface Props {
  time: number;
  interval: number;
  spaceId: string;
  message: string;
}

const ReservationSlotWithToolTip = (props: Props) => {
  const { time, interval, spaceId, message } = props;
  return (
    <Tooltip
      interactive
      placement="top"
      title={message}
      anchorContent={() => (
        <div>
          <ReservationSlot
            booking={{
              spaceId,
              startEpoch: time + interval / 2,
              endEpoch: time + interval,
              canView: false,
            }}
            interval={interval}
            isFullHeight
          />
        </div>
      )}
    />
  );
};

const styles = {
  slot: {
    opacity: 0.6,
  },
};

export default withStyles(styles)(ReservationSlotWithToolTip);
