import React from 'react';

// Components
import { MapLayerWrapper as Layer } from '../../../Common';

// Types
import { Layers } from '../../types';

// Theme
import { colors } from '../../../../Core/Theme';
import { createHash } from '../../../../Core/Utils';
import { OfficeLayersProps } from './types';

const LAYER_ID = Layers.UnavailableOffice;

// Layer properties
const fillProperties = {
  id: LAYER_ID,
  type: 'fill',
  source: 'features',
  paint: {
    'fill-pattern': 'deskUnavailable',
    'fill-color': colors.middle,
  },
};
const lineProperties = {
  id: `${LAYER_ID}-lines`,
  type: 'line',
  source: 'features',
  paint: {
    'line-color': colors.darkGray,
    'line-width': 1,
  },
};

// Legend
const legend = {
  key: LAYER_ID,
  style: {
    backgroundColor: colors.middle,
    borderColor: colors.darkGray,
    borderWidth: 1,
    borderStyle: 'solid',
    background: `repeating-linear-gradient(45deg, ${colors.middle} 1px, ${colors.darkGray} 5px)`,
  },
  labelKey: 'floorPlan.legend.unavailableOffice',
};

const UnavailableOffice = (props: OfficeLayersProps) => {
  const { featureIds } = props;

  return (
    <Layer
      key={`${LAYER_ID}-${createHash(featureIds)}`}
      properties={[fillProperties, lineProperties]}
      filter={['in', ['get', 'id'], ['literal', featureIds]]}
      {...props}
    />
  );
};

export { legend };
export default React.memo(UnavailableOffice);
