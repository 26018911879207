import { useMemo } from 'react';
import { useLocationBlackoutDates } from './useLocationBlackoutDates';
import { date as DateUtils } from '../Core/Utils';

export const useIsLocationDateAvailable = ({
  locationId,
  date,
  timeZoneId,
}: {
  locationId?: string;
  date: number;
  timeZoneId?: string;
}) => {
  const { isLoading, blackoutDates, hasError } = useLocationBlackoutDates({
    locationId,
  });

  const isBlackoutDate = useMemo(() => {
    if (!blackoutDates || !date) {
      return false;
    }

    return blackoutDates.some(objDate =>
      DateUtils.isSameDay(
        DateUtils.utcToZonedTime(
          DateUtils.getLocalDay(objDate.date),
          timeZoneId
        ),
        DateUtils.utcToZonedTime(DateUtils.fromUnixTime(date), timeZoneId)
      )
    );
  }, [blackoutDates, date, timeZoneId]);

  return { isLoading, blackoutDates, isBlackoutDate, hasError };
};
