import { StyleRulesCallback, withStyles } from '@material-ui/core';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import { ReactNode } from 'react';

type Props = {
  classes: ClassNameMap;
  children: ReactNode;
};

const TrackingTable = (props: Props) => {
  const { classes, children } = props;

  return <div className={classes.default}>{children}</div>;
};

const styles: StyleRulesCallback<string> = () => ({
  default: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
  },
});

export default withStyles(styles)(TrackingTable);
