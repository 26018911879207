import * as React from 'react';
import { useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import { bindActionCreators } from 'redux';

import { UpcomingAndAllReservations } from '../../Components';

import { getUserDefaultGrouping } from '../../Redux/Common/Groupings/selectors';
import locationsRedux from '../../Redux/Common/Locations';
import {
  getLocations,
  getLocationsById,
} from '../../Redux/Common/Locations/selectors';
import { getStaticImageUrl } from '../../Core/Utils';
import { breakpoints } from '../../Core/Theme';
import colors from '../../Core/Theme/colors';
import { S3IconStrings } from '../../resources';
import { CreateForm, Confirmation } from './Components';
import { getCreateDtoFromValues } from './utils';
import { createSeatBookingApi } from '../../Core/Api';
import { trackReserveCommunitySeat } from '../../Core/Tracking';
import { OPEN_WORKSPACE_LANDING_PATH } from './constants';
import { RESERVATION_TYPES } from '../../Models/anyReservation';

const TOP_OFFSET_GUESS = 70;

const OpenWorkspace = props => {
  const {
    classes,
    profile,
    listLocations,
    loadLocations,
    history,
    locationsById,
    usersDefaultGrouping,
    ...rest
  } = props;
  const containerRef = React.useRef();

  const [createdBooking, setCreatedBooking] = React.useState(null);
  const [upcomingRefreshCount, setUpcomingRefreshCount] = React.useState(0);
  const [topOffset, setTopOffset] = React.useState(TOP_OFFSET_GUESS);

  React.useEffect(() => {
    if (!containerRef.current) {
      return () => {};
    }
    setTopOffset(containerRef.current?.getBoundingClientRect()?.y);
    const onResize = () => {
      setTopOffset(containerRef.current?.getBoundingClientRect().y);
    };

    window.addEventListener('resize', onResize, false);
    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, [containerRef.current]);

  useEffect(() => {
    if (!listLocations.length) {
      loadLocations();
    }
  }, []);

  const onCloseConfirmation = () => {
    setCreatedBooking(null);
  };

  const upcomingReservationsUpdatedHandler = () => {
    setUpcomingRefreshCount(prevValue => prevValue + 1);
  };

  const handleCreate = async values => {
    const dto = getCreateDtoFromValues(values);

    trackReserveCommunitySeat();
    return createSeatBookingApi(dto).then(objResponse => {
      const { data } = objResponse;
      if (data) {
        upcomingReservationsUpdatedHandler();
        setCreatedBooking(data);
      }
    });
  };

  return (
    <>
      <div className={classes.absoluteBackground} />
      <div
        className={classes.container}
        {...rest}
        ref={containerRef}
        style={{ height: `calc(100vh - ${topOffset}px` }}
      >
        <div className={classes.contentWrapper}>
          <Grid container className={classes.content} spacing={8}>
            {!createdBooking ? (
              <CreateForm
                profile={profile}
                history={history}
                locationsById={locationsById}
                handleCreate={handleCreate}
                usersDefaultGrouping={usersDefaultGrouping}
              />
            ) : (
              <Confirmation
                history={history}
                booking={createdBooking}
                profile={profile}
                onClose={onCloseConfirmation}
              />
            )}
          </Grid>
          <UpcomingAndAllReservations
            history={history}
            upcomingRefreshCount={upcomingRefreshCount}
            upcomingUrlControlType={RESERVATION_TYPES.SEAT_BOOKING}
            upcomingUrlPathname={OPEN_WORKSPACE_LANDING_PATH}
            onUpcomingReservationsUpdated={upcomingReservationsUpdatedHandler}
          />
        </div>
      </div>
    </>
  );
};

const styles = theme => ({
  container: {
    position: 'relative',
    display: 'flex',
    overflow: 'hidden',
  },
  absoluteBackground: {
    backgroundImage: `linear-gradient(to bottom, rgba(0, 0, 0, 0.75) 0%, rgba(0, 0, 0, 0)), url(${getStaticImageUrl(
      S3IconStrings.openWorkspaceBackground
    )})`,
    backgroundSize: 'cover',
    height: '100vh',
    position: 'absolute',
    width: '100vw',
  },
  contentWrapper: {
    paddingLeft: 40,
    paddingRight: 40,
    [theme.breakpoints.up(breakpoints.SM)]: {
      paddingLeft: 20,
      paddingRight: 20,
    },
    overflowY: 'scroll',
    display: 'flex',
    width: '100%',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 2,
  },
  content: {
    backgroundColor: `${colors.white}E5`,
    margin: 'auto',
    [theme.breakpoints.up(breakpoints.SM)]: {
      width: 560,
    },
    [theme.breakpoints.up(breakpoints.MD)]: {
      width: 720,
    },
  },
});

OpenWorkspace.defaultProps = {};

OpenWorkspace.propTypes = {
  classes: PropTypes.object.isRequired,
  listLocations: PropTypes.arrayOf(PropTypes.object).isRequired,
  history: PropTypes.shape({}).isRequired,
  profile: PropTypes.object.isRequired,
  loadLocations: PropTypes.func.isRequired,
  locationsById: PropTypes.object.isRequired,
  usersDefaultGrouping: PropTypes.object.isRequired,
};

const mapStateToProps = state => {
  const { userManager } = state;
  return {
    profile: userManager.profile,
    listLocations: getLocations(state),
    locationsById: getLocationsById(state),
    usersDefaultGrouping: getUserDefaultGrouping(state),
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      loadLocations: locationsRedux.actions.addLocations,
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(OpenWorkspace));
