import { Http, stringifyUrl } from '../Utils';
import { CreateOccasionTypeDto, OccasionTypeDto } from './dto';

export const fetchOccasionTypes = (includeDeleted = false) => {
  const url = stringifyUrl('/occasions/types', {
    include_deleted: includeDeleted,
  });

  return Http.get<OccasionTypeDto[]>(url);
};

export const createOccasionType = (occasionType: CreateOccasionTypeDto) => {
  return Http.post<OccasionTypeDto>('/occasions/types', occasionType);
};

export const deleteOccasionType = (id: string) => {
  return Http.remove<OccasionTypeDto>(`/occasions/types/${id}`);
};

export const unDeleteOccasionType = (id: string) => {
  return Http.patch<OccasionTypeDto>(`/occasions/types/${id}`);
};
