import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import memoizeOne from 'memoize-one';

// Components
import { MapLayerWrapper as Layer } from '../../Common';
import Popup from '../Components/Popup';
import { calculateCentroid } from '../Utils';

// Constants
import { LAYERS } from '../Constants';

// Theme & Utils
import { colors } from '../../../Core/Theme';
import { createHash } from '../../../Core/Utils';
import { getIsMobile } from '../../../Core/Utils/windowDimensions';
import { sortByOfficeName } from '../../../Containers/ManageOffices/utils';

// Layer properties
const lineProperties = {
  id: `${LAYERS.UTILIZED_RESERVABLE_OFFICE}-lines`,
  type: 'line',
  source: 'features',
  paint: {
    'line-width': 2,
    'line-color': colors.palette.error.main,
  },
};

const fillProperties = {
  id: LAYERS.UTILIZED_RESERVABLE_OFFICE,
  type: 'fill',
  source: 'features',
  paint: {
    'fill-color': [
      'case',
      ['boolean', ['feature-state', 'hover'], false],
      colors.palette.primary.light,
      colors.palette.primary.main,
    ],
  },
};

const getCoordinates = memoizeOne((data, officeId, setPopupCoordinates) => {
  if (!data || !data.dataset.features) return;
  const featureOffice = data.dataset.features.find(f => f.id === officeId);
  const coordinates = calculateCentroid(featureOffice.geometry);
  setPopupCoordinates(coordinates);
});

const UtilizedReservableOffice = ({
  offices,
  data,
  selectedOfficeFeatureId,
  officeHoverId,
  ...props
}) => {
  const { t } = useTranslation();
  const isMobile = getIsMobile();

  // Show the pop up on the lowest number office
  const featuresLookups = Object.keys(data.featuresLookup)
    .reduce((acc, fKey) => {
      if (offices.includes(fKey)) {
        acc.push({ ...data.featuresLookup[fKey], featureId: fKey });
      }

      return acc;
    }, [])
    .sort(sortByOfficeName);

  const [popupCoordinates, setPopupCoordinates] = useState([]);

  if (
    featuresLookups.length > 0 &&
    officeHoverId &&
    featuresLookups.find(feature => feature.featureId === officeHoverId)
  ) {
    getCoordinates(data, officeHoverId, setPopupCoordinates);
  } else if (popupCoordinates.length) {
    setPopupCoordinates([]);
  }

  return (
    <>
      {!isMobile &&
        popupCoordinates.length > 0 &&
        !selectedOfficeFeatureId &&
        officeHoverId && (
          <Popup
            latitude={popupCoordinates[1]}
            longitude={popupCoordinates[0]}
            message={t('manageOffices.licensed_offices_error')}
            anchor="right"
            hasError
          />
        )}

      <Layer
        key={`${LAYERS.UTILIZED_RESERVABLE_OFFICE}-${createHash(offices)}`}
        properties={[fillProperties, lineProperties]}
        filter={['in', ['get', 'id'], ['literal', offices]]}
        {...props}
      />
    </>
  );
};

UtilizedReservableOffice.defaultProps = {
  offices: [],
  selectedOfficeFeatureId: null,
  officeHoverId: null,
};
UtilizedReservableOffice.propTypes = {
  offices: PropTypes.arrayOf(PropTypes.string),
  data: PropTypes.object.isRequired,
  selectedOfficeFeatureId: PropTypes.string,
  officeHoverId: PropTypes.string,
};

export default React.memo(UtilizedReservableOffice);
