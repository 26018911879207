import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { useState } from 'react';

import { CloseButton, Drawer, Snack } from '../../../Components/Common';

import OfficeForm from './OfficeForm';

import { SnackVariantes } from '../../../Components/Common/Snack/snackVariantes';

const OfficeFormPanel = props => {
  const { office, currencyIsoCode, onClose, onUpdated, classes } = props;
  const [errorMsg, setErrorMsg] = useState();

  return (
    <>
      <Drawer
        isOpenDrawer={!!office}
        className="drawerPaperMediumSmall"
        anchor="right"
        onCloseDrawer={onClose}
        customDuration={{ enter: 350, exit: 300 }}
      >
        <div className={classes.content}>
          <CloseButton className={classes.closeButton} onClose={onClose} />
          {!!office && (
            <OfficeForm
              office={office}
              currencyIsoCode={currencyIsoCode}
              onUpdated={onUpdated}
              onError={msg => setErrorMsg(msg)}
            />
          )}
        </div>
      </Drawer>
      <Snack
        isOpen={!!errorMsg}
        message={errorMsg}
        autoClose
        onClose={() => setErrorMsg(null)}
        variant={SnackVariantes.Error}
      />
    </>
  );
};

const styles = () => ({
  content: {
    paddingTop: 60,
    height: 'calc(100% - 30px)',
  },
  closeButton: {
    position: 'absolute',
    right: '15px',
    top: '15px',
  },
});

OfficeFormPanel.defaultProps = {
  office: undefined,
};

OfficeFormPanel.propTypes = {
  office: PropTypes.object,
  currencyIsoCode: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  onUpdated: PropTypes.func.isRequired,
};

export default withStyles(styles)(OfficeFormPanel);
