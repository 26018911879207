// @ts-nocheck
/* eslint-disable prefer-rest-params */
/* eslint-disable react/jsx-filename-extension */
/* eslint-env browser */

import { createRoot } from 'react-dom/client';
import * as Sentry from '@sentry/react';
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import smoothscroll from 'smoothscroll-polyfill';
import { I18nextProvider } from 'react-i18next';

import i18n from './Core/i18n';

import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import store, { persistor } from './Redux';
import { initializeSentry } from './Core/Utils';

smoothscroll.polyfill();
window.Sentry = Sentry;
initializeSentry(
  process.env.REACT_APP_DSN,
  process.env.REACT_APP_SENTRY_ENVIRONMENT,
  process.env.REACT_APP_RELEASE
);

if (process.env.REACT_APP_GA_MEASUREMENT_ID) {
  const trackingId = process.env.REACT_APP_GA_MEASUREMENT_ID;

  const gtagjs = document.createElement('script');
  gtagjs.src = `https://www.googletagmanager.com/gtag/js?id=${trackingId}`;
  gtagjs.async = true;
  document.getElementsByTagName('head')[0].appendChild(gtagjs);

  /* eslint-disable no-undef */
  /* eslint-disable prefer-rest-params */
  window.dataLayer = window.dataLayer || [];
  window.gtag = function gtag() {
    dataLayer.push(arguments);
  };
  window.gtag('js', new Date());
  window.gtag('config', trackingId, {
    send_page_view: true,
    custom_map: {
      dimension1: 'user_role',
      dimension2: 'secondary_role',
      dimension3: 'home_location',
      dimension4: 'portal_section',
      dimension5: 'minutes_until_reservation',
      dimension6: 'reservation_location',
      dimension7: 'reservation_overage_notification',
      dimension8: 'room_name',
      dimension9: 'room_amenities',
      dimension10: 'room_seats',
      dimension11: 'location_on_page',
      dimension12: 'user_sfId',
      metric1: 'reservation_created',
      metric2: 'reservation_updated',
      metric3: 'reservation_cancelled',
    },
  });
  window.gtag('config', 'AW-774127352');
}

//  TODO: general loading state while store is being persisted

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
  <Sentry.ErrorBoundary>
    <Router>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <I18nextProvider i18n={i18n}>
            <App />
          </I18nextProvider>
        </PersistGate>
      </Provider>
    </Router>
  </Sentry.ErrorBoundary>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
