import Components from '../../Components';
import { canAccessHotDeskFeature } from '../../Core/Utils/userPermissions';
import { DESK_LANDING_PATH } from './constants';

const { containerTypes } = Components.Routes;

const RouteConfig = {
  path: DESK_LANDING_PATH,
  exact: true,
  name: 'navigation.menu.desk',
  private: true,
  type: containerTypes.SUBCONTENT,
  grouping: 'navigation.grouping.reserve',
  isBeta: true,
  requiresMemberPortalAccess: true,
  isAuthorized: ({ profile, userGroupings, userGroupingsIsLoading }) =>
    canAccessHotDeskFeature(profile, userGroupings, userGroupingsIsLoading),
};

export default RouteConfig;
