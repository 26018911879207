import { date as DateUtils } from '../../../Core/Utils';

export class RoomBookingDate extends String {
  static format = 'YYYY-MM-DD';

  private constructor(value?: any) {
    super(value);
  }

  static FromEpochAndTimezoneId(epoch: number, timezoneId: string) {
    const value = DateUtils.formatEpoch(
      epoch,
      RoomBookingDate.format,
      timezoneId
    );
    return new RoomBookingDate(value);
  }
}
