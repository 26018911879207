import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import queryString from 'query-string';
import get from 'lodash/get';

export const fetchDayPasses = objParams => {
  const accountSfId = get(objParams, ['accountSfId'], null);
  const monthEpoch = get(objParams, ['monthEpoch'], null);
  const includeRolloverBooks = get(objParams, ['includeRolloverBooks'], true);
  const shelf = get(objParams, ['shelf'], null);

  const objSchema = {
    account_sfid: accountSfId,
    year_month_epoch: monthEpoch,
    include_rollover_books: includeRolloverBooks,
    shelf,
  };

  const strQueryUrl = queryString.stringifyUrl(
    {
      url: '/credit_books/passes_infos',
      query: objSchema,
    },
    {
      skipNull: true,
    }
  );
  return axios.get(strQueryUrl);
};

export const postPurchasePass = objPassPurchase => {
  return axios.post(`credit_books/pass_purchase`, objPassPurchase, {
    headers: {
      'Idempotency-Key': uuidv4(),
    },
  });
};
export const postRefundsPass = objPassRefunds => {
  return axios.post(`credit_books/pass_refund`, objPassRefunds, {
    headers: {
      'Idempotency-Key': uuidv4(),
    },
  });
};
