import * as React from 'react';
import PropTypes from 'prop-types';
import { Redirect, Route } from 'react-router-dom';
import { useSelector } from 'react-redux';

import Auth from '../AuthContext';
import { customProps } from '../../../Core/Utils';
import FullPageCenterLoading from '../FullPageCenterLoading';
import {
  getCanAccessBillingPortal,
  getCanAccessMemberPortal,
  getUsersDefaultRoute,
} from '../../../Redux/Common/UserManager/selectors';

const PrivateRoute = props => {
  const {
    RenderProp,
    setSubNav,
    subNavOrder,
    subNavList,
    topNavList,
    isAuthorized,
    profile,
    userGroupings,
    userGroupingsIsLoading,
    requiresMemberPortalAccess,
    requiresBillingPortalAccess,
    ...rest
  } = props;

  const defaultRoute = useSelector(getUsersDefaultRoute);
  const canAccessMemberPortal = useSelector(getCanAccessMemberPortal);
  const canAccessBillingPortal = useSelector(getCanAccessBillingPortal);

  const isAllowedRoute =
    isAuthorized({
      profile,
      userGroupings,
      userGroupingsIsLoading,
    }) &&
    !(requiresMemberPortalAccess && !canAccessMemberPortal) &&
    !(requiresBillingPortalAccess && !canAccessBillingPortal);

  return (
    <React.Fragment>
      <Auth.Consumer>
        {({ isAuth }) => (
          <Route
            {...rest}
            render={prop => {
              if (userGroupingsIsLoading && isAuth && isAllowedRoute) {
                return <FullPageCenterLoading />;
              }
              if (isAuth && isAllowedRoute) {
                return (
                  <RenderProp
                    {...prop}
                    setSubNav={setSubNav}
                    subNavOrder={subNavOrder}
                    subNavList={subNavList}
                    topNavList={topNavList}
                  />
                );
              }
              if (isAuth) {
                return (
                  <Redirect
                    to={{
                      pathname: defaultRoute,
                    }}
                  />
                );
              }
              return (
                <Redirect
                  to={{
                    pathname: '/login',
                    state: {
                      to: window.location.pathname,
                      search: window.location.search,
                    },
                  }}
                />
              );
            }}
          />
        )}
      </Auth.Consumer>
    </React.Fragment>
  );
};
PrivateRoute.defaultProps = {
  topNavList: [],
  setSubNav: () => '',
  requireAuthorization: false,
  isAuthorized: () => true, // if no auth function is provided we assume they can go to that link
  requiresMemberPortalAccess: false,
  requiresBillingPortalAccess: false,
};

PrivateRoute.propTypes = {
  RenderProp: PropTypes.oneOfType([PropTypes.node, PropTypes.func]).isRequired,
  setSubNav: PropTypes.func,
  subNavOrder: PropTypes.arrayOf(PropTypes.string).isRequired,
  subNavList: customProps.subNavList.isRequired,
  topNavList: customProps.topNavList,
  requireAuthorization: PropTypes.bool,
  isAuthorized: PropTypes.func,
  profile: PropTypes.object.isRequired,
  userGroupings: PropTypes.arrayOf(PropTypes.object).isRequired,
  userGroupingsIsLoading: PropTypes.bool.isRequired,
  requiresMemberPortalAccess: PropTypes.bool,
  requiresBillingPortalAccess: PropTypes.bool,
};
export default PrivateRoute;
