import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

// Theme & Core
import { breakpoints, colors } from '../../../Core/Theme';
import { useWindowDimensions } from '../../../Core/Utils';

// Components
import BaseButton from '../BaseButton';

const ActionBar = props => {
  const { actions, handleAction, title, classes, datatestid } = props;
  const { width } = useWindowDimensions();

  const handleClick = id => () => handleAction(id);
  const smWidth = Math.floor(actions.length ? 12 / actions.length : 0);
  const isOdd = actions.length % 2 === 1;

  return (
    <Grid container spacing={width <= breakpoints.MD ? 8 : 24}>
      {title && (
        <Grid item xs={12} className={classes.titleArea}>
          <div className={classes.title}> {title}</div>
          <div className={classes.titleLine} />
        </Grid>
      )}
      {actions.map((action, idx) => {
        const { name, id } = action;
        const isLast = idx === actions.length - 1;
        return (
          <Grid item key={id} xs={isLast && isOdd ? 12 : 6} sm={smWidth}>
            <BaseButton
              variant="quickLink"
              name={name}
              onClick={handleClick(id)}
              datatestid={`${datatestid}_${name}_button`}
            />
          </Grid>
        );
      })}
    </Grid>
  );
};
const styles = theme => ({
  titleArea: {
    fontFamily: 'VerlagBold',
    fontSize: 18,
    textAlign: 'center',
    position: 'relative',
    [theme.breakpoints.up(breakpoints.MD)]: {
      fontSize: 20,
      marginBottom: -14,
    },
    [theme.breakpoints.up(breakpoints.MOBILE)]: {
      textAlign: 'left',
    },
  },
  titleLine: {
    position: 'absolute',
    background: colors.middle,
    height: 1,
    left: 0,
    width: '100%',
    top: '50%',
    [theme.breakpoints.up(breakpoints.MOBILE)]: {
      display: 'none',
    },
  },
  title: {
    [theme.breakpoints.down(breakpoints.MOBILE)]: {
      zIndex: 4,
      width: 'fit-content',
      margin: 'auto',
      position: 'relative',
      background: colors.palette.secondary2.main,
      paddingLeft: 10,
      paddingRight: 10,
    },
  },
});

ActionBar.defaultProps = {
  actions: [],
  handleAction: () => {},
  title: '',
  datatestid: 'action_bar',
};
ActionBar.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  actions: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string,
  handleAction: PropTypes.func,
  datatestid: PropTypes.string,
};
export default withStyles(styles)(ActionBar);
