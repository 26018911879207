/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import Lodash from 'lodash';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Formik } from 'formik';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import MaskedInput from 'react-text-mask';
import { withStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';

import SubmitButton from '../../../../Components/Common/SubmitButton';
import { FieldInput } from '../../../../Components/Common';
import Redux from '../../../../Redux/Common/UserManager';
import FormikInputs from '../../../../Components/FormikInputs';

import { Icons } from '../../../../resources';
import { colors, breakpoints } from '../../../../Core/Theme';
import { PHONE_MASK } from '../../../../Core/constants';
import { DELIVERY_METHODS } from '../../types';
import { canAccessFeatureToggleInPrimaryLocation } from '../../../../Core/Utils/userPermissions';

const ContactInfo = props => {
  const {
    classes,
    onClose,
    userProfile,
    userSettings,
    showWithError,
    patchUserProfile,
    userProfileIsLoading,
    userProfileHasError,
    toggleDrawer,
    userGroupings,
  } = props;
  const { email: userEmail, phoneNumber: userPhoneNumber } = userProfile;

  const { t } = useTranslation();

  const [submittedForm, setSubmittedForm] = React.useState(false);
  const [success, setIsSuccess] = React.useState(false);
  const [userUsesSMS, setUserUsesSMS] = React.useState(false);

  React.useEffect(() => {
    if (!submittedForm) return;
    if (userProfileIsLoading) return;
    if (userProfileHasError) return;
    setIsSuccess(true);
    setTimeout(() => {
      toggleDrawer();
    }, 500);
  }, [submittedForm, userProfileIsLoading, userProfileHasError]);

  React.useEffect(() => {
    const hasSms = Object.keys(userSettings).reduce((acc, k) => {
      const setting = userSettings[k];
      const { events, featureKey } = setting;
      const canAccessFeature =
        !featureKey ||
        canAccessFeatureToggleInPrimaryLocation(
          userProfile,
          userGroupings,
          false,
          featureKey
        );
      if (!canAccessFeature) {
        return acc;
      }
      const settingHasSms = Object.keys(events).reduce((acc2, ek) => {
        const event = events[ek];
        const eventHasSms = Lodash.get(
          event,
          ['deliveryMethods', DELIVERY_METHODS.SMS],
          false
        );
        return acc2 || eventHasSms;
      }, false);
      return acc || settingHasSms;
    }, false);
    setUserUsesSMS(hasSms);
  }, [userSettings]);

  const getValidationSchema = () => {
    if (userUsesSMS) {
      return {
        phoneNumber: Yup.string()
          .required(t('settings.phone_required'))
          .matches(/\(\d{3}\)\s\d{3}-\d{4}/, t('settings.phone_format')),
      };
    }
    return {
      phoneNumber: Yup.string()
        .nullable()
        .matches(/\(\d{3}\)\s\d{3}-\d{4}/, t('settings.phone_format')),
    };
  };

  const validationShema = Yup.object().shape(getValidationSchema());

  const nullFunction = () => null;

  const setFieldAndTouchedWithMask = (setField, name) => e => {
    const val = Lodash.get(e, ['target', 'value'], '');
    setField(name, val);
  };

  const submitForm = values => {
    if (userProfileIsLoading) return;
    const { phoneNumber } = values;
    const sfId = Lodash.get(userProfile, 'sfId', null);
    if (!sfId) return;
    const profile = { phoneNumber: phoneNumber || null };

    patchUserProfile(profile, sfId);
    setSubmittedForm(true);
  };

  return (
    <div className={classes.container}>
      <div className={classes.closeIconContainer} onClick={onClose}>
        <Icons.CloseIcon />
      </div>
      <div className={classes.title}>{t('settings.edit_contact_info')}</div>
      <Formik
        initialValues={{
          email: userEmail || '',
          phoneNumber: userPhoneNumber || '',
        }}
        validationSchema={validationShema}
        onSubmit={submitForm}
      >
        {formik => {
          const { setFieldValue, values, touched, errors } = formik;
          const submitIsDisabled =
            showWithError && values.phoneNumber.length === 0;
          return (
            <form className={classes.form} onSubmit={formik.handleSubmit}>
              <div>
                <FormikInputs
                  input="fieldInput"
                  name="email"
                  label={t('settings.email_label')}
                  className={classes.fieldInput}
                  placeholder={t('settings.email_placeholder')}
                  disabled
                />
                <MaskedInput
                  id="phoneNumber"
                  mask={PHONE_MASK}
                  onChange={setFieldAndTouchedWithMask(
                    setFieldValue,
                    'phoneNumber'
                  )}
                  guide
                  render={(ref, fieldProps) => (
                    <FieldInput
                      name="phoneNumber"
                      inputRef={ref}
                      label={t('settings.phone_label')}
                      inputClasses={{
                        root: classes.meetingInputField,
                      }}
                      InputLabelProps={{
                        shrink: true,
                        FormLabelClasses: {
                          root: classes.formLabelRoot,
                        },
                      }}
                      InputProps={{
                        ...fieldProps,
                        disableUnderline: true,
                      }}
                      placeholder="(555) 555 - 5555"
                      handleOnChange={nullFunction}
                      value={values.phoneNumber}
                      hasError={
                        (touched.phoneNumber && errors.phoneNumber) ||
                        (!errors.phoneNumber &&
                          !values.phoneNumber &&
                          showWithError)
                      }
                      errorMessage={
                        errors.phoneNumber || t('settings.add_a_phone')
                      }
                    />
                  )}
                />
              </div>
              <div>
                <SubmitButton
                  defaultText={t('settings.update_contact_info')}
                  type="submit"
                  isLoading={userProfileIsLoading}
                  isSuccess={success}
                  disabled={submitIsDisabled}
                />
              </div>
            </form>
          );
        }}
      </Formik>
    </div>
  );
};

const styles = theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    paddingLeft: 40,
    paddingRight: 40,
    paddingTop: 25,
    paddingBottom: 30,
  },
  title: {
    fontFamily: 'VerlagBold',
    fontSize: 24,
    color: colors.black,
    marginTop: 27,
  },
  upperContainer: {},
  lowerContainer: {},
  closeIconContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100%',
    marginTop: 40,
  },
  fieldInput: {
    marginTop: 10,
  },
  formLabelRoot: {
    color: `${colors.black}`,
    fontSize: '16px',
    fontFamily: 'VerlagBold',
  },
  meetingInputField: {
    marginTop: 30,
    [theme.breakpoints.up(breakpoints.MOBILE)]: {
      marginTop: 20,
    },
  },
  emailInputField: {
    color: colors.black,
  },
});

const mapStateToProps = state => {
  return {
    userSettings: Redux.selectors.getUserSettings(state),
    userProfileIsLoading: Redux.selectors.getUserProfileIsLoading(state),
    userProfileHasError: Redux.selectors.getUserProfileHasError(state),
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      patchUserProfile: Redux.actions.patchUserProfile,
      patchSettings: Redux.actions.patchUserNotificationSettings,
    },
    dispatch
  );
};

ContactInfo.propTypes = {
  onClose: PropTypes.func.isRequired,
  userProfile: PropTypes.object.isRequired,
  userSettings: PropTypes.object.isRequired,
  userGroupings: PropTypes.arrayOf(PropTypes.object).isRequired,
  showWithError: PropTypes.bool.isRequired,
  patchUserProfile: PropTypes.func.isRequired,
  userProfileIsLoading: PropTypes.bool.isRequired,
  userProfileHasError: PropTypes.bool.isRequired,
  toggleDrawer: PropTypes.func.isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(ContactInfo));
