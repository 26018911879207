import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Formik, Form, FieldArray } from 'formik';
import * as Yup from 'yup';
import clsx from 'clsx';
import Lodash from 'lodash';
import MaskedInput from 'react-text-mask';
import { useTranslation } from 'react-i18next';

import { SubmitButton } from '../../Components/Common';
import FormikInputs from '../../Components/FormikInputs';
import { demographicFields, socialMediaFields } from './memberProfileFields';
import { colors, breakpoints } from '../../Core/Theme';
import { iterateObjectProperties } from '../../Core/Utils';
import { groupingFeatureRoleAccess } from '../../Core/Utils/userPermissions';

const profileSchema = t =>
  Yup.object().shape({
    name: Yup.string()
      .required(t('general.required_field'))
      .max(255, t('general.field_limited', { count: 255 })),
    companyName: Yup.string().required(),
    jobTitle: Yup.string()
      .nullable()
      .max(128, t('general.field_limited', { count: 128 })),
    email: Yup.string().required(),
    phoneNumber: Yup.string()
      .nullable()
      .matches(
        /\(\d{3}\)\s\d{3}-\d{4}/,
        t('contactProfile.phone_format_error')
      ),
    linkedinProfileUrl: Yup.string()
      .nullable()
      .max(255, t('general.field_limited', { count: 255 })),
    twitterHandle: Yup.string()
      .nullable()
      .max(255, t('general.field_limited', { count: 255 })),
    instagramHandle: Yup.string()
      .nullable()
      .max(255, t('general.field_limited', { count: 255 })),
    facebookProfileUrl: Yup.string()
      .nullable()
      .max(255, t('general.field_limited', { count: 255 })),
    personalBio: Yup.string()
      .nullable()
      .max(255, t('general.field_limited', { count: 255 })),
    preferredPronounId: Yup.string().nullable(),
    preferredPronounCustom: Yup.string()
      .nullable()
      .max(255, t('general.field_limited', { count: 255 }))
      .when('preferredPronounId', {
        is: 'other',
        then: Yup.string().required(t('general.required')),
      }),
  });

const setFieldAndTouchedWithMask = (setField, name) => e => {
  const val = Lodash.get(e, ['target', 'value'], '');
  setField(name, val);
};

const MemberProfile = props => {
  const {
    classes,
    preferredPronouns,
    personalBio,
    twitterHandle,
    instagramHandle,
    facebookProfileUrl,
    linkedinProfileUrl,
    name,
    email,
    jobTitle,
    companyName,
    patchUser,
    sfId,
    fetchUserPickList,
    setUserProfile,
    setDisplayMsg,
    phoneNumber,
    openSnack,
    userGroupings,
    userSettings,
    userProfile,
  } = props;
  const [preferredPronounsPicklist, setPreferredPronounPicklist] =
    React.useState(null);
  const { t } = useTranslation();

  const [isSuccess, setSubmitSuccess] = React.useState(false);
  const [userUsesSMS, setUserUsesSMS] = React.useState(false);
  const [preferredPronounId, setPreferredPronounId] =
    React.useState(preferredPronouns);
  const [preferredPronounCustom, setPreferredPronounCustom] =
    React.useState(preferredPronouns);

  React.useEffect(() => {
    fetchUserPickList().then(res => {
      const pickList = res.data?.preferredPronounId;
      setPreferredPronounPicklist(pickList);

      if (pickList) {
        let pronounIsCustom;
        if (!preferredPronouns) {
          pronounIsCustom = false;
        } else {
          pronounIsCustom = !Object.keys(pickList).reduce((acc, pronoun) => {
            return (
              acc ||
              (pickList[pronoun] &&
                preferredPronouns &&
                pickList[pronoun].toLowerCase() ===
                  preferredPronouns.toLowerCase())
            );
          }, false);
        }
        if (pronounIsCustom) {
          setPreferredPronounId('other');
          setPreferredPronounCustom(preferredPronouns);
        } else {
          setPreferredPronounCustom(null);
          setPreferredPronounId(
            Object.keys(pickList).find(
              key => pickList[key] === preferredPronouns
            )
          );
        }
      }
    });
  }, []);

  React.useEffect(() => {
    let userHasAccesToNotificationFeature = false;
    iterateObjectProperties(userSettings, (obj, key) => {
      const featureKey = Lodash.get(obj, ['featureKey'], null);
      if (featureKey) {
        const groupingDto = {
          groupings: userGroupings,
          groupingFeatureKey: featureKey,
        };
        const access = groupingFeatureRoleAccess.HasFeatureInPrimaryLocation({
          obj: groupingDto,
          profile: userProfile,
        });
        userHasAccesToNotificationFeature = access;
      }

      if (key !== 'smsEnabled' || !userHasAccesToNotificationFeature) return;

      const value = obj[key];
      if (value) {
        setUserUsesSMS(true);
      }
    });
  }, [userSettings]);

  let pronounOptions;
  if (preferredPronounsPicklist) {
    pronounOptions = Object.keys(preferredPronounsPicklist).reduce(
      (acc, item) => {
        const temp = {
          value: item,
          label: preferredPronounsPicklist[item],
        };
        return acc.concat(temp);
      },
      []
    );
    pronounOptions = pronounOptions.concat([
      { value: 'other', label: t('contactProfile.prefer_self_describe') },
    ]);
  } else {
    pronounOptions = [];
  }

  return (
    <Formik
      initialValues={{
        preferredPronounId,
        preferredPronounCustom,
        personalBio,
        twitterHandle,
        instagramHandle,
        facebookProfileUrl,
        linkedinProfileUrl,
        name,
        email,
        companyName,
        jobTitle,
        phoneNumber,
      }}
      enableReinitialize
      validationSchema={profileSchema(t)}
      validateOnBlur
      validateOnChange={false}
      onSubmit={(values, actions) => {
        const newPhoneNumber = Lodash.get(values, ['phoneNumber'], null);

        if (userUsesSMS && Lodash.isEmpty(newPhoneNumber)) {
          setDisplayMsg(t('contactProfile.sms_disable'));
          actions.setSubmitting(false);
          setSubmitSuccess(false);

          return null;
        }
        //  To filter out values that cannot be change
        const temp = Object.keys(values).reduce((acc, keyName) => {
          if (keyName === 'companyName' || keyName === 'email') {
            return acc;
          }

          if (
            keyName === 'preferredPronounId' &&
            values[keyName] &&
            values[keyName].includes('other')
          ) {
            return acc;
          }
          if (
            keyName === 'preferredPronounCustom' &&
            values.preferredPronounId &&
            !values.preferredPronounId.includes('other')
          ) {
            return acc;
          }
          if (keyName === 'phoneNumber') {
            if (!values[keyName]) {
              acc[keyName] = null;
              return acc;
            }
            acc[keyName] = values[keyName].replace(/\D/g, '');
            return acc;
          }
          acc[keyName] = values[keyName];
          return acc;
        }, {});

        return patchUser(temp, sfId).then(
          responseUserProfile => {
            const objUserProfile = Lodash.get(
              responseUserProfile,
              ['data'],
              {}
            );
            setUserProfile(objUserProfile);
            setDisplayMsg(null);
            actions.setSubmitting(false);
            setSubmitSuccess(true);
            openSnack();
            setTimeout(() => setSubmitSuccess(false), 3000);
          },
          () => {
            setDisplayMsg(t('general.updates_failed'));
            actions.setSubmitting(false);
            setSubmitSuccess(false);
          }
        );
      }}
      render={renderProps => {
        const {
          setFieldValue,
          values,
          handleSubmit,
          isSubmitting,
          handleBlur,
        } = renderProps;

        return (
          <Form className={classes.form} onSubmit={handleSubmit}>
            <section className={classes.preferredPronouns}>
              <FormikInputs
                input="select"
                name="preferredPronounId"
                options={pronounOptions}
                label={t('contactProfile.labels.pronoun')}
                className={classes.select}
                displayEmpty
              />
              {values.preferredPronounId &&
              values.preferredPronounId.includes('other') ? (
                <div className={classes.flex}>
                  <FormikInputs
                    input="fieldInput"
                    name="preferredPronounCustom"
                    label={t('contactProfile.labels.custom_pronoun')}
                    placeholder={t('contactProfile.placeholders.pronoun')}
                    className={classes.flexItem}
                    InputLabelProps={{
                      shrink: true,
                      FormLabelClasses: {
                        root: classes.customLabel,
                      },
                    }}
                  />
                </div>
              ) : null}
            </section>
            <section className={classes.flexContainer}>
              <FieldArray
                name="contactDemographics"
                render={() => (
                  <>
                    {demographicFields.map(field => {
                      const input = (ref, fieldProps) => {
                        return (
                          <div className={classes.flex}>
                            <FormikInputs
                              input="fieldInput"
                              name={field.name}
                              label={t(field.label)}
                              inputRef={ref}
                              className={classes.flexItem}
                              placeholder={
                                field.placeholder ? t(field.placeholder) : ''
                              }
                              onBlur={handleBlur}
                              disabled={field.disabled}
                              type={field.type ? field.type : 'text'}
                              InputProps={{
                                ...fieldProps,
                                disableUnderline: true,
                              }}
                            />
                          </div>
                        );
                      };

                      return field.mask ? (
                        <MaskedInput
                          mask={field.mask}
                          id="phone_number_input"
                          onChange={setFieldAndTouchedWithMask(
                            setFieldValue,
                            field.name
                          )}
                          guide
                          onBlur={handleBlur}
                          render={(ref, fieldProps) => input(ref, fieldProps)}
                        />
                      ) : (
                        input(null, {})
                      );
                    })}
                  </>
                )}
              />
            </section>
            <section>
              <FormikInputs
                input="fieldInput"
                name="personalBio"
                label={t('contactProfile.labels.short_bio')}
                className={clsx(classes.shortBio)}
                placeholder={t('contactProfile.placeholders.short_bio')}
                onBlur={handleBlur}
                multiline
              />
            </section>
            <section>
              <div className={classes.sectionTitle}> Connect </div>
              <p className={classes.label}>
                {t('contactProfile.social_profile_description')}
              </p>
            </section>
            <FieldArray
              name="contactSocialMedia"
              render={() => (
                <section className={classes.flexContainer}>
                  <>
                    {socialMediaFields.map(field => {
                      return (
                        <div className={classes.flex}>
                          <FormikInputs
                            input="fieldInput"
                            name={field.name}
                            label={t(field.label)}
                            className={classes.flexItem}
                            placeholder={t(field.placeHolder)}
                            onBlur={handleBlur}
                          />
                        </div>
                      );
                    })}
                  </>
                </section>
              )}
            />
            <SubmitButton
              className={classes.submitButton}
              defaultText={t('general.save_updates')}
              type="submit"
              isLoading={isSubmitting}
              isSuccess={isSuccess}
            />
          </Form>
        );
      }}
    />
  );
};

const styles = theme => ({
  form: {
    [theme.breakpoints.up(breakpoints.MOBILE)]: {
      marginLeft: 55,
      maxWidth: 720,
    },
    [theme.breakpoints.down(breakpoints.MOBILE)]: {
      marginTop: 40,
    },
  },
  label: {
    padding: 0,
    margin: 0,
    fontFamily: 'VerlagLight',
    fontSize: 16,
    [theme.breakpoints.up(breakpoints.MOBILE)]: {
      fontSize: 18,
    },
  },
  select: {
    padding: 0,
    marginTop: 0,
    [theme.breakpoints.up(breakpoints.MOBILE)]: {
      maxWidth: '290px',
      marginRight: 70,
      marginTop: 30,
    },
  },
  shortBio: {
    fontFamily: 'VerlagLight',
    marginTop: 30,
    marginBottom: 0,
    fontSize: 18,
    color: colors.dark,
    [theme.breakpoints.up(breakpoints.MOBILE)]: {
      maxWidth: 'calc(100% - 70px)',
    },
  },
  sectionTitle: {
    fontFamily: 'VerlagBold',
    fontSize: 16,
    marginTop: 40,
    marginBottom: 10,
    [theme.breakpoints.up(breakpoints.MOBILE)]: {
      fontSize: 20,
    },
  },
  flexContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
  },
  flexItemContents: {
    [theme.breakpoints.up(breakpoints.MOBILE)]: {
      width: '290px',
    },
  },
  flexItem: {
    color: colors.dark,
    marginTop: 30,
    [theme.breakpoints.up(breakpoints.MOBILE)]: {
      maxWidth: '290px',
      marginRight: 70,
    },
  },
  flex: {
    [theme.breakpoints.up(breakpoints.MOBILE)]: {
      width: '360px',
    },
    width: '100%',
  },

  preferredPronouns: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
  },
  submitButton: {
    marginTop: 50,
    [theme.breakpoints.up(breakpoints.MOBILE)]: {
      width: 290,
    },
    marginBottom: 50,
  },
  customLabel: {
    minWidth: 290,
  },
});

MemberProfile.defaultProps = {
  classes: {},
  preferredPronouns: '',
  personalBio: '',
  twitterHandle: '',
  instagramHandle: '',
  facebookProfileUrl: '',
  linkedinProfileUrl: '',
  email: '',
  jobTitle: '',
  companyName: '',
  phoneNumber: '',
  name: '',
};

MemberProfile.propTypes = {
  classes: PropTypes.shape({}),
  userGroupings: PropTypes.arrayOf(PropTypes.object).isRequired,
  userSettings: PropTypes.shape({}).isRequired,
  userProfile: PropTypes.shape({}).isRequired,
  preferredPronouns: PropTypes.string,
  personalBio: PropTypes.string,
  twitterHandle: PropTypes.string,
  instagramHandle: PropTypes.string,
  facebookProfileUrl: PropTypes.string,
  linkedinProfileUrl: PropTypes.string,
  name: PropTypes.string,
  email: PropTypes.string,
  setUserProfile: PropTypes.func.isRequired,
  jobTitle: PropTypes.string,
  companyName: PropTypes.string,
  patchUser: PropTypes.func.isRequired,
  sfId: PropTypes.string.isRequired,
  fetchUserPickList: PropTypes.func.isRequired,
  setDisplayMsg: PropTypes.func.isRequired,
  phoneNumber: PropTypes.string,
  openSnack: PropTypes.func.isRequired,
};

export default withStyles(styles)(MemberProfile);
