import * as React from 'react';
import { useEffect, useMemo } from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useTranslation } from 'react-i18next';

// Components
import { Panel, DrawerTab, Loading, Text } from '../Common';
import { Card } from './Components';
import SmartMeetingRoomBooking from '../SmartMeetingRoomBooking/SmartMeetingRoomBooking';
import ReserveDeskWithApi from '../ReserveDeskWithApi';
import SeatReservationSummary from '../SeatReservationSummary/SeatReservationSummary';

// Core & Utils
import { date as DateUtils } from '../../Core/Utils';
import colors from '../../Core/Theme/colors';
import { fetchAnyReservations } from '../../Core/Api';
import {
  trackOpenDeskReservationInUpcomingClick,
  trackOpenOfficeReservationInUpcomingClick,
  trackOpenRoomReservationInUpcomingClick,
  trackOpenSeatReservationInUpcomingClick,
  trackOpenUpcomingClick,
} from '../../Core/Tracking';
import { getLocationsById } from '../../Redux/Common/Locations/selectors';
import { getCardDataFromAnyReservation } from './utils';

// Constants
import { UPCOMING_LIMIT, UPCOMING_PANEL_WIDTH } from './constants';
import { RESERVATION_TYPES } from '../../Models/anyReservation';
import OfficeReservationSummary from '../OfficeReservationSummary/OfficeReservationSummary';

const UpcomingReservations = props => {
  const {
    classes,
    profile,
    history,
    locationsById,
    renderFooter,
    urlControlType,
    urlPathname,
    upcomingRefreshCount,
    tabVariant,
    onOpen,
    onClose,
    footerText,
    onClickFooter,
    isOpen,
    datatestid,
    onSuccess,
    tabClassName,
  } = props;
  const { t } = useTranslation();

  const [isUpcomingOpen, setUpcomingOpen] = React.useState(isOpen);
  const [reservations, setReservations] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(true);
  const [conferenceBooking, setConferenceBooking] = React.useState(null);
  const [deskReservation, setDeskReservation] = React.useState(null);
  const [seatBooking, setSeatBooking] = React.useState(null);
  const [officeReservation, setOfficeReservation] = React.useState(null);

  const closeUpcoming = () => {
    setUpcomingOpen(false);
    onClose();
  };

  const openUpcoming = () => {
    setUpcomingOpen(true);
    onOpen();
  };

  const onClickTab = () => {
    trackOpenUpcomingClick();
    openUpcoming();
  };

  useEffect(() => {
    if (isOpen) {
      openUpcoming();
    } else {
      closeUpcoming();
    }
  }, [isOpen]);

  const fetchData = () => {
    setIsLoading(true);
    fetchAnyReservations({
      limit: UPCOMING_LIMIT,
      startEpoch: DateUtils.getDateInMoment().unix(),
      userSfId: profile?.sfId,
      includeOngoing: true,
      reservationTypes: [
        RESERVATION_TYPES.DESK_RESERVATION,
        RESERVATION_TYPES.SEAT_BOOKING,
        RESERVATION_TYPES.CONFERENCE_BOOKING,
        RESERVATION_TYPES.OFFICE_RESERVATION,
      ],
    }).then(objResponse => {
      const { data } = objResponse;
      if (!data) {
        return;
      }
      const { reservations: newReservations } = data;
      setReservations(newReservations);
      setIsLoading(false);
    });
  };

  useEffect(() => {
    if (profile.sfId) {
      fetchData();
    }
  }, [upcomingRefreshCount, profile.sfId]);

  const dataForReservations = useMemo(() => {
    return reservations.map(r =>
      getCardDataFromAnyReservation(r, t, classes, profile)
    );
  }, [reservations]);

  const handleOnBookingClick = booking => {
    trackOpenRoomReservationInUpcomingClick();
    setConferenceBooking(booking);
  };

  const handleOnDeskReservationClick = reservation => {
    trackOpenDeskReservationInUpcomingClick();
    return setDeskReservation(reservation);
  };

  const handleOnSeatBookingClick = booking => {
    trackOpenSeatReservationInUpcomingClick();
    setSeatBooking(booking);
  };

  const handleOnOfficeReservationClick = booking => {
    trackOpenOfficeReservationInUpcomingClick();
    setOfficeReservation(booking);
  };

  const onClick = anyReservation => {
    const { type, reservation } = anyReservation;
    switch (type) {
      case RESERVATION_TYPES.CONFERENCE_BOOKING:
        return handleOnBookingClick(reservation);
      case RESERVATION_TYPES.DESK_RESERVATION:
        return handleOnDeskReservationClick(reservation);
      case RESERVATION_TYPES.SEAT_BOOKING:
        return handleOnSeatBookingClick(reservation);
      case RESERVATION_TYPES.OFFICE_RESERVATION:
        return handleOnOfficeReservationClick(reservation);
      default:
        return null;
    }
  };

  const renderUpcomingReservations = () => {
    if (isLoading) {
      return <Loading color={colors.white} />;
    }

    if (!reservations.length)
      return (
        <Text
          text={t('Upcoming.no_reservations')}
          className={classes.noReservations}
        />
      );

    return dataForReservations.map(data => (
      <Card onClick={onClick} key={data.reservation.id} {...data} />
    ));
  };

  const setDeskReservations = deskReservations => {
    setDeskReservation(deskReservations?.[0]);
  };

  const handleSuccess = type => () => {
    onSuccess(type);
    fetchData();
  };

  return (
    <>
      {!isUpcomingOpen && (
        <DrawerTab
          datatestid={datatestid}
          onClick={onClickTab}
          variant={tabVariant}
          count={reservations.length ? reservations.length : null}
          customClasses={{ tab: tabClassName }}
        >
          {t('general.upcoming')}
        </DrawerTab>
      )}
      <Panel
        isOpen={isUpcomingOpen}
        onClickClose={closeUpcoming}
        title={t('Upcoming.title')}
        customClasses={{ title: classes.title }}
        renderFooter={renderFooter}
        textFooter={footerText}
        onClickButtonFooter={onClickFooter}
        hasFooter
        width={UPCOMING_PANEL_WIDTH}
      >
        {renderUpcomingReservations()}
      </Panel>

      <SmartMeetingRoomBooking
        isOpen={!!conferenceBooking}
        onClose={() => {
          setConferenceBooking(null);
        }}
        objBooking={conferenceBooking}
        onSuccess={handleSuccess(RESERVATION_TYPES.CONFERENCE_BOOKING)}
        history={history}
        urlPathname={
          urlControlType === RESERVATION_TYPES.CONFERENCE_BOOKING
            ? urlPathname
            : null
        }
      />
      <ReserveDeskWithApi
        isOpen={!!deskReservation}
        onClose={() => setDeskReservation(null)}
        reservations={[deskReservation]}
        isCancel
        history={history}
        setReservations={setDeskReservations}
        onSubmit={handleSuccess(RESERVATION_TYPES.DESK_RESERVATION)}
        urlPathname={
          urlControlType === RESERVATION_TYPES.DESK_RESERVATION
            ? urlPathname
            : null
        }
      />
      <SeatReservationSummary
        isOpen={!!seatBooking}
        onClose={() => setSeatBooking(null)}
        reservation={seatBooking}
        profile={profile}
        locationsById={locationsById}
        history={history}
        setSelectedReservation={setSeatBooking}
        onSeatReservationUpdated={handleSuccess(RESERVATION_TYPES.SEAT_BOOKING)}
        urlPathname={
          urlControlType === RESERVATION_TYPES.SEAT_BOOKING ? urlPathname : null
        }
      />
      <OfficeReservationSummary
        profile={profile}
        onClose={() => setOfficeReservation(null)}
        reservation={officeReservation}
        onSuccess={handleSuccess(RESERVATION_TYPES.OFFICE_RESERVATION)}
      />
    </>
  );
};

const styles = () => ({
  tz: {
    color: colors.darkGray,
    paddingLeft: 3,
  },
  noReservations: {
    fontFamily: 'VerlagBold',
    color: colors.white,
  },
});

UpcomingReservations.defaultProps = {
  renderFooter: () => null,
  urlControlType: null,
  urlPathname: null,
  tabVariant: 'light',
  onOpen: () => {},
  onClose: () => {},
  footerText: '',
  onClickFooter: () => null,
  isOpen: false,
  datatestid: 'upcoming_reservations',
  onSuccess: () => {},
  tabClassName: '',
};

UpcomingReservations.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.shape({}).isRequired,
  profile: PropTypes.object.isRequired,
  locationsById: PropTypes.object.isRequired,
  renderFooter: PropTypes.func,
  urlControlType: PropTypes.string,
  urlPathname: PropTypes.string,
  upcomingRefreshCount: PropTypes.number.isRequired,
  tabVariant: PropTypes.string,
  onOpen: PropTypes.func,
  onClose: PropTypes.func,
  footerText: PropTypes.string,
  onClickFooter: PropTypes.func,
  isOpen: PropTypes.bool,
  datatestid: PropTypes.string,
  onSuccess: PropTypes.func,
  tabClassName: PropTypes.string,
};

const mapStateToProps = state => {
  const { userManager } = state;
  return {
    profile: userManager.profile,
    locationsById: getLocationsById(state),
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators({}, dispatch);
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(UpcomingReservations));
