import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { colors } from '../../../Core/Theme';

const FilterButton = props => {
  const { classes, label, isSelected, key, onClick, className, isDisabled } =
    props;

  return (
    <button
      className={clsx([
        classes.filterButton,
        isSelected && classes.filterButtonActive,
        className,
      ])}
      key={key}
      onClick={onClick}
      type="button"
      disabled={isDisabled}
    >
      {label}
    </button>
  );
};

const styles = () => ({
  filterButton: {
    boxShadow: 'none',
    fontWeight: 'normal',
    background: 'transparent',
    fontFamily: 'VerlagBook',
    height: 38,
    fontSize: 18,
    paddingLeft: 12,
    paddingRight: 12,
    borderRadius: 19,
    border: `1px solid ${colors.gray}`,
    marginTop: 10,
    '&:focus:not(.focus-visible)': {
      outline: 0,
    },
    '&:hover': {
      color: colors.black,
    },
    cursor: 'pointer',
    backgroundColor: colors.white,
  },
  filterButtonActive: {
    border: `2.5px solid ${colors.palette.primary.light}`,
    borderWidth: 2,
  },
});
FilterButton.defaultProps = {
  className: '',
  onClick: () => null,
  isDisabled: false,
};

FilterButton.propTypes = {
  isSelected: PropTypes.bool.isRequired,
  label: PropTypes.string.isRequired,
  key: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  onClick: PropTypes.func,
  isDisabled: PropTypes.bool,
};

export default withStyles(styles)(FilterButton);
