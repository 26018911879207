import PropTypes from 'prop-types';
import Auth from '@industriousoffice/member-portal-auth';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Redirect } from 'react-router-dom';
import Redux from '../../Redux/Common/UserManager';
import { HomeRoute } from '../../Core/constants';

const Logout = ({ logout }) => {
  Auth.signOut();
  logout();

  return (
    <Redirect
      from="/home/logout"
      to={{
        pathname: HomeRoute,
      }}
    />
  );
};

Logout.propTypes = {
  logout: PropTypes.func.isRequired,
};

const mapDispatchToProps = dispatch => {
  const { actions } = Redux;
  return bindActionCreators(
    {
      logout: actions.logout,
    },
    dispatch
  );
};

export default connect(null, mapDispatchToProps)(Logout);
