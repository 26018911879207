import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { Popup as PopupMapbox } from 'react-map-gl';
import clsx from 'clsx';

import { colors } from '../../../../Core/Theme';

const Popup = props => {
  const {
    classes,
    message,
    onClosePopup,
    longitude,
    latitude,
    anchor,
    hasError,
  } = props;
  return (
    <>
      {longitude && latitude ? (
        <PopupMapbox
          latitude={latitude}
          longitude={longitude}
          closeButton={false}
          onClose={onClosePopup}
          anchor={anchor}
        >
          <div
            className={clsx(classes.tooltip, hasError && classes.tooltipError)}
          >
            {message}
          </div>
        </PopupMapbox>
      ) : null}
    </>
  );
};

const styles = {
  tooltip: {
    fontFamily: 'VerlagBook',
    fontSize: 12,
    padding: '9px 15px',
    maxWidth: 246,
  },
  tooltipError: {
    color: colors.palette.error.main,
  },
};
Popup.defaultProps = {
  message: '',
  anchor: 'top',
  hasError: false,
};

Popup.propTypes = {
  classes: PropTypes.object.isRequired,
  message: PropTypes.string,
  onClosePopup: PropTypes.func.isRequired,
  longitude: PropTypes.number.isRequired,
  latitude: PropTypes.number.isRequired,
  anchor: PropTypes.string,
  hasError: PropTypes.bool,
};

export default withStyles(styles)(Popup);
