/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

import SelectInputBoxWithGroups from '../SelectInputBoxWithGroups';
import TextButton from '../TextButton';
import { getIsMobile } from '../../../Core/Utils';
import BaseLocationListDrawer from '../BaseLocationListDrawer';
import { SkeletonLoader } from '../index';
import { breakpoints } from '../../../Core/Theme';

const BaseLocationList = props => {
  const {
    classes,
    onChange,
    datatestid,
    label,
    renderValue,
    isLoading,
    hasError,
    profile,
    options,
    value,
    onReturnHome,
    shouldRenderReturnHome,
    loadingDisplayValue,
    titleKey,
    getIsItemSelected,
    disabledTooltipText,
  } = props;

  const { t } = useTranslation();

  const [isOpenModal, setIsOpenModal] = React.useState(false);
  const isMobile = getIsMobile();

  const renderViewHomeLocation = () => {
    return (
      <TextButton
        text={t('locationEditor.home_cta')}
        onClick={onReturnHome}
        className={classes.homeButton}
      />
    );
  };
  const toggleLocationModal = () => {
    if (isMobile) {
      return setIsOpenModal(!isOpenModal);
    }

    return null;
  };

  const getRenderValue = l => {
    if (renderValue) return renderValue(l);
    return l?.displayName ?? l?.externalName;
  };

  if (isLoading) {
    return <SkeletonLoader className={classes.loadingRow} />;
  }
  return (
    <>
      {isMobile && (
        <BaseLocationListDrawer
          isOpen={isOpenModal}
          onCloseDrawer={toggleLocationModal}
          onChange={onChange}
          withHeader={false}
          titleKey={titleKey}
          options={options}
          onReturnHome={onReturnHome}
          profile={profile}
          shouldRenderReturnHome={shouldRenderReturnHome}
          getIsItemSelected={getIsItemSelected}
          disabledTooltipText={disabledTooltipText}
        />
      )}

      <div className={classes.main}>
        <div onClick={toggleLocationModal}>
          <SelectInputBoxWithGroups
            {...props}
            options={options}
            onChange={onChange}
            value={value}
            renderValue={getRenderValue}
            label={label || t('general.location').toUpperCase()}
            datatestid={datatestid}
            readOnly={isMobile}
            alwaysShowCarat
            hasError={hasError}
            loadingDisplayValue={loadingDisplayValue}
            isLoading={isLoading}
            disabledTooltipText={disabledTooltipText}
          />
        </div>
        {shouldRenderReturnHome ? renderViewHomeLocation() : null}
      </div>
    </>
  );
};

const styles = theme => ({
  main: {},
  homeButton: {
    marginTop: 5,
    paddingLeft: 0,
    fontSize: 18,
  },
  loadingRow: {
    height: 40,
    [theme.breakpoints.up(breakpoints.MOBILE)]: {
      minWidth: 200,
    },
  },
});

BaseLocationList.defaultProps = {
  datatestid: '',
  gaCategory: '',
  label: null,
  renderValue: null,
  value: null,
  hasError: false,
  loadingDisplayValue: null,
  titleKey: null,
  getIsItemSelected: () => false,
  disabledTooltipText: null,
};

BaseLocationList.propTypes = {
  classes: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  gaCategory: PropTypes.string,
  datatestid: PropTypes.string,
  label: PropTypes.string,
  renderValue: PropTypes.func,
  isLoading: PropTypes.bool.isRequired,
  hasError: PropTypes.bool,
  profile: PropTypes.object.isRequired,
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
  value: PropTypes.object,
  onReturnHome: PropTypes.func.isRequired,
  shouldRenderReturnHome: PropTypes.bool.isRequired,
  loadingDisplayValue: PropTypes.string,
  titleKey: PropTypes.string,
  getIsItemSelected: PropTypes.func,
  disabledTooltipText: PropTypes.string,
  t: PropTypes.func.isRequired,
};

export default withStyles(styles)(BaseLocationList);
