import { withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import PropTypes from 'prop-types';

// Theme
import { breakpoints, colors } from '../../../Core/Theme';

const FloatingActionDescription = ({ classes, description, className }) => {
  return (
    <div className={clsx(classes.container, className)}>
      {description.map((d, idx) => (
        <p
          key={d}
          className={clsx(
            classes.description,
            idx > 0 && classes.smallDescription
          )}
        >
          {d}
        </p>
      ))}
    </div>
  );
};

const styles = theme => ({
  container: {
    [theme.breakpoints.up(breakpoints.MOBILE)]: {
      bottom: '2.5rem',
      left: 0,
      right: 0,
      width: 555,
      margin: '0 auto',
      display: 'flex',
      alignItems: 'center',
      borderRadius: 3,
      flexDirection: 'column',
      padding: '0px 20px',
      boxSizing: 'border-box',
    },
    background: colors.palette.tertiary2.main,
    boxShadow: '0 0 4px 0 rgba(0, 0, 0, 0.25)',
    position: 'fixed',
    padding: '0px 20px',
    bottom: 0,
    width: 'calc(100% - 40px)',
  },
  description: {
    textAlign: 'center',
    fontFamily: 'VerlagBold',
    fontSize: 20,
    color: colors.white,
    margin: '15px 0px',
  },
  smallDescription: {
    display: 'block',
    textAlign: 'center',
    fontFamily: 'VerlagBook',
    fontSize: 20,
    color: colors.white,
    margin: '15px 0px',
    marginTop: -10,
  },
});

FloatingActionDescription.defaultProps = {
  className: 'floating-description',
};

FloatingActionDescription.propTypes = {
  description: PropTypes.arrayOf(PropTypes.string).isRequired,
  className: PropTypes.arrayOf(PropTypes.string),
};

export default withStyles(styles)(FloatingActionDescription);
