import * as React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import {
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  Typography,
  MenuItem,
  withStyles,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

import { Text, LabelChip } from '../Common';
import {
  trackNavigationClick,
  trackTopMenuCategoryNavigation,
} from '../../Core/Tracking';
import { CONSTS } from './styles';
import { colors } from '../../Core/Theme';

const toggleIsExpanded = (isExpanded, setIsExpanded) => () => {
  isExpanded ? setIsExpanded(false) : setIsExpanded(true);
};

const NavPanelMenu = props => {
  const { classes, name, navTabs, onClose, isOpen } = props;
  const [isExpanded, setIsExpanded] = React.useState(true);
  const { t } = useTranslation();

  const navItems = navTabs.map(navTab => {
    return (
      <MenuItem
        className={clsx(
          classes.menuAccordionItem,
          navTab.withBottomBorder && classes.bottomBorder
        )}
        key={`navpanel_${navTab.path}`}
      >
        <Link
          to={navTab.path}
          className={classes.link}
          onClick={() => {
            navTab.onClick?.();
            if (navTab.isExternal) {
              window.open(navTab.path, '_blank');
            }
            trackNavigationClick({ event_label: t(navTab.name) });
            onClose(!isOpen);
            setIsExpanded(false);
          }}
          datatestid={`home_${navTab.name}_navigation_button`}
        >
          <Text
            textStyle="h5"
            text={t(
              navTab.sidePanelPathName || navTab.shortPathName || navTab.name
            )}
            className={clsx(classes.text, navTab.className)}
          />
          {navTab.isBeta ? (
            <LabelChip
              className={classes.labelChip}
              label={t('navigation.beta')}
            />
          ) : null}
        </Link>
      </MenuItem>
    );
  });

  return (
    <ExpansionPanel
      expanded={isExpanded}
      onChange={toggleIsExpanded(isExpanded, setIsExpanded)}
      className={classes.menuAccordion}
      datatestid={`home_${name}_navigation_button`}
    >
      <ExpansionPanelSummary
        expandIcon={
          <ExpandMoreIcon className={classes.menuItemAccordionExpandIcon} />
        }
        aria-controls="panel1a-content"
        id="panel1a-header"
        classes={{
          root: classes.menuItemAccordionHeader,
          expanded: classes.menuItemAccordionHeaderExpanded,
        }}
        onClick={trackTopMenuCategoryNavigation({ event_label: t(name) })}
      >
        <Typography className={classes.menuItemAccordionTitle}>
          {t(name)}
        </Typography>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails className={classes.menuItemAccordionDetails}>
        {navItems}
      </ExpansionPanelDetails>
    </ExpansionPanel>
  );
};

const styles = () => ({
  menuAccordion: {
    border: '0px',
    boxShadow: 'none',
    margin: 0,
    padding: 0,
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:last-child': {
      borderRadius: 0,
    },
    '&:before': {
      display: 'none',
    },
    backgroundColor: colors.darkest,
    '&:focus': {
      backgroundColor: colors.darkest,
    },
    '&:hover': {
      backgroundColor: colors.darkest,
    },
  },

  menuItemAccordionExpandIcon: {
    color: 'white',
  },

  menuItemAccordionHeader: {
    borderBottom: '0px',
    marginBottom: -1,
    maxHeight: '49px !important',
    minHeight: '49px !important',
  },

  menuItemAccordionHeaderExpanded: {
    '&:first-child': {
      margin: '0px 0 0px 0',
    },
  },

  menuItemAccordionTitle: {
    color: colors.white,
    fontSize: '18px',
    paddingLeft: `calc(${CONSTS.MENU_ITEM_PADDING} - 20px)`,
  },

  menuItemAccordionDetails: {
    margin: 0,
    padding: 0,
    display: 'block',
    backgroundColor: colors.darkest,
    width: '100%',
  },

  menuAccordionItem: {
    backgroundColor: colors.darkest,
    color: colors.white,
    marginRight: 40,
    marginLeft: 40,
    paddingLeft: 0,
    paddingRight: 0,
    '&:focus': {
      backgroundColor: colors.dark,
      marginRight: CONSTS.MENU_ITEM_ACCENT_WIDTH,
    },
    '&:hover': {
      backgroundColor: colors.dark,
      marginRight: CONSTS.MENU_ITEM_ACCENT_WIDTH,
    },
  },

  link: {
    textDecoration: 'none',
    color: colors.white,
    width: '100%',
    display: 'contents',
  },
  labelChip: {
    marginLeft: 8,
  },
  bottomBorder: {
    borderBottom: 'solid 1px white',
  },
  text: {
    fontWeight: 'normal',
  },
});

NavPanelMenu.defaultProps = {
  navTabs: [],
  classes: {},
  name: '',
};

NavPanelMenu.propTypes = {
  classes: PropTypes.object,
  navTabs: PropTypes.arrayOf(PropTypes.shape({})),
  name: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default withStyles(styles)(NavPanelMenu);
