import typeToReducer from 'type-to-reducer';
import Lodash from 'lodash';

import * as types from './actionTypes';

const initialState = {
  selectedGrouping: {},
  queriedGroupings: [],
  locationDetails: {
    loading: false,
    data: {
      byId: {},
      allIds: [],
    },
  },
};

export const setSelectedGrouping = (state, action) => {
  const selectedGrouping = Lodash.get(action, ['payload'], {});
  const groupingId = selectedGrouping?.idGrouping;
  return {
    ...state,
    selectedGrouping,
    queriedGroupings: [...state.queriedGroupings, groupingId],
  };
};

const onLoadingLocationDetailsPending = state => {
  return {
    ...state,
    locationDetails: {
      ...state.locationDetails,
      loading: true,
    },
  };
};

const onLoadLocationDetailsFulfilled = (state, action) => {
  const locationDetails = Lodash.get(action, ['payload'], {});
  const { sfId } = locationDetails;

  return {
    ...state,
    locationDetails: {
      ...state.locationDetails,
      data: {
        byId: { ...state.locationDetails.data.byId, [sfId]: locationDetails },
        allIds: [...state.locationDetails.data.allIds, sfId],
      },
      loading: false,
    },
  };
};

const onLoadLocationDetailsRejected = state => {
  return {
    ...state,
    locationDetails: {
      ...state.locationDetails,
      loading: false,
    },
  };
};

const reducer = typeToReducer(
  {
    [types.SET_GROUPING]: setSelectedGrouping,
    [types.LOAD_LOCATION_DETAILS]: {
      PENDING: onLoadingLocationDetailsPending,
      FULFILLED: onLoadLocationDetailsFulfilled,
      REJECTED: onLoadLocationDetailsRejected,
    },
  },
  initialState
);

export default reducer;
