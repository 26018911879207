import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

// Components
import { Snack, InfoBanner } from '../../Components/Common';
import UserManager from '../../Redux/Common/UserManager';
import { getUserProfileAccount } from '../../Redux/Common/UserManager/selectors';
import AccountProfile from './AccountProfile';
import { Panel } from '../../Components/DemographicsCommon';

// Theme
import { breakpoints, colors } from '../../Core/Theme';

// API
import {
  updateAccountAvatar,
  putAccount,
  fetchAccountPickList,
} from '../../Core/Api';

const AccountMainContainer = props => {
  const { classes, userAccountProfile, setUserAccountProfile } = props;
  const {
    name,
    contactEmail,
    websiteUrl,
    companySize: companySizeId,
    description,
    twitterHandle,
    instagramHandle,
    facebookProfileUrl,
    linkedinProfileUrl,
    sfId,
    photoUrl,
  } = userAccountProfile;

  const { t } = useTranslation();

  const [isModalOpen, setModalOpen] = React.useState(false);
  const [displayImage, setDisplayImage] = React.useState(photoUrl);
  const [displayMsg, setDisplayMsg] = React.useState(null);
  const [isConfirmSnackOpen, setConfirmSnackOpen] = React.useState(false);

  const toggleModal = () => {
    isModalOpen ? setModalOpen(false) : setModalOpen(true);
  };

  const openSnack = () => setConfirmSnackOpen(true);
  const closeSnack = () => setConfirmSnackOpen(false);

  const onSubmit = userSfId => {
    return function submit(image) {
      return updateAccountAvatar(userSfId, image).then(
        () => {
          openSnack();
        },
        () => setDisplayMsg(t('general.updates_failed'))
      );
    };
  };

  return (
    <main className={classes.content}>
      {displayMsg && (
        <InfoBanner
          variant="error"
          theme="light"
          text={displayMsg}
          className={classes.errorMessage}
        />
      )}
      <h1 className={classes.header}>Company Profile</h1>
      <div className={classes.mainView}>
        <Panel
          className={classes.panel}
          src={displayImage}
          name={name}
          toggleModal={toggleModal}
          sfId={sfId}
          setDisplayImage={setDisplayImage}
          image={displayImage}
          isModalOpen={isModalOpen}
          imageSubmit={onSubmit(sfId)}
          imageTitle={t('account.edit_logo')}
        />
        <AccountProfile
          name={name}
          contactEmail={contactEmail}
          websiteUrl={websiteUrl}
          companySizeId={companySizeId}
          description={description}
          twitterHandle={twitterHandle}
          instagramHandle={instagramHandle}
          facebookProfileUrl={facebookProfileUrl}
          linkedinProfileUrl={linkedinProfileUrl}
          sfId={sfId}
          putAccount={putAccount}
          fetchAccountPickList={fetchAccountPickList}
          setUserAccountProfile={setUserAccountProfile}
          setDisplayMsg={setDisplayMsg}
          openSnack={openSnack}
        />
        <Snack
          message={t('general.updates_saved')}
          autoClose
          isOpen={isConfirmSnackOpen}
          onClose={closeSnack}
        />
      </div>
    </main>
  );
};

const styles = theme => ({
  content: {
    marginTop: 40,
    marginLeft: 20,
    marginRight: 20,
    [theme.breakpoints.up(breakpoints.MOBILE)]: {
      padding: theme.spacing.unit * 3,
      marginLeft: 150,
      marginRight: 0,
      marginTop: 24,
    },
  },
  header: {
    padding: 0,
    margin: 0,
    fontFamily: 'VerlagBold',
    fontWeight: 100,
    fontSize: 24,
    [theme.breakpoints.up(breakpoints.MOBILE)]: {
      fontSize: 32,
    },
  },
  panel: {
    flexGrow: 1,
    // padding: theme.spacing.unit * 3,
    display: 'flex',
    flexDirection: 'column',
    maxWidth: 120,
    padding: 0,
  },
  mainView: {
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column',
    [theme.breakpoints.up(breakpoints.MOBILE)]: {
      flexDirection: 'row',
      marginTop: 30,
    },
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: colors.palette.background.paper,
    border: '2px solid #000',
  },
  errorMessage: {
    width: 'auto',
    marginBottom: 20,
    [theme.breakpoints.up(breakpoints.MOBILE)]: {
      marginTop: -20,
      marginRight: 150,
    },
  },
});

AccountMainContainer.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  userAccountProfile: PropTypes.shape({
    name: PropTypes.string,
    description: PropTypes.string,
    twitterHandle: PropTypes.string,
    instagramHandle: PropTypes.string,
    facebookProfileUrl: PropTypes.string,
    linkedinProfileUrl: PropTypes.string,
    companySize: PropTypes.string,
    websiteUrl: PropTypes.string,
    contactEmail: PropTypes.string,
    sfId: PropTypes.string,
    photoUrl: PropTypes.string,
  }).isRequired,
  setUserAccountProfile: PropTypes.func.isRequired,
};

const mapStateToProps = state => {
  return {
    userAccountProfile: getUserProfileAccount(state),
  };
};

const mapDispatchToProps = dispatch => {
  const { actions } = UserManager;
  return bindActionCreators(
    {
      setUserAccountProfile: actions.updateUserAccount,
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(AccountMainContainer));
