import Components from '../../Components';
import { UserDto } from '../../Core/Api';
import { getIsVirtualMember } from '../../Core/Utils/userPermissions';
import { RESERVE_OFFICE_PATH } from './reserveOfficeConstants';

const { containerTypes } = Components.Routes;

const RouteConfig = {
  path: RESERVE_OFFICE_PATH,
  exact: true,
  name: 'navigation.menu.reserve_offices',
  private: true,
  type: containerTypes.SUBCONTENT,
  sidePanelPathName: 'navigation.menu.reserve_offices_short',
  grouping: 'navigation.grouping.reserve',
  requiresMemberPortalAccess: true,
  isAuthorized: ({ profile }: { profile: UserDto }) => {
    return !getIsVirtualMember(profile);
  },
};

export default RouteConfig;
