import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import MaterialDrawer from '@material-ui/core/Drawer';
import { KeyboardArrowRight, Close } from '@material-ui/icons';
import clsx from 'clsx';

import { TRANSITION_DURATION_MS } from '../DrawerTab';
import { colors, breakpoints } from '../../../Core/Theme';
import { noOp } from '../../../Core/constants';

export const PANEL_OPEN_WIDTH = 275;
export const PANEL_OPEN_DURATION_MS = TRANSITION_DURATION_MS;
const PANEL_PADDING_TOP = 17;

const ScrollingPanel = props => {
  const { classes, children, isOpen, onClickClose, title, renderFooter } =
    props;
  const [hideDrawer, setHideDrawer] = React.useState(isOpen);
  const [drawerOffset, setDrawerOffset] = React.useState(isOpen);

  const panelRef = React.useRef();

  // hide the drawer when it is closed, and has had time to transition away
  React.useEffect(() => {
    if (isOpen) {
      setHideDrawer(false);
    } else {
      setTimeout(() => setHideDrawer(true), PANEL_OPEN_DURATION_MS);
    }
  }, [isOpen]);

  React.useEffect(() => {
    setDrawerOffset(panelRef.current?.getBoundingClientRect()?.y);
  }, [panelRef.current]);

  return (
    <>
      <MaterialDrawer
        anchor="right"
        variant="persistent"
        open={isOpen}
        classes={{
          paperAnchorRight: clsx(classes.main),
        }}
        style={hideDrawer ? { visibility: 'hidden' } : {}}
      >
        <div
          className={classes.wrapper}
          ref={panelRef}
          style={{
            height: `calc(100vh - ${drawerOffset + PANEL_PADDING_TOP}px)`,
          }}
        >
          <KeyboardArrowRight
            onClick={() => onClickClose(false)}
            className={classes.rightArrow}
          />
          <div className={classes.body}>
            <Close
              className={classes.closeButton}
              onClick={() => onClickClose(false)}
            />
            <div className={classes.header}>
              <div className={clsx(classes.title)}>{title}</div>
            </div>
            <div className={classes.scroll}>{children}</div>
          </div>
          {renderFooter ? (
            <div className={classes.footer}>{renderFooter()}</div>
          ) : null}
        </div>
      </MaterialDrawer>
    </>
  );
};

const styles = theme => ({
  main: {
    background: colors.darkest,
    position: 'fixed',
    zIndex: 1300,
    overflow: 'hidden',
    [theme.breakpoints.up(breakpoints.MOBILE)]: {
      position: 'absolute',
      zIndex: 1000,
    },
    [theme.breakpoints.down(breakpoints.MOBILE)]: {
      left: 0,
      top: 0,
      right: 0,
      bottom: 0,
      zIndex: 1290,
    },
  },
  closeButton: {
    position: 'absolute',
    color: colors.white,
    right: 17,
    [theme.breakpoints.up(breakpoints.MOBILE)]: {
      display: 'none',
    },
  },
  header: {
    fontFamily: 'VerlagBook',
    fontSize: 16,
    color: colors.white,
    display: 'flex',
    flexDirection: 'column',
    marginBottom: 20,
    [theme.breakpoints.down(breakpoints.MOBILE)]: {
      fontFamily: 'VerlagBold',
      marginTop: 50,
      marginBottom: 40,
    },
    position: 'relative',
  },
  rightArrow: {
    position: 'absolute',
    left: '20px !important',
    top: '15px !important',
    bottom: 0,
    cursor: 'pointer',
    marginLeft: -13,
    color: colors.white,
    '&:hover': {
      background: colors.dark,
      borderRadius: '50%',
    },
    [theme.breakpoints.down(breakpoints.MOBILE)]: {
      display: 'none',
      left: '40px !important',
    },
  },
  title: {
    textAlign: 'center',
    padding: 0,
    margin: 0,
    [theme.breakpoints.down(breakpoints.MOBILE)]: {
      fontSize: 20,
      fontFamily: 'VerlagBold',
      width: '100%',
    },
    [theme.breakpoints.up(breakpoints.MOBILE)]: {
      fontFamily: 'VerlagBook',
      fontSize: 16,
    },
  },
  wrapper: {
    position: 'relative',
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    paddingTop: PANEL_PADDING_TOP,
    [theme.breakpoints.up(breakpoints.MOBILE)]: {
      width: PANEL_OPEN_WIDTH,
      paddingLeft: 20,
      paddingRight: 20,
    },
    [theme.breakpoints.down(breakpoints.MOBILE)]: {
      paddingLeft: 40,
      paddingRight: 40,
    },
  },
  scroll: {
    overflowY: 'scroll',
    paddingBottom: 70,
    '-ms-overflow-style': 'none',
    marginLeft: -6,
    paddingLeft: 6,
    marginRight: -6,
    paddingRight: 6,
    marginTop: -4,
    paddingTop: 4,
    '&::-webkit-scrollbar': {
      width: 0 /* Remove scrollbar space */,
      background: 'transparent' /* Optional: just make scrollbar invisible */,
    },
  },
  body: {
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
  },
  footer: {
    paddingTop: 20,
    paddingBottom: 30,
  },
});

ScrollingPanel.defaultProps = {
  children: null,
  onClickClose: noOp,
  renderFooter: () => null,
};

ScrollingPanel.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  isOpen: PropTypes.bool.isRequired,

  onClickClose: PropTypes.func,
  title: PropTypes.string.isRequired,
  renderFooter: PropTypes.func,
};

export default withStyles(styles)(ScrollingPanel);
