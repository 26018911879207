import Lodash from 'lodash';

export const fetchHandlerActions = fetchFn => {
  return (...params) => {
    return fetchFn(...params).then(response => {
      if (response === undefined) {
        return Promise.reject();
      }

      if (response.status >= 400) {
        return Promise.reject(response.data);
      }
      return Lodash.get(response, 'data', []);
    });
  };
};

export default fetchHandlerActions;
