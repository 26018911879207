import Loading from '../Loading';
import { colors } from '../../../Core/Theme';

const FullPageCenterLoading = () => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        width: '100vw',
        position: 'absolute',
        top: 0,
      }}
    >
      <Loading size={60} color={colors.gray} />
    </div>
  );
};

export default FullPageCenterLoading;
