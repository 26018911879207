import * as React from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import { useTranslation } from 'react-i18next';
import Moment from 'moment';
import Lodash from 'lodash';
import memoizeOne from 'memoize-one';

// Core
import { colors, breakpoints } from '../../Core/Theme';
import {
  fetchUserBalance,
  fetchSeatUsages,
  fetchAnyReservationsForMonth,
} from '../../Core/Api';
import { getColumns, getLabelKeyFromType, getTotalCharges } from './utils';
import { S3IconStrings } from '../../resources';
import {
  getStaticImageUrl,
  date as dateUtils,
  formatCurrency,
} from '../../Core/Utils';

// Redux
import { getUserProfile } from '../../Redux/Common/UserManager/selectors';
import { getLocationsById } from '../../Redux/Common/Locations/selectors';

// Components
import { EmptyState, SmartTableWithSearchBox, TextButton } from '../Common';
import { SummaryRow, SummaryFooter, DayPassSummaryBox } from './Components';
import ReserveDeskWithApi from '../ReserveDeskWithApi';
import SeatReservationSummary from '../SeatReservationSummary';

import { RESERVATION_TYPES } from '../../Models/anyReservation';
import SmartMeetingRoomBooking from '../SmartMeetingRoomBooking/SmartMeetingRoomBooking';
import { AnyReservation } from '../../Models';
import OfficeReservationSummary from '../OfficeReservationSummary/OfficeReservationSummary';
import { useOfficeReservationCharges } from '../../Hooks/useOfficeReservationCharges';
import useDayPasses from '../../Hooks/useDayPasses';
import { DESK_DAYPASS_DEPRECATION_MONTH } from './constants';

const RESERVATION_FETCH_LIMIT = 100;

const ActivityTableSummary = props => {
  const {
    classes,
    accountSfId,
    profile,
    month,
    locationsById,
    ctaText,
    onAction,
    setHasError,
    history,
  } = props;
  const { t } = useTranslation();

  const [isLoading, setLoading] = React.useState(true);
  const [tableSearch, setTableSearch] = React.useState('');

  const [reservations, setReservations] = React.useState([]);
  const [countReservedOpenWorkspaces, setCountReservedOpenWorkspaces] =
    React.useState(null);

  const [meetingRoomBalance, setMeetingRoomBalance] = React.useState({});
  const [selectedReservation, setSelectedReservation] = React.useState({});
  const [selectedConference, setSelectedConference] = React.useState({});
  const [selectedOfficeReservation, setOfficeReservation] =
    React.useState(null);
  const [hasMoreData, setHasMoreData] = React.useState(false);
  const [offset, setOffset] = React.useState(0);
  const [isLoadingMore, setIsLoadingMore] = React.useState(false);

  const [selectedOpenWorkspaceBooking, setSelectedOpenWorkspaceBooking] =
    React.useState({});
  const [refreshCount, setRefreshCount] = React.useState(0);

  const fetchConferenceHoursForMonth = async m => {
    return fetchUserBalance(accountSfId, m, true).then(objResponse => {
      const { data } = objResponse;
      setMeetingRoomBalance(data);
    });
  };

  const fetchSeatUsagesForMonth = m => {
    return fetchSeatUsages({
      accountSfId,
      date: dateUtils.getDateInMoment(m).format('YYYY-MM'),
    }).then(objResponse => {
      const { data } = objResponse;
      setCountReservedOpenWorkspaces(data?.countSeatsUsed);
    });
  };

  const handleError = () => setHasError(true);

  const getAnyReservationsForMonth = (m, offsetToUse = offset) => {
    return fetchAnyReservationsForMonth({
      month: dateUtils.getDateInMoment(m).format('YYYY-MM'),
      limit: RESERVATION_FETCH_LIMIT,
      offset: offsetToUse,
      accountSfId,
      includeDeleted: true,
      reservationTypes: [
        RESERVATION_TYPES.DESK_RESERVATION,
        RESERVATION_TYPES.SEAT_BOOKING,
        RESERVATION_TYPES.CONFERENCE_BOOKING,
        RESERVATION_TYPES.OFFICE_RESERVATION,
      ],
    }).then(objResponse => {
      const { data } = objResponse;
      const { count, reservations: newReservations } = data;

      setHasMoreData(RESERVATION_FETCH_LIMIT === count);
      setOffset(offsetToUse + RESERVATION_FETCH_LIMIT);
      setReservations(
        offsetToUse ? [...reservations, ...newReservations] : newReservations
      );
    }, handleError);
  };

  const getDataForMonth = m => {
    setLoading(true);
    setHasMoreData(false);
    Promise.all([
      getAnyReservationsForMonth(m, 0),
      fetchConferenceHoursForMonth(m),
      fetchSeatUsagesForMonth(m),
    ]).then(() => {
      setLoading(false);
    });
  };

  const loadMore = async () => {
    setIsLoadingMore(true);
    await getAnyReservationsForMonth(month, offset);
    setIsLoadingMore(false);
  };

  React.useEffect(() => {
    if (!accountSfId || !month) {
      return;
    }
    getDataForMonth(month);
  }, [month, accountSfId, refreshCount]);

  const reservationsForTable = reservations.map(r => {
    return {
      ...r,
      key: AnyReservation.getReservationId(r),
      typeLabel: t(getLabelKeyFromType(r.type)),
    };
  });

  const closeConference = () => {
    setSelectedConference({});
  };

  const closeOpenWorkspaceBooking = () => setSelectedOpenWorkspaceBooking({});
  const closeEdit = () => setSelectedReservation({});

  const onCancelReservation = () => {
    setRefreshCount(refreshCount + 1);
  };

  const refreshData = () => {
    setRefreshCount(refreshCount + 1);
  };

  const handleSelectBooking = info => {
    const { data } = info;
    const { type } = data;
    if (type === RESERVATION_TYPES.DESK_RESERVATION) {
      setSelectedReservation(data.reservation);
    }
    if (type === RESERVATION_TYPES.SEAT_BOOKING) {
      setSelectedOpenWorkspaceBooking(data.reservation);
    }
    if (type === RESERVATION_TYPES.CONFERENCE_BOOKING) {
      setSelectedConference(data.reservation);
    }
    if (type === RESERVATION_TYPES.OFFICE_RESERVATION) {
      setOfficeReservation(data.reservation);
    }
  };

  const interLeavedReservations = memoizeOne(anyReservations => {
    return Lodash.orderBy(anyReservations, 'startEpoch', 'desc');
  });

  const renderEmptyMessage = () => {
    return (
      <EmptyState
        imageSrc={getStaticImageUrl(S3IconStrings.emptyMoon1)}
        imageSrcSet={`${getStaticImageUrl(
          S3IconStrings.emptyMoon2
        )}, ${getStaticImageUrl(S3IconStrings.emptyMoon2)}`}
        imageAlt={t('altTexts.empty_moon')}
        title={t('accountActivity.table.empty.title')}
        description={t('accountActivity.table.empty.description')}
      />
    );
  };

  const renderNoResultsMessage = () => {
    return (
      <span className={classes.emptyMessage}>{t('general.no_results')}</span>
    );
  };

  const {
    hasUnlimitedDayPasses,
    allottedPasses,
    usedPasses,
    isFetching: isLoadingPasses,
    charge: dayPassCharge,
  } = useDayPasses(month, profile, accountSfId);

  const {
    charges: officeCharges,
    isLoading: isLoadingCharges,
    totalReservations: totalOfficeReservations,
    formattedCharges: formattedOfficeCharges,
  } = useOfficeReservationCharges({
    accountSfId,
    startEpoch: month,
    endEpoch: dateUtils.getDateInMoment(month).endOf('month').unix(),
  });

  const showDeskDayPassInfo = dateUtils
    .getDateInMoment(month)
    .isBefore(dateUtils.getDateInMoment(DESK_DAYPASS_DEPRECATION_MONTH));

  const totalCharges = getTotalCharges(
    meetingRoomBalance?.overage,
    meetingRoomBalance?.currency,
    dayPassCharge,
    officeCharges,
    showDeskDayPassInfo
  );

  return (
    <div className={classes.wrapper}>
      <Grid container className={classes.content} spacing={16}>
        <Grid
          item
          xs={12}
          md={4}
          className={clsx(classes.summarySection, classes.section)}
        >
          <div className={classes.sectionTitle}>
            {t('accountActivity.monthly_summary')}
          </div>
          <div className={classes.monthHeader}>
            <div>
              {Moment.unix(month || Moment().unix()).format('MMMM YYYY')}
            </div>
            {ctaText && <TextButton text={ctaText} onClick={onAction} />}
          </div>
          <Grid item xs={12}>
            <DayPassSummaryBox
              month={month}
              accountSfId={accountSfId}
              hasUnlimitedDayPasses={hasUnlimitedDayPasses}
              isLoading={isLoadingCharges || isLoadingPasses}
              allottedPasses={allottedPasses}
              usedPasses={usedPasses}
              dayPassCharge={dayPassCharge}
              showDeskDayPassInfo={showDeskDayPassInfo}
              totalOfficeReservations={totalOfficeReservations}
              formattedOfficeCharges={formattedOfficeCharges}
            />
          </Grid>
          <Grid item xs={12}>
            <SummaryRow
              title={t('accountActivity.summary.meeting_room_title')}
              leftTitle={t('accountActivity.summary.a_of_b', {
                a: meetingRoomBalance?.totalCreditsUsed,
                b:
                  meetingRoomBalance?.monthlyAllowanceCredits === -1
                    ? t('general.unlimited')
                    : meetingRoomBalance?.monthlyAllowanceCredits,
              })}
              leftDescription={t('accountActivity.summary.used')}
              rightTitle={
                meetingRoomBalance.overage != null &&
                meetingRoomBalance.currency != null
                  ? formatCurrency({
                      value: meetingRoomBalance.overage,
                      currency: meetingRoomBalance.currency,
                    })
                  : undefined
              }
              rightDescription={t('accountActivity.summary.charge')}
              isLoading={isLoading}
            />
          </Grid>
          <Grid item xs={12}>
            <SummaryRow
              title={t('accountActivity.summary.ow_title')}
              leftTitle={countReservedOpenWorkspaces}
              leftDescription={t('accountActivity.summary.reservations')}
              isLoading={isLoading}
            />
          </Grid>
          <Grid item xs={12}>
            <SummaryFooter
              leftText={t('accountActivity.summary.total_charges')}
              rightText={totalCharges}
              isLoading={isLoading || isLoadingCharges || isLoadingPasses}
              leftSize={4}
              rightSize={8}
            />
          </Grid>
        </Grid>
        <Grid item xs={12} md={8} className={classes.section}>
          <div className={clsx(classes.sectionTitle, classes.reservationTitle)}>
            {t('accountActivity.reservations')}
          </div>

          <SmartTableWithSearchBox
            isLoading={isLoading}
            searchValue={tableSearch}
            searchFields={[
              'reservation.user.name',
              'reservation.owner.name',
              'typeLabel',
            ]}
            onChangeSearchValue={setTableSearch}
            data={interLeavedReservations(reservationsForTable)}
            placeholder={t('accountActivity.search.placeholder')}
            emptyMessage={renderEmptyMessage()}
            noResultsMessage={renderNoResultsMessage()}
            columns={getColumns(classes, t, profile)}
            className={classes.table}
            containerClassName={classes.tableContainer}
            headerClassName={classes.tableHeader}
            onActionPress={handleSelectBooking}
            hasMore={hasMoreData}
            loadMore={loadMore}
            isLoadingMore={isLoadingMore}
            initialLoad
          />
        </Grid>
      </Grid>
      <ReserveDeskWithApi
        isOpen={!Lodash.isEmpty(selectedReservation)}
        onClose={closeEdit}
        isCancel
        reservations={[selectedReservation]}
        onSubmit={onCancelReservation}
        history={history}
      />
      <SmartMeetingRoomBooking
        isOpen={!Lodash.isEmpty(selectedConference)}
        onClose={closeConference}
        objBooking={selectedConference}
        onSuccess={refreshData}
      />
      <SeatReservationSummary
        isOpen={!Lodash.isEmpty(selectedOpenWorkspaceBooking)}
        onClose={closeOpenWorkspaceBooking}
        reservation={selectedOpenWorkspaceBooking}
        profile={profile}
        locationsById={locationsById}
        setSelectedReservation={setSelectedOpenWorkspaceBooking}
        onSeatReservationUpdated={refreshData}
      />
      <OfficeReservationSummary
        profile={profile}
        onClose={() => setOfficeReservation(null)}
        reservation={selectedOfficeReservation}
        onSuccess={refreshData}
      />
    </div>
  );
};

const styles = theme => ({
  wrapper: {
    background: colors.white,
    boxShadow: ' 0 0 4px 0 rgba(0, 0, 0, 0.1)',
    borderRadius: 3,
    border: `solid 1px ${colors.middle}`,
    padding: 8,
    paddingBottom: 0,
    [theme.breakpoints.up(breakpoints.MD)]: {
      paddingLeft: 20,
      paddingTop: 8,
      paddingRight: 8,
      paddingBottom: 0,
    },
  },
  section: {
    [theme.breakpoints.up(breakpoints.MD)]: {},
  },
  summarySection: {
    [theme.breakpoints.up(breakpoints.MD)]: {
      borderRight: `solid 1px ${colors.middle}`,
      paddingRight: `20px !important`,
      paddingBottom: 0,
      marginBottom: 8,
    },
  },
  monthHeader: {
    fontFamily: 'VerlagBold',
    fontSize: 21,
    borderBottom: `solid 1px ${colors.middle}`,
    display: 'flex',
    justifyContent: 'space-between',
    paddingBottom: 9,
    marginBottom: 10,
    marginLeft: -8,
    paddingLeft: 8,
    marginRight: -8,
    paddingRight: 8,
    lineHeight: '20px',
    marginTop: 5,
    [theme.breakpoints.up(breakpoints.MD)]: {
      marginLeft: -21,
      paddingLeft: 20,
      marginRight: -21,
      paddingRight: 12,
      fontSize: 24,
      paddingBottom: 56,
      marginTop: 0,
    },
  },
  sectionTitle: {
    fontFamily: 'VerlagLight',
    fontSize: 12,
    marginBottom: 5,
    paddingTop: 12,
  },
  reservationTitle: {
    fontFamily: 'VerlagBold',
    fontSize: 21,
    [theme.breakpoints.up(breakpoints.MD)]: {
      marginLeft: 12,
      fontSize: 24,
      marginTop: 15,
    },
  },
  tableContainer: {
    boxShadow: 'none',
    border: 'none',
    marginTop: -33,
    marginLeft: -8,
    marginRight: -8,
    width: 'auto',
  },
  tableHeader: {
    marginBottom: 12,
    paddingLeft: 8,
    paddingRight: 8,
    [theme.breakpoints.up(breakpoints.MD)]: {
      margin: 0,
      marginBottom: 10,
      marginTop: -25,
    },
  },
  table: {
    [theme.breakpoints.up(breakpoints.MD)]: {
      height: 690,
    },
  },
  inactive: {
    color: colors.mediumGray,
  },
  cancelledLabel: {
    color: colors.darkest,
    fontSize: 14,
    fontFamily: 'VerlagBold',
  },
});
ActivityTableSummary.defaultProps = {
  accountSfId: '',
  ctaText: '',
  onAction: () => {},
  setHasError: () => {},
};

ActivityTableSummary.propTypes = {
  classes: PropTypes.object.isRequired,
  profile: PropTypes.object.isRequired,
  locationsById: PropTypes.object.isRequired,
  accountSfId: PropTypes.string,
  month: PropTypes.string.isRequired,
  ctaText: PropTypes.string,
  onAction: PropTypes.func,
  setHasError: PropTypes.func,
  history: PropTypes.object.isRequired,
};

const mapStateToProps = state => {
  return {
    profile: getUserProfile(state),
    locationsById: getLocationsById(state),
  };
};

export default connect(mapStateToProps)(
  withStyles(styles)(ActivityTableSummary)
);
