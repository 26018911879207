import Route from './Route';
import { LazyloadWrapper } from '../../Components/Common';

const RoomBookingMainContainer = LazyloadWrapper(
  import('./RoomBookingMainContainer')
);

export default {
  RenderProp: RoomBookingMainContainer,
  mainRoute: Route,
};
