import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

const RadioGroup = ({ children, label, classes, className }) => {
  return (
    <div className={className}>
      {label && <div className={classes.label}>{label}</div>}
      {children}
    </div>
  );
};

const styles = {
  label: {
    fontFamily: 'VerlagBold',
    fontSize: 12,
    paddingBottom: 10,
  },
};
RadioGroup.defaultProps = {
  label: '',
};
RadioGroup.propTypes = {
  label: PropTypes.string,
};

export default withStyles(styles)(RadioGroup);
