/* eslint-disable react/require-default-props  */

import { StyleRulesCallback, withStyles } from '@material-ui/core';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { colors, filters } from '../../../Core/Theme';
import { AmenityDto } from '../../../Core/Api/dto';
import { Amenity } from '../../../Models/amenity';

type Props = {
  className?: string;
  classes: ClassNameMap;
  isLighter?: boolean;
  isColoured?: boolean;
  amenity: AmenityDto;
};

const OfficeAmenity = (props: Props) => {
  const {
    classes,
    isLighter = false,
    isColoured = false,
    className,
    amenity,
  } = props;
  const { t } = useTranslation();

  const { altIconUrl, iconUrl, iconName, name } = new Amenity(amenity, t);

  return (
    <div
      className={clsx(
        classes.container,
        isLighter && classes.lighter,
        className
      )}
    >
      <img
        className={classes.icon}
        src={isColoured ? iconUrl : altIconUrl}
        alt={iconName}
      />
      <span>{name}</span>
    </div>
  );
};

const style: StyleRulesCallback<string> = () => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    color: colors.darkGray,
  },
  icon: {
    marginRight: 5,
  },
  lighter: {
    color: colors.subtleGray,

    '& img': {
      filter: filters.subtleGray,
    },
  },
});

export default withStyles(style)(OfficeAmenity);
