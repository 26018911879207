/* eslint-disable jsx-a11y/interactive-supports-focus */
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { Text } from '../../../Common';
import { colors, breakpoints } from '../../../../Core/Theme';

const DetailedScheduleRow = ({
  classes,
  onClick,
  title,
  subHeadingtitle,
  scheduleCompany,
  floor,
  location,
  deskKey,
  deskKeyOptions,
  addons,
}) => {
  const { t } = useTranslation();

  return (
    <div
      className={classes.container}
      onClick={onClick}
      role="button"
      onKeyDown={onClick}
    >
      <div className={classes.content}>
        <div className={classes.leftContainer}>
          <div className={classes.divider} />

          <div className={classes.scheduleInfoContainer}>
            <div className={classes.titleContainer}>
              <Text text={t(title)} className={classes.title} />
              <Text
                text={subHeadingtitle}
                className={classes.subHeadingtitle}
              />
              <div>
                <Text text={scheduleCompany} className={classes.company} />
                <Text text={location} className={classes.location} />
              </div>
            </div>
          </div>
        </div>
        <div className={classes.scheduleInfoContainerRight}>
          {addons.length !== 0 && (
            <div className={classes.addons}>
              {addons.map(a => (
                <div className={classes.addonPill}>
                  {t(`deskAdmin.addons.${a}`)}
                </div>
              ))}
            </div>
          )}
          <Text
            text={t('general.floor', { floorName: floor })}
            className={classes.floor}
          />
          <Text text={t(deskKey, deskKeyOptions)} className={classes.desk} />
        </div>
      </div>
    </div>
  );
};

const styles = theme => ({
  container: {
    display: 'flex',
    cursor: 'pointer',
    flex: 1,
    borderRadius: 2,
    border: `solid 1px ${colors.middle}`,
    borderLeft: 'none',
    paddingTop: 5,
    paddingBottom: 5,
    marginRight: 3,
    '&:hover': {
      boxShadow: '0 0 4px 2px rgba(0, 0, 0, 0.1)',
    },
  },
  content: {
    display: 'flex',
    flex: 1,
    justifyContent: 'space-between',
  },
  scheduleInfoContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  scheduleInfoContainerRight: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    paddingRight: 10,
    textAlign: 'right',
    [theme.breakpoints.up(breakpoints.MOBILE)]: {
      paddingRight: 15,
    },
  },
  titleContainer: {
    display: 'contents',
  },
  title: {
    fontFamily: 'VerlagBold',
    fontSize: '10px',
    lineHeight: '14px',
    paddingTop: 2,
    paddingLeft: 4,
    paddingRight: 4,
    color: colors.white,
    borderRadius: 2,
    width: 'max-content',
    overflow: 'hidden',
    backgroundColor: colors.palette.primary.main,
  },
  subHeadingtitle: {
    fontFamily: 'VerlagBold',
    fontSize: 12,
    overflow: 'hidden',
    paddingTop: 3,
    [theme.breakpoints.up(breakpoints.MOBILE)]: {
      fontSize: 14,
    },
  },
  company: {
    fontFamily: 'VerlagLight',
    fontSize: 12,
    [theme.breakpoints.up(breakpoints.MOBILE)]: {
      fontSize: 14,
      borderRight: `solid 1px ${colors.black}`,
      paddingRight: 4,
      marginRight: 4,
    },
  },
  floor: {
    fontFamily: 'VerlagLight',
    fontSize: 10,
    [theme.breakpoints.up(breakpoints.MOBILE)]: {
      fontSize: 12,
    },
  },
  desk: {
    fontFamily: 'VerlagBook',
    fontSize: 12,
    paddingTop: 4,
    [theme.breakpoints.up(breakpoints.MOBILE)]: {
      fontSize: 14,
    },
  },
  location: {
    fontFamily: 'VerlagLight',
    color: colors.darkest,
    display: 'none',
    [theme.breakpoints.up(breakpoints.MOBILE)]: {
      fontSize: 12,
      display: 'inline',
    },
  },
  divider: {
    width: '3px',
    height: 'calc(100% + 12px)',
    marginRight: '10px',
    borderRadius: '15px',
    marginTop: -6,
    backgroundColor: colors.palette.primary.main,
  },
  leftContainer: {
    display: 'flex',
  },
  addonPill: {
    backgroundColor: colors.palette.primary.main,
    fontFamily: 'VerlagBook',
    fontSize: 10,
    color: colors.white,
    borderRadius: 2,
    paddingLeft: 3,
    paddingRight: 3,
    width: 'fit-content',
    marginLeft: 5,
    paddingTop: 2,
    paddingBottom: 1,
  },
  addons: {
    marginLeft: 'auto',
    marginBottom: 5,
    display: 'flex',
  },
});

DetailedScheduleRow.defaultProps = {
  title: null,
  subHeadingtitle: null,
  location: null,
  onClick: () => null,
  scheduleCompany: '',
  floor: '',
  deskKeyOptions: {},
  deskKey: '',
  addons: [],
};

DetailedScheduleRow.propTypes = {
  title: PropTypes.string,
  subHeadingtitle: PropTypes.string,
  location: PropTypes.string,
  onClick: PropTypes.func,
  scheduleCompany: PropTypes.string,
  floor: PropTypes.string,
  deskKey: PropTypes.string,
  deskKeyOptions: PropTypes.object,
  addons: PropTypes.arrayOf(PropTypes.string),
};

export default withStyles(styles)(DetailedScheduleRow);
