import React from 'react';

// Components
import { MapLayerWrapper as Layer } from '../../Common';

// Constants
import { LAYERS } from '../Constants';

// Theme
import { colors } from '../../../Core/Theme';

// Layer properties
const fillProperties = {
  id: LAYERS.OVERLAY,
  type: 'fill',
  source: 'features',
  filter: ['in', ['get', 'type'], ['literal', [LAYERS.OVERLAY]]],
  paint: {
    'fill-color': colors.blackOpacityLight,
  },
};

const Overlay = props => {
  return <Layer properties={[fillProperties]} {...props} />;
};

export default React.memo(Overlay);
