import Components from '../../Components';

const { containerTypes } = Components.Routes;

const RouteConfig = {
  path: '/home/settings',
  exact: true,
  name: 'navigation.menu.settings',
  private: true,
  type: containerTypes.CONTENT,
};

export default RouteConfig;
