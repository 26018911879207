/* eslint-disable jsx-a11y/no-noninteractive-tabindex */

import 'react-dates/initialize';
import moment from 'moment';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { ExpandMore } from '@material-ui/icons';

import { colors } from '../../../Core/Theme';
import Monthpicker from './Monthpicker';

const MonthPick = props => {
  const { selectedMonth, onChange, classes, className } = props;
  const month = moment.unix(selectedMonth).month();
  const year = moment.unix(selectedMonth).year();
  return (
    <div className={className}>
      <Monthpicker month={month} year={year} locale="en" onChange={onChange}>
        <div className={classes.inputText} tabIndex={0}>
          {moment.unix(selectedMonth).format('MMMM YYYY')}
          <ExpandMore className={classes.expandMore} />
        </div>
      </Monthpicker>
    </div>
  );
};

const styles = {
  inputText: {
    background: 'white',
    backgroundColor: `${colors.white} !important`,
    borderRadius: 4,
    border: `solid 1px ${colors.middle}`,
    padding: '10px 0px 9px 10px',
    fontFamily: 'VerlagBook',
    fontSize: 18,
    '&:focus:not(.focus-visible)': {
      outline: 'none',
    },
  },
  expandMore: {
    float: 'right',
  },
};
MonthPick.defaultProps = {
  onChange: () => '',
};

MonthPick.propTypes = {
  selectedMonth: PropTypes.number.isRequired,
  onChange: PropTypes.func,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(MonthPick);
