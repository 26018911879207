import clsx from 'clsx';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { withStyles } from '@material-ui/core';

import { colors } from '../../../Core/Theme';

const DeskStatus = ({ classes, isBookable, isReserved, teammate }) => {
  const isDisabled = !isBookable || isReserved;
  const { t } = useTranslation();

  const renderStatus = () => {
    if (!isBookable) {
      return t('deskFloorPlan.deskStatus.notReservable');
    }
    if (!isReserved) {
      return t('deskFloorPlan.deskStatus.available');
    }
    if (teammate === null) {
      return t('deskFloorPlan.deskStatus.reserved');
    }
    if (teammate.isSelf) {
      return t('deskFloorPlan.deskStatus.you');
    }
    return t('deskFloorPlan.deskStatus.teammate');
  };

  return (
    <div
      className={clsx(
        classes.status,
        isDisabled && classes.statusDisabled,
        teammate !== null && classes.statusTeammate
      )}
    >
      <>{renderStatus()}</>
    </div>
  );
};

const styles = () => ({
  status: {
    backgroundColor: '#e7f5e6',
    border: '1px solid rgba(134, 206, 131, 0.2)',
    color: colors.palette.primary.main,
    fontSize: 15,
    lineHeight: '18px',
    borderRadius: 12,
    padding: '3px 8px',
    fontFamily: 'VerlagBold',
  },
  statusDisabled: {
    backgroundColor: colors.white,
    color: '#959595',
    borderColor: 'rgba(149, 149, 149, 0.65)',
    fontFamily: 'VerlagBook',
  },
  statusTeammate: {
    backgroundColor: '#fdd344',
    borderColor: '#fdd344',
    color: colors.black,
    fontFamily: 'VerlagBold',
  },
});

DeskStatus.propTypes = {
  classes: PropTypes.object.isRequired,
  isBookable: PropTypes.bool.isRequired,
  isReserved: PropTypes.bool.isRequired,
  teammate: PropTypes.shape({
    name: PropTypes.string,
    sfId: PropTypes.string,
    isSelf: PropTypes.bool,
    avatarUrl: PropTypes.string,
  }).isRequired,
};

export default withStyles(styles)(DeskStatus);
