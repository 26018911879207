import * as React from 'react';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect, useSelector } from 'react-redux';
import queryString from 'query-string';

import {
  getUserToken,
  getStatusUser,
  getCurrentPathname,
  getUsersDefaultRoute,
} from '../../Redux/Common/UserManager/selectors';

const Root = props => {
  const { userToken, isUserActive, currentPathname } = props;
  const defaultRoute = useSelector(getUsersDefaultRoute);

  const hashRef = React.useRef(window.location.hash);
  const { current: hashUrl } = hashRef;
  const query = queryString.parse(hashUrl);
  if (query.email) {
    query.email = query.email.replace(' ', '+');
  }

  switch (query.path) {
    //  TODO: remove this once we update the link in prod lambda
    case 'resetpassword': {
      return (
        <Redirect
          from="/"
          to={{
            pathname: '/resetpassword',
            state: {
              username: query.email,
              tempPassword: query.tpw,
              name: query.name,
            },
          }}
        />
      );
    }
    //  TODO: need to refactor forget password to use normal routing
    case 'forgotpassword': {
      return (
        <Redirect
          from="/"
          to={{
            pathname: '/forgotpassword',
            state: {
              view: query.view,
              email: query.email,
              code: query.code,
              name: query.name,
            },
          }}
        />
      );
    }

    default: {
      if (userToken && isUserActive) {
        return <Redirect from="/" to={currentPathname || defaultRoute} />;
      }
      return <Redirect from="/" to="/login" />;
    }
  }
};

Root.propTypes = {
  userToken: PropTypes.string.isRequired,
  isUserActive: PropTypes.bool.isRequired,
  currentPathname: PropTypes.string.isRequired,
};

const mapStateToProps = state => {
  return {
    currentPathname: getCurrentPathname(state),
    userToken: getUserToken(state),
    isUserActive: getStatusUser(state),
  };
};

export default connect(mapStateToProps)(Root);
