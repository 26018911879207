/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */

import * as React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Lodash from 'lodash';
import { useTranslation } from 'react-i18next';

import { colors, breakpoints } from '../../Core/Theme';
import Header from './Components/Header';
import Body from './Components/Body';
import Link from './Components/Link';

const FAQ = props => {
  const { history } = props;
  React.useEffect(() => {
    const hash = Lodash.get(history, ['location', 'hash'], null);
    if (hash && document.getElementById(hash.substr(1))) {
      // Check if there is a hash and if an element with that id exists
      document.getElementById(hash.substr(1)).scrollIntoView();
    }
  }, [history.location.hash]);

  const { classes } = props;
  const { t } = useTranslation();

  return (
    <div>
      <div className={classes.title}>{t('Faq.title')}</div>
      <div className={classes.contentWrapper}>
        <div className={classes.contentOutline}>
          <div className={classes.header}>
            <Header id={0} title={t('Faq.section1.title')} />
            <Header id={1} title={t('Faq.section2.title')} />
            <Header id={2} title={t('Faq.section3.title')} />
            <Header id={3} title={t('Faq.section4.title')} />
            <Header id={4} title={t('Faq.section5.title')} />
            <Header id={5} title={t('Faq.section6.title')} />
            <Header id={6} title={t('Faq.section7.title')} />
            <Header id={7} title={t('Faq.section8.title')} />
            <Header id={8} title={t('Faq.section9.title')} />
            <Header id={9} title={t('Faq.section10.title')} />
            <Header id={10} title={t('Faq.section11.title')} />
            <Header id={11} title={t('Faq.section12.title')} />
            <Header id={12} title={t('Faq.section13.title')} />
            <Header id={13} title={t('Faq.section14.title')} />
            <Header id={14} title={t('Faq.section15.title')} />
            <Header id={15} title={t('Faq.section16.title')} />
            <Header id={16} title={t('Faq.section17.title')} />
            <Header id={17} title={t('Faq.section18.title')} />
            <Header id={18} title={t('Faq.section19.title')} />
            <Header id={19} title={t('Faq.section20.title')} />
            <Header id={20} title={t('Faq.section21.title')} />
            <Header id={21} title={t('Faq.section22.title')} />
            <Header id={22} title={t('Faq.section23.title')} />
            <Header id={23} title={t('Faq.section24.title')} />
            <Header id={24} title={t('Faq.section25.title')} />
            <Header id={25} title={t('Faq.section26.title')} />
            <Header id={26} title={t('Faq.section27.title')} />
            <Header id={27} title={t('Faq.section28.title')} />
          </div>
          <div className={classes.body}>
            <Body question={t('Faq.section1.title')} id={0}>
              <p className={classes.answer}>
                {t('Faq.section1.answer')}
                <Link
                  id={0}
                  url="https://drive.google.com/file/d/1esBZSZXSsmBXWzL5AMrinAwnJynchJWB/view"
                  linkLabel="US,"
                />
                <Link
                  id={1}
                  url="https://drive.google.com/file/d/1gIn40sSla6JMEizt6K1B1kPgz-5JzQs1/view?usp=drive_link"
                  linkLabel=" Canada"
                />
                {t('Faq.section1.answer2')}
              </p>
            </Body>
            <Body
              question={t('Faq.section2.title')}
              id={1}
              body={t('Faq.section2.body')}
            >
              <p className={classes.answer}> {t('Faq.section2.body')}</p>
              <p className={classes.answer}> {t('Faq.section2.body2')}</p>
              <p className={classes.questionNotLink}>
                {t('Faq.section2.question')}
                <p className={classes.answer}>{t('Faq.section2.answer')}</p>
              </p>
            </Body>
            <Body question={t('Faq.section3.title')} id={2}>
              <p className={classes.questionNotLink}>
                {t('Faq.section3.question1.question')}
                <p className={classes.answer}>
                  {t('Faq.section3.question1.answer')}
                </p>
              </p>
              <p className={classes.questionNotLink}>
                {t('Faq.section3.question2.question')}
                <p className={classes.answer}>
                  {t('Faq.section3.question2.answer')}
                </p>
              </p>
              <p className={classes.questionNotLink}>
                {t('Faq.section3.question3.question')}
                <p className={classes.answer}>
                  {t('Faq.section3.question3.answer')}
                  <Link
                    id={2}
                    url="https://www.speedtest.net/"
                    linkLabel="speedtest"
                  />
                  {t('Faq.section3.question3.answer2')}
                </p>
              </p>
              <p className={classes.questionNotLink}>
                {t('Faq.section3.question4.question')}
                <p className={classes.answer}>
                  {t('Faq.section3.question4.answer')}
                </p>
              </p>
              <p className={classes.learnMore}>
                {t('Faq.section3.learnMoreText')}
              </p>
            </Body>
            <Body question={t('Faq.section4.title')} id={3}>
              <p className={classes.questionNotLink}>
                {t('Faq.section4.question1.question')}
                <p className={classes.answer}>
                  {t('Faq.section4.question1.answer')}
                </p>
              </p>
              <p className={classes.questionNotLink}>
                {t('Faq.section4.question2.question')}
                <p className={classes.answer}>
                  {t('Faq.section4.question2.answer')}
                </p>
              </p>
              <p className={classes.questionNotLink}>
                {t('Faq.section4.question3.question')}
                <p className={classes.answer}>
                  {t('Faq.section4.question3.answer')}
                </p>
              </p>
              <p className={classes.questionNotLink}>
                {t('Faq.section4.question4.question')}
                <p className={classes.answer}>
                  {t('Faq.section4.question4.answer')}
                </p>
              </p>
              <p className={classes.questionNotLink}>
                {t('Faq.section4.question5.question')}
                <p className={classes.answer}>
                  {t('Faq.section4.question5.answer')}
                </p>
              </p>
              <p className={classes.questionNotLink}>
                {t('Faq.section4.question6.question')}
                <p className={classes.answer}>
                  {t('Faq.section4.question6.answer')}
                </p>
              </p>
              <p className={classes.questionNotLink}>
                {t('Faq.section4.question7.question')}
                <p className={classes.answer}>
                  {t('Faq.section4.question7.answer')}
                </p>
              </p>
            </Body>
            <Body question={t('Faq.section5.title')} id={4}>
              <p className={classes.questionNotLink}>
                {t('Faq.section5.question1.question')}
                <p className={classes.answer}>
                  {t('Faq.section5.question1.answer')}
                </p>
              </p>
              <p className={classes.questionNotLink}>
                {t('Faq.section5.question2.question')}
                <p className={classes.answer}>
                  {t('Faq.section5.question2.answer')}
                </p>
              </p>
              <p className={classes.questionNotLink}>
                {t('Faq.section5.question3.question')}
                <p className={classes.answer}>
                  {t('Faq.section5.question3.answer')}
                  <Link
                    id={3}
                    linkLabel="Billing Portal"
                    url="/billing"
                    target="_self"
                  />
                  {t('Faq.section5.question3.answer2')}
                </p>
              </p>
              <p className={classes.questionNotLink}>
                {t('Faq.section5.question4.question')}
                <p className={classes.answer}>
                  {t('Faq.section5.question4.answer')}
                </p>
                <p className={classes.answer}>
                  {t('Faq.section5.question4.answer2')}
                  <u>{t('Faq.section5.question4.answer3')}</u>
                </p>
                <p className={classes.answer}>
                  {t('Faq.section5.question4.answer4')}
                </p>
              </p>
              <p className={classes.questionNotLink}>
                {t('Faq.section5.question5.question')}
                <p className={classes.answer}>
                  {t('Faq.section5.question5.answer')}
                </p>
              </p>
              <p className={classes.learnMore}>
                {t('Faq.section5.learnMoreText')}
                <Link
                  id={4}
                  url="https://docs.google.com/document/d/1PaK7Idd-3oY2cn9smu_EZs7l0EJjCOT4R5baerDUjDk/export?format=pdf"
                  linkLabel=" Billing FAQ."
                />
              </p>
            </Body>
            <Body question={t('Faq.section6.title')} id={5}>
              <p className={classes.questionNotLink}>
                {t('Faq.section6.question1.question')}
                <p className={classes.answer}>
                  {t('Faq.section6.question1.answer')}
                  <Link
                    id={5}
                    url="https://drive.google.com/file/d/1esBZSZXSsmBXWzL5AMrinAwnJynchJWB/view"
                    linkLabel="Holiday Schedule in the Member Handbook."
                  />
                </p>
              </p>
              <p className={classes.questionNotLink}>
                {t('Faq.section6.question2.question')}
                <p className={classes.answer}>
                  {t('Faq.section6.question2.answer')}
                  <Link
                    id={6}
                    url="https://www.industriousoffice.com/locations"
                    linkLabel="our website"
                  />
                  {t('Faq.section6.question2.answer2')}
                </p>
              </p>
              <p className={classes.questionNotLink}>
                {t('Faq.section6.question3.question')}
                <p className={classes.answer}>
                  {t('Faq.section6.question3.answer')}
                </p>
              </p>
              <p className={classes.questionNotLink}>
                {t('Faq.section6.question4.question')}
                <p className={classes.answer}>
                  <Link
                    id={7}
                    url="https://www.industriousoffice.com/locations"
                    linkLabel="Our website"
                  />
                  {t('Faq.section6.question4.answer')}
                </p>
              </p>
            </Body>
            <Body question={t('Faq.section7.title')} id={6}>
              <p className={classes.questionNotLink}>
                {t('Faq.section7.question1.question')}
                <p className={classes.answer}>
                  {t('Faq.section7.question1.answer')}
                </p>
              </p>
              <p className={classes.questionNotLink}>
                {t('Faq.section7.question2.question')}
                <p className={classes.answer}>
                  {t('Faq.section7.question2.answer')}
                </p>
              </p>
              <p className={classes.questionNotLink}>
                {t('Faq.section7.question3.question')}
                <p className={classes.answer}>
                  {t('Faq.section7.question3.answer')}
                </p>
              </p>
              <p className={classes.questionNotLink}>
                {t('Faq.section7.question4.question')}
                <p className={classes.answer}>
                  {t('Faq.section7.question4.answer')}
                </p>
              </p>
              <p className={classes.questionNotLink}>
                {t('Faq.section7.question5.question')}
                <p className={classes.answer}>
                  {t('Faq.section7.question5.answer')}
                </p>
              </p>
              <p className={classes.questionNotLink}>
                {t('Faq.section7.question6.question')}
                <p className={classes.answer}>
                  {t('Faq.section7.question6.answer')}
                </p>
              </p>
            </Body>
            <Body question={t('Faq.section8.title')} id={7}>
              <p className={classes.questionNotLink}>
                {t('Faq.section8.question1.question')}
                <p className={classes.answer}>
                  {t('Faq.section8.question1.answer')}
                </p>
              </p>
              <p className={classes.questionNotLink}>
                {t('Faq.section8.question2.question')}
                <p className={classes.answer}>
                  {t('Faq.section8.question2.answer')}
                  <ul>
                    <li>
                      <b>{t('Faq.section8.question2.accessmembers')}</b>
                      {t('Faq.section8.question2.accessMembersDescription')}
                    </li>
                    <li>
                      <b>{t('Faq.section8.question2.dedicatedOfficeMember')}</b>
                      {t(
                        'Faq.section8.question2.dedicatedOfficeMemberDescription'
                      )}
                    </li>
                    <li>
                      <b>{t('Faq.section8.question2.virtualMemberships')}</b>
                      {t(
                        'Faq.section8.question2.virtualMembershipsDescription'
                      )}
                    </li>
                  </ul>
                </p>
              </p>
              <p className={classes.questionNotLink}>
                {t('Faq.section8.question3.question')}
                <p className={classes.answer}>
                  {t('Faq.section8.question3.answer')}
                </p>
              </p>
              <p className={classes.questionNotLink}>
                {t('Faq.section8.question4.question')}
                <p className={classes.answer}>
                  {t('Faq.section8.question4.answer')}
                </p>
              </p>
            </Body>
            <Body question={t('Faq.section9.title')} id={8}>
              <p className={classes.answer}>
                {t('Faq.section9.body')}
                <Link
                  id={8}
                  url="https://drive.google.com/file/d/1esBZSZXSsmBXWzL5AMrinAwnJynchJWB/view"
                  linkLabel="Member Handbook"
                />
              </p>
            </Body>
            <Body question={t('Faq.section10.title')} id={9}>
              <p className={classes.answer}>{t('Faq.section10.body')}</p>
            </Body>
            <Body question={t('Faq.section11.title')} id={10}>
              <p className={classes.questionNotLink}>
                {t('Faq.section11.question1.question')}
                <p className={classes.answer}>
                  {t('Faq.section11.question1.answer')}
                </p>
              </p>
              <p className={classes.questionNotLink}>
                {t('Faq.section11.question2.question')}
                <p className={classes.answer}>
                  {t('Faq.section11.question2.answer')}
                </p>
              </p>
              <p className={classes.questionNotLink}>
                {t('Faq.section11.question3.question')}
                <p className={classes.answer}>
                  {t('Faq.section11.question3.answer')}
                </p>
              </p>
            </Body>
            <Body question={t('Faq.section12.title')} id={11}>
              <p className={classes.answer}>{t('Faq.section12.body')}</p>
            </Body>
            <Body question={t('Faq.section13.title')} id={12}>
              <p className={classes.answer}>{t('Faq.section13.body')}</p>
            </Body>
            <Body question={t('Faq.section14.title')} id={13}>
              <p className={classes.answer}>{t('Faq.section14.body')}</p>
            </Body>
            <Body question={t('Faq.section15.title')} id={14}>
              <p className={classes.answer}>{t('Faq.section15.body')}</p>
            </Body>
            <Body question={t('Faq.section16.title')} id={15}>
              <p className={classes.answer}>
                {t('Faq.section16.body')}
                <Link
                  id={9}
                  linkLabel="Notification Settings"
                  url="https://portal.industriousoffice.com/home/settings"
                />
                {t('Faq.section16.body2')}
              </p>
            </Body>
            <Body question={t('Faq.section17.title')} id={16}>
              <p className={classes.answer}>{t('Faq.section17.body')}</p>
              <p className={classes.answer}>
                {t('Faq.section17.answer')}
                <ul>
                  <li>{t('Faq.section17.answer2')}</li>
                  <li>{t('Faq.section17.answer3')}</li>
                </ul>
              </p>
              <Link
                id={10}
                linkLabel="How to Reserve an Office for the Day"
                url="https://app.tango.us/app/workflow/How-to-Reserve-a-Private-Office-for-the-Day-e1fbb67116ed45039efdef7cd8216efd"
              />
            </Body>
            <Body question={t('Faq.section18.title')} id={17}>
              <p className={classes.answer}>
                {t('Faq.section18.body')}
                <ul>
                  <li>{t('Faq.section18.answer1')}</li>
                  <li>{t('Faq.section18.answer2')}</li>
                  <li>{t('Faq.section18.answer3')}</li>
                  <li>{t('Faq.section18.answer4')}</li>
                  <li>
                    {t('Faq.section18.learnMore')}
                    <Link
                      id={11}
                      linkLabel=" here"
                      url="https://docs.google.com/document/d/13Yit8ogu2uf8Y8BNClXPCBIklpagmA0-TV-TYJa5XWM/edit#heading=h.ylctyahq6oxl"
                    />
                    {t('Faq.section18.learnMore2')}
                  </li>
                </ul>
              </p>
            </Body>
            <Body question={t('Faq.section19.title')} id={18}>
              <p className={classes.answer}>{t('Faq.section19.body')}</p>
            </Body>
            <Body question={t('Faq.section20.title')} id={19}>
              <p className={classes.answer}>{t('Faq.section20.body')}</p>
              <p className={classes.answer}>{t('Faq.section20.body2')}</p>
            </Body>
            <Body question={t('Faq.section21.title')} id={20}>
              <p className={classes.answer}>{t('Faq.section21.body')}</p>
            </Body>
            <Body question={t('Faq.section22.title')} id={21}>
              <p className={classes.answer}>{t('Faq.section22.body')}</p>
            </Body>
            <Body question={t('Faq.section23.title')} id={22}>
              <p className={classes.answer}>{t('Faq.section23.body')}</p>
            </Body>
            <Body question={t('Faq.section24.title')} id={23}>
              <p className={classes.answer}>{t('Faq.section24.body')}</p>
              <p className={classes.answer}>
                <ul>
                  <li>{t('Faq.section24.answer1')}</li>
                  <li>{t('Faq.section24.answer2')}</li>
                  <li>{t('Faq.section24.answer3')}</li>
                </ul>
              </p>
            </Body>
            <Body question={t('Faq.section25.title')} id={24}>
              <p className={classes.answer}>{t('Faq.section25.body')}</p>
            </Body>
            <Body question={t('Faq.section26.title')} id={25}>
              <Link
                id={12}
                linkLabel="How to Reserve a Desk Within Your Office"
                url="https://app.tango.us/app/workflow/How-to-Reserve-a-Desk-Within-A-Dedicated-Office-8914f482da194bf38bafc2ff2086bb48"
              />
            </Body>
            <Body question={t('Faq.section27.title')} id={26}>
              <p className={classes.answer}>{t('Faq.section27.body')}</p>
            </Body>
            <Body question={t('Faq.section28.title')} id={27}>
              <p className={classes.answer}>{t('Faq.section28.body')}</p>
            </Body>
          </div>
        </div>
      </div>
    </div>
  );
};

const styles = theme => ({
  title: {
    fontFamily: 'VerlagBold',
    [theme.breakpoints.up('xs')]: {
      height: 145,
      fontSize: 36,
      paddingLeft: 40,
      lineHeight: '145px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 24,
      paddingLeft: 20,
      height: 109,
      lineHeight: '109px',
    },
  },
  question: {
    fontFamily: 'VerlagBold',
    color: `${colors.textPalette.link.main} !important`,
    display: 'block',
    paddingTop: 10,
    paddingBottom: 10,
    textDecoration: 'none',
    [theme.breakpoints.up('xs')]: {
      fontSize: 20,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 18,
    },
  },
  questionNotLink: {
    fontFamily: 'VerlagBold',
    display: 'block',
    [theme.breakpoints.up('xs')]: {
      fontSize: 20,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 18,
    },
  },
  answer: {
    fontFamily: 'VerlagLight',
    [theme.breakpoints.up('xs')]: {
      fontSize: 20,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 18,
    },
  },
  contentWrapper: {
    background: colors.palette.secondary2.main,
    paddingTop: 40,
    overflow: 'scroll',
    paddingBottom: 30,
    boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.25) inset',
    [theme.breakpoints.up('xs')]: {
      paddingLeft: 40,
      paddingRight: 40,
    },
    [theme.breakpoints.down('xs')]: {
      paddingLeft: 20,
      paddingRight: 20,
    },
  },
  contentOutline: {
    boxShadow: `0 0 5px 0 rgba(0, 0, 0, 0.25)`,
    backgroundColor: colors.white,
    paddingLeft: 20,
    paddingRight: 20,
    [theme.breakpoints.up(breakpoints.MOBILE)]: {
      paddingLeft: 40,
      paddingRight: 40,
    },
  },
  header: {
    background: colors.white,
    borderBottom: `solid 1px ${colors.light}`,
    paddingBottom: 30,
    paddingTop: 30,
  },
  body: {
    background: colors.white,
    paddingTop: 30,
    paddingBottom: 1,
  },
  response: {
    position: 'relative',
  },
  learnMore: {
    fontFamily: 'VerlagBold',
    [theme.breakpoints.up('xs')]: {
      fontSize: 20,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 18,
    },
  },
});

FAQ.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(FAQ);
