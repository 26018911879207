import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import DeskStatus from './DeskStatus';
import DeskSelection from './DeskSelection';

const DeskItem = ({
  classes,
  desk,
  isSelected,
  handleClickDesk,
  canSelectDesk,
}) => {
  const { t } = useTranslation();
  const isDisabled = !desk.isBookable || desk.isReserved;
  const isClickable =
    !isDisabled && (canSelectDesk || (!canSelectDesk && isSelected));

  const onClickDesk = () => {
    if (!isDisabled) {
      handleClickDesk(desk);
    }
  };

  return (
    <div
      className={clsx(
        classes.deskWrapper,
        isClickable && classes.deskWrapperEnabled,
        !isDisabled &&
          !canSelectDesk &&
          !isSelected &&
          classes.deskWrapperUnselectable,
        isSelected && classes.deskWrapperSelected
      )}
      onClick={onClickDesk}
      aria-hidden="true"
    >
      <div className={classes.deskSelection}>
        <DeskSelection
          isBookable={desk.isBookable}
          isReserved={desk.isReserved}
          nameTeammate={desk.teammate?.name}
          isSelected={isSelected}
        />
        <div className={classes.deskInfo}>
          <div
            className={clsx(
              classes.deskName,
              isDisabled &&
                !isSelected &&
                desk.teammate === null &&
                classes.textDisabled
            )}
          >
            {t('general.desk_number', { deskNumber: desk.deskNumber })}
          </div>
          {desk.isReserved && !isSelected && (
            <div
              className={clsx(
                classes.reservedMember,
                desk.isReserved &&
                  !isSelected &&
                  desk.teammate === null &&
                  classes.textDisabled
              )}
            >
              {desk.teammate !== null ? (
                <>{desk.teammate.name}</>
              ) : (
                <>{t('general.industrious_member')}</>
              )}
            </div>
          )}
        </div>
      </div>
      {!isSelected && (
        <DeskStatus
          isBookable={desk.isBookable}
          isReserved={desk.isReserved}
          teammate={desk.teammate}
        />
      )}
    </div>
  );
};

const styles = () => ({
  deskWrapper: {
    padding: '16px 12px 20px 20px',
    borderTop: '1px solid #e5e5e5',
    position: 'relative',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  deskWrapperEnabled: {
    cursor: 'pointer',
    '&:hover': {
      background: 'rgba(253, 211, 68, 0.2)',
    },
  },
  deskWrapperUnselectable: {
    opacity: '55%',
  },
  deskWrapperSelected: {
    background: '#fee9a2',
    '&:hover': {
      background: '#fee9a2',
    },
  },
  deskSelection: {
    display: 'flex',
    alignItems: 'center',
  },
  deskInfo: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: 20,
  },
  deskName: {
    fontSize: 20,
    fontFamily: 'VerlagBold',
    lineHeight: '26px',
  },
  reservedMember: {
    fontSize: 14,
  },
  textDisabled: {
    color: '#959595',
  },
});

DeskItem.propTypes = {
  classes: PropTypes.object.isRequired,
  desk: PropTypes.shape({
    deskNumber: PropTypes.number,
    isBookable: PropTypes.bool,
    isReserved: PropTypes.bool,
    teammate: PropTypes.shape({
      name: PropTypes.string,
      sfId: PropTypes.string,
      isSelf: PropTypes.bool,
      avatarUrl: PropTypes.string,
    }),
  }).isRequired,
  isSelected: PropTypes.bool.isRequired,
  handleClickDesk: PropTypes.func.isRequired,
  canSelectDesk: PropTypes.bool.isRequired,
};

export default withStyles(styles)(DeskItem);
