import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import TextButton from '../TextButton';
import { colors } from '../../../Core/Theme';

const ActionableListItem = ({
  id,
  mainCaption,
  actionCaption,
  onActionClick,
  classes,
}) => {
  const handleActionClick = () => {
    onActionClick(id);
  };
  return (
    <div className={classes.constainer} id={id}>
      <div className={classes.mainCaption}>{mainCaption}</div>
      <TextButton
        onClick={handleActionClick}
        className={classes.actionCaption}
        text={actionCaption}
      />
    </div>
  );
};

const styles = () => ({
  constainer: {
    fontFamily: 'VerlagLight',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: '53px',
    fontSize: '18px',
    borderBottom: `1px solid ${colors.middle}`,
  },
  mainCaption: {
    color: colors.black,
  },
  actionCaption: {
    color: colors.palette.primary.main,
    cursor: 'pointer',
    fontSize: '18px',
    marginTop: '3px',
  },
});

ActionableListItem.propTypes = {
  id: PropTypes.string.isRequired,
  mainCaption: PropTypes.string.isRequired,
  actionCaption: PropTypes.string.isRequired,
  onActionClick: PropTypes.func.isRequired,
};

export default withStyles(styles)(ActionableListItem);
