/* eslint-disable no-prototype-builtins */
/* eslint-disable import/prefer-default-export */
import { date as DateUtils } from '../../Core/Utils';

export const loadDataForGrouping =
  (
    loadOccasions,
    loadLocationDetails,
    locationDetailsAllIds,
    queriedGroupings,
    setIsLoading,
    setTimezone,
    profile,
    objUserPermissions,
    setHomePageGrouping
  ) =>
  grouping => {
    const { location } = grouping;

    setIsLoading(true);

    setHomePageGrouping(grouping);

    const timeZoneId = location?.timeZoneId;

    const now = DateUtils.getDateInMoment().unix();
    const oldTz = DateUtils.getCurrentZone();

    DateUtils.setTimezone(timeZoneId);

    setTimezone({ newTz: timeZoneId, oldTz });

    const promises = [];

    if (!queriedGroupings.includes(grouping.idGrouping)) {
      promises.push(
        loadOccasions(
          false,
          0,
          grouping.idGrouping,
          location.sfId,
          now,
          undefined,
          undefined,
          true,
          true
        )
      );
    }
    if (!locationDetailsAllIds.includes(location.sfId)) {
      promises.push(loadLocationDetails(location.sfId));
    }

    Promise.all(promises).then(() => {
      setIsLoading(false);
    });
  };
