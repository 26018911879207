import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import ConfigurationCaptions from './Components/ConfigurationCaptions';
import Switch from './Components/CustomSwitch';
import { colors } from '../../../Core/Theme';
import { SkeletonLoader } from '../index';

const RowToggle = props => {
  const {
    title,
    subtitle,
    leftSwitchValue,
    rightSwitchValue,
    leftSwitchHandleChange,
    rightSwitchHandleChange,
    hasError,
    classes,
    isRightSwitchHidden,
    isLeftSwitchHidden,
    isLoading,
  } = props;

  const { t } = useTranslation();
  return (
    <div
      className={clsx(
        classes.root,
        isLeftSwitchHidden && classes.oneToggleRoot
      )}
    >
      <ConfigurationCaptions title={title} subtitle={subtitle} />
      {!isLeftSwitchHidden && (
        <div className={classes.optionElement}>
          {isLoading && <SkeletonLoader className={classes.loader} />}
          {!isLoading && (
            <Switch value={leftSwitchValue} onChange={leftSwitchHandleChange} />
          )}
        </div>
      )}
      {!isRightSwitchHidden && (
        <div className={classes.optionElement}>
          {isLoading && <SkeletonLoader className={classes.loader} />}
          {!isLoading && (
            <Switch
              value={rightSwitchValue}
              onChange={rightSwitchHandleChange}
            />
          )}
        </div>
      )}
      {hasError && (
        <div className={classes.errorText}>
          {t('settings.one_method_enabled')}
        </div>
      )}
    </div>
  );
};

const styles = theme => ({
  root: {
    display: 'grid',
    gridTemplateColumns: 'auto 150px 150px',
    width: '100%',
    marginTop: 20,
    [theme.breakpoints.down('xs')]: {
      gridTemplateColumns: 'auto 90px 90px ',
    },
  },
  oneToggleRoot: {
    gridTemplateColumns: 'auto 150px',
    [theme.breakpoints.down('xs')]: {
      gridTemplateColumns: 'auto 90px',
    },
  },
  optionElement: {
    textAlign: 'right',
  },
  errorText: {
    width: '100%',
    textAlign: 'right',
    gridColumnEnd: 'span 3',
    marginTop: 10,
    fontFamily: 'VerlagBook',
    fontSize: 16,
    color: colors.darkGray,
  },
  loader: {
    width: 50,
    height: 20,
    marginLeft: 'auto',
  },
});

RowToggle.defaultProps = {
  hasError: false,
  subtitle: null,
  isRightSwitchHidden: false,
  isLeftSwitchHidden: false,
  isLoading: false,
};

RowToggle.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  leftSwitchValue: PropTypes.bool.isRequired,
  rightSwitchValue: PropTypes.bool.isRequired,
  leftSwitchHandleChange: PropTypes.func.isRequired,
  rightSwitchHandleChange: PropTypes.func.isRequired,
  hasError: PropTypes.bool,
  isRightSwitchHidden: PropTypes.bool,
  isLeftSwitchHidden: PropTypes.bool,
  isLoading: PropTypes.bool,
};

export default withStyles(styles)(RowToggle);
