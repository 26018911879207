import typeToReducer from 'type-to-reducer';
import Lodash from 'lodash';
import * as types from './actionTypes';

const initialState = {
  total: 0,
  limit: 0,
  offset: 0,
  parcelHistories: [],
  loading: false,
  hasError: false,
  firstLoadingOccurred: false,
  selectedDate: null,
};

export const onLoadParcelsPending = state => {
  return {
    ...state,
    loading: true,
    hasError: false,
  };
};

export const onLoadParcelsFulfilled = (state, action) => {
  const result = Lodash.get(action, 'payload', []);
  const { total, limit, offset } = result;
  const existingParcels = Lodash.get(state, 'parcelHistories', []);
  const receivedParcels = Lodash.get(result, 'parcelHistories', []);
  const parcelHistories = Lodash.unionBy(
    existingParcels,
    receivedParcels,
    'idParcelHistory'
  );
  return {
    ...state,
    total,
    limit,
    offset,
    parcelHistories,
    loading: false,
    hasError: false,
    firstLoadingOccurred: true,
  };
};

export const onLoadParcelsRejected = state => {
  return {
    ...state,
    loading: false,
    hasError: true,
  };
};

export const onResetToInitialState = () => {
  return initialState;
};

export const onSetSelectedDate = (state, action) => {
  const payload = Lodash.get(action, 'payload', null);
  return {
    ...state,
    selectedDate: payload,
  };
};

const parcelsReducer = typeToReducer(
  {
    [types.LOAD_PARCELS]: {
      PENDING: onLoadParcelsPending,
      FULFILLED: onLoadParcelsFulfilled,
      REJECTED: onLoadParcelsRejected,
    },
    [types.RESET_TO_INITIAL_STATE]: onResetToInitialState,
    [types.SET_SELECTED_DATE]: onSetSelectedDate,
  },
  initialState
);

export default parcelsReducer;
