import Auth from '@industriousoffice/member-portal-auth';
import { captureException } from '@sentry/react';
import * as Yup from 'yup';

import { pwValidate } from '../../Core/Utils';
import { resetPassword } from '../../Core/Api/authenticationAPI';

const errorMsgDefault =
  'The email you’ve entered does not belong to an account. Please check your email and try again.';

//  steps function is to automate the change of process of status of page
const steps = (setLoading, cb, setSuccess, setDisplayMsg) => {
  return new Promise(resolve => {
    resolve(setLoading(true));
  })
    .then(() => cb())
    .then(res => {
      if (res.success) {
        setTimeout(() => {
          setLoading(false);
          setSuccess(true);
        }, 1500);
      } else {
        setLoading(false);
        setDisplayMsg(res.message || res.err || errorMsgDefault);
      }
    })
    .catch(e => {
      captureException(e);
      setLoading(false);
      setDisplayMsg('There was an error with your request.');
    });
};

export const submitEmail = async (
  setLoading,
  email,
  setSuccess,
  setDisplayMsg
) => {
  if (!email) {
    return setDisplayMsg('Email cannot be empty.');
  }
  const lowerCasedEmail = email.toLowerCase().trim();
  const schema = Yup.string().email();
  const isValid = await schema.isValid(lowerCasedEmail);
  if (!isValid) {
    return setDisplayMsg('Please enter a valid email.');
  }
  setLoading(true);
  return resetPassword(lowerCasedEmail).then(() => {
    setLoading(false);
    setSuccess(true);
  });
};

export const submitCode = (
  setLoading,
  email,
  code,
  newPassword,
  setSuccess,
  setDisplayMsg
) => {
  const result = pwValidate(newPassword);
  if (result.success) {
    const enforceLowerCase = email.toLowerCase().trim();
    return steps(
      setLoading,
      () => Auth.submitCode(enforceLowerCase, code, newPassword),
      setSuccess,
      setDisplayMsg
    );
  }
  return setDisplayMsg(result.message);
};
