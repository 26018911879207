import PropTypes from 'prop-types';

import LocationAndDateSelectDesktop from './LocationAndDateSelect';
import LocationAndDateSelectMobile from './LocationAndDateSelectMobile';

const LocationAndDateSelectWrapper = props => {
  const { variant, ...rest } = props;
  if (variant && variant === 'mobile') {
    return <LocationAndDateSelectMobile {...rest} />;
  }
  return <LocationAndDateSelectDesktop {...rest} />;
};

LocationAndDateSelectWrapper.defaultProps = {
  variant: null,
};
LocationAndDateSelectWrapper.propTypes = {
  variant: PropTypes.string,
};

export default LocationAndDateSelectWrapper;
