export const EVENT_FETCH_LIMIT = 4;
export const SCHEDULE_FETCH_LIMIT = 15;
export const SCHEDULE_ID_TYPE = {
  EVENT: 'idOccasion',
  BOOKING: 'idBooking',
  DESK: 'Oasis',
};
export const SCHEDULE_TYPE = {
  EVENT: 'EVENT',
  BOOKING: 'BOOKING',
  DESK: 'DESK',
};
export const RSVPS_ACTIONS = {
  UPDATE: 'RSVPS_UPDATE',
  DELETE: 'RSVPS_DELETE',
};
