import PropTypes from 'prop-types';
import clsx from 'clsx';

import { withStyles } from '@material-ui/core/styles';

import IconText from '../IconText';
import { Icons } from '../../../resources';

const LocationText = props => {
  const { classes, variant, selectedGrouping, ...rest } = props;

  const isWhite = variant === 'white';

  return (
    selectedGrouping?.displayName && (
      <IconText
        leftIcon={
          <Icons.LocationPin
            className={clsx(
              classes.iconLocationPin,
              isWhite && classes.isWhite
            )}
          />
        }
        text={selectedGrouping.displayName}
        {...rest}
      />
    )
  );
};

LocationText.defaultProps = {
  variant: '',
  selectedGrouping: {},
};

LocationText.propTypes = {
  variant: PropTypes.string,
  selectedGrouping: PropTypes.object,
};

const styles = () => ({
  iconLocationPin: {
    width: 15,
    height: 15,
  },
  isWhite: {
    filter: 'invert(1)',
  },
});

export default withStyles(styles)(LocationText);
