import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import Lodash from 'lodash';
import { withTranslation } from 'react-i18next';

// Styles
import clsx from 'clsx';
import { colors, breakpoints } from '../../../../../../Core/Theme';

// Common Components
import Button from '../../../../../../Components/Common/BaseButton';
import TitleDescription from '../../../../../../Components/Common/TitleDescription';
import SubmitButton from '../../../../../../Components/Common/SubmitButton';

// Core
import { customProps } from '../../../../../../Core/Utils';
import { MeetingSummaryTypes } from '../../../../constants';

class MeetingEditSummary extends Component {
  LightTooltip = withStyles(theme => ({
    tooltip: {
      backgroundColor: colors.white,
      color: 'rgba(0, 0, 0, 0.87)',
      boxShadow: theme.shadows[1],
      fontSize: 12,
      fontFamily: 'VerlagLight',
      border: '1px solid #898989',
      width: '204px',
      height: '65px',
      borderRadius: '3px',
    },
  }))(Tooltip);

  renderSummary = objSummaryDetails => {
    const { classes, t } = this.props;
    const keys = Object.keys(objSummaryDetails);
    const validKeys = keys.filter(k => objSummaryDetails[k].isVisible);
    const width = `${100 / (validKeys.length || 1)}%`;
    const isTwoElementDisplay = validKeys.length === 2;
    return keys.map(key => {
      if (key === 'time') {
        const value = Lodash.get(objSummaryDetails[key], ['value'], null);

        return (
          <div
            className={classes.titleDescription}
            datatestid={`rr_${key}_selected`}
            style={isTwoElementDisplay ? { width, paddingLeft: 10 } : {}}
          >
            <TitleDescription
              title={t('meetingSummary.time')}
              description={
                value ||
                `${objSummaryDetails[key].initialTime} - ${objSummaryDetails[key].endTime}`
              }
            />
          </div>
        );
      }
      return objSummaryDetails[key].isVisible ? (
        <div
          className={classes.titleDescription}
          datatestid={`rr_${key}_selected`}
          style={isTwoElementDisplay ? { width } : {}}
        >
          <TitleDescription
            title={t(
              `meetingSummary.${objSummaryDetails[key].key
                .toLowerCase()
                .split(' ')
                .join('_')}`
            )}
            description={objSummaryDetails[key].value}
          />
        </div>
      ) : null;
    });
  };

  render() {
    const {
      classes,
      onUpdate,
      onCancel,
      isButtonDisabled,
      data,
      isSubmitting,
      isSuccess,
      submitLabel,
      cancelLabel,
      type,
      isSelectedDateUnavailable,
    } = this.props;

    return (
      <React.Fragment>
        <div className={classes.summaryContainerEdit}>
          <div className={classes.summaryDetailsContainerBooking}>
            <div className={classes.summaryDetailsInfo}>
              {this.renderSummary(data)}
            </div>
            <div className={classes.editButtonsEdit}>
              <React.Fragment>
                {type === MeetingSummaryTypes.EDIT && (
                  <SubmitButton
                    className={classes.buttonUpdateReservation}
                    defaultText={submitLabel}
                    size="large"
                    isLoading={isSubmitting}
                    isSuccess={isSuccess}
                    disabled={isButtonDisabled}
                    onClick={onUpdate}
                    fullWidth
                    datatestid="rr_update_reservation_button"
                  />
                )}
                {!isSelectedDateUnavailable && (
                  <Button
                    className={clsx(
                      classes.buttonCancelReservation,
                      type === MeetingSummaryTypes.CANCELLATION &&
                        classes.buttonOnlyCancelReservation
                    )}
                    size="large"
                    name={cancelLabel}
                    fullWidth
                    onClick={onCancel}
                    datatestid="rr_cancel_reservation_button"
                  />
                )}
              </React.Fragment>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const styles = theme => ({
  editButtonsEdit: {
    display: 'flex',
    flexDirection: 'row-reverse',
    [theme.breakpoints.up(breakpoints.MOBILE)]: {
      alignItems: 'flex-end',
      height: '100%',
      flexDirection: 'column',
      marginRight: '3.8%',
      marginLeft: '3.8%',
    },
    [theme.breakpoints.down(breakpoints.MOBILE)]: {
      width: '100%',
      justifyContent: 'center',
    },
  },
  titleDescription: {
    [theme.breakpoints.up(breakpoints.MOBILE)]: {},
  },
  summaryContainerEdit: {
    flexDirection: 'column',
    display: 'flex',
    [theme.breakpoints.up(breakpoints.MOBILE)]: {
      width: '100%',
    },
    backgroundColor: colors.white,
  },
  summaryDetailsInfo: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    [theme.breakpoints.down(breakpoints.MOBILE)]: {
      flexDirection: 'row',
      width: '100%',
    },
    [theme.breakpoints.up(breakpoints.MOBILE)]: {
      width: '100%',
      alignItems: 'flex-start',
      maxWidth: 400,
    },
  },
  summaryDetailsContainerBooking: {
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'row',
    display: 'flex',
    height: '100%',
    padding: '2%',
    paddingLeft: '5%',
    [theme.breakpoints.up(breakpoints.MOBILE)]: {
      height: '100%',
      paddingTop: 20,
      alignItems: 'flex-start',
    },
    [theme.breakpoints.down(breakpoints.MOBILE)]: {
      flexDirection: 'column',
      padding: '20px',
      height: '105px',
    },
  },
  buttonUpdateReservation: {
    [theme.breakpoints.up(breakpoints.MOBILE)]: {
      width: '210px',

      marginTop: 0,
    },
    [theme.breakpoints.down(breakpoints.MOBILE)]: {
      marginLeft: '10px',
      width: '100%',
      maxWidth: '195px',
    },
  },
  overageIcon: {
    fill: colors.dark,
    height: '16px',
    width: '16px',
    marginLeft: '10px',
  },
  buttonCancelReservation: {
    [theme.breakpoints.up(breakpoints.MOBILE)]: {
      width: '210px',
      marginTop: 15,
    },
    [theme.breakpoints.down(breakpoints.MOBILE)]: {
      width: '100%',
      maxWidth: '195px',
      marginTop: 8,
    },
  },
  buttonOnlyCancelReservation: {
    marginTop: 0,
    maxWidth: '100%',
  },
});

MeetingEditSummary.defaultProps = {
  submitLabel: 'Update Reservation',
  cancelLabel: 'Cancel Reservation',
};

MeetingEditSummary.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  onUpdate: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  isButtonDisabled: PropTypes.bool.isRequired,
  data: customProps.meetingSummary.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  isSuccess: PropTypes.bool.isRequired,
  submitLabel: PropTypes.string,
  cancelLabel: PropTypes.string,
  type: PropTypes.string.isRequired,
  isSelectedDateUnavailable: PropTypes.bool.isRequired,
  t: PropTypes.func.isRequired,
};
export default withTranslation()(withStyles(styles)(MeetingEditSummary));
