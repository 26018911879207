import { useTranslation } from 'react-i18next';

import { formatCurrency } from '../../../../Core/Utils';
import { useMonthlyMeetingRoomStats } from '../../../../Hooks';

import TrackingTable from './TrackingTable';
import TrackingTitle from './TrackingTitle';
import TrackingTableItem from './TrackingTableItem';
import TrackingContainer from './TrackingContainer';

type Props = {
  accountSfId: string;
  startMonthEpoch: number;
  refreshCount: number;
};

const UNLIMITED_PASS_ALLOTTED = -1;

const MeetingRoomStats = (props: Props) => {
  const { accountSfId, startMonthEpoch, refreshCount } = props;
  const { t } = useTranslation();
  const { stats, isLoading, hasError } = useMonthlyMeetingRoomStats({
    accountSfId,
    startMonthEpoch,
    refreshCount,
  });

  const unit = t<string>('general.hours');

  const canShowAllotted = Boolean(
    stats?.monthlyAllowanceCredits || stats?.monthlyAllowanceCredits === 0
  );
  const hasUnlimitedBalance =
    stats?.monthlyAllowanceCredits === UNLIMITED_PASS_ALLOTTED;

  const allottedValue = hasUnlimitedBalance
    ? `${t('myReservations.tracking_hour.unlimited')} ${unit}`
    : `${stats?.monthlyAllowanceCredits} ${unit}`;

  const monthlyCharge = stats
    ? formatCurrency({ value: stats.overage, currency: stats.currency })
    : undefined;

  return (
    <TrackingContainer>
      <TrackingTitle title={t('myReservations.tracking_hour.meeting_rooms')} />
      <TrackingTable>
        {canShowAllotted && (
          <TrackingTableItem
            label={t('myReservations.tracking_hour.allotted')}
            value={hasError ? '' : allottedValue}
            isLoading={isLoading}
          />
        )}
        <TrackingTableItem
          label={t('myReservations.tracking_hour.used')}
          value={hasError ? '' : `${stats?.totalCreditsUsed} ${unit}`}
          isLoading={isLoading}
        />
        {!hasUnlimitedBalance && (
          <TrackingTableItem
            label={t('myReservations.tracking_hour.monthly_charge')}
            value={hasError ? '' : monthlyCharge}
            isLoading={isLoading}
            isLong
          />
        )}
      </TrackingTable>
    </TrackingContainer>
  );
};

export default MeetingRoomStats;
