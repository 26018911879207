import React from 'react';
import PropTypes from 'prop-types';

// Components
import { MapLayerWrapper as Layer } from '../../Common';

// Constants
import { LAYERS } from '../Constants';

// Theme & Utils
import { colors } from '../../../Core/Theme';
import { createHash } from '../../../Core/Utils';

// Layer properties
const fillProperties = {
  id: LAYERS.RESERVABLE_OFFICE,
  type: 'fill',
  source: 'features',
  paint: {
    'fill-color': [
      'case',
      ['boolean', ['feature-state', 'hover'], false],
      colors.palette.primary.light,
      colors.palette.primary.main,
    ],
  },
};

const lineProperties = {
  id: `${LAYERS.RESERVABLE_OFFICE}-lines`,
  type: 'line',
  source: 'features',
  paint: {
    'line-color': colors.palette.primary.main,
    'line-width': 1,
  },
};

// Legend
const legend = {
  key: LAYERS.RESERVABLE_OFFICE,
  style: {
    width: 19,
    height: 19,
    background: `linear-gradient(90deg, ${colors.palette.primary.main} 50%, transparent 50%),repeating-linear-gradient(45deg, ${colors.palette.primary.main},${colors.palette.primary.main} 2px, ${colors.middle} 2px, ${colors.middle} 4px)`,
  },
  labelKey: 'deskFloorPlan.legend.reservableOffice',
};

const ReservableOffice = props => {
  const { offices } = props;
  return (
    <Layer
      key={`${LAYERS.RESERVABLE_OFFICE}-${createHash(offices)}`}
      properties={[fillProperties, lineProperties]}
      filter={['in', ['get', 'id'], ['literal', offices]]}
      {...props}
    />
  );
};

ReservableOffice.defaultProps = {
  offices: [],
};
ReservableOffice.propTypes = {
  offices: PropTypes.arrayOf(PropTypes.string),
};

export { legend };
export default React.memo(ReservableOffice);
