import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Lodash from 'lodash';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import clsx from 'clsx';

// Theme
import { colors } from '../../../Core/Theme';
import Loading from '../Loading';

import { FilterPopoverAnchor } from './Components';

const StyledTableHead = withStyles(() => ({
  root: {
    borderCollapse: 'unset',
    textAlign: 'left',
  },
}))(TableHead);

const StyledTableRow = withStyles(() => ({
  root: {
    '&:nth-of-type(odd)': {
      fontFamily: 'VerlagBold',
    },
    height: '33px',
    position: 'sticky',
  },
}))(TableRow);

const StyledTableCell = withStyles(() => ({
  head: {
    backgroundColor: 'white',
    color: colors.black,
    fontSize: '15px',
    position: 'sticky',
    top: 0,
    borderBottomColor: colors.palette.secondary.main,
    borderBottomWidth: '2px',
    borderCollapse: 'none',
    paddingLeft: '0px',
    textAlign: 'left',
  },
  body: {
    fontSize: '15px',
    borderCollapse: 'unset',
  },
  root: {
    borderCollapse: 'unset',
  },
}))(TableCell);

const renderColumns = (
  columnsData,
  classes,
  { onFilterPress, filtersParams, withInnerPadding }
) => {
  return columnsData.map((column, index) => {
    const filterableItems = Lodash.get(column, ['filters'], []);
    const strColumnLabel = Lodash.get(column, ['label'], null);
    const strColumnDescription = Lodash.get(column, ['description'], null);
    const hasFilterableItems = !Lodash.isEmpty(filterableItems);
    const filters = Lodash.get(filtersParams, [column.key], []);
    const isFirstColumn = index === 0;
    const isLastColumn = index === columnsData.length - 1;
    return (
      <StyledTableCell
        variant="head"
        className={clsx(
          classes.head,
          isFirstColumn && withInnerPadding && classes.innerPaddingLeft,
          isLastColumn && withInnerPadding && classes.innerPaddingRight,
          column.className,
          hasFilterableItems && classes.filterCellHead
        )}
        style={{ borderCollapse: 'unset' }}
        key={column.key}
      >
        {Lodash.toUpper(strColumnLabel)}
        {strColumnDescription ? (
          <div className={classes.headDescription}>{strColumnDescription}</div>
        ) : null}

        {hasFilterableItems && (
          <FilterPopoverAnchor
            items={filterableItems}
            key={column.key}
            onFilter={listFilters => {
              onFilterPress({ ...filtersParams, [column.key]: listFilters });
            }}
            selectedItems={filters}
          />
        )}
      </StyledTableCell>
    );
  });
};

const renderEmptystate = (emptyMessage, classes) => {
  return (
    <div>
      <div className={classes.emptyMessage}>{emptyMessage}</div>
    </div>
  );
};

const TableInfo = props => {
  const {
    renderRow,
    rows,
    classes,
    columns,
    className,
    emptyMessage,
    isLoading,
    customClasses,
    loadingClassName,
    customLoader,
  } = props;
  const headerRow = Lodash.get(customClasses, 'headerRow', null);
  const head = Lodash.get(customClasses, 'head', null);
  return (
    <React.Fragment>
      <Table className={[className, classes.tableStyle].join(' ')}>
        <StyledTableHead className={head || classes.root}>
          <StyledTableRow align="right" className={headerRow || classes.root}>
            {renderColumns(columns, customClasses || classes, props)}
          </StyledTableRow>
        </StyledTableHead>
        {!isLoading ? (
          <TableBody className={classes.body}>{renderRow(rows)}</TableBody>
        ) : null}
      </Table>
      {!isLoading &&
        Lodash.isEmpty(rows) &&
        renderEmptystate(emptyMessage, classes)}
      {isLoading && !customLoader ? (
        <div className={clsx(classes.loading, loadingClassName)}>
          <Loading color={colors.palette.secondary.main} />
        </div>
      ) : null}
      {isLoading && customLoader ? customLoader : null}
    </React.Fragment>
  );
};

const styles = () => ({
  innerPaddingLeft: {
    paddingLeft: '20px !important',
  },
  innerPaddingRight: {
    paddingRight: '20px !important',
  },
  body: {
    overflowY: 'scroll',
  },
  tableStyle: {
    borderCollapse: 'unset',
    tableLayout: 'fixed',
    width: '100%',
  },
  head: {
    padding: 0,
    borderCollapse: 'unset',
    verticalAlign: 'baseline',
  },
  emptyMessage: {
    textAlign: 'center',
    marginTop: 16,
    marginBottom: 16,
  },
  loading: {
    textAlign: 'center',
    marginTop: 70,
  },
  headWithFilter: {
    marginBottom: '12px',
  },
  filterButtonContainer: {
    borderRadius: '3px',
    border: 'solid 0.8px #d8d8d8',
    display: 'inline-flex',
    marginLeft: '10px',
    padding: '3px',
    cursor: 'pointer',
  },
  filterColumnButton: {
    height: 15,
    width: 15,
  },
  filterCellHead: {
    paddingBottom: 6,
  },
  headDescription: {
    fontSize: 10,
    paddingTop: 3,
    paddingBottom: 3,
  },
});

TableInfo.defaultProps = {
  isLoading: false,
  customClasses: null,
  loadingClassName: '',
  customLoader: null,
};
TableInfo.propTypes = {
  renderRow: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  columns: PropTypes.arrayOf(PropTypes.object).isRequired,
  rows: PropTypes.arrayOf(PropTypes.object).isRequired,
  emptyMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
    .isRequired,
  isLoading: PropTypes.bool,
  customClasses: PropTypes.shape({
    head: PropTypes.string,
    headerRow: PropTypes.string,
  }),
  loadingClassName: PropTypes.string,
  customLoader: PropTypes.object,
};
export default withStyles(styles)(TableInfo);
