import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { useTranslation } from 'react-i18next';
import { CloseButton, TextButton } from '../../../../Components/Common';
import { Icons } from '../../../../resources';
import {
  date as DateUtils,
  strings as StringsUtils,
} from '../../../../Core/Utils';
import MapLink from '../../../../Components/Common/MapLink/Maplink';
import { breakpoints } from '../../../../Core/Theme';
import { trackReserveMeetingRoom } from '../../../../Core/Tracking';
import { getIsStandalone } from '../../../../Models/groupings';
import { ROOM_BOOKING_PATH } from '../../../RoomBooking/constants';

const Confirmation = props => {
  const { classes, history, booking, onClose, profile } = props;
  const { t } = useTranslation();

  const { grouping, groupingId, startEpoch, manager } = booking;
  const { location } = grouping;

  const timeZoneId = location?.timeZoneId;

  const onNavigateRoomBooking = () => {
    trackReserveMeetingRoom();
    const strUrlParams = StringsUtils.stringifyParams({
      queryParams: {
        selectedDate: DateUtils.getDateInMoment(startEpoch)
          .tz(timeZoneId)
          .format('YYYY-MM-DD'),
        groupingId,
      },
    });
    history.push({
      pathname: ROOM_BOOKING_PATH,
      search: strUrlParams,
    });
  };

  const onClickEmail = () => window.open(`mailto:${manager?.email}`);

  return (
    <Grid container className={classes.content} spacing={16}>
      <CloseButton onClose={onClose} className={classes.close} />
      <Grid item xs={12} className={classes.confirmCheck}>
        <Icons.ConfirmCheck />
      </Grid>
      <Grid item xs={12} className={classes.title}>
        {t('openWorkspace.confirmation.title')}
      </Grid>
      <Grid item xs={12} className={classes.text}>
        <div>
          {t('openWorkspace.confirmation.see_you')}{' '}
          <MapLink
            location={location}
            grouping={grouping}
            profile={profile}
            className={classes.textButton}
          />
        </div>
        {t('openWorkspace.confirmation.see_on', {
          date: DateUtils.getDateInMoment(startEpoch).format(
            'dddd, MMMM DD, YYYY'
          ),
        })}
      </Grid>
      <Grid item xs={12} className={classes.text}>
        {t('openWorkspace.confirmation.meeting_label')}
        <TextButton
          text={t('openWorkspace.confirmation.meeting_cta')}
          onClick={onNavigateRoomBooking}
          className={classes.textButton}
        />
      </Grid>
      <Grid item xs={12} className={classes.text}>
        {t('openWorkspace.confirmation.requests_label')}
        {manager?.email ? (
          <TextButton
            text={t('openWorkspace.confirmation.requests_cta', {
              locationName: getIsStandalone(grouping)
                ? grouping.groupingName
                : location.externalName,
            })}
            onClick={onClickEmail}
            className={classes.textButton}
          />
        ) : (
          <div>{t('openWorkspace.confirmation.requests_desc')}</div>
        )}
      </Grid>
      <Grid item xs={12} className={classes.text}>
        {t('openWorkspace.confirmation.email_description')}
      </Grid>
    </Grid>
  );
};

const styles = theme => ({
  content: {
    position: 'relative',
    paddingTop: 30,
    paddingBottom: 30,
    paddingLeft: 20,
    paddingRight: 20,
    textAlign: 'center',
    [theme.breakpoints.up(breakpoints.MOBILE)]: {
      paddingTop: 100,
      paddingBottom: 50,
      paddingLeft: 65,
      paddingRight: 65,
    },
  },
  confirmCheck: {
    margin: 'auto',
    paddingTop: 30,

    [theme.breakpoints.up(breakpoints.MOBILE)]: {
      marginBottom: 30,
      paddingTop: 100,
    },
  },
  title: {
    fontFamily: 'VerlagBold',
    fontSize: 24,
    paddingBottom: 15,
  },
  text: {
    fontFamily: 'VerlagLight',
  },
  textButton: {
    display: 'block',
    margin: 'auto',
    fontFamily: 'VerlagBold',
  },
  close: {
    position: 'absolute',
    right: 20,
    top: 20,
    [theme.breakpoints.up(breakpoints.MOBILE)]: { right: 40, top: 40 },
  },
});
Confirmation.defaultProps = {};

Confirmation.propTypes = {
  booking: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  profile: PropTypes.object.isRequired,
};

export default withStyles(styles)(Confirmation);
