import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import Fab from '@material-ui/core/Fab';
import { useTranslation } from 'react-i18next';

// Core
import { colors, breakpoints } from '../../../../../Core/Theme';

// Constants

const DrawerImageHeader = props => {
  const { classes, onCloseButton, src } = props;
  const { t } = useTranslation();
  return (
    <div className={classes.headerImage}>
      <Fab
        size="small"
        onClick={onCloseButton}
        aria-label={t('general.close')}
        className={classes.closeButton}
      >
        <CloseIcon />
      </Fab>
      <img
        src={src}
        alt={t('altTexts.meeting')}
        className={classes.image}
        width="100%"
        height="100%"
      />
    </div>
  );
};

const styles = theme => ({
  headerImage: {
    [theme.breakpoints.up(breakpoints.MOBILE)]: {
      width: '100%',
      maxWidth: '100%',
      height: '35%',
    },
    width: '100%',
    maxWidth: '100%',
    height: '31%',
  },
  image: {
    height: '100%',
    objectFit: 'cover',
  },
  closeButton: {
    color: colors.black,
    backgroundColor: colors.white,
    position: 'absolute',
    right: '15px',
    top: '15px',
    '&:hover': {
      backgroundColor: colors.white,
    },
  },
});

DrawerImageHeader.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  onCloseButton: PropTypes.func.isRequired,
  src: PropTypes.string.isRequired,
};

export default withStyles(styles)(DrawerImageHeader);
