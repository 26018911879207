import { StyleRulesCallback, withStyles } from '@material-ui/core';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import clsx from 'clsx';

type Props = {
  name: string;
  classes: ClassNameMap;
  className?: string;
};

const OfficeName = (props: Props) => {
  const { name, classes, className } = props;

  return <span className={clsx(classes.default, className)}>{name}</span>;
};

OfficeName.defaultProps = {
  className: '',
};

const styles: StyleRulesCallback<string> = () => ({
  default: {
    fontSize: 18,
    fontFamily: 'VerlagBold',
    marginRight: 20,
    letterSpacing: '0.5px',
  },
});

export default withStyles(styles)(OfficeName);
