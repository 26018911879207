import { withStyles } from '@material-ui/core/styles';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import PropTypes from 'prop-types';

import TextButton from '../../TextButton';
import { colors } from '../../../../Core/Theme';

const LinkColumn = props => {
  const { label, onActionPress, classes, type, data } = props;
  const handleOnPress = () => {
    return onActionPress({ type, data });
  };

  return (
    <p className={classes.openLinkCell}>
      <TextButton
        text={
          <>
            {label}
            <OpenInNewIcon
              onClick={handleOnPress}
              className={classes.openNewIcon}
            />
          </>
        }
        onClick={handleOnPress}
        className={classes.openLinkCellButton}
      />
    </p>
  );
};

LinkColumn.propTypes = {
  label: PropTypes.string.isRequired,
  onActionPress: PropTypes.func.isRequired,
  data: PropTypes.shape.isRequired,
  type: PropTypes.string.isRequired,
};

const styles = () => ({
  openLinkCellButton: {
    padding: 0,
    paddingRight: 4,
    textAlign: 'left',
  },
  openLinkCell: {
    display: 'flex',
    alignItems: 'center',
  },
  openNewIcon: {
    color: colors.palette.primary.main,
    width: 14,
    height: 14,
    cursor: 'pointer',
    paddingLeft: 3,
    verticalAlign: 'text-top',
  },
});

export default withStyles(styles)(LinkColumn);
