import React from 'react';

// Api
import { fetchDayPasses } from '../Core/Api';

const useDayPasses = (
  unixDate,
  userProfile,
  alternateAccountSfId = '', // a second account can be set here and the usage for them will be returned.
  options
) => {
  const {
    onlyFetchNonUserAccountData = false, // when true only fetches passes for accounts that aren't the logged in users account
    initialIsFetching = false,
    skip = false, // when true will not fetch any passes
  } = options ?? {};

  const [passesRemaining, setPassesRemaining] = React.useState('--');
  const [hasUnlimitedDayPasses, setHasUnlimitedDayPasses] =
    React.useState(false);
  const [raw, setRaw] = React.useState(true);
  const [isFetching, setIsFetching] = React.useState(initialIsFetching);
  const [hasError, setError] = React.useState(false);
  const [allottedPasses, setAllotted] = React.useState(0);
  const [charge, setCharge] = React.useState(null);
  const [usedPasses, setUsed] = React.useState();

  const accountSfId = alternateAccountSfId || userProfile?.accountSfId;

  const fetchAndSaveDayPasses = async (monthEpoch, strAccountSfId) => {
    if (
      (onlyFetchNonUserAccountData &&
        accountSfId === userProfile.accountSfId) ||
      skip
    ) {
      setIsFetching(false);
      return;
    }

    const strAccountSfIdToUse = strAccountSfId || accountSfId;

    setIsFetching(true);
    fetchDayPasses({
      monthEpoch,
      accountSfId: strAccountSfIdToUse,
      shelf: 'Oasis',
    })
      .then(objResponse => {
        setIsFetching(false);
        setError(false);
        const { data } = objResponse;

        setPassesRemaining(data.walletBalance);
        setHasUnlimitedDayPasses(data.hasUnlimitedDayPasses);
        setAllotted(data.allotted);
        setUsed(data.usages - data.cancellations);
        setCharge(data.overageCharge);
        return setRaw(data);
      })
      .catch(() => {
        setError(true);
        return setIsFetching(false);
      });
  };

  React.useEffect(() => {
    fetchAndSaveDayPasses(unixDate);
  }, [unixDate, alternateAccountSfId]);

  const fetchUsage = (intUnixDate, strAccountSfId) => {
    fetchAndSaveDayPasses(intUnixDate, strAccountSfId);
  };

  return {
    raw,
    isFetching,
    passesRemaining,
    fetchUsage,
    hasError,
    allottedPasses,
    usedPasses,
    charge,
    hasUnlimitedDayPasses,
  };
};

export default useDayPasses;
