import Auth from '@industriousoffice/member-portal-auth';
import { captureException } from '@sentry/react';

import { isNull } from 'util';
import { pwValidate } from '../../Core/Utils';

const steps = (setLoading, cb, setSuccess, setDisplayMsg, t) => {
  return new Promise(resolve => {
    resolve(setLoading(true));
  })
    .then(() => cb())
    .then(
      res => {
        if (res.success) {
          setTimeout(() => {
            setLoading(false);
            setSuccess(true);
          }, 1500);
        } else {
          setDisplayMsg(res.message);
          setLoading(false);
        }
      },
      e => {
        captureException(e);
        setDisplayMsg(t('resetPW.error'));
        setLoading(false);
      }
    );
};

const updatePW = async (
  username,
  tempPassword,
  newPassword,
  updateDisplayMsg,
  setLoading,
  setSuccess,
  t
) => {
  if (isNull(username) || isNull(tempPassword)) {
    return updateDisplayMsg(t('resetPW.navigate_from_email'));
  }

  if (!newPassword) {
    return updateDisplayMsg(t('resetPW.password_not_empty'));
  }

  const validate = pwValidate(newPassword);

  if (validate.success) {
    return steps(
      setLoading,
      () => Auth.firstTimeSignIn(username, tempPassword, newPassword),
      setSuccess,
      updateDisplayMsg
    );
  }
  return updateDisplayMsg(validate.message);
};

export default updatePW;
