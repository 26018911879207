import axios from 'axios';
import queryString from 'query-string';

export const fetchMemberSuggestions = (
  query,
  locationSfId,
  role = '',
  restrictToLocation = false,
  limit = 0,
  offset = 0,
  accountSfId = ''
) => {
  return new Promise(async (resolve, reject) => {
    const suggestions = axios.get(
      `users/lookups?limit=${limit}&offset=${offset}&location_sfid=${locationSfId}&name_contains=${query}&role=${role}&restrict_to_location=${restrictToLocation}&account_sfid=${accountSfId}`
    );
    if (suggestions.status === 403) {
      reject(suggestions);
    }
    resolve(suggestions);
  }).catch(objError => {
    return objError;
  });
};

export const fetchAccountSuggestions = (query, locationSfId, limit, offset) => {
  const objSchema = {
    location_sfid: locationSfId,
    name_contains: query,
    limit,
    offset,
  };
  const strEndpoint = '/accounts/suggestions';

  const strQueryUrl = queryString.stringifyUrl(
    {
      url: strEndpoint,
      query: objSchema,
    },
    {
      skipNull: true,
    }
  );

  return axios.get(strQueryUrl);
};
