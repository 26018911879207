import PropTypes from 'prop-types';

import SearchInputBox from './SearchInputBox';
import SearchInput from './SearchInput';

const searchInputVariant = {
  box: props => <SearchInputBox {...props} />,
};

const SearchInputWrapper = props => {
  const { variant } = props;
  const searchInputVariantComponent = variant
    ? searchInputVariant[variant]
    : null;

  return searchInputVariantComponent ? (
    searchInputVariantComponent(props)
  ) : (
    <SearchInput {...props} />
  );
};

SearchInputWrapper.defaultProps = {
  variant: null,
};

SearchInputWrapper.propTypes = {
  variant: PropTypes.string,
};

export default SearchInputWrapper;
