import { NB_MS_IN_SECONDE, NB_SECONDS_IN_MINUTE } from '../Utils';
import { trackEvent } from './gtag';

export interface BookingToTrack {
  startEpoch: number;
  space: {
    sfId: string;
    location: {
      externalName: string;
    };
    name: string;
    amenities?: { name: string }[];
    seats: number;
  };
}

const createBookingTrack = (booking: BookingToTrack) => {
  const todayEpoch = new Date().getTime();
  const secondsUntilReservation =
    booking.startEpoch - todayEpoch / NB_MS_IN_SECONDE;

  return {
    minutes_until_reservation: secondsUntilReservation / NB_SECONDS_IN_MINUTE,
    reservation_location: booking.space.location.externalName,
    room_name: booking.space.name,
    room_amenities: booking.space.amenities?.map(x => x.name).join(', '),
    room_seats: booking.space.seats,
  };
};

export const trackBookingCreation = (booking: BookingToTrack) => {
  const bookingTrack = createBookingTrack(booking);

  trackEvent('reservation_created', {
    event_category: 'Room reservation',
    reservation_created: 1,
    ...bookingTrack,
  });
};

export const trackBookingUpdate = (booking: BookingToTrack) => {
  const bookingTrack = createBookingTrack(booking);

  trackEvent('reservation_updated', {
    event_category: 'Room reservation',
    reservation_updated: 1,
    ...bookingTrack,
  });
};

export const trackBookingCancellation = (booking: BookingToTrack) => {
  const bookingTrack = createBookingTrack(booking);

  trackEvent('reservation_cancelled', {
    event_category: 'Room reservation',
    reservation_cancelled: 1,
    ...bookingTrack,
  });
};

export const trackBookingCancellationFromSummary = () => {
  trackEvent('Click', {
    event_category: 'Room Reservation Summary',
    event_label: 'Cancel',
  });
};

export const trackBookingUpdateFromSummary = () => {
  trackEvent('Click', {
    event_category: 'Room Reservation Summary',
    event_label: 'Update',
  });
};
