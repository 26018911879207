import { EditorState, convertToRaw, convertFromRaw } from 'draft-js';
import Moment from 'moment-timezone';
import Lodash from 'lodash';
import { RRule, rrulestr } from 'rrule';
import { captureException } from '@sentry/react';

import { DEFAULT_ERROR_MESSAGE, EVENT_FETCH_LIMIT_LIST } from './constants';
import {
  createOccasion,
  deleteOccasion as deleteOccasionApi,
  updateOccasion as updateOccasionApi,
  addOccasionImage,
  getOccasionById,
  unDeleteOccasionType,
  getRsvpsById,
  deleteRsvps,
} from '../../Core/Api';
import { getIsOpenToCoworking } from '../../Models/groupings';
// This util contains the functions this container uses when communicating with the API and for set up

// this function is used to get the initial formik values from a booking (or {}) for the event drawer
export const getInitialValues = (
  selectedLocation,
  selectedDate,
  locations,
  selectedEvent,
  occasionTypesById,
  selectedGrouping = null,
  hospitalityCategories
) => {
  const isPrivate = !getIsOpenToCoworking(selectedGrouping);

  if (!Lodash.isEmpty(selectedEvent)) {
    const {
      locationSfId,
      description,
      isVisible,
      spaceSfId,
      primaryOccasionImage,
      primaryOccasionTypeId,
      occasionTypes,
      cost,
      hospitalityCategory,
      isVirtual,
      locationDetails,
      externalRegistrationLink,
      ...rest
    } = selectedEvent;
    const location = Lodash.find(locations, { sfId: locationSfId });
    const descConverted = EditorState.createWithContent(
      convertFromRaw(JSON.parse(description))
    );
    let roomId = spaceSfId || 'Custom';
    if (roomId === 'Custom' && isVirtual && !locationDetails) {
      roomId = null;
    }
    const eventImage = Lodash.get(primaryOccasionImage, 'imageUrl', null);
    const idOccasionImage = Lodash.get(
      primaryOccasionImage,
      'idOccasionImage',
      null
    );

    const eventTypesSelected = occasionTypes.map(t => {
      const type = occasionTypesById[t];
      const idOccasionType = Lodash.get(type, 'idOccasionType', 0);
      return { ...type, isSelected: true, key: idOccasionType };
    });
    let hospitalityCategoryToUse = hospitalityCategory;
    if (hospitalityCategory && !hospitalityCategory.active) {
      hospitalityCategoryToUse =
        hospitalityCategories?.find(h => h.categoryName === 'Other') ??
        hospitalityCategory;
    }
    if (location) {
      return {
        ...rest,
        spaceSfId: roomId,
        primaryOccasionTypeId: primaryOccasionTypeId.toString(),
        isVisible: isVisible.toString(),
        locationName: location.name,
        description: descConverted,
        eventImage,
        idOccasionImage,
        occasionTypes: eventTypesSelected,
        cost: cost * 1,
        groupingId: selectedGrouping.idGrouping || '',
        isVirtual,
        locationDetails,
        isPrivate,
        hospitalityCategory: hospitalityCategoryToUse
          ? [
              {
                id: hospitalityCategoryToUse.id,
                key: hospitalityCategoryToUse.id,
                categoryName: hospitalityCategoryToUse.categoryName,
                name: hospitalityCategoryToUse.categoryName,
                displayOrder: hospitalityCategoryToUse.displayOrder,
                createdOn: hospitalityCategoryToUse.createdOn,
                imageUrl: hospitalityCategoryToUse.imageUrl,
                active: hospitalityCategoryToUse.active,
              },
            ]
          : [],
        externalRegistrationLink,
        hasExternalLink: !!externalRegistrationLink,
      };
    }
  }
  const now = Moment();
  const minuteOffset = now.minute() % 15;
  const start = Moment.unix(selectedDate).set({
    hours: Moment(now).hours(),
    minutes: Moment(now).minute() - minuteOffset + 15,
    seconds: 0,
  });
  const locationName = Lodash.get(
    selectedLocation,
    ['externalName'],
    'No Location Selected'
  );
  return {
    title: '',
    startEpoch: start.unix(),
    endEpoch: start.add(30, 'minutes').unix(),
    description: EditorState.createEmpty(),
    isVisible: 'false',
    occasionTypes: [],
    cost: 0,
    spaceSfId: null,
    locationName,
    locationDetails: null,
    canRsvp: false,
    attendeeLimit: null,
    canWaitlist: false,
    groupingId: selectedGrouping.idGrouping || '',
    hospitalityCategory: [],
    numberOfAttendees: null,
    isVirtual: false,
    conferenceLink: null,
    conferenceLinkPassword: null,
    hasExternalLink: false,
    externalRegistrationLink: null,
    isPrivate,
  };
};

export const loadMoreEvents =
  (addOccasions, sfId, groupingId, hasMore, lastListStartEpochForGrouping) =>
  () => {
    if (!hasMore) {
      return;
    }
    addOccasions(
      EVENT_FETCH_LIMIT_LIST,
      0,
      groupingId,
      sfId,
      lastListStartEpochForGrouping + 1,
      undefined,
      undefined,
      true,
      false
    );
  };

// this function takes the formik values for creating an event and makes and object that the api can handle to create/update the event
const getValuesForApi = (values, selectedLocation) => {
  const {
    locationDetails,
    spaceSfId,
    startEpoch,
    endEpoch,
    cost,
    title,
    description,
    canRsvp,
    canWaitlist,
    isVisible,
    primaryOccasionTypeId,
    occasionTypes,
    idOccasionImage,
    eventImage,
    attendeeLimit,
    groupingId,
    imageUrl,
    hospitalityCategory,
    isVirtual,
    conferenceLinkPassword,
    conferenceLink,
    hasExternalLink,
    externalRegistrationLink,
  } = values;
  const { address, sfId: locationSfId } = selectedLocation; // hqlocationhack, hq location hack we should be able to save the sfId and just get the value from values when the hack is removed
  const costCurrency = (+cost).toFixed(2);
  const descRaw = JSON.stringify(convertToRaw(description.getCurrentContent()));
  const eventImageIsFile = Lodash.get(eventImage, 'name', null);
  return {
    locationSfId,
    address,
    locationDetails,
    spaceSfId: spaceSfId === 'Custom' ? null : spaceSfId,
    startEpoch: +startEpoch,
    endEpoch: +endEpoch,
    cost: canRsvp ? costCurrency : '0.00',
    currency: 'USD',
    title,
    description: descRaw,
    canRsvp,
    isVisible: Boolean(isVisible),
    primaryOccasionType: { idOccasionType: +primaryOccasionTypeId },
    idOccasionImage: eventImageIsFile ? null : idOccasionImage,
    occasionTypes,
    canWaitlist:
      canRsvp && !hasExternalLink && attendeeLimit ? canWaitlist : false,
    attendeeLimit:
      canRsvp && !hasExternalLink && attendeeLimit ? +attendeeLimit : null,
    groupingId,
    imageUrl,
    hospitalityCategory: !Lodash.isEmpty(hospitalityCategory)
      ? hospitalityCategory[0]
      : null,
    isVirtual,
    conferenceLink: isVirtual ? conferenceLink : null,
    conferenceLinkPassword: isVirtual ? conferenceLinkPassword : null,
    externalRegistrationLink: hasExternalLink ? externalRegistrationLink : null,
  };
};

export const getMultiValuesForApi = (values, selectedLocation) => {
  const { pattern } = values;
  const result = getValuesForApi(values, selectedLocation);
  return { ...result, pattern, isVirtual: false };
};

export const getEditMultiValuesForApi = (
  values,
  selectedLocation,
  occasion,
  occasionTypesById
) => {
  const initial = getValuesForApi(values, selectedLocation);
  const obj = { ...initial, occasionTypes: [] };
  const occasionTypes = Lodash.get(initial, 'occasionTypes', []);
  occasionTypes.forEach(el => {
    const ot = occasionTypesById[el];
    if (ot) obj.occasionTypes.push(occasionTypesById[el]);
  });
  return { idOccasion: Number(occasion.idOccasion), ...obj };
};

export const createEvent =
  (addOccasionRedux, selectedLocation, closeDrawerCb, setCreateNotification) =>
  (setIsLoading, setIsSuccess, setIsError, setErrorMessage) =>
  values => {
    const occasion = getValuesForApi(values, selectedLocation);
    const { eventImage } = values;
    const { idOccasionImage } = occasion;
    setIsLoading();
    return createOccasion(occasion)
      .then(res => {
        if (eventImage && eventImage.name && !idOccasionImage) {
          // if it is a file, upload it via the api
          const { data } = res;
          return addOccasionImage(data.idOccasion, eventImage);
        }

        return res;
      })
      .then(res => {
        const { data } = res;
        addOccasionRedux(data);
        setIsSuccess();
        setTimeout(() => {
          closeDrawerCb();
          setCreateNotification(true);
        }, 1000);
      })
      .catch(e => {
        captureException(e);
        setErrorMessage(DEFAULT_ERROR_MESSAGE);
        setIsError();
      });
  };

export const isEventDuplicate =
  (occasionTypesAllIds, occasionTypesById) => newName => {
    const isDuplicate = occasionTypesAllIds.reduce((acc, id) => {
      if (acc) return acc;
      const currentType = occasionTypesById[id];
      const { name, deletedOn } = currentType;
      return (
        acc || (!deletedOn && name.toLowerCase() === newName.toLowerCase())
      );
    }, false);
    return isDuplicate;
  };

export const updateEvent =
  (
    updateOccasionRedux,
    selectedEvent,
    selectedLocation,
    occasionRsvps,
    updateRsvpRedux,
    closeDrawerCb
  ) =>
  (
    setIsLoading,
    setIsSuccess,
    setIsError,
    setErrorMessage,
    values,
    isValid,
    touchAll
  ) =>
  async () => {
    if (!isValid) {
      touchAll();
      return;
    }

    const { idOccasion } = selectedEvent;
    const rsvp = (occasionRsvps || []).filter(o => o.occasionId === idOccasion);

    const occasion = getValuesForApi(values, selectedLocation);

    const wasEventChanged = !Object.keys(occasion).every(key => {
      return occasion[key] === selectedEvent[key];
    });

    const { eventImage } = values;
    const existingImage = Lodash.get(
      selectedEvent,
      ['primaryOccasionImage', 'imageUrl'],
      ''
    );
    setIsLoading();

    try {
      if (eventImage && eventImage !== existingImage) {
        const updatedOccasion = await addOccasionImage(idOccasion, eventImage);
        updateOccasionRedux(updatedOccasion.data);
      }

      if (wasEventChanged) {
        const updatedOccasion = await updateOccasionApi(idOccasion, occasion);
        updateOccasionRedux(updatedOccasion.data);
        const id = Lodash.get(rsvp, [0, 'idRsvp'], 0);
        if (id) {
          getRsvpsById(id).then(objResponse => {
            const { data } = objResponse;
            updateRsvpRedux(data);
          });
        }
      }

      setIsSuccess();
      setTimeout(closeDrawerCb, 3000);
    } catch (e) {
      captureException(e);
      setErrorMessage(DEFAULT_ERROR_MESSAGE);
      if (occasion.attendeeLimit < selectedEvent.attendeeLimit) {
        setErrorMessage(
          'Cannot limit the attendee list because of existing RSVPs.'
        );
      }
      if (
        selectedEvent.externalRegistrationLink &&
        !occasion.externalRegistrationLink
      ) {
        setErrorMessage('Cannot remove external registration url from event');
      }
      setIsError();
    }
  };

export const deleteEvent =
  (deleteOccasionRedux, selectedEvent, closeDrawerCb) =>
  (setIsLoading, setIsSuccess, setIsError, setErrorMessage) =>
  () => {
    const { idOccasion } = selectedEvent;
    setIsLoading();
    return deleteOccasionApi(idOccasion)
      .then(() => {
        deleteOccasionRedux(selectedEvent);
        setIsSuccess();
        setTimeout(closeDrawerCb, 1000);
      })
      .catch(e => {
        captureException(e);
        setErrorMessage(DEFAULT_ERROR_MESSAGE);
        setIsError();
      });
  };

export const deleteMultiEvent =
  (deleteOccasionRedux, event, cbCloseDrawer, setStatus) => () => {
    const { idOccasion } = event;
    setStatus('loading');
    return deleteOccasionApi(idOccasion)
      .then(() => {
        deleteOccasionRedux(event);
        setStatus('success');
        setTimeout(cbCloseDrawer, 1000);
      })
      .catch(e => {
        captureException(e);
        setStatus('error');
      });
  };

export const unDeleteType =
  (id, setDeletedTypeId, updateOccasionTypeRedux, setShouldResetTypeResults) =>
  () => {
    unDeleteOccasionType(id).then(objResponse => {
      const { data } = objResponse;
      updateOccasionTypeRedux(data);
      setDeletedTypeId(0);
      setShouldResetTypeResults(true);
    });
  };

export const removeRsvps = (
  setUndoDeleteMessage,
  rsvps,
  deleteRsvpRedux,
  selectedEvent,
  updateOccasionRedux
) => {
  const rsvpIds = rsvps.map(r => r.idRsvp);
  setUndoDeleteMessage(null);
  rsvpIds.forEach(a => {
    deleteRsvpRedux(a);
  });

  deleteRsvps(rsvpIds).then(res => {
    if (res) {
      getOccasionById(selectedEvent).then(response => {
        updateOccasionRedux(response.data);
      });
    }
  });
};

// dayOfWeek: Monday, Tuesday...
export const removeDayFromDailyRRule = (dayOfWeek, strRRule) => {
  const rRuleDaysObj = {
    Monday: 0,
    Tuesday: 1,
    Wednesday: 2,
    Thursday: 3,
    Friday: 4,
    Saturday: 5,
    Sunday: 6,
  };
  const rRuleDaysMap = {
    0: 'MO',
    1: 'TU',
    2: 'WE',
    3: 'TH',
    4: 'FR',
    5: 'SA',
    6: 'SU',
  };
  const dow = rRuleDaysObj[dayOfWeek];
  const rule = rrulestr(strRRule);
  const recurrence = Lodash.get(rule, ['options', 'byweekday'], []);
  const newRecurrence = recurrence.filter(el => el !== dow);
  const recurrenceMap = newRecurrence.map(el => rRuleDaysMap[el]);
  const newRule = new RRule({
    freq: RRule.DAILY,
    byweekday: recurrenceMap.map(el => RRule[el]),
  });
  return newRule.toString();
};

export const renderVirtualLink = (
  canAccessUrl,
  canRsvp,
  rsvpd,
  eventFull,
  content
) => {
  if (!canRsvp) {
    return content;
  }

  if (canRsvp && (rsvpd || canAccessUrl) && !eventFull) {
    return content;
  }

  return null;
};
