// A full screen page without the standard navigation header
// public routes
// EX: Login, resetPW
export const PAGE = 'PAGE';

// The private route version of a page, Represents the content below the header
// Appears in the signout/main menu
// EX: Settings
export const CONTENT = 'CONTENT';

//  Private routes, doesn't appear in any menu
//  EX: Header
export const NAV = 'NAV';

//  Private routes, content below the standard header, but appears in the subnav
// EX: Calendar
export const SUBCONTENT = 'SUBCONTENT';
