import MemoizeOne from 'memoize-one';

export const getSfBaseUrl = MemoizeOne(() => {
  const sfEnv = process.env.REACT_APP_SF_ENV;
  if (sfEnv === 'develop' || sfEnv === 'staging') {
    return `https://industrious--${sfEnv}.my.salesforce.com/`;
  }
  if (sfEnv === 'prod') {
    return 'https://industrious.lightning.force.com/';
  }
  return '';
});

export const getStaticUrl = (path: string) => {
  if (!path) {
    return '';
  }
  const sfEnv = process.env.REACT_APP_SF_ENV;
  return `https://mpstatic.industriousoffice.com/${sfEnv}/${path}`;
};

export const getStaticImageUrl = (fileName: string) => {
  return getStaticUrl(fileName);
};

export const getGoogleMapsLink = (
  location: { address: string; city: string; state: string; zipCode: string },
  brandName: string = ''
) => {
  const { address, city, state, zipCode } = location;

  return `https://www.google.com/maps/search/?api=1&query=${encodeURI(
    `${brandName} ${address} ${city} ${state} ${zipCode}`
  )}`;
};

export const getLightningUrl = () => {
  const sfEnv = process.env.REACT_APP_SF_ENV;
  switch (sfEnv) {
    case 'prod':
      return 'https://industrious.lightning.force.com';
    case 'staging':
      return 'https://industrious--staging.sandbox.my.salesforce.com';
    default:
      return 'https://industrious--develop.sandbox.my.salesforce.com';
  }
};

export const getWebsiteUrl = (suffix: string) => {
  const base = process.env.REACT_APP_WEBSITE_URL;
  return `${base}/${suffix}`;
};
