import Lodash from 'lodash';
import typeToReducer from 'type-to-reducer';
import moment from 'moment-timezone';

import { FILTERS_TYPE } from '../constants';
import { date as Date } from '../../../Core/Utils';
import * as types from './actionTypes';

const initialState = {
  // room vars
  amenFilters: [],
  amenities: [],
  seatFilters: [],
  floorsFilters: [],
  queriedDateAndLocation: [],
  // user input vars
  selectedDate: Date.getTodayNotWeekend(),
  selectedMonth: null,
  selectedLocation: {},
  amenitiesLoading: false,
  upcomingResvDrawerIsOpen: false,
  tz: moment.tz.guess(true),
  bookingRestrictions: {},
  selectedGrouping: null,
  queriedDateAndGrouping: {},
  lastGroupingAt: {},
};

function toggleInArr(arr, val) {
  const inArr = arr.includes(val);
  let newArr = [];
  if (inArr) {
    newArr = arr.filter(value => value !== val);
  } else {
    newArr = arr.concat(val);
  }
  return newArr;
}

const onSetTimeZone = (state, action) => {
  const { oldTz, newTz } = Lodash.get(action, ['payload'], {});
  const strSelectedDate =
    Lodash.get(state, ['selectedDate'], null) || Date.getTodayNotWeekend();
  const strSelectedMonth = Lodash.get(state, ['selectedMonth'], null);

  const strInternalOldTz = oldTz || moment.tz.guess(true);
  const initialMonth = moment.tz(newTz).startOf('month').unix();
  return {
    ...state,
    selectedDate: Date.updateDateForTZ(
      strSelectedDate,
      newTz,
      strInternalOldTz
    ),
    selectedMonth: !strSelectedMonth
      ? initialMonth
      : Date.updateDateForTZ(strSelectedMonth, newTz, strInternalOldTz),
    tz: newTz,
  };
};

export const onAddAmenitiesFulfilled = (state, action) => {
  const amenities = Lodash.get(action, ['payload'], {});
  return {
    ...state,
    amenities,
    amenitiesLoading: false,
  };
};

const onAddAmenitiesRejected = state => {
  return {
    ...state,
    amenitiesLoading: false,
  };
};

export const onAddAmenitiesPending = state => {
  return {
    ...state,
    amenitiesLoading: true,
  };
};

// UPDATE_DATE
export const onUpdateDate = (state, action) => {
  const datePayload = Lodash.get(action, ['payload'], {});
  return {
    ...state,
    selectedDate: datePayload,
  };
};

// UPDATE_MONTH
export const onUpdateMonth = (state, action) => {
  const datePayload = Lodash.get(action, ['payload'], {});
  return {
    ...state,
    selectedMonth: datePayload,
  };
};

// SET_LOCATION
export const onSetLocation = (state, action) => {
  const datePayload = Lodash.get(action, ['payload'], {});
  return {
    ...state,
    selectedLocation: datePayload,
  };
};

// SET_GROUPING
export const onSetGrouping = (state, action) => {
  const grouping = Lodash.get(action, ['payload'], {});
  const { idGrouping, locationSfId } = grouping;
  return {
    ...state,
    lastGroupingAt: {
      ...state.lastGroupingAt,
      [locationSfId]: idGrouping,
    },
    selectedGrouping: grouping,
  };
};

// TOGGLE_FILTER
export const onToggleFilter = (state, action) => {
  const typePayload = Lodash.get(action, ['payload', 'type'], {});
  if (typePayload === FILTERS_TYPE.AMENITY) {
    return {
      ...state,
      amenFilters: toggleInArr(state.amenFilters, action.payload.filter),
    };
  }
  if (typePayload === FILTERS_TYPE.FLOOR) {
    return {
      ...state,
      floorsFilters: toggleInArr(state.floorsFilters, action.payload.filter),
    };
  }
  if (typePayload === FILTERS_TYPE.SEAT) {
    const seatFilters = state.seatFilters.includes(action.payload.filter)
      ? []
      : [action.payload.filter];
    return {
      ...state,
      seatFilters,
    };
  }
  return state;
};

// CLEAR_ALL_FILTERS
export const onClearFilters = state => {
  return {
    ...state,
    seatFilters: [],
    amenFilters: [],
    floorsFilters: [],
  };
};

// ADD_QUERIED_DATE_LOCATION
export const onAddQueriedDateAndLocation = (state, action) => {
  const queriedDateAndLocationPayload = Lodash.get(action, ['payload'], {});
  const queriedDateAndLocation = Lodash.get(
    state,
    ['queriedDateAndLocation'],
    []
  );
  if (Lodash.includes(queriedDateAndLocation, queriedDateAndLocationPayload)) {
    return state;
  }
  const queriedDateAndLocationUpdated = [
    ...queriedDateAndLocation,
    queriedDateAndLocationPayload,
  ];
  return {
    ...state,
    queriedDateAndLocation: queriedDateAndLocationUpdated,
  };
};

export const onAddQueriedDateGrouping = (state, action) => {
  const { payload } = action;
  const { groupingId, date } = payload;

  const existingDates = Lodash.get(
    state,
    ['queriedDateAndGrouping', groupingId],
    []
  );
  const newDateList = [...existingDates, date];
  return {
    ...state,
    queriedDateAndGrouping: {
      ...state.queriedDateAndGrouping,
      [groupingId]: newDateList,
    },
  };
};

export const setUpcomingResvDrawerState = (state, action) => {
  const { payload } = action;
  return {
    ...state,
    upcomingResvDrawerIsOpen: payload,
  };
};

export const addBookingRestriction = (state, action) => {
  const { payload } = action;
  const { month, locationSfId, data } = payload;
  const currentLocationRestrictions = Lodash.get(
    state.bookingRestrictions,
    locationSfId,
    {}
  );
  return {
    ...state,
    bookingRestrictions: {
      ...state.bookingRestrictions,
      [locationSfId]: { ...currentLocationRestrictions, [month]: data },
    },
  };
};

const contentReducer = typeToReducer(
  {
    [types.UPDATE_DATE]: onUpdateDate,
    [types.UPDATE_MONTH]: onUpdateMonth,
    [types.SET_LOCATION]: onSetLocation,
    [types.TOGGLE_FILTER]: onToggleFilter,
    [types.ADD_QUERIED_DATE_LOCATION]: onAddQueriedDateAndLocation,
    [types.SET_TIMEZONE]: onSetTimeZone,
    [types.ADD_ROOM_AMENITIES]: {
      PENDING: onAddAmenitiesPending,
      FULFILLED: onAddAmenitiesFulfilled,
      REJECTED: onAddAmenitiesRejected,
    },
    [types.SET_UPCOMING_RESV_DRAWER_STATE]: setUpcomingResvDrawerState,
    [types.ADD_BOOKING_RESTRICTION]: addBookingRestriction,
    [types.SET_GROUPING]: onSetGrouping,
    [types.ADD_QUERIED_DATE_GROUPING]: onAddQueriedDateGrouping,
    [types.CLEAR_ALL_FILTERS]: onClearFilters,
  },
  initialState
);

export default contentReducer;
