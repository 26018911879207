import Lodash from 'lodash';

import { getIsOpenToCoworking, getIsStandalone } from '../../Models/groupings';
import { ACTIONS_COLUMNS } from '../../Components/Common/SmartTable/Constants';

export const getColumns = (
  classes,
  t,
  groupingAccounts,
  coworkingAccountsCount
) => {
  return [
    {
      key: info => {
        const grouping = info.data;
        return (
          <div className={classes.nameCell}>
            {Lodash.get(grouping, 'groupingName', '')}
          </div>
        );
      },
      label: t('groupManagement.columns.name'),
      className: classes.nameColumn,
    },
    {
      key: info => {
        const grouping = info.data;

        if (getIsOpenToCoworking(grouping)) {
          return t('groupManagement.table.all_coworking');
        }
        const accountsForGrouping = groupingAccounts.filter(
          a => a.groupingId === grouping.idGrouping
        );
        if (!accountsForGrouping.length) {
          return t('groupManagement.table.no_accounts');
        }
        const showAndMore = accountsForGrouping.length > 6;
        const accountNames = accountsForGrouping.map((a, i) => (
          <div
            className={classes.accountItem}
            key={`account_grouping_${a.accountSfId}_${a.groupingId}`}
          >
            <div className={classes.accountName}>{a.account.name}</div>
            {i === 5 && showAndMore && (
              <div className={classes.andMore}>
                and {accountsForGrouping.length - 6} more
              </div>
            )}
          </div>
        ));
        return (
          <div className={classes.accountArea}>
            <div className={classes.accountSubColumn}>
              {accountNames.slice(0, 3)}
            </div>
            <div className={classes.accountSubColumn}>
              {accountNames.slice(3, 6)}
              {showAndMore ? (
                <div className={classes.andMoreSmall}>
                  and {accountNames.length - 6} more
                </div>
              ) : null}
            </div>
          </div>
        );
      },
      label: t('groupManagement.columns.accounts'),
      className: classes.accountColumn,
    },

    {
      key: info => {
        const grouping = info.data;
        if (getIsStandalone(grouping)) {
          return t('groupManagement.table.show_separate');
        }
        return t('groupManagement.table.show_with');
      },
      label: t('groupManagement.columns.display'),
    },

    {
      key: 'options',
      label: '',
      type: 'options',
      options: grouping => {
        return getIsOpenToCoworking(grouping)
          ? [
              {
                label: t('groupManagement.table.actions.view', {
                  count: coworkingAccountsCount,
                }),
                type: 'edit',
              },
            ]
          : [
              { label: t('groupManagement.table.actions.edit'), type: 'edit' },
              {
                label: t('groupManagement.table.actions.delete'),
                type: ACTIONS_COLUMNS.CANCEL,
              },
            ];
      },
    },
  ];
};

export const compareGroupings = (groupA, groupB) => {
  const nameA = Lodash.get(groupA, 'groupingName', '');
  const nameB = Lodash.get(groupB, 'groupingName', '');
  if (!nameA || !nameB) {
    return 1;
  }
  if (groupA.openToCoworkingMembers) {
    return -1;
  }
  if (groupB.openToCoworkingMembers) {
    return 1;
  }
  return nameA.toLowerCase() >= nameB.toLowerCase() ? 1 : -1;
};

export const openSalesforce = (sfUrl, sfId) => () =>
  window.open(`${sfUrl}${sfId}`);
