import React from 'react';
import PropTypes from 'prop-types';

// Components
import Label from './Label';

// Constants
import { LAYERS } from '../Constants';

// Theme & Utils
import { colors } from '../../../Core/Theme';
import { createHash } from '../../../Core/Utils';

// Layer properties
const symbolProperties = {
  id: `${LAYERS.LABEL}-SelectedDesk`,
  type: 'symbol',
  source: 'features',
  paint: {
    'text-color': colors.darkest,
  },
  layout: {
    'text-size': ['interpolate', ['linear'], ['zoom'], 15, 10, 18, 12],
    'text-allow-overlap': true,
    'text-transform': 'uppercase',
    'text-font': ['Verlag Book'],
  },
};

const SelectedDesksLabels = props => {
  const { desks, indicators } = props;
  return (
    <Label
      key={`${LAYERS.LABEL}-SelectedDesk-${createHash(desks)}`}
      properties={[symbolProperties]}
      filter={['in', ['get', 'id'], ['literal', desks]]}
      layout={{
        ...symbolProperties.layout,
        'text-field': ['get', ['get', 'id'], ['literal', indicators]],
      }}
      labelVisible
      {...props}
    />
  );
};

SelectedDesksLabels.defaultProps = {
  desks: [],
  indicators: {},
};
SelectedDesksLabels.propTypes = {
  desks: PropTypes.arrayOf(PropTypes.string),
  indicators: PropTypes.shape({ [PropTypes.string]: PropTypes.string }),
};

export default React.memo(SelectedDesksLabels);
