import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

import Tooltip from '../Tooltip';
import { breakpoints } from '../../../Core/Theme';

import { Icons } from '../../../resources';

const { CloseIcon } = Icons;

const InfoTooltip = props => {
  const { classes, isVisible, description, onClose, customClasses } = props;

  const close = () => onClose && onClose();

  return (
    <Tooltip
      {...props}
      open={isVisible}
      customClasses={{
        tooltip: classes.tooltip,
        arrow: classes.arrowTooltip,
        arrowPopper: customClasses.arrowPopper,
      }}
    >
      <div className={classes.closeIconContainer}>
        <CloseIcon className={classes.closeIcon} onClick={close} />
      </div>
      <div className={classes.descriptionContainer}>{description}</div>
    </Tooltip>
  );
};

const styles = theme => ({
  arrowTooltip: {
    [theme.breakpoints.down(breakpoints.MOBILE)]: {
      top: 'unset !important',
    },
  },
  tooltip: {
    textAlign: 'center',
    padding: '0 0 0 0',
  },
  closeIconContainer: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'flex-end',
    position: 'absolute',
    right: 7,
    top: 5,
  },
  closeIcon: {
    width: 10,
    height: 7,
    cursor: 'pointer',
  },
  descriptionContainer: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    paddingLeft: '15px',
    paddingRight: '15px',
    paddingTop: '15px',
    paddingBottom: '10px',
    fontSize: '14px',
    fontFamily: 'VerlagLight',
  },
});

InfoTooltip.defaultProps = {
  customClasses: {},
};
InfoTooltip.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  description: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  customClasses: PropTypes.shape,
};

export default withStyles(styles)(InfoTooltip);
