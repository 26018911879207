import Components from '../../Components';
import { companyInfoAccess, accessHQ } from '../../Core/Utils/userPermissions';

const { containerTypes } = Components.Routes;

const RouteConfig = {
  path: '/home/companyprofile',
  exact: true,
  name: 'navigation.menu.company',
  private: true,
  type: containerTypes.CONTENT,
  isAuthorized: ({ profile }) => {
    return (
      profile &&
      companyInfoAccess.update({
        profile,
        obj: profile.account,
      }) &&
      !accessHQ(profile)
    );
  },
};

export default RouteConfig;
