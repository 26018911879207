import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

import Slide from '@material-ui/core/Slide';
import { breakpoints } from '../../../Core/Theme';
import { Loading, FloatingActionDescription } from '../../../Components/Common';
import EmptyState from './EmptyState';

import Office from './Office';

const OfficesList = ({
  classes,
  offices,
  isLoading,
  desksIdsSelected,
  handleClickDesk,
  isDisabled,
  floatingDescription,
  canSelectDesk,
  editingReservation,
  userReservedDesks,
  isBannerVisible,
  tooltipSelectedDeskId,
}) => {
  return (
    <>
      {isLoading ? (
        <div className={classes.loading}>
          <Loading />
        </div>
      ) : (
        <>
          {offices.length === 0 && <EmptyState />}
          {offices.map(office => (
            <div className={classes.officeWrapper}>
              <Office
                key={office.parentOfficeSfId}
                office={office}
                desksIdsSelected={desksIdsSelected}
                handleClickDesk={handleClickDesk}
                isDisabled={isDisabled}
                canSelectDesk={canSelectDesk}
                editingDesk={
                  editingReservation.desk &&
                  office.parentOfficeSfId ===
                    editingReservation.desk.parentOffice.sfId
                    ? editingReservation.desk
                    : null
                }
                userReservedDesks={userReservedDesks}
                tooltipSelectedDeskId={tooltipSelectedDeskId}
              />
            </div>
          ))}
        </>
      )}
      <Slide direction="up" in={isBannerVisible} mountOnEnter unmountOnExit>
        <FloatingActionDescription description={floatingDescription} />
      </Slide>
    </>
  );
};

const styles = theme => ({
  loading: {
    display: 'flex',
    justifyContent: 'center',
  },
  officeWrapper: {
    width: 'calc(100% - 40px)',
    margin: '0 auto 10px',
    [theme.breakpoints.up(breakpoints.MOBILE)]: {
      width: 555,
      marginBottom: 20,
    },
  },
});

OfficesList.defaultProps = {
  offices: [],
  floatingDescription: null,
  userReservedDesks: [],
  isBannerVisible: false,
  tooltipSelectedDeskId: null,
};

OfficesList.propTypes = {
  classes: PropTypes.object.isRequired,
  offices: PropTypes.arrayOf(PropTypes.shape({})),
  isLoading: PropTypes.bool.isRequired,
  desksIdsSelected: PropTypes.arrayOf(PropTypes.number).isRequired,
  handleClickDesk: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool.isRequired,
  floatingDescription: PropTypes.arrayOf(PropTypes.string),
  canSelectDesk: PropTypes.bool.isRequired,
  editingReservation: PropTypes.object.isRequired,
  userReservedDesks: PropTypes.arrayOf(PropTypes.shape({})),
  isBannerVisible: PropTypes.bool,
  tooltipSelectedDeskId: PropTypes.number,
};

export default withStyles(styles)(OfficesList);
