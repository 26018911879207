import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

import { colors } from '../../../Core/Theme';

const LabelChip = props => {
  const { classes, className, label } = props;
  return <span className={clsx(classes.container, className)}>{label}</span>;
};

const styles = {
  container: {
    color: colors.palette.secondary.main,
    borderRadius: 17,
    fontFamily: 'VerlagBold',
    padding: '4px 8px 4px 8px',
    border: `1px solid ${colors.palette.secondary.main}`,
    fontSize: 11,
    lineHeight: 1.1,
  },
};

LabelChip.defaultProps = {
  className: '',
  label: '',
};

LabelChip.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
};

export default withStyles(styles)(LabelChip);
