import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { colors } from '../../../../Core/Theme';
import { date as DateUtils } from '../../../../Core/Utils';

const DayBox = props => {
  const { momentStart, classes, timeZoneId, datatestid } = props;
  const { t } = useTranslation();

  return (
    <div className={classes.dayBox} datatestid={datatestid}>
      <div className={classes.dayBoxDay}>
        {DateUtils.getDayString(momentStart, timeZoneId, t)}
      </div>
      <div className={classes.dayBoxDate}>{momentStart.format('[MMM D]')}</div>
    </div>
  );
};

const styles = () => ({
  dayBox: {
    minWidth: 65,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    borderRight: `solid 1px ${colors.middle}`,
  },
  dayBoxDay: {
    fontFamily: 'VerlagBold',
    fontSize: 9,
  },
  dayBoxDate: {
    fontFamily: 'VerlagBold',
    fontSize: 18,
    marginTop: 5,
  },
});

DayBox.defaultProps = {
  timeZoneId: null,
  datatestid: 'day_box',
};

DayBox.propTypes = {
  classes: PropTypes.object.isRequired,
  momentStart: PropTypes.object.isRequired,
  timeZoneId: PropTypes.string,
  datatestid: PropTypes.string,
};

export default withStyles(styles)(DayBox);
