/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Modal from '@material-ui/core/Modal';

// Theme
import { colors } from '../../../../Core/Theme';
import { CloseButton, SubmitButton, TextButton, FilterButton } from '../..';

const FilterModal = props => {
  const {
    classes,
    onFilter,
    isOpen,
    onClose,
    columns,
    activeItems,
    onClickItem,
    onClearFilters,
  } = props;

  const { t } = useTranslation();

  const itemsByColumn = {};
  columns
    .filter(c => c.filters?.length)
    .forEach(c => {
      itemsByColumn[c.key] = c.filters;
    });

  const renderFilters = column => {
    const { key, filters } = column;
    return filters.map(l => {
      const { label, value } = l;
      const handleOnClick = () => onClickItem({ value, columnKey: key });
      return (
        <FilterButton
          label={label}
          onClick={handleOnClick}
          className={classes.filterButton}
          isSelected={activeItems[key]?.includes(value)}
          key={value}
        >
          {label}
        </FilterButton>
      );
    });
  };

  const renderItems = () => {
    return Object.keys(itemsByColumn).map(key => {
      const column = columns.find(c => c.key === key);
      return (
        <div>
          <div className={classes.sectionLabel}>
            {column.label.toUpperCase()}
          </div>
          <div>{renderFilters(column)}</div>
        </div>
      );
    });
  };

  return (
    <Modal open={isOpen}>
      <div className={classes.container}>
        <div className={classes.header}>
          <TextButton
            text={t('general.table.filters.clear')}
            onClick={onClearFilters}
          />
          <CloseButton onClose={onClose} />
        </div>
        <div className={classes.title}>{t('general.table.filters.title')}</div>
        <div className={classes.filters}> {renderItems()}</div>
        <div className={classes.submitButtonSection}>
          <SubmitButton
            defaultText={t('general.table.filters.cta')}
            onClick={onFilter}
            className={classes.submitButton}
          />
        </div>
      </div>
    </Modal>
  );
};

const styles = () => ({
  container: {
    height: '100vh',
    background: colors.palette.background.paper,
    paddingLeft: 20,
    paddingRight: 20,
    paddingTop: 20,
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  title: {
    textAlign: 'center',
    fontFamily: 'VerlagBold',
    fontSize: 20,
  },
  filters: {},
  sectionLabel: {
    fontFamily: 'VerlagBook',
    fontSize: 12,
    paddingTop: 30,
  },
  filterButton: {
    marginRight: 10,
  },
  submitButtonSection: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    paddingBottom: 30,
    paddingTop: 20,
    background: 'white',
  },
  submitButton: {
    height: 50,
    marginLeft: 20,
    marginRight: 20,
    width: 'calc(100% - 40px)',
  },
});

FilterModal.propTypes = {
  onFilter: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onClickItem: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  columns: PropTypes.arrayOf(PropTypes.object).isRequired,
  onClearFilters: PropTypes.func.isRequired,
  activeItems: PropTypes.shape({}).isRequired,
};
export default withStyles(styles)(FilterModal);
