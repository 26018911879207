import Lodash from 'lodash';
import { locationRoleAccess } from '../../../Core/Utils/userPermissions';
import { getBookingRestrictions } from '../../../Core/Api';

const getAndAddBookingRestrictions =
  (addBookingRestriction, profile, bookingRestrictions, locations) =>
  (month, locationSfId) => {
    const location = Lodash.find(locations, { sfId: locationSfId }) || {
      sfId: locationSfId,
    };
    if (
      !locationRoleAccess.hasBookingRestrictions({ profile, obj: location })
    ) {
      return;
    }
    const existingRestrictions = Lodash.get(
      bookingRestrictions,
      ['location', 'month'],
      null
    );
    if (!existingRestrictions) {
      getBookingRestrictions(profile.sfId, month, locationSfId).then(
        response => {
          const { data } = response;
          const payload = {
            locationSfId,
            month,
            data,
          };
          addBookingRestriction(payload);
        }
      ); // todo handle error state
    }
  };

export default getAndAddBookingRestrictions;
