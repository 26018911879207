/* eslint-disable @typescript-eslint/no-use-before-define */
import * as React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import clsx from 'clsx';
import get from 'lodash/get';
import isNil from 'lodash/isNil';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import Lodash from 'lodash';
import toUpper from 'lodash/toUpper';
import includes from 'lodash/includes';
import without from 'lodash/without';
import { bindActionCreators } from 'redux';
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';
import { Formik } from 'formik';
import Moment from 'moment';
import { useIdleTimer } from 'react-idle-timer';

// Components
import { GroupingFeatureKey } from '@industriousoffice/member-portal-rbac/dist/types';
import {
  SelectInput,
  LocationAndDateSelect,
  SubmitButton,
  DrawerTab,
  InfoTooltip,
  Snack,
  Tabs,
  RoundedButton,
} from '../../Components/Common';
import { DeskFloorPlan, ReserveDeskWithApi } from '../../Components';
import { MAP_TYPE } from '../../Components/DeskFloorPlan/Constants';
import { DesksReservationDrawer } from './Components';
import Amenities from '../RoomBooking/Components/Header/Amenities';
import {
  getNextAvailableDay,
  getSelectedDaysAvailability,
} from '../../Components/AvailabilityDayPicker/utils';
import OfficesList from './OfficesList';

// Core
import { breakpoints, colors } from '../../Core/Theme';
import { getIsManager } from '../../Core/Utils/userPermissions';
import { useDayAvailability } from '../../Hooks';

// Redux
import {
  getSelectedGrouping,
  getSelectedFloor,
} from '../DeskReservationLanding/Redux/selectors';
import floorsRedux from '../../Redux/Common/Floors';
import {
  getLocationsById,
  getLoadingLocations,
} from '../../Redux/Common/Locations/selectors';
import locationsRedux from '../../Redux/Common/Locations';
import {
  getUserProfile,
  getUserId,
} from '../../Redux/Common/UserManager/selectors';
import { getAllDisplayGroupings } from '../../Redux/Common/Groupings/selectors';
import {
  getFloors,
  getFloorsAllIds,
  getFloorsById,
} from '../../Redux/Common/Floors/selectors';

// Utils
import { date as DateUtils, strings as StringsUtils } from '../../Core/Utils';
import { getAllReservedDesks } from '../../Components/DeskFloorPlan/Utils';

// Constants
import {
  DESK_RESERVATION_SCHEMA,
  RESERVATION_END_HOUR_OFFSET,
  RESERVATION_START_HOUR_OFFSET,
  MAX_DAY_TO_RESERVATE,
} from './Constants';
import { ViewModes } from './viewModes.enum';

// Api
import {
  fetchFloorMapsOffices,
  fetchReservations as addReservations,
  getAvailableDeskCounts,
  fetchDesksWithAvailability,
} from '../../Core/Api';

import { getLocationFloors, selectFloor } from './Utils';
import { getCompleteReservationsById } from '../../Redux/Common/Reservations/selectors';
import { DESK_LANDING_PATH } from '../DeskReservationLanding/constants';
import { getIsIntegrated, getIsStandalone } from '../../Models/groupings';
import { Floor } from '../../Models';
import { useGroupings } from '../../Hooks/useGroupings';

const LOADING_TIME = 4000;
const TOOLTIP_DELAY = 20 * 1000;
const LIMIT_DESK_INTERACTION_EDITING = 1;

const DeskReservation = props => {
  const {
    classes,
    loadLocations,
    addFloors,
    profile,
    floorsAllIds,
    floorsById,
    floors,
    history,
    locationsById,
    reservationsById,
    isFetchingLocations,
    selectedGrouping,
    userSfId,
    displayGroupings,
  } = props;
  const { t } = useTranslation();

  const [selectedDeskDate, setSelectedDate] = React.useState(null);
  const [previouslySelectedDate, setPreviouslySelectedDate] =
    React.useState(null);
  const [lastLocationSfIdSearch, setLastLocationSfIdSearch] =
    React.useState(null);
  const [lastGroupingIdSearch, setLastGroupingIdSearch] = React.useState(null);

  const [selectedDeskGrouping, setSelectedGrouping] =
    React.useState(selectedGrouping);
  const [previouslySelectedGrouping, setPreviouslySelectedGrouping] =
    React.useState(null);
  const [inputtedDeskGrouping, setInputtedDeskGrouping] =
    React.useState(selectedGrouping);

  const [selectedDesks, setSelectedDesks] = React.useState([]);
  const [selectedDesksWithOwners, setSelectedDesksWithOwners] = React.useState(
    {}
  );
  const [hasAnyMappedFloors, setLocationHasMappedFloors] =
    React.useState(false);

  const [selectedFloor, setSelectedFloor] = React.useState(null);
  const [reservations, setReservations] = React.useState([]);

  const [isLoadingList, setIsLoadingList] = React.useState(true);
  const [isLoading, setIsLoading] = React.useState(true);

  const [isReservationOpen, setIsReservationOpen] = React.useState(false);
  const [activeAmenities, setActiveAmenities] = React.useState([]);
  const [amenities, setAmenities] = React.useState([]);
  const [offices, setOffices] = React.useState([]);
  const [officesList, setOfficesList] = React.useState([]);

  const [mapStyleId, setMapStyleId] = React.useState(null);
  const [mapboxDatasetId, setMapDatasetId] = React.useState(null);
  const [isVisibleTooltipSelectedDesk, setIsVisibleTooltipSelectedDesk] =
    React.useState(false);
  const [descriptionTooltipSelectedDesks, setDescriptionTooltipSelectedDesks] =
    React.useState(t('reserveDesk.tooltip_review_selected_desk'));
  const [
    isVisibleTooltipSelectAnotherDesk,
    setIsVisibleTooltipSelectAnotherDesk,
  ] = React.useState(true);
  const [isReviewDrawerOpen, setReviewDrawerOpen] = React.useState(false);
  const [showOverlay, setShowOverlay] = React.useState(false);
  const [reservationsToReview, setReservationsToReview] = React.useState([]);
  const [shouldResetInput, setShouldResetInput] = React.useState(false);
  const [editReservation, setEditReservation] = React.useState({});
  const [isEditConflictSnack, setEditConflictSnack] = React.useState(false);
  const [conflictDeskId, setConflictDeskId] = React.useState(null);
  const [floorsWithDesks, setFloorsWithDesks] = React.useState([]);
  const [tooltipSelectedDeskId, setTooltipSelectedDeskId] =
    React.useState(null);
  const [locationFloors, setLocationFloors] = React.useState(
    getLocationFloors(
      selectedDeskGrouping?.locationSfId,
      floors,
      floorsWithDesks
    )
  );
  const floorOptions = React.useMemo(
    () => locationFloors.map(floor => Floor.createFloorOption(floor, t)),
    [locationFloors]
  );

  const [viewMode, setViewMode] = React.useState(ViewModes.List);

  const { search, pathname } = useLocation();

  const {
    groupings,
    isLoading: isLoadingGroupings,
    defaultGrouping,
  } = useGroupings(GroupingFeatureKey.HotDesk);

  const isEdit = !Lodash.isEmpty(editReservation);

  const locationSfId = selectedDeskGrouping?.locationSfId;
  const locationTimezoneId = selectedDeskGrouping?.location?.timeZoneId;
  const tabs = [
    {
      text: t('manageOffices.view_types.list'),
      id: 0,
    },
    {
      text: t('manageOffices.view_types.floorplan'),
      id: 1,
    },
  ];

  const handleSwitchMode = () => {
    setViewMode(
      viewMode === ViewModes.List ? ViewModes.Floormap : ViewModes.List
    );
  };

  React.useEffect(() => {
    if (selectedDeskGrouping.idGrouping) {
      setLastLocationSfIdSearch(selectedDeskGrouping.locationSfId);
      setLastGroupingIdSearch(selectedDeskGrouping.idGrouping);
      fetchFloorAvailabilities(selectedDeskGrouping);
    }
    setAmenities([]);
    fetchLocations();
    addFloors();
  }, []);

  React.useEffect(() => {
    handleUrlNavigation();
  }, [search, floorsById, locationsById]);

  React.useEffect(() => {
    if (Lodash.isEmpty(selectedDeskGrouping) && groupings.length) {
      setSelectedGrouping(groupings.find(g => g.isDefault));
    }
  }, [groupings.length]);

  React.useEffect(() => {
    if (
      !isEmpty(selectedFloor) &&
      selectedFloor.locationSfId === selectedDeskGrouping?.locationSfId
    ) {
      return;
    }
    if (isEmpty(selectedDeskGrouping)) {
      return;
    }
    if (floorsWithDesks.length) {
      selectFloor(
        setSelectedFloor,
        selectedFloor,
        selectedDeskGrouping?.locationSfId,
        floors,
        floorsWithDesks
      );
    }
  }, [floors, floorsWithDesks, selectedDeskGrouping]);

  React.useEffect(() => {
    if (
      isEmpty(selectedDeskGrouping) ||
      Lodash.isEmpty(selectedFloor) ||
      !selectedDeskDate
    ) {
      return;
    }

    if (locationSfId) {
      const params = queryString.parse(search);
      const reservationId = get(params, ['reservationId'], 0);
      if (!reservationId) {
        selectFloor(
          setSelectedFloor,
          selectedFloor,
          selectedDeskGrouping?.locationSfId,
          floors
        );
      }
      const isAFloorMapped = floors.some(
        floor =>
          floor.mapboxDatasetMappings?.length &&
          floor.locationSfId === locationSfId
      );
      setLocationHasMappedFloors(isAFloorMapped);
    }
  }, [selectedDeskGrouping, floors]);

  React.useEffect(() => {
    if (Lodash.isEmpty(selectedFloor)) {
      return;
    }
    if (conflictDeskId) {
      setConflictDeskId(null);
      setEditConflictSnack(null);
    }
    const floorId = get(selectedFloor, ['idFloor'], null);
    const floorMappings = Lodash.get(
      selectedFloor,
      ['mapboxDatasetMappings'],
      []
    );
    const floorMappingsOrdered = Lodash.orderBy(
      floorMappings,
      ['createdOn'],
      ['desc']
    );
    const floorMapping = Lodash.head(floorMappingsOrdered);
    const strMapStyleId = get(floorMapping, ['mapboxStyleId'], null);
    const strMapboxDatasetId = get(floorMapping, ['mapboxDatasetId'], null);
    const params = queryString.parse(search);
    const strFloorId = get(params, ['floorId'], null);

    handleValidFloor(strFloorId);

    if (
      (isNil(strMapboxDatasetId) || isNil(strMapStyleId)) &&
      viewMode === ViewModes.Floormap
    ) {
      setMapStyleId(null);
      setMapDatasetId(null);
      setIsLoading(false);
      return;
    }
    const sfId = get(selectedFloor, ['locationSfId'], null);
    const officesWithoutSelectedFloor = offices.filter(objOffice => {
      const strOfficeFloorId = get(objOffice, 'floorUuid', null);
      return strOfficeFloorId !== floorId;
    });
    setIsLoading(true);
    setIsLoadingList(true);
    fetchReservations();
    fetchFloorMapsOffices({
      floorId,
      locationSfId: sfId,
      onlyIncludeOasis: false,
      includeUnmapped: true,
    })
      .then(res => {
        const data = get(res, ['data'], null);
        setMapStyleId(strMapStyleId);
        setMapDatasetId(strMapboxDatasetId);
        setLastLocationSfIdSearch(sfId);
        setLastGroupingIdSearch(Lodash.get(selectedDeskGrouping, 'idGrouping'));
        setOffices([...officesWithoutSelectedFloor, ...data]);
        return setIsLoading(false);
      })
      .catch(() => {
        return setIsLoading(false);
      });

    fetchDesksList();
  }, [selectedFloor]);

  const setDesksAndOwnersFromReservation = reservation => {
    const idDesk = Lodash.get(reservation, ['desk', 'idDesk']);
    setSelectedDesks([idDesk]);
    const { owner, desk } = reservation;

    setSelectedDesksWithOwners({ [idDesk]: { ...desk, owner } });
    setImmediate(() => setShouldResetInput(true));
  };

  React.useEffect(() => {
    if (Lodash.isEmpty(editReservation) || editReservation.isRemoved) {
      return;
    }
    setDesksAndOwnersFromReservation(editReservation);
    setTimeout(() => setIsReservationOpen(true), 500);

    const reservationGroupingId = Lodash.get(editReservation, [
      'desk',
      'parentOffice',
      'groupingId',
    ]);
    const grouping = displayGroupings.find(
      g => g.idGrouping === reservationGroupingId
    );
    if (grouping) {
      setSelectedGrouping(grouping);
      setInputtedDeskGrouping(grouping);
    }
  }, [editReservation]);

  React.useEffect(() => {
    if (
      Lodash.isEmpty(editReservation) ||
      editReservation.isRemoved ||
      isLoadingList ||
      !officesList ||
      (!isLoadingList && officesList.length === 0)
    ) {
      return;
    }

    const officeToEditInList = officesList.find(
      office =>
        office.parentOfficeSfId === editReservation.desk.parentOfficeSfId
    );
    if (officeToEditInList) {
      const deskToEditInList = officeToEditInList.desks.find(
        d => d.idDesk === editReservation.desk.idDesk
      );

      if (deskToEditInList.isReserved) {
        const newOfficesListWithReservationToEdit = officesList.map(office => {
          if (
            office.parentOfficeSfId !== editReservation.desk.parentOfficeSfId
          ) {
            return office;
          }
          return {
            ...office,
            desks: office.desks.map(desk =>
              desk.idDesk === editReservation.desk.idDesk
                ? { ...desk, isReserved: false, teammate: null }
                : desk
            ),
          };
        });
        setOfficesList(newOfficesListWithReservationToEdit);
      }
    }
  }, [officesList, editReservation]);

  useIdleTimer({
    timeout: TOOLTIP_DELAY,
    onIdle: () => setIsVisibleTooltipSelectedDesk(!isReservationOpen),
  });

  React.useEffect(() => {
    setLocationFloors(
      getLocationFloors(
        selectedDeskGrouping?.locationSfId,
        floors,
        floorsWithDesks
      )
    );
  }, [floors, selectedDeskGrouping, floorsWithDesks]);

  const { isFetching, availabilities, fetchAvailability } = useDayAvailability(
    DateUtils.getDateInMoment(selectedDeskDate).startOf('month').unix(),
    DateUtils.getDateInMoment(selectedDeskDate).endOf('month').unix(),
    locationSfId,
    locationTimezoneId,
    selectedDeskGrouping
  );

  const { userReservedDesks } = getAllReservedDesks({
    reservations,
    userSfId,
  });

  const getAvailaibleDesks = () => {
    return officesList.reduce((count, office) => {
      const avalaibleDesks = office.desks.filter(
        ({ isBookable, isReserved }) => isBookable && !isReserved
      );

      return count + avalaibleDesks.length;
    }, 0);
  };

  const fetchFloorAvailabilities = grouping => {
    if (Lodash.isEmpty(grouping)) {
      return;
    }
    getAvailableDeskCounts({
      locationSfId: grouping?.locationSfId,
      startEpoch: Moment().startOf('day').unix(),
      endEpoch: Moment().endOf('day').unix() + 1,
      splitByFloor: true,
      includeUnmapped: true,
    }).then(objResponse => {
      const floorCounts = Lodash.get(
        objResponse,
        ['data', 'counts', 0, 'floors'],
        []
      );

      const validFloors = floorCounts.reduce((acc, countArray) => {
        const { floorId, totalDesks } = countArray;
        if (totalDesks) {
          acc.push(floorId);
        }
        return acc;
      }, []);
      setFloorsWithDesks(validFloors);
    });
  };

  const fetchLocations = () => {
    return loadLocations(false);
  };

  const setMapLoading = () => {
    const sfId = selectedDeskGrouping?.locationSfId;
    const groupingId = selectedDeskGrouping?.idGrouping;
    if (
      (!isEqual(sfId, lastLocationSfIdSearch) ||
        !isEqual(lastGroupingIdSearch, groupingId)) &&
      !isLoading
    ) {
      setLastLocationSfIdSearch(sfId);
      setLastGroupingIdSearch(groupingId);
      setIsLoading(true);
      return setTimeout(() => {
        return setIsLoading(false);
      }, LOADING_TIME);
    }
    return null;
  };

  const fetchReservations = () => {
    const unixToday = DateUtils.getDateInMoment().unix();
    const startEpoch = DateUtils.updateDateForTZ(
      DateUtils.getUnixStartOfDay(selectedDeskDate || unixToday),
      locationTimezoneId,
      DateUtils.getCurrentZone()
    );

    const endEpoch = DateUtils.updateDateForTZ(
      DateUtils.getUnixEndOfDay(selectedDeskDate || unixToday),
      locationTimezoneId,
      DateUtils.getCurrentZone()
    );

    const isSelectedFloorAtLocation =
      Lodash.get(selectedFloor, 'locationSfId') === locationSfId;
    return addReservations({
      startEpoch,
      endEpoch,
      locationSfId,
      limit: 100,
      offset: 0,
      paged: true,
      floorId: isSelectedFloorAtLocation
        ? Lodash.get(selectedFloor, 'idFloor')
        : Lodash.get(locationFloors, [0, 'idFloor']),
    })
      .then(res => {
        const arrReservations = get(res, ['data', 'reservations'], []);
        const arrReservationsForSameDateSelected = Lodash.filter(
          arrReservations,
          objReservation => {
            const reservationDate = get(objReservation, ['startEpoch'], null);
            return (
              selectedDeskDate &&
              DateUtils.isSameDate(selectedDeskDate, reservationDate)
            );
          }
        );
        const selectedDeskId = Lodash.get(selectedDesks, 0);
        const params = queryString.parse(search);
        const reservationId = get(params, ['reservationId']);
        Lodash.remove(arrReservationsForSameDateSelected, {
          id: reservationId * 1,
        });
        setReservations(arrReservationsForSameDateSelected);
        if (reservationId) {
          const conflict = Lodash.find(arrReservationsForSameDateSelected, {
            resourceId: selectedDeskId,
          });
          const reservationToEdit = Lodash.find(arrReservations, {
            id: reservationId * 1,
          });
          if (reservationToEdit && Lodash.isEmpty(editReservation)) {
            setEditReservation(reservationToEdit);
            const reservationGroupingId = Lodash.get(reservationToEdit, [
              'parentOffice',
              'groupingId',
            ]);
            const grouping = displayGroupings.find(
              g => g.idGrouping === reservationGroupingId
            );
            if (grouping) {
              setSelectedGrouping(grouping);
              setInputtedDeskGrouping(grouping);
            }
          }
          if (conflict) {
            setEditConflictSnack(true);
            setConflictDeskId(selectedDeskId);
            setSelectedDesksWithOwners({});
            setSelectedDesks([]);
          } else if (
            !selectedDesks.length &&
            !editReservation.isRemoved &&
            !Lodash.isEmpty(editReservation)
          ) {
            setDesksAndOwnersFromReservation(editReservation);
          }
        }
        return setMapLoading();
      })
      .catch(() => {
        return setIsLoading(false);
      });
  };

  // Fetching offices without floorplans
  const fetchDesksList = () => {
    if (selectedFloor?.locationSfId !== selectedDeskGrouping?.locationSfId)
      return;
    setIsLoadingList(true);
    const floorId = selectedFloor.idFloor;
    const unixToday = DateUtils.getDateInMoment().unix();
    const startEpoch = DateUtils.updateDateForTZ(
      DateUtils.getUnixStartOfDay(selectedDeskDate || unixToday),
      locationTimezoneId,
      DateUtils.getCurrentZone()
    );

    const endEpoch = DateUtils.updateDateForTZ(
      DateUtils.getUnixEndOfDay(selectedDeskDate || unixToday),
      locationTimezoneId,
      DateUtils.getCurrentZone()
    );

    fetchDesksWithAvailability({
      locationSfId: selectedDeskGrouping?.locationSfId,
      startEpoch,
      endEpoch,
      floorId,
      integratedOnly: getIsIntegrated(selectedDeskGrouping),
      ...(getIsStandalone(selectedDeskGrouping) && {
        groupingIds: [selectedDeskGrouping.idGrouping],
      }),
    })
      .then(res => {
        setIsLoadingList(false);
        return setOfficesList(res.data);
      })
      .catch(() => {
        return setIsLoadingList(false);
      });
  };

  const handleValidFloor = strFloorId => {
    if (!isNil(strFloorId) && !isEmpty(floorsById)) {
      const isValidFloor = !isNil(floorsById[strFloorId]);
      if (isValidFloor) {
        return handleUpdateUrlParams({
          floorId: strFloorId,
        });
      }
      return history.push({
        pathname: DESK_LANDING_PATH,
      });
    }
    return null;
  };

  const handleUpdateUrlParams = params => {
    const currParams = queryString.parse(search);
    const strUrlParams = StringsUtils.stringifyParams({
      queryParams: { ...currParams, ...params },
      options: { skipNull: true },
    });
    return history.push({
      pathname,
      search: strUrlParams,
    });
  };

  const handleUrlNavigation = async () => {
    const params = queryString.parse(search);
    const strFloorId = get(params, ['floorId'], null);
    const reservationId = get(params, ['reservationId'], 0);

    const reservation = reservationsById[reservationId];
    if (!reservation && reservationId && !isLoading) {
      return history.push({
        pathname: DESK_LANDING_PATH,
      });
    }
    const varDate = get(
      params,
      ['selectedDate'],
      DateUtils.getDateInMoment().unix()
    );

    if (floorsAllIds.length && !Lodash.isEmpty(locationsById)) {
      handleUpdateFloor(strFloorId);
    }
    return handleUpdateDate(varDate);
  };

  const handleUpdateDate = varDate => {
    const isValid = DateUtils.getIsDateValid(varDate);
    let date = !isValid ? DateUtils.getDateInMoment().unix() : varDate;

    if (!isValid) {
      return history.push({
        pathname: DESK_LANDING_PATH,
      });
    }

    // eslint-disable-next-line no-restricted-globals
    if (isNaN(varDate) && !isNil(varDate) && isValid) {
      date = DateUtils.getDateInMoment(varDate).unix();
    }

    const dayRange = DateUtils.getRangeDaysBetweenDates(
      DateUtils.getDateInMoment().unix(),
      date
    );
    const isToFar = dayRange > MAX_DAY_TO_RESERVATE;
    const isPast = dayRange < 0 && !getIsManager(profile);

    if (isToFar || isPast) {
      date = DateUtils.getDateInMoment().unix();
    }

    handleUpdateUrlParams({
      selectedDate: DateUtils.getUnixTimeInFormat(date, 'YYYY-MM-DD'),
    });

    return setSelectedDate(date);
  };

  // runs when search floor params are updated
  const handleUpdateFloor = strFloorId => {
    const objFloor = Lodash.get(floorsById, [strFloorId]);

    if (objFloor) {
      setSelectedFloor(objFloor);
    }
  };

  const handleOnChangeDate = objDateMoment => {
    setShowOverlay(true);
    if (conflictDeskId) {
      setConflictDeskId(null);
      setEditConflictSnack(null);
    }
    const intDateSelected = DateUtils.getDateInMoment(objDateMoment).unix();

    setPreviouslySelectedDate(selectedDeskDate);
    setSelectedDate(intDateSelected);
  };

  const handleOnchangeLocation = newGrouping => {
    setPreviouslySelectedGrouping(selectedDeskGrouping);
    setSelectedGrouping(newGrouping);
    setShowOverlay(true);
  };

  const handleOnChangeFloor = objFloor => {
    const floorId = get(objFloor, ['idFloor'], null);
    handleUpdateUrlParams({
      floorId,
    });
    return setSelectedFloor(objFloor);
  };

  const handleCloseOverlay = e => {
    if (showOverlay) {
      setShowOverlay(false);
      if (previouslySelectedDate) {
        setSelectedDate(previouslySelectedDate);
      }
      if (previouslySelectedGrouping) {
        setSelectedGrouping(previouslySelectedGrouping);
      }
    }
    e.stopPropagation();
  };

  const handleOnAmenitiesFilterClick = intAmenity => {
    const isActive = includes(activeAmenities, intAmenity);

    return isActive
      ? setActiveAmenities([...without(activeAmenities, intAmenity)])
      : setActiveAmenities([...activeAmenities, intAmenity]);
  };

  const handleFindADesk = objFormik => () => {
    const { resetForm } = objFormik;
    setTooltipSelectedDeskId(null);
    setInputtedDeskGrouping(selectedDeskGrouping);
    setShowOverlay(false);

    if (isEmpty(selectedDeskGrouping)) {
      return;
    }

    handleUpdateUrlParams({
      selectedDate: DateUtils.getUnixTimeInFormat(
        selectedDeskDate,
        'YYYY-MM-DD'
      ),
    });
    setMapLoading();
    fetchFloorAvailabilities(selectedDeskGrouping);
    fetchReservations();
    resetForm();

    if (selectedFloor?.locationSfId !== selectedDeskGrouping?.locationSfId) {
      handleUpdateUrlParams({
        floorId: undefined,
      });
    }
    fetchDesksList();
  };

  const handleOnDeskPress = (objFormik, canSelectDesk) => objDesk => {
    const { setFieldValue, values } = objFormik;
    const { selected, desks } = values;
    const idDesk = get(objDesk, ['idDesk'], null);
    const isSelected = includes(selected, idDesk);
    const parentOffice = offices.find(o => o.sfId === objDesk.parentOfficeSfId);

    const floor = floorsById[parentOffice.floorUuid];

    if (!canSelectDesk && !isSelected) {
      setTooltipSelectedDeskId(idDesk);
      setIsVisibleTooltipSelectedDesk(true);
      setDescriptionTooltipSelectedDesks(
        t('reserveDesk.tooltip_select_different_desk')
      );
      return;
    }

    setDescriptionTooltipSelectedDesks(
      t('reserveDesk.tooltip_review_selected_desk')
    );

    if (conflictDeskId) {
      setConflictDeskId(null);
      setEditConflictSnack(null);
    }

    if (isEdit) {
      setEditReservation({
        ...editReservation,
        resourceId: idDesk,
        desk: { ...objDesk, parentOffice, floor: floor.floorName },
      });
    }
    if (isEmpty(selectedDesks)) {
      handleOpenReservationDrawer();
    }

    if (isSelected) {
      setTooltipSelectedDeskId(null);
      const desksCopy = { ...desks };
      delete desksCopy[idDesk];
      setFieldValue('desks', desksCopy);
    } else if (!getIsManager(profile) && !selected.length) {
      setFieldValue('desks', {
        [idDesk]: {
          ...objDesk,
          owner: profile,
          parentOffice,
          floor: floor.floorName,
        },
      });
    } else {
      setFieldValue('desks', {
        ...desks,
        [idDesk]: {
          ...objDesk,
          parentOffice,
          floor: floor.floorName,
        },
      });
    }

    isSelected
      ? setFieldValue('selected', [...without(selected, idDesk)])
      : setFieldValue('selected', [...selected, idDesk]);
  };

  const handleReviewEditReservations = () => {
    const editReservationsToReview = {
      ...editReservation,
      startEpoch: Moment.unix(selectedDeskDate)
        .add(RESERVATION_START_HOUR_OFFSET, 'hours')
        .unix(),
      endEpoch: Moment.unix(selectedDeskDate)
        .add(RESERVATION_END_HOUR_OFFSET, 'hours')
        .unix(),
      grouping: selectedGrouping,
    };
    setReservationsToReview([editReservationsToReview]);
    return setReviewDrawerOpen(true);
  };

  const handleOnReviewReservations = values => () => {
    const { selected, desks } = values;

    if (isEdit) {
      return handleReviewEditReservations(values);
    }

    const newReservationsToReview = selected.map(id => {
      const desk = desks[id];
      const { owner } = desk;
      const parentOffice = offices.find(
        office => office.sfId === desk.parentOfficeSfId
      );

      const { floorUuid } = parentOffice;
      const floor = floorsById[floorUuid] || {};
      const fullDesk = parentOffice.desks.find(d => d.idDesk === id) || desk;

      return {
        desk: { ...fullDesk, parentOffice: { ...parentOffice, floor } },
        startEpoch: Moment.unix(selectedDeskDate)
          .add(RESERVATION_START_HOUR_OFFSET, 'hours')
          .unix(),
        endEpoch: Moment.unix(selectedDeskDate)
          .add(RESERVATION_END_HOUR_OFFSET, 'hours')
          .unix(),
        owner,
        ownerSfId: owner?.sfId,
        grouping: selectedGrouping,
      };
    });
    setReservationsToReview(newReservationsToReview);
    return setReviewDrawerOpen(true);
  };

  const handleOnSubmitReservations = () => {
    return null;
  };

  const handleOpenReservationDrawer = () => {
    if (isVisibleTooltipSelectedDesk) {
      setIsVisibleTooltipSelectedDesk(false);
    }
    return setIsReservationOpen(true);
  };

  const handleOnRemoveSelectedDeskFromDrawer = objFormik => deskId => {
    const { setFieldValue, values } = objFormik;
    const { desks, selected } = values;
    const desksCopy = { ...desks };
    delete desksCopy[deskId];
    setSelectedDesksWithOwners(desksCopy);
    setSelectedDesks(selected.filter(idDesk => idDesk !== deskId));

    if (isEdit) {
      setEditReservation({ ...editReservation, isRemoved: true });
    }

    return setFieldValue('desks', desksCopy);
  };

  const handleOnCloseTooltipSelectedDesk = () => {
    return setIsVisibleTooltipSelectedDesk(false);
  };

  const handleCloseReviewDrawer = values => {
    const valueReservations = Lodash.get(values, 'reservations', []);
    const newSelectedDesksWithOwners = valueReservations.reduce((acc, r) => {
      const idDesk = Lodash.get(r, ['desk', 'idDesk']);
      const floor = Lodash.get(r, [
        'desk',
        'parentOffice',
        'floor',
        'floorName',
      ]);
      const { owner, desk } = r;
      acc[idDesk] = { ...desk, owner, floor };
      return acc;
    }, {});
    setSelectedDesksWithOwners(newSelectedDesksWithOwners);
    setSelectedDesks(
      valueReservations.map(r => Lodash.get(r, ['desk', 'idDesk']))
    );
    setImmediate(() => setShouldResetInput(true));
    setReviewDrawerOpen(false);
    setReservationsToReview([]);
  };

  const handleOnCloseTooltipSelectAnotherDesk = () => {
    return setIsVisibleTooltipSelectAnotherDesk(false);
  };

  const availabilitiesTimeZoneId = Lodash.get(
    availabilities,
    [0, 'timezoneId'],
    ''
  );

  const selectedDaysAvailability = getSelectedDaysAvailability(
    availabilities,
    selectedDeskDate,
    availabilitiesTimeZoneId
  );

  const { nextAvailableDayEpoch, nextAvailableDayCount } = getNextAvailableDay(
    availabilities,
    selectedDeskDate
  );

  const getFloatingDescription = () => {
    if (isEdit) {
      return [
        t('deskFloorPlan.deskReservation.description_edit'),
        t('deskFloorPlan.deskReservation.description_edit_2'),
      ];
    }
    return [];
  };

  const handleResetForm = objFormik => () => {
    const { resetForm } = objFormik;
    fetchReservations();
    resetForm();
    handleCloseReviewDrawer();
  };

  const handleCreateSuccess = objFormik => () => {
    setSelectedDesksWithOwners({});
    setSelectedDesks([]);
    handleResetForm(objFormik);
    setEditReservation(null);
    fetchAvailability(
      Moment().startOf('month').unix(),
      Moment().endOf('month').unix(),
      locationSfId,
      locationTimezoneId
    );
  };

  const getInteractionLimit = () => {
    if (isEdit) {
      return LIMIT_DESK_INTERACTION_EDITING;
    }

    return null;
  };

  const renderFormMap = objFormik => {
    const { values } = objFormik;
    const { selected } = values;
    const canSelectDesk = !(isEdit && selected.length);

    const isBannerVisible = isEdit;

    const getUnselectablePopupLabel = () => {
      if (selected.length) {
        return t(
          'deskFloorPlan.deskReservation.description_select_another_desk'
        );
      }
      return null;
    };

    return (
      <div className={classes.sectionMap}>
        <DeskFloorPlan
          onDeskPress={handleOnDeskPress(objFormik, canSelectDesk)}
          mapStyleId={mapStyleId}
          datasetId={mapboxDatasetId}
          withLegend={false}
          isLoading={isFetchingLocations || isLoading}
          offices={offices}
          selectedDesks={selected}
          reservations={reservations}
          type={MAP_TYPE.DESK_RESERVATION}
          selectedFloor={selectedFloor}
          hasAnyMappedFloors={hasAnyMappedFloors}
          selectedReservationId={
            selected.length ? Lodash.get(editReservation, ['id']) : null
          }
          conflictDeskId={conflictDeskId}
          isInteractionDisabled={!canSelectDesk}
          floatingDescription={getFloatingDescription()}
          limitDeskInteraction={getInteractionLimit()}
          unselectablePopupLabel={getUnselectablePopupLabel()}
          userSfId={profile?.sfId}
          groupingId={Lodash.get(inputtedDeskGrouping, 'idGrouping', null)}
          isBannerVisible={isBannerVisible}
          accountSfId={profile?.accountSfId}
        />
      </div>
    );
  };

  const renderForm = objFormik => {
    const { values } = objFormik;
    const { selected } = values;
    const canSelectDesk = !(isEdit && selected.length);

    const isBannerVisible = isEdit;

    const floatingDescription = getFloatingDescription();

    const goToAvailable = () => {
      setSelectedDate(nextAvailableDayEpoch);
      setImmediate(handleFindADesk(objFormik));
    };

    return (
      <div className={classes.wrapper}>
        <Grid container direction="column" style={{ flexGrow: 1 }}>
          <Grid
            container
            justify="center"
            alignItems="center"
            className={classes.header}
          >
            <Grid
              item
              xs={12}
              className={clsx(classes.section, classes.dateSection)}
            >
              {/* Desktop  */}
              <div className={classes.findDeskContainer}>
                <LocationAndDateSelect
                  selectedGrouping={selectedDeskGrouping}
                  selectedDate={selectedDeskDate}
                  onDateChange={handleOnChangeDate}
                  onChangeLocation={handleOnchangeLocation}
                  availabilities={availabilities}
                  isLoading={isLoading || isFetching || isLoadingGroupings}
                  profile={profile}
                  locationSfId={locationSfId}
                  goToAvailable={goToAvailable}
                  selectedDaysAvailability={selectedDaysAvailability}
                  nextAvailableDayCount={nextAvailableDayCount}
                  nextAvailableDayEpoch={nextAvailableDayEpoch}
                  dayPickerProps={{
                    disablePastDate: true,
                  }}
                  locationTimezoneId={locationTimezoneId}
                  locationListProps={{
                    locationGroupings: groupings,
                    usersDefaultGroupings: defaultGrouping,
                    isLoading: isLoadingGroupings,
                  }}
                  datatestid="see_desks"
                />
                <SubmitButton
                  id="find-desk"
                  className={classes.findDeskButton}
                  onClick={handleFindADesk(objFormik)}
                  isLoading={isLoading || isFetching}
                  disabled={!selectedDaysAvailability}
                  defaultText={
                    selectedDaysAvailability
                      ? t('deskReservation.see_desks', {
                          count: selectedDaysAvailability,
                        })
                      : t('reserveDesk.find_desk_cta')
                  }
                  datatestid="desk_see_x_desks_button"
                />
              </div>
              <div className={classes.findDeskContainerMobile}>
                <LocationAndDateSelect
                  variant="mobile"
                  label={t('reserveDesk.edit_search')}
                  actionLabel={t('reserveDesk.find_desk_cta')}
                  selectedGrouping={selectedDeskGrouping}
                  selectedDate={selectedDeskDate}
                  onChangeDate={handleOnChangeDate}
                  onChangeLocation={setSelectedGrouping}
                  onSelectLocationAndDate={handleFindADesk(objFormik)}
                  dayPickerProps={{
                    disablePastDate: true,
                  }}
                  profile={profile}
                  locationSfId={locationSfId}
                  goToAvailable={goToAvailable}
                  availabilities={availabilities}
                  selectedDaysAvailability={selectedDaysAvailability}
                  nextAvailableDayCount={nextAvailableDayCount}
                  nextAvailableDayEpoch={nextAvailableDayEpoch}
                  isLoading={isLoading || isFetching || isLoadingGroupings}
                  locationTimezoneId={locationTimezoneId}
                  locationListProps={{
                    locationGroupings: groupings,
                    usersDefaultGroupings: defaultGrouping,
                    isLoading: isLoadingGroupings,
                  }}
                />
              </div>
            </Grid>
          </Grid>

          <Grid container direction="column" className={classes.resultSection}>
            <Grid
              item
              xs={12}
              className={clsx(classes.section, classes.filterSection)}
            >
              <div className={classes.filterInputs}>
                <div>
                  <span className={classes.filterByText}>
                    {toUpper(t('general.filter_by'))}
                  </span>
                  <div className={classes.filtersContainer}>
                    <SelectInput
                      disabled={false}
                      options={floorOptions}
                      onChange={handleOnChangeFloor}
                      value={selectedFloor}
                      renderValue={f =>
                        t('general.floor', {
                          floorName: get(f, 'floorName', ''),
                        })
                      }
                      variant="boxed"
                      className={classes.floorInput}
                      noCaratWithSingleOption
                      inputClassName={classes.floorInputClass}
                    />
                    {amenities.length > 0 && (
                      <div className={classes.amenitiesContainer}>
                        <Amenities
                          label={null}
                          amenities={amenities}
                          amenFilters={activeAmenities}
                          onFilterButtonClick={handleOnAmenitiesFilterClick}
                        />
                      </div>
                    )}
                  </div>
                </div>

                <div className={classes.availableDeskContainer}>
                  <span>
                    {t('reserveDesk.available_desks', {
                      count: getAvailaibleDesks(),
                    })}
                  </span>
                </div>
              </div>
            </Grid>
            {showOverlay && (
              <div
                role="presentation"
                className={classes.overlay}
                onClick={handleCloseOverlay}
                onKeyDown={handleCloseOverlay}
              />
            )}
            <Grid item xs={12} className={classes.wrapperViews}>
              <Tabs
                legends={tabs}
                variant="boxed"
                selectedId={viewMode}
                onTabClick={handleSwitchMode}
                className={classes.tabViewType}
              />
              <RoundedButton
                className={clsx(
                  classes.switchViewButton,
                  floatingDescription.length > 0 &&
                    classes.switchViewButtonAbove
                )}
                onClick={handleSwitchMode}
              >
                {viewMode === ViewModes.List
                  ? t('manageOffices.view_types_switch_button.floorplan')
                  : t('manageOffices.view_types_switch_button.list')}
              </RoundedButton>
              {!isReservationOpen && (
                <InfoTooltip
                  isVisible={isVisibleTooltipSelectedDesk}
                  onClose={handleOnCloseTooltipSelectedDesk}
                  description={descriptionTooltipSelectedDesks}
                  customClasses={{ arrowPopper: classes.reviewTooltip }}
                  anchorContent={() => (
                    <DrawerTab
                      customClasses={{ tab: classes.drawerPill }}
                      onClick={handleOpenReservationDrawer}
                      variant="light"
                      count={objFormik.values.selected.length}
                    >
                      {t('deskReservation.drawer.selected')}
                    </DrawerTab>
                  )}
                />
              )}
              <DesksReservationDrawer
                isOpen={isReservationOpen}
                setOpen={setIsReservationOpen}
                onReviewReservation={handleOnReviewReservations(
                  objFormik.values
                )}
                selected={objFormik.values.selected}
                desks={objFormik.values.desks}
                onRemoveDesk={handleOnRemoveSelectedDeskFromDrawer(objFormik)}
                profile={profile}
                isVisibleTooltip={isVisibleTooltipSelectAnotherDesk}
                isValid={objFormik.isValid}
                onCloseTooltip={handleOnCloseTooltipSelectAnotherDesk}
                setShouldResetInput={setShouldResetInput}
                shouldResetInput={shouldResetInput}
                locationsById={locationsById}
                isEdit={isEdit}
              />
              <ReserveDeskWithApi
                isOpen={isReviewDrawerOpen}
                onClose={handleCloseReviewDrawer}
                onSubmit={handleCreateSuccess(objFormik)}
                reservations={reservationsToReview}
                onEdit={handleCloseReviewDrawer}
                handleResetForm={handleResetForm(objFormik)}
                history={history}
              />
              {viewMode === ViewModes.List ? (
                <div
                  className={clsx(
                    classes.officeListWrapper,
                    floatingDescription.length > 0 &&
                      classes.officeListWrapperWithBlueMessage
                  )}
                >
                  <OfficesList
                    offices={officesList}
                    isLoading={isLoading || isLoadingList}
                    desksIdsSelected={objFormik.values.selected}
                    handleClickDesk={handleOnDeskPress(
                      objFormik,
                      canSelectDesk
                    )}
                    floatingDescription={floatingDescription}
                    canSelectDesk={canSelectDesk}
                    editingReservation={editReservation}
                    userReservedDesks={userReservedDesks}
                    isBannerVisible={isBannerVisible}
                    tooltipSelectedDeskId={tooltipSelectedDeskId}
                  />
                </div>
              ) : (
                renderFormMap(objFormik)
              )}
            </Grid>
          </Grid>
        </Grid>
        <Snack
          message={t('reserveDesk.date_conflict')}
          autoClose
          isOpen={isEditConflictSnack}
          onClose={() => setEditConflictSnack(false)}
        />
      </div>
    );
  };
  return (
    <Formik
      validationSchema={DESK_RESERVATION_SCHEMA}
      initialValues={{
        desks: selectedDesksWithOwners,
        selected: selectedDesks,
      }}
      render={renderForm}
      onSubmit={handleOnSubmitReservations}
      enableReinitialize
      isInitialValid
    />
  );
};

const styles = theme => ({
  sectionMap: {
    position: 'relative',
  },
  wrapperViews: {
    position: 'relative',
    flexGrow: 1,
  },
  circularProgress: {
    color: colors.gray,
  },
  loadingContainer: {
    height: 'calc(80vh - 80%)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  filtersContainer: {
    display: 'flex',
  },
  availableDeskContainer: {
    [theme.breakpoints.up(breakpoints.MOBILE)]: {
      position: 'absolute',
      right: 20,
      alignSelf: 'flex-end',
    },
  },
  drawerPill: {
    [theme.breakpoints.down(breakpoints.MOBILE)]: {
      bottom: 90,
    },
  },
  floorInput: {
    width: 'fit-content',
    margin: 'auto',
    display: 'block',
    marginTop: '10px',
    height: 38,
  },
  floorInputClass: {
    borderRadius: '20px !important',
    paddingTop: 10,
    paddingBottom: 8,
  },
  amenitiesContainer: {
    marginLeft: 10,
  },
  wrapper: {
    background: colors.palette.secondary2.main,
    overflow: 'hidden',
    minHeight: 'calc(100vh - 90px)',
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up(breakpoints.MOBILE)]: {
      minHeight: 'calc(100vh - 60px)',
    },
  },
  header: {
    background: colors.white,
    paddingTop: 20,
  },
  section: {
    paddingRight: 20,
    paddingLeft: 20,
    paddingTop: 15,
    paddingBottom: 15,
  },
  filterSection: {
    display: 'flex',
    [theme.breakpoints.up(breakpoints.MOBILE)]: {
      justifyContent: 'none',
    },
    justifyContent: 'space-between',
    paddingRight: 20,
    paddingLeft: 20,
    background: colors.white,
    borderBottom: `1px solid ${colors.blackOpacityLightest}`,
    position: 'relative',
  },
  filterInputs: {
    [theme.breakpoints.up(breakpoints.MOBILE)]: {
      display: 'flex',
      marginLeft: 'auto',
      marginRight: 'auto',
      textAlign: 'center',
      width: 'unset',
    },
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  },
  dateSection: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  resultSection: {
    flexGrow: 1,
    position: 'relative',
  },
  divider: {
    borderBottom: `1px solid ${colors.middle}`,
    paddingRight: `0px !important`,
    paddingLeft: `0px !important`,
  },
  availableDesksText: {
    fontFamily: 'VerlagBook',
    fontSize: 16,
  },
  text: {
    fontFamily: 'VerlagBook',
    fontSize: 18,
    paddingBottom: 10,
  },
  filterByText: {
    fontFamily: 'VerlagBook',
    fontSize: 14,
    paddingBottom: 10,
  },
  findDeskButton: {
    marginTop: '0px !important',
    paddingRight: 20,
    paddingLeft: 20,
    marginLeft: 20,
    minWidth: 131,
  },
  findDeskContainer: {
    [theme.breakpoints.up(breakpoints.MOBILE)]: {
      display: 'flex',
      justifyContent: 'center',
    },
    display: 'none',
  },
  findDeskContainerMobile: {
    [theme.breakpoints.up(breakpoints.MOBILE)]: {
      display: 'none',
    },
    width: '100%',
  },
  overlay: {
    position: 'absolute',
    left: 0,
    right: 0,
    bottom: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    zIndex: 5,
    display: 'block',
  },
  reviewTooltip: {
    zIndex: 100,
  },
  textSelectedMonthPasses: {
    fontFamily: 'VerlagBold',
  },
  popupOutDayContainer: {
    [theme.breakpoints.up(breakpoints.MOBILE)]: {
      width: '60%',
    },
    width: '100%',
    marginBottom: 10,
  },
  oneDayPassTooltip: {
    color: colors.white,
    fontSize: 17,
  },
  onePassesRemainingTooltip: {
    fontSize: 12,
    fontFamily: 'VerlagBook',
  },
  reachTeamTooltip: {
    width: 'unset',
    minWidth: 210,
  },
  officeListWrapper: {
    paddingTop: 20,
    paddingBottom: 80,
    [theme.breakpoints.up(breakpoints.MOBILE)]: {
      paddingTop: 110,
      paddingBottom: 0,
    },
  },
  officeListWrapperWithBlueMessage: {
    paddingBottom: 120,
  },
  tabViewType: {
    display: 'none',
    margin: '35px auto 30px',
    position: 'absolute',
    zIndex: 2,
    right: 0,
    left: 0,
    [theme.breakpoints.up(breakpoints.MOBILE)]: {
      display: 'block',
    },
  },
  switchViewButton: {
    position: 'fixed',
    zIndex: 2,
    bottom: 20,
    width: 150,
    margin: 'auto',
    right: 0,
    left: 0,
    [theme.breakpoints.up(breakpoints.MOBILE)]: {
      display: 'none',
    },
  },
  switchViewButtonAbove: {
    bottom: 120,
  },
});

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      addFloors: floorsRedux.actions.addFloors,
      loadLocations: locationsRedux.actions.addLocations,
    },
    dispatch
  );
};

const mapStateToProps = state => {
  return {
    floorsAllIds: getFloorsAllIds(state),
    floorsById: getFloorsById(state),
    floors: getFloors(state),
    selectedGrouping: getSelectedGrouping(state),
    selectedFloor: getSelectedFloor(state),
    profile: getUserProfile(state),
    locationsById: getLocationsById(state),
    reservationsById: getCompleteReservationsById(state),
    isFetchingLocations: getLoadingLocations(state),
    userSfId: getUserId(state),
    displayGroupings: getAllDisplayGroupings(state),
  };
};

DeskReservation.propTypes = {
  history: PropTypes.shape({}).isRequired,
  profile: PropTypes.object.isRequired,
  reservationsById: PropTypes.shape({}).isRequired,
  loadLocations: PropTypes.func.isRequired,
  addFloors: PropTypes.func.isRequired,
  floorsAllIds: PropTypes.arrayOf(PropTypes.object).isRequired,
  floorsById: PropTypes.shape({}).isRequired,
  floors: PropTypes.arrayOf(PropTypes.object).isRequired,
  locationsById: PropTypes.object.isRequired,
  isFetchingLocations: PropTypes.bool.isRequired,
  selectedGrouping: PropTypes.object.isRequired,
  userSfId: PropTypes.string.isRequired,
  displayGroupings: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(DeskReservation));
