import { centroid, polygon, point, bboxPolygon, bbox } from '@turf/turf';
import get from 'lodash/get';
import isNil from 'lodash/isNil';
import upperFirst from 'lodash/upperFirst';
import isFunction from 'lodash/isFunction';
import isEmpty from 'lodash/isEmpty';
import has from 'lodash/has';

import { v4 as uuidv4 } from 'uuid';
import { FlyToInterpolator } from 'react-map-gl';
import memoizeOne from 'memoize-one';
import {
  GEOMETRY_TYPES,
  FEATURES_TYPE,
  LAYERS,
  RESOURCE_TYPE_DESK,
  INTERACTIVE_MAPBOX_FEATURE_TYPE,
  LABEL_DISABLED_MAPBOX_FEATURE_TYPE,
} from './Constants';
import { createHash } from '../../Core/Utils';

const getCenter = coordinates => {
  const objCentroid = centroid(coordinates);
  const center = get(objCentroid, ['geometry', 'coordinates'], []);
  return center;
};

const centroidPolygon = coordinates => {
  const polygonCoordinates = polygon(coordinates);
  return getCenter(polygonCoordinates);
};

const centroidPoint = coordinates => {
  const polygonCoordinates = point(coordinates);
  return getCenter(polygonCoordinates);
};

const centroidByTypes = {
  [GEOMETRY_TYPES.POLYGON]: centroidPolygon,
  [GEOMETRY_TYPES.POINT]: centroidPoint,
};

export const calculateCentroid = ({ type, coordinates }) => {
  const centroidType = centroidByTypes[type];
  return centroidType(coordinates);
};

export const generateBoundingBoxFeature = coordinatesBoundingBox => {
  if (coordinatesBoundingBox.length > 0) {
    return {
      type: 'FeatureCollection',
      features: [
        {
          ...bboxPolygon(coordinatesBoundingBox),
          properties: { type: 'Overlay' },
        },
      ],
    };
  }
  return {};
};

const generateBoundingBoxCoordinates = boundingBox => {
  const coordinates = [
    get(boundingBox, ['_ne', 'lng'], 0),
    get(boundingBox, ['_ne', 'lat'], 0),
    get(boundingBox, ['_sw', 'lng'], 0),
    get(boundingBox, ['_sw', 'lat'], 0),
  ];
  return coordinates;
};

export const generateFeatureOverlay = memoizeOne(objBounds => {
  if (!isNil(objBounds)) {
    const boundingBox = generateBoundingBoxCoordinates(objBounds);
    return {
      ...bboxPolygon(boundingBox),
      properties: { type: LAYERS.OVERLAY },
    };
  }
  return {};
});

export const generateNewViewport = ({
  zoom,
  coordinates,
  withTransition = true,
}) => {
  const [longitude, latitude] = coordinates;
  return {
    longitude,
    latitude,
    zoom,
    ...(withTransition && {
      transitionInterpolator: new FlyToInterpolator({ speed: 1.2 }),
      transitionDuration: 'auto',
    }),
  };
};

const getDeskFeatureMapping = (data, type, datasetId) => {
  return data.reduce((lookup, objItem) => {
    const arrMabpoxFeatureProductMappings = get(
      objItem,
      ['mapboxFeatureProductMappings'],
      null
    );

    if (arrMabpoxFeatureProductMappings == null) {
      return lookup;
    }

    const objMabpoxFeatureProductMapping = arrMabpoxFeatureProductMappings.find(
      objMapboxFeature => {
        const strMapboxDatasetId = get(
          objMapboxFeature,
          ['mapboxDatasetId'],
          null
        );
        return strMapboxDatasetId === datasetId;
      }
    );
    const mapboxFeatureId = get(
      objMabpoxFeatureProductMapping,
      ['mapboxFeatureId'],
      null
    );

    if (isNil(mapboxFeatureId)) {
      return lookup;
    }

    return {
      ...lookup,
      [mapboxFeatureId]: {
        ...objItem,
        type,
      },
    };
  }, {});
};

export const getFeatureMappings = (data, datasetId) => {
  return data.reduce((objFeatureLookup, objOffice) => {
    const strFeatureId = get(
      objOffice,
      ['mapboxFeatureProductMappings', 0, 'mapboxFeatureId'],
      null
    );
    const desks = get(objOffice, ['desks'], []);

    return {
      ...objFeatureLookup,
      [strFeatureId]: objOffice,
      ...getDeskFeatureMapping(desks, FEATURES_TYPE.DESK, datasetId),
    };
  }, {});
};

const getProductID = objProduct => {
  const featuresIdentifier = {
    [FEATURES_TYPE.DESK]: data => get(data, ['idDesk'], null),
  };
  const type = get(objProduct, ['type'], null);
  return featuresIdentifier[type]
    ? featuresIdentifier[type](objProduct)
    : get(objProduct, ['sfId'], null);
};

const mapFeature = objFeature => {
  const featureId = get(objFeature, ['id'], null);
  const originalProperties = get(objFeature, ['properties'], {});
  const properties = {
    ...originalProperties,
    id: featureId || uuidv4(),
  };

  return { ...objFeature, properties };
};

const isDesk = memoizeOne(type => {
  const desksType = [FEATURES_TYPE.DESK];
  return desksType.includes(type);
});

const getFeatureLabel = (listMapboxFeature, featureId) => {
  return listMapboxFeature.find(objFeature => {
    const strAssociatedFeature = get(
      objFeature,
      ['properties', 'associatedFeature'],
      null
    );
    const strType = get(objFeature, ['properties', 'type'], null);

    return (
      strAssociatedFeature === featureId && strType === FEATURES_TYPE.LABEL
    );
  });
};

const getOfficeFeatureDesk = (listMapboxFeature, strOfficeId) => {
  const relatedDesks = listMapboxFeature.filter(feature => {
    const officeId = get(feature, ['properties', 'officeId'], null);
    const featureType = get(feature, ['properties', 'type'], null);

    return strOfficeId === officeId && featureType === FEATURES_TYPE.DESK;
  });

  return relatedDesks.map(desk => get(desk, ['id'], null));
};

const generateProductMappings = (objFeatureLookup, listMapboxFeature) => {
  return listMapboxFeature.reduce((objProductsLookup, objFeature) => {
    const strFeatureId = get(objFeature, ['id'], null);
    const objProduct = get(objFeatureLookup, [`${strFeatureId}`], null);

    if (isNil(objProduct)) {
      return objProductsLookup;
    }

    const strId = getProductID(objProduct);
    const isDeskType = isDesk(get(objProduct, [`type`], null));
    const strProductType = isDeskType
      ? FEATURES_TYPE.DESK
      : FEATURES_TYPE.OFFICE;
    const objLabelFeature = getFeatureLabel(listMapboxFeature, strFeatureId);

    const objFeatureResult = {
      mapboxFeatureID: strFeatureId,
      label: get(objLabelFeature, ['properties', 'id'], null),
      ...(isDeskType && {
        chair: get(objFeature, ['properties', 'associatedFeature'], null),
      }),
      ...(!isDeskType && {
        desks: getOfficeFeatureDesk(listMapboxFeature, strFeatureId),
      }),
    };

    const objLookupProductByKey = objProductsLookup[strProductType];

    return {
      ...objProductsLookup,
      [strProductType]: {
        ...objLookupProductByKey,
        [strId]: objFeatureResult,
      },
    };
  }, {});
};

const getOfficeData = (listMapboxFeature, objFeatureLookup) => {
  return generateProductMappings(objFeatureLookup, listMapboxFeature);
};

export const getMapData = (objData, listOffices, datasetId) => {
  const features = get(objData, ['features'], []);
  const featuresWithPropertyId = features.map(mapFeature);
  const featuresLookup = getFeatureMappings(listOffices, datasetId);
  return {
    dataset: {
      ...objData,
      features: featuresWithPropertyId,
    },
    ...getOfficeData(featuresWithPropertyId, featuresLookup),
    featuresLookup,
  };
};

export const getAllDesks = ({ offices }) => {
  if (offices.length === 0) {
    return [];
  }

  return offices.reduce((desks, objOffice) => {
    const listOfficeDesks = get(objOffice, ['desks'], []);
    return [...desks, ...listOfficeDesks];
  }, []);
};

export const getReservableDesks = ({ offices }) => {
  if (offices.length === 0) {
    return [];
  }

  return offices.reduce((desks, objOffice) => {
    const isOfficeReservable = get(objOffice, ['isOasisReservable'], false);

    if (isOfficeReservable) {
      const listOfficeDesks = get(objOffice, ['desks'], []);
      const listDesksWithGroupings = listOfficeDesks.map(d => ({
        ...d,
        groupingId: objOffice.groupingId,
        parentOffice: objOffice,
      }));
      return [...desks, ...listDesksWithGroupings];
    }

    return desks;
  }, []);
};

export const getAllReservedDesks = ({ reservations, userSfId = '' }) => {
  if (reservations.length === 0) {
    return { reservedDesks: [], userReservedDesks: [] };
  }
  return reservations.reduce(
    (objDesks, objReservation) => {
      const { reservedDesks, userReservedDesks } = objDesks;
      const strResourceTypeId = get(objReservation, ['resourceTypeId'], null);
      const strOwnerSfId = get(objReservation, ['ownerSfId'], null);

      const idDesk = get(objReservation, ['desk', 'idDesk'], null);
      if (strResourceTypeId === RESOURCE_TYPE_DESK && idDesk) {
        if (strOwnerSfId === userSfId) {
          return {
            reservedDesks,
            userReservedDesks: [...userReservedDesks, idDesk],
          };
        }
        return { reservedDesks: [...reservedDesks, idDesk], userReservedDesks };
      }
      return objDesks;
    },
    { reservedDesks: [], userReservedDesks: [] }
  );
};

export const getOfficeHasAvailableDesks = ({ desks, reservations }) => {
  const arrOfficeDesksStatus = desks.map(objDesk => {
    const idDesk = get(objDesk, ['idDesk'], null);
    const isBookable = get(objDesk, ['isBookable'], null);

    if (!isBookable || reservations.includes(idDesk)) {
      return false;
    }

    return true;
  });

  return arrOfficeDesksStatus.some(
    desksAvailableStatus => desksAvailableStatus === true
  );
};

const getBathroomDisplayName = objFeature => {
  const strBathroomGender = get(objFeature, ['properties', 'gender'], null);
  return isNil(strBathroomGender)
    ? 'Bathroom'
    : `${strBathroomGender} Bathroom`;
};
const getCommonSpacesDisplayName = () => {
  return `Common Space`;
};

const objGenericFeatureLabel = {
  [FEATURES_TYPE.BATHROOM]: getBathroomDisplayName,
  [FEATURES_TYPE.COMMON_SPACES]: getCommonSpacesDisplayName,
};

export const getDisplayName = objFeature => {
  const strFeatureType = get(objFeature, ['properties', 'type'], null);
  const strFeatureName = get(objFeature, ['properties', 'name'], null);
  const getGenericLabel = objGenericFeatureLabel[strFeatureType];

  return isFunction(getGenericLabel)
    ? getGenericLabel(objFeature)
    : strFeatureName || strFeatureType;
};

const getLabelIndicators = objLabelIndicators => objFeature => {
  const strFeatureType = get(objFeature, ['properties', 'type'], null);
  if (
    !LABEL_DISABLED_MAPBOX_FEATURE_TYPE.includes(upperFirst(strFeatureType))
  ) {
    const strFeatureId = get(objFeature, ['id'], null);
    return {
      ...objLabelIndicators,
      [strFeatureId]: getDisplayName(objFeature),
    };
  }
  return objLabelIndicators;
};

export const getCommonArea = mapboxLookup => {
  const features = get(mapboxLookup, ['dataset', 'features'], []);

  return features.reduce(
    (objCommonLabels, objFeature) => {
      const { indicators, commonAreaIds } = objCommonLabels;
      const strFeatureId = get(objFeature, ['id'], null);
      const strFeatureType = get(objFeature, ['properties', 'type'], null);
      const getLabels = getLabelIndicators(indicators);

      if (
        strFeatureId &&
        strFeatureType &&
        !INTERACTIVE_MAPBOX_FEATURE_TYPE.includes(upperFirst(strFeatureType))
      ) {
        return {
          commonAreaIds: [...commonAreaIds, strFeatureId],
          indicators: getLabels(objFeature),
        };
      }

      return objCommonLabels;
    },
    { commonAreaIds: [], indicators: {} }
  );
};

const getBbox = memoizeOne(objFeatureMapCollection => {
  if (
    isEmpty(objFeatureMapCollection) ||
    !has(objFeatureMapCollection, ['type']) ||
    !has(objFeatureMapCollection, ['features'])
  ) {
    return [];
  }

  return bbox(objFeatureMapCollection);
});

export const isSameMap = (newFeaturesMap, lastFeaturesMap) => {
  const newMapBboxHash = createHash(getBbox(get(newFeaturesMap, [0], {})));
  const lastMapBboxHash = createHash(getBbox(get(lastFeaturesMap, [0], {})));

  return newMapBboxHash === lastMapBboxHash;
};
