/* eslint-env browser */
/* eslint-disable no-underscore-dangle */
import { combineReducers, createStore, applyMiddleware, compose } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import { createPromise } from 'redux-promise-middleware';
import reduxThunk from 'redux-thunk';
import storage from 'redux-persist/lib/storage';
import calReducer from '../Containers/RoomBooking/Redux';
import userManagerReducer from './Common/UserManager';
import locationsReducer from './Common/Locations';
import roomsReducer from './Common/Rooms';
import notificationsReducer from './Common/Notifications';
import bookingsReducer from './Common/Bookings';
import eventsReducer from '../Containers/Events/Redux';
import groupingsReducer from './Common/Groupings';
import reservationsReducer from './Common/Reservations';
import floorsReducer from './Common/Floors';

import homeReducer from '../Containers/Home/Redux';
import groupManagementReducer from '../Containers/GroupManagement/Redux';

import errorMiddleware from './PromiseMiddleware';
import receptionistReducer from '../Containers/CheckInActivity/Redux';
import parcelsReducer from '../Containers/PackageAlerts/Redux';
import communityMessages from '../Containers/MessageMembers/Redux';
import reserveDesk from '../Containers/DeskReservationLanding/Redux';
import visitingMemberReducer from '../Containers/VisitingMemberReservations/Redux';

export const middlewares = [errorMiddleware, reduxThunk];

//  This is to dynmically import mock store
//  Cannot use import due to async issues, resort to require
export const mockStore =
  process.env.NODE_ENV === 'production'
    ? null
    : (() => {
        //  eslint-disable-next-line global-require
        const configureMockStore = require('redux-mock-store');
        return configureMockStore.default([...middlewares, createPromise()]);
      })();

const authPersistConfig = {
  key: 'auth',
  storage,
  blacklist: ['notificationSettingsLoadingState'],
};

const calPersistConfig = {
  key: 'calendar',
  storage,
  whitelist: [
    'selectedLocation',
    'upcomingResvDrawerIsOpen',
    'selectedGrouping',
    'lastGroupingAt',
  ],
};

const recPersistConfig = {
  key: 'receptionist',
  storage,
  whitelist: ['selectedLocation'],
};

const eventsPersistConfig = {
  key: 'events',
  storage,
  whitelist: ['selectedLocation', 'lastSelectedGroupingsByLocation'],
};

const communityMessagesPersistConfig = {
  key: 'communityMessages',
  storage,
  whitelist: ['selectedLocation'],
};

const groupManagementConfig = {
  key: 'groupManagement',
  storage,
  whitelist: ['selectedLocation'],
};

const reserveDeskConfig = {
  key: 'reserveDesk',
  storage,
  whitelist: ['selectedGrouping', 'selectedFloor'],
};

const visitingMembersPersistConfig = {
  key: 'visitingMembers',
  storage,
  whitelist: ['selectedGrouping'],
};

const groupingsPersistConfig = {
  key: 'groupings',
  storage,
  whitelist: ['displayGroupings'],
};

export const combinedReducers = combineReducers({
  calendar: persistReducer(calPersistConfig, calReducer.reducer),
  rooms: roomsReducer.reducer,
  locations: locationsReducer.reducer,
  notifications: notificationsReducer.reducer,
  parcels: parcelsReducer.reducer,
  userManager: persistReducer(authPersistConfig, userManagerReducer.reducer),
  events: persistReducer(eventsPersistConfig, eventsReducer.reducer),
  receptionist: persistReducer(recPersistConfig, receptionistReducer.reducer),
  communityMessages: persistReducer(
    communityMessagesPersistConfig,
    communityMessages.reducer
  ),
  bookings: bookingsReducer.reducer,
  groupings: persistReducer(groupingsPersistConfig, groupingsReducer.reducer),
  home: homeReducer.reducer,
  groupManagement: persistReducer(
    groupManagementConfig,
    groupManagementReducer.reducer
  ),
  reserveDesk: persistReducer(reserveDeskConfig, reserveDesk.reducer),
  floors: floorsReducer.reducer,
  reservations: reservationsReducer.reducer,
  visitingMembers: persistReducer(
    visitingMembersPersistConfig,
    visitingMemberReducer.reducer
  ),
});

function fnRootReducerInterceptor(objState, objAction) {
  if (objAction.type === userManagerReducer.actionTypes.LOG_OUT) {
    return combinedReducers({}, objAction);
  }
  return combinedReducers(objState, objAction);
}

const composeEnhancers =
  (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
      trace: true,
      traceLimit: 25,
    })) ||
  compose;

export const store = createStore(
  fnRootReducerInterceptor,
  composeEnhancers(applyMiddleware(...middlewares, createPromise()))
);

export const persistor = persistStore(store);

export default store;
