import { INTERVAL_HEIGHT } from './constants';

export const timeToPixelOffset = (interval, startEpoch, startBooking) => {
  return (
    Math.round(
      100 * ((startBooking - startEpoch) / interval) * INTERVAL_HEIGHT
    ) / 100
  );
};

export const getHeightOfBooking = (
  interval,
  startEpoch,
  endEpoch,
  isCoworkerBooking
) => {
  return (
    ((endEpoch - startEpoch) / interval) * INTERVAL_HEIGHT -
    (isCoworkerBooking ? 2 : 1)
  ); // the subtracted 1 allows the border to show around the booking, and we subtract 2 for coworker bookings for extra definition since they are similar to the gray of the grid
};
