/* eslint-disable import/prefer-default-export */
import { groupingFeatureRoleAccess } from '../../../Core/Utils/userPermissions';
import { StateAbbreviationMap } from '../../../resources';

export const makeOption = t => grouping => {
  if (!grouping) {
    return null;
  }
  return {
    label: grouping.displayName,
    value: grouping,
    key: grouping.idGrouping,
    groupId: grouping.isInYourLocationsSection
      ? 'primary'
      : grouping.location?.parentSfId,
    groupLabel: grouping.isInYourLocationsSection
      ? t('general.your_locations')
      : grouping.location?.parent?.name,
    isFirst: grouping.isInYourLocationsSection,
    description: {
      address: grouping.location?.address,
      city: grouping.location?.city,
      state: grouping.location?.state,
      zipCode: grouping.location?.zipCode,
    },
    isDisabled: !grouping.isBookable,
    search: `${grouping.location.address},${grouping.location.city},${
      grouping.location.zipCode
    },${
      StateAbbreviationMap[grouping.location?.state] ?? grouping.location?.state
    }`,
  };
};

export const filterLocationsForFeatureToggle = (
  profile,
  groupings,
  featureKey
) => {
  if (!featureKey) return groupings;
  return groupings.filter(g => {
    const groupingDto = {
      groupings: [g],
      groupingFeatureKey: featureKey,
    };
    return groupingFeatureRoleAccess.hasFeature({
      obj: groupingDto,
      profile,
    });
  });
};
