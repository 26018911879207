export enum GatewayServices {
  'user' = 'user',
  'access' = 'access',
  'authentication' = 'authentication',
  'inventory' = 'inventory',
}

export enum ApiExposure {
  Public = 0,
  Admin = 1,
}

export const getGatewayUrl = (
  service: GatewayServices,
  version = '1.1.0',
  host = process?.env?.REACT_APP_API_GATEWAY,
  apiExposure: ApiExposure = ApiExposure.Public
) => {
  if (apiExposure === ApiExposure.Admin) {
    return new URL(`/${service}/admin/v${version}`, host).href;
  }

  return new URL(`/${service}/v${version}`, host).href;
};
