import { schema, normalize } from 'normalizr';
import Lodash from 'lodash';

//  constructor
export function NormObjCstr(byId, allIds) {
  this.byId = byId;
  this.allIds = allIds;
}

const getKeys = obj => {
  if (!obj) {
    return [];
  }
  return Object.keys(obj).map(id => Number(id));
};

// user Schema
const userSchema = new schema.Entity(
  'user',
  {},
  {
    idAttribute: 'idUser',
  }
);

export const normalizeUser = array => {
  const temp = normalize(array, normalizeUser);
  return new NormObjCstr(temp.entities.byId, temp.result);
};

//  rooms

const bookingSchema = new schema.Entity(
  'bookings',
  {
    user: userSchema,
  },
  {
    idAttribute: 'idBooking',
  }
);

const bookingArraySchema = [bookingSchema];

export const normalizeBookings = data => {
  const temp = Array.isArray(data)
    ? normalize(data, bookingArraySchema)
    : normalize(data, bookingSchema);

  if (Lodash.isEmpty(temp.entities)) {
    return {
      bookings: new NormObjCstr({}, []),
      users: new NormObjCstr({}, []),
    };
  }
  //  this code is to coerce the type to Number
  const userKeys = getKeys(temp.entities.user);
  return {
    bookings: new NormObjCstr(temp.entities.bookings, temp.result),
    users: new NormObjCstr(temp.entities.user, userKeys),
  };
};
