import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';

import { EmptyStateDesks } from '../../../resources';

const EmptyState = ({ classes }) => {
  const { t } = useTranslation();

  return (
    <div className={classes.wrapper}>
      <img
        src={EmptyStateDesks}
        alt={t('altTexts.desk_empty')}
        className={classes.image}
      />
      <div className={classes.title}>
        {t('deskFloorPlan.deskReservation.no_desks_available_title')}
      </div>
      <div className={classes.description}>
        {t('deskFloorPlan.deskReservation.no_desks_available_desc')}
      </div>
    </div>
  );
};

const styles = () => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: '20px 16px 0',
  },
  title: {
    fontFamily: 'VerlagBold',
    fontSize: 20,
    marginBottom: 16,
    textAlign: 'center',
  },
  description: {
    fontSize: 18,
    textAlign: 'center',
  },
  image: {
    width: 250,
    marginBottom: 40,
  },
});

EmptyState.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(EmptyState);
