import { createAction } from 'redux-actions';
import * as types from './actionTypes';
import { fetchBookings } from '../../../Core/Api';
import { fetchHandlerActions } from '../../../Core/Utils/fetch';

export const addBookings = createAction(
  types.LOAD_BOOKINGS,
  fetchHandlerActions(fetchBookings)
);

export const addBooking = createAction(types.ADD_BOOKING);

export const updateBooking = createAction(types.UPDATE_BOOKING);

export const deleteBooking = createAction(types.DELETE_BOOKING);
