import Route from './Route';
import { LazyloadWrapper } from '../../Components/Common';

const CheckInActivityMainContainer = LazyloadWrapper(
  import('./CheckInActivityMainContainer')
);

export default {
  RenderProp: CheckInActivityMainContainer,
  mainRoute: Route,
};
