import Components from '../../Components';
import { getIsManager } from '../../Core/Utils/userPermissions';
import { VISITING_MEMBER_PATH } from './constants';

const { containerTypes } = Components.Routes;

const RouteConfig = {
  path: VISITING_MEMBER_PATH,
  exact: true,
  name: 'navigation.menu.visiting_members',
  private: true,
  type: containerTypes.PAGE,
  grouping: 'navigation.grouping.reserve',
  requiresMemberPortalAccess: true,
  isAuthorized: ({ profile }) => {
    return getIsManager(profile);
  },
};

export default RouteConfig;
