import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import { useTranslation } from 'react-i18next';
import { FieldArray, Form } from 'formik';

import {
  Panel,
  SubmitButton,
  Drawer,
  Text,
  TextButton,
  InfoTooltip,
} from '../../../Components/Common';
import { DeskEmptyState } from '../../../Components';
import { breakpoints, colors } from '../../../Core/Theme';
import DeskReservationRow from './DeskReservationRow';
import { getIsMobile } from '../../../Core/Utils/windowDimensions';
import { getAccountSfIdOfReservations } from '../../../Components/ReserveDesk/utils';
import { Office } from '../../../Models';

const DesksReservationDrawer = props => {
  const {
    classes,
    isOpen,
    setOpen,
    onReviewReservation,
    desks,
    selected,
    onRemoveDesk,
    isVisibleTooltip,
    onCloseTooltip,
    profile,
    isValid,
    shouldResetInput,
    setShouldResetInput,
    locationsById,
    isEdit,
    isDisabledReview,
  } = props;
  const { t } = useTranslation();
  const isMobile = getIsMobile();

  const accountSfId = getAccountSfIdOfReservations(
    Object.values(desks),
    profile
  );

  const close = () => setOpen(false);

  const handleOnRemoveDesk = ({ arrayHelpers, index, deskId }) => {
    const remove = get(arrayHelpers, ['remove'], null);
    if (remove) {
      remove(index);
    }

    return onRemoveDesk && onRemoveDesk(deskId);
  };

  const handleDisplayNameIfDeskIsUnselected = deskId => {
    const currentDesk = desks[deskId];
    if (!currentDesk) return null;

    const officeInfo = Office.getOfficeName(currentDesk.parentOffice);
    return t('general.officeAndDesk', {
      officeInfo,
      deskNumber: currentDesk.deskNumber,
    });
  };

  const renderContent = () => {
    return (
      <>
        <div className={classes.divider} />
        <div className={classes.reservations}>
          {isEmpty(selected) ? (
            <DeskEmptyState
              title={t('reserveDesk.empty_title_desk_selected')}
              description={t('reserveDesk.empty_description_desk_selected')}
              customClasses={{
                emptyState: classes.emptyState,
                mediumTitle: classes.mediumTitle,
                emptyDescription: classes.emptyDescription,
              }}
            />
          ) : (
            <FieldArray
              name="selected"
              render={arrayHelpers => (
                <Form className={classes.formWrapper}>
                  {selected.map((strDeskId, index) => {
                    return (
                      <DeskReservationRow
                        input="deskReservationRow"
                        onRemoveDesk={() =>
                          handleOnRemoveDesk({
                            arrayHelpers,
                            index,
                            deskId: strDeskId,
                          })
                        }
                        accountSfId={accountSfId}
                        fieldName={`desks.${strDeskId}.owner`}
                        id={index}
                        title={t('general.deskXofY', {
                          number: index + 1,
                          total: selected.length,
                        })}
                        floor={t('general.floor', {
                          floorName:
                            desks[strDeskId]?.floor ||
                            desks[strDeskId]?.parentOffice?.floor?.floorName ||
                            '',
                        })}
                        displayName={handleDisplayNameIfDeskIsUnselected(
                          strDeskId
                        )}
                        desks={desks}
                        setShouldResetInput={setShouldResetInput}
                        shouldResetInput={shouldResetInput}
                        profile={profile}
                        locationsById={locationsById}
                      />
                    );
                  })}
                </Form>
              )}
            />
          )}
        </div>
      </>
    );
  };

  return (
    <>
      {isMobile && (
        <Drawer
          isOpenDrawer={isOpen}
          onCloseDrawer={close}
          anchor="bottom"
          withCloseButton
          onCloseButton={close}
          classNameRoot={classes.drawerContainer}
          classes={{ paper: classes.drawerContainer }}
          customDuration={{ enter: 350, exit: 300 }}
        >
          <div className={classes.mainDrawer}>
            <Text
              text={t('deskReservation.drawer.title')}
              className={classes.title}
            />

            {renderContent()}
            <div className={classes.reviewButtonContainer}>
              <InfoTooltip
                isVisible={isOpen && isVisibleTooltip}
                placement="top"
                onClose={onCloseTooltip}
                description={t('reserveDesk.tooltip_select_more_desk')}
                anchorContent={() => (
                  <div className={classes.selectAnotherDeskButton}>
                    <TextButton
                      text={t('reserveDesk.select_another_desk')}
                      onClick={close}
                    />
                  </div>
                )}
              />

              <div className={classes.reviewButtonArea}>
                <SubmitButton
                  onClick={onReviewReservation}
                  defaultText={
                    isEdit
                      ? t('deskReservation.drawer.review_edit')
                      : t('deskReservation.drawer.review')
                  }
                  className={classes.footerButton}
                  disabled={isDisabledReview || !isValid || !selected.length}
                  datatestid="desk_review_reservation"
                />
              </div>
            </div>
          </div>
        </Drawer>
      )}
      <div className={classes.panel}>
        <Panel
          isOpen={isOpen}
          onClickClose={close}
          onClickButtonFooter={onReviewReservation}
          hasFooter={isOpen}
          buttonFooter={
            isEdit
              ? t('deskReservation.drawer.review_edit')
              : t('deskReservation.drawer.review')
          }
          variant="light"
          hideMobileCloseButton
          buttonDisabled={isDisabledReview || !isValid || !selected.length}
          title={t('deskReservation.title')}
          customClasses={{
            drawer: classes.drawerPaper,
            title: classes.title,
            header: classes.header,
            rightArrow: classes.rightArrow,
          }}
          footertestid="desk_review_reservation"
          datatestid="desks_reservations_drawer"
        >
          <div className={classes.main}>{renderContent()}</div>
        </Panel>
      </div>
    </>
  );
};

const styles = theme => ({
  selectAnotherDeskButton: {
    paddingTop: 8,
  },
  mediumTitle: {
    fontSize: '14px !important',
  },
  emptyDescription: {
    fontSize: '12px !important',
  },
  emptyState: {
    paddingTop: '20px !important',
  },
  reviewButtonArea: {
    paddingTop: 15,
    paddingLeft: 20,
    paddingRight: 20,
  },
  reviewButtonContainer: {
    position: 'absolute',
    bottom: 20,
    justifyContent: 'center',
    right: 0,
    left: 0,
    [theme.breakpoints.down(breakpoints.MOBILE)]: {
      bottom: 0,
      paddingBottom: 15,
    },
  },
  mainDrawer: {
    textAlign: 'center',
    marginTop: 50,
    height: 'calc(100vh - 50px)',
  },
  drawerContainer: {
    top: 0,
    borderTop: 'none',
    overflow: 'hidden',
    padding: 20,
  },
  reservations: {
    [theme.breakpoints.down(breakpoints.MOBILE)]: {
      height: 'calc(60vh - 20px)',
      overflow: 'scroll',
      '&::-webkit-scrollbar': {
        width: 0 /* Remove scrollbar space */,
        background: 'transparent' /* Optional: just make scrollbar invisible */,
      },
    },
  },
  rightArrow: {
    color: colors.black,
    '&:hover': {
      background: 'transparent',
    },
  },
  header: {
    marginBottom: 15,
  },
  title: {
    [theme.breakpoints.up(breakpoints.MOBILE)]: {
      fontSize: 16,
    },
    fontSize: 20,
    fontFamily: 'VerlagBook',
    textAlign: 'center',
  },
  divider: {
    [theme.breakpoints.up(breakpoints.MOBILE)]: {
      paddingTop: 0,
    },
    borderBottom: `solid 1px ${colors.light}`,
    paddingTop: 20,
  },
  panel: {
    [theme.breakpoints.down(breakpoints.MOBILE)]: {
      display: 'none',
    },
  },
  drawer: {
    [theme.breakpoints.up(breakpoints.MOBILE)]: {
      display: 'none',
    },
  },
  drawerPaper: {
    top: 'auto',
    minHeight: 'calc(80vh - 80px)',
  },
  main: {
    textAlign: 'center',
  },
  form: {
    marginTop: 20,
    height: 'calc(100% - 40px)',
    display: 'flex',
    flexDirection: 'column',
  },
  formWrapper: {
    marginBottom: 150,
    display: 'flex',
    flexDirection: 'column',
  },
});

DesksReservationDrawer.defaultProps = {
  selected: [],
  desks: {},
  locationsById: {},
  isEdit: false,
  isDisabledReview: false,
};

DesksReservationDrawer.propTypes = {
  desks: PropTypes.object,
  selected: PropTypes.arrayOf(PropTypes.string),
  onReviewReservation: PropTypes.func.isRequired,
  onRemoveDesk: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  isValid: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  profile: PropTypes.object.isRequired,
  isVisibleTooltip: PropTypes.bool.isRequired,
  onCloseTooltip: PropTypes.func.isRequired,
  shouldResetInput: PropTypes.bool.isRequired,
  setShouldResetInput: PropTypes.func.isRequired,
  locationsById: PropTypes.object,
  isEdit: PropTypes.bool,
  isDisabledReview: PropTypes.bool,
};

export default withStyles(styles)(DesksReservationDrawer);
