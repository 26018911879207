/* eslint-disable react/require-default-props */

import { StyleRulesCallback, withStyles } from '@material-ui/core';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import clsx from 'clsx';
import { ReactNode } from 'react';
import { breakpoints, theme } from '../../../../Core/Theme';

type Props = {
  classes: ClassNameMap;
  className?: string;
  children: ReactNode;
};

const TrackingContainer = (props: Props) => {
  const { classes, className, children } = props;

  return <div className={clsx(classes.default, className)}>{children}</div>;
};

const styles: StyleRulesCallback<string> = () => ({
  default: {
    marginRight: 20,
    [theme.breakpoints.up(breakpoints.MOBILE)]: {
      width: '50%',
    },
    marginTop: 10,
    borderBottom: 'none',
  },
});

export default withStyles(styles)(TrackingContainer);
