import Lodash from 'lodash';
import typeToReducer from 'type-to-reducer';
import moment from 'moment-timezone';

import { date } from '../../../Core/Utils';
import * as types from './actionTypes';
import drawerContent from '../../../Components/Reception/Header/drawerContent';

const { root } = drawerContent;

const initialState = {
  selectedLocation: {},
  tz: moment.tz.guess(true),
  notificationsYearData: [],
  notificationsLoading: false,
  notificationsHasError: false,
  notificationsFirstLoadingOccurred: false,
  selectedLocationSettings: {},
  selectedLocationSettingsLoading: false,
  selectedLocationSettingsHasError: false,
  redirectToPackageAlerts: false,
  drawerIsOpen: false,
  activeDrawerContent: root,
  notificationRecipients: [],
};

const onSetTimeZone = (state, action) => {
  const { oldTz, newTz } = Lodash.get(action, ['payload'], {});
  const strSelectedDate =
    Lodash.get(state, ['selectedDate'], null) || date.getTodayNotWeekend();
  const strSelectedMonth = Lodash.get(state, ['selectedMonth'], null);

  const strInternalOldTz = oldTz || moment.tz.guess(true);
  const initialMonth = moment.tz(newTz).startOf('month').unix();
  return {
    ...state,
    selectedDate: date.updateDateForTZ(
      strSelectedDate,
      newTz,
      strInternalOldTz
    ),
    selectedMonth: !strSelectedMonth
      ? initialMonth
      : date.updateDateForTZ(strSelectedMonth, newTz, strInternalOldTz),
    tz: newTz,
  };
};

export const onSetLocation = (state, action) => {
  const payload = Lodash.get(action, ['payload'], {});
  return {
    ...state,
    selectedLocation: payload,
  };
};

export const onLoadNotificationsPending = state => {
  return {
    ...state,
    notificationsLoading: true,
  };
};

export const onLoadNotificationsFulfilled = (state, action) => {
  const data = Lodash.get(action, ['payload'], {
    notificationsYearData: [],
  });

  const exportData = data.receptionHistories.map(recipient => {
    return {
      idReceptionHistory: recipient.idReceptionHistory,
      type: recipient.receptionType,
      location: recipient.locationSfId,
      locationName: recipient.location.name,
      date: recipient.receptionDate,
      visitorHost: recipient.visitorHostId,
      visitorHostName: recipient.visitorHost.name,
      visitorName: recipient.visitorName,
      visitorEmail: recipient.visitorEmail,
      visitorMessage: recipient.visitorMessage,
    };
  });

  const combinedData = [...exportData, ...data.receptionHistories];

  return {
    ...state,
    notificationsYearData: Lodash.uniqBy(combinedData, 'idReceptionHistory'),
    notificationsLoading: false,
    notificationsHasError: false,
    notificationsFirstLoadingOccurred: true,
  };
};

export const onLoadNotificationsRejected = state => {
  return {
    ...state,
    notificationsLoading: false,
    notificationsHasError: true,
  };
};

export const onLoadSelectedLocationSettingsPending = state => {
  return {
    ...state,
    selectedLocationSettingsLoading: true,
  };
};

const sortByName = recipientList => {
  return recipientList.sort((a, b) => {
    if (a.name > b.name) {
      return 1;
    }
    if (a.name < b.name) {
      return -1;
    }
    return 0;
  });
};

export const onLoadSelectedLocationSettingsFulfilled = (state, action) => {
  const settings = Lodash.get(action, ['payload', 'settings'], null);
  const recipients = Lodash.get(
    action,
    ['payload', 'notificationRecipients'],
    null
  );
  const notificationRecipients = recipients.map(recipient => {
    return recipient.user;
  });
  return {
    ...state,
    selectedLocationSettings: settings || {},
    selectedLocationSettingsLoading: false,
    selectedLocationSettingsHasError: false,
    notificationRecipients: sortByName(notificationRecipients) || {},
  };
};

export const onLoadSelectedLocationSettingsRejected = state => {
  return {
    ...state,
    selectedLocationSettingsLoading: false,
    selectedLocationSettingsHasError: true,
  };
};

export const onRedirectToPackageAlerts = (state, action) => {
  const payload = Lodash.get(action, 'payload', false);
  return {
    ...state,
    redirectToPackageAlerts: payload,
  };
};

export const onSetMailroomDescription = (state, action) => {
  const settings = Lodash.get(action, ['payload', 'settings'], null);
  return {
    ...state,
    selectedLocationSettings: settings || {},
  };
};

export const onToggleDrawerState = (state, action) => {
  const payload = Lodash.get(action, 'payload', false);
  return {
    ...state,
    drawerIsOpen: payload,
    activeDrawerContent: payload ? state.activeDrawerContent : root,
    redirectToPackageAlerts: payload ? state.redirectToPackageAlerts : false,
  };
};

export const onSetActiveDrawerContent = (state, action) => {
  const payload = Lodash.get(action, 'payload', root);
  return {
    ...state,
    activeDrawerContent: payload,
  };
};

export const onAddNotificationRecipient = (state, action) => {
  const payload = Lodash.get(action, 'payload', root);
  let memberExists = false;
  if (state.notificationRecipients) {
    memberExists =
      state.notificationRecipients.filter(e => e.sfId === payload.sfId).length >
      0;
  }

  return {
    ...state,
    notificationRecipients: memberExists
      ? state.notificationRecipients
      : sortByName([...state.notificationRecipients, payload]),
  };
};

export const onRemoveNotificationRecipient = (state, action) => {
  const payload = Lodash.get(action, 'payload', root);
  const recipients = state.notificationRecipients.filter(
    e => e.sfId !== payload.sfId
  );
  return {
    ...state,
    notificationRecipients: sortByName(recipients),
  };
};

const contentReducer = typeToReducer(
  {
    [types.SET_LOCATION]: onSetLocation,
    [types.SET_TIMEZONE]: onSetTimeZone,
    [types.LOAD_YEAR_NOTIFICATIONS]: {
      PENDING: onLoadNotificationsPending,
      FULFILLED: onLoadNotificationsFulfilled,
      REJECTED: onLoadNotificationsRejected,
    },
    [types.LOAD_SELECTED_LOCATION_SETTINGS]: {
      PENDING: onLoadSelectedLocationSettingsPending,
      FULFILLED: onLoadSelectedLocationSettingsFulfilled,
      REJECTED: onLoadSelectedLocationSettingsRejected,
    },
    [types.SET_REDIRECT_TO_PACKAGE_ALERTS]: onRedirectToPackageAlerts,
    [types.SET_MAILROOM_DESC]: onSetMailroomDescription,
    [types.SET_DRAWER_STATE]: onToggleDrawerState,
    [types.SET_ACTIVE_DRAWER_CONTENT]: onSetActiveDrawerContent,
    [types.ADD_NOTIFICATION_RECIPIENT]: onAddNotificationRecipient,
    [types.REMOVE_NOTIFICATION_RECIPIENT]: onRemoveNotificationRecipient,
  },
  initialState
);

export default contentReducer;
