import * as React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import UpcomingReservations from '../UpcomingReservations';
import MyReservations from '../../Containers/MyReservations';

import { trackOpenViewAllReservationsClick } from '../../Core/Tracking';

const UpcomingAndAllReservations = props => {
  const {
    history,
    upcomingUrlControlType,
    upcomingUrlPathname,
    upcomingTabVariant,
    upcomingRefreshCount,
    upcomingOnOpen,
    upcomingOnClose,
    upcomingIsOpen,
    datatestid,
    onUpcomingReservationsUpdated,
    onSuccess,
    tabClassName,
  } = props;

  const { t } = useTranslation();
  const [isAllReservationsOpen, setAllReservationsOpen] = React.useState(false);

  const onClickViewAll = () => {
    setAllReservationsOpen(true);
    trackOpenViewAllReservationsClick();
  };

  const onCloseViewAll = () => setAllReservationsOpen(false);

  const handleMyReservationsSuccess = type => {
    onSuccess(type);
    onUpcomingReservationsUpdated();
  };

  return (
    <>
      <UpcomingReservations
        history={history}
        tabVariant={upcomingTabVariant}
        urlPathname={upcomingUrlPathname}
        urlControlType={upcomingUrlControlType}
        upcomingRefreshCount={upcomingRefreshCount}
        onOpen={upcomingOnOpen}
        onClose={upcomingOnClose}
        footerText={t('openWorkspace.view_reservations')}
        onClickFooter={onClickViewAll}
        isOpen={upcomingIsOpen}
        datatestid={datatestid}
        onSuccess={onSuccess}
        tabClassName={tabClassName}
      />
      <MyReservations
        isOpen={isAllReservationsOpen}
        onClose={onCloseViewAll}
        history={history}
        onSuccess={handleMyReservationsSuccess}
      />
    </>
  );
};

UpcomingAndAllReservations.defaultProps = {
  upcomingUrlControlType: undefined,
  upcomingUrlPathname: undefined,
  upcomingRefreshCount: 0,
  upcomingTabVariant: undefined,
  upcomingOnOpen: () => null,
  upcomingOnClose: () => null,
  upcomingIsOpen: undefined,
  datatestid: 'upcoming_and_all_reservations',
  onUpcomingReservationsUpdated: () => {},
  onSuccess: () => {},
  tabClassName: '',
};

UpcomingAndAllReservations.propTypes = {
  history: PropTypes.shape({}).isRequired,
  upcomingUrlControlType: PropTypes.string,
  upcomingUrlPathname: PropTypes.string,
  upcomingRefreshCount: PropTypes.number,
  upcomingTabVariant: PropTypes.string,
  upcomingOnOpen: PropTypes.func,
  upcomingOnClose: PropTypes.func,
  upcomingIsOpen: PropTypes.bool,
  datatestid: PropTypes.string,
  onUpcomingReservationsUpdated: PropTypes.func,
  onSuccess: PropTypes.func,
  tabClassName: PropTypes.string,
};

export default UpcomingAndAllReservations;
