import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FloorDto } from '../Core/Api';
import { Floor, SelectOption } from '../Models';

import { useFloors } from './useFloors';

export const useFloorOptions = (selectedGrouping: { locationSfId: string }) => {
  const { floors } = useFloors();
  const [floorOptions, setFloorOptions] = useState<SelectOption<FloorDto>[]>();
  const { t } = useTranslation();

  useEffect(() => {
    if (!floors?.length || !selectedGrouping) {
      return;
    }

    setFloorOptions(undefined);

    const options = floors
      .filter(floor => selectedGrouping.locationSfId === floor.locationSfId)
      .sort(Floor.sortFloors)
      .map(floor => Floor.createFloorOption(floor, t));

    setFloorOptions(options);
  }, [floors, selectedGrouping]);

  return { floorOptions };
};
