export const SET_USER_TOKEN = 'SET_USER_TOKEN';

export const LOG_OUT = 'LOG_OUT';

export const LOG_IN = 'LOG_IN';

export const SET_USER_ID = 'SET_USER_ID';

export const SET_USER_PROFILE = 'SET_USER_PROFILE';

export const SET_TIMEZONE = 'USER_MANAGER/SET_TIMEZONE';

export const SET_USER_ACTIVE = 'SET_USER_ACTIVE';

export const TOGGLE_EMAIL_OPT_OUT = 'TOGGLE_EMAIL_OPT_OUT';

export const UPDATE_USER_ACCOUNT = 'UPDATE_USER_ACCOUNT';

export const SET_IS_FIRST_LOGIN = 'IS_FIRST_LOGIN';

export const SET_CURRENT_PATH = 'SET_CURRENT_PATH';

export const FETCH_USER_NOTIFICATION_SETTINGS =
  'FETCH_USER_NOTIFICATION_SETTINGS';
export const FETCH_USER_NOTIFICATION_SETTINGS_PENDING =
  'FETCH_USER_NOTIFICATION_SETTINGS_PENDING';
export const FETCH_USER_NOTIFICATION_SETTINGS_FULFILLED =
  'FETCH_USER_NOTIFICATION_SETTINGS_FULFILLED';
export const FETCH_USER_NOTIFICATION_SETTINGS_REJECTED =
  'FETCH_USER_NOTIFICATION_SETTINGS_REJECTED';

export const FETCH_USER_PROFILE = 'FETCH_USER_PROFILE';

export const PATCH_USER_NOTIFICATION_SETTINGS =
  'PATCH_USER_NOTIFICATION_SETTINGS';
export const PATCH_USER_NOTIFICATION_SETTINGS_PENDING =
  'PATCH_USER_NOTIFICATION_SETTINGS_PENDING';
export const PATCH_USER_NOTIFICATION_SETTINGS_FULFILLED =
  'PATCH_USER_NOTIFICATION_SETTINGS_FULFILLED';
export const PATCH_USER_NOTIFICATION_SETTINGS_REJECTED =
  'PATCH_USER_NOTIFICATION_SETTINGS_REJECTED';

export const PATCH_USER_NOTIFICATION_SETTINGS_REDUX =
  'PATCH_USER_NOTIFICATION_SETTINGS_REDUX';

export const PATCH_USER_PROFILE = 'PATCH_USER_PROFILE';
export const PATCH_USER_PROFILE_PENDING = 'PATCH_USER_PROFILE_PENDING';
export const PATCH_USER_PROFILE_FULFILLED = 'PATCH_USER_PROFILE_FULFILLED';
export const PATCH_USER_PROFILE_REJECTED = 'PATCH_USER_PROFILE_REJECTED';

export const FETCH_ACCOUNT_SETTINGS = 'FETCH_ACCOUNT_SETTINGS';
export const FETCH_ACCOUNT_SETTINGS_PENDING = 'FETCH_ACCOUNT_SETTINGS_PENDING';
export const FETCH_ACCOUNT_SETTINGS_FULFILLED =
  'FETCH_ACCOUNT_SETTINGS_FULFILLED';
export const FETCH_ACCOUNT_SETTINGS_REJECTED =
  'FETCH_ACCOUNT_SETTINGS_REJECTED';

export const PATCH_ACCOUNT_SETTINGS = 'PATCH_ACCOUNT_SETTINGS';
export const PATCH_ACCOUNT_SETTINGS_PENDING = 'PATCH_ACCOUNT_SETTINGS_PENDING';
export const PATCH_ACCOUNT_SETTINGS_FULFILLED =
  'PATCH_ACCOUNT_SETTINGS_FULFILLED';
export const PATCH_ACCOUNT_SETTINGS_REJECTED =
  'PATCH_ACCOUNT_SETTINGS_REJECTED';

export const ADD_API_ERROR = 'ADD_API_ERROR';

export const ADDRESS_API_ERROR = 'ADDRESS_API_ERROR';

export const SET_CUSTOM_ERROR_DESCRIPTION = 'SET_CUSTOM_ERROR_DESCRIPTION';

export const SET_CLIENT_ACCESS_CLAIMS = 'SET_CLIENT_ACCESS_CLAIMS';
