import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

import TextButton from '../TextButton/TextButton';
import { Icons } from '../../../resources';
import { breakpoints } from '../../../Core/Theme';

const LinkButton = ({ text, classes, ...rest }) => {
  return (
    <TextButton
      text={
        <div>
          {text}
          <Icons.ExternalLink className={classes.icon} />
        </div>
      }
      {...rest}
    />
  );
};

const styles = theme => ({
  icon: {
    height: 15,
    paddingLeft: 7,
    paddingRight: 7,
    [theme.breakpoints.up(breakpoints.MOBILE)]: { height: 12 },
  },
});

LinkButton.propTypes = {
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  classes: PropTypes.shape({}).isRequired,
};

export default withStyles(styles)(LinkButton);
