/* eslint-disable react/no-unused-state */
import React from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';

const Container = styled.div.attrs({
  style: ({ x, y }) => ({
    transform: `translate(${x}px, ${y}px)`,
  }),
})`
  cursor: grab;

  ${({ isDragging }) =>
    isDragging &&
    css`
      position: relative;
      z-index: 99999;
      opacity: 0.8;
      cursor: grabbing;
    `};
`;

export default class Draggable extends React.Component {
  state = {
    isDragging: false,

    originalX: 0,
    originalY: 0,

    translateX: 0,
    translateY: 0,

    lastTranslateX: 0,
    lastTranslateY: 0,
  };

  componentWillUnmount() {
    window.removeEventListener('mousemove', this.handleMouseMove);
    window.removeEventListener('mouseup', this.handleMouseUp);
  }

  handleMouseDown = ({ clientX, clientY }) => {
    const { onDragStart, index } = this.props;
    window.addEventListener('mousemove', this.handleMouseMove);
    window.addEventListener('mouseup', this.handleMouseUp);

    if (onDragStart) {
      onDragStart(index);
    }

    this.setState({
      originalX: clientX,
      originalY: clientY,
      isDragging: true,
    });
  };

  handleMouseMove = ({ clientX, clientY }) => {
    const { isDragging, translateX, translateY } = this.state;
    const { onDrag } = this.props;

    if (!isDragging) {
      return;
    }

    this.setState(
      prevState => ({
        translateX: clientX - prevState.originalX + prevState.lastTranslateX,
        translateY: clientY - prevState.originalY + prevState.lastTranslateY,
      }),
      () => {
        if (onDrag) {
          onDrag({
            translateX,
            translateY,
          });
        }
      }
    );
  };

  handleMouseUp = () => {
    const { translateX, translateY } = this.state;
    const { onDragEnd } = this.props;
    window.removeEventListener('mousemove', this.handleMouseMove);
    window.removeEventListener('mouseup', this.handleMouseUp);

    this.setState(
      {
        originalX: 0,
        originalY: 0,
        lastTranslateX: translateX,
        lastTranslateY: translateY,

        isDragging: false,
      },
      () => {
        if (onDragEnd) {
          onDragEnd();
        }
      }
    );
  };

  render() {
    const { children, canDrag } = this.props;
    const { isDragging } = this.state;

    if (canDrag) {
      return (
        <Container
          onMouseDown={this.handleMouseDown}
          onDragStartCapture={this.handleOnClick}
          isDragging={isDragging}
        >
          <div draggable="true"> {children}</div>
        </Container>
      );
    }
    return <div> {children}</div>;
  }
}

Draggable.defaultProps = {
  onDrag: () => null,
  onDragEnd: () => null,
};

Draggable.propTypes = {
  canDrag: PropTypes.bool.isRequired,
  onDragEnd: PropTypes.func,
  onDrag: PropTypes.func,
  onDragStart: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
};
