import * as React from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import Truncate from 'react-truncate';
import { useTranslation } from 'react-i18next';

import { breakpoints } from '../../../Core/Theme';
import TextButton from '../TextButton';
import { getIsMobile } from '../../../Core/Utils/windowDimensions';

const TRANSITION_DURATION = 400;

const CollapsibleText = props => {
  const { classes, children, lines, className } = props;
  const { t } = useTranslation();
  const isMobile = getIsMobile();

  const [isTruncated, setIsTruncated] = React.useState(isMobile);
  // need to track the close transition so the lines in the truncate component aren't limited until after the transition
  const [isTransitioningClosed, setIsTransitioning] = React.useState(false);

  const toggleTruncation = () => {
    if (!isTruncated) {
      setIsTransitioning(true);
      setTimeout(() => {
        setIsTruncated(true);
        setIsTransitioning(false);
      }, TRANSITION_DURATION);
    } else {
      setIsTruncated(false);
    }
  };
  return (
    <div
      className={clsx(classes.text, className)}
      style={
        isTruncated || isTransitioningClosed
          ? { maxHeight: lines * 26 }
          : { maxHeight: 1000 }
      }
    >
      <Truncate
        lines={isTruncated && !isTransitioningClosed ? lines : -1}
        ellipsis={
          <>
            ...
            <TextButton
              text={t('general.see_more')}
              onClick={toggleTruncation}
            />
          </>
        }
      >
        {children}
      </Truncate>
      {!isTruncated && isMobile && (
        <TextButton text={t('general.see_less')} onClick={toggleTruncation} />
      )}
    </div>
  );
};

const styles = theme => ({
  text: {
    fontFamily: 'VerlagLight',
    fontSize: 16,
    lineHeight: '26px',
    transition: `max-height ${TRANSITION_DURATION}ms ease-in-out`,
    overflow: 'hidden',
    [theme.breakpoints.up(breakpoints.MOBILE)]: {
      fontSize: 18,
      lineHeight: '24px',
    },
  },
});
CollapsibleText.defaultProps = {
  lines: 2,
  className: '',
};

CollapsibleText.propTypes = {
  lines: PropTypes.number,
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
};

export default withStyles(styles)(CollapsibleText);
