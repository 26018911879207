import clsx from 'clsx';

import { AnyReservation, Office } from '../../Models';
import { ACTIONS_COLUMNS } from '../Common/SmartTable/Constants';
import {
  RESERVATION_ACTIONS,
  RESERVATION_TYPES,
} from '../../Models/anyReservation';
import { date as DateUtils, formatCurrency } from '../../Core/Utils';
import { USD_CURRENCY_CODE } from './constants';

export const getLabelKeyFromType = type => {
  if (type === RESERVATION_TYPES.CONFERENCE_BOOKING) {
    return 'accountActivity.table.types.conference';
  }
  if (type === RESERVATION_TYPES.SEAT_BOOKING) {
    return 'accountActivity.table.types.openWorkspace';
  }
  if (type === RESERVATION_TYPES.DESK_RESERVATION) {
    return 'accountActivity.table.types.desk';
  }
  return 'accountActivity.table.types.office';
};

export const getColumns = (classes, t, profile) => {
  const columns = [
    {
      key: b => {
        const anyReservationWithType = b.data;
        const { reservation, type } = anyReservationWithType;
        const { startEpoch, endEpoch, active: isActive = true } = reservation;

        const bookingLocation = AnyReservation.getReservationLocation(
          anyReservationWithType
        );
        const { timeZoneId, timeZoneCode } = bookingLocation ?? {};

        return (
          <div className={clsx(!isActive && classes.inactive)}>
            {!isActive && (
              <div className={classes.cancelledLabel}>
                {t('general.cancelled').toUpperCase()}:
              </div>
            )}
            <div className={classes.spacer}>
              {DateUtils.getFormattedDateInMomentWithZone(
                startEpoch,
                timeZoneId,
                '[M/D/YYYY]'
              )}
            </div>
            {type === RESERVATION_TYPES.CONFERENCE_BOOKING && (
              <div>
                {DateUtils.getFormattedDateInMomentWithZone(
                  startEpoch,
                  timeZoneId,
                  '[h:mma]'
                )}
                -
                {DateUtils.getFormattedDateInMomentWithZone(
                  endEpoch,
                  timeZoneId,
                  '[h:mma]'
                )}
                <span className={classes.tableTzId}> {timeZoneCode}</span>
              </div>
            )}
          </div>
        );
      },
      label: t('accountActivity.table.columns.date'),
    },
    {
      key: info => {
        const anyReservationWithType = info.data;
        const { reservation } = anyReservationWithType;

        const owner = AnyReservation.getReservationOwner(
          anyReservationWithType
        );
        return (
          <div className={clsx(!reservation.active && classes.inactive)}>
            {owner.name}
          </div>
        );
      },
      label: t('accountActivity.table.columns.member'),
    },
    {
      key: info => {
        const anyReservationWithType = info.data;
        const { reservation, type } = anyReservationWithType;
        const { active } = reservation;

        return (
          <div className={clsx(!active && classes.inactive)}>
            {t(getLabelKeyFromType(type))}
          </div>
        );
      },
      label: t('accountActivity.table.columns.type'),
    },
    {
      key: info => {
        const anyReservationWithType = info.data;
        const { reservation, type } = anyReservationWithType;
        const { active } = reservation;

        const location = AnyReservation.getReservationLocation(
          anyReservationWithType
        );

        const externalName = location?.externalName;

        if (type === RESERVATION_TYPES.SEAT_BOOKING) {
          return (
            <div className={clsx(!active && classes.inactive)}>
              <div>{location?.externalName}</div>
            </div>
          );
        }

        let floorName;
        let spaceName;

        if (type === RESERVATION_TYPES.CONFERENCE_BOOKING) {
          const { space } = reservation;
          spaceName = space?.name ?? '';
          floorName = space?.floor?.floorName ?? '';
        }
        if (type === RESERVATION_TYPES.OFFICE_RESERVATION) {
          floorName = reservation?.office?.floor?.floorName;

          spaceName = Office.getOfficeName(reservation?.office);
        }
        if (type === RESERVATION_TYPES.DESK_RESERVATION) {
          floorName = reservation?.desk?.parentOffice?.floor?.floorName;
          const deskNumber = reservation?.desk?.deskNumber;

          const officeName = Office.getOfficeName(
            reservation?.desk?.parentOffice
          );

          spaceName = t('deskAdmin.upcoming.desk', {
            deskNumber,
            officeName,
          });
        }

        return (
          <div className={clsx(!active && classes.inactive)}>
            <div className={classes.spacer}>Floor {floorName}</div>
            <div className={classes.spacer}>{spaceName}</div>
            <div>{externalName}</div>
          </div>
        );
      },
      label: t('accountActivity.table.columns.location'),
      className: classes.tableLocation,
    },
    {
      key: info => {
        const anyReservationWithType = info.data;
        const { reservation, type } = anyReservationWithType;
        const { active } = reservation;

        if (type === RESERVATION_TYPES.CONFERENCE_BOOKING) {
          const duration = reservation.endEpoch - reservation.startEpoch;
          return (
            <div className={clsx(!active && classes.inactive)}>
              {t('general.num_hours', { count: duration / 60 / 60 })}
            </div>
          );
        }

        if (type === RESERVATION_TYPES.OFFICE_RESERVATION) {
          return (
            <div className={clsx(!active && classes.inactive)}>
              {reservation.usesDayPasses
                ? t('accountActivity.table.data.day_pass')
                : formatCurrency({
                    value: reservation.price,
                    currency: reservation.currency,
                  })}
            </div>
          );
        }
        return <div className={clsx(!active && classes.inactive)}>—</div>;
      },
      className: classes.tablePrice,
      label: t('accountActivity.table.columns.price'),
    },
  ];
  columns.push({
    key: 'options',
    label: '',
    type: 'options',
    className: classes.optionsColumn,
    options: r => {
      const { reservation, type } = r;
      const { active } = reservation;
      if (!active) {
        return [];
      }
      const canEdit = AnyReservation.getReservationPermissions({
        reservation,
        profile,
        type,
        action: RESERVATION_ACTIONS.update,
      });
      const canCancel = AnyReservation.getReservationPermissions({
        reservation,
        profile,
        type,
        action: RESERVATION_ACTIONS.delete,
      });

      const options = [];

      if (canEdit) {
        options.push({ label: 'Edit', type: 'edit' });
      }
      if (canCancel) {
        options.push({
          label: 'Cancel',
          type: ACTIONS_COLUMNS.CANCEL,
        });
      }

      return options;
    },
  });

  return columns;
};

export const getTotalCharges = (
  meetingRoomCharge = 0,
  meetingRoomCurrency = USD_CURRENCY_CODE,
  dayPassCharge = 0,
  officeCharges = [],
  showDeskDayPassInfo
) => {
  if (showDeskDayPassInfo) {
    return [
      formatCurrency({
        currency: USD_CURRENCY_CODE,
        value:
          (meetingRoomCurrency === USD_CURRENCY_CODE ? meetingRoomCharge : 0) +
          dayPassCharge,
      }),
      ...(meetingRoomCurrency !== USD_CURRENCY_CODE
        ? [
            formatCurrency({
              currency: meetingRoomCurrency,
              value: meetingRoomCharge,
            }),
          ]
        : []),
    ].join(' + ');
  }
  const officeChargesHasMeetingRoomCurrency = officeCharges.find(
    charge => charge.currency === meetingRoomCurrency
  );

  const allCharges = officeChargesHasMeetingRoomCurrency
    ? officeCharges.map(charge => {
        const { total, currency } = charge;
        if (currency === meetingRoomCurrency) {
          return { currency, total: total + meetingRoomCharge };
        }
        return charge;
      })
    : [
        ...officeCharges,
        { currency: meetingRoomCurrency, total: meetingRoomCharge },
      ];
  return allCharges
    .map(charge =>
      formatCurrency({
        currency: charge.currency,
        value: charge.total,
      })
    )
    .join(' + ');
};
