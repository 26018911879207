import 'focus-visible/dist/focus-visible';
import * as React from 'react';
import { useLocation, withRouter } from 'react-router-dom';
import { MuiThemeProvider } from '@material-ui/core/styles';
import AuthMP from '@industriousoffice/member-portal-auth';
import { bindActionCreators } from 'redux';
import { connect, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import Lodash from 'lodash';
import { useTranslation } from 'react-i18next';
import * as Sentry from '@sentry/react';

import { useState } from 'react';
import Containers from './Containers';
import InitialLoad from './Containers/InitialLoad';
import Components from './Components';
import { theme } from './Core/Theme';
import userRedux from './Redux/Common/UserManager';
import groupingsRedux from './Redux/Common/Groupings';
import {
  getUserGroupings,
  getUserGroupingsIsLoading,
} from './Redux/Common/Groupings/selectors';
import Auth from './Components/Common/AuthContext';
import { setCustomDimensions } from './Core/Tracking/gtag';
import { sortTopNavigation, sortNavGroupings } from './Core/Utils/navigation';

import {
  getUserProfile,
  getUserToken,
  getStatusUser,
  getSecondaryUserRole,
  getApiErrors,
  getUnAddressedApiErrors,
  getUserId,
  getCanAccessBillingPortal,
  getCanAccessMemberPortal,
} from './Redux/Common/UserManager/selectors';
import ErrorMessage from './Components/Common/ErrorMessage/ErrorMessage';

const getDocumentTitle = (pathname, routes, t) => {
  if (pathname === '/home') {
    return 'Home | Industrious Member Portal';
  }
  const route = Lodash.find(routes, r => r.mainRoute.path === pathname);
  const routeName = Lodash.get(route, ['mainRoute', 'name'], '');
  if (routeName) {
    return `${t(routeName)} | Industrious Member Portal`;
  }

  return 'Industrious Member Portal';
};

const ScrollToTop = withRouter(({ history, children }) => {
  const [previousPath, setPreviousPath] = useState(window.location.pathname);
  React.useEffect(() => {
    const unlisten = history.listen(location => {
      const { pathname } = location;
      if (pathname !== previousPath) {
        window.scrollTo(0, 0);
        setPreviousPath(pathname);
      }
    });

    return () => {
      unlisten();
    };
  }, []);

  return <>{children}</>;
});

const AppRouter = props => {
  const {
    setCurrentPath,
    profile,
    userToken,
    isUserActive,
    addUserGroupings,
    userGroupings,
    userGroupingsIsLoading,
    secondaryUserRole,
    addGroupings,
    apiErrors,
    addressApiErrors,
    unaddressedApiErrors,
    fetchUserProfile,
    userSfId,
  } = props;
  const { Routes } = Components;
  const location = useLocation();
  const { t } = useTranslation();

  const canAccessMemberPortal = useSelector(getCanAccessMemberPortal);
  const canAccessBillingPortal = useSelector(getCanAccessBillingPortal);

  React.useEffect(() => {
    // This can happen in weird case when user come back to MP
    // We need to fetch the user profile as we don't have it anymore
    if (userToken && !profile.sfId) {
      fetchUserProfile(userSfId);
    }
  }, []);

  React.useEffect(() => {
    if (userToken && profile.sfId) {
      addUserGroupings(profile.sfId);
      addGroupings();
      addressApiErrors(); // mark all errors from before refresh as addressed
    }
  }, [userToken, profile.sfId]);

  AuthMP.configure(
    process.env.REACT_APP_REGION,
    process.env.REACT_APP_COGNITO_USER_POOL,
    process.env.REACT_APP_COGNITO_APP_ID
  );

  if (userToken && profile.sfId) {
    const param = {
      user_role: profile.role,
      home_location: profile.primaryLocation.externalName,
      secondary_role: secondaryUserRole,
      user_sfId: profile.sfId,
    };
    setCustomDimensions(param);
  }
  // definite routes are those the user can always access based on just their profile info
  // definite routes are shown as menu items
  // possible routes are routes the user can maybe access but we need more information, such as userGroupings
  // possible routes we allow the user to go to while the other information is loading
  const { definiteRoutes, possibleRoutes } = Object.values(Containers).reduce(
    (acc, container) => {
      const { mainRoute } = container;
      if (mainRoute.requiresMemberPortalAccess && !canAccessMemberPortal) {
        return acc;
      }
      if (mainRoute.requiresBillingPortalAccess && !canAccessBillingPortal) {
        return acc;
      }
      // if there is no auth function assume we want to show the menu function
      if (
        !mainRoute.isAuthorized ||
        mainRoute.isAuthorized({
          profile,
          userGroupings,
          userGroupingsIsLoading,
        })
      ) {
        acc.possibleRoutes.push(container);
      }
      if (
        !mainRoute.isAuthorized ||
        mainRoute.isAuthorized({
          profile,
          userGroupings,
          userGroupingsIsLoading: false,
        })
      ) {
        // false is passed as the loading value to hide the menu items until it is sure they should be displayed
        acc.definiteRoutes.push(container);
      }
      return acc;
    },
    { definiteRoutes: [], possibleRoutes: [] }
  );

  React.useEffect(() => {
    if (
      location.pathname.includes('home') &&
      !location.pathname.includes('logout')
    ) {
      setCurrentPath(location.pathname);
    }
    document.title = getDocumentTitle(
      window.location.pathname,
      possibleRoutes,
      t
    );
    setCustomDimensions({
      page_path: location.pathname,
      location_on_page: location.pathname,
    });
  }, [location]);
  const listToUse = userGroupingsIsLoading ? possibleRoutes : definiteRoutes;
  const navList = listToUse.reduce(
    (acc, container) => {
      const { mainRoute } = container;
      const { subNav, topNavList } = acc;
      if (mainRoute.type === Routes.containerTypes.CONTENT) {
        const { isMenuItemHidden } = mainRoute;

        if (
          isMenuItemHidden &&
          isMenuItemHidden({ profile, userGroupings, userGroupingsIsLoading })
        ) {
          return acc;
        }
        topNavList.push(mainRoute);
      }
      if (mainRoute.type === Routes.containerTypes.SUBCONTENT) {
        const { grouping, name, isMenuItemHidden } = mainRoute;
        if (
          isMenuItemHidden &&
          isMenuItemHidden({ profile, userGroupings, userGroupingsIsLoading })
        ) {
          return acc;
        }
        if (grouping) {
          subNav[grouping] = subNav[grouping] || [];
        }
        grouping
          ? subNav[grouping].push(mainRoute)
          : (subNav[name] = [mainRoute]);
      }
      return acc;
    },
    { topNavList: [], subNav: {} }
  );

  sortTopNavigation(navList.topNavList);
  const subNavOrder = sortNavGroupings(Object.keys(navList.subNav));

  // create routes
  const containerRoutes = Routes.AppRoutes(
    possibleRoutes,
    subNavOrder,
    navList.subNav,
    navList.topNavList,
    Components.Common.PrivateRoute,
    Components.Common.ErrorBoundary,
    profile,
    userToken && isUserActive,
    userGroupings,
    userGroupingsIsLoading
  );

  return (
    <MuiThemeProvider theme={theme}>
      <InitialLoad>
        <Auth.Provider>
          {userToken && unaddressedApiErrors.length > 0 && (
            <ErrorMessage
              addressApiErrors={addressApiErrors}
              apiErrors={apiErrors}
              unaddressedApiErrors={unaddressedApiErrors}
            />
          )}
          <ScrollToTop>{containerRoutes}</ScrollToTop>
        </Auth.Provider>
      </InitialLoad>
    </MuiThemeProvider>
  );
};

const mapStateToProps = state => {
  return {
    profile: getUserProfile(state),
    userToken: getUserToken(state),
    isUserActive: getStatusUser(state),
    userGroupings: getUserGroupings(state),
    userGroupingsIsLoading: getUserGroupingsIsLoading(state),
    secondaryUserRole: getSecondaryUserRole(state),
    apiErrors: getApiErrors(state),
    unaddressedApiErrors: getUnAddressedApiErrors(state),
    userSfId: getUserId(state),
  };
};

const mapDispatchToProps = dispatch => {
  const { actions } = userRedux;

  return bindActionCreators(
    {
      setCurrentPath: actions.setCurrentPath,
      addUserGroupings: groupingsRedux.actions.addUserGroupings,
      addGroupings: groupingsRedux.actions.addGroupings,
      addressApiErrors: actions.addressApiErrors,
      fetchUserProfile: actions.fetchUserProfile,
    },
    dispatch
  );
};

AppRouter.propTypes = {
  setCurrentPath: PropTypes.func.isRequired,
  profile: PropTypes.object.isRequired,
  userToken: PropTypes.string.isRequired,
  isUserActive: PropTypes.bool.isRequired,
  addUserGroupings: PropTypes.func.isRequired,
  addGroupings: PropTypes.func.isRequired,
  userGroupings: PropTypes.arrayOf(PropTypes.object).isRequired,
  userGroupingsIsLoading: PropTypes.bool.isRequired,
  secondaryUserRole: PropTypes.string.isRequired,
  addressApiErrors: PropTypes.func.isRequired,
  apiErrors: PropTypes.arrayOf(PropTypes.object).isRequired,
  unaddressedApiErrors: PropTypes.arrayOf(PropTypes.object).isRequired,
  fetchUserProfile: PropTypes.func.isRequired,
  userSfId: PropTypes.string.isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Sentry.withProfiler(AppRouter));
