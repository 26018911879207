import axios from 'axios';
import FormData from 'form-data';

const defaults = {
  date_order: 'DESC',
  start_epoch: 0,
  end_epoch: 4102358400,
  limit: 100,
  offset: 0,
};

export const fetchNotifications = (
  locationSfid,
  offset,
  startEpoch,
  endEpoch,
  limit
) => {
  const d = defaults;
  const params = {
    date_order: d.date_order,
    limit: limit || d.limit,
    offset,
    start_epoch: startEpoch || d.start_epoch,
    end_epoch: endEpoch || d.end_epoch,
    location_sfid: locationSfid,
  };
  const url = '/notifications/receptions';
  return axios.get(url, { params });
};

export const fetchSelectedLocation = sfid => {
  const url = `/locations/${sfid}`;
  return axios.get(url);
};

export const updateSelectedLocationSettings = (sfid, objSettings) => {
  const url = `/locations/${sfid}/settings`;
  return axios.put(url, objSettings);
};

export const updateNotificationRecipients = (locationSfId, recipientsArr) => {
  const url = `/locations/${locationSfId}/notification_recipients`;
  const recipients = {
    notificationRecipients: recipientsArr,
  };
  return axios.put(url, recipients);
};

export const fetchParcels = (
  locationSFid,
  offset,
  startEpoch,
  endEpoch,
  limit
) => {
  const params = {
    location_sfid: locationSFid,
    offset: offset || defaults.offset,
    start_epoch: startEpoch || defaults.start_epoch,
    end_epoch: endEpoch || defaults.end_epoch,
    limit: limit || defaults.limit,
    date_order: defaults.date_order,
  };

  return axios.get('/notifications/parcels', { params });
};

export const sendParcels = objParcel => {
  return axios.post('/notifications/parcels', objParcel);
};

export const sendParcelsImage = (locationSfId, image) => {
  const data = new FormData();
  data.append('file', image);
  data.append('locationSfId', locationSfId);
  return axios.post(`/notifications/parcels/image`, data, {
    headers: {
      // eslint-disable-next-line no-underscore-dangle
      'Content-Type': `multipart/form-data ; boundary=${data._boundary}`,
    },
  });
};
