/* eslint-disable no-nested-ternary */

import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Checkbox from '@material-ui/core/Checkbox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import clsx from 'clsx';

import { colors } from '../../../Core/Theme';

const CheckBox = props => {
  const {
    checkBoxText,
    label,
    onChange,
    checkedColor,
    labelStyle,
    unCheckedColor,
    checked,
    inputClasses,
    disabled,
    classes,
    variant,
    datatestid,
    checkboxClassName,
    ...rest
  } = props;
  const isSmall = variant === 'small';
  const style = isSmall ? { height: 16, padding: 0 } : { padding: 0 };
  return (
    <FormControlLabel
      control={
        <Checkbox
          className={checkboxClassName}
          inputProps={{ datatestid: `${datatestid}_input` }}
          icon={
            <CheckBoxOutlineBlankIcon
              style={
                checked && !disabled
                  ? { color: checkedColor, ...style }
                  : !disabled
                  ? { color: unCheckedColor || checkedColor, ...style }
                  : { color: colors.middle, ...style }
              }
              datatestid={`${datatestid}_unchecked`}
            />
          }
          checkedIcon={
            <CheckBoxIcon
              style={
                checked && !disabled
                  ? { fill: checkedColor, ...style }
                  : !disabled
                  ? { fill: unCheckedColor || checkedColor, ...style }
                  : { color: colors.middle, ...style }
              }
              datatestid={`${datatestid}_checked`}
            />
          }
          checked={checked}
          onChange={disabled ? () => {} : onChange}
          {...rest}
        />
      }
      classes={{
        label: clsx(
          labelStyle,
          classes.label,
          isSmall && classes.labelSmall,
          disabled && classes.labelDisabled
        ),
        ...inputClasses,
      }}
      datatestid={`${datatestid}_label`}
      label={checkBoxText || label}
      disabled={disabled}
    />
  );
};
const styles = {
  labelDisabled: {
    color: colors.dark,
    cursor: 'default',
  },
  label: {
    fontFamily: 'VerlagBook',
    fontSize: 18,
    paddingTop: 5,
  },
  labelSmall: {
    fontFamily: 'VerlagLight',
    fontSize: 16,
    marginLeft: -10,
    paddingTop: 1,
  },
};

CheckBox.defaultProps = {
  checkedColor: colors.palette.primary.main,
  unCheckedColor: colors.dark,
  labelStyle: '',
  checked: false,
  inputClasses: {},
  checkBoxText: null,
  label: '',
  disabled: false,
  variant: '',
  datatestid: 'check_box',
  checkboxClassName: '',
};

CheckBox.propTypes = {
  checkBoxText: PropTypes.oneOfType([PropTypes.string, PropTypes.object]), // we should phase this out
  label: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  checkedColor: PropTypes.string,
  labelStyle: PropTypes.string,
  unCheckedColor: PropTypes.string,
  checked: PropTypes.bool,
  inputClasses: PropTypes.object,
  disabled: PropTypes.bool,
  variant: PropTypes.string,
  datatestid: PropTypes.string,
  checkboxClassName: PropTypes.string,
};

export default withStyles(styles)(CheckBox);
