export const SET_SELECTED_LOCATION = 'SET_SELECTED_LOCATION';

export const SET_TIMEZONE = 'EVENTS/SET_TIMEZONE';

export const SET_SELECTED_DATE = 'SET_DATE';

export const SET_SELECTED_MENU_WEEK = 'SET_SELECTED_MENU_WEEK';

export const SET_SELECTED_EVENT = 'SET_SELECTED_EVENT';

export const ADD_OCCASIONS = 'ADD_OCCASIONS';

export const ADD_OCCASION_TYPES = 'ADD_OCCASION_TYPES';

export const ADD_OCCASION_RSVPS = 'ADD_OCCASION_RSVPS';

export const ADD_OCCASION = 'ADD_OCCASION';

export const DELETE_OCCASION = 'DELETE_OCCASION';

export const UPDATE_OCCASION = 'UPDATE_OCCASION';

export const ADD_OCCASION_TYPE = 'ADD_OCCASION_TYPE';

export const UPDATE_OCCASION_TYPE = 'UPDATE_OCCASION_TYPE';

export const SET_SELECTED_GROUPING = 'SET_SELECTED_GROUPING';

export const ADD_QUERIED_LOCATION = 'ADD_QUERIED_LOCATION';

export const UPDATE_RSVP = 'UPDATE_RSVP';

export const DELETE_RSVP = 'DELETE_RSVP';

export const ADD_HOSPITALITY_CATEGORIES = 'ADD_HOSPITALITY_CATEGORIES';

export const ADD_RSVP = 'ADD_RSVP';
