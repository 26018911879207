// name must correspond to Account Profile

export const demographicFields = [
  { name: 'name', label: 'account.labels.name' },
  { name: 'contactEmail', label: 'account.labels.email' },
  {
    name: 'websiteUrl',
    label: 'account.labels.website',
  },
];

export const socialMediaFields = [
  {
    name: 'linkedinProfileUrl',
    label: 'account.labels.linkedin',
    placeHolder: 'account.placeholders.instagram',
  },
  {
    name: 'twitterHandle',
    label: 'account.labels.twitter',
    placeHolder: 'account.placeholders.instagram',
  },
  {
    name: 'instagramHandle',
    label: 'account.labels.instagram',
    placeHolder: 'account.placeholders.instagram',
  },
  {
    name: 'facebookProfileUrl',
    label: 'account.labels.facebook',
    placeHolder: 'account.placeholders.instagram',
  },
];
