import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Fab from '@material-ui/core/Fab';
import CloseIcon from '@material-ui/icons/Close';
import Lodash from 'lodash';
import Moment from 'moment';
import { EditorState, convertFromRaw } from 'draft-js';
import { useTranslation } from 'react-i18next';

import { Icons, S3IconStrings } from '../../../../resources';

import { getStaticImageUrl, formatCurrency } from '../../../../Core/Utils';

// Components
import {
  Drawer,
  TextButton,
  RichTextEditor,
  AddCalendarBtn,
} from '../../../../Components/Common';
import RsvpButton from '../RsvpButton';
import { getRTEDecorators } from '../../../../Components/Common/RichTextEditor';
import DayBox from '../DayBox/DayBox';

import { getOccasionById, createRsvp, deleteRsvp } from '../../../../Core/Api';
import {
  trackClickAddress,
  trackAddEventToCalendar,
  trackWaitlistRsvp,
  trackCancelRsvp,
  trackRsvp,
  trackCancelWaitlistRsvp,
  trackVirtualEventLinkFromCard,
  trackExternalEventLinkFromCard,
} from '../../../../Core/Tracking/gtag';
import { renderVirtualLink } from '../../util';
import Redux from '../../Redux';

import { getOccasionRsvps, getOccasionTypesById } from '../../Redux/selectors';

import { colors, breakpoints } from '../../../../Core/Theme';

const PriceTag = ({ currency, cost, classes }) => {
  const { t } = useTranslation();
  return (
    <div className={classes.priceTag}>
      <p className={classes.label}>{t('events.price')}</p>
      <p className={classes.price}>
        {formatCurrency({ value: cost, currency })}
      </p>
    </div>
  );
};

PriceTag.defaultProps = {
  currency: '$',
  cost: 0,
  classes: {},
};

PriceTag.propTypes = {
  currency: PropTypes.string,
  cost: PropTypes.number,
  classes: PropTypes.shape({}),
};

const addCalendarOnClick = e => {
  e.stopPropagation();
  trackAddEventToCalendar({ event_category: 'Detail Drawer' });
};

const eventDrawerContent = (
  classes,
  selectedEvent,
  occasionTypesById,
  onRsvp,
  onUnrsvp,
  openExternalRegistrationLink,
  externalRegistrationLink,
  rsvpd,
  ableToWaitlist,
  waitlisted,
  eventFull,
  isLoading,
  canAccessUrl
) => {
  const {
    title,
    startEpoch,
    endEpoch,
    locationDetails,
    address,
    description,
    cost,
    currency,
    canRsvp,
    primaryOccasionImage,
    primaryOccasionTypeId,
    conferenceLink,
    conferenceLinkPassword,
    numberOfAttendees,
  } = selectedEvent;
  const occasionTypeName = Lodash.get(
    occasionTypesById,
    [primaryOccasionTypeId, 'name'],
    ''
  );
  const descConverted = EditorState.createWithContent(
    convertFromRaw(JSON.parse(description)),
    getRTEDecorators()
  );
  const buttonOnClick = cb => e => {
    cb();
    e.stopPropagation();
  };

  const openWindow = () => {
    trackClickAddress({ event_category: 'Upcoming Events' });
    window.open(
      `https://www.google.com/maps/search/?api=1&query=${encodeURI(address)}`
    );
  };
  const openEventLink = () => {
    trackVirtualEventLinkFromCard();
    window.open(conferenceLink);
  };
  const location = Lodash.get(selectedEvent, ['space', 'name'], '');
  const startTime = Moment(startEpoch * 1000).format('[h:mma]');
  const endTime = Moment(endEpoch * 1000).format('[h:mma]');
  const eventImage = Lodash.get(primaryOccasionImage, 'imageUrl', null);
  const isVirtual = Lodash.get(selectedEvent, 'isVirtual', false);
  const occasionTypeImage = Lodash.get(
    occasionTypesById,
    [primaryOccasionTypeId, 'imageUrl'],
    null
  );
  const locationContent = () => {
    return (
      <div className={classes.places}>
        <span className={classes.location}>
          <img src={Icons.Location} alt="location" className={classes.icon} />
          {location || locationDetails}
        </span>
        {address ? (
          <TextButton
            text={address}
            className={classes.address}
            onClick={buttonOnClick(openWindow)}
          />
        ) : null}
      </div>
    );
  };
  const virtualLink = () => {
    return (
      <div className={classes.joinEventContainer}>
        <TextButton
          text="Join event virtually"
          className={classes.joinLink}
          onClick={buttonOnClick(openEventLink)}
        />
        <span className={classes.joinBar}>|</span>
        <span className={classes.joinPassword}>
          Password: {conferenceLinkPassword || 'none'}
        </span>
      </div>
    );
  };
  return (
    <>
      <div
        className={classes.scrollBody}
        style={{ height: canRsvp ? 'calc(100% - 90px)' : '100%' }}
      >
        <header className={classes.imageHeader}>
          <img
            src={
              eventImage ||
              occasionTypeImage ||
              getStaticImageUrl(S3IconStrings.eventsDefault)
            }
            alt="room"
            className={classes.eventImage}
            width="100%"
            height="100%"
          />
          <DayBox
            startEpoch={startEpoch}
            occasionTypeName={occasionTypeName}
            className={classes.dayBox}
            isVirtual={isVirtual}
          />
        </header>
        <div className={classes.body}>
          <h2 className={classes.title}>{title}</h2>
          <h4 className={classes.time}>
            {startTime} - {endTime}
          </h4>

          {location || locationDetails ? locationContent() : null}

          {isVirtual && conferenceLink
            ? renderVirtualLink(
                canAccessUrl,
                canRsvp,
                rsvpd,
                eventFull,
                virtualLink()
              )
            : null}

          {numberOfAttendees > 6 ? (
            <div className={classes.numAttendees}>
              <span>{numberOfAttendees} people going</span>
            </div>
          ) : null}

          {!canRsvp && cost * 1 !== 0 ? (
            <PriceTag
              currency={currency}
              cost={cost}
              classes={{
                priceTag: classes.priceTag,
                price: classes.price,
                label: classes.label,
              }}
            />
          ) : null}
          <div className={classes.richTextEditor}>
            <RichTextEditor editorState={descConverted} isDisabled />
          </div>
          <div className={classes.addToCalendar}>
            <AddCalendarBtn
              event={{ ...selectedEvent, description: descConverted }}
              onClick={addCalendarOnClick}
            />
          </div>
        </div>
      </div>
      {canRsvp ? (
        <footer className={classes.footer}>
          <RsvpButton
            rsvpd={rsvpd}
            canRsvp={canRsvp}
            eventFull={eventFull}
            ableToWaitlist={ableToWaitlist}
            waitlisted={waitlisted}
            isLoading={isLoading}
            rsvpAction={buttonOnClick(onRsvp)}
            unrsvpAction={buttonOnClick(onUnrsvp)}
            externalRegistrationLinkAction={buttonOnClick(
              openExternalRegistrationLink
            )}
            externalRegistrationLink={externalRegistrationLink}
            className={classes.rsvpButton}
            datatestid="event_drawer_status"
          />
        </footer>
      ) : null}
    </>
  );
};
const EventDrawerView = props => {
  const {
    classes,
    isOpen,
    closeDrawer,
    selectedEvent,
    occasionTypesById,
    profile,
    rsvps,
    updateRsvpRedux,
    deleteRsvpRedux,
    updateOccasionRedux,
    noAnimation,
    canAccessUrl,
    onSuccess,
  } = props;

  const { t } = useTranslation();
  const rsvp = rsvps.filter(o => o.occasionId === selectedEvent.idOccasion);
  const waitlisted = Lodash.get(rsvp, [0, 'isWaitlisted'], false);
  const idRsvp = Lodash.get(rsvp, [0, 'idRsvp'], 0);

  const {
    canWaitlist,
    numberOfAttendees,
    attendeeLimit,
    externalRegistrationLink,
  } = selectedEvent;
  const [isLoading, setIsLoading] = React.useState(false);

  const openExternalRegistrationLink = () => {
    trackExternalEventLinkFromCard();
    if (externalRegistrationLink) {
      window.open(externalRegistrationLink);
    }
  };

  const onRsvp = () => {
    const { idOccasion } = selectedEvent;
    const id = idOccasion * 1;

    const postdata = {
      userSfId: profile.sfId,
      occasionId: id,
      isWaitlisted: false,
    };

    setIsLoading(true);
    createRsvp(postdata).then(objResponse => {
      const { data, status } = objResponse;
      const { isWaitlisted } = data;
      if (isWaitlisted) {
        trackWaitlistRsvp();
      } else {
        trackRsvp();
      }
      const newRsvpId = Lodash.get(data, 'idRsvp', '');
      if (status === 201) {
        updateRsvpRedux(data);
        if (isWaitlisted) {
          getOccasionById(idOccasion).then(response => {
            updateOccasionRedux(response.data);
            if (!response.data.canWaitlist) {
              deleteRsvp(newRsvpId).then(() => {
                deleteRsvpRedux(newRsvpId);
                setIsLoading(false);
              });
            }
          });
          setIsLoading(false);
          onSuccess();
        } else {
          setIsLoading(false);
        }
      }
    });
  };

  const onUnrsvp = () => {
    setIsLoading(true);
    if (waitlisted) {
      trackCancelWaitlistRsvp();
    } else {
      trackCancelRsvp();
    }
    deleteRsvp(idRsvp).then(objResponse => {
      const { status } = objResponse;
      if (status === 200) {
        deleteRsvpRedux(idRsvp);
        const { idOccasion } = selectedEvent;
        getOccasionById(idOccasion).then(response => {
          updateOccasionRedux(response.data);
          setIsLoading(false);
        });
        onSuccess();
      }
    });
  };
  return (
    <Drawer
      anchor="right"
      isOpenDrawer={isOpen}
      onCloseDrawer={closeDrawer}
      className="drawerPaperMediumSmall"
      noAnimation={noAnimation}
    >
      {Lodash.isEmpty(selectedEvent) ? null : (
        <article className={classes.main}>
          <Fab
            size="small"
            onClick={closeDrawer}
            aria-label={t('general.close')}
            className={classes.closeButton}
            datatestid="events_drawer_close_button"
          >
            <CloseIcon />
          </Fab>
          {eventDrawerContent(
            classes,
            selectedEvent,
            occasionTypesById,
            onRsvp,
            onUnrsvp,
            openExternalRegistrationLink,
            externalRegistrationLink,
            rsvp.length > 0,
            canWaitlist,
            waitlisted,
            numberOfAttendees > 0 &&
              attendeeLimit &&
              numberOfAttendees >= attendeeLimit * 1,
            isLoading,
            canAccessUrl
          )}
        </article>
      )}
    </Drawer>
  );
};

const styles = theme => ({
  main: {
    height: '100%',
    position: 'relative',
  },
  body: {
    position: 'relative',
    margin: '27px 41px 0px 40px',
    display: 'flex',
    justifyContent: 'space-around',
    flexDirection: 'column',
    [theme.breakpoints.down(breakpoints.MOBILE)]: {
      margin: '27px 20px 20px 20px',
    },
  },
  closeButton: {
    color: colors.black,
    backgroundColor: colors.white,
    position: 'absolute',
    right: '15px',
    top: '15px',
    boxShadow: '0 0 4px 0 rgba(0, 0, 0, 0.25)',
    '&:hover': {
      backgroundColor: colors.white,
    },
    '&:focus': {
      boxShadow: '0 0 5px 0 rgba(0, 0, 0, `)',
    },
    zIndex: 1000,
  },
  eventImage: {
    height: '100%',
    objectFit: 'cover',
  },
  imageHeader: {
    height: 215,
    width: '100%',
    maxWidth: '100%',
    position: 'relative',
  },
  title: {
    fontFamily: 'VerlagBook',
    fontSize: '24px',
    fontWeight: 'bold',
    margin: 0,
    padding: 0,
    overflowWrap: 'break-word',
  },
  time: {
    fontFamily: 'VerlagBook',
    fontSize: '18px',
    fontWeight: 'bold',
    margin: 0,
    padding: 0,
    marginBottom: 20,
  },
  places: {
    marginTop: 0,
  },
  location: {
    fontFamily: 'VerlagBook',
    fontSize: 14,
    paddingRight: 10,
  },
  address: {
    fontFamily: 'VerlagBook',
    fontSize: 14,
    color: colors.palette.primary.main,
  },
  icon: {
    height: 13,
    paddingRight: 3,
  },
  richTextEditor: {
    marginTop: 15,
  },
  footer: {
    display: 'flex',
    position: 'absolute',
    bottom: 0,
    width: '100%',
    paddingBottom: 10,
    background: colors.white,
    left: 0,
    right: 0,
    justifyContent: 'space-evenly',
    boxShadow: '0 -2px 2px 0 rgba(0, 0, 0, 0.1)',
  },
  priceTag: {
    marginTop: 25,
    marginBottom: 0,
  },
  label: {
    margin: '0 0 3px 0',
    fontFamily: 'VerlagBook',
    fontSize: '14px',
    color: colors.black,
    fontWeight: 'bold',
    lineHeight: 1.29,
    letterSpacing: '0.5px',
  },
  price: {
    margin: 0,
    fontFamily: 'VerlagLight',
    color: colors.black,
    fintSize: 18,
  },
  dayBox: {
    top: '88px',
    left: '39px',
    [theme.breakpoints.down(breakpoints.MOBILE)]: {
      left: 20,
    },
  },
  addToCalendar: {
    marginTop: 25,
  },
  rsvpButton: {
    width: 'calc(100% - 80px)',
    maxWidth: '100%',
  },
  joinEventContainer: {
    fontSize: 14,
    marginTop: 5,
    marginBottom: 5,
  },
  joinLink: {
    fontFamily: 'VerlagBook',
    fontSize: 14,
    color: colors.palette.primary.main,
    textAlign: 'left',
    padding: 0,
    textDecoration: 'underline',
    '&:visited': {
      color: colors.palette.primary.main,
    },
  },
  joinBar: {
    marginLeft: 4,
  },
  joinPassword: {
    marginLeft: 4,
  },
  scrollBody: {
    overflowY: 'scroll',
    '-ms-overflow-style': 'none',
    '&::-webkit-scrollbar': {
      width: 0 /* Remove scrollbar space */,
      background: 'transparent' /* Optional: just make scrollbar invisible */,
    },
  },
});

EventDrawerView.defaultProps = {
  classes: {},
  isOpen: false,
  selectedEvent: {},
  occasionTypesById: {},
  rsvps: [],
  onSuccess: () => null,
};

EventDrawerView.propTypes = {
  classes: PropTypes.shape({}),
  isOpen: PropTypes.bool,
  closeDrawer: PropTypes.func.isRequired,
  selectedEvent: PropTypes.shape({
    title: PropTypes.string,
    idOccasion: PropTypes.string,
    startEpoch: PropTypes.number,
    endEpoch: PropTypes.number,
    locationDetails: PropTypes.string,
    address: PropTypes.string,
    description: PropTypes.shape({}),
    cost: PropTypes.number,
    currency: PropTypes.string,
    canRsvp: PropTypes.bool,
    canWaitlist: PropTypes.bool,
    numberOfAttendees: PropTypes.number,
    attendeeLimit: PropTypes.number,
    primaryOccasionImage: PropTypes.string,
    primaryOccasionTypeId: PropTypes.string,
    externalRegistrationLink: PropTypes.string,
  }),
  occasionTypesById: PropTypes.shape({}),
  profile: PropTypes.object.isRequired,
  rsvps: PropTypes.arrayOf(PropTypes.object),
  updateRsvpRedux: PropTypes.func.isRequired,
  deleteRsvpRedux: PropTypes.func.isRequired,
  updateOccasionRedux: PropTypes.func.isRequired,
  noAnimation: PropTypes.bool.isRequired,
  canAccessUrl: PropTypes.bool.isRequired,
  onSuccess: PropTypes.func,
};

const mapStateToProps = state => {
  return {
    profile: state.userManager.profile,
    rsvps: getOccasionRsvps(state),
    occasionTypesById: getOccasionTypesById(state),
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      updateRsvpRedux: Redux.actions.updateRsvp,
      deleteRsvpRedux: Redux.actions.deleteRsvp,
      updateOccasionRedux: Redux.actions.updateOccasion,
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(EventDrawerView));
