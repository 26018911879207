/*  eslint-disable consistent-return */

import Moment from 'moment';
import Lodash from 'lodash';
import { Action, ResourceType } from '@industriousoffice/member-portal-rbac';
// Api
import { fetchMemberSuggestions } from '../../Core/Api';

//  Access Control
import handleUserPermissions from '../../Core/Utils/userPermissions';

export const getIntDiffHours = (initialTime, endTime) => {
  const start = Moment.unix(initialTime * 1);
  const end = Moment.unix(endTime * 1);
  const diffBetweenHours = Moment.duration(end.diff(start)).asHours();
  if (diffBetweenHours && diffBetweenHours > 0) {
    return diffBetweenHours;
  }
  return 0;
};

export const getDiffHours = (initialTime, endTime) => {
  const diffBetweenHours = getIntDiffHours(initialTime, endTime);
  if (diffBetweenHours && diffBetweenHours > 0) {
    return `${Math.round(diffBetweenHours * 100) / 100} hours`;
  }
  return '0';
};

export const formattedTime = date => {
  const formatted = Moment(date || new Date(), 'X').format('[h:mma]');
  return formatted;
};

export const formattedDate = date => {
  const formatted = Moment(new Date(date)).format('LL');
  return formatted;
};

/* eslint-disable no-param-reassign */
export const orderBookings = bookings => {
  return Lodash.orderBy(bookings, ['startEpoch'], ['asc']);
};

const compareUsers = locationSfId => (userA, userB) => {
  if (
    userB.primaryLocationSfId !== locationSfId ||
    userA.primaryLocationSfId !== locationSfId
  ) {
    return userB.primaryLocationSfId === locationSfId ? 1 : -1;
  }
  const nameA = Lodash.get(userA, ['name'], '');
  const nameB = Lodash.get(userB, ['name'], '');

  return nameA.toLowerCase() < nameB.toLowerCase() ? -1 : 1;
};

export const getSuggestions =
  (profile, locationSfId) => async (inputValue, setResults) => {
    const objUser = {
      profile,
      action: Action.AdminCreate,
      resourceType: ResourceType.Booking,
    };
    if (!handleUserPermissions(objUser)) {
      return;
    }

    fetchMemberSuggestions(inputValue, locationSfId, '', false, 100).then(
      response => {
        if (Lodash.get(response, ['status'], null) >= 400) {
          return;
        }
        const data = Lodash.get(response, ['data', 'users'], []);
        setResults(data.sort(compareUsers(locationSfId)));
      }
    );
  };

export const timeOverlapsBooking = (
  bookings,
  currentId,
  startTime,
  endTime
) => {
  let startOverlap = false;
  let endOverlap = false;
  bookings.forEach(booking => {
    const id = Lodash.get(booking, ['idBooking'], -2);
    if (id !== currentId && currentId >= 0 && id >= 0) {
      startOverlap =
        startOverlap ||
        (startTime >= booking.startEpoch && startTime < booking.endEpoch);
      endOverlap =
        endOverlap ||
        (endTime > booking.startEpoch && endTime <= booking.endEpoch);
      if (startTime < booking.startEpoch && endTime > booking.endEpoch) {
        startOverlap = true;
        endOverlap = true;
      }
    }
  });
  return { startOverlap, endOverlap };
};
