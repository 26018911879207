/* eslint-disable no-underscore-dangle */
import { StyleRulesCallback, Theme, withStyles } from '@material-ui/core';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import { KeyboardEvent, MouseEvent, useEffect, useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { History } from 'history';
import queryString from 'query-string';

import * as React from 'react';
import { useSelector } from 'react-redux';
import { GroupingFeatureKey } from '@industriousoffice/member-portal-rbac/dist/types';
import {
  fetchOfficeReservationById,
  FloorDto,
  GroupingDto,
  OfficeAvailabilityDto,
  OfficeReservationDto,
} from '../../Core/Api';

import { date as DateUtils } from '../../Core/Utils';

import { breakpoints, colors } from '../../Core/Theme';

import {
  useDayPasses,
  useFloors,
  useIsLocationDateAvailable,
} from '../../Hooks';
import {
  useFloorsWithOfficeAvailabilities,
  useOfficeAvailabilities,
} from './Hooks';

import { EmptyStateDesks } from '../../resources';
import { ReserveOfficeViewModes } from './reserveOfficeViewModes.enum';

import {
  EmptyState,
  Loading,
  SelectInput,
  Tabs,
} from '../../Components/Common';
import OfficeReservationFloorPlan from '../../Components/FloorPlan/OfficeReservationFloorPlan';
import { UpcomingAndAllReservations } from '../../Components';
import {
  OfficeAvailabilityCount,
  OfficeAvailabilityList,
  OfficeReservationCreationPanel,
  OfficeReservationFilter,
  ReserveOfficeHeader,
} from './Components';
import { RoundedButton } from '../../Components/Common/RoundedButton';
import { useGroupings } from '../../Hooks/useGroupings';
import { getUserProfile } from '../../Redux/Common/UserManager/selectors';
import {
  passUsageRoleAccess,
  locationRoleAccess,
} from '../../Core/Utils/userPermissions';
import OfficeReservationSummary from '../../Components/OfficeReservationSummary/OfficeReservationSummary';
import { RESERVE_OFFICE_PATH } from './reserveOfficeConstants';
import BlackoutDateMessage from './Components/BlackoutDateMessage';

type Props = {
  classes: ClassNameMap;
  history: History;
};

const ReserveOfficePage = (props: Props) => {
  const { classes, history } = props;
  const profile = useSelector(getUserProfile);
  const { t } = useTranslation();
  const { floors } = useFloors();

  const [selectedGrouping, setSelectedGrouping] = useState<GroupingDto>();
  const [selectedEpoch, setSelectedEpoch] = useState(
    DateUtils.getTodayNotWeekend()
  );
  const { floorOptions, selectedFloor, selectFloor, setSelectedFloor } =
    useFloorsWithOfficeAvailabilities();
  const { officeAvailabilities, searchOfficeAvailabilities } =
    useOfficeAvailabilities();

  const [viewMode, setViewMode] = useState(ReserveOfficeViewModes.List);
  const [selectedOfficeAvailability, setSelectedOfficeAvailability] =
    useState<OfficeAvailabilityDto>();
  const [showOverlay, setShowOverlay] = useState(false);
  const [upcomingRefreshCount, setUpcomingRefreshCount] = useState(0);
  const [filterRefreshCount, setFilterRefreshCount] = useState(0);
  const [reservationFromUrlId, setReservationFromUrlId] =
    useState<OfficeReservationDto>();

  const tabs = [
    {
      text: t('reserveOffice.view_types.list'),
      id: ReserveOfficeViewModes.List,
      mobileText: t('reserveOffice.view_types.show_floorplan'),
    },
    {
      text: t('reserveOffice.view_types.floorplan'),
      id: ReserveOfficeViewModes.Floormap,
      mobileText: t('reserveOffice.view_types.show_list'),
    },
  ];

  const {
    hasUnlimitedDayPasses,
    passesRemaining,
    isFetching: isFetchingDayPasses,
    fetchUsage,
  } = useDayPasses(
    DateUtils.getStartOfMonthEpochInZone(
      selectedEpoch,
      profile?.account?.location?.timeZoneId
    ), // need to ensure date midnight is midnight in zone of accounts location
    profile
  );

  const isReservingWithDayPasses = passUsageRoleAccess.purchase({
    obj: {
      passesRemaining,
      passesNeeded: 1,
      hasUnlimitedDayPasses,
      date: DateUtils.getDateInMoment(selectedEpoch),
    },
    profile,
  });

  const hasBookingRestrictions = useMemo(() => {
    if (!selectedGrouping) return false;
    return locationRoleAccess.hasBookingRestrictions({
      profile,
      obj: selectedGrouping?.location,
    });
  }, [selectedGrouping]);

  const {
    displayGroupings,
    isLoading: isLoadingGroupings,
    defaultGrouping,
  } = useGroupings(GroupingFeatureKey.TeamOffice);

  React.useEffect(() => {
    const windowLocationSearch = window.location.search;
    const params = queryString.parse(windowLocationSearch);
    const { 'reservation-id': reservationId } = params;
    if (Number(reservationId)) {
      fetchOfficeReservationById(Number(reservationId)).then(objResponse => {
        setReservationFromUrlId(objResponse.data);
      });
    }
  }, [window.location.search]);

  React.useEffect(() => {
    if (!selectedGrouping && defaultGrouping) {
      setSelectedGrouping(defaultGrouping);
    }
  }, [setSelectedGrouping, defaultGrouping]);

  const { isBlackoutDate } = useIsLocationDateAvailable({
    locationId: selectedGrouping?.location._id,
    date: selectedEpoch,
    timeZoneId: selectedGrouping?.location.timeZoneId,
  });

  const isDateUnavailable = isBlackoutDate && hasBookingRestrictions;

  useEffect(() => {
    const initialize = async () => {
      if (!selectedGrouping) return;
      const floor = await selectFloor(selectedGrouping, selectedEpoch);
      searchOfficeAvailabilities({
        grouping: selectedGrouping,
        epoch: selectedEpoch,
        floorId: floor?.idFloor,
      });
    };

    if (floors.length && !floorOptions) {
      initialize();
    }
  }, [floors, selectedGrouping]);

  const handleFloorChange = (floor: FloorDto) => {
    setSelectedFloor(floor);
    searchOfficeAvailabilities({
      grouping: selectedGrouping,
      epoch: selectedEpoch,
      floorId: floor.idFloor,
    });
  };

  const handleReservationRequested = (
    officeAvailability: OfficeAvailabilityDto
  ) => {
    setSelectedOfficeAvailability(officeAvailability);
  };

  const handleSearchRequested = async (
    grouping: GroupingDto,
    epoch: number
  ) => {
    setSelectedGrouping(grouping);
    setSelectedEpoch(epoch);

    setShowOverlay(false);
    const floor = await selectFloor(grouping, epoch);
    return searchOfficeAvailabilities({
      grouping,
      epoch,
      floorId: floor?.idFloor,
    });
  };

  const handleClosePanel = () => {
    setSelectedOfficeAvailability(undefined);
  };

  const handleReserved = () => {
    searchOfficeAvailabilities({
      grouping: selectedGrouping,
      epoch: selectedEpoch,
      floorId: selectedFloor?.idFloor,
    });
    setUpcomingRefreshCount(upcomingRefreshCount + 1);
    fetchUsage(
      DateUtils.getStartOfMonthEpochInZone(
        selectedEpoch,
        profile?.account?.location?.timeZoneId
      )
    );
  };

  const handleFilterChange = (grouping: GroupingDto, epoch: number) => {
    setShowOverlay(grouping !== selectedGrouping || epoch !== selectedEpoch);
  };

  const handleCloseOverlay = (
    e: MouseEvent<HTMLDivElement> | KeyboardEvent<HTMLDivElement>
  ) => {
    if (showOverlay) {
      setShowOverlay(false);
      setFilterRefreshCount(filterRefreshCount + 1);
    }
    e.stopPropagation();
  };

  const handleChangeFromUpcomingEvents = async () => {
    await searchOfficeAvailabilities({
      grouping: selectedGrouping,
      epoch: selectedEpoch,
      floorId: selectedFloor?.idFloor,
    });
    setUpcomingRefreshCount(upcomingRefreshCount + 1);
    fetchUsage();
  };

  const toggleViewMode = (): void => {
    if (viewMode === ReserveOfficeViewModes.List) {
      return setViewMode(ReserveOfficeViewModes.Floormap);
    }
    return setViewMode(ReserveOfficeViewModes.List);
  };

  return (
    <>
      <div className={classes.page}>
        <ReserveOfficeHeader>
          <OfficeReservationFilter
            grouping={selectedGrouping}
            epoch={selectedEpoch}
            onSearchRequested={handleSearchRequested}
            onFilterChange={handleFilterChange}
            refreshCount={filterRefreshCount}
            isLoadingGroupings={isLoadingGroupings}
            groupings={displayGroupings}
          />
        </ReserveOfficeHeader>
        <div className={classes.contentContainer}>
          <UpcomingAndAllReservations
            history={history}
            upcomingRefreshCount={upcomingRefreshCount}
            upcomingTabVariant="dark"
            onSuccess={handleChangeFromUpcomingEvents}
            tabClassName={classes.upcomingTab}
          />

          {showOverlay && (
            <div
              role="presentation"
              className={classes.overlay}
              onClick={handleCloseOverlay}
              onKeyDown={handleCloseOverlay}
            />
          )}
          <div className={classes.center}>
            <div className={classes.content}>
              {floorOptions && (
                <>
                  {(!!officeAvailabilities?.length || !!selectedFloor) &&
                    !isDateUnavailable && (
                      <div className={classes.contentHeader}>
                        <div className={classes.contentHeaderLeft}>
                          {selectedFloor && (
                            <SelectInput
                              disabled={false}
                              options={floorOptions}
                              onChange={handleFloorChange}
                              variant="boxed"
                              value={selectedFloor}
                              noCaratWithSingleOption
                              inputClassName={classes.select}
                              classNameCarat={classes.selectCarat}
                            />
                          )}
                          {!!officeAvailabilities?.length && (
                            <OfficeAvailabilityCount
                              offices={officeAvailabilities}
                            />
                          )}
                        </div>
                        <Tabs
                          legends={tabs}
                          variant="boxed"
                          selectedId={viewMode}
                          onTabClick={setViewMode}
                          tabClassName={classes.tabs}
                        />
                      </div>
                    )}

                  {!officeAvailabilities && !isDateUnavailable && <Loading />}
                  {viewMode === ReserveOfficeViewModes.List &&
                    selectedGrouping &&
                    !!officeAvailabilities?.length &&
                    !isDateUnavailable && (
                      <OfficeAvailabilityList
                        officeAvailabilities={officeAvailabilities}
                        currencyIsoCode={
                          selectedGrouping.location.currencyIsoCode
                        }
                        onReservationRequested={handleReservationRequested}
                        isReservingWithDayPasses={isReservingWithDayPasses}
                        isFetchingDayPasses={isFetchingDayPasses}
                      />
                    )}
                  {viewMode === ReserveOfficeViewModes.Floormap &&
                    selectedFloor &&
                    officeAvailabilities &&
                    !isDateUnavailable && (
                      <div className={classes.sectionMap}>
                        <OfficeReservationFloorPlan
                          mapStyleId={
                            selectedFloor.mapboxDatasetMappings[0]
                              ?.mapboxStyleId
                          }
                          datasetId={
                            selectedFloor.mapboxDatasetMappings[0]
                              ?.mapboxDatasetId
                          }
                          isLoading={false}
                          offices={officeAvailabilities}
                          selectedFloorName={selectedFloor.floorName}
                          selectedOffice={selectedOfficeAvailability}
                          onSelectOffice={office =>
                            handleReservationRequested(office)
                          }
                        />
                      </div>
                    )}
                  {isDateUnavailable && (
                    <BlackoutDateMessage
                      title={t('reserveOffice.blackout_date_unavailable_title')}
                      description={t(
                        'reserveOffice.blackout_date_unavailable_message'
                      )}
                    />
                  )}
                  {officeAvailabilities?.length === 0 && (
                    <EmptyState
                      title={t('reserveOffice.no_office_available_title')}
                      description={t('reserveOffice.no_office_available_desc')}
                      imageSrc={EmptyStateDesks}
                      imageAlt={t('altTexts.desk_empty')}
                      imageClassName={classes.emptyImage}
                    />
                  )}
                </>
              )}
              {!floorOptions && <Loading />}
            </div>
          </div>
        </div>
      </div>
      {!isDateUnavailable && (
        <RoundedButton
          className={classes.switchViewButton}
          onClick={toggleViewMode}
        >
          {tabs[viewMode].mobileText}
        </RoundedButton>
      )}
      {selectedGrouping && (
        <OfficeReservationCreationPanel
          history={history}
          currencyIsoCode={selectedGrouping.location.currencyIsoCode}
          grouping={selectedGrouping}
          officeAvailability={selectedOfficeAvailability}
          onClose={handleClosePanel}
          onReserved={handleReserved}
          isReservingWithDayPasses={isReservingWithDayPasses}
          isFetchingDayPasses={isFetchingDayPasses}
          passesRemaining={passesRemaining}
          hasUnlimitedDayPasses={hasUnlimitedDayPasses}
        />
      )}
      <OfficeReservationSummary
        profile={profile}
        onClose={() => {
          setReservationFromUrlId(undefined);
          history.push({
            pathname: RESERVE_OFFICE_PATH,
          });
        }}
        reservation={reservationFromUrlId}
        onSuccess={() => {
          setUpcomingRefreshCount(upcomingRefreshCount + 1);
          history.push({
            pathname: RESERVE_OFFICE_PATH,
          });
        }}
      />
    </>
  );
};

const style: StyleRulesCallback<string> = (theme: Theme) => ({
  page: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    backgroundColor: colors.palette.secondary2.light,
    minHeight: '100vh',
  },
  center: {
    display: 'flex',
    justifyContent: 'center',
  },
  content: {
    width: 'calc(100vw - 2rem)',
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
    padding: '16px 20px 0px 20px',
    alignItems: 'center',
    [theme.breakpoints.up(breakpoints.SM)]: {
      maxWidth: 670,
    },
    [theme.breakpoints.up(breakpoints.MOBILE)]: {
      padding: '1rem 7rem',
      width: '100%',
    },
  },
  contentHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  },
  contentHeaderLeft: {
    display: 'flex',
    gap: '1rem',
    alignItems: 'center',
    flexDirection: 'column',
    [theme.breakpoints.up(breakpoints.MOBILE)]: {
      flexDirection: 'row',
    },
  },
  select: {
    borderRadius: '9999px !important',
    paddingTop: 2,
    paddingBottom: 2,
    width: 'fit-content',
  },
  selectCarat: {
    top: `calc(50% - 8px)`,
    right: 10,
    position: `absolute`,
    pointerEvents: `none`,
  },
  tabs: {
    textAlign: 'center',
    paddingTop: '4px',
    paddingBottom: '4px',
    display: 'none',
    [theme.breakpoints.up(breakpoints.MOBILE)]: {
      display: 'block',
    },
  },
  emptyImage: {
    width: '100%',
    height: 'auto',
  },
  contentContainer: {
    position: 'relative',
    flex: 1,
  },
  overlay: {
    position: 'absolute',
    left: 0,
    right: 0,
    bottom: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    zIndex: 5,
    display: 'none',
    [theme.breakpoints.up(breakpoints.MOBILE)]: {
      display: 'block',
    },
  },
  pageWrapper: {
    position: 'absolute',
    left: 0,
    right: 0,
    bottom: 0,
    width: '100vw',
    height: '100vh',
  },
  switchViewButton: {
    position: 'fixed',
    zIndex: 2,
    bottom: 20,
    width: 150,
    margin: 'auto',
    right: 0,
    left: 0,
    [theme.breakpoints.up(breakpoints.MOBILE)]: {
      display: 'none',
    },
  },
  upcomingTab: {
    top: 291,
    bottom: 'auto',
  },
});

export default withStyles(style)(ReserveOfficePage);
