import Lodash from 'lodash';

import DayPicker from '../Common/DayPicker';
import { date as DateUtils } from '../../Core/Utils';

const DayPickerWrapper = objProps => {
  const type = Lodash.get(objProps, ['type'], 'day');
  const value = Lodash.get(objProps, ['field', 'value'], null);
  const onChange = Lodash.get(objProps, ['onChange'], () => null);
  const handleOnFocus = Lodash.get(objProps, ['onFocus'], () => null);
  const handleOnBlur = Lodash.get(objProps, ['onBlur'], () => null);

  const handleIsFocus = isFocus => {
    if (isFocus) {
      return handleOnFocus();
    }
    return handleOnBlur();
  };

  // TODO: This should be removed after we refactor dayPicker Component to handle data properly
  const handleOnChange = objMomentDate => {
    return onChange(DateUtils.getUnixStartOfDay(objMomentDate.unix()));
  };

  return (
    <DayPicker
      {...objProps.field}
      {...objProps}
      type={type}
      onDateChange={handleOnChange}
      selectedDate={value}
      isFocus={handleIsFocus}
    />
  );
};

export default DayPickerWrapper;
