import { colors } from '../../../Core/Theme';

const styles = theme => ({
  main: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
    width: '100%',
    paddingRight: 0,
    position: 'relative',
  },
  textInputRoot: {
    borderBottom: 'solid 1px black',
    background: colors.white,
  },
  textInputRootBoxed: {
    border: `solid 1px ${colors.middle}`,
    borderRadius: 4,
  },
  textInput: {
    fontFamily: 'VerlagLight',
    fontSize: 18,
    color: colors.black,
    width: '80%',
    paddingBottom: 9,
    paddingTop: 12,
    lineHeight: '20px',
    textOverflow: 'ellipsis',
  },
  boxedTextInput: {
    fontSize: 16,
    color: colors.black,
    width: '80%',
    paddingBottom: 7,
    lineHeight: '16px',
    textOverflow: 'ellipsis',
    paddingLeft: 10,
    fontFamily: 'VerlagBook',
  },
  carat: {
    top: 'calc(50% - 3px)',
    right: 0,
    position: `absolute`,
    width: 15,
    height: 10,
    cursor: 'pointer',
  },
  boxedCarat: {
    top: 'calc(50% - 1px)',
    right: 10,
    width: 13,
    height: 8,
  },
  menuPaper: {
    width: '100%',
    maxHeight: 220,
    zIndex: 2,
    maxWidth: 'calc(100vw - 40px)',
  },
  loader: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '16px 0px',
    background: 'white',
  },
  error: {
    borderColor: colors.alertMessage,
  },
  label: {
    fontFamily: 'VerlagBold',
    fontSize: 12,
    cursor: 'default',
    lineHeight: '16px',
  },
  boxedLabel: {
    paddingBottom: 2,
  },
  labelError: {
    color: colors.alertMessage,
  },
  labelHidden: {
    position: 'absolute !important',
    height: 1,
    width: 1,
    overflow: 'Hidden',
    clip: 'rect(1px, 1px, 1px, 1px)',
    whiteSpace: 'nowrap',
  },
  errorMessage: {
    fontFamily: 'VerlagLight',
    fontSize: 12,
    color: colors.alertMessage,
    paddingTop: 3,
    lineHeight: '16px',
  },
});

export default styles;
