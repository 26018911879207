/* eslint-disable react/no-array-index-key */
import React from 'react';

// Components
import { MapLayerWrapper as Layer } from '../../../Common';

// Constants
import { FEATURES_TYPE } from '../../constants';

// Types
import { LayerProperties } from '../../../Common/MapLayerWrapper/types';
import { Layers } from '../../types';
import { Legend } from '../types';

// Theme & Utils
import { colors } from '../../../../Core/Theme';

const LAYER_ID = Layers.Office;

// Layer properties
const fillProperties: LayerProperties = {
  id: LAYER_ID,
  type: 'fill',
  source: 'features',
  paint: {
    'fill-color': colors.palette.secondary2.dark,
  },
};
const lineProperties: LayerProperties = {
  id: `${LAYER_ID}-lines`,
  type: 'line',
  source: 'features',
  paint: {
    'line-color': colors.palette.secondary2.darkest,
    'line-width': 2,
  },
};

// Legend
const legend: Legend = {
  key: LAYER_ID,
  style: {
    backgroundColor: colors.middle,
    borderColor: colors.darkGray,
    borderWidth: 1,
    borderStyle: 'solid',
    background: `repeating-linear-gradient(45deg, ${colors.middle} 1px, ${colors.darkGray} 5px)`,
  },
  labelKey: 'floorPlan.legend.office',
};

const Office = () => {
  return (
    <Layer
      properties={[fillProperties, lineProperties]}
      filter={['==', 'type', FEATURES_TYPE.OFFICE]}
    />
  );
};

export { legend };
export default React.memo(Office);
