import Lodash from 'lodash';

import { getCommonArea } from '../Utils';
import { FEATURES_TYPE } from '../Constants';
import { OfficeStatuses } from '../../../Models/office';

const ManageWorkspaces = ({ mapboxLookup, showLicensedErrors }) => {
  const getOffices = ({ offices, featuresData, groupingId }) => {
    const objMappedOffice = offices.reduce(
      (objData, objOffice) => {
        const strOfficeId = Lodash.get(objOffice, ['sfId'], null);
        const strGroupingId = Lodash.get(objOffice, ['groupingId'], null);
        const strFeatureID = Lodash.get(
          mapboxLookup,
          [FEATURES_TYPE.OFFICE, `${strOfficeId}`, 'mapboxFeatureID'],
          null
        );

        if (!strFeatureID) {
          return objData;
        }

        if (
          objOffice.status === OfficeStatuses.Licensed &&
          !objOffice.isOasisReservable
        ) {
          return {
            ...objData,
            utilized: [...objData.utilized, strFeatureID],
          };
        }

        if (
          strGroupingId &&
          groupingId !== strGroupingId &&
          objOffice.isOasisReservable
        ) {
          return {
            ...objData,
            unavailable: [...objData.unavailable, strFeatureID],
          };
        }

        if (
          objOffice.status === OfficeStatuses.Licensed &&
          objOffice.isOasisReservable &&
          showLicensedErrors
        ) {
          return {
            ...objData,
            utilizedReservable: [...objData.utilizedReservable, strFeatureID],
          };
        }

        if (objOffice.isFlexOffice) {
          return {
            ...objData,
            flexDesk: [...objData.flexDesk, strFeatureID],
          };
        }

        if (objOffice.isOasisReservable) {
          return {
            ...objData,
            reservable: [...objData.reservable, strFeatureID],
          };
        }

        return {
          ...objData,
          unassigned: [...objData.unassigned, strFeatureID],
        };
      },
      {
        reservable: [],
        unavailable: [],
        flexDesk: [],
        unassigned: [],
        utilized: [],
        utilizedReservable: [],
      }
    );

    const features = featuresData?.features || [];

    const newListOfficeFeatures = features.reduce((acc, f) => {
      const { properties, id } = f;
      if (Lodash.get(properties, 'type') === FEATURES_TYPE.OFFICE) {
        acc.push(id);
      }
      return acc;
    }, []);

    const listUtilized = Lodash.get(objMappedOffice, 'utilized', []);

    return {
      ...objMappedOffice,
      utilized: [
        ...listUtilized,
        ...Lodash.difference(newListOfficeFeatures, [
          ...objMappedOffice.reservable,
          ...objMappedOffice.flexDesk,
          ...objMappedOffice.unassigned,
          ...listUtilized,
        ]),
      ],
    };
  };

  const getDesks = ({ desks }) => {
    return desks.reduce(
      (objData, objDesk) => {
        const strDeskID = Lodash.get(objDesk, ['idDesk'], null);
        const strFeatureID = Lodash.get(
          mapboxLookup,
          [FEATURES_TYPE.DESK, `${strDeskID}`, 'mapboxFeatureID'],
          null
        );
        const strChairFeatureID = Lodash.get(
          mapboxLookup,
          [FEATURES_TYPE.DESK, `${strDeskID}`, 'chair'],
          null
        );

        if (!strFeatureID || !strDeskID) {
          return objData;
        }

        if (objDesk.active) {
          if (objDesk.isBookable) {
            return {
              ...objData,
              active: [...objData.active, strFeatureID, strChairFeatureID],
            };
          }

          return {
            ...objData,
            inactive: [...objData.inactive, strFeatureID, strChairFeatureID],
          };
        }

        return objData;
      },
      {
        inactive: [],
        active: [],
        selected: [],
      }
    );
  };

  const generateLabelsSource = () => {
    const commonArea = getCommonArea(mapboxLookup);
    return {
      commonArea,
    };
  };

  return {
    getOffices,
    getDesks,
    generateLabelsSource,
  };
};

export default ManageWorkspaces;
