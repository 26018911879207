import { useState } from 'react';

import { useTranslation } from 'react-i18next';

import { date as DateUtils } from '../../../Core/Utils';
import {
  fetchOfficeAvailabilities,
  FloorDto,
  GroupingDto,
} from '../../../Core/Api';
import { Floor, SelectOption } from '../../../Models';
import { useFloors } from '../../../Hooks';
import { getIsStandalone } from '../../../Models/groupings';

export const useFloorsWithOfficeAvailabilities = () => {
  const [floorOptions, setFloorOptions] = useState<SelectOption<FloorDto>[]>();
  const [selectedFloor, setSelectedFloor] = useState<FloorDto | null>(null);
  const { t } = useTranslation();
  const { floors } = useFloors();

  const fetchFloorNamesWithReservableOffices = async (
    grouping: GroupingDto,
    epoch: number
  ) => {
    const isStandalone = getIsStandalone(grouping);

    return fetchOfficeAvailabilities({
      groupingId: isStandalone ? grouping.idGrouping : undefined,
      locationSfId: grouping.locationSfId,
      integratedOnly: !isStandalone,
      startEpoch: DateUtils.updateDateForTZ(
        DateUtils.getUnixStartOfDay(epoch),
        grouping.location.timeZoneId,
        DateUtils.getCurrentZone()
      ),
      endEpoch: DateUtils.updateDateForTZ(
        DateUtils.getUnixEndOfDay(epoch),
        grouping.location.timeZoneId,
        DateUtils.getCurrentZone()
      ),
    }).then(response => response.data.map(office => office.floorName));
  };

  const selectFloor = async (grouping: GroupingDto, epoch: number) => {
    const floorNames = await fetchFloorNamesWithReservableOffices(
      grouping,
      epoch
    );

    const options = floors
      .filter(
        floor =>
          grouping.locationSfId === floor.locationSfId &&
          floorNames.includes(floor.floorName)
      )
      .sort(Floor.sortFloors)
      .map(floor => Floor.createFloorOption(floor, t));

    setFloorOptions(options);

    if (!options.length) {
      setSelectedFloor(null);
      return null;
    }

    const defaultSelectedFloor = options[0].value;
    setSelectedFloor(defaultSelectedFloor);

    return defaultSelectedFloor;
  };

  return { floorOptions, selectedFloor, selectFloor, setSelectedFloor };
};
