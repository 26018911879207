import { Route, Redirect } from 'react-router';
import { useSelector } from 'react-redux';

import * as containerTypes from './containerTypes';

import { getUsersDefaultRoute } from '../../Redux/Common/UserManager/selectors';

const publicRoutes = ['/login', '/resetpassword', '/'];

const renderRoutes = (
  containerList,
  subNavOrder,
  subNavList,
  topNavList,
  PrivateRoute,
  ErrorBoundary,
  profile,
  isLoggedIn,
  userGroupings,
  userGroupingsIsLoading
) => {
  const privateRoutes = [];
  const routes = containerList.map(c => {
    const { mainRoute } = c;
    if (mainRoute.private) {
      privateRoutes.push(mainRoute.path);
    }
    return mainRoute.path;
  });
  const defaultRoute = useSelector(getUsersDefaultRoute);

  if (!routes.includes(window.location.pathname)) {
    return (
      <Redirect
        to={{
          pathname: '/login',
          state: {
            to: defaultRoute,
          },
        }}
      />
    );
  }
  if (
    !(
      privateRoutes.includes(window.location.pathname) ||
      window.location.pathname === '/login'
    ) &&
    isLoggedIn
  ) {
    return (
      <Redirect
        to={{
          pathname: defaultRoute,
        }}
      />
    );
  }

  return containerList.reduce((acc, container) => {
    const { mainRoute, RenderProp } = container;
    if (publicRoutes.includes(mainRoute.path)) {
      return acc.concat(
        <ErrorBoundary key={`publicRoute_${mainRoute.type}_${mainRoute.path}`}>
          <Route {...mainRoute} render={props => <RenderProp {...props} />} />
        </ErrorBoundary>
      );
    }
    if (mainRoute.private && mainRoute.type === containerTypes.CONTENT) {
      return acc.concat(
        <ErrorBoundary key={`privateRoute_${mainRoute.type}_${mainRoute.path}`}>
          <PrivateRoute
            {...mainRoute}
            RenderProp={RenderProp}
            subNavOrder={subNavOrder}
            subNavList={subNavList}
            profile={profile}
            userGroupings={userGroupings}
            userGroupingsIsLoading={userGroupingsIsLoading}
          />
        </ErrorBoundary>
      );
    }
    if (mainRoute.private && mainRoute.type === containerTypes.NAV) {
      return acc.concat(
        <ErrorBoundary key={`privateRoute_${mainRoute.type}_${mainRoute.path}`}>
          <PrivateRoute
            {...mainRoute}
            RenderProp={RenderProp}
            subNavOrder={subNavOrder}
            subNavList={subNavList}
            topNavList={topNavList}
            profile={profile}
            userGroupings={userGroupings}
            userGroupingsIsLoading={userGroupingsIsLoading}
          />
        </ErrorBoundary>
      );
    }
    if (mainRoute.private && mainRoute.type === containerTypes.PAGE) {
      return acc.concat(
        <ErrorBoundary key={`privateRoute_${mainRoute.type}_${mainRoute.path}`}>
          <PrivateRoute
            {...mainRoute}
            RenderProp={RenderProp}
            subNavOrder={subNavOrder}
            subNavList={subNavList}
            profile={profile}
            userGroupings={userGroupings}
            userGroupingsIsLoading={userGroupingsIsLoading}
          />
        </ErrorBoundary>
      );
    }
    if (mainRoute.private && mainRoute.type === containerTypes.SUBCONTENT) {
      return acc.concat(
        <ErrorBoundary key={`privateRoute_${mainRoute.type}_${mainRoute.path}`}>
          <PrivateRoute
            {...mainRoute}
            RenderProp={RenderProp}
            subNavOrder={subNavOrder}
            subNavList={subNavList}
            profile={profile}
            userGroupings={userGroupings}
            userGroupingsIsLoading={userGroupingsIsLoading}
          />
        </ErrorBoundary>
      );
    }
    return acc.concat(
      <ErrorBoundary key={`publicRoute_${mainRoute.type}_${mainRoute.path}`}>
        <Route {...mainRoute} render={props => <RenderProp {...props} />} />
      </ErrorBoundary>
    );
  }, []);
};

export default renderRoutes;
