import { LIMIT_RESERVATIONS } from '../constants';
import { BookingDto, BookingsPageDto } from './dto';
import { stringifyUrl, Http } from '../Utils';

export enum ReservationSources {
  MemberPortal = 'Member Portal',
  OnDemand = 'On Demand',
  Breather = 'Breather',
}

export interface CreateBookingDto {
  userSfId: string;
  spaceSfId: string;
  startEpoch: number;
  endEpoch: number;
  title?: string;
  description?: string;
  breatherBookingId?: string;
  withManager?: boolean;
  source?: ReservationSources;
}

export interface UpdateBookingDto {
  spaceSfId: string;
  startEpoch: number;
  endEpoch: number;
  title?: string;
  description?: string;
}

export interface FetchAllBookingsOptions {
  startEpoch: number;
  endEpoch: number;
  isFromRentableSpace?: boolean;
  locationSfId?: string;
  groupingId?: string;
  includeDeleted?: boolean;
}

export interface FetchBookingsPageOptions {
  startEpoch: number;
  endEpoch: number;
  limit?: number;
  offset?: number;
  sortOrder?: 'ASC' | 'DESC';
  includeDeleted?: boolean;
  userSfId?: string;
  accountSfId?: string;
}

interface FetchBookingsOptions
  extends FetchAllBookingsOptions,
    FetchBookingsPageOptions {}

export const createBooking = (booking: CreateBookingDto) => {
  return Http.post<BookingDto>('/bookings', booking);
};

export const deleteBooking = (bookingId: number) => {
  return Http.remove(`/bookings/${bookingId}`);
};

export const updateBooking = (bookingId: number, booking: UpdateBookingDto) => {
  return Http.put<BookingDto>(`/bookings/${bookingId}`, booking);
};

const createFetchAllBookingsQueryParams = (
  options: FetchAllBookingsOptions
) => {
  return {
    end_epoch: options.endEpoch,
    grouping_id: options.groupingId,
    include_deleted: options.includeDeleted || false,
    is_from_rentable_space: options.isFromRentableSpace,
    location_sfid: options.locationSfId,
    start_epoch: options.startEpoch,
  };
};

export const fetchAllBookings = (options: FetchAllBookingsOptions) => {
  const queryParams = createFetchAllBookingsQueryParams(options);
  const url = stringifyUrl('bookings', queryParams);

  return Http.get<BookingDto[]>(url);
};

const createFetchBookingsPageQueryParams = (
  options?: FetchBookingsPageOptions
) => {
  return {
    limit: options?.limit || LIMIT_RESERVATIONS,
    offset: options?.offset,
    sort_order: options?.sortOrder,
    start_epoch: options?.startEpoch,
    end_epoch: options?.endEpoch,
    include_deleted: options?.includeDeleted || false,
    user_sfid: options?.userSfId,
    account_sfid: options?.accountSfId,
  };
};

export const fetchBookingsPage = (options?: FetchBookingsPageOptions) => {
  const queryParams = createFetchBookingsPageQueryParams(options);
  const url = stringifyUrl('bookings/paged', queryParams);

  return Http.get<BookingsPageDto>(url);
};

export const deleteAllBookingsForUserSfId = (userSfId: string) => {
  return Http.remove<number[]>(`/bookings/${userSfId}/delete_all`);
};

export const reassingBookingsForUserSfId = (userSfId: string) => {
  return Http.put<BookingDto[]>(`/bookings/${userSfId}/reassign`);
};

export const getBookingById = (bookingId: string) => {
  return Http.get<BookingDto>(`/bookings/${bookingId}`);
};

export const fetchBookings = (options?: FetchBookingsOptions) => {
  if (options?.groupingId || options?.locationSfId) {
    return fetchAllBookings(options);
  }
  return fetchBookingsPage(options);
};

export const getBookingsByUserSfId = (
  userSfId: string,
  options: FetchBookingsOptions
) => {
  return fetchBookings({ userSfId, ...options });
};

export const fetchBookingRules = () => {
  return Http.get(`/booking_rules`);
};
