import { useTranslation } from 'react-i18next';
import { getGoogleMapsLink } from '../../../Core/Utils';
import InfoBox from '../InfoBox';
import { getIsStandalone } from '../../../Models/groupings';
import { GroupingDto } from '../../../Core/Api/dto/Grouping';

type Props = {
  grouping?: GroupingDto;
  infoBoxClassName?: string;
  profile: { brand: { name: string } };
};

export const InfoBoxLocation = (props: Props) => {
  const { grouping, infoBoxClassName, profile } = props;
  const { t } = useTranslation();

  const handleOnLocationPress = () => {
    if (grouping?.location) {
      window.open(getGoogleMapsLink(grouping?.location, profile.brand?.name));
    }
  };

  const getActionDescriptions = () => {
    if (!grouping) {
      return [];
    }

    const { address, city, state, zipCode } = grouping.location;
    return [
      {
        text: `${address}, ${city}, ${state}, ${zipCode}`,
        onAction: handleOnLocationPress,
      },
    ];
  };

  return (
    <InfoBox
      title={t('general.location')}
      descriptions={[
        grouping?.displayName ??
          (getIsStandalone(grouping)
            ? grouping?.groupingName
            : grouping?.location.externalName),
      ]}
      actionDescriptions={getActionDescriptions()}
      className={infoBoxClassName}
    />
  );
};

InfoBoxLocation.defaultProps = {
  infoBoxClassName: undefined,
  grouping: undefined,
};
