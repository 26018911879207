import { StyleRulesCallback, Theme, withStyles } from '@material-ui/core';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import { breakpoints, colors } from '../../../../Core/Theme';

type Props = {
  classes: ClassNameMap;
  title: string;
};

const TrackingTitle = (props: Props) => {
  const { classes, title } = props;

  return <div className={classes.title}>{title}</div>;
};

const styles: StyleRulesCallback<string> = (theme: Theme) => ({
  title: {
    width: '100%',
    fontFamily: 'VerlagBold',
    lineHeight: 1.13,
    color: colors.black,
    paddingTop: 10,
    border: 'none',
    paddingBottom: 10,
    borderBottom: `1px solid ${colors.black}`,
    fontSize: 16,
    [theme.breakpoints.up(breakpoints.MOBILE)]: {
      paddingBottom: 5,
      borderBottom: `none`,
      fontSize: 14,
    },
  },
});

export default withStyles(styles)(TrackingTitle);
