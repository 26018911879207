import Moment from 'moment';
import Lodash from 'lodash';
import { date as DateUtils } from '../../Core/Utils';

export const getSelectedDaysAvailability = (
  availabilities,
  selectedDate,
  availabilitiesTimeZoneId
) => {
  const daysAvailability = availabilities.find(a => {
    const { startEpoch } = a;
    const adjustedStart = DateUtils.updateDateForTZ(
      startEpoch,
      Moment().tz() || Moment.tz.guess(),
      availabilitiesTimeZoneId
    );
    return Moment.unix(selectedDate).isSame(Moment.unix(adjustedStart), 'day');
  });
  return Lodash.get(daysAvailability, 'totalRemaining', null);
};

export const getNextAvailableDay = (availabilities, selectedDate) => {
  const nextAvailableDay = availabilities.find(a => {
    const { startEpoch } = a;
    const isAvailable = Lodash.get(a, 'totalRemaining', 0);

    return isAvailable && startEpoch > selectedDate;
  });
  const nextAvailableDayEpoch = Lodash.get(nextAvailableDay, 'startEpoch');
  const nextAvailableDayCount = Lodash.get(nextAvailableDay, 'totalRemaining');

  return { nextAvailableDayCount, nextAvailableDayEpoch };
};
