/* eslint-disable react/jsx-filename-extension */

export const EVENT_DRAWER_TYPES = {
  CREATE: 'CREATE',
  EDIT: 'EDIT',
  CONFIRMATION: 'CONF',
  VIEW: 'VIEW',
};

export const MULTICREATE_TYPES = {
  CREATE: 'CREATE',
  EDIT: 'EDIT',
};

export const EVENTS_PATH = '/home/events';

export const EVENT_FETCH_LIMIT_LIST = 20;
export const RSVP_FETCH_LIMIT = 50;

export const MULTICREATE_DRAWER_STATES = {
  INTERSTITIAL: 'INTERSTITIAL',
  FULL: 'FULL',
};

export const DEFAULT_ERROR_MESSAGE =
  'An error occurred and your updates were not saved. Please try again.';

export const LOADING_CONTENT_DESKTOP = (
  <>
    <rect x="20" y="20" rx="4" ry="4" width="760" height="15" />
    <rect x="20" y="108" rx="4" ry="4" width="200" height="200" />
    <rect x="270" y="108" rx="4" ry="4" width="150" height="15" />
    <rect x="470" y="108" rx="4" ry="4" width="180" height="15" />
    <rect x="270" y="152" rx="4" ry="4" width="150" height="15" />
    <rect x="270" y="196" rx="4" ry="4" width="150" height="15" />
    <rect x="20" y="400" rx="4" ry="4" width="calc(100% - 40px)" height="15" />
    <rect x="20" y="488" rx="4" ry="4" width="215" height="215" />
    <rect x="270" y="488" rx="4" ry="4" width="150" height="15" />
    <rect x="470" y="488" rx="4" ry="4" width="180" height="15" />
    <rect x="270" y="532" rx="4" ry="4" width="150" height="15" />
    <rect x="270" y="576" rx="4" ry="4" width="150" height="15" />
    <rect x="20" y="780" rx="4" ry="4" width="calc(100% - 40px)" height="15" />
    <rect x="20" y="868" rx="4" ry="4" width="215" height="215" />
    <rect x="270" y="868" rx="4" ry="4" width="150" height="15" />
    <rect x="470" y="868" rx="4" ry="4" width="180" height="15" />
    <rect x="270" y="912" rx="4" ry="4" width="150" height="15" />
    <rect x="270" y="956" rx="4" ry="4" width="150" height="15" />
  </>
);

export const LOADING_CONTENT_MOBILE = (
  <>
    <rect x="20" y="20" rx="4" ry="4" width="calc(100% - 40px)" height="20" />

    <rect x="20" y="108" rx="4" ry="4" width="calc(100% - 40px)" height="200" />

    <rect x="20" y="340" rx="4" ry="4" width="calc(100% - 40px)" height="15" />
    <rect x="20" y="380" rx="4" ry="4" width="40%" height="15" />
    <rect x="calc(50% + 10px)" y="380" rx="4" ry="4" width="40%" height="15" />
    <rect x="20" y="420" rx="4" ry="4" width="calc(100% - 40px)" height="15" />

    <rect x="20" y="500" rx="4" ry="4" width="calc(100% - 40px)" height="20" />

    <rect x="20" y="588" rx="4" ry="4" width="calc(100% - 40px)" height="200" />

    <rect x="20" y="720" rx="4" ry="4" width="calc(100% - 40px)" height="15" />
    <rect x="20" y="720" rx="4" ry="4" width="40%" height="15" />
    <rect x="calc(50% + 10px)" y="760" rx="4" ry="4" width="40%" height="15" />
    <rect x="20" y="800" rx="4" ry="4" width="calc(100% - 40px)" height="15" />
  </>
);

export const LOADING_BLOCK = (
  <>
    <rect x="0" y="0" rx="1" ry="1" width="100%" height="100%" />
  </>
);

export const TYPES_OF_RECURRENCE = {
  ONCE: 'once',
  DAILY: 'daily',
  WEEKLY: 'weekly',
};

export const DELETION_TYPES = {
  CURRENT: 'current',
  DAY_FUTURE: 'currentDayFuture',
  ALL_FUTURE: 'allFuture',
};
