import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

// Theme
import { colors, breakpoints } from '../../../Core/Theme';
import { Icons } from '../../../resources';

const Visibility = props => {
  const { classes, visible } = props;
  const { t } = useTranslation();
  if (visible) {
    return (
      <div className={classes.main}>
        <img
          src={Icons.Visible}
          alt={t('altTexts.open_eye')}
          className={classes.visibleEye}
        />
        {t('visibility.visible')}
      </div>
    );
  }
  return (
    <div className={classes.main}>
      <img
        src={Icons.NotVisible}
        alt={t('altTexts.hidden_eye')}
        className={classes.hiddenEye}
      />
      {t('visibility.not_visible')}
    </div>
  );
};

const styles = theme => ({
  main: {
    textAlign: 'center',
    marginTop: 4,
    fontFamily: 'VerlagBook',
    fontSize: 14,
    color: colors.darkest,
    display: 'flex',
    justifyContent: 'center',
    whiteSpace: 'nowrap',
    [theme.breakpoints.up(breakpoints.MOBILE)]: {
      fontSize: 16,
    },
  },
  visibleEye: {
    width: 21,
    height: 13,
    paddingRight: 8,
    paddingTop: 2,
    [theme.breakpoints.up(breakpoints.MOBILE)]: {
      paddingTop: 1,
    },
  },
  hiddenEye: {
    width: 20,
    height: 15,
    paddingRight: 8,
    marginTop: -1,
  },
});

Visibility.propTypes = {
  classes: PropTypes.object.isRequired,
  visible: PropTypes.bool.isRequired,
};
export default withStyles(styles)(Visibility);
