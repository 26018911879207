import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Popover from '@material-ui/core/Popover';
import { useTranslation } from 'react-i18next';

import { BaseButton, SubmitButton } from '..';

const ConfirmationPopover = props => {
  const { anchorEl, classes, onCancel, onConfirm, description } = props;
  const { t } = useTranslation();
  return (
    <Popover
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      onClose={onCancel}
      classes={{ paper: classes.paper }}
    >
      <div>
        {description}
        <div className={classes.buttonContainer}>
          <BaseButton
            name={t('general.go_back')}
            className={classes.goBack}
            onClick={onCancel}
          />
          <SubmitButton
            defaultText={t('general.continue')}
            onClick={onConfirm}
          />
        </div>
      </div>
    </Popover>
  );
};

const styles = {
  paper: {
    maxWidth: 'calc(100% - 60px)',
    width: 295,
    fontFamily: 'VerlagLight',
    fontSize: 18,
    padding: 15,
    boxShadow: '0 0 3px 1px rgba(0, 0, 0, 0.25)',
    marginTop: 5,
  },
  buttonContainer: {
    display: 'flex',
  },
  goBack: {
    marginTop: 8,
    width: '100%',
    marginRight: 15,
  },
};

ConfirmationPopover.defaultProps = {};

ConfirmationPopover.propTypes = {
  classes: PropTypes.object.isRequired,
  anchorEl: PropTypes.node.isRequired,
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  description: PropTypes.node.isRequired,
};

export default withStyles(styles)(ConfirmationPopover);
