import { GroupingLocationList } from '../Common';

const GroupingLocationListWrapper = objProps => {
  return (
    <GroupingLocationList
      {...objProps.field}
      {...objProps}
      selectedGrouping={objProps?.field?.value}
    />
  );
};

export default GroupingLocationListWrapper;
