import { Http, stringifyUrl } from '../Utils';
import { OfficeAvailabilityDto, OfficeAvailableCountDto } from './dto';

const basePath = 'office-availabilities';

interface FetchCountAvailableOfficesParams {
  groupingId?: string;
  startEpoch: number;
  endEpoch: number;
  floorId?: string;
  locationSfId?: string;
  integratedOnly?: boolean;
}

export const fetchCountAvailableOffices = (
  params: FetchCountAvailableOfficesParams
) => {
  const queryParams = {
    grouping_id: params.groupingId,
    start_epoch: params.startEpoch,
    end_epoch: params.endEpoch,
    floor_id: params.floorId,
    location_sfid: params.locationSfId,
    integrated_only: params.integratedOnly,
  };
  const url = stringifyUrl(`${basePath}/count`, queryParams);
  return Http.get<OfficeAvailableCountDto>(url);
};

interface FetchOfficeAvailabilitiesParams
  extends FetchCountAvailableOfficesParams {}

export const fetchOfficeAvailabilities = async (
  params: FetchOfficeAvailabilitiesParams
) => {
  const queryParams = {
    grouping_id: params.groupingId,
    start_epoch: params.startEpoch,
    end_epoch: params.endEpoch,
    floor_id: params.floorId,
    location_sfid: params.locationSfId,
    integrated_only: params.integratedOnly,
  };
  const url = stringifyUrl(basePath, queryParams);
  return Http.get<OfficeAvailabilityDto[]>(url);
};
