import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';

import { CommunityManagerShape } from '../Models/CommunityManager';
import { ProfileImage, SpeechBubble } from '../../../Components/Common';
import { trackCommunityManagerEmailClick } from '../../../Core/Tracking/gtag';
import { colors } from '../../../Core/Theme';

const CommunityManager = props => {
  const { classes, communityManager } = props;
  const { t } = useTranslation();

  const onEmailClick = () => {
    trackCommunityManagerEmailClick();
  };

  const renderHello = () => {
    return (
      <div className={classes.helloBar}>
        <div className={classes.profileImageContainer}>
          <ProfileImage
            source={communityManager.imageUrl}
            alternative={t('home.my_profile')}
          />
        </div>
        <div className={classes.helloBarText}>
          <span className={classes.name}>{communityManager.name}</span>
          <a
            className={classes.email}
            onClick={onEmailClick}
            onKeyPress={onEmailClick}
            target="_blank"
            rel="noopener noreferrer"
            href={`mailto:${communityManager.email}`}
            role="button"
          >
            <span className={classes.emailText}>{communityManager.email}</span>
          </a>
        </div>
      </div>
    );
  };

  const renderSpeechBubble = () => {
    return (
      <SpeechBubble zIndex={50}>
        <div className={classes.speechBubbleTitle}>
          {t('home.need_support')}
        </div>
        <div className={classes.speechBubbleText}>{t('home.cm')}</div>
      </SpeechBubble>
    );
  };

  return (
    <div className={classes.contents} datatestid="community_manager_widget">
      {renderHello()}
      <div className={classes.speechBubbleContainer}>
        {renderSpeechBubble()}
      </div>
    </div>
  );
};

const styles = () => ({
  contents: {
    display: 'flex',
    flexDirection: 'column',

    borderRadius: 2,

    backgroundColor: colors.palette.primary.main,
    color: colors.white,

    paddingTop: 20,
    paddingLeft: 20,
    paddingRight: 20,
    paddingBottom: 13,
  },
  helloBar: {
    display: 'flex',
    flexDirection: 'row',
  },
  helloBarText: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: 15,
    overflow: 'hidden',
    whiteSpace: 'nowrap',

    justifyContent: 'center',
  },
  name: {
    display: 'inline-block',
    width: '100%',

    fontFamily: 'VerlagBold',
    fontSize: '18px',

    float: 'left',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  email: {
    textDecoration: 'none',
  },
  emailText: {
    display: 'inline-block',
    width: '100%',

    fontFamily: 'VerlagBook',
    fontSize: '16px',
    color: colors.white,

    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  profileImageContainer: {
    width: 50,
    height: 50,
  },
  speechBubbleContainer: {
    marginTop: 5,
  },
  speechBubbleTitle: {
    fontFamily: 'VerlagBold',
    fontSize: '18px',
    color: colors.black,
  },
  speechBubbleText: {
    marginTop: 3,
    fontFamily: 'VerlagLight',
    fontSize: '16px',
    color: colors.black,
  },
});

CommunityManager.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  communityManager: PropTypes.shape(CommunityManagerShape).isRequired,
};

export default withStyles(styles)(CommunityManager);
