import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';

import { toggleDesks } from '../../../Core/Api';
import FormikInputs from '../../../Components/FormikInputs';
import { colors } from '../../../Core/Theme';
import PopupAlertReservations from '../../ManageOffices/Components/PopupAlertReservations';

const DeskItemToggle = ({
  classes,
  allDesks,
  desk,
  isDisabled,
  handleDeskToggleSuccess,
  handleDeskToggleError,
  deskAvailabilities,
  selectedGrouping,
  locationSfId,
}) => {
  const { t } = useTranslation();

  const [reservationsPopup, setReservationsPopup] = useState(false);
  const [successMsg, setSuccessMsg] = useState(false);
  const [value, setValue] = useState(desk.isBookable);
  const [isUpdating, setIsUpdating] = useState(false);

  const toggleDesk = async () => {
    setValue(!value);
    setIsUpdating(true);

    const desks = allDesks.map(d =>
      d.idDesk === desk.idDesk ? { ...d, isBookable: !value } : d
    );
    await handleDeskToggleSuccess(desks);

    toggleDesks({ desks }).then(objResponse => {
      const { data } = objResponse;
      if (data) {
        setSuccessMsg(true);
        setReservationsPopup(false);
        setIsUpdating(false);
        setTimeout(() => {
          setSuccessMsg(false);
        }, 4000);
      } else {
        handleDeskToggleError(data);
        setValue(value);
        setIsUpdating(false);
      }
    });
  };

  const handleChangeDesk = () => {
    const availabilityDesk = deskAvailabilities.find(
      da => da.deskId === desk.idDesk
    );
    const isReservedAfter =
      availabilityDesk && availabilityDesk.isReservedAfter;

    if (isReservedAfter) {
      setReservationsPopup(true);
    } else if (!isUpdating) {
      toggleDesk();
    }
  };

  useEffect(() => {
    setValue(desk.isBookable);
  }, [desk.isBookable]);

  return (
    <div className={classes.deskItem}>
      {successMsg && (
        <span className={classes.successMsg}>{t('manageOffices.saved')}</span>
      )}
      <FormikInputs
        input="switch"
        name={`${desk.idDesk}`}
        label={`${t('general.desk')} ${desk.deskNumber}`}
        className={classes.switchDeskInput}
        labelClass={classes.switchDeskLabel}
        disabled={isDisabled}
        onChange={handleChangeDesk}
        value={value}
      />
      <PopupAlertReservations
        open={reservationsPopup}
        handleOnClose={() => setReservationsPopup(false)}
        handleOnConfirm={toggleDesk}
        selectedGrouping={selectedGrouping}
        deskNumber={desk.deskNumber}
        locationSfId={locationSfId}
      />
    </div>
  );
};

const styles = () => ({
  successMsg: {
    color: colors.palette.primary.main,
    fontFamily: 'VerlagBold',
    fontSize: 18,
    position: 'absolute',
    top: 'calc(50% - 12px)',
    right: 80,
  },
  deskItem: {
    padding: '16px 12px 20px 20px',
    borderTop: '1px solid #e5e5e5',
    position: 'relative',
  },
  switchDeskLabel: {
    fontFamily: 'VerlagBold',
    fontSize: 20,
  },
  switchDeskInput: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  },
});

DeskItemToggle.defaultProps = {
  selectedGrouping: null,
};

DeskItemToggle.propTypes = {
  classes: PropTypes.object.isRequired,
  allDesks: PropTypes.arrayOf(PropTypes.object).isRequired,
  desk: PropTypes.arrayOf(PropTypes.object).isRequired,
  isDisabled: PropTypes.bool.isRequired,
  handleDeskToggleSuccess: PropTypes.func.isRequired,
  handleDeskToggleError: PropTypes.func.isRequired,
  deskAvailabilities: PropTypes.arrayOf(PropTypes.object).isRequired,
  selectedGrouping: PropTypes.string,
  locationSfId: PropTypes.string.isRequired,
};

export default withStyles(styles)(DeskItemToggle);
