import { StyleRulesCallback, withStyles } from '@material-ui/core';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import clsx from 'clsx';

import { useTranslation } from 'react-i18next';
import { formatCurrency } from '../../../Core/Utils';
import { SkeletonLoader } from '../index';

type Props = {
  classes: ClassNameMap;
  className?: string;
  price: number;
  currencyIsoCode: string;
  isReservingWithDayPasses: boolean;
  isLoading: boolean;
  isManager: boolean;
};

const OfficePrice = (props: Props) => {
  const {
    classes,
    className,
    price,
    currencyIsoCode,
    isReservingWithDayPasses,
    isLoading,
    isManager,
  } = props;
  const { t } = useTranslation();

  const renderPrice = () => {
    if (isManager)
      return `${t('reserveOffice.day_pass')} | ${formatCurrency({
        value: price,
        currency: currencyIsoCode,
      })}`;
    return isReservingWithDayPasses && !isLoading
      ? t('reserveOffice.day_pass')
      : formatCurrency({ value: price, currency: currencyIsoCode });
  };

  return (
    <div className={clsx(classes.default, className)}>
      {isLoading ? (
        <SkeletonLoader className={classes.loader} />
      ) : (
        renderPrice()
      )}
    </div>
  );
};

OfficePrice.defaultProps = {
  className: '',
};

const styles: StyleRulesCallback<string> = () => ({
  default: {
    fontSize: 16,
    fontFamily: 'VerlagBold',
  },
  loader: {
    height: 18,
    width: 80,
  },
});

export default withStyles(styles)(OfficePrice);
