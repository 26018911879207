import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';

import Text from '../../Common/Text';
import styles from './styles';

const PasswordReq = props => {
  const { classes, text } = props;
  const { t } = useTranslation();
  return (
    <div className={classes.message}>
      <Text text={text ?? t('resetPW.password_requirements')} />
    </div>
  );
};

PasswordReq.defaultProps = {
  text: undefined,
};

PasswordReq.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  text: PropTypes.string,
};

export default withStyles(styles)(PasswordReq);
