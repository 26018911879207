import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

const Banner = props => {
  const { classes, backgroundColor, children } = props;
  return (
    <div
      className={classes.root}
      style={{
        backgroundColor,
      }}
    >
      {children}
    </div>
  );
};

const styles = () => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    fontFamily: 'VerlagBook',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
  },
});

Banner.defaultProps = {
  backgroundColor: '#f8f8f8',
};

Banner.propTypes = {
  backgroundColor: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.any]).isRequired,
};

export default withStyles(styles)(Banner);
