import { ACTIONS_COLUMNS } from '../../Components/Common/SmartTable/Constants';

export const getOptions = ({ canCancel, canView, canEdit, t }) => {
  if (!canEdit && !canCancel && !canView) {
    return null;
  }
  if (canView && !canEdit && !canCancel) {
    return [{ label: t('general.view'), type: ACTIONS_COLUMNS.VIEW }];
  }
  const options = [];
  if (canEdit) {
    options.push({
      label: t('general.edit'),
      type: ACTIONS_COLUMNS.EDIT,
    });
  }
  if (canCancel) {
    options.push({
      label: t('general.cancel'),
      type: ACTIONS_COLUMNS.CANCEL,
    });
  }

  return options;
};

export const getColumnList = ({ classes, t }) => {
  return [
    {
      label: t('myReservations.column.date'),
      className: classes.date,
      key: info => {
        return (
          <div className={classes.cell}>
            <div className={classes.cellRow}> {info.data.date.day}</div>
            <div className={classes.cellRow}>{info.data.date.hoursEpoch}</div>
          </div>
        );
      },
    },
    {
      key: 'owner',
      label: t('myReservations.column.owner'),
      className: classes.titleOwner,
    },
    {
      key: 'type',
      label: t('myReservations.column.type'),
      className: classes.titleHead,
    },
    {
      label: t('myReservations.column.location'),
      className: classes.location,
      key: info => {
        return (
          <div className={classes.cell}>
            <div className={classes.cellRow}>
              {info.data.location.floorName}
            </div>
            <div className={classes.cellRow}>{info.data.location.nameRoom}</div>
            <div className={classes.cellRow}>{info.data.location.link}</div>
          </div>
        );
      },
    },

    {
      key: 'hours',
      label: t('myReservations.column.price'),
    },
    {
      key: 'options',
      label: '',
      type: 'options',
      className: classes.options,
      options: data => {
        return getOptions({ ...data, t });
      },
    },
  ];
};
