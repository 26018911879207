import { withStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import get from 'lodash/get';

import { TextButton } from '../../../Components/Common';
import FormikInput from '../../../Components/FormikInputs';
import { getIsManager } from '../../../Core/Utils/userPermissions';

const DeskReservationRow = props => {
  const {
    classes,
    id,
    onRemoveDesk,
    fieldName,
    displayName,
    accountSfId,
    desks,
    floor,
    shouldResetInput,
    setShouldResetInput,
    profile,
    locationsById,
    title,
  } = props;
  const { t } = useTranslation();

  const userMenuItem = {
    sfId: get(profile, 'sfId'),
    name: get(profile, 'name'),
  };
  const owner = get({ desks }, fieldName.split('.'), {});
  const name = get(owner, 'name', '');

  const handleOnRemoveDesk = () => {
    return onRemoveDesk && onRemoveDesk(id);
  };

  const isManager = getIsManager(profile);

  return (
    <div className={classes.main}>
      <span className={classes.deskTitleText}>{title} </span>
      <div className={classes.floorText}>
        <span>{floor}</span>
      </div>
      <div className={classes.deskInfo}>
        <span className={classes.deskNameText}>{displayName}</span>
        <TextButton
          text={t('general.remove')}
          onClick={handleOnRemoveDesk}
          className={classes.removeButton}
        />
      </div>
      <div className={classes.memberInput}>
        <FormikInput
          name={fieldName}
          label={t('reserveDesk.member_label_not_required').toUpperCase()}
          input="memberAutocomplete"
          defaultValue={name}
          variant="boxed"
          id="name"
          accountSfId={accountSfId}
          withAccount={isManager}
          placeholder={t('reserveDesk.member_placeholder')}
          topMenuItems={isManager ? [userMenuItem] : []}
          disabled={false}
          readOnly={false}
          setShouldResetInput={setShouldResetInput}
          shouldResetInput={shouldResetInput}
          locationsById={locationsById}
          withLocation={isManager}
          datatestid="desk_search_name"
          menuClassName={classes.memberMenu}
        />
      </div>
    </div>
  );
};

const styles = () => ({
  main: {
    paddingLeft: 2,
    paddingRight: 2,
    paddingTop: 15,
  },
  memberInput: {
    paddingTop: 10,
    textAlign: 'initial',
  },
  deskInfo: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  floorText: {
    display: 'flex',
    justifyContent: 'space-between',
    fontFamily: 'VerlagBold',
    fontSize: 16,
    paddingTop: 10,
  },
  removeButton: {
    fontFamily: 'VerlagBook',
    fontSize: 12,
  },
  deskNameText: {
    fontFamily: 'VerlagBook',
    fontSize: 16,
  },
  deskTitleText: {
    display: 'flex',
    fontFamily: 'VerlagBold',
    fontSize: 12,
  },
  memberMenu: {
    width: '100%',
  },
});

DeskReservationRow.defaultProps = {
  id: 0,
  accountSfId: 0,
  locationsById: {},
  floor: '',
};

DeskReservationRow.propTypes = {
  onRemoveDesk: PropTypes.func.isRequired,
  id: PropTypes.number,
  fieldName: PropTypes.string.isRequired,
  displayName: PropTypes.string.isRequired,
  accountSfId: PropTypes.string,
  shouldResetInput: PropTypes.bool.isRequired,
  setShouldResetInput: PropTypes.func.isRequired,
  desks: PropTypes.object.isRequired,
  locationsById: PropTypes.object,
  profile: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  floor: PropTypes.string,
};

export default withStyles(styles)(DeskReservationRow);
