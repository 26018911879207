import { useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import RichTextEditor from '../RichTextEditor';
import { colors } from '../../../Core/Theme';
import Text from '../Text';

const RichTextLimited = ({
  value,
  limit,
  classes,
  onChange,
  classNameLabelTitle,
  label,
  prefix,
  placeholder,
  prefixColor,
  isDisabled,
}) => {
  const [charRemaining, setCharRemaining] = useState(limit);
  const { t } = useTranslation();
  const classCharRemaining =
    charRemaining < 0 ? classes.charRemainingOverLimit : classes.charRemaining;

  const handleOnChange = valueState => {
    const currLength = valueState.getCurrentContent().getPlainText().length;
    setCharRemaining(limit - currLength);
    return onChange(valueState);
  };

  return (
    <div>
      <Text
        text={label}
        className={classNameLabelTitle || classes.labelTitle}
      />
      <RichTextEditor
        editorState={value}
        placeholder={placeholder}
        onChange={handleOnChange}
        classNameEditor={classes.inputContent}
        classNameRoot={classes.container}
        readOnlyPrefix={prefix}
        readOnlyPrefixColor={prefixColor}
        isDisabled={isDisabled}
      />
      <Text
        text={t('richTextEditor.characters_remaining', {
          count: charRemaining,
        })}
        className={classCharRemaining}
      />
    </div>
  );
};

const styles = () => ({
  container: {
    borderBottom: 0,
  },
  labelTitle: {
    fontFamily: 'VerlagBold',
    fontSize: '12px',
  },
  inputContent: {
    border: `1px solid ${colors.dark}`,
    borderRadius: '4px',
    minHeight: '110px',
    maxHeight: '110px',
    fontFamily: 'VerlagLight',
    marginTop: '10px',
    padding: '15px',
    fontSize: 18,
    overflowWrap: 'break-word',
    overflowY: 'scroll',
    '&::-webkit-scrollbar': {
      width: 0 /* Remove scrollbar space */,
      background: 'transparent' /* Optional: just make scrollbar invisible */,
    },
    cursor: 'text',
  },
  charRemaining: {
    color: colors.darkGray,
    fontFamily: 'VerlagLight',
    fontSize: '12px',
    display: 'block',
    width: '100%',
    textAlign: 'right',
    marginTop: '3px',
  },
  charRemainingOverLimit: {
    color: colors.palette.error.main,
    fontFamily: 'VerlagLight',
    fontSize: '12px',
    display: 'block',
    width: '100%',
    textAlign: 'right',
    marginTop: '3px',
  },
});

RichTextLimited.defaultProps = {
  value: null,
  onChange: () => null,
  classNameLabelTitle: null,
  label: null,
  prefix: null,
  prefixColor: null,
  placeholder: null,
  isDisabled: null,
};

RichTextLimited.propTypes = {
  value: PropTypes.shape({}),
  limit: PropTypes.number.isRequired,
  onChange: PropTypes.func,
  classNameLabelTitle: PropTypes.string,
  label: PropTypes.string,
  prefix: PropTypes.string,
  prefixColor: PropTypes.string,
  placeholder: PropTypes.string,
  isDisabled: PropTypes.bool,
};

export default withStyles(styles)(RichTextLimited);
