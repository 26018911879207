export const LOAD_GROUPINGS_PENDING = 'LOAD_GROUPINGS_PENDING';
export const LOAD_GROUPINGS_FULFILLED = 'LOAD_GROUPINGS_FULFILLED';
export const LOAD_GROUPINGS_REJECTED = 'LOAD_GROUPINGS_REJECTED';
export const LOAD_GROUPINGS = 'LOAD_GROUPINGS';

export const LOAD_USER_GROUPINGS_PENDING = 'LOAD_USER_GROUPINGS_PENDING';
export const LOAD_USER_GROUPINGS_FULFILLED = 'LOAD_USER_GROUPINGS_FULFILLED';
export const LOAD_USER_GROUPINGS_REJECTED = 'LOAD_USER_GROUPINGS_REJECTED';
export const LOAD_USER_GROUPINGS = 'LOAD_USER_GROUPINGS';

export const UPDATE_GROUPING = 'UPDATE_GROUPING';

export const ADD_GROUPING = 'ADD_GROUPING';

export const DELETE_GROUPING = 'DELETE_GROUPING';
