import { createAction } from 'redux-actions';
import * as types from './actionTypes';
import {
  deleteGrouping as deleteGroupingApi,
  fetchGroupings,
  fetchUserGroupings,
} from '../../../Core/Api';
import { fetchHandlerActions } from '../../../Core/Utils/fetch';

export const addGroupings = createAction(
  types.LOAD_GROUPINGS,
  fetchHandlerActions(fetchGroupings)
);

export const addUserGroupings = createAction(
  types.LOAD_USER_GROUPINGS,
  fetchHandlerActions(fetchUserGroupings)
);

export const updateGrouping = createAction(types.UPDATE_GROUPING);

export const addGrouping = createAction(types.ADD_GROUPING);

export const deleteGrouping = createAction(
  types.DELETE_GROUPING,
  fetchHandlerActions(deleteGroupingApi)
);
