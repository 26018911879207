import PropTypes from 'prop-types';
import MaterialSwitch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { colors } from '../../../Core/Theme';

const Switch = props => {
  const {
    classes,
    withTextNotifier,
    label,
    disabled,
    labelClass,
    onChange,
    value,
  } = props;

  const handleChange = e => {
    if (onChange) {
      return onChange(e.target.checked, e);
    }
    return null;
  };

  return (
    <div className={classes.main}>
      <FormControlLabel
        disabled={disabled}
        control={
          <div className={classes.switchMain}>
            {withTextNotifier && value ? (
              <div className={clsx(disabled && classes.disabledNotifier)}>
                Yes
              </div>
            ) : null}
            {withTextNotifier && !value ? (
              <div className={clsx(disabled && classes.disabledNotifier)}>
                No
              </div>
            ) : null}
            <MaterialSwitch
              checked={value}
              onChange={disabled ? () => {} : handleChange}
              onDrag={disabled ? () => {} : handleChange}
              classes={{
                checked: classes.checked,
                colorSecondary: classes.colorSecondary,
                root: classes.root,
                bar: disabled ? classes.barDisabled : classes.bar,
                icon: disabled ? classes.iconDisabled : classes.icon,
                iconChecked: classes.iconChecked,
                switchBase: disabled
                  ? classes.switchBaseDisabled
                  : classes.switchBase,
              }}
            />
          </div>
        }
        labelPlacement="start"
        label={label}
        classes={{
          root: classes.labelRoot,
          label: clsx(
            classes.label,
            disabled && classes.labelDisabled,
            labelClass
          ),
        }}
      />
    </div>
  );
};

const styles = {
  main: {
    display: 'flex',
  },
  root: {
    height: 20,
    '&:hover $iconChecked': {
      boxShadow: '0 0 0 2px rgba(100, 170, 172, 0.3) !important',
    },
    '&:hover $icon': {
      boxShadow: '0 0 0 2px rgba(238, 238, 238, 0.5)',
    },
  },
  switchBase: {
    height: 20,
  },
  switchBaseDisabled: {
    height: 20,
    cursor: 'default',
  },
  icon: {
    color: colors.white,
    boxShadow: '0 0 2px 0 rgba(0, 0, 0, 0.24)',
    border: 'solid 0.5px rgba(137, 137, 137, 0.54)',
  },
  bar: {
    backgroundColor: colors.dark,
    opacity: 0.54,
  },
  iconDisabled: {
    color: `${colors.lightest} !important`,
  },
  barDisabled: {
    backgroundColor: `${colors.light} !important`,
    opacity: '1 !important',
  },
  checked: {},
  colorSecondary: {
    '&$checked': {
      '& + $bar': {
        opacity: 0.5,
        backgroundColor: colors.palette.primary.main,
      },
    },
  },
  iconChecked: {
    color: colors.palette.primary.main,
    boxShadow: '0 0 2px 0 rgba(0, 0, 0, 0.24)',
    border: 'none',
  },
  labelRoot: {
    width: '100%',
    flexDirection: 'row-reverse',
    justifyContent: 'space-between',
    marginLeft: 0,
  },
  label: {
    fontFamily: 'VerlagBook',
    fontSize: 18,
  },
  labelDisabled: {
    color: `${colors.black} !important`,
  },
  disabledNotifier: {
    color: colors.darkGray,
  },
  switchMain: {
    display: 'flex',
  },
};
Switch.defaultProps = {
  withTextNotifier: false,
  label: '',
  disabled: false,
  labelClass: '',
  value: false,
};

Switch.propTypes = {
  value: PropTypes.bool,
  withTextNotifier: PropTypes.bool,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  labelClass: PropTypes.string,
};

export default withStyles(styles)(Switch);
