/* eslint-disable jsx-a11y/interactive-supports-focus */
import Lodash from 'lodash';
import CloseIcon from '@material-ui/icons/Close';

import { colors } from '../../../Core/Theme';
import SelectAutocomplete from '../SelectAutocomplete';

const customStyles = {
  singleValue: base => ({
    ...base,
    backgroundColor: colors.palette.primary.main,
    fontSize: 15,
    color: colors.white,
    height: 30,
    borderBottomLeftRadius: 4,
    borderTopLeftRadius: 4,
    paddingLeft: 13,
    paddingRight: 9,
    alignItems: 'center',
    position: 'initial',
    marginRight: 0,
    paddingTop: 6,
  }),
};

const singleValueStyles = {
  singleValueRemove: {
    backgroundColor: colors.palette.primary.main,
    fontSize: 15,
    color: colors.white,
    height: 30,
    borderBottomRightRadius: 4,
    borderTopRightRadius: 4,
    borderBottomLeftRadius: 0,
    borderTopLeftRadius: 0,
    cursor: 'pointer',
    paddingLeft: 4,
    paddingRight: 4,
    alignItems: 'center',
    display: 'flex',
    position: 'initial',
    marginLeft: 0,
    marginRight: 0,
    borderLeft: '1px solid white',
    paddingTop: 0,
  },
  container: {
    position: 'absolute',
    display: 'flex',
    top: '50%',
  },
};

const SingleValue = objReactSelectProps => {
  const { getStyles, getValue, selectProps, theme, clearValue } =
    objReactSelectProps;
  const label = Lodash.get(getValue(), [0, 'label'], '');
  const styles = getStyles('singleValue', { ...selectProps, theme });
  const handleOnRemoveValue = () => clearValue();

  return (
    <div
      style={{
        ...singleValueStyles.container,
        maxWidth: styles.maxWidth,
      }}
    >
      <div style={styles}>{label}</div>
      <div
        style={{
          ...styles,
          ...singleValueStyles.singleValueRemove,
        }}
        onClick={handleOnRemoveValue}
        onKeyPress={handleOnRemoveValue}
        role="button"
      >
        <CloseIcon fontSize="small" />
      </div>
    </div>
  );
};

const SelectAutocompleteWithBash = objProps => {
  return (
    <SelectAutocomplete
      {...objProps}
      components={{ SingleValue }}
      styles={customStyles}
    />
  );
};

export default SelectAutocompleteWithBash;
