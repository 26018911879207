import { useEffect, useState } from 'react';

import { fetchGroupings } from '../Core/Api/groupingAPI';
import { GroupingDto } from '../Core/Api/dto/Grouping';
import { getIsStandalone } from '../Models/groupings';

export const useGroupings = (featureKey?: string) => {
  const [groupings, setGroupings] = useState<GroupingDto[]>([]);
  // Display groupings are all standalone and openToCoworking member groupings
  const [displayGroupings, setDisplayGroupings] = useState<GroupingDto[]>([]);

  const [defaultGrouping, setDefaultGrouping] = useState<GroupingDto>();

  const [isLoading, setIsLoading] = useState(true);
  const [hasError, setError] = useState(false);

  const loadGroupings = () => {
    setIsLoading(true);
    fetchGroupings(featureKey).then(objResponse => {
      if (objResponse.data) {
        setGroupings(objResponse.data);
        setDefaultGrouping(objResponse.data.find(g => g.isDefault));
        setDisplayGroupings(
          objResponse.data?.filter(
            g => g.openToCoworkingMembers || getIsStandalone(g)
          )
        );
      } else {
        setError(true);
      }
      setIsLoading(false);
    });
  };
  useEffect(() => {
    loadGroupings();
  }, []);

  return {
    groupings,
    isLoading,
    fetchGroupings,
    hasError,
    defaultGrouping,
    displayGroupings,
  };
};
