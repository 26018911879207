/* eslint-disable no-underscore-dangle */
/* eslint-disable func-names */
import * as React from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';
import clsx from 'clsx';
import * as Yup from 'yup';
import { connect } from 'react-redux';
import queryString from 'query-string';
import { useEffect } from 'react';
import { bindActionCreators } from 'redux';

import {
  Drawer,
  BackButton,
  Text,
  InfoBox,
  SlideAcross,
  SubmitButton,
  BaseButton,
  BannerSlide,
  Snack,
} from '../Common';
import FormikInput from '../FormikInputs';
import { colors, breakpoints } from '../../Core/Theme';
import { TRANSITION_DURATION } from '../Common/SlideConfirmationMessage/constants';

import { getGoogleMapsLink, date as DateUtils } from '../../Core/Utils';
import {
  seatBookingRoleAccess,
  getIsManager,
  locationRoleAccess,
} from '../../Core/Utils/userPermissions';
import {
  updateSeatReservation,
  deleteSeatReservation,
  fetchSeatReservationById,
  fetchSeatReservationAvailability,
} from '../../Core/Api/seatBookings';
import { getIsStandalone } from '../../Models/groupings';
import { getUpdateDtoFromValues } from './utils';
import { setCustomErrorDescription as setCustomErrorDescriptionRedux } from '../../Redux/Common/UserManager/actions';
import {
  trackCancelSeatReservation,
  trackUpdateSeatReservation,
} from '../../Core/Tracking';
import { useIsLocationDateAvailable } from '../../Hooks';

const seatReservationSummarySchema = (
  userSfId,
  location,
  hasSeatLimitReached,
  originalReservation,
  timeZoneId
) =>
  Yup.object().shape({
    date: Yup.number()
      .required('general.required_field')
      .test(
        'is limit reached primary',
        'reservationSeatSummary.limit_error',
        date => {
          const updatedStart = DateUtils.updateDateForTZ(date, timeZoneId);
          const startInZoneOfReservation =
            DateUtils.getMomentFromUnixAndTimeZone(updatedStart, timeZoneId);
          const reservationIsSameDay = startInZoneOfReservation.isSame(
            DateUtils.getMomentFromUnixAndTimeZone(
              originalReservation?.startEpoch,
              timeZoneId
            ),
            'day'
          );

          return !hasSeatLimitReached || reservationIsSameDay;
        }
      ),
    user: Yup.object().nullable().required('general.required_field'),
  });

const SeatReservationSummary = ({
  isOpen,
  onClose,
  classes,
  profile,
  reservation,
  locationsById,
  setSelectedReservation,
  onSeatReservationUpdated,
  history,
  urlPathname,
  setCustomErrorDescription,
}) => {
  const { t } = useTranslation();

  const [hasScrolled, setHasScrolled] = React.useState(false);
  const [isSubmitSuccess, setSubmitSuccess] = React.useState(false);
  const [showCancelConfirmation, setShowCancelConfirmation] =
    React.useState(false);
  const [isUpdateConfirmationVisible, setUpdateConfirmationVisible] =
    React.useState(false);

  const [isSuccessSnackOpen, setSuccessSnackOpen] = React.useState(false);
  const [
    isLoadingSeatReservationAvailability,
    setIsLoadingSeatReservationAvailability,
  ] = React.useState(false);
  const [seatReservationAvailability, setSeatReservationAvailability] =
    React.useState(null);
  const [currentDate, setCurrentDate] = React.useState();

  const windowLocationSearch = window.location.search;
  const params = queryString.parse(windowLocationSearch);
  const urlReservationId = params?.reservationId;

  const fetchSeatReservation = async () => {
    fetchSeatReservationById(urlReservationId).then(objResponse => {
      const urlReservation = objResponse.data;
      if (urlReservation) {
        setSelectedReservation(urlReservation);
        return;
      }
      setCustomErrorDescription(t('openWorkspace.reservation_load_error'));
      history.push({
        pathname: '/home',
      });
    });
  };

  useEffect(() => {
    if (urlReservationId && urlPathname && !reservation) {
      fetchSeatReservation();
    }
  }, [urlReservationId]);

  useEffect(() => {
    setCurrentDate(null);
    if (!urlPathname) {
      return;
    }
    if (isOpen) {
      history.push({
        pathname: urlPathname,
        search: `reservationId=${urlReservationId || reservation?.id}`,
      });
      return;
    }

    history.push({
      pathname: urlPathname,
    });
  }, [isOpen]);

  const grouping = reservation?.grouping ?? {};
  const location = grouping?.location ?? {};
  const externalName = location?.externalName ?? '';
  const address = location?.address ?? '';
  const city = location?.city ?? '';
  const zipCode = location?.zipCode ?? '';
  const state = location?.state ?? '';
  const timeZoneId = location?.timeZoneId;

  const { isLoading: isLoadingBlackoutDates, isBlackoutDate } =
    useIsLocationDateAvailable({
      locationId: isOpen ? location._id : null,
      date: currentDate || reservation?.startEpoch,
      timeZoneId: location.timeZoneId,
    });

  const hasBookingRestrictions = React.useMemo(() => {
    if (!location) return false;
    return locationRoleAccess.hasBookingRestrictions({
      profile,
      obj: location,
    });
  }, [location, profile]);

  const isDateUnavailable = isBlackoutDate && hasBookingRestrictions;

  const fetchReservationsAvailability = (date, validateForm) => {
    setIsLoadingSeatReservationAvailability(true);

    fetchSeatReservationAvailability({
      groupingId: reservation?.groupingId,
      date: DateUtils.getDateInMoment(date).format('YYYY-MM-DD'),
    })
      .then(objResponse => {
        const { data } = objResponse;
        if (data) {
          setSeatReservationAvailability(data);
        }
      })
      .finally(() => {
        setIsLoadingSeatReservationAvailability(false);
        validateForm();
      });
  };

  const onChangeDate = validateForm => date => {
    fetchReservationsAvailability(date, validateForm);
    setCurrentDate(date);
  };

  const canEdit = reservation
    ? seatBookingRoleAccess.update({
        obj: {
          booking: reservation,
          numVisitingMemberBookings:
            seatReservationAvailability?.numVisitingMemberBookings,
        },
        profile,
      })
    : false;

  const canCancel = reservation
    ? seatBookingRoleAccess.delete({
        obj: { booking: reservation },
        profile,
      })
    : false;

  const isManager = getIsManager(profile);

  React.useEffect(() => {
    if (!isOpen) {
      setShowCancelConfirmation(false);
      setUpdateConfirmationVisible(false);
      setSubmitSuccess(false);
    } else if (isOpen) {
      setSuccessSnackOpen(false);
    }
  }, [isOpen]);

  const handleOnLocationPress = () => {
    window.open(getGoogleMapsLink(location, profile?.brand?.name));
  };

  const onCloseCancelConfirmation = () => {
    return setShowCancelConfirmation(false);
  };

  const onOpenCancelConfirmation = () => {
    return setShowCancelConfirmation(true);
  };

  const onCloseUpdateConfirmation = () => {
    return setUpdateConfirmationVisible(false);
  };

  const waitForFakeSuccessState = () => {
    return new Promise(resolve => {
      setTimeout(resolve, 500);
      setSubmitSuccess(true);
    });
  };

  const handleOnCloseDrawer = () => {
    setTimeout(() => {
      setSelectedReservation(null);
    }, 500);
  };

  const handleOnCancelReservation = formikHelpers => {
    const reservationId = reservation?.id;
    formikHelpers.setSubmitting(true);
    return deleteSeatReservation(reservationId)
      .then(() => {
        setSubmitSuccess(true);
        setSuccessSnackOpen(true);
        onSeatReservationUpdated();
        trackCancelSeatReservation();
      })
      .catch(() => {
        formikHelpers.setSubmitting(false);
      })
      .finally(() => {
        formikHelpers.setSubmitting(false);
        handleOnCloseDrawer();
      });
  };

  const handleOnUpdateReservation = async (values, setSubmitting) => {
    const updateDto = getUpdateDtoFromValues(values, reservation);
    const reservationId = reservation?.id;
    const isUserSame = updateDto?.userSfId === reservation?.userSfId;
    const isDateSame = values?.date === reservation?.startEpoch;
    setSubmitting(true);
    setUpdateConfirmationVisible(false);
    if (!isDateSame || !isUserSame) {
      return updateSeatReservation(reservationId, updateDto)
        .then(() => {
          onSeatReservationUpdated();
          setSubmitSuccess(true);
          trackUpdateSeatReservation();
        })
        .catch(() => {
          setSubmitting(false);
        })
        .finally(() => {
          setSubmitting(false);
          handleOnCloseDrawer();
        });
    }
    await waitForFakeSuccessState();
    return handleOnCloseDrawer();
  };

  const handleOnSubmit = async (values, formikHelpers) => {
    const updateDto = getUpdateDtoFromValues(values, reservation);
    if (updateDto?.userSfId !== profile.sfId) {
      return setUpdateConfirmationVisible(true);
    }

    return handleOnUpdateReservation(values, formikHelpers.setSubmitting);
  };

  const renderContent = objForm => {
    const {
      submitForm,
      isSubmitting,
      isValid,
      validateForm,
      setSubmitting,
      values,
    } = objForm;

    return (
      <div className={classes.main}>
        <BackButton onBackPress={onClose} className={classes.spacer} />
        <Text
          text={t('reservationSeatSummary.title')}
          className={clsx(classes.title, classes.spacer)}
        />
        <div
          className={clsx(
            classes.scrollBody,
            hasScrolled && classes.shadowWrapped
          )}
          onScroll={a =>
            setHasScrolled(a.currentTarget && a.currentTarget.scrollTop > 10)
          }
        >
          <InfoBox
            title={t('general.location')}
            descriptions={[
              getIsStandalone(grouping) ? grouping?.groupingName : externalName,
            ]}
            actionDescriptions={
              location
                ? [
                    {
                      text: `${address}, ${city}, ${state}, ${zipCode}`,
                      onAction: handleOnLocationPress,
                    },
                  ]
                : null
            }
            className={clsx(classes.infoBoxTop, classes.spacer)}
          />
          <div className={clsx(classes.dayPickerContainer, classes.spacer)}>
            <span className={classes.date}>
              {t('reservationSeatSummary.date')}
            </span>
            <FormikInput
              input="dayPicker"
              name="date"
              disablePastDate={!isManager}
              disabled={!canEdit}
              error={isDateUnavailable}
              forceShowError={isDateUnavailable}
              onChange={onChangeDate(validateForm)}
              disableWeekends={
                !locationRoleAccess.bookWeekendsAt({
                  profile,
                  obj: location,
                })
              }
            />
            {isDateUnavailable && (
              <p className={classes.blackoutDayMessage}>
                {t('reservationSeatSummary.blackout_date_message')}
              </p>
            )}
          </div>
          <div className={clsx(classes.userContainer, classes.spacer)}>
            <div className={classes.openWorkspace}>
              {t('reservationSeatSummary.openWorkspace')}
            </div>

            <FormikInput
              label={t('reservationSeatSummary.seatFor')}
              name="user"
              input="memberAutocomplete"
              defaultValue={reservation?.user?.name}
              variant="boxed"
              accountSfId={!isManager ? profile?.accountSfId : undefined}
              placeholder={t('openWorkspace.user.placeholder')}
              topMenuItems={isManager ? [profile] : []}
              errorClassName={classes.error}
              disabled={!canEdit}
              readOnly={!canEdit}
              locationsById={locationsById}
              inputClassName={classes.memberInput}
              labelClassName={classes.userInputLabel}
              menuClassName={classes.userMenu}
            />
          </div>

          <div className={classes.footerActions}>
            <Text
              text={t('reservationSeatSummary.disclaimer')}
              className={classes.disclaimer}
            />
            {canEdit || canCancel ? (
              <div className={classes.footer}>
                <BaseButton
                  name={t('reservationSeatSummary.cancel_cta')}
                  onClick={onOpenCancelConfirmation}
                  isLoading={isSubmitting || isLoadingBlackoutDates}
                  className={clsx(
                    classes.cancelButton,
                    !canEdit && classes.fullWidthButton
                  )}
                  disabled={!canCancel || isDateUnavailable}
                />
                {canEdit && (
                  <SubmitButton
                    defaultText={t('reservationSeatSummary.update_cta')}
                    onClick={submitForm}
                    disabled={!canEdit || !isValid || isDateUnavailable}
                    isLoading={
                      isSubmitting ||
                      isLoadingSeatReservationAvailability ||
                      isLoadingBlackoutDates
                    }
                    className={classes.updateButton}
                    isSuccess={isSubmitSuccess}
                  />
                )}
              </div>
            ) : null}
          </div>
        </div>
        <BannerSlide
          isShowing={isUpdateConfirmationVisible}
          labelLeftAction={t('general.no_go_back')}
          labelRightAction={t('general.yes_continue')}
          labelInfo={t('reservationSeatSummary.updatePopup.title')}
          subLabelInfo={t('reservationSeatSummary.updatePopup.description')}
          variant="small"
          onLeftActionPress={onCloseUpdateConfirmation}
          onRightActionPress={() =>
            handleOnUpdateReservation(values, setSubmitting)
          }
          isSuccess={isSubmitSuccess}
          isSubmitting={isSubmitting}
        />
        <BannerSlide
          isShowing={showCancelConfirmation}
          labelLeftAction={t('general.no_go_back')}
          labelRightAction={t('general.yes_cancel')}
          labelInfo={t('reservationSeatSummary.cancel_confirmation')}
          variant="small"
          onLeftActionPress={onCloseCancelConfirmation}
          onRightActionPress={() => handleOnCancelReservation(objForm)}
          isSuccess={isSubmitSuccess}
          isSubmitting={isSubmitting}
        />
      </div>
    );
  };

  const handleOnCloseSuccessSnack = () => {
    return setSuccessSnackOpen(false);
  };

  return (
    <>
      <Drawer
        isOpenDrawer={isOpen}
        className="drawerPaperMediumSmall"
        anchor="right"
        onCloseDrawer={onClose}
      >
        <SlideAcross enterOn duration={TRANSITION_DURATION}>
          <Formik
            initialValues={{
              user: reservation?.user,
              date: reservation?.startEpoch,
            }}
            validationSchema={seatReservationSummarySchema(
              profile.sfId,
              location,
              !isLoadingSeatReservationAvailability
                ? seatReservationAvailability?.hasSeatsLimitReached
                : false,
              reservation,
              timeZoneId
            )}
            validateOnBlur
            validateOnMount
            onSubmit={handleOnSubmit}
            render={renderContent}
          />
        </SlideAcross>
      </Drawer>
      <Snack
        message={t('reservationSeatSummary.cancel_snack')}
        autoClose
        isOpen={isSuccessSnackOpen}
        onClose={handleOnCloseSuccessSnack}
      />
    </>
  );
};

const styles = theme => ({
  main: {
    background: colors.palette.secondary2.main,
    paddingTop: 30,
    height: 'calc(100% - 30px)',
  },
  spacer: {
    marginLeft: 20,
    marginRight: 20,
    [theme.breakpoints.up(breakpoints.MOBILE)]: {
      marginLeft: 40,
      marginRight: 40,
    },
  },
  title: {
    fontSize: 24,
    fontFamily: 'VerlagBold',
    paddingTop: 15,
    display: 'block',
    paddingBottom: 10,
  },
  infoBox: {
    marginTop: 20,
  },
  infoBoxTop: {
    marginTop: 10,
  },
  scrollBody: {
    overflowY: 'scroll',
    paddingBottom: 200,
    height: 'calc(100% - 273px)',
    '&::-webkit-scrollbar': {
      width: 0 /* Remove scrollbar space */,
      background: 'transparent' /* Optional: just make scrollbar invisible */,
    },
    scrollbarWidth: 'none',
    overflowX: 'hidden',
  },
  shadowWrapped: {
    boxShadow: '0 0 4px 0 rgba(0, 0, 0, 0.25)',
    marginLeft: -20,
    paddingLeft: 20,
    paddingRight: 20,
    marginRight: -20,
    [theme.breakpoints.up(breakpoints.MOBILE)]: {
      marginLeft: -40,
      marginRight: -40,
      paddingLeft: 40,
      paddingRight: 40,
    },
  },
  disclaimer: {
    fontSize: 12,
    fontFamily: 'VerlagLight',
    display: 'block',
    paddingTop: 20,
  },
  footerActions: {
    background: colors.palette.secondary2.main,
    position: 'absolute',
    right: 20,
    left: 20,
    bottom: 40,
    [theme.breakpoints.up(breakpoints.MOBILE)]: {
      right: 40,
      left: 40,
    },
  },
  footer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: 20,
  },
  updateButton: {
    width: 'calc(50% - 3px)',
    marginTop: 0,
    letterSpacing: 0,
  },
  dayPickerContainer: {
    marginTop: 15,
    background: colors.white,
    paddingLeft: 10,
    paddingRight: 10,
    paddingBottom: 8,
    borderRadius: 4,
  },
  userContainer: {
    marginTop: 15,
    background: colors.white,
    paddingLeft: 10,
    paddingRight: 10,
    paddingBottom: 8,
    paddingTop: 3,
    borderRadius: 4,
  },
  openWorkspace: {
    fontSize: 15,
    fontFamily: 'VerlagBold',
    textTransform: 'uppercase',
    paddingTop: 3,
    paddingBottom: 8,
  },
  date: {
    fontSize: 12,
    fontFamily: 'VerlagBold',
    textTransform: 'uppercase',
  },
  memberInput: {
    fontSize: 18,
  },
  error: {
    fontSize: 16,
    paddingTop: 4,
  },
  userInputLabel: {
    textTransform: 'uppercase',
    fontFamily: 'VerlagBold',
    fontSize: 12,
    cursor: 'default',
    lineHeight: '16px',
  },
  userMenu: {
    width: '100%',
    [theme.breakpoints.up(breakpoints.MOBILE)]: {
      width: 320,
    },
  },
  cancelButton: {
    width: 'calc(50% - 3px)',
    marginTop: 0,
    letterSpacing: 0,
  },
  fullWidthButton: {
    width: '100%',
  },
  blackoutDayMessage: {
    color: colors.palette.error.main,
  },
});

SeatReservationSummary.defaultProps = {
  reservation: null,
  urlPathname: null,
};

SeatReservationSummary.propTypes = {
  classes: PropTypes.object.isRequired,
  isOpen: PropTypes.bool.isRequired,
  profile: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  reservation: PropTypes.object,
  locationsById: PropTypes.object.isRequired,
  setSelectedReservation: PropTypes.func.isRequired,
  onSeatReservationUpdated: PropTypes.func.isRequired,
  setCustomErrorDescription: PropTypes.func.isRequired,
  urlPathname: PropTypes.string,
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      setCustomErrorDescription: setCustomErrorDescriptionRedux,
    },
    dispatch
  );
};

export default connect(
  null,
  mapDispatchToProps
)(withStyles(styles)(SeatReservationSummary));
