import { StyleRulesCallback, withStyles } from '@material-ui/core';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import clsx from 'clsx';
import { ReactNode } from 'react';

type Props = {
  className?: string;
  classes: ClassNameMap;
  children: ReactNode;
};

const OfficeAmenityList = (props: Props) => {
  const { classes, className, children } = props;

  return <div className={clsx(classes.default, className)}>{children}</div>;
};

const style: StyleRulesCallback<string> = () => ({
  default: {
    display: 'flex',
    gap: '1rem',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
});

OfficeAmenityList.defaultProps = {
  className: '',
};

export default withStyles(style)(OfficeAmenityList);
