/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
//  The appropriate labels are passed but since they are passed via the get label props function the linter doesn't pick it up

/* eslint-disable react/jsx-no-duplicate-props */
// The linter things inputProps and InputProps are the same even though the are different in the mui textfield implementation
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { useState } from 'react';
import Downshift from 'downshift';
import InputAdornment from '@material-ui/core/InputAdornment';
import MenuItem from '@material-ui/core/MenuItem';

import TextField from '@material-ui/core/TextField';
import Lodash from 'lodash';
import ListSubheader from '@material-ui/core/ListSubheader';
import clsx from 'clsx';
import memoizeOne from 'memoize-one';

// Utils
import { useTranslation } from 'react-i18next';
import Carat from '../Carat';
import { colors } from '../../../Core/Theme';
import Menu from '../Menu';
import {
  isMatchingStateAbbreviation,
  isMatchingStateName,
} from '../../../Core/Utils';
import { Icons } from '../../../resources';
import { Tooltip } from '../index';

const handleKeyDown =
  (
    closeMenu,
    optionGroups,
    alphabetizedGroupIds,
    handleChange,
    highlightedIndex,
    setInputValue
  ) =>
  e => {
    const flattened = alphabetizedGroupIds.reduce((acc, id) => {
      return acc.concat(optionGroups[id]);
    }, []);
    if (e.key === 'Enter') {
      const option = flattened[highlightedIndex];
      if (option) {
        handleChange(option.value);
        closeMenu();
        setInputValue(option.label);
      }
    }
  };

const getSuggestions = memoizeOne((inputValue = '', options) => {
  const inputs = inputValue.split(' ');
  const matches = options.filter(o => {
    const location = o.value.sfId ? o.value : o.value.location;
    const labelWords = o.label.split(' ');
    const descriptionWordsAddress = location.address
      ? location.address.split(' ')
      : [];
    const descriptionWordsCity = location.city ? location.city.split(' ') : [];
    const descriptionWordsZipCode = location.zipCode
      ? location.zipCode.split(' ')
      : [];
    const groupLabelWords = o.groupLabel.split(' ');
    const doesMatchInputPart = inputs.some(input => {
      return (
        labelWords.some(w => w.toLowerCase().startsWith(input.toLowerCase())) ||
        groupLabelWords.some(w =>
          w.toLowerCase().startsWith(input.toLowerCase())
        ) ||
        descriptionWordsCity.some(w =>
          w.toLowerCase().startsWith(input.toLowerCase())
        ) ||
        descriptionWordsAddress.some(w =>
          w.toLowerCase().startsWith(input.toLowerCase())
        ) ||
        isMatchingStateAbbreviation(input, location) ||
        descriptionWordsZipCode.some(w =>
          w.toLowerCase().startsWith(input.toLowerCase())
        )
      );
    });

    return doesMatchInputPart || isMatchingStateName(inputValue, location);
  });
  return matches;
});

const onType = (clearSelection, setInputValue) => event => {
  if (event.target.value === '') {
    clearSelection();
  }
  setInputValue(event.target.value);
};

const getOptionGroups = memoizeOne(options => {
  const groupedOptions = options.reduce((acc, o) => {
    if (acc[o.groupId]) {
      acc[o.groupId].push(o);
    } else {
      acc[o.groupId] = [o];
    }
    return acc;
  }, {});
  // Sort the options for each group

  const optionsToReturn = {};
  Object.keys(groupedOptions).forEach(k => {
    const optionList = groupedOptions[k];
    optionsToReturn[k] = Lodash.sortBy(optionList, 'label');
  });
  return optionsToReturn;
});

const getAlphabetizedGroupIds = memoizeOne(optionGroups => {
  const groupKeys = Object.keys(optionGroups);

  return groupKeys.sort((a, b) => {
    const nameA = Lodash.get(optionGroups, [a, 0, 'groupLabel'], '');
    const aIsFirst = Lodash.get(optionGroups, [a, 0, 'isFirst'], false);

    const nameB = Lodash.get(optionGroups, [b, 0, 'groupLabel'], '');
    const bIsFirst = Lodash.get(optionGroups, [b, 0, 'isFirst'], false);

    if (aIsFirst) {
      return -1;
    }
    if (bIsFirst) {
      return 1;
    }
    return nameA > nameB ? 1 : -1;
  });
});

const SelectWithGroup = props => {
  const {
    classes,
    options,
    onChange,
    label,
    className,
    datatestid,
    onFocus,
    onBlur,
    value,
    renderValue,
    disabled,
    classNameMain,
    classNameTextInput,
    classNameCarat,
    readOnly,
    isLabelHidden,
    alwaysShowCarat,
    placeholder,
    errorMessage,
    hasError,
    errorClassName,
    isLoading,
    loadingDisplayValue,
    disabledTooltipText,
  } = props;

  const [inputValue, setInputValue] = useState('');
  const [isFocused, setFocused] = useState(false);
  const { t } = useTranslation();
  const withMenu = options.length > 1;

  const handleFocus = openMenu => () => {
    openMenu();
    onFocus();
    setInputValue('');
    setFocused(true);
  };
  const handleBlur = closeMenu => () => {
    onBlur();
    closeMenu();
    setInputValue('');
    setFocused(false);
  };

  const matchedOptions = inputValue
    ? getSuggestions(inputValue, options)
    : options;
  const optionGroups = getOptionGroups(matchedOptions);
  const alphabetizedGroupIds = getAlphabetizedGroupIds(optionGroups);

  return (
    <div className={className}>
      <Downshift id="downshift-location-select">
        {({
          clearSelection,
          getInputProps,
          getMenuProps,
          isOpen,
          openMenu,
          closeMenu,
          getLabelProps,
          getItemProps,
          highlightedIndex,
        }) => {
          const handleWrapped = o => () => {
            onChange(o.value);
            setInputValue('');
            setFocused(false);
          };
          const getValue = () => {
            if (!readOnly && isFocused && isOpen) {
              return inputValue;
            }
            if (isLoading && loadingDisplayValue && !value) {
              return loadingDisplayValue;
            }
            return renderValue(value);
          };

          let index = -1;

          const menuItems = alphabetizedGroupIds.map(groupId => {
            const group = optionGroups[groupId];
            const groupName = Lodash.get(
              optionGroups,
              [groupId, 0, 'groupLabel'],
              ''
            );

            return (
              <div className={classes.stickyGroup} key={groupId}>
                <ListSubheader className={classes.groupHeader}>
                  {groupName.toUpperCase()}
                </ListSubheader>
                {group.map(o => {
                  index++;
                  const renderMenuItem = () => (
                    <div>
                      <MenuItem
                        className={clsx(
                          classes.menuItem,
                          o.isDisabled && classes.disabledMenuItem
                        )}
                        {...getItemProps({
                          onClick: handleWrapped(o),
                          index,
                          key: o.key,
                          item: {
                            name: o.label,
                            id: o.value,
                          },
                          disabled: o.isDisabled,
                        })}
                        style={{
                          backgroundColor:
                            index === highlightedIndex
                              ? colors.palette.secondary2.main
                              : colors.white,
                        }}
                      >
                        <div
                          style={{
                            textOverflow: 'ellipsis',
                            overflow: 'hidden',
                            paddingTop: 5,
                          }}
                          datatestid={`${datatestid}_item`}
                        >
                          <div
                            className={classes.menuItemLabel}
                            datatestid={`${datatestid}_item_label`}
                          >
                            {' '}
                            {o.label}
                          </div>
                          <div className={classes.description}>
                            {`${o.description.address}, ${o.description.city}, ${o.description.state} ${o.description.zipCode}`}
                          </div>
                          {o.isDisabled && (
                            <Icons.ClosedLock className={classes.closedLock} />
                          )}
                        </div>
                      </MenuItem>
                    </div>
                  );

                  return o.isDisabled && disabledTooltipText ? (
                    <Tooltip
                      interactive
                      customClasses={{
                        tooltip: classes.reachTeamTooltip,
                      }}
                      title={disabledTooltipText}
                      anchorContent={renderMenuItem}
                      placement="right"
                    />
                  ) : (
                    renderMenuItem()
                  );
                })}
              </div>
            );
          });

          return (
            <div
              className={clsx(
                classes.main,
                disabled && classes.disabledBorder,
                classNameMain
              )}
              aria-haspopup
            >
              <label
                className={clsx(
                  classes.label,
                  (isLabelHidden || !label) && classes.labelHidden,
                  hasError && classes.errorText
                )}
                {...getLabelProps()}
              >
                {label}
              </label>
              <TextField
                onClick={() =>
                  !withMenu || disabled ? null : handleFocus(openMenu)()
                }
                InputProps={{
                  classes: {
                    root: classes.textInputRoot,
                    input: clsx(
                      classes.textInput,
                      disabled && classes.disabledInput,
                      classNameTextInput,
                      hasError && classes.errorInput,
                      hasError && !!value && classes.errorInputNotEmpty
                    ),
                  },
                  readOnly,
                  disableUnderline: true,
                  fullWidth: true,
                  endAdornment: (withMenu || alwaysShowCarat) && (
                    <InputAdornment position="end" id="carat id">
                      <Carat
                        className={clsx(
                          classes.expandMoreIcon,
                          disabled && classes.disabledIcon,
                          classNameCarat
                        )}
                        size="medium"
                        onClick={isOpen ? handleBlur(closeMenu) : openMenu}
                        datatestid={`${datatestid}_carat`}
                      />
                    </InputAdornment>
                  ),
                }}
                disabled={disabled || !withMenu}
                inputProps={{
                  ...getInputProps({
                    onChange: onType(clearSelection, setInputValue),
                    onFocus: handleFocus(openMenu),
                    value: getValue(),
                    datatestid: `${datatestid}_input`,
                    onBlur: handleBlur(closeMenu),
                  }),
                }}
                placeholder={placeholder}
                onKeyDown={handleKeyDown(
                  closeMenu,
                  optionGroups,
                  alphabetizedGroupIds,
                  onChange,
                  highlightedIndex,
                  setInputValue
                )}
              />
              <Menu open={isOpen && withMenu && !readOnly && !isLoading}>
                <div className={classes.menuPaper} {...getMenuProps()}>
                  {menuItems}
                </div>
              </Menu>
              {errorMessage && hasError && (
                <div className={clsx(classes.errorText, errorClassName)}>
                  {t(errorMessage)}
                </div>
              )}
            </div>
          );
        }}
      </Downshift>
    </div>
  );
};

const styles = () => ({
  main: {
    borderBottom: `solid 1px black`,
    position: 'relative',
  },
  disabledBorder: {
    borderBottom: `solid 1px ${colors.gray}`,
  },
  menuPaper: {
    maxHeight: 270,
    width: '100%',
    overflowY: 'scroll',
    background: 'white',
  },
  menuItem: {
    fontSize: 18,
    fontFamily: 'VerlagLight',
    paddingLeft: 30,
    marginBottom: 5,
    paddingRight: 30,
  },
  textInputRoot: {
    width: '100%',
    lineHeight: '24px',
  },
  textInput: {
    fontFamily: 'VerlagBold',
    fontSize: '20px',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    color: 'black',
    paddingTop: 11,
    paddingBottom: 8,
    lineHeight: '20px',
    height: 19,
  },
  disabledInput: {
    color: colors.darkGray,
  },
  label: {
    fontFamily: 'VerlagBold',
    fontSize: 12,
    width: '100%',
    display: 'block',
    paddingBottom: 2,
  },
  menuItemLabel: {
    lineHeight: '70%',
    marginTop: 5,
    paddingTop: 5,
  },
  description: {
    fontFamily: 'VerlagLight',
    fontSize: 14,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  expandMoreIcon: {
    top: 'calc(50% - 1px)',
    right: 0,
    position: `absolute`,
    cursor: 'pointer',
    width: 12,
    height: 8,
    background: 'white',
  },
  disabledIcon: {
    filter: 'opacity(.3)',
  },
  groupHeader: {
    fontFamily: 'VerlagBold',
    fontSize: 12,
    lineHeight: '12px',
    color: 'black',
    paddingLeft: 20,
    paddingTop: 15,
    paddingBottom: 10,
    background: 'white',
  },
  oneOptionName: {
    fontFamily: 'VerlagBold',
    fontSize: '20px',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    width: '100%',
    paddingBottom: '6',
    paddingTop: 7,
    lineHeight: '24px',
    borderBottom: `solid 1px ${colors.black}`,
  },
  stickyGroup: {
    position: 'relative',
  },
  labelHidden: {
    position: 'absolute !important',
    height: 1,
    width: 1,
    overflow: 'Hidden',
    clip: 'rect(1px, 1px, 1px, 1px)',
    whiteSpace: 'nowrap',
  },
  errorInput: {
    borderColor: `${colors.palette.error.main} !important`,
    '&:focus': {
      color: colors.black,
    },
  },
  errorInputNotEmpty: {
    color: `${colors.palette.error.main} !important`,
  },
  errorText: {
    color: colors.palette.error.main,
  },
  disabledMenuItem: {
    opacity: 0.6,
  },
  closedLock: {
    position: 'absolute',
    right: 20,
    top: 10,
    cursor: 'default',
  },
});

SelectWithGroup.defaultProps = {
  className: '',
  datatestid: '',
  onFocus: () => {},
  onBlur: () => {},
  renderValue: a => a,
  disabled: false,
  readOnly: false,
  classNameMain: null,
  classNameTextInput: null,
  classNameCarat: null,
  isLabelHidden: false,
  alwaysShowCarat: false,
  placeholder: null,
  errorMessage: '',
  hasError: false,
  errorClassName: null,
  isLoading: false,
  loadingDisplayValue: '',
  disabledTooltipText: null,
};

SelectWithGroup.propTypes = {
  classes: PropTypes.object.isRequired,
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
  label: PropTypes.string.isRequired,
  className: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  datatestid: PropTypes.string,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  renderValue: PropTypes.func,
  value: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  classNameMain: PropTypes.string,
  classNameTextInput: PropTypes.string,
  classNameCarat: PropTypes.string,
  isLabelHidden: PropTypes.bool,
  alwaysShowCarat: PropTypes.bool,
  placeholder: PropTypes.string,
  errorMessage: PropTypes.string,
  hasError: PropTypes.bool,
  errorClassName: PropTypes.string,
  isLoading: PropTypes.bool,
  loadingDisplayValue: PropTypes.string,
  disabledTooltipText: PropTypes.string,
};

export default withStyles(styles)(SelectWithGroup);
