// Layers
import CommonArea from './CommonArea';
import {
  Office,
  OfficeLegend,
  AvailableOffice,
  AvailableOfficeLegend,
  AssignedOffice,
  AssignedOfficeLegend,
  UnavailableOffice,
  UnavailableOfficeLegend,
  UnassignedOffice,
  UnassignedOfficeLegend,
  UtilizedOffice,
  UtilizedOfficeLegend,
  ReservedByTeammateOffices,
  ReservedByTeammateOfficesLegend,
  SelectedOffice,
  SelectedOfficeLegend,
} from './Office';
import Overlay from './Overlay';
import CommonLabels from './CommonLabels';
import { Layers } from '../types';

const Legends: { [key in Layers]?: any } = {
  AssignedOffice: AssignedOfficeLegend,
  Office: OfficeLegend,
  UnassignedOffice: UnassignedOfficeLegend,
  UtilizedOffice: UtilizedOfficeLegend,
  ReservedByTeammateOffices: ReservedByTeammateOfficesLegend,
  SelectedOffice: SelectedOfficeLegend,
  UnavailableOffice: UnavailableOfficeLegend,
  AvailableOffice: AvailableOfficeLegend,
};

export {
  Overlay,
  CommonArea,
  CommonLabels,
  Office,
  UnassignedOffice,
  UtilizedOffice,
  AvailableOffice,
  AssignedOffice,
  UnavailableOffice,
  ReservedByTeammateOffices,
  SelectedOffice,
  Legends,
};
