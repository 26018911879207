import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

import { Avatar } from '../Common';
import ImageInput from '../Common/ImageInput';

const Panel = props => {
  const {
    name,
    classes,
    className,
    toggleModal,
    isModalOpen,
    sfId,
    setDisplayImage,
    image,
    imageSubmit,
    imageTitle,
  } = props;
  return (
    <section className={className}>
      <Avatar
        src={image}
        textDisplay={`${name[0]}`}
        className={classes.avatar}
      />
      <div className={classes.photoInput}>
        <ImageInput
          image={image}
          setDisplayImage={setDisplayImage}
          toggleModal={toggleModal}
          isModalOpen={isModalOpen}
          sfId={sfId}
          textInput
          onSubmit={imageSubmit}
          title={imageTitle}
        />
      </div>
    </section>
  );
};

Panel.defaultProps = {
  image: '',
  name: ' ',
  classes: {},
};

Panel.propTypes = {
  classes: PropTypes.shape({}),
  name: PropTypes.string,
  image: PropTypes.string,
  toggleModal: PropTypes.func.isRequired,
  imageSubmit: PropTypes.func.isRequired,
  setDisplayImage: PropTypes.func.isRequired,
  isModalOpen: PropTypes.bool.isRequired,
  sfId: PropTypes.string.isRequired,
  imageTitle: PropTypes.string.isRequired,
};

const styles = () => ({
  avatar: {
    width: '115px',
    height: '115px',
    fontSize: 40,
    marginTop: 30,
  },
  photoInput: {
    marginTop: 15,
    textAlign: 'center',
  },
});

export default withStyles(styles)(Panel);
