import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

// Styling
import styles from './styles';

const AuthBody = props => {
  const { classes, children, styleName } = props;

  return <article className={classes[styleName]}>{children}</article>;
};

AuthBody.defaultProps = {
  styleName: 'form',
};
AuthBody.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  children: PropTypes.shape({}).isRequired,
  styleName: PropTypes.string,
};

export default withStyles(styles)(AuthBody);
