import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import { breakpoints, colors } from '../../../Core/Theme';

const FadeInModal = props => {
  const { isModalOpen, handleClose, RenderProp, classes } = props;

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={isModalOpen}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
      className={classes.modal}
    >
      <Fade in={isModalOpen}>
        <div className={classes.paper}>
          <RenderProp />
        </div>
      </Fade>
    </Modal>
  );
};

FadeInModal.defaultProps = {
  classes: {},
};

FadeInModal.propTypes = {
  isModalOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  RenderProp: PropTypes.shape({}).isRequired,
  classes: PropTypes.shape({}),
};

const styles = theme => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: colors.palette.background.paper,
    boxShadow: '0 0 4px 0 rgba(0, 0, 0, 0.5)',
    [theme.breakpoints.down(breakpoints.MOBILE)]: {
      height: '100%',
      width: '100%',
      borderRadius: 0,
    },
    '&:focus': {
      outline: 'none',
    },
    borderRadius: 3,
  },
});

export default withStyles(styles)(FadeInModal);
