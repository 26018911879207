import PropTypes from 'prop-types';
// http://rmosolgo.github.io/prop-types/#%7B%22json%22%3A%22%7B%5Cn%20%20%5C%22name%5C%22%3A%20%5C%22Bluth%20Family%5C%22%2C%5Cn%20%20%5C%22parents%5C%22%3A%20%5B%5Cn%20%20%20%20%7B%5Cn%20%20%20%20%20%20%5C%22name%5C%22%3A%20%5C%22George%5C%22%2C%5Cn%20%20%20%20%20%20%5C%22age%5C%22%3A%2072%5Cn%20%20%20%20%7D%2C%5Cn%20%20%20%20%7B%5Cn%20%20%20%20%20%20%5C%22name%5C%22%3A%20%5C%22Lucille%5C%22%2C%5Cn%20%20%20%20%20%20%5C%22age%5C%22%3A%2068%5Cn%20%20%20%20%7D%5Cn%20%20%5D%2C%5Cn%20%20%5C%22children%5C%22%3A%20%5B%5Cn%20%20%20%20%7B%5Cn%20%20%20%20%20%20%5C%22name%5C%22%3A%20%5C%22Michael%5C%22%2C%5Cn%20%20%20%20%20%20%5C%22age%5C%22%3A%2045%5Cn%20%20%20%20%7D%2C%5Cn%20%20%20%20%7B%5Cn%20%20%20%20%20%20%5C%22name%5C%22%3A%20%5C%22GOB%5C%22%2C%5Cn%20%20%20%20%20%20%5C%22age%5C%22%3A%2041%5Cn%20%20%20%20%7D%2C%5Cn%20%20%20%20%7B%5Cn%20%20%20%20%20%20%5C%22name%5C%22%3A%20%5C%22Lindsay%5C%22%2C%5Cn%20%20%20%20%20%20%5C%22age%5C%22%3A%2037%5Cn%20%20%20%20%7D%5Cn%20%20%5D%5Cn%7D%22%2C%22function_wrapper%22%3Afalse%2C%22destructure%22%3Afalse%2C%22semicolons%22%3Afalse%2C%22alphabetize%22%3Atrue%2C%22dangle_commas%22%3Afalse%2C%22required%22%3Atrue%7D

const topNavList = PropTypes.arrayOf(
  PropTypes.shape({
    path: PropTypes.string,
    exact: PropTypes.bool,
    name: PropTypes.string,
    private: PropTypes.bool,
    type: PropTypes.string,
  })
);

const subNavList = PropTypes.shape({
  path: PropTypes.string,
  exact: PropTypes.bool,
  name: PropTypes.string,
});

const room = PropTypes.shape({
  amens: PropTypes.arrayOf(PropTypes.string),
  credits: PropTypes.number,
  id: PropTypes.string,
  name: PropTypes.string,
  seats: PropTypes.number,
});

const history = PropTypes.shape({
  action: PropTypes.string,
  block: PropTypes.func,
  createHref: PropTypes.func,
  go: PropTypes.func,
  goBack: PropTypes.func,
  goForward: PropTypes.func,
  length: PropTypes.number,
  listen: PropTypes.func,
  location: PropTypes.object,
  push: PropTypes.func,
  replace: PropTypes.func,
});

const profile = PropTypes.shape({
  IdUser: PropTypes.number,
  account: PropTypes.shape({
    IdSfAccount: PropTypes.number,
    description: PropTypes.string,
    industry: PropTypes.any,
    isDeleted: PropTypes.bool,
    location: PropTypes.shape({
      IdLocation: PropTypes.number,
      address: PropTypes.string,
      address2: PropTypes.string,
      calendlyLink: PropTypes.string,
      city: PropTypes.string,
      county: PropTypes.string,
      dedicatedParkingSpotsTotal: PropTypes.any,
      externalName: PropTypes.string,
      isDeleted: PropTypes.bool,
      landlord: PropTypes.string,
      licensor: PropTypes.string,
      locationSummary: PropTypes.string,
      name: PropTypes.string,
      parentLocation: PropTypes.any,
      phoneNumber: PropTypes.any,
      seatsOpen: PropTypes.number,
      seatsTotal: PropTypes.number,
      sfId: PropTypes.string,
      state: PropTypes.string,
      timeZone: PropTypes.string,
      zipCode: PropTypes.string,
    }),
    locationSfId: PropTypes.string,
    name: PropTypes.string,
    ownerSfId: PropTypes.string,
    parentAccountSfId: PropTypes.any,
    phoneNumber: PropTypes.string,
    photoUrl: PropTypes.string,
    sfId: PropTypes.string,
    websiteUrl: PropTypes.string,
  }),
  accountSfId: PropTypes.string,
  email: PropTypes.string,
  firstName: PropTypes.string,
  isDeleted: PropTypes.bool,
  lastName: PropTypes.string,
  name: PropTypes.string,
  sfId: PropTypes.string,
});

const meetingSummary = PropTypes.shape({
  date: PropTypes.string,
  time: PropTypes.shape({
    initialTime: PropTypes.string,
    endTime: PropTypes.string,
  }),
  hours: PropTypes.string,
});

const customProps = {
  topNavList,
  subNavList,
  room,
  history,
  profile,
  meetingSummary,
};

export default customProps;
