import axios from 'axios';
import queryString from 'query-string';
import Lodash from 'lodash';

export const createSeatBookingApi = data => {
  return axios.post(`/seat_bookings`, data);
};

export const fetchSeatReservations = objParams => {
  const endEpoch = Lodash.get(objParams, ['endEpoch'], null);
  const startEpoch = Lodash.get(objParams, ['startEpoch'], null);
  const limit = Lodash.get(objParams, ['limit'], null);
  const offset = Lodash.get(objParams, ['offset'], null);
  const userSfId = Lodash.get(objParams, ['userSfId'], null);
  const accountSfId = Lodash.get(objParams, ['accountSfId'], null);
  const includeDeleted = Lodash.get(objParams, ['includeDeleted'], false);

  const objSchema = {
    end_epoch: endEpoch,
    start_epoch: startEpoch,
    limit,
    offset,
    user_sfid: userSfId,
    account_sfid: accountSfId,
    include_deleted: includeDeleted,
    reservation_types: ['seatbooking'],
  };
  const strEndpoint = 'any-reservations/paged';
  const strQueryUrl = queryString.stringifyUrl(
    {
      url: strEndpoint,
      query: objSchema,
    },
    {
      skipNull: true,
    }
  );
  return axios.get(strQueryUrl);
};

export const fetchSeatReservationById = reservationId => {
  return axios.get(`/seat_bookings/${reservationId}`);
};

export const updateSeatReservation = (reservationId, data) => {
  return axios.put(`/seat_bookings/${reservationId}`, { ...data });
};

export const deleteSeatReservation = reservationId => {
  return axios.delete(`/seat_bookings/${reservationId}`);
};

export const fetchSeatReservationAvailability = objParams => {
  const groupingId = Lodash.get(objParams, ['groupingId'], null);
  const date = Lodash.get(objParams, ['date'], null);

  const objSchema = {
    grouping_id: groupingId,
    date,
  };
  const strEndpoint = 'seat_bookings/availability';
  const strQueryUrl = queryString.stringifyUrl(
    {
      url: strEndpoint,
      query: objSchema,
    },
    {
      skipNull: true,
    }
  );
  return axios.get(strQueryUrl);
};

export const fetchSeatUsages = objParams => {
  const objSchema = {
    account_sfid: objParams.accountSfId,
    date: objParams.date,
  };
  const strEndpoint = 'seat_bookings/usages';
  const strQueryUrl = queryString.stringifyUrl(
    {
      url: strEndpoint,
      query: objSchema,
    },
    {
      skipNull: true,
    }
  );
  return axios.get(strQueryUrl);
};
