import { withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';

import { INTERVAL_HEIGHT, TIME_LABEL_WIDTH } from '../constants';
import { colors } from '../../../Core/Theme';
import { date as DateUtils } from '../../../Core/Utils';

const TimeLabel = ({ classes, timeIntervals, interval, timeZoneId }) => {
  let format = '[h a]';
  if (interval < 60 * 60) {
    format = '[h:mm a]';
  }
  const content = timeIntervals.map((i, idx) => {
    return (
      <div className={classes.timeLabelSegment} key={i}>
        {idx === 0
          ? null
          : DateUtils.getDateInMomentWithZone(i, timeZoneId).format(format)}
      </div>
    );
  });
  return <div className={classes.timeLabel}>{content}</div>;
};

const styles = {
  timeLabel: {
    height: '100%',
    width: TIME_LABEL_WIDTH,
    marginTop: -9,
  },
  timeLabelSegment: {
    height: INTERVAL_HEIGHT,
    color: colors.black,
    fontFamily: 'VerlagLight',
    fontSize: '15px',
    width: TIME_LABEL_WIDTH,
  },
};

TimeLabel.propTypes = {
  classes: PropTypes.object.isRequired,
  timeIntervals: PropTypes.arrayOf(PropTypes.object).isRequired,
  timeZoneId: PropTypes.string.isRequired,
  interval: PropTypes.number.isRequired,
};
export default withStyles(styles)(TimeLabel);
