import { createSelector } from 'reselect';
import Lodash from 'lodash';
import { validSeats } from '../../../Containers/RoomBooking/calUtils/validator';
import { getSelectedGroupingId } from '../../../Containers/RoomBooking/Redux/selectors';

export const getRoomsReducer = state => Lodash.get(state, 'rooms', {});
export const getLoading = state => Lodash.get(state, ['rooms', 'loading'], '');

const getAmens = state => Lodash.get(state, ['calendar', 'amenFilters'], []);
const getFloors = state => Lodash.get(state, ['calendar', 'floorsFilters'], []);
const getSeats = state => Lodash.get(state, ['calendar', 'seatFilters'], []);
const getCurrentLocationSfId = state =>
  Lodash.get(state, ['calendar', 'selectedLocation', 'sfId'], '');

const getCurrentLocationSfIdEvents = state =>
  state.events.selectedGrouping?.locationSfId ?? '';

export const getRoomsById = createSelector(getRoomsReducer, rooms => {
  return Lodash.get(rooms, ['roomData', 'byId'], {});
});
const getRoomsAllIds = createSelector(getRoomsReducer, rooms => {
  return Lodash.get(rooms, ['roomData', 'allIds'], []);
});

export const getBookingRulesById = createSelector(getRoomsReducer, rooms => {
  return Lodash.get(rooms, ['bookingRules', 'byId'], {});
});

export const getBookingRulesAllIds = createSelector(getRoomsReducer, rooms => {
  return Lodash.get(rooms, ['bookingRules', 'allIds'], []);
});

export const getBookingRulesLoading = createSelector(getRoomsReducer, rooms => {
  return Lodash.get(rooms, ['bookingRulesLoading'], false);
});

const getSpaceTypesById = createSelector(getRoomsReducer, rooms => {
  return Lodash.get(rooms, ['spaceTypes', 'byId'], {});
});
export const getFloorsById = createSelector(getRoomsReducer, rooms => {
  return Lodash.get(rooms, ['floors', 'byId'], {});
});
const getFloorsAllIds = createSelector(getRoomsReducer, rooms => {
  return Lodash.get(rooms, ['floors', 'allIds'], []);
});

const getAmensById = createSelector(getRoomsReducer, rooms => {
  return Lodash.get(rooms, ['amenities', 'byId'], {});
});

export const getAllRooms = createSelector(
  [
    getRoomsById,
    getRoomsAllIds,
    getBookingRulesById,
    getSpaceTypesById,
    getAmensById,
  ],
  (roomsById, roomsAllIds, bookingRulesById, spaceTypesById, amensById) => {
    const completedRooms = roomsAllIds.map(id => {
      const room = Lodash.get(roomsById, id, {});
      const { bookingRule, spaceType, amenities } = room;
      const bookingRuleComplete = Lodash.get(
        bookingRulesById,
        bookingRule,
        null
      );
      const spaceTypeComplete = Lodash.get(spaceTypesById, spaceType, null);
      const amenitiesComplete = amenities.map(a => {
        return Lodash.get(amensById, a, null);
      });
      return {
        ...room,
        bookingRule: bookingRuleComplete,
        spaceType: spaceTypeComplete,
        amenities: amenitiesComplete,
      };
    });
    return completedRooms;
  }
);

export const getCalendarRooms = createSelector([getAllRooms], allRooms => {
  const filteredRooms = allRooms.filter(room => {
    return !Lodash.get(room, ['bookingRule', 'rentable'], false);
  });
  const sortedRooms = Lodash.sortBy(filteredRooms, 'name');
  return sortedRooms;
});

const getLocationSfIdFromProps = (state, props) =>
  Lodash.get(props, 'locationSfId', null);

export const getCurrentRoomsCalendar = createSelector(
  [
    getCalendarRooms,
    getAmens,
    getSeats,
    getCurrentLocationSfId,
    getFloors,
    getSelectedGroupingId,
  ],
  (rooms, amenitySfIds, seats, locationSfId, floors, idGrouping) => {
    return rooms.filter(objRoom => {
      const roomsAmenities = Lodash.get(objRoom, ['amenities'], []);
      const strRoomFloorId = Lodash.get(objRoom, ['floor'], null);
      const strLocationSfId = Lodash.get(objRoom, ['locationSfId'], null);
      const strRoomGroupingId = Lodash.get(objRoom, ['groupingId'], null);
      const isBookable = Lodash.get(objRoom, ['bookingRule', 'bookable'], true); // if there is no booking rule assume it should be in the calendar
      return (
        amenitySfIds.reduce((isValid, amenitySfId) => {
          return (
            isValid && !!Lodash.find(roomsAmenities, { sfId: amenitySfId })
          );
        }, true) &&
        validSeats(seats, objRoom) &&
        (floors.reduce((isValid, strFloorId) => {
          return isValid || strRoomFloorId === strFloorId;
        }, false) ||
          floors.length === 0) &&
        strLocationSfId === locationSfId &&
        isBookable &&
        strRoomGroupingId === idGrouping
      );
    });
  }
);

const getGroupingFromProps = (_, props) => props.groupingId;
const getAmenFiltersFromProps = (_, props) => props.amenFilters;
const getSeatFiltersFromProps = (_, props) => props.seatFilters;
const getFloorFiltersFromProps = (_, props) => props.floorFilters;

export const getCurrentRoomsSelectedBookingGrouping = createSelector(
  [
    getCalendarRooms,
    getAmenFiltersFromProps,
    getSeatFiltersFromProps,
    getFloorFiltersFromProps,
    getGroupingFromProps,
  ],
  (rooms, amenitySfIds = [], seats = [], floors = [], idGrouping) => {
    return rooms.filter(objRoom => {
      const roomsAmenities = objRoom?.amenities;
      const strRoomFloorId = objRoom?.floor;
      const strRoomGroupingId = objRoom?.groupingId;
      const isBookable = objRoom?.bookingRule?.bookable ?? true;
      return (
        amenitySfIds.reduce((isValid, amenitySfId) => {
          return isValid && !!roomsAmenities.find(r => r.sfId === amenitySfId);
        }, true) &&
        validSeats(seats, objRoom) &&
        (floors.reduce((isValid, strFloorId) => {
          return isValid || strRoomFloorId === strFloorId;
        }, false) ||
          floors.length === 0) &&
        isBookable &&
        strRoomGroupingId === idGrouping
      );
    });
  }
);

export const getCurrentRoomsCalendarNoGrouping = createSelector(
  [
    getCalendarRooms,
    getAmens,
    getSeats,
    getCurrentLocationSfId,
    getFloors,
    getLocationSfIdFromProps,
  ],
  (
    rooms,
    amenitySfIds,
    seats,
    currentLocationSfId,
    floors,
    locationSfIdFromProps
  ) => {
    return rooms.filter(objRoom => {
      const roomsAmenities = Lodash.get(objRoom, ['amenities'], []);
      const strLocationSfId = Lodash.get(objRoom, ['locationSfId'], null);
      const isBookable = Lodash.get(objRoom, ['bookingRule', 'bookable'], true); // if there is no booking rule assume it should be in the calendar
      const locationSfId = locationSfIdFromProps || currentLocationSfId;
      return (
        amenitySfIds.reduce((isValid, amenitySfId) => {
          return (
            isValid && !!Lodash.find(roomsAmenities, { sfId: amenitySfId })
          );
        }, true) &&
        validSeats(seats, objRoom) &&
        floors.reduce((isValid, floor) => {
          return (
            isValid && !!Lodash.find(objRoom, { floor: { floorName: floor } })
          );
        }, true) &&
        strLocationSfId === locationSfId &&
        isBookable
      );
    });
  }
);

export const getCurrentRoomsEvents = createSelector(
  [getCalendarRooms, getCurrentLocationSfIdEvents],
  (rooms, locationSfId) => {
    return rooms.filter(objRoom => {
      const strLocationSfId = Lodash.get(objRoom, ['locationSfId'], null);
      return strLocationSfId === locationSfId;
    });
  }
);

export const getCurrentRoomsForCalendarLocation = createSelector(
  [getCalendarRooms, getCurrentLocationSfId],
  (rooms, locationSfId) => {
    return rooms.filter(objRoom => {
      const strLocationSfId = Lodash.get(objRoom, ['locationSfId'], null);
      return strLocationSfId === locationSfId;
    });
  }
);

export const getAmenitiesForCurrentLocation = createSelector(
  [getCurrentRoomsForCalendarLocation],
  rooms => {
    const roomsAmenities = rooms.map(objRoom =>
      Lodash.get(objRoom, ['amenities'], [])
    );
    const amenities = Lodash.flatten(roomsAmenities).filter(
      (elem, pos, arr) => {
        return (
          arr.map(a => a.idAmenityType).indexOf(elem.idAmenityType) === pos
        );
      }
    );
    amenities.sort((a, b) => (a.idAmenityType > b.idAmenityType ? 1 : -1));
    return amenities;
  }
);

export const getRoomsForCurrentCalendarLocation = createSelector(
  [getCurrentLocationSfId, getAllRooms],
  (locationSfId, rooms) => {
    return rooms.reduce(
      (acc, room) => {
        if (room.locationSfId === locationSfId) {
          acc.allIds.push(room.sfId);
          acc.byId[room.sfId] = room;
        }
        return acc;
      },
      { allIds: [], byId: {} }
    );
  }
);

const compareFloors = (floorA, floorB) => {
  const nameA = Lodash.get(floorA, 'floorName', '');
  const nameB = Lodash.get(floorB, 'floorName', '');
  if (!nameA || !nameB) {
    return 1;
  }
  const aIsInt = !Number.isNaN(parseInt(nameA, 10));
  const bIsInt = !Number.isNaN(parseInt(nameB, 10));
  if (aIsInt && bIsInt) {
    return nameA * 1 >= nameB * 1 ? 1 : -1;
  }
  if (aIsInt) {
    return -1;
  }
  if (bIsInt) {
    return 1;
  }
  return nameA.toLowerCase() >= nameB.toLowerCase() ? 1 : -1;
};

export const getSortedFloorsForCalendarLocation = createSelector(
  [getFloorsById, getFloorsAllIds, getCurrentLocationSfId],
  (floorsById, floorsAllIds, locationSfId) => {
    const floors = floorsAllIds.reduce((acc, id) => {
      const floor = floorsById[id];
      if (floor.locationSfId === locationSfId) {
        acc.push(floor);
      }
      return acc;
    }, []);
    const sortedFloors = floors.sort(compareFloors);
    return sortedFloors;
  }
);

export const getSortedBookingRules = createSelector(
  [getBookingRulesById, getBookingRulesAllIds],
  (bookingRulesLookup, listBookingRules) => {
    return Lodash.sortBy(listBookingRules, intBookingId => {
      return Lodash.get(
        bookingRulesLookup,
        [intBookingId, 'costPerHour'],
        null
      );
    });
  }
);

const BOOKING_RULE_COST_PER_HOUR_FREE = 0;

export const getBookingRuleFree = createSelector(
  [getBookingRulesById, getBookingRulesAllIds],
  (bookingRulesLookup, listBookingRules) => {
    return Lodash.find(listBookingRules, intBookingRuleId => {
      const objBookingRule = bookingRulesLookup[intBookingRuleId];

      return (
        Lodash.get(objBookingRule, ['costPerHour'], null) ===
        BOOKING_RULE_COST_PER_HOUR_FREE
      );
    });
  }
);
