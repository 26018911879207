import { schema, normalize } from 'normalizr';
import Lodash from 'lodash';

//  constructor
export function NormObjCstr(byId, allIds) {
  this.byId = byId;
  this.allIds = allIds;
}

const groupingSchema = new schema.Entity(
  'groupings',
  {},
  {
    idAttribute: 'idGrouping',
  }
);

const groupingArraySchema = [groupingSchema];

export const normalizeGroupings = data => {
  const temp = Array.isArray(data)
    ? normalize(data, groupingArraySchema)
    : normalize(data, groupingSchema);

  if (Lodash.isEmpty(temp.entities)) {
    return {
      groupings: new NormObjCstr({}, []),
    };
  }
  //  this code is to coerce the type to Number
  return {
    groupings: new NormObjCstr(temp.entities.groupings, temp.result),
  };
};
