import {
  FeatureCollection,
  Geometry,
  GeometryCollection,
  Properties,
} from '@turf/turf';
import { OfficeAvailabilityDto } from '../../../Core/Api';

export enum MapType {
  OfficeReservation = 'OfficeReservation',
  ManageOffices = 'ManageOffices',
}

export type MapData = {
  dataset: FeatureCollection<Geometry | GeometryCollection, Properties>;
  featuresLookup: { [featureId: string]: OfficeAvailabilityDto };
};

export type MapDataset = MapData['dataset'];

export type FeatureCollectionData = MapDataset['features'];

export type FeatureData = FeatureCollectionData[0];

export type LabelIndicators = {
  [featureId: string]: string;
};

export type CommonAreaIndicator = {
  indicators: { [featureId: string]: string };
  commonAreaIds: string[];
};

export type ZoomLevel = {
  INITIAL_ZOOM: number;
  MIN_ZOOM: number;
  MAX_ZOOM: number;
  VISIBLE_ZOOM_LABELS: number;
  DEFAULT_FLY_TO_ZOOM: number;
};

export type ZoomLevels = {
  vector: ZoomLevel;
  raster: ZoomLevel;
};

export type ViewPort = {
  width: string;
  height: string;
  zoom?: number;
};

export enum GeometryTypes {
  Polygon = 'Polygon',
  LineString = 'LineString',
  Point = 'Point',
}

export enum Layers {
  Label = 'Label',
  Overlay = 'Overlay',
  CommonArea = 'CommonArea',

  // Office
  Office = 'Office',
  UtilizedOffice = 'UtilizedOffice',
  UnassignedOffice = 'UnassignedOffice',
  AssignedOffice = 'AssignedOffice',
  AvailableOffice = 'AvailableOffice',
  UnavailableOffice = 'UnavailableOffice',
  SelectedOffice = 'SelectedOffice',
  ReservedByTeammateOffices = 'ReservedByTeammateOffices',

  // Desk
  ReservableDesk = 'ReservableDesk',
  UnreservableDesk = 'UnreservableDesk',
  AvailableDesk = 'AvailableDesk',
  UnavailableDesk = 'UnavailableDesk',
  SelectedDesk = 'SelectedDesk',
  UnselectableDesk = 'UnselectableDesk',
  UserReservedDesk = 'UserReservedDesk',
}
