import { stringifyUrl, Http } from '../Utils';

import {
  AccountDto,
  AccountSettingDto,
  PagedAccountsDto,
  SortOrder,
} from './dto';

export const fetchAccount = (sfId: string) => {
  return Http.get<AccountDto>(`/accounts/${sfId}`);
};

export interface FetchAccountsParams {
  locationSfId?: string;
  groupingId?: string;
  nameContains?: string;
  activeAfterDate?: string;
  sortOrder?: SortOrder;
  limit?: number;
  offset?: number;
}

const createFetchAccountsQueryParams = (params: FetchAccountsParams) => {
  return {
    location_sfid: params.locationSfId,
    active_after_date: params.activeAfterDate,
    grouping_id: params.groupingId,
    name_contains: params.nameContains,
    sort_order: params.sortOrder,
    limit: String(params.limit),
    offset: String(params.offset),
  };
};

export const fetchAccounts = (params: FetchAccountsParams) => {
  const queryParams = createFetchAccountsQueryParams(params);
  const url = stringifyUrl('accounts', queryParams);
  return Http.get<PagedAccountsDto>(url);
};

export const fetchAccountSettings = (sfId: string) => {
  return Http.get<AccountSettingDto[]>(`/account_settings/${sfId}`);
};

export const patchAccountSetting = (patch: AccountSettingDto) => {
  return Http.patch<AccountSettingDto>(`/account_settings/`, patch);
};
