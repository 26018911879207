import { withStyles } from '@material-ui/core/styles';
import Slide from '@material-ui/core/Slide';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import Tooltip from '@material-ui/core/Tooltip';
import { useTranslation } from 'react-i18next';

// Styles
import { colors, breakpoints } from '../../../Core/Theme';

// Constants
import { Icons } from '../../../resources';

// Common Components
import Button from '../BaseButton';
import SubmitButton from '../SubmitButton';

const BannerSlide = props => {
  const {
    classes,
    labelInfo,
    onLeftActionPress,
    onRightActionPress,
    isSubmitting,
    isSuccess,
    isDisabled,
    labelLeftAction,
    labelRightAction,
    slideDisabled,
    classNameBannerSlidedAndContainer,
    classNameBannerSlideParagraphContainer,
    classNameBannerSlideInfoContainer,
    saveButtonOnly,
    isShowing,
    direction,
    classNameSubmitButton,
    timeout,
    classNameButtonArea,
    rootClassName,
    subLabelInfo,
    popoverInfo,
    variant,
  } = props;
  const { t } = useTranslation();
  const isSmall = variant === 'small';
  const getContent = () => (
    <div
      className={clsx(
        classes.confirmationSummaryArea,
        rootClassName,
        isSmall && classes.confirmationSummaryAreaSmall
      )}
    >
      <div
        className={clsx(
          classes.bannerAndSlideContainer,
          labelInfo && classes.withLabelInfoContainer,
          classNameBannerSlidedAndContainer,
          isSmall && classes.bannerAndSlideContainerSmall
        )}
      >
        {labelInfo && (
          <div
            className={clsx(
              classes.bannerSlideInfoContainer,
              classNameBannerSlideInfoContainer
            )}
          >
            <p
              className={clsx(
                classNameBannerSlideParagraphContainer,
                classes.bannerSlideParagraphContainer
              )}
            >
              {labelInfo}
              {popoverInfo ? (
                <Tooltip
                  classes={{
                    tooltip: classes.Tooltip,
                    popper: classes.TooltipPopper,
                  }}
                  title={popoverInfo}
                  placement="right-start"
                  disableTouchListener
                >
                  <span>
                    <img
                      src={Icons.QuestionToolTip}
                      className={classes.helpIcon}
                      alt={t('altTexts.tooltip_info')}
                    />
                  </span>
                </Tooltip>
              ) : null}
              <p>
                {subLabelInfo ? (
                  <b
                    className={clsx(
                      classNameBannerSlideParagraphContainer,
                      classes.bannerSlideParagraphContainer
                    )}
                  >
                    {subLabelInfo}
                  </b>
                ) : null}
              </p>
            </p>
          </div>
        )}
        <div
          className={clsx(
            classes.buttonArea,
            saveButtonOnly && classes.withSaveButtonOnlyContainer,
            labelInfo && classes.withLabelInfoButtonAreaContainer,
            classNameButtonArea,
            isSmall && classes.buttonAreaSmall
          )}
        >
          {saveButtonOnly ? null : (
            <Button
              className={classes.leftActionRootContainer}
              size="large"
              name={labelLeftAction}
              fullWidth
              onClick={onLeftActionPress}
              autoFocus
            />
          )}
          <div
            className={clsx(
              saveButtonOnly && classes.rightActionContainerOneButton,
              classes.rightActionContainer,
              classNameSubmitButton
            )}
          >
            <SubmitButton
              className={clsx(
                classes.rightActionRootContainer,
                classNameSubmitButton
              )}
              defaultText={labelRightAction}
              onClick={onRightActionPress}
              isLoading={isSubmitting}
              isSuccess={isSuccess}
              disabled={isDisabled}
            />
          </div>
        </div>
      </div>
    </div>
  );
  return slideDisabled ? (
    getContent()
  ) : (
    <Slide
      appear
      direction={direction}
      in={isShowing}
      mountOnEnter
      unmountOnExit
      timeout={timeout}
    >
      {getContent()}
    </Slide>
  );
};

const styles = theme => ({
  bannerSlideInfoContainer: {
    fontFamily: 'VerlagLight',
    paddingLeft: 25,
    paddingRight: 25,
    [theme.breakpoints.down(breakpoints.MOBILE)]: {
      width: '100%',
      paddingLeft: 0,
      paddingRight: 0,
      paddingTop: 0,
    },
  },
  bannerAndSlideContainerSmall: {
    flexDirection: 'column !important',
  },
  bannerAndSlideContainer: {
    justifyContent: 'space-around',
    alignItems: 'center',
    flexDirection: 'row',
    [theme.breakpoints.down(breakpoints.MOBILE)]: {
      flexDirection: 'column',
    },
    [theme.breakpoints.up(breakpoints.MOBILE)]: {
      display: 'flex',
    },
    backgroundColor: colors.white,
  },
  withLabelInfoContainer: {
    justifyContent: 'space-between',
  },
  rightActionRootContainer: {
    width: '100%',
    marginTop: 0,
    [theme.breakpoints.up(breakpoints.MOBILE)]: {
      width: '150px',
    },
  },
  leftActionRootContainer: {
    width: '100%',
    marginRight: '10px',
    [theme.breakpoints.up(breakpoints.MOBILE)]: {
      width: '150px',
      marginRight: 25,
    },
  },
  bannerSlideParagraphContainer: {
    fontFamily: 'VerlagLight',
    fontSize: 16,
    padding: '0 0 0 0',
    [theme.breakpoints.down(breakpoints.MOBILE)]: {
      marginTop: 0,
      paddingLeft: 0,
      paddingRight: 0,
      paddingTop: 0,
    },
  },
  rightActionContainer: {
    [theme.breakpoints.down(breakpoints.MOBILE)]: {
      height: '50px',
      width: '100%',
    },
  },
  rightActionContainerOneButton: {
    [theme.breakpoints.up(breakpoints.MOBILE)]: {
      width: '100%',
    },
    [theme.breakpoints.down(breakpoints.MOBILE)]: {
      height: '50px',
      width: '100%',
    },
  },
  withSaveButtonOnlyContainer: {
    flex: 1,
    width: 'auto',
    [theme.breakpoints.up(breakpoints.MOBILE)]: {
      paddingLeft: 25,
      paddingRight: 25,
    },
  },
  withLabelInfoButtonAreaContainer: {
    [theme.breakpoints.up(breakpoints.MOBILE)]: {
      paddingRight: 25,
    },
  },
  buttonArea: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    paddingTop: 15,
    [theme.breakpoints.down(breakpoints.MOBILE)]: {
      paddingTop: 15,
      width: '100%',
    },
  },
  confirmationSummaryArea: {
    position: 'fixed',
    bottom: 20,
    left: 0,
    right: 0,
    justifyContent: 'center',
    boxShadow: `0 -2px 2px 0 rgba(0, 0, 0, 0.25)`,
    background: 'white',
    [theme.breakpoints.down(breakpoints.MOBILE)]: {
      left: 0,
      right: 0,
      bottom: 0,
      padding: 20,
      background: colors.white,
    },
  },
  confirmationSummaryAreaSmall: {
    paddingBottom: 20,
    bottom: 0,
  },
  buttonAreaSmall: {
    paddingRight: 0,
    paddingTop: 0,
  },
  Tooltip: {
    backgroundColor: colors.white,
    width: 270,
    borderRadius: 3,
    color: colors.black,
    boxShadow: '0 0 3px 1px rgba(0, 0, 0, 0.25)',
    fontSize: 12,
    fontFamily: 'VerlagLight',
    border: `solid 1px ${colors.dark}`,
    [theme.breakpoints.up(breakpoints.MOBILE)]: {
      marginTop: -50,
    },
    [theme.breakpoints.down(breakpoints.MOBILE)]: {
      width: '205px',
      marginTop: 5,
    },
  },
  TooltipPopper: {
    opacity: 1,
  },
  helpIcon: {
    fill: colors.black,
    height: '16px',
    width: '16px',
    transform: 'translate(0px, 3px)',
  },
});

BannerSlide.defaultProps = {
  labelInfo: null,
  subLabelInfo: null,
  popoverInfo: null,
  onLeftActionPress: null,
  onRightActionPress: null,
  isSubmitting: false,
  isSuccess: false,
  isDisabled: false,
  labelLeftAction: null,
  labelRightAction: null,
  slideDisabled: false,
  classNameBannerSlidedAndContainer: null,
  classNameBannerSlideParagraphContainer: null,
  classNameBannerSlideInfoContainer: null,
  classNameSubmitButton: null,
  classNameButtonArea: null,
  rootClassName: null,
  saveButtonOnly: false,
  isShowing: false,
  direction: 'up',
  variant: 'default',
};

BannerSlide.propTypes = {
  subLabelInfo: PropTypes.string,
  popoverInfo: PropTypes.string,
  labelInfo: PropTypes.string,
  classNameSubmitButton: PropTypes.string,
  rootClassName: PropTypes.string,
  onLeftActionPress: PropTypes.func,
  onRightActionPress: PropTypes.func,
  isSubmitting: PropTypes.bool,
  isSuccess: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isShowing: PropTypes.bool,
  direction: PropTypes.string,
  labelLeftAction: PropTypes.string,
  labelRightAction: PropTypes.string,
  classNameBannerSlidedAndContainer: PropTypes.string,
  classNameButtonArea: PropTypes.string,
  classNameBannerSlideParagraphContainer: PropTypes.string,
  classNameBannerSlideInfoContainer: PropTypes.string,
  slideDisabled: PropTypes.bool,
  saveButtonOnly: PropTypes.bool,
  timeout: PropTypes.number.isRequired,
  variant: PropTypes.string,
};

export default withStyles(styles)(BannerSlide);
