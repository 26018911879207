import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { PartnershipShape } from '../Models/Partnership';

import { breakpoints, colors } from '../../../Core/Theme';

import Divider from './Divider';
import PerkCard from '../../../Components/PerkCard';

import {
  trackMemberPerksSeeAll,
  trackPartnershipGetStartedClick,
} from '../../../Core/Tracking/gtag';

const Partnerships = props => {
  const { classes, partnerships } = props;
  const { t } = useTranslation();

  const onClick = objPartnership => {
    trackPartnershipGetStartedClick(objPartnership.clickTrackingLabel);
    if (window) {
      window.open(objPartnership.linkUrl);
    } else {
      throw new Error('Unable to open Partnership link, no window found');
    }
  };

  return (
    <div className={classes.contents}>
      <Divider
        text={t('home.partnerships.title')}
        datatestid="members_benefits_title"
      />
      <Grid container spacing={24} className={classes.partnerships}>
        {partnerships.map((objPerk, idx) => {
          return (
            <Grid item xs={12} sm={6} md={3}>
              <PerkCard
                datatestid={`members_benefits_item_${idx}`}
                body={objPerk.body}
                logo={objPerk.logo}
                perkValue={objPerk.value}
                onClick={() => onClick(objPerk)}
              />
            </Grid>
          );
        })}
      </Grid>
      <Link
        to="/home/member-benefits"
        className={classes.link}
        onClick={() => trackMemberPerksSeeAll()}
        datatestid="members_benefits_see_all_button"
      >
        {t('home.partnerships.see_all')}
      </Link>
    </div>
  );
};

const styles = theme => ({
  contents: {
    width: '100%',
  },
  partnerships: {
    marginTop: 30,
    justifyContent: 'center',
  },
  partnershipWrapper: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    flexGrow: 1,
  },
  partnershipWrapperNotLast: {
    [theme.breakpoints.up(breakpoints.MOBILE)]: {
      marginRight: 20,
    },
    [theme.breakpoints.down(breakpoints.MOBILE)]: {
      marginBottom: 20,
    },
  },
  link: {
    marginTop: '25px',
    textDecoration: 'none',
    display: 'block',
    textAlign: 'center',
    fontFamily: 'Verlagbook',
    fontSize: '20px',
    letterSpacing: '0.67px',
    color: colors.textPalette.link.main,
  },
});

Partnerships.defaultProps = {};

Partnerships.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  partnerships: PropTypes.arrayOf(PropTypes.shape(PartnershipShape)).isRequired,
};

export default withStyles(styles)(Partnerships);
