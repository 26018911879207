import { createSelector } from 'reselect';
import Lodash from 'lodash';

import {
  getFloorsAllIds,
  getFloorsById,
} from '../../../Redux/Common/Floors/selectors';

export const getDeskReducer = state => Lodash.get(state, ['reserveDesk'], {});

export const getSelectedLocationSfIdReserveDesk = createSelector(
  getDeskReducer,
  reserveDesk => reserveDesk?.selectedGrouping?.locationSfId
);

export const getSelectedGrouping = createSelector(getDeskReducer, reserveDesk =>
  Lodash.get(reserveDesk, ['selectedGrouping'], {})
);

export const getSelectedGroupingId = createSelector(
  getSelectedGrouping,
  selectedGrouping => Lodash.get(selectedGrouping, ['idGrouping'], '')
);
export const getSelectedDate = createSelector(getDeskReducer, reserveDesk =>
  Lodash.get(reserveDesk, ['selectedDate'], '')
);

export const getSelectedFloor = createSelector(getDeskReducer, reserveDesk =>
  Lodash.get(reserveDesk, ['selectedFloor'], {})
);

export const getFloorsAtLocation = createSelector(
  [getSelectedGrouping, getFloorsAllIds, getFloorsById],
  (grouping, floorsAllIds, floorsById) => {
    const { locationSfId } = grouping;
    return floorsAllIds.reduce((acc, id) => {
      const floor = floorsById[id];
      const { locationSfId: floorLocationSfId } = floor;
      if (locationSfId === floorLocationSfId) {
        acc.push(floor);
      }
      return acc;
    }, []);
  }
);
