import Lodash from 'lodash';

const getRoomId = objData => Lodash.get(objData, ['sfId'], '');
const getSeats = objData => Lodash.get(objData, ['seats'], 0);
const getRoomName = objData => Lodash.get(objData, ['name'], '');
const getAmens = objData => Lodash.get(objData, ['amenities'], []);

export const parseRoomData = objData => {
  const roomId = getRoomId(objData);
  const roomName = getRoomName(objData);
  const amens = getAmens(objData);
  const seats = getSeats(objData);
  return {
    roomId,
    roomName,
    amens,
    seats,
  };
};

export const parseRoomsData = rooms => {
  return rooms.map(room => {
    const roomId = getRoomId(room);
    const roomName = getRoomName(room);
    return {
      value: roomId,
      label: roomName,
    };
  });
};
