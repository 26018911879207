import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Snackbar from '@material-ui/core/Snackbar';
import clsx from 'clsx';
import Fab from '@material-ui/core/Fab';
import { useTranslation } from 'react-i18next';

// Theme
import { colors, breakpoints } from '../../../Core/Theme';
import TextButton from '../TextButton';

import { Icons } from '../../../resources';

const { CloseIcon } = Icons;

const DEFAULT_AUTO_CLOSE_INTERVAL = 8000;

const DEFAULT_RESUME_CLOSE_INTERVAL = 2000;

const Snack = props => {
  const {
    classes,
    message,
    isOpen,
    actionText,
    onAction,
    onClose,
    autoClose,
    variant,
    withClose,
  } = props;

  const { t } = useTranslation();
  const autoCloseInterval = DEFAULT_AUTO_CLOSE_INTERVAL;

  const closeWrapper = (a, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    onClose();
  };

  const isError = variant === 'error';

  return (
    <Snackbar
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      open={Boolean(isOpen)}
      ContentProps={{
        'aria-describedby': 'message-id',
        className: clsx(classes.main, isError && classes.mainError),
        classes: {
          message: classes.message,
        },
      }}
      autoHideDuration={autoCloseInterval}
      resumeHideDuration={autoClose ? DEFAULT_RESUME_CLOSE_INTERVAL : null}
      onClose={autoClose ? closeWrapper : () => null}
      message={
        <>
          <span id="message-id">{message}</span>
          {actionText ? (
            <>
              <TextButton
                text={actionText}
                onClick={onAction}
                className={classes.textButton}
              />
            </>
          ) : null}
          {withClose && (
            <Fab
              size="small"
              onClick={onClose}
              aria-label={t('general.close')}
              className={clsx(
                classes.closeButton,
                isError && classes.closeError
              )}
            >
              <CloseIcon
                className={clsx(
                  classes.closeIcon,
                  isError && classes.iconError
                )}
              />
            </Fab>
          )}
        </>
      }
    />
  );
};

const styles = theme => ({
  main: {
    backgroundColor: colors.palette.secondary.main,
    marginBottom: 30,
    borderRadius: 20,
    padding: '0 0 0 0',
    flexGrow: 0,
    maxWidth: '100%',
    marginRight: 20,
    marginLeft: 20,
    [theme.breakpoints.down(breakpoints.MOBILE)]: {
      marginBottom: 20,
    },
    [theme.breakpoints.up(breakpoints.MOBILE)]: {
      height: 41,
      minWidth: 230,
    },
  },
  mainError: {
    backgroundColor: colors.alertBackground,
  },
  message: {
    fontFamily: 'VerlagBook',
    color: 'black',
    fontSize: 15,
    paddingLeft: 15,
    paddingRight: 15,
    [theme.breakpoints.up('md')]: {
      whiteSpace: 'nowrap',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 14,
      whiteSpace: 'normal',
    },
    [theme.breakpoints.up(breakpoints.MOBILE)]: {
      textAlign: 'center',
      width: '100%',
    },
  },
  textButton: {
    padding: 5,
    fontFamily: 'VerlagBold',
    borderRadius: 2,
    '&:hover': {
      backgroundColor: colors.palette.secondary.light,
    },
  },
  closeButton: {
    height: 16,
    width: 16,
    minHeight: 16,
    borderRadius: 2,
    marginLeft: 10,
    background: 'transparent',
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: colors.palette.secondary.light,
      boxShadow: 'none',
    },
  },

  closeError: {
    '&:hover': {
      backgroundColor: colors.alertMessage,
    },
  },
  closeIcon: {
    height: 10,
    width: 10,
  },
});

Snack.defaultProps = {
  actionText: '',
  onAction: () => '',
  autoClose: false,
  onClose: () => null,
  variant: 'default',
  withClose: true,
  message: '',
  isOpen: false,
};

Snack.propTypes = {
  message: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  isOpen: PropTypes.bool,
  classes: PropTypes.object.isRequired,
  actionText: PropTypes.string,
  onAction: PropTypes.func,
  autoClose: PropTypes.bool,
  withClose: PropTypes.bool,
  onClose: PropTypes.func,
  variant: PropTypes.string,
};
export default withStyles(styles)(Snack);
