import { createAction } from 'redux-actions';

import * as types from './actionTypes';

import { fetchLocation } from '../../../Core/Api';
import { fetchHandlerActions } from '../../../Core/Utils/fetch';

export const setGrouping = createAction(types.SET_GROUPING);

export const addLocationDetails = createAction(
  types.LOAD_LOCATION_DETAILS,
  fetchHandlerActions(fetchLocation)
);
