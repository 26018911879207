import Components from '../../Components';
import {
  getIsDecisionMaker,
  groupingFeatureRoleAccess,
} from '../../Core/Utils/userPermissions';

const { containerTypes } = Components.Routes;

const RouteConfig = {
  path: '/home/memberManagement',
  exact: true,
  name: 'navigation.menu.membermanagement',
  private: true,
  type: containerTypes.CONTENT,
  requiresMemberPortalAccess: true,
  isAuthorized: ({ profile, userGroupings, userGroupingsIsLoading }) => {
    if (userGroupingsIsLoading) {
      return true;
    }
    const groupingDto = {
      groupings: userGroupings,
      groupingFeatureKey: 'MemberManagement',
    };
    const isDecisionMaker = getIsDecisionMaker(profile);
    const access = groupingFeatureRoleAccess.HasFeatureInPrimaryLocation({
      obj: groupingDto,
      profile,
    });
    return isDecisionMaker && access;
  },
};

export default RouteConfig;
