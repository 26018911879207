import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

import { colors, breakpoints } from '../../../Core/Theme';

const Divider = props => {
  const { classes, text, datatestid } = props;

  return (
    <div className={classes.contents}>
      {text ? (
        <>
          <span className={clsx(classes.line, classes.lineLeft)} />
          <span className={classes.text} datatestid={datatestid}>
            {text}
          </span>
          <span className={clsx(classes.line, classes.lineRight)} />
        </>
      ) : (
        <span className={classes.line} />
      )}
    </div>
  );
};

const LineMargin = {
  desktop: 20,
  mobile: 10,
};

const styles = theme => {
  return {
    contents: {
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',

      fontFamily: 'VerlagBold',
      [theme.breakpoints.up(breakpoints.MOBILE)]: {
        fontSize: '34px',
      },
      [theme.breakpoints.down(breakpoints.MOBILE)]: {
        fontSize: '20px',
      },
    },
    line: {
      height: 1,
      flexGrow: 1,
      backgroundColor: colors.middle,
    },
    lineLeft: {
      [theme.breakpoints.up(breakpoints.MOBILE)]: {
        marginRight: LineMargin.desktop,
      },
      [theme.breakpoints.down(breakpoints.MOBILE)]: {
        marginRight: LineMargin.mobile,
      },
    },
    lineRight: {
      [theme.breakpoints.up(breakpoints.MOBILE)]: {
        marginLeft: LineMargin.desktop,
      },
      [theme.breakpoints.down(breakpoints.MOBILE)]: {
        marginLeft: LineMargin.mobile,
      },
    },
    text: {
      [theme.breakpoints.down(breakpoints.MOBILE)]: {
        width: 168,
        textAlign: 'center',
      },
    },
  };
};

Divider.defaultProps = {
  text: null,
  datatestid: '',
};

Divider.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  text: PropTypes.oneOfType([PropTypes.string]),
  datatestid: PropTypes.string,
};

export default withStyles(styles)(Divider);
