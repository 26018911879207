import React from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import Lodash from 'lodash';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import FetchAutocomplete from '../FetchAutocomplete';

// Api
import { fetchMemberSuggestions } from '../../../Core/Api';
import { colors } from '../../../Core/Theme';

const compareUsers = locationSfId => (userA, userB) => {
  if (
    userB.primaryLocationSfId !== locationSfId ||
    userA.primaryLocationSfId !== locationSfId
  ) {
    return userB.primaryLocationSfId === locationSfId ? 1 : -1;
  }
  const nameA = Lodash.get(userA, ['name'], '');
  const nameB = Lodash.get(userB, ['name'], '');

  return nameA.toLowerCase() < nameB.toLowerCase() ? -1 : 1;
};

const getSuggestions =
  (locationSfId, accountSfId) => async (inputValue, setResults) => {
    fetchMemberSuggestions(
      inputValue,
      locationSfId,
      '',
      false,
      100,
      0,
      accountSfId
    ).then(response => {
      if (Lodash.get(response, ['status'], null) >= 400) {
        return;
      }
      const data = Lodash.get(response, ['data', 'users'], []);
      setResults(data.sort(compareUsers(locationSfId)));
    });
  };

const makeMenuItem =
  (
    classes,
    withAccount,
    isBoxed,
    disAllowedIds,
    topMenuItems,
    locationsById,
    withLocation
  ) =>
  (item, index, highlightedIndex, itemProps) => {
    const nameAccount = Lodash.get(item, ['account', 'name'], '');
    const name = Lodash.get(item, ['name'], '');
    const sfId = Lodash.get(item, ['sfId'], 'a');
    let locationName;
    if (withLocation) {
      const locationSfId = Lodash.get(item, ['account', 'locationSfId']);
      locationName = Lodash.get(locationsById, [locationSfId, 'externalName']);
    }
    if (disAllowedIds.includes(sfId)) {
      return null;
    }

    const borderIndex = topMenuItems.length - 1;

    const isLastTopMenuItem = index === borderIndex;
    const isNotTopMenuItem = index > borderIndex;

    return (
      <MenuItem
        style={{
          backgroundColor:
            index === highlightedIndex
              ? colors.palette.secondary2.main
              : colors.white,
          borderBottom:
            isLastTopMenuItem && isBoxed
              ? `solid 1px ${colors.middle}`
              : 'none',
        }}
        classes={{
          root: clsx(
            isBoxed && classes.boxedRoot,
            isBoxed &&
              withAccount &&
              isNotTopMenuItem &&
              classes.boxedCompanyRoot
          ),
        }}
        {...itemProps}
      >
        <div className={clsx(classes.menuItem, isBoxed && classes.boxedItem)}>
          <p className={clsx(classes.name, isBoxed && classes.boxedName)}>
            {name}
          </p>
          {withAccount && isNotTopMenuItem && (
            <p
              className={clsx(classes.company, isBoxed && classes.boxedCompany)}
            >
              {nameAccount} - {withLocation && locationName}
            </p>
          )}
        </div>
      </MenuItem>
    );
  };

const MemberAutocomplete = objProps => {
  const {
    classes,
    defaultValue,
    label,
    locationSfId,
    readOnly,
    accountSfId,
    withAccount,
    variant,
    disAllowedIds,
    topMenuItems,
    locationsById,
    withLocation,
    onChange,
    ...rest
  } = objProps;

  const isBoxed = variant === 'boxed';
  const [shouldResetResults, setShouldResetResults] = React.useState();

  React.useEffect(() => setShouldResetResults(true), [accountSfId]);

  return (
    <>
      <FetchAutocomplete
        {...rest}
        variant={variant}
        disabledNoMenu={readOnly}
        defaultValue={defaultValue}
        getSuggestions={
          readOnly ? () => null : getSuggestions(locationSfId, accountSfId)
        }
        makeMenuItem={makeMenuItem(
          classes,
          withAccount,
          isBoxed,
          disAllowedIds,
          topMenuItems,
          locationsById,
          withLocation
        )}
        topMenuItems={topMenuItems}
        label={label}
        shouldResetResults={shouldResetResults}
        setShouldResetResults={setShouldResetResults}
        onChange={onChange}
      />
    </>
  );
};
const styles = {
  menuItem: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    fontFamily: 'VerlagLight',
    fontSize: '20px',
    display: 'flex',
    padding: 'none',
  },
  name: {
    color: colors.black,
    width: 142,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  company: {
    color: colors.dark,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    position: 'absolute',
    marginLeft: 142,
    width: 142,
  },
  boxedName: {
    color: colors.black,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    fontSize: 16,
    margin: 0,
    lineHeight: 1,
    width: '100%',
  },
  boxedRoot: {
    paddingTop: 7,
    paddingBottom: 7,
  },
  boxedItem: {
    width: '100%',
    flexDirection: 'column',
  },
  boxedCompany: {
    position: 'relative',
    fontSize: 12,
    fontFamily: 'VerlagLight',
    margin: 0,
    lineHeight: 1,
    paddingTop: 5,
    width: '100%',
    paddingBottom: 1,
  },
  boxedCompanyRoot: {
    height: 33,
  },
};

MemberAutocomplete.defaultProps = {
  readOnly: false,
  defaultValue: '',
  locationSfId: '',
  withAccount: true,
  variant: 'boxed',
  disAllowedIds: [],
  topMenuItems: [],
  locationsById: {},
  withLocation: true,
  accountSfId: '',
};

MemberAutocomplete.propTypes = {
  classes: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
  readOnly: PropTypes.bool,
  defaultValue: PropTypes.string,
  locationSfId: PropTypes.string,
  withAccount: PropTypes.bool,
  variant: PropTypes.string,
  disAllowedIds: PropTypes.arrayOf(PropTypes.string),
  topMenuItems: PropTypes.arrayOf(PropTypes.object),
  locationsById: PropTypes.object,
  withLocation: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  accountSfId: PropTypes.string,
};
export default withStyles(styles)(MemberAutocomplete);
