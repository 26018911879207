import React from 'react';
import PropTypes from 'prop-types';
import memoizeOne from 'memoize-one';
import Lodash from 'lodash';
import { useTranslation } from 'react-i18next';

// Components
import { MapLayerWrapper as Layer } from '../../Common';
import { calculateCentroid } from '../Utils';
import Popup from '../Components/Popup';
// Constants
import { LAYERS } from '../Constants';

// Theme & Utils
import { colors } from '../../../Core/Theme';
import { createHash } from '../../../Core/Utils';

// Layer properties
const fillProperties = {
  id: LAYERS.UNAVAILABLE_DESK,
  type: 'fill',
  source: 'features',
  paint: {
    'fill-pattern': 'deskUnavailable',
    'fill-color': colors.middle,
  },
};
const lineProperties = {
  id: `${LAYERS.UNAVAILABLE_DESK}-lines`,
  type: 'line',
  source: 'features',
  paint: {
    'line-color': colors.darkGray,
    'line-width': 2,
    'line-opacity': 0.6,
  },
};

// Legend
const legend = {
  key: LAYERS.UNAVAILABLE_DESK,
  style: {
    backgroundColor: colors.middle,
    borderColor: colors.darkGray,
    borderWidth: 1,
    borderStyle: 'solid',
    background: `repeating-linear-gradient(45deg, ${colors.middle} 1px, ${colors.darkGray} 5px)`,
  },
  labelKey: 'deskFloorPlan.legend.unavailableDesk',
};

const getPopupInfo = memoizeOne((conflictDeskId, data, setInfoPopup) => {
  if (!conflictDeskId) {
    setInfoPopup(null);
    return;
  }

  const deskMapboxId = Lodash.get(
    data,
    ['Desk', conflictDeskId, 'mapboxFeatureID'],
    {}
  );
  const features = Lodash.get(data, ['dataset', 'features'], []);
  const featureConflict = Lodash.find(features, feature => {
    return Lodash.get(feature, ['id'], null) === deskMapboxId;
  });
  if (featureConflict) {
    const geometry = Lodash.get(featureConflict, ['geometry'], null);
    const coordinates = calculateCentroid(geometry);
    setInfoPopup(coordinates);
  }
});

const UnavailableDesk = props => {
  const { desks, conflictDeskId, data } = props;
  const { t } = useTranslation();
  const [infoPopup, setInfoPopup] = React.useState(null);
  getPopupInfo(conflictDeskId, data, setInfoPopup);
  return (
    <>
      <Popup
        onPopupClose={() => setInfoPopup(null)}
        latitude={Lodash.get(infoPopup, [1])}
        longitude={Lodash.get(infoPopup, [0])}
        message={t('deskFloorPlan.tooltips.desk_conflict')}
      />
      <Layer
        key={`${LAYERS.UNAVAILABLE_DESK}-${createHash(desks)}`}
        properties={[fillProperties, lineProperties]}
        filter={['in', ['get', 'id'], ['literal', desks]]}
        {...props}
      />
    </>
  );
};

UnavailableDesk.defaultProps = {
  desks: [],
  conflictDeskId: null,
};
UnavailableDesk.propTypes = {
  desks: PropTypes.arrayOf(PropTypes.string),
  data: PropTypes.object.isRequired,
  conflictDeskId: PropTypes.number,
};

export { legend };
export default React.memo(UnavailableDesk);
