import * as React from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import Lodash from 'lodash';

import { Tabs, Popover } from '../../../Components/Common';

import { OfficeTypes, OfficeStatuses } from '../../../Models/office';

import { trackManageWorkspacesClick } from '../../../Core/Tracking';
import OfficeNotReservableWarning from './OfficeNotReservableWarning';

const UnassignOffice = props => {
  const {
    classes,
    officeName,
    toggleOffice,
    disablePointerEvents,
    office,
    hasFutureReservation,
  } = props;
  const { t } = useTranslation();
  const [selectedTabId, setSelectedTabId] = React.useState('');
  const [confirmUnassignAnchor, setAnchorEl] = React.useState(null);

  React.useEffect(() => {
    if (Lodash.isEmpty(office)) {
      setSelectedTabId('');
      return;
    }
    const isOasisReservable = Lodash.get(office, 'isOasisReservable', false);

    if (isOasisReservable) {
      setSelectedTabId(OfficeTypes.Reservable);
      return;
    }
    setSelectedTabId(OfficeTypes.UnReservable);
  }, [office]);

  const tabs = [
    {
      text: t('manageOffices.office_types.reservable'),
      id: OfficeTypes.Reservable,
    },
    {
      text: t('manageOffices.office_types.unreservable'),
      id: OfficeTypes.UnReservable,
    },
  ];

  const onCancel = () => {
    setSelectedTabId(OfficeTypes.Reservable);
    setAnchorEl(null);
    trackManageWorkspacesClick('Cancel Disable Office');
  };

  const onConfirm = () => {
    setAnchorEl(null);
    toggleOffice(OfficeTypes.UnReservable);
    trackManageWorkspacesClick('Cancel Reservations');
  };

  const onTabClick = (id, e) => {
    setSelectedTabId(id);
    if (id === OfficeTypes.UnReservable) {
      trackManageWorkspacesClick('Disable Office');
      if (hasFutureReservation) {
        setAnchorEl(e.currentTarget);
      } else {
        onConfirm();
      }
    }
    if (id === OfficeTypes.Reservable) {
      toggleOffice(OfficeTypes.Reservable);
      trackManageWorkspacesClick('Enable Office');
    }
  };

  return (
    <>
      <div className={classes.label}>{t('manageOffices.office_status')}</div>
      <Tabs
        legends={tabs}
        variant="boxed"
        selectedId={selectedTabId}
        onTabClick={onTabClick}
        className={clsx(
          classes.officeState,
          disablePointerEvents && classes.disabledPointer
        )}
        tabClassName={classes.tabs}
        isDisabled={
          office.status === OfficeStatuses.Licensed && !office.isOasisReservable
        }
      />
      <Popover
        anchorEl={confirmUnassignAnchor}
        onCancel={onCancel}
        onConfirm={onConfirm}
        variant="confirmation"
        description={<OfficeNotReservableWarning officeName={officeName} />}
      />
    </>
  );
};

const styles = {
  officeState: {
    margin: 'auto',
    marginBottom: 30,
  },
  disabledPointer: {
    pointerEvents: 'none',
  },
  seeDesks: {
    padding: 0,
  },
  label: {
    fontFamily: 'VerlagBold',
    fontSize: 14,
    textAlign: 'center',
    paddingBottom: 8,
    textTransform: 'uppercase',
  },
  tabs: {
    paddingRight: 10,
  },
};

UnassignOffice.defaultProps = {
  officeName: '',
  disablePointerEvents: false,
};

UnassignOffice.propTypes = {
  classes: PropTypes.object.isRequired,
  officeName: PropTypes.string,
  toggleOffice: PropTypes.func.isRequired,
  disablePointerEvents: PropTypes.bool,
  office: PropTypes.object.isRequired,
  hasFutureReservation: PropTypes.bool.isRequired,
};

export default withStyles(styles)(UnassignOffice);
