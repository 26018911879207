/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { useEffect, useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

// Theme
import { breakpoints, colors } from '../../../../Core/Theme';

import { Icons } from '../../../../resources';
import FilterModal from './FilterModal';

const FilterModalAnchor = props => {
  const { classes, onFilter, columns, onClearFilters, selectedItems } = props;
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [activeItems, setActiveItems] = useState({});

  const onClickItem = ({ value, columnKey }) => {
    const filtersForColumn = activeItems[columnKey] ?? [];
    const isSelected = filtersForColumn?.includes(value);
    if (isSelected) {
      return setActiveItems(filtersForColumn.filter(item => item !== value));
    }
    return setActiveItems({
      ...activeItems,
      [columnKey]: [...filtersForColumn, value],
    });
  };

  useEffect(() => {
    setActiveItems(selectedItems);
  }, [isModalOpen, selectedItems]);

  const onCloseModal = () => {
    setIsModalOpen(false);
  };
  const handleOnClick = () => {
    setIsModalOpen(true);
  };

  const handleOnFilter = () => {
    onFilter(activeItems);
    onCloseModal();
  };
  const handleOnClear = () => {
    setActiveItems({});
    onClearFilters();
    onCloseModal();
  };

  const hasSelectedFilters = Object.values(activeItems).some(v => v.length);

  return (
    <>
      <div
        onClick={handleOnClick}
        className={clsx([
          classes.filterButtonContainer,
          hasSelectedFilters && classes.activeFilterButton,
        ])}
      >
        <img
          src={Icons.Filter}
          className={classes.filterColumnButton}
          alt={t('altTexts.filter')}
        />
      </div>
      <FilterModal
        isOpen={isModalOpen}
        onClose={onCloseModal}
        columns={columns}
        onClickItem={onClickItem}
        activeItems={activeItems}
        onClearFilters={handleOnClear}
        onFilter={handleOnFilter}
      />
    </>
  );
};

FilterModalAnchor.defaultProps = {
  selectedItems: {},
};
FilterModalAnchor.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  selectedItems: PropTypes.shape({}),
  onClearFilters: PropTypes.func.isRequired,
  classes: PropTypes.shape({}).isRequired,
  onFilter: PropTypes.func.isRequired,
};

const styles = theme => ({
  labelItem: {
    fontFamily: 'VerlagBold',
    fontSize: 15,
  },
  itemCheckbox: {
    marginBottom: -12,
  },
  rootPopover: {
    padding: 15,
  },
  popover: {
    boxShadow: '0 0 4px 1px rgba(0, 0, 0, 0.1)',
    borderRadius: 4,
    display: 'none',
    [theme.breakpoints.up(breakpoints.MOBILE)]: {
      display: 'block',
    },
  },
  headWithFilter: {
    marginBottom: '12px',
  },
  filterButtonContainer: {
    borderRadius: '4px',
    border: 'solid 0.8px #d8d8d8',
    display: 'inline-flex',
    marginLeft: '10px',
    padding: '3px',
    cursor: 'pointer',
  },
  filterColumnButton: {
    height: 32,
    width: 32,
    [theme.breakpoints.up(breakpoints.MOBILE)]: {
      height: 15,
      width: 15,
    },
  },
  description: {
    fontSize: 15,
    fontFamily: 'VerlagBook',
    opacity: 0.5,
    paddingLeft: 34,
    marginTop: -15,
    marginBottom: 20,
  },
  mobileModal: {
    height: '100vh',
    background: colors.palette.background,
  },
  activeFilterButton: {
    borderColor: colors.palette.primary.light,
    borderWidth: 2,
  },
});
export default withStyles(styles)(FilterModalAnchor);
