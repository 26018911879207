import React from 'react';
import PropTypes from 'prop-types';

// Components
import { MapLayerWrapper as Layer } from '../../Common';

// Constants
import { LAYERS } from '../Constants';

// Theme & Utils
import { colors } from '../../../Core/Theme';
import { createHash } from '../../../Core/Utils';

// Layer properties
const fillProperties = {
  id: LAYERS.UTILIZED_OFFICE,
  type: 'fill',
  source: 'features',
  paint: {
    'fill-color': [
      'case',
      ['boolean', ['feature-state', 'hover'], false],
      colors.middle,
      colors.gray,
    ],
  },
};

// Legend
const legend = {
  key: LAYERS.UTILIZED_OFFICE,
  style: {
    backgroundColor: colors.gray,
    borderColor: colors.gray,
    borderWidth: 2,
    borderStyle: 'solid',
  },
  labelKey: 'deskFloorPlan.legend.utilizedOffice',
};

const UtilizedOffice = props => {
  const { offices } = props;
  return (
    <Layer
      key={`${LAYERS.UTILIZED_OFFICE}-${createHash(offices)}`}
      properties={[fillProperties]}
      filter={['in', ['get', 'id'], ['literal', offices]]}
      {...props}
    />
  );
};

UtilizedOffice.defaultProps = {
  offices: [],
};
UtilizedOffice.propTypes = {
  offices: PropTypes.arrayOf(PropTypes.string),
};

export { legend };
export default React.memo(UtilizedOffice);
