import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';

import { SkeletonLoader } from '../../Common';
import { colors, breakpoints } from '../../../Core/Theme';

const SummaryFooter = props => {
  const { classes, leftText, rightText, isLoading, leftSize, rightSize } =
    props;

  return (
    <div>
      <Grid container className={classes.content} spacing={16}>
        <Grid item xs={leftSize} className={classes.left}>
          {leftText}
        </Grid>

        <Grid item xs={rightSize} className={classes.right}>
          {isLoading ? (
            <SkeletonLoader speed={2} className={classes.titleLoader} />
          ) : (
            rightText
          )}
        </Grid>
      </Grid>
    </div>
  );
};

SummaryFooter.defaultProps = {
  leftSize: 8,
  rightSize: 4,
};

SummaryFooter.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  classes: PropTypes.object.isRequired,
  rightText: PropTypes.string.isRequired,
  leftText: PropTypes.string.isRequired,
  leftSize: PropTypes.number,
  rightSize: PropTypes.number,
};

const styles = theme => ({
  content: {
    marginTop: 20,
    borderBottom: `solid 1px ${colors.middle}`,
    paddingBottom: 15,
    [theme.breakpoints.up(breakpoints.MD)]: {
      marginTop: 30,
      border: 'none',
      paddingBottom: 20,
    },
  },
  left: {
    fontFamily: 'VerlagBook',
    fontSize: 14,
    [theme.breakpoints.up(breakpoints.MOBILE)]: {
      fontSize: 16,
    },
  },
  titleLoader: {
    height: 15,
    width: 50,
    marginLeft: 'auto',
  },
  right: {
    fontFamily: 'VerlagBook',
    fontSize: 21,
    textAlign: 'right',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    [theme.breakpoints.up(breakpoints.MOBILE)]: {
      fontSize: 24,
    },
  },
});

export default withStyles(styles)(SummaryFooter);
