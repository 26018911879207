import Components from '../../Components';

const { containerTypes } = Components.Routes;
// change type to subcontent to add it to the left side nav
const RouteConfig = {
  path: '/home/FAQ',
  exact: true,
  name: 'navigation.menu.faq',
  private: true,
  type: containerTypes.CONTENT,
};

export default RouteConfig;
