import { InputTypes } from './InputTypes';

class InputPropsCleaner {
  static clean(props: any, type: InputTypes) {
    if (type === InputTypes.Input) {
      return this.cleanInput(props);
    }
    return props;
  }

  private static cleanInput(props: any) {
    const propsCopy = { ...props };

    delete propsCopy.skipTouched;

    return propsCopy;
  }
}

export default InputPropsCleaner;
