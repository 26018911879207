import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Tooltip from '@material-ui/core/Tooltip';
import Lodash from 'lodash';
import clsx from 'clsx';
import { withTranslation } from 'react-i18next';

// Styles
import { colors, breakpoints } from '../../../../../../Core/Theme';

// Common Components
import SubmitButton from '../../../../../../Components/Common/SubmitButton';
import TitleDescription from '../../../../../../Components/Common/TitleDescription';
// Core
import { customProps } from '../../../../../../Core/Utils';

class MeetingSummaryBooking extends Component {
  LightTooltip = withStyles(theme => ({
    tooltip: {
      backgroundColor: colors.white,
      color: 'rgba(0, 0, 0, 0.87)',
      boxShadow: theme.shadows[1],
      fontSize: 12,
      fontFamily: 'VerlagLight',
      border: '1px solid #898989',
      width: '204px',
      height: '65px',
      borderRadius: '3px',
    },
  }))(Tooltip);

  // TODO: Handle isOverageVisible  when the RBAC module is ready
  renderSummary = objSummaryDetails => {
    const { classes, t } = this.props;
    return Object.keys(objSummaryDetails).map(key => {
      if (key === 'time') {
        const value = Lodash.get(objSummaryDetails[key], ['value'], null);
        return (
          <div
            className={classes.titleDescription}
            key={`bookingSummary${key}`}
            datatestid={`rr_${key}_selected`}
          >
            <TitleDescription
              title={t('meetingSummary.time')}
              description={
                value ||
                `${objSummaryDetails[key].initialTime} - ${objSummaryDetails[key].endTime}`
              }
            />
          </div>
        );
      }

      return (
        <div
          className={classes.titleDescription}
          key={`bookingSummary${key}`}
          datatestid={`rr_${key}_selected`}
        >
          {objSummaryDetails[key].isVisible ? (
            <TitleDescription
              title={t(
                `meetingSummary.${objSummaryDetails[key].key
                  .toLowerCase()
                  .split(' ')
                  .join('_')}`
              )}
              description={objSummaryDetails[key].value}
            />
          ) : null}
        </div>
      );
    });
  };

  render() {
    const {
      classes,
      onReserve,
      isButtonDisabled,
      data,
      isSubmitting,
      values,
      label,
      classNameContainer,
      isSuccess,
      t,
    } = this.props;
    const isSuccessFromValues = Lodash.get(values, ['isSuccess'], false);
    const isSuccessFromProps = isSuccess;

    return (
      <React.Fragment>
        <div
          className={clsx(classes.summaryContainerBooking, classNameContainer)}
        >
          <div className={classes.summaryDetailsContainerBooking}>
            <div className={classes.summaryTimeDetailsInfo}>
              {this.renderSummary(data)}
            </div>
            <div className={classes.editButtonsBooking}>
              <SubmitButton
                className={classes.meetingbuttonReserveRoot}
                defaultText={
                  label || t('meetingSummary.create_reservation_cta')
                }
                fullWidth
                disabled={
                  !(isSuccess || isSuccessFromValues) && isButtonDisabled
                }
                onClick={onReserve}
                isLoading={isSubmitting}
                isSuccess={isSuccessFromValues || isSuccessFromProps}
                datatestid="rr_reserve_a_room_button"
              />
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const styles = theme => ({
  editButtonsBooking: {
    [theme.breakpoints.up(breakpoints.MOBILE)]: {
      flexDirection: 'column',
      alignItems: 'flex-end',
      display: 'flex',
      width: '210px',
      marginLeft: 40,
    },
  },
  titleDescription: {
    [theme.breakpoints.up(breakpoints.MOBILE)]: {},
    fontSize: '16px',
  },
  summaryContainerBooking: {
    flexDirection: 'column',
    display: 'flex',
    padding: '17px',
    alignItems: 'center',
    backgroundColor: colors.white,
    [theme.breakpoints.up(breakpoints.MOBILE)]: {
      paddingLeft: '40px',
      paddingRight: '40px',
    },
    [theme.breakpoints.down(breakpoints.MOBILE)]: {
      padding: '20px',
      height: '109px',
    },
  },
  summaryTimeDetailsInfo: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    [theme.breakpoints.up(breakpoints.MOBILE)]: {
      width: '100%',
      maxWidth: 400,
    },
  },
  summaryDetailsContainerBooking: {
    width: '100%',
    flexDirection: 'column',
    display: 'flex',
    [theme.breakpoints.up(breakpoints.MOBILE)]: {
      alignItems: 'center',
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
  },
  meetingbuttonReserveRoot: {
    [theme.breakpoints.down(breakpoints.MOBILE)]: {
      marginTop: '20px',
    },
    [theme.breakpoints.up(breakpoints.MOBILE)]: {
      width: '210px',
      marginTop: 0,
    },
  },
  overageIcon: {
    fill: colors.dark,
    height: '16px',
    width: '16px',
    marginLeft: '10px',
  },
});

MeetingSummaryBooking.defaultProps = {
  label: null,
  classNameContainer: null,
  isSuccess: null,
};

MeetingSummaryBooking.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  values: PropTypes.shape({}).isRequired,
  onReserve: PropTypes.func.isRequired,
  isButtonDisabled: PropTypes.bool.isRequired,
  data: customProps.meetingSummary.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  label: PropTypes.string,
  classNameContainer: PropTypes.string,
  isSuccess: PropTypes.bool,
  t: PropTypes.func.isRequired,
};

export default withTranslation()(withStyles(styles)(MeetingSummaryBooking));
