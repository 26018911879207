import { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Lodash from 'lodash';
import { withStyles } from '@material-ui/core/styles';

import { colors } from '../../../../Core/Theme';
import { date as DateUtils } from '../../../../Core/Utils';
// Components
import Calendar from '../../../../Components/Calendar';
// Selectors
import { getSelectedDate } from '../../../RoomBooking/Redux/selectors';
import { getBookingsForRoom } from '../../../../Redux/Common/Bookings/selectors';

import { getProfileUserId } from '../../../../Redux/Common/UserManager/selectors';
import {
  bookingRoleAccess,
  getMustRespectBusinessHours,
} from '../../../../Core/Utils/userPermissions';
import { timeOverlapsBooking } from '../../utils';

class MeetingCalendarBooking extends Component {
  handleDragExtendedHours = newEpoch => {
    const { setFieldValue, values } = this.props;
    const { initialTime } = values;
    if (newEpoch < initialTime * 1) {
      setFieldValue('initialTime', newEpoch);
      return;
    }
    setFieldValue('endTime', newEpoch);
  };

  render() {
    const {
      objBooking,
      selectedDate,
      room,
      bookingsForRoom,
      profile,
      classes,
      spaceSfId,
      startEpoch,
      endEpoch,
      title,
      isSuccess,
      valueDate,
      timeZoneId,
      location,
      isOutsideBusinessHoursError,
      isSelectedDateUnavailable,
    } = this.props;
    const bookingsWithRestrictions = bookingsForRoom.map(b => {
      return {
        ...b,
        canView: bookingRoleAccess.view({ profile, obj: b }),
        /* eslint-disable-next-line no-underscore-dangle */
        isMyBooking: b.userOid === profile._id,
        columnKey: b.spaceSfId,
        canDrag: false,
      };
    });

    const currentId = Lodash.get(objBooking, ['idBooking'], '');

    const { startOverlap, endOverlap } = timeOverlapsBooking(
      bookingsForRoom,
      currentId,
      startEpoch,
      endEpoch
    );
    const draftBooking = {
      ...objBooking,
      spaceSfId,
      startEpoch,
      endEpoch,
      title,
      isError:
        startOverlap ||
        endOverlap ||
        isOutsideBusinessHoursError ||
        isSelectedDateUnavailable,
      columnKey: spaceSfId,
    };
    const canCreateBookingAt = obj => {
      const { sfId } = profile;
      const objWithData = {
        ...obj,
        userSfId: sfId,
        space: { location: { timeZoneId, country: location?.country } },
      };
      return bookingRoleAccess.create({
        obj: objWithData,
        profile,
      });
    };

    const mustRespectBusinessHours = getMustRespectBusinessHours(
      profile,
      location
    );

    return (
      <div className={classes.calendar}>
        <Calendar
          startEpoch={DateUtils.getDateInMomentWithZone(
            valueDate || selectedDate,
            timeZoneId
          )
            .startOf('day')
            .unix()}
          endEpoch={
            DateUtils.getDateInMomentWithZone(
              valueDate || selectedDate,
              timeZoneId
            )
              .endOf('day')
              .unix() + 1
          }
          columnData={[{ ...room, key: spaceSfId }]}
          renderHeader={() => null}
          bookingsData={bookingsWithRestrictions}
          withCurrentTimeIndicator
          withTimeline
          draftBooking={isSuccess ? null : draftBooking}
          withVariableWidth
          topBorderClass={classes.calendarTopBorder}
          initialScrollTime={startEpoch - 60 * 60 * 2}
          timeZoneId={timeZoneId}
          canCreateBookingAt={canCreateBookingAt}
          mustRespectBusinessHours={mustRespectBusinessHours}
        />
      </div>
    );
  }
}

const styles = () => ({
  calendar: {
    height: '95%',
    overflowY: 'scroll',
    width: '95%',
  },
  calendarTopBorder: {
    borderTop: `solid 2px ${colors.middle}`,
    boxShadow: 'none',
  },
});

MeetingCalendarBooking.defaultProps = {
  objBooking: {},
};

MeetingCalendarBooking.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  objBooking: PropTypes.shape({}),
  values: PropTypes.shape({}).isRequired,
  selectedDate: PropTypes.number.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  spaceSfId: PropTypes.string.isRequired,
  startEpoch: PropTypes.number.isRequired,
  endEpoch: PropTypes.number.isRequired,
  room: PropTypes.object.isRequired,
  profile: PropTypes.object.isRequired,
  bookingsForRoom: PropTypes.arrayOf(PropTypes.object).isRequired,
  title: PropTypes.string.isRequired,
  isSuccess: PropTypes.bool.isRequired,
  valueDate: PropTypes.number.isRequired,
  timeZoneId: PropTypes.string.isRequired,
  location: PropTypes.object.isRequired,
  isOutsideBusinessHoursError: PropTypes.bool.isRequired,
  isSelectedDateUnavailable: PropTypes.bool.isRequired,
};

const mapStateToProps = (state, ownProps) => {
  return {
    rooms: state.calendar.rooms,
    amenFilters: state.calendar.amenFilters,
    seatFilters: state.calendar.seatFilters,
    user: state.calendar.user,
    userId: getProfileUserId(state, ownProps),
    selectedDate: getSelectedDate(state),
    bookingsForRoom: getBookingsForRoom(state, ownProps),
  };
};

export default withStyles(styles)(
  connect(mapStateToProps, null)(MeetingCalendarBooking)
);
