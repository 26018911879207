import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

//  Components
import { CircularProgress, Button } from '@material-ui/core';
import { Check } from '@material-ui/icons';

//  Style
import styles from './styles';

const SubmitButton = props => {
  const {
    onClick,
    classes,
    defaultText,
    isLoading,
    isSuccess,
    className,
    progressClassName,
    buttonStyle,
    disabled,
    focusRef,
    ...rest
  } = props;

  return (
    <Button
      className={
        disabled
          ? clsx(classes.buttonStyleDisabled, className, classes[buttonStyle])
          : clsx(className, classes[buttonStyle])
      }
      fullWidth
      onClick={isLoading || isSuccess ? () => '' : onClick}
      disabled={disabled}
      buttonRef={focusRef}
      {...rest}
      focusVisibleClassName={classes.buttonFocusVisible}
    >
      {(isLoading && (
        <CircularProgress
          variant="indeterminate"
          color="primary"
          className={[progressClassName, classes.progress].join(' ')}
          size={20}
        />
      )) ||
        (isSuccess && <Check className={classes.checkMark} />) ||
        defaultText}
    </Button>
  );
};

SubmitButton.defaultProps = {
  defaultText: '',
  onClick: () => '',
  isLoading: false,
  isSuccess: false,
  buttonStyle: 'buttonStyleDefault',
  disabled: false,
  focusRef: null,
  className: '',
  progressClassName: '',
  datatestid: '',
};

SubmitButton.propTypes = {
  onClick: PropTypes.func,
  classes: PropTypes.shape({}).isRequired,
  className: PropTypes.string,
  progressClassName: PropTypes.string,
  defaultText: PropTypes.string,
  isLoading: PropTypes.bool,
  isSuccess: PropTypes.bool,
  buttonStyle: PropTypes.string,
  disabled: PropTypes.bool,
  focusRef: PropTypes.object,
  datatestid: PropTypes.string,
};

export default withStyles(styles)(SubmitButton);
