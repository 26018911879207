export const CUSTOM_COLUMNS = {
  LINK: 'LINK',
  OPTIONS: 'OPTIONS',
};

export const ACTIONS_COLUMNS = {
  EXPORT: 'EXPORT',
  MANAGE: 'MANAGE',
  EDIT: 'EDIT',
  CANCEL: 'CANCEL',
  VIEW: 'VIEW',
  LINK: 'LINK',
};
