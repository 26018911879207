import Lodash from 'lodash';
import Moment from 'moment';

const selectGrouping =
  (
    addRooms,
    addBookings,
    setGrouping,
    startDate,
    endDate,
    locationSfId,
    setIsLoading,
    addQueriedDateAndGrouping,
    queriedDatesAndGroupings,
    handleUpdateUrlParams,
    locationTimeZoneId
  ) =>
  async grouping => {
    const hasVisitedGrouping = Lodash.get(
      queriedDatesAndGroupings,
      [grouping.idGrouping],
      []
    );
    handleUpdateUrlParams({
      groupingId: grouping.idGrouping,
      selectedDate: Moment.unix(startDate)
        .tz(locationTimeZoneId)
        .format('YYYY-MM-DD'),
    });
    const hasVisitedGroupingAtThisDate = hasVisitedGrouping.includes(startDate);
    setGrouping(grouping);

    if (hasVisitedGroupingAtThisDate) {
      if (setIsLoading) {
        setIsLoading(false);
      }
      return;
    }

    if (setIsLoading) {
      setIsLoading(true);
    }
    const listParallelPromises = [
      addQueriedDateAndGrouping({
        groupingId: grouping.idGrouping,
        date: startDate,
      }),
      addBookings({
        startEpoch: startDate,
        endEpoch: endDate,
        locationSfId,
        groupingId: grouping.idGrouping,
      }),
    ];
    if (!hasVisitedGrouping.length) {
      listParallelPromises.push(addRooms(locationSfId, grouping.idGrouping));
    }

    await Promise.all(listParallelPromises);
    if (setIsLoading) {
      setIsLoading(false);
    }
  };

export default selectGrouping;
