import isNil from 'lodash/isNil';
import has from 'lodash/has';
import map from 'lodash/map';
import some from 'lodash/some';
import get from 'lodash/get';
import sortBy from 'lodash/sortBy';
import find from 'lodash/find';

export const getLocationFloors = (
  locationSfId,
  floors = [],
  validFloorIds = []
) => {
  const filteredFloors = floors.filter(
    floor =>
      floor.locationSfId === locationSfId &&
      validFloorIds.includes(floor.idFloor)
  );
  return filteredFloors;
};

export const getDesksHasOwner = ({ listSelectedDesks, deskLookup }) => {
  if (listSelectedDesks.length === 0) {
    return false;
  }

  const arrHasOwner = map(listSelectedDesks, intSelectedKey => {
    const objDeskSelected = deskLookup[intSelectedKey];

    if (isNil(objDeskSelected)) {
      return false;
    }

    return has(objDeskSelected, 'owner');
  });

  const hasValidDesks = !some(arrHasOwner, hasOwner => hasOwner === false);

  return hasValidDesks;
};

export const selectFloor = (
  setSelectedFloor,
  selectedFloor,
  locationSfId,
  floors,
  validFloorIds
) => {
  const oldFloorLocation = get(selectedFloor, 'locationSfId', '');
  if (locationSfId === oldFloorLocation) {
    return;
  }
  const locationFloors = getLocationFloors(locationSfId, floors, validFloorIds);

  if (!locationFloors.length) {
    setSelectedFloor({});
  }
  const sorted = sortBy(locationFloors, 'floorName');
  setSelectedFloor(sorted[0]);
};

export const getPassesRemaining = usage => {
  const wallet = find(usage, {
    bookType: 'PassWallet',
    shelf: 'Oasis',
  });
  if (!wallet) {
    return 0;
  }

  return get(wallet, 'balance', 0);
};
