/* eslint-disable no-underscore-dangle */
/* eslint-disable func-names */
import * as React from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';

import { SubmitButton, TextButton } from '../../../../Components/Common';
import { RESERVE_OFFICE_PATH } from '../../../ReserveOffice/reserveOfficeConstants';
import FormikInput from '../../../../Components/FormikInputs';
import {
  getIsManager,
  getIsAccessMember,
  locationRoleAccess,
} from '../../../../Core/Utils/userPermissions';
import MapLink from '../../../../Components/Common/MapLink/Maplink';
import { breakpoints, colors } from '../../../../Core/Theme';
import { date as DateUtils } from '../../../../Core/Utils';
import { fetchSeatReservationAvailability } from '../../../../Core/Api/seatBookings';
import { useIsLocationDateAvailable } from '../../../../Hooks';

const CreateFormContent = props => {
  const {
    classes,
    profile,
    history,
    locationsById,
    errors,
    submitForm,
    isSubmitting,
    setFieldValue,
    values,
    isValid,
    usersDefaultGrouping,
  } = props;

  const { t } = useTranslation();

  const [grouping, setGrouping] = React.useState(null);
  const [pastZone, setPastZone] = React.useState(DateUtils.getCurrentZone());

  const [date, setDate] = React.useState(null);
  const [
    isLoadingSeatReservationAvailability,
    setIsLoadingSeatReservationAvailability,
  ] = React.useState(false);

  const [seatReservationAvailability, setSeatReservationAvailability] =
    React.useState(null);

  const handleChangeGrouping = g => {
    setGrouping(g);
  };

  React.useEffect(() => {
    if (
      getIsAccessMember(profile) &&
      usersDefaultGrouping &&
      !values.grouping
    ) {
      setFieldValue('grouping', usersDefaultGrouping);
      handleChangeGrouping(usersDefaultGrouping);
    }
  }, [usersDefaultGrouping]);

  const { isLoading: isLoadingBlackoutDates, isBlackoutDate } =
    useIsLocationDateAvailable({
      locationId: grouping?.location._id,
      date: values.date,
      timeZoneId: grouping?.location.timeZoneId,
    });

  const hasBookingRestrictions = React.useMemo(() => {
    if (!grouping?.location) return false;
    return locationRoleAccess.hasBookingRestrictions({
      profile,
      obj: grouping?.location,
    });
  }, [grouping?.location, profile]);

  const isDateUnavailable = isBlackoutDate && hasBookingRestrictions;

  const onClickOffice = () =>
    history.push({
      pathname: RESERVE_OFFICE_PATH,
    });

  const handleOnChangeDate = startDate => {
    setDate(startDate);
  };

  const fetchReservationsAvailability = () => {
    setIsLoadingSeatReservationAvailability(true);

    fetchSeatReservationAvailability({
      groupingId: grouping.idGrouping,
      date: DateUtils.getDateInMoment(date).format('YYYY-MM-DD'),
    })
      .then(objResponse => {
        const { data } = objResponse;
        if (data) {
          setSeatReservationAvailability(data);
        }
      })
      .finally(() => setIsLoadingSeatReservationAvailability(false));
  };

  const isManager = getIsManager(profile);

  React.useEffect(() => {
    if (grouping) {
      fetchReservationsAvailability();
    }
  }, [date, grouping]);

  // If the system timezone changes change the date to be midnight in the new zone
  useEffect(() => {
    setFieldValue(
      'date',
      DateUtils.updateDateForTZ(
        values.date,
        DateUtils.getCurrentZone(),
        pastZone
      )
    );
    setPastZone(DateUtils.getCurrentZone());
  }, [DateUtils.getCurrentZone()]);

  const renderLocationDisplay = g => {
    if (!g) return null;
    return `${g?.displayName} — ${g?.location?.parent?.name ?? ''}`;
  };

  return (
    <Grid container className={classes.content} spacing={16}>
      <Grid item xs={12}>
        <div className={classes.title}>{t('openWorkspace.title')}</div>
        <div className={classes.description}>
          {t('openWorkspace.description')}
        </div>
      </Grid>
      <Grid item xs={12}>
        <div className={classes.description2Prefix}>
          {t('openWorkspace.want_more')}
        </div>
        <div className={classes.description2Suffix}>
          {t('openWorkspace.want_more_prefix')}
        </div>
        <TextButton
          text={t('openWorkspace.want_more_cta')}
          onClick={onClickOffice}
          className={classes.reserveOfficeCta}
        />
        <div className={classes.description2Suffix}>
          {t('openWorkspace.want_more_suffix')}
        </div>
      </Grid>
      <Grid item xs={12}>
        <FormikInput
          placeholder={t('openWorkspace.location.placeholder')}
          label={t('openWorkspace.location.label').toUpperCase()}
          input="groupingLocationList"
          name="grouping"
          withBoxDesign
          withoutViewHomeLocation
          onChange={handleChangeGrouping}
          selectedGrouping={grouping}
          showYourLocationSection
          errorClassName={classes.error}
          renderValue={renderLocationDisplay}
          isLoading={isLoadingSeatReservationAvailability}
        />
        {seatReservationAvailability?.hasSeatsLimitReached &&
          !isManager &&
          !isLoadingSeatReservationAvailability &&
          !errors.grouping && (
            <Grid item xs={12} className={classes.errorDisplay}>
              <div className={classes.errorText}>
                {t('openWorkspace.location.fully_booked')}
              </div>
            </Grid>
          )}
        {!errors.grouping &&
        !seatReservationAvailability?.hasSeatsLimitReached &&
        !isManager ? (
          <div className={classes.locationButtons}>
            {grouping ? (
              <MapLink
                location={grouping.location}
                variant="address"
                className={classes.mapLink}
                withExternalIcon
                profile={profile}
              />
            ) : null}
          </div>
        ) : null}
      </Grid>
      <Grid item xs={12}>
        <div className={classes.dateLabel}>
          {t('openWorkspace.date.label').toUpperCase()}
        </div>
        <FormikInput
          input="dayPicker"
          name="date"
          disablePastDate={!isManager}
          onChange={handleOnChangeDate}
          forceShowError={isDateUnavailable}
          error={isDateUnavailable}
          disableWeekends={
            !locationRoleAccess.bookWeekendsAt({
              profile,
              obj: grouping?.location,
            })
          }
        />
        {isDateUnavailable && !isLoadingBlackoutDates && (
          <Grid item xs={12} className={classes.errorDisplay}>
            <div className={classes.errorText}>
              {t('openWorkspace.blackout_date_message')}
            </div>
          </Grid>
        )}
      </Grid>
      <Grid item xs={12}>
        <FormikInput
          name="user"
          label={t('openWorkspace.user.label').toUpperCase()}
          input="memberAutocomplete"
          defaultValue={!isManager ? profile?.name : undefined}
          variant="boxed"
          accountSfId={!isManager ? profile?.accountSfId : undefined}
          placeholder={t('openWorkspace.user.placeholder')}
          topMenuItems={isManager ? [profile] : []}
          errorClassName={classes.error}
          disabled={false}
          readOnly={false}
          locationsById={locationsById}
          inputClassName={classes.memberInput}
        />
      </Grid>
      <Grid item xs={12}>
        <SubmitButton
          onClick={submitForm}
          defaultText={t('openWorkspace.cta')}
          className={classes.cta}
          disabled={
            (seatReservationAvailability?.hasSeatsLimitReached && !isManager) ||
            !isValid ||
            isDateUnavailable
          }
          isLoading={
            isSubmitting ||
            isLoadingSeatReservationAvailability ||
            isLoadingBlackoutDates
          }
        />
      </Grid>
    </Grid>
  );
};

const styles = theme => ({
  content: {
    paddingTop: 30,
    paddingBottom: 30,
    paddingLeft: 20,
    paddingRight: 20,
    textAlign: 'left',
    [theme.breakpoints.up(breakpoints.MOBILE)]: {
      paddingTop: 50,
      paddingBottom: 50,
      paddingLeft: 65,
      paddingRight: 65,
    },
  },
  title: {
    fontFamily: 'VerlagBold',
    fontSize: 24,
    paddingBottom: 15,
    [theme.breakpoints.up(breakpoints.MOBILE)]: {
      fontSize: 38,
    },
  },
  description: {
    fontFamily: 'VerlagBook',
    fontSize: 18,
  },
  description2Prefix: {
    fontFamily: 'VerlagBold',
    fontSize: 16,
    display: 'inline',
  },
  description2Suffix: {
    fontFamily: 'VerlagBook',
    fontSize: 16,
    display: 'inline',
  },
  cta: {
    width: 250,
  },
  dateLabel: {
    fontFamily: 'VerlagBold',
    fontSize: 12,
    width: '100%',
    display: 'block',
    paddingBottom: 2,
  },
  locationButtons: {
    paddingLeft: 10,
    paddingTop: 4,
  },
  mapLink: {
    paddingBottom: 5,
    textAlign: 'left',
  },
  error: {
    fontSize: 16,
    paddingTop: 4,
  },
  reserveOfficeCta: {
    padding: 0,
    fontFamily: 'VerlagBold',
  },
  memberInput: {
    fontSize: 18,
  },
  errorDisplay: { display: 'flex', padding: '0 8px !important', marginTop: 6 },
  errorText: {
    color: colors.palette.error.main,
  },
});

CreateFormContent.defaultProps = {};

CreateFormContent.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.shape({}).isRequired,
  profile: PropTypes.object.isRequired,
  locationsById: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  isSubmitting: PropTypes.func.isRequired,
  submitForm: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  values: PropTypes.object.isRequired,
  usersDefaultGrouping: PropTypes.object.isRequired,
  isValid: PropTypes.bool.isRequired,
};

export default withStyles(styles)(CreateFormContent);
