import Components from '../../Components';
import {
  getIsDecisionMaker,
  getIsManager,
  getIsVirtualMember,
} from '../../Core/Utils/userPermissions';

const { containerTypes } = Components.Routes;

const RouteConfig = {
  path: '/home/account',
  exact: true,
  name: 'navigation.menu.account_overview',
  private: true,
  type: containerTypes.CONTENT,
  isAuthorized: ({ profile }) => {
    return (
      (getIsDecisionMaker(profile) || getIsManager(profile)) &&
      !getIsVirtualMember(profile)
    );
  },
  isMenuItemHidden: ({ profile }) => {
    return getIsManager(profile);
  },
};

export default RouteConfig;
