import { createAction } from 'redux-actions';
import * as types from './actionTypes';
import { fetchHandlerActions } from '../../../Core/Utils/fetch';
import { fetchGroupingAccounts } from '../../../Core/Api';

export const addQueriedLocation = createAction(types.ADD_QUERIED_LOCATION);

export const setLocation = createAction(types.SET_SELECTED_LOCATION);

export const setIsLoading = createAction(types.SET_LOADING_GROUP_MANAGEMENT);

export const addGroupingAccounts = createAction(
  types.ADD_GARS,
  fetchHandlerActions(fetchGroupingAccounts)
);

export const addGar = createAction(types.ADD_GAR);

export const deleteGar = createAction(types.DELETE_GAR);
