import { schema, normalize } from 'normalizr';
import Lodash from 'lodash';

//  constructor
export function NormObjCstr(byId, allIds) {
  this.byId = byId;
  this.allIds = allIds;
}

const getNumberKeys = obj => {
  if (!obj) {
    return [];
  }
  return Object.keys(obj).map(id => Number(id));
};

const getKeys = obj => {
  if (!obj) {
    return [];
  }
  return Object.keys(obj);
};

// amenities
const amenitySchema = new schema.Entity( // this replaces locations with the value in idAttricbute
  'amenities',
  {},
  {
    idAttribute: 'idAmenityType',
  }
);

export const normalizedAmenities = array => {
  const temp = normalize(array, amenitySchema);
  return new NormObjCstr(temp.entities.byId, temp.result);
};

// spaceType
const spaceTypeSchema = new schema.Entity( // this replaces locations with the value in idAttricbute
  'spaceType',
  {},
  {
    idAttribute: 'idSpaceType',
  }
);

export const normalizedSpaceTypes = array => {
  const temp = normalize(array, spaceTypeSchema);
  return new NormObjCstr(temp.entities.byId, temp.result);
};

// booking rule
const bookingRuleSchema = new schema.Entity( // this replaces locations with the value in idAttricbute
  'bookingRule',
  {},
  {
    idAttribute: 'idBookingRule',
    processStrategy: rule => {
      const ruleCopy = { ...rule };
      if (!ruleCopy) {
        return { idBookingRule: null };
      }
      return ruleCopy;
    },
  }
);

const bookingRuleArraySchema = [bookingRuleSchema];

export const normalizedBookingRule = array => {
  const temp = normalize(array, bookingRuleSchema);
  return new NormObjCstr(temp.entities.byId, temp.result);
};

export const normalizedBookingRules = array => {
  const temp = normalize(array, bookingRuleArraySchema);
  return new NormObjCstr(temp.entities.bookingRule, temp.result);
};

// floors
const floorSchema = new schema.Entity( // this replaces floors with the value in idAttricbute
  'floor',
  {},
  {
    idAttribute: 'idFloor',
    processStrategy: rule => {
      const ruleCopy = { ...rule };
      if (!ruleCopy) {
        return { idFloor: null };
      }
      return ruleCopy;
    },
  }
);

const floorArraySchema = [floorSchema];

export const normalizedFloor = array => {
  // normalizes a single floor on a room
  const temp = normalize(array, floorSchema);
  return new NormObjCstr(temp.entities.byId, temp.result);
};

export const normalizedFloors = array => {
  // normalizes an array of floors not tied to rooms
  const temp = normalize(array, floorArraySchema);
  return new NormObjCstr(temp.entities.floor, temp.result);
};

//  rooms

const roomSchema = new schema.Entity(
  'rooms',
  {
    spaceType: spaceTypeSchema,
    bookingRule: bookingRuleSchema,
    amenities: [amenitySchema],
    floor: floorSchema,
  },
  {
    idAttribute: 'idSpace',
  }
);

const roomArraySchema = [roomSchema];

export const normalizedRoom = data => {
  const temp = Array.isArray(data)
    ? normalize(data, roomArraySchema)
    : normalize(data, roomSchema);

  if (Lodash.isEmpty(temp.entities)) {
    return {
      rooms: new NormObjCstr({}, []),
      spaceTypes: new NormObjCstr({}, []),
      bookingRules: new NormObjCstr({}, []),
      amenities: new NormObjCstr({}, []),
      floors: new NormObjCstr({}, []),
    };
  }
  //  this code is to coerce the type to Number
  const typeKeys = getNumberKeys(temp.entities.spaceType);
  const ruleKeys = getNumberKeys(temp.entities.bookingRule);
  const amenKeys = getNumberKeys(temp.entities.amenities);
  const floorKeys = getKeys(temp.entities.floor);

  return {
    rooms: new NormObjCstr(temp.entities.rooms, temp.result),
    spaceTypes: new NormObjCstr(temp.entities.spaceType, typeKeys),
    bookingRules: new NormObjCstr(temp.entities.bookingRule || {}, ruleKeys),
    amenities: new NormObjCstr(temp.entities.amenities || {}, amenKeys),
    floors: new NormObjCstr(temp.entities.floor || {}, floorKeys),
  };
};
