import React from 'react';
import PropTypes from 'prop-types';

// Components
import Label from './Label';

// Constants
import { LAYERS } from '../Constants';

// Theme & Utils
import { colors } from '../../../Core/Theme';
import { createHash } from '../../../Core/Utils';

// Layer properties
const symbolProperties = {
  id: `${LAYERS.LABEL}-CommonLabel`,
  type: 'symbol',
  source: 'features',
  paint: {
    'text-color': colors.darkest,
  },
  layout: {
    'text-size': ['interpolate', ['linear'], ['zoom'], 15, 10, 18, 12],
    'text-allow-overlap': true,
    'text-transform': 'uppercase',
    'text-font': ['Verlag Bold'],
  },
};

const CommonLabel = props => {
  const { commonAreas, labelVisible, indicators } = props;

  return (
    <Label
      labelKey={`${LAYERS.LABEL}-CommonLabel--${createHash(`${labelVisible}`)}`}
      properties={[symbolProperties]}
      filter={['in', ['get', 'associatedFeature'], ['literal', commonAreas]]}
      layout={{
        ...symbolProperties.layout,
        'text-field': [
          'get',
          ['get', 'associatedFeature'],
          ['literal', indicators],
        ],
      }}
      {...props}
    />
  );
};

CommonLabel.defaultProps = {
  commonAreas: [],
  indicators: {},
};
CommonLabel.propTypes = {
  commonAreas: PropTypes.arrayOf(PropTypes.string),
  labelVisible: PropTypes.bool.isRequired,
  indicators: PropTypes.shape({ [PropTypes.string]: PropTypes.string }),
};

export default React.memo(CommonLabel);
