import Lodash from 'lodash';

export enum OfficeTypes {
  Reservable,
  UnReservable,
}

export enum OfficeStatuses {
  Available = 'Available',
  Licensed = 'Occupied',
  Pending = 'Pending',
}

const getOfficeName = (office: { overrideName: string; officeName: string }) =>
  office.overrideName || office.officeName;

const getOfficeType = (office: { isOasisReservable?: boolean }) => {
  if (Lodash.isEmpty(office)) {
    return null;
  }

  if (office.isOasisReservable) {
    return OfficeTypes.Reservable;
  }

  return OfficeTypes.UnReservable;
};

export default {
  getOfficeName,
  getOfficeType,
};
