import React from 'react';

// Components
import { MapLayerWrapper as Layer } from '../../../Common';

// Types
import { Layers } from '../../types';

// Theme & Utils
import { colors } from '../../../../Core/Theme';
import { createHash } from '../../../../Core/Utils';
import { OfficeLayersProps } from './types';

const LAYER_ID = Layers.UnassignedOffice;

// Layer properties
const fillProperties = {
  id: `${LAYER_ID}-lines`,
  type: 'line',
  source: 'features',
  paint: {
    'line-color': [
      'case',
      ['boolean', ['feature-state', 'hover'], false],
      colors.dark,
      colors.gray,
    ],
    'line-width': 5,
  },
};
const lineProperties = {
  id: LAYER_ID,
  type: 'fill',
  source: 'features',
  paint: {
    'fill-color': [
      'case',
      ['boolean', ['feature-state', 'hover'], false],
      colors.light,
      colors.white,
    ],
  },
};

// Legend
const legend = {
  key: LAYER_ID,
  style: {
    backgroundColor: colors.white,
    borderColor: colors.dark,
    borderWidth: 2,
    borderStyle: 'solid',
  },
  labelKey: 'floorPlan.legend.unassignedOffice',
};

const UnassignedOffice = (props: OfficeLayersProps) => {
  const { featureIds } = props;
  return (
    <Layer
      key={`${LAYER_ID}-${createHash(featureIds)}`}
      properties={[fillProperties, lineProperties]}
      filter={['in', ['get', 'id'], ['literal', featureIds]]}
      {...props}
    />
  );
};

export { legend };
export default React.memo(UnassignedOffice);
