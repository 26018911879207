import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';
import { colors } from '../../../Core/Theme';

const Loading = props => {
  const { color, size, className } = props;
  return (
    <CircularProgress
      style={{ color, height: size, width: size }}
      className={className}
    />
  );
};

Loading.defaultProps = {
  size: 40,
  color: colors.palette.primary.main,
  className: '',
};

Loading.propTypes = {
  color: PropTypes.string,
  size: PropTypes.number,
  className: PropTypes.string,
};

export default Loading;
