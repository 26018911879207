import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import clsx from 'clsx';
import { SkeletonLoader } from '../../Common';
import { colors, breakpoints } from '../../../Core/Theme';

const SummaryRow = props => {
  const {
    classes,
    title,
    leftTitle,
    leftDescription,
    rightTitle,
    rightDescription,
    isLoading,
  } = props;

  return (
    <div>
      <Grid container className={classes.content} spacing={16}>
        <Grid item xs={12} className={classes.title}>
          {title}
        </Grid>
        <Grid item xs={6} className={classes.side}>
          <Grid item xs={12} className={classes.sideTitle}>
            {isLoading ? (
              <SkeletonLoader speed={2} className={classes.titleLoader} />
            ) : (
              leftTitle
            )}
          </Grid>
          <Grid item xs={12} className={classes.sideDescription}>
            {leftDescription}
          </Grid>
        </Grid>

        {rightTitle ? (
          <Grid item xs={6} className={clsx(classes.right, classes.side)}>
            <Grid item xs={12} className={classes.sideTitle}>
              {isLoading ? (
                <SkeletonLoader
                  speed={2}
                  className={classes.titleLoaderRight}
                />
              ) : (
                rightTitle
              )}
            </Grid>
            <Grid item xs={12} className={classes.sideDescription}>
              {rightDescription}
            </Grid>
          </Grid>
        ) : null}
      </Grid>
    </div>
  );
};

SummaryRow.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  classes: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  leftTitle: PropTypes.string.isRequired,
  leftDescription: PropTypes.string.isRequired,
  rightTitle: PropTypes.string.isRequired,
  rightDescription: PropTypes.string.isRequired,
};

const styles = theme => ({
  side: {
    marginTop: 5,
    marginBottom: 30,
    [theme.breakpoints.up(breakpoints.MOBILE)]: {
      marginBottom: 60,
    },
  },
  title: {
    fontFamily: 'VerlagBook',
    fontSize: 12,
    marginTop: 30,
    [theme.breakpoints.up(breakpoints.MOBILE)]: {
      marginTop: 40,
    },
  },
  sideTitle: {
    fontFamily: 'VerlagBook',
    fontSize: 21,
  },
  sideDescription: {
    color: colors.darkGray,
    fontSize: 14,
    fontFamily: 'VerlagBook',
  },
  right: {
    textAlign: 'right',
  },
  titleLoader: {
    height: 40,
    width: 80,
  },
  titleLoaderRight: {
    height: 40, // matches height of side title
    width: 80,
    marginLeft: 'auto',
  },
  content: {
    borderBottom: `solid 1px ${colors.middle}`,
    [theme.breakpoints.down(breakpoints.MD)]: {
      margin: 0,
      width: 'auto',
    },
  },
});

export default withStyles(styles)(SummaryRow);
