import Lodash from 'lodash';
import memoizeOne from 'memoize-one';

import data from './data';
import { canAccessFeatureToggleInPrimaryLocation } from '../../Core/Utils/userPermissions';

export const getRowValuesFromSettings = memoizeOne(
  (settings, profile, userGroupings, userGroupingsIsLoading) => {
    const keys = Object.keys(settings);
    const orderedKeys = Lodash.sortBy(keys, k =>
      Lodash.get(data, [k, 'order'], -1)
    );
    return orderedKeys.map(k => {
      const row = settings[k];
      const { featureKey } = row;
      const events = Lodash.get(row, 'events');
      const eventKeys = Object.keys(events);
      const eventsFull = eventKeys.reduce((acc, ek) => {
        const canAccess = Lodash.get(
          data,
          [k, 'events', ek, 'canAccess'],
          true
        );
        if (
          (Lodash.isFunction(canAccess) && !canAccess(profile)) ||
          !canAccess
        ) {
          return acc;
        }

        const event = events[ek];
        const { deliveryMethods } = event;
        const title = `settings.${k}.events.${ek}.title`;
        const subtitle = `settings.${k}.events.${ek}.subtitle`;
        const deliveryMethodIsRequired = Lodash.get(
          data,
          [k, 'events', ek, 'deliveryMethodIsRequired'],
          false
        );
        acc[ek] = {
          title,
          subtitle,
          deliveryMethods,
          type: ek,
          deliveryMethodIsRequired,
        };
        return acc;
      }, {});
      const title = `settings.${k}.title`;
      const withSubtitle = Lodash.get(data, [k, 'withSubtitle'], false);
      const subtitle = withSubtitle ? `settings.${k}.subtitle` : '';
      const hideSms = Lodash.get(data, [k, 'hideSms'], false);
      const dataAccess = Lodash.get(data, [k, 'canAccess'], () => true);
      const canAccess =
        (featureKey
          ? canAccessFeatureToggleInPrimaryLocation(
              profile,
              userGroupings,
              userGroupingsIsLoading,
              featureKey
            )
          : true) && dataAccess(profile);
      return { id: k, events: eventsFull, title, subtitle, canAccess, hideSms };
    });
  }
);
