import styled, { css } from 'styled-components';

import { colors } from '../../../Core/Theme';

const Month = styled.div`
  ${props =>
    !props.selected &&
    css`
      cursor: pointer;
      &:hover {
        background-color: white;
        border: solid 1px ${colors.palette.primary.main};
      }
    `}

  &:focus {
    outline: none;
  }
  padding: 12px 0;
  transition: background-color 0.1s, color 0.1s;
  background-color: ${props =>
    props.selected ? colors.palette.primary.main : colors.white};
  color: ${props => (props.selected ? colors.white : colors.black)}
  height: 29px;
  max-width: 57px;
  margin: 8px 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 0 0 33.33%;
  font-family: VerlagBook;
  font-size: 18px;
  border-radius: 2px;
`;

export default Month;
