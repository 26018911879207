import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Lodash from 'lodash';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import Moment from 'moment-timezone';

// Components
import { breakpoints, colors } from '../../Core/Theme';
import { Text } from '../../Components/Common';
import {
  ReserveDeskForm,
  ReserveDeskWithApi,
  UpcomingAndAllReservations,
} from '../../Components';
import MyReservations from '../MyReservations';

// Redux
import { getUserProfile } from '../../Redux/Common/UserManager/selectors';
import { getLocationsById } from '../../Redux/Common/Locations/selectors';
import locationsRedux from '../../Redux/Common/Locations';
import floorsRedux from '../../Redux/Common/Floors';
import reserveDeskRedux from './Redux';
import {
  getSelectedGrouping,
  getSelectedFloor,
  getSelectedDate,
} from './Redux/selectors';

// Utils
import { selectLocation } from './utils';

// Core
import { trackReserveLanding } from '../../Core/Tracking';
import { getStaticImageUrl, date as DateUtils } from '../../Core/Utils';
import { S3IconStrings } from '../../resources';

// Constants
import { DESK_RESERVATION_PATH } from '../DeskReservation/Constants';
import { DESK_LANDING_PATH } from './constants';
import { RESERVATION_TYPES } from '../../Models/anyReservation';

const DeskReservationLanding = ({
  classes,
  profile,
  loadLocations,
  addFloors,
  setGrouping,
  selectedGrouping,
  selectedFloor,
  setFloor,
  setDate: setDateRedux,
  selectedDate,
  history,
}) => {
  const { t } = useTranslation();

  const [selectedReservation, setSelectedReservation] = useState({});
  const [isViewAllReservationsOpen, setViewAllReservationsOpen] =
    useState(false);
  const [date, setDate] = React.useState(Moment().unix());
  const [upcomingRefreshCount, setUpcomingRefreshCount] = React.useState(0);

  const background = getStaticImageUrl(S3IconStrings.deskLandingBackground);

  useEffect(() => {
    loadLocations(false);
    addFloors();
  }, []);

  const selectLocationWithArgs = selectLocation(
    setGrouping,
    selectedGrouping,
    setDateRedux,
    selectedDate,
    addFloors,
    setFloor,
    selectedFloor
  );

  const handleOnFindDesk = values => {
    trackReserveLanding();
    const { grouping, reserveDate } = values;

    const newDate = DateUtils.updateDateForTZ(
      reserveDate,
      grouping?.location?.timeZoneId,
      Moment().tz()
    );

    selectLocationWithArgs(grouping);

    history.push({
      pathname: DESK_RESERVATION_PATH,
      search: `?selectedDate=${newDate}&${grouping.idGrouping}`,
    });
  };

  const closeEdit = () => setSelectedReservation({});

  const toggleViewAllReservations = () => {
    setViewAllReservationsOpen(!isViewAllReservationsOpen);
  };

  const handleOnChangeDate = intUnixDate => {
    return setDate(intUnixDate);
  };

  const upcomingReservationsUpdatedHandler = () => {
    setUpcomingRefreshCount(prevValue => prevValue + 1);
  };

  return (
    <div
      className={classes.backgroundImageContainer}
      style={{
        backgroundImage: `url(${background})`,
      }}
      datatestid="desk_reserve_background_image"
    >
      <div className={classes.container}>
        <div className={clsx(classes.content)}>
          <div className={classes.formAndTitleContainer}>
            <div>
              <Text
                text={t('deskReservation.title')}
                className={classes.textTitle}
                datatestid="desk_reserve_title"
              />
            </div>
            <div className={classes.formContainer}>
              <ReserveDeskForm
                profile={profile}
                onFindDesk={handleOnFindDesk}
                date={date}
                onChangeDate={handleOnChangeDate}
                datatestid="desk_reserve_form"
              />
            </div>
          </div>
          <UpcomingAndAllReservations
            datatestid="desk_upcoming_reservations"
            history={history}
            upcomingUrlControlType={RESERVATION_TYPES.DESK_RESERVATION}
            upcomingUrlPathname={DESK_LANDING_PATH}
            upcomingRefreshCount={upcomingRefreshCount}
            onUpcomingReservationsUpdated={upcomingReservationsUpdatedHandler}
          />
        </div>
      </div>

      <ReserveDeskWithApi
        isOpen={!Lodash.isEmpty(selectedReservation)}
        onClose={closeEdit}
        isCancel
        reservations={[selectedReservation]}
        history={history}
      />

      <MyReservations
        isOpen={isViewAllReservationsOpen}
        onClose={toggleViewAllReservations}
        history={history}
      />
    </div>
  );
};

const styles = theme => ({
  backgroundImageContainer: {
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
  },
  textTitle: {
    fontFamily: 'VerlagBold',
    color: colors.white,
    fontSize: 24,
    letterSpacing: '0.8px',
    [theme.breakpoints.up(breakpoints.MOBILE)]: {
      fontSize: 38,
      letterSpacing: '1.27px',
    },
  },
  formContainer: {
    marginTop: 15,
    [theme.breakpoints.up(breakpoints.MOBILE)]: {
      width: '56%',
    },
    width: '77%',
  },
  container: {
    height: '100%',
    position: 'relative',
    overflowX: 'hidden',
    display: 'flex',
    minHeight: 'calc(100vh - 60px)',
    overflowY: 'hidden',
    backgroundImage:
      'linear-gradient(to bottom, rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0))',
    [theme.breakpoints.up(breakpoints.MOBILE)]: {
      minHeight: 'calc(100vh - 60px)',
    },
  },
  formAndTitleContainer: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    marginTop: '17vh',
    maxWidth: '100vw',
    [theme.breakpoints.up(breakpoints.MOBILE)]: {
      marginTop: '28vh',
    },
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
});

DeskReservationLanding.propTypes = {
  profile: PropTypes.object.isRequired,
  selectedGrouping: PropTypes.object.isRequired,
  setDate: PropTypes.func.isRequired,
  setGrouping: PropTypes.func.isRequired,
  setFloor: PropTypes.func.isRequired,
  loadLocations: PropTypes.func.isRequired,
  selectedFloor: PropTypes.object.isRequired,
  addFloors: PropTypes.func.isRequired,
  selectedDate: PropTypes.number.isRequired,
};

const mapStateToProps = state => {
  return {
    profile: getUserProfile(state),
    locationsById: getLocationsById(state),
    selectedGrouping: getSelectedGrouping(state),
    selectedDate: getSelectedDate(state),
    selectedFloor: getSelectedFloor(state),
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      setGrouping: reserveDeskRedux.actions.setGrouping,
      setFloor: reserveDeskRedux.actions.setFloor,
      setDate: reserveDeskRedux.actions.setDate,
      loadLocations: locationsRedux.actions.addLocations,
      addFloors: floorsRedux.actions.addFloors,
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(DeskReservationLanding));
