import Components from '../../Components';
import {
  getIsDecisionMaker,
  getIsManager,
  getIsVirtualMember,
} from '../../Core/Utils/userPermissions';
import { ACCOUNT_ACTIVITY_PATH } from './constants';

const { containerTypes } = Components.Routes;

const RouteConfig = {
  path: ACCOUNT_ACTIVITY_PATH,
  exact: true,
  name: 'navigation.menu.account_activity',
  private: true,
  type: containerTypes.CONTENT,
  isAuthorized: ({ profile }) => {
    return (
      getIsDecisionMaker(profile) ||
      getIsManager(profile) ||
      getIsVirtualMember(profile)
    );
  },
  isMenuItemHidden: ({ profile }) => {
    return !getIsVirtualMember(profile);
  },
};

export default RouteConfig;
