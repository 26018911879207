import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';

import {
  OfficeCard,
  OfficeName,
  OfficeFloor,
  RoundedButton,
  TextButton,
} from '../../../Components/Common';
import { colors, breakpoints } from '../../../Core/Theme';
import { OfficeTypes, OfficeStatuses } from '../../../Models/office';
import {
  activateReservableOffice,
  deactivateReservableOffice,
} from '../../../Core/Api';
import DeskItemToggle from './DeskItemToggle';

import { Office } from '../../../Models';
import { useFloors } from '../../../Hooks';
import OfficeDeskReservableResume from './OfficeDeskReservableResume';
import OfficeNotReservableWarningDialog from '../../ManageOffices/Components/OfficeNotReservableWarningDialog';
import { SelectOfficeVariants } from '../../ManageOffices/Components/SelectOfficeVariants';
import SelectOfficeType from '../../ManageOffices/Components/SelectOfficeType';

const OfficeAssign = ({
  classes,
  office,
  selectedGrouping,
  handleOfficeListSuccess,
  deskAvailabilities,
  isDisabled,
  onEditBtnClicked,
  hasFutureReservation,
}) => {
  const { t } = useTranslation();
  const { floors } = useFloors();
  const [officeType, setOfficeType] = useState(Office.getOfficeType(office));

  const [showDesksList, setDesksList] = useState(false);
  const [successMsg, setSuccessMsg] = useState(false);
  const [popupError, setPopupError] = useState(false);
  const [showOfficeNotReservableWarning, setShowOfficeNotReservableWarning] =
    useState(false);
  const nbReservableDesks = office.desks.filter(d => d.isBookable).length;
  const officeName = Office.getOfficeName(office);
  const floorName = floors.find(
    floor => floor.idFloor === office.floorUuid
  )?.floorName;

  const officeTypeOptions = [
    {
      value: OfficeTypes.Reservable,
      label: t('manageOffices.office_types.reservable'),
    },
    {
      value: OfficeTypes.UnReservable,
      label: t('manageOffices.office_types.unreservable'),
    },
  ];

  useEffect(() => {
    setOfficeType(Office.getOfficeType(office));
  }, [office]);

  const handleOfficeResponse = (objResponse, officeTypeId) => {
    const { data } = objResponse;
    if (data) {
      setSuccessMsg(true);
      handleOfficeListSuccess(data);

      setTimeout(() => {
        setSuccessMsg(false);
      }, 4000);
    } else {
      setPopupError(true);
      setOfficeType(
        officeTypeId === OfficeTypes.UnReservable
          ? OfficeTypes.Reservable
          : OfficeTypes.UnReservable
      );
    }
  };

  const deactivateOffice = officeTypeId => {
    deactivateReservableOffice(office.sfId).then(objResponse =>
      handleOfficeResponse(objResponse, officeTypeId)
    );
  };

  const handleSelectOfficeType = officeTypeId => {
    if (officeTypeId === OfficeTypes.UnReservable) {
      if (hasFutureReservation) {
        setShowOfficeNotReservableWarning(true);
      } else {
        setOfficeType(officeTypeId);
        deactivateOffice(officeTypeId);
      }
    }
    if (officeTypeId === OfficeTypes.Reservable) {
      setOfficeType(officeTypeId);
      activateReservableOffice(office.sfId, selectedGrouping.idGrouping).then(
        objResponse => handleOfficeResponse(objResponse, officeTypeId)
      );
    }
  };

  const handleOnClosePopupError = () => {
    setPopupError(false);
  };

  const handleUnreservableCancelled = () => {
    setShowOfficeNotReservableWarning(false);
  };

  const handleUnreservableConfirmed = () => {
    setShowOfficeNotReservableWarning(false);
    deactivateOffice(OfficeTypes.UnReservable);
  };

  const handleDeskToggleSuccess = udpatedDesks => {
    handleOfficeListSuccess({
      ...office,
      desks: udpatedDesks,
    });
  };

  const handleDeskToggleError = () => {
    handleOfficeListSuccess(office);
    setPopupError(true);
  };

  const handleEditBtnClicked = event => {
    event.stopPropagation();
    onEditBtnClicked();
  };

  const selectVariant =
    officeType === OfficeTypes.Reservable
      ? SelectOfficeVariants.Active
      : SelectOfficeVariants.Default;

  return (
    <>
      <OfficeCard
        className={clsx(
          !isDisabled && classes.card,
          isDisabled && classes.disabled
        )}
      >
        <div
          className={clsx(!isDisabled && classes.clickable)}
          onClick={() => !isDisabled && setDesksList(!showDesksList)}
          aria-hidden="true"
        >
          {office.status === OfficeStatuses.Licensed && (
            <div className={classes.licensedOffice}>
              {t('general.licensed')}
            </div>
          )}
          <div className={classes.header}>
            <div>
              <OfficeName name={officeName} />
              <OfficeFloor floorName={floorName} />
            </div>

            <TextButton
              text={t('manageOffices.edit_office')}
              onClick={handleEditBtnClicked}
              className={classes.editButton}
            />
          </div>

          <div className={classes.reservableDesksMobile}>
            {t('manageOffices.desks_reservable', {
              nbDesks: nbReservableDesks,
              count: office.desks.length,
            })}
          </div>
          <div className={classes.actions}>
            <div className={classes.officeStatus}>
              <div className={classes.officeStatusLabel}>
                {t('manageOffices.select_office_status_lbl')}
              </div>
              <div className={classes.officeStatusSelect}>
                <SelectOfficeType
                  options={officeTypeOptions.map(option => ({
                    ...option,
                    hidden: option.value === officeType,
                  }))}
                  onChange={handleSelectOfficeType}
                  value={officeType}
                  variant={selectVariant}
                  className={classes.selectInput}
                  disabled={isDisabled}
                />
                {successMsg && (
                  <span className={classes.successMsg}>
                    {t('manageOffices.saved')}
                  </span>
                )}
              </div>
            </div>

            <OfficeDeskReservableResume
              nbReservableDesks={nbReservableDesks}
              nbTotalDesks={office.desks.length}
              isDisabled={isDisabled}
              isDesksDisplayed={showDesksList}
            />
          </div>
        </div>
        {showDesksList && office.desks.length > 0 && (
          <div className={classes.desks}>
            {office.desks
              .sort((a, b) => a.deskNumber - b.deskNumber)
              .map(desk => (
                <DeskItemToggle
                  key={desk.idDesk}
                  desk={desk}
                  isDisabled={officeType === OfficeTypes.UnReservable}
                  allDesks={office.desks}
                  handleDeskToggleSuccess={handleDeskToggleSuccess}
                  handleDeskToggleError={handleDeskToggleError}
                  deskAvailabilities={deskAvailabilities}
                  selectedGrouping={selectedGrouping.idGrouping}
                  locationSfId={selectedGrouping.locationSfId}
                />
              ))}
          </div>
        )}
      </OfficeCard>
      <Dialog
        open={popupError}
        onClose={handleOnClosePopupError}
        classes={{
          paper: classes.containerPopup,
        }}
      >
        <>
          <div className={classes.titlePopup}>
            {t('manageOffices.popup_error.title')}
          </div>
          <div>{t('manageOffices.popup_error.description')}</div>
          <RoundedButton
            className={classes.buttonPopup}
            onClick={handleOnClosePopupError}
          >
            {t('general.close')}
          </RoundedButton>
        </>
      </Dialog>
      {selectedGrouping.locationSfId && (
        <OfficeNotReservableWarningDialog
          open={showOfficeNotReservableWarning}
          onCancel={handleUnreservableCancelled}
          onConfirm={handleUnreservableConfirmed}
          officeName={officeName}
        />
      )}
    </>
  );
};

const styles = theme => ({
  card: {
    '&:hover': {
      boxShadow: '0 0 7px 1px rgba(61, 50, 50, 0.25)',
    },
  },
  disabled: {
    color: colors.subtleGray,
  },
  clickable: {
    cursor: 'pointer',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 10,
    [theme.breakpoints.up(breakpoints.MOBILE)]: {
      marginBottom: 15,
    },
  },
  licensedOffice: {
    fontSize: 12,
    fontFamily: 'VerlagBold',
    textTransform: 'uppercase',
    marginBottom: 16,
    letterSpacing: 0.3,
  },
  officeAmenityList: {
    marginBottom: 10,
    [theme.breakpoints.up(breakpoints.MOBILE)]: {
      marginBottom: 15,
    },
  },
  editOfficeName: {
    display: 'none',
    position: 'relative',
    [theme.breakpoints.up(breakpoints.MOBILE)]: {
      display: 'flex',
    },
  },
  actions: {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'space-between',
    [theme.breakpoints.up(breakpoints.MOBILE)]: {
      alignItems: 'end',
    },
  },
  selectInput: {
    marginRight: 12,
    fontFamily: 'Verlag',
  },
  officeStatus: {
    display: 'flex',
    flexDirection: 'column',
  },
  officeStatusLabel: {
    fontSize: 12,
    textTransform: 'uppercase',
    fontFamily: 'VerlagBold',
    marginBottom: 8,
  },
  officeStatusSelect: {
    display: 'flex',
    alignItems: 'center',
  },
  successMsg: {
    color: colors.palette.primary.main,
    fontFamily: 'VerlagBold',
    fontSize: 18,
  },
  reservableDesksMobile: {
    fontSize: 14,
    marginBottom: 10,

    [theme.breakpoints.up(breakpoints.MOBILE)]: {
      display: 'none',
    },
  },
  containerPopup: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '16px 30px',
    fontSize: 18,
    textAlign: 'center',
  },
  titlePopup: {
    fontFamily: 'VerlagBold',
    marginBottom: 20,
  },
  buttonPopup: {
    margin: '16px 20px 0',
    width: 150,
  },
  editButton: {
    textDecoration: 'underline',
    fontFamily: 'VerlagBold',
    cursor: 'pointer',
  },
  desks: {
    marginTop: 12,
  },
  hideOnMobile: {
    [theme.breakpoints.down(breakpoints.MOBILE)]: {
      display: 'none',
    },
  },
  showOnlyOnMobile: {
    [theme.breakpoints.up(breakpoints.MOBILE)]: {
      display: 'none',
    },
  },
});

OfficeAssign.propTypes = {
  classes: PropTypes.object.isRequired,
  office: PropTypes.object.isRequired,
  selectedGrouping: PropTypes.object.isRequired,
  handleOfficeListSuccess: PropTypes.func.isRequired,
  deskAvailabilities: PropTypes.arrayOf(PropTypes.object).isRequired,
  isDisabled: PropTypes.bool.isRequired,
  onEditBtnClicked: PropTypes.func.isRequired,
  hasFutureReservation: PropTypes.bool.isRequired,
};

export default withStyles(styles)(OfficeAssign);
