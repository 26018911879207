const valObjCntr = (success, message) => {
  return {
    success,
    message,
  };
};

const message =
  'The entered password does not fulfill the password requirements. Please enter a different password.';

function pwValidate(password) {
  if (password.length < 8) {
    return valObjCntr(false, message);
  }

  if (!/[A-Z]/g.test(password)) {
    return valObjCntr(false, message);
  }

  if (!/[a-z]/g.test(password)) {
    return valObjCntr(false, message);
  }
  if (!/[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/g.test(password)) {
    return valObjCntr(false, message);
  }
  if (!/[0-9]/g.test(password)) {
    return valObjCntr(false, message);
  }

  return valObjCntr(true, 'ok');
}

export default pwValidate;
