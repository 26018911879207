/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { withStyles } from '@material-ui/core/styles';
import Popover from '@material-ui/core/Popover';
import Lodash from 'lodash';
import PropTypes from 'prop-types';

// Theme
import { breakpoints, colors } from '../../../../Core/Theme';

import CheckBox from '../../CheckBox';
import SubmitButton from '../../SubmitButton';

const FilterPopover = props => {
  const {
    classes,
    items,
    onFilter,
    anchorRef,
    onClose,
    onClickItem,
    activeItems,
  } = props;

  return (
    <Popover
      open={Boolean(anchorRef)}
      anchorEl={anchorRef}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      onClose={onClose}
      classes={{ paper: classes.popover }}
    >
      <div className={classes.rootPopover}>
        {items &&
          items.map(objItem => {
            const isChecked = Lodash.includes(activeItems, objItem.value);
            return (
              <div className={classes.itemCheckbox}>
                <CheckBox
                  id={objItem.value}
                  onChange={() => onClickItem(objItem.value)}
                  checked={isChecked}
                  checkBoxText={objItem.label}
                  inputClasses={{
                    label: classes.labelItem,
                  }}
                  checkedColor={
                    isChecked ? colors.palette.primary.main : colors.dark
                  }
                />
                {objItem.description && (
                  <div className={classes.description}>
                    {objItem.description}
                  </div>
                )}
              </div>
            );
          })}
        <SubmitButton defaultText="Filter" onClick={onFilter} />
      </div>
    </Popover>
  );
};

const styles = theme => ({
  labelItem: {
    fontFamily: 'VerlagBold',
    fontSize: 15,
  },
  itemCheckbox: {
    marginBottom: -12,
  },
  rootPopover: {
    padding: 15,
  },
  popover: {
    boxShadow: '0 0 4px 1px rgba(0, 0, 0, 0.1)',
    borderRadius: 4,
    display: 'none',
    [theme.breakpoints.up(breakpoints.MOBILE)]: {
      display: 'block',
    },
  },
  filterColumnButton: {
    height: 32,
    width: 32,
    [theme.breakpoints.up(breakpoints.MOBILE)]: {
      height: 15,
      width: 15,
    },
  },
  description: {
    fontSize: 15,
    fontFamily: 'VerlagBook',
    opacity: 0.5,
    paddingLeft: 34,
    marginTop: -15,
    marginBottom: 20,
  },
});

FilterPopover.propTypes = {
  items: PropTypes.arrayOf(PropTypes.string).isRequired,
  activeItems: PropTypes.arrayOf(PropTypes.string).isRequired,
  onFilter: PropTypes.func.isRequired,
  anchorRef: PropTypes.shape({}).isRequired,
  onClose: PropTypes.func.isRequired,
  onClickItem: PropTypes.func.isRequired,
};
export default withStyles(styles)(FilterPopover);
