import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import clsx from 'clsx';

const EmptyState = props => {
  const {
    classes,
    imageSrc,
    imageSrcSet,
    imageAlt,
    title,
    description,
    imageClassName,
  } = props;

  return (
    <div className={classes.emptyState}>
      <img
        src={imageSrc}
        srcSet={imageSrcSet}
        alt={imageAlt}
        className={clsx(classes.emptyImage, imageClassName)}
      />
      <div className={classes.emptyTitle}>{title}</div>
      <div className={classes.emptyDescription}>{description}</div>
    </div>
  );
};

const styles = {
  emptyState: {
    textAlign: 'center',
    maxWidth: 250,
    display: 'block',
    margin: 'auto',
  },
  emptyTitle: {
    fontFamily: 'VerlagBold',
    fontSize: 24,
  },
  emptyDescription: {
    fontFamily: 'VerlagLight',
    fontSize: 18,
    marginTop: 10,
  },
  emptyImage: {
    marginTop: 80,
    width: 80,
    height: 80,
    marginBottom: 60,
  },
};
EmptyState.defaultProps = {
  imageSrcSet: undefined,
  imageClassName: undefined,
};

EmptyState.propTypes = {
  imageSrc: PropTypes.string.isRequired,
  imageSrcSet: PropTypes.string,
  imageAlt: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  imageClassName: PropTypes.string,
};

export default withStyles(styles)(EmptyState);
