import React from 'react';

// Components
import { MapLayerWrapper as Layer } from '../../../Common';

// Types
import { LayerProperties } from '../../../Common/MapLayerWrapper/types';
import { Layers } from '../../types';
import { Legend } from '../types';
import { OfficeLayersProps } from './types';

// Theme & Utils
import { colors } from '../../../../Core/Theme';
import { createHash } from '../../../../Core/Utils';

const LAYER_ID = Layers.AvailableOffice;

// Layer properties
const fillProperties: LayerProperties = {
  id: LAYER_ID,
  type: 'fill',
  source: 'features',
  paint: {
    'fill-color': colors.greenLight,
  },
};
const lineProperties: LayerProperties = {
  id: `${LAYER_ID}-lines`,
  type: 'line',
  source: 'features',
  paint: {
    'line-color': colors.green,
    'line-width': 1,
    'line-opacity': 0.6,
  },
};

// Legend
const legend: Legend = {
  key: LAYER_ID,
  style: {
    backgroundColor: colors.greenLight,
    borderColor: colors.green,
    borderWidth: 1,
    borderStyle: 'solid',
  },
  labelKey: 'floorPlan.legend.availableOffice',
};

const AvailableOffice = (props: OfficeLayersProps) => {
  const { featureIds } = props;

  return (
    <Layer
      key={`${LAYER_ID}-${createHash(featureIds)}`}
      properties={[fillProperties, lineProperties]}
      filter={['in', ['get', 'id'], ['literal', featureIds]]}
      {...props}
    />
  );
};

export { legend };
export default React.memo(AvailableOffice);
