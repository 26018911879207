import { createSelector } from 'reselect';
import Lodash from 'lodash';
import { getRoomsForCurrentCalendarLocation } from '../Rooms/selectors';
import { date, isBookingAvailable } from '../../../Core/Utils';
import {
  getSelectedDate,
  getCurrentLocationSfId,
  getRoomIdFromProps,
  getStartEpoch,
  getEndEpoch,
  getBookingId,
  getSelectedGroupingId,
} from '../../../Containers/RoomBooking/Redux/selectors';
import { getLocationsById } from '../Locations/selectors';

export const getBookingsReducers = state => Lodash.get(state, 'bookings', {});

const getBookingsById = createSelector(getBookingsReducers, bookings => {
  return Lodash.get(bookings, ['bookingData', 'byId'], {});
});
const getBookingsAllIds = createSelector(getBookingsReducers, bookings => {
  return Lodash.get(bookings, ['bookingData', 'allIds'], []);
});
const getUsersById = createSelector(getBookingsReducers, bookings => {
  return Lodash.get(bookings, ['users', 'byId'], {});
});

export const getCompleteBookings = createSelector(
  [getBookingsAllIds, getBookingsById, getUsersById],
  (bookingsAllIds, bookingsById, usersById) => {
    const completedBookings = bookingsAllIds.map(id => {
      const booking = Lodash.get(bookingsById, id, {});
      const { user } = booking;
      const userComplete = Lodash.get(usersById, user, null);
      return {
        ...booking,
        user: userComplete,
      };
    });
    return completedBookings;
  }
);

export const getCompleteBookingsForSelectedDateAndLocationCalendar =
  createSelector(
    [
      getCompleteBookings,
      getSelectedDate,
      getCurrentLocationSfId,
      getSelectedGroupingId,
      getLocationsById,
    ],
    (bookings, selectedDate, locationSfId, idGrouping, locationsById) => {
      const bookingsFilterByDate = bookings
        .filter(booking => {
          return (
            date.isSameDate(booking.startEpoch, selectedDate) &&
            Lodash.get(booking, ['space', 'locationSfId'], 'a') ===
              locationSfId &&
            Lodash.get(booking, ['space', 'groupingId'], 'a') === idGrouping
          );
        })
        .map(b => ({
          ...b,
          space: { ...b.space, location: locationsById[b.space.locationSfId] },
        }));
      return bookingsFilterByDate;
    }
  );

export const getBookingsForRoom = createSelector(
  [getCompleteBookingsForSelectedDateAndLocationCalendar, getRoomIdFromProps],
  (bookings, roomId) => {
    return bookings.filter(booking => {
      return booking.spaceSfId === roomId;
    });
  }
);

export const getIsBookingInRange = createSelector(
  [getBookingsForRoom, getStartEpoch, getEndEpoch, getBookingId],
  (bookings, startEpoch, endEpoch, selectedIdBooking) => {
    if (Lodash.isEmpty(bookings)) {
      return false;
    }

    const booking = bookings.filter(objBooking => {
      if (selectedIdBooking === objBooking.idBooking) {
        return false;
      }
      return isBookingAvailable(
        startEpoch,
        endEpoch,
        objBooking.startEpoch,
        objBooking.endEpoch
      );
    });
    if (booking.length) {
      return true;
    }

    return false;
  }
);

export const getBookingsFilteredBySpaces = createSelector(
  [
    getCompleteBookingsForSelectedDateAndLocationCalendar,
    getRoomsForCurrentCalendarLocation,
  ],
  (bookings, filteredRooms) => {
    return bookings.filter(booking => filteredRooms.byId[booking.spaceSfId]);
  }
);
