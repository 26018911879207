import {
  StyleRulesCallback,
  Theme,
  withStyles,
} from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import { useTranslation } from 'react-i18next';

import OfficeNotReservableWarning, {
  WarningProps,
} from './OfficeNotReservableWarning';
import { Styled } from '../../../Core/Types';
import { breakpoints } from '../../../Core/Theme';
import { RoundedButton, RoundedButtonTypes } from '../../../Components/Common';

type Props = {
  open: boolean;
  onCancel: () => void;
  onConfirm: () => void;
} & WarningProps &
  Styled;

const OfficeNotReservableWarningDialog = (props: Props) => {
  const { classes, open, onCancel, onConfirm, ...warningProps } = props;
  const { t } = useTranslation();

  return (
    <Dialog
      open={open}
      onClose={onCancel}
      classes={{
        paper: classes.container,
      }}
    >
      <>
        <OfficeNotReservableWarning {...warningProps} />

        <div className={classes.btnContainer}>
          <RoundedButton
            className={classes.button}
            onClick={onCancel}
            type={RoundedButtonTypes.Secondary}
          >
            {t('manageOffices.warning_office_not_reservable.cta_no')}
          </RoundedButton>
          <RoundedButton className={classes.button} onClick={onConfirm}>
            {t('manageOffices.warning_office_not_reservable.cta_yes')}
          </RoundedButton>
        </div>
      </>
    </Dialog>
  );
};

const styles: StyleRulesCallback<string> = (theme: Theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '16px 30px',
    fontSize: 18,
    textAlign: 'center',
  },
  btnContainer: {
    display: 'flex',
    flexDirection: 'column-reverse',
    [theme.breakpoints.up(breakpoints.MOBILE)]: {
      flexDirection: 'row',
    },
  },
  button: {
    marginRight: 20,
    width: 150,
  },
});

export default withStyles(styles)(OfficeNotReservableWarningDialog);
