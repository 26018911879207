/* eslint-disable react/no-array-index-key */
/* eslint-disable react/require-default-props */
import { Layer } from 'react-map-gl';
import { LayerProperties } from './types';

type LayerComponentProps = {
  properties: LayerProperties | LayerProperties[];
  filter: any[];
  layout?: object;
};

const LayerComponent = (props: LayerComponentProps) => {
  const { properties } = props;

  if (Array.isArray(properties)) {
    return (
      <>
        {properties.map((propertyItem, index) => {
          return (
            <Layer
              key={`${index}-layer-${propertyItem.id}`}
              {...propertyItem}
              {...props}
            />
          );
        })}
      </>
    );
  }

  return <Layer key={`layer-${properties.id}`} {...properties} {...props} />;
};

export default LayerComponent;
