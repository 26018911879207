import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import { colors, breakpoints } from '../../../Core/Theme';
import { Tooltip, LabelChip } from '../../../Components/Common';

const ReserveButton = props => {
  const {
    cta,
    className,
    classes,
    onClick,
    description,
    unit,
    iconSrc,
    isDisabled,
    isBeta,
    tooltipText,
    ...rest
  } = props;
  const { t } = useTranslation();

  const renderButtonContent = () => (
    <div>
      <Button
        variant="outlined"
        {...rest}
        disabled={isDisabled}
        className={clsx(
          classes.container,
          className,
          isDisabled && classes.containerDisabled
        )}
        onClick={onClick}
        focusVisibleClassName={classes.focusVisible}
        classes={{ label: classes.content }}
      >
        <div
          className={clsx(
            classes.imageBox,
            isDisabled && classes.imageBoxDisabled
          )}
        >
          {iconSrc ? (
            <img className={classes.icon} src={iconSrc} alt="right" />
          ) : null}
          {unit && <div className={classes.unit}>{unit.toUpperCase()}</div>}
        </div>
        <div className={classes.info}>
          <div>
            <span className={classes.cta}>{cta}</span>
            {isBeta && (
              <LabelChip
                className={classes.labelChip}
                label={t('navigation.beta')}
              />
            )}
          </div>
          <div className={classes.description}> {description}</div>
        </div>
      </Button>
    </div>
  );

  return tooltipText ? (
    <Tooltip
      interactive
      placement="bottom"
      title={tooltipText}
      anchorContent={renderButtonContent}
    />
  ) : (
    renderButtonContent()
  );
};
const styles = theme => ({
  focusVisible: {
    background: `${colors.white} !important`,
    boxShadow: '0 0 7px 1px rgba(0, 0, 0, 0.25)',
  },
  container: {
    background: `${colors.white} !important`,
    borderRadius: 2,
    boxShadow: '0 0 4px 0 rgba(0, 0, 0, 0.1)',
    border: `solid 1px ${colors.middle} !important`,
    textTransform: 'none',
    padding: 0,
    width: '100%',
    color: `${colors.black} !important`,
    justifyContent: 'flex-start',
    height: 135,
    '&:hover': {
      boxShadow: '0 0 7px 1px rgba(0, 0, 0, 0.25)',
    },
  },
  containerDisabled: {
    boxShadow: 'none',
    borderColor: `${colors.light} !important`,
    color: `${colors.subtleGray} !important`,
  },
  imageBoxDisabled: {
    opacity: 0.5,
  },
  content: {
    display: 'flex',
    flexDirection: 'row',
    height: '100%',
  },
  info: {
    textAlign: 'left',
    paddingLeft: 15,
    paddingRight: 15,
  },
  cta: {
    fontFamily: 'VerlagBold',
    fontSize: 16,
    lineHeight: '1em',
    [theme.breakpoints.up(breakpoints.MOBILE)]: {
      fontSize: 21,
    },
  },
  description: {
    fontFamily: 'VerlagLight',
    fontSize: 14,
    lineHeight: '1.3em',
    [theme.breakpoints.up(breakpoints.MOBILE)]: {
      fontSize: 16,
      fontFamily: 'VerlagBook',
    },
    [theme.breakpoints.up(breakpoints.LG)]: {
      paddingTop: 5,
    },
  },
  imageBox: {
    height: '100%',
    aspectRatio: 1,
    position: 'relative',
    background: colors.palette.secondary2.main,
  },
  icon: {
    width: '100%',
    height: '100%',
  },
  labelChip: {
    marginLeft: 10,
    color: colors.textPalette.link.main,
    border: `1px solid ${colors.textPalette.link.main}`,
    fontSize: 12,
  },
  unit: {
    color: colors.white,
    backgroundColor: colors.palette.tertiary1.main,
    position: 'absolute',
    left: 0,
    right: 0,
    bottom: 0,
    fontSize: 12,
    borderTopRightRadius: 2,
    borderTopLeftRadius: 2,
    [theme.breakpoints.up(breakpoints.MOBILE)]: {
      fontSize: 15,
    },
  },
});

ReserveButton.defaultProps = {
  onClick: () => '',
  className: '',
  isDisabled: false,
  isBeta: false,
  tooltipText: '',
  unit: '',
};
ReserveButton.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  onClick: PropTypes.func,
  cta: PropTypes.string.isRequired,
  className: PropTypes.string,
  description: PropTypes.string.isRequired,
  unit: PropTypes.string,
  iconSrc: PropTypes.string.isRequired,
  isDisabled: PropTypes.bool,
  isBeta: PropTypes.bool,
  tooltipText: PropTypes.string,
};
export default withStyles(styles)(ReserveButton);
