import React from 'react';
import PropTypes from 'prop-types';

// Components
import { MapLayerWrapper as Layer } from '../../Common';

// Constants
import { LAYERS } from '../Constants';

// Theme
import { colors } from '../../../Core/Theme';

// Layer properties
const fillProperties = {
  id: LAYERS.UNAVAILABLE_OFFICE,
  type: 'fill',
  source: 'features',
  paint: {
    'fill-color': colors.palette.secondary2.dark,
  },
};
const lineProperties = {
  id: `${LAYERS.UNAVAILABLE_OFFICE}-lines`,
  type: 'line',
  source: 'features',
  paint: {
    'line-color': colors.palette.secondary2.darkest,
    'line-width': 2,
  },
};

const UnavailableOffice = props => {
  const { offices } = props;
  return (
    <Layer
      properties={[fillProperties, lineProperties]}
      filter={['in', ['get', 'id'], ['literal', offices]]}
      {...props}
    />
  );
};

UnavailableOffice.defaultProps = {
  offices: [],
};
UnavailableOffice.propTypes = {
  offices: PropTypes.arrayOf(PropTypes.string),
};

export default React.memo(UnavailableOffice);
