/* eslint-disable jsx-a11y/interactive-supports-focus */
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { getMonth, getYear } from 'date-fns';
import { Close } from '@material-ui/icons';
import Hidden from '@material-ui/core/Hidden';
import Moment from 'moment';
import { withStyles } from '@material-ui/core/styles';
import Lodash from 'lodash';
import { withTranslation } from 'react-i18next';

import { colors, breakpoints } from '../../../Core/Theme';
import TooltipContainer from './TooltipContainer';
import Month from './Month';
import ArrowRight from './ArrowRight';
import ArrowLeft from './ArrowLeft';
import Header from './Header';
import { getWindowDimensions } from '../../../Core/Utils';
import SubmitButton from '../SubmitButton';

export const Year = styled.span`
  user-select: none;
`;

const Container = styled.div`
  * {
    box-sizing: border-box;
  }

  &:focus {
    outline: none;
  }
`;

const MonthContainer = styled.div`
  background-color: ${colors.white};
  border-radius: 4px;
  display: flex;
  flex-wrap: wrap;
  margin: 0px auto;
  padding: 0 5px;
  padding-bottom: 8px;
  justify-content: center;
`;

const currentDate = new Date();
const currentYear = getYear(currentDate);
const SIDE_PADDING = 10;
class MonthPicker extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      year: props.year,
      open: false,
      month: props.month,
    };
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  // Other functions
  setWrapperRef = node => {
    this.wrapperRef = node;
  };

  changeValue = (date, event) => {
    const { onChange } = this.props;
    if (onChange) onChange(Moment(date).unix(), event);
  };

  close = () => {
    const { year, month } = this.props;

    const { onBlur } = this.props;
    const { open } = this.state;
    if (!open) return;
    this.setState({ open: false, year, month });
    if (onBlur) onBlur();
  };

  // Event handlers
  handleClickOutside = event => {
    const element = event.target;
    if (this.wrapperRef && !this.wrapperRef.contains(element)) {
      this.close(event);
    }
  };

  handleKeyDown = event => {
    const { open, year, month } = this.state;
    // const { month } = this.props;

    if (!open) return null;

    const current = Moment().month(month).year(year);
    switch (event.key) {
      case 'ArrowLeft': {
        event.preventDefault();

        const nextDate = current.subtract(1, 'M');
        this.setState({
          month: nextDate.month(),
          year: nextDate.year(),
          open: true,
        });
        return null;
      }

      case 'ArrowRight': {
        event.preventDefault();

        const nextDate = current.add(1, 'M');
        this.setState({
          month: nextDate.month(),
          year: nextDate.year(),
          open: true,
        });
        return null;
      }

      case 'ArrowUp': {
        event.preventDefault();

        const nextDate = current.subtract(4, 'M');
        this.setState({
          month: nextDate.month(),
          year: nextDate.year(),
          open: true,
        });
        return null;
      }

      case 'ArrowDown': {
        event.preventDefault();

        const nextDate = current.add(4, 'M');
        this.setState({
          month: nextDate.month(),
          year: nextDate.year(),
          open: true,
        });
        return null;
      }
      case 'Tab': {
        this.close();
        return null;
      }
      case 'Enter': {
        return this.changeValue(current, event);
      }

      default: {
        return null;
      }
    }
  };

  handleMonthChange = (month, isMobile) => event => {
    event.persist();
    const { year } = this.state;
    const date = Moment().month(month).year(year).startOf('month');
    if (isMobile) {
      this.setState({ month });
    } else {
      this.setState({ month, open: false });
      this.changeValue(date, event);
    }
  };

  handleTriggerClick = event => {
    event.persist();
    const { onFocus, onBlur } = this.props;
    const { open } = this.state;

    if (event.key && event.key === 'Tab') {
      onBlur();
      return;
    }

    this.toggleOpen();
    if (!open && onFocus) onFocus();
    if (open && onBlur) onBlur();
  };

  isAllowedYear = year => {
    const { allowedYears } = this.props;

    if (!allowedYears) return true;

    if (Array.isArray(allowedYears)) {
      return allowedYears.indexOf(year) >= 0;
    }

    const { before, after } = allowedYears;

    if (before && after) {
      return year < before && year > after;
    }

    if (before) return year < before;
    if (after) return year > after;

    return false;
  };

  nextYear = () => {
    const { allowedYears } = this.props;
    const { year } = this.state;

    if (Array.isArray(allowedYears)) {
      const sortedYears = allowedYears.sort((a, b) => a - b);
      const currentIndex = sortedYears.indexOf(year);

      if (currentIndex < sortedYears.length - 1) {
        const nextYear = sortedYears[currentIndex + 1];
        this.setState({ year: nextYear });
      }
    }

    const nextYear = year + 1;

    if (this.isAllowedYear(nextYear)) {
      this.setState({ year: nextYear });
    }
  };

  previousYear = () => {
    const { allowedYears } = this.props;
    const { year } = this.state;

    if (Array.isArray(allowedYears)) {
      const sortedYears = allowedYears.sort((a, b) => a - b);
      const currentIndex = sortedYears.indexOf(year);

      if (currentIndex > 0) {
        const previousYear = sortedYears[currentIndex - 1];
        this.setState({ year: previousYear });
      }
    }

    const previousYear = year - 1;

    if (this.isAllowedYear(previousYear)) {
      this.setState({ year: previousYear });
    }
  };

  submitMonth = () => {
    const { year, month } = this.state;
    const date = Moment().month(month).year(year).startOf('month');
    this.setState({ open: false });
    this.changeValue(date, {});
  };

  toggleOpen = () => {
    this.setState(({ open }) => ({ open: !open }));
  };

  // Render functions
  renderMonths = () => {
    const { year, month } = this.props;
    const stateYear = Lodash.get(this.state, 'year');
    const { width } = getWindowDimensions();
    const selectedMonth = month;
    const months = [];
    const monthWidth = (width - 2 * SIDE_PADDING) / 4;
    const monthMargin = (monthWidth - 60) / 2;
    const isMobile = width <= breakpoints.MOBILE;

    for (let index = 0; index < 12; index++) {
      const monthName = Moment().month(index).year(year).format('MMM');

      const isSelectedMonth =
        selectedMonth !== undefined &&
        index === selectedMonth &&
        year === stateYear;

      months.push(
        <Month
          selected={isSelectedMonth}
          key={monthName}
          onClick={this.handleMonthChange(index, isMobile)}
          index={index}
          style={
            isMobile
              ? {
                  width: 60,
                  margin: monthMargin,
                }
              : {}
          }
        >
          {monthName}
        </Month>
      );
    }

    return months;
  };

  render() {
    const { open, year } = this.state;
    const { className, dialogClassName, children, classes, t } = this.props;

    return (
      <Container
        className={className}
        tabIndex={-1}
        ref={this.setWrapperRef}
        onKeyDown={this.handleKeyDown}
      >
        <div
          aria-haspopup
          onClick={this.handleTriggerClick}
          onKeyDown={this.handleTriggerClick}
          style={{ cursor: 'pointer' }}
          role="button"
          aria-expanded={open}
        >
          {children}
        </div>
        {open && (
          <TooltipContainer className={dialogClassName || classes.popup}>
            <Header>
              <Hidden smUp implementation="css">
                <Close onClick={this.close} className={classes.closeX} />
                <div className={classes.title}>
                  {t('daypicker.select_a_month')}
                </div>
              </Hidden>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <ArrowLeft onClick={this.previousYear} />
                <Year>{year}</Year>
                <ArrowRight onClick={this.nextYear} />
              </div>
            </Header>
            <MonthContainer role="menu">{this.renderMonths()}</MonthContainer>
            <Hidden smUp implementation="css">
              <SubmitButton
                defaultText={t('daypicker.select_month')}
                className={classes.submitButton}
                onClick={this.submitMonth}
              />
            </Hidden>
          </TooltipContainer>
        )}
      </Container>
    );
  }
}

const styles = () => ({
  title: {
    fontFamily: 'VerlagBold',
    fontSize: '20px',
    marginBottom: 35,
    textAlign: 'center',
  },
  closeX: {
    width: 30,
    height: 30,
    marginLeft: '92%',
    marginBottom: '5px',
  },
  submitButton: {
    marginRight: 20,
    marginLeft: 20,
    width: 'calc(100% - 40px)',
    position: 'absolute',
    bottom: 30,
  },
  popup: {
    background: 'white',
    zIndex: 1600,
    position: 'fixed',
  },
});

MonthPicker.defaultProps = {
  month: getMonth(currentDate),
  year: currentYear,
  onBlur: () => '',
  onFocus: () => '',
  allowedYears: null,
  className: '',
  dialogClassName: '',
};

MonthPicker.propTypes = {
  allowedYears: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.number),
    PropTypes.shape({
      before: PropTypes.number,
      after: PropTypes.number,
    }),
  ]),
  month: PropTypes.number,
  year: PropTypes.number,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  onChange: PropTypes.func.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]).isRequired,
  className: PropTypes.string,
  dialogClassName: PropTypes.string,
  classes: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
};

export default withTranslation()(withStyles(styles)(MonthPicker));
