import axios from 'axios';

import getApiUrl from '../Utils/api';

const API_VERSION = '1.5.0';

axios.defaults.baseURL = getApiUrl(API_VERSION, process.env.REACT_APP_API_HOST);

export * from './dto';
export * from './amenitiesAPI';
export * from './userAPI';
export * from './bookingApi';
export * from './roomAPI';
export * from './occasionsAPI';
export * from './locationAPI';
export * from './interceptors';
export * from './suggestionsAPI';
export * from './receptionistAPI';
export * from './messagesAPI';
export * from './groupingAPI';
export * from './commonAPI';
export * from './officesAPI';
export * from './officeAvailabilitiesAPI';
export * from './accountsAPI';
export * from './onDemandAPI';
export * from './floorPlanAPI';
export * from './floorsAPI';
export * from './reservableDesksAPI';
export * from './reservationsAPI';
export * from './sfAuthAPI';
export * from './anyReservationsAPI';
export * from './seatBookings';
export * from './officeReservationsAPI';
export * from './occasionTypesAPI';
export * from './reccurenceRulesAPI';
export * from './rsvpsAPI';
