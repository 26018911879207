import styled, { keyframes } from 'styled-components';

import { colors } from '../../../Core/Theme';

const fadeIn = keyframes`
  from {
    transform: scaleY(0.95);
    opacity: 0;
  }

  to {
    transform: scaleY(1);
    opacity: 1;
  }
`;

const TooltipContainer = styled.div`
  margin-top: 8px;
  transform-origin: top center;
  animation: ${fadeIn} 0.2s;
  position: absolute;
  box-shadow: 0 0 3px 1px rgba(0, 0, 0, 0.25);
  border: 1px solid ${colors.dark};
  border-radius: 3px;
  z-index: 10;
  width: 303px;
  @media (max-width: 680px) {
    height: 100%;
    width: 100%;
    border: none;
    top: 0;
    left: 0;
    background-color: ${colors.white};
    margin-top: 0px;
  }
`;

export default TooltipContainer;
