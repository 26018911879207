import { StyleRulesCallback, withStyles } from '@material-ui/core';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import { useTranslation } from 'react-i18next';

type Props = {
  classes: ClassNameMap;
};

const ReservedByMe = (props: Props) => {
  const { classes } = props;
  const { t } = useTranslation();

  return (
    <div>
      <span className={classes.label}>
        {t<string>('reserveOffice.reserved_by_me')}
      </span>
    </div>
  );
};

const styles: StyleRulesCallback<string> = () => ({
  label: {
    fontSize: 14,
    fontFamily: 'VerlagBold',
    marginRight: '0.5rem',
    letterSpacing: 'normal',
  },
});

export default withStyles(styles)(ReservedByMe);
