/* eslint-disable  jsx-a11y/no-noninteractive-element-to-interactive-role */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable  jsx-a11y/click-events-have-key-events */

// inspired by https://codesandbox.io/s/draftjs-demo-pt-22-blog-lm535
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import React from 'react';
import { EditorState, RichUtils } from 'draft-js';
import Editor from 'draft-js-plugins-editor';
import styled from 'styled-components';
import clsx from 'clsx';
import { withTranslation } from 'react-i18next';

import addLinkPlugin from './addLinkPlugin';
import { Icons } from '../../../resources';
import { colors, breakpoints } from '../../../Core/Theme';
import FieldInput from '../FieldInput';
import TextButton from '../TextButton';
import Popover from '../Popover';

const LINKIFY_REGEX_EXACT =
  '(https?://(?:www.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9].[^s]{2,}|www.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9].[^s]{2,}|https?://(?:www.|(?!www))[a-zA-Z0-9]+.[^s]{2,}|www.[a-zA-Z0-9]+.[^s]{2,})';

const DRAFT_JS_CLASSES = {
  PLACEHOLDER: '.public-DraftEditorPlaceholder-inner',
  INPUT_BLOCK:
    '.public-DraftEditor-content > div > div:nth-child(1) > .public-DraftStyleDefault-block',
};

const RichTextPrefix = styled.div`
  ${props =>
    props.hasPlaceholder && !props.hasContent
      ? `${DRAFT_JS_CLASSES.PLACEHOLDER}`
      : DRAFT_JS_CLASSES.INPUT_BLOCK}:before {
    content: '${props => props.prefix}';
    color: ${props => props.prefixColor || colors.darkGray};
  }
   {
    color: ${props => (props.hasContent ? colors.black : colors.darkGray)};
  }
`;

class RichTextEditor extends React.Component {
  static getDerivedStateFromProps(props) {
    if (props.editorState) {
      return {
        editorState: props.editorState,
      };
    }
    return null;
  }

  constructor(props) {
    super(props);
    // this has to be stored in state or the links don't work
    const { editorState } = this.props;
    this.state = {
      editorState,
      isLinkBoxOpen: false,
      anchorEl: null,
      linkInput: null,
      selectedText: null,
      linkInputError: false,
      isFocus: false,
    };
    this.editor = React.createRef();
    this.linkInputRef = React.createRef();

    this.linkPaster = this.linkPaster.bind(this);
  }

  // runs when the user clicks apply to add a link
  onAddLink = () => {
    const { editorState, linkInput } = this.state;
    const selection = editorState.getSelection();
    if (!linkInput || !linkInput.match(LINKIFY_REGEX_EXACT)) {
      this.setState({ linkInputError: true });
      return;
    }
    if (!linkInput) {
      this.onChangeAndSetState(
        RichUtils.toggleLink(editorState, selection, null)
      );
      return;
    }

    const content = editorState.getCurrentContent();

    const contentWithEntity = content.createEntity('LINK', 'MUTABLE', {
      url: linkInput,
    });
    const newEditorState = EditorState.push(
      editorState,
      contentWithEntity,
      'create-entity'
    );
    const entityKey = contentWithEntity.getLastCreatedEntityKey();
    const newState = RichUtils.toggleLink(newEditorState, selection, entityKey);
    this.onChangeAndSetState(newState);
    this.closePopover();
  };

  onChangeAndSetState = editorState => {
    const { onChange, isDisabled } = this.props;
    if (!isDisabled) {
      this.setState({ editorState });
      onChange(editorState);
    }
  };

  getInlineStyles = () => [
    {
      icon: 'Bold',
      style: 'BOLD',
      onChange: this.toggleStyle('BOLD'),
    },
    {
      icon: 'Italic',
      style: 'ITALIC',
      onChange: this.toggleStyle('ITALIC'),
    },
    {
      icon: 'Underline',
      style: 'UNDERLINE',
      onChange: this.toggleStyle('UNDERLINE'),
    },
    { icon: 'Hyperlink', style: 'LINK', onChange: this.openLinkBox },
  ];

  getLinkFromBlocks = (contentState, selection) => {
    const { editorState } = this.state;
    if (!selection.isCollapsed()) {
      const startKey = editorState.getSelection().getStartKey();
      const startOffset = selection.getStartOffset();
      const blockWithLinkAtBeginning = contentState.getBlockForKey(startKey);
      const linkKey = blockWithLinkAtBeginning.getEntityAt(startOffset);
      if (linkKey) {
        const linkInstance = contentState.getEntity(linkKey);
        const { url } = linkInstance.getData();
        return url;
      }
    }
    return null;
  };

  getTextFromBlocks = (contentState, selection, blockDelimiter) => {
    const startKey = selection.getStartKey();
    const endKey = selection.getEndKey();
    const blocks = contentState.getBlockMap();

    let lastWasEnd = false;
    const selectedBlock = blocks
      .skipUntil(block => {
        return block.getKey() === startKey;
      })
      .takeUntil(block => {
        const result = lastWasEnd;

        if (block.getKey() === endKey) {
          lastWasEnd = true;
        }

        return result;
      });

    return selectedBlock
      .map(block => {
        const key = block.getKey();
        let text = block.getText();

        let start = 0;
        let end = text.length;

        if (key === startKey) {
          start = selection.getStartOffset();
        }
        if (key === endKey) {
          end = selection.getEndOffset();
        }

        text = text.slice(start, end);
        return text;
      })
      .join(blockDelimiter);
  };

  closePopover = () => {
    this.setState({
      isLinkBoxOpen: false,
      selectedText: null,
      linkInput: null,
      linkInputError: false,
      anchorEl: null,
    });
  };

  focus = () => {
    if (this.editor.current) {
      this.editor.current.focus();
    }
  };

  handleKeyCommand = command => {
    const { editorState } = this.state;
    const newState = RichUtils.handleKeyCommand(
      editorState,
      command,
      this.onChangeAndSetState
    );

    if (command === 'add-link') {
      this.setState({ isLinkBoxOpen: true });
    }
    if (newState) {
      this.onChangeAndSetState(newState);
      return 'handled';
    }
    return 'not-handled';
  };

  handleLinkType = linkInput => {
    this.setState({
      linkInput,
      linkInputError: !linkInput || !linkInput.match(LINKIFY_REGEX_EXACT),
    });
  };

  //  allows the user to highlight text and then paste the link onto it to make a hyperlink
  linkPaster = onChangeAndSetState => ({
    handlePastedText(text, html, editorState) {
      let nextState = editorState;
      if (text.match(LINKIFY_REGEX_EXACT)) {
        const selection = nextState.getSelection();

        if (!selection.isCollapsed()) {
          const content = editorState.getCurrentContent();
          const contentWithEntity = content.createEntity('LINK', 'MUTABLE', {
            url: text,
          });
          const newEditorState = EditorState.push(
            editorState,
            contentWithEntity,
            'create-entity'
          );
          const entityKey = contentWithEntity.getLastCreatedEntityKey();
          nextState = RichUtils.toggleLink(
            newEditorState,
            selection,
            entityKey
          );
          onChangeAndSetState(nextState);
          return 'handled';
        }
      }

      return 'not-handled';
    },
  });

  openLinkBox = e => {
    const { editorState } = this.state;
    const selection = editorState.getSelection();
    const contentState = editorState.getCurrentContent();
    const selectedText = this.getTextFromBlocks(contentState, selection, ' ');
    const linkInput = this.getLinkFromBlocks(contentState, selection);
    this.setState(
      {
        selectedText,
        linkInput,
        anchorEl: e.currentTarget,
        isLinkBoxOpen: true,
      },
      () => {
        setTimeout(() => {
          if (this.linkInputRef.current) {
            return this.linkInputRef.current.focus();
          }

          return null;
        }, 200);
      }
    );
  };

  toggleFocus = () =>
    this.setState(prevState => ({ isFocus: !prevState.isFocus }));

  toggleInlineStyle = inlineStyle => {
    const { editorState } = this.state;
    this.onChangeAndSetState(
      RichUtils.toggleInlineStyle(editorState, inlineStyle)
    );
  };

  toggleStyle = inlineStyle => e => {
    e.preventDefault();
    this.toggleInlineStyle(inlineStyle);
  };

  render() {
    const inlineStyles = this.getInlineStyles();
    const {
      editorState,
      isLinkBoxOpen,
      anchorEl,
      linkInput,
      selectedText,
      linkInputError,
      isFocus,
    } = this.state;
    const {
      classes,
      isError,
      isDisabled,
      placeholder,
      classNameRoot,
      classNameEditor,
      readOnlyPrefix,
      readOnlyPrefixColor,
      onBlur,
      datatestid,
      t,
    } = this.props;
    const styles = editorState.getCurrentInlineStyle();
    const textContent = editorState.getCurrentContent().getPlainText();
    return (
      <div
        className={clsx(
          !isDisabled && classNameRoot,
          isError && classes.bodyError
        )}
        datatestid={datatestid}
      >
        {isDisabled ? null : (
          <div className={classes.controls} onClick={this.focus}>
            {inlineStyles.map(type => (
              <button
                className={clsx(
                  classes.iconBox,
                  styles.has(type.style) && classes.selectedIconBox
                )}
                onMouseDown={type.onChange}
                key={type.icon}
                type="button"
              >
                <img
                  src={Icons[type.icon]}
                  alt={type.icon}
                  className={classes.icon}
                />
              </button>
            ))}
          </div>
        )}

        <div className={classNameEditor || classes.editor} onClick={this.focus}>
          {readOnlyPrefix ? (
            <RichTextPrefix
              hasPlaceholder={!isFocus}
              hasContent={textContent.length}
              prefix={readOnlyPrefix}
              prefixColor={readOnlyPrefixColor}
            >
              <Editor
                editorState={editorState}
                handleKeyCommand={this.handleKeyCommand}
                onChange={this.onChangeAndSetState}
                ref={this.editor}
                plugins={[
                  addLinkPlugin,
                  this.linkPaster(this.onChangeAndSetState),
                ]}
                readOnly={isDisabled}
                placeholder={isFocus ? null : placeholder}
                onBlur={() => {
                  this.toggleFocus();
                  onBlur();
                }}
                onFocus={this.toggleFocus}
              />
            </RichTextPrefix>
          ) : (
            <Editor
              editorState={editorState}
              handleKeyCommand={this.handleKeyCommand}
              onChange={this.onChangeAndSetState}
              ref={this.editor}
              plugins={[
                addLinkPlugin,
                this.linkPaster(this.onChangeAndSetState),
              ]}
              readOnly={isDisabled}
              placeholder={placeholder}
              onBlur={onBlur}
            />
          )}
        </div>
        <Popover
          anchorEl={isLinkBoxOpen ? anchorEl : null}
          onClose={this.closePopover}
          headerMessage={t('richTextEditor.add_hyperlink')}
          content={
            <div className={classes.popover}>
              <FieldInput
                className={
                  selectedText
                    ? classes.fieldInputStyle
                    : classes.fieldInputStyleNoBorder
                }
                value={selectedText}
                variant="standard"
                onChange={this.handleLinkType}
                placeholder={t('richTextEditor.text_placeholder')}
                InputProps={{
                  disableUnderline: true,
                  classes: {
                    input: classes.inputRootDisabled,
                  },
                }}
                disabled
                label={t('richTextEditor.text_label')}
                id="textInputRTE"
              />
              <FieldInput
                className={classes.fieldInputStyle}
                value={linkInput}
                placeholder={t('richTextEditor.link_placeholder')}
                variant="standard"
                onChange={this.handleLinkType}
                InputProps={{
                  disableUnderline: true,
                  classes: {
                    input: classes.inputRoot,
                  },
                }}
                label={t('richTextEditor.link_label')}
                id="linkInputRTE"
                hasError={linkInputError}
                errorMessage={t('richTextEditor.invalid_link')}
                inputRef={this.linkInputRef}
              />
              <TextButton
                text={t('richTextEditor.apply')}
                onClick={this.onAddLink}
                className={classes.applyButton}
                isDisabled={linkInputError}
              />
            </div>
          }
        />
      </div>
    );
  }
}

const styles = theme => ({
  bodyError: {
    borderColor: colors.alertMessage,
  },
  icon: {
    height: 13,
  },
  iconBox: {
    width: 26,
    borderRadius: 2,
    border: `solid 1px ${colors.middle}`,
    background: 'transparent',
    marginLeft: 10,
    cursor: 'pointer',
    paddingLeft: 6,
    paddingTop: 4,
    [theme.breakpoints.up(breakpoints.MOBILE)]: {
      marginLeft: 5,
    },
    '&:focus:not(.focus-visible)': {
      outline: 0,
    },
    '&:hover': {
      color: colors.black,
    },
  },
  selectedIconBox: {
    borderColor: colors.palette.primary.main,
    borderWidth: 1.5,
    paddingLeft: 4,
    paddingRight: 5,
  },
  controls: {
    textAlign: 'right',
    height: 25,
    paddingBottom: 5,
  },
  editor: {
    paddingTop: 10,
    paddingBottom: 5,
    fontFamily: 'VerlagBook',
    paddingLeft: 10,
    paddingRight: 5,
    fontSize: 18,
    border: `solid 1px ${colors.middle} !important`,
    borderRadius: 4,
  },
  editorError: {
    border: `solid 1px ${colors.alertMessage} !important`,
  },
  fieldInputStyle: {
    backgroundColor: colors.white,
    margin: '0px',
    marginTop: 10,
  },
  fieldInputStyleNoBorder: {
    border: 'none',
    backgroundColor: colors.white,
    margin: '0px',
    marginTop: 10,
  },
  inputRoot: {
    fontSize: 16,
  },
  inputRootDisabled: {
    fontSize: 16,
    color: colors.darkest,
  },
  popover: {
    width: 220,
  },
  applyButton: {
    paddingTop: 10,
    paddingBottom: 10,
    width: '100%',
    textAlign: 'center',
  },
  requiredErrorMessage: {
    fontFamily: 'VerlagLight',
    fontSize: 13,
    color: colors.alertMessage,
    paddingTop: 2,
  },
});

RichTextEditor.defaultProps = {
  isError: false,
  isDisabled: false,
  onChange: () => null,
  placeholder: null,
  classNameRoot: null,
  classNameEditor: null,
  readOnlyPrefix: null,
  readOnlyPrefixColor: null,
  onBlur: () => null,
  datatestid: 'rich_text',
};

RichTextEditor.propTypes = {
  onChange: PropTypes.func,
  editorState: PropTypes.object.isRequired,
  isError: PropTypes.bool,
  isDisabled: PropTypes.bool,
  placeholder: PropTypes.string,
  classNameRoot: PropTypes.string,
  classNameEditor: PropTypes.string,
  readOnlyPrefix: PropTypes.string,
  readOnlyPrefixColor: PropTypes.string,
  onBlur: PropTypes.func,
  datatestid: PropTypes.string,
  t: PropTypes.func.isRequired,
};

export default withTranslation()(withStyles(styles)(RichTextEditor));
