/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import * as React from 'react';
import 'react-dates/initialize';
import { SingleDatePicker } from 'react-dates';
import 'react-dates/lib/css/_datepicker.css';
import moment from 'moment';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { ArrowForwardIos, ArrowBackIos, Close } from '@material-ui/icons';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import {
  useWindowDimensions,
  date as DateUtils,
  getStaticImageUrl,
} from '../../../Core/Utils';
import styles from './styles';
import { breakpoints } from '../../../Core/Theme';
import TextButton from '../TextButton';
import Carat from '../Carat';
import SubmitButton from '../SubmitButton';
import { SkeletonLoader } from '..';
import { WEEK, DAY } from './constants';

require('./react_dates_overrides.css');

const root = document.documentElement;

const navPaddingTop = 15;
const BOOKING_RANGE_LIMIT = 90;

const shortMonths = [3, 4, 5, 6];
const arrowForward = datatestid => (
  <div
    style={{
      right: 0,
      left: 276,
      position: 'absolute',
      top: navPaddingTop,
      background: 'white',
      height: 40,
    }}
    datatestid={`${datatestid}_next_month`}
  >
    <ArrowForwardIos
      style={{
        height: 12,
        pointerEvents: 'fill',
        marginTop: 12,
      }}
    />
  </div>
);
const arrowBackward = datatestid => (
  <div
    style={{
      left: 0,
      right: 276,
      position: 'absolute',
      top: navPaddingTop,
      background: 'white',
      height: 40,
    }}
    datatestid={`${datatestid}_previous_month`}
  >
    <ArrowBackIos
      style={{
        height: 12,
        marginTop: 12,
        pointerEvents: 'fill',
        float: 'right',
      }}
    />
  </div>
);

const todayWithUnderline = (day, isDisabled, classes, datatestid) => {
  return (
    <div
      className={clsx(isDisabled && classes.disabledDate)}
      style={{
        borderBottom: `solid 1px black`,
        display: 'inline-block',
        paddingRight: 1,
        paddingLeft: 1,
      }}
      datatestid={`${datatestid}_${day}`}
    >
      {day}
    </div>
  );
};

const renderDayContents =
  (
    momentSelectedDate,
    classes,
    type,
    daySize,
    isLoading,
    disabledDates,
    datatestid,
    timeZoneId
  ) =>
  day => {
    const isSundayOfWeek = day.day() === 0;
    if (isLoading && isSundayOfWeek) {
      return <SkeletonLoader className={classes.loader} />;
    }
    if (isLoading) {
      return <div />;
    }
    const isDisabled = disabledDates.reduce(
      (acc, d) => acc || momentSelectedDate.isSame(d, 'day'),
      false
    );

    // If the day is today and not selected, render with underline
    if (
      day.isSame(moment().tz(timeZoneId), 'day') &&
      !day.isSame(momentSelectedDate, 'day')
    ) {
      return todayWithUnderline(day.date(), isDisabled, classes, datatestid);
    }
    if (type === WEEK) {
      const weekDates = [0, 1, 2, 3, 4].map(el => {
        return moment(momentSelectedDate).add(el, 'days').format('YYYY-MM-DD');
      });
      const isMondayOfWeek = day.day() === 1;
      const isFridayOfWeek = day.day() === 5;
      const isWeekDate = weekDates.some(day1 =>
        moment(day.format('YYYY-MM-DD')).isSame(day1, 'day')
      );

      if (isWeekDate) {
        return (
          <div
            className={clsx(
              classes.weekDate,
              isMondayOfWeek && classes.monday,
              isFridayOfWeek && classes.friday
            )}
            datatestid={`${datatestid}_day`}
            style={{ lineHeight: `${daySize - 1}px` }}
          >
            {day.date()}
          </div>
        );
      }
    }
    // otherwise
    return (
      <div
        className={clsx(isDisabled && classes.disabledDate)}
        datatestid={`${datatestid}_day`}
      >
        {day.date()}
      </div>
    );
  };

const getInitialDate = timeZoneId => {
  const now = moment().tz(timeZoneId);
  //  if it is a weekend
  if (DateUtils.isWeekend(now)) {
    now.add(8 - now.isoWeekday(), 'days');
  }
  return now.startOf('day').unix();
};

//  disable more than 90 days if they can't see future days
//  disable days in the past if they aren't allowed (this is used in the booking drawer)
// day is a moment date with zone
const getIsOutsideRange =
  (disablePastDate, isAllowedFutureDays, disableWeekends, timeZoneId) =>
  day => {
    const today = moment(new Date()).tz(timeZoneId);
    const isTooFarOut = today.diff(day, 'days') <= -BOOKING_RANGE_LIMIT;
    const isPastDates = disablePastDate
      ? today.isSameOrAfter(day) && !today.isSame(day, 'day')
      : null;
    const isUnallowedWeekend = disableWeekends && DateUtils.isWeekend(day);
    return (
      (isTooFarOut && !isAllowedFutureDays) || isPastDates || isUnallowedWeekend
    );
  };

const close = (setExpandOnClick, setFocus, isFocus) => {
  setFocus(false);
  setTimeout(() => setExpandOnClick(true), 200);
  isFocus(false);
};

const closeWrapped = (setExpandOnClick, setFocus, isFocus) => () => {
  close(setExpandOnClick, setFocus, isFocus);
};

const open = (setExpandOnClick, setFocus, isFocus, onOpen) => {
  setFocus(true);
  isFocus(true);
  setExpandOnClick(false);
  onOpen();
};

const openWrapped = (setExpandOnClick, setFocus, isFocus, onOpen) => () => {
  open(setExpandOnClick, setFocus, isFocus, onOpen);
};

const onFocusChange =
  (setFocus, withFullScreenPortal, setExpandOnClick, isFocus, onOpen) =>
  ({ focused }) => {
    if (focused) {
      open(setExpandOnClick, setFocus, isFocus, onOpen);
    } else if (!withFullScreenPortal) {
      close(setExpandOnClick, setFocus, isFocus);
    }
  };

const renderCloseButton =
  (
    width,
    buttonClass,
    divClass,
    setFocus,
    setExpandOnClick,
    classes,
    isFocus,
    datatestid,
    t
  ) =>
  () => {
    if (width < breakpoints.MOBILE) {
      return (
        <div className={divClass}>
          <Close
            datatestid={`${datatestid}_close_button`}
            className={buttonClass}
            onClick={closeWrapped(setExpandOnClick, setFocus, isFocus)}
          />
          <br />
          <p
            className={classes.dateTitle}
            datatestid={`${datatestid}_title_label`}
          >
            {t('daypicker.select_date')}
          </p>
          <SubmitButton
            datatestid={`${datatestid}_submit_button`}
            className={classes.submitDate}
            defaultText={t('daypicker.select_date')}
            onClick={closeWrapped(setExpandOnClick, setFocus, isFocus)}
          />
        </div>
      );
    }
    return '';
  };

const getDaySize = (width, withFullScreenPortal) => {
  if (!withFullScreenPortal) {
    return 40;
  }
  if (width <= 330) {
    return 43;
  }
  if (width <= 380) {
    return 49;
  }
  return 53;
};

const DayPicker = props => {
  const {
    classes,
    selectedDate,
    onDateChange,
    onReturnToday,
    withTodayButton,
    valueDate,
    disabled,
    disablePastDate,
    isAllowedFutureDays,
    isFocus,
    verticalSpacing,
    id,
    className,
    datatestid,
    isInvisible,
    type,
    onOpen,
    classNameRoot,
    classNameCarat,
    classNameInputLabel,
    variant,
    isLoading,
    disabledDates,
    disableWeekends,
    onPrevMonthClick,
    onNextMonthClick,
    caratProps,
    error,
    errorMessage,
    hasError,
    timeZoneId,
  } = props;

  const { t } = useTranslation();
  const timeZoneToUse = timeZoneId ?? DateUtils.getCurrentZone();
  const momentSelectedDate = DateUtils.getDateInMomentWithZone(
    selectedDate,
    timeZoneToUse
  );
  const momentSelectedOrValueDate = DateUtils.getDateInMomentWithZone(
    valueDate ?? selectedDate,
    timeZoneToUse
  );

  const [focus, setFocus] = React.useState(false);

  const [expandOnClick, setExpandOnClick] = React.useState(true);
  const isRound = variant === 'round';

  React.useEffect(() => {
    if (isInvisible) {
      root.style.setProperty('--clor', 'transparent');
    } else {
      root.style.setProperty('--clor', 'black');
    }
  }, [isInvisible]);
  let button = '';
  if (withTodayButton && selectedDate !== getInitialDate(timeZoneToUse)) {
    button = (
      <div className={classes.textButton}>
        <TextButton
          text={t('daypicker.go_to_today')}
          state="default"
          onClick={onReturnToday}
          size="medium"
          className={classes.todayButton}
          datatestid={`${datatestid}_go_to_today`}
        />
      </div>
    );
  } else if (withTodayButton) {
    button = <div className={classes.textButton} />;
  }
  const { width } = useWindowDimensions();
  const withFullScreenPortal = width < breakpoints.MOBILE;
  const daySize = getDaySize(width, withFullScreenPortal);

  const generateWeekName = () => {
    const initialMonth = momentSelectedDate.format('MMMM');

    const finalMonth = moment(momentSelectedDate).add(4, 'days').format('MMMM');
    const initialDay = momentSelectedDate.format('D');
    const finalDay = moment(momentSelectedDate).add(4, 'days').format('D');
    let name = '';
    if (initialMonth === finalMonth) {
      name = `${initialMonth} ${initialDay} - ${finalDay}`;
    } else {
      name = `${initialMonth} ${initialDay} - ${finalMonth} ${finalDay}`;
    }
    return name;
  };

  let displayFormat;
  if (type === WEEK) {
    displayFormat = ' ';
  } else {
    displayFormat = shortMonths.includes(momentSelectedDate.month())
      ? '[dddd, MMMM D]'
      : '[dddd, MMM D]';
  }
  if (isRound) {
    displayFormat = shortMonths.includes(momentSelectedDate.month())
      ? '[ddd, MMMM D]'
      : '[ddd, MMM D]';
  }
  return (
    <div>
      <div
        className={clsx(
          classNameRoot || classes.wrapper,
          hasError && classes.errorRoot
        )}
        datatestid={datatestid}
      >
        <div
          className={clsx(
            className || classes.dayPicker,
            isRound && classes.roundPicker
          )}
          onClick={
            expandOnClick
              ? openWrapped(setExpandOnClick, setFocus, isFocus, onOpen)
              : null
          }
          style={{
            cursor: disabled ? 'default' : 'pointer',
            opacity: disabled ? 0.5 : 1,
          }}
        >
          {type === WEEK && (
            <div className={classes.dateLabel}>{generateWeekName()}</div>
          )}
          {error && (
            <div className={classes.dateLabelError}>
              {momentSelectedOrValueDate.format(displayFormat)}
            </div>
          )}
          {isLoading && (
            <div className={clsx(classes.inputLabel, classNameInputLabel)}>
              {momentSelectedOrValueDate.format(displayFormat)}
            </div>
          )}
          {isRound && (
            <img
              src={getStaticImageUrl('2020-08/calendarIcon.svg')}
              className={classes.calendarIcon}
              alt={t('altTexts.calendar')}
            />
          )}
          <SingleDatePicker
            date={isLoading ? null : momentSelectedOrValueDate}
            onDateChange={varValue => {
              onDateChange(varValue);
              if (!withFullScreenPortal) setFocus(false);
            }}
            focused={disabled ? false : focus}
            disabled={disabled}
            onFocusChange={onFocusChange(
              setFocus,
              withFullScreenPortal,
              setExpandOnClick,
              isFocus,
              onOpen
            )}
            id={`${id}__day`}
            verticalSpacing={verticalSpacing}
            numberOfMonths={1}
            //  determines whether to show days not in this month, and we want toshow them
            enableOutsideDays
            hideKeyboardShortcutsPanel
            isOutsideRange={getIsOutsideRange(
              disablePastDate,
              isAllowedFutureDays,
              disableWeekends,
              timeZoneToUse
            )}
            showDefaultInputIcon={false}
            weekDayFormat="ddd"
            daySize={daySize}
            navNext={arrowForward(datatestid)}
            navPrev={arrowBackward(datatestid)}
            withFullScreenPortal={withFullScreenPortal}
            readOnly
            displayFormat={displayFormat}
            renderCalendarInfo={renderCloseButton(
              width,
              classes.closeButton,
              classes.closeDiv,
              setFocus,
              setExpandOnClick,
              classes,
              isFocus,
              datatestid,
              t
            )}
            calendarInfoPosition="top"
            renderDayContents={renderDayContents(
              momentSelectedDate,
              classes,
              type,
              daySize,
              isLoading,
              disabledDates,
              datatestid,
              timeZoneToUse
            )}
            isDayBlocked={d => disabledDates.includes(d.startOf('day').unix())}
            onPrevMonthClick={onPrevMonthClick}
            onNextMonthClick={onNextMonthClick}
            datatestid={datatestid}
          />
          {disabled || isInvisible || isRound ? null : (
            <div
              className={clsx(classes.carat, classNameCarat)}
              onClick={
                expandOnClick
                  ? openWrapped(setExpandOnClick, setFocus, isFocus, onOpen)
                  : null
              }
              datatestid={`${datatestid}_carat`}
            >
              <Carat size="mediumSmall" {...caratProps} />
            </div>
          )}
        </div>
      </div>
      {errorMessage && hasError && (
        <div className={classes.errorMessage}>{t(errorMessage)}</div>
      )}
      {button}
    </div>
  );
};

DayPicker.defaultProps = {
  onReturnToday: () => '',
  withTodayButton: false,
  disablePastDate: false,
  valueDate: null,
  disabled: false,
  verticalSpacing: 0,
  isFocus: () => null,
  className: null,
  isAllowedFutureDays: false,
  datatestid: 'date_picker_day',
  isInvisible: false,
  type: DAY,
  onOpen: () => {},
  classNameRoot: null,
  classNameCarat: null,
  variant: 'basic',
  isLoading: false,
  disabledDates: [],
  disableWeekends: false,
  onPrevMonthClick: () => {},
  onNextMonthClick: () => {},
  classNameInputLabel: '',
  caratProps: {},
  error: false,
  hasError: false,
  errorMessage: '',
  timeZoneId: null,
};

DayPicker.propTypes = {
  classes: PropTypes.object.isRequired,
  selectedDate: PropTypes.number.isRequired,
  valueDate: PropTypes.number,
  disabled: PropTypes.bool,
  onDateChange: PropTypes.func.isRequired,
  onReturnToday: PropTypes.func,
  withTodayButton: PropTypes.bool,
  disablePastDate: PropTypes.bool,
  isAllowedFutureDays: PropTypes.bool,
  isFocus: PropTypes.func,
  verticalSpacing: PropTypes.number,
  id: PropTypes.string.isRequired,
  className: PropTypes.string,
  datatestid: PropTypes.string,
  isInvisible: PropTypes.bool,
  type: PropTypes.string,
  onOpen: PropTypes.func,
  classNameRoot: PropTypes.string,
  classNameCarat: PropTypes.string,
  classNameInputLabel: PropTypes.string,
  variant: PropTypes.string,
  isLoading: PropTypes.bool,
  disabledDates: PropTypes.arrayOf(PropTypes.number),
  disableWeekends: PropTypes.bool,
  onPrevMonthClick: PropTypes.func,
  onNextMonthClick: PropTypes.func,
  caratProps: PropTypes.object,
  error: PropTypes.bool,
  hasError: PropTypes.bool,
  errorMessage: PropTypes.string,
  timeZoneId: PropTypes.string,
};

export default withStyles(styles)(DayPicker);
