import moment from 'moment-timezone';

import { SEAT_BOOKING_END_HOUR_OFFSET } from './constants';
import { date as DateUtils } from '../../Core/Utils';

export const getInitialDate = () => {
  if (moment().hour() >= SEAT_BOOKING_END_HOUR_OFFSET) {
    return moment().add(1, 'day').startOf('day').unix();
  }
  return moment().startOf('day').unix();
};

export const getCreateDtoFromValues = ({ grouping, user, date }) => {
  return {
    userSfId: user.sfId,
    groupingId: grouping.idGrouping,
    day: DateUtils.getDateInMoment(date).format('YYYY-MM-DD'),
  };
};
