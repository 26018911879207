import reducer, * as reducers from './reducer';
import * as actions from './actions';
import * as actionTypes from './actionTypes';
import * as selectors from './selectors';

export default {
  reducer,
  actions,
  actionTypes,
  reducers,
  selectors,
};
