import { ViewPort, ZoomLevels } from './types';

export const ZOOM_LEVEL: ZoomLevels = {
  vector: {
    INITIAL_ZOOM: 14,
    MIN_ZOOM: 12,
    MAX_ZOOM: 16,
    VISIBLE_ZOOM_LABELS: 15,
    DEFAULT_FLY_TO_ZOOM: 16,
  },
  raster: {
    INITIAL_ZOOM: 14,
    MIN_ZOOM: 12,
    MAX_ZOOM: 16,
    VISIBLE_ZOOM_LABELS: 15,
    DEFAULT_FLY_TO_ZOOM: 16,
  },
};

export const ZOOM_KEYS = {
  INITIAL_ZOOM: 'INITIAL_ZOOM',
  MIN_ZOOM: 'MIN_ZOOM',
  MAX_ZOOM: 'MAX_ZOOM',
  VISIBLE_ZOOM_LABELS: 'VISIBLE_ZOOM_LABELS',
  DEFAULT_FLY_TO_ZOOM: 'DEFAULT_FLY_TO_ZOOM',
};

export const DEFAULT_VIEWPORT: ViewPort = {
  width: '100vw',
  height: '64vh',
};

export const FEATURES_TYPE = {
  CAFE: 'Cafe',
  BATHROOM: 'Bathroom',
  OFFICE: 'Office',
  DESK: 'Desk',
  CHAIR: 'Chair',
  CONFERENCE: 'Conference',
  LABEL: 'Label',
  PHONE_BOOTH: 'Phone Booth',
  COPY_ROOM: 'Copy Room',
  CANVAS: 'Canvas',
  MAILROOM: 'Mailroom',
  MECHANICS: 'Mechanics',
  STORAGE: 'Storage',
  COMMON_SPACES: 'Common Spaces',
  CONFIG: 'Config',
};

export const LABEL_DISABLED_MAPBOX_FEATURE_TYPE = [
  FEATURES_TYPE.PHONE_BOOTH,
  FEATURES_TYPE.COPY_ROOM,
  FEATURES_TYPE.MAILROOM,
  FEATURES_TYPE.CANVAS,
  FEATURES_TYPE.MECHANICS,
  FEATURES_TYPE.STORAGE,
];

export const INTERACTIVE_MAPBOX_FEATURE_TYPE = [
  FEATURES_TYPE.OFFICE,
  FEATURES_TYPE.DESK,
  FEATURES_TYPE.CHAIR,
];
