import { Http, stringifyUrl } from '../Utils';
import { CreateRsvpDto, RsvpDto, RsvpsPageDto } from './dto';

export const getUserOccasionRsvpsPage = (
  userSfId: string,
  startEpoch: number,
  includeDeleted = false,
  limit: number,
  offset: number,
  includeOngoing: boolean,
  endEpoch: number
) => {
  const url = stringifyUrl(`/users/${userSfId}/rsvps`, {
    include_deleted: includeDeleted,
    limit,
    offset,
    start_epoch: startEpoch,
    end_epoch: endEpoch,
    include_ongoing: includeOngoing,
  });

  return Http.get<RsvpsPageDto>(url);
};

export const getOccasionRsvps = (occasionId: string) => {
  return Http.get<RsvpDto[]>(`/occasions/${occasionId}/rsvps`);
};

export const getRsvpsById = (id: string) => {
  return Http.get<RsvpDto>(`/rsvps/${id}/`);
};

export const deleteRsvps = (rsvpIds: number[]) => {
  return Http.remove<void>(`/rsvps/`, {
    data: {
      rsvpIds,
    },
  });
};

export const updateFromWaitlistedToRsvp = (rsvpIds: number[]) => {
  return Http.put<RsvpDto[]>(`/rsvps/`, { rsvpIds });
};

export const createRsvp = (rsvp: CreateRsvpDto) => {
  return Http.post<RsvpDto>(`/rsvps`, rsvp);
};

export const deleteRsvp = (id: string) => {
  return Http.remove<void>(`/rsvps/${id}`);
};
