import Components from '../../Components';
import { DESK_RESERVATION_PATH } from './Constants';
import { canAccessHotDeskFeature } from '../../Core/Utils/userPermissions';

const { containerTypes } = Components.Routes;

const RouteConfig = {
  path: DESK_RESERVATION_PATH,
  exact: true,
  name: 'navigation.menu.deskReservation',
  private: true,
  type: containerTypes.PAGE,
  grouping: 'navigation.grouping.reserve',
  requiresMemberPortalAccess: true,
  isAuthorized: ({ profile, userGroupings, userGroupingsIsLoading }) =>
    canAccessHotDeskFeature(profile, userGroupings, userGroupingsIsLoading),
};

export default RouteConfig;
