import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { colors } from '../../../../../Core/Theme';

const ConfigurationCaptions = props => {
  const { title, subtitle, classes } = props;
  return (
    <div>
      <div className={classes.title}>{title}</div>
      {subtitle && <div className={classes.subtitle}>{subtitle}</div>}
    </div>
  );
};

ConfigurationCaptions.defaultProps = {
  subtitle: null,
};

ConfigurationCaptions.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
};

const styles = {
  title: {
    fontFamily: 'VerlagBook',
    fontSize: '18px',
    lineHeight: 1,
    color: colors.black,
  },
  subtitle: {
    fontFamily: 'VerlagLight',
    fontSize: '16px',
    lineHeight: 1,
    marginTop: 5,
    color: colors.darkest,
  },
};
export default withStyles(styles)(ConfigurationCaptions);
