import * as React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
import clsx from 'clsx';
import { Link as RouterLink } from 'react-router-dom';
import { colors, breakpoints } from '../../../Core/Theme';

import QuickLinkButton from './QuickLinkButton';
//  Use this to avoid duplicate prop component
const DuplicatePropLink = React.forwardRef((props, ref) => {
  return <RouterLink ref={ref} {...props} />;
});

const BaseButton = props => {
  const {
    name,
    className,
    link,
    classes,
    disabled,
    onClick,
    variant,
    ...rest
  } = props;

  if (variant === 'quickLink') {
    return <QuickLinkButton {...props} />;
  }
  const isTiny = variant === 'tiny';
  return (
    <React.Fragment>
      {link ? (
        <Link
          underline="always"
          component={DuplicatePropLink}
          className={className}
          onClick={onClick}
          {...rest}
        >
          {name}
        </Link>
      ) : (
        <Button
          variant="contained"
          {...rest}
          className={clsx(
            classes.default,
            className,
            disabled && classes.disabled,
            isTiny && classes.tiny
          )}
          onClick={disabled ? () => null : onClick}
          focusVisibleClassName={classes.buttonFocusVisible}
        >
          {name}
        </Button>
      )}
    </React.Fragment>
  );
};
const styles = theme => ({
  buttonFocusVisible: {
    background: `${colors.middle} !important`,
  },
  default: {
    fontFamily: 'VerlagBold',
    textTransform: 'none',
    color: colors.palette.primary.main,
    backgroundColor: colors.white,
    borderColor: colors.palette.primary.main,
    borderWidth: 2,
    border: 'solid 2px',
    boxShadow: 'none',
    fontSize: 15,
    textAlign: 'center',
    padding: 0,
    '&:hover': {
      borderColor: colors.palette.primary.light,
      backgroundColor: colors.palette.primary.lightest,
    },
    '&:focus': {
      boxShadow: 'none',
      backgroundColor: colors.white,
    },
    [theme.breakpoints.up(breakpoints.MOBILE)]: {
      height: 40,
      borderRadius: 20,
    },
    [theme.breakpoints.down(breakpoints.MOBILE)]: {
      height: 50,
      borderRadius: 25,
    },
  },
  disabled: {
    backgroundColor: colors.white,
    borderColor: colors.light,
    cursor: 'default',
    '&:hover': {
      borderColor: colors.light,
      backgroundColor: colors.white,
    },
  },
  tiny: {
    height: 22,
    fontFamily: 'VerlagBold',
    minWidth: 47,
    borderWidth: 1,
    fontSize: 12,
    boxShadow: '0 0 4px 1px rgba(0, 0, 0, 0.1)',
    borderRadius: 11,
  },
});

BaseButton.defaultProps = {
  link: false,
  onClick: () => '',
  className: '',
  disabled: false,
  variant: '',
};
BaseButton.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  onClick: PropTypes.func,
  link: PropTypes.bool,
  name: PropTypes.string.isRequired,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  variant: PropTypes.string,
};
export default withStyles(styles)(BaseButton);
