import { useState } from 'react';

import {
  fetchOfficeAvailabilities,
  GroupingDto,
  OfficeAvailabilityDto,
} from '../../../Core/Api';
import { date as DateUtils } from '../../../Core/Utils';
import { getIsStandalone } from '../../../Models/groupings';

export const useOfficeAvailabilities = () => {
  const [officeAvailabilities, setOfficeAvailabilities] =
    useState<OfficeAvailabilityDto[]>();

  const searchOfficeAvailabilities = async (params: {
    grouping?: GroupingDto;
    epoch: number;
    floorId?: string;
  }) => {
    const { grouping, epoch, floorId } = params;
    if (!grouping) {
      return;
    }

    setOfficeAvailabilities(undefined);

    const isStandalone = getIsStandalone(grouping);

    const response = await fetchOfficeAvailabilities({
      groupingId: isStandalone ? grouping.idGrouping : undefined,
      locationSfId: grouping.locationSfId,
      integratedOnly: !isStandalone,
      startEpoch: DateUtils.updateDateForTZ(
        DateUtils.getUnixStartOfDay(epoch),
        grouping.location.timeZoneId,
        DateUtils.getCurrentZone()
      ),
      endEpoch: DateUtils.updateDateForTZ(
        DateUtils.getUnixEndOfDay(epoch),
        grouping.location.timeZoneId,
        DateUtils.getCurrentZone()
      ),
      floorId,
    });

    if (response.status === 200) {
      setOfficeAvailabilities(response.data);
    }
  };

  return { officeAvailabilities, searchOfficeAvailabilities };
};
