import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { useTranslation } from 'react-i18next';
import Moment from 'moment';
import queryString from 'query-string';

// Core
import { colors, breakpoints } from '../../Core/Theme';
import { HomeRoute } from '../../Core/constants';
import { date as DateUtil } from '../../Core/Utils';
import {
  companyInfoAccess,
  getIsVirtualMember,
} from '../../Core/Utils/userPermissions';

// Components
import { BackButton, DayPicker, InfoBanner } from '../../Components/Common';
import { ActivityTableSummary } from '../../Components';

// Api
import { fetchAccount } from '../../Core/Api';

// Redux
import { getUserProfile } from '../../Redux/Common/UserManager/selectors';
import locationsRedux from '../../Redux/Common/Locations';
import { getLocations } from '../../Redux/Common/Locations/selectors';
import { ACCOUNT_ACTIVITY_PATH } from './constants';

const AccountActivity = props => {
  const { classes, history, profile, addLocations, locations } = props;
  const { t } = useTranslation();

  const [account, setAccount] = React.useState(null);
  const [month, setMonth] = React.useState(null);
  const [hasError, setHasError] = React.useState(null);

  const handleBadAccount = () => {
    history.push(HomeRoute);
  };

  const initializeDataForAccount = a => {
    DateUtil.setTimezone(a?.location?.timeZoneId);
    setMonth(Moment().startOf('month').unix());
    setAccount(a);
  };

  React.useEffect(() => {
    const windowLocationSearch = window.location.search;
    const params = queryString.parse(windowLocationSearch);
    const { accountSfId } = params;
    if (!accountSfId) {
      history.push(
        `${ACCOUNT_ACTIVITY_PATH}?accountSfId=${profile?.accountSfId}`
      );
      return;
    }
    if (!locations.length) {
      addLocations(false); // false is the include deleted value
    }
    if (accountSfId === profile?.accountSfId) {
      setAccount(profile?.account);
      initializeDataForAccount(profile?.account);
      return;
    }
    fetchAccount(accountSfId).then(objResponse => {
      const { data } = objResponse;
      const userCanUpdateAccount = companyInfoAccess.update({
        profile,
        obj: data,
      });
      if (!userCanUpdateAccount) {
        handleBadAccount();
        return;
      }
      initializeDataForAccount(data);
    }, handleBadAccount);
  }, [window.location.search]);

  const handleMonth = m => {
    setMonth(m);
  };

  const isVirtualMember = getIsVirtualMember(profile);

  return (
    <div className={classes.wrapper}>
      {hasError && (
        <InfoBanner
          variant="error"
          theme="light"
          text={t('general.page_error')}
          className={classes.errorMessage}
        />
      )}
      <Grid container className={classes.content} spacing={16}>
        {isVirtualMember ? null : (
          <Grid item xs={12}>
            <BackButton
              className={classes.backContainer}
              onBackPress={() =>
                history.push({
                  pathname: '/home/account',
                  search: `accountSfId=${account?.sfId}`,
                })
              }
            />
          </Grid>
        )}
        <Grid item xs={12} className={classes.title}>
          {t('accountActivity.title')}
        </Grid>
        <Grid item xs={12}>
          <DayPicker
            type="month"
            onChange={handleMonth}
            selectedMonth={month || Moment().startOf('month').unix()}
            variant="box"
            className={classes.month}
          />
        </Grid>
        <Grid item xs={12}>
          <ActivityTableSummary
            month={month}
            accountSfId={account?.sfId}
            account={account}
            setHasError={setHasError}
            history={history}
          />
        </Grid>
      </Grid>
    </div>
  );
};

const styles = theme => ({
  wrapper: {
    background: colors.palette.secondary2.main,
    paddingTop: 26,
    paddingRight: 14,
    paddingLeft: 14,
    [theme.breakpoints.up(breakpoints.MOBILE)]: {
      paddingTop: 14,
      paddingRight: 44,
      paddingLeft: 44,
    },
    minHeight: '100vh',
  },
  title: {
    fontFamily: 'VerlagBold',
    fontSize: 24,
    [theme.breakpoints.up(breakpoints.MOBILE)]: {
      fontSize: 36,
    },
  },
  month: {
    maxWidth: 200,
  },
  errorMessage: {
    width: 'auto',
    marginBottom: 20,
  },
});

AccountActivity.propTypes = {
  profile: PropTypes.object.isRequired,
  addLocations: PropTypes.func.isRequired,
  locations: PropTypes.arrayOf(PropTypes.object).isRequired,
};

const mapStateToProps = state => {
  return {
    profile: getUserProfile(state),
    locations: getLocations(state),
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      addLocations: locationsRedux.actions.addLocations,
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(AccountActivity));
