import { date as DateUtils } from '../../Core/Utils';

export const getUpdateDtoFromValues = (values, reservation) => {
  const groupingId = reservation?.groupingId;
  const userSfId = values?.user?.sfId;
  const day = DateUtils.getDateInMoment(values?.date).format('YYYY-MM-DD');
  return {
    groupingId,
    userSfId,
    day,
  };
};
