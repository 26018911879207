/* eslint-disable jsx-a11y/label-has-for */

import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import * as React from 'react';
import ListSubheader from '@material-ui/core/ListSubheader';
import Lodash from 'lodash';
import memoizeOne from 'memoize-one';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import clsx from 'clsx';
import SearchInput from '../SearchInput';

import { colors, breakpoints } from '../../../Core/Theme';
import { Tooltip } from '../index';

const getOptionGroups = memoizeOne(options => {
  const groupedOptions = options.reduce((acc, o) => {
    if (acc[o.groupId]) {
      acc[o.groupId].push(o);
    } else {
      acc[o.groupId] = [o];
    }
    return acc;
  }, {});

  const optionsToReturn = {};
  Object.keys(groupedOptions).forEach(k => {
    const optionList = groupedOptions[k];
    optionsToReturn[k] = Lodash.sortBy(optionList, 'label');
  });

  return optionsToReturn;
});

const getAlphabetizedGroupIds = memoizeOne(optionGroups => {
  const groupKeys = Object.keys(optionGroups);

  return groupKeys.sort((a, b) => {
    const nameA = Lodash.get(optionGroups, [a, 0, 'groupLabel'], '');
    const nameB = Lodash.get(optionGroups, [b, 0, 'groupLabel'], '');
    const aIsFirst = Lodash.get(optionGroups, [a, 0, 'isFirst'], false);
    const bIsFirst = Lodash.get(optionGroups, [b, 0, 'isFirst'], false);
    if (aIsFirst) {
      return -1;
    }
    if (bIsFirst) {
      return 1;
    }

    return nameA > nameB ? 1 : -1;
  });
});

const getSuggestions = memoizeOne((inputValue = '', options) => {
  const inputs = inputValue.split(' ');
  const matches = options.filter(o => {
    const labelWords = o.label.split(' ');
    const groupLabelWords = o.groupLabel.split(' ');
    const searchWords = o.search?.split(',') ?? [];
    const doesMatch =
      inputs.reduce((acc, input) => {
        return (
          acc ||
          labelWords.some(w => w.toLowerCase().startsWith(input.toLowerCase()))
        );
      }, false) ||
      inputs.reduce((acc, input) => {
        return groupLabelWords.some(w =>
          w.toLowerCase().startsWith(input.toLowerCase())
        );
      }, false) ||
      inputs.reduce((acc, input) => {
        return searchWords.some(w =>
          w.toLowerCase().startsWith(input.toLowerCase())
        );
      }, false);
    return doesMatch;
  });
  return matches;
});

const ListWithGroups = props => {
  const {
    options,
    classes,
    renderItem,
    onClick,
    withSearch,
    searchLabel,
    searchPlaceholder,
    listClassName,
    noResultsText,
    customClasses,
    searchInputVariant,
    groupNameToUpperCase,
    disabledTooltipText,
  } = props;
  const [searchInput, setSearchInput] = React.useState('');
  const searchLabelClassName = Lodash.get(customClasses, ['searchLabel'], null);
  const groupHeaderClassName = Lodash.get(customClasses, ['groupHeader'], null);
  const listItemClassName = Lodash.get(customClasses, ['listItem'], null);

  const matchedOptions = searchInput
    ? getSuggestions(searchInput, options)
    : options;

  const optionGroups = getOptionGroups(matchedOptions);
  const alphabetizedGroupIds = getAlphabetizedGroupIds(optionGroups);

  const clickWrapped = o => () => onClick(o);

  const items = alphabetizedGroupIds.map(groupId => {
    const group = optionGroups[groupId];
    const groupName = Lodash.get(optionGroups, [groupId, 0, 'groupLabel'], '');
    return (
      <div className={classes.stickyGroup} key={groupId}>
        <ListSubheader
          className={clsx(classes.groupHeader, groupHeaderClassName)}
        >
          {groupNameToUpperCase ? groupName.toUpperCase() : groupName}
        </ListSubheader>
        {group.map(o => {
          const renderOption = () => (
            <div>
              <ListItem
                button
                className={clsx(classes.listItem, listItemClassName)}
                onClick={clickWrapped(o)}
                disabled={o.isDisabled}
                classes={{ disabled: classes.listItemDisabled }}
              >
                {renderItem ? (
                  renderItem(o)
                ) : (
                  <div
                    style={{
                      textOverflow: 'ellipsis',
                      overflow: 'hidden',
                    }}
                  >
                    {o.label}
                  </div>
                )}
              </ListItem>
            </div>
          );
          return o.isDisabled && disabledTooltipText ? (
            <Tooltip
              interactive
              customClasses={{
                tooltip: classes.reachTeamTooltip,
              }}
              title={disabledTooltipText}
              anchorContent={renderOption}
              position="bottom-start"
            />
          ) : (
            renderOption()
          );
        })}
      </div>
    );
  });

  return (
    <>
      {withSearch && (
        <div className={classes.search}>
          <label
            className={clsx(classes.searchLabel, searchLabelClassName)}
            htmlFor="search input"
            id="search input label"
          >
            {searchLabel}
          </label>
          <div className={classes.searchBox}>
            <SearchInput
              onChange={setSearchInput}
              value={searchInput}
              className={classes.searchBox}
              id="search input"
              placeholder={searchPlaceholder}
              variant={searchInputVariant}
            />
          </div>
        </div>
      )}
      <div
        className={clsx(
          listClassName,
          classes.list,
          !alphabetizedGroupIds.length && classes.listNoBorder
        )}
      >
        {!alphabetizedGroupIds.length && (
          <div className={classes.noResults}>{noResultsText}</div>
        )}
        <List disablePadding>{items} </List>
      </div>
    </>
  );
};

const styles = theme => ({
  stickyGroup: {
    position: 'relative',
  },
  groupHeader: {
    fontFamily: 'VerlagBold',
    fontSize: 12,
    lineHeight: '12px',
    color: 'black',
    paddingLeft: 20,
    paddingRight: 20,
    paddingTop: 15,
    paddingBottom: 10,
    background: 'white',
    borderBottom: `solid 1px ${colors.light}`,
    [theme.breakpoints.up(breakpoints.MOBILE)]: {
      paddingLeft: 40,
      paddingRight: 40,
    },
    '&:hover': {
      backgroundColor: colors.white,
    },
  },
  listItem: {
    borderBottom: `solid 1px ${colors.light}`,
    paddingLeft: 20,
    paddingRight: 20,
    paddingTop: 6,
    paddingBottom: 6,
    [theme.breakpoints.up(breakpoints.MOBILE)]: {
      paddingLeft: 40,
      paddingRight: 40,
    },
    '&:hover': {
      backgroundColor: colors.lightest,
    },
  },
  search: {
    marginTop: 20,
  },
  searchLabel: {
    fontFamily: 'VerlagBold',
    fontSize: 18,

    [theme.breakpoints.up(breakpoints.MOBILE)]: {
      fontSize: 20,
    },
  },
  searchBox: {
    marginTop: 20,
  },
  noResults: {
    fontFamily: 'VerlagLight',
    fontSize: 16,
    color: colors.darkGray,
    textAlign: 'center',
    marginTop: 10,
  },
  list: {
    borderTop: `solid 1px ${colors.light}`,
  },
  listNoBorder: {
    borderTop: 'none',
  },
  listItemDisabled: {
    opacity: 1,
  },
});

ListWithGroups.defaultProps = {
  renderItem: null,
  options: [],
  onClick: () => {},
  withSearch: false,
  groupNameToUpperCase: true,
  searchLabel: '',
  searchPlaceholder: '',
  listClassName: '',
  noResultsText: '',
  searchInputVariant: 'box',
  customClasses: {},
  disabledTooltipText: null,
};

ListWithGroups.propTypes = {
  options: PropTypes.arrayOf(PropTypes.object),
  classes: PropTypes.object.isRequired,
  customClasses: PropTypes.object,
  renderItem: PropTypes.func,
  onClick: PropTypes.func,
  withSearch: PropTypes.bool,
  groupNameToUpperCase: PropTypes.bool,
  searchLabel: PropTypes.string,
  searchPlaceholder: PropTypes.string,
  listClassName: PropTypes.string,
  noResultsText: PropTypes.string,
  searchInputVariant: PropTypes.string,
  disabledTooltipText: PropTypes.string,
};

export default withStyles(styles)(ListWithGroups);
