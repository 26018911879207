export const trackEvent = (action, params) => {
  if (window.gtag) {
    window.gtag('event', action, params);
  }
};

export const trackEventClick = params => {
  trackEvent('Click', params);
};

export const setCustomDimensions = params => {
  if (window.gtag) {
    window.gtag('set', params);
  }
};

export const trackDatepickerClick = () =>
  trackEventClick({
    event_category: 'Room reservation',
    event_label: 'Datepicker',
  });

export const trackCategoryClick = categoryLabel =>
  trackEventClick({
    event_category: 'Perk Category',
    event_label: categoryLabel,
  });

export const trackDateSelection = () =>
  trackEventClick({
    event_category: 'Room reservation',
    event_label: 'Select date',
  });

export const trackLocationDropdownClick = (locationName, eventCategory) =>
  trackEventClick({
    event_category: eventCategory,
    event_label: `Location dropdown ${locationName}`,
  });

export const trackRoomPagination = () =>
  trackEventClick({
    event_category: 'Room reservation',
    event_label: 'Room paginate',
  });

export const trackRoomReservationFilter = (filterType, filterValue) =>
  trackEventClick({
    event_category: 'Room reservation',
    event_label: `${filterType}: ${filterValue}`,
  });

export const trackTimeslotClick = () =>
  trackEventClick({
    event_category: 'Room reservation',
    event_label: 'Create drawer open',
  });

export const trackReservationClick = params =>
  trackEventClick({
    event_category: 'Room reservation',
    event_label: 'Edit drawer open',
    ...params,
  });

export const trackViewMyReservationsClick = () =>
  trackEventClick({
    event_category: 'Room reservation',
    event_label: 'View All My Reservations',
  });

export const trackMyScheduleReservationClick = () =>
  trackEventClick('Click', {
    event_category: 'Your Schedule',
    event_label: 'Edit drawer open',
  });

export const trackMyScheduleEventClick = () =>
  trackEventClick('Click', {
    event_category: 'Your Schedule',
    event_label: 'Events',
  });

export const trackViewMemberLeavingReservationsClick = () =>
  trackEvent('Click', {
    event_category: 'Room reservation',
    event_label: 'View All member left reservations',
  });

export const trackDeleteAllMemberLeavingReservationClick = () =>
  trackEvent('Click', {
    event_category: 'Auto-remove',
    event_label: 'Delete All',
  });

export const trackTakeOwnerShipMemberLeavingReservationClick = () =>
  trackEvent('Click', {
    event_category: 'Auto-remove',
    event_label: 'Take Ownership',
  });

export const trackReferralLinkClick = params => {
  trackEvent('Click', {
    event_category: 'Referral',
    event_label: 'Referral CTA',
    location_on_page: 'Banner',
    ...params,
  });
};

export const trackReserveAMeetingRoomClick = () =>
  trackEventClick({
    event_category: 'Room reservation',
    event_label: 'Back to Reserve a Meeting Room',
  });

export const trackFloorFilterClick = (floor, category) =>
  trackEventClick({
    event_category: category || 'Room reservation',
    event_label: `Floor: ${floor}`,
  });

export const trackOpenReserveNowPanelClick = () =>
  trackEventClick({
    event_category: 'Room reservation',
    event_label: 'Reserve Now panel open',
  });

export const trackReserveNowPanelClick = () =>
  trackEventClick({
    event_category: 'Room reservation',
    event_label: 'Reserve Now in panel',
  });

export const trackReserveNowFlyoverClick = () =>
  trackEventClick({
    event_category: 'Room reservation',
    event_label: 'Reserve Now in flyover',
  });
/** */

export const trackNavigationClick = params => {
  trackEvent('Click', {
    event_category: 'Navigation',
    ...params,
  });
};

export const trackTopMenuCategoryNavigation = params => () => {
  trackEvent('Click', {
    event_category: 'Navigation',
    ...params,
  });
};

// Events

export const trackLocationSelectedEvents = newLocationName => {
  trackEvent('Click', {
    event_category: 'Location Dropdown',
    event_label: newLocationName,
  });
};

export const trackEventsMenuVisibilityClick = params => {
  trackEvent('Click', {
    event_category: 'Menu At A Glance',
    ...params,
  });
};

export const trackEditEventsFromMenu = params => {
  trackEvent('Click', {
    event_category: 'Menu At A Glance',
    event_label: 'Date Card Edit',
    ...params,
  });
};

export const trackViewEventsFromMenu = eventTitle => {
  trackEvent('Click', {
    event_category: 'Menu At A Glance',
    event_label: `${eventTitle}`,
  });
};

export const trackAddEventToCalendar = params => {
  trackEvent('Click', {
    event_label: 'Add to my calendar',
    ...params,
  });
};

export const trackViewEventsFromCard = params => {
  trackEvent('Click', {
    event_category: 'UpcomingReservations Events',
    event_label: 'Detail',
    ...params,
  });
};

export const trackViewEventsFromLink = (eventName, params = {}) => {
  trackEvent('Link', {
    event_category: 'UpcomingReservations Events',
    event_label: `Date card ${eventName}`,
    ...params,
  });
};

export const trackClickAddress = params => {
  trackEvent('Click', {
    event_category: 'UpcomingReservations Events',
    event_label: 'Address',
    ...params,
  });
};

export const trackDuplicateEvent = params => {
  trackEvent('Click', {
    event_category: 'UpcomingReservations Events',
    event_label: 'Duplicate',
    ...params,
  });
};

export const trackEditEventFromCard = params => {
  trackEvent('Click', {
    event_category: 'UpcomingReservations Events',
    event_label: 'Detail Card Edit',
    ...params,
  });
};

export const trackDeleteEventFromCard = params => {
  trackEvent('Click', {
    event_category: 'UpcomingReservations Events',
    event_label: 'Detail Card Delete',
    ...params,
  });
};

export const trackVirtualEventLinkFromCard = params => {
  trackEvent('Click', {
    event_category: 'Detail Card',
    event_label: 'Event Link',
    ...params,
  });
};

export const trackSeeMoreEventsFromHome = params => {
  trackEvent('Click', {
    event_category: 'UpcomingReservations Events',
    event_label: 'See more',
    ...params,
  });
};

export const trackRsvp = params => {
  trackEvent('Click', {
    event_category: 'UpcomingReservations Events',
    event_label: 'RSVP',
    ...params,
  });
};
export const trackCancelRsvp = params => {
  trackEvent('Click', {
    event_category: 'UpcomingReservations Events',
    event_label: 'Cancel RSVP',
    ...params,
  });
};

export const trackWaitlistRsvp = params => {
  trackEvent('Click', {
    event_category: 'UpcomingReservations Events',
    event_label: 'Waitlist RSVP',
    ...params,
  });
};
export const trackCancelWaitlistRsvp = params => {
  trackEvent('Click', {
    event_category: 'UpcomingReservations Events',
    event_label: 'Cancel Waitlist',
    ...params,
  });
};
export const trackVirtualEventLink = params => {
  trackEvent('Click', {
    event_category: 'UpcomingReservations Events',
    event_label: 'Event Link',
    ...params,
  });
};
export const trackExternalEventLink = params => {
  trackEvent('Click', {
    event_category: 'UpcomingReservations Events',
    event_label: 'Register for Event',
    ...params,
  });
};
export const trackExternalEventLinkFromCard = params => {
  trackEvent('Click', {
    event_category: 'Details',
    event_label: 'Register for Event',
    ...params,
  });
};

const trackCommunityManagerClick = eventLabel => {
  trackEventClick({
    event_category: 'CM Module',
    event_label: eventLabel,
  });
};

export const trackCommunityManagerEmailClick = () => {
  trackCommunityManagerClick('Email');
};

export const trackPartnershipGetStartedClick = eventLabel =>
  trackEventClick({
    event_category: 'Partnerships',
    event_label: eventLabel,
  });

export const trackBusinessAssistanceClick = eventLabel =>
  trackEventClick({
    event_category: 'Assistance Module',
    event_label: eventLabel,
  });

export const trackBlogPostClick = blogTitle =>
  trackEventClick({
    event_category: 'Blog Post',
    event_label: blogTitle,
  });

export const trackSlackJoinConversation = params => {
  trackEvent('Click', {
    event_category: 'Connect to Community',
    event_label: 'Slack',
    ...params,
  });
};

export const trackInHomeKits = params => {
  trackEvent('Click', {
    event_category: 'In-home office kits',
    event_label: 'Learn more',
    ...params,
  });
};

export const trackHomeButtonClick = params => {
  trackEvent('Click', {
    event_category: 'Navigation',
    event_label: 'Logo',
    ...params,
  });
};

export const trackMemberPerksSeeAll = params => {
  trackEvent('Click', {
    event_category: 'Partnerships',
    event_label: 'See all',
    ...params,
  });
};

// GROUP MANAGEMENT

export const trackViewAllAccountsClick = params => {
  trackEvent('Click', {
    event_category: 'Group Management',
    event_label: 'View all',
    ...params,
  });
};

export const trackViewAccountsClick = params => {
  trackEvent('Click', {
    event_category: 'Group Management',
    event_label: 'View',
    ...params,
  });
};

export const trackEditAccountsClick = params => {
  trackEvent('Click', {
    event_category: 'Group Management',
    event_label: 'Edit',
    ...params,
  });
};

export const trackCreateGroupClick = params => {
  trackEvent('Click', {
    event_category: 'Group Management',
    event_label: 'Create',
    ...params,
  });
};

export const trackCloseViewEditDrawer = (eventLabel, eventCategory) => {
  trackEvent('Click', {
    event_category: eventCategory,
    event_label: eventLabel,
  });
};

// FLEX DESK

export const trackDesksReservationClick = eventLabel => {
  trackEvent('Click', {
    event_category: 'Desk Reservation',
    event_label: eventLabel,
  });
};

export const trackDatePickerClickFlex = params => {
  trackEvent('Click', {
    event_category: 'Flex Desk Landing',
    event_label: 'Datepicker',
    ...params,
  });
};

export const trackDatePickerSelectFlex = params => {
  trackEvent('Click', {
    event_category: 'Flex Desk Landing',
    event_label: 'Select Date',
    ...params,
  });
};

export const trackFloorFilterClickFlex = params => {
  trackEvent('Click', {
    event_category: 'Flex Desk Landing',
    event_label: 'Floor Filter',
    ...params,
  });
};

export const trackSaveGroupUpdates = () => {
  trackEvent('Click', {
    event_category: 'Edit Group',
    event_label: 'Save Updates',
  });
};

export const trackCreateNewGroup = () => {
  trackEvent('Click', {
    event_category: 'Create Group',
    event_label: 'Save Updates',
  });
};

export const trackReserveLanding = () => {
  trackEvent('Click', {
    event_category: 'Reserve Desk Landing',
    event_label: 'Find a Desk',
  });
};

export const trackHomePageNavButton = eventLabel => {
  trackEvent('Click', {
    event_category: 'Quick Link',
    event_label: eventLabel,
  });
};
export const trackDeskUsage = eventLabel => {
  trackEvent('Click', {
    event_category: 'Desk Usage',
    event_label: eventLabel,
  });
};

// Account Usage
export const trackClickAccountLink = () => {
  trackEvent('Click', {
    event_category: 'Manage Account',
    event_label: 'Account Link',
  });
};

export const trackChangeMonth = () => {
  trackEvent('Click', {
    event_category: 'Manage Account',
    event_label: 'Datepicker',
  });
};

export const trackOpenEditDrawer = () => {
  trackEvent('Click', {
    event_category: 'Manage Account',
    event_label: 'Edit Reservation Drawer Open',
  });
};

export const trackOpenCancelDrawer = () => {
  trackEvent('Click', {
    event_category: 'Manage Account',
    event_label: 'Cancel Reservation Drawer Open',
  });
};

export const trackOpenDayPassDrawer = () => {
  trackEvent('Click', {
    event_category: 'Manage Account',
    event_label: 'Day Passes',
  });
};

export const trackCloseDeskReservationSummary = () => {
  trackEvent('Click', {
    event_category: 'Reservation Summary',
    event_label: 'Close',
  });
};

export const trackDeskReservationSummaryReserve = () => {
  trackEvent('Click', {
    event_category: 'Reservation Summary',
    event_label: 'Reserve Desk',
  });
};

export const trackManageWorkspacesClick = label => {
  trackEvent('Click', {
    event_category: 'Manage Workspace',
    event_label: label,
  });
};

export const trackVisitingMemberReservationsClick = label => {
  trackEvent('Click', {
    event_category: 'Visiting Member Reservations',
    event_label: label,
  });
};

export const trackOpenUpcomingClick = () => {
  trackEvent('Click', {
    event_category: 'Upcoming Reservations',
    event_label: 'All Reservations Drawer',
  });
};

export const trackOpenViewAllReservationsClick = () => {
  trackEvent('Click', {
    event_category: 'Upcoming Reservations',
    event_label: 'All Reservations Page',
  });
};

export const trackOpenRoomReservationInUpcomingClick = () => {
  trackEvent('Click', {
    event_category: 'Upcoming Reservations',
    event_label: 'Room Reservation',
  });
};

export const trackOpenDeskReservationInUpcomingClick = () => {
  trackEvent('Click', {
    event_category: 'Upcoming Reservations',
    event_label: 'Desk Reservation',
  });
};

export const trackOpenOfficeReservationInUpcomingClick = () => {
  trackEvent('Click', {
    event_category: 'Upcoming Reservations',
    event_label: 'Office Reservation',
  });
};

export const trackUpdateDeskReservation = () => {
  trackEvent('Click', {
    event_category: 'Desk Reservation Summary',
    event_label: 'Cancel',
  });
};
export const trackCancelDeskReservation = () => {
  trackEvent('Click', {
    event_category: 'Desk Reservation Summary',
    event_label: 'Cancel',
  });
};
export const trackOpenSeatReservationInUpcomingClick = () => {
  trackEvent('Click', {
    event_category: 'Upcoming Reservations',
    event_label: 'Seat Reservation',
  });
};

export const trackUpdateSeatReservation = () => {
  trackEvent('Click', {
    event_category: 'Seat Reservation Summary',
    event_label: 'Update',
  });
};
export const trackCancelSeatReservation = () => {
  trackEvent('Click', {
    event_category: 'Seat Reservation Summary',
    event_label: 'Cancel',
  });
};

export const trackReserveCommunitySeat = () => {
  trackEvent('Click', {
    event_category: 'Reserve Community Seat',
    event_label: 'Community Seat Reservation Page',
  });
};

export const trackReserveMeetingRoom = () => {
  trackEvent('Click', {
    event_category: 'Reserve Meeting Room',
    event_label: 'Community Seat Reservation Page',
  });
};
