import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Lodash from 'lodash';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { connect } from 'react-redux';
import { useMemo, useState } from 'react';

import { TextButton, LocationText, SkeletonLoader } from '..';
import { trackLocationDropdownClick } from '../../../Core/Tracking';
import { breakpoints } from '../../../Core/Theme';
import colors from '../../../Core/Theme/colors';
import { getIsIntegrated } from '../../../Models/groupings';
import { getUserProfile } from '../../../Redux/Common/UserManager/selectors';
import {
  getIntegratedGroupings,
  getLocationGroupings,
  getUserDefaultGrouping,
} from '../../../Redux/Common/Groupings/selectors';
import {
  filterLocationsForFeatureToggle,
  makeOption,
} from '../GroupingLocationList/utils';
import BaseLocationListDrawer from '../BaseLocationListDrawer';

const LocationEditor = props => {
  const {
    classes,
    selectLocation,
    gaCategory,
    selectedGrouping,
    selectGrouping,
    disabled,
    className,
    datatestid,
    integratedGroupings,
    profile,
    locationGroupings,
    featureToggleKey,
    userDefaultGrouping,
    titleKey,
    withoutViewHomeLocation,
    isLoading,
  } = props;

  const { t } = useTranslation();

  const [isDrawerOpen, setDrawerOpen] = useState(false);
  const [menuAnchorEl, setAnchorEl] = useState(null);

  const openMenu = event => {
    setAnchorEl(event.currentTarget);
  };
  const closeMenu = () => {
    setAnchorEl(null);
  };

  const openDrawer = () => setDrawerOpen(true);
  const closeDrawer = () => setDrawerOpen(false);

  const selectWrapped = l => {
    if (gaCategory) {
      trackLocationDropdownClick(l?.displayName, gaCategory);
    }
    selectLocation(l);
    closeDrawer();
  };

  const selectGroupingWrapped = g => () => {
    selectGrouping(g);
    closeMenu();
  };

  const groupingMenuItems = useMemo(() => {
    return Lodash.sortBy(
      integratedGroupings
        .filter(
          g =>
            g.locationSfId === selectedGrouping?.locationSfId &&
            getIsIntegrated(g)
        )
        .map(l => {
          return { value: l, label: l.groupingName };
        }),
      'label'
    ).map(g => (
      <MenuItem
        className={classes.groupingMenuItem}
        onClick={selectGroupingWrapped(g.value)}
      >
        {g.label}
      </MenuItem>
    ));
  }, [selectedGrouping?.locationSfId, integratedGroupings.length]);

  const options = useMemo(
    () =>
      filterLocationsForFeatureToggle(
        profile,
        locationGroupings,
        featureToggleKey
      ).map(makeOption(t)),
    [featureToggleKey, locationGroupings.length]
  );

  const getIsItemSelected = item => {
    return selectedGrouping.idGrouping === item.value.idGrouping;
  };

  const onReturnHome = () => selectWrapped(userDefaultGrouping);

  const shouldRenderReturnHome = useMemo(() => {
    if (withoutViewHomeLocation) return false;

    if (!userDefaultGrouping?.openToCoworkingMembers) {
      return selectedGrouping?.idGrouping !== userDefaultGrouping?.idGrouping;
    }
    return selectedGrouping?.locationSfId !== profile?.primaryLocationSfId;
  }, [
    selectedGrouping?.idGrouping,
    userDefaultGrouping?.idGrouping,
    profile?.primaryLocationSfId,
  ]);

  return (
    <div className={clsx(classes.wrapper, className)}>
      {isLoading ? (
        <SkeletonLoader className={classes.loader} />
      ) : (
        <>
          {getIsIntegrated(selectedGrouping) && (
            <div className={classes.groupingWrapper}>
              <div>{selectedGrouping?.groupingName}</div>
              {groupingMenuItems.length > 1 && !disabled && (
                <div>
                  <TextButton
                    text={t('locationEditor.change_group')}
                    onClick={openMenu}
                    className={classes.groupingTextButton}
                    datatestid={`${datatestid}_change_group_button`}
                  />
                  <Menu
                    anchorEl={menuAnchorEl}
                    open={Boolean(menuAnchorEl)}
                    onClose={closeMenu}
                    getContentAnchorEl={null}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'right',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                    classes={{ paper: classes.groupingMenu }}
                  >
                    {groupingMenuItems}
                  </Menu>
                </div>
              )}
            </div>
          )}
          <div>
            <LocationText
              selectedGrouping={selectedGrouping}
              rootClasses={{ root: classes.locationNameRoot }}
            />
            {!disabled && (
              <>
                <TextButton
                  text={t('locationEditor.change_location')}
                  onClick={openDrawer}
                  className={classes.locationTextButton}
                  datatestid={`${datatestid}_change_location_button`}
                />
                <BaseLocationListDrawer
                  onCloseDrawer={closeDrawer}
                  isOpen={isDrawerOpen}
                  onChange={selectWrapped}
                  withSelectCheck
                  titleKey={titleKey}
                  options={options}
                  value={selectedGrouping}
                  withHeader
                  onReturnHome={onReturnHome}
                  shouldRenderReturnHome={shouldRenderReturnHome}
                  withoutViewHomeLocation={withoutViewHomeLocation}
                  getIsItemSelected={getIsItemSelected}
                />
              </>
            )}
          </div>
        </>
      )}
    </div>
  );
};

const styles = theme => ({
  wrapper: {
    display: 'flex',

    [theme.breakpoints.up(breakpoints.MOBILE)]: {
      float: 'right',
    },
    [theme.breakpoints.down(breakpoints.MOBILE)]: {
      flexDirection: 'row-reverse',
      justifyContent: 'space-between',
      width: '90%',
    },
  },
  groupingMenuItem: {
    fontFamily: 'VerlagBook',
    fontSize: 14,
    paddingTop: 5,
    paddingBottom: 5,
    '&:hover': {
      background: `${colors.palette.secondary2.main} !important`,
    },
    '&:focus:not(.focus-visible)': {
      background: 'white',
    },
  },
  groupingMenu: {
    borderRadius: 3,
    boxShadow: '0 0 4px 1px rgba(0, 0, 0, 0.1)',
    '&::-webkit-scrollbar': {
      width: 0 /* Remove scrollbar space */,
      background: 'transparent' /* Optional: just make scrollbar invisible */,
    },
  },
  groupingWrapper: {
    marginRight: 20,
    [theme.breakpoints.up(breakpoints.MOBILE)]: {
      textAlign: 'right',
    },
  },
  locationTextButton: {
    padding: 0,
    display: 'block',
    float: 'right',
  },
  groupingTextButton: {
    padding: 0,
  },
  locationNameRoot: {
    justifyContent: 'flex-end',
  },
  loader: {
    height: 30,
    width: 200,
  },
});

LocationEditor.defaultProps = {
  gaCategory: '',
  disabled: false,
  selectedGrouping: {},
  selectGrouping: () => {},
  selectLocation: () => {},
  className: '',
  datatestid: 'location_editor',
  featureToggleKey: null,
  titleKey: 'locationEditor.change_location',
  withoutViewHomeLocation: false,
  isLoading: false,
};

LocationEditor.propTypes = {
  classes: PropTypes.object.isRequired,
  selectLocation: PropTypes.func,
  gaCategory: PropTypes.string,
  selectedGrouping: PropTypes.object,
  selectGrouping: PropTypes.func,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  datatestid: PropTypes.string,
  integratedGroupings: PropTypes.arrayOf(PropTypes.object).isRequired,
  locationGroupings: PropTypes.arrayOf(PropTypes.object).isRequired,
  profile: PropTypes.object.isRequired,
  featureToggleKey: PropTypes.string,
  userDefaultGrouping: PropTypes.object.isRequired,
  titleKey: PropTypes.string,
  withoutViewHomeLocation: PropTypes.bool,
  isLoading: PropTypes.bool,
};

const mapStateToProps = (state, ownProps) => {
  return {
    profile: getUserProfile(state),
    integratedGroupings: getIntegratedGroupings(state),
    locationGroupings:
      ownProps.locationGroupings ?? getLocationGroupings(state),
    userDefaultGrouping:
      ownProps.userDefaultGrouping ?? getUserDefaultGrouping(state),
  };
};

export default connect(mapStateToProps)(withStyles(styles)(LocationEditor));
