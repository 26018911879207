import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

//  Components
import OpenInNew from '@material-ui/icons/OpenInNew';
import { SubmitButton } from '../../../../Components/Common';
import Button from '../../../../Components/Common/BaseButton';

// Theme
import { colors } from '../../../../Core/Theme';

const RsvpButton = props => {
  const {
    rsvpd,
    canRsvp,
    eventFull,
    ableToWaitlist,
    waitlisted,
    isLoading,
    classes,
    rsvpAction,
    unrsvpAction,
    externalRegistrationLink,
    externalRegistrationLinkAction,
    className,
    datatestid,
  } = props;
  const { t } = useTranslation();
  const renderButton = () => {
    if (!rsvpd && !eventFull) {
      return (
        <SubmitButton
          className={clsx(classes.submitButton, className)}
          defaultText={t('events.eventRsvps.eventRsvpAttend')}
          onClick={rsvpAction}
          isLoading={isLoading}
          isSuccess={false}
          datatestid={`${datatestid}_rsvp`}
        />
      );
    }

    if ((rsvpd && !waitlisted) || (eventFull && isLoading && !waitlisted)) {
      return (
        <Button
          className={clsx(classes.attendingButton, className)}
          size="large"
          name={t('events.eventRsvps.eventRsvpAttending')}
          fullWidth
          onClick={unrsvpAction}
          datatestid={`${datatestid}_attending`}
        />
      );
    }

    if (rsvpd && eventFull && ableToWaitlist && waitlisted) {
      return (
        <Button
          className={clsx(classes.attendingButton, className)}
          size="large"
          name={t('events.eventRsvps.waitlisted')}
          fullWidth
          onClick={unrsvpAction}
          datatestid={`${datatestid}_waitlisted`}
        />
      );
    }

    if (!rsvpd && eventFull && ableToWaitlist && !waitlisted) {
      return (
        <SubmitButton
          className={clsx(classes.submitButton, className)}
          defaultText={t('events.eventRsvps.waitlist')}
          onClick={rsvpAction}
          isLoading={isLoading}
          isSuccess={false}
          datatestid={`${datatestid}_join_waitlist`}
        />
      );
    }

    if (!rsvpd && eventFull && !ableToWaitlist && !isLoading) {
      return (
        <div className={clsx(classes.eventFull, className)}>Event Full</div>
      );
    }

    return null;
  };

  return (
    <>
      {canRsvp && !externalRegistrationLink ? <>{renderButton()}</> : null}
      {canRsvp && externalRegistrationLink ? (
        <>
          <SubmitButton
            className={clsx(classes.submitButton, className)}
            defaultText={
              <>
                {t('events.eventRsvps.register')}
                <OpenInNew classes={{ root: classes.register }} />
              </>
            }
            onClick={externalRegistrationLinkAction}
            isLoading={isLoading}
            isSuccess={false}
          />
        </>
      ) : null}
    </>
  );
};

const VERTICAL_CARD_BREAKPOINT = 570;
const styles = theme => ({
  eventFull: {
    margin: '0px 0 10px 0',
    backgroundColor: colors.light,
    color: colors.black,
    fontFamily: 'VerlagBold',
    fontSize: 15,
    textAlign: 'center',
    lineHeight: '40px',
    width: '100%',
    [theme.breakpoints.up(VERTICAL_CARD_BREAKPOINT)]: {
      maxWidth: '238px',
      height: '40px',
      padding: 0,
      marginTop: 20,
      borderRadius: 20,
    },
    [theme.breakpoints.down(VERTICAL_CARD_BREAKPOINT)]: {
      height: 50,
      lineHeight: '50px',
      marginTop: 20,
      borderRadius: 25,
    },
  },
  submitButton: {
    margin: '0 0 10px 0',
    [theme.breakpoints.up(VERTICAL_CARD_BREAKPOINT)]: {
      maxWidth: '238px',
      height: '40px',
      padding: 0,
      marginTop: 20,
    },
    [theme.breakpoints.down(VERTICAL_CARD_BREAKPOINT)]: {
      height: 50,
      marginTop: 20,
      lineHeight: '50px',
    },
  },
  attendingButton: {
    [theme.breakpoints.up(VERTICAL_CARD_BREAKPOINT)]: {
      maxWidth: '238px',
      height: '40px',
      padding: 0,
      marginTop: 20,
    },
    [theme.breakpoints.down(VERTICAL_CARD_BREAKPOINT)]: {
      height: 50,
      marginTop: 20,
      lineHeight: '50px',
    },
  },
  attendingProgress: {
    color: colors.palette.primary.main,
  },
  register: {
    fontSize: 12,
    marginLeft: 4,
    color: colors.white,
    background: 'transparent !important',
    boxShadow: 'none !important',
  },
});

RsvpButton.defaultProps = {
  rsvpd: false,
  canRsvp: false,
  eventFull: false,
  ableToWaitlist: false,
  waitlisted: false,
  isLoading: false,
  rsvpAction: () => '',
  unrsvpAction: () => '',
  externalRegistrationLinkAction: () => '',
  externalRegistrationLink: null,
  className: '',
  datatestid: 'event_status',
};

RsvpButton.propTypes = {
  rsvpd: PropTypes.bool,
  canRsvp: PropTypes.bool,
  eventFull: PropTypes.bool,
  ableToWaitlist: PropTypes.bool,
  waitlisted: PropTypes.bool,
  isLoading: PropTypes.bool,
  rsvpAction: PropTypes.func,
  unrsvpAction: PropTypes.func,
  externalRegistrationLinkAction: PropTypes.func,
  externalRegistrationLink: PropTypes.string,
  classes: PropTypes.shape({}).isRequired,
  className: PropTypes.string,
  datatestid: PropTypes.string,
};

export default withStyles(styles)(RsvpButton);
