import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { Panel } from '../../../Components/Common';
import { breakpoints } from '../../../Core/Theme';
import DeskToggleContents from './DeskToggleContents';

const DeskTogglePanel = props => {
  const {
    classes,
    isOpen,
    isUnassigned,
    dirty,
    submitForm,
    isLoading,
    isSuccess,
  } = props;
  const { t } = useTranslation();
  return (
    <div className={classes.wrapper}>
      <Panel
        isOpen={isOpen}
        onClickClose={() => null}
        onClickButtonFooter={submitForm}
        hasFooter
        buttonFooter={t('general.save_updates')}
        variant="light"
        hideMobileCloseButton
        title={t('deskAdmin.title')}
        withoutRightArrow
        buttonDisabled={!dirty}
        buttonIsLoading={isLoading}
        buttonIsSuccess={isSuccess}
      >
        <div className={classes.main}>
          <DeskToggleContents
            isUnassigned={isUnassigned}
            className={classes.form}
            {...props}
          />
        </div>
      </Panel>
    </div>
  );
};

const styles = theme => ({
  wrapper: {
    [theme.breakpoints.down(breakpoints.MOBILE)]: {
      display: 'none',
    },
  },
  drawerPaper: {
    top: 0,
    borderTop: 'none',
    overflow: 'hidden',
  },
  main: {
    textAlign: 'center',
  },
  form: {
    marginTop: 20,
    display: 'flex',
    flexDirection: 'column',
  },
});

DeskTogglePanel.defaultProps = {};

DeskTogglePanel.propTypes = {
  classes: PropTypes.object.isRequired,
  isOpen: PropTypes.bool.isRequired,
  isUnassigned: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isSuccess: PropTypes.bool.isRequired,
  dirty: PropTypes.bool.isRequired,
  submitForm: PropTypes.func.isRequired,
};

export default withStyles(styles)(DeskTogglePanel);
