import React from 'react';

// Components
import Label from './Label';

// Types
import { Layers } from '../types';

// Theme & Utils
import { colors } from '../../../Core/Theme';
import { createHash } from '../../../Core/Utils';

const LAYER_ID = Layers.Label;

// Layer properties
const symbolProperties = {
  id: `${LAYER_ID}-CommonLabel`,
  type: 'symbol',
  source: 'features',
  paint: {
    'text-color': colors.darkest,
  },
  layout: {
    'text-size': ['interpolate', ['linear'], ['zoom'], 15, 10, 18, 12],
    'text-allow-overlap': true,
    'text-transform': 'uppercase',
    'text-font': ['Verlag Bold'],
  },
};

type CommonLabelProps = {
  commonAreas: string[];
  labelVisible: boolean;
  indicators: { [key: string]: string };
};

const CommonLabel = (props: CommonLabelProps) => {
  const { commonAreas, labelVisible, indicators } = props;

  return (
    <Label
      labelKey={`${LAYER_ID}-CommonLabel--${createHash(`${labelVisible}`)}`}
      properties={[symbolProperties]}
      filter={['in', ['get', 'associatedFeature'], ['literal', commonAreas]]}
      layout={{
        ...symbolProperties.layout,
        'text-field': [
          'get',
          ['get', 'associatedFeature'],
          ['literal', indicators],
        ],
      }}
      {...props}
    />
  );
};

export default React.memo(CommonLabel);
