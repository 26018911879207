import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { fonts } from '../../../Core/Theme';

const Text = props => {
  const { classes, textStyle, text, className, datatestid, ...rest } = props;
  return (
    <span
      datatestid={datatestid}
      className={clsx(className, classes ? classes[textStyle] : undefined)}
      {...rest}
    >
      {text}
    </span>
  );
};

const styles = fonts;
Text.defaultProps = {
  textStyle: '',
  datatestid: '',
  className: '',
};

Text.propTypes = {
  textStyle: PropTypes.string,
  text: PropTypes.string.isRequired,
  datatestid: PropTypes.string,
  className: PropTypes.string,
};

export default withStyles(styles)(Text);
