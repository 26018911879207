import { createAction } from 'redux-actions';
import * as types from './actionTypes';
import { fetchHandlerActions } from '../../../Core/Utils/fetch';
import { fetchCommunityMessagePage } from '../../../Core/Api';

export const setLocation = createAction(types.SET_LOCATION);

export const fetchMessages = createAction(
  types.GET_MESSAGES,
  fetchHandlerActions(fetchCommunityMessagePage),
  (limit, offset, locationSfIds, override) => ({
    limit,
    offset,
    locationSfIds,
    override,
  })
);

//  opted not for thunk because we want to handle error state at container
export const createMessage = createAction(types.CREATE_MESSAGE);

export const resetHasMoreOffset = createAction(types.RESET_HASMORE_OFFSET);
