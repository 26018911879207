import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

import { trackRoomReservationFilter } from '../../../../Core/Tracking';

import Redux from '../../Redux';
import store from '../../../../Redux';
import styles from './styles';
import { FILTERS_TYPE } from '../../constants';

function trackFilterButton(value, type, status) {
  if (!status) {
    switch (type) {
      case FILTERS_TYPE.AMENITY:
        trackRoomReservationFilter(FILTERS_TYPE.AMENITY, value);
        break;
      case FILTERS_TYPE.SEAT:
        trackRoomReservationFilter(FILTERS_TYPE.SEAT, value);
        break;
      case FILTERS_TYPE.FLOOR:
        trackRoomReservationFilter(FILTERS_TYPE.FLOOR, value);
        break;
      default:
        break;
    }
  }
}

const FilterButton = props => {
  const { classes, datatestid, withTracking } = props;
  const { status, content, value, type, isLast, onFilterButtonClick } = props;
  let style = status
    ? classes.filterButtonActive
    : classes.filterButtonInactive;
  if (isLast) {
    style = `${style} ${classes.lastAmen}`;
  }

  const handleOnclick = () => {
    if (withTracking) {
      trackFilterButton(value, type, status);
    }
    if (onFilterButtonClick) {
      return onFilterButtonClick(value, type, status);
    }

    return store.dispatch(Redux.actions.toggleFilter(value, type));
  };

  return (
    <button
      className={style}
      key={value}
      onClick={handleOnclick}
      type="button"
      datatestid={datatestid}
    >
      {content}
    </button>
  );
};

FilterButton.defaultProps = {
  isLast: false,
  onFilterButtonClick: null,
  withTracking: true,
  datatestid: '',
};

FilterButton.propTypes = {
  status: PropTypes.bool.isRequired,
  content: PropTypes.node.isRequired,
  value: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired,
  isLast: PropTypes.bool,
  datatestid: PropTypes.string,
  onFilterButtonClick: PropTypes.func,
  withTracking: PropTypes.bool,
};

export default withStyles(styles)(FilterButton);
