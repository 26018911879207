import { AnyReservationDeskDto } from './AnyReservationDeskDto';
import { AnyReservationMeetingRoomDto } from './AnyReservationMeetingRoomDto';
import { AnyReservationSeatDto } from './AnyReservationSeatDto';
import { AnyReservationSrvpDto } from './AnyReservationSrvpDto';

export enum AnyReservationTypes {
  Reservation = 'reservation',
  SeatBooking = 'seatbooking',
  Booking = 'booking',
  Rsvp = 'rsvp',
}

export type AnyReservation =
  | AnyReservationDeskDto
  | AnyReservationSeatDto
  | AnyReservationMeetingRoomDto
  | AnyReservationSrvpDto;

export interface AnyReservationPageDto {
  offset: number;
  limit: number;
  count: number;
  reservations: {
    type: AnyReservationTypes;
    reservation: AnyReservation;
  }[];
}
