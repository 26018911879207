export const ZOOM_LEVEL = {
  vector: {
    INITIAL_ZOOM: 14,
    MIN_ZOOM: 12,
    MAX_ZOOM: 16,
    VISIBLE_ZOOM_LABELS: 15,
    DEFAULT_FLY_TO_ZOOM: 16,
  },
  raster: {
    INITIAL_ZOOM: 14,
    MIN_ZOOM: 12,
    MAX_ZOOM: 16,
    VISIBLE_ZOOM_LABELS: 15,
    DEFAULT_FLY_TO_ZOOM: 16,
  },
};

export const ZOOM_KEYS = {
  INITIAL_ZOOM: 'INITIAL_ZOOM',
  MIN_ZOOM: 'MIN_ZOOM',
  MAX_ZOOM: 'MAX_ZOOM',
  VISIBLE_ZOOM_LABELS: 'VISIBLE_ZOOM_LABELS',
  DEFAULT_FLY_TO_ZOOM: 'DEFAULT_FLY_TO_ZOOM',
};

export const DEFAULT_VIEWPORT = {
  width: '100vw',
  height: '64vh',
};

export const GEOMETRY_TYPES = {
  POLYGON: 'Polygon',
  LINE_STRING: 'LineString',
  POINT: 'Point',
};

export const LAYERS = {
  LABEL: 'Label',
  OVERLAY: 'Overlay',
  COMMON: 'CommonArea',
  OFFICE: 'Office',
  UTILIZED_OFFICE: 'UtilizedOffice',
  UNASSIGNED_OFFICE: 'UnassignedOffice',
  RESERVABLE_OFFICE: 'ReservableOffice',
  RESERVABLE_OFFICE_UNAVAILABLE: 'ReservableOfficeUnavailable',
  RESERVABLE_DESK: 'ReservableDesk',
  UNRESERVABLE_DESK: 'UnreservableDesk',
  UTILIZED_RESERVABLE_OFFICE: 'UtilizedReservableOffice',
  // User
  ASSIGNED_OFFICE: 'AssignedOffice',
  AVAILABLE_DESK: 'AvailableDesk',
  UNAVAILABLE_DESK: 'UnavailableDesk',
  SELECTED_DESK: 'SelectedDesk',
  UNAVAILABLE_OFFICE: 'UnavailableOffice',
  UNSELECTABLE_DESK: 'UnselectableDesk',
  USER_RESERVED_DESK: 'UserReservedDesk',
};

export const FEATURES_TYPE = {
  CAFE: 'Cafe',
  BATHROOM: 'Bathroom',
  OFFICE: 'Office',
  DESK: 'Desk',
  CHAIR: 'Chair',
  CONFERENCE: 'Conference',
  LABEL: 'Label',
  PHONE_BOOTH: 'Phone Booth',
  COPY_ROOM: 'Copy Room',
  CANVAS: 'Canvas',
  MAILROOM: 'Mailroom',
  MECHANICS: 'Mechanics',
  STORAGE: 'Storage',
  COMMON_SPACES: 'Common Spaces',
  CONFIG: 'Config',
};

export const LABEL_DISABLED_MAPBOX_FEATURE_TYPE = [
  FEATURES_TYPE.PHONE_BOOTH,
  FEATURES_TYPE.COPY_ROOM,
  FEATURES_TYPE.MAILROOM,
  FEATURES_TYPE.CANVAS,
  FEATURES_TYPE.MECHANICS,
  FEATURES_TYPE.STORAGE,
];

export const INTERACTIVE_MAPBOX_FEATURE_TYPE = [
  FEATURES_TYPE.OFFICE,
  FEATURES_TYPE.DESK,
  FEATURES_TYPE.CHAIR,
];

export const MAP_TYPE = {
  DESK_RESERVATION: 'DESK_RESERVATION',
  MANAGE_WORKSPACES: 'MANAGE_WORKSPACES',
};

export const RESOURCE_TYPE_DESK = 'Desk';
