import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { colors, breakpoints } from '../../../../Core/Theme';
import { date as DateUtils } from '../../../../Core/Utils';
import { seatBookingRoleAccess } from '../../../../Core/Utils/userPermissions';
import { MapLink, TextButton } from '../../../../Components/Common';

const handleKeyDown = fxn => e => {
  if (e.key === 'Enter') {
    fxn();
  }
};

const ReservationCard = props => {
  const {
    classes,
    reservation,
    onClick,
    locationsById,
    profile,
    groupingsById,
    onEdit,
  } = props;
  const { t } = useTranslation();

  const locationSfId = reservation?.grouping?.location?.sfId;
  const reservationGroupingId = reservation?.groupingId;
  const userPrimaryLocation = locationsById?.[profile.primaryLocationSfId];
  const location = locationsById?.[locationSfId];
  const startEpoch = reservation?.startEpoch;
  const objMomentStartEpoch = DateUtils.getDateInMoment(startEpoch).tz(
    location?.timeZoneId
  );

  const reservationGrouping = groupingsById?.[reservationGroupingId];

  const clickWrapped = () => onClick(reservation);

  const canEdit = seatBookingRoleAccess.update({ obj: reservation, profile });

  return (
    <div
      className={classes.card}
      onClick={clickWrapped}
      role="button"
      tabIndex={0}
      onKeyDown={handleKeyDown(clickWrapped)}
    >
      <div className={classes.dayBox}>
        <div className={classes.dayBoxDay}>
          {DateUtils.getDayString(objMomentStartEpoch?.unix(), undefined, t)}
        </div>
        <div className={classes.dayBoxDate}>
          {objMomentStartEpoch
            .tz(userPrimaryLocation?.timeZoneId)
            .format('[MMM DD]')}
        </div>
      </div>
      <div className={classes.content}>
        <div className={classes.topWrapper}>
          <div className={classes.title}>
            {t('openWorkspace.upcoming.reservationCard.title')}
          </div>
          {canEdit && (
            <TextButton
              text={t('general.edit')}
              onClick={onEdit}
              className={classes.editButton}
            />
          )}
        </div>
        <div className={classes.locationContainer}>
          {location ? (
            <MapLink
              location={location}
              grouping={reservationGrouping}
              profile={profile}
              className={classes.locationTitle}
            />
          ) : null}
        </div>
      </div>
    </div>
  );
};

const styles = theme => ({
  card: {
    background: colors.white,
    borderTop: `solid 3px ${colors.palette.secondary.main}`,
    borderRadius: 4,
    marginBottom: 10,
    display: 'flex',
    '&:focus:not(.focus-visible)': {
      outline: 'none',
      boxShadow: '0 0 6px 3px rgba(0, 0, 0, 0.8)',
    },
    '&:hover': {
      cursor: 'pointer',
      boxShadow: '0 0 6px 3px rgba(0, 0, 0, 0.8)',
    },
    height: 79,
  },
  dayBox: {
    minWidth: 65,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    borderRight: `solid 1px ${colors.middle}`,
  },
  dayBoxDay: {
    fontFamily: 'VerlagBold',
    fontSize: 9,
  },
  dayBoxDate: {
    fontFamily: 'VerlagBold',
    fontSize: 18,
    marginTop: 5,
  },
  content: {
    paddingLeft: 10,
    fontSize: 14,
    fontFamily: 'VerlagBook',
    textAlign: 'left',
    width: '100%',
    [theme.breakpoints.down(breakpoints.MOBILE)]: {
      width: 'calc(100% - 75px)',
    },
  },
  title: {
    fontFamily: 'VerlagBold',
    paddingTop: 21,
  },
  locationTitle: {
    padding: 0,
    fontSize: 14,
    fontFamily: 'VerlagBook',
    marginLeft: 0,
    marginRight: 0,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '130px',
  },
  locationContainer: {
    marginBottom: 5,
  },
  editButton: {
    paddingRight: 10,
    fontSize: 14,
  },
  topWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
  },
});

ReservationCard.defaultProps = {
  onClick: () => {},
  onEdit: PropTypes.func,
};
ReservationCard.propTypes = {
  classes: PropTypes.object.isRequired,
  locationsById: PropTypes.object.isRequired,
  onClick: PropTypes.func,
  reservation: PropTypes.object.isRequired,
  profile: PropTypes.object.isRequired,
  groupingsById: PropTypes.object.isRequired,
  onEdit: PropTypes.func,
};

export default withStyles(styles)(ReservationCard);
