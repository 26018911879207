/* eslint-disable react/require-default-props */

import clsx from 'clsx';

import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog, { DialogProps } from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { Theme, withStyles } from '@material-ui/core/styles';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';

import Text from '../Text';
import { breakpoints } from '../../../Core/Theme';
import SubmitButton from '../SubmitButton';
import Button from '../BaseButton';

interface Props extends Partial<DialogProps> {
  onRightActionPress: () => void;
  onLeftActionPress?: () => void;
  title: string;
  leftActionLabel?: string;
  rightActionLabel?: string;
  description?: string;
  isVisible: boolean;
  classes: ClassNameMap;
  onClose: () => void;
  isRightActionLoading?: boolean;
}

const ConfirmationBox = (props: Props) => {
  const {
    classes,
    onRightActionPress = () => null,
    onLeftActionPress = () => null,
    title,
    leftActionLabel = null,
    rightActionLabel,
    description = null,
    isVisible,
    onClose,
    isRightActionLoading = false,
    ...rest
  } = props;
  const header = () => {
    return (
      <DialogTitle disableTypography className={classes.headerContainer}>
        <Text text={title} className={classes.titleConfirmation} />
      </DialogTitle>
    );
  };
  const actions = () => {
    return (
      <div
        className={clsx(
          classes.footerContent,
          !leftActionLabel && classes.footerWithOneAction
        )}
      >
        {leftActionLabel ? (
          <Button
            className={classes.deleteButton}
            name={leftActionLabel}
            onClick={onLeftActionPress}
          />
        ) : null}
        <SubmitButton
          className={classes.submitButton}
          defaultText={rightActionLabel}
          onClick={onRightActionPress}
          isLoading={isRightActionLoading}
        />
      </div>
    );
  };

  return (
    <Dialog
      {...rest}
      onClose={onClose}
      aria-labelledby="simple-dialog-title"
      open={isVisible}
      classes={{
        paper: classes.dialogContainer,
      }}
    >
      {header()}
      {description ? (
        <DialogContent>
          <Text text={description} />
        </DialogContent>
      ) : null}
      {actions()}
    </Dialog>
  );
};

const styles = (theme: Theme) => ({
  titleConfirmation: {
    fontFamily: 'VerlagBold',
    fontSize: '20px',
  },
  submitButton: {
    [theme.breakpoints.up(breakpoints.MOBILE)]: {
      width: '147px',
    },
    [theme.breakpoints.down(breakpoints.MOBILE)]: {
      width: '47%',
      marginLeft: 10,
    },
    marginTop: 0,
    marginLeft: 25,
  },
  deleteButton: {
    [theme.breakpoints.up(breakpoints.MOBILE)]: {
      width: '147px',
    },
    [theme.breakpoints.down(breakpoints.MOBILE)]: {
      width: '47%',
    },
  },
  footerContent: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingBottom: 20,
    paddingLeft: 25,
    paddingRight: 25,
  },
  footerWithOneAction: {
    justifyContent: 'flex-end',
  },
  dialogContainer: {
    width: '550px',
  },
  headerContainer: {
    marginBottom: '14px',
  },
});

export default withStyles(styles)(ConfirmationBox);
