/* eslint-disable react/require-default-props */

import { StyleRulesCallback, Theme, withStyles } from '@material-ui/core';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import clsx from 'clsx';
import { SkeletonLoader } from '../../../../Components/Common';
import { breakpoints, colors } from '../../../../Core/Theme';

type Props = {
  classes: ClassNameMap;
  isLong?: boolean;
  label: string;
  value?: string;
  isLoading?: boolean;
};

const TrackingTableItem = (props: Props) => {
  const { classes, isLong = false, label, value, isLoading } = props;

  return (
    <div
      className={clsx(classes.default, isLong && classes.longer)}
      key={`trackingnhourkey_${label}`}
    >
      <p className={classes.label}> {label} </p>
      <hr className={classes.horizon} />
      {isLoading ? (
        <SkeletonLoader className={classes.loader} />
      ) : (
        <p className={classes.value}> {value} </p>
      )}
    </div>
  );
};

const styles: StyleRulesCallback<string> = (theme: Theme) => ({
  default: {
    [theme.breakpoints.up(breakpoints.MOBILE)]: {
      flex: '1 0 20%',
      minWidth: 65,
    },
    minWidth: 140,
    width: '50%',
    marginBottom: 5,
  },
  longer: {
    [theme.breakpoints.up(breakpoints.MOBILE)]: {
      flex: '1 0 40%',
      minWidth: 65,
    },
  },
  value: {
    fontFamily: 'VerlagLight',
    fontSize: 15,
    margin: '0px',
    lineHeight: 1.5,
  },
  label: {
    fontFamily: 'VerlagBook',
    fontSize: 14,
    color: colors.black,
    margin: '0px',
    lineHeight: 1.29,
    marginBottom: 10,
    marginTop: 10,
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    [theme.breakpoints.up(breakpoints.MOBILE)]: {
      fontSize: 12,
      marginBottom: 0,
      marginTop: 0,
    },
  },
  horizon: {
    position: 'sticky',
    top: '0px',
    height: '1px',
    backgroundColor: colors.black,
    border: 'none',
    marginTop: '5px',
    marginBottom: '5px',
    width: '100%',
    [theme.breakpoints.down(breakpoints.MOBILE)]: {
      display: 'none',
    },
  },
  loader: {
    width: 65,
    height: 14,
  },
});

export default withStyles(styles)(TrackingTableItem);
