import reducer, * as reducers from './reducers';
import * as selectors from './selectors';
import * as actions from './actions';
import * as actionTypes from './actionTypes';

export default {
  reducers,
  reducer,
  actions,
  actionTypes,
  selectors,
};
