/* eslint-disable react/jsx-filename-extension */

export const CREATE_DRAWER = 'CREATE_DRAWER';

export const EDIT_DRAWER = 'EDIT_DRAWER';

export const GROUP_MANAGEMENT_PATH = '/home/groupManagement';

export const LOADING_CONTENT_DESKTOP = (
  <>
    <rect x="0" y="15" rx="4" ry="4" width="100" height="15" />
    <rect x="min(25vw, 350px)" y="15" rx="4" ry="4" width="150" height="15" />

    <rect x="0" y="50" rx="4" ry="4" width="100" height="15" />
    <rect x="min(25vw, 350px)" y="50" rx="4" ry="4" width="150" height="15" />

    <rect x="calc(100% - 50px)" y="15" rx="4" ry="4" width="50" height="15" />

    <rect x="0" y="80" rx="4" ry="4" width="100%" height="1" />

    <rect x="0" y="95" rx="4" ry="4" width="100" height="15" />
    <rect x="min(25vw, 350px)" y="95" rx="4" ry="4" width="150" height="15" />

    <rect x="0" y="130" rx="4" ry="4" width="100" height="15" />
    <rect x="min(25vw, 350px)" y="130" rx="4" ry="4" width="150" height="15" />

    <rect x="calc(100% - 50px)" y="95" rx="4" ry="4" width="50" height="15" />

    <rect x="0" y="160" rx="4" ry="4" width="100%" height="1" />

    <rect x="0" y="175" rx="4" ry="4" width="100" height="15" />
    <rect x="min(25vw, 350px)" y="175" rx="4" ry="4" width="150" height="15" />

    <rect x="0" y="210" rx="4" ry="4" width="100" height="15" />
    <rect x="min(25vw, 350px)" y="210" rx="4" ry="4" width="150" height="15" />

    <rect x="calc(100% - 50px)" y="175" rx="4" ry="4" width="50" height="15" />

    <rect x="0" y="240" rx="4" ry="4" width="100%" height="1" />
  </>
);
export const LOADING_CONTENT_MOBILE = (
  <>
    <rect x="0" y="15" rx="4" ry="4" width="125" height="15" />
    <rect x="50%" y="15" rx="4" ry="4" width="125" height="15" />

    <rect x="0" y="50" rx="4" ry="4" width="125" height="15" />
    <rect x="50%" y="50" rx="4" ry="4" width="125" height="15" />

    <rect x="0" y="80" rx="4" ry="4" width="100%" height="1" />

    <rect x="0" y="95" rx="4" ry="4" width="125" height="15" />
    <rect x="50%" y="95" rx="4" ry="4" width="125" height="15" />

    <rect x="0" y="130" rx="4" ry="4" width="125" height="15" />
    <rect x="50%" y="130" rx="4" ry="4" width="125" height="15" />

    <rect x="0" y="160" rx="4" ry="4" width="100%" height="1" />

    <rect x="0" y="175" rx="4" ry="4" width="125" height="15" />
    <rect x="50%" y="175" rx="4" ry="4" width="125" height="15" />

    <rect x="0" y="210" rx="4" ry="4" width="125" height="15" />
    <rect x="50%" y="210" rx="4" ry="4" width="125" height="15" />

    <rect x="0" y="240" rx="4" ry="4" width="100%" height="1" />
  </>
);
