import { TFunction } from 'i18next';
import { FloorDto } from '../Core/Api';
import { SelectOption } from './selectOption';

const sortFloors = (a: FloorDto, b: FloorDto) => {
  const numberA = +a.floorName;
  const numberB = +b.floorName;

  if (!Number.isNaN(numberA) && !Number.isNaN(numberB)) {
    return numberA - numberB;
  }

  return a.floorName.localeCompare(b.floorName);
};

const renderFloorValue = (floor: FloorDto, t: TFunction) => {
  return t('general.floor', { floorName: floor.floorName });
};

const createFloorOption = (
  floor: FloorDto,
  t: TFunction
): SelectOption<FloorDto> => ({
  value: floor,
  label: renderFloorValue(floor, t),
});

export default {
  sortFloors,
  createFloorOption,
  renderFloorValue,
};
