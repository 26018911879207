import { useEffect, useState } from 'react';
import { BlackoutDateDto, fetchLocationBlackoutDates } from '../Core/Api';

export const useLocationBlackoutDates = (props: { locationId?: string }) => {
  const { locationId } = props;
  const [blackoutDates, setBlackoutDates] = useState<BlackoutDateDto[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    const getLocationBlackoutDates = async (_locationId: string) => {
      setBlackoutDates([]);
      setIsLoading(true);
      setHasError(false);

      try {
        const response = await fetchLocationBlackoutDates(_locationId);

        const { data } = response;

        if (!data) {
          setHasError(true);
        }
        setBlackoutDates(data);
      } catch (error) {
        setHasError(true);
      } finally {
        setIsLoading(false);
      }
    };

    if (!locationId) {
      setBlackoutDates([]);
    } else {
      getLocationBlackoutDates(locationId);
    }
  }, [locationId]);

  return { blackoutDates, isLoading, hasError };
};
