import {
  bookingRoleAccess,
  officeReservationRoleAccess,
  reservationRoleAccess,
  seatBookingRoleAccess,
} from '../Core/Utils/userPermissions';

export const RESERVATION_ACTIONS = {
  update: 'update',
  delete: 'delete',
  view: 'view',
};

export const RESERVATION_TYPES = {
  SEAT_BOOKING: 'seatbooking',
  CONFERENCE_BOOKING: 'booking',
  DESK_RESERVATION: 'reservation',
  RSVP: 'rsvp',
  OFFICE_RESERVATION: 'officereservation',
};

const getReservationLocation = r => {
  const { reservation, type } = r;
  if (type === RESERVATION_TYPES.CONFERENCE_BOOKING) {
    return reservation?.space?.location;
  }
  if (type === RESERVATION_TYPES.RSVP) {
    return reservation?.occasion?.location;
  }
  return reservation?.grouping?.location;
};

const getReservationTimezoneId = r => {
  return getReservationLocation(r)?.timeZoneId;
};

const getReservationOwner = r => {
  const { reservation, type } = r;
  if (
    type === RESERVATION_TYPES.DESK_RESERVATION ||
    type === RESERVATION_TYPES.OFFICE_RESERVATION
  ) {
    return reservation?.owner;
  }
  return reservation.user;
};

const getReservationStartEpoch = r => {
  const { reservation, type } = r;
  if (type === RESERVATION_TYPES.RSVP) {
    return reservation?.occasion?.startEpoch;
  }
  return reservation.startEpoch;
};

const getReservationId = r => {
  const { reservation, type } = r;
  if (type === RESERVATION_TYPES.RSVP) {
    return reservation?.idRsvp;
  }
  if (type === RESERVATION_TYPES.CONFERENCE_BOOKING) {
    return reservation?.idBooking;
  }

  return reservation.id;
};

const getUpdateReservationPermissions = ({ reservation, profile, type }) => {
  switch (type) {
    case RESERVATION_TYPES.CONFERENCE_BOOKING:
      return bookingRoleAccess.update({ profile, obj: reservation });
    case RESERVATION_TYPES.SEAT_BOOKING:
      return seatBookingRoleAccess.update({
        profile,
        obj: { booking: reservation, numVisitingMemberBookings: 0 },
      }); // number is 0 because user can always edit booking to an available date });
    case RESERVATION_TYPES.DESK_RESERVATION:
      return reservationRoleAccess.update({ profile, obj: reservation });
    case RESERVATION_TYPES.OFFICE_RESERVATION:
      return false; // False until update is supported
    default:
      return false;
  }
};

const getDeleteReservationPermissions = ({ reservation, profile, type }) => {
  switch (type) {
    case RESERVATION_TYPES.CONFERENCE_BOOKING:
      return bookingRoleAccess.delete({ profile, obj: reservation });
    case RESERVATION_TYPES.SEAT_BOOKING:
      return seatBookingRoleAccess.delete({
        profile,
        obj: { booking: reservation },
      });
    case RESERVATION_TYPES.DESK_RESERVATION:
      return reservationRoleAccess.delete({ profile, obj: reservation });
    case RESERVATION_TYPES.OFFICE_RESERVATION:
      return officeReservationRoleAccess.delete({ profile, obj: reservation });
    default:
      return false;
  }
};

const getViewReservationPermissions = ({ reservation, profile, type }) => {
  switch (type) {
    case RESERVATION_TYPES.CONFERENCE_BOOKING:
      return bookingRoleAccess.view({ profile, obj: reservation });
    case RESERVATION_TYPES.SEAT_BOOKING:
      return seatBookingRoleAccess.view({
        profile,
        obj: { booking: reservation },
      });
    case RESERVATION_TYPES.DESK_RESERVATION:
      return reservationRoleAccess.view({ profile, obj: reservation });
    case RESERVATION_TYPES.OFFICE_RESERVATION:
      return officeReservationRoleAccess.view({ profile, obj: reservation });
    default:
      return false;
  }
};
const getReservationPermissions = ({ reservation, profile, type, action }) => {
  switch (action) {
    case RESERVATION_ACTIONS.update:
      return getUpdateReservationPermissions({
        reservation,
        profile,
        type,
      });
    case RESERVATION_ACTIONS.delete:
      return getDeleteReservationPermissions({
        reservation,
        profile,
        type,
      });
    case RESERVATION_ACTIONS.view:
      return getViewReservationPermissions({
        reservation,
        profile,
        type,
      });
    default:
      return false;
  }
};

export default {
  getReservationStartEpoch,
  getReservationTimezoneId,
  getReservationId,
  getReservationPermissions,
  getReservationLocation,
  getReservationOwner,
};
