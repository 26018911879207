/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable react/require-default-props */
import { useState, useEffect, useRef } from 'react';
import { Source, NavigationControl } from 'react-map-gl';
import { MapLayerMouseEvent } from 'mapbox-gl';
import {
  StyleRulesCallback,
  Theme,
  withStyles,
} from '@material-ui/core/styles';
import compact from 'lodash/compact';
import memoizeOne from 'memoize-one';
import { useTranslation } from 'react-i18next';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Geometry } from '@turf/turf';

// Constants
import Slide from '@material-ui/core/Slide';
import { DEFAULT_VIEWPORT, FEATURES_TYPE, ZOOM_LEVEL } from '../constants';

// Api & Core
import { fetchFeaturesForDatasetId } from '../../../Core/Api';
import { getStaticImageUrl } from '../../../Core/Utils';
import { S3IconStrings } from '../../../resources';
import { breakpoints, colors } from '../../../Core/Theme';

// Utils
import {
  calculateCentroid,
  generateNewViewport,
  generateFeatureOverlay,
  getMapData,
  isSameMap,
} from '../utils';

// Components
import * as Layers from '../Layers';
import {
  MapWrapper,
  FloatingActionDescription,
  MapLegend,
  Text,
} from '../../Common';
import { MapDataset, ViewPort, ZoomLevel, ZoomLevels } from '../types';

require('../style/map_gl_override.css');

const getMapStyleType = (refMap: any): keyof ZoomLevels => {
  const objStyleSheet = refMap?.style?.stylesheet?.sources;
  if (objStyleSheet == null) {
    return 'raster';
  }

  return objStyleSheet[Object.keys(objStyleSheet)[0]].type;
};

const getMapZoom =
  (refMap: any) => (params: { zoomKey: keyof ZoomLevel; config: any }) => {
    const { zoomKey, config } = params;

    const zoomConfigValue = config?.zoomKey;

    if (zoomConfigValue) {
      return zoomConfigValue;
    }

    const strMapStyle = getMapStyleType(refMap);

    return ZOOM_LEVEL[strMapStyle][zoomKey];
  };

const getMapConfig = (objFeatureData?: MapDataset) => {
  const features = objFeatureData?.features ?? [];
  const objFeatureConfig = features.find(feature => {
    return feature.properties?.type === FEATURES_TYPE.CONFIG;
  });

  return objFeatureConfig?.properties;
};

const getMapConfigMemoized = memoizeOne(getMapConfig, isSameMap);

type FloorPlanProps = {
  classes: any;
  offices: any[];
  datasetId: string;
  mapStyleId: string;
  isLoading: boolean;
  selectedFloorName: any;
  selectedOfficeMapFeatureId?: string;
  floatingDescription?: string[];
  legends?: any[];
  interactiveLayersIds?: string[];
  imagesToLoad?: any[];
  handleFeatureOnClick?: Function;
  getLayersToDisplay?: Function;
  hasAnyMappedFloors?: boolean;
};

const FloorPlan = (props: FloorPlanProps) => {
  const {
    offices,
    datasetId,
    mapStyleId,
    classes,
    selectedFloorName,
    selectedOfficeMapFeatureId,
    isLoading,
    hasAnyMappedFloors = false,
    floatingDescription = [],
    legends = [],
    interactiveLayersIds = [],
    imagesToLoad = [],
    handleFeatureOnClick = () => {},
    getLayersToDisplay = () => <></>,
  } = props;
  const [viewport, setViewport] = useState<ViewPort>(DEFAULT_VIEWPORT);
  const [isFetchingFeatures, setIsFetchingFeatures] = useState(!!datasetId);
  const [hoverIds, setHoverIds] = useState<string[]>([]);
  const [mapIsReady, setMapIsReady] = useState(false);
  const [hasOverlay, setHasOverlay] = useState(false);

  const [featuresData, setFeaturesData] = useState<MapDataset>();

  const [mapStyle, setMapStyle] = useState<string>();
  const { t } = useTranslation();
  const mapRef = useRef<typeof MapWrapper>();

  useEffect(() => {
    setViewport(DEFAULT_VIEWPORT);
    setMapIsReady(false);
    setMapStyle(undefined);
    setFeaturesData(undefined);
    if (datasetId) {
      setIsFetchingFeatures(true);
      fetchFeaturesForDatasetId(datasetId)
        .then(res => {
          setIsFetchingFeatures(false);
          setFeaturesData(res);
          return setMapStyle(
            `mapbox://styles/${process.env.REACT_APP_MAPBOX_USER}/${mapStyleId}`
          );
        })
        .catch(() => {
          return setIsFetchingFeatures(false);
        });
    }
  }, [datasetId]);

  useEffect(() => {
    if (selectedOfficeMapFeatureId == null && hasOverlay) {
      setHasOverlay(false);
    }

    if (selectedOfficeMapFeatureId && mapIsReady && featuresData != null) {
      flyToFeatureFocus(selectedOfficeMapFeatureId);
    }
  }, [selectedOfficeMapFeatureId, mapIsReady]);

  const data = getMapData(featuresData, offices);
  // @ts-ignore
  const map = mapRef?.current?.getMap();
  const zoom = viewport?.zoom ?? 0;
  const objMapConfig = getMapConfigMemoized(featuresData);
  const getZoom = getMapZoom(map);

  const getCursor = (params: { isHovering: boolean; isDragging: boolean }) => {
    const { isHovering, isDragging } = params;

    if (isDragging) {
      return 'grabbing';
    }

    return isHovering ? 'pointer' : 'grab';
  };

  const getOverlayFeature = () => {
    const boundingBox = map.getBounds();
    return generateFeatureOverlay(boundingBox);
  };

  const getDataWithOverlayBox = (): MapDataset => {
    const objOverlayFeature = getOverlayFeature();
    const actualFeatures = data?.dataset?.features ?? [];
    const featuresWithOverlaybox = [objOverlayFeature, ...actualFeatures];
    const geoJsonDataWithOverlayFeature = {
      ...(data?.dataset ?? {}),
      features: featuresWithOverlaybox,
    };

    // @ts-ignore
    return geoJsonDataWithOverlayFeature;
  };

  const getDataset = memoizeOne((overlay, mapReady) => {
    if (overlay && mapReady) {
      return getDataWithOverlayBox();
    }

    return data?.dataset;
  });

  const getIsLabelVisible = memoizeOne(intZoom => {
    if (
      intZoom >=
      getZoom({
        zoomKey: 'VISIBLE_ZOOM_LABELS',
        config: objMapConfig,
      })
    ) {
      return true;
    }
    return false;
  });

  // remove TODO
  const getMapStyleIsLoaded = (refMap: any) => {
    // eslint-disable-next-line no-underscore-dangle
    return refMap?._loaded === true;
  };

  const flyTo = (coordinates: [number, number]) => {
    const newViewport = generateNewViewport({
      coordinates,
      zoom: getZoom({
        zoomKey: 'DEFAULT_FLY_TO_ZOOM',
        config: objMapConfig,
      }),
    });
    return setViewport({ ...viewport, ...newViewport });
  };

  const flyToFeatureFocus = (featureId: string) => {
    const featureData = data.dataset.features.find(feature => {
      return feature.id === featureId;
    });

    if (featureData?.geometry == null) {
      return;
    }

    const coordinates = calculateCentroid({
      coordinates: (featureData.geometry as Geometry).coordinates,
      type: featureData.geometry.type,
    });
    if (coordinates == null) {
      return;
    }

    flyTo([coordinates[0], coordinates[1]]);
  };

  const handleOnViewportChange = (nextViewport: ViewPort) => {
    return setViewport(nextViewport);
  };

  const handleOnClick = (e: MapLayerMouseEvent) => {
    const feature = e?.features?.[0];

    if (feature?.properties == null) {
      return;
    }

    const featureData = data.officeByFeatureId[feature.properties.id];
    handleFeatureOnClick(featureData);
  };

  const handleOnHover = (e: MapLayerMouseEvent) => {
    const feature = e?.features?.[0];

    if (feature?.properties == null) {
      return null;
    }

    const { associatedFeature, id: featureId } = feature.properties;

    toggleHover([featureId, associatedFeature]);

    return setHoverIds([featureId, associatedFeature]);
  };

  const handleOnMouseLeave = () => {
    toggleHover(hoverIds);
  };

  const toggleHover = (listId: string[]) => {
    if (mapIsReady) {
      compact(hoverIds).forEach(strHoverId => {
        const isHover = listId.includes(strHoverId);
        map.setFeatureState(
          { source: 'features', id: strHoverId },
          { hover: isHover }
        );
      });
      setHoverIds([]);
    }
  };

  const loadMapImages = () => {
    if (imagesToLoad.length && map) {
      imagesToLoad.forEach(({ img, name }) => {
        map.loadImage(img, (err: any, bitMapImage: any) => {
          map.addImage(name, bitMapImage);
        });
      });
    }
  };

  const handleOnLoad = () => {
    if (map) {
      // eslint-disable-next-line no-underscore-dangle
      const isStyleFullLoaded = map._loaded;
      if (isStyleFullLoaded) {
        const centerCoordinates = map.style.stylesheet.center;
        const newViewport = generateNewViewport({
          coordinates: centerCoordinates,
          zoom: getZoom({
            zoomKey: 'INITIAL_ZOOM',
            config: objMapConfig,
          }),
          withTransition: false,
        });
        setViewport({ ...viewport, ...newViewport });
        loadMapImages();
        return setMapIsReady(true);
      }
    }

    return null;
  };

  const renderLoading = () => {
    return (
      <div className={classes.loadingContainer}>
        <CircularProgress
          size={50}
          thickness={8}
          className={classes.circularProgress}
        />
      </div>
    );
  };

  if (isLoading || isFetchingFeatures) {
    return renderLoading();
  }

  if ((!mapStyleId || !datasetId) && (!isLoading || !isFetchingFeatures)) {
    return (
      <div className={classes.emptyState}>
        <img
          src={getStaticImageUrl(S3IconStrings.deskWaiting1)}
          srcSet={`${getStaticImageUrl(S3IconStrings.deskWaiting2)},
   ${getStaticImageUrl(S3IconStrings.deskWaiting3)}`}
          alt={t('altTexts.desk_waiting')}
          className={classes.emptyImage}
        />
        <Text
          text={
            !hasAnyMappedFloors
              ? t('manageOffices.empty_title')
              : t('manageOffices.empty_floor_title', {
                  floorName: selectedFloorName,
                })
          }
          className={classes.mediumTitle}
        />
        <Text
          text={t('manageOffices.empty_description')}
          className={classes.emptyDescription}
        />
      </div>
    );
  }

  const isStyleLoaded = getMapStyleIsLoaded(map);

  return (
    <>
      {!isStyleLoaded && renderLoading()}
      <MapWrapper
        {...viewport}
        attributionControl={false}
        ref={mapRef}
        onLoad={handleOnLoad}
        maxZoom={getZoom({
          zoomKey: 'MAX_ZOOM',
          config: objMapConfig,
        })}
        minZoom={getZoom({
          zoomKey: 'MIN_ZOOM',
          config: objMapConfig,
        })}
        scrollZoom={!hasOverlay}
        dragPan={!hasOverlay}
        dragRotate={false}
        mapStyle={mapStyle}
        onViewportChange={handleOnViewportChange}
        // onViewStateChange={handleOnViewStateChange}
        interactiveLayerIds={interactiveLayersIds}
        onHover={handleOnHover}
        onMouseLeave={handleOnMouseLeave}
        onClick={handleOnClick}
        getCursor={getCursor}
        visible={isStyleLoaded}
        style={{ ...(!isStyleLoaded && { position: 'absolute' }) }}
      >
        {isStyleLoaded ? (
          <>
            {!hasOverlay && floatingDescription && (
              <>
                <div className={classes.navigationControlContainer}>
                  <NavigationControl showCompass={false} />
                </div>
                {
                  // @ts-ignore
                  <Slide direction="up" in mountOnEnter unmountOnExit>
                    <FloatingActionDescription
                      description={floatingDescription}
                    />
                  </Slide>
                }
              </>
            )}
            {legends.length && <MapLegend legends={legends} />}
            <Source
              id="features"
              type="geojson"
              // @ts-ignore
              data={getDataset(hasOverlay, mapIsReady)}
            >
              {getLayersToDisplay({
                labelVisible: getIsLabelVisible(zoom),
                mapData: data,
              })}
              <Layers.Overlay />
            </Source>
          </>
        ) : null}
      </MapWrapper>
    </>
  );
};

const styles: StyleRulesCallback<string> = (theme: Theme) => ({
  circularProgress: {
    color: colors.gray,
  },
  loadingContainer: {
    height: '70vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  navigationControlContainer: {
    position: 'absolute',
    left: 20,
    top: 10,
  },
  emptyState: {
    display: 'grid',
    textAlign: 'center',
    paddingTop: 55,
    paddingRight: 40,
    paddingLeft: 40,
    [theme.breakpoints.up(breakpoints.MOBILE)]: {
      paddingTop: 80,
    },
  },
  emptyImage: {
    margin: 'auto',
    marginBottom: 10,
    height: 100,
    paddingBottom: 10,
    [theme.breakpoints.up(breakpoints.MOBILE)]: {
      paddingBottom: 40,
    },
  },
  mediumTitle: {
    fontSize: 20,
    fontFamily: 'VerlagBold',
    marginBottom: 10,
    [theme.breakpoints.up(breakpoints.MOBILE)]: {
      fontSize: 24,
    },
  },
  emptyDescription: {
    fontSize: 20,
    fontFamily: 'VerlagLight',
    [theme.breakpoints.up(breakpoints.MOBILE)]: {
      fontSize: 18,
    },
  },
});

export default withStyles(styles)(FloorPlan);
