import typeToReducer from 'type-to-reducer';
import Lodash from 'lodash';

import * as types from './actionTypes';
import * as groupingTypes from '../../../Redux/Common/Groupings/actionTypes';

const initialState = {
  selectedLocation: {},
  isLoading: true,
  isError: false,
  gars: [],
  queriedLocations: [],
};

const setSelectedLocation = (state, action) => {
  const payload = Lodash.get(action, ['payload'], {});
  return {
    ...state,
    selectedLocation: payload,
  };
};

const onLoadingGars = state => {
  return {
    ...state,
    isLoading: true,
  };
};

export const onGarsFulfilled = (state, action) => {
  const payload = Lodash.get(action, ['payload'], []);
  const gars = [...state.gars, ...payload];
  return {
    ...state,
    gars,
    isLoading: false,
    isError: false,
  };
};

const onGarsRejected = state => {
  return {
    ...state,
    isLoading: false,
    isError: true,
  };
};

export const addQueriedLocation = (state, action) => {
  const { payload } = action;
  const queriedLocations = [...state.queriedLocations, payload];
  return {
    ...state,
    queriedLocations,
  };
};

const setIsLoading = (state, action) => {
  const { payload } = action;
  return {
    ...state,
    isLoading: payload,
  };
};
const addGar = (state, action) => {
  const { payload } = action;
  return {
    ...state,
    gars: [...state.gars, payload],
  };
};

export const deleteGar = (state, action) => {
  const { payload } = action;
  const { accountSfId, groupingId } = payload;
  const updatedGars = state.gars.filter(
    gar => !(gar.accountSfId === accountSfId && gar.groupingId === groupingId)
  );
  return {
    ...state,
    gars: updatedGars,
  };
};

export const deleteGrouping = (state, action) => {
  const { payload } = action;
  const { idGrouping } = payload;
  const updatedGars = state.gars.filter(
    gar => !(gar.groupingId === idGrouping)
  );

  return {
    ...state,
    gars: updatedGars,
  };
};

const reducer = typeToReducer(
  {
    [types.SET_SELECTED_LOCATION]: setSelectedLocation,
    [types.ADD_GARS]: {
      PENDING: onLoadingGars,
      FULFILLED: onGarsFulfilled,
      REJECTED: onGarsRejected,
    },
    [types.ADD_QUERIED_LOCATION]: addQueriedLocation,
    [types.SET_LOADING_GROUP_MANAGEMENT]: setIsLoading,
    [types.ADD_GAR]: addGar,
    [types.DELETE_GAR]: deleteGar,
    [groupingTypes.DELETE_GROUPING]: {
      FULFILLED: deleteGrouping,
    },
  },
  initialState
);

export default reducer;
