import { stateToMarkdown } from 'draft-js-export-markdown';

const ics = require('ics');

const appendUrlToDescription = (description, url, urlPassword) => {
  if (!url) {
    return description;
  }
  if (url && !urlPassword) {
    return `Event Link: ${url}\n\n${description}`;
  }
  return `Event Link: ${url}\nEvent Password: ${urlPassword}\n\n${description}`;
};

const createICS = async event => {
  const {
    startEpoch,
    endEpoch,
    title,
    description,
    space,
    conferenceLink,
    conferenceLinkPassword,
  } = event;

  const startTime = new Date(startEpoch * 1000);
  const endTime = new Date(endEpoch * 1000);
  const convDescription = stateToMarkdown(description.getCurrentContent());
  const descriptionWithUrl = appendUrlToDescription(
    convDescription,
    conferenceLink,
    conferenceLinkPassword
  );

  const calEvent = {
    start: [
      startTime.getUTCFullYear(),
      startTime.getUTCMonth() + 1,
      startTime.getUTCDate(),
      startTime.getUTCHours(),
      startTime.getUTCMinutes(),
    ],
    startInputType: 'utc',
    end: [
      endTime.getUTCFullYear(),
      endTime.getUTCMonth() + 1,
      endTime.getUTCDate(),
      endTime.getUTCHours(),
      endTime.getUTCMinutes(),
    ],
    endInputType: 'utc',
    title,
    description: descriptionWithUrl,
    status: 'CONFIRMED',
    ...(space && { location: space.name }),
    // organizer: { name, email },
    // geo
    // url
  };

  return new Promise((resolve, reject) => {
    ics.createEvent(calEvent, (err, file) => {
      err ? reject(err) : resolve(file);
    });
  });
};

export default createICS;
