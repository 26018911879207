/* eslint-disable jsx-a11y/interactive-supports-focus */
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

import { Text } from '../../../Common';
import { colors } from '../../../../Core/Theme';
import { Icons } from '../../../../resources';
import MapLink from '../../../Common/MapLink/Maplink';

const ScheduleRow = ({
  classes,
  onClick,
  title,
  time,
  renderCustomLocation,
  location,
  grouping,
}) => {
  return (
    <div
      className={classes.container}
      onClick={onClick}
      role="button"
      onKeyDown={onClick}
    >
      <div className={classes.content}>
        <div className={classes.divider} />
        <div className={classes.scheduleInfoContainer}>
          <Text text={time} className={classes.time} />
          <div className={classes.titleContainer}>
            <Text text={title} className={classes.title} />
          </div>

          {(location || grouping) && (
            <div className={classes.locationContainer}>
              {renderCustomLocation?.()}
              <MapLink
                location={location}
                grouping={grouping}
                className={classes.mapLink}
              />
            </div>
          )}
        </div>
      </div>
      <div className={classes.arrowContainer}>
        <Icons.ArrowRight />
      </div>
    </div>
  );
};

const styles = () => ({
  tagContainer: {
    backgroundColor: colors.palette.tertiary1.main,
    borderRadius: 2,
    marginRight: 15,
    width: ' max-content',
    paddingLeft: 4,
    paddingRight: 4,
    color: colors.white,
    textAlign: 'center',
  },
  tagText: {
    fontFamily: 'VerlagBold',
    fontSize: '10px',
    letterSpacing: 0.33,
  },
  locationPin: {
    height: '15px',
    width: '12px',
    marginTop: '-3px',
    marginRight: '4px',
  },
  locationContainer: {
    display: 'flex',
    fontSize: 12,
    lineHeight: '12px',
  },
  arrowContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  container: {
    display: 'flex',
    cursor: 'pointer',
    flex: 1,
  },
  content: {
    display: 'flex',
    flex: 1,
    paddingLeft: 1,
    paddingRight: 1,
  },
  scheduleInfoContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  titleContainer: {
    display: 'flex',
  },
  title: {
    fontFamily: 'VerlagBold',
    fontSize: '14px',
    lineHeight: '14px',
    paddingTop: '6px',
    marginBottom: '6px',
    overflow: 'hidden',
    display: '-webkit-box',
    '-webkit-line-clamp': 1,
    ' -webkit-box-orient': 'vertical',
  },
  subHeadingtitle: {
    fontFamily: 'VerlagBold',
    fontSize: '14px',
    lineHeight: '14px',
    paddingTop: '6px',
    marginBottom: '6px',
    overflow: 'hidden',
    display: '-webkit-box',
    '-webkit-line-clamp': 1,
    ' -webkit-box-orient': 'vertical',
    color: colors.dark,
    paddingLeft: '4px',
    paddingRight: '8px',
  },
  time: {
    fontFamily: 'VerlagBook',
    fontSize: '12px',
    lineHeight: '12px',
    marginTop: '3px',
  },
  divider: {
    width: '3px',
    height: '100%',
    backgroundColor: colors.palette.tertiary1.main,
    marginRight: '10px',
    borderRadius: '15px',
  },
  joinLink: {
    padding: 0,
    fontSize: 12,
    lineHeight: '12px',
  },
  address: {
    padding: 0,
    fontSize: 12,
    lineHeight: '12px',
  },
  virtualLinkContainer: {
    display: 'flex',
  },
  virtualCameraIcon: {
    height: '15px',
    width: '12px',
    marginRight: '4px',
    marginLeft: '1px',
  },
  mapLink: {
    fontSize: 12,
  },
});

ScheduleRow.defaultProps = {
  title: null,
  time: null,
  onClick: () => null,
  location: null,
  grouping: null,
  renderCustomLocation: null,
};

ScheduleRow.propTypes = {
  title: PropTypes.string,
  time: PropTypes.string,
  onClick: PropTypes.func,
  location: PropTypes.object,
  grouping: PropTypes.object,
  renderCustomLocation: PropTypes.func,
};

export default withStyles(styles)(ScheduleRow);
