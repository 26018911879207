// Expects a callback(object, objectKey) function
const iterateObjectProperties = (obj, callback) => {
  Object.keys(obj).forEach(key => {
    const newObj = obj[key];
    if (typeof newObj === 'object') {
      if (!newObj) return;
      iterateObjectProperties(newObj, callback);
    }
    callback(obj, key);
  });
};

export { iterateObjectProperties };
