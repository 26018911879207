/* eslint-disable react/no-unescaped-entities */
import PropTypes from 'prop-types';
import {
  PublicGoods,
  InstacartBusinessLogo,
  IndustriousSelectsLogo,
} from '../../../resources/logos';

export const PartnershipShape = {
  name: PropTypes.string.isRequired,
  logo: PropTypes.string.isRequired,
  logoSetPaths: PropTypes.string,
  body: PropTypes.string.isRequired,

  linkButtonText: PropTypes.string,
  linkUrl: PropTypes.string.isRequired,

  clickTrackingLabel: PropTypes.string.isRequired,
};

export const Partnerships = [
  {
    id: 2,
    clickTrackingLabel: 'Selects',
    value: 'perks.selects.value',
    body: 'perks.selects.body',
    logo: IndustriousSelectsLogo,
    linkUrl: 'https://bit.ly/indselects',
  },
  {
    id: 2,
    value: 'perks.public_goods.value',
    body: 'perks.public_goods.body',
    logo: PublicGoods,
    linkUrl:
      'https://www.publicgoods.com/account/register?view=industrious&utm_medium=partnerships&utm_source=partnershipsindustrious&utm_campaign=partnerships_industrious',
    clickTrackingLabel: 'PublicGoods',
  },
  {
    id: 3,
    clickTrackingLabel: 'Instacart Business',
    value: 'perks.instacart_business.value',
    body: 'perks.instacart_business.body',
    logo: InstacartBusinessLogo,
    linkUrl: 'http://instacart.com/business?bcode=industriousetybdj',
  },
];
