/* eslint-disable no-prototype-builtins */
/* eslint-disable react/no-did-update-set-state */
/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
/* eslint-disable jsx-a11y/tabindex-no-positive */

import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { useTranslation, withTranslation } from 'react-i18next';
import clsx from 'clsx';

// Common Components
import Drawer from '../../Components/Common/Drawer';
import MonthPicker from '../../Components/Common/DayPicker';
import { AccountAutocomplete, CloseButton } from '../../Components/Common';

// Components

// Constants
import { breakpoints } from '../../Core/Theme';

// Utils
import {
  getIsDecisionMaker,
  getIsManager,
} from '../../Core/Utils/userPermissions';
import { date as DateUtils } from '../../Core/Utils';

// Redux
import locationRedux from '../../Redux/Common/Locations';
import roomBookingRedux from '../RoomBooking/Redux';
import userManager from '../../Redux/Common/UserManager';
import MyReservationsTable from './Components/MyReservationsTable';
import { OWN_ACCOUNT_NAME } from './constants';
import bookingsRedux from '../../Redux/Common/Bookings';

const MyReservations = props => {
  const {
    isOpen,
    onClose,
    classes,
    profile,
    history,
    loadBookingRestriction,
    bookingRestrictions,
    locations,
    deleteReservationInCalendar,
    onViewReservation,
    onEditReservation,
    locationsLookup,
    onSuccess,
  } = props;
  const { t } = useTranslation();

  const initialMonth = DateUtils.getDateInMoment().startOf('month').unix();
  const [month, setMonth] = React.useState(initialMonth);
  const [accountSfId, setAccountSfId] = React.useState(null);
  const [accountTimezoneId, setAccountTimezoneId] = React.useState(null);

  React.useEffect(() => {
    setMonth(initialMonth);
    setAccountSfId(null);
    setAccountTimezoneId(null);
  }, [isOpen]);

  const isManager = getIsManager(profile);
  const isViewingCompany = getIsDecisionMaker(profile) && !isManager;

  const defaultAccountItem = {
    sfId: null,
    name: OWN_ACCOUNT_NAME,
    locationSfId: null,
  };

  const selectAccount = account => {
    setAccountSfId(account.sfId);
    setAccountTimezoneId(locationsLookup?.[account.locationSfId]?.timeZoneId);
  };

  return (
    <Drawer
      anchor="right"
      isOpenDrawer={isOpen}
      onCloseDrawer={onClose}
      scrollEnabled={false}
    >
      <div className={classes.container}>
        <CloseButton onClose={onClose} className={classes.closeButton} />
        <div className={classes.header}>
          {!isManager ? (
            <div className={classes.title}>
              {isViewingCompany
                ? t('myReservations.company_title')
                : t('myReservations.my_title')}
            </div>
          ) : null}
          {isManager ? (
            <div className={clsx(classes.accountViewing, classes.title)}>
              <span className={classes.viewing}>
                {t('myReservations.viewing')}
              </span>
              <AccountAutocomplete
                defaultValue={OWN_ACCOUNT_NAME}
                topMenuItems={[defaultAccountItem]}
                locationSfId={profile.primaryLocationSfId}
                label={t('myReservations.account')}
                isLabelHidden
                className={classes.accountPicker}
                onChange={selectAccount}
              />
            </div>
          ) : null}
          <div className={classes.monthPicker}>
            <MonthPicker
              type="month"
              onChange={setMonth}
              selectedMonth={month}
            />
          </div>
        </div>
        <MyReservationsTable
          month={month}
          profile={profile}
          accountSfId={accountSfId}
          className={classes.table}
          history={history}
          loadBookingRestriction={loadBookingRestriction}
          bookingRestrictions={bookingRestrictions}
          locations={locations}
          deleteReservationInCalendar={deleteReservationInCalendar}
          accountTimezoneId={accountTimezoneId}
          locationsById={locationsLookup}
          onViewReservation={onViewReservation}
          onEditReservation={onEditReservation}
          onSuccess={onSuccess}
        />
      </div>
    </Drawer>
  );
};

const styles = theme => ({
  container: {
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
  },
  header: {
    marginTop: 40,
    [theme.breakpoints.down(breakpoints.MOBILE)]: {
      paddingLeft: '20px',
      paddingRight: '20px',
    },
    paddingLeft: '40px',
    paddingRight: '40px',
  },
  table: {
    flex: 1,
    marginTop: 10,
    [theme.breakpoints.down(breakpoints.MOBILE)]: {
      paddingLeft: '20px',
      paddingRight: '20px',
    },
    paddingLeft: '40px',
    paddingRight: '40px',
  },
  monthPicker: {
    [theme.breakpoints.down(breakpoints.MOBILE)]: {
      width: '43.5%',
    },
    width: 150,
  },
  title: {
    [theme.breakpoints.down(breakpoints.MOBILE)]: {
      textAlign: 'center',
      fontSize: 20,
      paddingBottom: '32px',
    },
    fontSize: 24,
    fontFamily: "'VerlagBold'",
    paddingBottom: '20px',
  },

  closeButton: {
    [theme.breakpoints.down(breakpoints.MOBILE)]: {
      right: '5px',
      top: '5px',
    },
    position: 'absolute',
    right: '15px',
    top: '15px',
    boxShadow: 'none !important',
  },
  viewing: {
    paddingRight: 10,
  },
  accountViewing: {
    textAlign: 'left',
    [theme.breakpoints.up(breakpoints.MOBILE)]: {
      display: 'flex',
    },
  },
  accountPicker: {
    [theme.breakpoints.up(breakpoints.MOBILE)]: {
      marginTop: -3,
      width: 250,
    },
  },
});

MyReservations.defaultProps = {
  onEditReservation: () => null,
  onViewReservation: () => null,
  onSuccess: () => null,
};

MyReservations.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  profile: PropTypes.object.isRequired,
  locations: PropTypes.arrayOf(PropTypes.object).isRequired,
  deleteReservationInCalendar: PropTypes.func.isRequired,
  loadBookingRestriction: PropTypes.func.isRequired,
  bookingRestrictions: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  locationsLookup: PropTypes.object.isRequired,
  onViewReservation: PropTypes.func,
  onEditReservation: PropTypes.func,
  onSuccess: PropTypes.func,
};

const mapStateToProps = state => {
  const { selectors: userManagerSelectors } = userManager;
  const { selectors: roomBookingSelectors } = roomBookingRedux;
  const { selectors: locationSelectors } = locationRedux;

  return {
    profile: userManagerSelectors.getUserProfile(state),
    bookingRestrictions: roomBookingSelectors.getBookingRestrictions(state),
    locations: locationSelectors.getLocations(state),
    locationsLookup: locationSelectors.getLocationsById(state),
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      loadBookingRestriction: roomBookingRedux.actions.addBookingRestriction,
      deleteReservationInCalendar: bookingsRedux.actions.deleteBooking,
    },
    dispatch
  );
};

export default withTranslation()(
  withStyles(styles)(
    connect(mapStateToProps, mapDispatchToProps)(MyReservations)
  )
);
