import { createAction } from 'redux-actions';
import * as types from './actionTypes';
import { fetchParcels } from '../../../Core/Api';
import { fetchHandlerActions } from '../../../Core/Utils/fetch';

export const addParcels = createAction(
  types.LOAD_PARCELS,
  fetchHandlerActions(fetchParcels)
);

export const resetToInitialState = createAction(types.RESET_TO_INITIAL_STATE);

export const setSelectedDate = createAction(types.SET_SELECTED_DATE);
