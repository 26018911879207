import Phone from './Phone.svg';
import TV from './TV.svg';
import Monitor from './Monitor.svg';
import Whiteboard from './Whiteboard.svg';
import Camera from './Camera.svg';
import LeftArrow from './LeftArrow.png';
import RightArrow from './RightArrow.png';
import Visible from './Visible.png';
import NotVisible from './NotVisible.png';
import Bold from './Bold.png';
import Hyperlink from './Hyperlink.png';
import Italic from './Italic.png';
import Location from './Location.png';
import Underline from './Underline.png';
import Filter from './Filter.png';
import HomeSeatNav from './HomeSeatNav.png';
import HomeDeskNav from './HomeDeskNav.png';
import HomeConferenceNav from './HomeConferenceNav.png';
import HomeOfficeNav from './HomeOfficeNav.png';

import { ReactComponent as Calendar } from './Calendar.svg';
import { ReactComponent as CalendarIcon } from './Calendar2.svg';
import { ReactComponent as HelpOutline } from './help_outline.svg';
import { ReactComponent as WifiOnGrey } from './WifiOnGrey.svg';
import { ReactComponent as WifiOnWhite } from './WifiOnWhite.svg';
import { ReactComponent as KebabIcon } from './Kebab.svg';
import { ReactComponent as CloseIconGray } from './Close_Grey.svg';

import { ReactComponent as FoodIcon } from './Food.svg';
import { ReactComponent as PackageIcon } from './Package.svg';
import { ReactComponent as TourIcon } from './Tour.svg';
import { ReactComponent as VisitorIcon } from './Visitor.svg';
import { ReactComponent as AssistanceIcon } from './Assistance.svg';
import { ReactComponent as Carat } from './Carat.svg';
import { ReactComponent as RightArrowGrayIcon } from './GrayCarat.svg';
import { ReactComponent as RightArrowGreenIcon } from './GreenCarat.svg';
import { ReactComponent as RightTurningArrow } from './RightTurningArrow.svg';
import { ReactComponent as Export } from './Export.svg';
import { ReactComponent as ExportDisabled } from './ExportDisabled.svg';
import { ReactComponent as LocationSettings } from './Location_Settings.svg';
import { ReactComponent as LocationPin } from './LocationPin.svg';
import { ReactComponent as CloseIcon } from './Close.svg';
import { ReactComponent as CheckMarkIcon } from './Checkmark.svg';
import { ReactComponent as ComputerSearch } from './ComputerSearch.svg';
import { ReactComponent as ClosedLock } from './ClosedLock.svg';

import { ReactComponent as NoNotificationsImage } from './NoNotifications.svg';
import { ReactComponent as NoSchedule } from './NoSchedule.svg';
import { ReactComponent as EmptyCalendar } from './EmptyCalendar.svg';
import { ReactComponent as ArrowRight } from './ArrowRight.svg';
import { ReactComponent as ThreePeople } from './ThreePeople.svg';
import { ReactComponent as ExternalLink } from './ExternalLink.svg';
import { ReactComponent as Search } from './Search.svg';

import { ReactComponent as NoMessageHistory } from './NoMessageHistory.svg';
import { ReactComponent as Cancel } from './Cancel.svg';
import { ReactComponent as Edit } from './Edit.svg';
import { ReactComponent as BellNotificationSelected } from './BellNotificationSelected.svg';
import { ReactComponent as BellNotificationUnSelected } from './BellNotificationUnSelected.svg';
import { ReactComponent as ScheduleNotificationSelected } from './ScheduledNotificationSelected.svg';
import { ReactComponent as ScheduledNotificationUnSelected } from './ScheduledNotificationUnSelected.svg';
import { ReactComponent as VirtualCamera } from './VirtualCamera.svg';
import { ReactComponent as ConfirmCheck } from './ConfirmCheck.svg';

import { ReactComponent as ExpandedIcon } from './ExpandedIcon.svg';
import { ReactComponent as ExpandIcon } from './ExpandIcon.svg';

import QuestionToolTip from './ToolTip.png';
import {
  CompanyProfile,
  Demographics,
  leftD,
  leftM,
  MemberProfile,
  middleD,
  middleDEmpty,
  middleM,
  middleMEmpty,
  rightD,
  rightDEmpty,
  rightM,
  rightMEmpty,
  WelcomeScreen,
} from './OnboardingIcons';
import Upload from './Upload.png';
import Border from './Border.png';

import { ReactComponent as LeftArrowInLink } from './LeftArrowInLink.svg';
import { ReactComponent as RightArrowInLink } from './RightArrowInLink.svg';
import { ReactComponent as Clock } from './Clock.svg';
import { ReactComponent as ConferenceRoom } from './ConferenceRoom.svg';
import { ReactComponent as Seats } from './Seats.svg';
import { ReactComponent as Exclamation } from './Exclamation.svg';
import { ReactComponent as DropzoneUpload } from './DropzoneUpload.svg';

import { ReactComponent as Increment } from './Increment.svg';
import { ReactComponent as Decrement } from './Decrement.svg';

import { ReactComponent as NoDesk } from './NoDesk.svg';
import { ReactComponent as ProfessionalMailingAdr } from './ProfessionalMailingAdr.svg';

export default {
  AssistanceIcon,
  Bold,
  Border,
  Camera,
  Carat,
  CloseIcon,
  CloseIconGray,
  CompanyProfile,
  Demographics,
  Export,
  ExportDisabled,
  FoodIcon,
  HelpOutline,
  Hyperlink,
  Italic,
  LeftArrow,
  leftD,
  leftM,
  Location,
  LocationSettings,
  MemberProfile,
  middleD,
  middleDEmpty,
  middleM,
  middleMEmpty,
  NotVisible,
  NoNotificationsImage,
  PackageIcon,
  Phone,
  ProfessionalMailingAdr,
  QuestionToolTip,
  RightArrow,
  RightArrowGrayIcon,
  RightArrowGreenIcon,
  RightTurningArrow,
  rightD,
  rightDEmpty,
  rightM,
  rightMEmpty,
  TourIcon,
  TV,
  Monitor,
  Underline,
  Upload,
  Visible,
  VisitorIcon,
  WelcomeScreen,
  Whiteboard,
  WifiOnGrey,
  WifiOnWhite,
  Calendar,
  CalendarIcon,
  KebabIcon,
  NoMessageHistory,
  Cancel,
  Edit,
  BellNotificationSelected,
  BellNotificationUnSelected,
  ScheduleNotificationSelected,
  ScheduledNotificationUnSelected,
  LeftArrowInLink,
  RightArrowInLink,
  Clock,
  ConferenceRoom,
  Seats,
  CheckMarkIcon,
  ComputerSearch,
  Exclamation,
  LocationPin,
  NoSchedule,
  EmptyCalendar,
  ArrowRight,
  Filter,
  VirtualCamera,
  DropzoneUpload,
  ConfirmCheck,
  ThreePeople,
  ExternalLink,
  Search,
  Increment,
  Decrement,
  ExpandedIcon,
  ExpandIcon,
  NoDesk,
  HomeSeatNav,
  HomeDeskNav,
  HomeConferenceNav,
  HomeOfficeNav,
  ClosedLock,
};
