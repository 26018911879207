import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import XHR from 'i18next-xhr-backend';

import { enTranslations, frTranslations, nlTranslations } from './translations';
import { EN_US } from '../constants';

const shouldEnableDebug = environment => {
  if (environment === 'staging' || environment === 'prod') {
    return false;
  }
  return true;
};

i18n
  .use(XHR)
  .use(LanguageDetector)
  .init({
    debug: shouldEnableDebug(process.env.REACT_APP_ENV),
    lng: localStorage.getItem('currentLocale') || EN_US,
    fallbackLng: EN_US, // use en if detected lng is not available
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
    resources: {
      en: {
        translations: enTranslations,
      },
      fr: {
        translations: frTranslations,
      },
      nl: {
        translations: nlTranslations,
      },
    },
    // have a common namespace used around the full app
    ns: ['translations'],
    defaultNS: 'translations',
  });

export default i18n;
