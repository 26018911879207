import { useTranslation } from 'react-i18next';
import { StyleRulesCallback, withStyles } from '@material-ui/core';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import { History } from 'history';

import { ROOM_BOOKING_PATH } from '../../RoomBooking/constants';
import { RoomBookingDate } from '../../RoomBooking/Models';

import { strings as StringsUtils } from '../../../Core/Utils';
import { RoundedButton, RoundedButtonTypes } from '../../../Components/Common';
import { ReservationEmailSubjectDate } from '../Models';

type Props = {
  classes: ClassNameMap;
  officeName: string;
  locationName: string;
  locationEmail: string;
  epoch: number;
  groupingId: string;
  timezoneId: string;
  history: History;
};

const OfficeReservationConfirmation = (props: Props) => {
  const {
    classes,
    officeName,
    locationName,
    locationEmail,
    epoch,
    groupingId,
    timezoneId,
    history,
  } = props;
  const { t } = useTranslation();

  const handleRedirectToMeetingRoom = () => {
    const search = StringsUtils.stringifyParams({
      queryParams: {
        selectedDate: RoomBookingDate.FromEpochAndTimezoneId(epoch, timezoneId),
        groupingId,
      },
    });
    return history.push({
      pathname: ROOM_BOOKING_PATH,
      search,
    });
  };

  const handleRegisterAttendees = () => {
    const subject = t('reserveOffice.confirmation.attendees_email_subject', {
      office: officeName,
      date: ReservationEmailSubjectDate.FromEpoch(epoch),
      location: locationName,
    });
    const body = t('reserveOffice.confirmation.attendees_email_body');

    window.location.href = `mailto:${locationEmail}?subject=${subject}&body=${body}`;
  };

  return (
    <div className={classes.container}>
      <div className={classes.subContainer}>
        <div className={classes.title}>
          {t<string>('reserveOffice.confirmation.title')}
        </div>
        <div>{t<string>('reserveOffice.confirmation.subtitle')}</div>
      </div>

      <div className={classes.subContainer}>
        <div className={classes.title2}>
          {t<string>('reserveOffice.confirmation.attendees_title')}
        </div>
        <div>{t<string>('reserveOffice.confirmation.attendees_desc')}</div>
        <RoundedButton isSmall onClick={handleRegisterAttendees}>
          {t<string>('reserveOffice.confirmation.attendees_btn')}
        </RoundedButton>
      </div>

      <div className={classes.subContainer}>
        <div>
          {t<string>('reserveOffice.confirmation.meeting_room_desc', {
            locationName,
          })}
        </div>
        <RoundedButton
          isSmall
          type={RoundedButtonTypes.Secondary}
          onClick={handleRedirectToMeetingRoom}
        >
          {t<string>('reserveOffice.confirmation.meeting_room_btn')}
        </RoundedButton>
      </div>
    </div>
  );
};

const style: StyleRulesCallback<string> = () => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '2rem',
    fontSize: 16,
    fontFamily: 'VerlagLight',
    paddingLeft: 15,
    paddingRight: 15,
  },
  subContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '12px',
  },
  title: {
    fontFamily: 'VerlagBold',
    fontSize: 20,
  },
  title2: {
    fontFamily: 'VerlagBold',
  },
});

export default withStyles(style)(OfficeReservationConfirmation);
