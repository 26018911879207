import { createSelector } from 'reselect';
import { get } from 'lodash';

export const getCommunityMessagesState = state =>
  get(state, ['communityMessages'], {});

export const getSelectedLocation = createSelector(
  getCommunityMessagesState,
  communityMessages => get(communityMessages, ['selectedLocation'], {})
);

export const getCommunityMessagesAllIds = createSelector(
  getCommunityMessagesState,
  communityMessages => get(communityMessages, ['messages', 'allIds'], [])
);

export const getCommunityMessagesById = createSelector(
  getCommunityMessagesState,
  communityMessages => get(communityMessages, ['messages', 'byId'], {})
);

export const getCommunityMessagesError = createSelector(
  getCommunityMessagesState,
  communityMessages => get(communityMessages, ['hasError'], false)
);

export const getCommunityMessagesLoading = createSelector(
  getCommunityMessagesState,
  communityMessages => get(communityMessages, ['isLoading'], false)
);

export const getCommunityMessagesHasMore = createSelector(
  getCommunityMessagesState,
  communityMessages => get(communityMessages, ['hasMore'], false)
);

export const getCommunityMessagesOffset = createSelector(
  getCommunityMessagesState,
  communityMessages => get(communityMessages, ['offset'], 0)
);

export const getMessagesForLocation = createSelector(
  [getSelectedLocation, getCommunityMessagesAllIds, getCommunityMessagesById],
  (selectedLocation, messageAllIds, messageById) => {
    const { sfId } = selectedLocation;

    const messagesForLocation = messageAllIds.filter(id => {
      const message = messageById[id];
      const { criteria } = message;
      return criteria.some(criteriaObj => {
        return criteriaObj.referenceId === sfId;
      });
    });

    return messagesForLocation;
  }
);

export const getCreatedBy = createSelector(
  getCommunityMessagesState,
  communityMessages => get(communityMessages, ['createdBy'], {})
);

export const getQueriedLoations = createSelector(
  getCommunityMessagesState,
  communityMessages => get(communityMessages, ['queriedLocations'], {})
);
