import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import colors from '../../../Core/Theme/colors';
import { getStaticImageUrl } from '../../../Core/Utils';
import { S3IconStrings } from '../../../resources';
import { CloseButton } from '..';
import { breakpoints } from '../../../Core/Theme';
import UserManagerReducer from '../../../Redux/Common/UserManager';

const ErrorMessage = props => {
  const {
    classes,
    addressApiErrors,
    apiErrors,
    unaddressedApiErrors,
    customErrorDescription,
  } = props;
  const { t } = useTranslation();
  const onClose = addressApiErrors;

  const hasErrorPersisted = apiErrors.length !== unaddressedApiErrors.length;

  return (
    <>
      <div className={classes.overlay} />
      <div className={classes.messageWrapper}>
        <CloseButton
          onClose={onClose}
          className={classes.close}
          iconClassName={classes.closeIcon}
        />
        <img
          className={classes.image}
          src={getStaticImageUrl(S3IconStrings.exclaimAlert1)}
          srcSet={`${getStaticImageUrl(S3IconStrings.exclaimAlert2)},
          ${getStaticImageUrl(S3IconStrings.exclaimAlert3)}`}
          alt={t('altTexts.exclamation_point')}
        />
        {hasErrorPersisted ? (
          <>
            <div className={classes.title}>{t('error.persist_title')}</div>
            <div className={classes.description}>
              {customErrorDescription || t('error.persist_description')}
            </div>
          </>
        ) : (
          <>
            <div className={classes.title}>{t('error.title')}</div>
            <div className={classes.description}>
              {customErrorDescription || t('error.description')}
            </div>
          </>
        )}
      </div>
    </>
  );
};

const styles = theme => ({
  overlay: {
    position: 'fixed',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    background: colors.black,
    opacity: 0.5,
    zIndex: 1000,
  },
  messageWrapper: {
    position: 'absolute',
    background: colors.palette.primary.main,
    color: colors.white,
    zIndex: 1001,
    padding: 20,
    top: '40vh',
    width: 'calc(100vw - 80px)',
    marginLeft: 20,
    marginRight: 20,
    [theme.breakpoints.up(breakpoints.MOBILE)]: {
      top: 'calc(50% - 70px)',
      left: 'calc(50% - 165px)',
      minHeight: 145,
      width: 330,
      marginLeft: 0,
      marginRight: 0,
    },
  },
  image: {
    height: 50,
    width: 50,
    paddingTop: 20,
    [theme.breakpoints.up(breakpoints.MOBILE)]: {
      paddingTop: 0,
      height: 70,
      width: 70,
    },
  },
  title: {
    fontFamily: 'VerlagBold',
    fontSize: 20,
    paddingTop: 15,
    [theme.breakpoints.up(breakpoints.MOBILE)]: {
      paddingTop: 20,
    },
  },
  description: {
    fontFamily: 'VerlagBook',
    fontSize: 16,
    paddingTop: 2,
    paddingBottom: 5,
    [theme.breakpoints.up(breakpoints.MOBILE)]: {
      paddingBottom: 0,
    },
  },
  close: {
    position: 'absolute',
    top: 20,
    right: 20,
    boxShadow: 'none !important',
  },
  closeIcon: {
    filter: 'invert(1)',
  },
});

const mapStateToProps = state => {
  return {
    customErrorDescription:
      UserManagerReducer.selectors.getCustomErrorDescription(state),
  };
};

ErrorMessage.defaultProps = {};

ErrorMessage.propTypes = {
  addressApiErrors: PropTypes.func.isRequired,
  apiErrors: PropTypes.arrayOf(PropTypes.object).isRequired,
  unaddressedApiErrors: PropTypes.arrayOf(PropTypes.object).isRequired,
  customErrorDescription: PropTypes.string.isRequired,
};

export default connect(mapStateToProps, null)(withStyles(styles)(ErrorMessage));
