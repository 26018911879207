import PropTypes from 'prop-types';
import Drawer from '@material-ui/core/Drawer';
import { withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Fab from '@material-ui/core/Fab';
import { duration } from '@material-ui/core/styles/transitions';
import { useTranslation } from 'react-i18next';

// Components
import DrawerImageHeader from './components/DrawerImageHeader';

// Theme & Core
import { breakpoints, colors } from '../../../Core/Theme';
import { getIsMobile } from '../../../Core/Utils/windowDimensions';

import { Icons } from '../../../resources';

const { CloseIcon } = Icons;

const DrawerUI = props => {
  const {
    isOpenDrawer,
    onCloseDrawer,
    anchor,
    children,
    scrollEnabled,
    classes,
    imageSource,
    className,
    classNameRoot,
    variant,
    style,
    withWidthAnimation,
    customClass,
    noAnimation,
    withCloseButton,
    onCloseButton,
    closeButtonClassName,
    customDuration,
    customClasses,
    datatestid,
  } = props;
  const { t } = useTranslation();
  let rootClass = classes[className];

  if (!scrollEnabled) {
    rootClass = `${rootClass} ${classes.scrollDisabled}`;
  }
  const isMobile = getIsMobile();

  let transitionDuration =
    className === 'drawerPaperLarge' && !isMobile
      ? {
          enter: 300,
          exit: 550,
        }
      : {
          enter: duration.enteringScreen,
          exit: duration.leavingScreen,
        };
  if (customDuration) {
    transitionDuration = customDuration;
  }

  return (
    <Drawer
      anchor={anchor}
      open={isOpenDrawer}
      onClose={onCloseDrawer}
      PaperProps={{
        classes: {
          root: clsx(
            customClass,
            classNameRoot || rootClass,
            withWidthAnimation && classes.widthTransform,
            noAnimation && classes.noAnimation
          ),
        },
      }}
      classes={{ root: classes.root, ...customClasses }}
      variant={variant}
      style={style}
      transitionDuration={transitionDuration}
    >
      {imageSource ? (
        <DrawerImageHeader onCloseButton={onCloseDrawer} src={imageSource} />
      ) : null}
      {withCloseButton ? (
        <div className={classes.closingIconContainer}>
          <Fab
            size="small"
            onClick={onCloseButton}
            aria-label={t('general.close')}
            className={clsx(classes.closeButton, closeButtonClassName)}
            datatestid={`${datatestid}_close_button`}
          >
            <CloseIcon />
          </Fab>
        </div>
      ) : null}
      {children}
    </Drawer>
  );
};

const styles = theme => ({
  closeButton: {
    color: colors.black,
    backgroundColor: colors.white,
    position: 'absolute',
    right: '15px',
    top: '15px',
    boxShadow: '0 0 4px 0 rgba(0, 0, 0, 0.25)',
    '&:hover': {
      backgroundColor: colors.white,
    },
    '&:focus': {
      boxShadow: '0 0 5px 0 rgba(0, 0, 0, `)',
    },
    zIndex: 1000,
  },
  drawerPaper: {
    [theme.breakpoints.up(breakpoints.MD)]: {
      width: '58%',
      minWidth: '650px',
    },
    width: '100%',
    borderLeft: 'none',
    borderRight: 'none',
  },
  drawerPaperSmall: {
    [theme.breakpoints.up(breakpoints.MOBILE)]: {
      width: 375,
      overflowY: 'hidden',
    },
    width: '100%',
    borderLeft: 'none',
    borderRight: 'none',
  },
  drawerPaperMediumSmall: {
    [theme.breakpoints.up(breakpoints.MOBILE)]: {
      width: 400,
    },
    overflowY: 'hidden',
    overflowX: 'hidden',
    width: '100%',
    borderLeft: 'none',
    borderRight: 'none',
  },
  drawerPaperLarge: {
    [theme.breakpoints.up(breakpoints.MD)]: {
      width: '95%',
      overflowY: 'hidden',
    },
    width: '100%',
    borderLeft: 'none',
    borderRight: 'none',
  },
  scrollDisabled: {
    overflow: 'hidden',
  },
  root: {
    '-webkit-tap-highlight-color': 'transparent',
  },
  widthTransform: {
    transition:
      'transform 300ms cubic-bezier(0, 0, 0.2, 1) 0ms, width 1s !important',
  },
  noAnimation: {
    transition: 'none ! important',
  },
});

DrawerUI.defaultProps = {
  scrollEnabled: true,
  className: 'drawerPaper',
  imageSource: null,
  classNameRoot: null,
  variant: null,
  style: null,
  customClass: '',
  withWidthAnimation: false,
  noAnimation: false,
  withCloseButton: false,
  closeButtonClassName: '',
  customDuration: null,
  customClasses: {},
  datatestid: 'drawer',
  onCloseButton: () => null,
  onCloseDrawer: () => {},
};

DrawerUI.propTypes = {
  scrollEnabled: PropTypes.bool,
  isOpenDrawer: PropTypes.bool.isRequired,
  onCloseDrawer: PropTypes.func,
  onCloseButton: PropTypes.func,
  anchor: PropTypes.string.isRequired,
  classes: PropTypes.shape({}).isRequired,
  imageSource: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.any]).isRequired,
  className: PropTypes.string,
  classNameRoot: PropTypes.string,
  variant: PropTypes.string,
  style: PropTypes.shape({}),
  customClasses: PropTypes.shape({}),
  customClass: PropTypes.string,
  closeButtonClassName: PropTypes.string,
  withWidthAnimation: PropTypes.bool,
  noAnimation: PropTypes.bool,
  withCloseButton: PropTypes.bool,
  customDuration: PropTypes.shape({
    enter: PropTypes.number,
    exit: PropTypes.number,
  }),
  datatestid: PropTypes.string,
};

export default withStyles(styles)(DrawerUI);
