import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

import { colors } from '../../../Core/Theme';
import SkeletonLoader from '../SkeletonLoader';

const handleKeyDown = onClick => keyEvent => {
  const { key } = keyEvent;
  if (key === ' ') {
    onClick();
  }
};
//  text is the string that will be displayed,
//  state relates to the color of the text and can be default, accent or black
const TextButton = props => {
  const {
    text,
    state,
    onClick,
    classes,
    size,
    className,
    loadingClassName,
    renderIconLeft,
    isDisabled,
    isLoading,
    ...rest
  } = props;
  const containerWithIconClass = renderIconLeft
    ? classes.containerWithIcon
    : null;

  return isLoading ? (
    <SkeletonLoader className={clsx(classes.loader, loadingClassName)} />
  ) : (
    <button
      className={clsx(
        classes[state],
        classes[size],
        classes.simple,
        containerWithIconClass,
        className,
        isDisabled && classes.disabled
      )}
      onClick={isDisabled ? () => null : onClick}
      onKeyDown={handleKeyDown(onClick)}
      {...rest}
      type="button"
    >
      {renderIconLeft ? (
        <div className={classes.iconLeftContainer}>{renderIconLeft()}</div>
      ) : null}
      {text}
    </button>
  );
};

const styles = () => ({
  containerWithIcon: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  default: {
    fontFamily: 'VerlagBook',
    color: colors.textPalette.link.main,
    marginTop: 0,
    marginBottom: 0,
    cursor: 'pointer',
  },
  accent: {
    fontFamily: 'VerlagBook',
    color: colors.alertMessage,
    marginTop: 0,
    marginBottom: 0,
    cursor: 'pointer',
  },
  black: {
    fontFamily: 'VerlagBook',
    color: colors.black,
    marginTop: 0,
    marginBottom: 0,
    cursor: 'pointer',
  },
  small: {
    fontSize: '15px',
  },
  medium: {
    fontSize: '18px',
    lineHeight: '18px',
  },
  tiny: {
    fontSize: 12,
  },
  simple: {
    background: 'transparent',
    border: 'none',
    '&:focus:not(.focus-visible)': {
      outline: 'none',
    },
  },
  iconLeftContainer: {
    paddingRight: '7px',
  },
  disabled: {
    color: colors.darkGray,
    cursor: 'default !important',
  },
});

TextButton.defaultProps = {
  state: 'default',
  size: 'small',
  className: '',
  renderIconLeft: null,
  isDisabled: false,
  isLoading: false,
  loadingClassName: '',
  style: {},
  dataTestId: '',
};

TextButton.propTypes = {
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  state: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  size: PropTypes.string,
  className: PropTypes.string,
  renderIconLeft: PropTypes.func,
  isDisabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  loadingClassName: PropTypes.string,
  style: PropTypes.object,
  dataTestId: PropTypes.string,
};
export default withStyles(styles)(TextButton);
