import { useTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import { StyleRulesCallback } from '@material-ui/core/styles/withStyles';

import { TextButton } from '../../../Components/Common';
import { trackManageWorkspacesClick } from '../../../Core/Tracking';
import { Styled } from '../../../Core/Types';

export type WarningProps = {
  officeName: string;
};

const OfficeNotReservableWarning = (props: WarningProps & Styled) => {
  const { classes, officeName } = props;
  const { t } = useTranslation();
  const history = useHistory();

  const handleViewAllReservations = () => {
    trackManageWorkspacesClick('View all reservations');
    return history.push({
      pathname: '/home/dailyreservations',
    });
  };

  return (
    <div className={classes.container}>
      <div className={classes.title}>
        {t('manageOffices.warning_office_not_reservable.title')}
      </div>
      <div className={classes.subtitle}>
        {t('manageOffices.warning_office_not_reservable.subtitle', {
          officeName,
        })}
      </div>
      <div>
        {t('manageOffices.warning_office_not_reservable.review_part1')}
        <TextButton
          text={t('manageOffices.warning_office_not_reservable.review_part2')}
          size="medium"
          className={classes.link}
          onClick={handleViewAllReservations}
        />
        {t('manageOffices.warning_office_not_reservable.review_part3')}
      </div>
      <div className={classes.question}>
        {t('manageOffices.warning_office_not_reservable.question')}
      </div>
    </div>
  );
};

const styles: StyleRulesCallback<string> = () => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '16px 30px',
    fontSize: 18,
    textAlign: 'center',
  },
  title: {
    fontFamily: 'VerlagBold',
    marginBottom: 20,
  },
  subtitle: {
    color: 'red',
    fontFamily: 'VerlagBold',
    marginBottom: 8,
  },
  question: {
    fontFamily: 'VerlagBold',
    margin: '20px 0',
  },
  link: {
    padding: 0,
  },
});

export default withStyles(styles)(OfficeNotReservableWarning);
