// Styles
import { colors, breakpoints } from '../../../Core/Theme';

const styles = theme => ({
  buttonStyleDefault: {
    height: 50,
    fontFamily: 'VerlagBold',
    fontSize: '15px',
    textTransform: 'none',
    letterSpacing: '0.5px',
    marginTop: 8,
    padding: 0,
    borderRadius: 25,
    backgroundColor: colors.palette.primary.main,
    '&:hover': {
      [theme.breakpoints.up(breakpoints.MOBILE)]: {
        backgroundColor: colors.palette.primary.light,
      },
      backgroundColor: colors.palette.primary.light,
    },
    color: colors.white,
    [theme.breakpoints.up(breakpoints.MOBILE)]: {
      height: 40,
      borderRadius: 20,
    },
  },
  buttonStyleSmall: {
    [theme.breakpoints.up(breakpoints.MOBILE)]: {
      height: '40px',
      paddingLeft: 5,
      paddingRight: 5,
      borderRadius: 20,
    },
    borderRadius: 25,
    fontFamily: 'VerlagBold',
    height: '50px',
    textTransform: 'none',
    letterSpacing: '0.5px',
    marginBottom: 8,
    marginTop: 8,
    backgroundColor: colors.palette.primary.main,
    '&:hover': {
      [theme.breakpoints.up(breakpoints.MOBILE)]: {
        backgroundColor: colors.palette.primary.light,
      },
      backgroundColor: colors.palette.primary.main,
    },
    color: colors.white,
  },
  tiny: {
    height: 22,
    fontFamily: 'VerlagBold',
    textTransform: 'none',
    letterSpacing: '0.5px',
    backgroundColor: colors.palette.primary.main,
    color: colors.white,
    fontSize: 12,
    padding: 0,
    minWidth: 47,
    width: 47,
    borderRadius: 11,
    boxShadow: '0 0 4px 1px rgba(0, 0, 0, 0.1)',
    '&:hover': {
      [theme.breakpoints.up(breakpoints.MOBILE)]: {
        backgroundColor: colors.palette.primary.light,
      },
      backgroundColor: colors.palette.primary.main,
    },
  },
  progress: {
    color: colors.white,
  },
  checkMark: {
    color: colors.white,
    backgroundColor: 'transparent',
  },
  buttonStyleDisabled: {
    height: '50px',
    alignItems: 'center',
    fontFamily: 'VerlagBold',
    textTransform: 'none',
    color: `${colors.darkest} !important`,
    backgroundColor: `${colors.light} !important`,
    borderWidth: 2,
    [theme.breakpoints.down(breakpoints.MOBILE)]: {
      height: '50px',
      borderRadius: 25,
    },
    [theme.breakpoints.up(breakpoints.MOBILE)]: {
      height: '40px',
      width: '100%',
      borderRadius: 20,
    },
    '&:hover': {
      backgroundColor: colors.palette.primary.light,
    },
  },
  buttonFocusVisible: {
    backgroundColor: `${colors.palette.primary.light} !important`,
    outline: 'none',
  },
});

export default styles;
