import Auth from '@industriousoffice/member-portal-auth';

function loginAuth(username, password) {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await Auth.signIn(username.toLowerCase(), password);
      const token = await Auth.useToken.getIdToken();
      // TODO: Fix module to validate response sigin
      if (token.success && response.success) {
        const objData = {
          token: token.token,
          id: response.message['custom:contactid'],
          success: response.success,
          clientAccessClaims: JSON.parse(
            response.message['custom:client_access'] ?? '{}'
          ),
        };
        return resolve(objData);
      }

      return resolve(response);
    } catch (error) {
      return reject(error);
    }
  });
}

export default loginAuth;
