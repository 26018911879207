import { AnyReservation, Office } from '../../Models';
import { date as DateUtils } from '../../Core/Utils';

import {
  RESERVATION_ACTIONS,
  RESERVATION_TYPES,
} from '../../Models/anyReservation';

export const getCardDataFromAnyReservation = (r, t, classes, profile) => {
  const { type, reservation } = r;
  const canEdit = AnyReservation.getReservationPermissions({
    type,
    reservation,
    profile,
    action: RESERVATION_ACTIONS.update,
  });
  const canCancel = AnyReservation.getReservationPermissions({
    type,
    reservation,
    profile,
    action: RESERVATION_ACTIONS.delete,
  });

  if (type === RESERVATION_TYPES.SEAT_BOOKING) {
    return {
      reservation,
      time: t('Upcoming.all_day'),
      space: t('Upcoming.open_workspace_title'),
      location: reservation.grouping?.location,
      grouping: reservation.grouping,
      canEdit,
      canCancel,
      type,
      startEpoch: AnyReservation.getReservationStartEpoch(r),
    };
  }

  if (type === RESERVATION_TYPES.DESK_RESERVATION) {
    return {
      space: t('Upcoming.floor_office_desk', {
        floorName: reservation.desk?.parentOffice?.floor?.floorName,
        officeName: Office.getOfficeName(reservation.desk?.parentOffice),
        deskNumber: reservation.desk?.deskNumber,
      }),
      location: reservation.grouping?.location,
      grouping: reservation.grouping,
      time: t('Upcoming.all_day'),
      reservation,
      canEdit,
      canCancel,
      type,
      startEpoch: AnyReservation.getReservationStartEpoch(r),
    };
  }
  if (type === RESERVATION_TYPES.OFFICE_RESERVATION) {
    return {
      reservation,
      time: t('Upcoming.all_day'),
      space: t('Upcoming.floor_office', {
        floorName: reservation.office?.floor?.floorName,
        officeName: Office.getOfficeName(reservation.office),
      }),
      location: reservation.grouping?.location,
      grouping: reservation.grouping,
      canEdit: false,
      canCancel,
      type,
      startEpoch: AnyReservation.getReservationStartEpoch(r),
    };
  }
  if (type === RESERVATION_TYPES.CONFERENCE_BOOKING) {
    const timezoneId = AnyReservation.getReservationTimezoneId(r);

    return {
      time: (
        <div>
          {DateUtils.getDateInMomentWithZone(
            reservation.startEpoch,
            timezoneId
          ).format('[h:mma]')}{' '}
          -{' '}
          {DateUtils.getDateInMomentWithZone(
            reservation.endEpoch,
            timezoneId
          ).format('[h:mma]')}
          <span className={classes.tz}>
            {DateUtils.getTimeZoneCode(timezoneId)}
          </span>
        </div>
      ),
      space: reservation.space?.name,
      location: reservation.space?.location,
      grouping: reservation.space?.grouping,
      reservation,
      type,
      canEdit,
      canCancel,
      startEpoch: AnyReservation.getReservationStartEpoch(r),
    };
  }
  return {};
};
