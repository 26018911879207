/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable react/no-did-update-set-state */

// The linter thinks inputProps and InputProps are the same even though the are different in the mui textfield implementation

/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
//  The appropriate labels are passed but since they are passed via the get label props function the linter doesn't pick it up

import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import * as React from 'react';
import Downshift from 'downshift';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import Lodash from 'lodash';
import { debounce } from 'throttle-debounce';
import InfiniteScroll from 'react-infinite-scroller';
import MenuItem from '@material-ui/core/MenuItem';
import clsx from 'clsx';
import { withTranslation } from 'react-i18next';

import styles from './styles';
import Carat from '../Carat';
import { colors } from '../../../Core/Theme';
import CheckBox from '../CheckBox';
import Loading from '../Loading';
import Menu from '../Menu';

export const THRESHOLD_INFINITE_SCROLL = 4;

class FetchAutocomplete extends React.Component {
  constructor(props) {
    super(props);
    const { getSuggestions } = props;
    this.state = { q: '', results: {}, inputValue: '', selectedItems: [] };
    this.autocompleteSearchThrottled = debounce(500, getSuggestions);
  }

  componentDidMount() {
    const { defaultValue, getSuggestions, initialSelected, isMultiSelect } =
      this.props;
    let multiSelectInputString = initialSelected.reduce((acc, i) => {
      return `${acc}${i.name}, `;
    }, '');
    if (multiSelectInputString) {
      multiSelectInputString = multiSelectInputString.substring(
        0,
        Math.max(multiSelectInputString.length - 2, 0)
      );
    }
    this.setState({
      q: '',
      inputValue:
        isMultiSelect && initialSelected
          ? multiSelectInputString
          : defaultValue,
      lastSelection: defaultValue,
      lastActionWasSelection: false, // this tracks whether the user has selected or it typing into the field and is used to determine when to clear the input on multi select
      selectedItems: initialSelected,
    });
    getSuggestions('', this.setResults(''));
  }

  componentDidUpdate(prevProps) {
    const {
      shouldResetResults,
      setShouldResetResults,
      getSuggestions,
      setShouldResetInput,
      shouldResetInput,
      defaultValue,
    } = this.props;
    const { inputValue } = this.state;

    const oldReset = Lodash.get(prevProps, 'shouldResetResults', false);
    const oldDefaultValue = prevProps.defaultValue;

    if (shouldResetResults && !oldReset) {
      this.setState({ results: {} });
      setShouldResetResults(false);
      getSuggestions('', this.setResults(''));
      this.autocompleteSearchThrottled = debounce(500, getSuggestions);
    }
    const oldClearInput = Lodash.get(prevProps, 'shouldResetInput', false);

    if (shouldResetInput && !oldClearInput) {
      this.setState({ inputValue: defaultValue });
      setShouldResetInput(false);
    }
    if (defaultValue !== oldDefaultValue && !inputValue) {
      this.setState({ inputValue: defaultValue });
    }
  }

  onCloseMenu = () => {
    const { lastSelection, inputValue } = this.state;
    const { shouldClearInputAfterChange } = this.props;
    if (inputValue !== lastSelection) {
      this.setState({
        inputValue: shouldClearInputAfterChange ? '' : lastSelection,
      });
    }
  };

  onOpenMenu = openMenu => () => {
    const { resetLoadMoreQueryValue } = this.props;
    openMenu();
    this.setState({ q: '', inputValue: '' });
    resetLoadMoreQueryValue();
  };

  onType(clearSelection) {
    return async event => {
      const { defaultValue } = this.props;
      const { results } = this.state;
      const searchTerm =
        event.target.value === defaultValue ? '' : event.target.value;
      if (event.target.value === '') {
        clearSelection();
      }

      // call for new suggestions
      this.setState(
        {
          q: event.target.value,
          inputValue: event.target.value,
          lastActionWasSelection: false,
        },
        () => {
          // if we already have results don't need to cal
          if (results[searchTerm]) {
            return;
          }
          // call for results
          this.autocompleteSearchThrottled(
            searchTerm,
            this.setResults(searchTerm)
          );
        }
      );
    };
  }

  setResults = inputValue => data => {
    this.setState(prevState => ({
      results: {
        ...prevState.results,
        [inputValue]: data,
      },
    }));
  };

  addResults = (inputValue, data) => {
    const { results } = this.state;
    const prevResults = Lodash.get(results, [inputValue], []);
    this.setState({
      results: {
        ...results,
        [inputValue]: [...prevResults, ...data],
      },
    });
  };

  handleKeyDown = (highlightedIndex, items) => e => {
    if (
      e.key === 'Enter' &&
      (highlightedIndex || highlightedIndex === 0) &&
      items[highlightedIndex]
    ) {
      const selected = items[highlightedIndex];
      this.selectWrapped(selected)(e);
    }
  };

  // makes a generic multi selectable menu item
  makeMultiSelectMenuItem = (item, index, highlightedIndex, itemProps) => {
    const { name, isSelected } = item;
    const { selectedItems } = this.state;
    const isFinalSelectedItem = index === selectedItems.length - 1;
    return (
      <MenuItem
        style={{
          backgroundColor:
            index === highlightedIndex
              ? colors.palette.secondary2.main
              : colors.white,
        }}
        {...itemProps}
      >
        <div
          style={
            isFinalSelectedItem
              ? {
                  borderBottom: `solid 1px ${colors.middle}`,
                  height: 46,
                  width: '100%',
                }
              : {}
          }
        >
          <CheckBox checked={isSelected} checkBoxText={name} />
        </div>
      </MenuItem>
    );
  };

  // this function is used when the user clicks into the textfield when making a selection
  resetSelectionIfNeeded = () => {
    const { isMultiSelect, resetLoadMoreQueryValue } = this.props;
    const { inputValue, lastActionWasSelection } = this.state;
    if (isMultiSelect && inputValue && lastActionWasSelection) {
      this.setState({ q: '', inputValue: '', lastSelection: inputValue });
      resetLoadMoreQueryValue();
    }
  };

  selectWrapped = item => e => {
    e.preventDefault();
    const { onChange, isMultiSelect, shouldClearInputAfterChange } = this.props;
    const { selectedItems } = this.state;
    if (isMultiSelect) {
      const selectedItemsUpdated = this.toggleInArray(selectedItems, item);
      let multiSelectInputString = selectedItemsUpdated.reduce((acc, i) => {
        return `${acc}${i.name}, `;
      }, '');
      if (multiSelectInputString) {
        multiSelectInputString = multiSelectInputString.substring(
          0,
          Math.max(multiSelectInputString.length - 2, 0)
        );
      }
      onChange(selectedItemsUpdated);
      this.setState({
        q: '',
        inputValue: multiSelectInputString,
        lastSelection: multiSelectInputString,
        selectedItems: selectedItemsUpdated,
        lastActionWasSelection: true,
      });
    } else {
      onChange(item);
      this.setState({
        q: item.name,
        inputValue: !shouldClearInputAfterChange ? item.name : '',
        lastSelection: item.name,
      });
    }
  };

  stateReducer = (state, changes) => {
    const { isMultiSelect } = this.props;
    switch (changes.type) {
      case Downshift.stateChangeTypes.keyDownEnter:
      case Downshift.stateChangeTypes.clickItem:
        return {
          ...changes,
          isOpen: isMultiSelect ? state.isOpen : changes.isOpen, // don't close menu on select item for multi select
        };
      default:
        return changes;
    }
  };

  toggleInArray = (array, el) => {
    const ret = array.filter(a => a.key !== el.key);
    if (ret.length === array.length) {
      ret.push({ ...el, isSelected: true });
      const retSorted = Lodash.sortBy(ret, 'name');
      return retSorted;
    }
    return ret;
  };

  toggleMenu = (isOpen, openMenu, closeMenu) => {
    const { resetLoadMoreQueryValue } = this.props;
    return () => {
      if (!isOpen) {
        this.setState({
          q: '',
        });
        openMenu();
        resetLoadMoreQueryValue();
        return;
      }
      const { lastSelection } = this.state;
      this.setState({
        inputValue: lastSelection,
      });
      closeMenu();
    };
  };

  renderLoading = () => {
    const { hasMoreData, classes } = this.props;
    return (
      <div className={classes.loader} key="fetchautocompleteloading">
        {hasMoreData ? <Loading color={colors.palette.secondary.main} /> : null}
      </div>
    );
  };

  render() {
    const {
      classes,
      defaultValue,
      makeMenuItem,
      inputClassName,
      menuContentClassName,
      topMenuItems,
      useInfiniteScroll,
      hasMoreData,
      loadMoreData,
      placeholder,
      isMultiSelect,
      hasError,
      disabled,
      datatestid,
      onBlur,
      onFocus,
      disabledNoMenu,
      label,
      isLabelHidden,
      variant,
      errorMessage,
      errorClassName,
      labelClassName,
      t,
      menuClassName,
    } = this.props;
    const isBoxed = variant === 'boxed';
    const { results, q, inputValue, selectedItems } = this.state;

    return (
      <Downshift
        // id="downshift-location-select"
        stateReducer={this.stateReducer}
        itemToString={() => inputValue}
      >
        {({
          clearSelection,
          getInputProps,
          getMenuProps,
          getItemProps,
          isOpen,
          openMenu,
          closeMenu,
          highlightedIndex,
          getLabelProps,
        }) => {
          // when q is nothing it displays all results
          const searchTerm = q === defaultValue ? '' : q;
          let items = Lodash.get(results, searchTerm, []);
          if (topMenuItems) {
            //  top menu items are things  like my reservations and always live at the tope
            items = items.filter(i => {
              const { key } = i;
              return !Lodash.find(topMenuItems, { key }); // don't show menu items if in the top list
            });
            items = [...topMenuItems, ...items];
          }
          if (isMultiSelect && !Lodash.isEmpty(selectedItems)) {
            // these menu items live at the top but vary based on whats selected
            items = items.filter(i => {
              const { key } = i;
              return !Lodash.find(selectedItems, { key }); // don't show menu items if in the top list
            });
            items = [...selectedItems, ...items];
          }
          const menuItems = items.map((r, index) => {
            if (isMultiSelect && !makeMenuItem) {
              return this.makeMultiSelectMenuItem(
                r,
                index,
                highlightedIndex,
                getItemProps({
                  onClick: this.selectWrapped(r, topMenuItems),
                  key: `${r.sfId}${index}`,
                  index,
                  item: r,
                })
              );
            }
            const isFinalSelectedItem =
              isMultiSelect && index === selectedItems.length - 1;
            return makeMenuItem(
              r,
              index,
              highlightedIndex,
              getItemProps({
                disabled,
                onClick: this.selectWrapped(r),
                key: `${r.sfId}${index}`,
                index,
                item: r,
              }),
              isFinalSelectedItem
            );
          });
          return (
            <div className={clsx(classes.main)} aria-haspopup>
              {label && (
                <div
                  className={clsx(
                    labelClassName || classes.label,
                    hasError && classes.labelError,
                    isLabelHidden && classes.labelHidden,
                    isBoxed && classes.boxedLabel
                  )}
                  datatestid={`${datatestid}_label`}
                >
                  <label {...getLabelProps()}>{label}</label>
                </div>
              )}

              <TextField
                placeholder={placeholder}
                InputProps={{
                  classes: {
                    root: clsx(
                      !disabledNoMenu && classes.textInputRoot,
                      hasError && classes.error,
                      isBoxed && classes.textInputRootBoxed
                    ),
                    input: clsx(
                      inputClassName || classes.textInput,
                      isBoxed && classes.boxedTextInput
                    ),
                  },
                  disableUnderline: true,
                  fullWidth: true,
                  endAdornment: !disabledNoMenu && (
                    <InputAdornment position="end">
                      <Carat
                        className={clsx(
                          classes.carat,
                          isBoxed && classes.boxedCarat
                        )}
                        size="medium"
                        onClick={this.toggleMenu(isOpen, openMenu, closeMenu)}
                        datatestid={`${datatestid}_carat`}
                      />
                    </InputAdornment>
                  ),
                }}
                inputProps={{
                  ...getInputProps({
                    disabled: disabled || disabledNoMenu,
                    onChange: this.onType(clearSelection),
                    onFocus: () => {
                      this.onOpenMenu(openMenu)();
                      onFocus();
                    },
                    onBlur: () => {
                      this.onCloseMenu();
                      onBlur();
                    },
                    value: inputValue,
                    onMouseDown: this.resetSelectionIfNeeded,
                    datatestid: `${datatestid}_input`,
                  }),
                }}
                onKeyDown={this.handleKeyDown(highlightedIndex, items)}
              />
              {errorMessage && hasError && (
                <div className={clsx(classes.errorMessage, errorClassName)}>
                  {t(errorMessage)}
                </div>
              )}
              <Menu
                className={menuClassName}
                open={isOpen && menuItems.length > 0 && !disabledNoMenu}
              >
                <div
                  className={menuContentClassName || classes.menuPaper}
                  {...getMenuProps()}
                >
                  {useInfiniteScroll ? (
                    <InfiniteScroll
                      loadMore={loadMoreData(this.addResults)}
                      hasMore={hasMoreData}
                      threshold={THRESHOLD_INFINITE_SCROLL}
                      loader={this.renderLoading()}
                      useWindow={false}
                      initialLoad={false}
                    >
                      {menuItems}
                    </InfiniteScroll>
                  ) : (
                    menuItems
                  )}
                </div>
              </Menu>
            </div>
          );
        }}
      </Downshift>
    );
  }
}

FetchAutocomplete.defaultProps = {
  inputClassName: null,
  menuContentClassName: null,
  menuClassName: null,
  topMenuItems: null,
  useInfiniteScroll: false,
  hasMoreData: false,
  loadMoreData: () => null,
  placeholder: '',
  resetLoadMoreQueryValue: () => null,
  isMultiSelect: false,
  makeMenuItem: null,
  defaultValue: '',
  hasError: false,
  initialSelected: [],
  shouldResetResults: false,
  shouldClearInputAfterChange: false,
  setShouldResetResults: () => null,
  disabled: false,
  datatestid: '',
  onBlur: () => null,
  onFocus: () => null,
  label: '',
  disabledNoMenu: false,
  isLabelHidden: false,
  variant: 'boxed',
  errorMessage: '',
  shouldResetInput: false,
  setShouldResetInput: () => {},
  errorClassName: null,
  labelClassName: null,
};

FetchAutocomplete.propTypes = {
  classes: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  getSuggestions: PropTypes.func.isRequired,
  defaultValue: PropTypes.string,
  makeMenuItem: PropTypes.func,
  inputClassName: PropTypes.string,
  menuContentClassName: PropTypes.string,
  menuClassName: PropTypes.string,
  topMenuItems: PropTypes.arrayOf(PropTypes.object),
  useInfiniteScroll: PropTypes.bool,
  hasMoreData: PropTypes.bool,
  loadMoreData: PropTypes.func,
  resetLoadMoreQueryValue: PropTypes.func,
  placeholder: PropTypes.string,
  isMultiSelect: PropTypes.bool,
  hasError: PropTypes.bool,
  initialSelected: PropTypes.arrayOf(PropTypes.object),
  shouldResetResults: PropTypes.bool,
  shouldClearInputAfterChange: PropTypes.bool,
  setShouldResetResults: PropTypes.func,
  disabled: PropTypes.bool,
  datatestid: PropTypes.string,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  label: PropTypes.string,
  disabledNoMenu: PropTypes.bool,
  isLabelHidden: PropTypes.bool,
  variant: PropTypes.string,
  errorMessage: PropTypes.string,
  t: PropTypes.func.isRequired,
  shouldResetInput: PropTypes.bool,
  setShouldResetInput: PropTypes.func,
  errorClassName: PropTypes.string,
  labelClassName: PropTypes.string,
};

export default withTranslation()(withStyles(styles)(FetchAutocomplete));
