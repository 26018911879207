import Lodash from 'lodash';
import memoizeOne from 'memoize-one';

import { Office } from '../../Models';
import { fetchDayPasses } from '../../Core/Api';
import { getIsManager } from '../../Core/Utils/userPermissions';
import { getPassesRemaining } from '../../Containers/DeskReservation/Utils';

export const getAccountSfIdOfReservations = memoizeOne(
  (reservations, profile) => {
    const accountSfIds = reservations.map(r =>
      Lodash.get(
        r,
        ['owner', 'accountSfId'],
        Lodash.get(r, ['owner', 'account', 'sfId']),
        false
      )
    );
    const ownerAccountSfIds = reservations.map(r =>
      Lodash.get(r, ['reservationSet', 'setOwner', 'accountSfId'], false)
    );
    const allIds = [...accountSfIds, ...ownerAccountSfIds];
    const filteredIds = allIds.filter(a => a);
    const canCreateOutsideAccount = getIsManager(profile);
    const userAccountSfId = Lodash.get(profile, 'accountSfId', '');
    return Lodash.get(
      filteredIds,
      '0',
      canCreateOutsideAccount ? '' : userAccountSfId
    );
  }
);

export const getReservationSetOwner = (values, profile) => {
  const { reservations } = values;

  const setOwner = Lodash.get(reservations, [0, 'owner'], profile);
  const setOwnerAccountSfId = Lodash.get(setOwner, ['account', 'sfId'], '');
  const profileAccountSfId = Lodash.get(profile, ['accountSfId'], '');

  return profileAccountSfId === setOwnerAccountSfId ? profile : setOwner;
};

export const getDisplayInfoForReservation = (r, t) => {
  const { owner } = r;

  const floorName = Lodash.get(r, [
    'desk',
    'parentOffice',
    'floor',
    'floorName',
  ]);

  const deskNumber = Lodash.get(r, ['desk', 'deskNumber'], '');
  const officeName = Office.getOfficeName(
    Lodash.get(r, ['desk', 'parentOffice'], {})
  );
  const accountName = Lodash.get(r, ['owner', 'account', 'name'], '');
  const setOwnerAccountName = Lodash.get(
    r,
    ['reservationSet', 'setOwner', 'account', 'name'],
    ''
  );

  return {
    floor: t('general.floor', { floorName }),
    location: t('deskAdmin.upcoming.desk', { deskNumber, officeName }),
    owner,
    accountName: accountName || setOwnerAccountName,
  };
};

export const getDisplayPrice = price => {
  return `$${(Math.round(price * 100) / 100).toFixed(2)}`;
};

export const getDayPassUsage = (accountSfId, setPassesRemaining) => {
  fetchDayPasses({ accountSfId }).then(objResponse => {
    const { data } = objResponse;
    const balanceRemaining = getPassesRemaining(data);
    setPassesRemaining(balanceRemaining);
  });
};
