/* eslint-disable react/require-default-props */
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

// Api & Core
import { getStaticImageUrl } from '../../Core/Utils';
import { S3ImagesString } from '../../resources';

// Components
import * as Layers from './Layers';

import FloorPlan from './Components/FloorPlan';
import { getCommonArea } from './utils';
import { OfficeAvailabilityDto } from '../../Core/Api';
import { Layers as LayersEnum, MapData } from './types';

require('./style/map_gl_override.css');

const imagesToLoad = [
  {
    img: getStaticImageUrl(S3ImagesString.deskUnavailable),
    name: 'deskUnavailable',
  },
];

const legends = [
  LayersEnum.AvailableOffice,
  LayersEnum.UnavailableOffice,
  LayersEnum.SelectedOffice,
  LayersEnum.ReservedByTeammateOffices,
].map(layerId => Layers.Legends[layerId]);

type OfficeReservationFloorPlanProps = {
  datasetId: string;
  mapStyleId: string;
  offices: OfficeAvailabilityDto[];
  selectedFloorName: string;
  selectedOffice?: OfficeAvailabilityDto;
  onSelectOffice: (office: OfficeAvailabilityDto) => void;
  isLoading: boolean;
};

const OfficeReservationFloorPlan = (props: OfficeReservationFloorPlanProps) => {
  const {
    datasetId,
    mapStyleId,
    offices,
    selectedFloorName,
    selectedOffice,
    onSelectOffice,
    isLoading,
  } = props;

  const { t } = useTranslation();

  const officeMapFeatureIds = useMemo(() => {
    return {
      unavailable: offices
        .filter(
          office =>
            office.officeMapFeatureId != null &&
            office.isReserved &&
            office.reservedBy == null
        )
        .map(office => office.officeMapFeatureId!),
      reservedByTeammate: offices
        .filter(
          office =>
            office.officeMapFeatureId != null &&
            office.isReserved &&
            office.reservedBy != null
        )
        .map(office => office.officeMapFeatureId!),
      available: offices
        .filter(
          office => office.officeMapFeatureId != null && !office.isReserved
        )
        .map(office => office.officeMapFeatureId!),
    };
  }, [offices]);

  // eslint-disable-next-line react/prop-types
  const getLayersToDisplay = (params: {
    labelVisible: boolean;
    mapData: MapData;
  }) => {
    const { labelVisible, mapData } = params;

    const { commonAreaIds, indicators: commonAreaIndicators } = getCommonArea({
      mapData,
      t,
    });

    return (
      <>
        <Layers.CommonArea featureIds={commonAreaIds} />
        <Layers.Office />
        <Layers.UnavailableOffice
          featureIds={officeMapFeatureIds.unavailable}
        />
        <Layers.ReservedByTeammateOffices
          featureIds={officeMapFeatureIds.reservedByTeammate}
        />
        <Layers.SelectedOffice
          featureIds={
            selectedOffice?.officeMapFeatureId
              ? [selectedOffice.officeMapFeatureId]
              : []
          }
        />
        <Layers.AvailableOffice featureIds={officeMapFeatureIds.available} />
        <Layers.CommonLabels
          indicators={commonAreaIndicators}
          commonAreas={commonAreaIds}
          labelVisible={labelVisible}
        />
      </>
    );
  };

  return (
    <FloorPlan
      offices={offices}
      datasetId={datasetId}
      mapStyleId={mapStyleId}
      selectedFloorName={selectedFloorName}
      selectedOfficeMapFeatureId={selectedOffice?.officeMapFeatureId}
      legends={legends}
      imagesToLoad={imagesToLoad}
      handleFeatureOnClick={(office: OfficeAvailabilityDto) => {
        onSelectOffice(office);
      }}
      interactiveLayersIds={[LayersEnum.AvailableOffice]}
      isLoading={isLoading}
      getLayersToDisplay={getLayersToDisplay}
    />
  );
};

export default OfficeReservationFloorPlan;
