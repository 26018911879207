import { schema, normalize } from 'normalizr';
import { get } from 'lodash';
import { convertFromRaw } from 'draft-js';

//  constructor
export function NormObjCstr(byId, allIds) {
  this.byId = byId;
  this.allIds = allIds;
}

const senderSchema = new schema.Entity(
  'createdBy',
  {},
  {
    idAttribute: 'sfId',
  }
);

//  No need to normalize criteria
const messageSchema = new schema.Entity(
  'message',
  { createdBy: senderSchema },
  {
    idAttribute: 'idMessage',
    processStrategy: message => {
      const messageCopy = { ...message };
      const contentState = convertFromRaw(JSON.parse(messageCopy.body));
      messageCopy.body = messageCopy.body;
      messageCopy.subjectLine = contentState.getPlainText();
      return messageCopy;
    },
  }
);

const messagesArray = [messageSchema];

export const normalizeMessages = data => {
  const temp = Array.isArray(data)
    ? normalize(data, messagesArray)
    : normalize(data, messageSchema);

  const allIds = [].concat(get(temp, ['result'], []));
  const byId = get(temp, ['entities', 'message'], {});
  const createdBy = get(temp, ['entities', 'createdBy'], {});

  return { messages: new NormObjCstr(byId, allIds), createdBy };
};
