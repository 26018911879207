import MemberPortalRbac, {
  Action,
  BookingValidations,
  ResourceType,
} from '@industriousoffice/member-portal-rbac';
import memoizeOne from 'memoize-one';

import Lodash from 'lodash';

export const isAllowed = ({ profile, action, resourceType, obj }) => {
  const role = MemberPortalRbac(profile);
  const listActions = Lodash.isArray(action) ? action : [action];
  return listActions.reduce((isRoleAllowed, strAction) => {
    return (
      isRoleAllowed ||
      role.can({
        action: strAction,
        resourceType,
        obj,
      })
    );
  }, false);
};

export const RBAC = objOption => {
  return objExtraOption => {
    const onjInternalOption = {
      ...objOption,
      ...objExtraOption,
    };

    return isAllowed(onjInternalOption);
  };
};

const objBaseBookingPayload = {
  resourceType: ResourceType.Booking,
};

const objPassUsagePayload = {
  resourceType: ResourceType.PassUsage,
};

const objBaseLocationPayload = {
  resourceType: ResourceType.Location,
};

const objBaseRoomPayload = {
  resourceType: ResourceType.Space,
};

const objBaseAccountPayload = {
  resourceType: ResourceType.Account,
};

const objBaseOccasionPayload = {
  resourceType: ResourceType.Occasion,
};

const objReceptionPayload = {
  resourceType: ResourceType.Notification,
};

const objBaseGroupingFeaturePayload = {
  resourceType: ResourceType.GroupingFeatureToggle,
};
const objBaseGroupingPayload = {
  resourceType: ResourceType.Grouping,
};

const objBaseOfficePayload = {
  resourceType: ResourceType.Office,
};

const objBaseUserPayload = {
  resourceType: ResourceType.User,
};

const objBaseReservationPayload = {
  resourceType: ResourceType.Reservation,
};

const objBaseOfficeReservationPayload = {
  resourceType: ResourceType.OfficeReservation,
};
const objBaseSeatBookingPayload = {
  resourceType: ResourceType.SeatBooking,
};

export const seatBookingRoleAccess = {
  update: RBAC({
    ...objBaseSeatBookingPayload,
    action: [Action.Update],
  }),
  view: RBAC({
    ...objBaseSeatBookingPayload,
    action: [Action.Get],
  }),
  delete: RBAC({
    ...objBaseSeatBookingPayload,
    action: [Action.Delete],
  }),
};

export const receptionRoleAccess = {
  accessReception: RBAC({
    ...objReceptionPayload,
    action: [Action.AccessReception],
  }),
};

export const bookingRoleAccess = {
  adminCreate: RBAC({
    ...objBaseBookingPayload,
    action: [Action.AdminCreate],
  }),
  hasCredits: RBAC({
    ...objBaseBookingPayload,
    action: [Action.HaveCredits],
  }),
  modifyAnyTime: RBAC({
    ...objBaseBookingPayload,
    action: [Action.ModifyAnytime],
  }),
  createAnyTime: RBAC({
    ...objBaseBookingPayload,
    action: [Action.ModifyAnytime],
  }),
  create: RBAC({
    ...objBaseBookingPayload,
    action: [Action.Create],
  }),
  update: RBAC({
    ...objBaseBookingPayload,
    action: [Action.Update],
  }),
  delete: RBAC({
    ...objBaseBookingPayload,
    action: [Action.Delete],
  }),
  view: RBAC({
    ...objBaseBookingPayload,
    action: [Action.Get],
  }),
  viewBookingAndLocation: RBAC({
    ...objBaseBookingPayload,
    action: [Action.HasAccessToSubResource],
  }),
};

// TODO update with real RBAC policy
export const roomRoleAccess = {
  edit: RBAC(objBaseRoomPayload),
  create: RBAC(objBaseRoomPayload),
};

export const companyInfoAccess = {
  update: RBAC({ ...objBaseAccountPayload, action: [Action.Update] }),
  accessBillingForAccount: RBAC({
    ...objBaseAccountPayload,
    action: [Action.AccessBilling],
  }),
  list: RBAC({ ...objBaseAccountPayload, action: [Action.List] }),
};

export const occasionRoleAccess = {
  update: RBAC({ ...objBaseOccasionPayload, action: [Action.Update] }),
  create: RBAC({ ...objBaseOccasionPayload, action: [Action.Create] }),
  accessUrl: RBAC({ ...objBaseOccasionPayload, action: [Action.AccessUrl] }),
};

export const officesRoleAccess = {
  get: RBAC({
    ...objBaseOfficePayload,
    action: [Action.Get],
  }),
};

export const locationRoleAccess = {
  hasBookingRestrictions: RBAC({
    ...objBaseLocationPayload,
    action: [Action.HasBookingRestriction],
  }),
  accessOnHomePage: RBAC({
    ...objBaseLocationPayload,
    action: [Action.AccessHomepage],
  }),
  bookAt: RBAC({
    ...objBaseLocationPayload,
    action: [Action.BookAt],
  }),
  bookWeekendsAt: RBAC({
    ...objBaseLocationPayload,
    action: [Action.BookWeekendsAt],
  }),
};

export const groupingFeatureRoleAccess = {
  HasFeatureInPrimaryLocation: RBAC({
    ...objBaseGroupingFeaturePayload,
    action: [Action.HasFeatureInPrimaryLocation],
  }),
  hasFeature: RBAC({
    ...objBaseGroupingFeaturePayload,
    action: [Action.HasFeature],
  }),
};

export const groupingRoleAccess = {
  update: RBAC({
    ...objBaseGroupingPayload,
    action: [Action.Update],
  }),
};

export const userRoleAccess = {
  get: RBAC({
    ...objBaseUserPayload,
    action: [Action.Get],
  }),
  list: RBAC({
    ...objBaseUserPayload,
    action: [Action.List],
  }),
};

export const reservationRoleAccess = {
  update: RBAC({ ...objBaseReservationPayload, action: [Action.Update] }),
  create: RBAC({ ...objBaseReservationPayload, action: [Action.Create] }),
  delete: RBAC({ ...objBaseReservationPayload, action: [Action.Delete] }),
  view: RBAC({ ...objBaseReservationPayload, action: [Action.Get] }),
  hasCredits: RBAC({
    ...objBaseReservationPayload,
    action: [Action.HaveCredits],
  }),
};

export const officeReservationRoleAccess = {
  update: RBAC({ ...objBaseOfficeReservationPayload, action: [Action.Update] }),
  create: RBAC({ ...objBaseOfficeReservationPayload, action: [Action.Create] }),
  delete: RBAC({ ...objBaseOfficeReservationPayload, action: [Action.Delete] }),
  view: RBAC({ ...objBaseOfficeReservationPayload, action: [Action.Get] }),
  hasCredits: RBAC({
    ...objBaseOfficeReservationPayload,
    action: [Action.HaveCredits],
  }),
};

export const passUsageRoleAccess = {
  purchase: RBAC({ ...objPassUsagePayload, action: [Action.Purchase] }),
};

export const dayPassRoleAccess = {
  hasFeature: RBAC({
    resourceType: ResourceType.DayPasses,
    action: [Action.HasFeature],
  }),
};

export const accessHQ = profile => {
  const role = MemberPortalRbac(profile);
  return role.hasAccessToHq;
};
export default isAllowed;

export const hasOccasionsFeature = profile => {
  const role = MemberPortalRbac(profile);
  return role.hasOccasionsFeature;
};

export const getIsVirtualMember = profile => {
  const role = MemberPortalRbac(profile);
  return role.isVirtualMember;
};

export const getIsAccessMember = memoizeOne(profile => {
  const role = MemberPortalRbac(profile);
  return role.isAccessMember;
});

export const getHasGlobalNetworkAddon = memoizeOne(profile => {
  const role = MemberPortalRbac(profile);
  return role.hasGlobalNetworkAddon;
});

export const getIsManager = memoizeOne(profile => {
  const role = MemberPortalRbac(profile);
  return role.isManager;
});

export const getIsDecisionMaker = memoizeOne(profile => {
  const role = MemberPortalRbac(profile);
  return role.isDecisionMaker;
});

export const getMustRespectBusinessHours = (profile, location) =>
  MemberPortalRbac(profile).mustBookLocationWithinBusinessHours(location);

export const getWithinBookingHours = (startEpoch, endEpoch, timeZoneId) => {
  // bookings are in 30 minutes increments in the portal
  const bookingValidation = new BookingValidations(0, 30);
  return bookingValidation.validateWithinBusinessHours(
    startEpoch,
    endEpoch,
    timeZoneId
  );
};

export const canAccessFeatureToggleInPrimaryLocation = (
  profile,
  userGroupings,
  userGroupingsIsLoading,
  featureKey
) => {
  if (userGroupingsIsLoading) {
    return true;
  }
  const groupingDto = {
    groupings: userGroupings,
    groupingFeatureKey: featureKey,
  };
  return groupingFeatureRoleAccess.HasFeatureInPrimaryLocation({
    obj: groupingDto,
    profile,
  });
};

export const canAccessFeatureToggle = (
  profile,
  userGroupings,
  userGroupingsIsLoading,
  featureKey
) => {
  if (userGroupingsIsLoading) {
    return true;
  }
  const groupingDto = {
    groupings: userGroupings,
    groupingFeatureKey: featureKey,
  };
  return groupingFeatureRoleAccess.hasFeature({
    obj: groupingDto,
    profile,
  });
};

export const canAccessHotDeskFeature = (
  profile,
  userGroupings,
  userGroupingsIsLoading
) => {
  if (getIsVirtualMember(profile)) {
    return false;
  }
  return (
    getIsManager(profile) ||
    canAccessFeatureToggle(
      profile,
      userGroupings,
      userGroupingsIsLoading,
      'HotDesk'
    )
  );
};
