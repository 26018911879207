import { constants, colors, breakpoints } from '../../Core/Theme';

const CONSTS = {
  NAME_FONT_SIZE: '18px',

  USER_INFO_PADDING_R_LG: 50,
  USER_INFO_PADDING_T_LG: 30,
  USER_INFO_PADDING_SM: 16,

  SIDE_NAV_MENU_BUTTON_HEIGHT_SM: 60,
  SIDE_NAV_MENU_BUTTON_WIDTH_SM: 50,
  SIDE_NAV_MENU_BUTTON_HEIGHT_XS: 40,
  SIDE_NAV_MENU_BUTTON_WIDTH_XS: 28,
  SIDE_NAV_MENU_BUTTON_MARGIN_VERTICAL: 10,
  SIDE_NAV_MENU_BUTTON_PADDING_HORIZONTAL: 18,

  APP_BAR_BOX_SHADOW: '0 .5px .5px 0 rgba(0, 0, 0, 0.07)',

  SUB_NAV_FONT_FAMILY: 'VerlagBook',
  SUB_NAV_FONT_SIZE: '18px',
  SUB_NAV_PADDING_LEFT: 40,

  AVATAR_SIZE_LG: 50,
  AVATAR_SIZE_SM: 24,
  AVATAR_MARGIN_TOP_LG: -17,
  AVATAR_MARGIN_TOP_SM: 0,
  AVATAR_MARGIN_RIGHT_LG: 20,
  AVATAR_MARGIN_RIGHT_SM: 0,

  CARAT_PADDING_RIGHT: 30,
  CARAT_PADDING_LEFT: 20,
  CARAT_PADDING_LEFT_SUBNAV: 5,

  TOP_NAV_BORDER: `1px solid ${colors.dark}`,
  TOP_NAV_BORDER_RECEPTION: `3px solid ${colors.black}`,
  TOP_NAV_BORDER_RADIUS: 3,

  NAV_PROFILE_ITEMS_BORDER: `1px solid ${colors.middle}`,
};

const styles = theme => ({
  appBar: {
    [theme.breakpoints.up('xs')]: {
      backgroundColor: colors.white,
      boxShadow: CONSTS.APP_BAR_BOX_SHADOW,
      color: colors.black,
      textAlign: 'right',
      borderBottom: '1px solid #dddddd',
    },
    [theme.breakpoints.down('xs')]: {},
  },
  main: {
    display: 'flex',
    justifyContent: 'space-between',
    position: 'relative',
    [theme.breakpoints.up('xs')]: {
      height: constants.TOP_NAV_HEIGHT_LG,
    },
    [theme.breakpoints.down('xs')]: {
      height: constants.TOP_NAV_HEIGHT_SM,
    },
  },
  dummyHeader: {
    position: 'relative',
    borderBottom: 'solid 2px transparent',
  },
  dummyHeaderCover: {
    position: 'absolute',
    top: 0,
    right: 0,
    left: 0,
    bottom: 0,
    backgroundColor: colors.white,
    zIndex: 2,
  },
  userInfoDiv: {
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    [theme.breakpoints.up('xs')]: {
      paddingRight: 40,
    },
    [theme.breakpoints.down('xs')]: {
      paddingRight: 30,
    },
    display: 'flex',
    fontFamily: 'VerlagBook',
    fontSize: CONSTS.NAME_FONT_SIZE,
    marginLeft: '25px',
  },
  avatar: {
    [theme.breakpoints.up('xs')]: {
      marginRight: CONSTS.AVATAR_MARGIN_RIGHT_LG,
      marginTop: CONSTS.AVATAR_MARGIN_TOP_LG,
      width: CONSTS.AVATAR_SIZE_LG,
      height: CONSTS.AVATAR_SIZE_LG,
      backgroundColor: colors.middle,
    },
    [theme.breakpoints.down('xs')]: {
      height: CONSTS.AVATAR_SIZE_SM,
      width: CONSTS.AVATAR_SIZE_SM,
      marginTop: CONSTS.AVATAR_MARGIN_TOP_SM,
      marginRight: CONSTS.AVATAR_MARGIN_RIGHT_SM,
      backgroundColor: colors.middle,
    },
  },
  caratIcon: {
    paddingLeft: CONSTS.CARAT_PADDING_LEFT,
    cursor: 'pointer',
    textAlign: 'center',
    marginRight: -4,
    '&:focus:not(.focus-visible)': {
      outline: 'none',
    },
  },
  caratIconResponsive: {
    paddingLeft: '5px',
    cursor: 'pointer',
    marginRight: -4,
    '&:focus:not(.focus-visible)': {
      outline: 'none',
    },
  },
  menuPaperRec: {
    boxShadow: `0 0 3px 0 rgba(0, 0, 0, 0.25)`,
    border: CONSTS.TOP_NAV_BORDER_RECEPTION,
    borderBottom: CONSTS.TOP_NAV_BORDER,
    borderRight: CONSTS.TOP_NAV_BORDER,
    borderLeft: CONSTS.TOP_NAV_BORDER,
    borderRadius: 4,
    width: 210,
    top: '60px !important',
  },
  accountInfo: {
    flexDirection: 'row',
    display: 'flex',
    justifyContent: 'flex-end',
    textAlign: 'center',
    alignItems: 'center',
  },
  languageLabel: {
    textTransform: 'uppercase',
  },
  languageToggle: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
    flexDirection: 'row',
    marginLeft: '20px',
    cursor: 'pointer',
    display: 'flex',
    alignSelf: 'center',
    textAlign: 'center',
    alignItems: 'center',
    height: '15px',
    fontSize: 15,
    color: colors.palette.primary.main,
    fontWeight: 'bold',
    fontFamily: 'VerlagBold',
    padding: '4px 8px',
    borderColor: '#E4EDEC',
    borderRadius: 999,
    border: 'solid 1px',
  },
  languageMenuIcon: {
    cursor: 'pointer',
    fill: '#013E3F',
    marginLeft: 8,
    '&:focus:not(.focus-visible)': {
      outline: 'none',
    },
  },
  languageMenuNav: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
    boxShadow: `0 0 4px 0 rgba(0, 0, 0, 0.15)`,
    borderRadius: 4,
    width: 140,
    marginTop: '50px',
  },
  languageMenuItem: {
    backgroundColor: 'white',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  languageMenuItemText: {
    color: '#002D2D',
    fontSize: 16,
    fontWeight: 400,
  },
  checkIcon: {
    height: 19,
    marginLeft: 3,
    color: '#013E3F',
  },
  menuPaperProfileNav: {
    boxShadow: `0 0 3px 0 rgba(0, 0, 0, 0.25)`,
    borderTop: CONSTS.TOP_NAV_BORDER_RECEPTION,
    borderRadius: 4,
    width: 202,
    marginTop: '50px',
  },
  menuPaperRecWithBanner: {
    top: '102px !important',
  },
  menuPaperWithBanner: {
    top: '122px !important',
  },
  menuPaperDropDown: {
    borderTop: `5px solid ${colors.black}`,
  },

  sideNavMenuButton: {
    color: colors.black,
    [theme.breakpoints.down('sm')]: {
      height: CONSTS.SIDE_NAV_MENU_BUTTON_HEIGHT_SM,
      width: CONSTS.SIDE_NAV_MENU_BUTTON_WIDTH_SM,
      marginTop: 13,
      marginBottom: 10,
    },
    [theme.breakpoints.down('xs')]: {
      height: CONSTS.SIDE_NAV_MENU_BUTTON_HEIGHT_XS,
      width: CONSTS.SIDE_NAV_MENU_BUTTON_WIDTH_XS,
    },
    marginTop: CONSTS.SIDE_NAV_MENU_BUTTON_MARGIN_VERTICAL,
    marginBottom: CONSTS.SIDE_NAV_MENU_BUTTON_MARGIN_VERTICAL,
    paddingLeft: CONSTS.SIDE_NAV_MENU_BUTTON_PADDING_HORIZONTAL,
    paddingRight: CONSTS.SIDE_NAV_MENU_BUTTON_PADDING_HORIZONTAL,
    borderRight: `1px solid ${colors.middle}`,
    position: 'absolute',
    top: 0,
  },
  topSubNavLeftWrapper: {
    flex: 1,
    marginLeft: '1%',
    maxWidth: '40%',
  },
  topSubNavLeft: {
    display: 'flex',
    height: '100%',
    width: '100%',
  },
  topSubNavRightWrapper: {
    marginLeft: 'auto',
  },
  topSubNavRight: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: colors.palette.tertiary2.main,
    height: '100%',
    width: '100%',
  },
  subNavMenuItem: {
    textDecoration: 'none',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 'calc(100% - 10px)',
    width: 'max-content',
    textAlign: 'center',
    borderBottom: `5px solid transparent`,
    borderTop: `5px solid transparent`,
    color: colors.black,
  },
  subNavMenuItemLeft: {
    fontFamily: CONSTS.SUB_NAV_FONT_FAMILY,
    fontSize: CONSTS.SUB_NAV_FONT_SIZE,
    color: colors.black,
    marginLeft: '3%',
    marginRight: '3%',
    '&:hover': {
      borderBottom: `5px solid ${colors.accent}`,
    },
  },
  subNavMenuItemDropDown: {
    fontFamily: CONSTS.SUB_NAV_FONT_FAMILY,
    fontSize: CONSTS.SUB_NAV_FONT_SIZE,
    color: colors.black,
    marginLeft: '3%',
    marginRight: '3%',
  },
  subNavMenuItemRight: {
    paddingLeft: '15px',
    paddingRight: '15px',
    color: colors.white,
    fontFamily: CONSTS.SUB_NAV_FONT_FAMILY,
    fontSize: CONSTS.SUB_NAV_FONT_SIZE,
    lineHeight: 1.44,
    '&:hover': {
      borderBottom: `5px solid ${colors.accent}`,
    },
  },
  managerSubNavMenuItemRight: {
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
    [theme.breakpoints.down('sm')]: {
      display: 'visible !important',
    },
  },
  genNavProfileItems: {
    backgroundColor: 'white',
    borderBottom: CONSTS.NAV_PROFILE_ITEMS_BORDER,
    '&:hover': {
      backgroundColor: `${colors.palette.primary.main} !important`,
      color: `${colors.white} !important`,
    },
  },
  genNavItemReception: {
    backgroundColor: 'white',
    paddingTop: '4.5px',
    paddingBottom: '4.5px',
    '&:hover': {
      backgroundColor: `${colors.light} !important`,
    },
  },
  genNavItemReceptionText: {
    height: 'inherit',
    width: '100%',
    display: 'block',
    lineHeight: '1.5',
  },
  genNavProfileItemsText: {
    fontWeight: 'bold',
  },
  accountMobile: {
    paddingTop: '4px',
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
    },
  },
  accountDesktop: {
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  referBanner: {
    width: 'calc(100% - 50px)',
    backgroundColor: colors.palette.primary.main,
    paddingTop: 10,
    paddingBottom: 10,
    paddingLeft: 20,
    paddingRight: 30,
    fontFamily: 'VerlagBook',
    fontSize: 14,
    textAlign: 'center',
    lineHeight: '18px',
    color: colors.white,
    [theme.breakpoints.up(breakpoints.MOBILE)]: {
      fontSize: 16,
      paddingLeft: 50,
      paddingRight: 55,
      width: 'calc(100% - 105px)',
    },
  },
  referButton: {
    lineHeight: '17px',
    paddingLeft: 30,
    fontFamily: 'VerlagBold',
    color: colors.white,
    textDecoration: 'underline',
    paddingRight: 30,
  },
  closeReferralButton: {
    background: 'transparent !important',
    color: colors.white,
    boxShadow: 'none !important',
    height: 20,
    minHeight: 20,
    fontSize: 16,
    position: 'absolute',
    right: 0,
    top: 8,
    [theme.breakpoints.up(breakpoints.MOBILE)]: {
      fontSize: 20,
      right: 29,
      top: 10,
    },
  },
  contentLoader: {
    height: '100%',
    width: '100%',
    marginTop: 33,
  },
  contentLoaderWrapper: {
    height: '100%',
    width: 60,
    marginLeft: '3%',
    marginRight: '3%',
  },
  skeletonLoader: {
    height: 20,
    width: '60%',
  },
  adminButtonContainer: {
    width: '232px',
    marginRight: '29px',
    paddingTop: '5px',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  labelChip: {
    marginLeft: 10,
    color: colors.textPalette.link.main,
    border: `1px solid ${colors.textPalette.link.main}`,
    fontSize: 12,
  },
});

export default styles;
