/* eslint-disable jsx-a11y/no-noninteractive-tabindex */

import 'react-dates/initialize';
import moment from 'moment';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { ExpandMore } from '@material-ui/icons';

import Monthpicker from './Monthpicker';
import styles from './styles';

const MonthPick = props => {
  const { selectedMonth, onChange, classes } = props;
  const month = moment.unix(selectedMonth).month();
  const year = moment.unix(selectedMonth).year();
  return (
    <Monthpicker month={month} year={year} locale="en" onChange={onChange}>
      <div className={classes.inputText} tabIndex={0}>
        {moment.unix(selectedMonth).format('MMMM YYYY')}
        <ExpandMore className={classes.expandMore} />
      </div>
    </Monthpicker>
  );
};

MonthPick.defaultProps = {
  onChange: () => '',
};

MonthPick.propTypes = {
  selectedMonth: PropTypes.number.isRequired,
  onChange: PropTypes.func,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(MonthPick);
