import { useEffect, useState } from 'react';
import {
  OfficeReservationCurrencyChargeDto,
  fetchOfficeReservationCharges,
} from '../Core/Api';
import { formatCurrency } from '../Core/Utils/strings';

export const useOfficeReservationCharges = (props: {
  accountSfId: string;
  startEpoch: number;
  endEpoch: number;
  refreshCount?: number;
}) => {
  const { accountSfId, startEpoch, endEpoch, refreshCount } = props;
  const [charges, setCharges] =
    useState<OfficeReservationCurrencyChargeDto[]>();
  const [totalReservations, setTotalReservations] = useState<number>();
  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [formattedCharges, setFormattedCharges] = useState('');

  useEffect(() => {
    let ignore = false;

    const refreshOfficeReservationCharges = async () => {
      setIsLoading(true);
      setHasError(false);

      try {
        const { data } = await fetchOfficeReservationCharges({
          startEpoch,
          endEpoch,
          accountSfId,
        });
        if (ignore) return;

        if (!data) {
          setHasError(true);
        }
        setCharges(data.charges);
        setTotalReservations(data.totalReservations);
        setFormattedCharges(
          data.charges
            ?.map(charge =>
              formatCurrency({
                currency: charge.currency,
                value: charge.total,
              })
            )
            .join(' + ')
        );
      } catch (error) {
        setHasError(true);
      }

      setIsLoading(false);
    };

    if (accountSfId && startEpoch && endEpoch) {
      refreshOfficeReservationCharges();
    }

    return () => {
      ignore = true;
    };
  }, [accountSfId, startEpoch, endEpoch, refreshCount]);

  return { charges, isLoading, hasError, totalReservations, formattedCharges };
};
