import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Logos } from '../../../resources';
import { colors } from '../../../Core/Theme';

export const DefaultBackgroundColor = colors.white;
export const DefaultSource = Logos.IndLogoBeeBlack;

const ProfileImage = props => {
  const { classes, source, alternative, backgroundColor } = props;

  let imageStyle;
  if (source === DefaultSource || !source) {
    imageStyle = {
      marginLeft: '14%',
      marginRight: '16%',
      marginTop: '6%',
      width: '69%',
      height: '82%',
    };
  }
  return (
    <div className={classes.outerDiv}>
      <div
        className={classes.innerDiv}
        style={{
          backgroundColor,
        }}
      >
        <img
          src={source || DefaultSource}
          alt={alternative || 'Profile'}
          className={classes.image}
          style={imageStyle}
        />
      </div>
    </div>
  );
};

const styles = {
  outerDiv: {
    borderRadius: '50%',

    overflow: 'hidden',
    height: 0,
    paddingTop: '100%',
    position: 'relative',
  },
  innerDiv: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
  },
  image: {
    width: '100%',
    height: '100%',
  },
};

ProfileImage.defaultProps = {
  source: DefaultSource,
  alternative: null,

  backgroundColor: DefaultBackgroundColor,
};

ProfileImage.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  source: PropTypes.string,
  alternative: PropTypes.oneOfType([PropTypes.string]),

  backgroundColor: PropTypes.string,
};

export default withStyles(styles)(ProfileImage);
