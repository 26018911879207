import Grid from '@material-ui/core/Grid';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { History } from 'history';

import ReserveButton from './ReserveButton';
import { Icons } from '../../../resources';
import { trackHomePageNavButton } from '../../../Core/Tracking';
import { DESK_LANDING_PATH } from '../../DeskReservationLanding/constants';
import { ROOM_BOOKING_PATH } from '../../RoomBooking/constants';
import { RESERVE_OFFICE_PATH } from '../../ReserveOffice/reserveOfficeConstants';
import { OPEN_WORKSPACE_LANDING_PATH } from '../../OpenWorkspace/constants';
import {
  canAccessHotDeskFeature,
  getIsVirtualMember,
} from '../../../Core/Utils/userPermissions';
import { getUserProfile } from '../../../Redux/Common/UserManager/selectors';
import {
  getUserGroupings,
  getUserGroupingsIsLoading,
} from '../../../Redux/Common/Groupings/selectors';

interface Props {
  history: History;
}
const ReserveOptions = (props: Props) => {
  const { history } = props;
  const { t } = useTranslation();

  const profile = useSelector(getUserProfile);
  const userGroupings = useSelector(getUserGroupings);
  const userGroupingsIsLoading = useSelector(getUserGroupingsIsLoading);

  const goToReserveDesk = () => {
    trackHomePageNavButton('Desk Reservations');
    history.push(DESK_LANDING_PATH);
  };

  const goToReserveRoom = () => {
    trackHomePageNavButton('Room Reservations');
    history.push(ROOM_BOOKING_PATH);
  };

  const goToReserveOffice = () => {
    trackHomePageNavButton('Office Reservation');
    history.push(RESERVE_OFFICE_PATH);
  };

  const goToOpenWorkspace = () => {
    trackHomePageNavButton('Open Workspace Reservation');
    history.push(OPEN_WORKSPACE_LANDING_PATH);
  };

  const showHotDeskCard = canAccessHotDeskFeature(
    profile,
    userGroupings,
    userGroupingsIsLoading
  );

  const cardSizeLG = showHotDeskCard ? 6 : 4;
  const cardSizeXL = showHotDeskCard ? 3 : 4;

  const userIsVirtual = getIsVirtualMember(profile);

  return (
    <>
      <Grid item xs={12} md={6} lg={cardSizeLG} xl={cardSizeXL}>
        <ReserveButton
          cta={t('home.navigation_ctas.conference.cta')}
          description={t('home.navigation_ctas.conference.description')}
          onClick={goToReserveRoom}
          iconSrc={Icons.HomeConferenceNav}
        />
      </Grid>
      <Grid item xs={12} md={6} lg={cardSizeLG} xl={cardSizeXL}>
        <ReserveButton
          cta={t('home.navigation_ctas.office.cta')}
          description={t('home.navigation_ctas.office.description')}
          onClick={goToReserveOffice}
          iconSrc={Icons.HomeOfficeNav}
          isDisabled={userIsVirtual}
          tooltipText={
            userIsVirtual ? t('home.navigation_ctas.virtual_tooltip') : null
          }
        />
      </Grid>
      {showHotDeskCard && (
        <Grid item xs={12} md={6} lg={cardSizeLG} xl={cardSizeXL}>
          <ReserveButton
            cta={t('home.navigation_ctas.desk.cta')}
            description={t('home.navigation_ctas.desk.description')}
            onClick={goToReserveDesk}
            iconSrc={Icons.HomeDeskNav}
            isBeta
          />
        </Grid>
      )}
      <Grid item xs={12} md={6} lg={cardSizeLG} xl={cardSizeXL}>
        <ReserveButton
          cta={t('home.navigation_ctas.openWorkspace.cta')}
          description={t('home.navigation_ctas.openWorkspace.description')}
          onClick={goToOpenWorkspace}
          iconSrc={Icons.HomeSeatNav}
          isDisabled={userIsVirtual}
          tooltipText={
            userIsVirtual ? t('home.navigation_ctas.virtual_tooltip') : null
          }
        />
      </Grid>
    </>
  );
};

export default ReserveOptions;
