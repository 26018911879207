import { forwardRef } from 'react';
import ReactMapGL from 'react-map-gl';

const MapWrapper = forwardRef((props: any, ref: any) => {
  const { children } = props;

  return (
    <ReactMapGL
      ref={ref}
      mapboxApiAccessToken={process.env.REACT_APP_MAPBOX_ACCESSTOKEN}
      {...props}
    >
      {children}
    </ReactMapGL>
  );
});

export default MapWrapper;
