import React from 'react';
import PropTypes from 'prop-types';

// Components
import { MapLayerWrapper as Layer } from '../../Common';

// Constants
import { LAYERS } from '../Constants';

// Theme & Utils
import { colors } from '../../../Core/Theme';
import { createHash } from '../../../Core/Utils';

// Layer properties
const fillProperties = {
  id: LAYERS.RESERVABLE_OFFICE_UNAVAILABLE,
  type: 'fill',
  source: 'features',
  paint: {
    'fill-pattern': 'unavailable',
  },
};

const lineProperties = {
  id: `${LAYERS.RESERVABLE_OFFICE_UNAVAILABLE}-lines`,
  type: 'line',
  source: 'features',
  paint: {
    'line-color': colors.palette.primary.main,
    'line-width': 1,
  },
};

const ReservableOfficeUnavailable = props => {
  const { offices } = props;
  return (
    <Layer
      key={`${LAYERS.RESERVABLE_OFFICE_UNAVAILABLE}-${createHash(offices)}`}
      properties={[fillProperties, lineProperties]}
      filter={['in', ['get', 'id'], ['literal', offices]]}
      {...props}
    />
  );
};

ReservableOfficeUnavailable.defaultProps = {
  offices: [],
};
ReservableOfficeUnavailable.propTypes = {
  offices: PropTypes.arrayOf(PropTypes.string),
};

export default React.memo(ReservableOfficeUnavailable);
