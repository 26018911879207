/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import * as React from 'react';
import PropTypes from 'prop-types';
import {
  MenuList,
  MenuItem,
  Popper,
  Paper,
  withStyles,
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import Text from '../Text';
import { trackTopMenuCategoryNavigation } from '../../../Core/Tracking';
import { colors } from '../../../Core/Theme';
import LabelChip from '../LabelChip';

const handleMouseEnter = setAnchorEl => e => {
  setAnchorEl(e.currentTarget);
};

const handleMouseLeave = setAnchorEl => () => {
  setAnchorEl(null);
};

const handleClick = (onClickFn, setAnchorEl) => name => {
  onClickFn(name);
  setAnchorEl(null);
};

const genMenuItems = (navTab, onClick, classes, t) => {
  const { path, name, shortPathName } = navTab;
  return (
    <MenuItem key={path} selected={false} className={classes.menuItem}>
      <Link
        to={path}
        className={classes.link}
        onClick={() => onClick(name)}
        datatestid={`home_${t(shortPathName || name)}_navigation_button`}
      >
        <Text textStyle="h6" text={t(shortPathName || name)} />
        {navTab.isBeta ? (
          <LabelChip
            className={classes.labelChip}
            label={t('navigation.beta')}
          />
        ) : null}
      </Link>
    </MenuItem>
  );
};

const NavDropDown = props => {
  const { navTabs, name, classes, onClick, classNames } = props;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const { t } = useTranslation();

  return (
    <div
      onMouseEnter={handleMouseEnter(setAnchorEl)}
      onMouseLeave={handleMouseLeave(setAnchorEl)}
      className={clsx(classes.menu, classNames.menu)}
      onClick={trackTopMenuCategoryNavigation({ event_label: t(name) })}
      datatestid={t(name)}
    >
      <p
        className={clsx(classes.label, classNames.label)}
        aria-owns={anchorEl ? 'simple-menu' : undefined}
        aria-haspopup="true"
      >
        {t(name)}
      </p>

      <Popper
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        disablePortal
        placement="bottom-start"
      >
        <Paper className={classNames.paper}>
          <MenuList>
            {navTabs.map(navTab =>
              genMenuItems(
                navTab,
                handleClick(onClick, setAnchorEl),
                classes,
                t
              )
            )}
          </MenuList>
        </Paper>
      </Popper>
    </div>
  );
};

const styles = () => ({
  label: {
    cursor: 'pointer',
    margin: 0,
  },
  menu: {
    display: 'inline-block',
  },
  link: {
    textDecoration: 'none',
    color: 'black',
    width: '100%',
    marginLeft: -16,
    marginRight: -16,
    paddingLeft: 16,
    paddingRight: 16,
  },
  menuItem: {
    '&:hover': {
      backgroundColor: colors.palette.secondary2.main,
    },
  },
  labelChip: {
    marginLeft: 10,
    color: colors.textPalette.link.main,
    border: `1px solid ${colors.textPalette.link.main}`,
    fontSize: 12,
  },
});

NavDropDown.defaultProps = {
  classes: {},
  navTabs: [],
  name: '',
  onClick: () => null,
  classNames: {},
};

NavDropDown.propTypes = {
  classes: PropTypes.shape({}),
  navTabs: PropTypes.arrayOf(PropTypes.shape({})),
  name: PropTypes.string,
  onClick: PropTypes.func,
  classNames: PropTypes.shape({
    menu: PropTypes.string,
    label: PropTypes.string,
    paper: PropTypes.string,
  }),
};
export default withStyles(styles)(NavDropDown);
