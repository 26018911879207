import Components from '../../Components';

const { containerTypes } = Components.Routes;

const RouteConfig = {
  path: '/home/',
  exact: false,
  name: 'Header',
  private: true,
  type: containerTypes.NAV,
};

export default RouteConfig;
