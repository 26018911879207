/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */

import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Lodash from 'lodash';
import Moment from 'moment';
import MenuItem from '@material-ui/core/MenuItem';
import { EditorState, convertFromRaw } from 'draft-js';
import clsx from 'clsx';
// Components
import {
  TextButton,
  Kebab,
  AddCalendarBtn,
  Snack,
} from '../../../../Components/Common';
import RsvpButton from '../RsvpButton';
import { Icons, S3IconStrings } from '../../../../resources';
import DayBox from '../DayBox';

import Redux from '../../Redux';

import {
  getOccasionById,
  createRsvp,
  deleteRsvp,
  deleteOccasion,
} from '../../../../Core/Api';
import {
  trackAddEventToCalendar,
  trackViewEventsFromCard,
  trackClickAddress,
  trackEditEventFromCard,
  trackDeleteEventFromCard,
  trackWaitlistRsvp,
  trackCancelRsvp,
  trackRsvp,
  trackCancelWaitlistRsvp,
  trackVirtualEventLink,
  trackExternalEventLink,
} from '../../../../Core/Tracking';

import { renderVirtualLink } from '../../util';

// Theme
import { colors } from '../../../../Core/Theme';
import { getStaticImageUrl, formatCurrency } from '../../../../Core/Utils';

const menuItems = (
  openToEdit,
  openToDuplicate,
  openAttendeeDrawer,
  deleteCurrentEvent,
  classes,
  canRsvp,
  t
) => {
  const items = [
    <MenuItem
      onClick={openToEdit}
      className={classes.menuItem}
      key="Edit"
      datatestid="event_edit_menu_edit"
    >
      <div>{t('general.edit')}</div>
    </MenuItem>,
    <MenuItem
      onClick={openToDuplicate}
      className={classes.menuItem}
      key="Duplicate"
      datatestid="event_edit_menu_duplicate"
    >
      <div>{t('events.duplicate')}</div>
    </MenuItem>,
    <MenuItem
      onClick={deleteCurrentEvent}
      className={classes.menuItem}
      datatestid="event_edit_menu_delete"
    >
      <div>{t('events.delete')}</div>
    </MenuItem>,
  ];
  if (canRsvp) {
    items.splice(
      2,
      0,
      <MenuItem
        onClick={openAttendeeDrawer}
        className={classes.menuItem}
        datatestid="event_edit_view_rsvp"
      >
        <div>{t('events.eventRsvps.viewEventAttendees')}</div>
      </MenuItem>
    );
  }
  return items;
};

const onKeyDown = cb => e => {
  if (e.key === 'Enter' || e.key === 'Space') {
    return cb();
  }
  return null;
};

const addCalendarOnClick = e => {
  e.stopPropagation();
  trackAddEventToCalendar({ event_category: 'Upcoming Events' });
};

const EventCard = props => {
  const {
    classes,
    event,
    openDrawerToEdit,
    openDrawerToView,
    occasionType,
    canUpdate,
    canAccessUrl,
    openDrawerToDuplicate,
    openAttendeeDrawer,
    profile,
    deleteOccasionRedux,
    updateOccasionRedux,
    updateRsvpRedux,
    deleteRsvpRedux,
    occasionRsvps,
    className,
    imageClass,
    datatestid,
    hideRsvpButton,
  } = props;
  const { t } = useTranslation();
  const {
    idOccasion,
    primaryOccasionImage,
    title,
    startEpoch,
    endEpoch,
    address,
    locationDetails,
    cost,
    currency,
    description,
    canRsvp,
    canWaitlist,
    attendeeLimit,
    numberOfAttendees,
    isVirtual,
    conferenceLink,
    externalRegistrationLink,
  } = event;

  const rsvp = (occasionRsvps || []).filter(o => o.occasionId === idOccasion);
  const waitlisted = Lodash.get(rsvp, [0, 'isWaitlisted'], false);
  const idRsvp = Lodash.get(rsvp, [0, 'idRsvp'], 0);

  const [eventDeletedAction, setEventDeletedAction] = React.useState(false);
  const [eventDeleteCanceled, setEventDeleteCanceled] = React.useState(false);
  const [removeEvent, setRemoveEvent] = React.useState(false);
  const [eventDeletedMessage, setEventDeletedMessage] = React.useState('');
  const [isLoading, setIsLoading] = React.useState(false);
  const [startedWaitlisted, setStartedWaitlisted] = React.useState(false);
  const [startedWaitlisting, setStartedWaitlisting] = React.useState(false);
  const [memberWasWaitlisted, setMemberWasWaitlisted] = React.useState(false);
  const [memberDidNotRsvp, setMemberDidNotRsvp] = React.useState(false);
  const [rsvpd, setRsvpd] = React.useState(rsvp.length > 0);

  React.useEffect(() => {
    setRsvpd(rsvp.length > 0);
  }, [rsvp]);

  React.useEffect(() => {
    setEventDeletedMessage(`${title} was deleted.`);
    if (
      numberOfAttendees > 0 &&
      attendeeLimit &&
      numberOfAttendees >= attendeeLimit * 1
    ) {
      if (!(rsvp.length > 0) && canWaitlist && !waitlisted) {
        setStartedWaitlisted(true);
      }
    }
    if (waitlisted) {
      setStartedWaitlisting(true);
    }
  }, []);

  React.useEffect(() => {
    if (removeEvent) {
      if (!eventDeleteCanceled) {
        deleteOccasion(idOccasion).then(() => {
          setEventDeleteCanceled(false);
          setEventDeletedAction(false);
          setRemoveEvent(false);
          deleteOccasionRedux(event);
        });
      } else {
        setEventDeleteCanceled(true);
        setEventDeletedAction(false);
        setRemoveEvent(false);
      }
    }
  }, [removeEvent, eventDeleteCanceled]);

  const timeRange = `${Moment.unix(startEpoch).format(
    '[h:mma]'
  )} - ${Moment.unix(endEpoch).format('[h:mma]')}`;

  const location = Lodash.get(event, ['space', 'name'], '');

  const occasionTypeName = Lodash.get(occasionType, 'name', null);
  const image = Lodash.get(primaryOccasionImage, 'imageUrl', null);
  const occasionTypeImage = Lodash.get(occasionType, 'imageUrl', null);

  const openWindow = () => {
    trackClickAddress({ event_category: 'Upcoming Events' });
    window.open(
      `https://www.google.com/maps/search/?api=1&query=${encodeURI(address)}`
    );
  };

  const openEventLink = () => {
    trackVirtualEventLink();
    window.open(conferenceLink);
  };

  const onRsvp = () => {
    const id = idOccasion * 1;

    const postdata = {
      userSfId: profile.sfId,
      occasionId: id,
      isWaitlisted: false,
    };

    setIsLoading(true);

    createRsvp(postdata).then(objResponse => {
      const { data, status } = objResponse;
      const { isWaitlisted } = data;
      if (isWaitlisted) {
        trackWaitlistRsvp();
      } else {
        trackRsvp();
      }
      const newRsvpId = Lodash.get(data, 'idRsvp', '');

      if (status === 201) {
        updateRsvpRedux(data);
        if (isWaitlisted) {
          getOccasionById(idOccasion).then(response => {
            updateOccasionRedux(response.data);
            if (
              !(rsvp.length > 0) &&
              response.data.numberOfAttendees > 0 &&
              response.data.attendeeLimit &&
              response.data.numberOfAttendees >=
                response.data.attendeeLimit * 1 &&
              response.data.canWaitlist &&
              isWaitlisted
            ) {
              if (!startedWaitlisted && !startedWaitlisting) {
                setMemberWasWaitlisted(true);
                setStartedWaitlisted(true);
              }
            }

            if (!response.data.canWaitlist) {
              deleteRsvp(newRsvpId).then(() => {
                deleteRsvpRedux(newRsvpId);
                setIsLoading(false);
                setMemberDidNotRsvp(true);
              });
            }
          });
          setIsLoading(false);
        } else {
          getOccasionById(idOccasion).then(response => {
            updateOccasionRedux(response.data);
          });
          setIsLoading(false);
        }
      }
    });
  };

  const onUnrsvp = () => {
    setIsLoading(true);
    if (waitlisted) {
      trackCancelWaitlistRsvp();
    } else {
      trackCancelRsvp();
    }
    deleteRsvp(idRsvp).then(objResponse => {
      const { status } = objResponse;
      if (status === 200) {
        deleteRsvpRedux(idRsvp);
        getOccasionById(idOccasion).then(response => {
          updateOccasionRedux(response.data);
          setIsLoading(false);
        });
      }
    });
  };

  const openExternalRegistrationLink = () => {
    trackExternalEventLink();
    if (externalRegistrationLink) {
      window.open(externalRegistrationLink);
    }
  };

  const buttonOnClick = cb => e => {
    cb();
    e.stopPropagation();
  };

  const deleteCurrentEvent = () => () => {
    setEventDeletedAction(true);
    setEventDeleteCanceled(false);
    trackDeleteEventFromCard();
  };

  const cancelDeletion = () => {
    setEventDeletedAction(false);
    setEventDeleteCanceled(true);
  };

  const viewEvent = (openToView, e) => () => {
    trackViewEventsFromCard();
    openToView(e)();
  };

  const editEvent = (openToEdit, e) => () => {
    trackEditEventFromCard();
    openToEdit(e)();
  };

  //  TODO: refactor to button after material ui upgrade
  /* eslint-disable jsx-a11y/anchor-is-valid */
  const descConverted = EditorState.createWithContent(
    convertFromRaw(JSON.parse(description))
  );
  const kebabMenuItems = menuItems(
    editEvent(openDrawerToEdit, event),
    openDrawerToDuplicate(event),
    openAttendeeDrawer(event),
    deleteCurrentEvent(event),
    classes,
    canRsvp,
    t
  );
  const locationContent = () => {
    return (
      <div className={classes.places}>
        <span className={classes.location}>
          <img src={Icons.Location} alt="location" className={classes.icon} />
          {location || locationDetails}
        </span>

        {address ? (
          <TextButton
            text={address}
            className={classes.address}
            onClick={buttonOnClick(openWindow)}
          />
        ) : null}
      </div>
    );
  };
  const virtualLink = () => {
    return (
      <div className={classes.joinEventContainer}>
        <Icons.VirtualCamera className={classes.virtualCameraIcon} />

        <TextButton
          text="Join event virtually"
          className={classes.joinLink}
          onClick={buttonOnClick(openEventLink)}
        />
      </div>
    );
  };

  const renderAttendeeButton = () => {
    if (hideRsvpButton || !canRsvp) {
      return null;
    }
    return (
      <RsvpButton
        rsvpd={rsvpd}
        canRsvp={canRsvp}
        eventFull={
          numberOfAttendees > 0 &&
          attendeeLimit &&
          numberOfAttendees >= attendeeLimit * 1
        }
        ableToWaitlist={canWaitlist}
        waitlisted={waitlisted}
        isLoading={isLoading}
        rsvpAction={buttonOnClick(onRsvp)}
        unrsvpAction={buttonOnClick(onUnrsvp)}
        externalRegistrationLinkAction={buttonOnClick(
          openExternalRegistrationLink
        )}
        externalRegistrationLink={externalRegistrationLink}
      />
    );
  };

  return (
    <>
      <a
        onClick={viewEvent(openDrawerToView, event)}
        onKeyDown={onKeyDown(viewEvent(openDrawerToView, event))}
        className={clsx(classes.wrapper, !startEpoch && classes.disabled)}
        role="button"
        aria-label={t('general.view')}
        tabIndex="0"
        datatestid={datatestid}
      >
        {!eventDeletedAction ? (
          <div className={clsx(classes.main, className)}>
            <img
              src={
                image ||
                occasionTypeImage ||
                getStaticImageUrl(S3IconStrings.eventsDefault)
              }
              alt={t('altTexts.event')}
              className={clsx(classes.img, imageClass)}
            />
            <div className={classes.middle}>
              <div className={classes.middleContainer}>
                <h3 className={classes.title}>{title}</h3>
                {startEpoch ? (
                  <h4 className={classes.timeRange}>{timeRange}</h4>
                ) : null}
                {canUpdate ? (
                  <Kebab
                    className={classes.editButtonMobile}
                    menuItems={kebabMenuItems}
                    datatestid="event_edit_menu"
                  />
                ) : null}

                {location || locationDetails ? locationContent() : null}

                {isVirtual && conferenceLink
                  ? renderVirtualLink(
                      canAccessUrl,
                      canRsvp,
                      rsvpd,
                      numberOfAttendees > 0 &&
                        attendeeLimit * 1 &&
                        numberOfAttendees >= attendeeLimit * 1,
                      virtualLink()
                    )
                  : null}

                {(isVirtual &&
                  !(location || locationDetails) &&
                  numberOfAttendees > 6) ||
                (!isVirtual && numberOfAttendees > 6) ? (
                  <div className={classes.numAttendees}>
                    <span>{numberOfAttendees} people going</span>
                  </div>
                ) : null}
              </div>
            </div>
            <div
              className={clsx(classes.right, !startEpoch && classes.hideRsvp)}
            >
              {renderAttendeeButton()}
              <Snack
                message="This event is already full, but you have been added to the waitlist"
                isOpen={memberWasWaitlisted}
                autoClose
                onClose={() => setMemberWasWaitlisted(false)}
              />
              <Snack
                message="This event is already full so you could not RSVP"
                isOpen={memberDidNotRsvp}
                autoClose
                onClose={() => setMemberDidNotRsvp(false)}
              />

              {cost * 1 !== 0 ? (
                <h5 className={classes.price}>
                  {formatCurrency({
                    value: cost,
                    currency,
                  })}
                </h5>
              ) : null}
              {startEpoch ? (
                <div className={classes.calendarButton}>
                  <AddCalendarBtn
                    className={classes.calendarBtn}
                    event={{ ...event, description: descConverted }}
                    divClassName={classes.addToCalDiv}
                    onClick={addCalendarOnClick}
                  />
                </div>
              ) : null}
            </div>

            <DayBox
              startEpoch={startEpoch}
              occasionTypeName={occasionTypeName}
              className={classes.dayBox}
              isVirtual={isVirtual}
            />

            {canUpdate ? (
              <Kebab
                className={classes.editButtonDesktop}
                menuItems={kebabMenuItems}
                datatestid="event_edit_menu"
              />
            ) : null}
          </div>
        ) : null}
      </a>
      <Snack
        message={eventDeletedMessage}
        actionText="Undo"
        onAction={buttonOnClick(cancelDeletion)}
        isOpen={eventDeletedAction}
        autoClose
        onClose={() => {
          setRemoveEvent(true);
          setEventDeletedAction(false);
        }}
      />
    </>
  );
};
// the standard mobile breakpoint can't be used here because the card's min width is larger than it so we have to switch to the vertical treatment at 570px
const VERTICAL_CARD_BREAKPOINT = 570;
const styles = theme => ({
  wrapper: {
    zIndex: 0,
    '&:focus': {
      outline: 'none',
      border: 'none',
    },
  },
  main: {
    display: 'flex',
    background: 'white',
    boxShadow: '0 0 5px 0 rgba(0, 0, 0, 0.25)',
    borderRadius: 2,
    border: `solid 1px ${colors.light}`,
    position: 'relative',
    marginBottom: 25,
    flexDirection: 'column',
    maxWidth: '960px',
    cursor: 'pointer',
    alignItems: 'center',
    [theme.breakpoints.up(VERTICAL_CARD_BREAKPOINT)]: {
      flexDirection: 'row',
      height: 150,
      minWidth: 600,
    },
    [theme.breakpoints.down(VERTICAL_CARD_BREAKPOINT)]: {
      maxWidth: 'calc(100vw - 40px)',
      display: 'block',
    },
    '&:hover': {
      boxShadow: '0 0 7px 1px rgba(0, 0, 0, 0.25)',
    },
  },
  middleContainer: {
    flexDirection: 'column',
  },
  middle: {
    paddingTop: 15,
    paddingLeft: 20,
    position: 'relative',
    flex: '2 0 0',
    minWidth: 0,
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up(VERTICAL_CARD_BREAKPOINT)]: {
      maxWidth: 850,
      paddingLeft: 60,
    },
  },
  hideRsvp: {
    height: 'auto',
  },
  right: {
    padding: '10px 40px 15px 0',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    height: '100%',
    paddingBottom: 20,
    [theme.breakpoints.down(VERTICAL_CARD_BREAKPOINT)]: {
      paddingLeft: 20,
      paddingRight: 20,
      paddingTop: 0,
    },
  },
  calendarButton: {
    marginTop: 'auto',
  },
  img: {
    width: 'calc(100vw - 38px)',
    height: 'calc(75vw - 10px)', // make it 3/4 of the width
    minWidth: 152,
    borderRadius: 2,
    objectFit: 'cover',
    marginLeft: -1,
    marginTop: -1,
    marginBottom: 0,
    marginRight: -1,
    [theme.breakpoints.up(VERTICAL_CARD_BREAKPOINT)]: {
      width: 152,
      height: 152,
      marginLeft: -1,
      marginTop: -1,
      marginBottom: -1,
      marginRight: 0,
    },
  },
  title: {
    fontFamily: 'VerlagBold',
    fontSize: 20,
    padding: '0 50px 0 0',
    margin: 0,
    fontWeight: 'normal',
    overflowWrap: 'break-word',
    [theme.breakpoints.up(VERTICAL_CARD_BREAKPOINT)]: {
      fontSize: 22,
      paddingRight: 20,
      overflowWrap: 'normal',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
    },
  },
  timeRange: {
    fontSize: 16,
    fontFamily: 'VerlagBold',
    margin: '5px 0 0 0',
    padding: 0,
    fontWeight: 'normal',
    [theme.breakpoints.up(VERTICAL_CARD_BREAKPOINT)]: {
      fontSize: 18,
    },
  },
  places: {
    marginTop: 7,
    paddingBottom: 2,
  },
  location: {
    fontFamily: 'VerlagBook',
    fontSize: 14,
    paddingRight: 10,
  },
  address: {
    fontFamily: 'VerlagBook',
    fontSize: 14,
    color: colors.palette.primary.main,
    textAlign: 'left',
  },
  numAttendees: {
    fontFamily: 'VerlagBook',
    fontSize: 15,
    marginTop: 3,
    marginBottom: 5,
  },
  editButtonDesktop: {
    position: 'absolute',
    top: 10,
    right: 5,
    padding: 0,
    [theme.breakpoints.down(VERTICAL_CARD_BREAKPOINT)]: {
      display: 'none',
    },
  },
  editButtonMobile: {
    position: 'absolute',
    top: 10,
    right: 0,
    padding: 0,
    [theme.breakpoints.up(VERTICAL_CARD_BREAKPOINT)]: {
      display: 'none',
    },
  },
  icon: {
    height: 13,
    paddingRight: 3,
  },
  menuItem: {
    paddingTop: 5,
    paddingBottom: 5,
    fontFamily: 'VerlagLight',
    fontSize: 16,
  },
  dayBox: {
    top: 15,
    left: 15,
    [theme.breakpoints.up(VERTICAL_CARD_BREAKPOINT)]: {
      top: 26,
      left: 110,
    },
  },
  price: {
    margin: 0,
    fontSize: '15px',
    alignSelf: 'center',
    marginTop: 'auto',
    [theme.breakpoints.up(VERTICAL_CARD_BREAKPOINT)]: {
      textAlign: 'center',
    },
  },
  calendarBtn: {
    marginTop: 15,
    marginBottom: 5,
  },
  addToCalDiv: {
    marginTop: 'auto',
    [theme.breakpoints.down(VERTICAL_CARD_BREAKPOINT)]: {
      margin: 'auto',
    },
  },
  joinEventContainer: {
    fontSize: 14,
    marginTop: 6,
  },
  joinLink: {
    fontFamily: 'VerlagBook',
    fontSize: 14,
    color: colors.palette.primary.main,
    textAlign: 'left',
    padding: 0,
    textDecoration: 'underline',
    '&:visited': {
      color: colors.palette.primary.main,
    },
  },
  virtualCameraIcon: {
    paddingRight: 5,
  },
  disabled: {
    pointerEvents: 'none',
  },
});

EventCard.defaultProps = {
  occasionRsvps: [],
  openDrawerToEdit: () => () => null,
  openDrawerToView: () => () => null,
  openDrawerToDuplicate: () => () => null,
  openAttendeeDrawer: () => null,
  className: '',
  imageClass: '',
  datatestid: '',
  hideRsvpButton: false,
};

EventCard.propTypes = {
  openDrawerToEdit: PropTypes.func,
  openDrawerToView: PropTypes.func,
  openDrawerToDuplicate: PropTypes.func,
  occasionType: PropTypes.shape({}).isRequired,
  classes: PropTypes.object.isRequired,
  event: PropTypes.object.isRequired,
  canUpdate: PropTypes.bool.isRequired,
  canAccessUrl: PropTypes.bool.isRequired,
  openAttendeeDrawer: PropTypes.func,
  profile: PropTypes.object.isRequired,
  deleteOccasionRedux: PropTypes.func.isRequired,
  updateRsvpRedux: PropTypes.func.isRequired,
  deleteRsvpRedux: PropTypes.func.isRequired,
  updateOccasionRedux: PropTypes.func.isRequired,
  occasionRsvps: PropTypes.object,
  className: PropTypes.string,
  imageClass: PropTypes.string,
  datatestid: PropTypes.string,
  hideRsvpButton: PropTypes.bool,
};

const mapStateToProps = state => {
  return {
    profile: state.userManager.profile,
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      updateRsvpRedux: Redux.actions.updateRsvpRedux,
      deleteOccasionRedux: Redux.actions.deleteOccasion,
      updateOccasionRedux: Redux.actions.updateOccasion,
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(EventCard));
