import { useCallback } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import { getIsMobile } from '../../../Core/Utils/windowDimensions';
import { breakpoints, colors } from '../../../Core/Theme';
import Image from '../Image';
import { getStaticImageUrl } from '../../../Core/Utils';
import { S3IconStrings } from '../../../resources';

const PopupAlert = ({
  isVisible,
  onClose,
  classes,
  title,
  description,
  className,
  imageUrl,
  imageAlt,
}) => {
  const { t } = useTranslation();
  const handleOnClosePopup = () => {
    return onClose();
  };

  const renderPopupContent = useCallback(() => {
    return (
      <SnackbarContent
        classes={{
          root: clsx(classes.content, className),
        }}
        message={
          <>
            <IconButton
              key="close"
              aria-label={t('general.close')}
              color="inherit"
              className={classes.close}
              onClick={handleOnClosePopup}
            >
              <CloseIcon />
            </IconButton>

            <Image
              src={imageUrl}
              fallbackSrc={getStaticImageUrl(S3IconStrings.underConstruction3)}
              alt={imageAlt}
              className={classes.icon}
            />
            <div className={classes.popUpMessage}>{title}</div>
            <span className={classes.popUpDescription}>{description}</span>
          </>
        }
      />
    );
  });

  return (
    <Snackbar
      anchorOrigin={{
        vertical: getIsMobile() ? 'top' : 'bottom',
        horizontal: 'left',
      }}
      open={isVisible}
      className={classes.snackbarContainer}
    >
      {renderPopupContent()}
    </Snackbar>
  );
};

const styles = theme => ({
  popUpMessage: {
    fontFamily: 'VerlagBold',
    [theme.breakpoints.up(breakpoints.MOBILE)]: {
      fontSize: 23,
    },
    fontSize: 16,
  },
  popUpDescription: {
    fontFamily: 'VerlagBook',
    fontSize: 16,
    textAlign: 'left',
  },
  icon: {
    width: 54,
    height: 54,
    paddingTop: 23,
    [theme.breakpoints.up(breakpoints.MOBILE)]: {
      width: 77,
      height: 77,
    },
  },
  content: {
    backgroundColor: colors.palette.primary.main,
    flexDirection: 'column-reverse',
    [theme.breakpoints.up(breakpoints.MOBILE)]: {
      width: '20%',
    },
    width: '100%',
  },
  close: {
    backgroundColor: 'transparent',
    float: 'right',
  },
});

PopupAlert.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  classes: PropTypes.shape({}).isRequired,
  title: PropTypes.string.isRequired,
  className: PropTypes.string.isRequired,
  imageUrl: PropTypes.string.isRequired,
  imageAlt: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
};
export default withStyles(styles)(PopupAlert);
