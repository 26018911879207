import { colors, breakpoints } from '../../../../Core/Theme';

const CONSTS = {
  TEXT_TITLE_LABEL_FONT_SIZE: '12px',

  FILTER_BUTTON_HEIGHT: '38px',
  FILTER_BUTTON_BORDER_RADIUS: 19,
  FILTER_BUTTON_PADDING_SIDE_INACTIVE: 3,
  FILTER_BUTTON_PADDING_SIDE_ACTIVE: 1.5,
  FILTER_BUTTON_INACTIVE_BORDER: `1px solid #C9C9C9`,
  FILTER_BUTTON_ACTIVE_BORDER: `2.5px solid ${colors.palette.primary.main}`,
  FILTER_BUTTON_MIN_WIDTH: 60,
  FILTER_BUTTON_MARGIN_RIGHT: 10,
  FILTER_BUTTON_FOCUS_OUTLINE_WIDTH: 0,

  SEAT_BUTTON_FONT_SIZE: '20px',
  FLOOR_BUTTON_FONT_SIZE: '20px',
  AMEN_BUTTON_FONT_SIZE: '18px',

  DATE_LOC_BODY_LABEL_FONT_SIZE: '18px',
  DATE_LOC_BODY_LABEL_FONT_FAMILY: 'VerlagBold',
  DATE_LOC_BODY_LABEL_MARGIN_TOP: 5,

  BODY_MARGIN_TOP_LG: 20,
  BODY_MARGIN_TOP_XS: 0,

  MAIN_PADDING_LEFT_LG: 45,
  MAIN_PADDING_LEFT_XS: 20,
  MAIN_PADDING_RIGHT: 40,
  MAIN_PADDING_RIGHT_XS: 20,
  MAIN_MARGIN_BOTTOM: 10,
  MAIN_PADDING_TOP_XS: 40,
  MAIN_PADDING_TOP_LG: 20,
  MAIN_MARGIN_BOTTOM_LG: 20,

  RESV_BUTTON_FONT_SIZE_LG: 16,
  RESV_BUTTON_FONT_SIZE_XS: 14,
  RESV_BUTTON_FONT_FAMILY: 'VerlagBook',
  RESV_BUTTON_BORDER_LEFT: '1px solid black',
  RESV_BUTTON_COLOR: colors.palette.primary.main,

  TITLE_FONT_SIZE_LG: '36px',
  TITLE_FONT_SIZE_XS: '24px',

  DATE_LOC_MARGIN_RIGHT: 50,

  AMENITIES_MARGIN_LEFT: 40,

  LOCATION_MARGIN_LEFT: 60,

  TEXT_LABEL_WIDTH: 200,
  TEXT_LABEL_PADDING_TOP_LG: 20,
  TEXT_LABEL_PADDING_TOP_SM: 10,
  TEXT_LABEL_PADDING_BOTTOM: 10,

  USER_INFO_FONT_SIZE: 16,
  USER_INFO_MARGIN_TOP_LG: 30,
  USER_INFO_MARGIN_TOP_XS: 5,
  USER_INFO_HEIGHT: 30,

  ICON_HEIGHT: 18,
  ICON_MARGIN_TOP: 2,
};

const styles = theme => ({
  main: {
    [theme.breakpoints.up('sm')]: {
      paddingRight: CONSTS.MAIN_PADDING_RIGHT,
      marginBottom: 5,
    },
    position: 'static',
    paddingLeft: CONSTS.MAIN_PADDING_LEFT_LG,
    paddingTop: 20,
    marginTop: 0,
    [theme.breakpoints.down('xs')]: {
      paddingTop: 20,
      paddingLeft: CONSTS.MAIN_PADDING_LEFT_XS,
      paddingRight: CONSTS.MAIN_PADDING_RIGHT_XS,
      marginBottom: CONSTS.MAIN_MARGIN_BOTTOM,
    },
  },
  body: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: CONSTS.BODY_MARGIN_TOP_LG,
    flexWrap: 'wrap',
    marginBottom: 15,
    [theme.breakpoints.down('xs')]: {
      marginTop: CONSTS.BODY_MARGIN_TOP_XS,
    },
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
  },
  viewResvButton: {
    color: CONSTS.RESV_BUTTON_COLOR,
    fontFamily: CONSTS.RESV_BUTTON_FONT_FAMILY,
    fontSize: CONSTS.RESV_BUTTON_FONT_SIZE,
    marginLeft: 0,
    paddingLeft: 0,
    // height: 18,
    paddingTop: 3,
    [theme.breakpoints.down(breakpoints.MOBILE)]: {
      fontSize: 16,
    },
    cursor: 'pointer',
  },
  creditInfo: {
    marginRight: 8,
    paddingRight: 8,
  },
  borders: {
    borderRight: CONSTS.RESV_BUTTON_BORDER_LEFT,
  },
  goToToday: {
    paddingLeft: 0,
  },
  credit: {
    display: 'inline',

    [theme.breakpoints.up('sm')]: {
      display: 'inline',
    },
  },
  purchasedHours: {
    borderRight: `solid 1px ${colors.black}`,
    paddingRight: 8,
    marginRight: 8,
  },
  dateLabel: {
    fontFamily: 'VerlagBold',
    fontSize: 12,
    width: CONSTS.TEXT_LABEL_WIDTH,
    paddingBottom: 2,
  },
  textLabel: {
    fontFamily: 'VerlagBook',
    fontSize: CONSTS.TEXT_TITLE_LABEL_FONT_SIZE,
    paddingTop: CONSTS.TEXT_LABEL_PADDING_TOP_SM,
    marginBottom: -2,
  },
  location: {
    width: CONSTS.TEXT_LABEL_WIDTH,
    marginLeft: CONSTS.LOCATION_MARGIN_LEFT,
  },
  locationTitle: {
    fontFamily: 'VerlagBook',
    fontSize: CONSTS.TEXT_TITLE_LABEL_FONT_SIZE,
  },
  locationLabel: {
    fontFamily: CONSTS.DATE_LOC_BODY_LABEL_FONT_FAMILY,
    fontSize: 20,
    marginTop: 10,
  },
  filterButtonInactive: {
    boxShadow: 'none',
    fontWeight: 'normal',
    background: 'transparent',
    fontFamily: 'VerlagBook',
    height: CONSTS.FILTER_BUTTON_HEIGHT,
    marginRight: CONSTS.FILTER_BUTTON_MARGIN_RIGHT,
    paddingLeft: CONSTS.FILTER_BUTTON_PADDING_SIDE_INACTIVE,
    paddingRight: CONSTS.FILTER_BUTTON_PADDING_SIDE_INACTIVE,
    borderRadius: CONSTS.FILTER_BUTTON_BORDER_RADIUS,
    border: CONSTS.FILTER_BUTTON_INACTIVE_BORDER,
    minWidth: CONSTS.FILTER_BUTTON_MIN_WIDTH,
    marginTop: 10,
    '&:focus:not(.focus-visible)': {
      outline: 0,
    },
    '&:hover': {
      color: colors.black,
    },
    cursor: 'pointer',
    backgroundColor: colors.white,
  },
  filterButtonActive: {
    boxShadow: 'none',
    fontWeight: 'normal',
    backgroundColor: colors.white,
    fontFamily: 'VerlagBook',
    paddingLeft: CONSTS.FILTER_BUTTON_PADDING_SIDE_ACTIVE,
    paddingRight: CONSTS.FILTER_BUTTON_PADDING_SIDE_ACTIVE,
    height: CONSTS.FILTER_BUTTON_HEIGHT,
    marginRight: CONSTS.FILTER_BUTTON_MARGIN_RIGHT,
    borderRadius: CONSTS.FILTER_BUTTON_BORDER_RADIUS,
    border: CONSTS.FILTER_BUTTON_ACTIVE_BORDER,
    minWidth: CONSTS.FILTER_BUTTON_MIN_WIDTH,
    marginTop: 10,

    '&:focus:not(.focus-visible)': {
      outline: CONSTS.FILTER_BUTTON_FOCUS_OUTLINE_WIDTH,
    },
    '&:hover': {
      color: colors.black,
    },
    cursor: 'pointer',
  },
  icon: {
    height: CONSTS.ICON_HEIGHT,
    marginTop: CONSTS.ICON_MARGIN_TOP,
    paddingRight: 5,
  },
  purchasedHoursTooltip: {
    backgroundColor: colors.white,
    width: 150,
    borderRadius: 3,
    color: colors.black,
    boxShadow: '0 0 3px 1px rgba(0, 0, 0, 0.25)',
    fontSize: 12,
    fontFamily: 'VerlagLight',
    border: `solid 1px ${colors.dark}`,
    [theme.breakpoints.up(breakpoints.MOBILE)]: {
      transform: 'translate(-10px, 85px) !important',
    },
    [theme.breakpoints.down(breakpoints.MOBILE)]: {
      width: '205px',
      marginTop: 5,
    },
  },
  purchasedHoursPopper: {
    opacity: 1,
  },
  buttonArea: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  userInfo: {
    fontFamily: 'VerlagBook',
    marginTop: CONSTS.USER_INFO_MARGIN_TOP_LG,
    fontSize: CONSTS.USER_INFO_FONT_SIZE,
    [theme.breakpoints.down('xs')]: {
      marginTop: CONSTS.USER_INFO_MARGIN_TOP_XS,
      marginBottom: 10,
    },
  },
  helpIcon: {
    fill: colors.black,
    height: '16px',
    width: '16px',
    transform: 'translate(0px, 3px)',
  },
  amenitiesLabel: {
    fontSize: CONSTS.AMEN_BUTTON_FONT_SIZE,
    display: 'flex',
    flexWrap: 'nowrap',
    paddingLeft: 8,
    paddingRight: 8,
    paddingTop: 3,
  },
  seatsLabel: {
    fontSize: CONSTS.SEAT_BUTTON_FONT_SIZE,
  },
  floorsLabel: {
    fontSize: CONSTS.FLOOR_BUTTON_FONT_SIZE,
  },
  title: {
    [theme.breakpoints.up('sm')]: {
      fontFamily: 'VerlagBold',
      fontSize: CONSTS.TITLE_FONT_SIZE_LG,
      marginTop: 30,
    },
    [theme.breakpoints.down('sm')]: {
      fontFamily: 'VerlagBold',
      fontSize: CONSTS.TITLE_FONT_SIZE_XS,
      marginTop: 20,
    },
  },
  amensAndSeats: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    // paddingBottom: 15,
    paddingBottom: 10,
    [theme.breakpoints.down(breakpoints.MOBILE)]: {
      backgroundColor: colors.white,
      flexDirection: 'column',
      paddingLeft: 0,
      paddingRight: 0,
    },
    backgroundColor: colors.palette.secondary2.light,
    paddingLeft: 10,
    paddingRight: 10,
    marginTop: 10,
    marginBottom: 15,
  },
  dateAndLoc: {
    [theme.breakpoints.up('sm')]: {
      marginRight: CONSTS.DATE_LOC_MARGIN_RIGHT,
      marginTop: 30,
    },
    display: 'flex',
    flexDirection: 'row',
    // justifyContent: 'space-between',
    flexWrap: 'wrap',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      width: '100%',
    },
  },
  dayPicker: {
    marginTop: 5,
  },
  expandMoreIcon: {
    top: 'calc(50%)',
    right: 0,
    position: `absolute`,
    cursor: 'pointer',
    width: 15,
    height: 10,
    background: 'white',
  },
  lastAmen: {
    marginRight: 0,
  },
  datePicker: {
    paddingTop: 10,
  },
  noAmenitiesLabel: {
    fontFamily: 'VerlagBook',
    paddingTop: 8,
  },
  noFloorsLabel: {
    fontFamily: 'VerlagBook',
    paddingTop: 8,
  },
  errorMessage: {
    fontFamily: 'VerlagBold',
    fontSize: 13,
    border: `solid 2px ${colors.palette.tertiary1.main}`,
    color: colors.palette.tertiary1.main,
    paddingTop: 8,
    paddingBottom: 8,
    paddingLeft: 10,
    paddingRight: 10,
    marginTop: 10,
    borderRadius: 2,
    [theme.breakpoints.up(breakpoints.MOBILE)]: {
      fontSize: 18,
      paddingLeft: 15,
      paddingRight: 15,
      paddingTop: 5,
      paddingBottom: 5,
      marginTop: 0,
      marginBottom: 10,
      borderRadius: 3,
    },
  },

  dropdownRoot: {
    [theme.breakpoints.up('sm')]: {
      width: 200,
      paddingRight: 40,
    },
    position: 'relative',
    marginTop: 25,
  },
  dropdownRootLocation: {
    [theme.breakpoints.up('sm')]: {
      width: 300,
    },
  },
  dropdownRootRooms: {
    [theme.breakpoints.up('sm')]: {
      width: '250px',
    },
  },
  filtersLabel: {
    fontFamily: 'VerlagBook',
    fontSize: CONSTS.TEXT_TITLE_LABEL_FONT_SIZE,
    width: CONSTS.TEXT_LABEL_WIDTH,
    paddingTop: 15,
    [theme.breakpoints.down(breakpoints.SM)]: {
      paddingTop: CONSTS.TEXT_LABEL_PADDING_TOP_SM,
    },
  },
  seatsContainer: {
    marginRight: CONSTS.AMENITIES_MARGIN_LEFT,
  },
  filterArrow: {
    backgroundColor: colors.palette.secondary2.light,
    width: 41,
    height: 21,
    position: 'absolute',
    right: 30,
    top: 0,
    WebkitTransform: 'rotate(45deg)',
  },
  showFiltersContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    paddingBottom: 13,
    [theme.breakpoints.down(breakpoints.MOBILE)]: {
      flexDirection: 'row-reverse',
      width: '100%',
      justifyContent: 'space-between',
      display: 'block',
      paddingBottom: 0,
    },
  },
  allFiltersContainer: {
    width: '100%',
    position: 'relative',
    [theme.breakpoints.up(breakpoints.MOBILE)]: {
      marginTop: -5,
    },
  },
  filtersContainer: {
    display: 'flex',
  },
  clearAllFiltersMobile: {
    paddingBottom: 0,
    [theme.breakpoints.down(breakpoints.MOBILE)]: {
      paddingTop: 10,
    },
  },
  clearAllFiltersMainContainer: {
    position: 'absolute',
    right: 10,
    top: 8,
  },
  filterPillsContainer: {
    marginTop: 10,
    [theme.breakpoints.up(breakpoints.MOBILE)]: {
      marginLeft: 'auto',
    },
  },
  filterGroup: {
    [theme.breakpoints.up(breakpoints.MOBILE)]: {
      paddingRight: 30,
    },
  },
  collapseWrapperInner: {
    paddingTop: 5,
  },
  collapseContainer: {
    marginTop: -5,
  },
  balanceLoader: {
    width: 100,
    height: 15,
  },
});

export default styles;
