import Components from '../../Components';
import { HomeRoute } from '../../Core/constants';

const { containerTypes } = Components.Routes;

const RouteConfig = {
  path: HomeRoute,
  exact: true,
  name: 'navigation.menu.home',
  private: true,
  type: containerTypes.SUBCONTENT,
  grouping: 'navigation.grouping.home',
  shortPathName: 'navigation.menu.home',
  requiresMemberPortalAccess: true,
};

export default RouteConfig;
