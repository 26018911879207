import Components from '../../Components';

const { containerTypes, constants } = Components.Routes;

const RouteConfig = {
  path: '/home/member-benefits',
  exact: true,
  name: 'navigation.menu.perks',
  private: true,
  type: containerTypes.SUBCONTENT,
  position: constants.RIGHT,
  requiresMemberPortalAccess: true,
};

export default RouteConfig;
