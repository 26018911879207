import * as React from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import Lodash from 'lodash';

import TextButton from '../TextButton';
import { breakpoints, colors } from '../../../Core/Theme';

const VARIANTS = {
  primary: {
    color: colors.palette.tertiary2.main,
    textColor: colors.palette.tertiary2.main,
  },
  secondary: {
    color: colors.palette.primary.main,
    textColor: colors.palette.primary.main,
  },
  tertiary: {
    color: colors.palette.secondary.main,
    textColor: colors.palette.secondary.main,
  },
  error: { color: colors.alertBackground, textColor: colors.alertMessage },
};

const DEFAULT_COLOR = colors.palette.tertiary2.main;

const InfoBanner = props => {
  const {
    classes,
    text,
    text2,
    actionText,
    actionText2,
    onAction,
    onAction2,
    className,
    variant,
    theme,
    scrollOnMount,
  } = props;

  React.useEffect(() => {
    if (scrollOnMount) {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth',
      });
    }
  }, []);

  let borderColor;
  let backgroundColor;
  let textColor;
  let fontFamily;

  if (theme === 'light') {
    backgroundColor = 'white';
    borderColor = Lodash.get(VARIANTS, [variant, 'color'], DEFAULT_COLOR);
    textColor = Lodash.get(VARIANTS, [variant, 'textColor'], DEFAULT_COLOR);
    fontFamily = 'VerlagBold';
  } else {
    backgroundColor = Lodash.get(VARIANTS, [variant, 'color'], DEFAULT_COLOR);
    borderColor = Lodash.get(VARIANTS, [variant, 'color'], DEFAULT_COLOR);
    textColor = colors.black;
    fontFamily = 'VerlagBook';
  }

  return (
    <div
      className={clsx(classes.main, className)}
      style={{
        backgroundColor,
        borderColor: borderColor || backgroundColor,
        color: textColor,
        fontFamily,
      }}
    >
      {text}{' '}
      {actionText && (
        <TextButton
          text={actionText}
          onClick={onAction}
          className={classes.actionButton}
        />
      )}{' '}
      {text2}{' '}
      {actionText2 && (
        <TextButton
          text={actionText2}
          onClick={onAction2}
          className={classes.actionButton}
        />
      )}
    </div>
  );
};

const styles = theme => ({
  main: {
    borderRadius: 3,
    width: '100%',
    paddingLeft: 10,
    paddingRight: 10,
    paddingTop: 8,
    paddingBottom: 8,
    fontSize: 15,
    border: `solid 1.5px`,
    [theme.breakpoints.up(breakpoints.MOBILE)]: {
      paddingLeft: 15,
      paddingRight: 15,
      fontSize: 18,
      paddingTop: 11,
      paddingBottom: 11,
    },
  },
  actionButton: {
    color: 'black',
    fontSize: 15,
    borderBottom: 'solid 1px black',
    padding: '0 0 0 0',
    lineHeight: '13px',
    fontFamily: 'VerlagBold',
    [theme.breakpoints.up(breakpoints.MOBILE)]: {
      fontSize: 18,
      lineHeight: '16px',
    },
  },
});

InfoBanner.defaultProps = {
  className: '',
  actionText: '',
  actionText2: '',
  text2: '',
  onAction: () => {},
  onAction2: () => {},
  variant: 'primary',
  theme: 'dark',
  scrollOnMount: false,
};

InfoBanner.propTypes = {
  classes: PropTypes.object.isRequired,
  text: PropTypes.string.isRequired,
  text2: PropTypes.string,
  onAction: PropTypes.func,
  onAction2: PropTypes.func,
  className: PropTypes.string,
  actionText: PropTypes.string,
  actionText2: PropTypes.string,
  variant: PropTypes.string,
  theme: PropTypes.string,
  scrollOnMount: PropTypes.bool,
};

export default withStyles(styles)(InfoBanner);
