import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import MaterialPanel from '@material-ui/core/ExpansionPanel';
import clsx from 'clsx';

import ExpansionImage from './ExpansionImage';
import { colors } from '../../../Core/Theme';
import { Icons } from '../../../resources';

function ExpansionPanel(props) {
  const {
    classes,
    title,
    subtitle,
    children,
    expanded,
    noSummary,
    rootClass,
    withLeftIcon,
    classNamePanel,
    renderSummarySubtitle,
    classNameTitle,
    defaultExpanded,
    expandedIcon,
    expandIcon,
    onChange,
    callout,
    expandedClassName,
  } = props;
  const [isExpanded, setIsExpanded] = React.useState(null);

  const handleOnChangeExpanded = (_, expandedState) => {
    if (onChange) onChange(expandedState);
    return setIsExpanded(expandedState);
  };

  const renderLeftIcon = () => {
    return isExpanded
      ? expandedIcon || <Icons.ExpandedIcon className={classes.icon} />
      : expandIcon || <Icons.ExpandIcon className={classes.icon} />;
  };
  const renderExpansionSummary = () => {
    return (
      <ExpansionPanelSummary
        expandIcon={withLeftIcon ? null : <ExpansionImage />}
        classes={{ expanded: expandedClassName }}
      >
        {withLeftIcon ? renderLeftIcon() : null}
        <div
          className={clsx(withLeftIcon && classes.summaryContentWithLeftIcon)}
        >
          {callout && <div className={clsx(classes.callout)}>{callout}</div>}
          {title && (
            <div className={clsx(classes.title, classNameTitle)}>{title}</div>
          )}
          {renderSummarySubtitle
            ? renderSummarySubtitle()
            : subtitle && <div className={classes.subtitle}>{subtitle}</div>}
        </div>
      </ExpansionPanelSummary>
    );
  };
  return (
    <div className={classes.root}>
      <MaterialPanel
        expanded={expanded}
        defaultExpanded={defaultExpanded}
        className={clsx(classes.panel, classNamePanel)}
        onChange={handleOnChangeExpanded}
      >
        {noSummary ? null : renderExpansionSummary()}
        <ExpansionPanelDetails
          classes={{
            root: clsx(withLeftIcon && classes.withLeftIcon, rootClass),
          }}
        >
          {children}
        </ExpansionPanelDetails>
      </MaterialPanel>
    </div>
  );
}

const styles = () => ({
  summaryContentWithLeftIcon: {
    paddingLeft: 12,
  },
  icon: {
    width: 25,
    height: 25,
  },
  withLeftIcon: {
    paddingLeft: 61,
  },
  root: {
    width: '100%',
  },
  panel: {
    boxShadow: 'none',
  },
  title: {
    fontFamily: 'VerlagBold',
    fontSize: '20px',
    color: colors.black,
  },
  subtitle: {
    fontFamily: 'VerlagBook',
    fontSize: '16px',
    color: colors.darkest,
    marginTop: '10px',
  },
  callout: {
    position: 'absolute',
    color: colors.palette.tertiary1.main,
    fontFamily: 'VerlagBold',
    fontSize: 15,
    right: 20,
    top: 10,
  },
});

ExpansionPanel.defaultProps = {
  subtitle: null,
  title: null,
  noSummary: false,
  rootClass: '',
  expanded: null,
  defaultExpanded: true,
  withLeftIcon: null,
  classNamePanel: null,
  renderSummarySubtitle: null,
  classNameTitle: null,
  expandedIcon: null,
  expandIcon: null,
  onChange: null,
  callout: null,
  expandedClassName: '',
};

ExpansionPanel.propTypes = {
  classes: PropTypes.object.isRequired,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  children: PropTypes.any.isRequired,
  noSummary: PropTypes.bool,
  rootClass: PropTypes.string,
  expanded: PropTypes.bool,
  defaultExpanded: PropTypes.bool,
  withLeftIcon: PropTypes.bool,
  classNamePanel: PropTypes.string,
  renderSummarySubtitle: PropTypes.func,
  onChange: PropTypes.func,
  classNameTitle: PropTypes.string,
  expandedIcon: PropTypes.any,
  expandIcon: PropTypes.any,
  callout: PropTypes.string,
  expandedClassName: PropTypes.string,
};

export default withStyles(styles)(ExpansionPanel);
