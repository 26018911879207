import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import CheckIcon from '@material-ui/icons/Check';

import { colors, breakpoints } from '../../../Core/Theme';
import { Icons } from '../../../resources';
import TextButton from '../TextButton';
import Drawer from '../Drawer';
import LocationText from '../LocationText';
import ListWithGroups from '../ListWithGroups';
import { getStaticImageUrl } from '../../../Core/Utils';
import { trackLocationDropdownClick } from '../../../Core/Tracking';

const BaseLocationListDrawer = props => {
  const {
    classes,
    onChange,
    isOpen,
    onCloseDrawer,
    withHeader,
    titleKey,
    onReturnHome,
    shouldRenderReturnHome,
    options,
    value,
    getIsItemSelected,
    gaCategory,
    disabledTooltipText,
  } = props;
  const { t } = useTranslation();

  const selectWrapped = info => {
    onChange(info.value);
    if (gaCategory) {
      trackLocationDropdownClick(info?.value?.label, gaCategory);
    }
    setTimeout(() => onCloseDrawer(), 100);
  };

  const renderViewHomeLocation = () => {
    return (
      <TextButton
        text={t('locationEditor.home_cta')}
        onClick={onReturnHome}
        className={classes.homeButton}
      />
    );
  };

  const renderItem = l => {
    const { description, isDisabled } = l;
    const { state, address, zipCode, city } = description;
    const addressString = `${address}, ${city} ${state}, ${zipCode}`;
    const isSelected = getIsItemSelected(l);
    return (
      <>
        <div
          className={clsx(
            isDisabled && classes.disabledItem,
            classes.itemWrapper
          )}
        >
          <img
            src={getStaticImageUrl('2020-08/yellowLocationPin.svg')}
            className={classes.locationPin}
            alt={t('altTexts.location_pin')}
          />
          <div>
            <div className={classes.itemName}>{l.label}</div>
            <div className={classes.itemAddress}>{addressString}</div>
            {isSelected && <CheckIcon className={classes.checkIcon} />}
          </div>
        </div>
        {isDisabled && <Icons.ClosedLock className={classes.closedLock} />}
      </>
    );
  };

  return (
    <Drawer
      withCloseButton
      isOpenDrawer={isOpen}
      className="drawerPaperSmall"
      onCloseDrawer={onCloseDrawer}
      onCloseButton={onCloseDrawer}
      closeButtonClassName={clsx(
        classes.close,
        withHeader && classes.lightClose
      )}
      noAnimation
    >
      <div className={clsx(classes.wrapper, !withHeader && classes.topPadding)}>
        {withHeader && (
          <div className={classes.header}>
            <div className={classes.label}>
              {t('locationEditor.current_loc').toUpperCase()}
            </div>
            <div className={classes.currentLoc}>
              <LocationText
                selectedGrouping={value}
                variant="white"
                rootClasses={{
                  text: classes.locationName,
                  rootLeftIcon: classes.locationIcon,
                }}
              />
              {shouldRenderReturnHome ? renderViewHomeLocation() : null}
            </div>
          </div>
        )}
        <ListWithGroups
          options={options}
          withSearch
          searchLabel={t(titleKey)}
          renderItem={renderItem}
          onClick={selectWrapped}
          searchPlaceholder={t('locationEditor.search_placeholder')}
          listClassName={clsx(
            classes.locationList,
            !withHeader && classes.longLocationList
          )}
          noResultsText={t('general.no_results')}
          disabledTooltipText={disabledTooltipText}
        />
      </div>
    </Drawer>
  );
};

const styles = theme => ({
  wrapper: {
    paddingLeft: 20,
    paddingRight: 20,
    height: '100vh',
    overflow: 'hidden',
    [theme.breakpoints.up(breakpoints.MOBILE)]: {
      paddingLeft: 40,
      paddingRight: 40,
      height: 'auto',
    },
  },
  header: {
    background: colors.palette.primary.main,
    color: colors.white,
    padding: 20,
    paddingTop: 20,
    paddingBottom: 10,
    marginLeft: -20,
    marginRight: -20,
    [theme.breakpoints.up(breakpoints.MOBILE)]: {
      marginLeft: -40,
      marginRight: -40,
      paddingLeft: 40,
      paddingRight: 40,
    },
  },
  label: {
    fontFamily: 'VerlagBold',
    fontSize: 12,
  },
  currentLoc: {
    fontFamily: 'VerlagLight',
    color: colors.white,
    fontSize: 16,
    paddingTop: 10,
    display: 'flex',
  },
  homeButton: {
    color: 'white',
    fontSize: 12,
    marginLeft: 'auto',
    paddingRight: 0,
    fontFamily: 'VerlagLight',
  },
  close: {
    boxShadow: 'none',
    top: 5,
    right: 5,
    '&:hover': {
      background: 'none',
      boxShadow: 'none',
    },
  },
  lightClose: {
    filter: 'invert(1)',
    background: 'transparent',
  },
  locationList: {
    height: 'calc(100vh - 196px)',
    overflowY: 'scroll',
    marginLeft: -20,
    marginRight: -20,
    '-ms-overflow-style': 'none',
    '&::-webkit-scrollbar': {
      width: 0 /* Remove scrollbar space */,
      background: 'transparent' /* Optional: just make scrollbar invisible */,
    },
    paddingBottom: 15,
    marginTop: 20,
    [theme.breakpoints.up(breakpoints.MOBILE)]: {
      marginLeft: -40,
      marginRight: -40,
    },
  },
  longLocationList: {
    height: 'calc(100vh - 250px) !important',
    paddingBottom: 105,
  },
  locationName: {
    fontFamily: 'VerlagLight',
    lineHeight: '16px',
  },
  locationIcon: {
    height: 15,
    width: 15,
    marginTop: -5,
  },
  topPadding: {
    paddingTop: 20,
    height: 'calc(100vh - 20px)',
    overflow: 'hidden',
    [theme.breakpoints.up(breakpoints.MOBILE)]: {
      height: 'auto',
    },
  },
  itemWrapper: {
    display: 'flex',
  },
  itemName: {
    fontFamily: 'VerlagBook',
    fontSize: 14,
  },
  itemAddress: {
    fontFamily: 'VerlagLight',
    fontSize: 12,
    paddingTop: 1,
  },
  locationPin: {
    marginRight: 8,
  },
  checkIcon: {
    top: 14,
    right: 10,
    position: 'absolute',
    height: 19,
  },
  disabledItem: {
    opacity: 0.5,
  },
  closedLock: {
    top: 10,
    right: 10,
    position: 'absolute',
  },
});

BaseLocationListDrawer.defaultProps = {
  withHeader: true,
  shouldRenderReturnHome: false,
  onReturnHome: () => {},
  getIsItemSelected: () => false,
  gaCategory: null,
  disabledTooltipText: null,
};

BaseLocationListDrawer.propTypes = {
  classes: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onCloseDrawer: PropTypes.func.isRequired,
  withHeader: PropTypes.bool,
  titleKey: PropTypes.string.isRequired,
  onReturnHome: PropTypes.func,
  shouldRenderReturnHome: PropTypes.bool,
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
  value: PropTypes.object.isRequired,
  getIsItemSelected: PropTypes.func,
  gaCategory: PropTypes.string,
  disabledTooltipText: PropTypes.string,
};

export default withStyles(styles)(BaseLocationListDrawer);
