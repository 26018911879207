/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import * as React from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Lodash from 'lodash';
import Moment from 'moment';
import clsx from 'clsx';
import Draggable from 'react-draggable';
import useInterval from '@use-it/interval';
import { useTranslation } from 'react-i18next';

import { colors } from '../../Core/Theme';
import ReservationSlot from './ReservationSlot';
import { timeOverlapsBooking } from '../../Containers/MeetingRoomBooking/utils';
import { timeToPixelOffset } from './util';
import { busyHalfHour } from '../../resources';
import { getIsMobile } from '../../Core/Utils/windowDimensions';
import { date as DateUtils } from '../../Core/Utils';
import {
  TIME_LABEL_WIDTH,
  INTERVAL_HEIGHT,
  SEGMENT_WIDTH,
  DELAY_TIME_INDICATOR,
} from './constants';
import TimeLabel from './TimeLabel';
import ReservationSlotWithToolTip from './ReservationSlotWithTooltip';
import { getWithinBookingHours } from '../../Core/Utils/userPermissions';

const getCurrentTimeIndicator = (
  classes,
  interval,
  startEpoch,
  withTimeline,
  now
) => {
  return (
    <div
      className={classes.nowIndicator}
      style={{
        top: timeToPixelOffset(interval, startEpoch, now),
        left: withTimeline ? TIME_LABEL_WIDTH : 0,
      }}
    />
  );
};

const clickWrapped = (onClick, args, isLoading) => () => {
  if (isLoading) {
    return;
  }
  onClick(args);
};

const getBookingStyle = (
  booking,
  columnData,
  interval,
  startEpoch,
  isDraggable,
  isBeingDragged
) => {
  const startBooking = Lodash.get(booking, 'startEpoch', 0);
  const { columnKey } = booking;
  const columnIndex = Lodash.findIndex(columnData, { key: columnKey });
  if (columnIndex < 0) {
    return { display: 'none' };
  }
  let cursor = 'auto';
  if (isDraggable) {
    cursor = 'grab';
  }
  if (isBeingDragged) {
    cursor = 'grabbing';
  }
  return {
    top: timeToPixelOffset(interval, startEpoch, startBooking) + 1,
    left: columnIndex * (SEGMENT_WIDTH + 1),
    cursor,
    zIndex: isBeingDragged ? 3 : 2,
  };
};

const updateBookingForPosition = (
  booking,
  position,
  interval,
  startDay,
  columnData
) => {
  const { x, y } = position;
  const { startEpoch, columnKey, endEpoch } = booking;
  const bookingDuration = endEpoch - startEpoch;
  const updatedStart =
    startDay +
    ((y + timeToPixelOffset(interval, startDay, startEpoch)) /
      INTERVAL_HEIGHT) *
      interval; // this is correct
  const columnIndex = Lodash.findIndex(columnData, { key: columnKey });
  const updatedColumn = Lodash.get(
    columnData,
    x / (SEGMENT_WIDTH + 1) + columnIndex,
    {}
  );
  if (!updatedColumn) {
    return booking;
  }
  return {
    ...booking,
    columnKey: updatedColumn.key,
    startEpoch: updatedStart,
    endEpoch: updatedStart + bookingDuration,
  };
};

const onDrag =
  (setDragging, b, interval, startDay, columnData) => (e, position) => {
    e.stopPropagation();
    const updatedBooking = updateBookingForPosition(
      b,
      position,
      interval,
      startDay,
      columnData
    );
    setDragging(updatedBooking);
  };

const onStop =
  (
    onDrop,
    onClick,
    b,
    bookingDragging,
    setDragging,
    dragOverlap,
    setDropError
  ) =>
  () => {
    if (!Lodash.isEmpty(bookingDragging)) {
      onDrop(b, bookingDragging, setDragging, dragOverlap, setDropError);
    } else {
      onClick(b);
    }
  };

const getDragOverlap = (bookingDragging, bookingsData) => {
  const bookingDraggingStart = Lodash.get(bookingDragging, 'startEpoch', 0);
  const bookingDraggingEnd = Lodash.get(bookingDragging, 'endEpoch', 0);
  const bookingDraggingId = Lodash.get(bookingDragging, 'idBooking', null);
  const bookingDraggingColumn = Lodash.get(bookingDragging, 'columnKey', '');
  if (bookingDraggingId) {
    const bookingsForRoom = bookingsData.filter(
      b => b.columnKey === bookingDraggingColumn
    );
    const overlapValues = timeOverlapsBooking(
      bookingsForRoom,
      bookingDraggingId,
      bookingDraggingStart,
      bookingDraggingEnd
    );
    const dragOverlap = overlapValues.startOverlap || overlapValues.endOverlap;
    return dragOverlap;
  }
  return false;
};

const getBookingsDataWithoutDraft = (listBookings, objBookingDraft) => {
  if (Lodash.isEmpty(objBookingDraft)) {
    return listBookings;
  }

  return Lodash.filter(
    listBookings,
    objBooking =>
      objBooking.idBooking !== Lodash.get(objBookingDraft, ['idBooking'], null)
  );
};

const Calendar = props => {
  const {
    startEpoch,
    endEpoch,
    interval,
    classes,
    columnData,
    renderHeader,
    bookingsData,
    withTimeline,
    onClick,
    withCurrentTimeIndicator,
    canCreateBookingAt,
    draftBooking,
    initialScrollTime,
    withVariableWidth,
    heightClass,
    topBorderClass,
    tz,
    onDrop,
    isError,
    errorMessage,
    withHover,
    className,
    withFullCalendarHover,
    isDragEnabled,
    isLoading,
    numLoadingCols,
    timeZoneId,
    mustRespectBusinessHours,
  } = props;

  const { t } = useTranslation();
  const [bookingDragging, setDragging] = React.useState({});
  const [dropError, setDropError] = React.useState(false);
  const [hasSetScroll, setHasSetScroll] = React.useState(false);
  const [isMobile, setIsMobile] = React.useState(false);
  const [now, setNow] = React.useState(Moment().unix());

  const timeZoneToUse = timeZoneId ?? DateUtils.getCurrentZone();

  const internalScrollRef = React.createRef();
  const duration = endEpoch - startEpoch;
  const timeIntervals = [...Array(duration / interval).keys()].map(
    i => i * interval + startEpoch
  );

  useInterval(() => {
    setNow(Moment().unix());
  }, DELAY_TIME_INDICATOR);

  React.useEffect(() => {
    const mobile = getIsMobile();
    setIsMobile(mobile);
  }, []);

  React.useEffect(() => {
    if (internalScrollRef.current && !hasSetScroll) {
      internalScrollRef.current.scrollTop = Math.max(
        0,
        timeToPixelOffset(interval, startEpoch, initialScrollTime)
      );
      setHasSetScroll(true);
    }
  }, [internalScrollRef]);

  React.useEffect(() => {
    if (dropError) {
      setDropError(false);
    }
  }, [dropError]);

  const dragOverlap = getDragOverlap(bookingDragging, bookingsData);

  if (!columnData.length && !isLoading) {
    return null;
  }

  const showIndicator = now <= endEpoch && now >= startEpoch;
  const bookingsDataWithoutDraft = getBookingsDataWithoutDraft(
    bookingsData,
    draftBooking
  );
  const displayBookings = !isError && !isLoading;

  const loadingColumns = [...Array(numLoadingCols).keys()].map(a => {
    return {
      key: a,
    };
  });
  const columns = !isLoading ? columnData : loadingColumns;

  const getIsOutsideBusinessHours = (start, end) => {
    if (!mustRespectBusinessHours) return false;
    return !getWithinBookingHours(start, end, timeZoneToUse);
  };

  return (
    <div
      className={clsx(classes.main && className)}
      style={
        withVariableWidth
          ? { width: '100%' }
          : {
              width:
                (SEGMENT_WIDTH + 1) * columns.length +
                (withTimeline ? TIME_LABEL_WIDTH : 0),
            }
      }
    >
      <div
        className={classes.error}
        style={
          isError
            ? { left: withTimeline ? TIME_LABEL_WIDTH : 0 }
            : { display: 'none' }
        }
      />
      {isError && errorMessage ? (
        <div
          className={classes.errorMessage}
          style={{
            width: withTimeline ? `calc(80% - ${TIME_LABEL_WIDTH}px` : '80%',
          }}
        >
          {errorMessage}
        </div>
      ) : null}

      <div
        className={clsx(classes.header, topBorderClass)}
        style={withTimeline ? { marginLeft: TIME_LABEL_WIDTH } : {}}
      >
        {withTimeline && tz ? <div className={classes.tz}>{tz}</div> : null}
        {columns.map((col, idx) => {
          if (!col) {
            return null;
          }
          const isLast = idx === columns.length - 1;
          return (
            <div
              className={clsx(classes.headerColumn)}
              key={`calHeader${col.key}`}
            >
              {renderHeader(col, isLast, isLoading)}
            </div>
          );
        })}
      </div>
      <div className={clsx(classes.body, heightClass)} ref={internalScrollRef}>
        {withCurrentTimeIndicator && !isError && showIndicator
          ? getCurrentTimeIndicator(
              classes,
              interval,
              startEpoch,
              withTimeline,
              now
            )
          : null}
        {withTimeline ? (
          <div>
            <TimeLabel
              classes={classes}
              timeIntervals={timeIntervals}
              interval={interval}
              timeZoneId={timeZoneToUse}
            />
          </div>
        ) : null}
        <div className={classes.schedule}>
          {columns.map(col => {
            if (!col) {
              return null;
            }
            const { key } = col;
            return (
              <div
                className={clsx(classes.bodyColumn)}
                style={
                  withVariableWidth
                    ? {
                        width: '-moz-available', // chrome can't understand this so will use the width in the styles section
                      }
                    : { width: SEGMENT_WIDTH }
                }
                key={`bodyColumn${key}`}
              >
                <>
                  {withFullCalendarHover && (
                    <div
                      className={classes.bodyColumnHover}
                      onClick={clickWrapped(onClick, col, isLoading)}
                    />
                  )}

                  {timeIntervals.map(time => {
                    const canCreateAtTop =
                      Lodash.isEmpty(bookingDragging) &&
                      canCreateBookingAt({
                        spaceId: key,
                        startEpoch: time,
                        endEpoch: time + interval / 2,
                      });

                    const canCreateAtBottom =
                      Lodash.isEmpty(bookingDragging) &&
                      canCreateBookingAt({
                        spaceId: key,
                        startEpoch: time + interval / 2,
                        endEpoch: time + interval,
                      });
                    const isTopOutsideBusinessHours =
                      !canCreateAtTop &&
                      getIsOutsideBusinessHours(time, time + interval / 2);
                    const isBottomOutsideBusinessHours =
                      !canCreateAtBottom &&
                      getIsOutsideBusinessHours(
                        time + interval / 2,
                        time + interval
                      );
                    return (
                      <div
                        className={classes.interval}
                        key={`calendarInt${key}${time}`}
                      >
                        {isTopOutsideBusinessHours && (
                          <ReservationSlotWithToolTip
                            spaceId={key}
                            time={time}
                            interval={interval}
                            message={t('Calendar.outsideHours')}
                          />
                        )}
                        {isBottomOutsideBusinessHours && (
                          <ReservationSlotWithToolTip
                            spaceId={key}
                            time={time + interval / 2}
                            interval={interval}
                            message={t('Calendar.outsideHours')}
                          />
                        )}
                        <div className={classes.intervalDivider}> </div>
                        <div className={classes.halfIntervalDivider}> </div>
                        <div
                          className={clsx(
                            classes.halfIntervalSegmentTop,
                            withHover &&
                              canCreateAtTop &&
                              classes.halfIntervalWithHover
                          )}
                          onClick={clickWrapped(
                            onClick,
                            {
                              spaceId: key,
                              time,
                            },
                            isLoading
                          )}
                          datatestid={`calendar-segment-${Moment.unix(
                            time
                          ).format('H:mm')}`}
                        />
                        <div
                          className={clsx(
                            classes.halfIntervalSegment,
                            withHover &&
                              canCreateAtBottom &&
                              classes.halfIntervalWithHover
                          )}
                          onClick={clickWrapped(
                            onClick,
                            {
                              spaceId: key,
                              time: time + interval / 2,
                            },
                            isLoading
                          )}
                          datatestid={`calendar-segment-${Moment.unix(
                            time + interval / 2
                          ).format('H:mm')}`}
                        />
                      </div>
                    );
                  })}
                </>
              </div>
            );
          })}

          {isError && <div className={clsx(classes.unavailable)} />}
          {displayBookings &&
            bookingsDataWithoutDraft.map(b => {
              const { canDrag } = b;
              const canDragBooking = canDrag && !isMobile;
              const isBeingDragged = b.idBooking === bookingDragging.idBooking;
              return (
                <Draggable
                  grid={[SEGMENT_WIDTH + 1, INTERVAL_HEIGHT / 2]}
                  bounds="parent"
                  onStop={onStop(
                    onDrop,
                    onClick,
                    b,
                    bookingDragging,
                    setDragging,
                    dragOverlap,
                    setDropError
                  )}
                  onStart={canDragBooking ? () => true : () => false}
                  onDrag={onDrag(
                    setDragging,
                    b,
                    interval,
                    Moment.unix(startEpoch).startOf('day'),
                    columns
                  )}
                  key={`calBooking${b.idBooking}${b.startEpoch}${b.columnKey}${dropError}`}
                  position={(0, 0)}
                >
                  <div
                    className={
                      withVariableWidth
                        ? classes.bookingVarWidth
                        : classes.booking
                    }
                    style={getBookingStyle(
                      b,
                      columns,
                      interval,
                      startEpoch,
                      canDragBooking,
                      isBeingDragged
                    )}
                  >
                    <ReservationSlot
                      booking={b}
                      interval={interval}
                      onClick={
                        isMobile || !isDragEnabled || !canDragBooking
                          ? clickWrapped(onClick, b, isLoading)
                          : () => null
                      }
                    />
                  </div>
                </Draggable>
              );
            })}
          {!Lodash.isEmpty(draftBooking) ? (
            <div
              className={
                withVariableWidth ? classes.bookingVarWidth : classes.booking
              }
              style={getBookingStyle(
                draftBooking,
                columns,
                interval,
                startEpoch,
                false
              )}
            >
              <ReservationSlot
                booking={draftBooking}
                interval={interval}
                onClick={clickWrapped(onClick, draftBooking, isLoading)}
                isDraft
              />
            </div>
          ) : null}
        </div>
      </div>
      <div
        className={classes.borderBottom}
        style={{ marginLeft: withTimeline ? TIME_LABEL_WIDTH + 1 : 1 }}
      />
    </div>
  );
};

const styles = {
  main: {
    position: 'relative',
  },
  header: {
    display: 'flex',
    borderLeft: `solid 1px ${colors.light}`,
    borderRight: `solid 1px ${colors.light}`,
    background: colors.white,
    boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.25)',
    position: 'relative',
    zIndex: 3,
    borderTop: `solid 3px ${colors.black}`,
    borderRadius: 2,
  },
  headerColumn: {
    width: SEGMENT_WIDTH + 1,
  },
  body: {
    display: 'flex',
    maxHeight: '70vh',
    overflowY: 'scroll',
    position: 'relative',
    overflowX: 'hidden',
    borderRight: `solid 1px ${colors.middle}`,
    borderRadius: 2,
    '-ms-overflow-style': 'none',
    '&::-webkit-scrollbar': {
      width: 0 /* Remove scrollbar space */,
      background: 'transparent' /* Optional: just make scrollbar invisible */,
    },
  },
  bodyColumn: {
    borderLeft: `solid 1px ${colors.light}`,
    borderBottom: `solid 1px ${colors.light}`,
    backgroundColor: colors.white,
    height: 'fit-content',
    width: '-webkit-fill-available',
    position: 'relative',
  },
  bodyColumnHover: {
    '&:hover': {
      backgroundColor: `${colors.palette.primary.main}`,
      width: '94%',
      borderRadius: 4,
      height: 'auto',
      opacity: '60%',
    },
    cursor: 'pointer',
    backgroundColor: 'transparent',
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: 2,
  },
  schedule: {
    borderLeft: `solid 1px ${colors.middle}`,
    borderRadius: 2,
    display: 'flex',
    height: 'fit-content',
    position: 'relative',
    width: '100%',
  },
  interval: {
    position: 'relative',
    width: '-webkit-fill-available',
    height: INTERVAL_HEIGHT,
  },
  halfIntervalWithHover: {
    '&:hover': {
      backgroundColor: `${colors.palette.primary.light}`,
      width: '94%',
      borderRadius: 4,
      height: 29,
    },
  },
  halfIntervalSegmentTop: {
    height: 30,
    top: 2,
    position: 'absolute',
    width: '100%',
  },
  halfIntervalSegment: {
    height: 30,
    position: 'absolute',
    top: 33,
    width: '100%',
  },
  halfIntervalDivider: {
    position: 'absolute',
    height: 1,
    backgroundColor: colors.light,
    top: 32,
    width: '100%',
    zIndex: 1,
  },
  intervalDivider: {
    position: 'absolute',
    height: 2,
    backgroundColor: colors.light,
    width: '100%',
  },
  booking: {
    position: 'absolute',
    width: 190,
    marginRight: 11, // This margin is crucial for ensuring the correct bounds for draggable bookings
    marginLeft: 1,
    zIndex: 2,
  },

  bookingVarWidth: {
    position: 'absolute',
    width: '94%',
    zIndex: 2,
  },
  timeLabel: {
    height: '100%',
    width: TIME_LABEL_WIDTH,
    marginTop: -9,
  },
  timeLabelSegment: {
    height: INTERVAL_HEIGHT,
    color: colors.black,
    fontFamily: 'VerlagLight',
    fontSize: '15px',
    width: TIME_LABEL_WIDTH,
  },
  nowIndicator: {
    borderBottom: `solid 2px ${colors.palette.secondary.main}`,
    position: 'absolute',
    width: '100%',
    zIndex: 5,
  },
  borderBottom: {
    backgroundColor: colors.middle,
    height: 1,
    bottom: 0,
    marginRight: 1,
  },
  tz: {
    position: 'absolute',
    fontFamily: 'VerlagLight',
    fontSize: 13,
    left: -50,
    bottom: 5,
  },
  unavailable: {
    backgroundImage: `url(${busyHalfHour})`,
    backgroundSize: 190,
    zIndex: 2,
    borderRadius: 4,
    position: 'absolute',
    width: 'calc(100% - 2px)', // the 2px is so the calendars right border will show
    height: '100%',
  },
  error: {
    opacity: 0.5,
    position: 'absolute',
    height: '100%',
    background: colors.black,
    zIndex: 4,
    right: 0,
  },
  errorMessage: {
    fontFamily: 'VerlagBold',
    fontSize: 20,
    color: colors.palette.tertiary1.main,
    background: colors.white,
    padding: 10,
    borderRadius: 2,
    border: `solid 1.5px ${colors.palette.tertiary1.main}`,
    position: 'absolute',
    zIndex: 5,
    top: '40%',
    left: 'calc(10% + 42.5px)',
  },
};

Calendar.defaultProps = {
  interval: 60 * 60,
  withTimeline: false,
  onClick: () => '',
  canCreateBookingAt: () => true,
  withCurrentTimeIndicator: true,
  draftBooking: {},
  initialScrollTime: 0,
  topBorderClass: '',
  heightClass: '',
  withVariableWidth: false,
  tz: '',
  onDrop: () => '',
  isError: false,
  isDragEnabled: true,
  errorMessage: '',
  withHover: false,
  className: '',
  withFullCalendarHover: false,
  isLoading: false,
  numLoadingCols: 1,
  timeZoneId: null,
  mustRespectBusinessHours: false,
};

Calendar.propTypes = {
  classes: PropTypes.object.isRequired,
  startEpoch: PropTypes.number.isRequired,
  endEpoch: PropTypes.number.isRequired,
  interval: PropTypes.number,
  columnData: PropTypes.arrayOf(PropTypes.object).isRequired,
  renderHeader: PropTypes.func.isRequired,
  bookingsData: PropTypes.arrayOf(PropTypes.object).isRequired,
  withTimeline: PropTypes.bool,
  onClick: PropTypes.func,
  canCreateBookingAt: PropTypes.func,
  withCurrentTimeIndicator: PropTypes.bool,
  isDragEnabled: PropTypes.bool,
  draftBooking: PropTypes.object,
  initialScrollTime: PropTypes.number,
  topBorderClass: PropTypes.string,
  heightClass: PropTypes.string,
  withVariableWidth: PropTypes.bool,
  tz: PropTypes.string,
  onDrop: PropTypes.func,
  isError: PropTypes.bool,
  errorMessage: PropTypes.string,
  withHover: PropTypes.bool,
  className: PropTypes.string,
  withFullCalendarHover: PropTypes.bool,
  isLoading: PropTypes.bool,
  numLoadingCols: PropTypes.number,
  timeZoneId: PropTypes.string,
  mustRespectBusinessHours: PropTypes.bool,
};

export default withStyles(styles)(Calendar);
