import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Lodash from 'lodash';
import { withStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import * as Moment from 'moment';
import useDebounce from '@react-hook/debounce';

// Components
import {
  Text,
  SmartTableWithSearchBox,
  BackButton,
  LocationEditor,
} from '../../Components/Common';

// Core
import { colors, breakpoints } from '../../Core/Theme';

// Utils
import { ACCOUNT_STATES, getColumns } from './Utils';
import {
  getSfBaseUrl,
  getStaticImageUrl,
} from '../../Core/Utils/linkGenerator';

// Constants
import { Constants } from '../../Components/Common/SmartTable';

// Api
import { fetchAccounts } from '../../Core/Api';
import { trackDeskUsage } from '../../Core/Tracking';

// Redux
import ReserveDeskRedux from '../DeskReservationLanding/Redux';
import EmptyState from '../../Components/Common/EmptyState/EmptyState';
import { S3IconStrings } from '../../resources';

const ACTIVE_ACCOUNT_DAY_LIMIT = 90;
const DEFAULT_FETCH_LIMIT = 20;
const SEARCH_DEBOUNCE_DELAY = 500;

const DeskUsage = props => {
  const { classes, selectedMonth, history, selectedGrouping } = props;
  const { t } = useTranslation();
  const [searchValue, setSearchValue] = useState(null);
  const [debouncedSearchValue, setDebouncedSearchValue] = useDebounce(
    null,
    SEARCH_DEBOUNCE_DELAY
  );
  const [accounts, setAccountData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [offset, setOffset] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [isLoadingMore, setIsLoadingMore] = useState(false);

  const [accountStateFilters, setAccountStateFilters] = useState([]);

  const selectedLocationSfId = selectedGrouping?.locationSfId;

  const fetchAccountData = (
    _offset = offset,
    setLoading = setIsLoading,
    limit = DEFAULT_FETCH_LIMIT
  ) => {
    setLoading(true);
    fetchAccounts({
      locationSfId: selectedLocationSfId,
      groupingId: selectedGrouping?.idGrouping,
      activeAfterDate: Moment()
        .subtract(ACTIVE_ACCOUNT_DAY_LIMIT, 'days')
        .format('YYYY-MM-DD'),
      limit,
      nameContains: searchValue,
      offset: _offset,
    })
      .then(objResponse => {
        const { data } = objResponse;
        if (data) {
          const { accounts: accountsData, total } = data;
          setHasMore(_offset + limit < total);
          setOffset(limit + _offset);
          setAccountData(
            _offset ? [...accounts, ...accountsData] : accountsData
          );
        }
      })
      .finally(() => setLoading(false));
  };
  useEffect(() => {
    fetchAccountData();
  }, []);

  useEffect(() => {
    if (debouncedSearchValue || !isLoading) {
      fetchAccountData(0);
    }
  }, [debouncedSearchValue]);

  const handleOnBackPress = () => {
    return history.push({
      pathname: '/home/reservableDesks',
    });
  };

  const loadMoreAccounts = () => {
    if (!isLoadingMore) {
      fetchAccountData(offset, setIsLoadingMore);
    }
  };

  const handleOnChangeSearchValue = value => {
    setDebouncedSearchValue(value);
    return setSearchValue(value);
  };

  const handleShowAccountInNewTab = objData => {
    trackDeskUsage('Account Link');
    const sfBaseUrl = getSfBaseUrl();
    return window.open(`${sfBaseUrl}${objData?.sfId}`);
  };

  const handleExportUsage = objData => {
    trackDeskUsage('Export Usage Account');
    return objData;
  };

  const handleManageUsage = objData => {
    trackDeskUsage('Manage Account');
    return history.push({
      pathname: '/home/account',
      search: `?accountSfId=${objData?.sfId}`,
    });
  };

  const handleOnActionPress = objAction => {
    const actionType = Lodash.get(objAction, ['type'], null);
    const accountData = Lodash.get(objAction, ['data'], null);
    switch (actionType) {
      case Constants.ACTIONS_COLUMNS.LINK:
        return handleShowAccountInNewTab(accountData);
      case Constants.ACTIONS_COLUMNS.EXPORT:
        return handleExportUsage(accountData);
      case Constants.ACTIONS_COLUMNS.MANAGE:
        return handleManageUsage(accountData);
      default:
        return null;
    }
  };

  const handleOnFilterPress = objAction => {
    const actionValue = Lodash.get(objAction, ['isActive'], null);
    setAccountStateFilters(actionValue);
  };

  const onClearFilters = () => {
    setAccountStateFilters([]);
  };

  const handleOnExpansionStateChange = isExpanded => {
    if (isExpanded) {
      return trackDeskUsage('Expand');
    }
    return trackDeskUsage('Collapse');
  };

  const renderNoResultsMessage = () => {
    return (
      <span className={classes.emptyMessage}>{t('general.no_results')}</span>
    );
  };
  const renderEmptyMessage = () => {
    return (
      <EmptyState
        imageSrc={getStaticImageUrl(S3IconStrings.emptyMoon1)}
        imageSrcSet={`${getStaticImageUrl(
          S3IconStrings.emptyMoon2
        )}, ${getStaticImageUrl(S3IconStrings.emptyMoon2)}`}
        imageAlt={t('altTexts.empty_moon')}
        title={t('deskUsage.table.empty.title')}
        description={t('deskUsage.table.empty.description')}
      />
    );
  };

  const getFilteredAccounts = () => {
    if (accountStateFilters?.length === 2 || !accountStateFilters?.length) {
      return accounts;
    }
    if (accountStateFilters.includes(ACCOUNT_STATES.ACTIVE)) {
      return accounts.filter(a => a.isActive);
    }
    return accounts.filter(a => !a.isActive);
  };

  return (
    <div className={classes.container}>
      <Grid container className={classes.content} spacing={8}>
        <Grid item xs={12}>
          <BackButton
            className={classes.backContainer}
            onBackPress={handleOnBackPress}
          />
        </Grid>
        <Grid item xs={12}>
          <Grid container alignItems="center">
            <Grid xs={12} lg={8}>
              <Text
                text={t('deskUsage.desk_usage')}
                className={classes.title}
              />
            </Grid>
            <Grid xs={12} lg={4} className={classes.infoContainer}>
              <LocationEditor selectedGrouping={selectedGrouping} disabled />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} className={classes.tableContainer}>
          <SmartTableWithSearchBox
            className={classes.table}
            placeholder={t('deskUsage.search_by_account')}
            onActionPress={handleOnActionPress}
            emptyMessage={renderEmptyMessage()}
            noResultsMessage={renderNoResultsMessage()}
            onChangeSearchValue={handleOnChangeSearchValue}
            textHeader={t('deskUsage.all_accounts')}
            data={getFilteredAccounts()}
            columns={getColumns(selectedMonth, classes, t)}
            withAccordion
            onExpansionStateChange={handleOnExpansionStateChange}
            isLoading={isLoading}
            onFilterPress={handleOnFilterPress}
            filterParams={{ isActive: accountStateFilters }}
            useInfiniteScroll
            hasMore={hasMore}
            loadMore={loadMoreAccounts}
            isLoadingMore={isLoadingMore}
            numLoadingRows={DEFAULT_FETCH_LIMIT}
            onClearFilters={onClearFilters}
          />
        </Grid>
      </Grid>
    </div>
  );
};

DeskUsage.defaultProps = {
  selectedMonth: null,
};
DeskUsage.propTypes = {
  selectedMonth: PropTypes.number,
  selectedGrouping: PropTypes.object.isRequired,
};

const styles = theme => ({
  accountColumn: {
    width: '18%',
  },
  teamMemberColumn: {
    width: '15%',
  },
  decisionMakersColumn: {
    width: '15%',
  },
  remainingDayPassesColumn: {
    width: '17%',
  },
  meetingRoomChargesColumn: {
    width: '21%',
  },
  optionsColumn: {
    width: '10%',
  },
  activeColumn: {
    width: '10%',
  },
  content: {
    padding: 20,
  },
  container: {
    minHeight: '100vh',
    backgroundColor: colors.palette.secondary2.main,
  },
  table: {
    height: '358px',
    overflowY: 'auto',
    backgroundColor: colors.palette.background.paper,
    [theme.breakpoints.up(breakpoints.MOBILE)]: {
      height: '548px',
    },
  },
  tableContainer: {
    marginTop: 20,
  },
  title: {
    fontSize: 24,
    fontFamily: 'VerlagBold',
    [theme.breakpoints.up(breakpoints.MOBILE)]: {
      fontSize: 36,
    },
  },
  locationTitle: {
    fontSize: 16,
    fontFamily: 'VerlagBook',
  },
  groupingTitle: {
    marginRight: 20,
    fontSize: 16,
    fontFamily: 'VerlagBook',
  },
  infoContainer: {
    marginTop: 15,
    alignItems: 'center',
    display: 'flex',
    [theme.breakpoints.up(breakpoints.MOBILE)]: {
      justifyContent: 'flex-end',
      marginTop: 0,
    },
  },
  backContainer: {
    width: 40,
  },
  emptyMessage: {
    color: colors.darkGray,
    fontFamily: 'VerlagLight',
    fontSize: 16,
    lineHeight: 0.9,
  },
  nonePurchasedLabel: {
    color: colors.darkGray,
  },
});

const mapStateToProps = state => {
  return {
    selectedGrouping: ReserveDeskRedux.selectors.getSelectedGrouping(state),
  };
};

export default connect(mapStateToProps, null)(withStyles(styles)(DeskUsage));
