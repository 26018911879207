/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import Lodash from 'lodash';
import ArrowBack from '@material-ui/icons/ArrowBackIos';
import { withStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import IconText from '../IconText';

const BackButton = props => {
  const { className, classes, history, onBackPress, label } = props;
  const { t } = useTranslation();

  const handleOnBackPress = () => {
    if (onBackPress) onBackPress();
    if (history) {
      return history.goBack();
    }
    return () => {};
  };

  return (
    <div onClick={handleOnBackPress} className={className}>
      <IconText
        leftIcon={<ArrowBack className={classes.iconLocationPin} />}
        rootClasses={{
          rootLeftIcon: classes.iconContainer,
          text: classes.backLabel,
          root: classes.root,
        }}
        text={label || Lodash.toUpper(t('general.back'))}
      />
    </div>
  );
};

BackButton.defaultProps = {
  onBackPress: null,
  label: null,
  className: '',
};
BackButton.propTypes = {
  onBackPress: PropTypes.func,
  label: PropTypes.string,
  className: PropTypes.string,
};

const styles = () => ({
  root: {
    cursor: 'pointer',
  },
  iconLocationPin: {
    fontSize: 10,
  },
  iconContainer: {
    paddingRight: 1,
  },
  backLabel: {
    fontFamily: 'VerlagBold',
    fontSize: 12,
  },
});

export default withStyles(styles)(BackButton);
