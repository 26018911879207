/* eslint-disable no-underscore-dangle */
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useEffect, useState, useMemo } from 'react';
import queryString from 'query-string';

import {
  fetchConferenceBalance,
  getAndAddBookingRestrictions,
} from '../../Containers/RoomBooking/calUtils';
import MeetingRoomBooking from '../../Containers/MeetingRoomBooking';
import userManager from '../../Redux/Common/UserManager';
import roomBookingRedux from '../../Containers/RoomBooking/Redux';
import bookingsRedux from '../../Redux/Common/Bookings';
import roomsRedux from '../../Redux/Common/Rooms';
import locationRedux from '../../Redux/Common/Locations';
import groupingRedux from '../../Redux/Common/Groupings';
import { strings as StringsUtils, date as DateUtils } from '../../Core/Utils';
import { fetchDataForGrouping } from './utils';
import { useIsLocationDateAvailable } from '../../Hooks';
import { locationRoleAccess } from '../../Core/Utils/userPermissions';

const SmartMeetingRoomBooking = props => {
  const {
    objBooking,
    isOpen,
    onClose,
    profile,
    loadBookingRestriction,
    bookingRestrictions,
    locations,
    loadRooms,
    loadBookings,
    loadQueriedDateAndLocation,
    queriedLocations,
    addFloors,
    queriedDatesAndGroupings,
    addQueriedDateAndGrouping,
    history,
    urlPathname,
    listDisplayGroupings,
    timeZoneId: propTimeZone,
    groupingId: propGroupingId,
    locationSfId: propLocationSfId,
    onSuccess,
    locationsLookup,
    selectedDate,
    ...rest
  } = props;

  const [isLoading, setIsLoading] = useState(null);
  const [bookingTimeZoneId, setBookingTimeZoneId] = useState(null);
  const [monthlyBalance, setMonthlyBalance] = useState(null);
  const [isFetchingMonthlyBalance, setIsLoadingBalance] = useState(true);
  const [currentDate, setCurrentDate] = useState();

  const timeZoneToUse = bookingTimeZoneId || propTimeZone;

  const handleUpdateUrlParams = params => {
    const search = window?.location?.search;
    const currParams = queryString.parse(search);
    const strUrlParams = StringsUtils.stringifyParams({
      queryParams: { ...currParams, ...params },
      options: { skipNull: true },
    });
    if (history) {
      history.push({
        pathname: urlPathname,
        search: strUrlParams,
      });
    }
  };

  useEffect(() => {
    if (!history || !urlPathname) {
      return;
    }
    if (isOpen && objBooking) {
      handleUpdateUrlParams({
        bookingId: objBooking.idBooking,
      });
    } else {
      handleUpdateUrlParams({ bookingId: null });
    }
  }, [isOpen]);

  useEffect(() => {
    if (!objBooking) {
      setBookingTimeZoneId(null);
      return;
    }

    const location = locationsLookup[objBooking?.space?.locationSfId];
    if (!objBooking?.space?.groupingId || !location) {
      return;
    }
    setBookingTimeZoneId(location.timeZoneId);

    fetchDataForGrouping(
      loadRooms,
      loadBookings,
      loadQueriedDateAndLocation,
      queriedLocations,
      objBooking.startEpoch,
      setIsLoading,
      getAndAddBookingRestrictions(
        loadBookingRestriction,
        profile,
        bookingRestrictions,
        locations
      ),
      addFloors,
      queriedDatesAndGroupings,
      addQueriedDateAndGrouping,
      objBooking.space.groupingId,
      location
    );
  }, [objBooking, listDisplayGroupings, locationsLookup]);

  const { isLoading: isLoadingBlackoutDates, isBlackoutDate } =
    useIsLocationDateAvailable({
      locationId: objBooking?.space?.location._id,
      date: currentDate,
      timeZoneId: objBooking?.space?.location.timeZoneId,
    });

  const hasBookingRestrictions = useMemo(() => {
    if (!objBooking?.space?.location) return false;
    return locationRoleAccess.hasBookingRestrictions({
      profile,
      obj: objBooking?.space?.location,
    });
  }, [profile, objBooking?.space?.location]);

  const isDateUnavailable = isBlackoutDate && hasBookingRestrictions;

  const getAndAddRestrictionsForBooking = date => {
    const month = DateUtils.getDateInMomentWithZone(
      date ?? objBooking.startEpoch,
      timeZoneToUse
    );
    getAndAddBookingRestrictions(month, objBooking?.space?.locationSfId);
  };

  const handleSuccess = () => {
    onSuccess();
    getAndAddRestrictionsForBooking();
  };

  const getBalanceAndRestrictionsForDate = date => {
    setCurrentDate(date);
    fetchConferenceBalance(
      date,
      profile?.accountSfId,
      setIsLoadingBalance,
      setMonthlyBalance,
      profile?.account?.location?.timeZoneId,
      timeZoneToUse
    );
    getAndAddRestrictionsForBooking(date);
  };

  useEffect(() => {
    if (isOpen) {
      getBalanceAndRestrictionsForDate(objBooking?.startEpoch ?? selectedDate);
    }
  }, [isOpen]);

  return (
    <MeetingRoomBooking
      {...rest}
      openModal={isOpen}
      onCloseModal={onClose}
      objBooking={objBooking}
      profile={profile}
      groupingId={objBooking?.space?.groupingId ?? propGroupingId}
      location={
        locationsLookup[objBooking?.space?.locationSfId ?? propLocationSfId]
      }
      isLoadingData={isLoading || isLoadingBlackoutDates}
      timeZoneId={timeZoneToUse}
      getAndAddBookingRestrictions={getAndAddBookingRestrictions(
        loadBookingRestriction,
        profile,
        bookingRestrictions,
        locations
      )}
      onSuccess={handleSuccess}
      monthlyBalance={monthlyBalance?.balance}
      isFetchingMonthlyBalance={isFetchingMonthlyBalance}
      onChangeDate={getBalanceAndRestrictionsForDate}
      isSelectedDateUnavailable={isDateUnavailable}
    />
  );
};

SmartMeetingRoomBooking.defaultProps = {
  history: null,
  urlPathname: null,
  timeZoneId: null,
  groupingId: null,
  onSuccess: () => {},
  selectedDate: null,
  locationSfId: null,
};

SmartMeetingRoomBooking.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  updateMonth: PropTypes.func.isRequired,
  objBooking: PropTypes.object.isRequired,
  profile: PropTypes.object.isRequired,
  locations: PropTypes.arrayOf(PropTypes.object).isRequired,
  loadBookingRestriction: PropTypes.func.isRequired,
  bookingRestrictions: PropTypes.object.isRequired,
  loadRooms: PropTypes.func.isRequired,
  loadBookings: PropTypes.func.isRequired,
  setLocationCalendar: PropTypes.func.isRequired,
  loadQueriedDateAndLocation: PropTypes.func.isRequired,
  queriedLocations: PropTypes.arrayOf(PropTypes.string).isRequired,
  addFloors: PropTypes.func.isRequired,
  setGrouping: PropTypes.func.isRequired,
  queriedDatesAndGroupings: PropTypes.object.isRequired,
  addQueriedDateAndGrouping: PropTypes.func.isRequired,
  lastGroupingAt: PropTypes.object.isRequired,
  setTimeZoneRoomBooking: PropTypes.func.isRequired,
  history: PropTypes.object,
  urlPathname: PropTypes.string,
  listDisplayGroupings: PropTypes.arrayOf(PropTypes.object).isRequired,
  timeZoneId: PropTypes.string,
  groupingId: PropTypes.string,
  onSuccess: PropTypes.func,
  locationsLookup: PropTypes.object.isRequired,
  selectedDate: PropTypes.number,
  locationSfId: PropTypes.string,
};

const mapStateToProps = state => {
  const { selectors: userManagerSelectors } = userManager;
  const { selectors: roomBookingSelectors } = roomBookingRedux;
  const { selectors: locationSelectors } = locationRedux;
  const { selectors: groupingSelectors } = groupingRedux;

  return {
    profile: userManagerSelectors.getUserProfile(state),

    //  room booking
    bookingRestrictions: roomBookingSelectors.getBookingRestrictions(state),
    queriedLocations: roomBookingSelectors.getQueriedDatesAndLocations(state),
    lastGroupingAt: roomBookingSelectors.getLastGroupingAt(state),
    selectedDate: roomBookingSelectors.getSelectedDate(state),

    //  location
    locations: locationSelectors.getLocations(state),
    locationsLookup: locationSelectors.getLocationsById(state),

    // Groupings
    listDisplayGroupings: groupingSelectors.getAllDisplayGroupings(state),
  };
};
//

const mapDispatchToProps = dispatch => {
  const { actions: roomBookingActions } = roomBookingRedux;

  return bindActionCreators(
    {
      loadBookingRestriction: roomBookingActions.addBookingRestriction,
      loadRooms: roomsRedux.actions.addRooms,
      addFloors: roomsRedux.actions.addFloors,
      addQueriedDateAndGrouping: roomBookingActions.addQueriedDateGrouping,
      setGrouping: roomBookingActions.setGrouping,
      loadBookings: bookingsRedux.actions.addBookings,
      setLocationCalendar: roomBookingActions.setLocation,
      loadQueriedDateAndLocation: roomBookingActions.addQueriedDateAndLocation,
      setTimeZoneRoomBooking: roomBookingActions.setTimezone,
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SmartMeetingRoomBooking);
