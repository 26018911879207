import React from 'react';

// Components
import { MapLayerWrapper as Layer } from '../../Common';
import { LayerProperties } from '../../Common/MapLayerWrapper/types';

type LabelProps = {
  layout: object;
  labelVisible: boolean;
  labelKey: string;
  properties: LayerProperties[];
  filter: any[];
};
const Label = (props: LabelProps) => {
  const { layout, labelVisible, labelKey, ...rest } = props;

  return (
    <Layer
      key={labelKey}
      {...rest}
      layout={{
        ...layout,
        visibility: labelVisible ? 'visible' : 'none',
      }}
    />
  );
};

export default React.memo(Label);
