import { createSelector } from 'reselect';
import Lodash from 'lodash';
import Moment from 'moment';
import { getProfileLocationSfId } from '../../../Redux/Common/UserManager/selectors';
import { VISITING_ERROR_TYPES } from '../constants';
import { getAllDisplayGroupings } from '../../../Redux/Common/Groupings/selectors';

export const getRoomIdFromProps = (state, props) =>
  Lodash.get(props, ['roomId'], null) ||
  Lodash.get(props, ['values', 'roomId'], null);

const getFormSelectedDate = (state, props) => {
  const varDate = Lodash.get(props, ['values', 'selectedDate'], null);
  return varDate && Moment.isMoment(varDate)
    ? varDate.startOf('day').unix()
    : null;
};

export const getStartEpoch = (state, props) =>
  Lodash.get(props, ['values', 'initialTime'], '');

export const getEndEpoch = (state, props) =>
  Lodash.get(props, ['values', 'endTime'], '');

export const getBookingId = (state, props) =>
  Lodash.get(props, ['values', 'id'], '');

const getQueriedDateAndLocationSearch = state =>
  Lodash.get(state, ['calendar', 'queriedDateAndLocation'], []);

export const getCalendar = state => Lodash.get(state, ['calendar'], {});

export const getCurrentLocation = createSelector(getCalendar, objCalendar =>
  Lodash.get(objCalendar, ['selectedLocation'], {})
);

export const getCurrentLocationSfId = createSelector(
  getCurrentLocation,
  objLocation => Lodash.get(objLocation, ['sfId'], '')
);

export const getSelectedDate = createSelector(
  getCalendar,
  getFormSelectedDate,
  (objCalendar, intFormSelectedDate) =>
    intFormSelectedDate || Lodash.get(objCalendar, ['selectedDate'], '')
);

export const getSelectedMonthInUnix = createSelector(
  getCalendar,
  objCalendar => Lodash.get(objCalendar, ['selectedMonth'], 0) || 0
);

export const getBookings = createSelector(getCalendar, objCalendar =>
  Lodash.get(objCalendar, ['bookings'], [])
);

export const getCurrentQueriedSearch = createSelector(
  [getSelectedDate, getCurrentLocationSfId, getProfileLocationSfId],
  (selectedDate, locationSfId, userLocation) => {
    const queriedDateAndSfId = `${selectedDate} ${
      userLocation || locationSfId
    }`;
    return queriedDateAndSfId;
  }
);

export const getQueriedDatesAndLocations = createSelector(
  [getQueriedDateAndLocationSearch],
  queriedDateAndSfId => queriedDateAndSfId
);

export const getAmenities = createSelector(getCalendar, objCalendar =>
  Lodash.get(objCalendar, ['amenities'], {})
);

export const getTimezone = createSelector(getCalendar, objCalendar =>
  Lodash.get(objCalendar, ['tz'], '')
);

export const getUpcomingResvDrawerIsOpen = createSelector(
  getCalendar,
  objCalendar => Lodash.get(objCalendar, ['upcomingResvDrawerIsOpen'], false)
);

export const getAccountBalance = createSelector(getCalendar, objCalendar =>
  Lodash.get(objCalendar, ['accountBalance'], false)
);

export const getAccountBalanceIsLoading = createSelector(
  getCalendar,
  objCalendar => Lodash.get(objCalendar, ['accountBalanceIsLoading'], false)
);

export const getAccountMonthlyAllowanceCredits = createSelector(
  getAccountBalance,
  objUserAccountBalance =>
    Lodash.get(objUserAccountBalance, ['remainingMonthlyAllowanceCredits'], 0)
);

export const getAccountTotalCreditsUsed = createSelector(
  getAccountBalance,
  objUserAccountBalance =>
    Lodash.get(objUserAccountBalance, ['totalCreditsUsed'], 0)
);

export const getAccountRolloverCredits = createSelector(
  getAccountBalance,
  objUserAccountBalance =>
    Lodash.get(objUserAccountBalance, ['remainingRolloverCredits'], 0)
);

export const getAccountMonthlyAlottedCredits = createSelector(
  getAccountBalance,
  objUserAccountBalance =>
    Lodash.get(objUserAccountBalance, ['monthlyAllowanceCredits'], 0)
);

export const getAccountOverage = createSelector(getAccountBalance, objBalance =>
  Lodash.get(objBalance, ['overage'], 0)
);

export const getBookingRestrictions = createSelector(getCalendar, calendar =>
  Lodash.get(calendar, 'bookingRestrictions', {})
);

export const getVisitingError = createSelector(
  [getBookingRestrictions, getSelectedDate, getCurrentLocationSfId],
  (restrictions, date, locationSfId) => {
    const month = Math.max(Moment.unix(date).startOf('month').unix(), 0);
    const formattedDate = Moment.unix(date).format('YYYY-MM-DD');
    const restrictionsForDateAndLocation = Lodash.get(
      restrictions,
      [locationSfId, month],
      { remainingDaysToBook: 1 } // the backup here is one day since if if we don't have restrictions we assume the user has none
    );
    const { remainingDaysToBook } = restrictionsForDateAndLocation;
    if (remainingDaysToBook <= 0) {
      return VISITING_ERROR_TYPES.MONTH;
    }

    const dayRestrictions = Lodash.get(
      restrictionsForDateAndLocation,
      ['remainingBookableSecondsInDay', formattedDate],
      1
    );
    if (dayRestrictions <= 0) {
      return VISITING_ERROR_TYPES.DAY;
    }
    return VISITING_ERROR_TYPES.NONE;
  }
);

export const getLocationRestrictions = createSelector(
  [getBookingRestrictions, getCurrentLocationSfId],
  (restrictions, locationSfId) => {
    return Lodash.get(restrictions, [locationSfId], {});
  }
);

export const getRemainingTimeToBookAtLocation = createSelector(
  [getLocationRestrictions, getSelectedDate],
  (restrictions, date) => {
    const formattedDate = Moment.unix(date).format('YYYY-MM-DD');
    const month = Math.max(Moment.unix(date).startOf('month').unix(), 0);
    const remainingSecondsInDay = Lodash.get(
      restrictions,
      [month, 'remainingBookableSecondsInDay'],
      {}
    );
    return Lodash.get(remainingSecondsInDay, [formattedDate], 24 * 60 * 60);
  }
);

export const getSelectedGrouping = createSelector(
  [getCalendar],
  objCalendarState => {
    return Lodash.get(objCalendarState, ['selectedGrouping'], {});
  }
);

export const getFloorsFilters = createSelector(
  [getCalendar],
  objCalendarState => {
    return Lodash.get(objCalendarState, ['floorsFilters'], []);
  }
);

export const getSeatsFilters = createSelector(
  [getCalendar],
  objCalendarState => {
    return Lodash.get(objCalendarState, ['seatFilters'], []);
  }
);

export const getAmenFilters = createSelector(
  [getCalendar],
  objCalendarState => {
    return Lodash.get(objCalendarState, ['amenFilters'], []);
  }
);

export const getSelectedGroupingId = createSelector(
  [getCalendar],
  objCalendarState => {
    return Lodash.get(objCalendarState, ['selectedGrouping', 'idGrouping'], {});
  }
);

export const getQueriedDateAndGrouping = createSelector(
  [getCalendar],
  objCalendarState => {
    return Lodash.get(objCalendarState, ['queriedDateAndGrouping'], {});
  }
);

export const getLastGroupingAt = createSelector(
  [getCalendar],
  objCalendarState => {
    return Lodash.get(objCalendarState, ['lastGroupingAt'], {});
  }
);

export const getFiltersCount = createSelector(
  [getFloorsFilters, getSeatsFilters, getAmenFilters],
  (listFloorsFilters, listSeatsFilters, listAmensFilters) => {
    const listFloorsFiltersLength = listFloorsFilters.length;
    const listSeatsFiltersLength = listSeatsFilters.length;
    const listAmensFiltersLength = listAmensFilters.length;

    return (
      listFloorsFiltersLength + listSeatsFiltersLength + listAmensFiltersLength
    );
  }
);

export const getGroupingsForSelectedRoomBookingLocation = createSelector(
  getSelectedGrouping,
  getAllDisplayGroupings,
  (selectedGrouping, bookableGroupings) => {
    return bookableGroupings.filter(
      g =>
        g.type === 'Integrated' &&
        g.locationSfId === selectedGrouping?.locationSfId
    );
  }
);
