import React from 'react';
import PropTypes from 'prop-types';

// Components
import { MapLayerWrapper as Layer } from '../../Common';

// Constants
import { LAYERS } from '../Constants';

// Theme
import { colors } from '../../../Core/Theme';

// Layer properties
const fillProperties = {
  id: LAYERS.COMMON,
  type: 'fill',
  source: 'features',
  paint: {
    'fill-color': colors.palette.secondary2.dark,
  },
};
const lineProperties = {
  id: `${LAYERS.COMMON}-lines`,
  type: 'line',
  source: 'features',
  paint: {
    'line-color': colors.palette.secondary2.darkest,
    'line-width': 2,
  },
};

const CommonArea = props => {
  const { common } = props;
  return (
    <Layer
      properties={[fillProperties, lineProperties]}
      filter={['in', ['get', 'id'], ['literal', common]]}
      {...props}
    />
  );
};

CommonArea.defaultProps = {
  common: [],
};
CommonArea.propTypes = {
  common: PropTypes.arrayOf(PropTypes.string),
};

export default React.memo(CommonArea);
