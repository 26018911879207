import React from 'react';
import PropTypes from 'prop-types';

// Components
import { MapLayerWrapper as Layer } from '../../Common';

// Constants
import { LAYERS } from '../Constants';

// Theme & Utils
import { colors } from '../../../Core/Theme';
import { createHash } from '../../../Core/Utils';

// Layer properties
const fillProperties = {
  id: LAYERS.SELECTED_DESK,
  type: 'fill',
  source: 'features',
  paint: {
    'fill-color': [
      'case',
      ['boolean', ['feature-state', 'hover'], false],
      colors.palette.secondary.lightest,
      colors.palette.secondary.light,
    ],
  },
};
const lineProperties = {
  id: `${LAYERS.SELECTED_DESK}-lines`,
  type: 'line',
  source: 'features',
  paint: {
    'line-color': colors.palette.secondary.main,
    'line-width': 2,
    'line-opacity': 0.6,
  },
};

// Legend
const legend = {
  key: LAYERS.SELECTED_DESK,
  style: {
    backgroundColor: colors.palette.secondary.light,
    borderColor: colors.palette.secondary.main,
    borderWidth: 1,
    borderStyle: 'solid',
  },
  labelKey: 'deskFloorPlan.legend.selectedDesk',
};

const SelectedDesk = props => {
  const { desks } = props;
  return (
    <Layer
      key={`${LAYERS.SELECTED_DESK}-${createHash(desks)}`}
      properties={[fillProperties, lineProperties]}
      filter={['in', ['get', 'id'], ['literal', desks]]}
      {...props}
    />
  );
};

SelectedDesk.defaultProps = {
  desks: [],
};
SelectedDesk.propTypes = {
  desks: PropTypes.arrayOf(PropTypes.string),
};

export { legend };
export default React.memo(SelectedDesk);
