import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import Fab from '@material-ui/core/Fab';

import { Icons } from '../../../resources';

const { CloseIcon } = Icons;

const CloseButton = props => {
  const { classes, onClose, className, iconClassName } = props;
  const { t } = useTranslation();

  return (
    <Fab
      size="small"
      onClick={onClose}
      aria-label={t('general.close')}
      className={clsx(classes.closeButton, className)}
    >
      <CloseIcon className={iconClassName} />
    </Fab>
  );
};

const styles = () => ({
  closeButton: {
    background: 'transparent !important',
    width: 34,
    height: 34,
    minHeight: 34,
    boxShadow: '0 1px 4px 0 rgba(131, 120, 120, 0.25)',
    '&:hover': {
      boxShadow: '0 1px 10px 0 rgba(0, 0, 0, 0.3)',
    },
  },
});

CloseButton.defaultProps = {
  onClose: () => {},
  iconClassName: '',
};

CloseButton.propTypes = {
  classes: PropTypes.object.isRequired,
  onClose: PropTypes.func,
  iconClassName: PropTypes.string,
};

export default withStyles(styles)(CloseButton);
