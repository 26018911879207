import { createAction } from 'redux-actions';
import * as types from './actionTypes';
import { fetchLocations } from '../../../Core/Api';
import { fetchHandlerActions } from '../../../Core/Utils/fetch';

export const addLocations = createAction(
  types.LOAD_LOCATIONS,
  fetchHandlerActions(fetchLocations)
);

// export const setLocation = dispatch => location =>
//   dispatch(setPrimaryLocation(location));
