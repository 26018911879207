// Styles
import { colors, breakpoints } from '../../Core/Theme';

const styles = theme => ({
  fieldInputStyle: {
    backgroundColor: colors.white,
    borderRadius: '3px',
    height: '50px',
    marginTop: 16,
  },
  inputsContainer: {
    margin: '0 auto',
    flexDirection: 'column',
    maxHeight: '480px',
    [theme.breakpoints.up('xs')]: {
      maxWidth: '266px',
    },
    [theme.breakpoints.down('xs')]: {
      maxWidth: '100vh',
      marginLeft: 20,
      marginRight: 20,
    },
  },
  title: {
    height: '26px',
    fontFamily: "'VerlagBold'",
    fontSize: '24px',
    letterSpacing: 'normal',
    textAlign: 'center',
    color: colors.white,
    marginLeft: 42,
    marginRight: 42,
    [theme.breakpoints.up('xs')]: {
      marginTop: '10%',
    },
    [theme.breakpoints.down('xs')]: {
      margin: 'auto',
      marginTop: '5vh',
    },
  },
  containerMetaInfo: {
    width: '251px',
    height: '30px',
    fontFamily: "'VerlagBook'",
    fontSize: '12px',
    fontWeight: 'bold',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 1.25,
    letterSpacing: 'normal',
    color: colors.alertMessage,
    backgroundColor: colors.alertBackground,
    margin: 'auto',
    textAlign: 'center',
  },
  message: {
    fontFamily: "'VerlagLight'",
    fontSize: '16px',
    textAlign: 'center',
    color: colors.white,
    margin: 'auto',
    paddingLeft: 20,
    paddingRight: 20,
    marginTop: 15,
    [theme.breakpoints.up(breakpoints.MOBILE)]: {
      marginTop: '5%',
      paddingLeft: 40,
      paddingRight: 40,
    },
  },
  sentEmail: {
    width: '295px',
    minHeight: '84px',
    fontFamily: "'VerlagBook'",
    fontSize: '18px',
    fontWeight: 'normal',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 1.17,
    letterSpacing: 'normal',
    textAlign: 'center',
    color: colors.white,
    margin: 'auto',
    marginTop: '5%',
    marginBottom: 30,
    [theme.breakpoints.down(breakpoints.MOBILE)]: {
      marginLeft: 20,
      marginRight: 20,
      width: 'calc(100% - 40px)',
    },
  },

  inputRoot: {
    border: 'none',
    marginTop: '10px !important',
    paddingLeft: '10px',
  },
  errorMsg: {
    color: colors.alertMessage,
    backgroundColor: colors.alertBackground,
    fontFamily: "'VerlagBold'",
    fontSize: '12px',
    margin: '0 auto',
    borderRadius: 2,
    padding: '8px 10px 8px 10px',
    marginTop: 10,
    [theme.breakpoints.up('xs')]: {
      maxWidth: '246px',
    },
    [theme.breakpoints.down('xs')]: {
      maxWidth: '100vh',
      marginLeft: 20,
      marginRight: 20,
    },
  },
  inputCode: {
    border: 'none',
    paddingLeft: 10,
  },
  fieldInputStyleCode: {
    marginTop: 15,
    marginBottom: 5,
  },
  inputsContainerCode: {
    margin: '0 auto',
    marginTop: 25,
    flexDirection: 'column',
    maxHeight: '480px',
    [theme.breakpoints.up('xs')]: {
      maxWidth: '266px',
    },
    [theme.breakpoints.down('xs')]: {
      maxWidth: '100vh',
      marginLeft: 20,
      marginRight: 20,
    },
  },
  submitButton: {
    textTransform: 'none',
    fontSize: '15px',
    fontFamily: 'VerlagBold',
    letterSpacing: '0.5px',
    marginBottom: 40,
    marginTop: 30,
  },
  focusVisibleSubmit: {
    backgroundColor: colors.palette.primary.light,
    outline: '4px solid -webkit-focus-ring-color !important',
  },
  errorMsgCode: {
    color: colors.alertMessage,
    backgroundColor: colors.alertBackground,
    fontFamily: "'VerlagBold'",
    fontSize: '12px',
    margin: '0 auto',
    borderRadius: 2,
    padding: '8px 10px 8px 10px',
    marginTop: 10,
    [theme.breakpoints.up('xs')]: {
      maxWidth: '246px',
    },
    [theme.breakpoints.down('xs')]: {
      maxWidth: '100vh',
      marginLeft: 20,
      marginRight: 20,
    },
  },
});

export default styles;
