export const ADD_QUERIED_DATE = 'ADD_QUERIED_DATE';

export const ADD_QUERIED_DATE_LOCATION = 'ADD_QUERIED_DATE_LOCATION';

export const ADD_QUERIED_DATE_GROUPING = 'ADD_QUERIED_DATE_GROUPING';

export const ADD_LAST_GROUPING_AT = 'ADD_LAST_GROUPING_AT';

export const UPDATE_DATE = 'UPDATE_DATE';

export const UPDATE_MONTH = 'UPDATE_MONTH';

export const SET_LOCATION = 'SET_LOCATIONS';

export const SET_GROUPING = 'SET_GROUPING';

export const TOGGLE_FILTER = 'TOGGLE_FILTER';

export const ADD_ROOM_AMENITIES = 'ADD_ROOM_AMENITIES';

export const ADD_ROOM_AMENITIES_PENDING = 'ADD_ROOM_AMENITIES_PENDING';

export const ADD_ROOM_AMENITIES_FULFILLED = 'ADD_ROOM_AMENITIES_FULFILLED';

export const SET_UPCOMING_RESV_DRAWER_STATE = 'SET_UPCOMING_RESV_DRAWER_STATE';

export const ADD_BOOKING_RESTRICTION = 'ADD_BOOKING_RESTRICTION';

export const CLEAR_ALL_FILTERS = 'CLEAR_ALL_FILTERS';

export const SET_TIMEZONE = 'ROOM_BOOKING/SET_TIMEZONE';
