import { createAction } from 'redux-actions';
import * as types from './actionTypes';
import { fetchNotifications, fetchSelectedLocation } from '../../../Core/Api';
import { fetchHandlerActions } from '../../../Core/Utils/fetch';

export const setLocation = createAction(types.SET_LOCATION);

export const addYearNotifications = createAction(
  types.LOAD_YEAR_NOTIFICATIONS,
  fetchHandlerActions(fetchNotifications)
);

export const addSelectedLocationSettings = createAction(
  types.LOAD_SELECTED_LOCATION_SETTINGS,
  fetchHandlerActions(fetchSelectedLocation)
);

export const setRedirectToPackageAlerts = createAction(
  types.SET_REDIRECT_TO_PACKAGE_ALERTS
);

export const setMailroomDescription = createAction(types.SET_MAILROOM_DESC);

export const setDrawerState = createAction(types.SET_DRAWER_STATE);
export const setActiveDrawerContent = createAction(
  types.SET_ACTIVE_DRAWER_CONTENT
);

export const addNotificationRecipient = createAction(
  types.ADD_NOTIFICATION_RECIPIENT
);

export const removeNotificationRecipient = createAction(
  types.REMOVE_NOTIFICATION_RECIPIENT
);

export const setTimezone = createAction(types.SET_TIMEZONE);
