import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Lodash from 'lodash';
import clsx from 'clsx';

import Text from '../Text';

const IconText = props => {
  const { classes, text, leftIcon, rightIcon, rootClasses } = props;
  const rootClassNameLeftIcon = Lodash.get(rootClasses, ['rootLeftIcon'], null);
  const rootClassNameRightIcon = Lodash.get(
    rootClasses,
    ['rootRightIcon'],
    null
  );
  const rootClassName = Lodash.get(rootClasses, ['root'], null);
  const textClassName = Lodash.get(rootClasses, ['text'], null);
  return (
    <div className={clsx(classes.container, rootClassName)}>
      {leftIcon ? (
        <div className={clsx(classes.leftIcon, rootClassNameLeftIcon)}>
          {leftIcon}
        </div>
      ) : null}
      <Text text={text} className={clsx(classes.text, textClassName)} />
      {rightIcon ? (
        <div className={clsx(classes.rightIcon, rootClassNameRightIcon)}>
          {rightIcon}
        </div>
      ) : null}
    </div>
  );
};

IconText.defaultProps = {
  text: null,
  leftIcon: null,
  rightIcon: null,
  rootClasses: null,
};

IconText.propTypes = {
  text: PropTypes.string,
  leftIcon: PropTypes.any,
  rightIcon: PropTypes.any,
  rootClasses: PropTypes.shape({
    rootLeftIcon: PropTypes.string,
    rootRightIcon: PropTypes.string,
    root: PropTypes.string,
    text: PropTypes.string,
  }),
};

const styles = () => ({
  text: {
    fontFamily: 'VerlagBook',
    fontSize: 16,
  },
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  leftIcon: {
    paddingRight: 5,
  },
  rightIcon: {
    paddingLeft: 5,
  },
});
export default withStyles(styles)(IconText);
