/* eslint-disable import/prefer-default-export */
import { StateAbbreviationMap } from '../../../resources';

export const getOptionFromLocationOrGrouping = location => {
  return {
    value: location,
    label: location.externalName,
    description: {
      address: location.address,
      city: location.city,
      state: location.state,
      zipCode: location.zipCode,
    },
    groupId: location.parentSfId,
    groupLabel: location.parent?.name ?? '',
    search: `${location.address},${location.city},${location.zipCode},${
      StateAbbreviationMap[location?.state] ?? location?.state
    }`,
    key: location.sfId,
  };
};
