import { StyleRulesCallback, withStyles } from '@material-ui/core';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import { useTranslation } from 'react-i18next';
import { OfficeAvailabilityDto } from '../../../Core/Api';

type Props = {
  classes: ClassNameMap;
  offices: OfficeAvailabilityDto[];
};

const OfficeAvailabilityCount = (props: Props) => {
  const { classes, offices } = props;
  const { t } = useTranslation();

  const countAvailable = offices.filter(office => !office.isReserved).length;

  return (
    <div className={classes.container}>
      {t<string>('reserveOffice.count', {
        countAvailable,
        countTotal: offices.length,
      })}
    </div>
  );
};

const style: StyleRulesCallback<string> = () => ({
  container: {
    whiteSpace: 'nowrap',
  },
});

export default withStyles(style)(OfficeAvailabilityCount);
