import { StateAbbreviationMap } from '../../resources';

export const isMatchingStateAbbreviation = (input, location) => {
  const availableStateAbbreviations = location?.state
    ? location?.state.split(' ')
    : [];

  const inputInLowCase = input.toLowerCase();
  return availableStateAbbreviations.some(w =>
    w.toLowerCase().startsWith(inputInLowCase)
  );
};

export const isMatchingStateName = (input, location) => {
  const stateName = StateAbbreviationMap[location?.state] ?? location?.state;
  return !!stateName?.toLowerCase().includes(input.toLowerCase());
};
