/* eslint-disable react/require-default-props */
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { formatCurrency } from '../../../Core/Utils/strings';
import { SummaryRow } from './index';
import { USD_CURRENCY_CODE } from '../constants';
import { dayPassRoleAccess } from '../../../Core/Utils/userPermissions';
import { getUserProfile } from '../../../Redux/Common/UserManager/selectors';
import { date as dateUtils } from '../../../Core/Utils';

interface Props {
  hasUnlimitedDayPasses: boolean;
  usedPasses?: number;
  isLoading: boolean;
  dayPassCharge?: number;
  allottedPasses?: number;
  showDeskDayPassInfo: boolean;
  totalOfficeReservations: number;
  month: number;
  formattedOfficeCharges: string;
}

const DayPassSummaryBox = (props: Props) => {
  const {
    hasUnlimitedDayPasses,
    allottedPasses,
    usedPasses,
    isLoading,
    dayPassCharge,
    showDeskDayPassInfo,
    totalOfficeReservations,
    month,
    formattedOfficeCharges,
  } = props;
  const { t } = useTranslation();
  const profile = useSelector(getUserProfile);

  const usesDayPasses = dayPassRoleAccess.hasFeature({
    obj: {
      hasUnlimitedDayPasses,
      allotted: allottedPasses,
      date: dateUtils.getDateInMoment(month).toDate(),
    },
    profile,
  });

  return (
    <SummaryRow
      title={
        showDeskDayPassInfo
          ? t('accountActivity.summary.desk_title')
          : t('accountActivity.summary.office_title')
      }
      leftTitle={
        usesDayPasses
          ? t('accountActivity.summary.a_of_b', {
              a: usedPasses,
              b: hasUnlimitedDayPasses
                ? t('general.unlimited')
                : allottedPasses,
            })
          : `${totalOfficeReservations}`
      }
      leftDescription={
        usesDayPasses
          ? t('accountActivity.summary.used_passes')
          : t('accountActivity.summary.reservations')
      }
      rightTitle={
        showDeskDayPassInfo
          ? formatCurrency({
              value: dayPassCharge ?? 0,
              currency: USD_CURRENCY_CODE,
            })
          : formattedOfficeCharges ?? ''
      }
      rightDescription={t('accountActivity.summary.total_charge')}
      isLoading={isLoading}
    />
  );
};

export default DayPassSummaryBox;
