import route from './route';
import { LazyloadWrapper } from '../../Components/Common';

const VisitingMemberReservations = LazyloadWrapper(
  import('./VisitingMemberReservations')
);

export default {
  RenderProp: VisitingMemberReservations,
  mainRoute: route,
};
