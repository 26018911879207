/* eslint-disable react/require-default-props */
import { useState } from 'react';

import {
  StyleRulesCallback,
  withStyles,
  ExpansionPanelSummary,
  Typography,
  MenuItem,
  ExpansionPanel,
  ExpansionPanelDetails,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CheckIcon from '@material-ui/icons/Check';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import clsx from 'clsx';
import { colors } from '../../Core/Theme';
import { Text } from '../Common';

import { LOCALES, LOCALE_LABELS } from '../../Core/constants';
import { handlePress } from '../../Core/Utils/events';

type Props = {
  classes: ClassNameMap;
  title: string;
  onLanguageItemMenuClick: (l: string) => {};
};

const MobileLanguageMenu = ({
  classes,
  title,
  onLanguageItemMenuClick,
}: Props) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const labels = LOCALE_LABELS as { [key: string]: string };

  const toggleIsExpanded = () => setIsExpanded(!isExpanded);

  return (
    <ExpansionPanel
      className={classes.container}
      expanded={isExpanded}
      onChange={toggleIsExpanded}
    >
      <ExpansionPanelSummary
        expandIcon={<ExpandMoreIcon className={classes.expandIcon} />}
        aria-controls="language-panel-content"
        id="language-panel-header"
        classes={{
          root: classes.menuItemAccordionHeader,
          expanded: classes.menuItemAccordionHeaderExpanded,
        }}
      >
        <Typography className={classes.menuItemAccordionTitle}>
          {title}
        </Typography>
      </ExpansionPanelSummary>

      <ExpansionPanelDetails className={classes.menuItemAccordionDetails}>
        {LOCALES.map(l => (
          <MenuItem
            className={clsx(classes.menuAccordionItem)}
            key={`language_panel_${l}`}
            onClick={handlePress(onLanguageItemMenuClick, l)}
          >
            <Text text={labels[l]} className={classes.languageMenuItemText} />
            {title === l && <CheckIcon className={classes.checkIcon} />}
          </MenuItem>
        ))}
      </ExpansionPanelDetails>
    </ExpansionPanel>
  );
};

const styles: StyleRulesCallback = () => ({
  container: {
    border: '0px',
    boxShadow: 'none',
    margin: 0,
    padding: 0,
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:last-child': {
      borderRadius: 0,
    },
    '&:before': {
      display: 'none',
    },
    backgroundColor: colors.darkest,
    '&:focus': {
      backgroundColor: colors.darkest,
    },
    '&:hover': {
      backgroundColor: colors.darkest,
    },
  },
  expandIcon: {
    color: 'white',
  },
  menuItemAccordionDetails: {
    margin: 0,
    padding: 0,
    display: 'block',
    backgroundColor: colors.darkest,
    width: '100%',
  },
  menuItemAccordionTitle: {
    textTransform: 'uppercase',
    fontWeight: 'bold',
    color: colors.white,
    fontSize: '18px',
    paddingLeft: `calc(10% - 20px)`,
  },
  menuItemAccordionHeader: {
    borderBottom: '0px',
    marginBottom: -1,
    maxHeight: '49px !important',
    minHeight: '49px !important',
  },
  menuItemAccordionHeaderExpanded: {
    '&:first-child': {
      margin: '0px 0 0px 0',
    },
  },
  menuAccordionItem: {
    backgroundColor: colors.darkest,
    color: colors.white,
    alignItems: 'center',
    textAlign: 'center',
    marginRight: 40,
    marginLeft: 40,
    paddingLeft: 0,
    paddingRight: 0,
  },
  languageMenuItemText: {
    fontWeight: 'normal',
  },
  checkIcon: {
    marginLeft: 8,
    height: 19,
  },
});

export default withStyles(styles)(MobileLanguageMenu);
