/* eslint-disable react/require-default-props */

import { Theme, withStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import {
  ClassNameMap,
  StyleRulesCallback,
} from '@material-ui/core/styles/withStyles';

import { breakpoints, colors } from '../../../../Core/Theme';
import { date as DateUtils } from '../../../../Core/Utils';

import OfficeStats from './OfficeStats';
import MeetingRoomStats from './MeetingRoomStats';

type Props = {
  accountSfId: string;
  classes: ClassNameMap;
  month: number;
  timezoneId: string;
  refreshCount: number;
  className?: string;
};

const MyReservationsTracking = (props: Props) => {
  const {
    accountSfId,
    classes,
    month,
    className,
    timezoneId,
    refreshCount = 0,
  } = props;
  const { t } = useTranslation();

  const startEpoch: number = DateUtils.updateDateForTZ(month, timezoneId);
  const endEpoch = DateUtils.getUnixCurrentEndOfTheMonth(startEpoch);

  return (
    <div className={clsx(classes.container, className)}>
      <div className={classes.title}>
        {t<string>('myReservations.tracking_hour.title')}
      </div>
      <div className={classes.trackingHours}>
        <MeetingRoomStats
          accountSfId={accountSfId}
          startMonthEpoch={startEpoch}
          refreshCount={refreshCount}
        />
        <OfficeStats
          accountSfId={accountSfId}
          startEpoch={startEpoch}
          endEpoch={endEpoch}
          refreshCount={refreshCount}
        />
      </div>
    </div>
  );
};

const styles: StyleRulesCallback<string> = (theme: Theme) => ({
  container: {
    background: colors.palette.secondary2.main,
    paddingLeft: 7,
    paddingRight: 7,
  },
  trackingHour: {
    marginRight: 20,
    [theme.breakpoints.up(breakpoints.MOBILE)]: {
      width: '50%',
    },
  },
  trackingHours: {
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up(breakpoints.MOBILE)]: {
      flexDirection: 'row',
    },
  },
  title: {
    fontSize: 18,
    paddingBottom: 0,
    paddingTop: 10,
    fontFamily: 'VerlagBold',
    [theme.breakpoints.up(breakpoints.MOBILE)]: {
      paddingBottom: 5,
      fontSize: 16,
    },
  },
});

export default withStyles(styles)(MyReservationsTracking);
