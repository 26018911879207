import { schema, normalize } from 'normalizr';
import Lodash from 'lodash';

//  constructor
export function NormObjCstr(byId, allIds) {
  this.byId = byId;
  this.allIds = allIds;
}

//  Occasion Type from occasion types call
const occasionTypeSchema = new schema.Entity(
  'byId',
  {},
  { idAttribute: 'idOccasionType' }
);

const occasionTypesArrSchema = [occasionTypeSchema];

export const normalizedOccasionTypes = array => {
  const temp = normalize(array, occasionTypesArrSchema);
  return new NormObjCstr(temp.entities.byId, temp.result);
};

//  Occasions
const occasionTypeInOccasionSchema = new schema.Entity(
  'occasionTypes',
  {},
  {
    idAttribute: 'idOccasionType',
  }
);

const occasionTypeInOccasionArrSchema = [occasionTypeInOccasionSchema];

const occasionSchema = new schema.Entity(
  'occasions',
  { occasionTypes: occasionTypeInOccasionArrSchema },
  {
    idAttribute: 'idOccasion',
    processStrategy: occasion => {
      const occasionCopy = { ...occasion };
      occasionCopy.startEpoch = parseInt(occasion.startEpoch, 10);
      occasionCopy.endEpoch = parseInt(occasion.endEpoch, 10);
      delete occasionCopy.primaryOccasionType;
      delete occasionCopy.occasionTypeIds;
      return occasionCopy;
    },
  }
);

const occasionArrSchema = [occasionSchema];

export const normalizedOccasion = data => {
  const temp = Array.isArray(data)
    ? normalize(data, occasionArrSchema)
    : normalize(data, occasionSchema);
  if (Lodash.isEmpty(temp.entities)) {
    return {
      occasions: new NormObjCstr({}, []),
      occasionTypes: new NormObjCstr({}, []),
    };
  }

  //  this code is to coerce the type to Number because occasionTypes AllIds is already an array of number
  const typeKeys = Object.keys(temp.entities.occasionTypes).map(id =>
    Number(id)
  );

  return {
    occasions: new NormObjCstr(temp.entities.occasions, temp.result),
    occasionTypes: new NormObjCstr(temp.entities.occasionTypes, typeKeys),
  };
};
