import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { capitalize } from 'lodash';
import { useTranslation } from 'react-i18next';

import { colors, breakpoints } from '../../../Core/Theme';

const morningStart = 5;
const morningEnd = 12;
const afternoonEnd = 17;

const makeGreeting = (time, t) => {
  const currentHour = time.hour();
  if (currentHour >= morningStart && currentHour < morningEnd) {
    return t('home.greeting.morning');
  }
  if (currentHour >= morningEnd && currentHour < afternoonEnd) {
    return t('home.greeting.afternoon');
  }

  return t('home.greeting.evening');
};

const Greeting = props => {
  const { classes, firstName, now } = props;
  const { t } = useTranslation();

  return (
    <div className={classes.container}>
      <h1 className={classes.greeting} datatestid="home_greeting_message">
        {makeGreeting(now, t)}, {capitalize(firstName, t)}
      </h1>
    </div>
  );
};

Greeting.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  firstName: PropTypes.string.isRequired,
  now: PropTypes.shape({}).isRequired,
};

const styles = theme => ({
  container: {
    maxWidth: '328px',
    maxHeight: '36px',
    [theme.breakpoints.down(breakpoints.SM)]: {
      width: '100%',
      height: '24px',
    },
  },
  greeting: {
    margin: 0,
    padding: 0,
    fontFamily: 'VerlagBold',
    fontSize: '36px',
    letterSpacing: '1.2px',
    color: colors.black,
    whiteSpace: 'nowrap',
    fontWeight: 'normal',
    [theme.breakpoints.down(breakpoints.SM)]: {
      fontSize: '24px',
    },
  },
});

export default withStyles(styles)(Greeting);
