import { createSelector } from 'reselect';
import Lodash from 'lodash';
import { receptionTypes } from '../../../Containers/CheckInActivity/Components/MessageList/receptionTypes';
import { getProfileLocationSfId } from '../UserManager/selectors';
import { date as dateUtils } from '../../../Core/Utils';

const { getJsonDateTimeForTimezone } = dateUtils;

const getNotificationsObject = state => Lodash.get(state, 'notifications', {});
const getReceptionistObject = state => Lodash.get(state, 'receptionist', {});

export const getSelectedLocation = state =>
  Lodash.get(state, ['receptionist', 'selectedLocation'], {});

export const getSelectedLocationTimeZoneId = createSelector(
  getReceptionistObject,
  obj => Lodash.get(obj, ['selectedLocation', 'timeZoneId'], null)
);

export const countDates = notifications => {
  const history = [...notifications];
  const dates = [];

  for (let i = 0; i < history.length; i++) {
    const date = history[i].receptionDate.split('T')[0];
    if (!dates.some(el => el.date === date)) {
      dates.push({ date, count: 1 });
    } else {
      const d = dates.filter(el => el.date === date);
      if (d) {
        d[0].count++;
      }
    }
  }
  return dates;
};

export const getSelectedLocationSfId = createSelector(
  getSelectedLocation,
  locationObject => Lodash.get(locationObject, 'sfId', '')
);

export const getLoggedInInOwnLocation = createSelector(
  getProfileLocationSfId,
  getSelectedLocationSfId,
  (profileSfId, locationSfId) => profileSfId === locationSfId
);

export const getListNotifications = createSelector(
  getNotificationsObject,
  getSelectedLocationTimeZoneId,
  (notificationsObject, tz) => {
    const notifications = Lodash.get(notificationsObject, 'data', []);
    return notifications.map(el => ({
      ...el,
      receptionDate: getJsonDateTimeForTimezone(el.receptionDate, tz),
    }));
  }
);

export const getMyNotifications = createSelector(
  getListNotifications,
  notifications =>
    Lodash.filter(
      notifications,
      el =>
        el.receptionType !== receptionTypes.food &&
        el.receptionType !== receptionTypes.pkg &&
        el.receptionType !== receptionTypes.visitor
    )
);

export const getNotificationsOffset = createSelector(
  getNotificationsObject,
  obj => obj.offset
);

export const getNotificationsAreLoading = createSelector(
  getNotificationsObject,
  notificationsObject => Lodash.get(notificationsObject, 'loading', false)
);

export const getNotificationsHaveError = createSelector(
  getNotificationsObject,
  notificationsObject => Lodash.get(notificationsObject, 'hasError', false)
);

export const getNotificationsTotalCount = createSelector(
  getNotificationsObject,
  notificationsObject => Lodash.get(notificationsObject, 'total', 0)
);

export const getNotificationsLimit = createSelector(
  getNotificationsObject,
  notificationsObject => Lodash.get(notificationsObject, 'limit', 0)
);

export const getFirstLoadingOccurred = createSelector(
  getNotificationsObject,
  obj => Lodash.get(obj, 'firstLoadingOccurred', true)
);

export const getLoadedNotificationsCount = createSelector(
  getListNotifications,
  notifications => notifications.length
);

export const getDatesAvailable = createSelector(
  getListNotifications,
  notifications => countDates(notifications)
);

export const getMyNotificationsDatesAvailable = createSelector(
  getMyNotifications,
  notifications => countDates(notifications)
);

export const getFirstItem = createSelector(
  getListNotifications,
  notifications => Lodash.head(notifications)
);

export const getFirstMyItem = createSelector(
  getMyNotifications,
  notifications => Lodash.head(notifications)
);

export const getFirstItemId = createSelector(
  getListNotifications,
  notifications =>
    Lodash.get(Lodash.head(notifications), 'idReceptionHistory', '')
);

export const getFirstMyItemId = createSelector(
  getMyNotifications,
  notifications =>
    Lodash.get(Lodash.head(notifications), 'idReceptionHistory', '')
);

export const getAllNotificationsAreLoaded = createSelector(
  getLoadedNotificationsCount,
  getNotificationsTotalCount,
  (loadedCount, totalCount) => loadedCount >= totalCount
);

export const getNoNotificationsFound = createSelector(
  getNotificationsTotalCount,
  getFirstLoadingOccurred,
  getNotificationsAreLoading,
  (count, loadingOccurred, loading) =>
    loadingOccurred && count === 0 && loading === false
);

export const getLoadMoredataTriggerId = createSelector(
  getMyNotifications,
  notifications => {
    const n = 20;
    if (notifications.length < n) {
      return Lodash.get(Lodash.last(notifications), 'idReceptionHistory', '');
    }

    return Lodash.get(
      Lodash.head(notifications.slice(Math.max(notifications.length - n, 1))),
      'idReceptionHistory',
      ''
    );
  }
);

export const getIsFirstLoad = createSelector(
  getNotificationsObject,
  obj => obj.limit === obj.offset
);
