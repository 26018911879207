import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';

import {
  OfficeCard,
  OfficeName,
  OfficeFloor,
  RoundedButton,
  TextButton,
  OfficeSeatsChip,
  OfficePrice,
  OfficeAmenityList,
} from '../../../Components/Common';
import { colors, breakpoints } from '../../../Core/Theme';
import { OfficeTypes, OfficeStatuses } from '../../../Models/office';
import {
  activateReservableOffice,
  deactivateReservableOffice,
} from '../../../Core/Api';
import SelectOfficeType from './SelectOfficeType';
import { SelectOfficeVariants } from './SelectOfficeVariants';
import OfficeNotReservableWarningDialog from './OfficeNotReservableWarningDialog';
import OfficeAvailabilityAmenity from '../../../Components/Common/Office/OfficeAmenity';

import { Office } from '../../../Models';
import { useFloors } from '../../../Hooks';

const OfficeAssign = ({
  classes,
  office,
  selectedGrouping,
  handleOfficeListSuccess,
  isDisabled,
  hasError,
  hasFutureReservation,
  currencyIsoCode,
  onEditBtnClicked,
}) => {
  const { t } = useTranslation();
  const { floors } = useFloors();
  const [officeType, setOfficeType] = useState(Office.getOfficeType(office));

  const [successMsg, setSuccessMsg] = useState(false);
  const [popupError, setPopupError] = useState(false);
  const [showOfficeNotReservableWarning, setShowOfficeNotReservableWarning] =
    useState(false);
  const officeName = Office.getOfficeName(office);
  const floorName = floors.find(
    floor => floor.idFloor === office.floorUuid
  )?.floorName;
  const amenities = office.amenityOfficeRelations?.map(
    relation => relation.amenity
  );

  const officeTypeOptions = [
    {
      value: OfficeTypes.Reservable,
      label: t('manageOffices.office_types.reservable'),
    },
    {
      value: OfficeTypes.UnReservable,
      label: t('manageOffices.office_types.unreservable'),
    },
  ];

  useEffect(() => {
    setOfficeType(Office.getOfficeType(office));
  }, [office]);

  const handleOfficeResponse = (objResponse, officeTypeId) => {
    const { data } = objResponse;
    if (data) {
      setSuccessMsg(true);
      handleOfficeListSuccess(data);

      setTimeout(() => {
        setSuccessMsg(false);
      }, 4000);
    } else {
      setPopupError(true);
      setOfficeType(
        officeTypeId === OfficeTypes.UnReservable
          ? OfficeTypes.Reservable
          : OfficeTypes.UnReservable
      );
    }
  };

  const deactivateOffice = officeTypeId => {
    deactivateReservableOffice(office.sfId).then(objResponse =>
      handleOfficeResponse(objResponse, officeTypeId)
    );
  };

  const handleSelectOfficeType = officeTypeId => {
    if (officeTypeId === OfficeTypes.UnReservable) {
      if (hasFutureReservation) {
        setShowOfficeNotReservableWarning(true);
      } else {
        setOfficeType(officeTypeId);
        deactivateOffice(officeTypeId);
      }
    }
    if (officeTypeId === OfficeTypes.Reservable) {
      setOfficeType(officeTypeId);
      activateReservableOffice(office.sfId, selectedGrouping.idGrouping).then(
        objResponse => handleOfficeResponse(objResponse, officeTypeId)
      );
    }
  };

  const handleOnClosePopupError = () => {
    setPopupError(false);
  };

  const handleUnreservableCancelled = () => {
    setShowOfficeNotReservableWarning(false);
  };

  const handleUnreservableConfirmed = () => {
    setShowOfficeNotReservableWarning(false);
    deactivateOffice(OfficeTypes.UnReservable);
  };

  const handleEditBtnClicked = event => {
    event.stopPropagation();
    onEditBtnClicked();
  };

  const selectVariant =
    officeType === OfficeTypes.Reservable
      ? SelectOfficeVariants.Active
      : SelectOfficeVariants.Default;

  return (
    <>
      <OfficeCard
        className={clsx(
          hasError && classes.cardError,
          isDisabled && classes.disabled,
          classes.card
        )}
      >
        <div
          className={clsx(!isDisabled && classes.clickable)}
          aria-hidden="true"
        >
          {office.status === OfficeStatuses.Licensed && (
            <div className={classes.licensedOffice}>
              {t('general.licensed')}
            </div>
          )}
          <div className={classes.header}>
            <div>
              <OfficeName name={officeName} />
              <OfficeFloor floorName={floorName} />
            </div>

            <TextButton
              text={t('manageOffices.edit_office')}
              onClick={handleEditBtnClicked}
              className={classes.editButton}
            />
          </div>

          <div className={classes.subHeader}>
            <OfficeSeatsChip
              seats={office.seats}
              className={classes.seats}
              isDisabled={isDisabled}
            />
            <OfficePrice
              currencyIsoCode={currencyIsoCode}
              price={office.priceInMoney}
            />
          </div>

          {amenities.length > 0 && (
            <OfficeAmenityList className={classes.officeAmenityList}>
              {amenities.map(amenity => (
                <OfficeAvailabilityAmenity key={amenity.id} amenity={amenity} />
              ))}
            </OfficeAmenityList>
          )}

          <div className={classes.actions}>
            <div className={classes.officeStatus}>
              <div className={classes.officeStatusLabel}>
                {t('manageOffices.select_office_status_lbl')}
              </div>
              <div className={classes.officeStatusSelect}>
                <SelectOfficeType
                  options={officeTypeOptions.map(option => ({
                    ...option,
                    hidden: option.value === officeType,
                  }))}
                  onChange={handleSelectOfficeType}
                  value={officeType}
                  variant={selectVariant}
                  className={classes.selectInput}
                  disabled={isDisabled}
                />
                {successMsg && (
                  <span className={classes.successMsg}>
                    {t('manageOffices.saved')}
                  </span>
                )}
              </div>
            </div>
          </div>
          {office.status === OfficeStatuses.Licensed && (
            <div
              className={clsx(
                classes.disclaimerLicensedOffice,
                officeType !== OfficeTypes.UnReservable &&
                  classes.disclaimerLicensedOfficeError
              )}
            >
              {t(
                `manageOffices.licensed_offices${
                  officeType !== OfficeTypes.UnReservable ? '_error' : ''
                }`
              )}
            </div>
          )}
        </div>
      </OfficeCard>
      <Dialog
        open={popupError}
        onClose={handleOnClosePopupError}
        classes={{
          paper: classes.containerPopup,
        }}
      >
        <>
          <div className={classes.titlePopup}>
            {t('manageOffices.popup_error.title')}
          </div>
          <div>{t('manageOffices.popup_error.description')}</div>
          <RoundedButton
            className={classes.buttonPopup}
            onClick={handleOnClosePopupError}
          >
            {t('general.close')}
          </RoundedButton>
        </>
      </Dialog>
      {selectedGrouping.locationSfId && (
        <OfficeNotReservableWarningDialog
          open={showOfficeNotReservableWarning}
          onCancel={handleUnreservableCancelled}
          onConfirm={handleUnreservableConfirmed}
          officeName={officeName}
        />
      )}
    </>
  );
};

const styles = theme => ({
  cardError: {
    border: `1px solid ${colors.palette.error.main}`,
  },
  card: {
    '&:hover': {
      boxShadow: 'none !important',
    },
  },
  disabled: {
    color: colors.subtleGray,
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 10,
    [theme.breakpoints.up(breakpoints.MOBILE)]: {
      marginBottom: 15,
    },
  },
  subHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 10,
    [theme.breakpoints.up(breakpoints.MOBILE)]: {
      marginBottom: 15,
    },
  },
  licensedOffice: {
    fontSize: 12,
    fontFamily: 'VerlagBold',
    textTransform: 'uppercase',
    marginBottom: 16,
    letterSpacing: 0.3,
  },
  disclaimerLicensedOffice: {
    fontFamily: 'VerlagLight',
    marginTop: 10,
    color: 'black !important',
    fontSize: 12,
  },
  disclaimerLicensedOfficeError: {
    fontFamily: 'VerlagBold',
    color: `${colors.palette.error.main} !important`,
    fontSize: 15,
  },
  officeAmenityList: {
    marginBottom: 10,
    [theme.breakpoints.up(breakpoints.MOBILE)]: {
      marginBottom: 15,
    },
  },
  editOfficeName: {
    display: 'none',
    position: 'relative',
    [theme.breakpoints.up(breakpoints.MOBILE)]: {
      display: 'flex',
    },
  },
  actions: {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'space-between',
    [theme.breakpoints.up(breakpoints.MOBILE)]: {
      alignItems: 'end',
    },
  },
  selectInput: {
    marginRight: 12,
    fontFamily: 'Verlag',
  },
  officeStatus: {
    display: 'flex',
    flexDirection: 'column',
  },
  officeStatusLabel: {
    fontSize: 12,
    textTransform: 'uppercase',
    fontFamily: 'VerlagBold',
    marginBottom: 8,
  },
  officeStatusSelect: {
    display: 'flex',
    alignItems: 'center',
  },
  successMsg: {
    color: colors.palette.primary.main,
    fontFamily: 'VerlagBold',
    fontSize: 18,
  },
  reservableDesksMobile: {
    fontSize: 14,
    marginBottom: 10,

    [theme.breakpoints.up(breakpoints.MOBILE)]: {
      display: 'none',
    },
  },
  containerPopup: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '16px 30px',
    fontSize: 18,
    textAlign: 'center',
  },
  titlePopup: {
    fontFamily: 'VerlagBold',
    marginBottom: 20,
  },
  buttonPopup: {
    margin: '16px 20px 0',
    width: 150,
  },
  disclaimer: {
    marginTop: 12,
  },
  editButton: {
    textDecoration: 'underline',
    fontFamily: 'VerlagBold',
    cursor: 'pointer',
  },
  desks: {
    marginTop: 12,
  },
  hideOnMobile: {
    [theme.breakpoints.down(breakpoints.MOBILE)]: {
      display: 'none',
    },
  },
  showOnlyOnMobile: {
    [theme.breakpoints.up(breakpoints.MOBILE)]: {
      display: 'none',
    },
  },
});

OfficeAssign.propTypes = {
  classes: PropTypes.object.isRequired,
  office: PropTypes.object.isRequired,
  selectedGrouping: PropTypes.object.isRequired,
  handleOfficeListSuccess: PropTypes.func.isRequired,
  hasFutureReservation: PropTypes.bool.isRequired,
  isDisabled: PropTypes.bool.isRequired,
  hasError: PropTypes.bool.isRequired,
  currencyIsoCode: PropTypes.object.isRequired,
  onEditBtnClicked: PropTypes.func.isRequired,
};

export default withStyles(styles)(OfficeAssign);
