import { useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Lodash from 'lodash';
import memoizeOne from 'memoize-one';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import TextButton from '../TextButton';

const START_INDEX_TO_DISPLAY = 0;
const END_INDEX_TO_DISPLAY = 5;

const getDataToDisplay = memoizeOne((isShowingMore, data) => {
  if (isShowingMore) {
    return data;
  }

  return Lodash.slice(data, START_INDEX_TO_DISPLAY, END_INDEX_TO_DISPLAY);
});

const CollapseList = props => {
  const { data, key, classes, render } = props;
  const { t } = useTranslation();
  const [isShowingMore, setIsShowingMore] = useState(false);
  const toggleShowMore = () => setIsShowingMore(!isShowingMore);
  const hasMoreDataToDisplay =
    Lodash.isArray(data) && data.length > END_INDEX_TO_DISPLAY;
  const restToDisplay = Lodash.isArray(data) ? data.length : 0;

  return (
    <>
      {Lodash.map(getDataToDisplay(isShowingMore, data), (item, index) => {
        if (!Lodash.isNil(render) && Lodash.isFunction(render)) {
          return render({
            item,
            index,
          });
        }
        return <p key={`${key}_${index}`}>{item}</p>;
      })}
      {hasMoreDataToDisplay && (
        <p>
          <TextButton
            text={
              isShowingMore
                ? t('general.see_less')
                : t('general.see_count_more', { count: restToDisplay })
            }
            onClick={toggleShowMore}
            className={classes.seeMoreButton}
          />
        </p>
      )}
    </>
  );
};

CollapseList.defaultProps = {
  data: [],
  key: null,
  render: null,
};
CollapseList.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape),
  render: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  key: PropTypes.string,
};

const styles = () => ({
  seeMoreButton: {
    paddingLeft: 0,
  },
});

export default withStyles(styles)(CollapseList);
