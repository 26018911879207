import { withStyles } from '@material-ui/core/styles';

import { colors } from '../../../Core/Theme';
import SearchInput from './SearchInput';

const SearchInputBox = props => {
  const { classes } = props;
  return (
    <SearchInput
      {...props}
      customClasses={{
        input: classes.input,
      }}
    />
  );
};

const styles = () => ({
  input: {
    border: `solid 1px ${colors.middle}`,
    borderRadius: 4,
  },
});

export default withStyles(styles)(SearchInputBox);
