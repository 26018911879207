import Avatar from '@material-ui/core/Avatar';
import PropTypes from 'prop-types';

const ImageAvatar = props => {
  const { src, textDisplay, className, ...rest } = props;

  if (!src) {
    return (
      <Avatar {...rest} className={className}>
        {' '}
        {textDisplay}{' '}
      </Avatar>
    );
  }
  return <Avatar src={src} {...rest} className={className} />;
};

ImageAvatar.defaultProps = {
  src: '',
  textDisplay: '',
  className: '',
};

ImageAvatar.propTypes = {
  src: PropTypes.string,
  textDisplay: PropTypes.string,
  className: PropTypes.string,
};

export default ImageAvatar;
