import * as React from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { withFormik, FieldArray } from 'formik';
import Moment from 'moment-timezone';
import clsx from 'clsx';
import * as Yup from 'yup';
import Lodash from 'lodash';

// Access
import { reservationRoleAccess } from '../../Core/Utils/userPermissions';

import {
  Drawer,
  BackButton,
  Text,
  InfoBox,
  SubmitButton,
  BannerSlide,
  Snack,
  BaseButton,
  SlideConfirmationMessage,
  SlideAcross,
  TextButton,
  InfoBoxLocation,
} from '../Common';
import { colors, breakpoints } from '../../Core/Theme';
import { Addons, ResCard } from './Components';
import {
  trackCloseDeskReservationSummary,
  trackDeskReservationSummaryReserve,
} from '../../Core/Tracking';
import {
  getAccountSfIdOfReservations,
  getDisplayInfoForReservation,
  getReservationSetOwner,
} from './utils';

import { strings as StringsUtils, date as DateUtils } from '../../Core/Utils';
import { TRANSITION_DURATION } from '../Common/SlideConfirmationMessage/constants';
import { DESK_LANDING_PATH } from '../../Containers/DeskReservationLanding/constants';
import { DESK_RESERVATION_PATH } from '../../Containers/DeskReservation/Constants';
import { ROOM_BOOKING_PATH } from '../../Containers/RoomBooking/constants';
import { RoomBookingDate } from '../../Containers/RoomBooking/Models';

const WITH_ADDONS = false;

const reserveDeskSchema = Yup.object().shape({
  reservations: Yup.array(
    Yup.object().shape({
      owner: Yup.object()
        .shape()
        .test('owner exists', 'general.required_field', u => {
          return !Lodash.isEmpty(u);
        }),
    })
  ),
  addons: Yup.object().shape({}),
});

const ReserveDesk = props => {
  const {
    classes,
    isOpen,
    onClose,
    reservations,
    addonIds,
    submitForm,
    values,
    isCancel,
    locationsById,
    profile,
    deleteReservations,
    isValid,
    createReservations,
    location,
    passesRemaining,
    fetchUsage,
    onSubmit,
    handleResetForm,
    readOnly,
    history,
    handleUpdateReservation,
    handleCancelReservation,
    handleCreateReservations,
    groupingsAllIds,
    groupingsById,
    hasUnlimitedDayPasses,
    displayGroupings,
    ...rest
  } = props;

  const accountSfId = getAccountSfIdOfReservations(
    values.reservations,
    profile
  );
  const { t } = useTranslation();
  const [isSubmitLoading, setSubmitLoading] = React.useState(false);
  const [isSubmitSuccess, setSubmitSuccess] = React.useState(false);
  const [isSubmitConflict, setSubmitConflict] = React.useState(false);
  const [showCancelConfirmation, setShowCancelConfirmation] =
    React.useState(false);
  const [isSuccessSnackOpen, setSuccessSnackOpen] = React.useState(false);
  const [isFailSnackOpen, setFailSnackOpen] = React.useState(false);
  const [hasScrolled, setHasScrolled] = React.useState(false);

  const startEpoch = Lodash.get(reservations, [0, 'startEpoch'], 0);
  const arrReservations = Lodash.get(values, ['reservations'], []);

  const offices = reservations.map(r => {
    return Lodash.get(r, ['desk', 'parentOffice'], {});
  });
  const distinctOffices = Lodash.uniqBy(offices, 'sfId');
  const groupingId = Lodash.get(offices, [0, 'groupingId']);
  const grouping = displayGroupings.find(g => g.idGrouping === groupingId);
  const strDate = Moment.unix(startEpoch).format('[dddd, MMMM D, YYYY]');

  React.useEffect(() => {
    if (accountSfId) {
      const reservationStart = Lodash.get(reservations, [0, 'startEpoch']);

      const monthStart = reservationStart
        ? DateUtils.getMomentFromUnixAndTimeZone(
            reservationStart,
            reservations[0]?.owner?.account?.location?.timeZoneId
          )
            .startOf('month')
            .unix()
        : Moment.tz(new Date(), profile?.location?.timeZoneId)
            .startOf('month')
            .unix();

      fetchUsage(monthStart, accountSfId);
    }
  }, [accountSfId]);

  React.useEffect(() => {
    if (!isOpen) {
      setShowCancelConfirmation(false);
      setSubmitLoading(false);
      setSubmitSuccess(false);
      setSubmitConflict(false);
    } else if (isOpen) {
      setSuccessSnackOpen(false);
    }
  }, [isOpen]);

  const handleClose = (vals = values) => {
    trackCloseDeskReservationSummary();
    onClose(vals);
  };

  const handleApiError = error => {
    const status = Lodash.get(error, ['response', 'status']);
    setSubmitLoading(false);
    if (status === 409) {
      handleResetForm();
      return setSubmitConflict(true);
    }
    return setFailSnackOpen(true);
  };

  const canCancel = isCancel
    ? !arrReservations.some(
        reservation =>
          !reservationRoleAccess.delete({ obj: reservation, profile })
      )
    : false;

  const canEdit = isCancel
    ? !arrReservations.some(
        reservation =>
          !reservationRoleAccess.update({ obj: reservation, profile })
      )
    : false;

  const handleSuccess = (withClose = true) => {
    setSubmitLoading(false);
    setSubmitSuccess(true);
    setSuccessSnackOpen(true);
    if (withClose) {
      setTimeout(() => handleClose({}), 750);
    }
  };

  const handleOnCancel = () => {
    handleCancelReservation(
      arrReservations,
      deleteReservations,
      setSubmitLoading,
      handleApiError,
      onSubmit,
      handleSuccess
    );
  };

  const handleOnCreateUpdate = () => {
    if (Lodash.get(reservations, [0, 'id'])) {
      handleUpdateReservation(
        values,
        reservations,
        handleSuccess,
        handleApiError,
        setSubmitLoading,
        onSubmit
      );
      return;
    }

    const setOwner = getReservationSetOwner(values, profile);

    const canCreate = arrReservations.reduce((acc, r) => {
      if (
        !reservationRoleAccess.create({
          obj: { ...r, reservationSet: { setOwner }, grouping },
          profile,
        })
      ) {
        return false;
      }
      return acc;
    }, true);

    if (!isValid || !canCreate) {
      return;
    }
    trackDeskReservationSummaryReserve();

    handleCreateReservations(
      values,
      createReservations,
      setSubmitLoading,
      handleSuccess,
      handleApiError,
      setOwner
    );
  };

  const handleSubmit = () => {
    submitForm();
    if (isCancel && !showCancelConfirmation) {
      return setShowCancelConfirmation(true);
    }
    if (isCancel) {
      return handleOnCancel();
    }
    return handleOnCreateUpdate();
  };

  // handles click of the update button, directs user to page to update
  const handleUpdate = e => {
    const floorId = Lodash.get(reservations, [
      0,
      'desk',
      'parentOffice',
      'floorUuid',
    ]);
    const reservationId = Lodash.get(reservations, [0, 'id']);
    const strUrlParams = StringsUtils.stringifyParams({
      queryParams: { selectedDate: startEpoch, reservationId, floorId },
    });
    history.push({
      pathname: DESK_RESERVATION_PATH,
      search: strUrlParams,
    });
    e.stopPropagation();
  };

  const handleNavigateToConferenceRooms = () => {
    onSubmit();

    const roomBookingGroupingId = Lodash.find(groupingsAllIds, id => {
      const g = groupingsById[id];
      return (
        Lodash.get(g, 'locationSfId') === Lodash.get(location, 'sfId') &&
        Lodash.get(g, 'openToCoworkingMembers')
      );
    });
    const strUrlParams = StringsUtils.stringifyParams({
      queryParams: {
        selectedDate: RoomBookingDate.FromEpochAndTimezoneId(
          startEpoch,
          grouping.location.timeZoneId
        ),
        groupingId: roomBookingGroupingId,
      },
    });
    return history.push({
      pathname: ROOM_BOOKING_PATH,
      search: strUrlParams,
    });
  };

  const handleNavigateToDeskLanding = () => {
    onSubmit();
    return history.push({
      pathname: DESK_LANDING_PATH,
    });
  };

  const isDisabled = readOnly || (isCancel && !canCancel);

  return (
    <>
      <Drawer
        isOpenDrawer={isOpen}
        className="drawerPaperSmall"
        anchor="right"
        onCloseDrawer={
          isSuccessSnackOpen ? handleNavigateToDeskLanding : handleClose
        }
        withCloseButton={isSuccessSnackOpen}
        onCloseButton={handleNavigateToDeskLanding}
      >
        <SlideAcross
          enterOn={!isSuccessSnackOpen || isCancel}
          duration={TRANSITION_DURATION}
        >
          <div className={classes.main}>
            <BackButton onBackPress={handleClose} />
            <Text text={t('reserveDesk.title')} className={classes.title} />
            <div
              className={clsx(
                classes.scrollBody,
                hasScrolled && classes.shadowWrapped
              )}
              onScroll={a =>
                setHasScrolled(
                  a.currentTarget && a.currentTarget.scrollTop > 10
                )
              }
            >
              <InfoBoxLocation grouping={grouping} profile={profile} />

              <InfoBox
                title={t('general.date')}
                descriptions={[strDate]}
                className={classes.infoBox}
                withAction
                actionText={t('general.edit')}
                onAction={handleUpdate}
                disabled={!isCancel || isDisabled || !canEdit}
              />

              <FieldArray
                name="reservations"
                render={() => (
                  <>
                    {arrReservations.map((r, idx) => {
                      const info = getDisplayInfoForReservation(r, t);
                      return (
                        <ResCard
                          input="resCard"
                          className={classes.infoBox}
                          title={`${t('general.desk').toUpperCase()} ${
                            idx + 1
                          } of ${reservations.length}`}
                          name={`reservations.${idx}.owner`}
                          accountSfId={accountSfId}
                          disabled={isCancel}
                          hasUnlimitedBalance={hasUnlimitedDayPasses}
                          passesRemaining={
                            hasUnlimitedDayPasses
                              ? t('general.unlimited')
                              : passesRemaining
                          }
                          onEdit={handleUpdate}
                          profile={profile}
                          locationsById={locationsById}
                          {...info}
                        />
                      );
                    })}
                  </>
                )}
              />
              {WITH_ADDONS &&
                distinctOffices.map(o => (
                  <Addons
                    {...rest}
                    className={classes.infoBox}
                    office={o}
                    addons={addonIds}
                    disabled={isCancel}
                  />
                ))}
              <div className={classes.footerActions}>
                <Text
                  text={t('reserveDesk.disclaimer')}
                  className={classes.disclaimer}
                />
                {!isDisabled && (
                  <div className={classes.footer}>
                    {isCancel ? (
                      <>
                        <BaseButton
                          name={t('reserveDesk.cancel_cta')}
                          onClick={handleSubmit}
                          disabled={!canCancel}
                          isLoading={isSubmitLoading && !isCancel}
                          className={
                            canEdit
                              ? classes.cancelButton
                              : classes.fullWidthCancelButton
                          }
                        />

                        {canEdit && (
                          <SubmitButton
                            defaultText={t('reserveDesk.update_cta')}
                            onClick={handleUpdate}
                            disabled={!canCancel}
                            isLoading={isSubmitLoading}
                            className={classes.cancelButton}
                          />
                        )}
                      </>
                    ) : (
                      <SubmitButton
                        defaultText={t('reserveDesk.cta')}
                        onClick={handleSubmit}
                        isLoading={isSubmitLoading && !isCancel}
                        datatestid="desk_confirm_reservation"
                      />
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
          <BannerSlide
            labelLeftAction={t('general.no_go_back')}
            labelRightAction={t('general.yes_cancel')}
            labelInfo={t('reserveDesk.cancel_confirmation')}
            onLeftActionPress={() => setShowCancelConfirmation(false)}
            onRightActionPress={handleSubmit}
            isSuccess={isSubmitSuccess}
            isSubmitting={isSubmitLoading}
            isShowing={showCancelConfirmation}
            variant="small"
          />
        </SlideAcross>
        <SlideConfirmationMessage
          enterOn={isSuccessSnackOpen && !isCancel}
          onClose={handleNavigateToConferenceRooms}
          className={classes.confirmationBackground}
          message={
            <div className={classes.slideTitle}>
              {t('reserveDesk.success_confirmation.title')}
              <div className={classes.slideMessage}>
                {t('reserveDesk.success_confirmation.message', {
                  locationName: `${profile?.brand?.name} ${location?.externalName}`,
                })}
              </div>
              <TextButton
                onClick={handleNavigateToConferenceRooms}
                text={t('reserveDesk.success_confirmation.cta')}
              />
            </div>
          }
        />
      </Drawer>
      <Snack
        message={t('reserveDesk.cancel_snack')}
        autoClose
        isOpen={isSuccessSnackOpen && isCancel}
        onClose={() => setSuccessSnackOpen(false)}
      />
      <Snack
        message={t('general.updates_failed')}
        autoClose
        isOpen={isFailSnackOpen}
        onClose={() => setFailSnackOpen(false)}
        variant="error"
      />
      <Snack
        message={t('reserveDesk.submit_conflict', {
          count: reservations.length,
        })}
        autoClose
        isOpen={isSubmitConflict}
        onClose={() => setSubmitConflict(false)}
      />
    </>
  );
};

const styles = theme => ({
  main: {
    background: colors.palette.secondary2.main,
    paddingTop: 30,
    paddingLeft: 20,
    paddingRight: 20,
    height: 'calc(100% - 30px)',
    [theme.breakpoints.up(breakpoints.MOBILE)]: {
      paddingLeft: 40,
      paddingRight: 40,
    },
  },
  title: {
    fontSize: 24,
    fontFamily: 'VerlagBold',
    paddingTop: 15,
    display: 'block',
    paddingBottom: 10,
  },
  infoBox: {
    marginTop: 20,
  },
  infoBoxTop: {
    marginTop: 10,
  },
  disclaimer: {
    fontSize: 12,
    fontFamily: 'VerlagLight',
    display: 'block',
    paddingTop: 20,
    paddingBottom: 10,
  },
  scrollBody: {
    overflowY: 'scroll',
    paddingBottom: 200,
    height: 'calc(100% - 273px)',
    '&::-webkit-scrollbar': {
      width: 0 /* Remove scrollbar space */,
      background: 'transparent' /* Optional: just make scrollbar invisible */,
    },
    scrollbarWidth: 'none',
  },
  shadowWrapped: {
    boxShadow: '0 0 4px 0 rgba(0, 0, 0, 0.25)',
    // height: 1,
    marginLeft: -20,
    paddingLeft: 20,
    paddingRight: 20,
    marginRight: -20,
    [theme.breakpoints.up(breakpoints.MOBILE)]: {
      marginLeft: -40,
      marginRight: -40,
      paddingLeft: 40,
      paddingRight: 40,
    },
  },
  footer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: 20,
    marginBottom: 40,
  },
  cancelButton: {
    width: 'calc(50% - 3px)',
    marginTop: 0,
    letterSpacing: 0,
  },
  fullWidthCancelButton: {
    width: '100%',
  },
  slideTitle: {
    fontSize: 20,
    fontFamily: 'VerlagBold',
  },
  slideMessage: {
    fontSize: 16,
    fontFamily: 'VerlagLight',
    marginTop: 10,
  },
  slideCta: {
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  confirmationBackground: {
    backgroundColor: colors.palette.secondary2.main,
  },
  footerActions: {
    background: colors.palette.secondary2.main,
    position: 'absolute',
    bottom: 0,
    right: 20,
    left: 20,
    height: 200,
    [theme.breakpoints.up(breakpoints.MOBILE)]: {
      right: 40,
      left: 40,
    },
  },
});

ReserveDesk.defaultProps = {
  isCancel: false,
  locationsById: {},
  reservations: [],
  accountSfId: '',
  addonIds: ['av_kit', 'whiteboard'],
  location: {},
  passesRemaining: 0,
  fetchUsage: () => {},
  onSubmit: () => {},
  handleResetForm: () => {},
  readOnly: false,
  onEdit: () => {},
  groupingId: '',
};

ReserveDesk.propTypes = {
  classes: PropTypes.object.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  reservations: PropTypes.arrayOf(PropTypes.object),
  addonIds: PropTypes.arrayOf(PropTypes.string),
  submitForm: PropTypes.func.isRequired,
  accountSfId: PropTypes.string,
  profile: PropTypes.object.isRequired,
  accountPasses: PropTypes.object.isRequired,
  isCancel: PropTypes.bool,
  locationsById: PropTypes.object,
  deleteReservations: PropTypes.func.isRequired,
  createReservations: PropTypes.func.isRequired,
  isValid: PropTypes.bool.isRequired,
  location: PropTypes.object,
  passesRemaining: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  fetchUsage: PropTypes.func,
  onSubmit: PropTypes.func,
  handleResetForm: PropTypes.func,
  readOnly: PropTypes.bool,
  history: PropTypes.object.isRequired,
  handleUpdateReservation: PropTypes.func.isRequired,
  handleCancelReservation: PropTypes.func.isRequired,
  handleCreateReservations: PropTypes.func.isRequired,
  displayGroupings: PropTypes.arrayOf(PropTypes.object).isRequired,
  groupingsById: PropTypes.object.isRequired,
  groupingsAllIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  onEdit: PropTypes.func,
  groupingId: PropTypes.string,
  hasUnlimitedDayPasses: PropTypes.bool.isRequired,
};

const mapPropsToValues = props => {
  const allProps = { ...ReserveDesk.defaultProps, ...props };
  const { reservations, addonIds } = allProps;
  const reservationsValues = reservations.map(r => ({
    ...r,
    owner: Lodash.get(r, 'owner'),
    ownerSfId: Lodash.get(r, 'ownerSfId'),
  }));

  const addonsFromReservations = reservations.reduce((acc, r) => {
    const officeSfId = Lodash.get(r, ['desk', 'parentOfficeSfId'], '');
    const reservationAddons = Lodash.get(r, 'reservationAddons', []);

    if (acc[officeSfId]) {
      return acc;
    }
    const addonValues = addonIds.reduce((addonAcc, id) => {
      const newAcc = { ...addonAcc };
      newAcc[id] = reservationAddons.includes(id);
      return newAcc;
    }, {});

    acc[officeSfId] = addonValues;
    return acc;
  }, {});

  return { reservations: reservationsValues, addons: addonsFromReservations };
};

const formikSchema = {
  mapPropsToValues,
  validationSchema: () => {
    return reserveDeskSchema;
  },
  isInitialValid: props =>
    reserveDeskSchema.isValidSync(mapPropsToValues(props)),
  enableReinitialize: true,
  handleSubmit: () => {},
};

export default withFormik(formikSchema)(withStyles(styles)(ReserveDesk));
