/*eslint-disable */

import axios from 'axios';
import FormData from 'form-data';
import { GatewayServices, getGatewayUrl } from '../Utils/gateway';
import getApiUrl from '../Utils/api';

export const updateUserAvatar = (userSfId, image) => {
  const data = new FormData();
  data.append('file', image);
  return axios.post(`/users/${userSfId}/image`, data, {
    headers: {
      'Content-Type': `multipart/form-data  ; boundary=${data._boundary}`,
    },
  });
};

export const updateAccountAvatar = (accountSfId, image) => {
  const data = new FormData();
  data.append('file', image);
  return axios.post(`/accounts/${accountSfId}/image`, data, {
    headers: {
      'Content-Type': `multipart/form-data  ; boundary=${data._boundary}`,
    },
  });
};

export const userLogin = () => {
  return axios.put('/users/login');
};

export const fetchUserInfo = (id, apiVersion) => {
  return axios.get(`/users/${id}?with_groupings=true`, {
    baseURL:
      apiVersion != null
        ? getApiUrl(apiVersion, process.env.REACT_APP_API_HOST)
        : undefined,
  });
};

export const fetchUserBalance = (
  accountSfId,
  dateEpoch,
  includeUsages = false
) => {
  return axios.get(
    `/accounts/${accountSfId}/balance?date_epoch=${dateEpoch}&include_usages=${includeUsages}`
  );
};

export const patchUser = (fields, userId) => {
  return axios.patch(`/users/${userId}`, fields);
};

export const putAccount = (sfId, obj) => {
  return axios.put(`/accounts/${sfId}`, obj);
};

export const fetchUserPickList = () => {
  return axios.get('/users/picklist');
};

export const fetchAccountPickList = () => {
  return axios.get('/accounts/picklist');
};

export const fetchNotificationSettings = sfId => {
  return axios.get(`/users/${sfId}/notificationSettings`);
};

export const patchNotificationSettings = (sfId, obj) => {
  return axios.put(`/users/${sfId}/notificationSettings`, obj);
};

export const fetchUserDoorPinCode = userId => {
  return axios.get(`/users/${userId}/pin`, {
    baseURL: getGatewayUrl(GatewayServices.access),
  });
};

export const fetchAcsUser = userId => {
  return axios.get(`/users/${userId}`, {
    baseURL: getGatewayUrl(GatewayServices.access),
  });
};

export const resetUserDoorPinCode = userId => {
  return axios.put(
    `/users/${userId}/pin`,
    {},
    {
      baseURL: getGatewayUrl(GatewayServices.access),
    }
  );
};

export const getBookingRestrictions = (userSfId, month, locationSfId) => {
  return axios.get(
    `/users/${userSfId}/booking_restrictions?month_epoch=${month}&location_sfid=${locationSfId}`
  );
};
