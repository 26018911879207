import * as React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import clsx from 'clsx';
import { Fade } from '@material-ui/core';
import Lodash from 'lodash';
import { bindActionCreators } from 'redux';
import { Formik } from 'formik';
import * as Yup from 'yup';
import Moment from 'moment';

// Components
import { GroupingFeatureKey } from '@industriousoffice/member-portal-rbac/dist/types';
import {
  LocationEditor,
  CollapsibleText,
  Snack,
  SelectInput,
  Tabs,
  Loading,
  TextButton,
  BackButton,
} from '../../Components/Common';
import { DeskFloorPlan } from '../../Components';
import { UnassignOffice, DeskToggleForm, OfficeAssign } from './Components';
import OfficeFormPanel from './Components/OfficeFormPanel';

// Core
import { breakpoints, colors } from '../../Core/Theme';
import { ViewModes } from './viewModes.enum.ts';
import { OfficeTypes, OfficeStatuses } from '../../Models/office';

import { getIsMobile } from '../../Core/Utils/windowDimensions';

// Redux
import {
  getReservationsById,
  getReservationsAllIds,
  getDesksLookup,
} from '../../Redux/Common/Reservations/selectors';
import reservationRedux from '../../Redux/Common/Reservations';

// Api
import {
  activateReservableOffice,
  deactivateReservableOffice,
  toggleDesks,
  fetchFloorMapsOffices,
  getDeskAvailability,
  fetchOffices,
} from '../../Core/Api';
import { trackManageWorkspacesClick } from '../../Core/Tracking';

// Utils
import {
  getDeskDto,
  updateOffice,
  mergeSelectedDesksWithNewDesks,
  updateOfficeDesks,
  getReservationsIdByOfficeId,
  getReservationsIdByDeskIds,
  sortByOfficeName,
} from './utils';
import { Office } from '../../Models';
import { useFloorSelection, useLocations } from '../../Hooks';
import { useGroupings } from '../../Hooks/useGroupings';
import OfficesCount from '../ManageOffices/Components/OfficesCount';
import { EmptyStateLevels } from '../ManageOffices/Components/EmptyStateLevels.enum';
import EmptyState from '../ManageOffices/Components/EmptyState';

const TRANSITION_DURATION = 400;

const ZEN_DESK_URL = 'https://industrious.zendesk.com/hc/en-us/requests/new';

const schema = Yup.object().shape({});

const ManageDesks = props => {
  const {
    classes,
    deleteReservations,
    reservationsLookup,
    reservationsAllIds,
    desksLookup,
  } = props;

  const { t } = useTranslation();

  const [selectedGrouping, setSelectedGrouping] = React.useState(null);
  const {
    floorOptions,
    selectedFloor,
    isFloorSelectedInitialised,
    setSelectedFloor,
  } = useFloorSelection(selectedGrouping);

  useLocations();

  const {
    displayGroupings,
    isLoading: isLoadingGroupings,
    defaultGrouping,
  } = useGroupings(GroupingFeatureKey.HotDesk);

  const [isOfficeSelectedOnFloorPlan, setIsOfficeSelectedOnFloorPlan] =
    React.useState(false);
  const [isFocusingOver, setFocusingOver] = React.useState(true);
  const [isUnFocusingOver, setUnFocusingOver] = React.useState(true);
  const [selectedOffice, setSelectedOffice] = React.useState(null);
  const [selectedDesks, setSelectedDesks] = React.useState([]);

  const [viewMode, setViewMode] = React.useState(ViewModes.List);

  const [isSuccessSnackOpen, setSuccessSnack] = React.useState(false);
  const [isFailSnackOpen, setFailSnack] = React.useState(false);
  const [isSubmittingDesks, setSubmittingDesks] = React.useState(false);
  const [hasSubmittedDesks, setSubmittedDesks] = React.useState(false);
  const [isToggleDrawerOpen, setToggleDrawerOpen] = React.useState(false);

  const [officesMap, setOfficesMap] = React.useState([]);
  const [officesList, setOfficesList] = React.useState([]);

  const [mapStyleId, setMapStyleId] = React.useState(null);
  const [mapboxDatasetId, setMapDatasetId] = React.useState(null);

  const [isLoadingOffices, setIsLoadingOffices] = React.useState(false);
  const [isLoadingMap, setIsLoadingMap] = React.useState(false);
  const isLoading =
    isLoadingOffices || isLoadingMap || !isFloorSelectedInitialised;

  const [confirmDeskAnchor, setDeskAnchorEl] = React.useState(null);
  const [selectedDeskToToggle, setSelectedDeskToToggle] = React.useState(null);

  const [deskAvailabilities, setDeskAvailabilites] = React.useState([]);

  const isMobile = getIsMobile();

  const [officeEditing, setOfficeEditing] = React.useState();

  const [officeEditingSuccessMsg, setOfficeEditingSuccessMsg] =
    React.useState();

  const [refresher, setRefresher] = React.useState();

  const fetchAndSaveDeskAvailability = floorId => {
    getDeskAvailability({
      startEpoch: Moment().startOf('day').unix(),
      groupingIds: [selectedGrouping?.idGrouping],
      floorId,
    }).then(objResponse => {
      const { data } = objResponse;
      setDeskAvailabilites(data);
    });
  };

  const loadOffices = () => {
    setIsLoadingOffices(true);
    fetchOffices({
      locationSfId: selectedGrouping.locationSfId,
      groupingId: selectedGrouping.idGrouping,
      floorId: selectedFloor?.idFloor,
      withPendingStatus: true,
      withOccupiedStatus: true,
      withSuites: true,
    })
      .then(res => {
        setIsLoadingOffices(false);
        setOfficesList(res.data);
      })
      .catch(() => setIsLoadingOffices(false));
  };

  const loadOfficesMap = (styleId, datasetId) => {
    setIsLoadingMap(true);

    fetchFloorMapsOffices({
      floorId: selectedFloor?.idFloor,
      locationSfId: selectedGrouping.locationSfId,
      onlyIncludeOasis: false,
    })
      .then(res => {
        const { data } = res;
        setMapStyleId(styleId);
        setMapDatasetId(datasetId);
        setIsLoadingMap(false);

        if (selectedOffice && data) {
          setSelectedOffice(data.find(x => x.id === selectedOffice.id));
        }

        setOfficesMap(data);
      })
      .catch(() => setIsLoadingMap(false));
  };

  React.useEffect(() => {
    if (!selectedGrouping && defaultGrouping) {
      setSelectedGrouping(defaultGrouping);
    }
  });

  React.useEffect(() => {
    (async () => {
      if (!isFloorSelectedInitialised) {
        return;
      }
      const floorId = selectedFloor?.idFloor;
      const floorMappings = Lodash.get(
        selectedFloor,
        ['mapboxDatasetMappings'],
        []
      );
      const floorMappingsOrdered = Lodash.orderBy(
        floorMappings,
        ['createdOn'],
        ['desc']
      );
      const floorMapping = Lodash.head(floorMappingsOrdered);
      const strMapStyleId = Lodash.get(floorMapping, ['mapboxStyleId'], null);
      const strMapboxDatasetId = Lodash.get(
        floorMapping,
        ['mapboxDatasetId'],
        null
      );

      if (
        (Lodash.isNil(strMapboxDatasetId) || Lodash.isNil(strMapStyleId)) &&
        viewMode === ViewModes.Floormap
      ) {
        setMapStyleId(null);
        setMapDatasetId(null);
        return;
      }

      fetchAndSaveDeskAvailability(floorId);

      loadOffices();
      loadOfficesMap(strMapStyleId, strMapboxDatasetId);
    })();
  }, [selectedFloor, refresher]);

  const closeEditPanel = () => {
    setOfficeEditing();
  };

  const showEditPanel = office => {
    setOfficeEditing(office);
  };

  const showEditPanelFromFloorPlan = officeMap => {
    const officeToEdit = officesList.find(
      office => office.sfId === officeMap.sfId
    );
    showEditPanel(officeToEdit);
  };

  const refreshOffices = () => {
    setRefresher({});
  };

  const focus = objOffice => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    setFocusingOver(false);
    setIsOfficeSelectedOnFloorPlan(true);
    const { desks } = objOffice;
    setSelectedDesks(Lodash.sortBy(desks, 'deskNumber'));
    return setSelectedOffice(objOffice);
  };

  const unFocus = () => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    setUnFocusingOver(false);
    setIsOfficeSelectedOnFloorPlan(false);
  };

  const closeFailSnack = () => setFailSnack(false);
  const closeSuccessSnack = () => setSuccessSnack(false);
  const openSuccessSnack = () => setSuccessSnack(true);
  const openFailSnack = () => setFailSnack(true);

  const handleOfficeSuccess = objResponse => {
    const { data } = objResponse;
    const { desks } = data;
    const newDesks = mergeSelectedDesksWithNewDesks(selectedDesks, desks);
    setSelectedOffice(data);
    setSelectedDesks(newDesks);
    setOfficesList(updateOffice(officesList, data, newDesks));
    openSuccessSnack();
  };

  const handleOfficeListSuccess = responseOffice => {
    const officeFromMap = officesMap.find(
      office => office.sfId === responseOffice.sfId
    );

    // The office could not be in the floor plan
    if (officeFromMap) {
      const newDesks = mergeSelectedDesksWithNewDesks(
        officeFromMap.desks,
        responseOffice.desks
      );
      setOfficesMap(updateOffice(officesMap, responseOffice, newDesks));
    }

    setOfficesList(
      updateOffice(officesList, responseOffice, responseOffice.desks)
    );
    fetchAndSaveDeskAvailability(selectedFloor.idFloor);
    const inactiveDeskIds = responseOffice.desks
      .filter(d => !d.isBookable)
      .map(d => d.idDesk);
    if (inactiveDeskIds.length) {
      const getReservations = getReservationsIdByDeskIds(inactiveDeskIds);
      const arrReservationsId = getReservations({
        reservationsLookup,
        reservationsAllIds,
      });
      if (arrReservationsId.length > 0) deleteReservations(arrReservationsId);
    }
  };

  const toggleOffice = officeType => {
    const officeSfId = selectedOffice.sfId;
    const { idGrouping } = selectedGrouping;
    const { idFloor } = selectedFloor;

    const getReservations = getReservationsIdByOfficeId(officeSfId);
    if (officeType === OfficeTypes.UnReservable) {
      const arrReservationsId = getReservations({
        desksLookup,
        reservationsLookup,
        reservationsAllIds,
      });
      deactivateReservableOffice(officeSfId).then(
        handleOfficeSuccess,
        openFailSnack
      );
      if (arrReservationsId.length > 0) deleteReservations(arrReservationsId);
    }
    if (officeType === OfficeTypes.Reservable) {
      fetchAndSaveDeskAvailability(idFloor);
      activateReservableOffice(officeSfId, idGrouping).then(
        handleOfficeSuccess,
        openFailSnack
      );
    }
  };

  const updateDesks = async (values, objFormik) => {
    const dto = await getDeskDto(values, selectedDesks);
    setSubmittingDesks(true);
    trackManageWorkspacesClick('Save Changes');
    const { resetForm } = objFormik;
    const { idFloor } = selectedFloor;

    toggleDesks(dto).then(
      objResponse => {
        const { data } = objResponse;
        openSuccessSnack();
        setSubmittedDesks(true);
        setSubmittingDesks(false);
        setTimeout(() => {
          setSubmittedDesks(false);
          resetForm();
        }, 1500);
        setTimeout(() => setToggleDrawerOpen(false), 500);
        const newDesks = mergeSelectedDesksWithNewDesks(selectedDesks, data);
        setSelectedDesks(newDesks);
        setOfficesMap(updateOfficeDesks(officesMap, newDesks));
        setOfficesList(updateOfficeDesks(officesList, newDesks));
      },
      () => {
        openFailSnack();
        setToggleDrawerOpen(false);
        setSubmittingDesks(false);
      }
    );
    fetchAndSaveDeskAvailability(idFloor);
  };

  const handleSelectedFloorChange = floor => {
    trackManageWorkspacesClick('Floor');
    setSelectedFloor(floor);
  };

  const getInitialValues = () => {
    return selectedDesks.reduce((acc, d) => {
      const { idDesk, isBookable } = d;
      acc[idDesk] = selectedOffice?.isOasisReservable && isBookable;
      return acc;
    }, {});
  };

  const handleOnUpdated = () => {
    refreshOffices();
    closeEditPanel();
    setOfficeEditingSuccessMsg(t('manageOffices.office_form.success_msg'));
  };

  const handleCloseOfficeEditSuccessMsg = () => {
    setOfficeEditingSuccessMsg();
  };

  const offices = objFormik => {
    const { values, setFieldValue } = objFormik;
    const desksWithValues = Lodash.isEmpty(objFormik)
      ? selectedDesks
      : selectedDesks.map(d => {
          const { idDesk } = d;
          return { ...d, isBookable: Lodash.get(values, idDesk, false) };
        });

    const officeName = selectedOffice && Office.getOfficeName(selectedOffice);

    const isOasisReservable = selectedOffice?.isOasisReservable;

    const handleOnOfficePress = objOffice => {
      trackManageWorkspacesClick('Manage Office');
      return focus(objOffice);
    };

    const handleUnfocusOffice = () => {
      const newOffices = updateOffice(
        officesMap,
        selectedOffice,
        selectedDesks
      );
      return setOfficesMap(newOffices);
    };

    const handleOnOverlayPress = () => {
      trackManageWorkspacesClick('Drawer Close Cancel');
      handleUnfocusOffice();
      return unFocus();
    };

    const handleUnFocus = () => {
      setSelectedOffice(null);
      handleUnfocusOffice();
      return unFocus();
    };

    const handleDeskPress = objDesk => {
      if (!objDesk) {
        return;
      }
      const { idDesk } = objDesk;
      const deskAvailability = Lodash.find(deskAvailabilities, {
        deskId: idDesk,
      });
      if (selectedOffice.isOasisReservable) {
        if (
          Lodash.get(values, idDesk) &&
          !isMobile &&
          Lodash.get(deskAvailability, 'isReservedAfter')
        ) {
          setSelectedDeskToToggle(objDesk);
        }
        setFieldValue(idDesk, !Lodash.get(values, idDesk, false));
      }
    };

    const hasAnyMappedFloors = floorOptions?.some(
      floor => floor.value.mapboxDatasetMappings?.length
    );
    const hasFloors = !!floorOptions?.length;

    const tabs = [
      {
        text: t('manageOffices.view_types.list'),
        id: ViewModes.List,
      },
      {
        text: t('manageOffices.view_types.floorplan'),
        id: ViewModes.Floormap,
      },
    ];
    const handleSwitchMode = () => {
      setViewMode(
        viewMode === ViewModes.List ? ViewModes.Floormap : ViewModes.List
      );
    };

    const handleSelectedLocationChange = grouping => {
      setSelectedGrouping(grouping);
    };

    const handleSelectedGroupingChange = grouping => {
      setSelectedGrouping(grouping);
      refreshOffices();
    };

    const getOfficesListSorted = () => {
      const officesSortedByNames = officesList.sort(sortByOfficeName);

      const occupiedOffices = officesSortedByNames.filter(
        o => o.status === OfficeStatuses.Licensed
      );
      const otherOffices = officesSortedByNames.filter(
        o => o.status !== OfficeStatuses.Licensed
      );
      return [
        ...occupiedOffices.filter(o => o.isOasisReservable),
        ...otherOffices,
        ...occupiedOffices.filter(o => !o.isOasisReservable),
      ];
    };

    const displayedOffices = getOfficesListSorted();
    const nbOffices = displayedOffices.length;
    const nbReservableOffices = displayedOffices.filter(
      office =>
        office.status === OfficeStatuses.Available && office.isOasisReservable
    ).length;

    const getHasFutureReservation = React.useCallback(
      office => {
        const deskIds = office.desks?.map(d => d.idDesk);
        return deskAvailabilities
          .filter(a => deskIds?.includes(a.deskId))
          .some(a => a.isReservedAfter);
      },
      [deskAvailabilities]
    );

    return (
      <div className={classes.wrapper}>
        <div className={clsx(classes.header, classes.section)}>
          <div>
            <>
              <Fade
                in={isOfficeSelectedOnFloorPlan && isFocusingOver}
                timeout={TRANSITION_DURATION / 2 - 50}
                unmountOnExit
                onExited={() => {
                  setUnFocusingOver(true);
                  setSelectedOffice(null);
                  setSelectedDesks([]);
                }}
                // this timing is to make sure this fade transition finishes before the other fade enters
              >
                <div className={classes.floorPlanOfficeHeader}>
                  <BackButton
                    onBackPress={handleUnFocus}
                    label={t('manageOffices.focused_back').toUpperCase()}
                    className={classes.back}
                  />
                  {selectedOffice &&
                    selectedOffice.status === OfficeStatuses.Licensed && (
                      <div className={classes.licensedOffice}>
                        {t('general.licensed')}
                      </div>
                    )}
                  <div className={classes.officeName}>
                    <span className={classes.title}>{officeName}</span>
                  </div>
                  {selectedOffice && (
                    <UnassignOffice
                      disablePointerEvents={!!officeEditing}
                      officeName={officeName}
                      toggleOffice={toggleOffice}
                      office={selectedOffice}
                      hasFutureReservation={getHasFutureReservation(
                        selectedOffice
                      )}
                    />
                  )}
                  <TextButton
                    text={t('manageOffices.edit_office')}
                    onClick={() => showEditPanelFromFloorPlan(selectedOffice)}
                    className={classes.editButton}
                  />
                </div>
              </Fade>
              <Fade
                in={!isOfficeSelectedOnFloorPlan && isUnFocusingOver}
                // this timing is to make sure this fade transition finishes before the other fade enters
                timeout={TRANSITION_DURATION / 2 - 50}
                unmountOnExit
                onExited={() => setFocusingOver(true)}
              >
                <div>
                  <Grid container spacing={8}>
                    <Grid item xs={12} sm={7} className={classes.row}>
                      <div className={classes.title}>
                        {t('manageDesks.title')}
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={5} className={classes.row}>
                      <LocationEditor
                        selectedGrouping={selectedGrouping}
                        selectLocation={handleSelectedLocationChange}
                        selectGrouping={handleSelectedGroupingChange}
                        datatestid="manage_workspaces_location_editor"
                        showYourLocationSection
                        locationGroupings={displayGroupings}
                        userDefaultGrouping={defaultGrouping}
                        isLoading={isLoadingGroupings}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <CollapsibleText
                        lines={2}
                        maxLines={6}
                        className={classes.description}
                      >
                        {t('manageOffices.description')}
                        <div>
                          <span className={classes.zenDeskLabel}>
                            {t('manageOffices.zen_desk_label')}
                          </span>
                          <TextButton
                            text={t('manageOffices.zen_desk_cta')}
                            onClick={() => window.open(ZEN_DESK_URL)}
                            className={classes.zenDeskCta}
                          />
                        </div>
                      </CollapsibleText>
                    </Grid>
                  </Grid>
                </div>
              </Fade>
            </>
          </div>
        </div>
        <div className={classes.center}>
          <div className={classes.content}>
            {!isOfficeSelectedOnFloorPlan && (
              <div className={classes.contentHeader}>
                <div className={classes.contentHeaderLeft}>
                  {floorOptions && (
                    <SelectInput
                      disabled={false}
                      options={floorOptions}
                      onChange={handleSelectedFloorChange}
                      variant="boxed"
                      value={selectedFloor}
                      className={classes.floorInput}
                      noCaratWithSingleOption
                      inputClassName={classes.select}
                      classNameCarat={classes.selectCarat}
                    />
                  )}
                  {displayedOffices && !isLoading && (
                    <OfficesCount
                      nbOffices={nbOffices}
                      nbReservableOffices={nbReservableOffices}
                    />
                  )}
                </div>
                <Tabs
                  legends={tabs}
                  variant="boxed"
                  selectedId={viewMode}
                  onTabClick={handleSwitchMode}
                  className={clsx(
                    classes.hideOnMobile,
                    isOfficeSelectedOnFloorPlan && classes.hidden
                  )}
                  tabClassName={classes.tab}
                />
              </div>
            )}

            {viewMode === ViewModes.List ? (
              <div
                className={clsx(
                  classes.listView,
                  mapStyleId && mapboxDatasetId && classes.listViewWithPadding
                )}
              >
                {(!mapStyleId || !mapboxDatasetId) &&
                  !isLoadingMap &&
                  officesList.length > 0 && (
                    <div
                      className={clsx(
                        classes.errorMessage,
                        classes.hideOnMobile
                      )}
                    >
                      {t('manageOffices.no_floorplan_yet')}
                    </div>
                  )}

                {!isLoadingOffices &&
                  !isLoadingMap &&
                  officesList.length === 0 &&
                  isFloorSelectedInitialised && (
                    <EmptyState
                      level={
                        hasFloors
                          ? EmptyStateLevels.Floor
                          : EmptyStateLevels.Location
                      }
                    />
                  )}

                {isLoading ? (
                  <Loading />
                ) : (
                  displayedOffices.map(office => (
                    <div className={classes.officeItem} key={office.sfId}>
                      <OfficeAssign
                        key={office.id}
                        office={office}
                        selectedGrouping={selectedGrouping}
                        handleOfficeListSuccess={handleOfficeListSuccess}
                        deskAvailabilities={deskAvailabilities}
                        hasFutureReservation={getHasFutureReservation(office)}
                        onEditBtnClicked={() => showEditPanel(office)}
                      />
                    </div>
                  ))
                )}
              </div>
            ) : (
              <div
                className={clsx(
                  classes.section,
                  classes.floorPlanSection,
                  classes.calendarSection
                )}
              >
                <div className={classes.floorPlan}>
                  <DeskFloorPlan
                    onOverlayPress={handleOnOverlayPress}
                    selectedOffice={Lodash.get(selectedOffice, 'sfId', null)}
                    offices={officesMap}
                    desks={desksWithValues}
                    mapStyleId={mapStyleId}
                    datasetId={mapboxDatasetId}
                    isLoading={isLoadingMap}
                    onOfficePress={handleOnOfficePress}
                    onDeskPress={handleDeskPress}
                    hasAnyMappedFloors={hasAnyMappedFloors}
                    selectedFloor={selectedFloor}
                    selectedGrouping={selectedGrouping?.idGrouping}
                  />
                </div>

                {isOfficeSelectedOnFloorPlan && (
                  <DeskToggleForm
                    saveForm={updateDesks}
                    isOpen={isOfficeSelectedOnFloorPlan}
                    isUnassigned={!isOasisReservable}
                    variant="panel"
                    desks={selectedDesks}
                    isLoading={isSubmittingDesks}
                    isSuccess={hasSubmittedDesks}
                    objFormik={objFormik}
                    confirmAnchor={confirmDeskAnchor}
                    setAnchorEl={setDeskAnchorEl}
                    setSelectedDesk={setSelectedDeskToToggle}
                    selectedDesk={selectedDeskToToggle}
                    deskAvailabilities={deskAvailabilities}
                  />
                )}
              </div>
            )}
          </div>
        </div>
        {isOfficeSelectedOnFloorPlan && (
          <DeskToggleForm
            saveForm={updateDesks}
            isUnassigned={!isOasisReservable}
            desks={selectedDesks}
            isLoading={isSubmittingDesks}
            isSuccess={hasSubmittedDesks}
            isOpen={isToggleDrawerOpen}
            setOpen={setToggleDrawerOpen}
            objFormik={objFormik}
            confirmAnchor={confirmDeskAnchor}
            setAnchorEl={setDeskAnchorEl}
            setSelectedDesk={setSelectedDeskToToggle}
            selectedDesk={selectedDeskToToggle}
            deskAvailabilities={deskAvailabilities}
          />
        )}
        <Snack
          message={t('general.updates_saved')}
          autoClose
          isOpen={isSuccessSnackOpen}
          onClose={closeSuccessSnack}
        />
        <Snack
          message={t('general.updates_failed')}
          autoClose
          isOpen={isFailSnackOpen}
          onClose={closeFailSnack}
          variant="error"
        />
      </div>
    );
  };

  return (
    <>
      <Formik
        validationSchema={schema}
        initialValues={getInitialValues()}
        onSubmit={updateDesks}
        enableReinitialize
      >
        {offices}
      </Formik>

      <OfficeFormPanel
        office={officeEditing}
        onClose={closeEditPanel}
        onUpdated={handleOnUpdated}
      />

      <Snack
        isOpen={!!officeEditingSuccessMsg}
        message={officeEditingSuccessMsg}
        onClose={handleCloseOfficeEditSuccessMsg}
        autoClose
      />
    </>
  );
};

const styles = theme => ({
  wrapper: {
    overflow: 'hidden',
    // 80 is the smallest the header can be so the most we can safely remove here
    minHeight: 'calc(100vh - 80px)',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: colors.palette.secondary2.main,
  },
  header: {
    background: colors.white,
    boxShadow: '0 0 4px 0 rgba(0, 0, 0, 0.1)',
  },
  contentHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    marginTop: '1rem',
  },
  contentHeaderLeft: {
    display: 'flex',
    gap: '1rem',
    alignItems: 'center',
  },
  listView: {
    position: 'relative',
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
  },
  listViewWithPadding: {
    paddingTop: 10,
  },
  selectFloor: {
    background: colors.white,
    padding: 16,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    boxShadow: '0 0 4px 0 rgba(0, 0, 0, 0.25)',
  },
  errorMessage: {
    fontFamily: 'VerlagBold',
    fontSize: 18,
    marginBottom: 20,
  },
  hideOnMobile: {
    [theme.breakpoints.down(breakpoints.MOBILE)]: {
      display: 'none',
    },
  },
  showOnlyOnMobile: {
    [theme.breakpoints.up(breakpoints.MOBILE)]: {
      display: 'none',
    },
  },
  tab: {
    paddingTop: '4px',
    paddingBottom: '4px',
  },
  officeItem: {
    width: 'calc(100% - 40px)',
    marginBottom: 10,
    [theme.breakpoints.up(breakpoints.MOBILE)]: {
      width: '100%',
      marginBottom: 20,
    },
  },
  section: {
    paddingTop: 25,
    paddingLeft: 20,
    paddingRight: 20,
    paddingBottom: 15,
    [theme.breakpoints.up(breakpoints.MOBILE)]: {
      paddingTop: 20,
      paddingLeft: 40,
      paddingRight: 40,
      position: 'relative',
    },
  },
  row: {
    [theme.breakpoints.up(breakpoints.MOBILE)]: {
      marginTop: 5,
      marginBottom: 5,
    },
  },
  title: {
    fontSize: 24,
    fontFamily: 'VerlagBold',
    marginTop: 5,
    marginBottom: 2,
    [theme.breakpoints.up(breakpoints.MOBILE)]: {
      fontSize: 36,
      marginTop: 0,
      marginBottom: 0,
    },
  },
  description: {
    paddingBottom: 5,
    paddingTop: 10,
    [theme.breakpoints.up(breakpoints.MOBILE)]: {
      paddingBottom: 10,
      paddingTop: 0,
    },
  },
  locationEditor: {
    marginTop: 10,
    [theme.breakpoints.up(breakpoints.MOBILE)]: {
      marginTop: 17,
    },
  },
  officeName: {
    textAlign: 'center',
    marginBottom: 10,
    marginTop: 5,
    [theme.breakpoints.up(breakpoints.MOBILE)]: {
      marginTop: 20,
    },
  },
  calendarSection: {
    flex: 1,
  },
  select: {
    borderRadius: '9999px !important',
    paddingTop: 2,
    paddingBottom: 2,
  },
  selectCarat: {
    top: `calc(50% - 8px)`,
    right: 10,
    position: `absolute`,
    pointerEvents: `none`,
  },
  filterByLabel: {
    textTransform: 'uppercase',
    textAlign: 'center',
    marginBottom: 5,
    fontSize: 14,
  },
  floorPlanSection: {
    paddingLeft: 0,
    paddingTop: 0,
    paddingRight: 0,
    position: 'relative',
    backgroundColor: colors.palette.secondary2.main,
  },
  center: {
    display: 'flex',
    justifyContent: 'center',
  },
  content: {
    width: 'calc(100vw - 2rem)',
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
    padding: '1rem',
    alignItems: 'center',
    [theme.breakpoints.up(breakpoints.SM)]: {
      maxWidth: 670,
    },
    [theme.breakpoints.up(breakpoints.MOBILE)]: {
      padding: '1rem 7rem',
      width: '100%',
    },
  },
  hidden: {
    display: 'none',
  },
  licensedOffice: {
    fontSize: 12,
    fontFamily: 'VerlagBold',
    color: colors.darkGray,
    textTransform: 'uppercase',
    textAlign: 'center',
    marginBottom: -18,
  },

  zenDeskLabel: {
    fontFamily: 'VerlagBold',
  },
  zenDeskCta: {
    fontSize: 15,
    padding: 0,
    [theme.breakpoints.up(breakpoints.MOBILE)]: {
      fontSize: 18,
    },
  },
  editButton: {
    textDecoration: 'underline',
    fontFamily: 'VerlagBold',
  },
  floorPlanOfficeHeader: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    position: 'relative',
  },
  back: {
    left: 0,
    position: 'absolute',
  },
});

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      deleteReservations: reservationRedux.actions.deleteReservations,
    },
    dispatch
  );
};

const mapStateToProps = state => {
  return {
    reservationsLookup: getReservationsById(state),
    reservationsAllIds: getReservationsAllIds(state),
    desksLookup: getDesksLookup(state),
  };
};

ManageDesks.defaultProps = {};

ManageDesks.propTypes = {
  deleteReservations: PropTypes.func.isRequired,
  reservationsLookup: PropTypes.shape({}).isRequired,
  reservationsAllIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  desksLookup: PropTypes.shape({}).isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(ManageDesks));
