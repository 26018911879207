import { StyleRulesCallback, withStyles } from '@material-ui/core';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import { useTranslation } from 'react-i18next';
import { colors } from '../../../Core/Theme';

type Props = {
  classes: ClassNameMap;
  teammate: string;
};

const ReservedByTeammate = (props: Props) => {
  const { classes, teammate } = props;
  const { t } = useTranslation();

  return (
    <div>
      <span className={classes.label}>
        {t<string>('reserveOffice.reserved_by_teammate')}
      </span>
      <span className={classes.teammate}>{teammate}</span>
    </div>
  );
};

const styles: StyleRulesCallback<string> = () => ({
  label: {
    fontSize: 14,
    fontFamily: 'VerlagBold',
    marginRight: '0.5rem',
    letterSpacing: 'normal',
  },
  teammate: {
    fontSize: 14,
    fontWeight: 300,
    fontFamily: 'VerlagLight',
    color: colors.palette.primary.main,
  },
});

export default withStyles(styles)(ReservedByTeammate);
