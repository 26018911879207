import * as React from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Lodash from 'lodash';
import clsx from 'clsx';

import { SubmitButton, Popover, TextButton } from '../../../Components/Common';
import { colors, breakpoints } from '../../../Core/Theme';
import FormikInputs from '../../../Components/FormikInputs';

// Api
import { trackManageWorkspacesClick } from '../../../Core/Tracking';

const DeskToggleContents = props => {
  const {
    classes,
    desks,
    submitForm,
    className,
    withFooter,
    dirty,
    isUnassigned,
    isLoading,
    isSuccess,
    setFieldValue,
    values,
    confirmAnchor,
    setAnchorEl,
    setSelectedDesk,
    selectedDesk,
    deskAvailabilities,
  } = props;
  const { t } = useTranslation();
  const selectedDeskRef = React.createRef();

  React.useEffect(() => {
    if (selectedDesk && selectedDeskRef?.current) {
      setAnchorEl(selectedDeskRef.current);
    }
  }, [selectedDesk]);

  const handleClosePopover = () => {
    setAnchorEl(null);
    setTimeout(() => setSelectedDesk(null), 500);
  };

  const onCancel = () => {
    const { idDesk } = selectedDesk;
    setFieldValue(idDesk, true);
    handleClosePopover();
  };

  const handleChange = desk => (val, e) => {
    if (val) {
      trackManageWorkspacesClick('Enable Desk');
    } else {
      trackManageWorkspacesClick('Disable Desk');
    }

    const { idDesk } = desk;
    const deskAvailability = Lodash.find(deskAvailabilities, {
      deskId: idDesk,
    });
    if (values[idDesk] && Lodash.get(deskAvailability, 'isReservedAfter')) {
      setAnchorEl(e.currentTarget);
      setSelectedDesk(desk);
    } else {
      handleClosePopover();
    }
  };

  return (
    <div className={clsx(className, classes.main)}>
      <div className={classes.scroll}>
        {desks.map(d => {
          const { idDesk, deskNumber } = d;
          return (
            <div
              className={classes.row}
              ref={
                idDesk === Lodash.get(selectedDesk, 'idDesk')
                  ? selectedDeskRef
                  : null
              }
            >
              <FormikInputs
                input="switch"
                name={`${idDesk}`}
                label={`${t('general.desk')} ${deskNumber}`}
                labelClass={classes.virtualSwitchLabel}
                withTextNotifier
                disabled={isUnassigned}
                onChange={handleChange(d)}
              />
            </div>
          );
        })}
      </div>
      {withFooter && (
        <div className={classes.footer}>
          <SubmitButton
            defaultText={t('general.save_updates')}
            onClick={submitForm}
            className={classes.submit}
            disabled={!dirty}
            isLoading={isLoading}
            isSuccess={isSuccess}
          />
        </div>
      )}
      <Popover
        anchorEl={confirmAnchor}
        onCancel={onCancel}
        onConfirm={handleClosePopover}
        variant="confirmation"
        description={
          <div>
            {t('manageOffices.confirm_unassign_desk_part1', {
              deskNumber: Lodash.get(selectedDesk, 'deskNumber', ''),
            })}
            <TextButton
              text={t('manageOffices.all_desk_reservations')}
              size="medium"
              className={classes.seeDesks}
            />
            {t('manageOffices.confirm_unassign_desk_part2')}
          </div>
        }
      />
    </div>
  );
};

const styles = theme => ({
  main: {},
  row: {
    paddingTop: 15,
    paddingBottom: 15,
    borderTop: `solid 1px ${colors.light}`,
    [theme.breakpoints.down(breakpoints.MOBILE)]: {
      marginLeft: 40,
      marginRight: 40,
    },
  },
  submit: {
    marginTop: 20,
  },
  footer: {
    paddingLeft: 40,
    paddingRight: 40,
    paddingBottom: 20,
    position: 'absolute',
    right: 0,
    bottom: 0,
    left: 0,
    background: 'white',
  },
  scroll: {
    overflowY: 'scroll',
    height: 'calc(100% - 95px)',
    '-ms-overflow-style': 'none',
    '&::-webkit-scrollbar': {
      width: 0 /* Remove scrollbar space */,
      background: 'transparent' /* Optional: just make scrollbar invisible */,
    },
    zIndex: 2,
  },
  seeDesks: {
    padding: 0,
  },
});

DeskToggleContents.defaultProps = {
  className: '',
  withFooter: false,
};

DeskToggleContents.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  desks: PropTypes.arrayOf(PropTypes.object).isRequired,
  submitForm: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  withFooter: PropTypes.bool,
  dirty: PropTypes.bool.isRequired,
  isUnassigned: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isSuccess: PropTypes.bool.isRequired,
  confirmAnchor: PropTypes.node.isRequired,
  setAnchorEl: PropTypes.func.isRequired,
  setSelectedDesk: PropTypes.func.isRequired,
  selectedDesk: PropTypes.object.isRequired,
  deskAvailabilities: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default withStyles(styles)(DeskToggleContents);
