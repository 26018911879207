/* eslint-disable react/no-did-update-set-state */
/* eslint-disable react/sort-comp */

import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Lodash from 'lodash';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Moment from 'moment';
import { bindActionCreators } from 'redux';
import Slide from '@material-ui/core/Slide';
import Tooltip from '@material-ui/core/Tooltip';
import { Transition } from 'react-transition-group';

// Styles
import { withTranslation } from 'react-i18next';
import { colors, breakpoints } from '../../../../Core/Theme';

// Components
import MeetingSummaryConfirmation from './Components/MeetingSummaryConfirmation';
import MeetingSummaryEdit from './Components/MeetingSummaryEdit';
import MeetingSummaryCancelation from './Components/MeetingSummaryCancelation';
import MeetingSummaryBooking from './Components/MeetingSummaryBooking';
import MeetingSummaryUnEditable from './Components/MeetingSummaryUnEditable';

// Constants
import {
  MeetingSummaryTypes,
  MESSAGE_USER_DOES_NOT_OWN_THIS_BOOKING,
  transitionStyles,
} from '../../constants';
import { Icons } from '../../../../resources';

// Utils
import { getDiffHours, getIntDiffHours } from '../../utils';
import {
  bookingRoleAccess,
  locationRoleAccess,
} from '../../../../Core/Utils/userPermissions';
import {
  getCurrentBalance,
  getWindowDimensions,
  date as DateUtils,
} from '../../../../Core/Utils';
import {
  trackBookingUpdate,
  trackBookingUpdateFromSummary,
  trackBookingCancellation,
  trackBookingCancellationFromSummary,
} from '../../../../Core/Tracking';

// Redux
import {
  getSelectedDate,
  getVisitingError,
  getLocationRestrictions,
  getCurrentLocationSfId,
  getCurrentLocation,
} from '../../../RoomBooking/Redux/selectors';
import bookingsRedux from '../../../../Redux/Common/Bookings';
import { getIsBookingInRange } from '../../../../Redux/Common/Bookings/selectors';

import {
  getUserId,
  getProfileUserSfId,
  getHasUnlimitedHours,
} from '../../../../Redux/Common/UserManager/selectors';

import { getLocations } from '../../../../Redux/Common/Locations/selectors';

// Api
import {
  updateBooking,
  deleteBooking,
  fetchUserBalance,
  fetchUserInfo,
  ReservationSource,
} from '../../../../Core/Api';

class ReservationMeetingSummary extends Component {
  constructor() {
    super();
    this.state = {
      fromStatus: '',
      errorDisplay: null,
      hasOverageAnd30Min: false,
      currentBalanceMemberSelected: 0,
      currentMonthlyCreditsMemberSelected: 0,
      overage: 0,
      objBookingBase: {},
      showEditBanner: true,
      showBookingBanner: true,
      memberBalanceIsLoading: false,
      memberSelected: null,
    };
  }

  componentDidMount() {
    const { userSfId, objBooking, values } = this.props;
    const reservationOwnerSfId = Lodash.get(values, ['user', 'sfId'], userSfId);
    if (reservationOwnerSfId !== userSfId) {
      this.fetchBalanceMemberSelected();
    }
    this.setBookingBase(objBooking);
  }

  componentDidUpdate(prevProps) {
    const { setHeight, meetingSummaryHeight, meetingSummaryRef, type, values } =
      this.props;
    const oldType = Lodash.get(prevProps, ['type'], 'No type');
    const newHeight = Lodash.get(meetingSummaryRef, ['clientHeight'], -1);
    if (
      meetingSummaryRef &&
      (newHeight > meetingSummaryHeight + 1 ||
        newHeight < meetingSummaryHeight - 1) &&
      newHeight !== -1
    ) {
      setHeight(newHeight);
    }
    // this is neccessary to for the sliding to work
    if (
      type === MeetingSummaryTypes.EDIT &&
      (oldType === MeetingSummaryTypes.CANCELLATION_CONFIRMATION ||
        oldType === MeetingSummaryTypes.CONFIRMATION)
    ) {
      setTimeout(() => this.setState({ showEditBanner: true }), 250);
    }
    if (
      type === MeetingSummaryTypes.CANCELLATION_CONFIRMATION &&
      oldType !== MeetingSummaryTypes.CANCELLATION_CONFIRMATION
    ) {
      this.setState({ showEditBanner: false });
    }
    if (
      type === MeetingSummaryTypes.CONFIRMATION &&
      oldType !== MeetingSummaryTypes.CONFIRMATION
    ) {
      this.setState({ showEditBanner: false });
    }
    if (
      type === MeetingSummaryTypes.BOOKING &&
      oldType === MeetingSummaryTypes.CONFIRMATION
    ) {
      setTimeout(() => this.setState({ showBookingBanner: true }), 250);
    }
    if (
      type === MeetingSummaryTypes.CONFIRMATION &&
      oldType !== MeetingSummaryTypes.CONFIRMATION
    ) {
      this.setState({ showBookingBanner: false });
    }

    const { values: prevValues } = prevProps;
    const memberSelectedByOM = Lodash.get(values, ['reservedId', 'member'], {});
    const memberSelectedSfId = Lodash.get(memberSelectedByOM, ['sfId'], null);

    const nextMemberSelectedByOM = Lodash.get(
      prevValues,
      ['reservedId', 'member'],
      {}
    );
    const nextMemberSelectedSfId = Lodash.get(
      nextMemberSelectedByOM,
      ['sfId'],
      null
    );

    if (memberSelectedSfId !== nextMemberSelectedSfId) {
      this.fetchBalanceMemberSelected();
    }
  }

  setBookingBase = objBooking => {
    this.setState({
      objBookingBase: objBooking,
    });
  };

  resetCurrentBalanceMemberSelected = () => {
    this.setState({
      currentBalanceMemberSelected: 0,
      currentMonthlyCreditsMemberSelected: 0,
    });
  };

  fetchBalanceMemberSelected = async () => {
    const { values, locations, type, timeZoneId } = this.props;
    const { initialTime, user, source } = values;

    const memberSelected = Lodash.get(values, ['reservedId', 'member'], null);

    let memberData = null;
    if (type === MeetingSummaryTypes.EDIT) {
      const memberId = Lodash.get(user, ['_id'], null);
      const memberInfoResponse = await fetchUserInfo(memberId, '1.10.0');
      memberData = Lodash.get(memberInfoResponse, ['data'], null);
    }

    const member = memberSelected || memberData;

    if (!member || !member.role) {
      return;
    }
    const memberSelectedAccountSfId = member.account.sfId;

    const memberSelectedLocationSfId = member.account.locationSfId;

    const memberLocation = Lodash.find(locations, {
      sfId: memberSelectedLocationSfId,
    });
    const memberTz = Lodash.get(memberLocation, 'timeZoneId', '');
    const usesCredits = bookingRoleAccess.hasCredits({
      profile: member,
    });

    this.setState({ memberBalanceIsLoading: true, memberSelected: member });
    if (usesCredits && source !== ReservationSource.OnDemand) {
      const monthStart = DateUtils.getDateInMomentWithZone(
        initialTime,
        timeZoneId
      )
        .startOf('month')
        .unix();

      const updatedMonth = DateUtils.updateDateForTZ(
        monthStart,
        memberTz,
        timeZoneId
      );

      const objBalanceMemberSelected = await fetchUserBalance(
        memberSelectedAccountSfId,
        updatedMonth
      );
      const memberBalancePayload = Lodash.get(
        objBalanceMemberSelected,
        ['data'],
        {}
      );

      const currentMonthlyCreditsMemberSelected = Lodash.get(
        memberBalancePayload,
        'monthlyAllowanceCredits',
        0
      );
      this.setState({
        currentBalanceMemberSelected: getCurrentBalance(memberBalancePayload),
        currentMonthlyCreditsMemberSelected,
        memberBalanceIsLoading: false,
      });
    }
  };

  getDueHoursAndCredits = () => {
    const { currentBalanceMemberSelected, objBookingBase } = this.state;
    const { values, creditsPerHour, type, userSfId, userRemainingCredits } =
      this.props;
    const memberSelected = Lodash.get(values, ['reservedId', 'member'], {});
    const memberSelectedSfId = Lodash.get(memberSelected, ['sfId'], userSfId);
    const hasOtherMemberSelected = memberSelectedSfId !== userSfId;
    const { endEpoch, startEpoch } = objBookingBase;

    let baseBookingHours = 0;
    const bookingHours = getIntDiffHours(values.initialTime, values.endTime);
    if (type === MeetingSummaryTypes.EDIT) {
      baseBookingHours = getIntDiffHours(startEpoch, endEpoch);
    }
    const dueCredits = baseBookingHours
      ? (bookingHours - baseBookingHours) * creditsPerHour
      : bookingHours * creditsPerHour;

    const dueHours = hasOtherMemberSelected
      ? currentBalanceMemberSelected - dueCredits
      : userRemainingCredits - dueCredits;

    return { dueHours, dueCredits };
  };

  getBookingOverage = () => {
    const {
      currentBalanceMemberSelected,
      currentMonthlyCreditsMemberSelected,
    } = this.state;
    const { values, costPerHour, profile } = this.props;
    const memberSelected = Lodash.get(values, ['reservedId', 'member'], {});

    const hasCreditsUser = bookingRoleAccess.hasCredits({
      profile: !Lodash.isEmpty(memberSelected) ? memberSelected : profile,
    });

    if (!hasCreditsUser || currentMonthlyCreditsMemberSelected === -1) {
      return 0;
    }

    const { dueHours } = this.getDueHoursAndCredits();

    let overage = 0;
    if (dueHours >= 0) {
      return currentBalanceMemberSelected - dueHours;
    }
    overage = Math.abs(dueHours) * costPerHour;
    return overage;
  };

  toggleHasOverageAnd30Min = () => {
    this.setState(prevState => ({
      hasOverageAnd30Min: !prevState.hasOverageAnd30Min,
    }));
  };

  handleOnSubmitInfo = async (
    within30Minutes,
    canModifyAnyTime,
    callback,
    fromStatus
  ) => {
    const { profile, values } = this.props;
    const overage = this.getBookingOverage();
    const { user } = values;
    const memberSelected = Lodash.get(values, ['reservedId', 'member'], {});
    const member = memberSelected || user;
    const hasCredits =
      bookingRoleAccess.hasCredits({ profile }) ||
      bookingRoleAccess.hasCredits({ profile: member });
    const { dueHours } = this.getDueHoursAndCredits();

    const hasOverage = hasCredits && overage > 0 && dueHours <= 0;

    const isIn30Min = within30Minutes && !canModifyAnyTime;
    if (hasOverage || isIn30Min) {
      const hasOverageAnd30Min = hasOverage && isIn30Min;
      this.handleBookingStatusSummary();
      this.setState({
        fromStatus,
        hasOverageAnd30Min,
        overage,
      });
      return;
    }
    callback();
  };

  handleBookingStatusSummary = () => {
    const { setFieldValue } = this.props;
    setFieldValue('status', MeetingSummaryTypes.CONFIRMATION);
  };

  handleOnCompleteReservation = () => {
    const { hasOverageAnd30Min } = this.state;
    const { submitForm } = this.props;
    if (hasOverageAnd30Min) {
      this.handleBookingStatusSummary();
      this.toggleHasOverageAnd30Min();
      return;
    }
    submitForm();
  };

  handleGoBack = () => {
    const { setFieldValue } = this.props;
    const { fromStatus } = this.state;
    if (fromStatus === MeetingSummaryTypes.BOOKING) {
      setFieldValue('status', MeetingSummaryTypes.BOOKING);
    }
    if (fromStatus === MeetingSummaryTypes.EDIT) {
      setFieldValue('status', MeetingSummaryTypes.EDIT);
    }
  };

  handleOnCancelReservation = () => {
    const {
      setFieldValue,
      values,
      onCloseModal,
      setSubmitting,
      deleteBookingInCalendar,
      setError,
      onSuccess,
    } = this.props;
    const { fromStatus } = this.state;

    if (values.status === MeetingSummaryTypes.CANCELLATION_CONFIRMATION) {
      const bookingId = Lodash.get(values, ['id'], '');
      if (bookingId) {
        setSubmitting(true);
        setError(false);
        deleteBooking(bookingId).then(
          objResponse => {
            setSubmitting(false);

            if (objResponse.status === 403) {
              this.setState(
                {
                  errorDisplay: MESSAGE_USER_DOES_NOT_OWN_THIS_BOOKING,
                },
                () => {
                  setTimeout(() => {
                    onCloseModal();
                  }, 1000);
                }
              );
            }
            if (objResponse.status === 200) {
              setFieldValue('isSuccess', true);
              this.trackBookingCancellation();
              // Delete in redux store
              deleteBookingInCalendar(bookingId);
              onSuccess();
              setTimeout(() => {
                onCloseModal();
              }, 1000);
            }
          },
          () => {
            setError(true);
            setSubmitting(false);
          }
        );
      } else {
        setFieldValue('status', MeetingSummaryTypes.BOOKING);
        this.setState({
          fromStatus: MeetingSummaryTypes.BOOKING,
        });
      }
    } else if (values.status === MeetingSummaryTypes.EDIT) {
      setFieldValue('status', MeetingSummaryTypes.CANCELLATION_CONFIRMATION);
      this.setState({
        fromStatus: MeetingSummaryTypes.EDIT,
      });
    } else if (values.status === MeetingSummaryTypes.CONFIRMATION) {
      setFieldValue('status', fromStatus || MeetingSummaryTypes.BOOKING);
    } else {
      setFieldValue('status', MeetingSummaryTypes.CANCELLATION_CONFIRMATION);
    }
  };

  handleOnUpdate = () => {
    const {
      setSubmitting,
      setFieldValue,
      onCloseModal,
      updateBookingInCalendar,
      setError,
      onSuccess,
      values,
    } = this.props;
    const { hasOverageAnd30Min } = this.state;
    if (hasOverageAnd30Min) {
      this.handleBookingStatusSummary();
      this.toggleHasOverageAnd30Min();
      return;
    }

    setSubmitting(true);
    setError(false);

    const dto = this.createBookingUpdateDto();
    updateBooking(values.id, dto).then(
      objResponse => {
        setSubmitting(false);
        if (objResponse.data.error) {
          this.setState({
            errorDisplay: objResponse.data.error,
          });
        }
        if (objResponse.status === 200) {
          setFieldValue('isSuccess', true);
          this.trackBookingUpdate();
          const objBooking = Lodash.get(objResponse, ['data'], {});
          updateBookingInCalendar(objBooking);
          onCloseModal();
          onSuccess();
        }
      },
      () => {
        setError(true);
        setSubmitting(false);
      }
    );
  };

  createBookingUpdateDto = () => {
    const { values, roomSelected } = this.props;

    return {
      title: values.title,
      description: values.description,
      startEpoch: +values.initialTime,
      endEpoch: +values.endTime,
      spaceSfId: roomSelected.sfId,
    };
  };

  createBookingToTrack = () => {
    const { values, roomSelected } = this.props;

    return {
      startEpoch: +values.initialTime,
      space: {
        sfId: roomSelected.sfId,
        location: {
          externalName: roomSelected.location.externalName,
        },
        name: roomSelected.name,
        amenities: roomSelected.amenities?.map(amenity => ({
          name: amenity.name,
        })),
        seats: roomSelected.seats,
      },
    };
  };

  trackBookingUpdate() {
    const bookingToTrack = this.createBookingToTrack();
    trackBookingUpdate(bookingToTrack);
    trackBookingUpdateFromSummary();
  }

  trackBookingCancellation() {
    const bookingToTrack = this.createBookingToTrack();
    trackBookingCancellation(bookingToTrack);
    trackBookingCancellationFromSummary();
  }

  getIsVisitingBookingError = () => {
    const { locationRestrictions, values, profile, locationSfId } = this.props;
    const { objBookingBase } = this.state;
    const { startEpoch, endEpoch } = objBookingBase;
    if (
      !locationRoleAccess.hasBookingRestrictions({
        profile,
        obj: { sfId: locationSfId },
      })
    ) {
      return false;
    }
    const dateInFormat = Moment.unix(values.initialTime).format('YYYY-MM-DD');
    const month = Moment.unix(values.initialTime).startOf('month').unix();
    const restrictionsForDay = Lodash.get(locationRestrictions, month, {});
    const { maximumBookableSecondsPerDay, remainingBookableSecondsInDay } =
      restrictionsForDay;
    const remainingSecondsInDay = Lodash.get(
      remainingBookableSecondsInDay,
      dateInFormat,
      maximumBookableSecondsPerDay
    );
    const originalMeetingLength = endEpoch - startEpoch || 0;

    const meetingDelta =
      values.endTime - values.initialTime - originalMeetingLength;
    return meetingDelta > remainingSecondsInDay;
  };

  getInformationMessage = (overlapInHours, isModifyAnytime, isSuccess) => {
    const { classes, isOutsideBusinessHoursError, t } = this.props;
    if (isSuccess) {
      return null;
    }

    const isBreaking4HrRule = this.getIsVisitingBookingError();
    if (isBreaking4HrRule) {
      return (
        <div className={classes.summaryAlertLabelContainerOverLap}>
          <p className={classes.summaryLabelOverLap}>
            {t('meetingSummary.4_hr_warning')}
          </p>
        </div>
      );
    }

    if (isOutsideBusinessHoursError) {
      return (
        <div className={classes.summaryAlertLabelContainerOverLap}>
          <p className={classes.summaryLabelOverLap}>
            {t('meetingSummary.outsideHours')}
          </p>
        </div>
      );
    }
    if (overlapInHours) {
      return (
        <div className={classes.summaryAlertLabelContainerOverLap}>
          <p className={classes.summaryLabelOverLap}>
            {t('meetingSummary.unavailable_space')}
          </p>
        </div>
      );
    }
    if (!isModifyAnytime) {
      return (
        <div className={classes.summaryAlertLabelContainer}>
          <p className={classes.meetingSummaryLabel}>
            {t('meetingSummary.30_min_edit_warning')}
          </p>
        </div>
      );
    }
    return null;
  };

  getConfirmationMessage = hasOverage => {
    const { hasOverageAnd30Min, overage } = this.state;
    const { classes, t } = this.props;
    if (overage && hasOverage) {
      if (hasOverageAnd30Min) {
        return (
          <p className={classes.confirmationParagraph}>
            {t('meetingSummary.30_min_warning')}
          </p>
        );
      }

      return (
        <p className={classes.confirmationParagraphWithOverage}>
          {t('meetingSummary.overage_warning')}
          <Tooltip
            classes={{
              tooltip: classes.Tooltip,
              popper: classes.TooltipPopper,
            }}
            title={t('meetingSummary.overage_tooltip')}
            placement="right-start"
            disableTouchListener
          >
            <span>
              <img
                src={Icons.QuestionToolTip}
                className={classes.helpIcon}
                alt={t('altTexts.question_mark')}
              />
            </span>
          </Tooltip>
          <br />
          <b>{t('meetingSummary.continue_cta')}</b>
        </p>
      );
    }

    return (
      <p className={classes.confirmationParagraph}>
        {t('meetingSummary.30_min_warning')}
      </p>
    );
  };

  getInformationEditMessage = (modifyAnytime, overlapInHours) => {
    const { errorDisplay } = this.state;
    const {
      classes,
      isOutsideBusinessHoursError,
      t,
      isSelectedDateUnavailable,
    } = this.props;

    if (isSelectedDateUnavailable) {
      return (
        <div className={classes.bannerBlackoutDates}>
          <div>{t('meetingSummary.blackout_date_title')}</div>
          <div>{t('meetingSummary.blackout_date_message')}</div>
        </div>
      );
    }
    const isBreaking4HrRule = this.getIsVisitingBookingError();
    if (isBreaking4HrRule) {
      return (
        <div className={classes.summaryAlertLabelContainerOverLap}>
          <p className={classes.summaryLabelOverLap}>
            {t('meetingSummary.4_hr_warning')}
          </p>
        </div>
      );
    }

    if (isOutsideBusinessHoursError) {
      return (
        <div className={classes.summaryAlertLabelContainerOverLap}>
          <p className={classes.summaryLabelOverLap}>
            {t('meetingSummary.outsideHours')}
          </p>
        </div>
      );
    }
    if (overlapInHours) {
      return (
        <div className={classes.summaryAlertLabelContainerOverLap}>
          <p className={classes.summaryLabelOverLap}>
            {t('meetingSummary.unavailable_space')}
          </p>
        </div>
      );
    }
    if (errorDisplay) {
      return (
        <div className={classes.summaryAlertLabelContainer}>
          <p className={classes.summaryLabelOverLap}>{errorDisplay}</p>
        </div>
      );
    }
    if (!modifyAnytime) {
      return (
        <div className={classes.summaryAlertLabelContainer}>
          <p className={classes.meetingSummaryLabel}>
            {t('meetingSummary.30_min_edit_warning')}
          </p>
        </div>
      );
    }
    return null;
  };

  renderMeetingBanner = () => {
    const {
      errorDisplay,
      showEditBanner,
      showBookingBanner,
      fromStatus,
      currentMonthlyCreditsMemberSelected,
      memberBalanceIsLoading,
      memberSelected,
    } = this.state;
    const {
      type,
      errors,
      values,
      isBookingInRage,
      profile,
      userSfId,
      hasUnlimitedHours,
      timeZoneId,
      isOutsideBusinessHoursError,
      isSelectedDateUnavailable,
      t,
    } = this.props;
    const { width } = getWindowDimensions();
    const isMobile = width <= breakpoints.MOBILE;
    const { classes, ...rest } = this.props;
    const { isSuccess, endTime } = values;
    const memberSelectedSfId = Lodash.get(memberSelected, ['sfId'], userSfId);
    const hasOtherMemberSelected = memberSelectedSfId !== userSfId;

    const overage = this.getBookingOverage();
    const hasCreditsUser = bookingRoleAccess.hasCredits({
      profile: hasOtherMemberSelected ? memberSelected : profile,
    });
    const { dueHours, dueCredits } = this.getDueHoursAndCredits();

    const hasOverage = hasCreditsUser && overage > 0 && dueHours <= 0;

    const isFieldVisible =
      hasOtherMemberSelected && hasCreditsUser
        ? currentMonthlyCreditsMemberSelected !== -1
        : !hasUnlimitedHours && hasCreditsUser;
    const endTimeWithMinutesPast = Moment.unix(endTime)
      .add(30, 'minutes')
      .unix();
    const isBookeableNearest = Moment().isAfter(
      Moment.unix(endTimeWithMinutesPast)
    );
    const isOverlapInHours = isBookingInRage;
    const isVisitingError = this.getIsVisitingBookingError();
    const isFreeRoom = (dueHours === 0 && dueCredits === 0) || !hasCreditsUser;

    const objSummaryDetails = {
      date: {
        isVisible: true,
        key: 'Date',
        value: DateUtils.getDateInMomentWithZone(
          values.selectedDate || values.date,
          timeZoneId
        ).format('LL'),
      },
      time: {
        initialTime: DateUtils.getDateInMomentWithZone(
          values.initialTime,
          timeZoneId
        ).format('[h:mma]'),
        endTime: DateUtils.getDateInMomentWithZone(
          values.endTime,
          timeZoneId
        ).format('[h:mma]'),
        isVisible: true,
      },
      hours: {
        isVisible: isFieldVisible,
        key: 'Hours Used',
        value:
          isFreeRoom || memberBalanceIsLoading
            ? 0
            : getDiffHours(values.initialTime, values.endTime),
      },
    };
    const within30Minutes = values.initialTime * 1 - Moment().unix() <= 30 * 60;
    const canModifyAnyTime = bookingRoleAccess.modifyAnyTime({
      profile,
    });
    const canBookInPast = bookingRoleAccess.adminCreate({
      profile,
    });
    const message = this.getInformationEditMessage(
      canModifyAnyTime,
      isOverlapInHours
    );
    const isButtonDisabled =
      isOverlapInHours ||
      !Lodash.isEmpty(errors) ||
      (isBookeableNearest && !(canBookInPast || canModifyAnyTime)) ||
      isVisitingError ||
      isOutsideBusinessHoursError ||
      isSelectedDateUnavailable;
    switch (type) {
      case MeetingSummaryTypes.CONFIRMATION:
      case MeetingSummaryTypes.BOOKING:
      case MeetingSummaryTypes.CANCELLATION_CONFIRMATION:
      case MeetingSummaryTypes.EDIT:
      case MeetingSummaryTypes.CANCELLATION:
        return (
          <React.Fragment>
            {showEditBanner &&
            (type === MeetingSummaryTypes.EDIT ||
              type === MeetingSummaryTypes.CANCELLATION) ? (
              <div>
                {message}
                <MeetingSummaryEdit
                  {...rest}
                  cancelLabel={t('meetingSummary.cancel_reservation_cta')}
                  data={objSummaryDetails}
                  isButtonDisabled={isButtonDisabled}
                  submitLabel={
                    isSelectedDateUnavailable
                      ? t('meetingSummary.room_unavailable_cta')
                      : t('meetingSummary.update_reservation_cta')
                  }
                  isSelectedDateUnavailable={isSelectedDateUnavailable}
                  onUpdate={() =>
                    this.handleOnSubmitInfo(
                      within30Minutes,
                      canModifyAnyTime,
                      this.handleOnUpdate,
                      MeetingSummaryTypes.EDIT
                    )
                  }
                  onCancel={this.handleOnCancelReservation}
                  isSuccess={isSuccess}
                  type={type}
                />
              </div>
            ) : null}
            {showBookingBanner && type === MeetingSummaryTypes.BOOKING ? (
              <React.Fragment>
                {this.getInformationMessage(
                  isOverlapInHours,
                  canModifyAnyTime,
                  isSuccess
                )}
                <MeetingSummaryBooking
                  {...rest}
                  data={objSummaryDetails}
                  isButtonDisabled={isButtonDisabled}
                  onReserve={() =>
                    this.handleOnSubmitInfo(
                      within30Minutes,
                      canModifyAnyTime,
                      this.handleOnCompleteReservation,
                      MeetingSummaryTypes.BOOKING
                    )
                  }
                />
              </React.Fragment>
            ) : null}
            <Transition
              in={type === MeetingSummaryTypes.CANCELLATION_CONFIRMATION}
              timeout={225}
            >
              {state => (
                <div
                  style={{
                    ...transitionStyles[state],
                  }}
                >
                  {errorDisplay ? (
                    <div className={classes.summaryAlertLabelContainer}>
                      <p className={classes.summaryLabelOverLap}>
                        {errorDisplay}
                      </p>
                    </div>
                  ) : null}
                  <MeetingSummaryCancelation
                    {...rest}
                    isSuccess={isSuccess}
                    onBack={this.handleGoBack}
                    onCancel={this.handleOnCancelReservation}
                    isButtonDisabled={!canModifyAnyTime && isButtonDisabled}
                    isOnDemandReservation={
                      values.source === ReservationSource.OnDemand
                    }
                  />
                </div>
              )}
            </Transition>

            <Slide
              direction="up"
              in={type === MeetingSummaryTypes.CONFIRMATION}
              mountOnEnter
              unmountOnExit
            >
              <MeetingSummaryConfirmation
                messageOverage={this.getConfirmationMessage(hasOverage)}
                onComplete={
                  fromStatus === MeetingSummaryTypes.BOOKING
                    ? this.handleOnCompleteReservation
                    : this.handleOnUpdate
                }
                onCancel={this.handleOnCancelReservation}
                {...rest}
              />
            </Slide>
          </React.Fragment>
        );
      case MeetingSummaryTypes.UNEDITABLE:
        return (
          <React.Fragment>
            <div className={classes.summaryAlertLabelContainerUneditable}>
              <p className={classes.meetingSummaryLabelUneditable}>
                {t('meetingSummary.only_edit_if_owner')}
              </p>
            </div>
            <MeetingSummaryUnEditable
              data={objSummaryDetails}
              {...rest}
              profile={profile}
              bookingValues={values}
            />
          </React.Fragment>
        );
      case MeetingSummaryTypes.UNEDITABLE_WITH_ERROR:
        return (
          <React.Fragment>
            {isMobile ? (
              <div className={classes.summaryAlertLabelContainerUneditable}>
                <p className={classes.meetingSummaryLabelUneditable}>
                  {t('meetingSummary.reservation_in_30_min')}
                </p>
              </div>
            ) : null}
            <MeetingSummaryUnEditable
              data={objSummaryDetails}
              {...rest}
              profile={profile}
              bookingValues={values}
            />
          </React.Fragment>
        );

      default:
        return null;
    }
  };

  render() {
    return this.renderMeetingBanner();
  }
}

const styles = theme => ({
  summaryLabelOverLap: {
    marginLeft: '4%',
    fontSize: '12px',
    alignSelf: 'center',
    marginTop: 4,
    marginBottom: 4,
    fontFamily: 'VerlagBold',
    color: colors.alertMessage,

    [theme.breakpoints.up(breakpoints.MOBILE)]: {
      marginLeft: '4%',
      fontSize: '14px',
      color: colors.alertMessage,
    },
  },
  meetingSummaryLabel: {
    fontSize: '13px',
    fontFamily: 'VerlagLight',
    margin: 0,
    [theme.breakpoints.down(breakpoints.MOBILE)]: {
      textAlign: 'center',
    },
    [theme.breakpoints.up(breakpoints.MOBILE)]: {
      fontSize: '14px',
    },
  },
  meetingSummaryLabelUneditable: {
    fontSize: '13px',
    fontFamily: 'VerlagLight',
    margin: 0,
    [theme.breakpoints.down(breakpoints.MOBILE)]: {
      marginLeft: '20px',
    },
    [theme.breakpoints.up(breakpoints.MOBILE)]: {
      marginLeft: '40px',
      fontSize: '14px',
    },
  },
  summaryAlertLabelContainer: {
    [theme.breakpoints.up(breakpoints.MOBILE)]: {
      padding: '6px 40px',
    },
    padding: '5px 20px',
    flexDirection: 'row',
    backgroundColor: colors.palette.secondary2.main,
    fontSize: 12,
  },
  summaryAlertLabelContainerUneditable: {
    [theme.breakpoints.up(breakpoints.MOBILE)]: {
      display: 'none',
    },
    padding: '5px 0px',
    flexDirection: 'row',
    backgroundColor: colors.palette.secondary2.main,
    boxShadow: '0 -2px 2px 0 rgba(0, 0, 0, 0.1)',
  },
  summaryAlertLabelContainerOverLap: {
    [theme.breakpoints.up(breakpoints.MOBILE)]: {
      padding: '6px 0px',
    },
    border: `solid 1.5px ${colors.alertBackground}`,
    padding: '5px 0px',
    flexDirection: 'row',
    backgroundColor: colors.white,
    borderRadius: 2,
  },
  uneditableSummaryLabel: {
    fontSize: '13px',
    color: colors.palette.error.main,
    fontFamily: 'VerlagBold',
    [theme.breakpoints.down(breakpoints.MOBILE)]: {
      marginLeft: '20px',
      marginRight: '20px',
    },
    [theme.breakpoints.up(breakpoints.MOBILE)]: {
      marginLeft: '38px',
      fontSize: '15px',
    },
  },
  uneditableSummaryLabelContainer: {
    [theme.breakpoints.down(breakpoints.MOBILE)]: {
      height: '44px',
    },
    display: 'flex',
    alignItems: 'center',
    border: 'solid 1.5px #f0c8c8',
    flexDirection: 'row',
    height: '34px',
    backgroundColor: colors.white,
  },
  confirmationParagraph: {
    margin: 0,
    marginBottom: '10px',
  },
  confirmationParagraphWithOverage: {
    margin: 0,
    paddingBottom: 5,
    paddingTop: 5,
  },
  Tooltip: {
    backgroundColor: colors.white,
    width: 270,
    borderRadius: 3,
    color: colors.black,
    boxShadow: '0 0 3px 1px rgba(0, 0, 0, 0.25)',
    fontSize: 12,
    fontFamily: 'VerlagLight',
    border: `solid 1px ${colors.dark}`,
    [theme.breakpoints.up(breakpoints.MOBILE)]: {
      marginTop: -50,
    },
    [theme.breakpoints.down(breakpoints.MOBILE)]: {
      width: '205px',
      marginTop: 5,
    },
  },
  TooltipPopper: {
    opacity: 1,
  },
  helpIcon: {
    fill: colors.black,
    height: '16px',
    width: '16px',
    transform: 'translate(0px, 3px)',
  },
  bannerBlackoutDates: {
    color: colors.palette.error.main,
    fontFamily: 'VerlagBold',
    [theme.breakpoints.up(breakpoints.MOBILE)]: {
      padding: '6px 0px',
    },
    border: `solid 1.5px ${colors.alertBackground}`,
    padding: '5px 0px',
    flexDirection: 'row',
    backgroundColor: colors.white,
    borderRadius: 2,
    fontSize: 13,
    paddingLeft: 20,
    [theme.breakpoints.up(breakpoints.MOBILE)]: {
      fontSize: 16,
      paddingLeft: 40,
    },
  },
});

ReservationMeetingSummary.defaultProps = {
  updateBooking: () => null,
  deleteReservationInCalendar: () => null,
  meetingSummaryRef: null,
  hasUnlimitedHours: false,
  onSuccess: () => null,
};

ReservationMeetingSummary.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  type: PropTypes.string.isRequired,
  idUser: PropTypes.string.isRequired,
  data: PropTypes.shape({}).isRequired,
  errors: PropTypes.shape({}).isRequired,
  dirty: PropTypes.bool.isRequired,
  isValid: PropTypes.bool.isRequired,
  values: PropTypes.shape({}).isRequired,
  profile: PropTypes.shape({}).isRequired,
  submitForm: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  isBookingInRage: PropTypes.bool.isRequired,
  updateBooking: PropTypes.func,
  selectedDate: PropTypes.number.isRequired,
  onCloseModal: PropTypes.func.isRequired,
  setSubmitting: PropTypes.func.isRequired,
  deleteReservationInCalendar: PropTypes.func,
  deleteBookingInCalendar: PropTypes.func.isRequired,
  updateBookingInCalendar: PropTypes.func.isRequired,
  setHeight: PropTypes.func.isRequired,
  meetingSummaryRef: PropTypes.object,
  meetingSummaryHeight: PropTypes.number.isRequired,
  creditsPerHour: PropTypes.number.isRequired,
  costPerHour: PropTypes.number.isRequired,
  userSfId: PropTypes.string.isRequired,
  userId: PropTypes.string.isRequired,
  objBooking: PropTypes.shape({}).isRequired,
  setError: PropTypes.func.isRequired,
  userRemainingCredits: PropTypes.number.isRequired,
  locationRestrictions: PropTypes.func.isRequired,
  locationSfId: PropTypes.string.isRequired,
  hasUnlimitedHours: PropTypes.bool,
  locations: PropTypes.arrayOf(PropTypes.object).isRequired,
  selectedLocation: PropTypes.object.isRequired,
  onSuccess: PropTypes.func,
  timeZoneId: PropTypes.string.isRequired,
  rooms: PropTypes.arrayOf(PropTypes.object).isRequired,
  roomSelected: PropTypes.object.isRequired,
  isOutsideBusinessHoursError: PropTypes.bool.isRequired,
  t: PropTypes.func.isRequired,
  isSelectedDateUnavailable: PropTypes.bool.isRequired,
};

const mapStateToProps = (state, ownProps) => {
  return {
    isBookingInRage: getIsBookingInRange(state, ownProps),
    selectedDate: getSelectedDate(state),
    locationSfId: getCurrentLocationSfId(state),
    idUser: getUserId(state),
    userSfId: getProfileUserSfId(state, ownProps),
    isVisitingError: getVisitingError(state, ownProps),
    locationRestrictions: getLocationRestrictions(state),
    hasUnlimitedHours: getHasUnlimitedHours(state, ownProps),
    locations: getLocations(state),
    selectedLocation: getCurrentLocation(state),
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      updateBookingInCalendar: bookingsRedux.actions.updateBooking,
      deleteBookingInCalendar: bookingsRedux.actions.deleteBooking,
    },
    dispatch
  );
};
export default withTranslation()(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withStyles(styles)(ReservationMeetingSummary))
);
