import Components from '../../Components';
import { getIsVirtualMember } from '../../Core/Utils/userPermissions';

const { containerTypes } = Components.Routes;

const RouteConfig = {
  path: '/home/events',
  exact: true,
  name: 'navigation.menu.events',
  private: true,
  type: containerTypes.SUBCONTENT,
  requiresMemberPortalAccess: true,
  isAuthorized: ({ profile }) => {
    return !getIsVirtualMember(profile);
  },
};

export default RouteConfig;
