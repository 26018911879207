import typeToReducer from 'type-to-reducer';
import Lodash from 'lodash';
import * as types from './actionTypes';
import { normalizeGroupings, NormObjCstr } from './schema';

const initialState = {
  groupingsData: new NormObjCstr({}, []),
  userGroupingIds: [],
  userGroupingsIsLoading: true, // initializes as true since user groupings are called for on app mount and affect routing
  loading: false,
  hasError: false,
  displayGroupings: [],
  displayGroupingsIsLoading: true,
};

const onLoadingGroupingsPending = state => {
  return {
    ...state,
    loading: true,
    displayGroupingsIsLoading: true,
  };
};

const onLoadingUserGroupingsPending = state => {
  return {
    ...state,
    userGroupingsIsLoading: true,
  };
};
export const onLoadGroupingsFulfilled = (state, action) => {
  const { payload } = action;
  const groupingsNormalized = normalizeGroupings(payload);
  if (!payload.length) {
    return { ...state, loading: false, hasError: false };
  }

  const existingGroupingsById = Lodash.get(
    state,
    ['groupingsData', 'byId'],
    {}
  );
  const existingGroupingsAllIds = Lodash.get(
    state,
    ['groupingsData', 'allIds'],
    []
  );
  const newGroupingsById = {
    ...existingGroupingsById,
    ...groupingsNormalized.groupings.byId,
  };
  const newGroupingsAllIds = Lodash.uniqBy(
    existingGroupingsAllIds.concat(groupingsNormalized.groupings.allIds)
  );
  return {
    ...state,
    groupingsData: new NormObjCstr(newGroupingsById, newGroupingsAllIds),
    loading: false,
    hasError: false,
    displayGroupings: payload,
    displayGroupingsIsLoading: false,
  };
};

export const onLoadUserGroupingsFulfilled = (state, action) => {
  const { payload } = action;
  const groupingsNormalized = normalizeGroupings(payload);
  if (!payload.length) {
    return { ...state, loading: false, hasError: false };
  }

  const existingGroupingsById = Lodash.get(
    state,
    ['groupingsData', 'byId'],
    {}
  );
  const existingGroupingsAllIds = Lodash.get(
    state,
    ['groupingsData', 'allIds'],
    []
  );
  const newGroupingsById = {
    ...existingGroupingsById,
    ...groupingsNormalized.groupings.byId,
  };
  const newGroupingsAllIds = Lodash.uniqBy(
    existingGroupingsAllIds.concat(groupingsNormalized.groupings.allIds)
  );
  return {
    ...state,
    groupingsData: new NormObjCstr(newGroupingsById, newGroupingsAllIds),
    userGroupingIds: groupingsNormalized.groupings.allIds,
    userGroupingsIsLoading: false,
    hasError: false,
  };
};

const onLoadGroupingsRejected = state => {
  return {
    ...state,
    loading: false,
    hasError: true,
    displayGroupingsIsLoading: false,
  };
};

const onLoadUserGroupingsRejected = state => {
  return {
    ...state,
    userGroupingsIsLoading: false,
    hasError: true,
  };
};

export const onUpdateGrouping = (state, action) => {
  const { payload } = action;
  const groupingsNormalized = normalizeGroupings(payload);

  const existingGroupingsById = Lodash.get(
    state,
    ['groupingsData', 'byId'],
    {}
  );
  const existingGroupingsAllIds = Lodash.get(
    state,
    ['groupingsData', 'allIds'],
    []
  );
  const newGroupingsById = {
    ...existingGroupingsById,
    ...groupingsNormalized.groupings.byId,
  };

  return {
    ...state,
    groupingsData: new NormObjCstr(newGroupingsById, existingGroupingsAllIds),
    loading: false,
    hasError: false,
  };
};

export const onAddGrouping = (state, action) => {
  const { payload } = action;
  const { idGrouping } = payload;
  const groupingsNormalized = normalizeGroupings(payload);

  const existingGroupingsById = Lodash.get(
    state,
    ['groupingsData', 'byId'],
    {}
  );
  const existingGroupingsAllIds = Lodash.get(
    state,
    ['groupingsData', 'allIds'],
    []
  );
  const newGroupingsById = {
    ...existingGroupingsById,
    ...groupingsNormalized.groupings.byId,
  };

  return {
    ...state,
    groupingsData: new NormObjCstr(newGroupingsById, [
      ...existingGroupingsAllIds,
      idGrouping,
    ]),
    loading: false,
    hasError: false,
  };
};

export const deleteGrouping = (state, action) => {
  const { payload } = action;
  const { idGrouping } = payload;

  const existingGroupingsById = Lodash.get(
    state,
    ['groupingsData', 'byId'],
    {}
  );
  const existingGroupingsAllIds = Lodash.get(
    state,
    ['groupingsData', 'allIds'],
    []
  );

  return {
    ...state,
    groupingsData: new NormObjCstr(
      Lodash.omit(existingGroupingsById, idGrouping),
      existingGroupingsAllIds.filter(id => id !== idGrouping)
    ),
    userGroupingIds: state.userGroupingIds.filter(id => id !== idGrouping),
    displayGroupings: state.displayGroupings.filter(
      g => g.idGrouping !== idGrouping
    ),
  };
};

const contentReducer = typeToReducer(
  {
    [types.LOAD_GROUPINGS]: {
      PENDING: onLoadingGroupingsPending,
      FULFILLED: onLoadGroupingsFulfilled,
      REJECTED: onLoadGroupingsRejected,
    },
    [types.LOAD_USER_GROUPINGS]: {
      PENDING: onLoadingUserGroupingsPending,
      FULFILLED: onLoadUserGroupingsFulfilled,
      REJECTED: onLoadUserGroupingsRejected,
    },
    [types.UPDATE_GROUPING]: onUpdateGrouping,
    [types.ADD_GROUPING]: onAddGrouping,
    [types.DELETE_GROUPING]: {
      FULFILLED: deleteGrouping,
    },
  },
  initialState
);

export default contentReducer;
