import { Http, stringifyUrl } from '../Utils';
import {
  CreateGroupingDto,
  CreateGroupingResponseDto,
  DeleteGroupingAccountResponseDto,
  GroupingAccountDto,
  GroupingCoworkingAccount,
  GroupingDto,
  UpdateGroupingDto,
  UpdateGroupingResponseDto,
  UserGroupingDto,
} from './dto';

export const fetchGroupings = (featureKey?: string) => {
  const url = stringifyUrl('groupings', {
    feature_key: featureKey,
  });
  return Http.get<GroupingDto[]>(url);
};

export const fetchUserGroupings = (userSfId: string) => {
  return Http.get<UserGroupingDto[]>(`/users/${userSfId}/groupings`);
};

export const fetchGroupingAccounts = (groupingIds: string[]) => {
  const url = stringifyUrl('groupings/account_relations', {
    grouping_ids: groupingIds.join(','),
  });

  return Http.get<GroupingAccountDto[]>(url);
};

export const fetchGroupingCoworkingAccounts = (idGrouping: string) => {
  return Http.get<GroupingCoworkingAccount[]>(
    `/groupings/${idGrouping}/coworking_accounts`
  );
};

export const createGrouping = (grouping: CreateGroupingDto) => {
  return Http.post<CreateGroupingResponseDto>(`/groupings`, grouping);
};

export const updateGrouping = (id: string, grouping: UpdateGroupingDto) => {
  return Http.put<UpdateGroupingResponseDto>(`/groupings/${id}`, grouping);
};

export const addAccountToGrouping = (
  id: string,
  account: { accountSfId: string }
) => {
  return Http.put<GroupingAccountDto>(
    `/groupings/${id}/account_relations`,
    account
  );
};

export const removeAccountFromGrouping = (
  idGrouping: string,
  accountSfId: string
) => {
  return Http.remove<DeleteGroupingAccountResponseDto>(
    `/groupings/${idGrouping}/account_relations/${accountSfId}`
  );
};

export const fetchCanBeDeleted = (groupingIds: string[]) => {
  return Http.get<Record<string, boolean>>(
    stringifyUrl('groupings/can_be_deleted', {
      grouping_ids: groupingIds.join(','),
    })
  );
};

export const deleteGrouping = (idGrouping: string) => {
  return Http.remove<GroupingDto>(`/groupings/${idGrouping}`);
};
