import { useMemo } from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import Text from '../../../Common/Text';
import { colors, breakpoints } from '../../../../Core/Theme';
import { date as DateUtils } from '../../../../Core/Utils';
import ScheduleRow from '../ScheduleRow/ScheduleRow';
import {
  getRowDataForReservation,
  getDetailedRowDataForReservations,
} from '../../utils';
import DetailedScheduleRow from '../DetailedScheduleRow';

const ScheduleSection = ({
  classes,
  reservations,
  date,
  onClick,
  isDetailed,
  datatestid,
}) => {
  const dateInMoment = DateUtils.parseToMomentWithFormat(date, 'l');

  const isToday = DateUtils.getDateInMoment().isSame(dateInMoment, 'day');
  const { t } = useTranslation();

  const scheduleData = useMemo(() => {
    return reservations.map(r => {
      return isDetailed
        ? { reservation: r, data: getDetailedRowDataForReservations(r, t) }
        : { reservation: r, data: getRowDataForReservation(r, t, classes) };
    });
  }, [reservations, isDetailed]);

  return (
    <div className={classes.container}>
      <div
        className={clsx(
          classes.dateContainer,
          isDetailed && classes.detailedDateContainer
        )}
        datatestid="scheduleReservationDate"
      >
        <Text
          text={dateInMoment.format('ddd')}
          className={clsx(classes.dateText, isDetailed && classes.detailedDate)}
        />
        <Text
          text={dateInMoment.format('[MMM D]')}
          className={clsx(
            classes.dateMonthText,

            isDetailed && classes.detailedMonth
          )}
        />
      </div>
      <div className={clsx(classes.divider, classes.detailedDivider)} />
      <div
        className={clsx(
          classes.rowsContainer,
          isDetailed && classes.detailedContainer
        )}
      >
        {isToday ? (
          <div
            className={clsx(
              classes.todayContainer,
              isDetailed && classes.todayContainerDetailed
            )}
          >
            <Text text={t('Schedule.today')} className={classes.todayText} />
          </div>
        ) : null}
        {scheduleData.map((schedule, idx) => {
          const { data, reservation } = schedule;
          return (
            <div
              key={scheduleData.key}
              className={classes.rowContainer}
              datatestid={`${datatestid}_upcoming_${idx}_${reservation.type}`}
            >
              {isDetailed ? (
                <DetailedScheduleRow
                  onClick={() => onClick(reservation)}
                  {...data}
                />
              ) : (
                <ScheduleRow onClick={() => onClick(reservation)} {...data} />
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};

const styles = theme => ({
  container: {
    display: 'flex',
    flexShrink: 0,
  },
  dateContainer: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
    width: 46,
  },
  detailedDateContainer: {
    width: 42,
  },
  dateText: {
    fontFamily: 'VerlagBook',
    fontSize: 14,
    lineHeight: 0.86,
  },
  detailedDate: {
    fontSize: 12,
    paddingBottom: 2,
    paddingTop: 3,
  },
  dateMonthText: {
    fontFamily: 'VerlagBold',
    fontSize: 16,
    lineHeight: 0.88,
    color: colors.palette.tertiary1.main,
  },
  detailedMonth: {
    fontSize: 14,
  },
  divider: {
    width: '1px',
    backgroundColor: colors.middle,
    marginLeft: 11,
    marginRight: 9,
  },
  rowsContainer: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    borderBottom: `1px solid ${colors.middle}`,
    paddingBottom: 9,
    marginBottom: 1,
  },
  todayContainer: {
    backgroundColor: colors.palette.tertiary1.main,
    borderRadius: 1,
    padding: 2,
    width: 38,
    color: colors.white,
    textAlign: 'center',
  },
  todayContainerDetailed: {
    padding: 0,
  },
  todayText: {
    fontFamily: 'VerlagBold',
    fontSize: '12px',
    lineHeight: '12px',
  },
  rowContainer: {
    marginTop: 8,
  },
  detailedContainer: {
    borderBottom: `solid 1px ${colors.middle}`,
    paddingBottom: 9,
  },
  detailedDivider: {
    backgroundColor: colors.middle,
    [theme.breakpoints.down(breakpoints.MOBILE)]: {
      marginLeft: 7,
      marginRight: 5,
    },
  },
  joinRsvpLink: {
    fontSize: 12,
    padding: 0,
  },
});

ScheduleSection.defaultProps = {
  isDetailed: false,
  datatestid: '',
};

ScheduleSection.propTypes = {
  reservations: PropTypes.arrayOf(PropTypes.object).isRequired,
  date: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  isDetailed: PropTypes.bool,
  datatestid: PropTypes.string,
};

export default withStyles(styles)(ScheduleSection);
