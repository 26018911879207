import React from 'react';
import PropTypes from 'prop-types';
import memoizeOne from 'memoize-one';
import Lodash from 'lodash';
import { useTranslation } from 'react-i18next';

// Components
import { MapLayerWrapper as Layer } from '../../Common';
import Popup from '../Components/Popup';

// Constants
import { LAYERS } from '../Constants';

// Theme & Utils
import { colors } from '../../../Core/Theme';
import { createHash } from '../../../Core/Utils';
import { calculateCentroid } from '../Utils';

// Layer properties
const fillProperties = {
  id: LAYERS.UNSELECTABLE_DESK,
  type: 'fill',
  source: 'features',
  paint: {
    'fill-color': colors.lightest,
  },
};
const lineProperties = {
  id: `${LAYERS.UNSELECTABLE_DESK}-lines`,
  type: 'line',
  source: 'features',
  paint: {
    'line-color': colors.middle,
  },
};

// Legend
const legend = {
  key: LAYERS.UNSELECTABLE_DESK,
  style: {
    backgroundColor: colors.lightest,
    borderColor: colors.middle,
    borderWidth: 2,
    borderStyle: 'solid',
  },
  labelKey: 'deskFloorPlan.legend.availableDesk',
};

const getPopupInfo = memoizeOne((desks, hoverIds, data, setInfoPopup) => {
  if (!hoverIds || !hoverIds.length) {
    setInfoPopup(null);
    return;
  }
  if (!desks.length) {
    return;
  }
  hoverIds.forEach(id => {
    if (desks.includes(id)) {
      const features = Lodash.get(data, ['dataset', 'features'], []);
      const featureHover = Lodash.find(features, feature => {
        return Lodash.get(feature, ['id'], null) === id;
      });
      if (featureHover) {
        const geometry = Lodash.get(featureHover, ['geometry'], null);
        const coordinates = calculateCentroid(geometry);
        setInfoPopup(coordinates);
      }
    }
  });
});

const UnselectableDesk = props => {
  const { desks, hoverIds, data, unselectablePopupLabel } = props;
  const { t } = useTranslation();

  const [infoPopup, setInfoPopup] = React.useState(null);
  getPopupInfo(desks, hoverIds, data, setInfoPopup);
  return (
    <>
      <Popup
        message={
          unselectablePopupLabel ||
          t('deskFloorPlan.tooltips.unselectable_desk')
        }
        onPopupClose={() => setInfoPopup(null)}
        latitude={Lodash.get(infoPopup, [1])}
        longitude={Lodash.get(infoPopup, [0])}
      />
      <Layer
        key={`${LAYERS.UNSELECTABLE_DESK}-${createHash(desks)}`}
        properties={[fillProperties, lineProperties]}
        filter={['in', ['get', 'id'], ['literal', desks]]}
        {...props}
      />
    </>
  );
};

UnselectableDesk.defaultProps = {
  desks: [],
  unselectablePopupLabel: null,
};
UnselectableDesk.propTypes = {
  desks: PropTypes.arrayOf(PropTypes.string),
  hoverIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  classes: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
  unselectablePopupLabel: PropTypes.string,
};

export { legend };
export default React.memo(UnselectableDesk);
