/* eslint-disable no-prototype-builtins */
/* eslint-disable react/no-did-update-set-state */
/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
/* eslint-disable jsx-a11y/tabindex-no-positive */

import React, { useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Lodash from 'lodash';
import PropTypes from 'prop-types';
import { useTranslation, withTranslation } from 'react-i18next';
import Slide from '@material-ui/core/Slide';
import Moment from 'moment';

// Common Components
import { MapLink, SmartTable } from '../../../../Components/Common';
import MyReservationsTracking from '../MyReservationsTracking/MyReservationsTracking';
import SeatReservationSummary from '../../../../Components/SeatReservationSummary';
import ReserveOasisWithApi from '../../../../Components/ReserveDeskWithApi';
import SmartMeetingRoomBooking from '../../../../Components/SmartMeetingRoomBooking';
import BannerCancellation from '../../../MeetingRoomBooking/Components/MeetingSummaryReservation/Components/MeetingSummaryCancelation';

// Constants
import { breakpoints, colors } from '../../../../Core/Theme';
import { ACTIONS_COLUMNS } from '../../../../Components/Common/SmartTable/Constants';
import { WAIT_TIME_FOR_SUBMIT_BUTTON_SUCCESS } from '../../../../Core/constants';

// Utils
import { getIntDiffHours } from '../../../MeetingRoomBooking/utils';
import {
  getIsManager,
  getIsDecisionMaker,
} from '../../../../Core/Utils/userPermissions';
import { getColumnList } from '../../utilities';
import { AnyReservation, Office } from '../../../../Models';
import { date as DateUtils, formatCurrency } from '../../../../Core/Utils';
import {
  cancelOfficeReservation,
  deleteBooking,
  deleteSeatReservation,
} from '../../../../Core/Api';
import { trackBookingCancellation } from '../../../../Core/Tracking';
import { getAndAddBookingRestrictions } from '../../../RoomBooking/calUtils';
import {
  RESERVATION_ACTIONS,
  RESERVATION_TYPES,
} from '../../../../Models/anyReservation';
import { fetchAnyReservationsForMonth } from '../../../../Core/Api/anyReservationsAPI';

const DEFAULT_LIMIT = 10;
const INITIAL_OFFSET = 0;
const DEFAULT_CREDITS_PER_HOUR = 0;

const MyReservationsTable = props => {
  const {
    month,
    profile,
    classes,
    accountSfId,
    accountTimezoneId,
    history,
    loadBookingRestriction,
    bookingRestrictions,
    locations,
    deleteReservationInCalendar,
    locationsById,
    onViewReservation,
    onEditReservation,
    onSuccess,
    ...rest
  } = props;
  const { t } = useTranslation();

  const [isLoading, setLoading] = React.useState(true);
  const [isLoadingMore, setLoadingMore] = React.useState(false);

  const [hasMoreData, setHasMore] = React.useState(false);
  const [offset, setOffset] = React.useState(INITIAL_OFFSET);

  const [reservations, setReservations] = React.useState([]);

  const [selectedDeskReservation, setSelectedDeskReservation] =
    React.useState(null);
  const [selectedConferenceBooking, setSelectedConferenceBooking] =
    React.useState(null);
  const [
    selectedConferenceBookingToCancel,
    setSelectedConferenceBookingToCancel,
  ] = React.useState(null);
  const [selectedOfficeReservation, setOfficeReservation] =
    React.useState(null);
  const [selectedSeatBooking, setSelectedSeatBooking] = React.useState(null);
  const [selectedSeatBookingToCancel, setSelectedSeatBookingToCancel] =
    React.useState(null);
  const [isCancelling, setIsCancelling] = React.useState(false);
  const [isCancelSuccess, setCancelSuccess] = React.useState(false);
  const [refreshCount, setRefreshCount] = React.useState(0);

  const isViewingCompany =
    getIsDecisionMaker(profile) && !getIsManager(profile);

  const shouldShowPrice = true;

  const loadData = (setIsLoading = setLoading, _offset = 0) => {
    setIsLoading(true);
    fetchAnyReservationsForMonth({
      limit: DEFAULT_LIMIT,
      offset: _offset,
      month: DateUtils.getDateInMoment(month).format('YYYY-MM'),
      userSfId: !isViewingCompany && !accountSfId ? profile?.sfId : null,
      accountSfId:
        isViewingCompany || accountSfId
          ? accountSfId ?? profile?.accountSfId
          : null,
      reservationTypes: [
        RESERVATION_TYPES.DESK_RESERVATION,
        RESERVATION_TYPES.SEAT_BOOKING,
        RESERVATION_TYPES.CONFERENCE_BOOKING,
        RESERVATION_TYPES.OFFICE_RESERVATION,
      ],
    }).then(objResponse => {
      const { data } = objResponse;
      if (!data) {
        return;
      }
      const { count, reservations: newReservations, limit } = data;
      setHasMore(count >= limit);
      setOffset(_offset + limit);
      setReservations(
        _offset ? [...reservations, ...newReservations] : newReservations
      );
      setIsLoading(false);
    });
  };

  const refreshData = () => {
    setRefreshCount(refreshCount + 1);
  };

  const loadMoreData = () => {
    loadData(setLoadingMore, offset);
  };

  useEffect(() => {
    if (accountSfId || profile.sfId) {
      setOffset(0);
      loadData();
    }
  }, [month, accountSfId, refreshCount, profile.sfId]);

  const getDataForReservation = (reservation, reservationType) => {
    const { owner, grouping } = reservation;
    const location = grouping?.location;
    const timeZoneId = location?.timeZoneId;

    return {
      booking: reservation,
      bookingType: reservationType,
      date: {
        day: DateUtils.getDateInMomentWithZone(
          reservation.startEpoch,
          timeZoneId
        ).format('l'),
      },
      type: t('general.desk'),
      owner: Lodash.get(owner, 'name', 'Empty Desk'),
      location: {
        floorName: t('general.floor', {
          floorName: reservation.desk.parentOffice.floor.floorName,
        }),
        nameRoom: `${Office.getOfficeName(reservation.desk.parentOffice)}, ${t(
          'general.desk_number',
          {
            deskNumber: reservation.desk.deskNumber,
          }
        )}`,
        link: (
          <MapLink
            location={location}
            grouping={grouping}
            profile={profile}
            className={classes.mapLink}
          />
        ),
      },
    };
  };

  const getDataForBooking = (booking, bookingType) => {
    const { space, user, startEpoch, endEpoch } = booking;
    const location = space?.location;
    const grouping = space?.grouping;
    const timeZoneId = location?.timeZoneId;
    const timeZoneCode = DateUtils.getTimeZoneCode(timeZoneId);
    const bookingRule = Lodash.get(booking, ['space', 'bookingRule'], {});
    const hoursEpoch = (
      <div>
        {`${DateUtils.getDateInMomentWithZone(startEpoch, timeZoneId).format(
          '[h:mma]'
        )} - ${DateUtils.getDateInMomentWithZone(endEpoch, timeZoneId).format(
          '[h:mma]'
        )}`}
        <div className={classes.timeZone}>{timeZoneCode}</div>
      </div>
    );
    const creditsPerHour =
      bookingRule?.creditsPerHour ?? DEFAULT_CREDITS_PER_HOUR;

    return {
      booking,
      bookingType,
      date: {
        day: DateUtils.getDateInMomentWithZone(startEpoch, timeZoneId).format(
          'l'
        ),
        hoursEpoch,
      },
      type: t('general.meeting_room'),
      owner: user.name,
      location: {
        floorName: t('general.floor', {
          floorName: space.floor?.floorName,
        }),
        nameRoom: space?.name,
        link: (
          <MapLink
            location={location}
            grouping={grouping}
            profile={profile}
            className={classes.mapLink}
          />
        ),
      },
      ...(shouldShowPrice && {
        hours: t('myReservations.room_cost', {
          count: creditsPerHour * getIntDiffHours(startEpoch, endEpoch),
        }),
      }),
    };
  };

  const getDataForSeatBooking = (booking, bookingType) => {
    const { grouping, user, startEpoch } = booking;
    const timeZoneId = grouping?.location?.timeZoneId;

    return {
      booking,
      bookingType,
      date: {
        day: DateUtils.getDateInMomentWithZone(startEpoch, timeZoneId).format(
          'l'
        ),
      },
      type: t('general.openWorkspace'),
      owner: user?.name,
      location: {
        link: (
          <MapLink
            location={booking.grouping.location}
            grouping={booking.grouping}
            profile={profile}
            className={classes.mapLink}
          />
        ),
      },
    };
  };
  const getDataForOfficeReservation = (booking, bookingType) => {
    const { grouping, owner, startEpoch, office, currency, price } = booking;
    const timeZoneId = grouping?.location?.timeZoneId;
    return {
      booking,
      bookingType,
      date: {
        day: DateUtils.getDateInMomentWithZone(startEpoch, timeZoneId).format(
          'l'
        ),
      },
      type: t('general.office'),
      owner: owner?.name,
      location: {
        nameRoom: Office.getOfficeName(office),
        floorName: office.floor
          ? t('general.floor', {
              floorName: office.floor?.floorName,
            })
          : '',
        link: (
          <MapLink
            location={booking.grouping.location}
            grouping={booking.grouping}
            profile={profile}
            className={classes.mapLink}
          />
        ),
      },
      hours: booking?.usesDayPasses
        ? t('myReservations.table.day_pass')
        : formatCurrency({
            value: price,
            currency,
          }),
    };
  };

  const getTableData = () => {
    if (reservations.length) {
      return reservations.map(data => {
        const { type, reservation } = data;
        const canEdit = AnyReservation.getReservationPermissions({
          reservation,
          type,
          profile,
          action: RESERVATION_ACTIONS.update,
        });
        const canCancel = AnyReservation.getReservationPermissions({
          reservation,
          type,
          profile,
          action: RESERVATION_ACTIONS.delete,
        });

        const canView = AnyReservation.getReservationPermissions({
          reservation,
          type,
          profile,
          action: RESERVATION_ACTIONS.view,
        });
        if (type === RESERVATION_TYPES.DESK_RESERVATION) {
          return {
            canEdit,
            canCancel,
            canView,
            ...getDataForReservation(reservation, type),
          };
        }
        if (type === RESERVATION_TYPES.CONFERENCE_BOOKING) {
          return {
            canEdit,
            canCancel,
            canView,

            ...getDataForBooking(reservation, type),
          };
        }
        if (type === RESERVATION_TYPES.SEAT_BOOKING) {
          return {
            canEdit,
            canCancel,
            canView,

            ...getDataForSeatBooking(reservation, type),
          };
        }
        if (type === RESERVATION_TYPES.OFFICE_RESERVATION) {
          return {
            canEdit: false,
            canCancel,
            canView: false,
            ...getDataForOfficeReservation(reservation, type),
          };
        }
        return null;
      });
    }
    return [];
  };

  const createBookingToTrack = booking => {
    const { space } = booking;

    return {
      startEpoch: +booking.startEpoch,
      space: {
        sfId: space.sfId,
        location: {
          externalName: space.location.externalName,
        },
        name: space.name,
        amenities: space.amenities?.map(amenity => ({
          name: amenity.name,
        })),
        seats: space.seats,
      },
    };
  };

  const trackBookingDelete = () => {
    const bookingToTrack = createBookingToTrack(
      selectedConferenceBookingToCancel
    );
    trackBookingCancellation(bookingToTrack);
  };

  const onCancelConferenceBooking = () => {
    const idBooking = selectedConferenceBookingToCancel?.idBooking;
    const bookingLocation =
      selectedConferenceBookingToCancel?.space?.location?.sfId;

    const newMonth = Moment.unix(selectedConferenceBookingToCancel?.startEpoch)
      .startOf('month')
      .unix();
    setIsCancelling(true);

    return deleteBooking(idBooking)
      .then(() => {
        refreshData();
        onSuccess(RESERVATION_TYPES.CONFERENCE_BOOKING);
        setCancelSuccess(true);
        setIsCancelling(false);
        trackBookingDelete();
        deleteReservationInCalendar(idBooking);
        getAndAddBookingRestrictions(
          loadBookingRestriction,
          profile,
          bookingRestrictions,
          locations
        )(newMonth, bookingLocation);
        setTimeout(() => {
          setCancelSuccess(false);
          setSelectedConferenceBookingToCancel(null);
        }, WAIT_TIME_FOR_SUBMIT_BUTTON_SUCCESS);
      })
      .catch(() => {
        setIsCancelling(false);
        setCancelSuccess(false);
      });
  };

  const onCancelOfficeReservation = () => {
    setIsCancelling(true);
    return cancelOfficeReservation(selectedOfficeReservation?.id)
      .then(() => {
        refreshData();
        onSuccess(RESERVATION_TYPES.OFFICE_RESERVATION);
        setCancelSuccess(true);
        setIsCancelling(false);
        setTimeout(() => {
          setCancelSuccess(false);
          setOfficeReservation(null);
        }, WAIT_TIME_FOR_SUBMIT_BUTTON_SUCCESS);
      })
      .catch(() => {
        setIsCancelling(false);
        setCancelSuccess(false);
      });
  };
  const onActionPress = ({ type: actionType, data }) => {
    if (actionType === ACTIONS_COLUMNS.EDIT) {
      onEditReservation();
    }
    if (actionType === ACTIONS_COLUMNS.VIEW) {
      onViewReservation();
    }
    const { bookingType, booking } = data;
    if (bookingType === RESERVATION_TYPES.DESK_RESERVATION) {
      setSelectedDeskReservation(booking);
    }
    if (bookingType === RESERVATION_TYPES.CONFERENCE_BOOKING) {
      if (actionType === ACTIONS_COLUMNS.CANCEL) {
        setSelectedConferenceBookingToCancel(booking);
      } else {
        setSelectedConferenceBooking(booking);
      }
    }
    if (bookingType === RESERVATION_TYPES.SEAT_BOOKING) {
      if (actionType === ACTIONS_COLUMNS.CANCEL) {
        setSelectedSeatBookingToCancel(booking);
      } else {
        setSelectedSeatBooking(booking);
      }
    }
    if (bookingType === RESERVATION_TYPES.OFFICE_RESERVATION) {
      if (actionType === ACTIONS_COLUMNS.CANCEL) {
        setOfficeReservation(booking);
      }
    }
  };

  const onCancelSeatBooking = () => {
    const reservationId = selectedSeatBookingToCancel?.id;
    setIsCancelling(true);
    return deleteSeatReservation(reservationId)
      .then(() => {
        refreshData();
        onSuccess(RESERVATION_TYPES.SEAT_BOOKING);
        setCancelSuccess(true);
        setIsCancelling(false);
        setTimeout(() => {
          setCancelSuccess(false);
          setSelectedSeatBookingToCancel(null);
        }, WAIT_TIME_FOR_SUBMIT_BUTTON_SUCCESS);
      })
      .catch(() => {
        setIsCancelling(false);
        setCancelSuccess(false);
      });
  };

  const handleOnBackCancellationBanner = () => {
    if (selectedConferenceBookingToCancel) {
      return setSelectedConferenceBookingToCancel(null);
    }
    if (selectedOfficeReservation) {
      return setOfficeReservation(null);
    }
    return setSelectedSeatBookingToCancel(null);
  };

  const handleOnCancelReservation = () => {
    if (selectedConferenceBookingToCancel) {
      return onCancelConferenceBooking();
    }
    if (selectedOfficeReservation) {
      return onCancelOfficeReservation();
    }
    return onCancelSeatBooking();
  };

  const handleSuccess = type => () => {
    refreshData();
    onSuccess(type);
  };

  return (
    <>
      <SmartTable
        onActionPress={onActionPress}
        data={getTableData()}
        emptyMessage={t('myReservations.empty_table')}
        isLoading={isLoading}
        columns={getColumnList({ classes, t })}
        useInfiniteScroll
        hasMore={hasMoreData}
        loadMore={loadMoreData}
        isLoadingMore={isLoadingMore}
        numLoadingRows={DEFAULT_LIMIT * 2}
        renderCustomTopContent={() => (
          <MyReservationsTracking
            month={month}
            profile={profile}
            accountSfId={accountSfId || profile.accountSfId}
            className={classes.trackingDisplay}
            refreshCount={refreshCount}
            timezoneId={accountTimezoneId || profile.primaryLocation.timeZoneId}
          />
        )}
        {...rest}
      />
      <ReserveOasisWithApi
        isOpen={!!selectedDeskReservation}
        onClose={() => {
          setSelectedDeskReservation(null);
        }}
        onSubmit={handleSuccess(RESERVATION_TYPES.DESK_RESERVATION)}
        isCancel
        reservations={[selectedDeskReservation]}
        history={history}
      />
      <SmartMeetingRoomBooking
        isOpen={!!selectedConferenceBooking}
        onClose={() => {
          setSelectedConferenceBooking(null);
        }}
        objBooking={selectedConferenceBooking}
        respectGrouping={false}
        onSuccess={handleSuccess(RESERVATION_TYPES.CONFERENCE_BOOKING)}
      />
      <SeatReservationSummary
        isOpen={!!selectedSeatBooking}
        onClose={() => {
          setSelectedSeatBooking(null);
        }}
        reservation={selectedSeatBooking}
        profile={profile}
        locationsById={locationsById}
        setSelectedReservation={setSelectedSeatBooking}
        onSeatReservationUpdated={handleSuccess(RESERVATION_TYPES.SEAT_BOOKING)}
      />
      <Slide
        direction="up"
        in={
          !!selectedConferenceBookingToCancel ||
          !!selectedSeatBookingToCancel ||
          !!selectedOfficeReservation
        }
        mountOnEnter
        unmountOnExit
      >
        <div className={classes.confirmationCancellationContainer}>
          <BannerCancellation
            onCancel={handleOnCancelReservation}
            onBack={handleOnBackCancellationBanner}
            isSubmitting={isCancelling}
            isSuccess={isCancelSuccess}
          />
        </div>
      </Slide>
    </>
  );
};

const styles = theme => ({
  container: {
    [theme.breakpoints.down(breakpoints.MOBILE)]: {
      paddingLeft: '20px',
      paddingRight: '20px',
    },
    paddingLeft: '40px',
    paddingRight: '40px',
    overflow: 'scroll',
  },
  header: {
    marginTop: '62px',
    paddingBottom: '0px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    maxHeight: '88px',
  },
  monthPicker: {
    [theme.breakpoints.down(breakpoints.MOBILE)]: {
      width: '43.5%',
    },
    width: 150,
    marginBottom: 30,
  },
  title: {
    [theme.breakpoints.down(breakpoints.MOBILE)]: {
      textAlign: 'center',
      fontSize: 20,
      paddingBottom: '32px',
    },
    fontSize: 24,
    fontFamily: "'VerlagBold'",
    paddingBottom: '20px',
  },
  closeButton: {
    [theme.breakpoints.down(breakpoints.MOBILE)]: {
      right: '5px',
      top: '5px',
    },
    position: 'absolute',
    right: '15px',
    top: '15px',
  },

  noWifi: {
    margin: 'auto',
    marginTop: 50,
    width: 'calc(100% - 40px)',
    [theme.breakpoints.up(breakpoints.MOBILE)]: {
      width: 350,
      marginTop: 30,
    },
  },
  titleOwner: {
    padding: 0,
    borderCollapse: 'unset',
    width: '18%',
  },
  titleHead: {
    padding: 0,
    borderCollapse: 'unset',
    width: '20%',
  },
  date: {
    width: '20%',
  },
  location: {
    padding: 0,
    borderCollapse: 'unset',
    width: '20%',
  },
  options: {
    padding: 0,
    borderCollapse: 'unset',
    width: '65px',
  },
  table: {
    border: '1px solid red',
  },

  cellRow: {
    marginBottom: 15,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  tableBody: {
    marginTop: 10,
  },
  confirmationCancellationContainer: {
    boxShadow: '0 -2px 2px 0 rgba(0, 0, 0, 0.1)',
  },
  trackingDisplay: {
    marginBottom: 20,
  },
  timeZone: {
    color: colors.darkest,
    fontSize: '10px',
  },
  mapLink: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    width: '100%',
    textAlign: 'left',
    fontSize: 16,
  },
});

MyReservationsTable.defaultProps = {
  accountSfId: '',
  accountTimezoneId: null,
  onEditReservation: () => null,
  onViewReservation: () => null,
  onSuccess: () => null,
};

MyReservationsTable.propTypes = {
  profile: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  month: PropTypes.number.isRequired,
  accountSfId: PropTypes.string,
  accountTimezoneId: PropTypes.string,
  loadBookingRestriction: PropTypes.func.isRequired,
  bookingRestrictions: PropTypes.object.isRequired,
  locations: PropTypes.arrayOf(PropTypes.object).isRequired,
  deleteReservationInCalendar: PropTypes.func.isRequired,
  locationsById: PropTypes.object.isRequired,
  onViewReservation: PropTypes.func,
  onEditReservation: PropTypes.func,
  onSuccess: PropTypes.func,
};

export default withTranslation()(withStyles(styles)(MyReservationsTable));
