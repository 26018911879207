import { createSelector } from 'reselect';
import Lodash from 'lodash';

export const getFloorsReducer = state => Lodash.get(state, 'floors', {});

export const getFloorsById = createSelector(getFloorsReducer, floors => {
  return Lodash.get(floors, ['data', 'byId'], {});
});
export const getFloorsAllIds = createSelector(getFloorsReducer, floors => {
  return Lodash.get(floors, ['data', 'allIds'], []);
});

export const getFloors = createSelector(getFloorsById, floorsById =>
  Object.values(floorsById)
);
