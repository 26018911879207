import React from 'react';

// Components
import { MapLayerWrapper as Layer } from '../../Common';

// Types
import { Layers } from '../types';

// Theme
import { colors } from '../../../Core/Theme';

const LAYER_ID = Layers.CommonArea;

// Layer properties
const fillProperties = {
  id: LAYER_ID,
  type: 'fill',
  source: 'features',
  paint: {
    'fill-color': colors.palette.secondary2.dark,
  },
};
const lineProperties = {
  id: `${LAYER_ID}-lines`,
  type: 'line',
  source: 'features',
  paint: {
    'line-color': colors.palette.secondary2.darkest,
    'line-width': 2,
  },
};

const CommonArea = (props: { featureIds: string[] }) => {
  const { featureIds } = props;
  return (
    <Layer
      properties={[fillProperties, lineProperties]}
      filter={['in', ['get', 'id'], ['literal', featureIds]]}
      {...props}
    />
  );
};

export default React.memo(CommonArea);
