import Lodash from 'lodash';

export const getDeskDto = async (values, desks) => {
  return {
    desks: desks.map(d => {
      const { idDesk } = d;
      const isBookable = values[idDesk];
      return { ...d, isBookable };
    }),
  };
};

export const updateOffice = (offices, newOffice, newDesks) => {
  const { sfId, isOasisReservable, overrideName } = newOffice;
  return offices.map(o => {
    const officeId = Lodash.get(o, 'sfId', '');
    if (officeId === sfId) {
      return {
        ...o,
        isOasisReservable,
        overrideName,
        desks: newDesks,
      };
    }
    return o;
  });
};

export const mergeSelectedDesksWithNewDesks = (selectedDesks, newDesks) => {
  return selectedDesks.map(d => {
    const { idDesk, isBookable } = d;
    const matchingDesk = Lodash.find(newDesks, { idDesk });
    return {
      ...d,
      isBookable: Lodash.get(matchingDesk, 'isBookable', isBookable),
    };
  });
};

export const updateOfficeDesks = (offices, newDesks) => {
  const parentOfficeSfId = Lodash.get(newDesks, [0, 'parentOfficeSfId'], '');
  return offices.map(o => {
    const { sfId } = o;
    if (parentOfficeSfId === sfId) {
      return { ...o, desks: newDesks };
    }
    return o;
  });
};

export const getReservationsIdByOfficeId =
  strOfficeId =>
  ({ desksLookup, reservationsLookup, reservationsAllIds }) => {
    return reservationsAllIds.filter(strReservationId => {
      const objReservation = Lodash.get(
        reservationsLookup,
        [strReservationId],
        {}
      );
      const intReservationDeskId = Lodash.get(objReservation, ['desk'], null);

      if (intReservationDeskId) {
        const objReservationDesk = Lodash.get(
          desksLookup,
          [`${intReservationDeskId}`],
          {}
        );
        const strParentOfficeSfId = Lodash.get(
          objReservationDesk,
          [`parentOfficeSfId`],
          null
        );

        return strParentOfficeSfId === strOfficeId;
      }
      return false;
    });
  };

export const getReservationsIdByDeskIds =
  deskIds =>
  ({ reservationsLookup, reservationsAllIds }) => {
    return reservationsAllIds.filter(strReservationId => {
      const objReservation = reservationsLookup[strReservationId];
      if (!objReservation) {
        return false;
      }
      return deskIds.includes(objReservation.desk);
    });
  };

export const sortByOfficeName = (officeA, officeB) => {
  const officeAName = officeA.overrideName || officeA.officeName;
  const officeBName = officeB.overrideName || officeB.officeName;

  const nameAParts = officeAName.split(' ');
  const nameBParts = officeBName.split(' ');

  const officeANumber =
    nameAParts.length > 1 ? parseInt(officeAName.split(' ')[1], 10) : NaN;
  const officeBNumber =
    nameBParts.length > 1 ? parseInt(officeBName.split(' ')[1], 10) : NaN;

  const officeASuffix = Number.isNaN(officeANumber)
    ? officeAName
    : officeANumber;
  const officeBSuffix = Number.isNaN(officeBNumber)
    ? officeBName
    : officeBNumber;

  if (officeASuffix < officeBSuffix) {
    return -1;
  }
  if (officeASuffix > officeBSuffix) {
    return 1;
  }
  return 0;
};
