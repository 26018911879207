function validAmenities(amenities, cal) {
  if (amenities.length === 0) {
    return true;
  }
  if (!cal.amenities) {
    return false;
  }
  let ret = true;
  amenities.forEach(amenity => {
    ret = cal.amenities.filter(a => a.sfId === amenity).length > 0;
  });
  return ret;
}

export function validSeats(seats, cal) {
  if (seats.length === 0) {
    return true;
  }

  if (!cal.seats) {
    return false;
  }

  let ret = false;
  seats.forEach(s => {
    if (s === 's') {
      if (cal.seats >= 1 && cal.seats <= 4) {
        ret = true;
      }
    } else if (s === 'm') {
      if (cal.seats >= 5 && cal.seats <= 9) {
        ret = true;
      }
    } else if (s === 'l') {
      if (cal.seats >= 10) {
        ret = true;
      }
    }
  });

  return ret;
}
function validator(cal, amenities, seats) {
  return validAmenities(amenities, cal) && validSeats(seats, cal);
}

export default validator;
