import axios from 'axios';
import Lodash from 'lodash';
import Auth from '@industriousoffice/member-portal-auth';
import { captureException } from '@sentry/react';
import jsonwebtoken from 'jsonwebtoken';
import Moment from 'moment';

const refreshStatus = {
  refreshTokenPromise: undefined,
  isRefreshing: false,
};

const setRefreshTokenPromise = async promiseCb => {
  refreshStatus.refreshTokenPromise = promiseCb();
  return refreshStatus.refreshTokenPromise;
};
const getRefreshTokenPromise = async () => {
  return refreshStatus.refreshTokenPromise;
};
const resetRefreshTokenPromise = () => {
  refreshStatus.refreshTokenPromise = undefined;
  refreshStatus.isRefreshing = false;
  return refreshStatus.refreshTokenPromise;
};
const getIsRefreshing = () => {
  return refreshStatus.isRefreshing;
};
const setIsRefreshing = bool => {
  refreshStatus.isRefreshing = bool;
  return refreshStatus.isRefreshing;
};

export const BufferTimeInSeconds = 3 * 60; // 3 minutes

const refreshTokenResFactory = (wasTokenRefreshed, refreshToken = null) => ({
  wasTokenRefreshed,
  refreshToken,
});

const refreshTokenPipe = () => {
  setIsRefreshing(true);
  return setRefreshTokenPromise(Auth.refreshToken).then(newToken => newToken);
};

// Step 1. create check auth refresh Promise
export const isTokenValid = token => {
  const decoded = jsonwebtoken.decode(token);
  const { exp } = decoded;
  const nowInSeconds = Moment().unix();
  if (exp - BufferTimeInSeconds < nowInSeconds) {
    return false;
  }
  return true;
};

// Step 2. create flag that auth refresh is happening
export const checkRefreshHappenOrNeed = async token => {
  if (!token) return false;
  const validToken = isTokenValid(token);

  //  valid token skip
  if (validToken) {
    return refreshTokenResFactory(false);
  }

  //  if currently refreshing, wait for the token to resolve
  if (getIsRefreshing()) {
    return getRefreshTokenPromise().then(newToken => {
      return refreshTokenResFactory(true, newToken);
    });
  }

  //  if currently not refreshing, call refresh then wait for token to resolve
  return refreshTokenPipe().then(newToken => {
    resetRefreshTokenPromise();
    return refreshTokenResFactory(true, newToken);
  });
};

// Step 3. create request wrapper Promise link
export const setUpInterceptors = (UserManager, store) => {
  return axios.interceptors.request.use(config => {
    const authorization = Lodash.get(
      config,
      ['headers', 'common', 'Authorization'],
      null
    );

    const token = authorization?.slice(7);

    return checkRefreshHappenOrNeed(token)
      .then(res => {
        const { wasTokenRefreshed, refreshToken } = res;

        //  if refresh was unsuccessful, we log out and cancel request
        if (refreshToken && !refreshToken.success) {
          Auth.signOut();
          store.dispatch(UserManager.actions.logout());

          return {
            ...config,
            cancelToken: new axios.CancelToken(cancel =>
              cancel('refresh unsuccessful')
            ),
          };
        }
        //  if token was refreshed and it was successful
        if (wasTokenRefreshed && refreshToken.success) {
          const newToken = refreshToken.message.getIdToken();

          const newTokenJwt = newToken.getJwtToken();
          const clientAccessClaims = JSON.parse(
            newToken.payload['custom:client_access'] ?? '{}'
          );
          store.dispatch(
            UserManager.actions.setClientAccessClaims(clientAccessClaims)
          );
          axios.defaults.headers.common.Authorization = `Bearer ${newTokenJwt}`;
          store.dispatch(UserManager.actions.setUserToken(newTokenJwt));
          const headers = {
            ...config.headers,
            common: {
              ...config.headers.common,
              Authorization: `Bearer ${newTokenJwt}`,
            },
          };

          return { ...config, headers };
        }

        return config;
      })
      .catch(e => {
        captureException(e);
      });
  });
};
