import React from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import FetchAutocomplete from '../FetchAutocomplete';
import { fetchAccountSuggestions } from '../../../Core/Api';
import { breakpoints, colors } from '../../../Core/Theme';
import locationRedux from '../../../Redux/Common/Locations';

const ACCOUNT_LIMIT = 20;

const AccountAutocomplete = objProps => {
  const {
    classes,
    defaultValue,
    label,
    locationSfId,
    accountSfId,
    locationsById,
    className,
    topMenuItems,
    ...rest
  } = objProps;

  const [hasMore, setHasMore] = React.useState(false);
  const [offset, setOffset] = React.useState(false);
  const [input, setInput] = React.useState(false);

  const getSuggestions = () => async (inputValue, setResults) => {
    setInput(inputValue);
    fetchAccountSuggestions(inputValue, locationSfId, ACCOUNT_LIMIT, 0).then(
      response => {
        const { data } = response;
        if (!data) {
          return;
        }
        const { accounts, total } = data;
        setResults(accounts);
        setHasMore(total > ACCOUNT_LIMIT);
        setOffset(offset + ACCOUNT_LIMIT);
      }
    );
  };

  const loadMoreAccounts = addResults => () => {
    fetchAccountSuggestions(input, locationSfId, ACCOUNT_LIMIT, offset).then(
      response => {
        const { data } = response;
        if (!data) {
          return;
        }
        const { accounts, total } = data;
        setOffset(offset + ACCOUNT_LIMIT);
        setHasMore(total > ACCOUNT_LIMIT + offset);
        addResults(input, accounts);
      }
    );
  };

  const renderAccountItem = (item, index, highlightedIndex, itemProps) => {
    const location = locationsById[item.locationSfId];
    const locationName = location?.externalName;
    return (
      <MenuItem
        style={{
          backgroundColor:
            index === highlightedIndex
              ? colors.palette.secondary2.main
              : colors.white,
        }}
        classes={{ root: classes.menuItemRoot }}
        {...itemProps}
      >
        <div
          className={classes.menuItem}
          style={
            topMenuItems.length && topMenuItems.length - 1 === index
              ? {
                  borderBottom: `solid 1px ${colors.dark}`,
                  lineHeight: '50px',
                  padding: 0,
                  paddingTop: 4,
                  marginBottom: 5,
                }
              : {}
          }
        >
          <p className={classes.companyName}>{item.name}</p>
          {locationName && index !== 0 ? (
            <p className={classes.locationName}>{locationName}</p>
          ) : null}
        </div>
      </MenuItem>
    );
  };

  return (
    <div className={className}>
      <FetchAutocomplete
        {...rest}
        defaultValue={defaultValue}
        getSuggestions={getSuggestions(locationSfId, accountSfId)}
        isOpen
        makeMenuItem={renderAccountItem}
        label={label}
        inputClassName={classes.input}
        menuClassName={classes.menu}
        menuContentClassName={classes.menuContent}
        useInfiniteScroll
        loadMoreData={loadMoreAccounts}
        hasMoreData={hasMore}
        resetLoadMoreQueryValue={() => setInput('')}
        topMenuItems={topMenuItems}
      />
    </div>
  );
};
const styles = theme => ({
  menuItemRoot: {
    height: 55,
    padding: 0,
  },
  menuItem: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    fontFamily: 'VerlagLight',
    fontSize: '20px',
    width: '100%',
    marginLeft: 16,
    marginRight: 16,
    paddingTop: 5,
    paddingBottom: 1,
  },
  companyName: {
    color: colors.black,
    width: '100%',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    fontFamily: 'VerlagLight',
    fontSize: '20px',
    margin: 0,
    [theme.breakpoints.down(breakpoints.MOBILE)]: {
      fontSize: 18,
    },
  },
  locationName: {
    color: colors.dark,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    fontFamily: 'VerlagLight',
    fontSize: '14px',
    margin: 0,
    [theme.breakpoints.down(breakpoints.MOBILE)]: {
      fontSize: 14,
    },
  },
  input: {
    fontSize: 18,
    paddingBottom: 7,
    paddingTop: 9,
    width: 'calc(100% - 30px)',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    [theme.breakpoints.up(breakpoints.MOBILE)]: {
      fontSize: 20,
    },
  },
  menuContent: {
    maxHeight: 364,
    borderRadius: 3,
    boxShadow: `0 0 3px 1px rgba(0, 0, 0, 0.25)`,
    border: `solid 1px ${colors.dark}`,
    zIndex: 2,
    overflowY: 'scroll',
    [theme.breakpoints.down(breakpoints.MOBILE)]: {
      right: 0,
      left: 0,
      maxHeight: 250,
    },
  },
  menu: {
    [theme.breakpoints.down(breakpoints.MOBILE)]: {
      width: 'auto',
    },
  },
});

AccountAutocomplete.defaultProps = {
  defaultValue: '',
  locationSfId: null,
  topMenuItems: [],
};

AccountAutocomplete.propTypes = {
  classes: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
  defaultValue: PropTypes.string,
  locationSfId: PropTypes.string,
  locationsById: PropTypes.object.isRequired,
  topMenuItems: PropTypes.arrayOf(PropTypes.object),
};

const mapStateToProps = state => {
  return {
    locationsById: locationRedux.selectors.getLocationsById(state),
  };
};

export default withStyles(styles)(
  connect(mapStateToProps, undefined)(AccountAutocomplete)
);
