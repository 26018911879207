import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import axios from 'axios';

import { bindActionCreators } from 'redux';
import UserManager from '../../../Redux/Common/UserManager';
import store from '../../../Redux';
import { setUpInterceptors } from '../../../Core/Api';
import { setSentryUser } from '../../../Core/Utils';
import { setUpErrorInterceptor } from '../../../Core/Api/errorInterceptor';

const AuthContext = React.createContext();

const AuthProvider = props => {
  const { children, userToken, profile, addApiError } = props;

  //  set the default header when user goes to page directly
  axios.defaults.headers.common.Authorization = `Bearer ${userToken}`;
  setUpInterceptors(UserManager, store);
  setSentryUser(profile);
  setUpErrorInterceptor(addApiError);

  return (
    <AuthContext.Provider
      value={{
        isAuth: !!userToken,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

const mapStateToProps = state => {
  return {
    userToken: UserManager.selectors.getUserToken(state),
    userSfId: UserManager.selectors.getUserId(state),
    profile: UserManager.selectors.getUserProfile(state),
  };
};

const mapDispatchToProps = dispatch => {
  const { actions } = UserManager;

  return bindActionCreators(
    {
      addApiError: actions.addApiError,
    },
    dispatch
  );
};

AuthProvider.defaultProps = {
  userToken: '',
};

AuthProvider.propTypes = {
  userToken: PropTypes.string,
  profile: PropTypes.object.isRequired,
  addApiError: PropTypes.func.isRequired,
};
const { Consumer: AuthConsumer } = AuthContext;

export const Provider = connect(
  mapStateToProps,
  mapDispatchToProps
)(AuthProvider);

export const Consumer = AuthConsumer;
