/* eslint-disable func-names */
import * as Yup from 'yup';

export const DESK_RESERVATION_SCHEMA = Yup.object().shape({
  selected: Yup.array(),
  desks: Yup.object(),
});

export const RESERVATION_START_HOUR_OFFSET = 9;
export const RESERVATION_END_HOUR_OFFSET = 17;

export const MAX_DAY_TO_RESERVATE = 90;

export const DESK_RESERVATION_PATH = '/home/deskreservation';
