import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { date as dateUtils } from '../../../../Core/Utils';

import { useOfficeReservationCharges } from '../../../../Hooks';

import TrackingTable from './TrackingTable';
import TrackingTitle from './TrackingTitle';
import TrackingTableItem from './TrackingTableItem';
import TrackingContainer from './TrackingContainer';
import useDayPasses from '../../../../Hooks/useDayPasses';
import { DESK_DAYPASS_DEPRECATION_MONTH } from '../../../../Components/ActivityTableSummary/constants';
import { getUserProfile } from '../../../../Redux/Common/UserManager/selectors';
import { dayPassRoleAccess } from '../../../../Core/Utils/userPermissions';

type Props = {
  accountSfId: string;
  startEpoch: number;
  endEpoch: number;
  refreshCount: number;
};

const OfficeStats = (props: Props) => {
  const { accountSfId, startEpoch, endEpoch, refreshCount } = props;
  const profile = useSelector(getUserProfile);
  const { t } = useTranslation();
  const {
    charges,
    isLoading: isLoadingCharges,
    totalReservations,
    formattedCharges,
  } = useOfficeReservationCharges({
    accountSfId,
    startEpoch,
    endEpoch,
    refreshCount,
  });

  const {
    hasUnlimitedDayPasses,
    allottedPasses,
    usedPasses,
    isFetching: isLoadingPasses,
    charge: dayPassCharge,
  } = useDayPasses(startEpoch, profile, accountSfId);

  const showDeskDayPassInfo = dateUtils
    .getDateInMoment(startEpoch)
    .isBefore(dateUtils.getDateInMoment(DESK_DAYPASS_DEPRECATION_MONTH));

  const usesDayPasses = dayPassRoleAccess.hasFeature({
    obj: {
      hasUnlimitedDayPasses,
      allotted: allottedPasses,
      date: dateUtils.getDateInMoment(startEpoch).toDate(),
    },
    profile,
  });

  const isLoading = isLoadingCharges || isLoadingPasses;

  return (
    <TrackingContainer>
      <TrackingTitle
        title={
          showDeskDayPassInfo
            ? t('myReservations.tracking_hour.desks')
            : t('myReservations.tracking_hour.offices')
        }
      />
      <TrackingTable>
        {usesDayPasses && (
          <>
            <TrackingTableItem
              label={t('myReservations.tracking_hour.allotted')}
              value={
                hasUnlimitedDayPasses
                  ? t('myReservations.tracking_hour.unlimited')
                  : t('myReservations.tracking_hour.pass_cost', {
                      count: allottedPasses,
                    })
              }
              isLoading={isLoadingPasses}
            />
            <TrackingTableItem
              label={t('myReservations.tracking_hour.used')}
              value={t('myReservations.tracking_hour.pass_cost', {
                count: usedPasses,
              })}
              isLoading={isLoadingPasses}
            />
          </>
        )}
        {!usesDayPasses && (
          <TrackingTableItem
            label={t('myReservations.tracking_hour.used')}
            value={t('myReservations.tracking_hour.reservation_count', {
              count: totalReservations,
            })}
            isLoading={isLoading}
          />
        )}
        {((showDeskDayPassInfo && dayPassCharge) || !!charges?.length) && (
          <TrackingTableItem
            label={t('myReservations.tracking_hour.monthly_charge')}
            value={showDeskDayPassInfo ? `$${dayPassCharge}` : formattedCharges}
            isLoading={isLoading}
          />
        )}
      </TrackingTable>
    </TrackingContainer>
  );
};

export default OfficeStats;
