import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import { Icons } from '../../../resources';

const FailWhale = props => {
  const { classes, fullpage } = props;
  const { t } = useTranslation();

  return (
    <div className={clsx(classes.container, fullpage && classes.fullpage)}>
      <Icons.ComputerSearch className={classes.icon} />
      <h1 className={classes.header}>{t('failWhale.page_not_loaded')}</h1>
      <p className={classes.message}>{t('failWhale.please_refresh')}</p>
    </div>
  );
};

const styles = () => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  fullpage: {
    width: '100vw',
    height: '80vh',
  },
  icon: {
    width: '154px',
    height: '133px',
  },
  header: {
    margin: '30px 0 0 0',
    padding: 0,
    fontFamily: 'Verlagbook',
    fontSize: '26px',
    fontWeight: 'bold',
  },
  message: {
    margin: '15px 0 0 0',
    fontFamily: 'Verlagbook',
    fontSize: '24px',
    fontWeight: 300,
  },
});

FailWhale.defaultProps = {
  classes: {},
  fullpage: false,
};

FailWhale.propTypes = {
  classes: PropTypes.object,
  fullpage: PropTypes.bool,
};

export default withStyles(styles)(FailWhale);
