/* eslint-disable no-prototype-builtins */

import Lodash from 'lodash';
import Moment from 'moment';

import { date as DateUtils } from '../../Core/Utils';

export const getNewFloorFromData =
  (setSelectedFloor, selectedFloor, locationSfId) => data => {
    const oldFloorLocation = Lodash.get(selectedFloor, 'locationSfId', '');
    if (locationSfId === oldFloorLocation) {
      return;
    }
    const newFloors = Lodash.get(data, ['action', 'payload']);
    if (!newFloors.length) {
      setSelectedFloor({});
    }

    const sorted = Lodash.sortBy(newFloors, 'floorName');
    setSelectedFloor(sorted[0]);
  };

export const selectLocation =
  (
    setGrouping,
    selectedGrouping,
    setDate,
    selectedDate,
    addFloors,
    setSelectedFloor,
    selectedFloor
  ) =>
  grouping => {
    const { location } = grouping;

    const { sfId, timeZoneId } = location;
    const oldTimezone = Moment().tz();
    if (timeZoneId) {
      const newDate = DateUtils.updateDateForTZ(
        selectedDate,
        timeZoneId,
        oldTimezone
      );
      setDate(newDate);
      DateUtils.setTimezone(timeZoneId);
    }

    addFloors(sfId).then(
      getNewFloorFromData(setSelectedFloor, selectedFloor, sfId)
    );
    setGrouping(grouping);
  };
