import React from 'react';
import PropTypes from 'prop-types';

// Components
import { MapLayerWrapper as Layer } from '../../Common';

// Constants
import { LAYERS } from '../Constants';

// Theme & Utils
import { colors } from '../../../Core/Theme';
import { createHash } from '../../../Core/Utils';

// Layer properties
const fillPropertiesHover = {
  id: LAYERS.UNRESERVABLE_DESK,
  type: 'fill',
  source: 'features',
  paint: {
    'fill-color': [
      'case',
      ['boolean', ['feature-state', 'hover'], false],
      colors.light,
      colors.middle,
    ],
  },
};
const fillPropertiesNoHover = {
  id: LAYERS.UNRESERVABLE_DESK,
  type: 'fill',
  source: 'features',
  paint: {
    'fill-color': colors.middle,
  },
};
const lineProperties = {
  id: `${LAYERS.UNRESERVABLE_DESK}-lines`,
  type: 'line',
  source: 'features',
  paint: {
    'line-color': colors.darkGray,
    'line-width': 2,
    'line-opacity': 0.6,
  },
};

// Legend
const legend = {
  key: LAYERS.UNRESERVABLE_DESK,
  style: {
    backgroundColor: colors.middle,
    borderColor: colors.middle,
    borderWidth: 2,
    borderStyle: 'solid',
  },
  labelKey: 'deskFloorPlan.legend.unreservableDesk',
};

const UnreservableDesk = props => {
  const { desks, isOfficeUnassigned } = props;
  return (
    <Layer
      key={`${LAYERS.UNRESERVABLE_DESK}-${createHash(desks)}`}
      properties={[
        isOfficeUnassigned ? fillPropertiesNoHover : fillPropertiesHover,
        lineProperties,
      ]}
      filter={['in', ['get', 'id'], ['literal', desks]]}
      {...props}
    />
  );
};

UnreservableDesk.defaultProps = {
  desks: [],
  isOfficeUnassigned: false,
};
UnreservableDesk.propTypes = {
  desks: PropTypes.arrayOf(PropTypes.string),
  isOfficeUnassigned: PropTypes.bool,
};

export { legend };
export default React.memo(UnreservableDesk);
