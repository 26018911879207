import { useTranslation } from 'react-i18next';
import Chip, { ChipThemes } from '../../Chip/Chip';

type Props = {
  className?: string;
  seats: number;
  isDisabled?: boolean;
};

export const OfficeSeatsChip = (props: Props) => {
  const { className, seats, isDisabled } = props;
  const { t } = useTranslation();

  return (
    <Chip
      className={className}
      theme={isDisabled ? ChipThemes.Gray : ChipThemes.Primary}
    >
      {`${seats} ${
        seats > 1
          ? t<string>('reserveOffice.seats')
          : t<string>('reserveOffice.seat')
      }`}
    </Chip>
  );
};

OfficeSeatsChip.defaultProps = {
  className: '',
  isDisabled: false,
};
