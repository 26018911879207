import { useState } from 'react';
import { getReservedOfficeSfIds } from '../../../Core/Api';
import { date as DateUtils } from '../../../Core/Utils';

export const useOfficeHasFuturReservation = () => {
  const [officeSfIds, setOfficeSfIds] = useState<string[]>();
  const [isLoading, setIsLoading] = useState(false);
  const today = DateUtils.getTodayEpoch();

  const refresh = async (params: { floorId: string; locationSfId: string }) => {
    const { floorId, locationSfId } = params;
    setIsLoading(true);
    const result = await getReservedOfficeSfIds({
      floorId,
      locationSfId,
      minStartEpoch: today,
    });

    setOfficeSfIds(result);
    setIsLoading(false);
  };

  const hasFutureReservation = (officeSfId: string) =>
    officeSfIds?.includes(officeSfId);

  return { hasFutureReservation, isLoading, refresh };
};
