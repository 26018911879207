import * as React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { captureException } from '@sentry/react';
import { Icons } from '../../../resources';
import { colors } from '../../../Core/Theme';
import { createICS } from '../../../Core/Utils';
import TextButton from '../TextButton';

// TODO: refactor the a tag to BaseButton after we upgrade material UI to v4

const AddCalendarBtn = props => {
  const { classes, className, event, onClick, divClassName } = props;
  const { title, startEpoch, endEpoch, locationName, description } = event;

  const [file, setFile] = React.useState('');

  React.useEffect(() => {
    let cancel = false;

    createICS(event)
      .then(res => {
        if (!cancel) {
          setFile(res);
        }
      })
      .catch(e => captureException(e));

    return () => {
      cancel = true;
    };
  }, [title, startEpoch, endEpoch, locationName, description]);

  const download = e => {
    e.preventDefault();
    e.stopPropagation();
    const blob = new Blob([file], { type: 'text/calendar;charset=utf-8' });
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.setAttribute('download', `${title}.ics`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    onClick(e);
  };

  return (
    <TextButton
      text={
        <div className={clsx(classes.main, className)}>
          <Icons.Calendar />
          <span className={classes.text}>Add to my calendar</span>
        </div>
      }
      onClick={download}
      className={divClassName}
    />
  );
};

const styles = () => ({
  main: {
    fontFamily: 'VerlagBook',
    fontSize: '15px',
    fontWeight: 300,
    lineHeight: 1.2,
    letterSpacing: 'normal',
    color: colors.palette.primary.main,
    margin: 0,
    display: 'flex',
    style: '',
  },
  clickableDiv: {
    cursor: 'pointer',
    textDecoration: 'none',
  },
  text: {
    marginLeft: '5px',
  },
});

AddCalendarBtn.defaultProps = {
  classes: {},
  className: '',
  divClassName: '',
  event: {},
  onClick: () => null,
};

AddCalendarBtn.propTypes = {
  classes: PropTypes.shape({}),
  className: PropTypes.string,
  divClassName: PropTypes.string,
  event: PropTypes.shape({
    title: PropTypes.string,
    startEpoch: PropTypes.number,
    endEpoch: PropTypes.number,
    locationName: PropTypes.string,
    description: PropTypes.shape({}),
  }),
  onClick: PropTypes.func,
};

export default withStyles(styles)(AddCalendarBtn);
