/* eslint-disable no-shadow */
import * as React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import queryString from 'query-string';
import Lodash from 'lodash';
import { useTranslation } from 'react-i18next';

// Components
import {
  AuthPage,
  AuthBody,
  PasswordInput,
  PasswordReq,
} from '../../Components/Auth';
import { SubmitButton, Text } from '../../Components/Common';

//  Functionality
import updatePW from './updatePW';

// Styling
import styles from './styles';

const ResetPW = props => {
  const { classes, history } = props;
  const { t } = useTranslation();
  const [newPassword, inputNewPassword] = React.useState('');
  const [displayMsg, updateDisplayMsg] = React.useState('');
  const [isLoading, setLoading] = React.useState(false);
  const [isSuccess, setSuccess] = React.useState(false);

  const {
    email,
    tpw,
    name: rawName,
  } = queryString.parse(window.location.search);

  //  + character in email becomes space
  //  TODO: replace with const after we remove the fromHashRouting block
  let username = email ? email.replace(' ', '+') : email;
  let name = rawName ? Lodash.capitalize(rawName) : rawName;
  let tempPassword = tpw;

  //  TODO: remove following block after we update the link in lambda
  const { location } = props;
  const fromHashRouting = Lodash.get(location, ['state'], null);
  if (fromHashRouting) {
    const {
      username: hashUserName,
      name: hashName,
      tempPassword: hashTempPassword,
    } = fromHashRouting;
    username = hashUserName;
    name = hashName;
    tempPassword = hashTempPassword;
  }

  React.useEffect(() => {
    if (isSuccess) {
      setTimeout(() => history.push('/login'), 2500);
    }
  }, [isSuccess]);

  const handleEnter = e => {
    if (e.nativeEvent.type === 'click' || e.key === 'Enter') {
      updatePW(
        username,
        tempPassword,
        newPassword,
        updateDisplayMsg,
        setLoading,
        setSuccess,
        t
      );
    }
  };

  return (
    <AuthPage>
      <AuthBody styleName="form">
        <React.Fragment>
          <div className={classes.welcome}>
            <Text text={t('resetPW.welcome', { name })} />
          </div>
          <div className={classes.title}>
            <Text text={t('resetPW.set_password')} />
          </div>

          <div className={classes.inputsContainer}>
            {displayMsg && (
              <div className={classes.containerMetaInfo}>
                <Text text={displayMsg} />
              </div>
            )}
            <PasswordInput
              textfieldclass={classes.fieldInputStyle}
              placeholder={t('resetPW.password')}
              value={newPassword}
              onChange={e => inputNewPassword(e.target.value)}
              onKeyDown={handleEnter}
              label={t('resetPW.password')}
              id="passwordInput"
            />

            <PasswordReq />

            <SubmitButton
              isLoading={isLoading}
              isSuccess={isSuccess}
              defaultText={t('resetPW.create_account')}
              disabled={isLoading || isSuccess}
              onClick={handleEnter}
              className={classes.submit}
            />
          </div>
        </React.Fragment>
      </AuthBody>
    </AuthPage>
  );
};

ResetPW.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  location: PropTypes.shape({}).isRequired,
  history: PropTypes.shape({}).isRequired,
};

export default withStyles(styles)(ResetPW);
