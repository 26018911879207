// Styles
import { colors, breakpoints } from '../../Core/Theme';

const styles = theme => ({
  fieldInputStyle: {
    marginTop: '10%',
  },
  inputsContainer: {
    margin: '0 auto',
    flexDirection: 'column',
    maxWidth: '266px',
    maxHeight: '480px',
    marginTop: '5%',
    [theme.breakpoints.down(breakpoints.MOBILE)]: {
      marginLeft: 20,
      marginRight: 20,
      maxWidth: '100vw',
      marginTop: '4%',
    },
  },
  welcome: {
    fontFamily: "'VerlagBook'",
    fontSize: '36px',
    fontWeight: 'bold',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 1.25,
    letterSpacing: 'normal',
    color: colors.white,
    textAlign: 'center',
    marginTop: '10%',
    margin: 'auto',
    [theme.breakpoints.down(breakpoints.MOBILE)]: {
      marginTop: '30vh',
      fontSize: 24,
    },
  },
  containerMetaInfo: {
    height: '30px',
    fontFamily: "'VerlagBook'",
    fontSize: '12px',
    fontWeight: 'bold',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 1.25,
    letterSpacing: 'normal',
    color: colors.alertMessage,
    backgroundColor: colors.alertBackground,
    margin: 'auto',
    textAlign: 'left',
    padding: 10,
    borderRadius: 2,
  },
  title: {
    width: '247px',
    fontFamily: "'VerlagBook'",
    fontSize: '24px',
    fontWeight: 'normal',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 1.08,
    letterSpacing: 'normal',
    textAlign: 'center',
    color: colors.white,
    margin: 'auto',
    marginTop: '30px',
    marginBottom: '5%',
    [theme.breakpoints.down(breakpoints.MOBILE)]: {
      fontSize: 20,
      marginTop: '4%',
      marginBottom: '4%',
    },
  },
  inputLabel: {
    position: 'absolute !important',
    height: 1,
    width: 1,
    overflow: 'Hidden',
    clip: 'rect(1px, 1px, 1px, 1px)',
    whiteSpace: 'nowrap',
  },
  submit: {
    marginBottom: 40,
    marginTop: 8,
    height: 50,
    backgroundColor: `${colors.palette.primary.main} !important`,
    color: `${colors.white} !important`,
    '&:hover': {
      backgroundColor: `${colors.palette.primary.light} !important`,
    },
  },
});

export default styles;
