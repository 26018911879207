/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { useMemo } from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Lodash from 'lodash';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import { busyHalfHour } from '../../../resources';
import { getHeightOfBooking } from '../util';
import { colors } from '../../../Core/Theme';
import { ReservationSource } from '../../../Core/Api';

const ReservationSlot = props => {
  const { booking, classes, interval, onClick, isDraft, isFullHeight } = props;
  const { t } = useTranslation();

  const name = Lodash.get(booking, ['user', 'name'], '');
  const title =
    booking.source === ReservationSource.OnDemand
      ? t('general.onDemand')
      : booking.title;
  const { canView, isMyBooking, startEpoch, endEpoch, isError } = booking;
  const isUserBooking = isDraft || isMyBooking;
  const is30minBooking = endEpoch - startEpoch <= 30 * 60;
  const isCoworkerBooking = canView && !isMyBooking;
  const height = useMemo(() => {
    const bookingHeight = getHeightOfBooking(
      interval,
      startEpoch,
      endEpoch,
      isCoworkerBooking
    );
    return isFullHeight ? bookingHeight + 1 : bookingHeight;
  }, [interval, startEpoch, endEpoch, isCoworkerBooking, isFullHeight]);

  if (!(canView || isMyBooking) && !isDraft) {
    return (
      <div
        style={{
          height,
        }}
        className={classes.unavailable}
      />
    );
  }
  if (is30minBooking) {
    return (
      <div
        style={{
          height,
        }}
        className={clsx(
          classes.body,
          isUserBooking && classes.myBooking,
          isError && classes.error
        )}
        onClick={onClick}
      >
        <div
          className={clsx(classes.title, isUserBooking && classes.userTitle)}
        >
          {isUserBooking ? title || name : name}
        </div>
      </div>
    );
  }

  return (
    <div
      style={{
        height,
      }}
      className={clsx(
        classes.body,
        isUserBooking && classes.myBooking,
        isError && classes.error
      )}
      onClick={onClick}
    >
      <div className={clsx(classes.title, isUserBooking && classes.userTitle)}>
        {title || name}
      </div>
      {title && name ? (
        <div className={clsx(classes.name, isUserBooking && classes.userName)}>
          {name}
        </div>
      ) : null}
    </div>
  );
};

const styles = {
  unavailable: {
    backgroundImage: `url(${busyHalfHour})`,
    backgroundSize: 190,
    zIndex: 2,
    borderRadius: 4,
    position: 'relative',
    opacity: 0.6,
  },
  body: {
    background: colors.palette.secondary2.main,
    borderRadius: 4,
    fontSize: 15,
    paddingLeft: 10,
    zIndex: 2,
    position: 'relative',
  },
  myBooking: {
    background: colors.palette.primary.main,
  },
  title: {
    paddingTop: 8,
    fontFamily: 'VerlagBook',
    width: 'calc(100% - 5px)',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    paddingRight: 5,
  },
  userTitle: {
    color: colors.white,
  },
  name: {
    fontFamily: 'VerlagLight',
    paddingTop: 11,
    width: 'calc(100% - 5px)',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  userName: {
    color: colors.white,
  },
  error: {
    background: colors.alertBackground,
    opacity: 0.6,
  },
};

ReservationSlot.defaultProps = {
  onClick: () => '',
  isDraft: false,
  isFullHeight: false,
};

ReservationSlot.propTypes = {
  classes: PropTypes.object.isRequired,
  onClick: PropTypes.func,
  booking: PropTypes.object.isRequired,
  interval: PropTypes.number.isRequired,
  isDraft: PropTypes.bool,
  isFullHeight: PropTypes.bool,
};

export default withStyles(styles)(ReservationSlot);
