import { Theme, withStyles } from '@material-ui/core';
import {
  ClassNameMap,
  StyleRulesCallback,
} from '@material-ui/core/styles/withStyles';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import { Carat } from '../../../Components/Common';
import { breakpoints, colors } from '../../../Core/Theme';

type Props = {
  classes: ClassNameMap;
  nbReservableDesks: number;
  nbTotalDesks: number;
  isDisabled: boolean;
  isDesksDisplayed: boolean;
};

const OfficeDeskReservableResume = (props: Props) => {
  const {
    classes,
    nbReservableDesks,
    nbTotalDesks,
    isDisabled,
    isDesksDisplayed,
  } = props;
  const { t } = useTranslation();

  return (
    <div className={classes.reservableDesksWrapper}>
      <span className={classes.reservableDesksDesktop}>
        {t<string>('manageOffices.desks_reservable', {
          nbDesks: nbReservableDesks,
          count: nbTotalDesks,
        })}
      </span>
      <Carat
        size="medium"
        orientation={isDesksDisplayed ? 'up' : 'down'}
        className={clsx(classes.carat, isDisabled && classes.caratDisabled)}
      />
    </div>
  );
};

const style: StyleRulesCallback<string> = (theme: Theme) => ({
  reservableDesksWrapper: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
  },
  reservableDesksDesktop: {
    display: 'none',
    fontSize: 16,
    [theme.breakpoints.up(breakpoints.MOBILE)]: {
      display: 'block',
    },
  },
  carat: {
    marginLeft: 12,
    fill: colors.palette.primary.main,
    '&:hover': {
      fill: colors.palette.primary.light,
    },
  },
  caratDisabled: {
    fill: colors.gray,
    '&:hover': {
      fill: colors.gray,
    },
  },
});

export default withStyles(style)(OfficeDeskReservableResume);
