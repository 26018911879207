// Constants
import { breakpoints } from '../../../Core/Theme';
import { getStaticImageUrl } from '../../../Core/Utils';

const styles = theme => ({
  container: {
    display: 'flex',
    [theme.breakpoints.down(breakpoints.mobile)]: {
      backgroundImage: `url(${getStaticImageUrl(
        '2020-05/CenturyCity_Mobile.jpg'
      )})`,
    },
    backgroundImage: `url(${getStaticImageUrl(
      '2020-05/Portland_Desktop.jpg'
    )})`,
    width: '100%',
    height: '100vh',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    alignItems: 'center',
    justifyContent: 'center',
  },
});

export default styles;
