import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { conformToMask } from 'react-text-mask';
import { useTranslation } from 'react-i18next';

// Components
import { Snack, InfoBanner } from '../../Components/Common';
import UserManager from '../../Redux/Common/UserManager';
import {
  getUserProfile,
  getUserSettings,
} from '../../Redux/Common/UserManager/selectors';
import { getUserGroupings } from '../../Redux/Common/Groupings/selectors';

import MemberProfile from './MemberProfile';
import { Panel } from '../../Components/DemographicsCommon';

// Theme
import { breakpoints, colors } from '../../Core/Theme';

// API
import { updateUserAvatar, patchUser, fetchUserPickList } from '../../Core/Api';

// Utils
import { PHONE_MASK } from '../../Core/constants';
import PinCodeSection from './PinCodeSection';

const ContactMainContainer = props => {
  const { classes, userProfile, setUserProfile, userGroupings, userSettings } =
    props;
  const {
    preferredPronouns,
    personalBio,
    twitterHandle,
    instagramHandle,
    facebookProfileUrl,
    linkedinProfileUrl,
    name,
    email,
    sfId,
    avatarUrl,
    jobTitle,
    account,
    phoneNumber,
  } = userProfile;

  const { t } = useTranslation();
  const [isModalOpen, setModalOpen] = React.useState(false);
  const [displayImage, setDisplayImage] = React.useState(avatarUrl);
  const [displayMsg, setDisplayMsg] = React.useState(null);
  const [isConfirmSnackOpen, setConfirmSnackOpen] = React.useState(false);

  const toggleModal = () => {
    isModalOpen ? setModalOpen(false) : setModalOpen(true);
  };

  const openSnack = () => setConfirmSnackOpen(true);
  const closeSnack = () => setConfirmSnackOpen(false);

  const onSubmit = userSfId => {
    return function submit(image) {
      return updateUserAvatar(userSfId, image).then(
        () => {
          openSnack();
        },
        () => setDisplayMsg(t('general.updates_failed'))
      );
    };
  };
  let conformed;
  if (phoneNumber) {
    const phoneNumberJustNums = phoneNumber.replace(/\D/g, '');
    conformed = conformToMask(phoneNumberJustNums, PHONE_MASK, {
      guide: true,
    }).conformedValue;
  }

  return (
    <main className={classes.content}>
      {displayMsg && (
        <InfoBanner
          variant="error"
          theme="light"
          text={displayMsg}
          className={classes.errorMessage}
          scrollOnMount
        />
      )}
      <h1 className={classes.header}>{t('contactProfile.title')}</h1>
      <div className={classes.mainView}>
        <Panel
          className={classes.panel}
          name={name}
          toggleModal={toggleModal}
          setDisplayImage={setDisplayImage}
          sfId={sfId}
          image={displayImage}
          isModalOpen={isModalOpen}
          imageSubmit={onSubmit(sfId)}
          imageTitle={t('contactProfile.edit_profile_photo')}
        />
        <MemberProfile
          preferredPronouns={preferredPronouns}
          personalBio={personalBio}
          twitterHandle={twitterHandle}
          instagramHandle={instagramHandle}
          facebookProfileUrl={facebookProfileUrl}
          linkedinProfileUrl={linkedinProfileUrl}
          name={name}
          email={email}
          jobTitle={jobTitle}
          companyName={account?.name}
          patchUser={patchUser}
          sfId={sfId}
          fetchUserPickList={fetchUserPickList}
          setUserProfile={setUserProfile}
          setDisplayMsg={setDisplayMsg}
          phoneNumber={conformed || phoneNumber}
          openSnack={openSnack}
          userProfile={userProfile}
          userGroupings={userGroupings}
          userSettings={userSettings}
        />
        <Snack
          message={t('general.updates_saved')}
          autoClose
          isOpen={isConfirmSnackOpen}
          onClose={closeSnack}
        />
      </div>
      <PinCodeSection />
    </main>
  );
};

const styles = theme => ({
  content: {
    marginTop: 40,
    marginLeft: 20,
    marginRight: 20,
    [theme.breakpoints.up(breakpoints.MOBILE)]: {
      padding: theme.spacing.unit * 3,
      marginTop: 24,
      marginLeft: 150,
      marginRight: 0,
    },
  },
  header: {
    padding: 0,
    margin: 0,
    fontFamily: 'VerlagBold',
    fontWeight: 100,
    [theme.breakpoints.down(breakpoints.MOBILE)]: {
      fontSize: 24,
    },
  },
  panel: {
    flexGrow: 1,
    // padding: theme.spacing.unit * 3,
    display: 'flex',
    flexDirection: 'column',
    maxWidth: 120,
    padding: 0,
  },
  mainView: {
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column',
    [theme.breakpoints.up(breakpoints.MOBILE)]: {
      flexDirection: 'row',
      marginTop: 30,
    },
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: colors.palette.background.paper,
    border: '2px solid #000',
  },
  errorMessage: {
    width: 'auto',
    marginBottom: 20,
    [theme.breakpoints.up(breakpoints.MOBILE)]: {
      marginTop: -20,
      marginRight: 150,
    },
  },
  popup: {
    width: 375,
  },
});

ContactMainContainer.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  userGroupings: PropTypes.arrayOf(PropTypes.object).isRequired,
  userSettings: PropTypes.shape({}).isRequired,
  userProfile: PropTypes.shape({
    _id: PropTypes.string,
    preferredPronouns: PropTypes.string,
    personalBio: PropTypes.string,
    twitterHandle: PropTypes.string,
    instagramHandle: PropTypes.string,
    facebookProfileUrl: PropTypes.string,
    linkedinProfileUrl: PropTypes.string,
    name: PropTypes.string,
    email: PropTypes.string,
    sfId: PropTypes.string,
    avatarUrl: PropTypes.string,
    jobTitle: PropTypes.string,
    companyName: PropTypes.string,
    phoneNumber: PropTypes.string,
    account: PropTypes.shape({
      name: PropTypes.string,
    }),
  }).isRequired,
  setUserProfile: PropTypes.func.isRequired,
};

const mapStateToProps = state => {
  return {
    userProfile: getUserProfile(state),
    userGroupings: getUserGroupings(state),
    userSettings: getUserSettings(state),
  };
};

const mapDispatchToProps = dispatch => {
  const { actions } = UserManager;
  return bindActionCreators(
    {
      setUserProfile: actions.setUserProfile,
      setCustomErrorDescription: UserManager.actions.setCustomErrorDescription,
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(ContactMainContainer));
