import React from 'react';
import PropTypes from 'prop-types';

// Components
import { MapLayerWrapper as Layer } from '../../Common';

// Constants
import { LAYERS } from '../Constants';

// Theme & Utils
import { colors } from '../../../Core/Theme';
import { createHash } from '../../../Core/Utils';

// Layer properties
const fillProperties = {
  id: LAYERS.AVAILABLE_DESK,
  type: 'fill',
  source: 'features',
  paint: {
    'fill-color': [
      'case',
      ['boolean', ['feature-state', 'hover'], false],
      colors.greenLightest,
      colors.greenLight,
    ],
  },
};
const lineProperties = {
  id: `${LAYERS.AVAILABLE_DESK}-lines`,
  type: 'line',
  source: 'features',
  paint: {
    'line-color': colors.green,
    'line-width': 2,
    'line-opacity': 0.6,
  },
};

// Legend
const legend = {
  key: LAYERS.AVAILABLE_DESK,
  style: {
    backgroundColor: colors.greenLight,
    borderColor: colors.green,
    borderWidth: 1,
    borderStyle: 'solid',
  },
  labelKey: 'deskFloorPlan.legend.availableDesk',
};

const AvailableDesk = props => {
  const { desks } = props;
  return (
    <Layer
      key={`${LAYERS.AVAILABLE_DESK}-${createHash(desks)}`}
      properties={[fillProperties, lineProperties]}
      filter={['in', ['get', 'id'], ['literal', desks]]}
      {...props}
    />
  );
};

AvailableDesk.defaultProps = {
  desks: [],
};
AvailableDesk.propTypes = {
  desks: PropTypes.arrayOf(PropTypes.string),
};

export { legend };
export default React.memo(AvailableDesk);
