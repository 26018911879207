/* eslint-disable no-underscore-dangle */

import axios from 'axios';
import FormData from 'form-data';
import { GatewayServices, getGatewayUrl, ApiExposure } from '../Utils/gateway';

export const fetchRooms = (locationId, groupingId = '') => {
  return new Promise(async resolve => {
    const rooms = axios.get(
      `spaces?location_sfid=${locationId}&grouping_id=${groupingId}`
    );
    resolve(rooms);
  }).catch(() => {});
};

export const fetchRoomAmenities = (includeInactive = false) => {
  return new Promise(async (resolve, reject) => {
    const amenities = axios.get(
      `spaces/amenities?include_inactive=${includeInactive}`
    );
    if (amenities.status === 403) {
      reject(amenities);
    }
    resolve(amenities);
  }).catch(objError => {
    return objError;
  });
};

export const deleteRoom = roomId => {
  return axios.delete(`/spaces/${roomId}`);
};

export const createRoom = objRoom => {
  return axios.post('/spaces', objRoom);
};

export const updateRoom = (roomId, objRoom) => {
  return axios.put(`/spaces/${roomId}`, objRoom);
};

export const updateRoomImage = (roomId, image) => {
  const data = new FormData();
  data.append('files', image);
  return axios.post(`/spaces/${roomId}/image`, data, {
    headers: {
      'Content-Type': `multipart/form-data ; boundary=${data._boundary}`,
    },
  });
};

export const createFloor = objFloor => {
  return axios.post('/floors', objFloor);
};

export const fetchCiscoWorkspaces = workspaceName => {
  return axios.get(`cisco-workspaces?displayName=${workspaceName}`, {
    baseURL: getGatewayUrl(
      GatewayServices.inventory,
      '1.1.0',
      process?.env?.REACT_APP_API_GATEWAY,
      ApiExposure.Admin
    ),
  });
};

export const fetchCiscoWorkspaceById = workspaceId => {
  return axios.get(`cisco-workspaces/${workspaceId}`, {
    baseURL: getGatewayUrl(
      GatewayServices.inventory,
      '1.1.0',
      process?.env?.REACT_APP_API_GATEWAY,
      ApiExposure.Admin
    ),
  });
};
