import * as React from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { Collapse, Fade } from '@material-ui/core';
import clsx from 'clsx';
import { Maximize, Add } from '@material-ui/icons';
import Fab from '@material-ui/core/Fab';
import { useTranslation } from 'react-i18next';

import { colors } from '../../../Core/Theme';

const CollapseHeader = props => {
  const {
    classes,
    children,
    title,
    disabled,
    className,
    titleClassName,
    leftInput,
    datatestid,
  } = props;
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = React.useState(true);

  return (
    <div className={className}>
      <div className={classes.header}>
        {leftInput && <div className={classes.leftInput}>{leftInput}</div>}
        <div
          className={clsx(classes.title, titleClassName)}
          datatestid={datatestid}
        >
          {title}
        </div>
        {!disabled && (
          <Fab
            size="small"
            onClick={() => setIsOpen(!isOpen)}
            aria-label={t('general.toggle_open')}
            className={clsx(classes.closeButton)}
          >
            <Fade in={isOpen} timeout={300}>
              <Maximize className={classes.icon} />
            </Fade>
            <Fade in={!isOpen} timeout={300}>
              <Add className={clsx(classes.icon, classes.addIcon)} />
            </Fade>
          </Fab>
        )}
      </div>
      <Collapse in={isOpen} timeout="auto" unmountOnExit>
        <div>{children}</div>
      </Collapse>
    </div>
  );
};

const styles = () => ({
  header: {
    boxShadow: '0 0 4px 0 rgba(0, 0, 0, 0.1)',
    padding: 16,
    paddingBottom: 14,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: colors.white,
    position: 'relative',
  },
  title: {
    fontFamily: 'VerlagBold',
    fontSize: 14,
    lineHeight: 1,
    lineSpacing: '.6px',
  },
  closeButton: {
    background: 'transparent',
    boxShadow: 'none',
    position: 'absolute',
    right: 10,
    top: 12,
    '&:hover': {
      background: 'transparent',
      boxShadow: 'none',
    },
  },
  icon: {
    color: colors.gray,
    position: 'absolute',
  },
  addIcon: {
    top: 0,
  },
  leftInput: {
    position: 'absolute',
    left: 10,
  },
});

CollapseHeader.defaultProps = {
  leftInput: null,
  title: '',
  disabled: false,
  className: '',
  titleClassName: '',
  datatestid: '',
};

CollapseHeader.propTypes = {
  classes: PropTypes.object.isRequired,
  leftInput: PropTypes.node,
  title: PropTypes.string,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  titleClassName: PropTypes.string,
  datatestid: PropTypes.string,
};

export default withStyles(styles)(CollapseHeader);
