import { Moment } from 'moment';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import {
  StyleRulesCallback,
  Theme,
  withStyles,
} from '@material-ui/core/styles';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';

import {
  GroupingLocationList,
  DayPicker,
  SubmitButton,
} from '../../../Components/Common';
import { fetchCountAvailableOffices, GroupingDto } from '../../../Core/Api';

import { date as DateUtils } from '../../../Core/Utils';
import { locationRoleAccess } from '../../../Core/Utils/userPermissions';
import { breakpoints } from '../../../Core/Theme';
import {
  getUserProfile,
  selectIsManager,
} from '../../../Redux/Common/UserManager/selectors';
import LocationAndDateSelectMobile from '../../../Components/Common/LocationAndDateSelect/LocationAndDateSelectMobile';
import { getIsStandalone } from '../../../Models/groupings';

type Props = {
  classes: ClassNameMap;
  grouping?: GroupingDto;
  epoch: number;
  onSearchRequested: (grouping: GroupingDto, epoch: number) => void;
  onFilterChange: (grouping: GroupingDto, epoch: number) => void;
  refreshCount: number;
  isLoadingGroupings: boolean;
  groupings?: GroupingDto[];
};

const OfficeReservationFilter = (props: Props) => {
  const {
    classes,
    grouping: defaultGrouping,
    epoch: defaultEpoch,
    onSearchRequested,
    onFilterChange,
    refreshCount,
    isLoadingGroupings,
    groupings,
  } = props;

  const { t } = useTranslation();
  const isManager = useSelector(selectIsManager);
  const profile = useSelector(getUserProfile);

  const [count, setCount] = useState<number>();
  const [countIsLoading, setCountIsLoading] = useState(false);
  const [grouping, setGrouping] = useState(defaultGrouping);
  const [epoch, setEpoch] = useState(defaultEpoch);

  useEffect(() => {
    setGrouping(defaultGrouping);
    setEpoch(defaultEpoch);
  }, [refreshCount]);

  useEffect(() => {
    if (!grouping && defaultGrouping) setGrouping(defaultGrouping);
  }, [defaultGrouping]);

  useEffect(() => {
    setCountIsLoading(true);

    if (!grouping) return;
    const isStandalone = getIsStandalone(grouping);
    fetchCountAvailableOffices({
      groupingId: isStandalone ? grouping.idGrouping : undefined,
      locationSfId: grouping.locationSfId,
      integratedOnly: !isStandalone,
      startEpoch: DateUtils.updateDateForTZ(
        DateUtils.getUnixStartOfDay(epoch),
        grouping.location.timeZoneId,
        DateUtils.getCurrentZone()
      ),
      endEpoch: DateUtils.updateDateForTZ(
        DateUtils.getUnixEndOfDay(epoch),
        grouping.location.timeZoneId,
        DateUtils.getCurrentZone()
      ),
    })
      .then(response => setCount(response.data.count))
      .finally(() => setCountIsLoading(false));
  }, [grouping, epoch]);

  const btnText = useMemo(() => {
    if (count) {
      return t<string>('reserveOffice.button_text', { count });
    }
    return t<string>('reserveOffice.button_text_no_office');
  }, [count]);

  const handleGroupingChange = (selectedGrouping: GroupingDto) => {
    setGrouping(selectedGrouping);
    onFilterChange(selectedGrouping, epoch);
  };

  const handleEpochChange = (date: Moment | number) => {
    const value = DateUtils.getDateInMoment(date).unix();
    setEpoch(value);
    if (!grouping) return;
    onFilterChange(grouping, value);
  };

  const handleOnSelectLocationAndDate = (params: {
    selectedDate: number;
    selectedGrouping: GroupingDto;
  }) => {
    const { selectedDate, selectedGrouping } = params;
    onSearchRequested(selectedGrouping, selectedDate);
  };

  return (
    <>
      <div className={classes.filterContainer}>
        <div className={classes.select}>
          <GroupingLocationList
            onChange={handleGroupingChange}
            selectedGrouping={grouping}
            isLoadingBookableGroupings={false}
            datatestid="reserve_office_location_dropdown"
            withoutViewHomeLocation
            locationGroupings={groupings}
            usersDefaultGrouping={defaultGrouping}
            isLoading={isLoadingGroupings}
          />
        </div>
        <div className={classes.select}>
          <div className={classes.label}>
            {t<string>('reserveOffice.date_lbl')}
          </div>
          <DayPicker
            id="office_reservation_date_filter"
            type="day"
            onDateChange={handleEpochChange}
            valueDate={epoch}
            selectedDate={epoch}
            disableWeekends={
              !isManager ||
              !locationRoleAccess.bookWeekendsAt({
                profile,
                obj: grouping?.location,
              })
            }
            disablePastDate={!isManager}
            datatestid="reserve_office_date_dropdown"
          />
        </div>
        <SubmitButton
          className={classes.button}
          defaultText={btnText}
          onClick={() => {
            if (!grouping) return;
            onSearchRequested(grouping, epoch);
          }}
          isLoading={countIsLoading}
          datatestid="reserve_office_filter_action"
        />
      </div>
      <div className={classes.mobileFilters}>
        <LocationAndDateSelectMobile
          selectedGrouping={grouping}
          selectedDate={epoch}
          onSelectLocationAndDate={handleOnSelectLocationAndDate}
          onChangeLocation={handleGroupingChange}
          onChangeDate={handleEpochChange}
          actionLabel={btnText}
          isLoadingActionLabel={countIsLoading}
          label={t('reserveOffice.edit_your_search')}
          profile={profile}
          onReset={() => {
            setGrouping(defaultGrouping);
            setEpoch(defaultEpoch);
          }}
          locationListProps={{
            locationGroupings: groupings,
            usersDefaultGroupings: defaultGrouping,
            isLoading: isLoadingGroupings,
          }}
        />
      </div>
    </>
  );
};

const styles: StyleRulesCallback<string> = (theme: Theme) => ({
  filterContainer: {
    display: 'none',
    marginTop: 75,
    marginBottom: 15,
    [theme.breakpoints.up(breakpoints.MOBILE)]: {
      display: 'flex',
      alignItems: 'end',
      gap: '1rem',
      flexWrap: 'wrap',
    },
  },
  label: {
    fontFamily: 'VerlagBold',
    fontSize: 12,
    textTransform: 'uppercase',
    paddingBottom: 2,
  },
  button: {
    padding: 10,
    width: 'fit-content',
    display: 'none',
    [theme.breakpoints.down(breakpoints.SM)]: {
      width: '100%',
    },
    [theme.breakpoints.up(breakpoints.MOBILE)]: {
      display: 'block',
    },
  },
  select: {
    minWidth: 324,
    [theme.breakpoints.down(breakpoints.SM)]: {
      width: '100%',
    },
  },
  pageWrapper: {
    position: 'absolute',
    left: 0,
    right: 0,
    bottom: 0,
    width: '100vw',
    height: '100vh',
  },
  mobileFilters: {
    display: 'block',
    marginTop: 25,
    marginBottom: 16,
    [theme.breakpoints.up(breakpoints.MOBILE)]: {
      display: 'none',
    },
  },
});
OfficeReservationFilter.defaultProps = {
  grouping: undefined,
  groupings: [],
};

export default withStyles(styles)(OfficeReservationFilter);
