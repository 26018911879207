import { createAction } from 'redux-actions';
import * as types from './actionTypes';
import { fetchRoomAmenities } from '../../../Core/Api';
import { fetchHandlerActions } from '../../../Core/Utils/fetch';

export const updateDate = createAction(types.UPDATE_DATE);

export const toggleFilter = (value, type) => ({
  type: types.TOGGLE_FILTER,
  payload: { type, filter: value },
});

export const updateMonth = createAction(types.UPDATE_MONTH);

export const setLocation = createAction(types.SET_LOCATION);

export const setGrouping = createAction(types.SET_GROUPING);

export const addQueriedDateAndLocation = createAction(
  types.ADD_QUERIED_DATE_LOCATION
);

export const addQueriedDateGrouping = createAction(
  types.ADD_QUERIED_DATE_GROUPING
);
export const addAmenities = createAction(
  types.ADD_ROOM_AMENITIES,
  fetchHandlerActions(fetchRoomAmenities)
);

export const setUpcomingResvDrawerState = createAction(
  types.SET_UPCOMING_RESV_DRAWER_STATE
);

export const addBookingRestriction = createAction(
  types.ADD_BOOKING_RESTRICTION
);

export const addLastGroupingAt = createAction(types.ADD_LAST_GROUPING_AT);

export const clearAllFilters = createAction(types.CLEAR_ALL_FILTERS);

export const setTimezone = createAction(types.SET_TIMEZONE);
