/* eslint-disable consistent-return */
import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import Lodash from 'lodash';
import axios from 'axios';
import { bindActionCreators } from 'redux';
import { captureException } from '@sentry/react';
import queryString from 'query-string';
import { withTranslation } from 'react-i18next';

import { persistor } from '../../Redux';
import loginAuth from './loginAuth';
import { PasswordInput } from '../../Components/Auth';

// Core
import { userLogin } from '../../Core/Api';
import { setCustomDimensions } from '../../Core/Tracking/gtag';

// Redux
import Redux from '../../Redux/Common/UserManager';
import {
  getUserToken,
  getUserProfile,
  getStatusUser,
  getIsFirstLogin,
  getUsersDefaultRoute,
  getCanAccessMemberPortal,
  getCanAccessBillingPortal,
} from '../../Redux/Common/UserManager/selectors';

// Constants
import {
  USER_DOES_NOT_EXIST_MESSAGE,
  USER_IS_NOT_ACTIVE_MESSAGE,
  LOGIN_PATH,
} from './constants';

// Styles
import { colors } from '../../Core/Theme';
import styles from './styles';

// Components
import FieldInput from '../../Components/Common/FieldInput';
import CheckBox from '../../Components/Common/CheckBox';
import Button from '../../Components/Common/BaseButton';
import { SubmitButton } from '../../Components/Common';
import Text from '../../Components/Common/Text';

// Logos
import { Logos } from '../../resources';

// Permissions
import { accessHQ } from '../../Core/Utils/userPermissions';

class Login extends React.Component {
  constructor() {
    super();
    this.state = {
      username: '',
      password: '',
      displayMsg: '',
      isActiveRememberMe: false,
      isSigningIn: false,
      isSigningSuccessful: false,
    };
  }

  componentDidMount() {
    const mailSaved = localStorage.getItem('mail');
    const isRememberMe = JSON.parse(localStorage.getItem('isRememberMe'));

    if (isRememberMe && mailSaved) {
      this.setState({
        username: mailSaved,
        isActiveRememberMe: isRememberMe,
      });
    }
  }

  getRedirectPath = () => {
    const windowLocationSearch = window.location.search;
    const queryParams = queryString.parse(windowLocationSearch);
    return Lodash.get(queryParams, ['redirectPath'], null);
  };

  getUserData = userId => {
    const {
      setUserProfile,
      setUserActive,
      setIsFirstLogin,
      fetchNotificationSettings,
      t,
    } = this.props;
    return userLogin(userId)
      .then(responseUserProfile => {
        const objUserProfile = Lodash.get(responseUserProfile, ['data'], {});
        fetchNotificationSettings(objUserProfile.sfId);
        setUserProfile(objUserProfile);
        const { isFirstLogin } = objUserProfile;
        setIsFirstLogin(isFirstLogin);
        setUserActive(true);
        return objUserProfile;
      })
      .catch(varError => {
        if (varError === 403) {
          this.handleDisplayMessage(t('login.user_not_active'));
          return {};
        }
        captureException(varError);
        return varError;
      });
  };

  handleDisplayMessage = message => {
    const { t } = this.props;
    let displayMessage = '';
    switch (message) {
      case USER_DOES_NOT_EXIST_MESSAGE:
        displayMessage = t('login.user_does_not_exist');
        break;
      case USER_IS_NOT_ACTIVE_MESSAGE:
        displayMessage = t('login.user_is_not_active');
        break;
      default:
        displayMessage = t('login.error');
        break;
    }
    this.setState({
      displayMsg: displayMessage,
    });
  };

  handleEnter = key => {
    if (key.key === 'Enter') {
      this.handleSignIn();
    }
  };

  handleOnChange = event => {
    const {
      target: { value, name },
    } = event;
    this.setState({
      [name]: value,
    });
  };

  handleOnChangeUserName = value => {
    this.setState({
      username: value,
    });
  };

  handleSignIn = () => {
    const { username: mail, password, isActiveRememberMe } = this.state;
    const { setUserToken, setUserId, login, logout, setClientAccessClaims } =
      this.props;

    this.setState({ isSigningIn: true });
    const trimmedMail = mail.trim();

    // RAN-5170: issue when refresh token is expired
    // Loging in with another user breaks the app
    // due to `persist:auth' containing info from former authenticated user
    // persistor.purge() makes sure the persisted data is cleared.
    persistor
      .purge()
      .then(() => {
        loginAuth(trimmedMail, password).then(res => {
          if (res.success) {
            const { token, id, clientAccessClaims } = res;
            axios.defaults.headers.common.Authorization = `Bearer ${token}`;
            return this.getUserData(id).then(objResponse => {
              if (objResponse.idUser) {
                // if the call for data succeeded
                setUserId(id);
                login(objResponse, isActiveRememberMe);
                setClientAccessClaims(clientAccessClaims);
                this.setState(
                  { isSigningIn: false, isSigningSuccessful: true },
                  () => {
                    setTimeout(() => {
                      persistor.persist();
                      setUserToken(token);
                      this.setState({ isSigningSuccessful: false });
                    }, 500);
                  }
                );
              } else {
                // if the call for data failed
                logout(); // to reset the token and clear local storage
                this.setState({
                  isSigningIn: false,
                  isSigningSuccessful: false,
                });
              }
            });
          }
          this.handleDisplayMessage(res.message);
          return this.setState({ isSigningIn: false });
        });
      })
      .catch(e => {
        captureException(e);
        this.setState({ isSigningIn: false });
      });
  };

  toggleCheckbox = () => {
    this.setState(prevState => ({
      isActiveRememberMe: !prevState.isActiveRememberMe,
    }));
  };

  render() {
    const {
      displayMsg,
      username,
      password,
      isActiveRememberMe,
      isSigningIn,
      isSigningSuccessful,
    } = this.state;
    const {
      classes,
      userToken,
      isUserActive,
      firstTimeLogin,
      location,
      objProfile: user,
      defaultRoute,
      canAccessMemberPortal,
      canAccessBillingPortal,
      t,
    } = this.props;
    if (userToken && user.sfId && isUserActive) {
      const param = {
        user_role: user.role,
        home_location: user.primaryLocation.externalName,
        user_sfId: user.sfId,
      };

      setCustomDimensions(param);

      const to = Lodash.get(location, ['state', 'to'], null);
      const search = Lodash.get(location, ['state', 'search'], '');
      const redirectPath = this.getRedirectPath();
      if (redirectPath) {
        return <Redirect to={redirectPath} />;
      }
      if (to && to !== '/home/logout' && !firstTimeLogin) {
        return (
          <Redirect
            from={LOGIN_PATH}
            to={{
              pathname: to,
              search,
            }}
          />
        );
      }
      if (firstTimeLogin && !accessHQ(user) && canAccessMemberPortal) {
        return (
          <Redirect
            from={LOGIN_PATH}
            to={{
              pathname: '/onboarding',
              state: {
                firstTimeLogin: true,
              },
            }}
          />
        );
      }

      if (!canAccessMemberPortal && canAccessBillingPortal) {
        // we need a hard redirect and not a <Redirect /> component
        // because we are redirecting to a different app
        document.location.href = '/billing';
        return null;
      }

      return (
        <Redirect
          from={LOGIN_PATH}
          to={{
            pathname: defaultRoute,
          }}
        />
      );
    }
    return (
      <div className={classes.container}>
        <form className={classes.form} role="main">
          <div className={classes.titleLogoContainer}>
            <Logos.WhiteIndLogoVert
              className={classes.indLogo}
              datatestid="login_industr_logo_picture"
            />
          </div>
          <div className={classes.inputsContainer}>
            {displayMsg && (
              <div className={classes.containerMetaInfo}>
                <Text text={displayMsg} />
              </div>
            )}
            <FieldInput
              className={classes.fieldInputStyle}
              name="username"
              value={username}
              placeholder={t('login.email')}
              variant="standard"
              onChange={this.handleOnChangeUserName}
              isLabelHidden
              inputProps={{
                style: { paddingLeft: 10, paddingRight: 10 },
              }}
              // eslint-disable-next-line react/jsx-no-duplicate-props
              InputProps={{
                disableUnderline: true,
                classes: {
                  input: classes.inputRoot,
                },
              }}
              label="username box"
              id="usernameInput"
              InputLabelProps={{
                classes: { root: classes.inputLabel },
                shrink: true,
              }}
              datatestid="login_email_input_field"
            />
            <PasswordInput
              name="password"
              value={password}
              placeholder={t('login.password')}
              type="password"
              onChange={this.handleOnChange}
              onKeyDown={this.handleEnter}
              id="passwordInput"
              label="password box"
              datatestid="login_password_input_field"
            />
            <CheckBox
              checkedColor={colors.white}
              labelStyle={classes.checkBoxLabelStyle}
              checkBoxText={t('login.remember_me')}
              onChange={this.toggleCheckbox}
              value="isActiveRememberMe"
              checked={isActiveRememberMe}
              focusVisibleClassName={classes.checkBoxFocus}
              className={classes.checkBox}
              datatestid={
                isActiveRememberMe
                  ? 'login_remember_me_checkbox_full'
                  : 'login_remember_me_checkbox_empty'
              }
            />
            <SubmitButton
              className={classes.buttonSignInStyle}
              defaultText={t('login.sign_in')}
              isLoading={isSigningIn}
              isSuccess={isSigningSuccessful}
              onClick={this.handleSignIn}
              datatestid="login_signin_button"
            />
            <div className={classes.linkContainer}>
              <Button
                className={classes.forgotPasswordStyle}
                link
                name={t('login.forgot_password')}
                to="/forgotpassword"
                datatestid="login_forgot_password_link"
              />
            </div>
          </div>
        </form>
      </div>
    );
  }
}

Login.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  history: PropTypes.shape({}).isRequired,
  setUserToken: PropTypes.func.isRequired,
  setUserProfile: PropTypes.func.isRequired,
  setUserActive: PropTypes.func.isRequired,
  setUserId: PropTypes.func.isRequired,
  userToken: PropTypes.string.isRequired,
  login: PropTypes.func.isRequired,
  isUserActive: PropTypes.bool.isRequired,
  setIsFirstLogin: PropTypes.func.isRequired,
  firstTimeLogin: PropTypes.bool.isRequired,
  objProfile: PropTypes.shape({
    role: PropTypes.string.isRequired,
    primaryLocation: PropTypes.shape({
      externalName: PropTypes.string.isRequired,
    }),
    sfId: PropTypes.string.isRequired,
  }).isRequired,
  logout: PropTypes.func.isRequired,
  fetchNotificationSettings: PropTypes.func.isRequired,
  setClientAccessClaims: PropTypes.func.isRequired,
  defaultRoute: PropTypes.string.isRequired,
  canAccessMemberPortal: PropTypes.bool.isRequired,
  canAccessBillingPortal: PropTypes.bool.isRequired,
  t: PropTypes.func.isRequired,
};

const mapStateToProps = state => {
  return {
    userToken: getUserToken(state),
    objProfile: getUserProfile(state),
    isUserActive: getStatusUser(state),
    firstTimeLogin: getIsFirstLogin(state),
    defaultRoute: getUsersDefaultRoute(state),
    canAccessMemberPortal: getCanAccessMemberPortal(state),
    canAccessBillingPortal: getCanAccessBillingPortal(state),
  };
};

const mapDispatchToProps = dispatch => {
  const { actions } = Redux;
  return bindActionCreators(
    {
      setUserToken: actions.setUserToken,
      setUserId: actions.setUserId,
      setUserProfile: actions.setUserProfile,
      setUserActive: actions.setUserActive,
      login: actions.login,
      setIsFirstLogin: actions.setIsFirstLogin,
      logout: actions.logout,
      fetchNotificationSettings: actions.fetchUserNotificationSettings,
      setClientAccessClaims: actions.setClientAccessClaims,
    },
    dispatch
  );
};

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Login))
);
