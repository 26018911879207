import * as React from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import AvatarEditor from 'react-avatar-editor';
import { Slider } from 'material-ui-slider';
import { Close, ZoomIn, ZoomOut } from '@material-ui/icons';
import clsx from 'clsx';
import Fab from '@material-ui/core/Fab';
import { useTranslation } from 'react-i18next';

// Components
import ImageUpload from '../ImageUpload';
import SubmitButton from '../SubmitButton';

//  Core
import { useWindowDimensions } from '../../../Core/Utils';
import { colors, breakpoints } from '../../../Core/Theme';

const closeNoSave =
  (toggleModal, previousImage, setDisplayImage, setIsImageError) => () => {
    toggleModal();
    setDisplayImage(previousImage);
    setIsImageError(false);
  };
const ImageCropper = props => {
  const {
    image,
    setDisplayImage,
    classes,
    onSubmit,
    toggleModal,
    newPreviousImage,
    title,
    previousImage,
    isImageError,
    setIsImageError,
  } = props;
  const [editorRef, setEditorRef] = React.useState(null);
  const [scale, setScale] = React.useState(1);
  const { width } = useWindowDimensions();
  const { t } = useTranslation();
  const isMobile = width < breakpoints.MOBILE;
  const saveCroppedImage = async editor => {
    if (!isImageError) {
      try {
        const avatar = editor.getImageScaledToCanvas().toDataURL();
        const blob = await fetch(avatar).then(res => res.blob());
        await onSubmit(blob);
        setDisplayImage(avatar);
        newPreviousImage(avatar);
        toggleModal();
      } catch (e) {
        toggleModal();
      }
    }
  };
  return (
    <div className={classes.contentView}>
      <Fab
        size="small"
        onClick={closeNoSave(
          toggleModal,
          previousImage,
          setDisplayImage,
          setIsImageError
        )}
        aria-label={t('general.close')}
        className={classes.close}
      >
        <Close />
      </Fab>
      <p className={classes.title}>{title}</p>
      {isImageError ? (
        <div className={classes.imageError}>
          {t('general.image_size_error')}
        </div>
      ) : null}
      <div className={classes.cropperAligner}>
        <div
          className={clsx(
            classes.imageCropper,
            !image && classes.imageCropperError
          )}
        >
          <AvatarEditor
            image={image}
            ref={setEditorRef}
            width={200}
            height={200}
            borderRadius={200}
            border={isMobile ? [50, 25] : [100, 50]}
            scale={scale}
          />
        </div>
      </div>
      <div className={classes.bottomPanel}>
        <div className={classes.slider}>
          <ZoomOut fontSize="inherit" />
          <Slider
            min={0.4}
            max={2}
            decimals={1}
            value={scale}
            onChange={setScale}
            color={colors.black}
          />
          <ZoomIn fontSize="inherit" />
        </div>
        <div className={classes.controls}>
          <ImageUpload
            setDisplayImage={setDisplayImage}
            className={classes.uploadView}
            labelText={t('imageUpload.change_photo')}
            textButtonInput
            setIsImageError={setIsImageError}
          />
          <SubmitButton
            className={classes.submitButton}
            defaultText={t('imageUpload.save_photo')}
            type="submit"
            onClick={() => saveCroppedImage(editorRef)}
            disabled={isImageError && !image}
          />
        </div>
      </div>
    </div>
  );
};

const styles = theme => ({
  contentView: {
    textAlign: 'center',
    position: 'relative',
    [theme.breakpoints.up(breakpoints.MOBILE)]: {
      width: 450,
      paddingLeft: 75,
      paddingRight: 75,
    },
    [theme.breakpoints.down(breakpoints.MOBILE)]: {
      height: '100%',
    },
  },
  imageCropper: {
    background: colors.black,
    height: 250,
    [theme.breakpoints.up(breakpoints.MOBILE)]: {
      height: 304,
    },
  },
  imageCropperError: {
    background: colors.light,
    height: 250,
    [theme.breakpoints.up(breakpoints.MOBILE)]: {
      height: 304,
    },
  },
  uploadView: {
    width: '80%',
    height: '80%',
  },
  bottomPanel: {
    paddingTop: 15,
    paddingBottom: 15,
    background: colors.white,
    [theme.breakpoints.up(breakpoints.MOBILE)]: {},
  },
  submitButton: {
    width: 162,
    margin: 'auto',
    marginLeft: 0,
    marginRight: 0,
    fontFamily: 'VerlagBold',
    [theme.breakpoints.up(breakpoints.MOBILE)]: {
      width: 215,
    },
  },
  controls: {
    display: 'flex',
    whiteSpace: 'nowrap',
    justifyContent: 'space-evenly',
    [theme.breakpoints.down(breakpoints.MOBILE)]: {
      position: 'absolute',
      bottom: 70,
      left: 0,
      right: 0,
    },
  },
  title: {
    fontFamily: 'VerlagBold',
    fontSize: 20,
    marginTop: 50,
    [theme.breakpoints.up(breakpoints.MOBILE)]: {
      fontSize: 24,
      marginTop: 20,
    },
  },
  close: {
    position: 'absolute',
    right: '7px',
    top: '-30px',
    [theme.breakpoints.up(breakpoints.MOBILE)]: {
      right: '10px',
      top: '-10px',
    },
    background: colors.white,
    boxShadow: 'none',
    '&:hover': {
      background: colors.white,
    },
    '&:active': {
      boxShadow: 'none',
    },
  },
  slider: {
    display: 'flex',
    alignItems: 'center',
    fontSize: 20,
    [theme.breakpoints.down(breakpoints.MOBILE)]: {
      paddingLeft: 20,
      paddingRight: 20,
    },
  },
  cropperAligner: {
    display: 'flex',
    justifyContent: 'center',
  },
  imageError: {
    border: `solid 1.5px ${colors.alertBackground}`,
    backgroundColor: colors.white,
    borderRadius: 2,
    fontFamily: 'VerlagBold',
    fontSize: 13,
    color: colors.alertMessage,
    // marginLeft: 10,
    // marginRight: 10,
    paddingRight: 10,
    paddingLeft: 10,
    paddingTop: 5,
    paddingBottom: 5,
    textAlign: 'left',
    marginBottom: 10,
    [theme.breakpoints.up(breakpoints.MOBILE)]: {
      width: 377,
      marginLeft: 25,
    },
    [theme.breakpoints.down(breakpoints.MOBILE)]: {
      marginLeft: 10,
      marginRight: 10,
    },
  },
});

ImageCropper.defaultProps = {
  classes: {},
  image: '',
  newPreviousImage: () => '',
  title: 'Edit your profile photo',
};

ImageCropper.propTypes = {
  setDisplayImage: PropTypes.func.isRequired,
  classes: PropTypes.shape({}),
  image: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
  toggleModal: PropTypes.func.isRequired,
  newPreviousImage: PropTypes.func,
  title: PropTypes.string,
  previousImage: PropTypes.object.isRequired,
  setIsImageError: PropTypes.func.isRequired,
  isImageError: PropTypes.bool.isRequired,
};

export default withStyles(styles)(ImageCropper);
