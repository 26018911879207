export const GROUPING_TYPES = {
  STANDALONE: 'Standalone',
  INTEGRATED: 'Integrated',
};

export const getIsStandalone = grouping => {
  return grouping?.type === GROUPING_TYPES.STANDALONE;
};

export const getIsIntegrated = grouping => {
  return grouping?.type === GROUPING_TYPES.INTEGRATED;
};

export const getIsBookable = grouping => {
  return grouping?.isBookable === true;
};

export const getIsGrouping = grouping => {
  return !!grouping.idGrouping;
};

export const getIsOpenToCoworking = grouping => {
  return grouping.openToCoworkingMembers;
};
